import { Image, Text, View } from '@react-pdf/renderer'
import React from 'react'
import { EmployementStyle } from './css/EmployementStyle'
import CertificationIcon from "../../../assets/img/icons/certification-icon.png";
import { MyReviewsCSS } from './css/MyReviews';
import ReviewGrafik from "../../../assets/img/icons/review-graphic.png"
import RatinLists from './skill-result/RatinLists';
import Refresh from "./../../../assets/img/icons/refresh.png";
import StartIcon from "../../../assets/img/icons/star.png"
import { ratingName } from '../../../view/reviews/constant';

const MyReviews = ({ data }) => {
    return (
        Array.isArray(data) &&
        data?.map((itms)=>(
            <View style={{ ...EmployementStyle.employetmentMain, marginTop: "10px" }}>
                <View style={EmployementStyle.emplLayoutLeft} >
                    <View>
                        <Image source={CertificationIcon} style={{ ...EmployementStyle.employementIcon, height: "10px", width: "10px" }} />
                    </View>
                    <View style={EmployementStyle.emplLayoutLists}>
                        <Text style={EmployementStyle.empHeading}> Reviews</Text>
                    </View>
                </View>
                <View style={MyReviewsCSS.container}>
                    <View style={MyReviewsCSS.quoteImageContainer}>
                        <Image source={ReviewGrafik} style={MyReviewsCSS.quoteImage} />
                    </View>
                    <Text style={MyReviewsCSS.message}>{itms?.comment}</Text>
                    <View style={MyReviewsCSS.ratingContainer}>
                        {/* {Object.keys(itms?.rateParams)?.map((param) =>
                            <RatinLists title={ratingName[`${param}`]} />
                        )} */}

                    </View>
                    <View style={MyReviewsCSS.reviewProvider}>
                        <Text style={MyReviewsCSS.reviewerName}>{itms?.receiverName}</Text>
                        <Text style={MyReviewsCSS.reviewerDesg}>{itms?.receiverDesg}</Text>
                    </View>
                    <View style={MyReviewsCSS.footerArea}>
                        <View style={MyReviewsCSS.footerLeft}>
                            <View style={MyReviewsCSS.footerLeftBox}>
                                <View>
                                    <Image
                                        source={Refresh}
                                        style={MyReviewsCSS.lastDate} />
                                </View>
                                <View>
                                    <Text style={MyReviewsCSS.lastDate}> {new Date(itms?.ratedOn)
                                        .toDateString()
                                        .substring(
                                            4,
                                            new Date(itms?.ratedOn).toDateString().length
                                        )}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={MyReviewsCSS.footerRight}>
                            <View >
                                <Image source={StartIcon} style={MyReviewsCSS.startIcon} />
                            </View>
                            <View >
                                <Image source={StartIcon} style={MyReviewsCSS.startIcon} />
                            </View>
                            <View >
                                <Image source={StartIcon} style={MyReviewsCSS.startIcon} />
                            </View>
                            <View >
                                <Image source={StartIcon} style={MyReviewsCSS.startIcon} />
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        ))
    )
}

export default MyReviews