import * as types from "../";

export const showOpenFaqsAction = (navigate) => ({
  type: types.SHOW_OPEN_FAQS,
  navigate
})

export const uploadVideoWithoutDeleteAction = (data, navigate, setVideoPercentage, fromVideoTab) => ({
  type: types.UPLOAD_VIDEO_WITHOUT_DELETE,
  data,
  navigate,
  setVideoPercentage,
  fromVideoTab
})

export const uploadVideoAction = (data, navigate,setVideoPercentage, userId) => ({
  type: types.UPLOAD_VIDEO,
  data,
  navigate,
  setVideoPercentage,
  userId,
})

export const getReviewStatusAction = (data, navigate) => ({
  type: types.GET_DEV_REVIEW_STATUS,
  data,
  navigate
})

export const getInfoForReviewAction = (devId, navigate) => ({
  type: types.GET_DEV_INFO_FOR_REVIEW,
  devId,
  navigate
})

export const fetchReport = (data, navigate, setModal) => ({
  type: types.FETCH_REPORT,
  data,
  navigate,
  setModal
})

export const giveRatingDevReviewAction = (data, navigate) => ({
  type: types.GIVE_RATING_DEV_REVIEW,
  data,
  navigate
})

export const uploadMultiImageAction = (data, navigate,cb) => ({
  type: types.UPLOAD_MULTI_IMAGE,
  data,
  navigate,
  cb
});

export const getFaqLists = () => ({
  type: types.GET_FAQ_LISTS,
});

export const getJobByCategoryAction = (navigate,catId=null, data ,pageData,filter=false,search="") => ({
  type: types.GET_JOB_BY_CAT,
  navigate,
  pageData,
  data,
  pageData,
  filter,
  search,
  catId
});

export const getJobByJobIdAction = (jobId, navigate) => ({
  type: types.GET_JOB_BY_ID,
  jobId,
  navigate,
});

export const getPopularJobsAction = (navigate) => ({
  type: types.GET_POPULAR_JOB,
  navigate,
});

export const getMaintenanceOneAction = (navigate) => ({
  type: types.GET_MAINTENANCE_ONE_MESSAGE,
  navigate,
});

export const changePassword = (data, navigate,closeModal) => ({
  type: types.CHANGE_PASSWORD,
  data,
  navigate,
  closeModal
});

export const changeEmail = (email, navigate) => ({
  type: types.CHANGE_EMAIL,
  email,
  navigate,
});

export const getAllFaqGroup = (data, navigate) => ({
  type: types.GET_ALL_FAQ_GROUP,
  data,
  navigate,
});

export const getJobLists = (navigate,pageData=null) => ({
  type: types.GET_JOB_LIST,
  navigate,
  pageData
});

export const getJobByIdAction = (data, navigate) => ({
  type: types.GET_JOB_BY_ID_LIST,
  data,
  navigate,
});

export const currentJobCatAction = (currentCategory) => ({
  type: types.CURRENT_JOB_CATEGORY,
  data: currentCategory,
});

export const getBlogCategoriesAction = (navigate) => ({
  type: types.GET_BLOG_CATEGORIES,
  navigate,
});

export const setBlogCategoryAction = (currentCategory) => ({
  type: types.SET_BLOG_CATEGORY,
  data: currentCategory,
});

export const getAllBlogsById = (data, navigate) => ({
  type: types.GET_ALL_BLOG_BY_ID,
  data,
  navigate,
});

export const getPopularBlogs = (navigate) => ({
  type: types.GET_POPULAR_BLOGS,
  navigate,
});

export const getRecentBlogs = (navigate) => ({
  type: types.GET_RECENT_BLOGS,
  navigate,
});

export const getBlogCatOpen = (navigate) => ({
  type: types.GET_BLOG_CAT_OPEN,
  navigate,
});

export const getBlogOpen = (navigate) => ({
  type: types.GET_BLOG_OPEN,
  navigate,
});

export const getBlogByIdOpen = (blogId, navigate) => ({
  type: types.GET_BLOG_BY_ID_OPEN,
  blogId,
  navigate,
});

export const getBlogBySlugOpen = (data, navigate) => ({
  type: types.GET_BLOG_BY_SLUG_OPEN,
  data,
  navigate,
});

export const getCountry = () => ({
  type: types.GET_COUNTRY,
});

export const getState = (data, navigate) => ({
  type: types.GET_STATE,
  data,
  navigate,
});

export const getCity = (data, navigate) => ({
  type: types.GET_CITY,
  data,
  navigate,
});

export const ContactUsPostAPI = (data, navigate) => ({
  type: types.CONTACT_US_POST,
  data,
  navigate,
});

export const getBlogsAction = (searchValue, currentBlogCategory, navigate) => ({
  type: types.GET_BLOGS,
  searchValue,
  currentBlogCategory,
  navigate,
});

export const getBlogDetail = (slug, navigate) => ({
  type: types.GET_BLOG_DETAIL,
  slug,
  navigate,
});

export const getSpotlightedCandidates = (navigate) => ({
  type: types.GET_SPOTLIGHTED_CANDIDATES,
  navigate,
});

export const checkAuthentication = (navigate, userType) => ({
  type: types.CHECK_AUTHENTICATION_USER,
  navigate,
  userType,
});

export const getRecentFiveBlogs = (navigate) => ({
  type: types.RECENT_FIVE_BLOGS,
  navigate,
});

export const getApprovalUpdateAction = (navigate, email) => ({
  type: types.GET_APPROVAL_UPDATE,
  navigate,
  email,
});

export const chatGPTStandardEngAction = (navigate, data) => ({
  type: types.OPENAI_STANDARD_ENGLISH,
  navigate,
  data,
});

export const getDevSkillTestResultAction = (data, navigate, setModal, devId) => ({
    type: types.GET_DEV_SKILL_TEST_RESULT,
    data,
    navigate,
    setModal,
    devId
  })

export const getNotification = (navigate) =>({
  type: types.GET_NOTIFICATION,
  navigate
})

export const clearNotification = (navigate) =>({
  type: types.CLEAR_NOTIFICATION,
  navigate
})

export const setSocketAction = (socket) =>({
  type: types.SET_SOCKET,
  payload: socket
})

export const setNotificationData = (latestData) =>({
  type: types.SET_NOTIFICATION_DATA,
  payload: latestData
})


export const convertImageAction=(navigate,data)=>({
  type: types.CONVERT_IMAGE,
  data,
  navigate
})

export const updateTermCondition=(navigate,setModal)=>({
  type: types.UPDATE_TERM_CONDITION,
  navigate,
  setModal
})
export const clearNewFeature=(navigate,setModal)=>({
  type: types.NEW_FEATURE,
  navigate,
  setModal
})

export const getNewFeature=(navigate)=>({
  type: types.GET_NEW_FEATURE,
  navigate,
})

export const getSitemapUrl=(navigate,forType)=>({
  type: types.GET_SITEMAP_URL,
  navigate,
  forType
})

export const getPrincingPlanAction=(navigate,payload)=>({
  type: types.GET_PRICING_PLAN,
  navigate,
  payload
})

export const startPaymentPhonePay=(navigate,payload,cb)=>({
  type: types.START_PAYMENT_PHONE_PAY,
  navigate,
  payload,
  cb
})
export const startPaymentStripe=(navigate,payload,cb)=>({
  type: types.START_PAYMENT_STRIPE,
  navigate,
  payload,
  cb
})
export const paymentCheckOuActiont=(payload)=>({
  type:types.PAYMENT_CHECKOUT,
  payload
})
export const getCompanyJobsList=(payload)=>({
type:types.GET_COMPANY_JOBS,
  payload
})

export const addDevSkillAction=(payload)=>({
  type:types.ADD_DEV_SKILL,
    payload
  })
