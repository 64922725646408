import { Fragment } from "react";
import CompanyProfileDetail from "../../company-profile/CompanyProfileDetail";

const CompanyProfile = ({ }) => {
  return (
    <Fragment>
        <CompanyProfileDetail hideTour={true} />
    </Fragment>
  );
};

export default CompanyProfile;
