const AccordionFAQ = ({ title, content, setTitleOpen, titleOpen, image, fullWidth=false }) => {
   const handleOpeClose=()=>{
    titleOpen==title?setTitleOpen(""):setTitleOpen(title)
  }
  return (
    <div className="accordion-item-faq">
      <div className="accordion-title-faq col-12 hover-class" onClick={handleOpeClose}>
        <div className={`${titleOpen == title ? 'active-title' : 'inactive-title'} width-title`} style={{display: "flex"}}> {!!image && (<img src={image} width={30} height={30} style={{marginRight: "5px"}}/>)} <div style={{marginTop: "5px"}}>{title}</div></div>
        <span className='acc-icon-faq col-12'><span className={titleOpen == title ? 'active-title' : 'inactive-title'}>{titleOpen == title ? '-' : '+'}</span></span>
      </div>
      {titleOpen == title && <div className={`accordion-content-faq ${!fullWidth && "col-11"}`} dangerouslySetInnerHTML={{ __html: content }}></div>}
    </div>
  );
};

export default AccordionFAQ;