import React, { useContext, useEffect, useState } from "react";
import MetaTagSEO from "../../components/seo/MetaTagSEO";
import BannerSection from "../faqs/BannerSection";
import "./pricing-page.css";
import OlibrFlagDropDown from "../../components/dropdown/olibrFlagDropdown";
import PricingTabContent from "./components/PricingTabContent";
import { CountryFlagOption } from "../../components/functions";
import PricingFooter from "./components/PricingFooter";
import AddOns from "../../components/pricing/AddOns";
import { MainContext } from "../../context/MainContext";

const PricingPage = () => {
  const states = ["pricing-tabs-inactive", "pricing-tabs-active"];
  const { user } = useContext(MainContext);
  const [pricingTab, setpricingTab] = useState(false);
  const [isAnnualPlan, setAnnualPlan] = useState(true);
  const [countryCode, setCountryCode] = useState("IN");
  const [addOnData, setAddOnData] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});

  const [addOnCheckLists, setAddOnCheckedLists] = useState([]);
  const handleClick = () => {
    setAnnualPlan(!isAnnualPlan);
    setpricingTab(!pricingTab);
  };

  const changeCountry = (value) => {
    setAddOnData([]);
    setSelectedPlan({});
    setAddOnCheckedLists([]);
    setCountryCode(value);
  };

  useEffect(() => {
    if (user?.userType === "Client") {
      setCountryCode(user?.region);
    }
  }, [user]);

  return (
    <>
      <MetaTagSEO helmetName="pricing-page" />
      <div className="wrapper pricing-page">
        <BannerSection
          heading={"Pricing Packages"}
          classname={"pricing-banner"}
          name={"Pricing"}
        />
        <section>
          <div className="auto-container">
            <div className="sec-title text-center">
              <h2>Affordable, Simple and Easy</h2>
              <p>
              Unlock Your Recruitment Potential with Olibr's Diverse Pricing Plans 
              </p>
            </div>
            <div className="pricing-sec">
              <p className="bold text-center">
              Designed for Every Hiring Need
              </p>
              <div className="flex-group">
                <OlibrFlagDropDown
                  options={CountryFlagOption("IN-US")}
                  value={countryCode}
                  onSelect={(value) => changeCountry(value)}
                  defaultValue="IN"
                  disabled={user?.userType==="Client"}
                  isIsoCode={false}
                />
                {/* <small>Save upto 20% in Annual Plan</small> */}
              </div>
              <div className="row"><div className="col-12 pr-discount"> *Save up to<span> 20% </span>with annual plan!</div> </div>
              <div className="container">
                <br />
                <div className="buttonFor">
                  <span
                    className={`${
                      states[pricingTab ? 0 : 1]
                    } pricing-tab faqTabCursor`}
                    onClick={handleClick}
                  >
                    Annual
                  </span>
                  <span
                    className={`${
                      states[!pricingTab ? 0 : 1]
                    } pricing-tab faqTabCursor`}
                    onClick={handleClick}
                  >
                    Monthly
                  </span>
                </div>
                {/* <FaqRender
                DevFaqList={faqTab ? faqGroupListResDev : faqGroupListResClient}
              /> */}
                <PricingTabContent
                  countryCode={countryCode}
                  isAnnualPlan={isAnnualPlan}
                  addOnData={addOnData}
                  selectedPlan={selectedPlan}
                  setSelectedPlan={setSelectedPlan}
                />
              <AddOns
                  addOnCheckLists={addOnCheckLists}
                  setAddOnCheckedLists={setAddOnCheckedLists}
                  setAddOnData={setAddOnData}
                  addOnData={addOnData}
                  country={countryCode}
                  selectedPlan={selectedPlan}
                />
              </div>
            </div>
          </div>
        </section>
        <PricingFooter countryName={countryCode} />
      </div>
    </>
  );
};

export default PricingPage;
