import { StyleSheet } from "@react-pdf/renderer";
import { Font } from '@react-pdf/renderer';
import popinMidium from "../../../../assets/fonts/Poppins-Medium.ttf"
import popinLight from "../../../../assets/fonts/Poppins-Light.ttf"
import popinRegular from "../../../../assets/fonts/Poppins-Regular.ttf"

Font.register({
  family: 'Poppins-Medium',
  fonts: [
    { src: popinMidium, fontWeight: 'normal' },

  ],
});

Font.register({
  family: 'Poppins-Light',
  fonts: [
    { src: popinLight, fontWeight: 'normal' },
  ],
});
Font.register({
  family: 'Poppins-Regular',
  fonts: [
    { src: popinRegular, fontWeight: 'normal' },
  ],
});
export const EmployementStyle = StyleSheet.create({
  employetmentMain: {
    marginTop: "20px",
    flexDirection: "row",
  },
  technichalSkillImg: {
    marginRight: 5,
    width: 10
  },
  emplLayoutLeft: {
    flexDirection: "row",
    left: "10px",
    width: "24%"
  },
  employementIcon: {
    width: "14px",
    marginTop: "3px"
  },
  empHeading: {
    fontSize: "12px",
    fontFamily: "Poppins-Regular",
  },
  emplLayoutRight: {
    width: "76%",
    borderLeft: "0.8px dashed #7fa2c5",
  },
  emplName: {
    fontFamily: "Poppins-Regular",
    fontSize: "11px",
    fontWeight: 400,
    paddingLeft: "16px"
  },
  circle: {
    height: "8px",
    marginTop: "2px",
    left: "-4px",
    width: "8px",
    borderRadius: "50%",
    backgroundColor: "#7fa2c5",
    position: "absolute"
  },
  companyNameView: {
    flexDirection: "row"
  },
  companyNameLeft: {
    width: "70%",
  },
  companyNameRight:{
    width: "30%",
  },
  companyPresent: {
    fontSize: "9px",
    fontFamily: "Poppins-Light",
    marginLeft: "17px",
  },
  companyName: {
    fontSize: "10px",
    fontFamily: "Poppins-Light",
    marginLeft: "17px"
  },

  technology: {
    marginLeft: "17px",
    marginTop: "20px",
    flexDirection: "row",
    width: "89%",
    flexWrap: "wrap"
  },
  tools: {
    marginLeft: "17px",
    marginTop: "5px",
    flexDirection: "row",
    width: "89%",
    flexWrap: "wrap"
  },
  technologyLeft: {
    marginRight: "20px",
    marginTop: "5px"
  },
  technologyHeading: {
    fontSize: "10px",
    fontFamily: "Poppins-Light",
    marginTop: "4px",
  },
  technologyRight: {
    padding: "2px",
    border: "1px solid #e5e5e5",
    marginLeft: "10px",
    marginTop: "5px",
    borderRadius: "20px",
    padding: "3px"
  },
  technologySkill: {
    height: "10px",
    flexDirection: "row"
  },
  technichalSkillIcon: {
    float: "left"
  },
  technichalSkillText: {
    fontSize: "8px"
  }

})