import { Fragment } from "react";

const ClientProfile = ({ clientDetailAdmin }) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-4">
          <div className="title-head">
            {clientDetailAdmin?.data?.[0]?.fName}{" "}
            {clientDetailAdmin?.data?.[0]?.lName}
          </div>
          <span className="title-p">
            {clientDetailAdmin?.data?.[0]?.country
              ? clientDetailAdmin?.data?.[0]?.country
              : "USA"}
          </span>
        </div>
        <div className="col-4">
          <div className="title-head">Mobile</div>
          <span className="title-p">
            {clientDetailAdmin?.data?.[0]?.number}
          </span>
        </div>
        <div className="col-4">
          <div className="title-head">Company Name</div>
          <span className="title-p">
            {clientDetailAdmin?.data?.[0]?.clientCompanyName}
          </span>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-4">
          <div className="title-head">Email</div>
          <span className="title-p">{clientDetailAdmin?.data?.[0]?.email}</span>
        </div>
        <div className="col-4">
          <div className="title-head">Account Created</div>
          <span className="title-p">
            {new Date(clientDetailAdmin?.data?.[0]?.createdAt)
              ?.toDateString()
              ?.substring(4)
              ?.toLocaleUpperCase()}
          </span>
        </div>
        <div className="col-4">
          <div className="title-head">Job Posted</div>
          <span className="title-p">
            {clientDetailAdmin?.data?.[0]?.jobs_count}
          </span>
        </div>
      </div>
    </Fragment>
  );
};

export default ClientProfile;
