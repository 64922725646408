import { Text, View,  Image } from '@react-pdf/renderer';
import ProfileView from './css/ProfileView';
import bgImgForProfile from "../../../assets/img/profile-theme-bg.jpg";
import logoBrand from "../../../assets/img/profile-brand.png"
import LocationIcon from "../../../assets/img/icons/location-png.png";
import { returnDate, splitString } from '../../functions';
const BasicDetails = ({ resumeData, userImage }) => {
  return (
    resumeData?.fName && (
    <View style={ProfileView.profileTop}>
      <Image source={bgImgForProfile} style={ProfileView.bgImageProfile} />
      <View style={ProfileView.profileTopContainer}>
        <View style={ProfileView.profileTopLeft}>
          <Image source={`${userImage}`} style={ProfileView.userProfileImage} />
          <Image source={logoBrand} style={ProfileView.logoBrandProfile} />


        </View>
        <View style={ProfileView.profileTopRight}>
          <Text style={ProfileView.devNameTitle}>{resumeData?.fName}</Text>
          <Text style={ProfileView.devDesignation}>{resumeData?.devDesg}</Text>
          <View style={ProfileView.basicDetails}>
            <View style={ProfileView.basicDetailsFirst}>
              <View>
                <Image source={LocationIcon} style={ProfileView.locationIcon} />
              </View>
              <View>
                <Text style={ProfileView.locationText}>{resumeData?.devCity} {!!resumeData?.devCity && ","} {resumeData?.devState}</Text>
              </View>
            </View>
            {/* <View style={ProfileView.basicDetailsSecond}>
            <View>
              <Image source={EmailIcon} style={ProfileView.emailIcon} />
            </View>
            <View>
              <Text style={ProfileView.locationText}>satendrasahani19@gmail.com</Text>
            </View>
          </View>
          <View style={ProfileView.basicDetailsThird}>
            <View>
              <Image source={CallIcon} style={ProfileView.callIcon} />
            </View>
            <View>
              <Text style={ProfileView.locationText}>+91 8573890224</Text>
            </View>
          </View> */}
          </View>


          <Text style={ProfileView.noticePeriod}>
            {
              resumeData?.devAvlFrom?(
                returnDate(resumeData?.devAvlFrom) !== "Available" ?(
                  <>
                  Available From :
                  <Text style={{ color: "#242c33" }}>{returnDate(resumeData?.devAvlFrom)}</Text>
                  </>
                ):(
                  <Text style={{ color: "#242c33" }}>{returnDate(resumeData?.devAvlFrom)}</Text>
                )
              ):(
                resumeData?.devNoticePeriod !== "Available" ? <> Notice Period :<Text style={{ color: "#242c33" }}> {resumeData?.devNoticePeriod}</Text></> : "Available"
              )
            
            }


          </Text>
          <Text style={ProfileView.shortDesc}>{splitString(460, resumeData?.devTellUrStory)}</Text>
        </View>
      </View>
      <Image src={"https://ik.imagekit.io/olibr/assets/tr:w-571,h-237/hirenow-bg.jpg"} style={ProfileView.bannerBetween} />
    </View>
    )
  )
}

export default BasicDetails