import { StyleSheet } from "@react-pdf/renderer";
import { Font } from '@react-pdf/renderer';
import popinMidium from "../../../../assets/fonts/Poppins-Medium.ttf"
import popinLight from "../../../../assets/fonts/Poppins-Light.ttf"
import popinRegular from "../../../../assets/fonts/Poppins-Regular.ttf"

Font.register({
  family: 'Poppins-Medium',
  fonts: [
    { src: popinMidium, fontWeight: 'normal' },

  ],
});

Font.register({
  family: 'Poppins-Light',
  fonts: [
    { src: popinLight, fontWeight: 'normal' },
  ],
});
Font.register({
  family: 'Poppins-Regular',
  fonts: [
    { src: popinRegular, fontWeight: 'normal' },
  ],
});
export const Description = StyleSheet.create({
  container: {
    marginTop:"2px",
    width:"400px",
    fontFamily: "Poppins-Regular",
  },
})