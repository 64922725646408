
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { PDFProfilePreview } from './PDFProfilePreview.js';

const PdfConverter = ({resumeData, userImage,stackLists,skillList,setLoader}) => (
    <>
        {/* <PDFDownloadLink document={<PDFProfilePreview setLoader={setLoader}/>}> */}
        {/* </PDFDownloadLink> */}
              <PDFViewer width={"100%"} height={"1000px"}>
            <PDFProfilePreview resumeData={resumeData} setLoader={setLoader} skillList={skillList} stackLists={stackLists} userImage={userImage} />
        </PDFViewer>
    </>
);
export default PdfConverter