import React, { useContext, useEffect, useState } from "react";
import "./../pricing-page.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getPrincingPlanAction } from "../../../service/action/common";
import PricingBox from "../../../components/pricing/PricingBox";
import { getFeatureAction } from "../../../service/action/admin";
import { MainContext } from "../../../context/MainContext";
import { getSubscriptionSummary } from "../../../service/action/client";

const PricingTabContent = ({
  isAnnualPlan,
  countryCode,
  addOnData,
  setSelectedPlan,
  selectedPlan,
  excludeActive = false,
  actionPage = "pricing",
  allUpgrade = false,
}) => {
  const { allPlanList } = useSelector((state) => state.commonReducer);
  const { fetureList } = useSelector((state) => state.adminReducer);
  const [userType, setUserType] = useState("");
  const { user } = useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if(user?.userType == "Client"){
      setUserType(user?.userType);
    }
    
  }, [user]);

  useEffect(() => {
    let tempObj = {
      excludeActive: excludeActive,
      country: countryCode,
      planType: isAnnualPlan ? "Annual" : "Monthly",
    };
    if (userType == "Client") {
      dispatch(
        getPrincingPlanAction(navigate, {
          ...tempObj,
          excludeActive: true,
          actionPage,
        })
      );
    } else {
      dispatch(getPrincingPlanAction(navigate, tempObj));
    }
  }, [dispatch,navigate,countryCode, isAnnualPlan,actionPage, excludeActive,userType]);
  // dispatch, navigate,user
  useEffect(() => {
    dispatch(getFeatureAction(navigate));
  }, [dispatch, navigate]);

  useEffect(() => {
    if (userType == "Client") {
      dispatch(getSubscriptionSummary(navigate));
    }
  }, [user, dispatch, navigate]);

  return (
    <div className="pricing-container row">
      {allPlanList?.map((item, id) => (
        <PricingBox
          key={id}
          setSelectedPlan={setSelectedPlan}
          indexNo={id}
          addOnData={addOnData}
          selectedPlan={selectedPlan}
          pricingData={item}
          fetureList={fetureList}
          allUpgrade={allUpgrade}
        />
      ))}
    </div>
  );
};

export default PricingTabContent;
