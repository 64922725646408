import React from 'react'
import DataTable from 'react-data-table-component'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ClipLoader } from 'react-spinners'
import { customDataTable } from './customStyle'

const DataTables = ({ selectAll=false,setSelectedData, selectable, Infinit = false, listLoading = false, columns = [], listData = {}, onRowClick = false, fetchMoreData = false, height = "70vh" }) => {
    const Loader = () => (
        <div style={{ textAlign: "center" }}>
            {" "}
            <ClipLoader color="#3BA0AA" />{" "}
        </div>
    )
    const handleChange = ({ selectedRows }) => {
        !!selectable && setSelectedData(selectedRows)
    };
    const rowDisabledCriteria = row => {
        return row?.status == "closed";
    }

    return (
        <>
            {Infinit ?
                <InfiniteScroll
                    height={height}
                    dataLength={listData?.data ? listData?.data?.length : 0}
                    next={fetchMoreData}
                    hasMore={
                        listData?.totalDataCount <= listData?.data?.length ? false : true
                    }

                >
                    <DataTable
                        customStyles={customDataTable}
                        columns={columns}
                        data={listData?.data}
                        highlightOnHover
                        selectableRows
                        onRowClicked={(row) => onRowClick && onRowClick(row)}
                        Clicked={onRowClick}
                        progressPending={!listData?.data?.length && listLoading}
                        progressComponent={<Loader />}
                        pagination={!!selectable}
                        paginationServer
                        onSelectedRowsChange={handleChange}
                        className={`${!!selectable && "selectable-area"} ${!selectAll && "hide-select-all"}`}
                        selectableRowDisabled={rowDisabledCriteria}

                    />
                </InfiniteScroll> :
                <DataTable
                    customStyles={customDataTable}
                    columns={columns}
                    data={listData?.data}
                    highlightOnHover
                    selectableRows
                    onRowClicked={onRowClick && onRowClick}
                    Clicked={onRowClick}
                    progressPending={!listData?.data?.length && listLoading}
                    progressComponent={<Loader />}
                    pagination={!!selectable}
                    paginationServer
                    className={`${!!selectable && "selectable-area"}  ${!selectAll && "hide-select-all"}`}
                    selectableRowDisabled={rowDisabledCriteria}
                    onSelectedRowsChange={handleChange}
                />

            }
        </>
    )
}

export default DataTables