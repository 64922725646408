import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { forgotPasswordOG } from "../imageLinks";
import NoFollow from "../../../common/staticData/seo/NoFollow";

const ForgotPassword = ({ baseUrl }) => {
  return (
    <Helmet>
      <title>Forgot Password | Reset your password</title>

      <meta charset="UTF-8" />
      <meta name="title" content="Forgot Password | Reset your password" />

      <meta
        name="description"
        content="Forgot Password, you can reset your password here by using your registered user id, email or contact details"
      ></meta>

      <meta
        name="keywords"
        content="forgot password,Signup Clients, olibr.com, tech jobs, olibr, olibr.com, Hiring developers, remote software developers, work-from-home developers, wfh, remote jobs, IT jobs, US based IT companies, US Jobs, US IT Jobs"
      />

      <meta name="language" content="english"></meta>

      <html lang="en" />

      <meta name="robots" content={NoFollow()}></meta>

      <meta name="url" content={`${baseUrl}/forgot-password`} />

      <meta name="identifier-URL" content={`${baseUrl}/forgot-password`}></meta>

      {/* <meta name='subtitle' content='about us -- learn more'></meta> */}

      <link rel="canonical" href={`${baseUrl}/forgot-password`} />

      <meta name="referrer" content="origin-when-crossorigin"></meta>

      <link rel="image_src" href={forgotPasswordOG} />

      <meta name="copyright" content="olibr.com" />

      {/* ----Social Media Open Graph--------- */}

      <meta
        property="og:title"
        content="Forgot Password | Reset your password"
      />

      <meta property="og:type" content="website" />

      <meta property="og:url" content={`${baseUrl}/forgot-password`} />

      <meta property="og:image" content={forgotPasswordOG} />

      <meta property="og:site_name" content="olibr.com" />

      <meta
        property="og:description"
        content="Forgot Password, you can reset your password here by using your registered user id, email or contact details"
      ></meta>

      {/* <meta name='medium' content='blog'></meta> */}

      {/*   <meta name='fb:page_id' content='------------'></meta> */}

      {/* ---Organisation Details -Open Graph Business Tags----- */}

      <meta property="og:type" content="business.business" />

      <meta
        property="business:contact_data:street_address"
        content="26 Arrowhead Ln"
      />
      <meta property="business:contact_data:locality" content="Menlo Park" />
      <meta property="business:contact_data:region" content="CA" />
      <meta property="business:contact_data:postal_code" content="94025" />
      <meta property="business:contact_data:country_name" content="US" />

      {/* <meta name="og:latitude" content="37.416343" />

        <meta name="og:longitude" content="-122.153013" />


        <meta name="og:email" content="me@example.com" />

        <meta name="og:phone_number" content="650-123-4567"></meta> */}

      {/* ------ Twitter Cards--------- */}

      <meta name="twitter:site" content="@olibr" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Forgot Password | Reset your password"
      />

      <meta
        name="twitter:description"
        content="Forgot Password, you can reset your password here by using your registered user id, email or contact details"
      />

      {/*  <meta name="twitter:creator" content="@olibr"/> */}

      <meta name="twitter:image" content={forgotPasswordOG} />

      {/* ------Apple Devices ------ */}

      <meta
        name="apple-mobile-web-app-title"
        content="Forgot Password | Reset your password"
      />

      <meta name="apple-mobile-web-app-capable" content="yes" />

      <meta name="apple-touch-fullscreen" content="yes" />

      <meta name="apple-mobile-web-app-status-bar-style" content="black"></meta>

      {/* ----------blog or Article------------- */}

      {/*   







  <meta name='date' content='Sep. 27, 2010'/>   







  <meta name='search_date' content='2010-09-27'></meta>   







  <meta name='DC.title' content='Unstoppable Robot Ninja'/>   







  <meta name='revised' content='Sunday, July 18th, 2010, 5:15 pm'/>   







  <meta name='abstract' content=''/>   







  <meta name='topic' content=''/>   







  <meta name='summary' content=''/>   







  <meta name='Classification' content='Business'/>   







  <meta name='author' content='name, email@hotmail.com'/>   







  <meta name='designer' content=''/>   







  <meta name='reply-to' content='email@hotmail.com'/>   







  <meta name='owner' content=''></meta>   







                                           */}

      {/* ----------JSON LD Schema for SEO------ */}

      <script type="application/ld+json">
        {JSON.stringify(
          // Breadcrumbs----
          {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: `${baseUrl}/`,
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Forgot Password | Reset your password",
                item: `${baseUrl}/forgot-password`,
              },
            ],
          }
        )}
      </script>
      {/* --------------------------------- */}
      <script type="application/ld+json">
        {JSON.stringify(
          // Speakable ---
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            name: "Forgot Password | Reset your password",
            speakable: {
              "@type": "SpeakableSpecification",
              xpath: [
                "/html/head/title",
                "/html/head/meta[@name='description']/@content",
              ],
            },
            url: `${baseUrl}/forgot-password`,
          }
        )}
      </script>
      {/* ------------------------- */}
      <script type="application/ld+json">
        {JSON.stringify(
          //  Website/webpage schema--
          {
            "@context": "http://www.schema.org",
            "@type": "WebSite",
            name: "Forgot Password | Reset your password",
            url: `${baseUrl}/forgot-password`,
          }
        )}
      </script>
    </Helmet>
  );
};

export default ForgotPassword;
