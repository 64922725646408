import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/modal/ModalBox";
import { jobApprovedOrRejectAction } from "../../../service/action/admin";
import { useNavigate } from "react-router-dom";
import { olibrToast } from "../../../components/toaster";
import DataTables from "../../../components/data-tables/DataTables";
import ColumnLists from "./ColumnLists";
import { checkPermission } from "../../../components/functions";
import CandidateList from "../../client/jobs/CandidateList";

const JobLists = ({
  pageData,
  setPageData,
  handleEdit,
  categObj,
  handleGetAllJob,
  readOnly,
  selectable = false,
  setSelectedData = false,
  selectedData = []
}) => {
  const { allJobLists, isJobListsLoading } = useSelector(
    (state) => state.adminReducer
  );
  const { socket } = useSelector((state) => state.commonReducer);
  const [candidate, setCandidate] = useState({
    modal: false,
    clientId: ""
  })
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [approvalConfirm, setApprovalConfirm] = useState();

  const handleApprovalConfirm = (job) => {
    if (checkPermission(readOnly)) return false;
    job.isApproved
      ? olibrToast.error("You cannot unapproved an approved job.")
      : setApprovalConfirm({ isOpen: true, jobData: job });
  };

  const handleApproveJobPost = () => {
    dispatch(
      jobApprovedOrRejectAction(
        { jobId: approvalConfirm?.jobData?._id },
        navigate,
        setApprovalConfirm,
        handleGetAllJob,
        pageData,
        socket
      )
    );
  };

  const fetchMoreData = () => {
    handleGetAllJob({ ...pageData, page: pageData?.page + 1 });
    setPageData({ ...pageData, page: pageData?.page + 1 });
  };

  const openApplicants = (e, clientId,jobId,aplicants) => {
    e?.stopPropagation();
    if (clientId && aplicants) {
      setCandidate({
        modal: true,
        clientId,
        jobId
      })
    }
  }
  return (
    <>
      <Modal
        title={"Applicants"}
        size="80%"
        contentClass="padding-unset"
        open={candidate?.modal}
        content={() => <CandidateList jobIdAdmin={candidate?.jobId} idClient={candidate?.clientId} />}
        onClose={() => setCandidate({})}
        isFooterView={false}
      />
      <Modal
        open={approvalConfirm?.isOpen}
        title={"Confirm Approval"}
        onClose={() => setApprovalConfirm({ isOpen: false })}
        onDone={handleApproveJobPost}
        content={() => <p>You cannot unapprove after approval.</p>}
        onCancel={() => setApprovalConfirm({ isOpen: false })}
        isFooterView={true}
      />
      <DataTables
        columns={ColumnLists(categObj, handleApprovalConfirm, openApplicants)}
        listData={allJobLists}
        Infinit={true}
        fetchMoreData={fetchMoreData}
        listLoading={isJobListsLoading}
        selectable={selectable}
        onRowClick={(item) => handleEdit(item)}
        setSelectedData={setSelectedData}
        selectedData={selectedData}
      />
    </>
  );
};

export default JobLists;
