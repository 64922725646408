import React, { Fragment, useContext, useEffect, useRef } from "react";
import { useState } from "react";
import Listing from "./Listing";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clientFilterAction,
  getClientListAdmin,
  searchClientStartAction,
} from "../../../service/action/admin";
import { Icon } from "../../../components/icon";
import Modal from "../../../components/modal/ModalBox";
import SelectOption from "../../../components/select/SelectOption";
import { clientProfileStatusFilter } from "../../../common/constant";
import { debounce } from "lodash";
import OlibrDatePicker from "../../../components/olibr-datepicker";
import "./ClientManagement.css";
import { MainContext } from "../../../context/MainContext";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const ClientManagement = () => {
  const [search, setSearch] = useState("");
  const { user } = useContext(MainContext);
  const { allClientListAdmin, isAllClientListAdminLoading, filter } =
    useSelector((state) => state.adminReducer);
  const [pageData, setPageData] = useState({
    limit: 20,
    page: 1,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filterOpen, setFilterOpen] = useState(false);
  const [isFilterApply, setFilterApply] = useState(false);
  const [exportDataAccess, setExportDataAccess] = useState(false);

  const setFilter = (data) => {
    dispatch(clientFilterAction(data));
  };

  const cleanObj = (obj) => {
    return new Promise(function (resolve, reject) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName]?.length < 1
        ) {
          delete obj[propName];
        }
      }
      resolve(obj);
    });
  };

  const checkValidation = async (filterData) => {
    const cleanFilterValue = await cleanObj(filterData);
    return (
      Boolean(cleanFilterValue?.fName) ||
      Boolean(cleanFilterValue?.lName) ||
      Boolean(cleanFilterValue?.email) ||
      Boolean(cleanFilterValue?.mobNumber) ||
      Boolean(cleanFilterValue?.registrationDate) ||
      Boolean(cleanFilterValue?.date_from) ||
      Boolean(cleanFilterValue?.date_to) ||
      Boolean(cleanFilterValue?.status !== "all")
    );
  };

  const checkFilterApply = async () => {
    let checkFltr = await checkValidation(filter);
    setFilterApply(checkFltr);
  };

  useEffect(() => {
    checkFilterApply();
  });

  useEffect(() => {
    const isExportDataAccess = user?.access?.filter(
      (item) => item?.name == "ExportClientData" && item?.status == true
    );
    if (isExportDataAccess?.length || user?.access?.length < 1) {
      setExportDataAccess(true);
    }
  }, [user]);

  const fetchClientLists = async (filterData, limit, page, term = search) => {
    const cleanFilterValue = await cleanObj(filterData);
    let boolVal = await checkValidation(filterData);
    dispatch(
      getClientListAdmin(
        cleanFilterValue,
        navigate,
        boolVal && limit == 0 ? 0 : limit,
        boolVal && page == 0 ? 0 : page,
        term
      )
    );
  };

  useEffect(() => {
    fetchClientLists(filter, pageData?.limit, pageData?.page);
  }, [dispatch, navigate, pageData]);

  const clearFilter = () => {
    setFilter({
      fName: "",
      lName: "",
      email: "",
      status: "all",
      mobNumber: "",
      registrationDate: "",
      date_from: "",
      date_to: "",
    });
    dispatch(
      getClientListAdmin(
        { status: "all" },
        navigate,
        pageData?.limit,
        pageData?.page,
        search
      )
    );
  };

  const handleCloseFilterOpen = () => {
    setFilterOpen(false);
  };

  const handleFilter = () => {
    fetchClientLists(filter, pageData?.limit, pageData?.page);
    setFilterOpen(false);
  };

  const debounced = useRef(
    debounce((newSearchValue) => {
      setPageData({ ...pageData, page: 1 });
      fetchClientLists(filter, pageData?.limit, 1, newSearchValue);
    }, 1000)
  );

  const onSearchChange = (event) => {
    setSearch(event.target.value);
    !isAllClientListAdminLoading && dispatch(searchClientStartAction());
    debounced.current(event.target.value);
  };

  const handleContentFilter = () => {
    return (
      <>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label className="floating-label" style={{ zIndex: 0 }}>
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                value={filter?.fName}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    fName: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="floating-label" style={{ zIndex: 0 }}>
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                value={filter?.lName}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    lName: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="floating-label" style={{ zIndex: 0 }}>
                Email
              </label>
              <input
                type="text"
                className="form-control"
                value={filter?.email}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    email: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="floating-label">Profile status</label>
              <SelectOption
                optionArray={clientProfileStatusFilter}
                state={clientProfileStatusFilter?.find(
                  (item) => item?.value == filter?.status
                )}
                closeMenuOnSelect={true}
                onChangeFunction={(e) => {
                  setFilter({
                    ...filter,
                    status: e?.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="new-label" style={{ zIndex: 0 }}>
                Registered On
              </label>
              <OlibrDatePicker
                onChange={(e) => setFilter({ ...filter, registrationDate: e })}
                value={filter?.registrationDate ? filter?.registrationDate : ""}
                placeholder={"Registered On"}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label className="new-label" style={{ zIndex: 0 }}>
                Registered From
              </label>
              <OlibrDatePicker
                onChange={(e) => setFilter({ ...filter, date_from: e })}
                value={filter?.date_from ? filter?.date_from : ""}
                placeholder={"Registered From"}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label className="new-label" style={{ zIndex: 0 }}>
                To
              </label>
              <OlibrDatePicker
                onChange={(e) => setFilter({ ...filter, date_to: e })}
                value={filter?.date_to ? filter?.date_to : ""}
                placeholder={"To"}
              />
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-12">
            <div className="bottom-button">
              <button className="cancelBtn" onClick={() => clearFilter()}>
                Clear FILTERS
              </button>
              <button
                className="cancelBtn"
                onClick={() => setFilterOpen(false)}
              >
                CANCEL
              </button>
              <button className="submitBtn themeButton" onClick={handleFilter}>
                APPLY
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleImportExcel = () => {
    if (allClientListAdmin?.data?.data?.length > 0) {
      const json_data = allClientListAdmin?.data?.data.map((item) => ({
        Name: `${item.fName} ${item.lName}`,
        Email: item.email,
        CompanyName: item.clientCompanyName,
        LastLogin: item.lastLogin ? new Date(item.lastLogin).toUTCString() : "",
        Hired: item.hiredCount,
        Shortlisted: item.ShortlistedCount,
        JobPosted: item.jobPostedCount,
      }));
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const ws = XLSX.utils.json_to_sheet(json_data);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "clientData.xlsx");
    }
  };

  return (
    <Fragment>
      <Modal
        open={filterOpen}
        title={"Filter"}
        onClose={handleCloseFilterOpen}
        isFooterView={false}
        content={handleContentFilter}
      />
      <div className="main-div-developer">
        <div className="skills-management">
          <div className="block-header flex-space-between-row">
            <h4>Client Management</h4>
            <div className="flex-row-item">
              <span>
                {isFilterApply === false ? (
                  ""
                ) : (
                  <a
                    className="subQues color-orange mr5"
                    onClick={() => clearFilter()}
                  >
                    REMOVE FILTERS
                  </a>
                )}
                <a className="subQues" onClick={() => setFilterOpen(true)}>
                  FILTERS
                </a>
              </span>
              {exportDataAccess && (
                <Icon name="excel" onClick={handleImportExcel} />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-8"></div>
            <div className="search-block col-4">
              <input
                type="text"
                className="form-control"
                value={search}
                placeholder="Search Users"
                onChange={onSearchChange}
              />
              <span className="search-btn">
                <Icon name="search-icon" />
              </span>
            </div>
          </div>
          <br />
          <div className={`table`}>
            <Listing
              pageData={pageData}
              setPageData={setPageData}
              isLoading={isAllClientListAdminLoading}
              clientList={allClientListAdmin?.data}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ClientManagement;
