import "./Accordion.css";

const AccordionMenu = ({ title, content, setTitleOpen, titleOpen }) => {
  const handleOpeClose=()=>{
    titleOpen==title?setTitleOpen(""):setTitleOpen(title)
  }
  return (
    <div className="accordion-item">
      <div
        className="accordion-title acc-cursor"
        onClick={handleOpeClose}
      >
        <div className="">{title}</div>
        <span className="acc-icon">{title == titleOpen ? "-" : "+"}</span>
      </div>
      {title == titleOpen && <div className="accordion-content" dangerouslySetInnerHTML={{__html : content}}></div>}
    </div>
  );
};

export default AccordionMenu;
