export const tellYourStoryTip = {
  title: "Tell Your Story",
  content:
    "<div><h4>Tell your Story</h4>\n<p>Summarize your professional experience in 50 words or less for a concise and impactful representation of your qualifications.</p> </div>",
};
export const educationTip = {
  title: "Education Details",
  content:
    "<div><h4>Education Details</h4>\n<p>List your education history, including both your graduation and post-graduation (if any) degrees, with start and end years for a comprehensive overview of your educational background.</p></div>",
};
export const employementTip = {
  title: "Employment Details",
  content:
    `<div><ul><li>Specify your job title for each company you have worked for to give a clear picture of your professional experience and responsibilities.</li>
     <li> Include the complete company name for each organization you have worked for to clearly showcase your professional experience.</li>
     <li>Indicate the duration of your employment, including the start and end dates, for each company to provide a clear timeline of your work experience.</li>
     <li>When listing your job responsibilities, make sure to include the technologies and tools you used for that specific role. Utilize the drop-down options to mention them if it is given.</li>
     <li>Use bullet points to clearly outline your roles and responsibilities for each job you have held. This format makes it easy for the reader to quickly understand your key duties and accomplishments.</li></ul></div>\n\n
     <div><h4>Add Projects</h4>\n<p>For each company you have worked for, provide details on the projects you were involved in, including the project name, industry domain, team size, your role, and the skills you used to successfully complete the project. This information will help to demonstrate your hands-on experience and technical abilities.</p></div>`,
};
export const certificationsTip = {
  title: "Certifications",
  content:
    "<div><h4>Certifications</h4>\n<p>Include any certifications you have received along with their start and end dates to highlight your professional development and expertise in your field.</p></div>",
};
export const skillsTip = {
  title: "Skills",
  content:
    "<div><h4>Skills</h4>\n<p>List all your technical skills to showcase your expertise and give potential employers a comprehensive understanding of your capabilities.</p></div>",
};
export const devToolsTip = {
  title: "Tools",
  content:
    "<div><h4>Tools</h4>\n<p>Use the provided drop-down box to list the names of tools you have used, such as Jira, AWS Cloud, etc. to highlight your technical proficiency.</p></div>",
};
export const socialProfileTip = {
  title: "Social Profiles",
  content:
    "<div><h4>Social Profiles</h4>\n<p>Include your social profiles, such as LinkedIn, GitHub, Twitter, and Facebook, to provide a comprehensive view of your online presence and professional network.</p></div>",
};
export const devAChievementTip = {
  title: "Achievements",
  content:
    "<div><h4>Achievements</h4>\n<p>Demonstrate your successes and contributions by showcasing your achievements in both your professional and educational capacities.</p></div>",
};
export const profileGuidlinesTip = {
  title: "Photo Guidelines",
  content:
    "<div><h4>Instructions</h4>\n<p>Your profile picture is a crucial part of your professional image, so making a great first impression is important. Use these tips to ensure your profile picture is professional and presents you in the best light:</p>\n<br/><ul><li>Upload a professional photo.</li><li>A selfie with a plain background and good lighting works.</li><li>Maintain a pleasant facial expression.</li><li>Do not upload a passport-size photo.</li><li>Focus the camera on your face.</li><li>No full-length images.</li><li>Please upload a JPG or PNG file of a minimum of 400*400 pixels and not larger than 1 MB.</li></ul></div>",
};
