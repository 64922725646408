import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "../../../components/modal/ModalBox";
import { ClipLoader } from "react-spinners";
import { verifyDevEmailAction } from "../../../service/action/admin";

const EmailVerification = ({ HandleDropdwon,readOnly }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { verifyDevLoading } = useSelector((state) => state.adminReducer);
  const {
    register: userRegister,
    handleSubmit: userSubmit,
    reset: resetUserForm,
    formState: { errors: userError },
  } = useForm();

  const [verifyOrApproved, setVerifyOrApproved] = useState("");
  const [modal, setModal] = useState(false);
  const [responseEmail, setResponseEmail] = useState({
    registered: [],
    unregistered: [],
    uncompleteStep: [],
  });

  const verify = (data) => {
    setVerifyOrApproved("verified");
    let arr = [];
    let str = data?.emailArr.replaceAll(" ", "");
    arr = str.split(",");
    dispatch(
      verifyDevEmailAction({ email: arr }, navigate, setModal, setResponseEmail)
    );
  };
  useEffect(() => {
    resetUserForm();
    setVerifyOrApproved("");
    setResponseEmail({});
  }, []);

  const resultContent = () => {
    return (
      <div className="vrf-email">
        {responseEmail?.registered && responseEmail?.registered?.length > 1 ? (
          <div className="row">
            <div class="col-12">
              <h5>
                The following developers profiles have been {verifyOrApproved}
              </h5>
              <p>
                {responseEmail?.registered?.map((item) => (
                  <span>{item}, </span>
                ))}
              </p>
            </div>
          </div>
        ) : (
          responseEmail?.registered?.length == 1 && (
            <p>
              <b>
                {" "}
                {responseEmail?.registered && responseEmail?.registered[0]}{" "}
              </b>
              profile is {verifyOrApproved}
            </p>
          )
        )}
        {responseEmail?.unregistered &&
        responseEmail?.unregistered?.length > 1 ? (
          <div className="row">
            <div class="col-12">
              <h5>The following profiles were not updated</h5>
              <p className="red">
                {responseEmail?.unregistered?.map((item) => (
                  <span>{item}, </span>
                ))}
              </p>
            </div>
          </div>
        ) : (
          responseEmail?.unregistered?.length == 1 && (
            <p>
              <b className="text-danger">
                {" "}
                {responseEmail?.unregistered &&
                  responseEmail?.unregistered[0]}{" "}
              </b>
              profile is not {verifyOrApproved}
            </p>
          )
        )}

        {responseEmail?.uncompleteStep &&
        responseEmail?.uncompleteStep?.length > 1 ? (
          <div className="row">
            <div class="col-12">
              <h5>The following profiles were not complete all steps</h5>
              <p className="red">
                {responseEmail?.uncompleteStep?.map((item) => (
                  <span>{item}, </span>
                ))}
              </p>
            </div>
          </div>
        ) : (
          responseEmail?.uncompleteStep?.length == 1 && (
            <p>
              <b className="text-danger">
                {" "}
                {responseEmail?.uncompleteStep &&
                  responseEmail?.uncompleteStep[0]}{" "}
              </b>
              not complete all steps
            </p>
          )
        )}
      </div>
    );
  };

  return (
    <form>
      <Modal
        open={modal}
        isFooterView={false}
        onClose={() => setModal(false)}
        content={resultContent}
      />
      <div className="container">
        <div className="create-profile">
          <div className="block-header">
            <h4>Developer Email Verification</h4>
          </div>
          <HandleDropdwon />
          <div className="row">
            <div className="col-12">
              <div className="">
                <label
                  className={`new-label ${
                    userError?.emailArr && "text-danger"
                  }`}
                >
                  Emails*
                </label>
                <textarea
                  type="text"
                  className={`form-control ${
                    userError?.emailArr && "form-required"
                  }`}
                  placeholder="Please Enter comma separated emails!"
                  {...userRegister("emailArr", {
                    required: true,
                  })}
                  rows={8}
                  readOnly={readOnly}
                />
              </div>
              <br />
            </div>
            <div className="col-12 text-right">
              <button
                onClick={userSubmit(verify)}
                className="submitBtn themeButton"
                disabled={verifyDevLoading || readOnly}
              >
                {verifyDevLoading ? (
                  <ClipLoader size={15} color="#FFFFFF" />
                ) : (
                  "VERIFY"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EmailVerification;
