import { useContext, Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {  useSelector } from "react-redux";
import { Icon } from "../../components/icon";
import { MainContext } from "../../context/MainContext";

const SidebarLink = ({ location, routeLinks }) => {
    const { user } = useContext(MainContext);
    const [isProfileApproved, setProfileStatus] = useState(false);
    const { profileStatus, isProfileStatusLoading } = useSelector(
        (state) => state.developerReducer
    );

    useEffect(() => {
        if (!isProfileStatusLoading) {
            if (profileStatus === "approved") {
                !isProfileApproved && setProfileStatus(true);
            } else {
                isProfileApproved && setProfileStatus(false);
            }
        }
    }, [profileStatus, isProfileStatusLoading, isProfileApproved]);
    const renderLink = (route) => (
            <Link
                to={`${route.link}`}
                className={`${location.pathname?.includes(route?.link) ? "active" : ""}`}
            >
                <Icon name={route?.icon} />
            </Link>
    );

    return (
        <Fragment>
            {routeLinks.map((route, index) => (
                <Fragment key={Math.random()}>
                    {user?.access?.some(
                        (item) => item.name === route.name || route.name === "Settings"
                    ) ||
                        (user?.userType === "Developer" &&
                            ((route.name !== "Skill Test" &&
                                route.name !== "My Reviews" &&
                                route.name !== "Video Introduction") ||
                                isProfileApproved)) ||
                        user?.userType === "Client" ||
                        (user?.userType === "Admin" && user?.access?.length <= 0) ? (
                        <Fragment key={route.link}>
                            {route?.link === "/client/company-profile" ||
                                route.link === "/client/user-management" ? (
                                <>
                                    {user?.clientCurrentRole === "admin" ? (
                                        <>
                                            {route.link === "/client/user-management" ? (
                                                <>
                                                    {user.companyProfilePublish ? renderLink(route) : ""}
                                                </>
                                            ) : (
                                                renderLink(route)
                                            )}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </>
                            ) : (
                                renderLink(route)
                            )}
                        </Fragment>
                    ) : null}
                </Fragment>
            ))}
        </Fragment>
    );
};

export default SidebarLink;
