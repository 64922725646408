import React, { useEffect, Fragment, useState } from "react";
import userImage from "./../../../assets/img/author.png";
import moment from "moment";
import { olibrToast } from "../../../components/toaster";
import Modal from "../../../components/modal/ModalBox";
import { AiOutlineCalendar } from "react-icons/ai";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptInterview,
  cancelInterview,
} from "../../../service/action/client";
import RescheduleInterview from "../jobs/RescheduleInterview";
import { useNavigate } from "react-router-dom";
import SelectOption from "../../../components/select/SelectOption";
import "./Calendar.css";
import { ReactComponent as Suitcase } from "../../../assets/img/icons/suitcase_new.svg";
import { ReactComponent as Mail } from "../../../assets/img/icons/email-profile.svg";

export default function InterviewTable({ data, activeTab, isAll }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clientStoreState = useSelector((state) => state.clientReducer);
  const { socket } = useSelector((state) => state.commonReducer);
  const [interviewData, setInterviewData] = useState([]);
  const [rescheduleModalView, setRescheduleModalView] = useState(false);
  const [selectSlot, setSelectSlot] = useState(null);
  const [acceptModal, setAcceptModal] = useState(false);
  const [acceptModalIntDet, setAcceptModalIntDet] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [cancelMessage, setCancelMessage] = useState(null);
  const [cancelModalView, setCancelModalView] = useState(false);
  const [interviewDetailsModalView, setInterviewDetailsModalView] =
    useState(false);

  const rejectedReason = [
    {
      label: "Job closed.",
      value: "Job closed.",
    },
    {
      label: "Other",
      value: "other",
    },
  ];

  useEffect(() => {
    let viewData = [];
    if (activeTab === "upcoming") {
      viewData = data;
    } else if (activeTab === "new") {
      data?.length > 0 &&
        data?.forEach((item) => {
          item?.interviewData?.status !== "scheduled" && viewData.push(item);
        });
    }
    setInterviewData(viewData);
  }, [data, activeTab]);

  Date.prototype.addHours = function (h) {
    this.setTime(this.getTime() + h * 60 * 60 * 1000);
    return this;
  };
  const getStatusColor = (status) => {
    if (status === "pending") return "#E7B024";
    if (status === "canceled") return "red";
    if (status === "scheduled") return "#3BA0AA";
  };

  const onSlotAcceptingDone = () => {
    setAcceptModal(false);
  };

  const saveSlot = () => {
    if (selectSlot) {
      dispatch(
        acceptInterview(
          navigate,
          { ...acceptModalIntDet, ...selectSlot },
          onSlotAcceptingDone,
          socket
        )
      );
    } else {
      olibrToast.error("Please select slot.");
    }
  };

  const renderCancelInterviewModalContent = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <div style={{ width: "100%" }}>
            <SelectOption
              optionArray={rejectedReason}
              state={selectedOption}
              closeMenuOnSelect={true}
              placeholder={"Select reason of reject"}
              onChangeFunction={(e) => {
                setSelectedOption(e);
                if (e.value !== "other") {
                  setCancelMessage(e.value);
                } else {
                  setCancelMessage(null);
                }
              }}
            />
            {selectedOption?.value === "other" && (
              <textarea
                value={cancelMessage}
                placeholder="Enter reasion of reject"
                onChange={(e) => setCancelMessage(e.target.value)}
                style={{ marginTop: "15px" }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            marginTop: "25px",
            gap: "20px",
            flexDirection: "row",
          }}
        >
          <a
            className="cancelBtn"
            style={{
              background: "#3ba0aa",
              color: "white",
              border: "none",
              margin: "0px",
            }}
            onClick={() => {
              if (cancelMessage) {
                dispatch(
                  cancelInterview(acceptModalIntDet._id, navigate, {
                    ...acceptModalIntDet,
                    message: cancelMessage,
                  }, socket)
                );
                setCancelModalView(false);
              } else {
                olibrToast.error("Reason of reject is required.");
              }
            }}
          >
            Done
          </a>
        </div>
      </>
    );
  };

  const renderRescheduleModalContent = () => {
    return (
      <Fragment>
        <RescheduleInterview
          data={acceptModalIntDet}
          setRescheduleModalView={setRescheduleModalView}
          title={
            acceptModalIntDet?.interviewData?.status === "canceled" &&
            acceptModalIntDet?.interviewData?.cancelledBy ? (
              <span
                style={{
                  fontSize: "20px",
                }}
              >{`Developer has declined the interview due to: ${
                acceptModalIntDet?.interviewData?.message
                  ? acceptModalIntDet?.interviewData?.message
                  : "personal reason"
              }`}</span>
            ) : (
              acceptModalIntDet?.interviewData?.status === "canceled" && (
                <span
                  style={{
                    fontSize: "20px",
                  }}
                >
                  You have canceled the interview, but you can still reschedule.
                </span>
              )
            )
          }
        />
      </Fragment>
    );
  };

  const renderAcceptModalContent = () => {
    if (acceptModalIntDet)
      return (
        <Fragment>
          <div className="jd-content">
            <div className="row">
              <div className="col-3">
                <div className="logo">
                  <img src={acceptModalIntDet?.companyLogo} alt="" />
                </div>
              </div>
              <div className="col-9">
                <h1 style={{ textTransform: "capitalize" }}>
                  {acceptModalIntDet?.jobTitle}
                </h1>
                <div className="job-det" style={{ display: "flex", flexDirection:"column" }}>
                  <p
                    // onClick={() => details(jobDetail?.companySlug)}
                    style={{ cursor: "pointer", margin:" 5px 0px 0px 0px" }}
                  >
                    <span>
                      <Suitcase style={{ width: "20px", height: "16px" }} />
                    </span>
                    {/* {jobDetail?.organisation} */}
                    {acceptModalIntDet?.company}
                  </p>

                  <p
                    // onClick={() => details(jobDetail?.companySlug)}
                    style={{ cursor: "pointer", textTransform: "none",  margin:" 5px 0px 0px 0px" }}
                  >
                    <span>
                      <Mail />
                    </span>
                    {/* {jobDetail?.organisation} */}
                    {acceptModalIntDet?.clientEmail}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <h5
            style={{
              fontSize: "14px",
              marginBottom: "15px",
              fontWeight: "400",
              color: "#000",
            }}
          >
            <strong style={{ fontWeight: "600", marginRight: "7px" }}>
              Message:
            </strong>
            {acceptModalIntDet?.interviewData?.message}
          </h5>
          <h5 style={{ marginBottom: 0, cursor: "pointer" }}>
            Please select a time slot
          </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-5px",
              flexDirection: "column",
            }}
          >
            {acceptModalIntDet?.slots?.map((dt, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "12px 8px",
                    // border: `2px solid ${
                    //   selectSlot && index === selectSlot.index
                    //     ? "#3BA0AA"
                    //     : "#e0e0e0"
                    // }`,
                    border: "1px solid #e0e0e0",
                    borderRadius: "4px",
                    width: "100%",
                    marginTop: "15px",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectSlot({ ...acceptModalIntDet, index });
                  }}
                >
                  {`${new Date(dt.from).toDateString()} ${moment(
                    dt.from
                  ).format("h:mm a")} to ${moment(dt?.to).format("h:mm a")}`}
                  {/* <AiOutlineCalendar style={{ fontSize: "22px" }} /> */}
                  <input type="radio" className="modal-radio" />
                </div>
              );
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginTop: "25px",
              gap: "10px",
            }}
          >
            <a
              className="cancelBtn"
              style={{
                background: "#f15d3b",
                color: "white",
                border: "none",
                margin: "0px",
              }}
              onClick={() => {
                setAcceptModal(false);
                setCancelModalView(true);
              }}
            >
              Cancel
            </a>
            <a
              className="cancelBtn"
              style={{
                background: "#3ba0aa",
                color: "white",
                border: "none",
                margin: "0px",
              }}
              onClick={() => {
                setAcceptModal(false);
                setRescheduleModalView(true);
              }}
            >
              Reschedule
            </a>
            {acceptModalIntDet?.interviewData?.requestedBy && (
              <a
                className="cancelBtn"
                style={{
                  background: "#3ba0aa",
                  color: "white",
                  border: "none",
                  margin: "0px",
                }}
                onClick={() => {
                  saveSlot();
                }}
              >
                {clientStoreState.isInterviewSlotLoading ? (
                  <ClipLoader size={12} color="#ffffff" />
                ) : (
                  "Accept"
                )}
              </a>
            )}
          </div>
        </Fragment>
      );
  };

  const renderInterviewDetailsModalContent = () => {
    if (acceptModalIntDet)
      return (
        <>
          <h3 style={{ marginLeft: "10px" }} className="job-title">
            {acceptModalIntDet?.jobTitle}
          </h3>
          <span
            className="scheduled-time"
            style={{
              display: "inline-block",
              color: "#4e4e4e",
              fontSize: "14px",
              whiteSpace: "pre",
              marginTop: "-15px",
              marginLeft: "10px",
            }}
          >
            {acceptModalIntDet?.interviewData?.slots.map((dt, index) => {
              if (dt?.joinUrl) {
                return `From ${moment(dt?.from).format("h:mm a")} to ${moment(
                  dt?.to
                ).format("h:mm a")}\n${new Date(dt?.from).toDateString()}`;
              }
            })}
            <br />
            <br />
            <h5
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#000",
                marginTop: "10px",
              }}
            >
              <strong style={{ fontWeight: "600", marginRight: "7px" }}>
                Candidate Name:
              </strong>
              {acceptModalIntDet?.name}
            </h5>
            <br />
            <h5 style={{ fontSize: "14px", fontWeight: "400", color: "#000" }}>
              <strong style={{ fontWeight: "600", marginRight: "7px" }}>
                Interviewer:
              </strong>
              {acceptModalIntDet?.interviewer}
            </h5>
            <br />
            {acceptModalIntDet?.message && (
              <>
                <h5
                  style={{ fontSize: "14px", fontWeight: "400", color: "#000" }}
                >
                  <strong style={{ fontWeight: "600", marginRight: "7px" }}>
                    Message:
                  </strong>
                  <span>{acceptModalIntDet?.message}</span>
                </h5>
                <br />
              </>
            )}
            <h5
              style={{
                fontSize: "14px",
                color: getStatusColor(acceptModalIntDet?.interviewData?.status),
                textTransform: "capitalize",
                fontWeight: "400",
              }}
            >
              <strong
                style={{ fontWeight: "600", marginRight: "7px", color: "#000" }}
              >
                Status:
              </strong>
              {acceptModalIntDet?.interviewData?.status}
            </h5>
          </span>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "25px",
            }}
          >
            <a
              className="cancelBtn"
              style={{ background: "#f15d3b", color: "white", border: "none" }}
              onClick={() => {
                setInterviewDetailsModalView(false);
                setCancelModalView(true);
              }}
            >
              Cancel
            </a>
            <a
              className="cancelBtn"
              style={{ background: "orange", color: "white", border: "none" }}
              onClick={() => {
                setRescheduleModalView(true);
                setInterviewDetailsModalView(false);
              }}
            >
              Reschedule
            </a>
            <a
              className="cancelBtn"
              style={{ background: "#3ba0aa", color: "white", border: "none" }}
              target="_blank"
              href={
                acceptModalIntDet?.interviewData?.slots?.filter(
                  (itm) => itm.joinUrl
                )?.[0]?.interviewerUrl ||
                acceptModalIntDet?.interviewData?.slots?.filter(
                  (itm) => itm.joinUrl
                )?.[0]?.joinUrl
              }
            >
              Join
            </a>
          </div>
        </>
      );
  };

  const timeView = (hours, minutes) => {
    const h = hours <= 12 ? hours : hours - 12;
    const hrs = h < 10 ? `0${h}` : h;
    const min = minutes < 10 ? `0${minutes}` : minutes;
    return hours <= 12 ? `${hrs}:${min} AM` : `${hrs}:${min} PM`;
  };

  const viewStatus = (interview_data, status) => {
    if (interview_data.status === "canceled" && status) {
      return interview_data.cancelledBy === "developer"
        ? "Declined"
        : "Canceled";
    } else if (interview_data.status === "pending" && status) {
      return interview_data.requestedBy === "developer"
        ? "Reschedule Requested"
        : "Pending";
    } else if (interview_data.status === "scheduled") {
      if(status) {
        return "Scheduled"
      }
      const interview_date = interview_data?.slots?.filter(
        (item) => item.joinUrl
      )?.[0];
      return timeView(
        new Date(interview_date?.from).getHours(),
        new Date(interview_date?.from).getMinutes()
      );
    } else {
      return null;
    }
  };

  const dataViewer = (dataForView) => {
    return dataForView?.map((item, index) => {
      return (
        <tr
          key={Math.random()}
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (
              activeTab === "new" &&
              item?.interviewData?.status === "pending"
            ) {
              setAcceptModal(true);
              setAcceptModalIntDet({
                ...item?.interviewData,
                ...item,
              });
            } else if (
              activeTab === "new" &&
              item.interviewData.status === "canceled"
            ) {
              setAcceptModalIntDet({
                ...item?.interviewData,
                ...item,
              });
              setRescheduleModalView(true);
            } else if (
              activeTab === "upcoming" &&
              item.interviewData.status === "scheduled"
            ) {
              setAcceptModalIntDet({
                ...item?.interviewData,
                ...item,
              });
              setInterviewDetailsModalView(true);
            }
          }}
        >
          <td>
            <div className="developer-details vh-center-row">
              <figure>
                <img
                  src={item?.userImage ? item?.userImage : userImage}
                  alt="Developer Profilee Image"
                />
              </figure>
              <div>
                <h5>{item?.name}</h5>
                <p>{item?.designation}</p>
              </div>
            </div>
          </td>
          <td>{item?.jobTitle || item?.designation}</td>
          {isAll && activeTab === "upcoming" && <td>
          <span
              // data-tooltip={item?.interviewData?.message}
              id="profile-edit"
              style={{
                color: getStatusColor(item?.interviewData?.status),
              }}
            >
              {viewStatus(item?.interviewData, true)}
            </span>
            </td>}
          <td>
            <span
              // data-tooltip={item?.interviewData?.message}
              id="profile-edit"
              style={{
                color: getStatusColor(item?.interviewData?.status),
              }}
            >
              {viewStatus(item?.interviewData, activeTab === "upcoming" ? false : true)}
            </span>
          </td>
        </tr>
      );
    });
  };

  return (
    <Fragment>
      <Modal
        open={acceptModal}
        title={"Interview Details"}
        size={"50%"}
        onCancel={() => setAcceptModal(false)}
        onClose={() => setAcceptModal(false)}
        onDone={() => {}}
        isFooterView={false}
        content={() => renderAcceptModalContent()}
      />
      <Modal
        open={rescheduleModalView}
        title={"Reschedule Interview"}
        size={"50%"}
        onClose={() => setRescheduleModalView(false)}
        onCancel={() => setRescheduleModalView(false)}
        onDone={() => {}}
        isFooterView={false}
        content={() => renderRescheduleModalContent()}
      />
      <Modal
        open={cancelModalView}
        title={"Cancel Interview"}
        size={"50%"}
        onClose={() => setCancelModalView(false)}
        onCancel={() => setCancelModalView(false)}
        onDone={() => {}}
        isFooterView={false}
        content={() => renderCancelInterviewModalContent()}
      />
      <Modal
        open={interviewDetailsModalView}
        title={"Interview Details"}
        size={"50%"}
        onClose={() => setInterviewDetailsModalView(false)}
        onCancel={() => setInterviewDetailsModalView(false)}
        onDone={() => {}}
        isFooterView={false}
        content={() => renderInterviewDetailsModalContent()}
      />
      <div style={{ maxHeight: "350px", overflowY: "scroll" }}>
        <table
          className="dev-table"
          width="100%"
          border="0"
          cellPadding="0"
          cellSpacing="0"
        >
          {data && (
            <thead>
              <tr>
                <th>Name</th>
                <th>Job Title</th>
                {isAll && activeTab === "upcoming" && <th>Status</th>}
                <th>{activeTab === "upcoming" ? "Time" : "Status"}</th>
              </tr>
            </thead>
          )}
          <tbody>
            {activeTab === "upcoming" ? (
              <>
                {Object.keys(interviewData)?.map((item) => {
                  return (
                    <>
                      <tr>
                        <th className="dateTitle">
                          {new Date(item).toDateString()}
                        </th>
                        <th className="dateTitle"></th>
                        <th className="dateTitle"></th>
                        {isAll && activeTab === "upcoming" &&  <th className="dateTitle"></th>}
                      </tr>
                      {dataViewer(interviewData[item])}
                    </>
                  );
                })}
              </>
            ) : (
              dataViewer(interviewData)
            )}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}
