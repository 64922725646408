import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  whyJoinHead,
  whoCanBe,
  whyjoin,
  whoCanBeHead,
} from "../../common/constant/homeDeveloper";
import MetaTagSEO from "../../components/seo/MetaTagSEO";
import Blogsection from "../homepage-client/Blogsection";
import FaqSectionContent from "../homepage-client/FaqSection";
import Banner from "./Banner";
import CardSection from "./CardSection";
import "./HomeDeveloper.css";
import HowCanYouJoin from "./HowCanYouJoin";
import JobSection from "./JobSection";
import { useEffect } from "react";
import { showOpenFaqsAction } from "../../service/action/common";
import { useNavigate } from "react-router-dom";

const HomeDeveloper = () => {
  const { recentFiveBlogs, openFaqsData } = useSelector((state) => state.commonReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showOpenFaqsAction(navigate));
  }, [])

  return (
    <Fragment>
      <MetaTagSEO helmetName="developer-homepage" />
      <div className="wrapper">
        <Banner />
        <CardSection cardData={whyjoin} heading={whyJoinHead} />
        {/* <AreYouLooking /> */}
        <HowCanYouJoin />
        <CardSection cardData={whoCanBe} heading={whoCanBeHead} />
        {/* <BestDeveloper /> */}
        <JobSection />
        <section className="faq">
          <FaqSectionContent accordionData={openFaqsData?.devFaqs} />
        </section>

        {!!recentFiveBlogs?.length && (<Blogsection recentBlogs={recentFiveBlogs} />)}
      </div>
    </Fragment>
  );
};

export default HomeDeveloper;
