import React from 'react'
import DarkTheme from "../../../assets/img/company-profile/dark-blue-theme.png";
import BlueTheme from "../../../assets/img/company-profile/blue-theme.png";
import SkyTheme from "../../../assets/img/company-profile/sky-theme.png";


const TempleteOption = ({register,coverTemplate}) => {
  return (
    <div className="row" style={{ marginTop: "16px" }}>
    <div className="col-6">
        <div className="form-group">
          
            <div className="cover-tem">
            <label>
                Select Cover Template{" "}
                <i className="required">* </i>
            </label>
                <span>
                    <input
                        type="radio"
                        checked={coverTemplate == "dark-blue"}
                        value={"dark-blue"}
                        {...register("coverTemplate")}
                    />
                    <img src={DarkTheme} />
                </span>

                <span>
                    <input
                        type="radio"
                        checked={coverTemplate == "blue"}
                        value={"blue"}
                        {...register("coverTemplate")}
                    />{" "}
                    <img src={BlueTheme} />
                </span>

                <span>
                    <input
                        type="radio"
                        checked={
                            coverTemplate == "sky" || !coverTemplate
                        }
                        value={"sky"}
                        {...register("coverTemplate")}
                    />
                    <img src={SkyTheme} />
                </span>
            </div>
        </div>
    </div>
</div>
  )
}

export default TempleteOption