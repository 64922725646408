import React, { Fragment } from 'react'
import { commonTerms } from '../../../common/constant/term-condition/termCondition'

const CommonTermCondion = ({ acceptTerm, declineTerm, showHeader =true }) => {
    return (
        <div className='term-condtions-area'>
            {
                showHeader && (
                    <div className='term-condtions text-center'>
                        <h4 className='heading-term'>TERMS OF SERVICE</h4>
                        <p>LAST UPDATE ON SEP 12, 2023</p>
                    </div>
                )
            }

            <div className='content-list video-inst-box'>
                <p className='term-head'>Terms and Conditions</p>
                <ol>
                    {
                        commonTerms?.map((itm, index) => (
                            <Fragment key={index}>
                                {itm?.title && <li className='title'>{itm?.title}</li>}
                                <ol>
                                    {itm?.content?.map((data, index) => (
                                        <p className='inner-p' class={(itm?.content?.length - 1 !== index) && "remove-p"}>
                                            <span dangerouslySetInnerHTML={{ __html: data?.subTitle }}></span>
                                            <ul>
                                                {
                                                    data?.lists?.map((item) => (
                                                        <div>
                                                            {
                                                                typeof item == "string" ? <li className='inner-li' dangerouslySetInnerHTML={{ __html: item }}></li>
                                                                    : <div>
                                                                        <li className='inner-li' dangerouslySetInnerHTML={{ __html: item?.title }}>

                                                                        </li>
                                                                        <ul>
                                                                            {
                                                                                item?.list?.map((nestdItm) => (
                                                                                    <li className='nstd-li'>{nestdItm}</li>
                                                                                ))
                                                                            }

                                                                        </ul>
                                                                    </div>
                                                            }

                                                        </div>
                                                    ))
                                                }

                                            </ul>
                                        </p>
                                    ))
                                    }
                                </ol>
                                {itm?.description && <p>{itm?.description}</p>}
                            </Fragment>
                        ))
                    }
                </ol>
            </div>
            <div className='row'>
                {
                    acceptTerm && <div className='col-9 text-right'>
                        <button className='submitBtn bg-warning' onClick={declineTerm}>Decline</button>
                    </div>
                }

                {
                    declineTerm && <div className='col-3'>
                        <button className='submitBtn' onClick={acceptTerm}>Accept</button>
                    </div>
                }

            </div>
            <br />
            <br />
        </div>
    )
}

export default CommonTermCondion