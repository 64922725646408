export const ProgrfessBar = ({ title, marks, percentageScore = 100 }) => (
    <div className={`row ${title == "Total" && "total-rows-progress"}`}>
        <div className='col-3'>
            <p className='time'>{title}  </p>
        </div>
        <div className='col-6'>
            <div className='linear-progressbar-1'>
                <div style={{width: percentageScore+"%" }}></div>
            </div>
        </div>
        <div className='col-3'>
            <p className='time percentage'>{marks} </p>
        </div>
    </div>
)