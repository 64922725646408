import React from "react";
import { useNavigate } from "react-router-dom";
import DataTables from "../../../components/data-tables/DataTables";
import ColumnLists from "./ColumnLists";

const Listing = ({ clientList, isLoading, pageData, setPageData }) => {
  const navigate = useNavigate();

  const handleClientInfo = (clientId) => {
    navigate(`/admin/client-management/${clientId}`);
  };

  const fetchMoreData = () => {
    setPageData({ ...pageData, limit: pageData?.limit + 10 });
  };

  return (
     <DataTables
        columns={ColumnLists()}
        listData={clientList}
        Infinit={true}
        fetchMoreData={fetchMoreData}
        listLoading={isLoading}
        onRowClick={(item)=>handleClientInfo(item?._id)}
      />
  );
};

export default Listing;
