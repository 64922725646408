import React from 'react'
import Glassdoor from "../../assets/img/icons/glassdoor.png"
const AwardsRecognitins = ({ data, glassdoorLink }) => {
    return (
        !!data?.length && (
            <>
                <div className='award-recognitions'>
                    <div className='row'>
                        <div className='col-12'>
                        <h2>
            <span className="section-category">OUR AWARDS</span>
            <span className="section-title"> Awards & Recognitions
            </span>
            {
                                !!glassdoorLink && (
                                    <a href={glassdoorLink}>
                                        <div className='right-sec'>
                                            <img src={Glassdoor} height={"26px"} />
                                            <p>Review</p>
                                        </div>
                                    </a>
                                )
                            }
          </h2>
 

                        </div>

                    </div>
                    <div  className='row box-container'>
                    {
                            data?.map((itm) => itm?.title && (
                                <div className='col-3 box-item text-center'>
                                    <img src={itm?.awardImage} />
                                    <p>{itm?.title}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <br />
            </>
        )
    )
}

export default AwardsRecognitins