import React from "react";
import { Helmet } from "react-helmet-async";
import { devHomepageOG } from "../imageLinks";
import NoFollow from "../../../common/staticData/seo/NoFollow";

const CandidateProfile = ({ devDetails }) => {
  console.log(devDetails);
  return (

    <Helmet>
     { devDetails?.fName && (<title>Hire {devDetails?.fName + " " + devDetails?.lName + " "+devDetails?.devDesg + " | " +devDetails?.devCity+ " | Software Developer - Olibr"}</title>)}
      <meta charset="UTF-8" />
      <meta name="title" content={"Hire "+devDetails?.fName + " " + devDetails?.lName + " "+devDetails?.devDesg + " | " +devDetails?.devCity+ " | Software Developer - Olibr"} />
      <meta name="description" content={devDetails?.fName + " " + devDetails?.lName +" "+devDetails?.devTellUrStory?.substring(0, 150)}></meta>
      <link rel="canonical" href={window.location.href} />
      <meta
        name="keywords"
        content="Software Developer Jobs, Software Developer Jobs USA, jobs, olibr.com, tech jobs, olibr, olibr.com, Hiring developers, remote software developers, work-from-home developers, wfh, remote jobs, IT jobs, US based IT companies, US Jobs, US IT Jobs"
      />
      <meta name="robots" content={NoFollow()}></meta>

      <meta name="language" content="english"></meta>
      <html lang="en" />
      <meta name="url" content={window.location.href} />
      <meta name="identifier-URL" content={window.location.href}></meta>
      <meta name="referrer" content="origin-when-crossorigin"></meta>
      <link rel="image_src" href={devHomepageOG} />
      <meta name="copyright" content="olibr.com" />

      <meta
        property="og:title"
        content={"Hire "+devDetails?.fName + " " + devDetails?.lName + " "+devDetails?.devDesg + " | " +devDetails?.devCity+ " | Software Developer - Olibr"}
      />
      <meta name="og:description" content={devDetails?.fName + " " + devDetails?.lName +" "+ devDetails?.devTellUrStory?.substring(0, 150)}></meta>
      <meta property="og:url" content={window.location.href} />
      <meta property="og:image" content={devHomepageOG} />
      <meta property="og:site_name" content="@Olibr" />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@olibr" />
      <meta property="twitter:domain" content="olibr.com" />
      <meta property="twitter:url" content={window.location.href} />
      <meta
        name="twitter:title"
        content={"Hire "+devDetails?.fName + " " + devDetails?.lName + " "+devDetails?.devDesg + " | " +devDetails?.devCity+ " | Software Developer - Olibr"}
      />
      <meta name="twitter:description" content={devDetails?.fName + " " + devDetails?.lName +" "+devDetails?.devTellUrStory?.substring(0, 150)} />
      <meta name="twitter:image" content={devHomepageOG} />

      <meta property="og:type" content="business.business" />
      <meta
        property="business:contact_data:street_address"
        content="26 Arrowhead Ln"
      />

      {/* ------Apple Devices ------ */}
      <meta
        name="apple-mobile-web-app-title"
        content="New software developer jobs in USA companies | Olibr Jobs"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-touch-fullscreen" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black"></meta>
    </Helmet>
  );
};

export default CandidateProfile;
