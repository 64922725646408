import React, { Fragment, useContext, useRef, useState } from "react";
import "./TestResult.css";
import { useNavigate } from "react-router-dom";
import { resultParsing } from "../../../service/action/admin";
import { useDispatch, useSelector } from "react-redux";
import {
  testResult,
} from "../../../common/constant/admin/testResult";
import ClipLoaderComp from "../../../components/clipLoader/ClipLoader";
import { MainContext } from "../../../context/MainContext";
import { checkPermission, readOnlyCheck } from "../../../components/functions";

const TestResult = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uploadExcelRef = useRef();
  const [selectedFile, setSelectedFile] = useState(null);
  const {user:{access}}=useContext(MainContext)

  const handlePostResult = () => {
    let formData = new FormData();
    formData.append("file", selectedFile);
    dispatch(resultParsing(formData, navigate));
  };

  const {resultParsingLoading} = useSelector((state) => state.adminReducer); 

  return (
    <Fragment>
      <div className="container">
        <div className="create-profile">
          <div className="block-header">
            <h4>{testResult}</h4>
          </div>
          <input
            ref={uploadExcelRef}
            type="file"
            onChange={(e) => {
              if (checkPermission(readOnlyCheck(access,"Test Result"))) return false
              setSelectedFile(e?.target?.files[0])}}
            className="input-file"
            accept=".xls, .csv"
          />
          <div className="row">
            <div className="col-6 adornment">
              <label className="floating-label">Upload Result</label>
              <input type="text"
               disabled={readOnlyCheck(access,"Test Result")}
               value={selectedFile?.name} />
            </div>
            <div className=" col-3">
              <br />
              <button
                className="file-icon-btn"
                onClick={() => {
                  if (checkPermission(readOnlyCheck(access,"Test Result"))) return false
                  uploadExcelRef.current.click()}}
              >
                {"Upload Results"}
              </button>
            </div>
            <div className="col-3">
              <br />
              <button
                className="submitBtn themeButton"
                onClick={() => {
                  if (checkPermission(readOnlyCheck(access,"Test Result"))) return false
                  handlePostResult()}}
              >
                {resultParsingLoading ? <ClipLoaderComp color={"white"} size={15} /> : "SEND"}
              </button>
            </div>
          </div>
          <div className="row"></div>
          <br />

          <div></div>
        </div>
      </div>
    </Fragment>
  );
};

export default TestResult;
