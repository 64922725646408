import React, { useState } from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";
import { getPrevDateDiff } from "../functions";
import { Rating } from "@mui/material";

const MyReviews = ({ devReviews }) => {
  let slideshow = devReviews?.length;

  const calculateAverageRating = (rateParams) => {
    const totalRatings =
      parseFloat(rateParams.communication) +
      parseFloat(rateParams.adaptability) +
      parseFloat(rateParams.leaderShip) +
      parseFloat(rateParams.professionalism) +
      parseFloat(rateParams.problemSolving);
    return totalRatings / 5.0;
  };

  const settings = {
    dots: true,
    // speed: 500,
    slidesToShow: slideshow === 1 ? 1 : 2,
    slidesToScroll: 2,
    // autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [fullStory, setFullStory] = useState(false);

  // Initialize an array to track expanded state for each review
  const [expandedReviews, setExpandedReviews] = useState(Array(devReviews?.length).fill(false));

  // Function to toggle the expanded state for a specific review
  const toggleReviewExpand = (index) => {
    const newExpandedReviews = [...expandedReviews];
    newExpandedReviews[index] = !newExpandedReviews[index];
    setExpandedReviews(newExpandedReviews);
  };

  return (
    !!devReviews?.some((itm) => itm?.status == "done") && (
      <div className="resume-reviews">
        <h4 className="reviews-title margin-top-20">My Reviews</h4>
        <div className="carousel-outer">
          {/* <!-- Testimonial Carousel --> */}
          <div className="testimonial-carousel-three gap-x25">
            <Slider {...settings} arrows={false}>
              {devReviews?.map(
                (itm, index) =>
                  itm?.status === "done" && (
                    <div key={index} className="testimonial-block">
                      <div className="inner-box">
                        {/* <div className="text margin-top-30">{itm?.comment}</div> */}
                        {/* <div className="text margin-top-30">
                          {fullStory
                            ? itm?.comment
                            : itm?.comment.substring(0, 56)}{" "}
                          {itm?.comment.length >= 56 ? (
                            <a
                              className="read-more"
                              onClick={() => setFullStory(!fullStory)}
                            >
                              {" "}
                              ...
                              {fullStory ? "read less" : "read more"}
                            </a>
                          ) : (
                            ""
                          )}
                        </div> */}
                        <div className="text margin-top-30">
                          {expandedReviews[index]
                            ? itm?.comment
                            : itm?.comment.substring(0, 56)}{" "}
                          {itm?.comment.length >= 56 && (
                            <a
                              className="read-more"
                              onClick={() => toggleReviewExpand(index)}
                            >
                              {expandedReviews[index]
                                ? "...read less"
                                : "...read more"}
                            </a>
                          )}
                        </div>
                        <ul className="review-chip">
                          <li>
                            {/* Hard working<span>{itm?.rateParams?.adaptability}</span> */}
                            Adaptability
                            <span>{itm?.rateParams?.adaptability}</span>
                            <i className="fa fa-star star"></i>
                          </li>
                          <li>
                            Problem solving
                            <span>{itm?.rateParams?.problemSolving}</span>
                            <i className="fa fa-star star"></i>
                          </li>
                          <li>
                            {/* Analytical Skills<span>{itm?.rateParams?.leaderShip}</span> */}
                            Leadership<span>{itm?.rateParams?.leaderShip}</span>
                            <i className="fa fa-star star"></i>
                          </li>
                          <li>
                            Professionalism
                            <span>{itm?.rateParams?.professionalism}</span>
                            <i className="fa fa-star star"></i>
                          </li>
                          <li>
                            Communication
                            <span>{itm?.rateParams?.communication}</span>
                            <i className="fa fa-star star"></i>
                          </li>
                        </ul>
                        <div className="info-box">
                          <h4 className="name">{itm?.receiverName}</h4>
                          <span className="designation">
                            {itm?.receiverDesg}
                          </span>
                        </div>
                        <div className="review-footer margin-top-20">
                          <div className="review-time">
                            <i className="fa fa-regular fa-clock-four"></i>
                            {getPrevDateDiff(itm)}
                          </div>
                          <div className="average-rating flex-end">
                            <Rating
                              name="half-rating-read"
                              value={calculateAverageRating(itm.rateParams)}
                              precision={0.5}
                              readOnly
                              size="small"
                            />
                            <span className="average-value">
                              {calculateAverageRating(itm.rateParams)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </Slider>
          </div>
        </div>
      </div>
    )
  );
};
MyReviews.propTypes = {
  devReviews: PropTypes.array,
};
export default MyReviews;
