import React, { useState } from 'react'
import { contactOrSales, email, emailAddress, fullName, message, mobile, subject } from '../../common/constant/contact'
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { ContactUsPostAPI } from '../../service/action/common';
const ContactUsForm = ({ forUser = "Developer" }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [verified, setVerified] = useState(false);
    const [postObj, setPostObj] = useState({
        fullName: "",
        email: "",
        countryCode: "+91",
        mobileNumber: "",
        subject: "",
        message: "",
      });
    
    const onChange = (value) => {
        setVerified(true);
      };
      const handleChange = (e) => {
        setPostObj({ ...postObj, [e.target.name]: e.target.value });
      };

      const handleSubmit = () => {
        dispatch(ContactUsPostAPI(postObj, navigate));
        setPostObj({
          fullName: "",
          email: "",
          countryCode: "+91",
          mobileNumber: "",
          subject: "",
          message: "",
        });
      };
    
    
    return (
    <div className="contact-section">
    <div className="container">
      <div className="block-header">
        <h4>{contactOrSales}</h4>
      </div>
      <div className="row">
        <div className="col-8">
          <form>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label className="floating-label" htmlFor="">
                    {fullName}
                  </label>
                  <input
                    type="text"
                    name="fullName"
                    className="form-control"
                    placeholder="Dan Robert"
                    onChange={handleChange}
                    value={postObj?.fullName}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label className="floating-label" htmlFor="">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="dan@email.com"
                    onChange={handleChange}
                    value={postObj?.email}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label className="floating-label" htmlFor="">
                    {mobile}
                  </label>
                  <input
                    type="number"
                    name="mobileNumber"
                    min={0}
                    className="form-control number-arrow-hide"
                    onChange={handleChange}
                    value={postObj?.mobileNumber}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label className="floating-label" htmlFor="">
                    {subject}
                  </label>
                  <select
                    name="subject"
                    className="form-control"
                    onChange={handleChange}
                    value={postObj?.subject}
                  >
                    <option value={""}></option>
                    <option value={"General Query"}>General Query</option>
                    <option value={"Hire A Developer"}>
                      Hire A Developer
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label className="floating-label">{message}</label>
                  <textarea
                    rows={6}
                    name="message"
                    className="form-control"
                    value={postObj?.message}
                    onChange={handleChange}
                    placeholder="Type message here...."
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <ReCAPTCHA
                  sitekey="6Ld7d2skAAAAAAjdPNnGL-HjNgLtXeIthAu5qYnN"
                  onChange={onChange}
                />
              </div>
              <div className="col-12 contact-btn">
                <button
                  type="button"
                  className="btn btn-full"
                  onClick={handleSubmit}
                  disabled={!verified}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-4">
          <div className="contact-info">
            {/* <img src={EmailIcon} alt="email" /> */}
            <img
              src="https://ik.imagekit.io/olibr/assets/tr:w-303,h-250/email-icon.webp"
              alt="email"
            />
            <br></br>
            <br />
            <p>
              {forUser === "Client" ? "CLIENT SUPPORT" : email} <br />
              <span>
                {forUser === "Client"
                  ? "client.support@olibr.com"
                  : emailAddress}
              </span>{" "}
            </p>
            <br />
            <p>
              {forUser === "Client"?"GENERAL ENQUIRIES":"Sales Enquiries"}
              <br />
              <span>{forUser === "Client"?"info@olibr.com":"sales@olibr.com"} </span>{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ContactUsForm