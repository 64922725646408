import React from "react";
import { Fragment } from "react";
import { Icon } from "../../../../../components/icon";

const UpperBoxes = ({ isPlag, scores }) => {
  return (
    <Fragment>
      <div className="main-detailed">
        <div className="row">
          <div className="col-6">
            <div className="detailed-report">
            <h3>Total Score</h3>
              <div className="skill-graph score-circle">
                <div className="skill-graph">
                  <div className="single-chart">
                    <svg
                      viewBox="0 0 36 36"
                      className={`circular-chart ${scores?.isPassed ? "green" : "red"}`}
                    >
                      <path
                        className="circle-bg"
                        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                      />
                      <path
                        className="circle"
                        stroke-dasharray={`${parseInt(scores?.percentageScore)}, 100`}
                        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                      />
                      <text x="18" y="20.35" className="percentage">
                        {parseInt(scores?.obtainedScore)}/
                      {parseInt(scores?.maxScore)}
                      </text>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="detailed-report suspicious-bg">
              <h3>Suspicious Activity</h3>
              <Icon name="suspicious" />
              <div style={{ color: isPlag ? "#3ba0aa" : "" }}>
                {isPlag ? "Plagiarism Found" : "No Plagiarism Found"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UpperBoxes;
