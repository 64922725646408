import React, { useEffect, useState } from "react";
import OlibrDatePicker from "../olibr-datepicker";
import { useDispatch, useSelector } from "react-redux";
// import { getSkillAction } from "../../../../../service/action/common";
import SelectOption from "../select/SelectOption";
import { profLevelsArrLev } from "../../common/constant";
import { preventMinus } from "../../common/preventMinus";
import { getAllSkillsList } from "../../service/action/developer";
import { useNavigate } from "react-router-dom";

const AddDevSkills = ({
  errors,
  selectedSkill,
  setSelectedSkill,
  requiredSkill,
  userData
}) => {
  const {skillList}=useSelector((state)=>state.adminReducer)
  const [allSkillList, setSkillList] = useState([]);
  const dispatch = useDispatch();
  const navigate=useNavigate()
  useEffect(() => {
    dispatch(getAllSkillsList(navigate))
  }, []);


  const handleAddNew = () => {
    setSelectedSkill([
      ...selectedSkill,
      {
        experiency: "",
        lastUsed: null,
        proficiencyLevel: "",
        skill: "",
      },
    ]);
  };

  useEffect(() => {
    if (skillList?.data?.length) {
      let skill=skillList?.data?.map((itm)=>({...itm,value:itm?._id,label:itm?.skillName}))
      setSkillList(skill);
    }
  }, [skillList]);

  const removeRow = (index) => {
    let tempArr = selectedSkill.filter((_, id) => index != id);
    setSelectedSkill(tempArr);
  };
  const handleChangeSkill = (e, index) => {
    let tempArr = [...selectedSkill];
    tempArr[index].skill = e;
    setSelectedSkill(tempArr);
  };

  const handleChangesPLevel = (e, index) => {
    let tempArr = [...selectedSkill];
    tempArr[index].proficiencyLevel = e;
    setSelectedSkill(tempArr);
  };

  const handleChangeExperiency = (e, index) => {
    let temp = [...selectedSkill];
    let input = e.target.value;
    if (input >= 80) {
      e.preventDefault();
    } else {
      input = parseInt(input);
      if (input > 0) {
        temp[index].experiency = e.target.value.replace(/[^0-9.]/g, "");
      } else {
        temp[index].experiency = e.target.value.replace(/[^1-9.]/g, "");
      }
    }
    setSelectedSkill(temp);
  };

  const handleDateChanges = (e, index) => {
    let tempArr = [...selectedSkill];
    tempArr[index].lastUsed = e;
    setSelectedSkill(tempArr);
  };
  useEffect(() => {
    if (userData?.devSkillsInfo) {
      if (Array.isArray(userData?.devSkillsInfo)) {
        let tempSkill = [];
        userData?.devSkillsInfo?.map((itm) => {
          let proficiencyLevel = profLevelsArrLev.find(
            (item) => item.value == itm?.proficiencyLevel
          );
          let skillId = skillList?.data?.find(
            (item) => item._id == itm?.skillId
          );
          let experiency = false;
          if (itm?.experiency > 0) {
            if (itm?.experiency == 1) {
              experiency = itm?.experiency + " Year";
            } else {
              experiency = itm?.experiency + " Years";
            }
          } else {
            experiency = itm?.experiency;
          }
          tempSkill.push({
            experiency: experiency,
            proficiencyLevel: {
              value: proficiencyLevel?.value,
              label: proficiencyLevel?.label,
            },
            skill: { value: itm?.skillId, label: itm?.skillName },
            lastUsed: itm?.lastUsed ? itm?.lastUsed : "",
          });
        });

        let tempRequiredSkill = [];
        requiredSkill?.map((skillId) => {
          if (tempSkill?.some((oldSkill) => oldSkill?.skill?.value != skillId)) {
            let tempSkills = skillList?.data?.find(
              (item) => item.value == skillId
            );
            tempRequiredSkill.push({
              experiency: "",
              lastUsed: null,
              proficiencyLevel: "",
              skill: { value: tempSkills?.value, label: tempSkills?.label },
            });
          }
        });
        setSelectedSkill([...tempSkill, ...tempRequiredSkill]);
      }
    }
  }, [userData, requiredSkill, skillList]);

  const removeValue = (index) => {
    let tempArr = [...selectedSkill];
    tempArr[index].lastUsed = null;
    setSelectedSkill(tempArr);
  };

  const blurHandle = (e, index) => {
    let input = e.target.value;
    let temp = [...selectedSkill];
    if (input > 0) {
      if (input == 1) {
        temp[index].experiency = input + " Year";
      } else {
        temp[index].experiency = input + " Years";
      }
    } else {
      temp[index].experiency = input;
    }
    setSelectedSkill(temp);
  };

  const focusHandle = (e, index) => {
    let input = e.target.value;
    let temp = [...selectedSkill];
    if (input?.includes(" Years")) {
      let newValue = input.replace(" Years", "");
      temp[index].experiency = newValue;
    } else if (input?.includes(" Year")) {
      let newValue = input.replace(" Year", "");
      temp[index].experiency = newValue;
    } else {
      temp[index].experiency = input;
    }
    setSelectedSkill(temp);
  };

  const handleInputChange = (event, index) => {
    let input = event.target.value;
    let temp = [...selectedSkill];
    console.log("input", input);
    if (input > 70 || input < 1) {
      event.preventDefault();
    } else {
      input = parseInt(input);
      if (input > 0) {
        temp[index].experiency = event.target.value.replace(/[^0-9.]/g, "");
      } else {
        temp[index].experiency = event.target.value.replace(/[^1-9.]/g, "");
      }
      setSelectedSkill(temp);
    }
  };

  function filterExistSkill() {
    let tempAllSkill = [];
    if (skillList?.data) {
      for (let skill of skillList?.data) {
        if (
          ![...selectedSkill]?.find(
            (itm) => itm?.skill?.value == skill?._id
          )
        ) {
          tempAllSkill.push({...skill,label:skill?.skillName,value:skill?._id});
        }
      }
    }
    setSkillList(tempAllSkill);
  }


  return (
    <table className="add-skills-table">
      <thead>
        <tr>
          <th>
            Select Skill<span className="required-field">*</span>
          </th>
          <th>
            Proficiency Level<span className="required-field">*</span>
          </th>
          <th>
            Experience<span className="required-field">*</span>
          </th>
          <th>
            Last Used <span className="required-field">*</span>
          </th>
          <th>
            {/* <button
              type="button"
              className="add-info-btn static-btn"
              onClick={handleAddNew}
            >
              +
            </button> */}
            <span className="icon" onClick={handleAddNew}><img src="https://ik.imagekit.io/olibr/icons/tr:w-30,h-30/add_icon.svg" alt="add icon" /></span>
          </th>
        </tr>
      </thead>
      <tbody>
        {selectedSkill?.map((itm, index) => (
          <tr key={index}>
            <td className="col-3">
              <SelectOption
                optionArray={allSkillList || []}
                closeMenuOnSelect={true}
                onChangeFunction={(e) => handleChangeSkill(e, index)}
                state={{
                  label: itm?.skill?.label,
                  value: itm?.skill?.value,
                }}
                borderError={errors?.find(
                  (itm) => itm?.keyName == "skillId" && index == itm?.index
                )}
                searchable={true}
                className="job-select modal-select"
                smallSize={true}
                onClick={() => filterExistSkill()}
                menuPlacement="top"
                themeStyle={true}
                selectedValue={()=>{}}
              />
            </td>
            <td className="col-3">
              <SelectOption
                optionArray={profLevelsArrLev}
                closeMenuOnSelect={true}
                onChangeFunction={(e) => handleChangesPLevel(e, index)}
                state={{
                  label: itm?.proficiencyLevel?.label,
                  value: itm?.proficiencyLevel?.value,
                }}
                searchable={true}
                className="job-select modal-select"
                smallSize={true}
                menuPlacement="top"
                themeStyle={true}
                borderError={errors?.find(
                  (itm) =>
                    itm?.keyName == "proficiencyLevel" && index == itm?.index
                )}
              />
            </td>
            <td className="col-3">
              <input
                type="text"
                placeholder="Add Experience"
                value={itm?.experiency}
                onChange={(e) => handleChangeExperiency(e, index)}
                onFocus={(e) => focusHandle(e, index)}
                onBlur={(e) => blurHandle(e, index)}
                onKeyDown={preventMinus}
                onInput={(e) => handleInputChange(e, index)}
                max={2}
                min={1}
                style={{
                  border:
                    errors?.find(
                      (itm) =>
                        itm?.keyName == "experiency" && index == itm?.index
                    ) && "1px solid red",
                }}
              />
            </td>
            <td className="col-3">
              <OlibrDatePicker
                value={itm?.lastUsed?new Date(itm?.lastUsed):null}
                initialView={"onlyYear"}
                maxDate={new Date()}
                onChange={(e) => handleDateChanges(e, index)}
                indexNo={index}
                removeValue={removeValue}
                isMonthOnly={true}
                className={
                  errors?.find(
                    (itm) => itm?.keyName == "lastUsed" && index == itm?.index
                  ) && "border-red"
                }
              />
            </td>
            <td className="col-1">
              {/* <button
                type="button"
                className="delete-row-btn static-btn"
                onClick={() => removeRow(index)}
              >
                <span className="icon flaticon-substract"></span>
              </button> */}
              <a className="minus-icon" onClick={() => removeRow(index)}>-</a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AddDevSkills;
