/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from "react";
import { OtpGroup } from "./OtpGroup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {  verifyOTPAction } from "../../service/action/developer";
import { useState } from "react";
import { useEffect } from "react";

const OtpContent = ({sentOtp,countryCode,changeNo=false,onVerifyClick, closeModal,otpOpen, phoneNumber, setOtpOpen }) => {
  const [inputValues, setInputValues] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
    input6: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onCloseOtp = () => {
    setOtpOpen(false);
    closeModal()
    setInputValues({
      input1: "", 
      input2: "",
      input3: "",
      input4: "",
      input5: "",
      input6: "",
    });
  };

  useEffect(() => {
      setInputValues({
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
      });

  }, [otpOpen]);
  const onSubmitOtp = () => {
    let otp = Object.values(inputValues).join("");
    dispatch(verifyOTPAction({ otp }, navigate, onCloseOtp));
  };

  
  return (
    <Fragment>
      <OtpGroup
        phoneNumber={phoneNumber}
        countryCode={countryCode}
        inputValues={inputValues}
        setInputValues={setInputValues}
        onSubmit={onSubmitOtp}
        onResend={onVerifyClick}
        changeNo={changeNo}
        sentOtp={sentOtp}
      />
    </Fragment>
  );
};
export default OtpContent;
