import { Text, View } from '@react-pdf/renderer'
import { Html } from 'react-pdf-html'
import React from 'react'
import { Description } from './css/Description'
import { useEffect } from 'react'
import { useState } from 'react'

const EmpDescription = ({ content }) => {
  let head = `
    <html>
    <body>
    <style>
     .ul-list{
       font-size:12px;
       margin-left:-9px
     }
     .ul-list ul li{
        line-height:1.3px,;
     }
    </style>
    <div class="ul-list" style="font-size:12px;">
    `

  let footer = `
    </div>
    </body>
    </html>
    `

  const [html, setHtml] = useState("");
  useEffect(() => {
    setHtml(head + content + footer)
  }, [content])
  return (
    content &&  html? (
      <View style={Description.container}>
        <Html>
          {html}
        </Html>
      </View>
    )
    :
    <View style={{marginTop:3}}></View>

  )
}

export default EmpDescription