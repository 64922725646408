import React from 'react'
import PageTour from '../../../components/page-tour/PageTour'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';

const ManagementTeam = ({ setError, checkTeamManagement, removeError, setManagementTeam, imgIndex, uploadImages, pageTour, memberImg, managementTeam, skipPageTour, companyProfile, setPageTour, errors }) => {
    const { clientId } = useParams();
    const {
        isMembrImgLoading,
    } = useSelector((state) => state?.developerReducer);

    const handleTeamChange = (index, e) => {
        let clone = [...managementTeam];
        let obj = clone[index];
        if (e.target.name == "memberName") {
            obj.memberName = e.target.value;
        } else if (e.target.name == "memberDesg") {
            obj.memberDesg = e.target.value;
        } else if (e.target.name == "memberImage") {
            obj.memberImage = e.target.value;
        } else {
            obj.linkedInUrl = e.target.value;
        }
        clone[index] = obj;
        setManagementTeam([...clone]);

        if (index == 0) {
            if (managementTeam[0].memberName) {
                removeError("memberName");
            }
            if (managementTeam[0].memberDesg) {
                removeError("memberDesg");
            }
            if (managementTeam[0].linkedInUrl == "") {
                removeError("teamLinkedIn");
            }
        }
    };
    const addTeam = () => {
        const addTeamMember = () =>
            setManagementTeam([
                ...managementTeam,
                {
                    memberName: "",
                    memberDesg: "",
                    memberImage: "",
                    linkedInUrl: "",
                },
            ]);
        if (!managementTeam?.length) {
            addTeamMember();
        }
        let error = checkTeamManagement();
        if (error?.length) {
            setError(error);
            return false;
        } else {
            addTeamMember();
        }
    };

    const removeTeam = (index) => {
        if (managementTeam?.length) {
          let clone = [...managementTeam];
          let obj = clone?.filter((item, ind) => ind != index);
          setManagementTeam(obj);
        } else {
          let checkErro = checkTeamManagement();
          if (checkErro?.length) {
            setError(checkErro);
            return false;
          }
        }
      };

    return (
        <>
            <h4 className="form-title" id="team">
                <span>Add management team</span>{" "}
                <a className="plus-icon" onClick={addTeam}>
                    +
                </a>
            </h4>
            <PageTour
                open={pageTour == "team"}
                data={companyProfile.team}
                next={() => setPageTour("gallery")}
                className="team-section"
                skip={skipPageTour}
                total={6}
                id="#benefit"
            />
            {managementTeam?.map((item, index) => (
                <div className="row">
                    <div className="col-12">
                        <div className="add-team">
                            <div className="add-form">
                                <div className="row">
                                    <div className="col-3">
                                        <div className="form-group">
                                            <label
                                                className={`${index == 0 &&
                                                    errors?.includes("memberName") &&
                                                    "text-danger"
                                                    }`}
                                            >
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                value={item?.memberName}
                                                name="memberName"
                                                onChange={(e) =>
                                                    handleTeamChange(index, e)
                                                }
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="form-group">
                                            <label
                                                className={`${index == 0 &&
                                                    errors?.includes("memberDesg") &&
                                                    "text-danger"
                                                    }`}
                                            >
                                                Designation
                                                <i className="required">*</i>
                                            </label>
                                            <input
                                                type="text"
                                                value={item?.memberDesg}
                                                name="memberDesg"
                                                onChange={(e) =>
                                                    handleTeamChange(index, e)
                                                }
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="form-group adornment">
                                            <label
                                                className={`${errors?.includes("memberImage") &&
                                                    "text-danger"
                                                    }`}
                                            >
                                                Image
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors?.memberImage &&
                                                    "form-required"
                                                    }`}
                                                name="memberImage"
                                                disabled={clientId ? false : true}
                                            />
                                            <img
                                                className="input-img"
                                                src={
                                                    managementTeam[index]?.memberImage
                                                }
                                            />
                                            <input
                                                ref={memberImg[index]}
                                                type="file"
                                                onChange={(e) =>
                                                    uploadImages(
                                                        e,
                                                        "memberImage",
                                                        index
                                                    )
                                                }
                                                className={`input-file ${errors?.memberImage &&
                                                    "form-required"
                                                    }`}
                                                accept=".png, .jpeg, .jpg, .svg"
                                            />
                                            <a
                                                className="browseImage browseImageNew"
                                                onClick={() =>
                                                    memberImg[index].current.click()
                                                }
                                            >
                                                {isMembrImgLoading &&
                                                    imgIndex == index ? (
                                                    <ClipLoader
                                                        size={20}
                                                        color="#3BA0AA"
                                                    />
                                                ) : (
                                                    "Upload"
                                                )}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="form-group">
                                            <label
                                                className={`${index == 0 &&
                                                    errors?.includes("teamLinkedIn") &&
                                                    "text-danger"
                                                    }`}
                                            >
                                                LinkedIn Profile
                                            </label>
                                            <input
                                                type="text"
                                                value={item?.linkedInUrl}
                                                name="linkedInUrl"
                                                onChange={(e) =>
                                                    handleTeamChange(index, e)
                                                }
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="actions">
                                <a
                                    className="minus-icon"
                                    onClick={() => removeTeam(index)}
                                >
                                    -
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

        </>
    )
}

export default ManagementTeam