import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPendingSkillAction,
  updatePendingSkillAction,
} from "../../../../service/action/admin";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../components/modal/ModalBox";
import EditSkills from "./EditSkills";
import { olibrToast } from "../../../../components/toaster";
const PendingSkill = () => {
  const { pendingSkill } = useSelector((state) => state.adminReducer);
  const [skillDetail, setSkillDetail] = useState({
    modal: false,
    skill: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getPendingSkillAction(navigate));
  }, []);

  const rejectSkill = (skillId) => {
    const callBack = () => {
          olibrToast.success("Reject Successfully");
        dispatch(getPendingSkillAction(navigate));
      };
    dispatch(
      updatePendingSkillAction(navigate, { data: {skillId, isApproved: false } ,cb:callBack})
    );
  };


  const updatePendingSkill = useRef();
  return (
    <div className="main-div">
      <Modal
        open={skillDetail?.modal}
        content={() => (
          <EditSkills
            setSkillDetail={setSkillDetail}
            skillDetail={skillDetail?.skill}
            updatePendingSkill={updatePendingSkill}
          />
        )}
        onCancel={() => setSkillDetail({})}
        onClose={() => setSkillDetail({})}
        onDone={() => updatePendingSkill.current?.click()}
      />
      <div className="skills-management pending-skill">
        <div className="block-header">
          <h4 className="">Pending Skill</h4>
        </div>
        <div className="row">
          <div className="col-8"></div>
        </div>
        <br />
        <div className="table-div">
          <table className="table">
            <thead>
              <tr>
                <th className="column">
                  <div className="serialNumber">S.No</div>
                </th>
                <th className="column">Skill Name</th>
                <th className="column">User Name</th>
                <th className="column">User Type</th>
                <th className="column">Accept/Reject</th>
              </tr>
            </thead>
            <tbody>
              {pendingSkill?.map((itm, index) => (
                <>
                  <tr key={index} className="faqdiv">
                    <td className="titleQues sno">{index + 1}</td>
                    <td className="titleQues ">{itm?.skillName}</td>
                    <td className="titleQues ">
                      {itm?.fName + " " + itm?.lName}
                    </td>
                    <td className="titleQues ">{itm?.userType}</td>
                    <td className="titleQues">
                      <button
                        className="btn small-btn"
                        onClick={() =>
                          setSkillDetail({ modal: true, skill: itm })
                        }
                      >
                        Accept
                      </button>
                      <button
                      type="button"
                        onClick={()=>rejectSkill(itm?._id)}
                        className="btn small-btn bg-warning"
                      >
                        Reject
                      </button>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PendingSkill;
