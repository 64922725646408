const CommentsHistory = ({data}) => {

    const dateFormat = (date) =>{
        const dateArray = new Date(date)?.toDateString()?.split(" ");
        return `${dateArray[2]} ${dateArray[1]} ${dateArray[3]}`
    }

  return (
    <table className="table">
      <thead>
        <tr>
          <th className="column0">S.No</th>
          <th className="column1">Name</th>
          <th className="column1">Date</th>
          <th className="column1">Reason</th>
        </tr>
      </thead>
      <tbody>
        {data && typeof(data)!== "string" && data?.map((feedback, index) => (
          <tr key={feedback?._id} className="dev-hover">
            <td className="column0">
              {/* {(page - 1) * limit + (index + 1)} */} {index + 1}
            </td>
            <td className="column1">{feedback?.name}</td>
            <td className="column1">{ feedback?.date && dateFormat(feedback.date)}</td>
            <td className="column1">{feedback?.reason}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CommentsHistory;
