import React from "react";
import { Icon } from "../../../../components/icon";
import { getCountryName } from "../../../../components/functions";

const ColumnPricingPlan = ( changeStatus,onEdit) => {
  const columns = [
    {
      name: "S.No",
      selector: "sNo",
      width: "97px",
      cell: (_, index) => index + 1,
    },
    {
      name: "Plan Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Plan Theme",
      selector: "billingInterval",
      sortable: true,
      width: "120px",
    },
    {
      name: "Price",
      selector: "price",
      sortable: true,
      width: "120px",
      cell: (row) => <span>{row?.currency + " " + row?.price}</span>,
    },
    {
      name: "Country",
      selector: "country",
      sortable: true,
      cell: (row) => <span>{getCountryName(row?.country)}</span>,
      width: "120px",
    },
    {
      name: "Status",
      selector: "active",
      sortable: true,
      width: "140px",
      cell: (row) => (
        <span>
          <input
            type="checkbox"
            name="switch"
            onChange={(e) => e.preventDefault()}
            checked={row?.isActive}
          />
          <label
            onClick={(e) => {
              e.stopPropagation();
              changeStatus(row);
            }}
            className="label"
            htmlFor="switch3"
          ></label>
        </span>
      ),
    },
    {
      name: "Action",
      sortable: true,
      width: "140px",
      cell: (row) => (
        <span style={{cursor:"pointer"}} onClick={()=>onEdit(row)}>
          <Icon name="edit" />
        </span>
      ),
    },
  ];
  return columns;
};

export default ColumnPricingPlan;
