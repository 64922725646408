import React, { useContext, useEffect, useState } from "react";
import "../../pricing-page/pricing-page.css";
import "./subscription.css";
import OlibrFlagDropDown from "../../../components/dropdown/olibrFlagDropdown";
import { CountryFlagOption } from "../../../components/functions";
import AddOns from "../../../components/pricing/AddOns";
import PricingTabContent from "../../pricing-page/components/PricingTabContent";
import { MainContext } from "../../../context/MainContext";

const TwoPlansModal = () => {
  const states = ["pricing-tabs-active","pricing-tabs-inactive"];
  const {user}=useContext(MainContext)
  const [pricingTab, setpricingTab] = useState(true);
  const [isAnnualPlan, setAnnualPlan] = useState(true);
  const [countryCode, setCountryCode] = useState("IN");
  const [addOnData, setAddOnData] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});

  const [addOnCheckLists, setAddOnCheckedLists] = useState([]);
  const handleClick = () => {
    setAnnualPlan(!isAnnualPlan);
    setpricingTab(!pricingTab);
  };

  const changeCountry = (value) => {
    setAddOnData([]);
    setSelectedPlan({});
    setAddOnCheckedLists([]);
    setCountryCode(value);
  };


  useEffect(() => {
    if (user?.userType === "Client") {
      setCountryCode(user?.region);
    }
  }, [user]);
  

  return (
    <div className="two-plans-modal pricing-page">
      <div className="row pricing-modal-top">
        <div className="col-3">
          <OlibrFlagDropDown
            options={CountryFlagOption("IN-US")}
            value={countryCode}
            onSelect={(value) => changeCountry(value)}
            defaultValue="IN"
            isIsoCode={false}
            disabled={user?.userType === "Client"}
          />
        </div>
        <div className="col-6">
          <div className="buttonFor">
            <span
              className={`${
                states[pricingTab ? 0 : 1]
              } pricing-tab faqTabCursor`}
              onClick={handleClick}
            >
              Annual
            </span>
            <span
              className={`${
                states[!pricingTab ? 0 : 1]
              } pricing-tab faqTabCursor`}
              onClick={handleClick}
            >
              Monthly
            </span>
          </div>
          <small>Save upto 20% in Annual Plan</small>
        </div>
      </div>
      <PricingTabContent
        countryCode={countryCode}
        isAnnualPlan={isAnnualPlan}
        addOnData={addOnData}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
        excludeActive={false}
        actionPage=""
        allUpgrade={true}
      />
      <AddOns
        addOnCheckLists={addOnCheckLists}
        setAddOnCheckedLists={setAddOnCheckedLists}
        setAddOnData={setAddOnData}
        addOnData={addOnData}
        country={countryCode}
        selectedPlan={selectedPlan}
      />
    
    </div>
  );
};

export default TwoPlansModal;
