import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { slugArray, slugObj } from "../../../common/constant/admin/emailAuto";
import { monthArr } from "../../../common/constant/monthArr";
import Modal from "../../../components/modal/ModalBox";
import { getAllEmailSlugsAction, getEmailHistory } from "../../../service/action/admin";
import OlibrDatePicker from "../../../components/olibr-datepicker";
import DataTables from "../../../components/data-tables/DataTables";
import ColumnLists from "./ColumnLists";

const EmailHistory = () => {
  const [filter, setFilter] = useState({
    email: "",
    slug: "",
    to_date: "",
    from_date: "",
  });

  const cleanObj = (obj) => {
    return new Promise(function (resolve, reject) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName]?.length < 1
        ) {
          delete obj[propName];
        }
      }
      resolve(obj);
    });
  };

  const checkValidation = async (filterData) => {
    const cleanFilterValue = await cleanObj(filterData);
    return (
      Boolean(cleanFilterValue?.slug) ||
      Boolean(cleanFilterValue?.email) ||
      Boolean(cleanFilterValue?.to_date) ||
      Boolean(cleanFilterValue?.from_date)
    );
  };

  const [isFilterApply, setFilterApply] = useState(false);
  const checkFilterApply = async () => {
    let checkFltr = await checkValidation(filter);
    setFilterApply(checkFltr);
  };

  useEffect(() => {
    checkFilterApply();
  });

  const { emailHistory, emailHistoryLoading, allEmailSlugs } = useSelector(
    (state) => state.adminReducer
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pageData, setPageData] = useState({
    page: 1,
    limit: 20,
  });

  const applyFilterFunction = () => {
    dispatch(getEmailHistory(filter, { ...pageData, page: 1 }, navigate));
    handleCloseModal();
  };

  useEffect(() => {
    dispatch(getEmailHistory({}, pageData, navigate));
    dispatch(getAllEmailSlugsAction(navigate));
  }, []);

  const clearFilter = () => {
    setFilter({
      slug: "",
      email: "",
      from_date: "",
      to_date: "",
    });
    dispatch(getEmailHistory({}, { page: 1, limit: 20 }, navigate));
  };

  const [modalOpen, setModalOpen] = useState(false);
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const fetchMoreData = () => {
    dispatch(
      getEmailHistory(
        filter,
        { limit: pageData.limit + 20, page: pageData.page },
        navigate
      )
    );
    setPageData({ ...pageData, limit: pageData?.limit + 20 });
  };

  const dateFilter = (item) => {
    const date = item?.split("T")[0]?.split("-")[2] + " " + monthArr[parseInt(item?.split("T")[0]?.split("-")[1]) - 1] + " " + item?.split("T")[0]?.split("-")[0]
    const dates = new Date(item)
    return (
      <>
        {date} {dates?.toLocaleTimeString()}
      </>
    )
  }

  const handleFilterContent = () => {
    return (
      <>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label className="floating-label" style={{ zIndex: 0 }}>
                Email
              </label>
              <input
                type="text"
                className="form-control"
                value={filter?.email}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    email: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="floating-label" style={{ zIndex: 0 }}>
                Email Type
              </label>
              <select
                className="form-control"
                value={filter?.slug}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    slug: e.target.value,
                  })
                }
              >
                <option value=""></option>
                {Object.keys(allEmailSlugs)?.map((slug, index) => (
                  <option key={index} value={slug}>
                    {allEmailSlugs[`${slug}`]}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="floating-label" style={{ zIndex: 2 }}>
                From
              </label>
              <OlibrDatePicker
                onChange={(e) =>
                  setFilter({ ...filter, from_date: e })
                }
                value={filter?.from_date ? filter?.from_date : ""}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="floating-label" style={{ zIndex: 2 }}>
                To
              </label>
              <OlibrDatePicker
                onChange={(e) =>
                  setFilter({ ...filter, to_date: e })
                }
                value={filter?.to_date ? filter?.to_date : ""}
              />
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="bottom-button">
            <button className="cancelBtn" onClick={handleCloseModal}>
              cancel
            </button>
            <button
              className="submitBtn themeButton"
              onClick={applyFilterFunction}
            >
              Apply
            </button>
          </div>
        </div>
        <br />
      </>
    );
  };
  return (
    <Fragment>
      <Modal
        open={modalOpen}
        title={"FITLERS"}
        onClose={handleCloseModal}
        content={handleFilterContent}
        isFooterView={false}
      />
      <div className="main-div-developer">
        <div className="main-div">
          <div className="create-profile">
            <br />
            <div className="block-header">
              <h4 className="">Email History</h4>
              <span>
                {isFilterApply === false ? (
                  ""
                ) : (
                  <a
                    className="subQues color-orange mr5"
                    onClick={() => clearFilter()}
                  >
                    REMOVE FILTERS
                  </a>
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a className="subQues" onClick={handleOpenModal}>
                  FILTERS
                </a>
              </span>
            </div>
            <div className="row">
              <div className="col-8"></div>
              <div className="search-block col-4"></div>
            </div>
            <br />
            <div className="table-div">
              <div
                className="table-small"
              >
                <DataTables
                  columns={ColumnLists(allEmailSlugs)}
                  listData={emailHistory}
                  Infinit={true}
                  fetchMoreData={fetchMoreData}
                  listLoading={emailHistoryLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EmailHistory;
