import { Fragment, useState } from "react";
import { Icon } from "../../components/icon";
import OlibrShareLink from "../../components/share-link";
import { filterFrontUrlEnv } from "../../components/functions";

const CompanyProfileHeader = ({ data, publish, onEditProfile }) => {
  const [shareOpen, setShareOpen] = useState(false);

  return (
    <Fragment>
      <OlibrShareLink
        isOpen={shareOpen}
        baseUrl={filterFrontUrlEnv("company-profile/"+data?.slug)}
        title="Share Company Profile"
        onClose={() =>setShareOpen(false)}
      />
      {publish?.open && (
        <div className="row">
          <div className="col-12 text-right" style={{ margin: "15px 0px" }}>
            <span
              style={{ cursor: "pointer" }}
              onClick={onEditProfile}
              className="link"
            >
              Edit Profile
            </span>
          </div>
        </div>
      )}
      <header className="company-header">
        {data?.companyLogo && (
          <div className="company-logo">
            <a href="#">
              <img src={data?.companyLogo} alt="Brand Logo" />
            </a>
          </div>
        )}
        <div className="company-nav-right">
          <ul className="menu">
            {!!data?.companyGallery?.filter((itm) => itm)?.length && (
              <li className="menu-item">
                <a href="#gallery">Gallery</a>
              </li>
            )}
            {!!data?.perks?.length && (
              <li className="menu-item">
                <a href="#features">Features</a>
              </li>
            )}
            {!!data?.managementTeam?.filter(
              (itm) => itm?.memberName && itm?.memberDesg && itm?.memberImage
            )?.length && (
              <li className="menu-item">
                <a href="#team">Team</a>
              </li>
            )}
            {!!data?.clientAllJoblists?.length && (
              <li className="menu-item">
                <a href="#jobs">Jobs</a>
              </li>
            )}
            {(data?.companyWebsite || data?.number || data?.companyAddress) && (
              <li className="menu-item">
                <a href="#contact">Contact</a>
              </li>
            )}
          </ul>
          <li className="menu-item">
            <a href="#contact">Share</a>
          </li>
          <span
            title="Share Profile"
            style={{cursor:"pointer", margin: "9px 8px 0px 0px" }}
             onClick={() => setShareOpen(true)}
          >
            <Icon name="share-profile" />
          </span>
        </div>
      </header>
    </Fragment>
  );
};

export default CompanyProfileHeader;
