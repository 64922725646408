import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserImage from "../../assets/img/user-image.jpg";
import { routeLinks } from "../../common/sidebarRoute";
import SidebarLinks from "./sidebarLinks";
import { MainContext } from "../../context/MainContext";
import { Icon } from "../../components/icon";
import ShareProfileButton from "../../components/share-profile-button/ShareProfileButton";
import SideBarLink from "./SideBarLink";
import { getProfileStatus } from "../../service/action/developer";
import { logout } from "../../service/action/authentication";
import { updateTermCondition } from "../../service/action/common";
import Modal from "../../components/modal/ModalBox";
import CommonTermCondion from "../authentication/terms-condition/CommonTermCondion";

const Sidebar = ({ isCollapsed, setCollapsed }) => {
  const [hamHasOpen, setHamHasOpen] = useState(false);
  const [sidebarLink, setSidebarLink] = useState([]);
  const [isProfileApproved, setProfileStatus] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { socket } = useSelector((state) => state?.commonReducer);
  const [openTerms,setOpenTerms]=useState(false)
  const { userImageUrl, resumeData } = useSelector((state) => state.developerReducer);
  const {
    setUser,user,user: { isTermCondition,devDesg, userImage, userType, currentRole, image, fName, lName }
  } = useContext(MainContext);
  useEffect(() => {
    let userTypes = localStorage.getItem("userType")
    userType && setSidebarLink(routeLinks[userType || userTypes]);
    if (userTypes == "Developer") {
      dispatch(getProfileStatus(navigate))
    }
  }, [userType]);


  const { profileStatus, isProfileStatusLoading } = useSelector(
    (state) => state.developerReducer
  );

  useEffect(() => {
    if (!isProfileStatusLoading) {
      if (profileStatus === "approved") {
        !isProfileApproved && setProfileStatus(true);
      } else {
        isProfileApproved && setProfileStatus(false);
      }
    }
  }, [profileStatus, isProfileStatusLoading]);

  const returnDesg = () => {
    if (userType === "Admin") {
      return "Admin";
    } else if (userType === "Developer") {
      return devDesg ? devDesg : "Developer";
    } else if (userType === "Client") {
      return currentRole ? currentRole : "Client";
    }
  };
  const acceptTerm=()=>{
    dispatch(updateTermCondition(navigate,setOpenTerms))
  }
  const declineTerm=()=>{
    dispatch(logout(navigate, setUser, socket, user?._id));
  }

  useEffect(()=>{
    if(isTermCondition==false && userType !== "Admin"){
      setOpenTerms(true)
    }
  
  },[isTermCondition])
  return (
    <>
      <Modal
        open={openTerms}
        isCloseAble={false}
        size="70%"
        hasHeader={false}
        contentClass="terms-content-header"
        isFooterView={false}
        zIndex={1100}
        content={() =>
          <CommonTermCondion acceptTerm={acceptTerm} declineTerm={declineTerm} />
        }
      />
      <div
        className={
          hamHasOpen ? "hamburger-internal has-open" : "hamburger-internal"
        }
        onClick={() => setHamHasOpen(!hamHasOpen)}
      >
        <span className="line line1"></span>
        <span className="line line2"></span>
        <span className="line line3"></span>
      </div>

      <div className={`sidebar ${isCollapsed ? "sidebar-collapsed" : ""}`}>
        <div className="sidebar-brand">
          <Link to="/">
            <Icon name="logo-olibr" />
          </Link>
          {!isCollapsed && (<span onClick={() => setCollapsed(true)} className="collapse-button">
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
          </span>)}
        </div>
        <div className="user-profile">
          <div className="profile-img">
            <img
              src={
                userType === "Developer"
                  ? userImageUrl || userImage || UserImage
                  : UserImage
              }
              alt=""
            />
          </div>
          <div className="profile-details">
            <h4>
              {fName || fName} {lName || lName}
            </h4>
            <p>{returnDesg()}</p>
          </div>
        </div>
        <nav className="navbar">
          <ul className="sidebar-nav">
            <SidebarLinks location={location} routeLinks={sidebarLink} />
          </ul>
          {userType === "Developer" && isProfileApproved && <ShareProfileButton />}
        </nav>
      </div>
      {/* after sidebar collapse */}
      <div className={`sidebar sub-sidebar ${!isCollapsed && "sidebar-collapsed"}`}>
        <div className="img-box">
          <Icon name="olibr-favicon" />
        </div>
        <div className="user-profile">
          <div className="profile-img">
            <img
              src={
                userType === "Developer"
                  ? userImageUrl || userImage || UserImage
                  : UserImage
              }
              alt=""
            />
          </div>
        </div>
        <div className="white-box">
          <SideBarLink routeLinks={sidebarLink} location={location} />
        </div>

      </div>
    </>
  );
};

export default Sidebar;
