import React, { useState } from "react";
import { Fragment } from "react";
import Modal from "../../../components/modal/ModalBox";
import TableHeader from "./components/TableHeader";
import PricingPlanLists from "./components/PricingPlanLists";
import FeatureList from "./components/FeatureLists";
import AddsOnList from "./components/AddsOnList";
import AddEditPlan from "./pricing-form/AddEditPlan";
import AddfeatureForm from "./pricing-form/AddFeatureForm";
import AddOnPlanForm from "./pricing-form/AddOnPlanForm";
import PricingSlabList from "./components/PricingSlabList";
import AddEditPricingSlab from "./pricing-form/AddEditPricingSlab";
const PricingPlan = () => {
  const [addPlan, setAddPlan] = useState(false);
  const [addFeature, setAddFeature] = useState(false);
  const [openAddOn, setOpenAddOn] = useState(false);
  const [openPricingSlab, setPricingSlab] = useState(false);
  const [data, setData] = useState(null);

  const addnewFeature = () => {
    setData(null);
    setAddFeature(true);
  };

  const addnewPlan = () => {
    setData(null);
    setAddPlan(true);
  };

  const addNewSlab=()=>{
    setData(null);
    setPricingSlab(true);
  }
  return (
    <Fragment>
      {addPlan && (
        <Modal
          size="80%"
          open={addPlan}
          title={`${data?._id ? "Update" : "Add New"}  Plan`}
          content={() => <AddEditPlan setAddPlan={setAddPlan} data={data} />}
          onClose={() => setAddPlan(false)}
          onCancel={() => setAddPlan(false)}
          isFooterView={false}
        />
      )}
      {addFeature && (
        <Modal
          size="80%"
          open={addFeature}
          title={`${data?._id ? "Update" : "Add"} New Feature`}
          content={() => (
            <AddfeatureForm setAddFeature={setAddFeature} data={data} />
          )}
          onClose={() => setAddFeature(false)}
          onCancel={() => setAddFeature(false)}
          isFooterView={false}
        />
      )}
      {openAddOn && (
        <Modal
          size="50%"
          open={openAddOn}
          title={`${data?._id ? "Update" : "Add"} Add-ons`}
          content={() => (
            <AddOnPlanForm setOpenAddOn={setOpenAddOn} data={data} />
          )}
          onClose={() => setOpenAddOn(false)}
          onCancel={() => setOpenAddOn(false)}
          isFooterView={false}
        />
      )}
      {openPricingSlab && (
        <Modal
          size="50%"
          open={openPricingSlab}
          title={`${data?._id ? "Update" : "Add"} Pricing Slab`}
          content={() => (
            <AddEditPricingSlab setPricingSlab={setPricingSlab} data={data} />
          )}
          onClose={() => setPricingSlab(false)}
          onCancel={() => setPricingSlab(false)}
          isFooterView={false}
        />
      )}
      <TableHeader
        headerTitle={"Plan List"}
        onClick={addnewPlan}
        titleClick={addnewPlan}
        tableLists={
          <PricingPlanLists setAddPlan={setAddPlan} setData={setData} />
        }
      />

      <TableHeader
        onClick={addnewFeature}
        headerTitle={"Feature List"}
        tableLists={
          <FeatureList setAddFeature={setAddFeature} setData={setData} />
        }
      />
      <TableHeader
        onClick={() => {
          setData(null);
          setOpenAddOn(true);
        }}
        headerTitle={"Add-ons List"}
        tableLists={
          <AddsOnList setOpenAddOn={setOpenAddOn} setData={setData} />
        }
      />
      <TableHeader
        onClick={() => addNewSlab()}
        headerTitle={"Pricing Slab"}
        tableLists={
          <PricingSlabList setPricingSlab={setPricingSlab} setData={setData} />
        }
      />
    </Fragment>
  );
};

export default PricingPlan;
