import React, { useState } from "react";
import { Fragment } from "react";
import { Icon } from "../icon";
import "./ShareProfileButton.css";
import Modal from "../modal/ModalBox";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import CopyLink from "../../assets/img/icons/copy-link.svg";
import { useContext } from "react";
import { MainContext } from "../../context/MainContext";
import { olibrToast } from "../toaster";
const ShareProfileButton = () => {
  const [shareOpen, setShareOpen] = useState(false);
  const { user } = useContext(MainContext);
  const shareContent = () => {
    let sendurl =
      window.location.protocol +
      "//" +
      window.location.host +
      "/resume/" +
      user?._id +
      "-" +
      user?.fName +
      "-" +
      user?.lName;

    const copyLink = async () => {
      await navigator.clipboard.writeText(sendurl);
      olibrToast.success("Link Copied!");
    };
    return (
      <Fragment>
        <div className="social-share-buttons">
          <span>
            <WhatsappShareButton
              style={{ height: "64px", width: "64px", position: "absolute" }}
              url={sendurl}
            />
            <WhatsappIcon size={64} round={true} />
          </span>
          <span>
            <FacebookShareButton
              style={{ height: "64px", width: "64px", position: "absolute" }}
              url={sendurl}
            />
            <FacebookIcon size={64} round={true} />
          </span>
          <span>
            <LinkedinShareButton
              style={{ height: "64px", width: "64px", position: "absolute" }}
              url={sendurl}
            />
            <LinkedinIcon size={64} round={true} />
          </span>
          <span>
            <EmailShareButton
              style={{ height: "64px", width: "64px", position: "absolute" }}
              url={sendurl}
            />
            <EmailIcon size={64} round={true} />
          </span>

          <span className="copy-link-btn" onClick={copyLink}>
            {/* <img src={CopyLink} /> */}
            <img src="https://ik.imagekit.io/olibr/icons/tr:w-14,h-20/copy-link.svg" alt="copy link icon" />
            Copy Link
          </span>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Modal
        open={shareOpen}
        title={"Share Profile"}
        onClose={() => setShareOpen(false)}
        content={shareContent}
        isFooterView={false}
      />
      <div className="main-share-btn" onClick={() => setShareOpen(true)}>
        <div className="inner-share-btn">
          <div>
            <span className="share-profile-icon">
              <Icon name="share-profile" />
            </span>
            <span className="share-text">Share Olibr Profile</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ShareProfileButton;
