import React from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { saveSkillTest } from "../../../service/action/admin/index"
import { useContext } from 'react';
import { MainContext } from '../../../context/MainContext';
import { checkPermission, readOnlyCheck } from '../../../components/functions';

const CreateSkillTest = () => {
    const {user:{access}}=useContext(MainContext)
  const readOnly=readOnlyCheck(access,"Create Skill Test")
    const {
        register: skillRegister,
        handleSubmit: skillSubmit,
        formState: { errors: skillError },
    } = useForm();

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleSubmit = (data) => {
        dispatch(saveSkillTest(navigate, data))
    }
    return (
        <form>
            <div className='container'>
                <div className="row">
                    <div className="form-group col-6">
                        <label className={`floating-label ${skillError?.name && "text-danger"}`}>Title</label>
                        <input
                            type="text"
                            name="name"
                            className={`form-control col-6 ${skillError?.name && "form-required"}`}
                            {
                            ...skillRegister("name", { required: true })
                            }
                        />

                    </div>
                    <div className="form-group col-6">
                        <label className={`floating-label ${skillError?.cuttOffScore && "text-danger"}`}>Cutt-Off Score*</label>
                        <input
                            type="number"
                            name="cuttOffScore"
                            className={`form-control col-6 ${skillError?.title && "form-required"}`}
                            {
                            ...skillRegister("cuttOffScore", { required: true })
                            }
                        />

                    </div>

                    <div className="form-group col-6">
                        <label className={`floating-label ${skillError?.maxScore && "text-danger"}`}>Max Score *</label>
                        <input
                            type="number"
                            name="maxScore"
                            className={`form-control col-6 ${skillError?.maxScore && "form-required"}`}
                            {
                            ...skillRegister("maxScore", { required: true })
                            }
                        />

                    </div>
                    <div className="form-group col-6">
                        <label className={`floating-label ${skillError?.obtainedScore && "text-danger"}`}>Obtained Score *</label>
                        <input
                            type="number"
                            name="obtainedScore"
                            className={`form-control col-6 ${skillError?.obtainedScore && "form-required"}`}
                            {
                            ...skillRegister("obtainedScore", { required: true })
                            }
                        />

                    </div>
                    <div className="form-group col-6">
                        <label className={`floating-label ${skillError?.duration && "text-danger"}`}>Test Time *</label>
                        <input
                            type="number"
                            name="duration"
                            className={`form-control col-6 ${skillError?.duration && "form-required"}`}
                            {
                            ...skillRegister("duration", { required: true })
                            }
                        />

                    </div>

                </div>
                <br />
                <div className="col-12">
                    <div className="bottom-button">
                        <button
                            type="button"
                            onClick={skillSubmit(handleSubmit)}
                            disabled={readOnly}
                            className="submitBtn themeButton">
                            {
                                false ? <ClipLoader size={15} color="#FFFFFF" /> : "Submit"
                            }
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default CreateSkillTest