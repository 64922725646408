import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAddOnListAction, getPlanListsAction, updateAddOnStatusAction } from "../../../../service/action/admin";
import { Fragment } from "react";
import DataTables from "../../../../components/data-tables/DataTables";
import ColumnAddOn from "./ColumnAddsOn";
import { useNavigate } from "react-router-dom";

const AddsOnList = ({setOpenAddOn,setData}) => {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const { addOnList } = useSelector((state) => state.adminReducer);
   const getLists=()=>{
    dispatch(getAddOnListAction(navigate));
   }
 
  useEffect(() => {
    getLists()
  }, []);

  const handleEdit = (e,data) => {
    e.stopPropagation()
    setData(data)
    setOpenAddOn(true)
  };

  const changeStatus = (data) => {
    let tempObj={addonId:data?._id,status:!data?.isActive}
   dispatch(updateAddOnStatusAction(navigate,tempObj,getLists))
  };
  return addOnList && (
    <Fragment>
      <DataTables
        columns={ColumnAddOn(changeStatus,handleEdit)}
        listData={{ data: addOnList }}
        onRowClick={(item) => handleEdit(item)}
      />
    </Fragment>
  );
};

export default AddsOnList;
