import { useState } from "react";
import Skills from "../skills/Skills";
import Stacks from "../stacks/Stacks";
import Tools from "../tools/Tools";
import PendingSkill from "../pending-skill/PendingSkill";

const states = ["tabBtn-active", "tabBtn"];

const AdminTab = () => {
  const handleClick = (index) => setSelectedTab(index);
  const [selectedTab, setSelectedTab] = useState(0);

  const tabArr = ["Skills", "Tech Stacks", "Tools","Pending Skill"];
  const compArr = [<Skills />, <Stacks />, <Tools />,<PendingSkill/>];
  return (
    <div className="main-div-tab">
      <div className="tabContainer">
        {tabArr.map((item, index) => (
          <span
            key={index}
            className={selectedTab == index ? states[0] : states[1]}
            onClick={() => handleClick(index)}
          >
            {item}
          </span>
        ))}
      </div>
      <div>{compArr[selectedTab]}</div>
    </div>
  );
};

export default AdminTab;
