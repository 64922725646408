import React from "react";
import ProjectCard from "./ProjectCards";
import PropTypes from "prop-types";
const AdditionalProjects = ({ project }) => {
  return (
    !!project?.length && (
      <div className="resume-outer">
        <div className="upper-title">
          <h4>Additional Projects</h4>
        </div>
        <div className="additional-projects">
          {project?.map((item, index) => (
            <ProjectCard key={index} project={item} />
          ))}
        </div>
      </div>
    )
  );
};
AdditionalProjects.propTypes = {
  project: PropTypes.object,
};
export default AdditionalProjects;
