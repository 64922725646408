import React from "react";
import "../../view/pricing-page/pricing-page.css";
import {
  formatIndianNumber,
  percentageMinus,
  returnFetureList,
} from "../functions";
import { useContext } from "react";
import { MainContext } from "../../context/MainContext";
import { useDispatch, useSelector } from "react-redux";
import { paymentCheckOuActiont } from "../../service/action/common";
import { useNavigate } from "react-router";
import { olibrToast } from "../toaster";

const PricingBox = ({
  setSelectedPlan,
  selectedPlan,
  pricingData,
  fetureList,
  addOnData = false,
  indexNo,
  allUpgrade = false,
}) => {
  const { user } = useContext(MainContext);
  const { allPlanList } = useSelector((state) => state.commonReducer);
  let countryCode = pricingData?.country?.value || pricingData?.country;
  let billingInterval =
    pricingData?.billingInterval?.value || pricingData?.billingInterval;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const paymentCheckOut = (plan) => {
    if (user?.userType != "Client") {
      navigate("/login");
      return false;
    }
    let obj = { subscriptionPlanId: plan?._id, addons: addOnData };
    const callBackCheckout = (res) => {
      res?.message && olibrToast.success(res?.message);
      if (!pricingData?.name?.toLowerCase()?.includes("free")) {
        localStorage.setItem("checkout", JSON.stringify(res));
        navigate("/payment-overview");
      }
    };
    dispatch(
      paymentCheckOuActiont({ navigate, data: obj, cb: callBackCheckout })
    );
  };

  const buyNow = () => {
    return (
      <button
        type="button"
        className={`btn ${
          user?.userType == "Client" &&
          !pricingData?.BtnText &&
          !allUpgrade &&
          "btn-gray"
        }`}
        disabled={
          user?.userType == "Client" && !pricingData?.BtnText && !allUpgrade
        }
        onClick={() => paymentCheckOut(pricingData)}
      >
        {user?.userType == "Client"
          ? pricingData?.BtnText || (allUpgrade ? "Upgrade" : "Active")
          : "Start 1 Week free trial"}
      </button>
    );
  };

  return (
    <div
      className="card-wrapper pointer"
      onClick={() => setSelectedPlan && setSelectedPlan(pricingData)}
    >
      <div
        className={`pricing-card ${
          selectedPlan?._id == pricingData?._id && "tagged"
        }`}
      >
        {/* tagged */}
        <div className="pricing-header">
          {((allPlanList?.length > 2 && indexNo == 1) ||
            (allPlanList?.length < 3 && indexNo == 0)) && (
            <span className="tag">Recomemended</span>
          )}
          <h3>{pricingData?.name}</h3>
          <h4>
            {countryCode == "IN" ? "₹" : "$"}
            {parseInt(pricingData?.price) &&
            parseInt(pricingData?.discountPercentage) > 0 ? (
              <span>
                {" "}
                {billingInterval != "Annual"
                  ? formatIndianNumber(pricingData?.price)
                  : formatIndianNumber(parseInt(pricingData?.price / 12))}
              </span>
            ) : (
              <span></span>
            )}
            {parseInt(pricingData?.price) == 0 && <span>0</span>}

            {!!parseInt(pricingData?.price) > 0 && (
              <>
                <span className="big">
                  {percentageMinus(
                    pricingData?.price,
                    pricingData?.discountPercentage,
                    billingInterval
                  )}
                </span>
                /Month
              </>
            )}
          </h4>
          <small>{pricingData?.description}</small>
        </div>
        <div className="pricing-body">
          <ul
            dangerouslySetInnerHTML={{
              __html: returnFetureList(pricingData, fetureList),
            }}
          ></ul>
          {buyNow()}
        </div>
      </div>
    </div>
  );
};

export default PricingBox;
