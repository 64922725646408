import React, { Fragment } from "react";
import VideoHeader from "./VideoHeader";
import VideoBody from "./VideoBody";

const Instructions = () => {
  return (
    <Fragment>
      <div className="main-div-developer">
        <div
          className="skills-management"
          style={{ backgroundColor: "#F8FAFF" }}
        >
          <VideoBody />
        </div>
      </div>
    </Fragment>
  );
};

export default Instructions;
