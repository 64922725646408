import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UnSavedProfile from "./../../../assets/img/icons/shortlisted.svg";
import SavedProfile from "./../../../assets/img/icons/shortlisted-selected.svg";
import RejectedProfile from "./../../../assets/img/icons/profile-rejected.svg";
import RescheduleInterview from "./RescheduleInterview";
import { ClipLoader } from "react-spinners";
import UnRejectedProfile from "./../../../assets/img/icons/un-rejected.svg";
import SelectOption from "../../../components/select/SelectOption";
import { ReactComponent as CommentIcon } from "./../../../assets/img/icons/after-comment.svg";
import { ReactComponent as QuestionariesIcon } from "../../../assets/img/icons/message-red-2.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  ClearClientProfileComment,
  acceptInterview,
  addCandidateComments,
  cancelInterview,
  deleteCandidateComments,
  getAllActiveUserAction,
  getAllJobOfClientAction,
  getAllSkillClient,
  getAllStackClient,
  getAppliedForJobAction,
  getShortlistedForJobAction,
  updateCandidateComments,
} from "../../../service/action/client";
import UserAvitar from "./../../../assets/img/company-profile/company-member-1.jpg";
import { updateAppliedJobStatus } from "../../../service/action/client/index";
import Modal from "../../../components/modal/ModalBox";
import ProfilePreviewCommon from "../../../components/profile-preview/profilePreview";
import { Icon } from "../../../components/icon";
import CloseIcon from "../../../assets/img/icons/close-red.svg";
import CommentsC from "./candidates/CommentsC";
import ClientViewQuestion from "../../../components/questionaries/ClientViewQuestion";
import { checkExistsAnswer, countryFlag } from "../../../components/functions";
import { clearResume } from "../../../service/action/developer";
import moment from "moment";
import { olibrToast } from "../../../components/toaster";
import { MainContext } from "../../../context/MainContext";

const CandidateList = ({ idClient = undefined, jobIdAdmin = null }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clientIdent, setClientIdent] = useState(undefined);
  const {
    user: { clientCurrentRole, email },
  } = useContext(MainContext);
  const [isOpen, setOpen] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const { clientId } = useParams();
  useEffect(() => {
    !!clientId && setClientIdent(clientId);
  }, [clientId]);
  const [selectedUser, setSelectedUser] = useState();

  useEffect(() => {
    if (!!jobIdAdmin) {
      setOpen(jobIdAdmin);
      setActiveTab(0);
    }
  }, [jobIdAdmin]);

  useEffect(() => {
    setClientIdent(idClient);
  }, [idClient]);
  const { jobId } = useParams();
  const [candidates, setCandidates] = useState([]);
  const [rescheduleModalView, setRescheduleModalView] = useState(false);
  const [acceptModalIntDet, setAcceptModalIntDet] = useState(null);
  const [selectSlot, setSelectSlot] = useState(null);
  const [acceptModal, setAcceptModal] = useState(false);
  const [cancelMessage, setCancelMessage] = useState(null);
  const [cancelModalView, setCancelModalView] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const { clientSkillList, clientStackList, allAppliedJob, allActiveUser } =
    useSelector((state) => state.clientReducer);
  const clientStoreState = useSelector((state) => state.clientReducer);
  const rejectedReason = [
    {
      label: "Job closed.",
      value: "Job closed.",
    },
    {
      label: "other",
      value: "other",
    },
  ];

  const { socket } = useSelector((state) => state.commonReducer);

  const [devProfile, setDevProfile] = useState({
    modal: false,
    devId: null,
    jobStatus: null,
  });

  const [question, setQuestion] = useState([]);
  const [interviewDetailsModalView, setInterviewDetailsModalView] =
    useState(false);
  const handleOpen = (openId) => {
    isOpen === openId ? setOpen("") : setOpen(openId);
  };

  useEffect(() => {
    if (!isOpen) {
      jobId ? setOpen(jobId) : setOpen(Object.keys(allAppliedJob)?.[0]);
    }
  }, [allAppliedJob, jobId]);

  const getAppliedCandidateList = () => {
    if (jobId) {
      dispatch(getAppliedForJobAction(jobId, navigate));
      dispatch(getShortlistedForJobAction(jobId, navigate));
    } else {
      dispatch(getAllJobOfClientAction(navigate, clientIdent));
    }
  };

  const handleCloseDevProfile = () => {
    dispatch(clearResume());
    setDevProfile({ devId: null, modal: false, jobStatus: null });
  };

  const onSlotAcceptingDone = () => {
    setAcceptModal(false);
  };

  const saveSlot = () => {
    if (selectSlot) {
      dispatch(
        acceptInterview(
          navigate,
          { ...acceptModalIntDet, ...selectSlot },
          onSlotAcceptingDone,
          socket
        )
      );
    } else {
      olibrToast.error("Please select slot.");
    }
  };

  const onInterviewSchedule = (jobData) => {
    navigate("/client/scheduleInterview", {
      state: {
        jobData,
        clientSkillList,
        clientStackList,
      },
    });
  };

  const handleOpenDevProfile = (Id, status, jobId) => {
    setDevProfile({ devId: Id, modal: true, jobStatus: status, jobId });
  };

  const [rejectedDetails, setRjectDetails] = useState({
    modal: false,
    data: "",
  });

  const [commentDetails, setCommentDetails] = useState({
    modal: false,
    data: "",
  });

  const [comments, setComments] = useState({
    current: "",
    _id: "",
    appliedId: "",
    all: [],
  });

  const [feedBack, setFeedBack] = useState("");
  const closeRejectModal = () => {
    setRjectDetails({ modal: false, data: "" });
    setFeedBack("");
  };

  const closeCommentModal = () => {
    setCommentDetails({ modal: false, data: "" });
  };
  const rejectWithoutFeedback = () => {
    dispatch(
      updateAppliedJobStatus(
        navigate,
        getAppliedCandidateList,
        rejectedDetails?.data,
        socket,
        closeRejectModal
      )
    );
  };

  const updateMessageArr = () => {
    let items = comments?.all?.filter((item) => item?._id == comments?._id);
    let indexs = comments?.all?.findIndex((item) => item?._id == comments?._id);
    items[0].message = comments?.current;
    comments.all[indexs] = items[0];
    setComments(comments);
    dispatch(getAllJobOfClientAction(navigate, clientIdent));
  };

  const updateComment = () => {
    let Obj = {
      _id: comments?._id,
      message: comments?.current,
    };
    dispatch(
      updateCandidateComments(
        navigate,
        comments?.appliedId,
        Obj,
        updateMessageArr
      )
    );
  };

  const addElementArr = (element) => {
    setComments({ ...comments, all: element, current: "" });
    dispatch(getAllJobOfClientAction(navigate, clientIdent));
  };

  const addComments = () => {
    let Obj = {
      comment: comments?.current,
    };
    dispatch(
      addCandidateComments(navigate, comments?.appliedId, Obj, addElementArr)
    );
  };

  const removeFromArr = (elements) => {
    setComments({ ...comments, all: elements, _id: "", current: "" });
  };

  const deleteComment = (e, _id) => {
    e.stopPropagation();
    dispatch(
      deleteCandidateComments(
        navigate,
        comments?.appliedId,
        { _id },
        removeFromArr
      )
    );
  };
  const feedback = () => (
    <>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label className="floating-label">Message (optional)</label>

            <textarea
              type="text"
              rows={5}
              className="form-control"
              value={feedBack}
              onChange={(e) => setFeedBack(e.target.value)}
            />
            <div className="row">
              <div className="col-12 text-right">
                {feedBack && (
                  <img
                    src={CloseIcon}
                    onClick={clearClientJobComments}
                    className="clear-feedback"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="bottom-button">
        <button
          className="cancelBtn"
          onClick={
            rejectedDetails?.data?.status != "rejected"
              ? closeRejectModal
              : rejectWithoutFeedback
          }
        >
          {rejectedDetails?.data?.status != "rejected"
            ? "CANCEL"
            : "REJECT WITHOUT FEEDBACK"}
        </button>

        <button className="submitBtn" onClick={handlerejectProfile}>
          {rejectedDetails?.data?.status != "rejected"
            ? "UNREJECT"
            : "REJECT WITH FEEDBACK"}
        </button>
      </div>
    </>
  );

  const comment = () => (
    <CommentsC
      commentsList={comments?.all}
      comments={comments}
      setComments={setComments}
      updateComment={updateComment}
      addComments={addComments}
      deleteComment={deleteComment}
    />
  );
  const handlerejectProfile = () => {
    rejectedDetails.data.feedBack = feedBack;
    dispatch(
      updateAppliedJobStatus(
        navigate,
        getAppliedCandidateList,
        rejectedDetails.data,
        socket,
        closeRejectModal
      )
    );
  };
  const handleStatusChange = (data) => {
    if (data?.status == "rejected") {
      setFeedBack(
        "Thank you for your application. While your qualifications are notable, we've decided to pursue a different direction at this time."
      );
      setRjectDetails({
        modal: true,
        data,
      });
    } else {
      dispatch(
        updateAppliedJobStatus(navigate, getAppliedCandidateList, data, socket)
      );
    }
  };

  const handleShowComment = (data) => {
    setComments({
      current: "",
      appliedId: data?._id,
      all: data?.jobProfileComment,
    });
    setCommentDetails({
      modal: true,
      data: { ...data, comment: data?.jobProfileComment },
    });
  };

  const clearClientJobComments = () => {
    dispatch(
      ClearClientProfileComment(
        navigate,
        rejectedDetails?.data?._id,
        setFeedBack,
        getAppliedCandidateList
      )
    );
  };
  useEffect(() => {
    getAppliedCandidateList();
    dispatch(getAllSkillClient(navigate, "profile"));
    dispatch(getAllStackClient(navigate, ""));
  }, [navigate, dispatch, clientIdent]);

  useEffect(() => {
    if (activeTab == 0) {
      setCandidates(allAppliedJob?.[isOpen]?.appliedCandidate);
    } else if (activeTab == 1) {
      const shorlisted = allAppliedJob?.[isOpen]?.appliedCandidate?.filter(
        (item) => item.status === "shortlisted"
      );
      setCandidates(shorlisted);
    } else if (activeTab == 2) {
      const rejected = allAppliedJob?.[isOpen]?.appliedCandidate?.filter(
        (item) => item.status === "rejected"
      );
      setCandidates(rejected);
    } else if (activeTab == 3) {
      const pendingReview = allAppliedJob?.[isOpen]?.appliedCandidate?.filter(
        (item) => item.status === "pending"
      );
      setCandidates(pendingReview);
    }
  }, [allAppliedJob, activeTab, isOpen]);

  const renderCancelInterviewModalContent = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <div style={{ width: "100%" }}>
            <SelectOption
              optionArray={rejectedReason}
              state={selectedOption}
              closeMenuOnSelect={true}
              placeholder={"Select reason of reject"}
              onChangeFunction={(e) => {
                setSelectedOption(e);
                if (e.value !== "other") {
                  setCancelMessage(e.value);
                } else {
                  setCancelMessage(null);
                }
              }}
            />
            {selectedOption?.value === "other" && (
              <textarea
                value={cancelMessage}
                placeholder="Enter reasion of reject"
                onChange={(e) => setCancelMessage(e.target.value)}
                style={{ marginTop: "15px" }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            marginTop: "25px",
            gap: "20px",
            flexDirection: "row",
          }}
        >
          <a
            className="cancelBtn"
            style={{
              background: "#3ba0aa",
              color: "white",
              border: "none",
              margin: "0px",
            }}
            onClick={() => {
              if (cancelMessage) {
                dispatch(
                  cancelInterview(
                    acceptModalIntDet._id,
                    navigate,
                    {
                      ...acceptModalIntDet,
                      message: cancelMessage,
                    },
                    socket
                  )
                );
                setCancelModalView(false);
              } else {
                olibrToast.error("Reason of reject is required.");
              }
            }}
          >
            Done
          </a>
        </div>
      </>
    );
  };

  const renderAcceptModalContent = () => {
    if (acceptModalIntDet)
      return (
        <Fragment>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "5px",
              flexDirection: "column",
            }}
          >
            {acceptModalIntDet?.slots?.map((dt, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "12px 8px",
                    // border: `2px solid ${
                    //   selectSlot && index === selectSlot.index
                    //     ? "#3BA0AA"
                    //     : "#e0e0e0"
                    // }`,
                    border: "1px solid #e0e0e0",
                    borderRadius: "5px",
                    width: "100%",
                    marginTop: "15px",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  {`${new Date(dt?.from).toDateString()} ${moment(
                    dt.from
                  ).format("h:mm a")} to ${moment(dt?.to).format("h:mm a")}`}
                  {/* <AiOutlineCalendar style={{ fontSize: "22px" }} /> */}
                  <input
                    type="radio"
                    className="modal-radio"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectSlot({ ...acceptModalIntDet, index });
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginTop: "25px",
              gap: "10px",
            }}
          >
            <a
              className="cancelBtn"
              style={{
                background: "#f15d3b",
                color: "white",
                border: "none",
                margin: "0px",
              }}
              onClick={() => {
                setAcceptModal(false);
                setCancelModalView(true);
              }}
            >
              Cancel
            </a>
            <a
              className="cancelBtn"
              style={{
                background: "#3ba0aa",
                color: "white",
                border: "none",
                margin: "0px",
              }}
              onClick={() => {
                setAcceptModal(false);
                setRescheduleModalView({
                  modalStatus: true,
                  interviewDetails: acceptModalIntDet,
                });
              }}
            >
              Reschedule
            </a>
            {acceptModalIntDet?.interviewData?.requestedBy && (
              <a
                className="cancelBtn"
                style={{
                  background: "#3ba0aa",
                  color: "white",
                  border: "none",
                  margin: "0px",
                }}
                onClick={() => {
                  saveSlot();
                }}
              >
                {clientStoreState.isInterviewSlotLoading ? (
                  <ClipLoader size={12} color="#ffffff" />
                ) : (
                  "Accept"
                )}
              </a>
            )}
          </div>
        </Fragment>
      );
  };

  const handleRejected = (jobData) => {
    let tempObj = {
      _id: jobData._id,
      devId: jobData?.devId?._id,
      status: jobData.status === "rejected" ? "pending" : "rejected",
    };

    if (jobData.status === "rejected" && jobData?.jobProfileFeedBack) {
      setFeedBack(jobData?.jobProfileFeedBack);
      setRjectDetails({
        modal: true,
        data: tempObj,
      });
    } else {
      handleStatusChange(tempObj);
    }
  };

  const [questionModal, setQuestionModal] = useState(false);
  const openQuestion = (data) => {
    if (data?.length) {
      setQuestion(data);
      setQuestionModal(true);
    }
  };
  const checkIcon = (subject) => {
    let checkIcon =
      clientSkillList.find((item) => item?._id == subject) ||
      clientStackList.find((item) => item?._id == subject);
    return checkIcon?.skillTestIcon || checkIcon?.stackIcon;
  };

  const checkSkillStackIcon = (data) => {
    return data?.map(
      (item, index) =>
        item?.isPassed && (
          <li key={index}>
            <a>
              {item?.subject && (
                <img src={checkIcon(item?.subject)} alt="AWS Icon" />
              )}
              {item?.testName}
            </a>
          </li>
        )
    );
  };

  const getStatusColor = (status) => {
    if (status === "pending") return "#E7B024";
    if (status === "canceled") return "red";
    if (status === "scheduled") return "#3BA0AA";
  };

  const renderRescheduleModalContent = () => {
    return (
      <Fragment>
        <RescheduleInterview
          data={rescheduleModalView?.interviewDetails}
          setRescheduleModalView={setRescheduleModalView}
          title={
            rescheduleModalView?.interviewDetails?.status === "canceled" &&
            rescheduleModalView?.interviewDetails?.cancelledBy ? (
              <span
                style={{
                  fontSize: "20px",
                }}
              >{`Developer has declined the interview due to: ${
                acceptModalIntDet?.interviewData?.message
                  ? acceptModalIntDet?.interviewData?.message
                  : "personal reason"
              }`}</span>
            ) : (
              rescheduleModalView?.interviewDetails?.status === "canceled" && (
                <span
                  style={{
                    fontSize: "20px",
                  }}
                >
                  You have canceled the interview, but you can still reschedule.
                </span>
              )
            )
          }
        />
      </Fragment>
    );
  };

  const renderInterviewDetailsModalContent = () => {
    if (interviewDetailsModalView?.interviewDetails)
      return (
        <>
          <h3>{interviewDetailsModalView?.interviewDetails?.jobTitle}</h3>
          <span className="scheduled-time" style={{ display: "inline-block" }}>
            {interviewDetailsModalView?.interviewDetails?.slots?.map(
              (dt, index) => {
                if (dt?.joinUrl) {
                  return `${new Date(dt?.from).toDateString()} ${moment(
                    dt?.from
                  ).format("h:mm a")} to ${moment(dt?.to).format("h:mm a")}`;
                }
              }
            )}
            <br />
            <h5
              style={{
                fontSize: "15px",
                marginTop: "20px",
                color: getStatusColor(
                  interviewDetailsModalView?.interviewDetails?.status
                ),
                textTransform: "capitalize",
                fontWeight: "400",
              }}
            >
              <strong
                style={{ fontWeight: "600", marginRight: "7px", color: "#000" }}
              >
                Status:{" "}
              </strong>
              {interviewDetailsModalView?.interviewDetails?.status}
            </h5>
          </span>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "25px",
            }}
          >
            <a
              className="cancelBtn"
              style={{ background: "#f15d3b", color: "white", border: "none" }}
              onClick={() => {
                dispatch(
                  cancelInterview(
                    interviewDetailsModalView?.interviewDetails._id,
                    navigate,
                    interviewDetailsModalView?.interviewDetails,
                    socket
                  )
                );
                setInterviewDetailsModalView(false);
              }}
            >
              Cancel
            </a>
            <a
              className="cancelBtn"
              style={{ background: "orange", color: "white", border: "none" }}
              onClick={() => {
                setRescheduleModalView({
                  modalStatus: true,
                  interviewDetails: interviewDetailsModalView?.interviewDetails,
                });
                setInterviewDetailsModalView(false);
              }}
            >
              Reschedule
            </a>
            <a
              className="cancelBtn"
              style={{ background: "#3ba0aa", color: "white", border: "none" }}
              target="_blank"
              href={
                interviewDetailsModalView?.interviewDetails?.slots?.filter(
                  (itm) => itm.joinUrl
                )?.[0]?.interviewerUrl ||
                interviewDetailsModalView?.interviewDetails?.slots?.filter(
                  (itm) => itm.joinUrl
                )?.[0]?.joinUrl
              }
            >
              Join
            </a>
          </div>
        </>
      );
  };

  const viewStatus = (interview_data) => {
    if (interview_data.status === "canceled") {
      return interview_data.cancelledBy === "developer"
        ? "Declined"
        : "Canceled";
    } else if (interview_data.status === "pending") {
      return interview_data.requestedBy === "developer"
        ? "Reschedule Requested"
        : "Pending";
    }
  };

  const onHandleUserSelection = (user) => {
    setSelectedUser(user);
  };

  useEffect(() => {
    email &&
      dispatch(getAllActiveUserAction(navigate, email?.split("@")?.pop()));
  }, [email]);

  useEffect(() => {
    if (selectedUser?.value || clientId) {
      dispatch(
        getAllJobOfClientAction(navigate, clientId || selectedUser?.value)
      );
    }
  }, [selectedUser, navigate, dispatch, clientId]);

  useEffect(() => {
    if (clientCurrentRole === "admin") {
      dispatch(getAllJobOfClientAction(navigate, "all"));
    }
  }, [clientCurrentRole, dispatch, navigate]);

  const checkAction = (jobData) => {
    if (jobData?.isInterviewDisable) {
      olibrToast.error("Please Upgrade Plan");
      return true;
    } else {
      return false;
    }
  };
  return (
    <Fragment>
      <Modal
        open={questionModal}
        hasHeader={false}
        content={() => (
          <ClientViewQuestion
            question={question}
            setQuestionModal={setQuestionModal}
          />
        )}
        isCancelAble={false}
        size="60%"
        contentClass="questionaries-header"
        isFooterView={false}
      />
      <Modal
        open={devProfile?.modal}
        title={"Developer Profile"}
        size={"100%"}
        onClose={handleCloseDevProfile}
        isFooterView={false}
        content={() =>
          devProfile?.devId && (
            <ProfilePreviewCommon
              userId={devProfile?.devId}
              jobStatus={devProfile?.jobStatus}
              jobId={devProfile?.jobId || false}
              onClose={handleCloseDevProfile}
              profileStatus={false}
            />
          )
        }
      />
      <Modal
        title={"Feedback"}
        open={rejectedDetails?.modal}
        onClose={closeRejectModal}
        content={() => feedback()}
        buttonTitle="Send"
        isFooterView={false}
      />
      <Modal
        title={"Add Comments (Internal Only)"}
        open={commentDetails?.modal}
        onClose={closeCommentModal}
        content={() => comment()}
        buttonTitle="Send"
        isFooterView={false}
      />
      <Modal
        open={rescheduleModalView?.modalStatus}
        title={"Interview Details"}
        size={"50%"}
        onClose={() => setRescheduleModalView(false)}
        onCancel={() => setRescheduleModalView(false)}
        onDone={() => {}}
        isFooterView={false}
        content={() => renderRescheduleModalContent()}
      />
      <Modal
        open={interviewDetailsModalView?.modalStatus}
        title={"Interview Details"}
        size={"50%"}
        onClose={() => setInterviewDetailsModalView(false)}
        onCancel={() => setInterviewDetailsModalView(false)}
        onDone={() => {}}
        isFooterView={false}
        content={() => renderInterviewDetailsModalContent()}
      />
      <Modal
        open={acceptModal}
        title={"Interview Details"}
        size={"50%"}
        onCancel={() => setAcceptModal(false)}
        onClose={() => setAcceptModal(false)}
        onDone={() => {}}
        isFooterView={false}
        content={() => renderAcceptModalContent()}
      />
      <Modal
        open={cancelModalView}
        title={"Cancel Interview"}
        size={"50%"}
        onClose={() => setCancelModalView(false)}
        onCancel={() => setCancelModalView(false)}
        onDone={() => {}}
        isFooterView={false}
        content={() => renderCancelInterviewModalContent()}
      />
      <div className="row" id="candidate">
        <div className="col-12">
          <div
            className={`dash-block shortlisted-wrap ${
              jobIdAdmin && "padding-unset"
            }`}
          >
            <div className="card">
              {!clientIdent && (
                <div className="card-header job-head">
                  <h4>job detail</h4>
                  {clientCurrentRole == "admin" && (
                    <div className="user-selection-box">
                      <SelectOption
                        optionArray={[
                          { _id: "all", email: "All" },
                          ...allActiveUser,
                        ]?.map((item) => ({
                          value: item?._id,
                          label: item?.email,
                        }))}
                        closeMenuOnSelect={true}
                        state={
                          selectedUser
                            ? selectedUser
                            : { value: "all", label: "All" }
                        }
                        placeholder={"Select user"}
                        onChangeFunction={onHandleUserSelection}
                        themeStyle={true}
                      />
                    </div>
                  )}
                  <div className="header-right">
                    <a
                      className="link"
                      onClick={() => navigate("/client/add-job")}
                    >
                      Add New Job
                    </a>
                  </div>
                </div>
              )}
              {Object.keys(allAppliedJob)?.map(
                (item) =>
                  (jobIdAdmin ? item == jobIdAdmin : true) && (
                    <div className="toggle-list">
                      <div className="toggle-item">
                        <h3>
                          <a onClick={() => handleOpen(item)}>
                            <span className="candi-icon">
                              <span>
                                <Icon name="candi-icon" />
                              </span>
                              {allAppliedJob[item]?.jobTitle}
                            </span>{" "}
                            <i>{isOpen == item ? "-" : "+"}</i>
                          </a>
                        </h3>
                        {item === isOpen && (
                          <div className="toggle-content">
                            <div className="tabs">
                              <ul className="nav">
                                {[
                                  "All",
                                  "Shortlisted",
                                  "Rejected",
                                  "Pending Review",
                                ]?.map((item, index) => (
                                  <li>
                                    <a
                                      className={
                                        index === activeTab ? "active" : ""
                                      }
                                      onClick={() => setActiveTab(index)}
                                    >
                                      {item}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                              <div className="tab-details">
                                {candidates?.map((jobData, index) => (
                                  <div className="row" key={index}>
                                    <div className="col-12">
                                      <div className="job-category">
                                        <div className="user-img">
                                          <figure>
                                            <img
                                              src={
                                                !!jobData?.devId?.userImage
                                                  ? jobData?.devId?.userImage
                                                  : UserAvitar
                                              }
                                              alt="User"
                                            />
                                          </figure>
                                        </div>
                                        <div className="user-details">
                                          <div className="block-title">
                                            <h4>
                                              <span>
                                                <span
                                                  className="fit-content"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    (!clientIdent ||
                                                      jobIdAdmin) &&
                                                      handleOpenDevProfile(
                                                        jobData?.devId?._id,
                                                        jobData?.status,
                                                        jobData?.jobId?._id
                                                      );
                                                  }}
                                                >
                                                  {jobData.devId?.fName +
                                                    " " +
                                                    jobData.devId?.lName}
                                                </span>
                                              </span>
                                              {(!!jobData.devId?.devState ||
                                                !!jobData.devId?.devCity) && (
                                                <p className="user-location">
                                                  <span>
                                                    <img
                                                      src={countryFlag(
                                                        jobData.devId?.region
                                                      )}
                                                      height="13px"
                                                    />
                                                  </span>
                                                  {jobData.devId?.devCity},{" "}
                                                  {jobData.devId?.devState}
                                                </p>
                                              )}
                                              <small>
                                                {jobData.devId?.devDesg}
                                              </small>
                                            </h4>
                                          </div>
                                          <ul className="icon-tags">
                                            {checkSkillStackIcon(
                                              jobData?.devId?.devTechTestResult
                                            )}
                                          </ul>
                                          <div className="row">
                                            <div className="col-5">
                                              <div className="availablity">
                                                Available Now{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className={`shortlists  jobProfileFeedBack`}
                                        >
                                          {!clientIdent && (
                                            <span
                                              className="ico-group rejected"
                                              data-tooltip={
                                                jobData?.interviewData?.status
                                                  ? viewStatus(
                                                      jobData?.interviewData
                                                    )
                                                  : "Schedule Interview"
                                              }
                                              onClick={() => {
                                                if (
                                                  checkAction(
                                                    allAppliedJob[item]
                                                  )
                                                ) {
                                                  return false;
                                                }
                                                if (
                                                  jobData?.interviewData
                                                    ?.status === "scheduled"
                                                ) {
                                                  setInterviewDetailsModalView({
                                                    modalStatus: true,
                                                    interviewDetails: {
                                                      ...jobData?.interviewData,
                                                      _id: jobData?._id,
                                                    },
                                                  });
                                                } else if (
                                                  jobData?.interviewData
                                                    ?.status === "canceled"
                                                ) {
                                                  setRescheduleModalView({
                                                    modalStatus: true,
                                                    interviewDetails: {
                                                      ...jobData?.interviewData,
                                                      _id: jobData?._id,
                                                    },
                                                  });
                                                } else if (
                                                  jobData?.interviewData
                                                    ?.status === "pending"
                                                ) {
                                                  setAcceptModal(true);
                                                  setAcceptModalIntDet({
                                                    ...jobData?.interviewData,
                                                    _id: jobData?._id,
                                                  });
                                                } else {
                                                  onInterviewSchedule(jobData);
                                                }
                                              }}
                                            >
                                              <Icon
                                                name={"calender"}
                                                color={
                                                  jobData?.interviewData
                                                    ?.status === "pending"
                                                    ? "#FDAC36"
                                                    : jobData?.interviewData
                                                        ?.status === "canceled"
                                                    ? "#F15D3B"
                                                    : jobData?.interviewData
                                                        ?.status === "scheduled"
                                                    ? "#3BA0AA"
                                                    : "black"
                                                }
                                              />
                                            </span>
                                          )}
                                          {!clientIdent &&
                                            checkExistsAnswer(
                                              jobData.devAnswer
                                            ) && (
                                              <span
                                                data-tooltip={
                                                  "Questionnaire For Candidates"
                                                }
                                                onClick={() => {
                                                  if (
                                                    checkAction(
                                                      allAppliedJob[item]
                                                    )
                                                  ) {
                                                    return false;
                                                  }
                                                  openQuestion(
                                                    jobData?.devAnswer
                                                  );
                                                }}
                                              >
                                                <QuestionariesIcon />
                                              </span>
                                            )}
                                          {!jobIdAdmin && (
                                            <span
                                              className={`ico-group ${
                                                !jobData?.jobProfileComment
                                                  ?.length &&
                                                "ico-candidate-comment"
                                              } rejected`}
                                              data-tooltip={
                                                jobData?.jobProfileComment
                                                  ?.length
                                                  ? "Edit Comment"
                                                  : "Add Comment"
                                              }
                                              onClick={() => {
                                                if (
                                                  checkAction(
                                                    allAppliedJob[item]
                                                  )
                                                ) {
                                                  return false;
                                                }
                                                handleShowComment(jobData);
                                              }}
                                            >
                                              <CommentIcon />
                                            </span>
                                          )}

                                          {!clientIdent &&
                                            (jobData.status !== "shortlisted" ||
                                              activeTab === 0) && (
                                              <span
                                                className="ico-group rejected"
                                                data-tooltip={
                                                  jobData.status === "rejected"
                                                    ? "Unreject"
                                                    : "Reject"
                                                }
                                                onClick={() => {
                                                  if (
                                                    checkAction(
                                                      allAppliedJob[item]
                                                    )
                                                  ) {
                                                    return false;
                                                  }
                                                  handleRejected(jobData);
                                                }}
                                              >
                                                <img
                                                  src={
                                                    jobData.status ===
                                                    "rejected"
                                                      ? RejectedProfile
                                                      : UnRejectedProfile
                                                  }
                                                  alt="Rejected developer"
                                                />
                                              </span>
                                            )}
                                          {!clientIdent &&
                                            (jobData.status !== "rejected" ||
                                              activeTab === 0) && (
                                              <span
                                                className="ico-group rejected"
                                                data-tooltip={
                                                  jobData.status ===
                                                  "shortlisted"
                                                    ? "Unshortlist"
                                                    : "Shortlist"
                                                }
                                                onClick={() => {
                                                  if (
                                                    checkAction(
                                                      allAppliedJob[item]
                                                    )
                                                  ) {
                                                    return false;
                                                  }
                                                  handleStatusChange({
                                                    _id: jobData._id,
                                                    devId: jobData?.devId?._id,
                                                    status:
                                                      jobData.status ===
                                                      "shortlisted"
                                                        ? "pending"
                                                        : "shortlisted",
                                                  });
                                                }}
                                              >
                                                <img
                                                  src={
                                                    jobData.status ===
                                                    "shortlisted"
                                                      ? SavedProfile
                                                      : UnSavedProfile
                                                  }
                                                  alt="Shortlisted developer"
                                                />
                                              </span>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                <div className="outlet"></div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CandidateList;
