/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate, useHref } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MainContext } from "../../context/MainContext";
import { logout } from "../../service/action/authentication";
import { Icon } from "../../components/icon";
import Warning from "./client/Warning";
import Notification from "./header-icon/notification";
import DevNotice from "./developer/DevNotice";
import SubscriptionBanner from "./client/SubscriptionBanner";

const Header = ({ isCollapsed,setSubscriptionModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const route = useHref();
  const {
    user: { userType ,clientCurrentRole},
    user,
    setUser,
  } = useContext(MainContext);
  const [heading, setHeading] = useState("Welcome To Olibr");
  const { tabTitle } = useSelector((state) => state.developerReducer);
  const { isLogout, userInfo } = useSelector(
    (state) => state?.authenticationReducer
  );
  const { socket } = useSelector((state) => state?.commonReducer);

  const [drop, setDrop] = useState(false);

  useEffect(() => {
    onChangePath();
    profileHeading();
  }, [route]);

  useEffect(() => {
    profileHeading();
  }, [tabTitle]);

  const profileHeading = () => {
    if (route === "/developer/profile") {
      setHeading(tabTitle);
    }
  };

  const onChangePath = () => {
    const activePath = route
      ?.split("/")
      [route?.split("/")?.length - 1].split("-")
      ?.map((path) => path[0]?.toUpperCase() + path?.slice(1));
    if (activePath === "Dashboard") {
      setHeading(
        <Fragment>
          Welcome to the &nbsp;
          <span className="header-banner-span">Olibr Tribe</span>
        </Fragment>
      );
    } else if (route?.includes("/developer/video")) {
      setHeading("Video Instructions");
    } else if (route?.includes("/job-detail")) {
      setHeading("Job Detail");
    } else if (route?.includes("/client/edit-job/")) {
      setHeading("Edit");
    } else if (route?.includes("/client-profile-preview")) {
      setHeading("Developer Profile");
    } else if (route?.includes("/client-management")) {
      setHeading("Client Profile");
    } else if (route?.includes("/shortlisted-profile")) {
      setHeading("Job Requirement");
    } else if (route?.includes("client-jobs")) {
      setHeading("Client Job Detail");
    } else if (route?.includes("admin/job-desc")) {
      setHeading("Job Detail");
    } else if (route?.includes("/edit-blog")) {
      setHeading("Blog Detail");
    } else if (route?.includes("/template-edit")) {
      setHeading("Template");
    } else if (route?.includes("/job-applicants")) {
      setHeading("Posted Jobs");
    } else if (route?.includes("/client/posted-job")) {
      setHeading("Posted Jobs");
    } else if (route?.includes("/search-developer")) {
      setHeading("Search Talent");
    } else if (route?.includes("/settings")) {
      setHeading("Profile");
    } else if (route?.includes("/subscription")) {
      setHeading("Subscription Plan");
    } else if (route?.includes("/client/interviewCalender")) {
      setHeading("Interview Calendar");
    } else if (route?.includes("/client/scheduleInterview")) {
      setHeading("Schedule Interview")
    } else {
      setHeading(activePath.join(" "));
    }
  };

  const userNotification = {
    profileView: [
      {
        viewerName: "Raman Gupta",
        isSeen: false,
        viewedOn: "12-06-2023",
      },
      {
        viewerName: "Virendra Mehra",
        isSeen: true,
        viewedOn: "14-06-2023",
      },
    ],
    shortlisted: [
      {
        shortlisterName: "Krishna Bhanushali",
        isSeen: false,
        shortlistedOn: "15-06-2023",
      },
      {
        shortlisterName: "Neha",
        isSeen: true,
        shortlistedOn: "15-06-2023",
      },
    ],
  };
  return (
    <Fragment>
      {/* <header className="header"> */}
      <header className={`header ${isCollapsed ? "header-collapsed" : ""}`}>
        <h4 className="header-banner-h4"> {heading} </h4>
        {/* {userType == "Client" && <Warning user={user} />} */}
        <div className="header-right">
          {userType == "Client" &&  <SubscriptionBanner setSubscriptionModal={setSubscriptionModal} message={user?.subscriptionNotice}/>}
          {(userType == "Developer" || userType == "Client") && (
            <div>
              <Notification />
            </div>
          )}

          <div
            className={drop ? "dropdown-active" : "dropdown"}
            onClick={() => setDrop(!drop)}
          >
            <a href="#" onClick={(e)=> e.preventDefault()} >
              <Icon name="create-prof" />
            </a>
            <div className="dropdown-content">
              <ul className="popupList">
                <li
                  className="popUpListItem1 pop-flex"
                  onClick={() => {
                    userType === "Developer" &&
                      navigate("/developer/dashboard/settings");
                    userType === "Admin" && navigate("/admin/settings");
                    userType !== "Developer" &&
                      userType !== "Admin" &&
                      navigate("/content-writer/settings");
                  }}
                >
                  <Icon name="setting" />
                  &nbsp;&nbsp;Profile
                </li>
               { (userType === "Client" && clientCurrentRole=="admin") && <li
                  className="popUpListItem1 pop-flex"
                  onClick={() => {
                    userType !== "Developer" &&
                      userType !== "Admin" &&
                      navigate("/client/subscription");
                  }}
                >
                  <Icon name="subscription" />
                  &nbsp;&nbsp;Subscription
                </li>}
                <li
                  className="popUpListItem2 pop-flex"
                  onClick={() => {
                    !isLogout &&
                      dispatch(logout(navigate, setUser, socket, user?._id));
                  }}
                >
                  <Icon name="logout" />
                  &nbsp;&nbsp;Logout
                </li>
              </ul>
            </div>
          </div>
        </div>
        {userType == "Client" && <Warning user={user} />}
        {userType == "Developer" && user?.closeProfileNotice && <DevNotice />}
      </header>
    </Fragment>
  );
};

export default Header;
