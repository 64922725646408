import React from 'react'
import { Icon } from '../../../../components/icon'
const TableHeader = ({headerTitle,titleClick,onClick,title,tableLists}) => {
  return (
    <div className="main-div-developer" style={{height:"auto"}}>
    <div className="main-div main-div-addition" style={{height:"auto"}}>
       
      <div className="blog-list" style={{ display: "block" }}>
        <div className="block-header flex-space-between-row">
          <h4 className="">{headerTitle}</h4>

          <div className="flex-row-item">
            <span>
              <a
                className="subQues"
                href="#-"
                onClick={titleClick}
              >
                {title}
              </a>
            </span>
            <span
            onClick={onClick}
            >
              <Icon name="add-icon" />
            </span>
          </div>
        </div>
      </div>
      {tableLists}
    </div>
  </div>
  )
}

export default TableHeader