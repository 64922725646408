import React, { Fragment, useState } from "react";
import ProfileApproval from "./ProfileApproval";
import EnglishTestClear from "./EnglishTestClear";
import SkillTestClear from "./SkillTestClear";
import EmailVerification from "./EmailVerification";
import SelectOption from "../../../components/select/SelectOption";
import ProfileUnapproval from "./ProfileUnapproval";
import { useContext } from "react";
import { MainContext } from "../../../context/MainContext";
import { readOnlyCheck } from "../../../components/functions";

const DevVerification = () => {
  const { user: { access } } = useContext(MainContext)
  const readOnly = readOnlyCheck(access, "Direct Update")
  const [dropDown, setDropDown] = useState("verify");

  const directUpdateType = [
    {
      value: "verify",
      label: "Email Verify",
    },
    {
      value: "approve",
      label: "Profile Approve",
    },
    {
      value: "unapprove",
      label: "Profile Unapprove",
    },
    {
      value: "skill",
      label: "Skill Test Clear",
    },
    {
      value: "english",
      label: "English Test Clear",
    },
  ];

  const handleDropdwon = () => {
    return (
      <form>
        <div >
          <SelectOption
            optionArray={directUpdateType}
            state={directUpdateType?.find((item) => item?.value == dropDown)}
            onChangeFunction={(e) => {
              setDropDown(e?.value);
            }}
          />
        </div>
      </form>
    );
  };

  return (
    <Fragment>
      {dropDown === "verify" && (
        <EmailVerification readOnly={readOnly} HandleDropdwon={handleDropdwon} />
      )}
      {dropDown === "english" && (
        <EnglishTestClear readOnly={readOnly} HandleDropdwon={handleDropdwon} />
      )}
      {dropDown === "approve" && (
        <ProfileApproval readOnly={readOnly} HandleDropdwon={handleDropdwon} />
      )}
      {dropDown === "unapprove" && (
        <ProfileUnapproval readOnly={readOnly} HandleDropdwon={handleDropdwon} />
      )}
      {dropDown === "skill" && (
        <SkillTestClear readOnly={readOnly} HandleDropdwon={handleDropdwon} />
      )}
    </Fragment>
  );
};

export default DevVerification;
