import Select, { StylesConfig } from "react-select";
import React, { Fragment } from "react";
import { useEffect } from "react";
import CustomIndicatorSeparator from "./CustomIndicatorSeparator";

//prop DOC
//optionArray -- optionArray must be in [{value: "", label: ""}] object elements.
//<fieldProp> must be passed when react-hook-form is used, else simply use a useState hook to SELECT.
//if react-hook-form is used, then in values passed getValue() else pass state variable.

const SelectOption = ({
  fieldProp,
  state,
  setState,
  getValue,
  getValueName,
  fieldName = "",
  optionArray,
  isCheckBoxes = false,
  isMulti = false,
  closeMenuOnSelect = false,
  searchable = true,
  maxMenuHeight = 300,
  menuPlacement = "bottom",
  onChangeFunction,
  menuPortalTarget = undefined,
  disabled = false,
  isClearable = false,
  placeholder,
  onClick,
  setValue,
  className = "",
  isTranshparent = false,
  themeStyle=false,
  borderGray=false,
  onFocus=false,
  borderError=false
}) => {
  const fieldChange = (field, event) => {
    field.onChange(event);
  };
  let themeConst = (theme) => ({
    ...theme,
    borderRadius: 5,
    colors: {
      ...theme.colors,
      primary: "#9ccfd4",
      primary25: "#D7ECEE",
      primary50: "#C4E2E5",
    },
  });
  const conditionalStyle = (state) => {
    if (isTranshparent) {
      return { 
        backgroundColor: "transparent",
       borderColor:  "transparent", // Set the border color to transparent when focused
       boxShadow: state.isFocused ? "0 0 0 1px transparent" : "none", // Remove the box shadow when focused
       "&:hover": {
         borderColor: "transparent",
         borderBottomColor:"#e0e0e0;" // Set the hover border color to transparent
       },
       borderBottomColor:"#e0e0e0;",
       width:"90%"
      }
    }
    if(borderGray){
      return {
        borderBottom: "1px solid rgba(66,75,84,.2)", 
      }
    }
    if(themeStyle){
      let errorBorder=borderError ? {border:"1px solid red"}:{}
      return {
        borderBottom: "1px solid rgba(66,75,84,.2)", 
        minHeight:"33px",
        height:"33px",
        ...errorBorder
      }
    }
    return {
       borderColor: state.isFocused ? "#C4E2E5" : "grey"       
      }


  }

  const colourStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 999999, width: "100%" }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      ...conditionalStyle(state),
      fontSize: "12px",
      width: "100%"
    }),
    option: (styles) => {
      return ({
        ...styles,
        fontSize: "12px",
        lineHeight: "16px",
      })
    },
    valueLabel: (styles) => {
      return ({
        ...styles,
        fontSize: "12px"
      })
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor:  "#D7ECEE"
        // backgroundColor: data.isRequired ? "#ffdada " : "#D7ECEE",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#174044",
      fontSize: "12px"
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "#3ba0aa",
      ":hover": {
        backgroundColor: "#3ba0aa",
        color: "white",
      },
    }),
  };
  const handleChange = (currentObj) => {
    let tempArr = [...getValue(getValueName)]
    let index = tempArr.findIndex((item) => item?.label == currentObj?.label)
    tempArr[index].isRequired = !currentObj?.isRequired
    setValue(getValueName, tempArr)

  };

  const filterOptions=optionArray?.filter((option) => {
    // Check if the option's label is not in the selectedValue
    let selectedValue=getValueName?getValue(getValueName):state;
    if(selectedValue && !Array.isArray(selectedValue)){
      selectedValue=[selectedValue]
    }
    return selectedValue? !selectedValue?.some((selected) => selected.label === option.label):[];
  });
  return (
    <Fragment>
      <Select
        //optionals
        openMenuOnClick={()=>console.log("Dd")}
        onFocus={onFocus && onFocus}
        isClearable={isClearable}
        menuPortalTarget={menuPortalTarget}
        isDisabled={disabled}
        styles={colourStyles}
        maxMenuHeight={maxMenuHeight}
        isMulti={isMulti}
        isSearchable={searchable}
        closeMenuOnSelect={closeMenuOnSelect}
        menuPlacement={menuPlacement}
        theme={(theme) => themeConst(theme)}
        placeholder={!!placeholder ? <div style={{ marginTop: themeStyle?'-5px':0 }}>{placeholder}</div> : ""}
        //required props..
        value={
          getValueName
            ? (getValue(getValueName)
              ? getValue(getValueName)
              : [])
            : (state ? state : null)
        }
        onMenuOpen={!!onClick && onClick}
        options={filterOptions}
        onChange={(e) => {
          !!fieldProp ? <> {fieldChange(fieldProp, e)}{fieldName && setState(fieldName, "")}</> : onChangeFunction(e);
        }}
        filterOption={(option, inputValue) => {
          if (typeof option?.data.label == 'string') {
            return option?.data.label.toLowerCase().includes(inputValue.toLowerCase());
          }
          return option;
        }}
        className={className}
        getOptionLabel={(option) => (
          <div
          style={{marginTop:themeStyle?"-4px":0}}
          >
            {
              isCheckBoxes && (
                <input
                 
                  type="checkbox"
                  className="react-select-cus-chek"
                  onClick={() => handleChange(option)}
                  checked={option?.isRequired}
                />
              )
            }

            {option.label}
          </div>
        )}
        components={{
          IndicatorSeparator: () => (
            <CustomIndicatorSeparator isTranshparent={isTranshparent} />
          ), 
        }}
      />
    </Fragment>
  );
};

export default SelectOption;
