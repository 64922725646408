import React, { Fragment } from 'react'
import { Icon } from '../../../components/icon'

const ReviewHeader = ({onInfoClick, onAddClick}) => {
  return (
    <Fragment>
        <div className="block-header">
                <h4 className="heading">
                  My Reviews
                  <span onClick={onInfoClick}>
                    <Icon name="info-button" />
                  </span>
                </h4>
                <span onClick={onAddClick}>
                  {" "}
                  <Icon name="add-icon" />{" "}
                </span>
              </div>
    </Fragment>
  )
}

export default ReviewHeader