export const logoutText = "Logout";
export const SpecificCountry = [
  {
    country: "India",
    code: "91",
    iso: "IN",
  },
  {
    country: "United States",
    code: "1",
    iso: "US",
  },
];

export const planTypeConstant=[
  {
    value:"Free",
    label:"Free"
  },
  {
    value:"Monthly",
    label:"Monthly"
  },
  {
    value:"Annual",
    label:"Annual"
  }
]