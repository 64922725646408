import React, { Fragment, useState } from "react";
import { Icon } from "../../../../components/icon";

const SlabCard = ({ setPricingSlab, data, setData }) => {
  const [open, setOpen] = useState("");
  return (
    <Fragment>
      <div className="toggle-list">
        <div className="toggle-item">
          <h3>
            <a
              href="#-"
              //   onClick={() => handleOpen(item)}
              onClick={() => setOpen(data?.addonId)}
            >
              <span className="candi-icon">
                <span>
                  <Icon name="candi-icon" />
                </span>
                {data?.addonName}
              </span>{" "}
              <i>{open == data?.addonId ? "-" : "+"}</i>
            </a>
          </h3>
          {/* {item === isOpen && ( */}
       {data?.addonId==open &&   <div className="toggle-content">
            <div className="tabs">
              <div className="row nav">
                {[
                  "Min Quantity",
                  "Max Quantity",
                  "Price/Assessment",
                  "Edit",
                ]?.map((item, index) => (
                  <div className="col-3">{item}</div>
                ))}
              </div>
              <div className="tab-details">
                {data?.slabList?.map((item, index) => (
                  <div className="row" key={index}>
                    <div className="col-3">{item?.minQuantity}</div>
                    <div className="col-3">{item?.maxQuantity}</div>
                    <div className="col-3">
                      {data?.addonCurrency + " " + item?.pricePerAssessment}
                    </div>
                    <div
                      className="col-3"
                      onClick={() => {
                        setData({ ...item, addOnId: data?.addonId });
                        setPricingSlab(true);
                      }}
                    >
                      <Icon name="edit" />
                    </div>
                  </div>
                ))}
                <div className="outlet"></div>
              </div>
            </div>
          </div>}
          {/* )} */}
        </div>
      </div>
    </Fragment>
  );
};

export default SlabCard;
