import React from 'react'
import { useEffect } from 'react'
import { getSkillCategory } from '../../../service/action/admin'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

const SkillCategory = ({ activeTab, setActiveTab }) => {
    // const navigate = useNavigate();
    // const dispatch = useDispatch();
    // const { skillCategory } = useSelector((state) => state?.adminReducer)
    // useEffect(() => {
    //     dispatch(getSkillCategory(navigate))
    // }, [])


    return (
        <div className='toggle-list'>
            <div className='toggle-item'>
                <div className='toggle-content'>

                    <div class="tabs">
                        <ul className="nav">
                            {["Skill","Stack"]?.map((item, index) => (
                                <li key={index}>
                                    <a
                                        className={
                                            item=== activeTab ? "active" : ""
                                        }
                                        onClick={() => setActiveTab(item)}
                                    >
                                        {item}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SkillCategory