import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../view/authentication/login";
import { AppLayout } from "../view/appLayout";
import HomeDeveloper from "../view/homepage-dev/HomeDeveloper";
import ContactUs from "../view/contact/Contact";
import DevManagement from "../view/admin/dev-management/devManagement";
import TestResult from "../view/admin/test-result/TestResult";
import AdminTab from "../view/admin/skill-management/admin-tabs/AdminTab";
import ClientManagement from "../view/admin/client-management/ClientManagement";
import ClientDetail from "../view/admin/client-management/ClientDetail";
import JobFullDetail from "../view/admin/client-management/JobFullDetail";
import JobListOfClient from "../view/admin/client-management/JobListOfClient";
import BlogListNew from "../view/admin/blog-management/BlogList";
import AddNewBlog from "../view/admin/blog-management/AddNewBlog";
import EditBlog from "../view/admin/blog-management/EditBlog";
import BlogCatList from "../view/admin/blog-management/BlogCatList";
import EmailAutomation from "../view/admin/email-automation/EmailAutomation";
import AddTemplate from "../view/admin/email-automation/AddTemplate";
import EditTemplate from "../view/admin/email-automation/EditTemplate";
import EmailHistory from "../view/admin/email-history/emailHistory";
import ContactUsQueries from "../view/admin/contact-us-queries/ContactUsQueries";
import DevVerification from "../view/admin/verify-dev/DevVerification";
import Settings from "../components/settings/Settings";
import Usermanagement from "../view/admin/user-management/UserManagement";
import JobManagement from "../view/admin/job-management/JobManagement";
import FaqTabs from "../view/admin/faq-management/faq-tabs/FaqTabs";
import TestManagement from "../view/admin/test-management/TestManagement";
import ForgotPassword from "../view/authentication/forgotPassword/forgotPassword";
import ResendEmail from "../view/authentication/resendEmail/resendEmail";
import VerifyEmail from "../view/authentication/verifyEmail/verifyEmail";
import ResetPassword from "../view/authentication/resetPassword/resetPassword";
import ComingSoon from "../view/coming-soon/ComingSoon";
import ErrorPage from "../view/error-page/ErrorPage";
import Sitemap from "../view/admin/sitemap-management/Sitemap";
import CreateSkillTest from "../view/admin/create-skill-test/CreateSkillTest";
import SitemapTypes from "../view/admin/sitemap-management/SitemapTypes";
import ResumeOpen from "../view/open-profile/ResumeOpen";
import SpotlightHistory from "../view/admin/spotlight-history/SpotlightHistory";
import Maintenance from "../view/admin/maintenance/Maintenance";
import MyReview from "../view/developer/review/MyReview";
import SpeakWithDevList from "../view/admin/interview-requests/SpeakWithDevList";
import InterviewList from "../view/admin/interview-list/InterviewList";
import RequestDetail from "../view/admin/interview-requests/RequestDetail";
import BaseComponent from "../view/jobs/new-job/BaseComponent";
import CategoryList from "../view/admin/job-management/category-section/CategoryList";
import { useDispatch } from "react-redux";
import { useContext } from "react";
import { MainContext } from "../context/MainContext";
import CompanyProfile from "../view/company-profile/CompanyProfile";
import PerkBenefit from "../view/admin/perk-and-benefit/PerkBenefit";
import SkillExp from "../view/skill-exp/SkillExplanation";
import UnsubscribeComponent from "../components/unsubscribe";
import ReviewForm from "../view/reviews/ReviewForm";
import Acknowledgement from "../view/reviews/Acknowledgement";
import IntroductoryVideoDeveloper from "../view/developer/introductory-video";
import UserPassword from "../view/authentication/userPassword/userPassword";
import PdfConverter from "../components/profile-preview/custom-pdf/PdfConverter";
// import { setSocketAction } from "../service/action/common";
// import { io } from "socket.io-client";
// import { socket } from "../common/constant/socket";
import OldJobUrl from "../view/jobs/exists-url/OldJobUrl";
import DashboardAdmin from "../view/admin/dashboard/Dashboard";

//interview
import AllInterviews from "../view/client/dashboard/AllInterviews";
import ScheduleInterview from "../view/client/jobs/ScheduleInterview";
import PricingPage from "../view/pricing-page/PricingPage";
import PricingPlan from "../view/admin/pricing-plan/PricingPlan";
import PaymentSuccess from "../components/pricing/status/PaymentSuccess";
import PaymentFail from "../components/pricing/status/PaymentFail";
import PaymentOverview from "../components/pricing/PaymentSheet";

const Router = () => {
  // const dispatch = useDispatch();
  // let [isDev, setDev] = useState(true);
  // const { user } = useContext(MainContext);

  // useEffect(() => {
  //   dispatch(setSocketAction(io(socket())));
  // }, []);

  // useEffect(() => {
  //   user?.userType && setDev(user?.userType === "Developer");
  // }, [user]);

  return (
    <Routes>
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/payment-success" element={<PaymentSuccess />} />
      <Route path="/payment-failed" element={<PaymentFail />} />
      <Route path="/payment-overview" element={<PaymentOverview />} />
      <Route path="/skill-evaluation-toolkit" element={<SkillExp />} />
      <Route
        path="/admin/dashboard"
        element={<AppLayout child={<DashboardAdmin />} />}
      />
      <Route path="/test-pdf" element={<PdfConverter />} />
      <Route
        path="/company-profile/:companyId?/:isOpen?"
        element={<CompanyProfile />}
      />
      <Route path="/user/:userId" element={<UserPassword />} />
      <Route path="/" element={<Login />} />
      <Route path="/developer-homepage" element={<HomeDeveloper />} />
      <Route path="/coming-soon" element={<ComingSoon />} />
      <Route path="/skill-library" element={<SkillExp />} />
      <Route path="/jobs" element={<BaseComponent />} />
      <Route path="/software-developer-jobs-list" element={<OldJobUrl />} />
      <Route
        path="/software-developer-jobs-list/:jobId"
        element={<OldJobUrl />}
      />
      <Route path="/jobs/:jobId" element={<BaseComponent />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/resume/:devId" element={<ResumeOpen />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/resend-verification-email" element={<ResendEmail />} />
      <Route path="/verify-email/:email/:code" element={<VerifyEmail />} />
      <Route path="/reset-password/:email/:code" element={<ResetPassword />} />

      <Route
        path="/client/all-Interviews"
        element={<AppLayout child={<AllInterviews />} />}
      />
      <Route
        path="/client/scheduleInterview"
        element={<AppLayout child={<ScheduleInterview />} />}
      />

      <Route
        path="/developer/review"
        element={<AppLayout child={<MyReview />} />}
      />
      <Route
        path="/developer/video"
        element={<AppLayout child={<IntroductoryVideoDeveloper />} />}
      />
      <Route path="/review/:reviewId/:developerId" element={<ReviewForm />} />
      <Route
        path="/unsubscribe/:emailType/:userId"
        element={<UnsubscribeComponent />}
      />
   
      <Route path="/acknowledgement" element={<Acknowledgement />} />
      <Route
        path="/admin/job-categories"
        element={<AppLayout child={<CategoryList />} />}
      />
      <Route
        path="/admin/dev-management"
        element={<AppLayout child={<DevManagement />} />}
      />
      <Route
        path="/admin/test-result"
        element={<AppLayout child={<TestResult />} />}
      />
      <Route
        path="/admin/interview-request"
        element={<AppLayout child={<SpeakWithDevList />} />}
      />
      <Route
        path="/admin/interview-request/:requestId"
        element={<AppLayout child={<RequestDetail />} />}
      />
      <Route
        path="/admin/interview-list"
        element={<AppLayout child={<InterviewList />} />}
      />
      <Route
        path="/admin/maintenance"
        element={<AppLayout child={<Maintenance />} />}
      />
      <Route
        path="/admin/spotlight-history"
        element={<AppLayout child={<SpotlightHistory />} />}
      />
      <Route
        path="/admin/skill-management"
        element={<AppLayout child={<AdminTab />} />}
      />
      <Route
        path="/admin/client-management"
        element={<AppLayout child={<ClientManagement />} />}
      />
      <Route
        path="/admin/client-management/:clientId"
        element={<AppLayout child={<ClientDetail />} />}
      />
      <Route
        path="/admin/client-jobs/:clientId"
        element={<AppLayout child={<JobListOfClient />} />}
      />
      <Route
        path="/admin/test-result"
        element={<AppLayout child={<TestResult />} />}
      />
      <Route
        path="/admin/skill-management"
        element={<AppLayout child={<AdminTab />} />}
      />
      <Route
        path="/admin/client-management"
        element={<AppLayout child={<ClientManagement />} />}
      />
      <Route
        path="/admin/client-management/:clientId"
        element={<AppLayout child={<ClientDetail />} />}
      />
      <Route
        path="/admin/client-jobs/:clientId"
        element={<AppLayout child={<JobListOfClient />} />}
      />
      <Route
        path="/admin/job-desc/:jobId"
        element={<AppLayout child={<JobFullDetail />} />}
      />
      <Route
        path="/admin/blog"
        element={<AppLayout child={<BlogListNew />} />}
      />
      <Route
        path="/admin/add-blog"
        element={<AppLayout child={<AddNewBlog />} />}
      />
      <Route
        path="/admin/edit-blog/:blogId"
        element={<AppLayout child={<EditBlog />} />}
      />
      <Route
        path="/admin/blog-category-list"
        element={<AppLayout child={<BlogCatList />} />}
      />
      <Route
        path="/admin/email-history"
        element={<AppLayout child={<EmailHistory />} />}
      />
      <Route
        path="/admin/email-management"
        element={<AppLayout child={<EmailAutomation />} />}
      />
      <Route
        path="/admin/template"
        element={<AppLayout child={<AddTemplate />} />}
      />
      <Route
        path="/admin/template-edit/:tempId"
        element={<AppLayout child={<EditTemplate />} />}
      />
      <Route
        path="/admin/contact-us-queries"
        element={<AppLayout child={<ContactUsQueries />} />}
      />
      <Route
        path="/admin/settings"
        element={<AppLayout child={<Settings />} />}
      />
      <Route
        path="/admin/perks-and-benifits"
        element={<AppLayout child={<PerkBenefit />} />}
      />
      <Route
        path="/admin/perks-and-benifits"
        element={<AppLayout child={<PerkBenefit />} />}
      />
      <Route
        path="/admin/direct-update"
        element={<AppLayout child={<DevVerification />} />}
      />
      <Route
        path="/admin/sitemap-management"
        element={<AppLayout child={<Sitemap />} />}
      />
      <Route
        path="/admin/sitemap-types"
        element={<AppLayout child={<SitemapTypes />} />}
      />
      <Route
        path="/admin/create-skill-test"
        element={<AppLayout child={<CreateSkillTest />} />}
      />
      <Route
        path="/admin/user-management"
        element={<AppLayout child={<Usermanagement />} />}
      />
      <Route
        path="/content-writer/jobs"
        element={<AppLayout child={<JobManagement />} />}
      />
      <Route
        path="/content-writer/faqs"
        element={<AppLayout child={<FaqTabs />} />}
      />
      <Route
        path="/content-writer/english-test"
        element={<AppLayout child={<TestManagement />} />}
      />

      <Route
        path="/content-writer/settings"
        element={<AppLayout child={<Settings />} />}
      />
      <Route
        path="/admin/pricing-plan"
        element={<AppLayout child={<PricingPlan />} />}
      />

      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default Router;
