import React from 'react'
import CrossIcon from "../../assets/img/icons/cross.png";
const QuestionariLists = ({ data,clearQuestion}) => {
    return (
        <div className='col-12 questionari-lists'>
            {
                data?.map((itm,index) => !!itm?.questionLists?.some((itm)=>itm?.isAdded) && (
                    <>
                    <p className='heading'>{itm?.questionName}</p>
                    {
                        itm?.questionLists?.map((item,innerIndex)=>item?.isAdded &&(
                            <p className='list'>{item?.question}{item?.isMandatory &&<span className='starText'>*</span>} <span className='crossIcon' onClick={()=>clearQuestion(index,innerIndex)}><img src={CrossIcon}/></span></p>
                        ))
                    }
                    </>
                ))
            }

        </div>
    )
}

export default QuestionariLists