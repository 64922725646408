import React from "react";
import { Fragment } from "react";
import { useState } from "react";
import { Icon } from "../../components/icon";
import ShortEmailIcon from "../../assets/img/icons/short-email.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getInfoForReviewAction,
  getReviewStatusAction,
  giveRatingDevReviewAction,
} from "../../service/action/common";
import { rateParams, ratingName } from "./constant";
import { BarLoader, ClipLoader } from "react-spinners";
import { olibrToast } from "../../components/toaster";
import CannotRate from "./CannotRate";
const ReviewForm = () => {
  const { developerId, reviewId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { devBasicInfo, giveRevLoading, devReviewStatus, isReviewStatusLoad } =
    useSelector((state) => state.commonReducer);

  useEffect(() => {
    if (!!developerId && !!reviewId) {
      dispatch(getReviewStatusAction({ developerId, reviewId }, navigate));
    }
  }, [developerId, reviewId]);

  useEffect(() => {
    if (devReviewStatus === "resent" || devReviewStatus === "pending") {
      dispatch(getInfoForReviewAction(developerId, navigate));
    }
  }, [devReviewStatus]);

  const onSendReview = () => {
    // let atleastOneStar = false;
    let index = Object.keys(ratings)?.findIndex((item) => ratings[item] === 0);
    if (index === -1) {
      dispatch(
        giveRatingDevReviewAction(
          { reviewId, developerId, ratings, comment },
          navigate
        )
      );
    } else {
      olibrToast.error("You have to give atleast one star.");
    }
    console.log({ ratings, comment });
  };

  const [fullStory, setFullStory] = useState(false);
  const [comment, setComment] = useState("");
  const [ratings, setRatings] = useState(rateParams);

  return (
    <Fragment>
      {isReviewStatusLoad ? (
        <div className="loading-div">
          <div className="loading-box">
            <BarLoader width={"80%"} height={"10px"} color="#3ba0aa" />
          </div>
        </div>
      ) : devReviewStatus === "resent" || devReviewStatus === "pending" ? (
        <Fragment>
          <div className="container">
            <div className="row">
              <div className="col-9">
                <div className="new-profile-wrap">
                  <div className="profile-top">
                    <div className="new-profile-img">
                      {
                        <img
                          src={devBasicInfo?.userImage}
                          height={272}
                          width={272}
                          alt="Profile image "
                        />
                      }
                    </div>
                    <div className="new-profile-details">
                      <h5 className="user-name">
                        {devBasicInfo?.fName + " " + devBasicInfo?.lName}
                      </h5>
                      <p className="user-designation">{"Software Developer"}</p>

                      <div className="user-c">
                        <p className="user-location">
                          <span>
                            <img src={ShortEmailIcon} height="13px" />
                          </span>
                          {devBasicInfo?.email}
                        </p>
                      </div>

                      <p className="tellurstory">
                        {fullStory
                          ? devBasicInfo?.devTellUrStory
                          : devBasicInfo?.devTellUrStory?.substring(
                              0,
                              400
                            )}{" "}
                        {devBasicInfo?.devTellUrStory?.length >= 400 ? (
                          <a
                            className="read-more"
                            onClick={() => setFullStory(!fullStory)}
                          >
                            ....{fullStory ? "less" : "more"}
                          </a>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <span className="rate-head">
                        Rate the developer on the following criteria
                      </span>
                      <div className="rating-stars">
                        {Object.keys(ratingName)?.map((item) => (
                          <div className="row">
                            <div className="col-3">
                              <span className="para">{ratingName[item]}</span>
                              <span style={{ color: "red" }}>*</span>
                            </div>
                            <div className="col-8">
                              {[0, 0, 0, 0, 0]?.map((star, index) => (
                                <span
                                  onClick={() =>
                                    setRatings({
                                      ...ratings,
                                      [item]: index + 1,
                                    })
                                  }
                                  className={`fa fa-star fa-lg ${
                                    index < ratings[item]
                                      ? "rating-checked"
                                      : "rating-unchecked"
                                  }`}
                                ></span>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label className="floating-label">
                              {"Comments (Optional)"}
                            </label>
                            <textarea
                              rows={6}
                              name="comment"
                              className="form-control"
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                              placeholder="Type message here...."
                            />
                          </div>
                        </div>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <span className="btn" onClick={onSendReview}>
                          {giveRevLoading ? (
                            <ClipLoader color="white" size={15} />
                          ) : (
                            "Submit"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="aside-div">
                  <aside className="sidebar-right">
                    <div>
                      <Icon name="olibr-helps" />
                    </div>
                    <div className="olibr-help-text">
                      Olibr helps businesses thrive in the remote-first world
                    </div>
                  </aside>
                  <aside className="sidebar-right">
                    <Link to="/sign-up-client">
                      <div className="aside-box">
                        HIRE VETTED DEVELOPERS &#187;
                      </div>
                    </Link>
                    <Link to="/developer-homepage">
                      <div className="aside-box">APPLY For JOB &#187;</div>
                    </Link>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <CannotRate status={devReviewStatus}/>
      )}
    </Fragment>
  );
};

export default ReviewForm;
