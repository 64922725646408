import React from "react";
import { returnDate } from "../functions";
import PropTypes from "prop-types";
const DevAvailble = ({ devData }) => {
  return (
    <>
      {devData?.devAvlFrom ? (
        <p className="margin-bottom-20 notice-period">
          {/* {returnDate(devData?.devAvlFrom) === "Available" && ( */}
            <span>Available From: {returnDate(devData?.devAvlFrom)}</span>
          {/* )} */}
        </p>
      ) : (
        <>
          <span>Notice Period:{" "}{devData?.devNoticePeriod}</span>
        </>
      )}
    </>
  );
};
DevAvailble.propTypes = {
  devData: PropTypes.object,
};
export default DevAvailble;
