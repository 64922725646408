import * as types from "../../action";

const initialState = {
  isLoading: false,

  //IT WILL BE REMVOE AFTER ALL API INTEGRATE
  tempData: {},
  developer: {},
  client: {},
  stack: [],
  skill: [],
  faqGroupLists: {},
  jobListResp: [],
  countryList: [],
  stateList: [],
  cityList: [],
  contactUsPost: {},
  isBlogsLoading: false,
  isBlogDetailLoading: false,
  isBlogCategoriesLoading: false,
  blogsData: [],
  blogsDetails: {},
  blogCategoriesData: [],
  currentBlogCategory: null,
  spotLightedCandidates: [],
  blogCatOpenList: [],
  blogOpenDetail: {},
  blogOpenDetailSlug: {},
  dataForApproval: [],
  isApprovalDataLoading: false,
  jobOpenDetail: {},
  currentJobCategory: undefined,
  devBasicInfo: {},
  jobByCatList: {
    data: [],
  },
  devReviewStatus: "",
  isReviewStatusLoad: false,
  devTestReport: null,
  isTestReportLoading: false,
  getDevSkillTestResultData: {},
  getDevSkillTestResultLoading: false,
  uploadVideoLoad: false,
  notificationData: [],
  notiDeleting: false,
  socket: null,
  base64Image: null,
};

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_OPEN_FAQS_START:
      return { ...state, openFaqsLoading: true, openFaqsData: {} };
    case types.SHOW_OPEN_FAQS_SUCCESS:
      return { ...state, openFaqsLoading: false, openFaqsData: action?.data };
    case types.SHOW_OPEN_FAQS_FAIL:
      return { ...state, openFaqsLoading: false, openFaqsData: {} };

    case types.GET_DEV_SKILL_TEST_RESULT_START:
      return {
        ...state,
        getDevSkillTestResultLoading: true,
        getDevSkillTestResultData: {},
      };
    case types.GET_DEV_SKILL_TEST_RESULT_SUCCESS:
      return {
        ...state,
        getDevSkillTestResultLoading: false,
        getDevSkillTestResultData: action?.data?.data,
      };
    case types.GET_DEV_SKILL_TEST_RESULT_FAIL:
      return {
        ...state,
        getDevSkillTestResultLoading: false,
        getDevSkillTestResultData: {},
      };

    case types.GET_SKILL_BY_CATEGORY_DEV_START:
      return { ...state, getSkillByCatLoading: true, skillByCategoryDev: [] };
    case types.GET_SKILL_BY_CATEGORY_DEV_SUCCESS:
      return {
        ...state,
        getSkillByCatLoading: false,
        skillByCategoryDev: action?.data?.data,
      };
    case types.GET_SKILL_BY_CATEGORY_DEV_FAIL:
      return { ...state, getSkillByCatLoading: false, skillByCategoryDev: [] };

    case types.UPLOAD_VIDEO_WITHOUT_DELETE_START:
      return { ...state, uploadVideoLoad: true, videoTobeSent: "" };
    case types.UPLOAD_VIDEO_WITHOUT_DELETE_SUCCESS:
      console.log(action);
      return {
        ...state,
        uploadVideoLoad: false,
        videoTobeSent: action?.data?.filename,
      };
    case types.UPLOAD_VIDEO_WITHOUT_DELETE_FAIL:
      return { ...state, uploadVideoLoad: false, videoTobeSent: "" };

    case types.UPLOAD_VIDEO_START:
      return { ...state, uploadVideoLoad: true, uploadVideoUrl: "" };
    case types.UPLOAD_VIDEO_SUCCESS:
      return {
        ...state,
        uploadVideoLoad: false,
        uploadVideoUrl: action?.data?.data,
      };
    case types.UPLOAD_VIDEO_FAIL:
      return { ...state, uploadVideoLoad: false, uploadVideoUrl: "" };

    case types.GET_DEV_REVIEW_STATUS_START:
      return { ...state, isReviewStatusLoad: true, devReviewStatus: "" };
    case types.GET_DEV_REVIEW_STATUS_SUCCESS:
      return {
        ...state,
        isReviewStatusLoad: false,
        devReviewStatus: action?.data?.data,
      };
    case types.GET_DEV_REVIEW_STATUS_FAIL:
      return { ...state, isReviewStatusLoad: false, devReviewStatus: "" };

    case types.GET_DEV_INFO_FOR_REVIEW_START:
      return { ...state, isReviewInfoLoad: true, devBasicInfo: {} };
    case types.GET_DEV_INFO_FOR_REVIEW_SUCCESS:
      return {
        ...state,
        isReviewInfoLoad: false,
        devBasicInfo: action?.data?.data,
      };
    case types.GET_DEV_INFO_FOR_REVIEW_FAIL:
      return { ...state, isReviewInfoLoad: false, devBasicInfo: {} };

    case types.GIVE_RATING_DEV_REVIEW_START:
      return { ...state, giveRevLoading: true };
    case types.GIVE_RATING_DEV_REVIEW_SUCCESS:
      return { ...state, giveRevLoading: false };
    case types.GIVE_RATING_DEV_REVIEW_FAIL:
      return { ...state, giveRevLoading: false };

    case types.GET_BLOG_CAT_OPEN_START:
      return { ...state, isLoading: true, blogCatOpenList: [] };
    case types.GET_BLOG_CAT_OPEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        blogCatOpenList: action.data,
        currentBlogCategory:
          state.currentBlogCategory === null
            ? action?.data?.data?.[0]?._id
            : state.currentBlogCategory,
      };
    case types.GET_BLOG_CAT_OPEN_FAIL:
      return { ...state, isLoading: false, blogCatOpenList: [] };

    case types.CURRENT_JOB_CATEGORY:
      return {
        ...state,
        currentJobCategory: action.data,
      };

    case types.GET_JOB_BY_ID_START:
      return { ...state, jobByJobIdLoading: true, jobByIdDetail: {} };
    case types.GET_JOB_BY_ID_SUCCESS:
      return {
        ...state,
        jobByJobIdLoading: false,
        jobByIdDetail: action?.data,
      };
    case types.GET_JOB_BY_ID_FAIL:
      return { ...state, jobByJobIdLoading: false, jobByIdDetail: {} };

    case types.GET_POPULAR_JOB_START:
      return { ...state, popJobLoading: true };
    case types.GET_POPULAR_JOB_SUCCESS:
      return { ...state, popJobLoading: false, popularJobs: action?.data };
    case types.GET_POPULAR_JOB_FAIL:
      return { ...state, popJobLoading: false, popularJobs: [] };

    case types.GET_JOB_BY_CAT_START:
      return { ...state, jobByCatIdLoading: true };
    case types.GET_JOB_BY_CAT_SUCCESS:
      let tempArr = { ...state.jobByCatList };
      if (!!tempArr?.data?.length) {
        let tempArrs = [...tempArr?.data, ...action?.data?.data];
        tempArr.data = tempArrs;
      } else {
        tempArr = action?.data;
      }

      return { ...state, jobByCatIdLoading: false, jobByCatList: tempArr };
    case types.GET_JOB_BY_CAT_FAIL:
      return { ...state, jobByCatIdLoading: false, jobByCatList: [] };

    case types.GET_JOB_BY_CAT_FILTER_SUCCESS:
      return { ...state, jobByCatIdLoading: false, jobByCatList: action?.data };

    case types.GET_JOB_BY_ID_LIST_START:
      return { ...state, jobByIdLoading: true, jobOpenDetail: {} };
    case types.GET_JOB_BY_ID_LIST_SUCCESS:
      return { ...state, jobByIdLoading: false, jobOpenDetail: action?.data };
    case types.GET_JOB_BY_ID_LIST_FAIL:
      return { ...state, jobByIdLoading: false, jobOpenDetail: {} };

    case types.GET_BLOG_BY_ID_OPEN_START:
      return { ...state, blogByIdLoading: true, blogOpenDetail: {} };
    case types.GET_BLOG_BY_ID_OPEN_SUCCESS:
      return { ...state, blogByIdLoading: false, blogOpenDetail: action.data };
    case types.GET_BLOG_BY_ID_OPEN_FAIL:
      return { ...state, blogByIdLoading: false, blogOpenDetail: {} };

    case types.GET_BLOG_BY_SLUG_OPEN_START:
      return { ...state, blogBySlugLoading: true, blogOpenDetailSlug: {} };
    case types.GET_BLOG_BY_SLUG_OPEN_SUCCESS:
      return {
        ...state,
        blogBySlugLoading: false,
        blogOpenDetailSlug: action.data,
      };
    case types.GET_BLOG_BY_SLUG_OPEN_FAIL:
      return { ...state, blogBySlugLoading: false, blogOpenDetailSlug: {} };

    case types.GET_BLOG_OPEN_START:
      return { ...state, isLoading: true, blogOpenList: [] };
    case types.GET_BLOG_OPEN_SUCCESS:
      return { ...state, isLoading: false, blogOpenList: action.data };
    case types.GET_BLOG_OPEN_FAIL:
      return { ...state, isLoading: false, blogOpenList: [] };

    case types.GET_FAQ_LISTS_START:
      return { ...state, isLoading: true, devFaq: {} };
    case types.GET_FAQ_LISTS_SUCCESS:
      return { ...state, isLoading: false, devFaq: action.payload };
    case types.GET_FAQ_LISTS_FAIL:
      return { ...state, isLoading: false };

    case types.GET_COUNTRY_START:
      return { ...state, isLoading: true, countryList: [] };
    case types.GET_COUNTRY_SUCCESS:
      return { ...state, isLoading: false, countryList: action?.data?.data };
    case types.GET_COUNTRY_FAIL:
      return { ...state, isLoading: false };

    case types.GET_STATE_START:
      return { ...state, isLoading: true, stateList: [] };
    case types.GET_STATE_SUCCESS:
      return { ...state, isLoading: false, stateList: action?.data?.data };
    case types.GET_STATE_FAIL:
      return { ...state, isLoading: false };

    case types.GET_CITY_START:
      return { ...state, isLoading: true, cityList: [] };
    case types.GET_CITY_SUCCESS:
      return { ...state, isLoading: false, cityList: action?.data?.data };
    case types.GET_CITY_FAIL:
      return { ...state, isLoading: false };

    case types.CHANGE_PASSWORD_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.CHANGE_PASSWORD_SUCCESS:
      return { ...state, isLoading: false, tempData: action.payload };
    case types.CHANGE_PASSWORD_FAIL:
      return { ...state, isLoading: false };

    case types.CHANGE_EMAIL_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.CHANGE_EMAIL_SUCCESS:
      return { ...state, isLoading: false, tempData: action.payload };
    case types.CHANGE_EMAIL_FAIL:
      return { ...state, isLoading: false };

    case types.GET_APPROVAL_UPDATE_START:
      return { ...state, isApprovalDataLoading: true };
    case types.GET_APPROVAL_UPDATE_SUCCESS:
      return {
        ...state,
        dataForApproval: action?.data?.data,
        isApprovalDataLoading: false,
      };
    case types.GET_APPROVAL_UPDATE_FAIL:
      return { ...state, isApprovalDataLoading: false, dataForApproval: [] };

    case types.GET_ALL_FAQ_GROUP_START:
      return { ...state, isLoading: true };
    case types.GET_ALL_FAQ_GROUP_SUCCESS:
      if (action.data.faqFor === "Client") {
        state.client = action.data;
      }
      if (action.data.faqFor === "Developer") {
        state.developer = action.data;
      }
      if (action.data.faqFor === "Pricing") {
        state.pricing = action.data;
      }
      return {
        ...state,
        isLoading: false,
        faqGroupLists: {
          developer: state.developer,
          client: state.client,
          pricing: state.pricing,
        },
      };

    case types.GET_ALL_FAQ_GROUP_FAIL:
      return { ...state, isLoading: false };

    case types.GET_JOB_LIST_START:
      return { ...state, isLoading: true, jobListResp: [] };
    case types.GET_JOB_LIST_SUCCESS:
      return { ...state, isLoading: false, jobListResp: action.data };

    case types.GET_JOB_LIST_FAIL:
      return { ...state, isLoading: false };
    //Blogs

    case types.GET_BLOGS_START:
      return { ...state, isBlogsLoading: true, blogsData: [] };
    case types.GET_BLOGS_SUCCESS:
      return { ...state, isBlogsLoading: false, blogsData: action?.data?.data };
    case types.GET_BLOGS_FAIL:
      return { ...state, isBlogsLoading: false };

    case types.GET_BLOG_DETAIL_START:
      return { ...state, isBlogDetailLoading: true, blogsDetails: {} };
    case types.GET_BLOG_DETAIL_SUCCESS:
      return {
        ...state,
        isBlogDetailLoading: false,
        blogsDetails: action?.data?.data,
      };
    case types.GET_BLOG_DETAIL_FAIL:
      return { ...state, isBlogDetailLoading: false };

    case types.GET_BLOG_CATEGORIES_START:
      return {
        ...state,
        isBlogCategoriesLoading: true,
        blogCategoriesData: [],
      };
    case types.GET_BLOG_CATEGORIES_SUCCESS:
      return {
        ...state,
        isBlogCategoriesLoading: false,
        blogCategoriesData: action?.data?.data,
        currentBlogCategory:
          state.currentBlogCategory === null
            ? action?.data?.data?.[0]?.id
            : state.currentBlogCategory,
      };
    case types.GET_BLOG_CATEGORIES_FAIL:
      return { ...state, isBlogCategoriesLoading: false };

    case types.SET_BLOG_CATEGORY:
      return { ...state, currentBlogCategory: action.data };

    case types.RECENT_FIVE_BLOGS_START:
      return { ...state, recentFiveBlogsLoading: true, recentFiveBlogs: [] };
    case types.RECENT_FIVE_BLOGS_SUCCESS:
      return {
        ...state,
        recentFiveBlogsLoading: false,
        recentFiveBlogs: action.data,
      };
    case types.RECENT_FIVE_BLOGS_FAIL:
      return { ...state, recentFiveBlogsLoading: false, recentFiveBlogs: [] };

    case types.GET_ALL_BLOG_BY_ID_START:
      return { ...state, allBlogByIdLoading: true, allBlogById: [] };
    case types.GET_ALL_BLOG_BY_ID_SUCCESS:
      return { ...state, allBlogByIdLoading: false, allBlogById: action.data };
    case types.GET_ALL_BLOG_BY_ID_FAIL:
      return { ...state, allBlogByIdLoading: false, allBlogById: [] };

    case types.GET_POPULAR_BLOGS_START:
      return { ...state, isLoading: true, popularBlogs: {} };
    case types.GET_POPULAR_BLOGS_SUCCESS:
      return { ...state, isLoading: false, popularBlogs: action?.data };
    case types.GET_POPULAR_BLOGS_FAIL:
      return { ...state, isLoading: false, popularBlogs: {} };

    case types.GET_RECENT_BLOGS_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.GET_RECENT_BLOGS_SUCCESS:
      return { ...state, isLoading: false, tempData: action.payload };
    case types.GET_RECENT_BLOGS_FAIL:
      return { ...state, isLoading: false };

    case types.OPENAI_STANDARD_ENGLISH_START:
      return { ...state, stdEngLoading: true, openAiSdtEnglish: {} };
    case types.OPENAI_STANDARD_ENGLISH_SUCCESS:
      return { ...state, stdEngLoading: false, openAiSdtEnglish: action.data };
    case types.OPENAI_STANDARD_ENGLISH_FAIL:
      return { ...state, stdEngLoading: false, openAiSdtEnglish: {} };

    case types.CONTACT_US_POST_START:
      return { ...state, isLoading: true, contactUsPost: {} };
    case types.CONTACT_US_POST_SUCCESS:
      return { ...state, isLoading: false, contactUsPost: action?.data };
    case types.CONTACT_US_POST_FAIL:
      return { ...state, isLoading: false, contactUsPost: {} };

    case types.GET_MAINTENANCE_ONE_MESSAGE_START:
      return {
        ...state,
        getOnemaintenanceLoading: true,
        getOneMaintenanceMessage: {},
      };
    case types.GET_MAINTENANCE_ONE_MESSAGE_SUCCESS:
      return {
        ...state,
        getOnemaintenanceLoading: false,
        getOneMaintenanceMessage: action?.data,
      };
    case types.GET_MAINTENANCE_ONE_MESSAGE_FAIL:
      return {
        ...state,
        getOnemaintenanceLoading: false,
        getOneMaintenanceMessage: {},
      };

    case types.GET_SPOTLIGHTED_CANDIDATES_START:
      return { ...state, isLoading: true, spotLightedCandidates: [] };
    case types.GET_SPOTLIGHTED_CANDIDATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        spotLightedCandidates: action?.data?.data,
      };
    case types.GET_SPOTLIGHTED_CANDIDATES_FAIL:
      return { ...state, isLoading: false, spotLightedCandidates: [] };

    case types.ADD_FAQ_GROUP_SUCCESS:
      let tempFaqGroup = { ...state.faqGroupLists };
      if (action?.faqFor === "Developer") {
        tempFaqGroup.developer.data = [
          action?.data,
          ...tempFaqGroup?.developer?.data,
        ];
      } else {
        tempFaqGroup.client.data = [
          action?.data,
          ...tempFaqGroup?.client?.data,
        ];
      }
      return { ...state, faqGroupLists: tempFaqGroup };

    case types.ADD_FAQ_SUCCESS:
      let tempFaqLists = { ...state.faqGroupLists };
      if (action?.faqFor === "Developer") {
        let index = tempFaqLists.developer.data.findIndex(
          (item) => item?._id === action?.data?._id
        );
        if (index || index === 0) {
          tempFaqLists.developer.data[index] = action?.data;
        } else {
          tempFaqLists.developer.data = [
            action?.data,
            ...tempFaqLists?.developer?.data,
          ];
        }
      } else {
        let index = tempFaqLists.client.data.findIndex(
          (item) => item?._id === action?.data?._id
        );
        if (index || index === 0) {
          tempFaqLists.client.data[index] = action?.data;
        } else {
          tempFaqLists.client.data = [
            action?.data,
            ...tempFaqLists?.client?.data,
          ];
        }
      }
      return { ...state, faqGroupLists: tempFaqLists };

    case types.UPDATE_FAQ_SUCCESS:
      let tempFaqList = { ...state.faqGroupLists };
      if (action?.faqFor === "Developer") {
        let tempfaqIndD = tempFaqList.developer.data.findIndex(
          (item) => item?._id === action?.data?._id
        );
        tempFaqList.developer.data[tempfaqIndD] = action?.data;
      } else {
        let tempfaqIndC = tempFaqList.client.data.findIndex(
          (item) => item?._id === action?.data?._id
        );
        tempFaqList.client.data[tempfaqIndC] = action?.data;
      }
      return { ...state };

    case types.DELETE_FAQ_SUCCESS:
      let deleteFaqList = { ...state.faqGroupLists };
      if (action?.faqFor === "Developer") {
        let indexFaq = deleteFaqList.developer.data.findIndex(
          (item) => item?._id === action?.faqGroupId
        );
        let filterFaq = deleteFaqList.developer.data.filter(
          (item) => item?._id === action?.faqGroupId
        );
        filterFaq = filterFaq[0]?.faqLists?.filter(
          (item) => item?._id !== action?.faqId
        );
        deleteFaqList.developer.data[indexFaq].faqLists = filterFaq;
      } else {
        let indexFaq = deleteFaqList.client.data.findIndex(
          (item) => item?._id === action?.faqGroupId
        );
        let filterFaq = deleteFaqList.client.data.filter(
          (item) => item?._id === action?.faqGroupId
        );
        filterFaq = filterFaq[0]?.faqLists?.filter(
          (item) => item?._id !== action?.faqId
        );
        deleteFaqList.client.data[indexFaq].faqLists = filterFaq;
      }
      return { ...state };

    case types.DELETE_FAQ_GROUP_SUCCESS:
      let deleteFaqGroup = { ...state.faqGroupLists };
      let filter = [];
      if (action?.faqFor === "Developer") {
        filter = deleteFaqGroup.developer.data.filter(
          (item) => item?._id !== action?.faqGroupId
        );
        deleteFaqGroup.developer.data = filter;
      } else {
        filter = deleteFaqGroup.client.data.filter(
          (item) => item?._id !== action?.faqGroupId
        );
        deleteFaqGroup.client.data = filter;
      }
      return { ...state, faqGroupLists: deleteFaqGroup };

    case types.POST_BLOG_CAT_SUCCESS:
      return {
        ...state,
        blogCatOpenList: {
          ...state.blogCatOpenList,
          data: [action?.data, ...state.blogCatOpenList?.data],
        },
      };
    case types.UPDATE_BLOG_CAT_SUCCESS:
      const catIndex = state?.blogCatOpenList?.data?.findIndex(
        (item) => item?._id === action?.categoryId
      );
      const catTempData = [...state.blogCatOpenList.data];
      catTempData[catIndex] = action?.data;
      return {
        ...state,
        blogCatOpenList: { ...state.blogCatOpenList, data: catTempData },
      };

    case types.DELETE_BLOG_CAT_SUCCESS:
      const temCat = state?.blogCatOpenList?.data?.filter(
        (item) => item?._id !== action?.categoryId
      );
      return {
        ...state,
        blogCatOpenList: { ...state.blogCatOpenList, data: temCat },
      };

    case types.FETCH_REPORT_START:
      return { ...state, devTestReport: null, isTestReportLoading: true };
    case types.FETCH_REPORT_SUCCESS:
      return {
        ...state,
        devTestReport: action.data.data,
        isTestReportLoading: false,
      };
    case types.FETCH_REPORT_FAIL:
      return { ...state, devTestReport: null, isTestReportLoading: false };

    case types.GET_NOTIFICATION_START:
      return { ...state, notificationData: [] };
    case types.GET_NOTIFICATION_SUCCESS:
      return { ...state, notificationData: action?.data?.data };
    case types.GET_NOTIFICATION_FAIL:
      return { ...state, notificationData: [] };

    case types.CLEAR_NOTIFICATION_START:
      return { ...state, notiDeleting: true };
    case types.CLEAR_NOTIFICATION_SUCCESS:
      return { ...state, notificationData: [], notiDeleting: false };
    case types.CLEAR_NOTIFICATION_FAIL:
      return { ...state, notiDeleting: false };

    case types.SET_SOCKET:
      return { ...state, socket: action.payload };

    case types.SET_NOTIFICATION_DATA:
      return { ...state, notificationData: [...state.notificationData, action.payload] };

    case types.CONVERT_IMAGE_START:
      return { ...state, base64Image: "" };
    case types.CONVERT_IMAGE_SUCCESS:
      return { ...state, base64Image: action.data };
    case types.CONVERT_IMAGE_FAIL:
      return { ...state, base64Image: "" };

    case types.GET_NEW_FEATURE_START:
      return { ...state, isNewFeature: undefined };
    case types.GET_NEW_FEATURE_SUCCESS:
      return { ...state, isNewFeature: action?.data };
    case types.GET_NEW_FEATURE_FAIL:
      return { ...state, isNewFeature: undefined };

    case types.NEW_FEATURE_SUCCESS:
      return { ...state, isNewFeature: undefined };

    case types.GET_SITEMAP_URL_SUCCESS:
      return { ...state, sitemapXML: action?.data };

    case types.GET_PRICING_PLAN_START:
      return { ...state };
    case types.GET_PRICING_PLAN_SUCCESS:
      return { ...state, allPlanList: action?.data };
    case types.GET_PRICING_PLAN_FAIL:
      return { ...state };

    case types.START_PAYMENT_PHONE_PAY_START:
      return { ...state, isStartPayment: true };
    case types.START_PAYMENT_PHONE_PAY_SUCCESS:
      return { ...state, isStartPayment: false };
    case types.START_PAYMENT_PHONE_PAY_FAIL:
      return { ...state, isStartPayment: false };

    case types.START_PAYMENT_STRIPE_START:
      return { ...state, isStartPayment: true };
    case types.START_PAYMENT_STRIPE_SUCCESS:
      return { ...state, isStartPayment: false };
    case types.START_PAYMENT_STRIPE_FAIL:
      return { ...state, isStartPayment: false };

    case types.GET_COMPANY_JOBS_SUCCESS:
      return {...state,companyJobs:action?.data}

    default:
      return { ...state };
  }
};
