import "./Contact.css";
import {
  fillForm,
  getTouch,
  home,
  contact,
} from "../../common/constant/contact";
import MetaTagSEO from "../../components/seo/MetaTagSEO";
import ContactUsForm from "./ContactUsForm";

const ContactUs = () => { 
  return (
    <>
      <MetaTagSEO helmetName="contact-us" />
      <div className="wrapper">
        <div className="contact-banner">
          <h1>{getTouch}</h1>
          <p>{fillForm}</p>
        </div>
        <div className="olibr-breadcrumbs">
          <div className="container">
            <ul>
              <li>
                <a href="#">{home}</a>
              </li>
              <li>&rsaquo;</li>
              <li>{contact}</li>
            </ul>
          </div>
        </div>
        <ContactUsForm/>
      </div>
    </>
  );
};

export default ContactUs;
