import React, { Fragment } from "react";
import SelectOption from "../select/SelectOption";
import { countryCodeOption } from "../functions";
import { SpecificCountry } from "../../common/constant/commonData";

const MultipleJobCountry = ({setJobAddress, country, setCountry, location,className="pl-0" }) => {
  const handleAddCountry = () => {
    if (country?.length < 2) {
      let tempCountry = {
        countryCode: "",
        countryName: "",
      };
      setCountry([...country, tempCountry]);
    }
  };
  const handleRemoveCountry = (index) => {
    let temparr = [...country];
    temparr=temparr?.filter((_,id)=>id!==index)
    setCountry(temparr)
  };
  const handleCountryChange = (e, index) => {
    let temparr = [...country];
    temparr[index].countryCode = e?.value;
    temparr[index].countryName = e?.label;
    setCountry(temparr);
    setJobAddress([
        {
          state: "",
          city: "",
          stateName: "",
        },
      ]);
  };
  return location && ( country?.map((itm, id) => (
    <Fragment>
      <div className={`col-4 ${className}`}>
        <label>Country *</label>
        <SelectOption
          optionArray={countryCodeOption(SpecificCountry)}
          state={countryCodeOption(SpecificCountry)?.find(
            (item) => item?.value === itm?.countryCode
          )}
          onChangeFunction={(e) => handleCountryChange(e, id)}
          closeMenuOnSelect={true}
          themeStyle={true}
        />
      </div>

      {(location !== "On Site" && location !== "Hybrid") && (
        <>
          <div className="col-2">
            <div className="actions">
              <a
                href="#-"
                className="minus-icon minus-icon-job"
                onClick={() => handleRemoveCountry(id)}
              >
                -
              </a>
              <a
                href="#-"
                className="plus-icon plus-icon-job"
                onClick={handleAddCountry}
              >
                +
              </a>
            </div>
          </div>
          <div className="col-6"></div>
        </>
      )}
    </Fragment>
  ))
)};

export default MultipleJobCountry;
