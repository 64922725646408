import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addPricingSlabAction,
  getPricingSlabAction,
  updatePricingSlabAction,
} from "../../../../service/action/admin";
import SelectOption from "../../../../components/select/SelectOption";
import { returnAddonListArr } from "../../../../components/functions";

const AddEditPricingSlab = ({ data, setPricingSlab }) => {
  const { addOnList } = useSelector((state) => state.adminReducer);
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    control,
    watch,

    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getWatch = watch();
  const callBack = () => {
    setPricingSlab(false);
    dispatch(getPricingSlabAction(navigate));
  };

  const handleSave = (values) => {
    if (data?._id) {
      let tempData = { ...values };
      tempData?.addOnId && delete tempData?.addOnId
      tempData.addonId = tempData?.addOn?.value;
      tempData?.addOn && delete tempData?.addOn;
      tempData.minQuantity = parseInt(tempData?.minQuantity);
      tempData.maxQuantity =tempData?.maxQuantity? parseInt(tempData?.maxQuantity) : undefined;
      tempData.pricePerAssessment = parseInt(tempData?.pricePerAssessment);
      dispatch(
        updatePricingSlabAction(navigate, {
          data: tempData,
          id: data?._id,
          cb: callBack,
        })
      );
    } else {
      let tempData = { ...values };
      tempData?.addOnId && delete tempData?.addOnId
      tempData.addonId = tempData?.addOn?.value;
      tempData?.addOn && delete tempData?.addOn;
      tempData.minQuantity = parseInt(tempData?.minQuantity);
      tempData.maxQuantity = parseInt(tempData?.maxQuantity);
      tempData.maxQuantity =tempData?.maxQuantity? parseInt(tempData?.maxQuantity) : undefined;
      tempData.pricePerAssessment = parseInt(tempData?.pricePerAssessment);
      dispatch(
        addPricingSlabAction(navigate, { data: tempData, cb: callBack })
      );
    }
  };

  useEffect(() => {
    if (data && addOnList?.length ) {
      setValue("pricePerAssessment", data?.pricePerAssessment);
      setValue("minQuantity", data?.minQuantity);
      setValue("maxQuantity", data?.maxQuantity);
      let tempAddOn=addOnList?.find((item)=>item?._id==data?.addOnId)
      if(tempAddOn){
        setValue("addOn", {value:tempAddOn?._id,label:tempAddOn?.name});
      }
    } else {
      reset();
    }
  }, [addOnList ,data, setValue, reset]);
  return (
    <div className="job-rquirement">
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label className={errors?.addOn && "text-danger"}>
              Add On-List*
            </label>
            <Controller
              control={control}
              name="addOn"
              {...register("addOn", { required: true })}
              render={({ field }) => (
                <SelectOption
                  themeStyle={true}
                  optionArray={returnAddonListArr(addOnList)}
                  state={getWatch?.addOn || {}}
                  onChangeFunction={(e) => setValue("addOn", e)}
                  closeMenuOnSelect={true}
                />
              )}
            />
            {/* <input
              {...register("title", {
                required: true,
              })}
              type="text"
              className="form-control"
              placeholder="Add On-List"
            /> */}
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className={errors?.minQuantity && "text-danger"}>
              Min Quantity*
            </label>
            <input
              {...register("minQuantity", {
                required: true,
              })}
              type="number"
              className="form-control"
              placeholder="Min Quantity"
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label>
              Max Quantity
            </label>
            <input
              {...register("maxQuantity")}
              type="number"
              className="form-control"
              placeholder="Feature Name"
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className={errors?.pricePerAssessment && "text-danger"}>
              Price/Assessment*
            </label>
            <input
              {...register("pricePerAssessment", {
                required: true,
              })}
              type="number"
              className="form-control"
              placeholder="Price/Assessment"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-right">
          <button className="btn" onClick={handleSubmit(handleSave)}>
            {data?._id ? "Update" : "Add"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddEditPricingSlab;
