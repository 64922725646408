import React from 'react'
import CheckIcon from "../../assets/img/icons/checked.png"
import { useState } from 'react';
import { Icon } from '../icon';
import { compareTwoArray } from '../functions';

const BoxCollapse = ({setErrors,errors,setEditable, editable, data, index, setQuestionaries = false, questionaries = false }) => {

    const handleChecked = (indNo, isMandatory) => {
        let tempData = [...questionaries];
        tempData[index].questionLists[indNo].isMandatory = !isMandatory
        setQuestionaries(tempData)
    }

    const handleNewAdded = (indNo, isAdded) => {
        let tempData = [...questionaries];
        tempData[index].questionLists[indNo].isAdded = !isAdded
        setQuestionaries(tempData)
    }
    const [openCollapes, setOpenCollapse] = useState(0)
    const handleCollapseble = () => {
        if (openCollapes === index) {
            setOpenCollapse(-1)
        } else {
            setOpenCollapse(index)
        }
    }

    const handleEdit = (innerIndex) => {
        let tempData = [...questionaries];
        if((editable?.length && tempData[editable[0]].questionLists[editable[1]].question) ||!editable?.length ){
            setEditable([index, innerIndex])
            setErrors([])
        }else{
            setErrors([editable[0],editable[1]])
        }
      
    }

    const handleChange = (e, index, innerIndex) => {
        let tempData = [...questionaries];
        tempData[index].questionLists[innerIndex].question = e.target.value
        setQuestionaries(tempData)
    }

    const handelUpdate=(e)=>{
        e.stopPropagation();
        let tempData = [...questionaries];
        if(tempData[editable[0]].questionLists[editable[1]].question){
            setEditable([])
            setErrors([])
        }else{
            setErrors([editable[0],editable[1]])
            
        }
    }

    const addNewQuestion=(e)=>{
        e.stopPropagation();
        let tempData = [...questionaries];
        if(tempData?.[index]?.questionLists?.[0]?.question || !tempData?.[index]?.questionLists?.length){
            tempData[index].questionLists.unshift({
                isAdded:false,
                isMandatory:false,
                question:""
            })
            setQuestionaries(tempData)
            setEditable([index,0])
            setOpenCollapse(index)
        }else{
            setErrors([index,0])
        }
        
    }
    return (
        <div className='box-collapse-container'>
            <div className='headers'>
                <table className='table'>
                    <thead>
                        <tr onClick={handleCollapseble}>
                            <th >
                                <span className='minus-1'
                                >{
                                        openCollapes !== index ? "+" : "-"
                                    }</span>
                                    <span>{data?.questionName}
                                     {index== questionaries?.length-1 && 
                                     <button className='submitBtn adQuestion' type='button' onClick={addNewQuestion}>Add Question</button>}
                                     </span>
                            </th>
                            {openCollapes == index && (<>
                                <th></th>
                                <th className='th-end'> Mandatory</th>
                                <th className='th-last'>Add</th>
                            </>)}

                        </tr>
                    </thead>
                    <tbody className={openCollapes !== index && "hide"}>
                        {
                            data?.questionLists?.map((itm, innerIndex) => (
                                <tr key={innerIndex}>
                                    <td className='full-width'>
                                        {
                                            compareTwoArray(editable, [index, innerIndex]) ?
                                                <input type='text'
                                                 className={compareTwoArray(errors,[index, innerIndex]) && "border-red"}
                                                value={itm?.question} onChange={(e) => handleChange(e, index, innerIndex)} />
                                                :<div className='questionP'>{itm?.question}</div>
                                        }
                                    </td>
                                    <td className='text-right' onClick={() => handleEdit(innerIndex)}>
                                        {
                                            compareTwoArray(editable, [index, innerIndex]) ? <button className='submitBtn small-btn' onClick={handelUpdate}>update</button> : <Icon name="edit" />
                                        }
                                    </td>
                                    <td className="td-last-check text-center"><input type='checkbox' checked={itm?.isMandatory} onClick={() => handleChecked(innerIndex, itm?.isMandatory)} className='round-check' /></td>
                                    <td className='td-last-plus text-center' onClick={() => handleNewAdded(innerIndex, itm?.isAdded)}>{itm?.isAdded ? <img src={CheckIcon} className='checked-header' /> : "+"}</td>


                                </tr>
                            ))
                        }

                    </tbody>

                </table>
            </div>
        </div>
    )
}

export default BoxCollapse