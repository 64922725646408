//Common Api
export const CURRENT_JOB_CATEGORY = "CURRENT_JOB_CATEGORY";

export const REMOVE_UNATTEMPTED_SKILL = "REMOVE_UNATTEMPTED_SKILL";
export const REMOVE_UNATTEMPTED_SKILL_START = "REMOVE_UNATTEMPTED_SKILL_START";
export const REMOVE_UNATTEMPTED_SKILL_SUCCESS =
  "REMOVE_UNATTEMPTED_SKILL_SUCCESS";
export const REMOVE_UNATTEMPTED_SKILL_FAIL = "REMOVE_UNATTEMPTED_SKILL_FAIL";

export const SEND_OTP = "SEND_OTP";
export const SEND_OTP_START = "SEND_OTP_START";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAIL = "SEND_OTP_FAIL";

export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_START = "VERIFY_OTP_START";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAIL = "VERIFY_OTP_FAIL";

export const UPDATE_PLAGIARISM_STATUS = "UPDATE_PLAGIARISM_STATUS";
export const UPDATE_PLAGIARISM_STATUS_START = "UPDATE_PLAGIARISM_STATUS_START";
export const UPDATE_PLAGIARISM_STATUS_SUCCESS =
  "UPDATE_PLAGIARISM_STATUS_SUCCESS";
export const UPDATE_PLAGIARISM_STATUS_FAIL = "UPDATE_PLAGIARISM_STATUS_FAIL";

export const GET_ALL_EMAILS_SLUGS = "GET_ALL_EMAILS_SLUGS";
export const GET_ALL_EMAILS_SLUGS_START = "GET_ALL_EMAILS_SLUGS_START";
export const GET_ALL_EMAILS_SLUGS_SUCCESS = "GET_ALL_EMAILS_SLUGS_SUCCESS";
export const GET_ALL_EMAILS_SLUGS_FAIL = "GET_ALL_EMAILS_SLUGS_FAIL";

export const SHOW_OPEN_FAQS = "SHOW_OPEN_FAQS";
export const SHOW_OPEN_FAQS_START = "SHOW_OPEN_FAQS_START";
export const SHOW_OPEN_FAQS_SUCCESS = "SHOW_OPEN_FAQS_SUCCESS";
export const SHOW_OPEN_FAQS_FAIL = "SHOW_OPEN_FAQS_FAIL";

export const REMOVE_STACK_TEST = "REMOVE_STACK_TEST";
export const REMOVE_STACK_TEST_START = "REMOVE_STACK_TEST_START";
export const REMOVE_STACK_TEST_SUCCESS = "REMOVE_STACK_TEST_SUCCESS";
export const REMOVE_STACK_TEST_FAIL = "REMOVE_STACK_TEST_FAIL";

export const CHOOSE_STACK_TESTS = "CHOOSE_STACK_TESTS";
export const CHOOSE_STACK_TESTS_START = "CHOOSE_STACK_TESTS_START";
export const CHOOSE_STACK_TESTS_SUCCESS = "CHOOSE_STACK_TESTS_SUCCESS";
export const CHOOSE_STACK_TESTS_FAIL = "CHOOSE_STACK_TESTS_FAIL";

export const GET_DEV_SKILL_TEST_RESULT = "GET_DEV_SKILL_TEST_RESULT";
export const GET_DEV_SKILL_TEST_RESULT_START =
  "GET_DEV_SKILL_TEST_RESULT_START";
export const GET_DEV_SKILL_TEST_RESULT_SUCCESS =
  "GET_DEV_SKILL_TEST_RESULT_SUCCESS";
export const GET_DEV_SKILL_TEST_RESULT_FAIL = "GET_DEV_SKILL_TEST_RESULT_FAIL";

export const CHOOSE_SKILL_TEST = "CHOOSE_SKILL_TEST";
export const CHOOSE_SKILL_TEST_START = "CHOOSE_SKILL_TEST_START";
export const CHOOSE_SKILL_TEST_SUCCESS = "CHOOSE_SKILL_TEST_SUCCESS";
export const CHOOSE_SKILL_TEST_FAIL = "CHOOSE_SKILL_TEST_FAIL";

export const SET_SOCKET = "SET_SOCKET";
export const SET_NOTIFICATION_DATA = "SET_NOTIFICATION_DATA";

export const CONVERT_S3_IMAGE = "CONVERT_S3_IMAGE";
export const CONVERT_S3_IMAGE_SUCCESS = "CONVERT_S3_IMAGE_SUCCESS";

export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_START = "GET_NOTIFICATION_START";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL";

export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const CLEAR_NOTIFICATION_START = "CLEAR_NOTIFICATION_START";
export const CLEAR_NOTIFICATION_SUCCESS = "CLEAR_NOTIFICATION_SUCCESS";
export const CLEAR_NOTIFICATION_FAIL = "CLEAR_NOTIFICATION_FAIL";

export const CONVERT_IMAGE = "CONVERT_IMAGE";
export const CONVERT_IMAGE_START = "CONVERT_IMAGE_START";
export const CONVERT_IMAGE_SUCCESS = "CONVERT_IMAGE_SUCCESS";
export const CONVERT_IMAGE_FAIL = "CONVERT_IMAGE_FAIL";

export const UPDATE_TERM_CONDITION = "UPDATE_TERM_CONDITION";
export const UPDATE_TERM_CONDITION_START = "UPDATE_TERM_CONDITION_START";
export const UPDATE_TERM_CONDITION_FAIL = "UPDATE_TERM_CONDITION_FAIL";
export const UPDATE_TERM_CONDITION_SUCCESS = "UPDATE_TERM_CONDITION_SUCCESS";

export const GET_SKILL_TEST_STEP_DATA = "GET_SKILL_TEST_STEP_DATA";
export const GET_SKILL_TEST_STEP_DATA_START = "GET_SKILL_TEST_STEP_DATA_START";
export const GET_SKILL_TEST_STEP_DATA_SUCCESS =
  "GET_SKILL_TEST_STEP_DATA_SUCCESS";
export const GET_SKILL_TEST_STEP_DATA_FAIL = "GET_SKILL_TEST_STEP_DATA_FAIL";

export const GET_SKILL_BY_CATEGORY_DEV = "GET_SKILL_BY_CATEGORY_DEV";
export const GET_SKILL_BY_CATEGORY_DEV_START =
  "GET_SKILL_BY_CATEGORY_DEV_START";
export const GET_SKILL_BY_CATEGORY_DEV_SUCCESS =
  "GET_SKILL_BY_CATEGORY_DEV_SUCCESS";
export const GET_SKILL_BY_CATEGORY_DEV_FAIL = "GET_SKILL_BY_CATEGORY_DEV_FAIL";

export const GET_DEV_VIDEO = "GET_DEV_VIDEO";
export const GET_DEV_VIDEO_START = "GET_DEV_VIDEO_START";
export const GET_DEV_VIDEO_SUCCESS = "GET_DEV_VIDEO_SUCCESS";
export const GET_DEV_VIDEO_FAIL = "GET_DEV_VIDEO_FAIL";

export const UPLOAD_VIDEO_WITHOUT_DELETE = "UPLOAD_VIDEO_WITHOUT_DELETE";
export const UPLOAD_VIDEO_WITHOUT_DELETE_START =
  "UPLOAD_VIDEO_WITHOUT_DELETE_START";
export const UPLOAD_VIDEO_WITHOUT_DELETE_SUCCESS =
  "UPLOAD_VIDEO_WITHOUT_DELETE_SUCCESS";
export const UPLOAD_VIDEO_WITHOUT_DELETE_FAIL =
  "UPLOAD_VIDEO_WITHOUT_DELETE_FAIL";

export const UPLOAD_VIDEO = "UPLOAD_VIDEO";
export const UPLOAD_VIDEO_START = "UPLOAD_VIDEO_START";
export const UPLOAD_VIDEO_SUCCESS = "UPLOAD_VIDEO_SUCCESS";
export const UPLOAD_VIDEO_FAIL = "UPLOAD_VIDEO_FAIL";

export const NEW_FEATURE_CLICKED = "NEW_FEATURE_CLICKED";
export const NEW_FEATURE_CLICKED_START = "NEW_FEATURE_CLICKED_START";
export const NEW_FEATURE_CLICKED_SUCCESS = "NEW_FEATURE_CLICKED_SUCCESS";
export const NEW_FEATURE_CLICKED_FAIL = "NEW_FEATURE_CLICKED_FAIL";

export const GET_DEV_REVIEW_STATUS = "GET_DEV_REVIEW_STATUS";
export const GET_DEV_REVIEW_STATUS_START = "GET_DEV_REVIEW_STATUS_START";
export const GET_DEV_REVIEW_STATUS_SUCCESS = "GET_DEV_REVIEW_STATUS_SUCCESS";
export const GET_DEV_REVIEW_STATUS_FAIL = "GET_DEV_REVIEW_STATUS_FAIL";

export const GET_DEV_REVIEW_LISTS = "GET_DEV_REVIEW_LISTS";
export const GET_DEV_REVIEW_LISTS_START = "GET_DEV_REVIEW_LISTS_START";
export const GET_DEV_REVIEW_LISTS_SUCCESS = "GET_DEV_REVIEW_LISTS_SUCCESS";
export const GET_DEV_REVIEW_LISTS_FAIL = "GET_DEV_REVIEW_LISTS_FAIL";

export const DELETE_DEV_REVIEW = "DELETE_DEV_REVIEW";
export const DELETE_DEV_REVIEW_START = "DELETE_DEV_REVIEW_START";
export const DELETE_DEV_REVIEW_SUCCESS = "DELETE_DEV_REVIEW_SUCCESS";
export const DELETE_DEV_REVIEW_FAIL = "DELETE_DEV_REVIEW_FAIL";

export const CREATE_DEV_REVIEW = "CREATE_DEV_REVIEW";
export const CREATE_DEV_REVIEW_START = "CREATE_DEV_REVIEW_START";
export const CREATE_DEV_REVIEW_SUCCESS = "CREATE_DEV_REVIEW_SUCCESS";
export const CREATE_DEV_REVIEW_FAIL = "CREATE_DEV_REVIEW_FAIL";

export const RESEND_DEV_REVIEW = "RESEND_DEV_REVIEW";
export const RESEND_DEV_REVIEW_START = "RESEND_DEV_REVIEW_START";
export const RESEND_DEV_REVIEW_SUCCESS = "RESEND_DEV_REVIEW_SUCCESS";
export const RESEND_DEV_REVIEW_FAIL = "RESEND_DEV_REVIEW_FAIL";

export const GIVE_RATING_DEV_REVIEW = "GIVE_RATING_DEV_REVIEW";
export const GIVE_RATING_DEV_REVIEW_START = "GIVE_RATING_DEV_REVIEW_START";
export const GIVE_RATING_DEV_REVIEW_SUCCESS = "GIVE_RATING_DEV_REVIEW_SUCCESS";
export const GIVE_RATING_DEV_REVIEW_FAIL = "GIVE_RATING_DEV_REVIEW_FAIL";

export const GET_DEV_INFO_FOR_REVIEW = "GET_DEV_INFO_FOR_REVIEW";
export const GET_DEV_INFO_FOR_REVIEW_START = "GET_DEV_INFO_FOR_REVIEW_START";
export const GET_DEV_INFO_FOR_REVIEW_SUCCESS =
  "GET_DEV_INFO_FOR_REVIEW_SUCCESS";
export const GET_DEV_INFO_FOR_REVIEW_FAIL = "GET_DEV_INFO_FOR_REVIEW_FAIL";

export const GET_JOB_BY_CAT = "GET_JOB_BY_CAT";
export const GET_JOB_BY_CAT_START = "GET_JOB_BY_CAT_START ";
export const GET_JOB_BY_CAT_SUCCESS = "GET_JOB_BY_CAT_SUCCESS";
export const GET_JOB_BY_CAT_FAIL = "GET_JOB_BY_CAT_FAIL";

export const GET_JOB_BY_CAT_FILTER_SUCCESS = "GET_JOB_BY_CAT_FILTER_SUCCESS";

export const GET_JOB_BY_ID = "GET_JOB_BY_ID";
export const GET_JOB_BY_ID_START = "GET_JOB_BY_ID_START ";
export const GET_JOB_BY_ID_SUCCESS = "GET_JOB_BY_ID_SUCCESS";
export const GET_JOB_BY_ID_FAIL = "GET_JOB_BY_ID_FAIL";

export const GET_POPULAR_JOB = "GET_POPULAR_JOB";
export const GET_POPULAR_JOB_START = "GET_POPULAR_JOB_START ";
export const GET_POPULAR_JOB_SUCCESS = "GET_POPULAR_JOB_SUCCESS";
export const GET_POPULAR_JOB_FAIL = "GET_POPULAR_JOB_FAIL";

export const ADD_JOB_CAT = "ADD_JOB_CAT";
export const ADD_JOB_CAT_START = "ADD_JOB_CAT_START ";
export const ADD_JOB_CAT_SUCCESS = "ADD_JOB_CAT_SUCCESS";
export const ADD_JOB_CAT_FAIL = "ADD_JOB_CAT_FAIL";

export const UPDATE_JOB_CAT = "UPDATE_JOB_CAT";
export const UPDATE_JOB_CAT_START = "UPDATE_JOB_CAT_START ";
export const UPDATE_JOB_CAT_SUCCESS = "UPDATE_JOB_CAT_SUCCESS";
export const UPDATE_JOB_CAT_FAIL = "UPDATE_JOB_CAT_FAIL";

export const DELETE_JOB_CAT = "DELETE_JOB_CAT";
export const DELETE_JOB_CAT_START = "DELETE_JOB_CAT_START ";
export const DELETE_JOB_CAT_SUCCESS = "DELETE_JOB_CAT_SUCCESS";
export const DELETE_JOB_CAT_FAIL = "DELETE_JOB_CAT_FAIL";

export const GET_JOB_CAT = "GET_JOB_CAT";
export const GET_JOB_CAT_START = "GET_JOB_CAT_START ";
export const GET_JOB_CAT_SUCCESS = "GET_JOB_CAT_SUCCESS";
export const GET_JOB_CAT_FAIL = "GET_JOB_CAT_FAIL";

export const ALL_BLOG_NAME_AND_ID_START = "ALL_BLOG_NAME_AND_ID_START ";
export const ALL_BLOG_NAME_AND_ID = "ALL_BLOG_NAME_AND_ID";
export const ALL_BLOG_NAME_AND_ID_SUCCESS = "ALL_BLOG_NAME_AND_ID_SUCCESS";
export const ALL_BLOG_NAME_AND_ID_FAIL = "ALL_BLOG_NAME_AND_ID_FAIL";

export const UPDATE_QUERY_START = "UPDATE_QUERY_START ";
export const UPDATE_QUERY = "UPDATE_QUERY";
export const UPDATE_QUERY_SUCCESS = "UPDATE_QUERY_SUCCESS";
export const UPDATE_QUERY_FAIL = "UPDATE_QUERY_FAIL";

export const DELETE_QUERY_START = "DELETE_QUERY_START ";
export const DELETE_QUERY = "DELETE_QUERY";
export const DELETE_QUERY_SUCCESS = "DELETE_QUERY_SUCCESS";
export const DELETE_QUERY_FAIL = "DELETE_QUERY_FAIL";

export const GET_QUERIES_LIST_START = "GET_QUERIES_LIST_START ";
export const GET_QUERIES_LIST = "GET_QUERIES_LIST";
export const GET_QUERIES_LIST_SUCCESS = "GET_QUERIES_LIST_SUCCESS";
export const GET_QUERIES_LIST_FAIL = "GET_QUERIES_LIST_FAIL";

export const VERIFY_DEV_EMAIL_START = "VERIFY_DEV_EMAIL_START ";
export const VERIFY_DEV_EMAIL = "VERIFY_DEV_EMAIL";
export const VERIFY_DEV_EMAIL_SUCCESS = "VERIFY_DEV_EMAIL_SUCCESS";
export const VERIFY_DEV_EMAIL_FAIL = "VERIFY_DEV_EMAIL_FAIL";

export const APPROVE_DEV_EMAIL_START = "APPROVE_DEV_EMAIL_START ";
export const APPROVE_DEV_EMAIL = "APPROVE_DEV_EMAIL";
export const APPROVE_DEV_EMAIL_SUCCESS = "APPROVE_DEV_EMAIL_SUCCESS";
export const APPROVE_DEV_EMAIL_FAIL = "APPROVE_DEV_EMAIL_FAIL";

export const UNAPPROVE_DEV_EMAIL_START = "UNAPPROVE_DEV_EMAIL_START ";
export const UNAPPROVE_DEV_EMAIL = "UNAPPROVE_DEV_EMAIL";
export const UNAPPROVE_DEV_EMAIL_SUCCESS = "UNAPPROVE_DEV_EMAIL_SUCCESS";
export const UNAPPROVE_DEV_EMAIL_FAIL = "UNAPPROVE_DEV_EMAIL_FAIL";

export const GET_FAQ_LISTS_START = "GET_FAQ_LISTS_START ";
export const GET_FAQ_LISTS = "GET_FAQ_LISTS";
export const GET_FAQ_LISTS_SUCCESS = "GET_FAQ_LISTS_SUCCESS";
export const GET_FAQ_LISTS_FAIL = "GET_FAQ_LISTS_FAIL";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const CHANGE_EMAIL_START = "CHANGE_EMAIL_START";
export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const CHANGE_EMAIL_SUCCESS = "CHANGE_EMAIL_SUCCESS";
export const CHANGE_EMAIL_FAIL = "CHANGE_EMAIL_FAIL";

export const GET_ALL_FAQ_GROUP_START = "GET_ALL_FAQ_GROUP_START";
export const GET_ALL_FAQ_GROUP = "GET_ALL_FAQ_GROUP";
export const GET_ALL_FAQ_GROUP_SUCCESS = "GET_ALL_FAQ_GROUP_SUCCESS";
export const GET_ALL_FAQ_GROUP_FAIL = "GET_ALL_FAQ_GROUP_FAIL";

export const GET_JOB_LIST_START = "GET_JOB_LIST_START";
export const GET_JOB_LIST = "GET_JOB_LIST";
export const GET_JOB_LIST_SUCCESS = "GET_JOB_LIST_SUCCESS";
export const GET_JOB_LIST_FAIL = "GET_JOB_LIST_FAIL";

export const GET_BLOGS_START = "GET_BLOGS_START";
export const GET_BLOGS = "GET_BLOGS";
export const GET_BLOGS_SUCCESS = "GET_BLOGS_SUCCESS";
export const GET_BLOGS_FAIL = "GET_BLOGS_FAIL";

export const GET_BLOG_CATEGORIES_START = "GET_BLOG_CATEGORIES_START";
export const GET_BLOG_CATEGORIES = "GET_BLOG_CATEGORIES";
export const GET_BLOG_CATEGORIES_SUCCESS = "GET_BLOG_CATEGORIES_SUCCESS";
export const GET_BLOG_CATEGORIES_FAIL = "GET_BLOG_CATEGORIES_FAIL";

export const SET_BLOG_CATEGORY = "SET_BLOG_CATEGORY";

export const GET_ALL_BLOG_BY_ID_START = "GET_ALL_BLOG_BY_ID_START";
export const GET_ALL_BLOG_BY_ID = "GET_ALL_BLOG_BY_ID";
export const GET_ALL_BLOG_BY_ID_SUCCESS = "GET_ALL_BLOG_BY_ID_SUCCESS";
export const GET_ALL_BLOG_BY_ID_FAIL = "GET_ALL_BLOG_BY_ID_FAIL";

export const GET_BLOG_DETAIL_START = "GET_BLOG_DETAIL_START";
export const GET_BLOG_DETAIL = "GET_BLOG_DETAIL";
export const GET_BLOG_DETAIL_SUCCESS = "GET_BLOG_DETAIL_SUCCESS";
export const GET_BLOG_DETAIL_FAIL = "GET_BLOG_DETAIL_FAIL";

export const GET_RECENT_BLOGS_START = "GET_RECENT_BLOGS_START";
export const GET_RECENT_BLOGS = "GET_RECENT_BLOGS";
export const GET_RECENT_BLOGS_SUCCESS = "GET_RECENT_BLOGS_SUCCESS";
export const GET_RECENT_BLOGS_FAIL = "GET_RECENT_BLOGS_FAIL";

export const GET_POPULAR_BLOGS_START = "GET_POPULAR_BLOGS_START";
export const GET_POPULAR_BLOGS = "GET_POPULAR_BLOGS";
export const GET_POPULAR_BLOGS_SUCCESS = "GET_POPULAR_BLOGS_SUCCESS";
export const GET_POPULAR_BLOGS_FAIL = "GET_POPULAR_BLOGS_FAIL";

export const RECENT_FIVE_BLOGS_START = "RECENT_FIVE_BLOGS_START";
export const RECENT_FIVE_BLOGS = "RECENT_FIVE_BLOGS";
export const RECENT_FIVE_BLOGS_SUCCESS = "RECENT_FIVE_BLOGS_SUCCESS";
export const RECENT_FIVE_BLOGS_FAIL = "RECENT_FIVE_BLOGS_FAIL";

export const GET_COUNTRY_START = "GET_COUNTRY_START";
export const GET_COUNTRY = "GET_COUNTRY";
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";
export const GET_COUNTRY_FAIL = "GET_COUNTRY_FAIL";

export const GET_STATE_START = "GET_STATE_START";
export const GET_STATE = "GET_STATE";
export const GET_STATE_SUCCESS = "GET_STATE_SUCCESS";
export const GET_STATE_FAIL = "GET_STATE_FAIL";

export const RESUME_PARSING_ADMIN = "RESUME_PARSING_ADMIN";
export const RESUME_PARSING_ADMIN_START = "RESUME_PARSING_ADMIN_START";
export const RESUME_PARSING_ADMIN_FAIL = "RESUME_PARSING_ADMIN_FAIL";
export const RESUME_PARSING_ADMIN_SUCCESS = "RESUME_PARSING_ADMIN_SUCCESS";

export const GET_ADMIN_DEV_ENGLISH_RESULT = "GET_ADMIN_DEV_ENGLISH_RESULT";
export const GET_ADMIN_DEV_ENGLISH_RESULT_START =
  "GET_ADMIN_DEV_ENGLISH_RESULT_START";
export const GET_ADMIN_DEV_ENGLISH_RESULT_FAIL =
  "GET_ADMIN_DEV_ENGLISH_RESULT_FAIL";
export const GET_ADMIN_DEV_ENGLISH_RESULT_SUCCESS =
  "GET_ADMIN_DEV_ENGLISH_RESULT_SUCCESS";

export const UPDATE_ADMIN_DEV_ENGLISH_RESULT =
  "UPDATE_ADMIN_DEV_ENGLISH_RESULT";
export const UPDATE_ADMIN_DEV_ENGLISH_RESULT_START =
  "UPDATE_ADMIN_DEV_ENGLISH_RESULT_START";
export const UPDATE_ADMIN_DEV_ENGLISH_RESULT_FAIL =
  "UPDATE_ADMIN_DEV_ENGLISH_RESULT_FAIL";
export const UPDATE_ADMIN_DEV_ENGLISH_RESULT_SUCCESS =
  "UPDATE_ADMIN_DEV_ENGLISH_RESULT_SUCCESS";

export const GET_CITY_START = "GET_CITY_START";
export const GET_CITY = "GET_CITY";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";
export const GET_CITY_FAIL = "GET_CITY_FAIL";

//User Auth Api
export const GET_USER_START = "GET_USER_START";
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const LOGIN_START = "LOGIN_START";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const SIGN_UP_START = "SIGN_UP_START";
export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAIL = "SIGN_UP_FAIL";

export const FORGET_PASSWORD_START = "FORGET_PASSWORD_START";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAIL = "FORGET_PASSWORD_FAIL";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const EMAIL_VERIFICATION_START = "EMAIL_VERIFICATION_START";
export const EMAIL_VERIFICATION = "EMAIL_VERIFICATION";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
export const EMAIL_VERIFICATION_FAIL = "EMAIL_VERIFICATION_FAIL";

export const RESEND_EMAIL_VERIFICATION_START =
  "RESEND_EMAIL_VERIFICATION_START";
export const RESEND_EMAIL_VERIFICATION = "RESEND_EMAIL_VERIFICATION";
export const RESEND_EMAIL_VERIFICATION_SUCCESS =
  "RESEND_EMAIL_VERIFICATION_SUCCESS";
export const RESEND_EMAIL_VERIFICATION_FAIL = "RESEND_EMAIL_VERIFICATION_FAIL";

export const GET_BLOG_CAT_OPEN_START = "GET_BLOG_CAT_OPEN_START";
export const GET_BLOG_CAT_OPEN = "GET_BLOG_CAT_OPEN";
export const GET_BLOG_CAT_OPEN_SUCCESS = "GET_BLOG_CAT_OPEN_SUCCESS";
export const GET_BLOG_CAT_OPEN_FAIL = "GET_BLOG_CAT_OPEN_FAIL";

export const GET_BLOG_OPEN_START = "GET_BLOG_OPEN_START";
export const GET_BLOG_OPEN = "GET_BLOG_OPEN";
export const GET_BLOG_OPEN_SUCCESS = "GET_BLOG_OPEN_SUCCESS";
export const GET_BLOG_OPEN_FAIL = "GET_BLOG_OPEN_FAIL";

export const GET_BLOG_BY_ID_OPEN_START = "GET_BLOG_BY_ID_OPEN_START";
export const GET_BLOG_BY_ID_OPEN = "GET_BLOG_BY_ID_OPEN";
export const GET_BLOG_BY_ID_OPEN_SUCCESS = "GET_BLOG_BY_ID_OPEN_SUCCESS";
export const GET_BLOG_BY_ID_OPEN_FAIL = "GET_BLOG_BY_ID_OPEN_FAIL";

export const GET_BLOG_BY_SLUG_OPEN_START = "GET_BLOG_BY_SLUG_OPEN_START";
export const GET_BLOG_BY_SLUG_OPEN = "GET_BLOG_BY_SLUG_OPEN";
export const GET_BLOG_BY_SLUG_OPEN_SUCCESS = "GET_BLOG_BY_SLUG_OPEN_SUCCESS";
export const GET_BLOG_BY_SLUG_OPEN_FAIL = "GET_BLOG_BY_SLUG_OPEN_FAIL";

export const LOGIN_LINKEDIN = "LOGIN_LINKEDIN";
export const LOGIN_LINKEDIN_START = "LOGIN_LINKEDIN_START";
export const LOGIN_LINKEDIN_FAIL = "LOGIN_LINKEDIN_FAIL";
export const LOGIN_LINKEDIN_SUCCESS = "LOGIN_LINKEDIN_SUCCESS";

export const CHECK_USER = "CHECK_USER";
export const CHECK_USER_START = "CHECK_USER_START";
export const CHECK_USER_FAIL = "CHECK_USER_FAIL";
export const CHECK_USER_SUCCESS = "CHECK_USER_SUCCESS";

export const NEW_FEATURE = "NEW_FEATURE";
export const NEW_FEATURE_START = "NEW_FEATURE_START";
export const NEW_FEATURE_FAIL = "NEW_FEATURE_FAIL";
export const NEW_FEATURE_SUCCESS = "NEW_FEATURE_SUCCESS";

export const GET_NEW_FEATURE = "GET_NEW_FEATURE";
export const GET_NEW_FEATURE_START = "GET_NEW_FEATURE_START";
export const GET_NEW_FEATURE_FAIL = "GET_NEW_FEATURE_FAIL";
export const GET_NEW_FEATURE_SUCCESS = "GET_NEW_FEATURE_SUCCESS";

export const GET_SITEMAP_URL = "GET_SITEMAP_URL";
export const GET_SITEMAP_URL_START = "GET_SITEMAP_URL_START";
export const GET_SITEMAP_URL_FAIL = "GET_SITEMAP_URL_FAIL";
export const GET_SITEMAP_URL_SUCCESS = "GET_SITEMAP_URL_SUCCESS";

export const GET_PRICING_PLAN="GET_PRICING_PLAN";
export const GET_PRICING_PLAN_START = "GET_PRICING_PLAN_START";
export const GET_PRICING_PLAN_FAIL = "GET_PRICING_PLAN_FAIL";
export const GET_PRICING_PLAN_SUCCESS = "GET_PRICING_PLAN_SUCCESS";


export const START_PAYMENT_PHONE_PAY="START_PAYMENT_PHONE_PAY";
export const START_PAYMENT_PHONE_PAY_START = "START_PAYMENT_PHONE_PAY_START";
export const START_PAYMENT_PHONE_PAY_FAIL = "START_PAYMENT_PHONE_PAY_FAIL";
export const START_PAYMENT_PHONE_PAY_SUCCESS = "START_PAYMENT_PHONE_PAY_SUCCESS";

export const START_PAYMENT_STRIPE="START_PAYMENT_STRIPE";
export const START_PAYMENT_STRIPE_START = "START_PAYMENT_STRIPE_START";
export const START_PAYMENT_STRIPE_FAIL = "START_PAYMENT_STRIPE_FAIL";
export const START_PAYMENT_STRIPE_SUCCESS = "START_PAYMENT_STRIPE_SUCCESS";

export const PAYMENT_CHECKOUT="PAYMENT_CHECKOUT";
export const PAYMENT_CHECKOUT_START="PAYMENT_CHECKOUT_START";
export const PAYMENT_CHECKOUT_FAIL="PAYMENT_CHECKOUT_FAIL";
export const PAYMENT_CHECKOUT_SUCCESS="PAYMENT_CHECKOUT_SUCCESS";

export const GET_COMPANY_JOBS="GET_COMPANY_JOBS";
export const GET_COMPANY_JOBS_START="GET_COMPANY_JOBS_START";
export const GET_COMPANY_JOBS_FAIL="GET_COMPANY_JOBS_FAIL";
export const GET_COMPANY_JOBS_SUCCESS="GET_COMPANY_JOBS_SUCCESS";

export const ADD_DEV_SKILL="ADD_DEV_SKILL";
export const ADD_DEV_SKILL_START="ADD_DEV_SKILL_START";
export const ADD_DEV_SKILL_FAIL="ADD_DEV_SKILL_FAIL";
export const ADD_DEV_SKILL_SUCCESS="ADD_DEV_SKILL_SUCCESS";



//Admin Api
export const CREATE_SKILL_TEST = "CREATE_SKILL_TEST";
export const CREATE_SKILL_TEST_START = "CREATE_SKILL_TEST_START";
export const CREATE_SKILL_TEST_FAIL = "CREATE_SKILL_TEST_FAIL";
export const CREATE_SKILL_TEST_SUCCESS = "CREATE_SKILL_TEST_SUCCESS";

export const GET_SKILL_TEST = "GET_SKILL_TEST";
export const GET_SKILL_TEST_START = "GET_SKILL_TEST_START";
export const GET_SKILL_TEST_FAIL = "GET_SKILL_TEST_FAIL";
export const GET_SKILL_TEST_SUCCESS = "GET_SKILL_TEST_SUCCESS";

export const DELETE_BLOG_NEW_START = "DELETE_BLOG_NEW_START";
export const DELETE_BLOG_NEW = "DELETE_BLOG_NEW";
export const DELETE_BLOG_NEW_SUCCESS = "DELETE_BLOG_NEW_SUCCESS";
export const DELETE_BLOG_NEW_FAIL = "DELETE_BLOG_NEW_FAIL";

export const UPDATE_BLOG_NEW_START = "UPDATE_BLOG_NEW_START";
export const UPDATE_BLOG_NEW = "UPDATE_BLOG_NEW";
export const UPDATE_BLOG_NEW_SUCCESS = "UPDATE_BLOG_NEW_SUCCESS";
export const UPDATE_BLOG_NEW_FAIL = "UPDATE_BLOG_NEW_FAIL";

export const POST_BLOG_NEW_START = "POST_BLOG_NEW_START";
export const POST_BLOG_NEW = "POST_BLOG_NEW";
export const POST_BLOG_NEW_SUCCESS = "POST_BLOG_NEW_SUCCESS";
export const POST_BLOG_NEW_FAIL = "POST_BLOG_NEW_FAIL";

export const POST_BLOG_CAT_START = "POST_BLOG_CAT_START";
export const POST_BLOG_CAT = "POST_BLOG_CAT";
export const POST_BLOG_CAT_SUCCESS = "POST_BLOG_CAT_SUCCESS";
export const POST_BLOG_CAT_FAIL = "POST_BLOG_CAT_FAIL";

export const DELETE_BLOG_CAT_START = "DELETE_BLOG_CAT_START";
export const DELETE_BLOG_CAT = "DELETE_BLOG_CAT";
export const DELETE_BLOG_CAT_SUCCESS = "DELETE_BLOG_CAT_SUCCESS";
export const DELETE_BLOG_CAT_FAIL = "DELETE_BLOG_CAT_FAIL";

export const UPDATE_BLOG_CAT_START = "UPDATE_BLOG_CAT_START";
export const UPDATE_BLOG_CAT = "UPDATE_BLOG_CAT";
export const UPDATE_BLOG_CAT_SUCCESS = "UPDATE_BLOG_CAT_SUCCESS";
export const UPDATE_BLOG_CAT_FAIL = "UPDATE_BLOG_CAT_FAIL";

export const GET_DEVELOPER_LIST_START = "GET_DEVELOPER_LIST_START";
export const GET_DEVELOPER_LIST = "GET_DEVELOPER_LIST";
export const GET_DEVELOPER_LIST_SUCCESS = "GET_DEVELOPER_LIST_SUCCESS";
export const GET_DEVELOPER_LIST_FAIL = "GET_DEVELOPER_LIST_FAIL";

export const DEV_UPDATE_STATUS_START = "DEV_UPDATE_STATUS_START";
export const DEV_UPDATE_STATUS = "DEV_UPDATE_STATUS";
export const DEV_UPDATE_STATUS_SUCCESS = "DEV_UPDATE_STATUS_SUCCESS";
export const DEV_UPDATE_STATUS_FAIL = "DEV_UPDATE_STATUS_FAIL";

export const GET_ADMIN_SKILL_LIST_START = "GET_ADMIN_SKILL_LIST_START";
export const GET_ADMIN_SKILL_LIST = "GET_ADMIN_SKILL_LIST";
export const GET_ADMIN_SKILL_LIST_SUCCESS = "GET_ADMIN_SKILL_LIST_SUCCESS";
export const GET_ADMIN_SKILL_LIST_FAIL = "GET_ADMIN_SKILL_LIST_FAIL";

export const UPDATE_SKILL_STATUS_START = "UPDATE_SKILL_STATUS_START";
export const UPDATE_SKILL_STATUS = "UPDATE_SKILL_STATUS";
export const UPDATE_SKILL_STATUS_SUCCESS = "UPDATE_SKILL_STATUS_SUCCESS";
export const UPDATE_SKILL_STATUS_FAIL = "UPDATE_SKILL_STATUS_FAIL";

export const ADD_STACK_START = "ADD_STACK_START";
export const ADD_STACK = "ADD_STACK";
export const ADD_STACK_SUCCESS = "ADD_STACK_SUCCESS";
export const ADD_STACK_FAIL = "ADD_STACK_FAIL";

export const EDIT_STACK_START = "EDIT_STACK_START";
export const EDIT_STACK = "EDIT_STACK";
export const EDIT_STACK_SUCCESS = "EDIT_STACK_SUCCESS";
export const EDIT_STACK_FAIL = "EDIT_STACK_FAIL";

export const GET_ALL_TOOLS_START = "GET_ALL_TOOLS_START";
export const GET_ALL_TOOLS_SUCCESS = "GET_ALL_TOOLS_SUCCESS";
export const GET_ALL_TOOLS_FAIL = "GET_ALL_TOOLS_FAIL";

export const DELETE_ADMIN_TOOL_START = "DELETE_ADMIN_TOOL_START";
export const DELETE_ADMIN_TOOL_SUCCESS = "DELETE_ADMIN_TOOL_SUCCESS";
export const DELETE_ADMIN_TOOL_FAIL = "DELETE_ADMIN_TOOL_FAIL";

export const GET_ADMIN_STACK_LIST_START = "GET_ADMIN_STACK_LIST_START";
export const GET_ADMIN_STACK_LIST = "GET_ADMIN_STACK_LIST";
export const GET_ADMIN_STACK_LIST_SUCCESS = "GET_ADMIN_STACK_LIST_SUCCESS";
export const GET_ADMIN_STACK_LIST_FAIL = "GET_ADMIN_STACK_LIST_FAIL";

export const GET_ADMIN_DEV_SKILL_RESULT = "GET_ADMIN_DEV_SKILL_RESULT";
export const GET_ADMIN_DEV_SKILL_RESULT_START =
  "GET_ADMIN_DEV_SKILL_RESULT_START";
export const GET_ADMIN_DEV_SKILL_RESULT_FAIL =
  "GET_ADMIN_DEV_SKILL_RESULT_FAIL";
export const GET_ADMIN_DEV_SKILL_RESULT_SUCCESS =
  "GET_ADMIN_DEV_SKILL_RESULT_SUCCESS";

export const UPDATE_ADMIN_SKILL_TEST = "UPDATE_ADMIN_SKILL_TEST";
export const UPDATE_ADMIN_SKILL_TEST_START = "UPDATE_ADMIN_SKILL_TEST_START";
export const UPDATE_ADMIN_SKILL_TEST_FAIL = "UPDATE_ADMIN_SKILL_TEST_FAIL";
export const UPDATE_ADMIN_SKILL_TEST_SUCCESS =
  "UPDATE_ADMIN_SKILL_TEST_SUCCESS";

export const UPDATE_STACK_STATUS_START = "UPDATE_STACK_STATUS_START";
export const UPDATE_STACK_STATUS = "UPDATE_STACK_STATUS";
export const UPDATE_STACK_STATUS_SUCCESS = "UPDATE_STACK_STATUS_SUCCESS";
export const UPDATE_STACK_STATUS_FAIL = "UPDATE_STACK_STATUS_FAIL";

export const GET_ADMIN_STACK_SKILL = "GET_ADMIN_STACK_SKILL";
export const GET_ADMIN_STACK_SKILL_START = "GET_ADMIN_STACK_SKILL_START";
export const GET_ADMIN_STACK_SKILL_FAIL = "GET_ADMIN_STACK_SKILL_FAIL";
export const GET_ADMIN_STACK_SKILL_SUCCESS = "GET_ADMIN_STACK_SKILL_SUCCESS";

export const REFRESH_XOBIN_ASSESSMENT_START = "REFRESH_XOBIN_ASSESSMENT_START";
export const REFRESH_XOBIN_ASSESSMENT = "REFRESH_XOBIN_ASSESSMENT";
export const REFRESH_XOBIN_ASSESSMENT_SUCCESS =
  "REFRESH_XOBIN_ASSESSMENT_SUCCESS";
export const REFRESH_XOBIN_ASSESSMENT_FAIL = "REFRESH_XOBIN_ASSESSMENT_FAIL";

export const RENDER_ALL_HACKER_RANK_ASSESSMENT_START =
  "RENDER_ALL_HACKER_RANK_ASSESSMENT_START";
export const RENDER_ALL_HACKER_RANK_ASSESSMENT =
  "RENDER_ALL_HACKER_RANK_ASSESSMENT";
export const RENDER_ALL_HACKER_RANK_ASSESSMENT_SUCCESS =
  "RENDER_ALL_HACKER_RANK_ASSESSMENT_SUCCESS";
export const RENDER_ALL_HACKER_RANK_ASSESSMENT_FAIL =
  "RENDER_ALL_HACKER_RANK_ASSESSMENT_FAIL";

export const RENDER_ALL_HACKER_RANK_ASSESSMENT_NO_LIMIT_START =
  "RENDER_ALL_HACKER_RANK_ASSESSMENT_NO_LIMIT_START";
export const RENDER_ALL_HACKER_RANK_ASSESSMENT_NO_LIMIT =
  "RENDER_ALL_HACKER_RANK_ASSESSMENT_NO_LIMIT";
export const RENDER_ALL_HACKER_RANK_ASSESSMENT_NO_LIMIT_SUCCESS =
  "RENDER_ALL_HACKER_RANK_ASSESSMENT_NO_LIMIT_SUCCESS";
export const RENDER_ALL_HACKER_RANK_ASSESSMENT_NO_LIMIT_FAIL =
  "RENDER_ALL_HACKER_RANK_ASSESSMENT_NO_LIMIT_FAIL";

export const ADD_SKILL_START = "ADD_SKILL_START";
export const ADD_SKILL = "ADD_SKILL";
export const ADD_SKILL_SUCCESS = "ADD_SKILL_SUCCESS";
export const ADD_SKILL_FAIL = "ADD_SKILL_FAIL";

export const ADMIN_ALL_TOOLS = "ADMIN_ALL_TOOLS";
export const ADMIN_DELETE_TOOL = "ADMIN_DELETE_TOOL";
export const ADMIN_UPDATE_TOOL = "ADMIN_UPDATE_TOOL";
export const ADMIN_ADD_TOOL = "ADMIN_ADD_TOOL";

export const GET_ALL_PERK_AND_BENEFIT = "GET_ALL_PERK_AND_BENEFIT";
export const GET_ALL_PERK_BENEFIT_START = "GET_ALL_PERK_BENEFIT_START";
export const GET_ALL_PERK_BENEFIT_FAIL = "GET_ALL_PERK_BENEFIT_FAIL";
export const GET_ALL_PERK_BENEFIT_SUCCESS = "GET_ALL_PERK_BENEFIT_SUCCESS";

export const GET_COMPANY_PROFILE = "GET_COMPANY_PROFILE";
export const GET_COMPANY_PROFILE_START = "GET_COMPANY_PROFILE_START";
export const GET_COMPANY_PROFILE_SUCCESS = "GET_COMPANY_PROFILE_SUCCESS";
export const GET_COMPANY_PROFILE_FAIL = "GET_COMPANY_PROFILE_FAIL";

export const SAVE_COMPANY_PROFILE = "SAVE_COMPANY_PROFILE";
export const SAVE_COMPANY_PROFILE_START = "SAVE_COMPANY_PROFILE_START";
export const SAVE_COMPANY_PROFILE_SUCCESS = "SAVE_COMPANY_PROFILE_SUCCESS";
export const SAVE_COMPANY_PROFILE_FAIL = "SAVE_COMPANY_PROFILE_FAIL";

export const UPDATE_FIRST_COMPANY_PROFILE = "UPDATE_FIRST_COMPANY_PROFILE";
export const UPDATE_FIRST_COMPANY_PROFILE_START =
  "UPDATE_FIRST_COMPANY_PROFILE_START";
export const UPDATE_FIRST_COMPANY_PROFILE_SUCCESS =
  "UPDATE_FIRST_COMPANY_PROFILE_SUCCESS";
export const UPDATE_FIRST_COMPANY_PROFILE_FAIL =
  "UPDATE_FIRST_COMPANY_PROFILE_FAIL";

export const SAVE_PERK_AND_BENEFIT = "SAVE_PERK_AND_BENEFIT";
export const SAVE_PERK_BENEFIT_START = "SAVE_PERK_BENEFIT_START";
export const SAVE_PERK_BENEFIT_FAIL = "SAVE_PERK_BENEFIT_FAIL";
export const SAVE_PERK_BENEFIT_SUCCESS = "SAVE_PERK_BENEFIT_SUCCESS";

export const UPDATE_PERK_AND_BENEFIT = "UPDATE_PERK_AND_BENEFIT";
export const UPDATE_PERK_BENEFIT_START = "UPDATE_PERK_BENEFIT_START";
export const UPDATE_PERK_BENEFIT_FAIL = "UPDATE_PERK_BENEFIT_FAIL";
export const UPDATE_PERK_BENEFIT_SUCCESS = "UPDATE_PERK_BENEFIT_SUCCESS";

export const DELETE_PERK_AND_BENEFIT = "DELETE_PERK_AND_BENEFIT";
export const DELETE_PERK_BENEFIT_START = "DELETE_PERK_BENEFIT_START";
export const DELETE_PERK_BENEFIT_FAIL = "DELETE_PERK_BENEFIT_FAIL";
export const DELETE_PERK_BENEFIT_SUCCESS = "DELETE_PERK_BENEFIT_SUCCESS";

export const ADMIN_UPDATE_SKILL_START = "ADMIN_UPDATE_SKILL_START";
export const ADMIN_UPDATE_SKILL_SUCCESS = "ADMIN_UPDATE_SKILL_SUCCESS";
export const ADMIN_UPDATE_SKILL_FAIL = "ADMIN_UPDATE_SKILL_FAIL";

export const ADMIN_ADD_TOOL_START = "ADMIN_ADD_TOOL_START";
export const ADMIN_ADD_TOOL_SUCCESS = "ADMIN_ADD_TOOL_SUCCESS";
export const ADMIN_ADD_TOOL_FAIL = "ADMIN_ADD_TOOL_FAIL";

export const EDIT_SKILL_START = "EDIT_SKILL_START";
export const EDIT_SKILL = "EDIT_SKILL";
export const EDIT_SKILL_SUCCESS = "EDIT_SKILL_SUCCESS";
export const EDIT_SKILL_FAIL = "EDIT_SKILL_FAIL";

export const RENDER_TOPIC_LIST_START = "RENDER_TOPIC_LIST_START";
export const RENDER_TOPIC_LIST = "RENDER_TOPIC_LIST";
export const RENDER_TOPIC_LIST_SUCCESS = "RENDER_TOPIC_LIST_SUCCESS";
export const RENDER_TOPIC_LIST_FAIL = "RENDER_TOPIC_LIST_FAIL";

export const GET_ALL_ICONS_START = "GET_ALL_ICONS_START";
export const GET_ALL_ICONS = "GET_ALL_ICONS";
export const GET_ALL_ICONS_SUCCESS = "GET_ALL_ICONS_SUCCESS";
export const GET_ALL_ICONS_FAIL = "GET_ALL_ICONS_FAIL";

export const UPLOAD_ICONS_START = "UPLOAD_ICONS_START";
export const UPLOAD_ICONS = "UPLOAD_ICONS";
export const UPLOAD_ICONS_SUCCESS = "UPLOAD_ICONS_SUCCESS";
export const UPLOAD_ICONS_FAIL = "UPLOAD_ICONS_FAIL";

export const DELETE_STACK_START = "DELETE_STACK_START";
export const DELETE_STACK = "DELETE_STACK";
export const DELETE_STACK_SUCCESS = "DELETE_STACK_SUCCESS";
export const DELETE_STACK_FAIL = "DELETE_STACK_FAIL";

export const DELETE_SKILL_START = "DELETE_SKILL_START";
export const DELETE_SKILL = "DELETE_SKILL";
export const DELETE_SKILL_SUCCESS = "DELETE_SKILL_SUCCESS";
export const DELETE_SKILL_FAIL = "DELETE_SKILL_FAIL";

export const GET_DEVELOPER_PROFILE_START = "GET_DEVELOPER_PROFILE_START";
export const GET_DEVELOPER_PROFILE = "GET_DEVELOPER_PROFILE";
export const GET_DEVELOPER_PROFILE_SUCCESS = "GET_DEVELOPER_PROFILE_SUCCESS";
export const GET_DEVELOPER_PROFILE_FAIL = "GET_DEVELOPER_PROFILE_FAIL";

export const FRESHER = "FRESHER";

export const UPDATE_PROFILE_STATUS_START = "UPDATE_PROFILE_STATUS_START";
export const UPDATE_PROFILE_STATUS = "UPDATE_PROFILE_STATUS";
export const UPDATE_PROFILE_STATUS_SUCCESS = "UPDATE_PROFILE_STATUS_SUCCESS";
export const UPDATE_PROFILE_STATUS_FAIL = "UPDATE_PROFILE_STATUS_FAIL";

export const GET_CURRENT_EMPLOYMENT_START = "GET_CURRENT_EMPLOYMENT_START";
export const GET_CURRENT_EMPLOYMENT = "GET_CURRENT_EMPLOYMENT";
export const GET_CURRENT_EMPLOYMENT_SUCCESS = "GET_CURRENT_EMPLOYMENT_SUCCESS";
export const GET_CURRENT_EMPLOYMENT_FAIL = "GET_CURRENT_EMPLOYMENT_FAIL";

export const APPROVAL_UPDATE_START = "APPROVAL_UPDATE_START";
export const APPROVAL_UPDATE = "APPROVAL_UPDATE";
export const APPROVAL_UPDATE_SUCCESS = "APPROVAL_UPDATE_SUCCESS";
export const APPROVAL_UPDATE_FAIL = "APPROVAL_UPDATE_FAIL";

export const GET_APPROVAL_UPDATE_START = "GET_APPROVAL_UPDATE_START";
export const GET_APPROVAL_UPDATE = "GET_APPROVAL_UPDATE";
export const GET_APPROVAL_UPDATE_SUCCESS = "GET_APPROVAL_UPDATE_SUCCESS";
export const GET_APPROVAL_UPDATE_FAIL = "GET_APPROVAL_UPDATE_FAIL";

export const PROFILE_UPDATE_REQUEST_APPROVE_REJECT_START =
  "PROFILE_UPDATE_REQUEST_APPROVE_REJECT_START";
export const PROFILE_UPDATE_REQUEST_APPROVE_REJECT =
  "PROFILE_UPDATE_REQUEST_APPROVE_REJECT";
export const PROFILE_UPDATE_REQUEST_APPROVE_REJECT_SUCCESS =
  "PROFILE_UPDATE_REQUEST_APPROVE_REJECT_SUCCESS";
export const PROFILE_UPDATE_REQUEST_APPROVE_REJECT_FAIL =
  "PROFILE_UPDATE_REQUEST_APPROVE_REJECT_FAIL";

export const RESULT_PARSING_START = "RESULT_PARSING_START";
export const RESULT_PARSING = "RESULT_PARSING";
export const RESULT_PARSING_SUCCESS = "RESULT_PARSING_SUCCESS";
export const RESULT_PARSING_FAIL = "RESULT_PARSING_FAIL";

export const SET_MEMBER_PASSWORD_START = "SET_MEMBER_PASSWORD_START";
export const SET_MEMBER_PASSWORD = "SET_MEMBER_PASSWORD";
export const SET_MEMBER_PASSWORD_SUCCESS = "SET_MEMBER_PASSWORD_SUCCESS";
export const SET_MEMBER_PASSWORD_FAIL = "SET_MEMBER_PASSWORD_FAIL";

export const ADD_USER_START = "ADD_USER_START";
export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";

export const GET_USER_BY_ID_START = "GET_USER_BY_ID_START";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_FAIL = "GET_USER_BY_ID_FAIL";

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";
export const UPDATE_USER_STATUS_START = "UPDATE_USER_STATUS_START";
export const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS";
export const UPDATE_USER_STATUS_FAIL = "UPDATE_USER_STATUS_FAIL";

export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const USER_LISTING_START = "USER_LISTING_START";
export const USER_LISTING = "USER_LISTING";
export const USER_LISTING_SUCCESS = "USER_LISTING_SUCCESS";
export const USER_LISTING_FAIL = "USER_LISTING_FAIL";

export const GET_DEV_RESULT_START = "GET_DEV_RESULT_START";
export const GET_DEV_RESULT = "GET_DEV_RESULT";
export const GET_DEV_RESULT_SUCCESS = "GET_DEV_RESULT_SUCCESS";
export const GET_DEV_RESULT_FAIL = "GET_DEV_RESULT_FAIL";

export const DEV_CHOOSE_SKILL_GET_START = "DEV_CHOOSE_SKILL_GET_START";
export const DEV_CHOOSE_SKILL_GET = "DEV_CHOOSE_SKILL_GET";
export const DEV_CHOOSE_SKILL_GET_SUCCESS = "DEV_CHOOSE_SKILL_GET_SUCCESS";
export const DEV_CHOOSE_SKILL_GET_FAIL = "DEV_CHOOSE_SKILL_GET_FAIL";

export const FINAL_RESUME_ADMIN_CALL = "FINAL_RESUME_ADMIN_CALL";
export const FINAL_RESUME_ADMIN_CALL_START = "FINAL_RESUME_ADMIN_CALL_START";
export const FINAL_RESUME_ADMIN_CALL_SUCCESS =
  "FINAL_RESUME_ADMIN_CALL_SUCCESS";
export const FINAL_RESUME_ADMIN_CALL_FAIL = "FINAL_RESUME_ADMIN_CALL_FAIL";

export const GET_CATEGORY_LIST_START = "GET_CATEGORY_LIST_START";
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const GET_CATEGORY_LIST_SUCCESS = "GET_CATEGORY_LIST_SUCCESS";
export const GET_CATEGORY_LIST_FAIL = "GET_CATEGORY_LIST_FAIL";

export const ADD_CATEGORY_BLOG_START = "ADD_CATEGORY_BLOG_START";
export const ADD_CATEGORY_BLOG = "ADD_CATEGORY_BLOG";
export const ADD_CATEGORY_BLOG_SUCCESS = "ADD_CATEGORY_BLOG_SUCCESS";
export const ADD_CATEGORY_BLOG_FAIL = "ADD_CATEGORY_BLOG_FAIL";

export const DELETE_CATEGORY_BLOG_START = "DELETE_CATEGORY_BLOG_START";
export const DELETE_CATEGORY_BLOG = "DELETE_CATEGORY_BLOG";
export const DELETE_CATEGORY_BLOG_SUCCESS = "DELETE_CATEGORY_BLOG_SUCCESS";
export const DELETE_CATEGORY_BLOG_FAIL = "DELETE_CATEGORY_BLOG_FAIL";

export const UPDATE_CATEGORY_BLOG_START = "UPDATE_CATEGORY_BLOG_START";
export const UPDATE_CATEGORY_BLOG = "UPDATE_CATEGORY_BLOG";
export const UPDATE_CATEGORY_BLOG_SUCCESS = "UPDATE_CATEGORY_BLOG_SUCCESS";
export const UPDATE_CATEGORY_BLOG_FAIL = "UPDATE_CATEGORY_BLOG_FAIL";

export const GET_BLOG_LIST_START = "GET_BLOG_LIST_START";
export const GET_BLOG_LIST = "GET_BLOG_LIST";
export const GET_BLOG_LIST_SUCCESS = "GET_BLOG_LIST_SUCCESS";
export const GET_BLOG_LIST_FAIL = "GET_BLOG_LIST_FAIL";

export const POST_BLOG_START = "POST_BLOG_START";
export const POST_BLOG = "POST_BLOG";
export const POST_BLOG_SUCCESS = "POST_BLOG_SUCCESS";
export const POST_BLOG_FAIL = "POST_BLOG_FAIL";

export const UPDATE_BLOG_START = "UPDATE_BLOG_START";
export const UPDATE_BLOG = "UPDATE_BLOG";
export const UPDATE_BLOG_SUCCESS = "UPDATE_BLOG_SUCCESS";
export const UPDATE_BLOG_FAIL = "UPDATE_BLOG_FAIL";

export const DELETE_BLOG_START = "DELETE_BLOG_START";
export const DELETE_BLOG = "DELETE_BLOG";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_FAIL = "DELETE_BLOG_FAIL";

export const ADD_FAQ_GROUP_START = "ADD_FAQ_GROUP_START";
export const ADD_FAQ_GROUP = "ADD_FAQ_GROUP";
export const ADD_FAQ_GROUP_SUCCESS = "ADD_FAQ_GROUP_SUCCESS";
export const ADD_FAQ_GROUP_FAIL = "ADD_FAQ_GROUP_FAIL";

export const ADD_FAQ_START = "ADD_FAQ_START";
export const ADD_FAQ = "ADD_FAQ";
export const ADD_FAQ_SUCCESS = "ADD_FAQ_SUCCESS";
export const ADD_FAQ_FAIL = "ADD_FAQ_FAIL";

export const UPDATE_FAQ_START = "UPDATE_FAQ_START";
export const UPDATE_FAQ = "UPDATE_FAQ";
export const UPDATE_FAQ_SUCCESS = "UPDATE_FAQ_SUCCESS";
export const UPDATE_FAQ_FAIL = "UPDATE_FAQ_FAIL";

export const UPDATE_FAQ_GROUP_START = "UPDATE_FAQ_GROUP_START";
export const UPDATE_FAQ_GROUP = "UPDATE_FAQ_GROUP";
export const UPDATE_FAQ_GROUP_SUCCESS = "UPDATE_FAQ_GROUP_SUCCESS";
export const UPDATE_FAQ_GROUP_FAIL = "UPDATE_FAQ_GROUP_FAIL";

export const DELETE_FAQ_START = "DELETE_FAQ_START";
export const DELETE_FAQ = "DELETE_FAQ";
export const DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS";
export const DELETE_FAQ_FAIL = "DELETE_FAQ_FAIL";

export const DELETE_FAQ_GROUP_START = "DELETE_FAQ_GROUP_START";
export const DELETE_FAQ_GROUP = "DELETE_FAQ_GROUP";
export const DELETE_FAQ_GROUP_SUCCESS = "DELETE_FAQ_GROUP_SUCCESS";
export const DELETE_FAQ_GROUP_FAIL = "DELETE_FAQ_GROUP_FAIL";

export const ADD_QUESTION_START = "ADD_QUESTION_START";
export const ADD_QUESTION = "ADD_QUESTION";
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS";
export const ADD_QUESTION_FAIL = "ADD_QUESTION_FAIL";

export const ENGLISH_ALL_QUESTION_START = "ENGLISH_ALL_QUESTION_START";
export const ENGLISH_ALL_QUESTION = "ENGLISH_ALL_QUESTION";
export const ENGLISH_ALL_QUESTION_SUCCESS = "ENGLISH_ALL_QUESTION_SUCCESS";
export const ENGLISH_ALL_QUESTION_FAIL = "ENGLISH_ALL_QUESTION_FAIL";

export const DELETE_ENGLISH_QUESTION_START = "DELETE_ENGLISH_QUESTION_START";
export const DELETE_ENGLISH_QUESTION = "DELETE_ENGLISH_QUESTION";
export const DELETE_ENGLISH_QUESTION_SUCCESS =
  "DELETE_ENGLISH_QUESTION_SUCCESS";
export const DELETE_ENGLISH_QUESTION_FAIL = "DELETE_ENGLISH_QUESTION_FAIL";

export const UPDATE_ENGLISH_QUESTION_START = "UPDATE_ENGLISH_QUESTION_START";
export const UPDATE_ENGLISH_QUESTION = "UPDATE_ENGLISH_QUESTION";
export const UPDATE_ENGLISH_QUESTION_SUCCESS =
  "UPDATE_ENGLISH_QUESTION_SUCCESS";
export const UPDATE_ENGLISH_QUESTION_FAIL = "UPDATE_ENGLISH_QUESTION_FAIL";

export const GET_ALL_JOB_START = "GET_ALL_JOB_START";
export const GET_ALL_JOB = "GET_ALL_JOB";
export const GET_ALL_JOB_SUCCESS = "GET_ALL_JOB_SUCCESS";
export const GET_ALL_JOB_FAIL = "GET_ALL_JOB_FAIL";
export const GET_ALL_JOB_SUCCESS_FILTER = "GET_ALL_JOB_SUCCESS_FILTER";

export const ADD_JOB_START = "ADD_JOB_START";
export const ADD_JOB = "ADD_JOB";
export const ADD_JOB_SUCCESS = "ADD_JOB_SUCCESS";
export const ADD_JOB_FAIL = "ADD_JOB_FAIL";

export const EDIT_JOB_START = "EDIT_JOB_START";
export const EDIT_JOB = "EDIT_JOB";
export const EDIT_JOB_SUCCESS = "EDIT_JOB_SUCCESS";
export const EDIT_JOB_FAIL = "EDIT_JOB_FAIL";

export const DELETE_JOB_START = "DELETE_JOB_START";
export const DELETE_JOB = "DELETE_JOB";
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const DELETE_JOB_FAIL = "DELETE_JOB_FAIL";

export const ADMIN_DASHBOARD_STEP_ONE_START = "ADMIN_DASHBOARD_STEP_ONE_START";
export const ADMIN_DASHBOARD_STEP_ONE = "ADMIN_DASHBOARD_STEP_ONE";
export const ADMIN_DASHBOARD_STEP_ONE_SUCCESS =
  "ADMIN_DASHBOARD_STEP_ONE_SUCCESS";
export const ADMIN_DASHBOARD_STEP_ONE_FAIL = "ADMIN_DASHBOARD_STEP_ONE_FAIL";

export const GET_CLIENT_LIST_ADMIN = "GET_CLIENT_LIST_ADMIN";
export const GET_CLIENT_LIST_ADMIN_START = "GET_CLIENT_LIST_ADMIN_START";
export const GET_CLIENT_LIST_ADMIN_FAIL = "GET_CLIENT_LIST_ADMIN_FAIL";
export const GET_CLIENT_LIST_ADMIN_SUCCESS = "GET_CLIENT_LIST_ADMIN_SUCCESS";

export const GET_CLIENT_DETAIL_ADMIN = "GET_CLIENT_DETAIL_ADMIN";
export const GET_CLIENT_DETAIL_ADMIN_START = "GET_CLIENT_DETAIL_ADMIN_START";
export const GET_CLIENT_DETAIL_ADMIN_FAIL = "GET_CLIENT_DETAIL_ADMIN_FAIL";
export const GET_CLIENT_DETAIL_ADMIN_SUCCESS =
  "GET_CLIENT_DETAIL_ADMIN_SUCCESS";

export const GET_JOB_FULL_CLIENT_ADMIN = "GET_JOB_FULL_CLIENT_ADMIN";
export const GET_JOB_FULL_CLIENT_ADMIN_START =
  "GET_JOB_FULL_CLIENT_ADMIN_START";
export const GET_JOB_FULL_CLIENT_ADMIN_FAIL = "GET_JOB_FULL_CLIENT_ADMIN_FAIL";
export const GET_JOB_FULL_CLIENT_ADMIN_SUCCESS =
  "GET_JOB_FULL_CLIENT_ADMIN_SUCCESS";

export const GET_JOB_LIST_BY_CLIENT_ADMIN = "GET_JOB_LIST_BY_CLIENT_ADMIN";
export const GET_JOB_LIST_BY_CLIENT_ADMIN_START =
  "GET_JOB_LIST_BY_CLIENT_ADMIN_START";
export const GET_JOB_LIST_BY_CLIENT_ADMIN_FAIL =
  "GET_JOB_LIST_BY_CLIENT_ADMIN_FAIL";
export const GET_JOB_LIST_BY_CLIENT_ADMIN_SUCCESS =
  "GET_JOB_LIST_BY_CLIENT_ADMIN_SUCCESS";

export const GET_ADMIN_TEMPLATE_LIST_START = "GET_ADMIN_TEMPLATE_LIST_START";
export const GET_ADMIN_TEMPLATE_LIST = "GET_ADMIN_TEMPLATE_LIST";
export const GET_ADMIN_TEMPLATE_LIST_SUCCESS =
  "GET_ADMIN_TEMPLATE_LIST_SUCCESS";
export const GET_ADMIN_TEMPLATE_LIST_FAIL = "GET_ADMIN_TEMPLATE_LIST_FAIL";

export const GET_ADMIN_TEMPLATE_BY_ID_START = "GET_ADMIN_TEMPLATE_BY_ID_START";
export const GET_ADMIN_TEMPLATE_BY_ID = "GET_ADMIN_TEMPLATE_BY_ID";
export const GET_ADMIN_TEMPLATE_BY_ID_SUCCESS =
  "GET_ADMIN_TEMPLATE_BY_ID_SUCCESS";
export const GET_ADMIN_TEMPLATE_BY_ID_FAIL = "GET_ADMIN_TEMPLATE_BY_ID_FAIL";

export const GET_ADMIN_EMAIL_HISTORY_START = "GET_ADMIN_EMAIL_HISTORY_START";
export const GET_ADMIN_EMAIL_HISTORY = "GET_ADMIN_EMAIL_HISTORY";
export const GET_ADMIN_EMAIL_HISTORY_SUCCESS =
  "GET_ADMIN_EMAIL_HISTORY_SUCCESS";
export const GET_ADMIN_EMAIL_HISTORY_FAIL = "GET_ADMIN_EMAIL_HISTORY_FAIL";

export const POST_ADMIN_TEMPLATE_LIST_START = "POST_ADMIN_TEMPLATE_LIST_START";
export const POST_ADMIN_TEMPLATE_LIST = "POST_ADMIN_TEMPLATE_LIST";
export const POST_ADMIN_TEMPLATE_LIST_SUCCESS =
  "POST_ADMIN_TEMPLATE_LIST_SUCCESS";
export const POST_ADMIN_TEMPLATE_LIST_FAIL = "POST_ADMIN_TEMPLATE_LIST_FAIL";

export const UPDATE_ADMIN_TEMPLATE_LIST_START =
  "UPDATE_ADMIN_TEMPLATE_LIST_START";
export const UPDATE_ADMIN_TEMPLATE_LIST = "UPDATE_ADMIN_TEMPLATE_LIST";
export const UPDATE_ADMIN_TEMPLATE_LIST_SUCCESS =
  "UPDATE_ADMIN_TEMPLATE_LIST_SUCCESS";
export const UPDATE_ADMIN_TEMPLATE_LIST_FAIL =
  "UPDATE_ADMIN_TEMPLATE_LIST_FAIL";

export const DELETE_ADMIN_TEMPLATE_LIST_START =
  "DELETE_ADMIN_TEMPLATE_LIST_START";
export const DELETE_ADMIN_TEMPLATE_LIST = "DELETE_ADMIN_TEMPLATE_LIST";
export const DELETE_ADMIN_TEMPLATE_LIST_SUCCESS =
  "DELETE_ADMIN_TEMPLATE_LIST_SUCCESS";
export const DELETE_ADMIN_TEMPLATE_LIST_FAIL =
  "DELETE_ADMIN_TEMPLATE_LIST_FAIL";

export const GET_ADMIN_CLIENT_SHORTLIST_START =
  "GET_ADMIN_CLIENT_SHORTLIST_START";
export const GET_ADMIN_CLIENT_SHORTLIST = "GET_ADMIN_CLIENT_SHORTLIST";
export const GET_ADMIN_CLIENT_SHORTLIST_SUCCESS =
  "GET_ADMIN_CLIENT_SHORTLIST_SUCCESS";
export const GET_ADMIN_CLIENT_SHORTLIST_FAIL =
  "GET_ADMIN_CLIENT_SHORTLIST_FAIL";

export const GET_INTERVIEW_LIST_START = "GET_INTERVIEW_LIST_START";
export const GET_INTERVIEW_LIST = "GET_INTERVIEW_LIST";
export const GET_INTERVIEW_LIST_SUCCESS = "GET_INTERVIEW_LIST_SUCCESS";
export const GET_INTERVIEW_LIST_FAIL = "GET_INTERVIEW_LIST_FAIL";

export const GET_ADMIN_CLIENT_HIRED_START = "GET_ADMIN_CLIENT_HIRED_START";
export const GET_ADMIN_CLIENT_HIRED = "GET_ADMIN_CLIENT_HIRED";
export const GET_ADMIN_CLIENT_HIRED_SUCCESS = "GET_ADMIN_CLIENT_HIRED_SUCCESS";
export const GET_ADMIN_CLIENT_HIRED_FAIL = "GET_ADMIN_CLIENT_HIRED_FAIL";

export const GET_ADMIN_DEV_ACADEMIC = "GET_ADMIN_DEV_ACADEMIC";
export const GET_ADMIN_DEV_ACADEMIC_START = "GET_ADMIN_DEV_ACADEMIC_START";
export const GET_ADMIN_DEV_ACADEMIC_FAIL = "GET_ADMIN_DEV_ACADEMIC_FAIL";
export const GET_ADMIN_DEV_ACADEMIC_SUCCESS = "GET_ADMIN_DEV_ACADEMIC_SUCCESS";

export const UPDATE_ADMIN_DEV_ACADEMIC = "UPDATE_ADMIN_DEV_ACADEMIC";
export const UPDATE_ADMIN_DEV_ACADEMIC_START =
  "UPDATE_ADMIN_DEV_ACADEMIC_START";
export const UPDATE_ADMIN_DEV_ACADEMIC_FAIL = "UPDATE_ADMIN_DEV_ACADEMIC_FAIL";
export const UPDATE_ADMIN_DEV_ACADEMIC_SUCCESS =
  "UPDATE_ADMIN_DEV_ACADEMIC_SUCCESS";

export const SAVE_ADMIN_DEV_ACADEMIC = "SAVE_ADMIN_DEV_ACADEMIC";
export const SAVE_ADMIN_DEV_ACADEMIC_START = "SAVE_ADMIN_DEV_ACADEMIC_START";
export const SAVE_ADMIN_DEV_ACADEMIC_FAIL = "SAVE_ADMIN_DEV_ACADEMIC_FAIL";
export const SAVE_ADMIN_DEV_ACADEMIC_SUCCESS =
  "SAVE_ADMIN_DEV_ACADEMIC_SUCCESS";

export const DELETE_ADMIN_DEV_ACADEMIC = "DELETE_ADMIN_DEV_ACADEMIC";
export const DELETE_ADMIN_DEV_ACADEMIC_START =
  "DELETE_ADMIN_DEV_ACADEMIC_START";
export const DELETE_ADMIN_DEV_ACADEMIC_FAIL = "DELETE_ADMIN_DEV_ACADEMIC_FAIL";
export const DELETE_ADMIN_DEV_ACADEMIC_SUCCESS =
  "DELETE_ADMIN_DEV_ACADEMIC_SUCCESS";

export const GET_ADMIN_DEV_CERTIFICATION = "GET_ADMIN_DEV_CERTIFICATION";
export const GET_ADMIN_DEV_CERTIFICATION_START =
  "GET_ADMIN_DEV_CERTIFICATION_START";
export const GET_ADMIN_DEV_CERTIFICATION_SUCCESS =
  "GET_ADMIN_DEV_CERTIFICATION_SUCCESS";
export const GET_ADMIN_DEV_CERTIFICATION_FAIL =
  "GET_ADMIN_DEV_CERTIFICATION_FAIL";

export const SAVE_ADMIN_DEV_CERTIFICATION = "SAVE_ADMIN_DEV_CERTIFICATION";
export const SAVE_ADMIN_DEV_CERTIFICATION_START =
  "SAVE_ADMIN_DEV_CERTIFICATION_START";
export const SAVE_ADMIN_DEV_CERTIFICATION_SUCCESS =
  "SAVE_ADMIN_DEV_CERTIFICATION_SUCCESS";
export const SAVE_ADMIN_DEV_CERTIFICATION_FAIL =
  "SAVE_ADMIN_DEV_CERTIFICATION_FAIL";

export const UPDATE_ADMIN_DEV_CERTIFICATION = "UPDATE_ADMIN_DEV_CERTIFICATION";
export const UPDATE_ADMIN_DEV_CERTIFICATION_START =
  "UPDATE_ADMIN_DEV_CERTIFICATION_START";
export const UPDATE_ADMIN_DEV_CERTIFICATION_SUCCESS =
  "UPDATE_ADMIN_DEV_CERTIFICATION_SUCCESS";
export const UPDATE_ADMIN_DEV_CERTIFICATION_FAIL =
  "UPDATE_ADMIN_DEV_CERTIFICATION_FAIL";

export const DELETE_ADMIN_DEV_CERTIFICATION = "DELETE_ADMIN_DEV_CERTIFICATION";
export const DELETE_ADMIN_DEV_CERTIFICATION_START =
  "DELETE_ADMIN_DEV_CERTIFICATION_START";
export const DELETE_ADMIN_DEV_CERTIFICATION_SUCCESS =
  "DELETE_ADMIN_DEV_CERTIFICATION_SUCCESS";
export const DELETE_ADMIN_DEV_CERTIFICATION_FAIL =
  "DELETE_ADMIN_DEV_CERTIFICATION_FAIL";

export const GET_ADMIN_DEV_SOCIAL_PROFILE = "GET_ADMIN_DEV_SOCIAL_PROFILE";
export const GET_ADMIN_DEV_SOCIAL_PROFILE_START =
  "GET_ADMIN_DEV_SOCIAL_PROFILE_START";
export const GET_ADMIN_DEV_SOCIAL_PROFILE_FAIL =
  "GET_ADMIN_DEV_SOCIAL_PROFILE_FAIL";
export const GET_ADMIN_DEV_SOCIAL_PROFILE_SUCCESS =
  "GET_ADMIN_DEV_SOCIAL_PROFILE_SUCCESS";

export const SAVE_ADMIN_DEV_SOCIAL_PROFILE = "SAVE_ADMIN_DEV_SOCIAL_PROFILE";
export const SAVE_ADMIN_DEV_SOCIAL_PROFILE_START =
  "SAVE_ADMIN_DEV_SOCIAL_PROFILE_START";
export const SAVE_ADMIN_DEV_SOCIAL_PROFILE_FAIL =
  "SAVE_ADMIN_DEV_SOCIAL_PROFILE_FAIL";
export const SAVE_ADMIN_DEV_SOCIAL_PROFILE_SUCCESS =
  "SAVE_ADMIN_DEV_SOCIAL_PROFILE_SUCCESS";

export const UPDATE_ADMIN_DEV_SOCIAL_PROFILE =
  "UPDATE_ADMIN_DEV_SOCIAL_PROFILE";
export const UPDATE_ADMIN_DEV_SOCIAL_PROFILE_START =
  "UPDATE_ADMIN_DEV_SOCIAL_PROFILE_START";
export const UPDATE_ADMIN_DEV_SOCIAL_PROFILE_FAIL =
  "UPDATE_ADMIN_DEV_SOCIAL_PROFILE_FAIL";
export const UPDATE_ADMIN_DEV_SOCIAL_PROFILE_SUCCESS =
  "UPDATE_ADMIN_DEV_SOCIAL_PROFILE_SUCCESS";

export const GET_ADMIN_DEV_EMPLOYEMENT = "GET_ADMIN_DEV_EMPLOYEMENT";
export const GET_ADMIN_DEV_EMPLOYEMENT_START =
  "GET_ADMIN_DEV_EMPLOYEMENT_START";
export const GET_ADMIN_DEV_EMPLOYEMENT_FAIL = "GET_ADMIN_DEV_EMPLOYEMENT_FAIL";
export const GET_ADMIN_DEV_EMPLOYEMENT_SUCCESS =
  "GET_ADMIN_DEV_EMPLOYEMENT_SUCCESS";

export const SAVE_ADMIN_DEV_EMPLOYEMENT = "SAVE_ADMIN_DEV_EMPLOYEMENT";
export const SAVE_ADMIN_DEV_EMPLOYEMENT_START =
  "SAVE_ADMIN_DEV_EMPLOYEMENT_START";
export const SAVE_ADMIN_DEV_EMPLOYEMENT_FAIL =
  "SAVE_ADMIN_DEV_EMPLOYEMENT_FAIL";
export const SAVE_ADMIN_DEV_EMPLOYEMENT_SUCCESS =
  "SAVE_ADMIN_DEV_EMPLOYEMENT_SUCCESS";

export const UPDATE_ADMIN_DEV_EMPLOYEMENT = "UPDATE_ADMIN_DEV_EMPLOYEMENT";
export const UPDATE_ADMIN_DEV_EMPLOYEMENT_START =
  "UPDATE_ADMIN_DEV_EMPLOYEMENT_START";
export const UPDATE_ADMIN_DEV_EMPLOYEMENT_FAIL =
  "UPDATE_ADMIN_DEV_EMPLOYEMENT_FAIL";
export const UPDATE_ADMIN_DEV_EMPLOYEMENT_SUCCESS =
  "UPDATE_ADMIN_DEV_EMPLOYEMENT_SUCCESS";

export const DELETE_ADMIN_DEV_EMPLOYEMENT = "DELETE_ADMIN_DEV_EMPLOYEMENT";
export const DELETE_ADMIN_DEV_EMPLOYEMENT_START =
  "DELETE_ADMIN_DEV_EMPLOYEMENT_START";
export const DELETE_ADMIN_DEV_EMPLOYEMENT_FAIL =
  "DELETE_ADMIN_DEV_EMPLOYEMENT_FAIL";
export const DELETE_ADMIN_DEV_EMPLOYEMENT_SUCCESS =
  "DELETE_ADMIN_DEV_EMPLOYEMENT_SUCCESS";

export const GET_ADMIN_DEV_PROJECT = "GET_ADMIN_DEV_PROJECT";
export const GET_ADMIN_DEV_PROJECT_START = "GET_ADMIN_DEV_PROJECT_START";
export const GET_ADMIN_DEV_PROJECT_FAIL = "GET_ADMIN_DEV_PROJECT_FAIL";
export const GET_ADMIN_DEV_PROJECT_SUCCESS = "GET_ADMIN_DEV_PROJECT_SUCCESS";

export const SAVE_ADMIN_DEV_PROJECT = "SAVE_ADMIN_DEV_PROJECT";
export const SAVE_ADMIN_DEV_PROJECT_START = "SAVE_ADMIN_DEV_PROJECT_START";
export const SAVE_ADMIN_DEV_PROJECT_FAIL = "SAVE_ADMIN_DEV_PROJECT_FAIL";
export const SAVE_ADMIN_DEV_PROJECT_SUCCESS = "SAVE_ADMIN_DEV_PROJECT_SUCCESS";

export const UPDATE_ADMIN_DEV_PROJECT = "UPDATE_ADMIN_DEV_PROJECT";
export const UPDATE_ADMIN_DEV_PROJECT_START = "UPDATE_ADMIN_DEV_PROJECT_START";
export const UPDATE_ADMIN_DEV_PROJECT_FAIL = "UPDATE_ADMIN_DEV_PROJECT_FAIL";
export const UPDATE_ADMIN_DEV_PROJECT_SUCCESS =
  "UPDATE_ADMIN_DEV_PROJECT_SUCCESS";

export const DELETE_ADMIN_DEV_PROJECT = "DELETE_ADMIN_DEV_PROJECT";
export const DELETE_ADMIN_DEV_PROJECT_START = "DELETE_ADMIN_DEV_PROJECT_START";
export const DELETE_ADMIN_DEV_PROJECT_FAIL = "DELETE_ADMIN_DEV_PROJECT_FAIL";
export const DELETE_ADMIN_DEV_PROJECT_SUCCESS =
  "DELETE_ADMIN_DEV_PROJECT_SUCCESS";

export const GET_ADMIN_DEV_TOOLS = "GET_ADMIN_DEV_TOOLS";
export const GET_ADMIN_DEV_TOOLS_START = "GET_ADMIN_DEV_TOOLS_START";
export const GET_ADMIN_DEV_TOOLS_FAIL = "GET_ADMIN_DEV_TOOLS_FAIL";
export const GET_ADMIN_DEV_TOOLS_SUCCESS = "GET_ADMIN_DEV_TOOLS_SUCCESS";

export const SAVE_ADMIN_DEV_TOOLS = "SAVE_ADMIN_DEV_TOOLS";
export const SAVE_ADMIN_DEV_TOOLS_START = "SAVE_ADMIN_DEV_TOOLS_START";
export const SAVE_ADMIN_DEV_TOOLS_FAIL = "SAVE_ADMIN_DEV_TOOLS_FAIL";
export const SAVE_ADMIN_DEV_TOOLS_SUCCESS = "SAVE_ADMIN_DEV_TOOLS_SUCCESS";

export const UPDATE_ADMIN_DEV_TOOLS = "UPDATE_ADMIN_DEV_TOOLS";
export const UPDATE_ADMIN_DEV_TOOLS_START = "UPDATE_ADMIN_DEV_TOOLS_START";
export const UPDATE_ADMIN_DEV_TOOLS_FAIL = "UPDATE_ADMIN_DEV_TOOLS_FAIL";
export const UPDATE_ADMIN_DEV_TOOLS_SUCCESS = "UPDATE_ADMIN_DEV_TOOLS_SUCCESS";

export const DELETE_ADMIN_DEV_TOOLS = "DELETE_ADMIN_DEV_TOOLS";
export const DELETE_ADMIN_DEV_TOOLS_START = "DELETE_ADMIN_DEV_TOOLS_START";
export const DELETE_ADMIN_DEV_TOOLS_FAIL = "DELETE_ADMIN_DEV_TOOLS_FAIL";
export const DELETE_ADMIN_DEV_TOOLS_SUCCESS = "DELETE_ADMIN_DEV_TOOLS_SUCCESS";

export const GET_ADMIN_DEV_SKILLS = "GET_ADMIN_DEV_SKILLS";
export const GET_ADMIN_DEV_SKILLS_START = "GET_ADMIN_DEV_SKILLS_START";
export const GET_ADMIN_DEV_SKILLS_FAIL = "GET_ADMIN_DEV_SKILLS_FAIL";
export const GET_ADMIN_DEV_SKILLS_SUCCESS = "GET_ADMIN_DEV_SKILLS_SUCCESS";

export const SAVE_ADMIN_DEV_SKILLS = "SAVE_ADMIN_DEV_SKILLS";
export const SAVE_ADMIN_DEV_SKILLS_START = "SAVE_ADMIN_DEV_SKILLS_START";
export const SAVE_ADMIN_DEV_SKILLS_FAIL = "SAVE_ADMIN_DEV_SKILLS_FAIL";
export const SAVE_ADMIN_DEV_SKILLS_SUCCESS = "SAVE_ADMIN_DEV_SKILLS_SUCCESS";

export const UPDATE_ADMIN_DEV_SKILLS = "UPDATE_ADMIN_DEV_SKILLS";
export const UPDATE_ADMIN_DEV_SKILLS_START = "UPDATE_ADMIN_DEV_SKILLS_START";
export const UPDATE_ADMIN_DEV_SKILLS_FAIL = "UPDATE_ADMIN_DEV_SKILLS_FAIL";
export const UPDATE_ADMIN_DEV_SKILLS_SUCCESS =
  "UPDATE_ADMIN_DEV_SKILLS_SUCCESS";

export const DELETE_ADMIN_DEV_SKILLS = "DELETE_ADMIN_DEV_SKILLS";
export const DELETE_ADMIN_DEV_SKILLS_START = "DELETE_ADMIN_DEV_SKILLS_START";
export const DELETE_ADMIN_DEV_SKILLS_FAIL = "DELETE_ADMIN_DEV_SKILLS_FAIL";
export const DELETE_ADMIN_DEV_SKILLS_SUCCESS =
  "DELETE_ADMIN_DEV_SKILLS_SUCCESS";

export const GET_ADMIN_DEV_ACHIEVEMENT = "GET_ADMIN_DEV_ACHIEVEMENT";
export const GET_ADMIN_DEV_ACHIEVEMENT_START =
  "GET_ADMIN_DEV_ACHIEVEMENT_START";
export const GET_ADMIN_DEV_ACHIEVEMENT_FAIL = "GET_ADMIN_DEV_ACHIEVEMENT_FAIL";
export const GET_ADMIN_DEV_ACHIEVEMENT_SUCCESS =
  "GET_ADMIN_DEV_ACHIEVEMENT_SUCCESS";

export const SAVE_ADMIN_DEV_ACHIEVEMENT = "SAVE_ADMIN_DEV_ACHIEVEMENT";
export const SAVE_ADMIN_DEV_ACHIEVEMENT_START =
  "SAVE_ADMIN_DEV_ACHIEVEMENT_START";
export const SAVE_ADMIN_DEV_ACHIEVEMENT_FAIL =
  "SAVE_ADMIN_DEV_ACHIEVEMENT_FAIL";
export const SAVE_ADMIN_DEV_ACHIEVEMENT_SUCCESS =
  "SAVE_ADMIN_DEV_ACHIEVEMENT_SUCCESS";

export const UPDATE_ADMIN_DEV_ACHIEVEMENT = "UPDATE_ADMIN_DEV_ACHIEVEMENT";
export const UPDATE_ADMIN_DEV_ACHIEVEMENT_START =
  "UPDATE_ADMIN_DEV_ACHIEVEMENT_START";
export const UPDATE_ADMIN_DEV_ACHIEVEMENT_FAIL =
  "UPDATE_ADMIN_DEV_ACHIEVEMENT_FAIL";
export const UPDATE_ADMIN_DEV_ACHIEVEMENT_SUCCESS =
  "UPDATE_ADMIN_DEV_ACHIEVEMENT_SUCCESS";

export const DELETE_ADMIN_DEV_ACHIEVEMENT = "DELETE_ADMIN_DEV_ACHIEVEMENT";
export const DELETE_ADMIN_DEV_ACHIEVEMENT_START =
  "DELETE_ADMIN_DEV_ACHIEVEMENT_START";
export const DELETE_ADMIN_DEV_ACHIEVEMENT_FAIL =
  "DELETE_ADMIN_DEV_ACHIEVEMENT_FAIL";
export const DELETE_ADMIN_DEV_ACHIEVEMENT_SUCCESS =
  "DELETE_ADMIN_DEV_ACHIEVEMENT_SUCCESS";

export const UPDATE_ADMIN_DEV_SHORT_DESC = "UPDATE_ADMIN_DEV_SHORT_DESC";
export const UPDATE_ADMIN_DEV_SHORT_DESC_START =
  "UPDATE_ADMIN_DEV_SHORT_DESC_START";
export const UPDATE_ADMIN_DEV_SHORT_DESC_FAIL =
  "UPDATE_ADMIN_DEV_SHORT_DESC_FAIL";
export const UPDATE_ADMIN_DEV_SHORT_DESC_SUCCESS =
  "UPDATE_ADMIN_DEV_SHORT_DESC_SUCCESS";

export const DELETE_PROFILE_IMAGE_START = "DELETE_PROFILE_IMAGE_START";
export const DELETE_PROFILE_IMAGE_SUCCESS = "DELETE_PROFILE_IMAGE_SUCCESS";
export const DELETE_PROFILE_IMAGE_FAIL = "DELETE_PROFILE_IMAGE_FAIL";
export const DELETE_PROFILE_IMAGE = "DELETE_PROFILE_IMAGE";

export const UPDATE_ADMIN_DEV_PROFILE_IMAGE = "UPDATE_ADMIN_DEV_PROFILE_IMAGE";
export const UPDATE_ADMIN_DEV_PROFILE_IMAGE_START =
  "UPDATE_ADMIN_DEV_PROFILE_IMAGE_START";
export const UPDATE_ADMIN_DEV_PROFILE_IMAGE_FAIL =
  "UPDATE_ADMIN_DEV_PROFILE_IMAGE_FAIL";
export const UPDATE_ADMIN_DEV_PROFILE_IMAGE_SUCCESS =
  "UPDATE_ADMIN_DEV_PROFILE_IMAGE_SUCCESS";

export const DELETE_USER_PROFILE = "DELETE_USER_PROFILE";
export const DELETE_USER_PROFILE_START = "DELETE_USER_PROFILE_START";
export const DELETE_USER_PROFILE_FAIL = "DELETE_USER_PROFILE_FAIL";
export const DELETE_USER_PROFILE_SUCCESS = "DELETE_USER_PROFILE_SUCCESS";

export const BLOCK_USER_PROFILE = "BLOCK_USER_PROFILE";
export const BLOCK_USER_PROFILE_START = "BLOCK_USER_PROFILE_START";
export const BLOCK_USER_PROFILE_FAIL = "BLOCK_USER_PROFILE_FAIL";
export const BLOCK_USER_PROFILE_SUCCESS = "BLOCK_USER_PROFILE_SUCCESS";

//Developer Api
export const DASHBOARD_STEP_ONE_START = "DASHBOARD_STEP_ONE_START";
export const DASHBOARD_STEP_ONE = "DASHBOARD_STEP_ONE";
export const DASHBOARD_STEP_ONE_SUCCESS = "DASHBOARD_STEP_ONE_SUCCESS";
export const DASHBOARD_STEP_ONE_FAIL = "DASHBOARD_STEP_ONE_FAIL";

export const DEV_ACTIVATION = "DEV_ACTIVATION";
export const DEV_ACTIVATION_START = "DEV_ACTIVATION_START";
export const DEV_ACTIVATION_FAIL = "DEV_ACTIVATION_FAIL";
export const DEV_ACTIVATION_SUCCESS = "DEV_ACTIVATION_SUCCESS";

export const GET_DEV_EMAIL_AUTO = "GET_DEV_EMAIL_AUTO";
export const GET_DEV_EMAIL_AUTO_START = "GET_DEV_EMAIL_AUTO_START";
export const GET_DEV_EMAIL_AUTO_SUCCESS = "GET_DEV_EMAIL_AUTO_SUCCESS";
export const GET_DEV_EMAIL_AUTO_FAIL = "GET_DEV_EMAIL_AUTO_FAIL";

export const SET_ACTIVE_AFTER_LOGIN = "SET_ACTIVE_AFTER_LOGIN";

export const SET_TAB_TITLE = "SET_TAB_TITLE";
export const SET_BACK_TAB = "SET_BACK_TAB";
export const SET_NEXT_TAB = "SET_NEXT_TAB";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";

export const SAVE_FAVOURITE_JOB = "SAVE_FAVOURITE_JOB";
export const SAVE_FAVOURITE_JOB_START = "SAVE_FAVOURITE_JOB_START";
export const SAVE_FAVOURITE_JOB_SUCCESS = "SAVE_FAVOURITE_JOB_SUCCESS";
export const SAVE_FAVOURITE_JOB_FAIL = "SAVE_FAVOURITE_JOB_FAIL";

export const GET_FAVOURITE_JOB = "GET_FAVOURITE_JOB";
export const GET_FAVOURITE_JOB_START = "GET_FAVOURITE_JOB_START";
export const GET_FAVOURITE_JOB_FAIL = "GET_FAVOURITE_JOB_FAIL";
export const GET_FAVOURITE_JOB_SUCCESS = "GET_FAVOURITE_JOB_SUCCESS";

export const UPDATE_BASIC_DETAIL = "UPDATE_BASIC_DETAIL";
export const UPDATE_BASIC_DETAIL_START = "UPDATE_BASIC_DETAIL_START";
export const UPDATE_BASIC_DETAIL_FAIL = "UPDATE_BASIC_DETAIL_FAIL";
export const UPDATE_BASIC_DETAIL_SUCCESS = "UPDATE_BASIC_DETAIL_SUCCESS";

export const GET_STEP_COMPLETED_STATUS_START =
  "GET_STEP_COMPLETED_STATUS_START";
export const GET_STEP_COMPLETED_STATUS = "GET_STEP_COMPLETED_STATUS";
export const GET_STEP_COMPLETED_STATUS_SUCCESS =
  "GET_STEP_COMPLETED_STATUS_SUCCESS";
export const GET_STEP_COMPLETED_STATUS_FAIL = "GET_STEP_COMPLETED_STATUS_FAIL";

export const GET_RESUME_START = "GET_RESUME_START";
export const GET_RESUME = "GET_RESUME";
export const GET_RESUME_SUCCESS = "GET_RESUME_SUCCESS";
export const GET_RESUME_FAIL = "GET_RESUME_FAIL";

export const GET_PROFILE_STATUS_START = "GET_PROFILE_STATUS_START";
export const GET_PROFILE_STATUS = "GET_PROFILE_STATUS";
export const GET_PROFILE_STATUS_SUCCESS = "GET_PROFILE_STATUS_SUCCESS";
export const GET_PROFILE_STATUS_FAIL = "GET_PROFILE_STATUS_FAIL";

export const GET_CREATE_PROFILE_START = "GET_CREATE_PROFILE_START";
export const GET_CREATE_PROFILE = "GET_CREATE_PROFILE";
export const GET_CREATE_PROFILE_SUCCESS = "GET_CREATE_PROFILE_SUCCESS";
export const GET_CREATE_PROFILE_FAIL = "GET_CREATE_PROFILE_FAIL";

export const PROFICIENCY_TEST_LINK_START = "PROFICIENCY_TEST_LINK_START";
export const PROFICIENCY_TEST_LINK = "PROFICIENCY_TEST_LINK";
export const PROFICIENCY_TEST_LINK_SUCCESS = "PROFICIENCY_TEST_LINK_SUCCESS";
export const PROFICIENCY_TEST_LINK_FAIL = "PROFICIENCY_TEST_LINK_FAIL";

export const PROFICIENCY_TEST_RESULT_START = "PROFICIENCY_TEST_RESULT_START";
export const PROFICIENCY_TEST_RESULT = "PROFICIENCY_TEST_RESULT";
export const PROFICIENCY_TEST_RESULT_SUCCESS =
  "PROFICIENCY_TEST_RESULT_SUCCESS";
export const PROFICIENCY_TEST_RESULT_FAIL = "PROFICIENCY_TEST_RESULT_FAIL";

export const ENGLISH_TEST_RESULT_START = "ENGLISH_TEST_RESULT_START";
export const ENGLISH_TEST_RESULT = "ENGLISH_TEST_RESULT";
export const ENGLISH_TEST_RESULT_SUCCESS = "ENGLISH_TEST_RESULT_SUCCESS";
export const ENGLISH_TEST_RESULT_FAIL = "ENGLISH_TEST_RESULT_FAIL";

export const SKILL_LIST_START = "SKILL_LIST_START";
export const SKILL_LIST = "SKILL_LIST";
export const SKILL_LIST_SUCCESS = "SKILL_LIST_SUCCESS";
export const SKILL_LIST_FAIL = "SKILL_LIST_FAIL";

export const GET_DEV_SKILL_LIST_START = "GET_DEV_SKILL_LIST_START";
export const GET_DEV_SKILL_LIST = "GET_DEV_SKILL_LIST";
export const GET_DEV_SKILL_LIST_SUCCESS = "GET_DEV_SKILL_LIST_SUCCESS";
export const GET_DEV_SKILL_LIST_FAIL = "GET_DEV_SKILL_LIST_FAIL";

export const GET_ALL_SKILL_LIST_START = "GET_ALL_SKILL_LIST_START";
export const GET_ALL_SKILL_LIST = "GET_ALL_SKILL_LIST";
export const GET_ALL_SKILL_LIST_SUCCESS = "GET_ALL_SKILL_LIST_SUCCESS";
export const GET_ALL_SKILL_LIST_FAIL = "GET_ALL_SKILL_LIST_FAIL";

export const MANAGE_DEV_SKILL_START = "MANAGE_DEV_SKILL_START";
export const MANAGE_DEV_SKILL = "MANAGE_DEV_SKILL";
export const MANAGE_DEV_SKILL_SUCCESS = "MANAGE_DEV_SKILL_SUCCESS";
export const MANAGE_DEV_SKILL_FAIL = "MANAGE_DEV_SKILL_FAIL";

export const UPDATE_AVAILABLE_FROM_START = "UPDATE_AVAILABLE_FROM_START";
export const UPDATE_AVAILABLE_FROM = "UPDATE_AVAILABLE_FROM";
export const UPDATE_AVAILABLE_FROM_SUCCESS = "UPDATE_AVAILABLE_FROM_SUCCESS";
export const UPDATE_AVAILABLE_FROM_FAIL = "UPDATE_AVAILABLE_FROM_FAIL";

export const POST_DEV_STACK_START = "POST_DEV_STACK_START";
export const POST_DEV_STACK = "POST_DEV_STACK";
export const POST_DEV_STACK_SUCCESS = "POST_DEV_STACK_SUCCESS";
export const POST_DEV_STACK_FAIL = "POST_DEV_STACK_FAIL";

export const DEV_CHOOSE_SKILL_START = "DEV_CHOOSE_SKILL_START";
export const DEV_CHOOSE_SKILL = "DEV_CHOOSE_SKILL";
export const DEV_CHOOSE_SKILL_SUCCESS = "DEV_CHOOSE_SKILL_SUCCESS";
export const DEV_CHOOSE_SKILL_FAIL = "DEV_CHOOSE_SKILL_FAIL";

export const DELETE_DEV_SKILL_START = "DELETE_DEV_SKILL_START";
export const DELETE_DEV_SKILL = "DELETE_DEV_SKILL";
export const DELETE_DEV_SKILL_SUCCESS = "DELETE_DEV_SKILL_SUCCESS";
export const DELETE_DEV_SKILL_FAIL = "DELETE_DEV_SKILL_FAIL";

export const STACK_LIST_START = "STACK_LIST_START";
export const STACK_LIST = "STACK_LIST";
export const STACK_LIST_SUCCESS = "STACK_LIST_SUCCESS";
export const STACK_LIST_FAIL = "STACK_LIST_FAIL";

export const CLEAR_RESUME_PROFILE = "CLEAR_RESUME_PROFILE";

export const STACK_LIST_CHOSEN_START = "STACK_LIST_CHOSEN_START";
export const STACK_LIST_CHOSEN = "STACK_LIST_CHOSEN";
export const STACK_LIST_CHOSEN_SUCCESS = "STACK_LIST_CHOSEN_SUCCESS";
export const STACK_LIST_CHOSEN_FAIL = "STACK_LIST_CHOSEN_FAIL";

export const FINALIZE_RESUME_CALL_START = "FINALIZE_RESUME_CALL_START";
export const FINALIZE_RESUME_CALL = "FINALIZE_RESUME_CALL";
export const FINALIZE_RESUME_CALL_SUCCESS = "FINALIZE_RESUME_CALL_SUCCESS";
export const FINALIZE_RESUME_CALL_FAIL = "FINALIZE_RESUME_CALL_FAIL";

export const UPLOAD_PROFILE_IMAGE_START = "UPLOAD_PROFILE_IMAGE_START";
export const UPLOAD_PROFILE_IMAGE = "UPLOAD_PROFILE_IMAGE";
export const UPLOAD_PROFILE_IMAGE_SUCCESS = "UPLOAD_PROFILE_IMAGE_SUCCESS";
export const UPLOAD_PROFILE_IMAGE_FAIL = "UPLOAD_PROFILE_IMAGE_FAIL";

export const RESUME_PARSING_START = "RESUME_PARSING_START";
export const RESUME_PARSING = "RESUME_PARSING";
export const RESUME_PARSING_SUCCESS = "RESUME_PARSING_SUCCESS";
export const RESUME_PARSING_FAIL = "RESUME_PARSING_FAIL";

export const GET_RESUME_PARSER_DATA_START = "GET_RESUME_PARSER_DATA_START";
export const GET_RESUME_PARSER_DATA = "GET_RESUME_PARSER_DATA";
export const GET_RESUME_PARSER_DATA_SUCCESS = "GET_RESUME_PARSER_DATA_SUCCESS";
export const GET_RESUME_PARSER_DATA_FAIL = "GET_RESUME_PARSER_DATA_FAIL";

export const UPLOAD_IMAGE_START = "UPLOAD_IMAGE_START";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAIL = "UPLOAD_IMAGE_FAIL";

export const UPLOAD_MULTI_IMAGE_START = "UPLOAD_MULTI_IMAGE_START";
export const UPLOAD_MULTI_IMAGE = "UPLOAD_MULTI_IMAGE";
export const UPLOAD_MULTI_IMAGE_SUCCESS = "UPLOAD_MULTI_IMAGE_SUCCESS";
export const UPLOAD_MULTI_IMAGE_FAIL = "UPLOAD_MULTI_IMAGE_FAIL";

export const START_TEST_START = "START_TEST_START";
export const START_TEST = "START_TEST";
export const START_TEST_SUCCESS = "START_TEST_SUCCESS";
export const START_TEST_FAIL = "START_TEST_FAIL";

export const FETCH_REPORT_START = "FETCH_REPORT_START";
export const FETCH_REPORT = "FETCH_REPORT";
export const FETCH_REPORT_SUCCESS = "FETCH_REPORT_SUCCESS";
export const FETCH_REPORT_FAIL = "FETCH_REPORT_FAIL";

export const ENGLISH_TEST_START = "ENGLISH_TEST_START";
export const ENGLISH_TEST = "ENGLISH_TEST";
export const ENGLISH_TEST_SUCCESS = "ENGLISH_TEST_SUCCESS";
export const ENGLISH_TEST_FAIL = "ENGLISH_TEST_FAIL";

export const ENGLISH_TEST_CHECK = "ENGLISH_TEST_CHECK";
export const ENGLISH_TEST_CHECK_START = "ENGLISH_TEST_CHECK_START";
export const ENGLISH_TEST_CHECK_FAIL = "ENGLISH_TEST_CHECK_FAIL";
export const ENGLISH_TEST_CHECK_SUCCESS = "ENGLISH_TEST_CHECK_SUCCESS";

export const POST_RESULT_DATA_START = "POST_RESULT_DATA_START";
export const POST_RESULT_DATA = "POST_RESULT_DATA";
export const POST_RESULT_DATA_SUCCESS = "POST_RESULT_DATA_SUCCESS";
export const POST_RESULT_DATA_FAIL = "POST_RESULT_DATA_FAIL";
export const POST_RESULT_DATA_CLOSED_CLICK = "POST_RESULT_DATA_CLOSED_CLICK";

export const SAVE_ENGLISH_ACTIVITY = "SAVE_ENGLISH_ACTIVITY";
export const SAVE_ENGLISH_ACTIVITY_START = "SAVE_ENGLISH_ACTIVITY_START";
export const SAVE_ENGLISH_ACTIVITY_FAIL = "SAVE_ENGLISH_ACTIVITY_FAIL";
export const SAVE_ENGLISH_ACTIVITY_SUCCESS = "SAVE_ENGLISH_ACTIVITY_SUCCESS";

export const GET_ENGLISH_TEST_RESULT_START = "GET_ENGLISH_TEST_RESULT_START";
export const GET_ENGLISH_TEST_RESULT = "GET_ENGLISH_TEST_RESULT";
export const GET_ENGLISH_TEST_RESULT_SUCCESS =
  "GET_ENGLISH_TEST_RESULT_SUCCESS";
export const GET_ENGLISH_TEST_RESULT_FAIL = "GET_ENGLISH_TEST_RESULT_FAIL";

export const ASK_FOR_APPROVAL_START = "ASK_FOR_APPROVAL_START";
export const ASK_FOR_APPROVAL = "ASK_FOR_APPROVAL";
export const ASK_FOR_APPROVAL_SUCCESS = "ASK_FOR_APPROVAL_SUCCESS";
export const ASK_FOR_APPROVAL_FAIL = "ASK_FOR_APPROVAL_FAIL";

export const UPDATE_PAGE_TOUR = "UPDATE_PAGE_TOUR";
export const UPDATE_PAGE_TOUR_SUCCESS = "UPDATE_PAGE_TOUR_SUCCESS";

export const GET_DEV_STATUS_START = "GET_DEV_STATUS_START";
export const GET_DEV_STATUS = "GET_DEV_STATUS";
export const GET_DEV_STATUS_SUCCESS = "GET_DEV_STATUS_SUCCESS";
export const GET_DEV_STATUS_FAIL = "GET_DEV_STATUS_FAIL";

export const GET_RESULT_DEV_START = "GET_RESULT_DEV_START";
export const GET_RESULT_DEV = "GET_RESULT_DEV";
export const GET_RESULT_DEV_SUCCESS = "GET_RESULT_DEV_SUCCESS";
export const GET_RESULT_DEV_FAIL = "GET_RESULT_DEV_FAIL";

export const GET_APPLIED_JOB_START = "GET_APPLIED_JOB_START";
export const GET_APPLIED_JOB = "GET_APPLIED_JOB";
export const GET_APPLIED_JOB_SUCCESS = "GET_APPLIED_JOB_SUCCESS";
export const GET_APPLIED_JOB_FAIL = "GET_APPLIED_JOB_FAIL";

export const GET_SHORT_LIST_JOB_START = "GET_SHORT_LIST_JOB_START";
export const GET_SHORT_LIST_JOB = "GET_SHORT_LIST_JOB";
export const GET_SHORT_LIST_JOB_SUCCESS = "GET_SHORT_LIST_JOB_SUCCESS";
export const GET_SHORT_LIST_JOB_FAIL = "GET_SHORT_LIST_JOB_FAIL";

export const GET_LATEST_JOB_LIST_START = "GET_LATEST_JOB_LIST_START";
export const GET_LATEST_JOB_LIST = "GET_LATEST_JOB_LIST";
export const GET_LATEST_JOB_LIST_SUCCESS = "GET_LATEST_JOB_LIST_SUCCESS";
export const GET_LATEST_JOB_LIST_FAIL = "GET_LATEST_JOB_LIST_FAIL";

export const GET_VETTED_SKILL_START = "GET_VETTED_SKILL_START";
export const GET_VETTED_SKILL = "GET_VETTED_SKILL";
export const GET_VETTED_SKILL_SUCCESS = "GET_VETTED_SKILL_SUCCESS";
export const GET_VETTED_SKILL_FAIL = "GET_VETTED_SKILL_FAIL";

export const GET_JOB_FULL_CLIENT_DEV = "GET_JOB_FULL_CLIENT_DEV";
export const GET_JOB_FULL_CLIENT_DEV_START = "GET_JOB_FULL_CLIENT_DEV_START";
export const GET_JOB_FULL_CLIENT_DEV_FAIL = "GET_JOB_FULL_CLIENT_DEV_FAIL";
export const GET_JOB_FULL_CLIENT_DEV_SUCCESS =
  "GET_JOB_FULL_CLIENT_DEV_SUCCESS";

export const GET_DEV_ACADEMIC = "GET_DEV_ACADEMIC";
export const GET_DEV_ACADEMIC_START = "GET_DEV_ACADEMIC_START";
export const GET_DEV_ACADEMIC_FAIL = "GET_DEV_ACADEMIC_FAIL";
export const GET_DEV_ACADEMIC_SUCCESS = "GET_DEV_ACADEMIC_SUCCESS";

export const SAVE_DEV_ACADEMIC = "SAVE_DEV_ACADEMIC";
export const SAVE_DEV_ACADEMIC_START = "SAVE_DEV_ACADEMIC_START";
export const SAVE_DEV_ACADEMIC_FAIL = "SAVE_DEV_ACADEMIC_FAIL";
export const SAVE_DEV_ACADEMIC_SUCCESS = "SAVE_DEV_ACADEMIC_SUCCESS";

export const UPDATE_DEV_ACADEMIC = "UPDATE_DEV_ACADEMIC";
export const UPDATE_DEV_ACADEMIC_START = "UPDATE_DEV_ACADEMIC_START";
export const UPDATE_DEV_ACADEMIC_FAIL = "UPDATE_DEV_ACADEMIC_FAIL";
export const UPDATE_DEV_ACADEMIC_SUCCESS = "UPDATE_DEV_ACADEMIC_SUCCESS";

export const DELETE_DEV_ACADEMIC = "DELETE_DEV_ACADEMIC";
export const DELETE_DEV_ACADEMIC_START = "DELETE_DEV_ACADEMIC_START";
export const DELETE_DEV_ACADEMIC_FAIL = "DELETE_DEV_ACADEMIC_FAIL";
export const DELETE_DEV_ACADEMIC_SUCCESS = "DELETE_DEV_ACADEMIC_SUCCESS";

export const GET_DEV_CERTIFICATION = "GET_DEV_CERTIFICATION";
export const GET_DEV_CERTIFICATION_START = "GET_DEV_CERTIFICATION_START";
export const GET_DEV_CERTIFICATION_FAIL = "GET_DEV_CERTIFICATION_FAIL";
export const GET_DEV_CERTIFICATION_SUCCESS = "GET_DEV_CERTIFICATION_SUCCESS";

export const SAVE_DEV_CERTIFICATION = "SAVE_DEV_CERTIFICATION";
export const SAVE_DEV_CERTIFICATION_START = "SAVE_DEV_CERTIFICATION_START";
export const SAVE_DEV_CERTIFICATION_FAIL = "SAVE_DEV_CERTIFICATION_FAIL";
export const SAVE_DEV_CERTIFICATION_SUCCESS = "SAVE_DEV_CERTIFICATION_SUCCESS";

export const DELETE_DEV_CERTIFICATION = "DELETE_DEV_CERTIFICATION";
export const DELETE_DEV_CERTIFICATION_START = "DELETE_DEV_CERTIFICATION_START";
export const DELETE_DEV_CERTIFICATION_FAIL = "DELETE_DEV_CERTIFICATION_FAIL";
export const DELETE_DEV_CERTIFICATION_SUCCESS =
  "DELETE_DEV_CERTIFICATION_SUCCESS";

export const UPDATE_DEV_CERTIFICATION = "UPDATE_DEV_CERTIFICATION";
export const UPDATE_DEV_CERTIFICATION_START = "UPDATE_DEV_CERTIFICATION_START";
export const UPDATE_DEV_CERTIFICATION_FAIL = "UPDATE_DEV_CERTIFICATION_FAIL";
export const UPDATE_DEV_CERTIFICATION_SUCCESS =
  "UPDATE_DEV_CERTIFICATION_SUCCESS";

export const GET_DEV_SOCIAL_PROFILE = "GET_DEV_SOCIAL_PROFILE";
export const GET_DEV_SOCIAL_PROFILE_START = "GET_DEV_SOCIAL_PROFILE_START";
export const GET_DEV_SOCIAL_PROFILE_FAIL = "GET_DEV_SOCIAL_PROFILE_FAIL";
export const GET_DEV_SOCIAL_PROFILE_SUCCESS = "GET_DEV_SOCIAL_PROFILE_SUCCESS";

export const SAVE_DEV_SOCIAL_PROFILE = "SAVE_DEV_SOCIAL_PROFILE";
export const SAVE_DEV_SOCIAL_PROFILE_START = "SAVE_DEV_SOCIAL_PROFILE_START";
export const SAVE_DEV_SOCIAL_PROFILE_FAIL = "SAVE_DEV_SOCIAL_PROFILE_FAIL";
export const SAVE_DEV_SOCIAL_PROFILE_SUCCESS =
  "SAVE_DEV_SOCIAL_PROFILE_SUCCESS";

export const UPDATE_DEV_SOCIAL_PROFILE = "UPDATE_DEV_SOCIAL_PROFILE";
export const UPDATE_DEV_SOCIAL_PROFILE_START =
  "UPDATE_DEV_SOCIAL_PROFILE_START";
export const UPDATE_DEV_SOCIAL_PROFILE_FAIL = "UPDATE_DEV_SOCIAL_PROFILE_FAIL";
export const UPDATE_DEV_SOCIAL_PROFILE_SUCCESS =
  "UPDATE_DEV_SOCIAL_PROFILE_SUCCESS";

export const DELETE_DEV_SOCIAL_PROFILE = "DELETE_DEV_SOCIAL_PROFILE";
export const DELETE_DEV_SOCIAL_PROFILE_START =
  "DELETE_DEV_SOCIAL_PROFILE_START";
export const DELETE_DEV_SOCIAL_PROFILE_FAIL = "DELETE_DEV_SOCIAL_PROFILE_FAIL";
export const DELETE_DEV_SOCIAL_PROFILE_SUCCESS =
  "DELETE_DEV_SOCIAL_PROFILE_SUCCESS";

export const GET_DEV_EMPLOYEMENT = "GET_DEV_EMPLOYEMENT";
export const GET_DEV_EMPLOYEMENT_START = "GET_DEV_EMPLOYEMENT_START";
export const GET_DEV_EMPLOYEMENT_SUCCESS = "GET_DEV_EMPLOYEMENT_SUCCESS";
export const GET_DEV_EMPLOYEMENT_FAIL = "GET_DEV_EMPLOYEMENT_FAIL";

export const SAVE_DEV_EMPLOYEMENT = "SAVE_DEV_EMPLOYEMENT";
export const SAVE_DEV_EMPLOYEMENT_START = "SAVE_DEV_EMPLOYEMENT_START";
export const SAVE_DEV_EMPLOYEMENT_SUCCESS = "SAVE_DEV_EMPLOYEMENT_SUCCESS";
export const SAVE_DEV_EMPLOYEMENT_FAIL = "SAVE_DEV_EMPLOYEMENT_FAIL";

export const UPDATE_DEV_EMPLOYEMENT = "UPDATE_DEV_EMPLOYEMENT";
export const UPDATE_DEV_EMPLOYEMENT_START = "UPDATE_DEV_EMPLOYEMENT_START";
export const UPDATE_DEV_EMPLOYEMENT_SUCCESS = "UPDATE_DEV_EMPLOYEMENT_SUCCESS";
export const UPDATE_DEV_EMPLOYEMENT_FAIL = "UPDATE_DEV_EMPLOYEMENT_FAIL";

export const DELETE_DEV_EMPLOYEMENT = "DELETE_DEV_EMPLOYEMENT";
export const DELETE_DEV_EMPLOYEMENT_START = "DELETE_DEV_EMPLOYEMENT_START";
export const DELETE_DEV_EMPLOYEMENT_SUCCESS = "DELETE_DEV_EMPLOYEMENT_SUCCESS";
export const DELETE_DEV_EMPLOYEMENT_FAIL = "DELETE_DEV_EMPLOYEMENT_FAIL";

export const GET_DEV_PROJECT = "GET_DEV_PROJECT";
export const GET_DEV_PROJECT_START = "GET_DEV_PROJECT_START";
export const GET_DEV_PROJECT_FAIL = "GET_DEV_PROJECT_FAIL";
export const GET_DEV_PROJECT_SUCCESS = "GET_DEV_PROJECT_SUCCESS";

export const SAVE_DEV_PROJECT = "SAVE_DEV_PROJECT";
export const SAVE_DEV_PROJECT_START = "SAVE_DEV_PROJECT_START";
export const SAVE_DEV_PROJECT_FAIL = "SAVE_DEV_PROJECT_FAIL";
export const SAVE_DEV_PROJECT_SUCCESS = "SAVE_DEV_PROJECT_SUCCESS";

export const UPDATE_DEV_PROJECT = "UPDATE_DEV_PROJECT";
export const UPDATE_DEV_PROJECT_START = "UPDATE_DEV_PROJECT_START";
export const UPDATE_DEV_PROJECT_FAIL = "UPDATE_DEV_PROJECT_FAIL";
export const UPDATE_DEV_PROJECT_SUCCESS = "UPDATE_DEV_PROJECT_SUCCESS";

export const DELETE_DEV_PROJECT = "DELETE_DEV_PROJECT";
export const DELETE_DEV_PROJECT_START = "DELETE_DEV_PROJECT_START";
export const DELETE_DEV_PROJECT_FAIL = "DELETE_DEV_PROJECT_FAIL";
export const DELETE_DEV_PROJECT_SUCCESS = "DELETE_DEV_PROJECT_SUCCESS";

export const GET_DEV_ALL_TOOLS = "GET_DEV_ALL_TOOLS";
export const GET_DEV_ALL_TOOLS_START = "GET_DEV_ALL_TOOLS_START";
export const GET_DEV_ALL_TOOLS_SUCCESS = "GET_DEV_ALL_TOOLS_SUCCESS";
export const GET_DEV_ALL_TOOLS_SAVE = "GET_DEV_ALL_TOOLS_SAVE";

export const REMOVE_DEV_PROJECT_IMAGE = "REMOVE_DEV_PROJECT_IMAGE";
export const REMOVE_DEV_PROJECT_IMAGE_SUCCESS =
  "REMOVE_DEV_PROJECT_IMAGE_SUCCESS";

export const GET_DEV_TOOLS = "GET_DEV_TOOLS";
export const GET_DEV_TOOLS_START = "GET_DEV_TOOLS_START";
export const GET_DEV_TOOLS_FAIL = "GET_DEV_TOOLS_FAIL";
export const GET_DEV_TOOLS_SUCCESS = "GET_DEV_TOOLS_SUCCESS";

export const SAVE_DEV_TOOLS = "SAVE_DEV_TOOLS";
export const SAVE_DEV_TOOLS_START = "SAVE_DEV_TOOLS_START";
export const SAVE_DEV_TOOLS_FAIL = "SAVE_DEV_TOOLS_FAIL";
export const SAVE_DEV_TOOLS_SUCCESS = "SAVE_DEV_TOOLS_SUCCESS";

export const DELETE_DEV_TOOLS = "DELETE_DEV_TOOLS";
export const DELETE_DEV_TOOLS_START = "DELETE_DEV_TOOLS_START";
export const DELETE_DEV_TOOLS_FAIL = "DELETE_DEV_TOOLS_FAIL";
export const DELETE_DEV_TOOLS_SUCCESS = "DELETE_DEV_TOOLS_SUCCESS";

export const UPDATE_DEV_TOOLS = "UPDATE_DEV_TOOLS";
export const UPDATE_DEV_TOOLS_START = "UPDATE_DEV_TOOLS_START";
export const UPDATE_DEV_TOOLS_FAIL = "UPDATE_DEV_TOOLS_FAIL";
export const UPDATE_DEV_TOOLS_SUCCESS = "UPDATE_DEV_TOOLS_SUCCESS";

export const GET_DEV_SKILLS = "GET_DEV_SKILLS";
export const GET_DEV_SKILLS_START = "GET_DEV_SKILLS_START";
export const GET_DEV_SKILLS_FAIL = "GET_DEV_SKILLS_FAIL";
export const GET_DEV_SKILLS_SUCCESS = "GET_DEV_SKILLS_SUCCESS";

export const SAVE_DEV_SKILLS = "SAVE_DEV_SKILLS";
export const SAVE_DEV_SKILLS_START = "SAVE_DEV_SKILLS_START";
export const SAVE_DEV_SKILLS_FAIL = "SAVE_DEV_SKILLS_FAIL";
export const SAVE_DEV_SKILLS_SUCCESS = "SAVE_DEV_SKILLS_SUCCESS";

export const DELETE_DEV_SKILLS = "DELETE_DEV_SKILLS";
export const DELETE_DEV_SKILLS_START = "DELETE_DEV_SKILLS_START";
export const DELETE_DEV_SKILLS_FAIL = "DELETE_DEV_SKILLS_FAIL";
export const DELETE_DEV_SKILLS_SUCCESS = "DELETE_DEV_SKILLS_SUCCESS";

export const UPDATE_DEV_SKILLS = "UPDATE_DEV_SKILLS";
export const UPDATE_DEV_SKILLS_START = "UPDATE_DEV_SKILLS_START";
export const UPDATE_DEV_SKILLS_FAIL = "UPDATE_DEV_SKILLS_FAIL";
export const UPDATE_DEV_SKILLS_SUCCESS = "UPDATE_DEV_SKILLS_SUCCESS";

export const GET_DEV_ACHIEVEMENT = "GET_DEV_ACHIEVEMENT";
export const GET_DEV_ACHIEVEMENT_START = "GET_DEV_ACHIEVEMENT_START";
export const GET_DEV_ACHIEVEMENT_FAIL = "GET_DEV_ACHIEVEMENT_FAIL";
export const GET_DEV_ACHIEVEMENT_SUCCESS = "GET_DEV_ACHIEVEMENT_SUCCESS";

export const SAVE_DEV_ACHIEVEMENT = "SAVE_DEV_ACHIEVEMENT";
export const SAVE_DEV_ACHIEVEMENT_START = "SAVE_DEV_ACHIEVEMENT_START";
export const SAVE_DEV_ACHIEVEMENT_FAIL = "SAVE_DEV_ACHIEVEMENT_FAIL";
export const SAVE_DEV_ACHIEVEMENT_SUCCESS = "SAVE_DEV_ACHIEVEMENT_SUCCESS";

export const DELETE_DEV_ACHIEVEMENT = "DELETE_DEV_ACHIEVEMENT";
export const DELETE_DEV_ACHIEVEMENT_START = "DELETE_DEV_ACHIEVEMENT_START";
export const DELETE_DEV_ACHIEVEMENT_FAIL = "DELETE_DEV_ACHIEVEMENT_FAIL";
export const DELETE_DEV_ACHIEVEMENT_SUCCESS = "DELETE_DEV_ACHIEVEMENT_SUCCESS";

export const UPDATE_DEV_ACHIEVEMENT = "UPDATE_DEV_ACHIEVEMENT";
export const UPDATE_DEV_ACHIEVEMENT_START = "UPDATE_DEV_SKILLS_START";
export const UPDATE_DEV_ACHIEVEMENT_FAIL = "UPDATE_DEV_SKILLS_FAIL";
export const UPDATE_DEV_ACHIEVEMENT_SUCCESS = "UPDATE_DEV_ACHIEVEMENT_SUCCESS";

export const UPDATE_DEV_DESCRIPTION = "UPDATE_DEV_DESCRIPTION";
export const UPDATE_DEV_DESCRIPTION_START = "UPDATE_DEV_DESCRIPTION_START";
export const UPDATE_DEV_DESCRIPTION_FAIL = "UPDATE_DEV_DESCRIPTION_FAIL";
export const UPDATE_DEV_DESCRIPTION_SUCCESS = "UPDATE_DEV_DESCRIPTION_SUCCESS";

//---------------------FOR CLIENT---------------------------------------
export const CLIENT_SIGNUP_START = "CLIENT_SIGNUP_START";
export const CLIENT_SIGNUP = "CLIENT_SIGNUP";
export const CLIENT_SIGNUP_SUCCESS = "CLIENT_SIGNUP_SUCCESS";
export const CLIENT_SIGNUP_FAIL = "CLIENT_SIGNUP_FAIL";

export const SET_CLIENT_FILTER = "SET_CLIENT_FILTER";

export const CLIENT_INVITE_USER_START = "CLIENT_INVITE_USER_START";
export const CLIENT_INVITE_USER = "CLIENT_INVITE_USER";
export const CLIENT_INVITE_USER_SUCCESS = "CLIENT_INVITE_USER_SUCCESS";
export const CLIENT_INVITE_USER_FAIL = "CLIENT_INVITE_USER_FAIL";

export const GET_EMAIL_COUNT = "GET_EMAIL_COUNT";
export const GET_EMAIL_COUNT_START = "GET_EMAIL_COUNT_START";
export const GET_EMAIL_COUNT_FAIL = "GET_EMAIL_COUNT_FAIL";
export const GET_EMAIL_COUNT_SUCCESS = "GET_EMAIL_COUNT_SUCCESS";

export const SEND_JOB_MAIL = "SEND_JOB_MAIL";
export const SEND_JOB_MAIL_START = "SEND_JOB_MAIL_START";
export const SEND_JOB_MAIL_FAIL = "SEND_JOB_MAIL_FAIL";
export const SEND_JOB_MAIL_SUCCESS = "SEND_JOB_MAIL_SUCCESS";


export const GET_CLIENT_EMAIL = "GET_CLIENT_EMAIL";
export const GET_CLIENT_EMAIL_START = "GET_CLIENT_EMAIL_START";
export const GET_CLIENT_EMAIL_FAIL = "GET_CLIENT_EMAIL_FAIL";
export const GET_CLIENT_EMAIL_SUCCESS = "GET_CLIENT_EMAIL_SUCCESS";

export const GET_JOB_TITLE_LISTS="GET_JOB_TITLE_LISTS";
export const GET_JOB_TITLE_START = "GET_JOB_TITLE_START";
export const GET_JOB_TITLE_FAIL = "GET_JOB_TITLE_FAIL";
export const GET_JOB_TITLE_SUCCESS = "GET_JOB_TITLE_SUCCESS";

export const GET_PLAN_LISTS="GET_PLAN_LISTS";
export const GET_PLAN_LISTS_START = "GET_PLAN_LISTS_START";
export const GET_PLAN_LISTS_FAIL = "GET_PLAN_LISTS_FAIL";
export const GET_PLAN_LISTS_SUCCESS = "GET_PLAN_LISTS_SUCCESS";

export const GET_FEATURE_LIST="GET_FEATURE_LIST";
export const GET_FEATURE_LIST_START = "GET_FEATURE_LIST_START";
export const GET_FEATURE_LIST_FAIL = "GET_FEATURE_LIST_FAIL";
export const GET_FEATURE_LIST_SUCCESS = "GET_FEATURE_LIST_SUCCESS";


export const GET_ADD_ON_LISTS="GET_ADD_ON_LISTS";
export const GET_ADD_ON_LISTS_START = "GET_ADD_ON_LISTS_START";
export const GET_ADD_ON_LISTS_FAIL = "GET_ADD_ON_LISTS_FAIL";
export const GET_ADD_ON_LISTS_SUCCESS = "GET_ADD_ON_LISTS_SUCCESS";

export const ADD_PRICING_FEATURE="ADD_PRICING_FEATURE";
export const ADD_PRICING_FEATURE_START = "ADD_PRICING_FEATURE_START";
export const ADD_PRICING_FEATURE_FAIL = "ADD_PRICING_FEATURE_FAIL";
export const ADD_PRICING_FEATURE_SUCCESS = "ADD_PRICING_FEATURE_SUCCESS";

export const UPDATE_PRICING_FEATURE="UPDATE_PRICING_FEATURE";
export const UPDATE_PRICING_FEATURE_START = "UPDATE_PRICING_FEATURE_START";
export const UPDATE_PRICING_FEATURE_FAIL = "UPDATE_PRICING_FEATURE_FAIL";
export const UPDATE_PRICING_FEATURE_SUCCESS = "UPDATE_PRICING_FEATURE_SUCCESS";

export const ADD_PRICING_PLAN="ADD_PRICING_PLAN";
export const ADD_PRICING_PLAN_START = "ADD_PRICING_PLAN_START";
export const ADD_PRICING_PLAN_FAIL = "ADD_PRICING_PLAN_FAIL";
export const ADD_PRICING_PLAN_SUCCESS = "ADD_PRICING_PLAN_SUCCESS";

export const UPDATE_PRICING_PLAN="UPDATE_PRICING_PLAN";
export const UPDATE_PRICING_PLAN_START = "UPDATE_PRICING_PLAN_START";
export const UPDATE_PRICING_PLAN_FAIL = "UPDATE_PRICING_PLAN_FAIL";
export const UPDATE_PRICING_PLAN_SUCCESS = "UPDATE_PRICING_PLAN_SUCCESS";

export const GET_ADD_ON_LIST="GET_ADD_ON_LIST";
export const GET_ADD_ON_LIST_START = "GET_ADD_ON_LIST_START";
export const GET_ADD_ON_LIST_FAIL = "GET_ADD_ON_LIST_FAIL";
export const GET_ADD_ON_LIST_SUCCESS = "GET_ADD_ON_LIST_SUCCESS";

export const SAVE_ADD_ON="SAVE_ADD_ON";
export const SAVE_ADD_ON_START = "SAVE_ADD_ON_START";
export const SAVE_ADD_ON_FAIL = "SAVE_ADD_ON_FAIL";
export const SAVE_ADD_ON_SUCCESS = "SAVE_ADD_ON_SUCCESS";


export const UPDATE_ADD_ON="UPDATE_ADD_ON";
export const UPDATE_ADD_ON_START = "UPDATE_ADD_ON_START";
export const UPDATE_ADD_ON_FAIL = "UPDATE_ADD_ON_FAIL";
export const UPDATE_ADD_ON_SUCCESS = "UPDATE_ADD_ON_SUCCESS";

export const UPDATE_ADD_ON_STATUS="UPDATE_ADD_ON_STATUS";
export const UPDATE_ADD_ON_STATUS_START = "UPDATE_ADD_ON_STATUS_START";
export const UPDATE_ADD_ON_STATUS_FAIL = "UPDATE_ADD_ON_STATUS_FAIL";
export const UPDATE_ADD_ON_STATUS_SUCCESS = "UPDATE_ADD_ON_STATUS_SUCCESS";


export const UPDATE_PLAN_STATUS="UPDATE_PLAN_STATUS";
export const UPDATE_PLAN_STATUS_START = "UPDATE_PLAN_STATUS_START";
export const UPDATE_PLAN_STATUS_FAIL = "UPDATE_PLAN_STATUS_FAIL";
export const UPDATE_PLAN_STATUS_SUCCESS = "UPDATE_PLAN_STATUS_SUCCESS";

export const GET_PRICING_SLAB="GET_PRICING_SLAB";
export const GET_PRICING_SLAB_START = "GET_PRICING_SLAB_START";
export const GET_PRICING_SLAB_FAIL = "GET_PRICING_SLAB_FAIL";
export const GET_PRICING_SLAB_SUCCESS = "GET_PRICING_SLAB_SUCCESS";

export const ADD_PRICING_SLAB="ADD_PRICING_SLAB";
export const ADD_PRICING_SLAB_START = "ADD_PRICING_SLAB_START";
export const ADD_PRICING_SLAB_FAIL = "ADD_PRICING_SLAB_FAIL";
export const ADD_PRICING_SLAB_SUCCESS = "ADD_PRICING_SLAB_SUCCESS";

export const UPDATE_PRICING_SLAB="UPDATE_PRICING_SLAB";
export const UPDATE_PRICING_SLAB_START = "UPDATE_PRICING_SLAB_START";
export const UPDATE_PRICING_SLAB_FAIL = "UPDATE_PRICING_SLAB_FAIL";
export const UPDATE_PRICING_SLAB_SUCCESS = "UPDATE_PRICING_SLAB_SUCCESS";

export const GET_PENDING_SKILL="GET_PENDING_SKILL";
export const GET_PENDING_SKILL_START = "GET_PENDING_SKILL_START";
export const GET_PENDING_SKILL_FAIL = "GET_PENDING_SKILL_FAIL";
export const GET_PENDING_SKILL_SUCCESS = "GET_PENDING_SKILL_SUCCESS";

export const UPDATE_PENDING_SKILL="UPDATE_PENDING_SKILL";
export const UPDATE_PENDING_SKILL_START = "UPDATE_PENDING_SKILL_START";
export const UPDATE_PENDING_SKILL_FAIL = "UPDATE_PENDING_SKILL_FAIL";
export const UPDATE_PENDING_SKILL_SUCCESS = "UPDATE_PENDING_SKILL_SUCCESS";

export const CLIENT_ACTIVATE_DEACTIVATE_USER_START =
  "CLIENT_ACTIVATE_DEACTIVATE_USER_START";
export const CLIENT_ACTIVATE_DEACTIVATE_USER =
  "CLIENT_ACTIVATE_DEACTIVATE_USER";
export const CLIENT_ACTIVATE_DEACTIVATE_USER_SUCCESS =
  "CLIENT_ACTIVATE_DEACTIVATE_SUCCESS";
export const CLIENT_ACTIVATE_DEACTIVATE_USER_FAIL =
  "CLIENT_ACTIVATE_DEACTIVATE_USER_FAIL";

export const COMPANY_ALL_ACTIVE_USER_START = "COMPANY_ALL_ACTIVE_USER_START";
export const COMPANY_ALL_ACTIVE_USER = "COMPANY_ALL_ACTIVE_USER";
export const COMPANY_ALL_ACTIVE_USER_SUCCESS =
  "COMPANY_ALL_ACTIVE_USER_SUCCESS";
export const COMPANY_ALL_ACTIVE_USER_FAIL = "COMPANY_ALL_ACTIVE_USER_FAIL";

export const CLIENT_GET_PROFILE_START = "CLIENT_GET_PROFILE_START";
export const CLIENT_GET_PROFILE = "CLIENT_GET_PROFILE";
export const CLIENT_GET_PROFILE_SUCCESS = "CLIENT_GET_PROFILE_SUCCESS";
export const CLIENT_GET_PROFILE_FAIL = "CLIENT_GET_PROFILE_FAIL";

export const GET_COMPANY_USER_START = "GET_COMPANY_USER_START";
export const GET_COMPANY_USER = "GET_COMPANY_USER";
export const GET_COMPANY_USER_SUCCESS = "GET_COMPANY_USER_SUCCESS";
export const GET_COMPANY_USER_FAIL = "GET_COMPANY_USER_FAIL";

export const GET_CLIENT_JOBLISTS = "GET_CLIENT_JOBLISTS_LATEST";
export const GET_CLIENT_JOBLISTS_START = "GET_CLIENT_JOBLISTS_LATEST_START";
export const GET_CLIENT_JOBLISTS_FAIL = "GET_CLIENT_JOBLISTS_LATEST_FAIL";
export const GET_CLIENT_JOBLISTS__SUCCESS =
  "GET_CLIENT_JOBLISTS_LATEST_SUCCESS";

export const GET_CLIENT_SAVED_PROFILE = "GET_CLIENT_SAVED_PROFILE";
export const GET_CLIENT_SAVED_PROFILE_START = "GET_CLIENT_SAVED_PROFILE_START";
export const GET_CLIENT_SAVED_PROFILE_FAIL = "GET_CLIENT_SAVED_PROFILE_FAIL";
export const GET_CLIENT_SAVED_PROFILE_SUCCESS =
  "GET_CLIENT_SAVED_PROFILE_SUCCESS";
export const GET_CLIENT_SAVED_PROFILE_ONLY = "GET_CLIENT_SAVED_PROFILE_ONLY";

export const UPDATE_CLIENT_VIEW_PROFILE = "UPDATE_CLIENT_VIEW_PROFILE";
export const UPDATE_CLIENT_VIEW_PROFILE_START =
  "UPDATE_CLIENT_VIEW_PROFILE_START";
export const UPDATE_CLIENT_VIEW_PROFILE_SUCCESS =
  "UPDATE_CLIENT_VIEW_PROFILE_SUCCESS";
export const UPDATE_CLIENT_VIEW_PROFILE_FAIL =
  "UPDATE_CLIENT_VIEW_PROFILE_FAIL";

export const CLEAR_VIEW_FILTER = "CLEAR_VIEW_FILTER";
export const CLEAR_VIEW_FILTER_START = "CLEAR_VIEW_FILTER_START";
export const CLEAR_VIEW_FILTER_SUCCESS = "CLEAR_VIEW_FILTER_SUCCESS";
export const CLEAR_VIEW_FILTER_FAIL = "CLEAR_VIEW_FILTER_FAIL";

export const GET_CLIENT_HIRED_CANDIDATES = "GET_CLIENT_HIRED_CANDIDATES";
export const GET_CLIENT_HIRED_CANDIDATES_START =
  "GET_CLIENT_HIRED_CANDIDATES_START";
export const GET_CLIENT_HIRED_CANDIDATES_FAIL =
  "GET_CLIENT_HIRED_CANDIDATES_FAIL";
export const GET_CLIENT_HIRED_CANDIDATES_SUCCESS =
  "GET_CLIENT_HIRED_CANDIDATES_SUCCESS";

export const GET_CLIENT_ALL_JOBLISTS = "GET_CLIENT_ALL_JOBLISTS";
export const GET_CLIENT_ALL_JOBLISTS_START = "GET_CLIENT_ALL_JOBLISTS_START";
export const GET_CLIENT_ALL_JOBLISTS_FAIL = "GET_CLIENT_ALL_JOBLISTS_FAIL";
export const GET_CLIENT_ALL_JOBLISTS_SUCCESS =
  "GET_CLIENT_ALL_JOBLISTS_SUCCESS";

export const JOB_REFRESH = "JOB_REFRESH";
export const JOB_REFRESH_START = "JOB_REFRESH_START";
export const JOB_REFRESH_FAIL = "JOB_REFRESH_FAIL";

export const GET_CLIENT_ALL_JOBTITLES = "GET_CLIENT_ALL_JOBTITLES";
export const GET_CLIENT_ALL_JOBTITLES_START = "GET_CLIENT_ALL_JOBTITLES_START";
export const GET_CLIENT_ALL_JOBTITLES_FAIL = "GET_CLIENT_ALL_JOBTITLES_FAIL";
export const GET_CLIENT_ALL_JOBTITLES_SUCCESS =
  "GET_CLIENT_ALL_JOBTITLES_SUCCESS";

export const GET_CLIENT_DEV_LISTS = "GET_CLIENT_DEV_LISTS";
export const GET_CLIENT_DEV_LISTS_START = "GET_CLIENT_DEV_LISTS_START";
export const GET_CLIENT_DEV_LISTS_FAIL = "GET_CLIENT_DEV_LISTS_FAIL";
export const GET_CLIENT_DEV_LISTS_SUCCESS = "GET_CLIENT_DEV_LISTS_SUCCESS";

export const UNSAVED_PROFILE = "UNSAVED_PROFILE";
export const UNSAVED_PROFILE_START = "UNSAVED_PROFILE_START";
export const UNSAVED_PROFILE_FAIL = "UNSAVED_PROFILE_FAIL";
export const UNSAVED_PROFILE_SUCCESS = "UNSAVED_PROFILE_SUCCESS";

export const ADD_NEW_JOB_POST = "ADD_NEW_JOB_POST";
export const ADD_NEW_JOB_POST_START = "ADD_NEW_JOB_POST_START";
export const ADD_NEW_JOB_POST_FAIL = "ADD_NEW_JOB_POST_FAIL";
export const ADD_NEW_JOB_POST_SUCCCESS = "ADD_NEW_JOB_POST_SUCCCESS";

export const APPLY_NEW_JOB = "APPLY_NEW_JOB";
export const APPLY_NEW_JOB_START = "APPLY_NEW_JOB_START";
export const APPLY_NEW_JOB_FAIL = "APPLY_NEW_JOB_FAIL";
export const APPLY_NEW_JOB_SUCCESS = "APPLY_NEW_JOB_SUCCESS";

export const APPLY_NEW_MULTI_JOB = "APPLY_NEW_MULTI_JOB";
export const APPLY_NEW_MULTI_JOB_START = "APPLY_NEW_MULTI_JOB_START";
export const APPLY_NEW_MULTI_JOB_SUCCESS = "APPLY_NEW_MULTI_JOB_SUCCESS";
export const APPLY_NEW_MULTI_JOB_FAIL = "APPLY_NEW_MULTI_JOB_FAIL";

export const GET_CLIENT_JOB = "GET_CLIENT_JOB";
export const GET_CLIENT_JOB_START = "GET_CLIENT_JOB_START";
export const GET_CLIENT_JOB_FAIL = "GET_CLIENT_JOB_FAIL";
export const GET_CLIENT_JOB_SUCCESS = "GET_CLIENT_JOB_SUCCESS";

export const UPDATE_CLIENT_JOB = "UPDATE_CLIENT_JOB";
export const UPDATE_CLIENT_JOB_START = "UPDATE_CLIENT_JOB_START";
export const UPDATE_CLIENT_JOB_FAIL = "UPDATE_CLIENT_JOB_FAIL";
export const UPDATE_CLIENT_JOB_SUCCESS = "UPDATE_CLIENT_JOB_SUCCESS";

export const GET_CLIENT_SKILL_LISTS = "GET_CLIENT_SKILL_LISTS";
export const GET_CLIENT_SKILL_LISTS_START = "GET_CLIENT_SKILL_LISTS_START";
export const GET_CLIENT_SKILL_LISTS_FAIL = "GET_CLIENT_SKILL_LISTS_FAIL";
export const GET_CLIENT_SKILL_LISTS_SUCCESS = "GET_CLIENT_SKILL_LISTS_SUCCESS";

export const CLEAR_PROFILE_FEEDBACK = "CLEAR_PROFILE_FEEDBACK";
export const CLEAR_PROFILE_FEEDBACK_START = "CLEAR_PROFILE_FEEDBACK_START";
export const CLEAR_PROFILE_FEEDBACK_FAIL = "CLEAR_PROFILE_FEEDBACK_FAIL";
export const CLEAR_PROFILE_FEEDBACK_SUCCESS = "CLEAR_PROFILE_FEEDBACK_SUCCESS";

export const GET_CLIENT_STACK_LISTS = "GET_CLIENT_STACK_LISTS";
export const GET_CLIENT_STACK_LISTS_START = "GET_CLIENT_STACK_LISTS_START";
export const GET_CLIENT_STACK_LISTS_FAIL = "GET_CLIENT_STACK_LISTS_FAIL";
export const GET_CLIENT_STACK_LISTS_SUCCESS = "GET_CLIENT_STACK_LISTS_SUCCESS";

export const SAVE_CLIENT_HIRING_SKILLS = "SAVE_CLIENT_HIRING_SKILLS";
export const SAVE_CLIENT_HIRING_SKILLS_START =
  "SAVE_CLIENT_HIRING_SKILLS_START";
export const SAVE_CLIENT_HIRING_SKILLS_FAIL = "SAVE_CLIENT_HIRING_SKILLS_FAIL";
export const SAVE_CLIENT_HIRING_SKILLS_SUCCESS =
  "SAVE_CLIENT_HIRING_SKILLS_SUCCESS";

export const CLIENT_DIRECT_CONTACT = "CLIENT_DIRECT_CONTACT";
export const CLIENT_DIRECT_CONTACT_START = "CLIENT_DIRECT_CONTACT_START";
export const CLIENT_DIRECT_CONTACT_FAIL = "CLIENT_DIRECT_CONTACT_FAIL";
export const CLIENT_DIRECT_CONTACT_SUCCESS = "CLIENT_DIRECT_CONTACT_SUCCESS";

export const GET_DEV_DETAILS = "GET_DEV_DETAILS";
export const GET_DEV_DETAILS_START = "GET_DEV_DETAILS_START";
export const GET_DEV_DETAILS_FAIL = "GET_DEV_DETAILS_FAIL";
export const GET_DEV_DETAILS_SUCCESS = "GET_DEV_DETAILS_SUCCESS";

export const SPEAK_WITH_DEV = "SPEAK_WITH_DEV";
export const SPEAK_WITH_DEV_START = "SPEAK_WITH_DEV_START";
export const SPEAK_WITH_DEV_FAIL = "SPEAK_WITH_DEV_FAIL";
export const SPEAK_WITH_DEV_SUCCESS = "SPEAK_WITH_DEV_SUCCESS";

export const GET_SPEAK_WITH_DEV_LIST = "GET_SPEAK_WITH_DEV_LIST";
export const GET_SPEAK_WITH_DEV_LIST_START = "GET_SPEAK_WITH_DEV_LIST_START";
export const GET_SPEAK_WITH_DEV_LIST_FAIL = "GET_SPEAK_WITH_DEV_LIST_FAIL";
export const GET_SPEAK_WITH_DEV_LIST_SUCCESS =
  "GET_SPEAK_WITH_DEV_LIST_SUCCESS";

export const GET_CLIENT_JOB_DETAIL = "GET_CLIENT_JOB_DETAIL";
export const GET_CLIENT_JOB_DETAIL_START = "GET_CLIENT_JOB_DETAIL_START";
export const GET_CLIENT_JOB_DETAIL_FAIL = "GET_CLIENT_JOB_DETAIL_FAIL";
export const GET_CLIENT_JOB_DETAIL_SUCCESS = "GET_CLIENT_JOB_DETAIL_SUCCESS";

export const REMOVE_FROM_SHORT_LIST_CLIENT = "REMOVE_FROM_SHORT_LIST_CLIENT";
export const REMOVE_FROM_SHORT_LIST_CLIENT_START =
  "REMOVE_FROM_SHORT_LIST_CLIENT_START";
export const REMOVE_FROM_SHORT_LIST_CLIENT_FAIL =
  "REMOVE_FROM_SHORT_LIST_CLIENT_FAIL";
export const REMOVE_FROM_SHORT_LIST_CLIENT_SUCCESS =
  "REMOVE_FROM_SHORT_LIST_CLIENT_SUCCESS";

export const REMOVE_FROM_HIRED_LIST_CLIENT = "REMOVE_FROM_HIRED_LIST_CLIENT";
export const REMOVE_FROM_HIRED_LIST_CLIENT_START =
  "REMOVE_FROM_HIRED_LIST_CLIENT_START";
export const REMOVE_FROM_HIRED_LIST_CLIENT_FAIL =
  "REMOVE_FROM_HIRED_LIST_CLIENT_FAIL";
export const REMOVE_FROM_HIRED_LIST_CLIENT_SUCCESS =
  "REMOVE_FROM_HIRED_LIST_CLIENT_SUCCESS";

export const DELETE_CLIENT_JOB = "DELETE_CLIENT_JOB";
export const DELETE_CLIENT_JOB_START = "DELETE_CLIENT_JOB_START";
export const DELETE_CLIENT_JOB_SUCCESS = "DELETE_CLIENT_JOB_SUCCESS";
export const DELETE_CLIENT_JOB_FAIL = "DELETE_CLIENT_JOB_FAIL";

export const ASSIGN_TO_JOB = "ASSIGN_TO_JOB";
export const ASSIGN_TO_JOB_START = "ASSIGN_TO_JOB_START";
export const ASSIGN_TO_JOB_SUCCESS = "ASSIGN_TO_JOB_SUCCESS";
export const ASSIGN_TO_JOB_FAIL = "ASSIGN_TO_JOB_FAIL";

export const UNASSIGN_TO_JOB = "UNASSIGN_TO_JOB";
export const UNASSIGN_TO_JOB_START = "UNASSIGN_TO_JOB_START";
export const UNASSIGN_TO_JOB_SUCCESS = "UNASSIGN_TO_JOB_SUCCESS";
export const UNASSIGN_TO_JOB_FAIL = "UNASSIGN_TO_JOB_FAIL";

export const GET_TOP_FIVE_SKILL = "GET_TOP_FIVE_SKILL";
export const GET_TOP_FIVE_SKILL_START = "GET_TOP_FIVE_SKILL_START";
export const GET_TOP_FIVE_SKILL_FAIL = "GET_TOP_FIVE_SKILL_FAIL";
export const GET_TOP_FIVE_SKILL_SUCCESS = "GET_TOP_FIVE_SKILL_SUCCESS";

export const UPDATE_SHORT_LISTS = "UPDATE_SHORT_LISTS";
export const UPDATE_SHORT_LISTS_START = "UPDATE_SHORT_LISTS_START";
export const UPDATE_SHORT_LISTS_FAIL = "UPDATE_SHORT_LISTS_FAIL";
export const UPDATE_SHORT_LISTS_SUCCESS = "UPDATE_SHORT_LISTS_SUCCESS";

export const UPDATE_APPLIED_JOB_STATUS = "UPDATE_APPLIED_JOB_STATUS";
export const UPDATE_APPLIED_JOB_STATUS_START =
  "UPDATE_APPLIED_JOB_STATUS_START";
export const UPDATE_APPLIED_JOB_STATUS_FAIL = "UPDATE_APPLIED_JOB_STATUS_FAIL";
export const UPDATE_APPLIED_JOB_STATUS_SUCCESS =
  "UPDATE_APPLIED_JOB_STATUS_SUCCESS";

export const SAVE_PROFILE = "SAVE_PROFILE";
export const SAVE_PROFILE_START = "SAVE_PROFILE_START";
export const SAVE_PROFILE_FAIL = "SAVE_PROFILE_FAIL";
export const SAVE_PROFILE_SUCCESS = "SAVE_PROFILE_SUCCESS";

export const GET_ENGLISH_TEST_STATUS = "GET_ENGLISH_TEST_STATUS";
export const GET_ENGLISH_TEST_STATUS_START = "GET_ENGLISH_TEST_STATUS_START";
export const GET_ENGLISH_TEST_STATUS_FAIL = "GET_ENGLISH_TEST_STATUS_FAIL";
export const GET_ENGLISH_TEST_STATUS_SUCCESS =
  "GET_ENGLISH_TEST_STATUS_SUCCESS";

export const POST_ENGLISH_TEST_STATUS = "POST_ENGLISH_TEST_STATUS";
export const POST_ENGLISH_TEST_STATUS_START = "POST_ENGLISH_TEST_STATUS_START";
export const POST_ENGLISH_TEST_STATUS_FAIL = "POST_ENGLISH_TEST_STATUS_FAIL";
export const POST_ENGLISH_TEST_STATUS_SUCCESS =
  "POST_ENGLISH_TEST_STATUS_SUCCESS";

export const UPDATE_ENGLISH_TEST_STATUS = "UPDATE_ENGLISH_TEST_STATUS";
export const UPDATE_ENGLISH_TEST_STATUS_START =
  "UPDATE_ENGLISH_TEST_STATUS_START";
export const UPDATE_ENGLISH_TEST_STATUS_FAIL =
  "UPDATE_ENGLISH_TEST_STATUS_FAIL";
export const UPDATE_ENGLISH_TEST_STATUS_SUCCESS =
  "UPDATE_ENGLISH_TEST_STATUS_SUCCESS";

export const GET_SELECTED_SKILLS = "GET_SELECTED_SKILLS";
export const GET_SELECTED_SKILLS_START = "GET_SELECTED_SKILLS_START";
export const GET_SELECTED_SKILLS_FAIL = "GET_SELECTED_SKILLS_FAIL";
export const GET_SELECTED_SKILLS_SUCCESS = "GET_SELECTED_SKILLS_SUCCESS";

export const UPDATE_CANDIDATE_COMMENT = "UPDATE_CANDIDATE_COMMENT";
export const UPDATE_CANDIDATE_COMMENT_START = "UPDATE_CANDIDATE_COMMENT_START";
export const UPDATE_CANDIDATE_COMMENT_FAIL = "UPDATE_CANDIDATE_COMMENT_FAIL";
export const UPDATE_CANDIDATE_COMMENT_SUCCESS =
  "UPDATE_CANDIDATE_COMMENT_SUCCESS";

export const ADD_CANDIDATE_COMMENT = "ADD_CANDIDATE_COMMENT";
export const ADD_CANDIDATE_COMMENT_START = "ADD_CANDIDATE_COMMENT_START";
export const ADD_CANDIDATE_COMMENT_FAIL = "ADD_CANDIDATE_COMMENT_FAIL";
export const ADD_CANDIDATE_COMMENT_SUCCESS = "ADD_CANDIDATE_COMMENT_SUCCESS";

export const DELETE_CANDIDATE_COMMENT = "DELETE_CANDIDATE_COMMENT";
export const DELETE_CANDIDATE_COMMENT_START = "DELETE_CANDIDATE_COMMENT_START";
export const DELETE_CANDIDATE_COMMENT_FAIL = "DELETE_CANDIDATE_COMMENT_FAIL";
export const DELETE_CANDIDATE_COMMENT_SUCCESS =
  "DELETE_CANDIDATE_COMMENT_SUCCESS";

export const UPDATE_CLIENT_JOB_STATUS = "UPDATE_CLIENT_JOB_STATUS";
export const UPDATE_CLIENT_JOB_STATUS_START = "UPDATE_CLIENT_JOB_STATUS_START";
export const UPDATE_CLIENT_JOB_STATUS_FAIL = "UPDATE_CLIENT_JOB_STATUS_FAIL";
export const UPDATE_CLIENT_JOB_STATUS_SUCCESS =
  "UPDATE_CLIENT_JOB_STATUS_SUCCESS";

export const UPDATE_ADMIN_JOB_STATUS = "UPDATE_ADMIN_JOB_STATUS";
export const UPDATE_ADMIN_JOB_STATUS_START = "UPDATE_ADMIN_JOB_STATUS_START";
export const UPDATE_ADMIN_JOB_STATUS_FAIL = "UPDATE_ADMIN_JOB_STATUS_FAIL";
export const UPDATE_ADMIN_JOB_STATUS_SUCCESS =
  "UPDATE_ADMIN_JOB_STATUS_SUCCESS";

export const ADD_CLIENT_ADD_JOB = "ADD_CLIENT_ADD_JOB";
export const SUCCESS_ADD_CLIENT_ADD_JOB = "SUCCESS_ADD_CLIENT_ADD_JOB";

export const CONTACT_US_POST = "CONTACT_US_POST";
export const CONTACT_US_POST_START = "CONTACT_US_POST_START";
export const CONTACT_US_POST_FAIL = "CONTACT_US_POST_FAIL";
export const CONTACT_US_POST_SUCCESS = "CONTACT_US_POST_SUCCESS";

export const BLOGS_SYNCUP = "BLOGS_SYNCUP";
export const BLOGS_SYNCUP_START = "BLOGS_SYNCUP_START";
export const BLOGS_SYNCUP_FAIL = "BLOGS_SYNCUP_FAIL";
export const BLOGS_SYNCUP_SUCCESS = "BLOGS_SYNCUP_SUCCESS";

export const GET_SPOTLIGHTED_CANDIDATES = "GET_SPOTLIGHTED_CANDIDATES";
export const GET_SPOTLIGHTED_CANDIDATES_START =
  "GET_SPOTLIGHTED_CANDIDATES_START";
export const GET_SPOTLIGHTED_CANDIDATES_FAIL =
  "GET_SPOTLIGHTED_CANDIDATES_FAIL";
export const GET_SPOTLIGHTED_CANDIDATES_SUCCESS =
  "GET_SPOTLIGHTED_CANDIDATES_SUCCESS";

export const UPDATE_SPOTLIGHTED_CANDIDATES = "UPDATE_SPOTLIGHTED_CANDIDATES";
export const UPDATE_SPOTLIGHTED_CANDIDATES_START =
  "UPDATE_SPOTLIGHTED_CANDIDATES_START";
export const UPDATE_SPOTLIGHTED_CANDIDATES_FAIL =
  "UPDATE_SPOTLIGHTED_CANDIDATES_FAIL";
export const UPDATE_SPOTLIGHTED_CANDIDATES_SUCCESS =
  "UPDATE_SPOTLIGHTED_CANDIDATES_SUCCESS";

export const CHECK_AUTHENTICATION_USER = "CHECK_AUTHENTICATION_USER";
export const CHECK_AUTHENTICATION_USER_START =
  "CHECK_AUTHENTICATION_USER_START";
export const CHECK_AUTHENTICATION_USER_FAIL = "CHECK_AUTHENTICATION_USER_FAIL";
export const CHECK_AUTHENTICATION_USER_SUCCESS =
  "CHECK_AUTHENTICATION_USER_SUCCESS";

export const GET_RESUME_ADMIN = "GET_RESUME_ADMIN";
export const GET_RESUME_ADMIN_START = "GET_RESUME_ADMIN_START";
export const GET_RESUME_ADMIN_FAIL = "GET_RESUME_ADMIN_FAIL";
export const GET_RESUME_ADMIN_SUCCESS = "GET_RESUME_ADMIN_SUCCESS";

export const GET_RESUME_OPEN = "GET_RESUME_OPEN";
export const GET_RESUME_OPEN_START = "GET_RESUME_OPEN_START";
export const GET_RESUME_OPEN_FAIL = "GET_RESUME_OPEN_FAIL";
export const GET_RESUME_OPEN_SUCCESS = "GET_RESUME_OPEN_SUCCESS";

export const GET_JOB_BY_ID_LIST = "GET_JOB_BY_ID_LIST";
export const GET_JOB_BY_ID_LIST_START = "GET_JOB_BY_ID_LIST_START";
export const GET_JOB_BY_ID_LIST_FAIL = "GET_JOB_BY_ID_LIST_FAIL";
export const GET_JOB_BY_ID_LIST_SUCCESS = "GET_JOB_BY_ID_LIST_SUCCESS";

export const OPENAI_STANDARD_ENGLISH = "OPENAI_STANDARD_ENGLISH";
export const OPENAI_STANDARD_ENGLISH_START = "OPENAI_STANDARD_ENGLISH_START";
export const OPENAI_STANDARD_ENGLISH_FAIL = "OPENAI_STANDARD_ENGLISH_FAIL";
export const OPENAI_STANDARD_ENGLISH_SUCCESS =
  "OPENAI_STANDARD_ENGLISH_SUCCESS";

export const GET_SITEMAP_TYPE = "GET_SITEMAP_TYPE";
export const GET_SITEMAP_TYPE_START = "GET_SITEMAP_TYPE_START";
export const GET_SITEMAP_TYPE_FAIL = "GET_SITEMAP_TYPE_FAIL";
export const GET_SITEMAP_TYPE_SUCCESS = "GET_SITEMAP_TYPE_SUCCESS";

export const POST_SITEMAP_TYPE = "POST_SITEMAP_TYPE";
export const POST_SITEMAP_TYPE_START = "POST_SITEMAP_TYPE_START";
export const POST_SITEMAP_TYPE_FAIL = "POST_SITEMAP_TYPE_FAIL";
export const POST_SITEMAP_TYPE_SUCCESS = "POST_SITEMAP_TYPE_SUCCESS";

export const UPDATE_SITEMAP_TYPE = "UPDATE_SITEMAP_TYPE";
export const UPDATE_SITEMAP_TYPE_START = "UPDATE_SITEMAP_TYPE_START";
export const UPDATE_SITEMAP_TYPE_FAIL = "UPDATE_SITEMAP_TYPE_FAIL";
export const UPDATE_SITEMAP_TYPE_SUCCESS = "UPDATE_SITEMAP_TYPE_SUCCESS";

export const DELETE_SITEMAP_TYPE = "DELETE_SITEMAP_TYPE";
export const DELETE_SITEMAP_TYPE_START = "DELETE_SITEMAP_TYPE_START";
export const DELETE_SITEMAP_TYPE_FAIL = "DELETE_SITEMAP_TYPE_FAIL";
export const DELETE_SITEMAP_TYPE_SUCCESS = "DELETE_SITEMAP_TYPE_SUCCESS";

export const GET_SPOTLIGHT_HISTORY_START = "GET_SPOTLIGHT_HISTORY_START";
export const GET_SPOTLIGHT_HISTORY = "GET_SPOTLIGHT_HISTORY";
export const GET_SPOTLIGHT_HISTORY_SUCCESS = "GET_SPOTLIGHT_HISTORY_SUCCESS";
export const GET_SPOTLIGHT_HISTORY_FAIL = "GET_SPOTLIGHT_HISTORY_FAIL";

export const JOB_APPROVED_OR_REJECT = "JOB_APPROVED_OR_REJECT";
export const JOB_APPROVED_OR_REJECT_START = "JOB_APPROVED_OR_REJECT_START";
export const JOB_APPROVED_OR_REJECT_SUCCESS = "JOB_APPROVED_OR_REJECT_SUCCESS";
export const JOB_APPROVED_OR_REJECT_FAIL = "JOB_APPROVED_OR_REJECT_FAIL";

export const GET_MAINTENANCE_MESSAGE_START = "GET_MAINTENANCE_MESSAGE_START";
export const GET_MAINTENANCE_MESSAGE = "GET_MAINTENANCE_MESSAGE";
export const GET_MAINTENANCE_MESSAGE_SUCCESS =
  "GET_MAINTENANCE_MESSAGE_SUCCESS";
export const GET_MAINTENANCE_MESSAGE_FAIL = "GET_MAINTENANCE_MESSAGE_FAIL";

export const DELETE_MAINTENANCE_MESSAGE_START =
  "DELETE_MAINTENANCE_MESSAGE_START";
export const DELETE_MAINTENANCE_MESSAGE = "DELETE_MAINTENANCE_MESSAGE";
export const DELETE_MAINTENANCE_MESSAGE_SUCCESS =
  "DELETE_MAINTENANCE_MESSAGE_SUCCESS";
export const DELETE_MAINTENANCE_MESSAGE_FAIL =
  "DELETE_MAINTENANCE_MESSAGE_FAIL";

export const UPDATE_MAINTENANCE_MESSAGE_START =
  "UPDATE_MAINTENANCE_MESSAGE_START";
export const UPDATE_MAINTENANCE_MESSAGE = "UPDATE_MAINTENANCE_MESSAGE";
export const UPDATE_MAINTENANCE_MESSAGE_SUCCESS =
  "UPDATE_MAINTENANCE_MESSAGE_SUCCESS";
export const UPDATE_MAINTENANCE_MESSAGE_FAIL =
  "UPDATE_MAINTENANCE_MESSAGE_FAIL";

export const UPDATE_MAINTENANCE_STATUS_START =
  "UPDATE_MAINTENANCE_STATUS_START";
export const UPDATE_MAINTENANCE_STATUS = "UPDATE_MAINTENANCE_STATUS";
export const UPDATE_MAINTENANCE_STATUS_SUCCESS =
  "UPDATE_MAINTENANCE_STATUS_SUCCESS";
export const UPDATE_MAINTENANCE_STATUS_FAIL = "UPDATE_MAINTENANCE_STATUS_FAIL";

export const ADD_MAINTENANCE_MESSAGE_START = "ADD_MAINTENANCE_MESSAGE_START";
export const ADD_MAINTENANCE_MESSAGE = "ADD_MAINTENANCE_MESSAGE";
export const ADD_MAINTENANCE_MESSAGE_SUCCESS =
  "ADD_MAINTENANCE_MESSAGE_SUCCESS";
export const ADD_MAINTENANCE_MESSAGE_FAIL = "ADD_MAINTENANCE_MESSAGE_FAIL";

export const GET_MAINTENANCE_ONE_MESSAGE_START =
  "GET_MAINTENANCE_ONE_MESSAGE_START";
export const GET_MAINTENANCE_ONE_MESSAGE = "GET_MAINTENANCE_ONE_MESSAGE";
export const GET_MAINTENANCE_ONE_MESSAGE_SUCCESS =
  "GET_MAINTENANCE_ONE_MESSAGE_SUCCESS";
export const GET_MAINTENANCE_ONE_MESSAGE_FAIL =
  "GET_MAINTENANCE_ONE_MESSAGE_FAIL";

export const GET_APPLIED_FOR_JOB = "GET_APPLIED_FOR_JOB";
export const GET_APPLIED_FOR_JOB_START = "GET_APPLIED_FOR_JOB_START";
export const GET_APPLIED_FOR_JOB_SUCCESS = "GET_APPLIED_FOR_JOB_SUCCESS";
export const GET_APPLIED_FOR_JOB_FAIL = "GET_APPLIED_FOR_JOB_FAIL";

export const GET_ALL_JOB_OF_CLIENT = "GET_ALL_JOB_OF_CLIENT";
export const GET_ALL_JOB_OF_CLIENT_START = "GET_ALL_JOB_OF_CLIENT_START";
export const GET_ALL_JOB_OF_CLIENT_SUCCESS = "GET_ALL_JOB_OF_CLIENT_SUCCESS";
export const GET_ALL_JOB_OF_CLIENT_FAIL = "GET_ALL_JOB_OF_CLIENT_FAIL";

export const GET_SHORTLISTED_FOR_JOB = "GET_SHORTLISTED_FOR_JOB";
export const GET_SHORTLISTED_FOR_JOB_START = "GET_SHORTLISTED_FOR_JOB_START";
export const GET_SHORTLISTED_FOR_JOB_SUCCESS =
  "GET_SHORTLISTED_FOR_JOB_SUCCESS";
export const GET_SHORTLISTED_FOR_JOB_FAIL = "GET_SHORTLISTED_FOR_JOB_FAIL";

export const UPDATE_FAQ_STATUS = "UPDATE_FAQ_STATUS";
export const UPDATE_FAQ_STATUS_START = "UPDATE_FAQ_STATUS_START";
export const UPDATE_FAQ_STATUS_SUCCESS = "UPDATE_FAQ_STATUS_SUCCESS";
export const UPDATE_FAQ_STATUS_FAIL = "UPDATE_FAQ_STATUS_FAIL";

export const UPDATE_FAQ_GROUP_STATUS = "UPDATE_FAQ_GROUP_STATUS";
export const UPDATE_FAQ_GROUP_STATUS_START = "UPDATE_FAQ_GROUP_STATUS_START";
export const UPDATE_FAQ_GROUP_STATUS_SUCCESS =
  "UPDATE_FAQ_GROUP_STATUS_SUCCESS";
export const UPDATE_FAQ_GROUP_STATUS_FAIL = "UPDATE_FAQ_GROUP_STATUS_FAIL";

export const DELETE_SKILL_GROUP_NAME = "DELETE_SKILL_GROUP_NAME";
export const DELETE_SKILL_GROUP_NAME_START = "DELETE_SKILL_GROUP_NAME_START";
export const DELETE_SKILL_GROUP_NAME_SUCCESS =
  "DELETE_SKILL_GROUP_NAME_SUCCESS";
export const DELETE_SKILL_GROUP_NAME_FAIL = "DELETE_SKILL_GROUP_NAME_FAIL";

export const GET_CLIENT_FIRST_ENTRY = "GET_CLIENT_FIRST_ENTRY";
export const GET_CLIENT_FIRST_ENTRY_START = "GET_CLIENT_FIRST_ENTRY_START";
export const GET_CLIENT_FIRST_ENTRY_SUCCESS = "GET_CLIENT_FIRST_ENTRY_SUCCESS";
export const GET_CLIENT_FIRST_ENTRY_FAIL = "GET_CLIENT_FIRST_ENTRY_FAIL";

export const UPDATE_CLIENT_FIRST_ENTRY = "UPDATE_CLIENT_FIRST_ENTRY";
export const UPDATE_CLIENT_FIRST_ENTRY_START =
  "UPDATE_CLIENT_FIRST_ENTRY_START";
export const UPDATE_CLIENT_FIRST_ENTRY_SUCCESS =
  "UPDATE_CLIENT_FIRST_ENTRY_SUCCESS";
export const UPDATE_CLIENT_FIRST_ENTRY_FAIL = "UPDATE_CLIENT_FIRST_ENTRY_FAIL";

export const UPDATE_ASSESSMENT_NAME = "UPDATE_ASSESSMENT_NAME";
export const UPDATE_ASSESSMENT_NAME_START = "UPDATE_ASSESSMENT_NAME_START";
export const UPDATE_ASSESSMENT_NAME_SUCCESS = "UPDATE_ASSESSMENT_NAME_SUCCESS";
export const UPDATE_ASSESSMENT_NAME_FAIL = "UPDATE_ASSESSMENT_NAME_FAIL";

export const CLIENT_EMAIL_AUTO = "CLIENT_EMAIL_AUTO";
export const CLIENT_EMAIL_AUTO_START = "CLIENT_EMAIL_AUTO_START";
export const CLIENT_EMAIL_AUTO_FAIL = "CLIENT_EMAIL_AUTO_FAIL";
export const CLIENT_EMAIL_AUTO_SUCCESS = "CLIENT_EMAIL_AUTO_SUCCESS";

export const CLIENT_ACTIVATION_SUCCESS = "CLIENT_ACTIVATION_SUCCESS";

export const UPDATE_EMAIL_SETTING_STATUS = "UPDATE_EMAIL_SETTING_STATUS";
export const UPDATE_EMAIL_SETTING_STATUS_START =
  "UPDATE_EMAIL_SETTING_STATUS_START";
export const UPDATE_EMAIL_SETTING_STATUS_SUCCESS =
  "UPDATE_EMAIL_SETTING_STATUS_SUCCESS";
export const UPDATE_EMAIL_SETTING_STATUS_FAIL =
  "UPDATE_EMAIL_SETTING_STATUS_FAIL";

export const GET_SKILL_CATEGORY = "GET_SKILL_CATEGORY";
export const GET_SKILL_CATEGORY_START = "GET_SKILL_CATEGORY_START";
export const GET_SKILL_CATEGORY_SUCCESS = "GET_SKILL_CATEGORY_SUCCESS";
export const GET_SKILL_CATEGORY_FAIL = "GET_SKILL_CATEGORY_FAIL";

export const GET_SKILL_CATEGORY_LISTS = "GET_SKILL_CATEGORY_LISTS";
export const GET_SKILL_CATEGORY_LISTS_START = "GET_SKILL_CATEGORY_LISTS_START";
export const GET_SKILL_CATEGORY_LISTS_SUCESS =
  "GET_SKILL_CATEGORY_LISTS_SUCESS";
export const GET_SKILL_CATEGORY_LISTS_FAIL = "GET_SKILL_CATEGORY_LISTS_FAIL";

export const GET_STACK_CATEGORY_LISTS = "GET_STACK_CATEGORY_LISTS";
export const GET_STACK_CATEGORY_LISTS_START = "GET_STACK_CATEGORY_LISTS_START";
export const GET_STACK_CATEGORY_LISTS_SUCESS =
  "GET_STACK_CATEGORY_LISTS_SUCESS";
export const GET_STACK_CATEGORY_LISTS_FAIL = "GET_STACK_CATEGORY_LISTS_FAIL";

export const CREATE_SKILLS_CATEGORY = "CREATE_SKILLS_CATEGORY";
export const CREATE_SKILLS_CATEGORY_START = "CREATE_SKILLS_CATEGORY_START";
export const CREATE_SKILLS_CATEGORY_SUCCESS = "CREATE_SKILLS_CATEGORY_SUCCESS";
export const CREATE_SKILLS_CATEGORY_FAIL = "CREATE_SKILLS_CATEGORY_FAIL";

export const UPDATE_SKILLS_CATEGORY = "UPDATE_SKILLS_CATEGORY";
export const UPDATE_SKILLS_CATEGORY_START = "UPDATE_SKILLS_CATEGORY_START";
export const UPDATE_SKILLS_CATEGORY_SUCCESS = "UPDATE_SKILLS_CATEGORY_SUCCESS";
export const UPDATE_SKILLS_CATEGORY_FAIL = "UPDATE_SKILLS_CATEGORY_FAIL";

//Interview
export const SET_INTERVIEWS_SLOT_DEV = "SET_INTERVIEWS_SLOT_DEV";
export const SET_INTERVIEWS_SLOT_DEV_START = "SET_INTERVIEWS_SLOT_DEV_START ";
export const SET_INTERVIEWS_SLOT_DEV_SUCCESS =
  "SET_INTERVIEWS_SLOT_DEV_SUCCESS";
export const SET_INTERVIEWS_SLOT_DEV_FAIL = "SET_INTERVIEWS_SLOT_DEV_FAIL";

export const SCHEDULE_INTERVIEW = "SCHEDULE_INTERVIEW";
export const SCHEDULE_INTERVIEW_START = "SCHEDULE_INTERVIEW_START ";
export const SCHEDULE_INTERVIEW_SUCCESS = "SCHEDULE_INTERVIEW_SUCCESS";
export const SCHEDULE_INTERVIEW_FAIL = "SCHEDULE_INTERVIEW_FAIL";

export const ACCEPT_INTERVIEW = "ACCEPT_INTERVIEW";
export const ACCEPT_INTERVIEW_START = "ACCEPT_INTERVIEW_START ";
export const ACCEPT_INTERVIEW_SUCCESS = "ACCEPT_INTERVIEW_SUCCESS";
export const ACCEPT_INTERVIEW_FAIL = "ACCEPT_INTERVIEW_FAIL";

export const RESCHEDULE_INTERVIEW = "RESCHEDULE_INTERVIEW";
export const RESCHEDULE_INTERVIEW_BY_DEV = "RESCHEDULE_INTERVIEW_BY_DEV";
export const RESCHEDULE_INTERVIEW_START = "RESCHEDULE_INTERVIEW_START ";
export const RESCHEDULE_INTERVIEW_SUCCESS = "RESCHEDULE_INTERVIEW_SUCCESS";
export const RESCHEDULE_INTERVIEW_FAIL = "RESCHEDULE_INTERVIEW_FAIL";

export const CANCEL_INTERVIEW = "CANCEL_INTERVIEW";
export const CANCEL_INTERVIEW_BY_DEV = "CANCEL_INTERVIEW_BY_DEV";
export const CANCEL_INTERVIEW_START = "CANCEL_INTERVIEW_START ";
export const CANCEL_INTERVIEW_SUCCESS = "CANCEL_INTERVIEW_SUCCESS";
export const CANCEL_INTERVIEW_FAIL = "CANCEL_INTERVIEW_FAIL";

export const GET_INTERVIEWS_DATA = "GET_INTERVIEWS_DATA";
export const GET_INTERVIEWS_DATA_START = "GET_INTERVIEWS_DATA_START ";
export const GET_INTERVIEWS_DATA_SUCCESS = "GET_INTERVIEWS_DATA_SUCCESS";
export const GET_INTERVIEWS_DATA_FAIL = "GET_INTERVIEWS_DATA_FAIL";

export const GET_INTERVIEWS_DATA_DEV = "GET_INTERVIEWS_DATA_DEV";
export const GET_INTERVIEWS_DATA_DEV_START = "GET_INTERVIEWS_DATA_DEV_START ";
export const GET_INTERVIEWS_DATA_DEV_SUCCESS =
  "GET_INTERVIEWS_DATA_DEV_SUCCESS";
export const GET_INTERVIEWS_DATA_DEV_FAIL = "GET_INTERVIEWS_DATA_DEV_FAIL";

export const GET_DEV_FILTER = "GET_DEV_FILTER";
export const GET_DEV_FILTER_START = "GET_DEV_FILTER_START ";
export const GET_DEV_FILTER_SUCCESS = "GET_DEV_FILTER_SUCCESS";
export const GET_DEV_FILTER_FAIL = "GET_DEV_FILTER_FAIL";

export const UPDATE_SNOOZE = "UPDATE_SNOOZE";
export const UPDATE_SNOOZE_START = "UPDATE_SNOOZE_START";
export const UPDATE_SNOOZE_FAIL = "UPDATE_SNOOZE_FAIL";
export const UPDATE_SNOOZE_SUCCESS = "UPDATE_SNOOZE_SUCCESS";

export const GET_SUMMARY_SUBSCRIPTION = "GET_SUMMARY_SUBSCRIPTION";
export const GET_SUMMARY_SUBSCRIPTION_START = "GET_SUMMARY_SUBSCRIPTION_START ";
export const GET_SUMMARY_SUBSCRIPTION_SUCCESS = "GET_SUMMARY_SUBSCRIPTION_SUCCESS";
export const GET_SUMMARY_SUBSCRIPTION_FAIL = "GET_SUMMARY_SUBSCRIPTION_FAIL";

export const GET_CLIENT_PAYMENT_HISTORY = "GET_CLIENT_PAYMENT_HISTORY";
export const GET_CLIENT_PAYMENT_HISTORY_START = "GET_CLIENT_PAYMENT_HISTORY_START ";
export const GET_CLIENT_PAYMENT_HISTORY_SUCCESS = "GET_CLIENT_PAYMENT_HISTORY_SUCCESS";
export const GET_CLIENT_PAYMENT_HISTORY_FAIL = "GET_CLIENT_PAYMENT_HISTORY_FAIL";

export const GET_CANCEL_ACTIVE_PLAN = "GET_CANCEL_ACTIVE_PLAN";
export const GET_CANCEL_ACTIVE_PLAN_START = "GET_CANCEL_ACTIVE_PLAN_START ";
export const GET_CANCEL_ACTIVE_PLAN_SUCCESS = "GET_CANCEL_ACTIVE_PLAN_SUCCESS";
export const GET_CANCEL_ACTIVE_PLAN_FAIL = "GET_CANCEL_ACTIVE_PLAN_FAIL";

export const CHECK_CLIENT_RESTRICTION_PAGE="CHECK_CLIENT_RESTRICTION_PAGE";

export const DELETE_EMAIL = "DELETE_EMAIL";
export const DELETE_EMAIL_START = "DELETE_EMAIL_START ";
export const DELETE_EMAIL_SUCCESS = "DELETE_EMAIL_SUCCESS";
export const DELETE_EMAIL_FAIL = "DELETE_EMAIL_FAIL";