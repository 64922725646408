import React, { useEffect, useState } from 'react'
import CheckIcon from "../../assets/img/icons/checked.png"
import BoxCollapse from '../box-collapse/BoxCollapse'
import { questionriesConstant, } from '../../common/constant/questionaries/questionaries'
import { clearQuestionaries } from '../functions'

const Questionaries = ({ handleSubmit, questionaries ,cancelBtn}) => {
    const [question, setQuestion] = useState([]);

    useEffect(() => {
        setQuestion(clearQuestionaries(questionriesConstant))
    }, [questionriesConstant])

    useEffect(()=>{
        questionaries?.data?.length && setQuestion(questionaries?.data)
    },[questionaries])

    const [editable,setEditable]=useState([])
    const [errors,setErrors]=useState([])

     const submit=()=>{
        if(!errors?.length && (editable?.length ? question[editable[0]].questionLists[editable[1]].question!="":true)) {
            handleSubmit(question)
        }else{
            setErrors([editable[0],editable[1]])
        }
     }
    return (
        <div className='questionaries-area'>
            <div className='row header-section'>
                <div className='col-8'>
                    <p className='header-title'>Select Questionnaire for candidates</p>
                </div>
                <div className='col-4 text-right'>
                    <p> <img src={CheckIcon} className='checked-header' />Required Questions</p>
                </div>

            </div>
            <div className='box-items'>
                {
                    question?.map((itm, index) => (
                        <BoxCollapse
                            data={itm}
                            editable={editable}
                            setEditable={setEditable}
                            index={index}
                            questionaries={question}
                            errors={errors}
                            setErrors={setErrors}
                            setQuestionaries={setQuestion}
                        />
                    ))
                }

            </div>

            <div className='row'>
                <div className='col-12 text-right '>
                     <button className='cancelBtn' type='button'  onClick={cancelBtn}>Cancel</button>
                    <button className='submitBtn' type='button' onClick={submit}>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default Questionaries