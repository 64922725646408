import React from "react";
import PropType from "prop-types";
import ChipsArray from "./common/ChipsArray";
const ToolSection = ({ result }) => {
  return !!result?.length && (
    <div className="sidebar-widget">
      <h4 className="widget-title">Tools</h4>
      <div className="widget-content">
          <ChipsArray chipData={result} />
      </div>
    </div>
  );
};
ToolSection.propTypes = {
    result: PropType.array,
};
export default ToolSection;
