import React, { Fragment, useContext, useEffect, useState } from "react";
import { Icon } from "../icon";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { devActivation } from "../../service/action/developer";
import { MainContext } from "../../context/MainContext";
import { getClientEmailSettings } from "../../service/action/client";

const Client = ({ openModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    user: { _id },
  } = useContext(MainContext);
  const { devActivations } = useSelector((state) => state?.clientReducer);
  const handleActivation = (slug, val) => {
    let payload = {
      emailType: slug,
      userId: _id,
      status: val,
    };
    dispatch(devActivation(navigate, payload, "client"));
  };

  useEffect(() => {
    dispatch(getClientEmailSettings(navigate));
  }, []);
  const { user } = useContext(MainContext);
  return (
    <div id="account-setting">
      <div className="setting-card">
        <h2>
          Hi, {user?.fName} {user?.lName}
        </h2>
        <div className="row">
          <div className="col-4">
            <h3>
              Email
              {/* <span onClick={() => openModal("email")}><Icon name="edit" /></span> */}
            </h3>
            <p>{user?.email ? user?.email : "N/A"}</p>
          </div>
          <div className="col-4">
            <h3>
              Phone Number
              {/* <span onClick={() => openModal("number")}>
                    <Icon name="edit" />
                  </span> */}
            </h3>

            <p>
              {" "}
              {user?.number ? <> {"+ 91 ".concat(user?.number)}</> : "N/A"}
            </p>
          </div>
          <div className="col-4">
            <h3>
              Password
              <span onClick={() => openModal("password")}>
                <Icon name="edit" />
              </span>
            </h3>
            <p>********</p>
          </div>
        </div>
      </div>

      {!!devActivations?.data?.length && (
        <div className="setting-card setting-jobs-skill">
          <h3>Email Alerts</h3>
          {devActivations?.data?.map((item) => (
            <>
              <h4>{item?.settingTitle}</h4>
              <div className="row">
                <div className="col-11">
                  <p className="noti">{item?.SettingSubTitle}</p>
                </div>
                <div className="col-1">
                  <span
                    onClick={() => handleActivation(item?.slug, !item?.status)}
                  >
                    <input
                      type="checkbox"
                      name="switch"
                      checked={!!item?.status}
                    />
                    <label className="label" htmlFor="switch3"></label>
                  </span>
                </div>
              </div>
            </>
          ))}
        </div>
      )}

      <div className="row">
        <div className="col-12 text-right">
          <button
            className="btn"
            onClick={() => navigate("/client/client-dashboard")}
          >
            back
          </button>
        </div>
      </div>
      {/* <div className="setting-card">
                        <div className="row">
                            <div className="col-12 text-center">
                                <a href="#" className="logout">Sign Out</a>
                            </div>
                        </div>
                    </div> */}
    </div>
  );
};

export default Client;
