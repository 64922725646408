import * as types from "../";

export const getDeveloperLists = (
  data,
  navigate,
  limit = 0,
  page = 0,
  term = "",
  apiType
) => ({
  type: types.GET_DEVELOPER_LIST,
  data,
  navigate,
  limit,
  page,
  term,
  apiType
});

export const jobApprovedOrRejectAction = (
  data,
  navigate,
  setApprovalConfirm,
  handleGetAllJob,
  pageData,
  socket
) => ({
  type: types.JOB_APPROVED_OR_REJECT,
  data,
  navigate,
  setApprovalConfirm,
  handleGetAllJob,
  pageData,
  socket
});

export const getJobCatAction = (navigate) => ({
  type: types.GET_JOB_CAT,
  navigate,
});

export const getAllEmailSlugsAction = (navigate) => ({
  type: types.GET_ALL_EMAILS_SLUGS,
  navigate,
});

export const updatePlagiarismAction = (data ,navigate, setPlag, devId) => ({
  type: types.UPDATE_PLAGIARISM_STATUS,
  navigate,
  data,
  setPlag,
  devId
});

export const postJobCatAction = (data, navigate, closeModal) => ({
  type: types.ADD_JOB_CAT,
  data,
  navigate,
  closeModal,
});

export const deleteJobCatAction = (jobCatId, navigate, closeModal) => ({
  type: types.DELETE_JOB_CAT,
  jobCatId,
  navigate,
  closeModal,
});

export const updateJobCatAction = (jobCatId, data, navigate, closeModal) => ({
  type: types.UPDATE_JOB_CAT,
  jobCatId,
  data,
  navigate,
  closeModal,
});

export const searchStartAction = () => ({
  type: types.GET_DEVELOPER_LIST_START,
});

export const devUpdateStatus = (data, navigate) => ({
  type: types.DEV_UPDATE_STATUS,
  data,
  navigate,
});

export const getAdminSkillLists = (skillType, navigate, pageData) => ({
  type: types.GET_ADMIN_SKILL_LIST,
  skillType,
  navigate,
  pageData,
});

export const updateSkillStatus = (data, navigate, category) => ({
  type: types.UPDATE_SKILL_STATUS,
  data,
  navigate,
  category,
});

export const addStack = (data, navigate, closeModal) => ({
  type: types.ADD_STACK,
  data,
  navigate,
  closeModal,
});

export const editStack = (data, navigate, closeModal) => ({
  type: types.EDIT_STACK,
  data,
  navigate,
  closeModal,
});

export const getAdminStackLists = (stack, navigate, pageData) => ({
  type: types.GET_ADMIN_STACK_LIST,
  stack,
  navigate,
  pageData,
});

export const getAdminSkillResult = (navigate, email) => ({
  type: types.GET_ADMIN_DEV_SKILL_RESULT,
  email,
  navigate,
});

export const updateAdminSkillTest = (navigate, data) => ({
  type: types.UPDATE_ADMIN_SKILL_TEST,
  data,
  navigate,
});
export const getSkillOrStack = (navigate, data) => ({
  type: types.GET_ADMIN_STACK_SKILL,
  data,
  navigate,
});

export const updateStackStatus = (data, navigate, category) => ({
  type: types.UPDATE_STACK_STATUS,
  data,
  navigate,
  category,
});

export const refreshXobinAssessment = (navigate) => ({
  type: types.REFRESH_XOBIN_ASSESSMENT,
  navigate,
});

export const renderAllHackerrankAssessments = (navigate, offset = 0) => ({
  type: types.RENDER_ALL_HACKER_RANK_ASSESSMENT,
  offset,
  navigate,
});

export const renderAllHackerrankAssessmentsNoLimitAction = (navigate) => ({
  type: types.RENDER_ALL_HACKER_RANK_ASSESSMENT_NO_LIMIT,
  navigate,
});

export const addSkill = (
  data,
  navigate,
  skillType = "skill",
  pageData,
  closeModal,
  resetForm
) => ({
  type: types.ADD_SKILL,
  data,
  navigate,
  skillType,
  pageData,
  closeModal,
  resetForm,
});

export const editSkill = (
  data,
  navigate,
  skillType = "skill",
  pageData,
  closeModal,
  resetForm
) => ({
  type: types.EDIT_SKILL,
  data,
  navigate,
  skillType,
  pageData,
  closeModal,
  resetForm,
});

export const renderTopicList = (navigate, offset = 0) => ({
  type: types.RENDER_TOPIC_LIST,
  offset,
  navigate,
});

export const getAllIcons = (navigate, offset = 0) => ({
  type: types.GET_ALL_ICONS,
  offset,
  navigate,
});

export const getAllTools = (navigate, pageData) => ({
  type: types.ADMIN_ALL_TOOLS,
  navigate,
  pageData,
});

export const getAllPerkAndBenefit = (navigate, forType = null) => ({
  type: types.GET_ALL_PERK_AND_BENEFIT,
  navigate,
  forType,
});
export const savePerkAndBenefit = (navigate, data, close) => ({
  type: types.SAVE_PERK_AND_BENEFIT,
  data,
  close,
  navigate,
});
export const deletePerkAndBenefit = (navigate, data) => ({
  type: types.DELETE_PERK_AND_BENEFIT,
  data,
  navigate,
});
export const updatePerkAndBenefit = (navigate, data, id, closeModal) => ({
  type: types.UPDATE_PERK_AND_BENEFIT,
  data,
  navigate,
  close: closeModal,
  id,
});
export const deleteTool = (id, navigate, pageData) => ({
  type: types.ADMIN_DELETE_TOOL,
  id,
  pageData,
  navigate,
});

export const addTool = (data, navigate, pageData, closeModal, resetForm) => ({
  type: types.ADMIN_ADD_TOOL,
  data,
  pageData,
  closeModal,
  resetForm,
  navigate,
});
export const updateTool = (
  data,
  navigate,
  pageData,
  closeModal,
  resetForm
) => ({
  type: types.ADMIN_UPDATE_TOOL,
  data,
  pageData,
  navigate,
  closeModal,
  resetForm,
});

export const uploadIcons = (data, navigate) => ({
  type: types.UPLOAD_ICONS,
  data,
  navigate,
});

export const deleteStack = (stackId, navigate, offset = 0) => ({
  type: types.DELETE_STACK,
  stackId,
  navigate,
  offset,
});

export const deleteSkill = (
  skillId,
  navigate,
  skillType = "skill",
  pageData
) => ({
  type: types.DELETE_SKILL,
  skillId,
  navigate,
  skillType,
  pageData,
});

export const getDeveloperProfile = (devId, navigate, setModal = false) => ({
  type: types.GET_DEVELOPER_PROFILE,
  devId,
  navigate,
  setModal,
});

export const fresherAction = (userId, navigate) => ({
  type: types.FRESHER,
  userId,
  navigate,
});

export const updateProfileStatus = (
  userName,
  data,
  navigate,
  page = 0,
  limit = 0,
  filter = null,
  setModal = false
) => ({
  type: types.UPDATE_PROFILE_STATUS,
  userName,
  data,
  navigate,
  page,
  limit,
  filter,
  setModal,
});

export const getDevenglishResult = (data, navigate) => ({
  type: types.GET_ADMIN_DEV_ENGLISH_RESULT,
  data,
  navigate,
});
export const updateDevEnglishResult = (data, navigate) => ({
  type: types.UPDATE_ADMIN_DEV_ENGLISH_RESULT,
  data,
  navigate,
});
export const adminResumeParsing = (
  data,
  navigate,
  devId,
  setResume = null
) => ({
  type: types.RESUME_PARSING_ADMIN,
  data,
  navigate,
  devId,
  setResume,
});

export const resultParsing = (data, navigate) => ({
  type: types.RESULT_PARSING,
  data,
  navigate,
});

export const setMemberPassword = (data, navigate) => ({
  type: types.SET_MEMBER_PASSWORD,
  data,
  navigate,
});

export const addUser = (data, navigate, setDetails, resetForm) => ({
  type: types.ADD_USER,
  data,
  navigate,
  setDetails,
  resetForm,
});

export const getUser = (userId, navigate) => ({
  type: types.GET_USER,
  userId,
  navigate,
});

export const updateUser = (
  userId,
  data,
  pageData,
  setDetails,
  resetForm,
  navigate
) => ({
  type: types.UPDATE_USER,
  setDetails,
  pageData,
  resetForm,
  userId,
  data,
  navigate,
});
export const updateUserStatus = (userId, status, navigate) => ({
  type: types.UPDATE_USER_STATUS,
  userId,
  status,
  navigate,
});

export const deleteUser = (userId, setDetails, resetForm, navigate) => ({
  type: types.DELETE_USER,
  userId,
  navigate,
  setDetails,
  resetForm,
});

export const userListing = (navigate, pageData) => ({
  type: types.USER_LISTING,
  navigate,
  pageData,
});

export const getResultDev = (userId, navigate) => ({
  type: types.GET_DEV_RESULT,
  userId,
  navigate,
});

export const getCategoryList = (navigate) => ({
  type: types.GET_CATEGORY_LIST,
  navigate,
});

export const addCategoryBlog = (data, navigate) => ({
  type: types.ADD_CATEGORY_BLOG,
  data,
  navigate,
});

export const deleteCategoryBlog = (categoryId, navigate) => ({
  type: types.DELETE_CATEGORY_BLOG,
  categoryId,
  navigate,
});

export const updateCategoryBlog = (categoryId, data, navigate) => ({
  type: types.UPDATE_CATEGORY_BLOG,
  categoryId,
  data,
  navigate,
});

export const getBlogList = (navigate) => ({
  type: types.GET_BLOG_LIST,
  navigate,
});

export const postBlogCat = (navigate, data, resetForm) => ({
  type: types.POST_BLOG_CAT,
  data,
  navigate,
  resetForm,
});

export const deleteBlogCat = (categoryId, setModal, navigate) => ({
  type: types.DELETE_BLOG_CAT,
  categoryId,
  setModal,
  navigate,
});

export const updateBlogCat = (categoryId, data, setModal, navigate) => ({
  type: types.UPDATE_BLOG_CAT,
  categoryId,
  data,
  setModal,
  navigate,
});

export const postBlogNew = (data, navigate) => ({
  type: types.POST_BLOG_NEW,
  data,
  navigate,
});

export const deleteBlogNew = (blogId, navigate) => ({
  type: types.DELETE_BLOG_NEW,
  blogId,
  navigate,
});

export const updateBlogNew = (blogId, data, navigate) => ({
  type: types.UPDATE_BLOG_NEW,
  blogId,
  data,
  navigate,
});

export const postBlog = (data, navigate) => ({
  type: types.POST_BLOG,
  data,
  navigate,
});

export const updateBlog = (blogId, data, navigate) => ({
  type: types.UPDATE_BLOG,
  blogId,
  data,
  navigate,
});

export const deleteBlog = (blogId, navigate) => ({
  type: types.DELETE_BLOG,
  blogId,
  navigate,
});

export const addFaqGroup = (data, setModal, resetForm, navigate) => ({
  type: types.ADD_FAQ_GROUP,
  data,
  setModal,
  resetForm,
  navigate,
});

export const addFaq = (navigate, data, faqFor, setModal, resetForm) => ({
  type: types.ADD_FAQ,
  data,
  navigate,
  setModal,
  resetForm,
  faqFor,
});

export const updateFaq = (data, faqFor, setModal, resetForm, navigate) => ({
  type: types.UPDATE_FAQ,
  data,
  setModal,
  resetForm,
  navigate,
  faqFor,
});

export const updateFaqGroup = (data, setModal, resetForm, navigate) => ({
  type: types.UPDATE_FAQ_GROUP,
  data,
  navigate,
  setModal,
  resetForm,
});

export const deleteFaq = (navigate, data, faqFor, setModal, resetForm) => ({
  type: types.DELETE_FAQ,
  data,
  navigate,
  faqFor,
  setModal,
  resetForm,
});

export const deleteFaqGroup = (
  navigate,
  data,
  faqFor,
  setModal,
  resetForm
) => ({
  type: types.DELETE_FAQ_GROUP,
  data,
  navigate,
  faqFor,
  setModal,
  resetForm,
});

export const addQuestion = (data, navigate, closeModal) => ({
  type: types.ADD_QUESTION,
  data,
  navigate,
  closeModal,
});

export const englishAllQuestion = (navigate) => ({
  type: types.ENGLISH_ALL_QUESTION,
  navigate,
});

export const deleteEnglishQuestion = (questionId, navigate) => ({
  type: types.DELETE_ENGLISH_QUESTION,
  questionId,
  navigate,
});

export const updateEnglishQuestion = (data, navigate) => ({
  type: types.UPDATE_ENGLISH_QUESTION,
  data,
  navigate,
});

export const getAllJobs = (navigate, pageData,filterObj={},clearFirst) => ({
  type: types.GET_ALL_JOB,
  navigate,
  pageData,
  filterObj,
  clearFirst
});

export const addJob = (data, setModal, navigate, socket) => ({
  type: types.ADD_JOB,
  data,
  setModal,
  navigate,
  socket
});

export const editJob = (data, setModal, navigate) => ({
  type: types.EDIT_JOB,
  data,
  navigate,
  setModal,
});

export const deleteJob = (navigate, jobId, setModal, setDeleteModal) => ({
  type: types.DELETE_JOB,
  jobId,
  navigate,
  setModal,
  setDeleteModal,
});

export const dashboardStepOne = (
  devId,
  data,
  filterValue,
  page,
  limit,
  navigate
) => ({
  type: types.ADMIN_DASHBOARD_STEP_ONE,
  devId,
  data,
  filterValue,
  page,
  limit,
  navigate,
});

export const searchClientStartAction = () => ({
  type: types.GET_CLIENT_LIST_ADMIN_START,
});

export const getClientListAdmin = (
  data,
  navigate,
  limit = 0,
  page = 0,
  term = ""
) => ({
  type: types.GET_CLIENT_LIST_ADMIN,
  data,
  navigate,
  limit,
  page,
  term,
});

export const getClientDetailAdmin = (clientId, navigate) => ({
  type: types.GET_CLIENT_DETAIL_ADMIN,
  clientId,
  navigate,
});

export const getClientListAdminShortListed = (clientId, navigate) => ({
  type: types.GET_ADMIN_CLIENT_SHORTLIST,
  clientId,
  navigate,
});

export const getClientListAdminHired = (clientId, navigate) => ({
  type: types.GET_ADMIN_CLIENT_HIRED,
  clientId,
  navigate,
});

export const getJobListByClientAdmin = (clientId, pageData, navigate) => ({
  type: types.GET_JOB_LIST_BY_CLIENT_ADMIN,
  clientId,
  pageData,
  navigate,
});

export const getJobFullClientAdmin = (jobId, navigate) => ({
  type: types.GET_JOB_FULL_CLIENT_ADMIN,
  jobId,
  navigate,
});

export const blogsSyncupAction = (navigate) => ({
  type: types.BLOGS_SYNCUP,
  navigate,
});

export const updateSpotLightStatus = (data, navigate) => ({
  type: types.UPDATE_SPOTLIGHTED_CANDIDATES,
  data,
  navigate,
});

export const updateEmailTemplate = (templateId, data, navigate) => ({
  type: types.UPDATE_ADMIN_TEMPLATE_LIST,
  templateId,
  data,
  navigate,
});

export const getAllEmailTemplate = (navigate) => ({
  type: types.GET_ADMIN_TEMPLATE_LIST,
  navigate,
});

export const getEmailTemplateById = (templateId, navigate) => ({
  type: types.GET_ADMIN_TEMPLATE_BY_ID,
  templateId,
  navigate,
});

export const deleteEmailTemplate = (templateId, navigate) => ({
  type: types.DELETE_ADMIN_TEMPLATE_LIST,
  templateId,
  navigate,
});

export const addEmailTemplate = (data, navigate, resetForm) => ({
  type: types.POST_ADMIN_TEMPLATE_LIST,
  data,
  navigate,
  resetForm,
});

export const getEmailHistory = (data, pageData, navigate) => ({
  type: types.GET_ADMIN_EMAIL_HISTORY,
  data,
  pageData,
  navigate,
});

export const getAdminDevAcademic = (navigate, data) => ({
  type: types.GET_ADMIN_DEV_ACADEMIC,
  data,
  navigate,
});

export const updateAdminDevAcademic = (
  navigate,
  devId,
  data,
  setDetails,
  resetForm
) => ({
  type: types.UPDATE_ADMIN_DEV_ACADEMIC,
  data,
  navigate,
  devId,
  setDetails,
  resetForm,
});

export const saveAdminDevAcademic = (
  navigate,
  data,
  setDetails,
  resetForm
) => ({
  type: types.SAVE_ADMIN_DEV_ACADEMIC,
  data,
  navigate,
  setDetails,
  resetForm,
});

export const deleteAdminDevAcademic = (navigate, data, devId) => ({
  type: types.DELETE_ADMIN_DEV_ACADEMIC,
  data,
  devId,
  navigate,
});

export const getAdminDevCertification = (navigate, data) => ({
  type: types.GET_ADMIN_DEV_CERTIFICATION,
  data,
  navigate,
});

export const updateAdminDevCertification = (
  navigate,
  devId,
  data,
  setDetails,
  resetForm
) => ({
  type: types.UPDATE_ADMIN_DEV_CERTIFICATION,
  data,
  devId,
  navigate,
  setDetails,
  resetForm,
});

export const saveAdminDevCertification = (
  navigate,
  data,
  setDetails,
  resetForm
) => ({
  type: types.SAVE_ADMIN_DEV_CERTIFICATION,
  data,
  navigate,
  setDetails,
  resetForm,
});

export const deleteAdminDevCertification = (navigate, data, devId) => ({
  type: types.DELETE_ADMIN_DEV_CERTIFICATION,
  data,
  devId,
  navigate,
});

export const getAdminDevSocialProfile = (navigate, data) => ({
  type: types.GET_ADMIN_DEV_SOCIAL_PROFILE,
  data,
  navigate,
});
export const saveAdminDevSocialProfile = (navigate, data) => ({
  type: types.SAVE_ADMIN_DEV_SOCIAL_PROFILE,
  data,
  navigate,
});
export const updateAdminDevSocialProfile = (navigate, devId, data) => ({
  type: types.UPDATE_ADMIN_DEV_SOCIAL_PROFILE,
  data,
  devId,
  navigate,
});
export const getAdminDevEmployement = (navigate, data) => ({
  type: types.GET_ADMIN_DEV_EMPLOYEMENT,
  data,
  navigate,
});

export const updateAdminDevEmployement = (
  navigate,
  devId,
  data,
  setDetails,
  resetForm
) => ({
  type: types.UPDATE_ADMIN_DEV_EMPLOYEMENT,
  data,
  devId,
  navigate,
  setDetails,
  resetForm,
});

export const saveAdminDevEmployement = (
  navigate,
  data,
  setDetails,
  resetForm,
  devId
) => ({
  type: types.SAVE_ADMIN_DEV_EMPLOYEMENT,
  data,
  navigate,
  setDetails,
  resetForm,
  devId,
});

export const deleteAdminDevEmployement = (navigate, data, devId) => ({
  type: types.DELETE_ADMIN_DEV_EMPLOYEMENT,
  data,
  devId,
  navigate,
});

export const getAdminDevProject = (navigate, data) => ({
  type: types.GET_ADMIN_DEV_PROJECT,
  data,
  navigate,
});

export const updateAdminDevProject = (
  navigate,
  data,
  setDetails,
  resetForm,
  isAdditionProject
) => ({
  type: types.UPDATE_ADMIN_DEV_PROJECT,
  data,
  navigate,
  setDetails,
  resetForm,
  isAdditionProject
});

export const saveAdminDevProject = (navigate, data, setDetails, resetForm,isAdditionProject) => ({
  type: types.SAVE_ADMIN_DEV_PROJECT,
  data,
  navigate,
  setDetails,
  resetForm,
  isAdditionProject
});

export const deleteAdminDevProject = (navigate, data, devId,isAdditionProject,cb) => ({
  type: types.DELETE_ADMIN_DEV_PROJECT,
  data,
  devId,
  navigate,
  isAdditionProject,
  cb
});

export const getAdminDevResult = (userId, navigate) => ({
  type: types.GET_DEV_RESULT,
  userId,
  navigate,
});

export const finalizeResumeCallAdmin = (
  navigate,
  data,
  userId,
  filterValue = null,
  pageData
) => ({
  type: types.FINAL_RESUME_ADMIN_CALL,
  userId,
  navigate,
  data,
  filterValue,
  pageData,
});

export const getAdminDevTools = (navigate, data) => ({
  type: types.GET_ADMIN_DEV_TOOLS,
  data,
  navigate,
});

export const updateAdminDevTools = (
  navigate,
  devId,
  data,
  setDetails,
  resetForm
) => ({
  type: types.UPDATE_ADMIN_DEV_TOOLS,
  data,
  devId,
  navigate,
  setDetails,
  resetForm,
});

export const saveAdminDevTools = (
  navigate,
  devId,
  data,
  setDetails,
  resetForm
) => ({
  type: types.SAVE_ADMIN_DEV_TOOLS,
  data,
  devId,
  navigate,
  setDetails,
  resetForm,
});

export const deleteAdminDevTools = (navigate, data, devId) => ({
  type: types.DELETE_ADMIN_DEV_TOOLS,
  data,
  devId,
  navigate,
});

export const getAdminDevSkills = (navigate, data) => ({
  type: types.GET_ADMIN_DEV_SKILLS,
  data,
  navigate,
});

export const updateAdminDevSkills = (
  navigate,
  devId,
  data,
  setDetails,
  resetForm
) => ({
  type: types.UPDATE_ADMIN_DEV_SKILLS,
  data,
  devId,
  navigate,
  setDetails,
  resetForm,
});

export const saveAdminDevSkills = (navigate, data, setDetails, resetForm) => ({
  type: types.SAVE_ADMIN_DEV_SKILLS,
  data,
  navigate,
  setDetails,
  resetForm,
});

export const deleteAdminDevSkills = (navigate, data, devId) => ({
  type: types.DELETE_ADMIN_DEV_SKILLS,
  data,
  devId,
  navigate,
});

export const getAdminDevAchievement = (navigate, data) => ({
  type: types.GET_ADMIN_DEV_ACHIEVEMENT,
  data,
  navigate,
});

export const updateAdminDevAchievement = (
  navigate,
  devId,
  data,
  setDetails,
  resetForm
) => ({
  type: types.UPDATE_ADMIN_DEV_ACHIEVEMENT,
  data,
  devId,
  navigate,
  setDetails,
  resetForm,
});

export const saveAdminDevAchievement = (
  navigate,
  data,
  setDetails,
  resetForm
) => ({
  type: types.SAVE_ADMIN_DEV_ACHIEVEMENT,
  data,
  navigate,
  setDetails,
  resetForm,
});

export const deleteAdminDevAchievement = (navigate, data, devId) => ({
  type: types.DELETE_ADMIN_DEV_ACHIEVEMENT,
  data,
  devId,
  navigate,
});

export const updateAdminDevShortDesciption = (
  navigate,
  devId,
  data,
  closeModal
) => ({
  type: types.UPDATE_ADMIN_DEV_SHORT_DESC,
  data,
  devId,
  navigate,
  closeModal,
});
export const deleteProfileImage = (navigate, devId, setModal) => ({
  type: types.DELETE_PROFILE_IMAGE,
  devId,
  navigate,
  setModal,
});

export const getResumeAdminAction = (userId, navigate) => ({
  type: types.GET_RESUME_ADMIN,
  userId,
  navigate,
});

export const profileUpdateApproveRejectAction = (data, navigate) => ({
  type: types.PROFILE_UPDATE_REQUEST_APPROVE_REJECT,
  data,
  navigate,
});

export const verifyDevEmailAction = (data, navigate, setModal, setContent) => ({
  type: types.VERIFY_DEV_EMAIL,
  data,
  navigate,
  setModal,
  setContent,
});

export const updateAdminJobStatus = (navigate, status) => ({
  type: types.UPDATE_ADMIN_JOB_STATUS,
  navigate,
  status,
});

export const approveDevEmailAction = (
  data,
  navigate,
  setModal,
  setContent
) => ({
  type: types.APPROVE_DEV_EMAIL,
  data,
  setModal,
  setContent,
  navigate,
});

export const unapproveDevEmailAction = (
  data,
  navigate,
  setModal,
  setContent
) => ({
  type: types.UNAPPROVE_DEV_EMAIL,
  data,
  setModal,
  setContent,
  navigate,
});

export const profileUpdateStatusResetAction = () => ({
  type: types.PROFILE_UPDATE_REQUEST_APPROVE_REJECT_FAIL,
});

export const getAllQueries = (navigate, pageData) => ({
  type: types.GET_QUERIES_LIST,
  navigate,
  pageData,
});

export const deleteQueriesAction = (id, pageData, navigate) => ({
  type: types.DELETE_QUERY,
  id,
  pageData,
  navigate,
});

export const updateQueriesAction = (data, pageData, navigate) => ({
  type: types.UPDATE_QUERY,
  data,
  pageData,
  navigate,
});

export const allBlogNameAndIdAction = (navigate) => ({
  type: types.ALL_BLOG_NAME_AND_ID,
  navigate,
});

export const saveSkillTest = (navigate, data) => ({
  type: types.CREATE_SKILL_TEST,
  data,
  navigate,
});
export const getSkillTest = (navigate) => ({
  type: types.GET_SKILL_TEST,
  navigate,
});
export const deleteDevProfile = (
  navigate,
  userId,
  closeModal,
  filter,
  pageData,
  userType = "developer"
) => ({
  type: types.DELETE_USER_PROFILE,
  navigate,
  userId,
  filter,
  pageData,
  closeModal,
  userType,
});

export const blockClientProfileAction = (
  navigate,
  data,
  closeModal
) => ({
  type: types.BLOCK_USER_PROFILE,
  navigate,
  data,
  closeModal,
});

export const getSitemapTypeAction = (navigate) => ({
  type: types.GET_SITEMAP_TYPE,
  navigate,
});

export const postSitemapTypeAction = (data, navigate) => ({
  type: types.POST_SITEMAP_TYPE,
  data,
  navigate,
});

export const updateSitemapTypeAction = (data, sitemapTypeId, navigate) => ({
  type: types.UPDATE_SITEMAP_TYPE,
  data,
  sitemapTypeId,
  navigate,
});

export const deleteSitemapTypeAction = (sitemapTypeId, navigate) => ({
  type: types.DELETE_SITEMAP_TYPE,
  sitemapTypeId,
  navigate,
});

export const getSpotlightHistoryAction = (navigate) => ({
  type: types.GET_SPOTLIGHT_HISTORY,
  navigate,
});

export const getMaintenanceAction = (navigate) => ({
  type: types.GET_MAINTENANCE_MESSAGE,
  navigate,
});

export const updateMaintenanceStatusAction = (data, navigate) => ({
  type: types.UPDATE_MAINTENANCE_STATUS,
  data,
  navigate,
});

export const addMaintenanceStatusAction = (data, navigate, closeModal) => ({
  type: types.ADD_MAINTENANCE_MESSAGE,
  data,
  navigate,
  closeModal,
});

export const updateMaintenanceMessageAction = (data, navigate, closeModal) => ({
  type: types.UPDATE_MAINTENANCE_MESSAGE,
  data,
  navigate,
  closeModal,
});

export const deleteMaintenanceMessageAction = (data, navigate, closeModal) => ({
  type: types.DELETE_MAINTENANCE_MESSAGE,
  data,
  navigate,
  closeModal,
});

export const getSpeakWithDevListAction = (navigate) => ({
  type: types.GET_SPEAK_WITH_DEV_LIST,
  navigate,
});

export const updateFaqStatusAction = (data, navigate, faqFor) => ({
  type: types.UPDATE_FAQ_STATUS,
  data,
  navigate,
  faqFor,
});

export const updateFaqGroupStatusAction = (data, navigate, faqFor) => ({
  type: types.UPDATE_FAQ_GROUP_STATUS,
  data,
  navigate,
  faqFor,
});

export const getInterviewListAction = (navigate, pageData) => ({
  type: types.GET_INTERVIEW_LIST,
  navigate,
  pageData,
});

export const updateAssessmentNameAction = (
  data,
  skillType,
  onClose,
  navigate,
  pageData
) => ({
  type: types.UPDATE_ASSESSMENT_NAME,
  data,
  skillType,
  onClose,
  navigate,
  pageData,
});

export const updateEmailSettingStatusAction = (data, navigate) => ({
  type: types.UPDATE_EMAIL_SETTING_STATUS,
  data,
  navigate,
});

export const getSkillCategory = (navigate) => ({
  type: types.GET_SKILL_CATEGORY,
  navigate,
});

export const getSkillWithCategory = (navigate, typeList="") => ({
  type: types.GET_SKILL_CATEGORY_LISTS,
  navigate,
  typeList
});

export const getStackWithCategory = (navigate) => ({
  type: types.GET_STACK_CATEGORY_LISTS,
  navigate,
});

export const createSkillsCategory = (navigate, data, setObj) => ({
  type: types.CREATE_SKILLS_CATEGORY,
  navigate,
  data,
  setObj,
});
export const updateSkillsCategory = (navigate, data, setObj, skillOrStack) => ({
  type: types.UPDATE_SKILLS_CATEGORY,
  navigate,
  data,
  setObj,
  skillOrStack,
});

export const clientFilterAction = (data) => ({
  type: types.SET_CLIENT_FILTER,
  data,
});
 export const getEmailCount=(navigate,filter)=>({
  type: types.GET_EMAIL_COUNT,
  navigate,
  filter
 })

 export const sendJobMail=(navigate,data)=>({
  type:types.SEND_JOB_MAIL,
  navigate,
  data
 })

 export const getEmailListAction=()=>({
  type:types.GET_CLIENT_EMAIL,
 })
 export const getJobTitleListAction=(navigate,clientId)=>({
  type:types.GET_JOB_TITLE_LISTS,
  clientId,
  navigate
 })
 
 export const getPlanListsAction=(navigate,data)=>({
  type:types.GET_PLAN_LISTS,
  data,
  navigate
 })

 export const getFeatureAction=(navigate,data)=>({
  type:types.GET_FEATURE_LIST,
  data,
  navigate
 })

 export const getAddOnListAction=(navigate,data)=>({
  type:types.GET_ADD_ON_LISTS,
  data,
  navigate
 })

 export const addFeatureAction=(navigate,data,cb)=>({
  type:types.ADD_PRICING_FEATURE,
  data,
  navigate,
  cb
 })
 export const updateFeatureAction=(navigate,data,cb)=>({
  type:types.UPDATE_PRICING_FEATURE,
  data,
  navigate,
  cb
 })

 export const addPricingPlanAction=(navigate,data,cb)=>({
  type:types.ADD_PRICING_PLAN,
  data,
  navigate,
  cb
 })
 export const updatePricingPlanAction=(navigate,data,cb)=>({
  type:types.UPDATE_PRICING_PLAN,
  data,
  navigate,
  cb
 })


 export const saveAddOnAction=(navigate,data,cb)=>({
  type:types.SAVE_ADD_ON,
  data,
  navigate,
  cb
 })

 export const updateAddOnAction=(navigate,data,cb)=>({
  type:types.UPDATE_ADD_ON,
  data,
  navigate,
  cb
 })

 export const updateAddOnStatusAction=(navigate,data,cb)=>({
  type:types.UPDATE_ADD_ON_STATUS,
  data,
  navigate,
  cb
 })

 export const updatePlanStatusAction=(navigate,data,cb)=>({
  type:types.UPDATE_PLAN_STATUS,
  data,
  navigate,
  cb
 })
 export const getPricingSlabAction=(navigate)=>({
  type:types.GET_PRICING_SLAB,
  navigate,
 })
 export const addPricingSlabAction=(navigate,payload)=>({
  type:types.ADD_PRICING_SLAB,
  navigate,
  payload
 })
 export const updatePricingSlabAction=(navigate,payload)=>({
  type:types.UPDATE_PRICING_SLAB,
  navigate,
  payload
 })
 export const getPendingSkillAction=(navigate)=>({
  type:types.GET_PENDING_SKILL,
  navigate,
 })
 export const updatePendingSkillAction=(navigate,payload)=>({
  type:types.UPDATE_PENDING_SKILL,
  navigate,
  payload
 })
