import React, { Fragment } from "react";

const ReviewBlock = ({onClick}) => {
  return (
    <Fragment>
      <div className="my-review">
        <div className="content">
          <p>
          Boost your Olibr profile! Request genuine reviews from current or past managers via work email. Beware, fake reviews risk platform disbarment.
          </p>
          <button
            onClick={onClick}
            className="submitBtn"
          >
            Request Reviews
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default ReviewBlock;
