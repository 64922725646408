import "./tooltip.css";

const Tooltip = ({ title, tooltipText, titleColor }) => {
  return (
    <div class="tooltip" style={{ color: titleColor }}>
      {title}
      <span class="tooltiptext">{tooltipText}</span>
    </div>
  );
};

export default Tooltip;