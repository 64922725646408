import React, { useEffect, useState, Fragment } from "react";
import moment from "moment";
import "./Calendar.css";
import Modal from "../../../components/modal/ModalBox";
import RescheduleInterview from "../jobs/RescheduleInterview";
import { cancelInterview } from "../../../service/action/client";
import SelectOption from "../../../components/select/SelectOption";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import userImage from "./../../../assets/img/author.png";
import { olibrToast } from "../../../components/toaster";

const getDaysCountFormMonth = (month, year) => {
  if (month === 1) return 31;
  if (month === 2) {
    if (year % 4 === 0) return 29;
    else return 28;
  }
  if (month === 3) return 31;
  if (month === 4) return 30;
  if (month === 5) return 31;
  if (month === 6) return 30;
  if (month === 7) return 31;
  if (month === 8) return 31;
  if (month === 9) return 30;
  if (month === 10) return 31;
  if (month === 11) return 30;
  if (month === 12) return 31;
};

const getCalData = (month, year) => {
  const days = getDaysCountFormMonth(month, year);
  let arr = [];
  for (let i = 0; i < days; i++) {
    let count = i % 5;
    let length = arr.length;
    let obj = {
      date: i + 1,
      completeDate: `${year}-${month}-${i + 1}`,
      day: moment(new Date(`${year}-${month}-${i + 1}`)).format("dddd"),
    };
    if (count === 0) arr[length] = [obj];
    else arr[length - 1].push(obj);
  }
  return arr;
};

const AllInterviewCalendar = ({ data, month, year, onClickViewMore }) => {
  const [calData, setCalData] = useState(null);
  const [today, setToday] = useState(null);
  const [interviewDetailsModalView, setInterviewDetailsModalView] =
    useState(false);
  const { socket } = useSelector((state) => state.commonReducer);
  const [rescheduleModalView, setRescheduleModalView] = useState(false);
  const [acceptModalIntDet, setAcceptModalIntDet] = useState(null);
  const [cancelMessage, setCancelMessage] = useState(null);
  const [cancelModalView, setCancelModalView] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  let count = 0;
  const rejectedReason = [
    {
      label: "Job closed.",
      value: "Job closed.",
    },
    {
      label: "other",
      value: "other",
    },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let arr = getCalData(month + 1, year);
    setCalData(arr);
  }, [month, year]);

  useEffect(() => {
    const todayDate = new Date();
    let dd = todayDate.getDate();
    let mm = todayDate.getMonth();
    let yyyy = todayDate.getFullYear();
    setToday(`${yyyy}-${mm + 1}-${dd}`);
  }, []);

  Date.prototype.addHours = function (h) {
    this.setTime(this.getTime() + h * 60 * 60 * 1000);
    return this;
  };
  const getStatusColor = (status) => {
    if (status === "pending") return "#E7B024";
    if (status === "canceled") return "red";
    if (status === "scheduled") return "#3BA0AA";
  };

  const renderCancelInterviewModalContent = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <div style={{ width: "100%" }}>
            <SelectOption
              optionArray={rejectedReason}
              state={selectedOption}
              closeMenuOnSelect={true}
              placeholder={"Select reason of reject"}
              onChangeFunction={(e) => {
                setSelectedOption(e);
                if (e.value !== "other") {
                  setCancelMessage(e.value);
                } else {
                  setCancelMessage(null);
                }
              }}
            />
            {selectedOption?.value === "other" && (
              <textarea
                value={cancelMessage}
                placeholder="Enter reasion of reject"
                onChange={(e) => setCancelMessage(e.target.value)}
                style={{ marginTop: "15px" }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            marginTop: "25px",
            gap: "20px",
            flexDirection: "row",
          }}
        >
          <a
            className="cancelBtn"
            style={{
              background: "#3ba0aa",
              color: "white",
              border: "none",
              margin: "0px",
            }}
            onClick={() => {
              if (cancelMessage) {
                dispatch(
                  cancelInterview(
                    acceptModalIntDet._id,
                    navigate,
                    {
                      ...acceptModalIntDet,
                      message: cancelMessage,
                    },
                    socket
                  )
                );
                setCancelModalView(false);
              } else {
                olibrToast.error("Reason of reject is required.");
              }
            }}
          >
            Done
          </a>
        </div>
      </>
    );
  };

  const renderRescheduleModalContent = () => {
    return (
      <Fragment>
        <RescheduleInterview
          data={acceptModalIntDet}
          setRescheduleModalView={setRescheduleModalView}
          title={
            acceptModalIntDet?.interviewData?.status === "canceled" &&
            acceptModalIntDet?.interviewData?.cancelledBy ? (
              <span
                style={{
                  fontSize: "20px",
                }}
              >{`Developer has declined the interview due to: ${
                acceptModalIntDet?.interviewData?.message
                  ? acceptModalIntDet?.interviewData?.message
                  : "personal reason"
              }`}</span>
            ) : (
              acceptModalIntDet?.interviewData?.status === "canceled" && (
                <span
                  style={{
                    fontSize: "20px",
                  }}
                >
                  You have canceled the interview, but you can still reschedule.
                </span>
              )
            )
          }
        />
      </Fragment>
    );
  };

  const renderInterviewDetailsModalContent = () => {
    if (acceptModalIntDet)
      return (
        <>
          <h3 style={{ marginLeft: "10px" }} className="job-title">
            {acceptModalIntDet?.jobTitle}
          </h3>
          <span
            className="scheduled-time"
            style={{
              display: "inline-block",
              color: "#4e4e4e",
              fontSize: "14px",
              whiteSpace: "pre",
              marginTop: "-15px",
              marginLeft: "10px",
            }}
          >
            {acceptModalIntDet?.interviewData?.slots.map((dt, index) => {
              if (dt?.joinUrl) {
                return `From ${moment(dt?.from).format("h:mm a")} to ${moment(
                  dt.to
                ).format("h:mm a")}\n${new Date(dt?.from).toDateString()}`;
              }
            })}
            <br />
            <br />
            <h5 style={{ fontSize: "14px", fontWeight: "400", color: "#000" }}>
              <strong style={{ fontWeight: "600", marginRight: "7px" }}>
                Candidate Name:
              </strong>
              {acceptModalIntDet?.name}
            </h5>
            <br />
            <h5 style={{ fontSize: "14px", fontWeight: "400", color: "#000" }}>
              <strong style={{ fontWeight: "600", marginRight: "7px" }}>
                Interviewer:
              </strong>
              {acceptModalIntDet?.interviewer}
            </h5>
            <br />
            <h5 style={{ fontSize: "14px", fontWeight: "400", color: "#000" }}>
              <strong style={{ fontWeight: "600", marginRight: "7px" }}>
                Message:
              </strong>
              {acceptModalIntDet?.interviewData?.message}
            </h5>
            <h5
              style={{
                fontSize: "14px",
                color: getStatusColor(acceptModalIntDet?.interviewData?.status),
                textTransform: "capitalize",
                fontWeight: "400",
              }}
            >
              <strong
                style={{ fontWeight: "600", marginRight: "7px", color: "#000" }}
              >
                Status:
              </strong>
              {acceptModalIntDet?.interviewData?.status}
            </h5>
          </span>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "25px",
            }}
          >
            <a
              className="cancelBtn"
              style={{ background: "#f15d3b", color: "white", border: "none" }}
              onClick={() => {
                setInterviewDetailsModalView(false);
                setCancelModalView(true);
              }}
            >
              Cancel
            </a>
            <a
              className="cancelBtn"
              style={{ background: "orange", color: "white", border: "none" }}
              onClick={() => {
                setRescheduleModalView(true);
                setInterviewDetailsModalView(false);
              }}
            >
              Reschedule
            </a>
            <a
              className="cancelBtn"
              style={{ background: "#3ba0aa", color: "white", border: "none" }}
              target="_blank"
              href={
                acceptModalIntDet?.interviewData?.slots?.filter(
                  (itm) => itm.joinUrl
                )?.[0]?.interviewerUrl ||
                acceptModalIntDet?.interviewData?.slots?.filter(
                  (itm) => itm.joinUrl
                )?.[0]?.joinUrl
              }
            >
              Join
            </a>
          </div>
        </>
      );
  };

  if (calData)
    return (
      <>
        <Modal
          open={rescheduleModalView}
          title={"Interview Details"}
          size={"50%"}
          onClose={() => setRescheduleModalView(false)}
          onCancel={() => setRescheduleModalView(false)}
          onDone={() => {}}
          isFooterView={false}
          content={() => renderRescheduleModalContent()}
        />
        <Modal
          open={cancelModalView}
          title={"Cancel Interview"}
          size={"50%"}
          onClose={() => setCancelModalView(false)}
          onCancel={() => setCancelModalView(false)}
          onDone={() => {}}
          isFooterView={false}
          content={() => renderCancelInterviewModalContent()}
        />
        <Modal
          open={interviewDetailsModalView}
          title={"Interview Details"}
          size={"50%"}
          onClose={() => setInterviewDetailsModalView(false)}
          onCancel={() => setInterviewDetailsModalView(false)}
          onDone={() => {}}
          isFooterView={false}
          content={() => renderInterviewDetailsModalContent()}
        />

        <table
          className="interview-cal"
          style={{ width: "100%" }}
          cellSpacing="5"
        >
          <tbody>
            {calData.map((c) => {
              return (
                <tr>
                  {c.map((cc) => {
                    return (
                      <td
                        style={{
                          background:
                            today === cc.completeDate ? "#E3FEFF" : "",
                        }}
                      >
                        <div className="day-date">
                          <span className="date">{cc.date}</span>
                          <span className="day">{cc.day}</span>
                        </div>

                        <div className="flex-group">
                          {data[cc.completeDate.toString()] &&
                            data[cc.completeDate.toString()]?.map(
                              (d, index) => {
                                const isHidden = index >= 4;

                                return (
                                  <div
                                    key={index}
                                    // className="schedule"
                                    className={`schedule ${
                                      isHidden ? "hidden-schedule" : ""
                                    }`}
                                    style={{
                                      cursor: "pointer",
                                      padding: "0px 5px",
                                      boxSizing: "border-box",
                                    }}
                                    onClick={() => {
                                      setAcceptModalIntDet(d);
                                      setInterviewDetailsModalView(true);
                                    }}
                                  >
                                    {/* add "pending" to figure's class name to give orange boundary 
                                  to those interviews that have a pending status */}
                                    <figure
                                      className={
                                        d?.interviewData?.status === "scheduled"
                                          ? "schedular-photo"
                                          : "pending"
                                      }
                                    >
                                      <img
                                        src={
                                          d.userImage ? d.userImage : userImage
                                        }
                                        alt="User Picture"
                                        title={d.jobTitle}
                                      />
                                    </figure>
                                    {/* <span className="agenda" title={d.jobTitle}>
                                    {d.jobTitle || d.designation}
                                  </span> */}
                                  </div>
                                );
                              }
                            )}
                          {data[cc.completeDate.toString()]?.length > 4 && (
                            <a
                              className="more-link"
                              href="#!"
                              onClick={(e) => {
                                e.preventDefault();
                                onClickViewMore(cc.completeDate);
                              }}
                            >
                              +{data[cc.completeDate.toString()].length - 4}
                            </a>
                          )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  else return "";
};

export default AllInterviewCalendar;
