import React, { Fragment, useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ClipLoader, ScaleLoader } from "react-spinners";
import {
  getAllTools,
  deleteTool,
  updateTool,
  addTool,
  uploadIcons,
} from "../../../../service/action/admin";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../components/modal/ModalBox";
import Paginations from "../../../../components/pagination/paginations";
import { Icon } from "../../../../components/icon";
import { olibrToast } from "../../../../components/toaster";
import { useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import SelectOption from "../../../../components/select/SelectOption";
import { useContext } from "react";
import { MainContext } from "../../../../context/MainContext";
import { checkPermission, readOnlyCheck } from "../../../../components/functions";

const Tools = () => {
  const dispatch = useDispatch();
  const {user:{access}}=useContext(MainContext)
  const {
    register: toolRegister,
    handleSubmit: toolSubmit,
    setValue: setToolValue,
    getValues: getToolValue,
    control: toolControll,
    reset: resetToolForm,
    formState: { errors: toolError },
  } = useForm();
  const {
    toolList,
    skillIcons,
    isIconUploading,
    uploadIcon,
    isLoadingAllTools,
  } = useSelector((state) => state.adminReducer);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const uploadIconRef = useRef();

  const [pageData, setPageData] = useState({
    page: 1,
    limit: 20,
    search: "",
  });
  const handleNext = () => {
    setPageData({ ...pageData, page: pageData.page + 1 });
  };
  const handlePrev = () => {
    setPageData({ ...pageData, page: pageData.page - 1 });
  };
  const handleSearch = (e) => {
    dispatch(
      getAllTools(navigate, { ...pageData, page: 1, search: e.target.value })
    );
    setPageData({ ...pageData, search: e.target.value });
  };
  useEffect(() => {
    dispatch(getAllTools(navigate, pageData));
  }, [pageData.limit, dispatch, navigate]);

  const fetchMoreData = () => {
    setPageData({
      ...pageData,
      limit: pageData?.limit + 10
    })
  }

  const closeModal = () => {
    resetToolForm();
    setOpen(false);
    setIsEdit(false);
    setToolObj({
      toolsName: "",
      toolsIcon: "https://olibr.s3.ap-south-1.amazonaws.com/resume/fb8228471cd2d8ef1662032476998.svg",
    });
  };

  const openEditModal = () => {
    setIsEdit(true);
    setOpen(true);
  };

  const [toolObj, setToolObj] = useState({
    toolsName: "",
    toolsIcon: "https://olibr.s3.ap-south-1.amazonaws.com/resume/fb8228471cd2d8ef1662032476998.svg",
  });

  const handleEditTool = (item) => {
    resetToolForm();
    setToolObj({
      id: item._id,
      toolsIcon: item?.toolsIcon,
    });
    setToolValue("toolsName", item?.toolsName)
    openEditModal();
  };

  useEffect(() => {
    Object.keys(uploadIcon)?.length > 0 &&
      setToolObj({
        ...toolObj,
        toolsIcon: uploadIcon?.icons?.[uploadIcon?.icons?.length - 1],
      });
  }, [uploadIcon]);

  const onIconSelect = (e) => {
    setToolObj({ ...toolObj, toolsIcon: e.value });
  }

  const handleContent = () => {
    return (
      <form>
        <div className="form-group">
          <label htmlFor="" className={`floating-label ${toolError?.toolsName && "text-danger"}`}>
            Tool Name*
          </label>
          <input
            name="toolsName"
            {
            ...toolRegister("toolsName", {
              required: true
            })
            }
            type="text"
            className="form-control"
          />
        </div>

        <div className="row-flex">
          <div className="form-group">
            <label htmlFor="" className="floating-label">
              Choose icon
            </label>
            <SelectOption
                optionArray={skillIcons?.map((icon) => ({
                  value: icon,
                  label: (
                    <div style={{ width: "200px", display: "flex" }}>
                      {" "}
                      <img src={icon} height="30px" width="30px" />{" "}
                    </div>
                  ),
                }))}
                menuPlacement={"top"}
                state={{
                  value: toolObj?.toolsIcon,
                  label: (
                    <div style={{ width: "200px", display: "flex" }}>
                      {" "}
                      <img
                        src={toolObj?.toolsIcon}
                        height="30px"
                        width="30px"
                      />{" "}
                    </div>
                  ),
                }}
                closeMenuOnSelect={false}
                onChangeFunction={onIconSelect}
              />
          </div>
          <input
            ref={uploadIconRef}
            type="file"
            onChange={onUploadIcon}
            className="input-file"
            accept=".png, .jpeg, .jpg, .svg"
          />
          <a
            className="file-icon"
            onClick={() => uploadIconRef.current.click()}
          >
            {isIconUploading ? (
              <ScaleLoader color="#3BA0AA" height={10} />
            ) : (
              "Upload icon"
            )}
          </a>
        </div>
      </form>
    );
  };

  const onUploadIcon = async (e) => {
    let formData = new FormData();
    formData.append("imageFile", e.target.files[0]);
    if (e.target.files[0]?.size > 1024 * 1024) {
      olibrToast.error("Please use image of size less than 1MB");
    }
    else {
      dispatch(uploadIcons(formData, navigate));
    }
    e.target.value = null;
  };

  const addTools = (data) => {
    const newObj = {
      ...toolObj,
      ...data,

    }
    isEdit
      ? dispatch(updateTool(newObj, navigate, pageData, closeModal, resetToolForm))
      : dispatch(addTool(newObj, navigate, pageData, closeModal, resetToolForm));
  };

  return (
    <Fragment>
      <Modal
        title={isEdit ? "Edit Tool" : "Add Tool"}
        open={open}
        onDone={toolSubmit(addTools)}
        onCancel={closeModal}
        onClose={closeModal}
        content={handleContent}
      />
      <div className="skills-management">
        <div className="block-header">
          <h4 className="">Tools</h4>
          <span
            onClick={() => {
              if (checkPermission(readOnlyCheck(access,"Skills Management"))) return false 
              setOpen(true);
            }}
          >
            {" "}
            <Icon name="add-icon" />{" "}
          </span>
        </div>

        <div className="row">
          <div className="col-8"></div>
          <div className="search-block col-4">
            <input
              type="text"
              className="form-control"
              placeholder="Search Tool"
              value={pageData.search}
              onChange={handleSearch}
            />
            <a className="search-btn">
              <Icon name="search-icon" />
            </a>
          </div>
        </div>
        <br />
        <InfiniteScroll
          dataLength={
            toolList?.data ? toolList?.data?.length : 0
          }
          next={fetchMoreData}
          height={"40vh"}
          hasMore={
            toolList?.totalDataCount <= toolList?.data?.length
              ? false
              : true
          }
          loader={
            isLoadingAllTools && (
              <div style={{ textAlign: "center" }}>
                {" "}
                <ClipLoader color="#3BA0AA" />{" "}
              </div>
            )
          }
        >
          <div className="table-div">
            <div style={{ display: "flex", flexWrap: "wrap" }}>

              {

                toolList?.data
                  ?.filter((tool) =>
                    tool?.toolsName
                      ?.toLowerCase()
                      .includes(pageData.search?.toLowerCase())
                  )
                  .map((item, index) => (
                    <Fragment key={index}>
                      <li
                        className="test-list"
                        onClick={() => {
                          if (checkPermission(readOnlyCheck(access,"Skills Management"))) return false 
                          handleEditTool(item)}}
                      >
                        <div className="test-card grey">
                          <div className="skill-img">
                            <figure>
                              <img src={item?.toolsIcon} width={"30px"} alt="" />
                            </figure>
                          </div>
                          <div className="skill-detail">
                            <h6 title={"title"}>{item?.toolsName}</h6>
                            <a
                              className="link"
                              onClick={(e) => {
                                if (checkPermission(readOnlyCheck(access,"Skills Management"))) return false 
                                e.stopPropagation();
                                dispatch(
                                  deleteTool(item._id, navigate, pageData)
                                );
                              }}
                            >
                              Remove
                            </a>
                          </div>
                        </div>
                      </li>
                    </Fragment>
                  ))}
              {Array.isArray(toolList?.data) && toolList?.data?.length > 0 ? (
                ""
              ) : (
                <tr><p>No Tool found...</p></tr>
              )}

            </div>
          </div>
        </InfiniteScroll>
      </div>
    </Fragment>
  );
};

export default Tools;
