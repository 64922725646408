import React from 'react'
import { EmployementStyle } from './css/EmployementStyle'
import EmployementIcon from "../../../assets/img/icons/education-icon.png";
import { Image, Text, View } from '@react-pdf/renderer';
import { Educations } from './css/Educations';
import { formDateDateYear } from '../../functions';
const Education = ({ data }) => {
    return (
        Array.isArray(data) && data?.length && (
            <View style={{ ...EmployementStyle.employetmentMain, marginTop: "10px" }}>
                <View style={EmployementStyle.emplLayoutLeft} >
                    <View>
                        <Image source={EmployementIcon} style={EmployementStyle.employementIcon} />
                    </View>
                    <View style={EmployementStyle.emplLayoutLists}>
                        <Text style={EmployementStyle.empHeading}> Education</Text>
                    </View>
                </View>
                <View>
                    {
                        data?.map((edu,index)=>(
                            <View key={index} style={Educations.rightLayout}>
                            <View style={Educations.left}>
                                <Text>{edu?.specialization}</Text>
                                <Text style={Educations.gray}>{edu?.specialization}</Text>
                            </View>
                            <View style={Educations.right}>
                                <Text>{formDateDateYear(edu?.startDate) } - {formDateDateYear(edu?.endDate)}</Text>
                            </View>
                        </View>
                        ))
                    }
                  
                </View>
            </View>
        )

    )
}

export default Education