import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateSnooze } from "../../../../service/action/developer";
import { useState } from "react";
import { useEffect } from "react";

const Snooze = ({ resumeData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [iseChecked, setChecked] = useState(false);

  useEffect(() => {
    if(resumeData?.isProfileSnooze)
    setChecked(resumeData?.isProfileSnooze);
  }, [resumeData]);

  return (
    <div className="setting-card">
      <h3>Snooze</h3>
      <div className="row">
        <div className="col-11">
          <p className="noti">
            You are not available for new jobs right now. Clients will not be
            able to contact you.
          </p>
        </div>
        <div className="col-1">
          <span onClick={() => dispatch(updateSnooze(navigate,setChecked))}>
            <input
              type="checkbox"
              name="switch"
              onChange={(e) => e.preventDefault()}
              checked={iseChecked}
            />
            <label className="label" htmlFor="switch3"></label>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Snooze;
