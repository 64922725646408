import React, { Fragment } from "react";
import Modal from "../modal/ModalBox";
import { BarLoader, ClipLoader } from "react-spinners";
import { Icon } from "../icon";

const PercentageLoaderContent = ({ uploadLoading, uploadPercentage }) => {
  const uploadVideoContent = () => {
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <div className="dr-logo">
              <Icon name="logo-olibr" />
            </div>
            <div className="skill-graph score-circle">
              <div className="skill-graph">
                <div className="single-chart">
                  <svg viewBox="0 0 36 36" className={`circular-chart green`}>
                    <path
                      className="circle-bg"
                      d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                      className="circle"
                      stroke-dasharray={`${uploadPercentage}, 100`}
                      d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <text x="18" y="20.35" className="percentage">
                      {uploadPercentage} %
                    </text>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="text-center">
            {uploadLoading && (<BarLoader color="#3ba0aa" className="bar-loader"/>)}
              <span>
                <p style={{ display: "inline" }}>
                  This may take few seconds.. Please Wait!
                </p>
              </span>{" "}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Modal
        open={uploadLoading}
        title={"uploading..."}
        isCloseAble={false}
        isFooterView={false}
        content={uploadVideoContent}
      />
    </Fragment>
  );
};

export default PercentageLoaderContent;
