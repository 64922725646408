import { Document, Page, Text, View } from "@react-pdf/renderer";
import ProfileView from "./css/ProfileView";
import BasicDetails from "./BasicDetails";
import Employement from "./Employement";
import Skills from "./Skills";
import Education from "./Education";
import Tools from "./Tools";
import Certifications from "./Certifications";
import Achievement from "./Achievement";
import VettedSkills from "./VettedSkills";
import MyReviews from "./MyReviews";

export const PDFProfilePreview = ({
  resumeData,
  userImage,
  skillList,
  stackLists,
  setLoader,
}) => {
  return (
    <Document>
      <Page style={ProfileView.page}>
        <BasicDetails resumeData={resumeData} userImage={userImage} />
        <View style={ProfileView.padd}>
          <VettedSkills
            data={resumeData?.devTechTestResult}
            skillList={skillList}
            stackLists={stackLists}
          />
          <Employement
            devEmployment={resumeData?.devEmployment}
            projects={resumeData?.devProjectDetails}
          />
          <Skills data={resumeData?.devTechSkills} />
          <Tools data={resumeData?.devTools} />
          <Education data={resumeData?.devAcademic} />
          <Certifications data={resumeData?.devCertificates} />
          <Achievement data={resumeData?.devAchievement} />
          <MyReviews data={resumeData?.devReviews} />
        </View>
        {setLoader(false)}
      </Page>
    </Document>
  );
};
