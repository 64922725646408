import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import NoFollow from "../../../common/staticData/seo/NoFollow";
import Favicon from "../../../assets/img/favicon.jpeg"
import { TwoLineDesc } from "../../functions";

const JobDetail = ({ baseUrl, jobdetail }) => {
  const [description, setDescription] = useState("")
  const [jobTitle, setJobTitle] = useState("")
  const getCityAndState = (jobAddress) => {
    let string = [];
    jobAddress?.map((itm) => {
      string.push(itm?.city, itm?.state)
    })
    return string?.toLocaleString();
  }
  const getCity = (jobAddress) => {
    let string = [];
    jobAddress?.map((itm) => {
      string.push(itm?.city)
    })
    return string?.toLocaleString();
  }
  const getState = (jobAddress) => {
    let string = [];
    jobAddress?.map((itm) => {
      string.push(itm?.state)
    })
    return string?.toLocaleString();
  }


  const [skills, setSkills] = useState("");
  useEffect(() => {
    let arr1 = [];
    let arr2 = [];
    arr1 = !!jobdetail?.devChooseSkills?.length
      ? jobdetail?.devChooseSkills?.map((item) => item?.skillName)
      : [];
    arr2 = !!jobdetail?.devChooseStacks?.length
      ? jobdetail?.devChooseStacks?.map((item) => item?.stackName)
      : [];
    let str = [...arr1, ...arr2]?.join(", ");
    setSkills(str);


    // let tempDesc = `Job Title:${jobdetail?.jobTitle + " | Olibr Jobs"
    //   }, Company : ${jobdetail?.organisation},Experience required : ${jobdetail?.minExpRange + " - " + jobdetail?.maxExpRange + " years"
    //   }, Skill Required: ${skills}, Salary: ${jobdetail?.budgetMin + " - " + jobdetail?.budgetMax + " LPA"
    //   }, Job Location:${jobdetail?.jobLocation === "Remote"
    //     ? "Remote"
    //     : getCityAndState(jobdetail?.jobAddress) +
    //     " (" +
    //     jobdetail?.jobLocation +
    //     " )"
    //   }. Click here to apply for this job.`


    // let tempTitle=jobdetail?.jobTitle + " | Olibr Jobs"
    if (jobdetail && Object.keys(jobdetail)?.length) {
      const locations = "Job Location:" + jobdetail?.jobLocation === "Remote" ? "Remote" : getCityAndState(jobdetail?.jobAddress) + " (" + jobdetail?.jobLocation + ")";
      let tempDesc = "Apply for "+jobdetail?.jobTitle + " | " + jobdetail?.organisation + " | " + locations+" | "+ TwoLineDesc(jobdetail?.shortDesc)+", Skill Required:"+ skills;
      let tempTitle = jobdetail?.jobTitle + " | " + jobdetail?.organisation + "| Olibr Jobs"     
      setDescription(tempDesc)
      setJobTitle(tempTitle)
    }
  }, [jobdetail]);

  return (
    <Helmet>
      <title>{jobTitle}</title>
      <link rel="icon" type="image/png" sizes="32x32" href={Favicon} />
      <meta charset="UTF-8" />
      <meta name="title" content={jobTitle} />
      <meta
        name="description"
        content={description}
      ></meta>
      <link rel="canonical" href={window.location.href} />
      <meta
        name="keywords"
        content="Software Developer Jobs, Software Developer Jobs USA, jobs, olibr.com, tech jobs, olibr, olibr.com, Hiring developers, remote software developers, work-from-home developers, wfh, remote jobs, IT jobs, US based IT companies, US Jobs, US IT Jobs"
      />
      <meta name="robots" content={NoFollow(jobdetail?.status)}></meta>

      <meta name="language" content="english"></meta>
      <html lang="en" />
      <meta name="url" content={window.location.href} />
      <meta name="identifier-URL" content={window.location.href}></meta>
      <meta name="referrer" content="origin-when-crossorigin"></meta>
      <link rel="image_src" href={jobdetail?.jobLogo} />
      <meta name="copyright" content="olibr.com" />

      <meta
        property="og:title"
        content={jobTitle}
      />
      <meta
        name="og:description"
        content={description}
      ></meta>
      <meta property="og:url" content={window.location.href} />
      <meta property="og:image" content={jobdetail?.jobLogo} />
      <meta property="og:site_name" content="@Olibr" />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@olibr" />
      <meta property="twitter:domain" content="olibr.com" />
      <meta property="twitter:url" content={window.location.href} />
      <meta
        name="twitter:title"
        content={jobTitle}
      />
      <meta
        name="twitter:description"
        content={description}
      />
      <meta name="twitter:image" content={jobdetail?.jobLogo} />

      <meta property="og:type" content="business.business" />
      <meta
        property="business:contact_data:street_address"
        content="26 Arrowhead Ln"
      />
      <meta
        property="business:contact_data:locality"
        getCity
        content={jobdetail?.jobLocation === "Remote"
          ? "Remote" : getCity(jobdetail?.jobAddress)}
      />
      <meta
        property="business:contact_data:region"
        content={jobdetail?.jobLocation === "Remote"
          ? "Remote" : getState(jobdetail?.jobAddress)}
      />
      <meta property="business:contact_data:postal_code" content="94025" />
      <meta
        property="business:contact_data:country_name"
        content={!!getCity(jobdetail?.jobAddress) ? "IN" : "US"}
      />

      {/* ------Apple Devices ------ */}
      <meta
        name="apple-mobile-web-app-title"
        content="New software developer jobs in USA companies | Olibr Jobs"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-touch-fullscreen" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black"></meta>
      {/* ----------JSON LD Schema for SEO------ */}

      <script type="application/ld+json">
        {JSON.stringify(
          // ------organisation - employment agency -----
          {
            "@context": "https://schema.org",
            "@type": "EmploymentAgency",
            name: "olibr.com",
            image: "",
            "@id": "",
            url: "",
            telephone: "",
            address: {
              "@type": "PostalAddress",
              streetAddress: "26 Arrowhead Ln",
              addressLocality: "Menlo Park, CA",
              postalCode: "94025",
              addressCountry: "US",
            },
            sameAs: [
              "https://www.facebook.com/olibrcom",
              "https://twitter.com/olibr",
              "https://www.linkedin.com/company/olibrcom/",
              "https://olibr.com/",
              "https://www.instagram.com/olibr",
              "https://www.youtube.com/@olibr",
              "",
            ],
            department: {
              "@type": "EmploymentAgency",
              name: "Olibr.com",
              image: "",
              telephone: "",
            },
          }
        )}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(
          // Speakable ---
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            name: "Apply for Jobs - Jobs List",
            speakable: {
              "@type": "SpeakableSpecification",
              xpath: [
                "/html/head/title",
                "/html/head/meta[@name='description']/@content",
              ],
            },
            url: `${baseUrl}/jobs`,
          }
        )}
      </script>
      {/* --------------- */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org/",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "Home",
              item: baseUrl,
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "Software developer jobs list",
              item: `${baseUrl}/jobs`,
            },
            {
              "@type": "ListItem",
              position: 3,
              name: jobdetail?.jobTitle,
              item: window.location.href,
            },
          ],
        })}
      </script>
    </Helmet>
  );
};

export default JobDetail;
