import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getAddOnListAction } from "../../service/action/admin";
import {
  formatIndianNumber,
  percentageMinus,
  returnAddOnQuantity,
  returnAddOnTotal,
  totalPrice,
} from "../functions";
import { useContext } from "react";
import { MainContext } from "../../context/MainContext";
import {
  paymentCheckOuActiont,
} from "../../service/action/common";

const AddOns = ({
  country,
  addOnData,
  setAddOnData,
  setAddOnCheckedLists,
  addOnCheckLists,
  selectedPlan,
}) => {
  const { user } = useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addOnList } = useSelector((state) => state.adminReducer);

  useEffect(() => {
    dispatch(getAddOnListAction(navigate, { page: "open", country }));
  }, [country, dispatch, navigate]);

  const increaseQntt = (addOn) => {
    let index = addOnData?.findIndex((item) => item?.addonId == addOn?._id);
    if (index >= 0) {
      let tempArr = [...addOnData];
      tempArr[index].addonQuantity = addOnData[index].addonQuantity + 1;
      setAddOnData(tempArr);
    } else {
      setAddOnData([...addOnData, { addonId: addOn?._id, addonQuantity: 2 }]);
    }
  };
  const decreaseQntt = (addOn) => {
    let index = addOnData?.findIndex((item) => item?.addonId == addOn?._id);
    if (index >= 0) {
      if (addOnData[index].addonQuantity > 1) {
        let tempArr = [...addOnData];
        tempArr[index].addonQuantity = addOnData[index].addonQuantity - 1;
        setAddOnData(tempArr);
      }
    }
  };

  const checkCheckedAddOn = (addOn) => {
    return !addOnCheckLists?.some((item) => item == addOn?._id);
  };

  const handleCheckBox = (addOn) => {
    let index = addOnCheckLists?.findIndex((item) => item == addOn?._id);
    if (index >= 0) {
      let filterData = addOnCheckLists?.filter((item) => item != addOn?._id);
      let filterAddOns = addOnData?.filter(
        (item) => item?.addonId != addOn?._id
      );
      setAddOnData(filterAddOns);
      setAddOnCheckedLists(filterData);
    } else {
      setAddOnData([
        ...addOnData,
        {
          addonId: addOn?._id,
          addonQuantity: 1,
        },
      ]);
      setAddOnCheckedLists([...addOnCheckLists, addOn?._id]);
    }
  };

  const handleSubmit = () => {
    if (user?.userType != "Client") {
      navigate("/login");
      return false;
    } else {
      let obj = { subscriptionPlanId: selectedPlan?._id, addons: addOnData };
      const callBackCheckout = (res) => {
        localStorage.setItem("checkout", JSON.stringify(res));
        navigate("/payment-overview");
      };
      dispatch(
        paymentCheckOuActiont({ navigate, data: obj, cb: callBackCheckout })
      );
    }
  };
  let isClientAdmin=(user?.userType==="Client" && user?.clientCurrentRole == "admin" )
  return addOnList?.map((item, index) => (
    <>
      <div key={index} className="custom-test">
        <div className="custom-test-form">
          <div className="checkbox-input">
           {isClientAdmin && <input
              type="checkbox"
              onClick={() => handleCheckBox(item)}
              checked={!checkCheckedAddOn(item)}
            />}
            <label>{item?.name}</label>
          </div>
         {isClientAdmin &&  <div className="numTests">
            <button
              disabled={checkCheckedAddOn(item)}
              onClick={() => decreaseQntt(item)}
            >
              -
            </button>
            <span>{returnAddOnQuantity(item, addOnData)}</span>
            <button
              disabled={checkCheckedAddOn(item)}
              onClick={() => increaseQntt(item)}
            >
              +
            </button>
          </div>}

          <div className="total">
            {!checkCheckedAddOn(item) && (
              <>
                Total: {item?.country == "IN" ? "₹" : "$"}
                <span>
                  {formatIndianNumber(returnAddOnTotal(item, addOnData))}
                </span>
              </>
            )}
          </div>
        </div>
        <p className={`colored-p ${!isClientAdmin && "ml-0"}`}>
          {item?.country == "IN" ? "₹" : "$"}
          {item?.price}/Test
        </p>
        <small className={!isClientAdmin && "ml-0"}>{item?.description}</small>
      </div>
      {!checkCheckedAddOn(item) && (
        <div className="pricing-summary">
          <div className="bill-overview">
            {selectedPlan?._id && (
              <div>
                <span>1 Plan</span> {item?.country == "IN" ? "₹" : "$"}
                {percentageMinus(
                  selectedPlan?.price,
                  selectedPlan?.discountPercentage
                )}{" "}
              </div>
            )}
            {selectedPlan?._id && "+"}
            <div>
              <span>
                {returnAddOnQuantity(item, addOnData)} Add-On
                {returnAddOnQuantity(item, addOnData) !== 1 ? "s" : ""}
              </span>{" "}
              {item?.country == "IN" ? "₹" : "$"}
              {formatIndianNumber(returnAddOnTotal(item, addOnData))}
            </div>{" "}
            =
            <div>
              <span>Total</span> {item?.country == "IN" ? "₹" : "$"}
              {totalPrice(
                returnAddOnTotal(item, addOnData),
                selectedPlan?.price || 0,
                selectedPlan?.discountPercentage || 0
              )}
            </div>
          </div>
          {isClientAdmin &&
          <button className="btn" type="button" onClick={handleSubmit}>
            Proceed to buy
          </button>}
        </div>
      )}
    </>
  ));
};

export default AddOns;
