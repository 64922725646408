import React from "react";
import { useNavigate } from "react-router-dom";

const PaymentFail = () => {
  const navigate = useNavigate();
  return (
    <div className="payment-result">
      <div className="payment-msg payment-fail">
        <i className="fa fa-solid fa-times-circle"></i>
        <h1 className="text-danger">payment failed!</h1>
        <p>
          We regret to inform you that the transaction failed. Please try again!
        </p>
      </div>
      <div>
        <a
          class="btn"
          href="#-"
          onClick={() => navigate("/client/subscription")}
        >
          Back to Home
        </a>
      </div>
    </div>
  );
};

export default PaymentFail;
