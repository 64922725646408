import React from 'react'
import AccordionFAQ from '../faqs/Accordion'
import { useState } from 'react';
import { useEffect } from 'react';
import {  useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';

const Stack = () => {
  const [titleOpen, setTitleOpen] = useState("");
  const [allLists, setAllLists] = useState([]);
  const { stackWithCategory, isStackWithCat } = useSelector(
    (state) => state?.adminReducer
  );

    useEffect(() => {
        let others = []
        let withoutCat = []
        stackWithCategory?.map((itm, index) => {
            let cat = Object?.keys(itm)?.[0];
            if (cat?.toLocaleLowerCase()?.includes("other")) {
                others = itm
            } else {
                withoutCat.push(itm)
            }
        })
        if(Object?.keys(others)?.length){
            withoutCat.push(others)
        }
        setAllLists(withoutCat)
    }, [stackWithCategory])
    const arr = [0, 1];

  const Loader = () => (
    <div style={{ textAlign: "center" }}>
      {" "}
      <ClipLoader color="#3BA0AA" height={10} />
    </div>
  );
  return (
    <>
      {isStackWithCat ? (
        <Loader />
      ) : (
        <div className="row">
          {arr?.map((it) => (
            <div className="col-12">
              {Array.isArray(allLists) === false
                ? ""
                : allLists
                    ?.filter((item, i) => i % 2 === it)
                    ?.map((item) => (
                      <>
                        <div key={item?._id}>
                          <div>
                            <div
                              className="faq-heading"
                            >
                              {!!Object?.keys(item)?.[0] &&
                                Object?.keys(item)?.[0]}
                            </div>
                              {item[Object?.keys(item)?.[0]]?.map(
                                (items, index) => (
                                  <>
                                  <div className="accordion-faq">
                                      <AccordionFAQ
                                        key={items?._id}
                                        title={items?.stackName}
                                        content={items?.description}
                                        setTitleOpen={setTitleOpen}
                                        titleOpen={titleOpen}
                                        image={items?.stackIcon}
                                      />
                                      </div>
                                    </>
                                  )
                                )}
                          </div>
                        </div>
                        {/* )} */}
                      </>
                    ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Stack;
