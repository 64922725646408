import React from "react";
import BannerSection from "../../view/faqs/BannerSection";
import { useState } from "react";
import { Fragment } from "react";

const NavBar = () => {

  return (
    <Fragment>
      <BannerSection
        heading={"Skill Library"}
        classname={"skill-stack-banner"}
        name={"Skills"}
      />
    </Fragment>
  );
};

export default NavBar;
