import React from "react";
import PropTypes from "prop-types";
import EducationSection from "./EducationSection";
import Certification from "./Certification";
import AchivementSection from "./AchivementSection";
import Employement from "./Employement";
import AdditionalProjects from "./AdditionalProjects";
const AboutSection = ({ devData }) => {

  return (
    <div className="content-column col-8">
      <div className="job-detail">
        <h4>Candidate&apos;s About</h4>

        {/* <!-- About Video Box --> */}
        {/* <div className="video-outer">
          <CustomModal
            open={openVideo}
            onClose={() => setOpenVideo(false)}
            headerShown={false}
            footerShown={false}
            content={() => <VideoModal link={devData?.devVideo} />}
            bodyClass="modal-type-two"
          />
          {devData?.devVideo && (
            <div className="video-box">
              <figure className="image">
                <div className="play-now" role="button">
                  <Image
                    src={devData?.userImage}
                    alt="video banner"
                    width={850}
                    height={380}
                  ></Image>
                  <i
                    className="icon flaticon-play-button-3"
                    aria-hidden="true"
                    onClick={() => setOpenVideo(true)}
                  ></i>
                </div>
              </figure>
            </div>
          )}
        </div> */}
        
        <p>{devData?.devTellUrStory}</p>

        {/* <!-- Candidate Resume Start --> */}
        <Employement devData={devData} />
        {/* <OtherProjectCarousel project={devData?.devAdditionalProject} /> */}
        <AdditionalProjects project={devData?.devAdditionalProject} />
        <EducationSection devAcademic={devData?.devAcademic} />
        <Certification devCertificates={devData?.devCertificates} />
        <AchivementSection devAchievement={devData?.devAchievement} />

        {/* <!-- Candidate Resume End --> */}
      </div>
    </div>
  );
};
AboutSection.propTypes = {
  devData: PropTypes.object,
};
export default AboutSection;
