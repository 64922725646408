import { Fragment } from "react";
import CandidateList from "../../client/jobs/CandidateList";

const Candidate = ({ }) => {
  return (
    <Fragment>
      <CandidateList/>
    </Fragment>
  );
};

export default Candidate;
