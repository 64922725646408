import { Fragment, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Skills.css";
import { Controller, useForm } from "react-hook-form";
import { ClipLoader, ScaleLoader } from "react-spinners";
import Select from "react-select";
import "./Skills.css";
import {
  getAdminSkillLists,
  renderAllHackerrankAssessments,
  renderTopicList,
  getAllIcons,
  updateSkillStatus,
  editSkill,
  addSkill,
  uploadIcons,
  deleteSkill,
  getSkillTest,
  renderAllHackerrankAssessmentsNoLimitAction,
  updateAssessmentNameAction,
  getSkillCategory,
  getSkillWithCategory,
  createSkillsCategory,
  updateSkillsCategory,
} from "../../../../service/action/admin";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../components/modal/ModalBox";
import { Icon } from "../../../../components/icon";
import { olibrToast } from "../../../../components/toaster";
import SelectOption from "../../../../components/select/SelectOption";
import DataTables from "../../../../components/data-tables/DataTables";
import ColumnLists from "./ColumnLists";
import {
  checkPermission,
  readOnlyCheck,
} from "../../../../components/functions";
import { useContext } from "react";
import { MainContext } from "../../../../context/MainContext";
import { isCoreArray } from "../../../developer/review/constants";
import OlibrEditor from "../../../../components/editor";

const Skills = () => {
  const dispatch = useDispatch();
  const {
    assessmentList,
    assessmentListNoLimit,
    topicList,
    skillIcons,
    isIconUploading,
    uploadIcon,
    testSkills,
    skillCategory,
    skillWithCategory,
    isLoadingSaveSkill,
  } = useSelector((state) => state.adminReducer);
  const navigate = useNavigate();
  const {
    formState: { errors },
  } = useForm();
  const {
    register: skillRegister,
    handleSubmit: skillSubmit,
    setValue: setSkillValue,
    getValues: getSkillValue,
    control: skillControll,
    reset: resetSkillForm,
    formState: { errors: skillError },
  } = useForm();
  const {
    register: aNameRegister,
    handleSubmit: aNameSubmit,
    setValue: setANameValue,
    getValues: getANameValue,
    control: aNameControll,
    formState: { errors: aNameError },
  } = useForm();

  const uploadIconRef = useRef();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editorText, setEditorText] = useState("");
  const [error, setError] = useState([]);
  const [deleteIdModal, setDeleteIdModal] = useState({
    deleteModalOpen: false,
    deleteId: "",
  });

  const {
    user: { access },
  } = useContext(MainContext);
  const [assessmentLists, setAssessmentList] = useState([]);
  const [pageData, setPageData] = useState({
    page: 1,
    limit: 17,
  });
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (assessmentList && testSkills) {
      let tempAssess = [];
      let testSkill = [];
      assessmentList?.map((item) => {
        tempAssess?.push({ value: item?.id, label: item?.name });
      });

      testSkills?.map((item) => {
        testSkill?.push({ value: item?.id, label: item?.name });
      });
      let arr = [...tempAssess, ...testSkill];
      arr.sort((a, b) => {
        let fa = a?.label?.toLowerCase(),
          fb = b?.label?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      setAssessmentList(arr);
    }
  }, [assessmentList, testSkills]);

  const [unlimitAssessmentSkills, setUnlimitAssessmentSkills] = useState([]);
  useEffect(() => {
    let allAssessments = [];
    allAssessments = assessmentListNoLimit?.map((item) => ({
      value: item?.id,
      label: item?.name,
    }));
    setUnlimitAssessmentSkills(allAssessments);
  }, [assessmentListNoLimit]);

  const openModal = () => {
    setEditorText("");
    setError([]);
    resetSkillForm();
    setModal(true);
  };

  // const filterSkills = (selectSkills) => {
  //   let result = []
  //   for (let x of selectSkills) {
  //     let tempskills = skillLi?.data?.find((itm) => itm?._id == x)
  //     if (tempskills) {
  //       result?.push(tempskills)
  //     }

  //   }
  //   return result;
  // }
  const closeModal = () => {
    setModal(false);
    resetSkillForm();
    isEdit && setIsEdit(false);
    setSkillObj({
      skillTestIcon:
        "https://olibr.s3.amazonaws.com/resume/906d8d43eb3ac4c01653140199554.png",
    });
  };

  const handleAddCategory = () => {
    if (groupDetails?.edit) {
      let tempObj = {
        categoryId: groupDetails?.id,
        categoryName: groupDetails?.name,
      };
      dispatch(
        updateSkillsCategory(navigate, tempObj, setGroupDetails, "skill")
      );
    } else {
      dispatch(
        createSkillsCategory(
          navigate,
          { name: groupDetails?.name },
          setGroupDetails
        )
      );
    }
  };

  useEffect(() => {
    dispatch(renderAllHackerrankAssessments(navigate));
    dispatch(getSkillTest(navigate));
    dispatch(renderTopicList(navigate));
    dispatch(getAllIcons(navigate));
    dispatch(getSkillCategory(navigate));
    dispatch(getSkillWithCategory(navigate, "all"));
  }, [navigate, dispatch]);

  // useEffect(() => {
  // dispatch(getAdminSkillLists("skill", navigate, pageData));
  // }, [pageData.limit]);

  const updateSatus = (skill, category) => {
    let statusValue = "Inactive";
    if (skill?.skillStatus !== "Active") {
      statusValue = "Active";
    }
    const newData = {
      skillId: skill?._id,
      skillStatus: statusValue,
    };
    dispatch(updateSkillStatus(newData, navigate, category));
  };

  const [skillObj, setSkillObj] = useState({
    skillTestIcon:
      "https://e7.pngegg.com/pngimages/980/714/png-clipart-computer-icons-code-symbol-coding-miscellaneous-angle.png",
  });

  const [groupDetails, setGroupDetails] = useState({
    modal: false,
    edit: false,
    name: "",
    id: "",
  });

  const editCategory = (category) => {
    let catId;
    skillCategory?.map((item) => {
      if (item?.name == category) {
        catId = item?._id;
      }
    });
    setGroupDetails({
      id: catId,
      edit: true,
      name: category,
      modal: true,
    });
  };

  const handleEdit = (skill, category) => {
    setModal(true);
    setIsEdit(true);
    resetSkillForm();
    setError([]);
    let tempObj = {};
    skillCategory?.map((item) => {
      if (item?.name == category) {
        tempObj = {
          value: item?._id,
          label: item?.name,
        };
      }
    });
    setSkillValue("category", tempObj);
    setSkillValue("skillName", skill?.skillName);
    setSkillValue("skillGroupName", skill?.skillGroupName);
    setSkillValue("skillLevel", skill?.skillLevel);
    setSkillValue("skillId", skill?._id);
    setSkillValue("skillTestTime", skill?.skillTestTime);
    setSkillValue("maxScore", skill?.maxScore);
    setSkillValue("cutOffScore", skill?.cutOffScore);
    setSkillValue("skills", [{ value: skill?.aId, label: skill?.aName }]);
    setEditorText(skill?.description);
    const tempTopic = topicList?.default
      ?.filter((item) => skill?.topic?.includes(item?.id))
      ?.map((item) => ({ value: item?.id, label: item?.name }));
    setSkillValue("topic", tempTopic);
    setSkillValue(
      "isCore",
      isCoreArray?.find((item) => item?.value === skill?.isCore)
    );
    setSkillObj({
      skillTestIcon: skill?.skillTestIcon,
    });

    setDeleteIdModal({
      ...deleteIdModal,
      deleteId: skill?._id,
    });
  };

  const handleSubmit = (data) => {
    if (!editorText || editorText == "<p><br></p>") {
      setError(["description"]);
      return false;
    } else {
      setError([]);
    }

    const newData = {
      ...skillObj,
      ...data,
      isCore: data?.isCore?.value,
      category: data?.category?.value,
      aId: data?.skills?.value?.toString(),
      aName: data?.skills?.label,
      description: editorText,
      topic: Array.isArray(data?.topic)
        ? data?.topic?.map((item) => item?.value)
        : [],
    };

    newData?.hasOwnProperty("skills") && delete newData?.skills;
    isEdit
      ? dispatch(
          editSkill(
            newData,
            navigate,
            "skill",
            pageData,
            closeModal,
            resetSkillForm
          )
        )
      : dispatch(
          addSkill(
            newData,
            navigate,
            "skill",
            pageData,
            closeModal,
            resetSkillForm
          )
        );
  };
  useEffect(() => {
    uploadIcon &&
      Object.keys(uploadIcon)?.length > 0 &&
      setSkillObj({
        skillTestIcon: uploadIcon?.icons?.[uploadIcon?.icons?.length - 1],
      });
  }, [uploadIcon]);

  const onUploadIcon = (e) => {
    let formData = new FormData();
    formData.append("imageFile", e?.target?.files[0]);
    if (e?.target?.files[0]?.size > 1024 * 1024) {
      olibrToast.error("Please use image of size less than 1MB");
    } else {
      dispatch(uploadIcons(formData, navigate));
    }
    e.target.value = null;
  };

  const deleteSkillModalOpen = () => {
    setModal(false);
    setDeleteIdModal({
      ...deleteIdModal,
      deleteModalOpen: true,
    });
  };

  const deleteSkillModalClose = () => {
    setDeleteIdModal({
      deleteModalOpen: false,
      deleteId: "",
    });
  };

  const handleSkillDelete = () => {
    dispatch(deleteSkill(deleteIdModal.deleteId, navigate, "skill", pageData));
    deleteSkillModalClose();
    closeModal();
  };
  const handleSearchSkill = (e) => {
    setSearch(e.target.value);
    // dispatch(
    //   getAdminSkillLists("skill", navigate, {
    //     ...pageData,
    //     page: 1,
    //     search: e.target.value,
    //   })
    // );
    // setPageData({ ...pageData, search: e.target.value });
  };

  const deleteContent = () => {
    return (
      <>
        <div className="form-group">
          <p>Are you sure you want to delete this Skill?</p>
        </div>
      </>
    );
  };

  const handleContentFunc = () => {
    return (
      <form>
        <div className="form-group">
          <label
            htmlFor=""
            className={`${skillError?.skillName && "text-danger"} new-label`}
          >
            Skill Name*
          </label>
          <input
            name="skillName"
            {...skillRegister("skillName", {
              required: true,
            })}
            type="text"
            className="form-control"
          />
        </div>
        <div className="form-group select-group">
          <label className="new-label">
            Select skills test associated with
          </label>
          <Controller
            control={skillControll}
            name="skills"
            {...skillRegister("skills")}
            render={({ field }) => (
              <SelectOption
                optionArray={assessmentLists}
                fieldProp={field}
                getValue={getSkillValue}
                getValueName={"skills"}
                closeMenuOnSelect={true}
                searchable={true}
              />
            )}
          />
        </div>
        <div className="form-group select-group">
          <label className="new-label">Select topic</label>
          <Controller
            control={skillControll}
            name="topic"
            {...skillRegister("topic")}
            render={({ field }) => (
              <SelectOption
                optionArray={topicList?.default?.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                fieldProp={field}
                getValue={getSkillValue}
                getValueName={"topic"}
                closeMenuOnSelect={true}
                searchable={true}
              />
            )}
          />
        </div>
        <div className="form-group select-group">
          <label className="new-label">Category</label>
          <Controller
            control={skillControll}
            name="category"
            {...skillRegister("category")}
            render={({ field }) => (
              <SelectOption
                optionArray={skillCategory?.map((item) => ({
                  value: item?._id,
                  label: item?.name,
                }))}
                fieldProp={field}
                getValue={getSkillValue}
                getValueName={"category"}
                closeMenuOnSelect={true}
                searchable={true}
              />
            )}
          />
        </div>
        <div className="form-group select-group">
          <label className={`new-label ${skillError?.isCore && "text-danger"}`}>
            Select Type
          </label>
          <Controller
            control={skillControll}
            name="isCore"
            {...skillRegister("isCore", {
              required: true,
            })}
            render={({ field }) => (
              <SelectOption
                optionArray={isCoreArray}
                fieldProp={field}
                getValue={getSkillValue}
                getValueName={"isCore"}
                closeMenuOnSelect={true}
                searchable={true}
              />
            )}
          />
        </div>
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <label className="new-label">Skill Test Time</label>
              <input
                name="skillTestTime"
                {...skillRegister("skillTestTime")}
                type="number"
                min={0}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label
                htmlFor=""
                className={`${skillError?.maxScore && "text-danger"} new-label`}
              >
                Max score*
              </label>
              <input
                {...skillRegister("maxScore", {
                  required: true,
                })}
                name="maxScore"
                type="number"
                min={0}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label
                htmlFor=""
                className={`${
                  skillError?.cutOffScore && "text-danger"
                } new-label`}
              >
                Cutoff score*
              </label>
              <input
                {...skillRegister("cutOffScore", {
                  required: true,
                })}
                name="cutOffScore"
                type="number"
                min={0}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label
                htmlFor=""
                className={`${
                  skillError?.skillGroupName && "text-danger"
                } new-label`}
              >Group Name*</label>
              <input
                {...skillRegister("skillGroupName", {
                  required: true,
                })}
                name="skillGroupName"
                type="text"
                min={0}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label
                htmlFor=""
                className={`${
                  skillError?.skillLevel && "text-danger"
                } new-label`}
              >Skill Level*</label>
              <input
                {...skillRegister("skillLevel", {
                  required: true,
                })}
                name="skillLevel"
                type="number"
                min={0}
                className="form-control"
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <label
            htmlFor=""
            className={`floating-label ${
              error?.includes("description") && "text-danger"
            }`}
          >
            Description *
          </label>
          <OlibrEditor
            value={editorText ? editorText : ""}
            onChange={(e) => setEditorText(e)}
          />
        </div>
        <div className="row-flex">
          <div className="form-group">
            <label className="new-label">Choose icon</label>
            <Select
              isLoadingSkillList={false}
              isSearchable={false}
              maxMenuHeight={200}
              value={{
                value: skillObj?.skillTestIcon,
                label: (
                  <div style={{ width: "200px", display: "flex" }}>
                    {" "}
                    <img
                      src={skillObj?.skillTestIcon}
                      height="30px"
                      width="30px"
                      alt=""
                    />{" "}
                  </div>
                ),
              }}
              menuPlacement={"top"}
              options={skillIcons?.map((icon) => ({
                value: icon,
                label: (
                  <div style={{ width: "200px", display: "flex" }}>
                    {" "}
                    <img src={icon} height="30px" width="30px" alt=""/>{" "}
                  </div>
                ),
              }))}
              onChange={(e) => setSkillObj({ skillTestIcon: e?.value })}
            />
          </div>
          <a
            href="#-"
            className="file-icon"
            onClick={() => {
              if (checkPermission(readOnlyCheck(access, "Skills Management")))
                return false;
              uploadIconRef.current.click();
            }}
          >
            {isIconUploading ? (
              <ScaleLoader color="#3BA0AA" height={10} />
            ) : (
              "Upload icon"
            )}
          </a>
        </div>
      </form>
    );
  };

  const groupContent = () => (
    <>
      <div className="form-group">
        <label
          htmlFor=""
          className={`${aNameError?.aName && "text-danger"} floating-label`}
        >
          Group Name
        </label>
        <input
          value={groupDetails?.name}
          onChange={(e) =>
            setGroupDetails({
              ...groupDetails,
              name: e.target.value,
            })
          }
          type="text"
          className="form-control"
        />
      </div>
    </>
  );

  const [renameOpen, setRenameOpen] = useState(false);
  const submitAName = (data) => {
    console.log(getANameValue("aObject"));
    console.log(getANameValue("aName"));
    let finalPosting = {
      assessmentId: data?.aObject?.value,
      newName: data?.aName,
    };
    // console.log({assessmentId: data?.aObject?.value, newName: data?.aName})
    dispatch(
      updateAssessmentNameAction(
        finalPosting,
        "skill",
        closeANameModal,
        navigate,
        pageData
      )
    );
  };

  const aNameContent = () => {
    return (
      <Fragment>
        <div className="form-group select-group">
          <label
            className={`${aNameError?.aObject && "text-danger"} floating-label`}
          >
            Select skills test associated with*
          </label>
          <Controller
            control={aNameControll}
            name="aName"
            {...aNameRegister("aObject", { required: true })}
            render={({ field }) => (
              <SelectOption
                optionArray={unlimitAssessmentSkills}
                fieldProp={field}
                getValue={getANameValue}
                getValueName={"aObject"}
                closeMenuOnSelect={true}
                searchable={true}
              />
            )}
          />
        </div>
        <div className="form-group">
          <label
            htmlFor=""
            className={`${aNameError?.aName && "text-danger"} floating-label`}
          >
            Assessment Name*
          </label>
          <input
            name="aName"
            {...aNameRegister("aName", {
              required: true,
            })}
            type="text"
            className="form-control"
          />
        </div>
      </Fragment>
    );
  };
  const closeANameModal = () => {
    setANameValue("aName", "");
    setANameValue("aObject", "");
    setRenameOpen(false);
  };

  const openRenameModel = () => {
    dispatch(renderAllHackerrankAssessmentsNoLimitAction(navigate));
    setRenameOpen(true);
  };

  const addGroup = () => {
    setGroupDetails({ modal: true });
  };
  return (
    <Fragment>
      <div className="main-div">
        <Modal
          open={deleteIdModal.deleteModalOpen}
          title={"Delete Skill"}
          onClose={deleteSkillModalClose}
          onDone={handleSkillDelete}
          onCancel={deleteSkillModalClose}
          content={deleteContent}
        />

        <Modal
          open={renameOpen}
          title={"Update Assessment Name"}
          onClose={closeANameModal}
          onDone={aNameSubmit(submitAName)}
          onCancel={closeANameModal}
          content={aNameContent}
        />

        <input
          ref={uploadIconRef}
          type="file"
          onChange={onUploadIcon}
          className="input-file"
          accept=".png, .jpeg, .jpg, .svg"
        />
        <div className="skills-management">
          <div className="block-header">
            <h4 className="">Skills</h4>
            <span
              onClick={() => {
                if (checkPermission(readOnlyCheck(access, "Skills Management")))
                  return false;
                openModal();
              }}
            >
              {" "}
              <Icon name="add-icon" />{" "}
            </span>
            <span>
              <button
                className="submitBtn"
                onClick={() => {
                  if (
                    checkPermission(readOnlyCheck(access, "Skills Management"))
                  )
                    return false;
                  openRenameModel();
                }}
              >
                Update Assessment Name
              </button>{" "}
            </span>{" "}
            <span>
              <button
                className="submitBtn skill-group-btn"
                onClick={() => {
                  if (
                    checkPermission(readOnlyCheck(access, "Skills Management"))
                  )
                    return false;
                  addGroup();
                }}
              >
                Add Group
              </button>
            </span>
          </div>

          <div className="row">
            <div className="col-8"></div>
            <div className="search-block col-4">
              <input
                type="text"
                className="form-control"
                value={search}
                placeholder="Search skills"
                onChange={handleSearchSkill}
              />
              <a className="search-btn">
                <Icon name="search-icon" />
              </a>
            </div>
          </div>
          <br />
          <div className="table-div">
            {/* <DataTables
              columns={ColumnLists(updateSatus)}
              fetchMoreData={fetchMoreData}
              listLoading={isLoadingSkillList}
              listData={skillList}
              Infinit={true}
              onRowClick={(row) => handleEdit(row)}
            />
             */}

            <table className="table">
              <thead>
                <tr>
                  <th className="column">
                    <div className="serialNumber">S.No</div>
                  </th>
                  <th className="column">Category Name</th>
                  <th className="column">Assessments</th>
                  <th className="column">Status</th>
                </tr>
              </thead>
              <tbody>
                {skillWithCategory?.map((itm, index) => (
                  <>
                    <tr
                      key={index}
                      className="faqdiv"
                      onClick={() => {
                        if (
                          checkPermission(
                            readOnlyCheck(access, "Skills Management")
                          )
                        )
                          return false;
                        editCategory(Object?.keys(itm)?.[0]);
                      }}
                    >
                      <td className="titleQues sno">
                        <b>{index + 1}</b>
                      </td>
                      <td className="titleQues">
                        <b>{Object?.keys(itm)?.[0]}</b>
                      </td>
                    </tr>
                    {itm[Object?.keys(itm)?.[0]]
                      ?.filter(
                        (items) =>
                          items?.skillName
                            ?.toLowerCase()
                            ?.includes(search?.toLowerCase()) ||
                          items?.aName
                            ?.toLowerCase()
                            ?.includes(search?.toLowerCase())
                      )
                      ?.map((item, ind) => (
                        <tr
                          key={ind}
                          className="faqdiv"
                          onClick={() => {
                            if (
                              checkPermission(
                                readOnlyCheck(access, "Skills Management")
                              )
                            )
                              return false;
                            handleEdit(item, Object?.keys(itm)?.[0]);
                          }}
                        >
                          <td className="titleQues sno"></td>
                          <td className="titleQues">
                            <button className="skillname">
                              <img
                                src={item?.skillTestIcon}
                                alt=""
                                width="30"
                                height="30"
                              />
                              <span> {item?.skillName}</span>
                            </button>
                          </td>
                          <td>
                            <span> {item?.aName}</span>
                          </td>
                          <td>
                            <span>
                              <input
                                type="checkbox"
                                name="switch"
                                onChange={(e) => e.preventDefault()}
                                checked={item?.skillStatus === "Active"}
                              />
                              <label
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (
                                    checkPermission(
                                      readOnlyCheck(access, "Skills Management")
                                    )
                                  )
                                    return false;
                                  updateSatus(item, Object?.keys(itm)?.[0]);
                                }}
                                className="label"
                                htmlFor="switch3"
                              ></label>
                            </span>
                          </td>
                        </tr>
                      ))}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <br />
      </div>

      <Modal
        open={groupDetails?.modal}
        title={`${groupDetails?.edit ? "Edit" : "Add"} Group Name`}
        content={groupContent}
        onClose={() => setGroupDetails({})}
        onCancel={() => setGroupDetails({})}
        onDone={handleAddCategory}
      />
      <Modal
        open={modal}
        title={`${isEdit ? "Edit" : "Add"} Skill`}
        isDoneLoading={isLoadingSaveSkill}
        onDone={skillSubmit(handleSubmit)}
        onClose={closeModal}
        onCancel={closeModal}
        isDeletable={isEdit && !readOnlyCheck(access, "Skills Management")}
        isDeleteIcon={true}
        onDelete={deleteSkillModalOpen}
        content={handleContentFunc}
      />
    </Fragment>
  );
};

export default Skills;
