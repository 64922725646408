import React, { Fragment, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import add from "../../../assets/img/icons/add_icon.svg";
import { useEffect } from "react";
import {
  getBlogByIdOpen,
  getBlogCatOpen,
} from "../../../service/action/common/index";
import {
  deleteBlogNew,
  updateBlogNew,
  uploadIcons,
} from "../../../service/action/admin";
import { statusArr } from "./blog-listing-common/CommonConstant";
import { modules, formats } from "../../../common/constant/quill-editor/Common";
import { ClipLoader } from "react-spinners";
import { olibrToast } from "../../../components/toaster";
import Modal from "../../../components/modal/ModalBox";
import { Icon } from "../../../components/icon";
import { Controller, useForm } from "react-hook-form";
import Select from 'react-select';

const EditBlog = () => {
  const {
    register: blogRegister,
    handleSubmit: blogSubmit,
    setValue: setBlogValue,
    getValues: getBlogValue,
    control: blogControll,
    reset: resetBlogForm,
    formState: { errors: blogError },
  } = useForm();

  const {
    register: htmlRegister,
    handleSubmit: htmlSubmit,
    setValue: setHtmlValue,
    reset: resetHtmlForm,
    formState: { errors: htmlError },

  } = useForm();

  const {
    register: helmetRegister,
    handleSubmit: helmetSubmit,
    setValue: setHelmetValue,
    getValues: getHelmetValue,
    reset: resetHelmetForm,
    formState: { errors: helmetError },

  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { blogId } = useParams();

  const { blogOpenDetail, blogCatOpenList } = useSelector(
    (state) => state.commonReducer
  );
  const { uploadIcon, isIconUploading, updateBlogNewLoading, deleteBlogNewLoading, blogNameIdList } = useSelector((state) => state.adminReducer);

  const [isFaq, setIsFaq] = useState(false);
  useEffect(() => {
    dispatch(getBlogByIdOpen(blogId, navigate));
    dispatch(getBlogCatOpen(navigate));
  }, [navigate, dispatch]);

  useEffect(() => {
    resetBlogForm();
    resetHelmetForm();
    resetHtmlForm();
  }, [])

  useEffect(() => {
    setBlogValue("blogTitle", blogOpenDetail?.data?.blogTitle);
    setBlogValue("coverImage", blogOpenDetail?.data?.coverImage);
    setBlogValue("authorName", blogOpenDetail?.data?.authorName);
    setBlogValue("authorDesg", blogOpenDetail?.data?.authorDesg);
    setBlogValue("blogCategory", blogOpenDetail?.data?.blogCategory);
    setBlogValue("publishedDate", blogOpenDetail?.data?.publishedDate);
    setBlogValue("blogCategory", blogOpenDetail?.data?.blogCategory);
    setBlogValue("isPopular", blogOpenDetail?.data?.isPopular);
    setBlogValue("blogStatus", blogOpenDetail?.data?.blogStatus);
    setBlogValue("slug", blogOpenDetail?.data?.slug);
    // setBlogValue("faqs", blogOpenDetail?.data?.faqs);
    setBlogValue("blogTags", blogOpenDetail?.data?.blogTags);
    setBlogValue("authorDescription", blogOpenDetail?.data?.authorDescription);
    setBlogValue("numOfLikes", blogOpenDetail?.data?.numOfLikes);
    setBlogValue("numOfDisLikes", blogOpenDetail?.data?.numOfDisLikes);
    setBlogValue("blogContent", blogOpenDetail?.data?.blogContent);
    setBlogValue("minutesRead", blogOpenDetail?.data?.minutesRead);
    setHelmetValue("helmetText", blogOpenDetail?.data?.helmetText);
    setHtmlValue("htmlCode", blogOpenDetail?.data?.blogContent)
    setRelatedArr(blogOpenDetail?.data?.relatedBlogs)
    setBlogDetail({
      ...blogDetail,
      tag: blogOpenDetail?.data?.blogTags,
    })
    setBlogDetails({blogDetails ,faqs: blogOpenDetail?.data?.faqs});
    setBlogHead(blogOpenDetail?.data?.blogTitle);
  }, [blogOpenDetail]);

  const [blogDetail, setBlogDetail] = useState({
    tag: [],
    blogStatus: "Draft"
  })

  const [htmlCode, setHtmlCode] = useState("")
  const [catList, setCatList] = useState([]);
  useEffect(() => {
    let arr = [];
    arr = blogCatOpenList?.data?.map((item) => ({
      value: item?._id,
      label: item?.categoryName,
    }));
    setCatList(arr);
  }, [blogCatOpenList]);

  const navBack = () => {
    navigate("/admin/blog");
  };

  const [blogDetails, setBlogDetails] = useState({
    faqs: [],
  });

  const handleDelete = () => {
    dispatch(deleteBlogNew(blogId, navigate));
  };
  const handleSubmitBlog = (data) => {

    data?.hasOwnProperty("tags") && delete data?.tags;
    let idStr = data?.blogContent;
    let newIdStr1 = "";
    let newIdStr2 = "";
    let newIdStr3 = "";
    let newIdStr4 = "";
    let newIdStr5 = "";
    let newIdStr6 = "";

    let s = document.createElement("div");
    s.innerHTML = idStr;
    
    let hArr = s.querySelectorAll("h1 ,h2, h3, h4, h5, h6")
    let arrIndex = 0;
    let arrId1 = idStr?.split("<h1");
    for (let i = 0; i < arrId1?.length; i++) {
      newIdStr1 += i
        ? `<h1 id='${String(hArr[arrIndex++]?.innerText?.split(" ")?.join("-"))}' ${arrId1[i]} `
        : arrId1[i];
    }

    let arrId2 = newIdStr1?.split("<h2");
    for (let i = 0; i < arrId2?.length; i++) {
      newIdStr2 += i
        ? `<h2 id='${String(String(hArr[arrIndex++]?.innerText?.split(" ")?.join("-")))}' ${arrId2[i]} `
        : arrId2[i];
    }

    let arrId3 = newIdStr2?.split("<h3");
    for (let i = 0; i < arrId3?.length; i++) {
      newIdStr3 += i
        ? `<h3 id='${String(String(hArr[arrIndex++]?.innerText?.split(" ")?.join("-")))}' ${arrId3[i]} `
        : arrId3[i];
    }

    let arrId4 = newIdStr3?.split("<h4");
    for (let i = 0; i < arrId4?.length; i++) {
      newIdStr4 += i
        ? `<h4 id='${String(String(hArr[arrIndex++]?.innerText?.split(" ")?.join("-")))}' ${arrId4[i]} `
        : arrId4[i];
    }

    let arrId5 = newIdStr4?.split("<h5");
    for (let i = 0; i < arrId5?.length; i++) {
      newIdStr5 += i
        ? `<h5 id='${String(String(hArr[arrIndex++]?.innerText?.split(" ")?.join("-")))}' ${arrId5[i]} `
        : arrId5[i];
    }

    let arrId6 = newIdStr5?.split("<h6");
    for (let i = 0; i < arrId6?.length; i++) {
      newIdStr6 += i
        ? `<h6 id='${String(String(hArr[arrIndex++]?.innerText?.split(" ")?.join("-")))}' ${arrId6[i]} `
        : arrId6[i];
    }

    let newstr = data?.slug;
    newstr = newstr?.trim();
    setTitleChange(false)    
    dispatch(
      updateBlogNew(blogId, { ...data, slug: newstr, faqs: blogDetails?.faqs, blogContent: newIdStr6, blogTitle: blogHead, relatedBlogs: relatedArr ? relatedArr : [] }, navigate)
    );
  };



  const [isPreview, setPreview] = useState(false);

  useEffect(() => {
    resetBlogForm();
  }, [])
  const handleAddCategory = () => {
    navigate("/admin/blog-category-list");
  };

  const uploadIconRef = useRef();

  const onUploadIcon = async (e) => {
    let formData = new FormData();
    formData.append("imageFile", e.target.files[0]);
    if (e.target.files[0]?.size > 1024 * 1024) {
      olibrToast.error("Please use image of size less than 1MB");
    }
    else {
      dispatch(uploadIcons(formData, navigate));
    }
    e.target.value = null;
  };

  useEffect(() => {
    setBlogValue("coverImage", uploadIcon?.icons?.[uploadIcon?.icons?.length - 1])
  }, [uploadIcon]);

  const renderBlogContent = () => {
    return (
      <Fragment>
        <strong>Cover Image: </strong>
        <div className=""><img src={getBlogValue("coverImage")} style={{ margin: "auto" }} /></div>
        <br />
        <div dangerouslySetInnerHTML={{ __html: getBlogValue("blogContent") }}></div>
      </Fragment>
    );
  };

  const handleChangeQues = (e) => {
    setQuestionPair({ ...questionPair, [e.target.name]: e.target.value });
  };

  const [questionPair, setQuestionPair] = useState({
    question: "",
    answer: "",
  });

  const [edit, setEdit] = useState({ index: "", isEdit: false });
  const editQuestion = (index) => {
    setEdit({ isEdit: true, index: index });
    setQuestionPair(blogDetails?.faqs[index]);
  };

  const deleteQuestion = (index) => {
    let arr = blogDetails?.faqs;
    arr?.splice(index, 1);
    setBlogDetails({ ...blogDetails, faqs: arr });
  };

  const addQuestionPair = () => {
    setBlogDetails({
      ...blogDetails,
      faqs: [...blogDetails.faqs, questionPair],
    });
  };

  const editQuestionPair = () => {
    let ArrFaq = blogDetails?.faqs;
    ArrFaq[edit.index] = questionPair;
    setBlogDetails({
      ...blogDetails,
      faqs: ArrFaq
    })
    setEdit({ index: "", isEdit: false });
  }

  useEffect(() => {
    setQuestionPair({
      question: "",
      answer: "",
    });
  }, [blogDetails?.faqs?.length]);
  const [code, setCode] = useState("");

  const [isTitleChange, setTitleChange] = useState(false);


  const addTagToBlog = () => {
    if (!getBlogValue("tags")) {
      olibrToast.error("please enter skill name!");
      return false;
    }
    if (blogDetail?.tag && blogDetail?.tag?.length > 0) {
      const check = blogDetail?.tag?.filter((item) => item?.toUpperCase() == getBlogValue("tags")?.toUpperCase())
      if (check?.length > 0) {
        olibrToast.error("tag already exists!")
        return false;
      } else {
        setBlogValue("blogTags", [...getBlogValue("blogTags"), getBlogValue("tags")]);
        setBlogDetail({ ...blogDetail, tag: [...blogDetail?.tag, getBlogValue("tags")] })
      }
    } else {
      setBlogValue("blogTags", [getBlogValue("tags")])
      setBlogDetail({ ...blogDetail, tag: [getBlogValue("tags")] })
    }
    setBlogValue("tags", "");
  }

  const [blogBodyCode, setBlogBodyCode] = useState(false);
  const [helmetOpen, setHelmetOpen] = useState(false);
  const renderBlogHtmlContent = () => {
    return (
      <form>
        <textarea
          className={`form-control ${htmlError?.htmlCode && "form-required"}`}
          {
          ...htmlRegister("htmlCode", {
            required: true
          })
          }
          rows="10" />
        <br />
        <div className="col-12">
          <div className="bottom-button">
            <button className="cancelBtn" type="button" onClick={() => setBlogBodyCode(false)}>
              Cancel
            </button>
            <button
              onClick={htmlSubmit(doneHtmlContent)}
              className="submitBtn themeButton">
              {
                false ? <ClipLoader size={15} color="#FFFFFF" /> : "Submit"
              }
            </button>
          </div>
        </div>
      </form>
    )
  }
  const renderHelmetContent = () => {
    return (
      <form>
        <textarea
          className={`form-control ${helmetError?.helmetText && "form-required"}`}
          name="helmetText"
          {
          ...helmetRegister("helmetText", { required: true })
          }
          rows="10"

        />
        <br />
        <div className="col-12">
          <div className="bottom-button">
            <button className="cancelBtn" type="button" onClick={() => closeHelmetContent}>
              Cancel
            </button>
            <button
              onClick={helmetSubmit(submitHelmetText)}
              className="submitBtn themeButton">
              {
                false ? <ClipLoader size={15} color="#FFFFFF" /> : "Submit"
              }
            </button>
          </div>
        </div>
      </form>
    )
  }

  const closeHelmetContent = () => {
    setHelmetOpen(false);
  }

  const submitHelmetText = (data) => {
    setBlogValue("helmetText", data?.helmetText)
    setHelmetOpen(false);
  }

  const doneHtmlContent = (data) => {
    setBlogValue("blogContent", data?.htmlCode)
    setBlogBodyCode(false);
  }

  const openBlogCode = () => {
    setHtmlCode(code);
    setBlogBodyCode(true)
  }

  const [blogHead, setBlogHead] = useState("");
  useEffect(() => {
      if(isTitleChange){
        let str = blogHead;
    let newStr = str?.split(" ")?.join("-"); 
    newStr = newStr.replaceAll("?", "")
    newStr = newStr.replaceAll("&", "")
    newStr = newStr.toLocaleLowerCase();
    setBlogValue("slug", newStr);
      }
  }, [blogHead])

  const [relatedArr ,setRelatedArr] = useState([]);
  
  return (
    <Fragment>
      <div className="main-div-developer">
        <div className="skills-management">
          <div className="tabContainer blogtabcont">
            <button className={!isFaq ? "tabBtn-active" : "tabBtn"} onClick={() => setIsFaq(false)}>
              Blog
            </button>
            <button className={isFaq ? "tabBtn-active" : "tabBtn"} onClick={() => setIsFaq(true)}>
              FAQs
            </button>
          </div>
          <div className="block-header">
          </div>
          <button className="submitBtn themeButton" onClick={handleAddCategory}>
            Add Category
          </button>
          {!isFaq ? (
            <form>
              <div className="row">
                <div className="col-6">
                  <div className="">
                    <label className={`floating-label ${blogError?.blogTitle && "text-danger"}`}>Blog Title</label>
                    <input
                      name="blogTitle"
                      type="text"
                      className="form-control"
                      value={blogHead}
                      onChange={(e) => setBlogHead(e.target.value)}
                      placeholder=""
                      onFocus={() => setTitleChange(true)}
                      onBlur={() => setTitleChange(false)}
                      // {...blogRegister("blogTitle", {
                      //   required: true
                      // })}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group adornment">
                    <label className="floating-label"></label>
                    <input
                      type="text"
                      className={`form-control ${blogError?.coverImage && "form-required"}`}
                      {...blogRegister("coverImage", {
                        required: getBlogValue("blogStatus") == "Draft" ? false : true
                      })}
                    />
                    <input
                      ref={uploadIconRef}
                      type="file"
                      onChange={onUploadIcon}
                      className={`input-file ${blogError?.coverImage && "form-required"}`}
                      accept=".png, .jpeg, .jpg, .svg"
                    />
                    <a
                      className="browseImage"
                      onClick={() => uploadIconRef.current.click()}
                    >
                      {isIconUploading ? (
                        <ClipLoader size={20} color="#3BA0AA" />
                      ) : (
                        "Upload Cover"
                      )}
                    </a>
                  </div>
                </div>
                <div className="col-6">
                  <div className="">
                    <label className={`floating-label ${blogError?.authorName && "text-danger"}`}>Author Name</label>
                    <input
                      name="authorName"
                      type="text"
                      className="form-control"
                      {...blogRegister("authorName", {
                        required: getBlogValue("blogStatus") == "Draft" ? false : true
                      })}
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <label className={`floating-label ${blogError?.authorDesg && "text-danger"}`} htmlFor="">
                      Designation
                    </label>
                    <input
                      name="authorDesg"
                      type="text"
                      className="form-control"
                      placeholder=""
                      {...blogRegister("authorDesg", {
                        required: getBlogValue("blogStatus") == "Draft" ? false : true
                      })}
                    />
                  </div>
                </div>

                <div className=" col-3">
                  <label className={`floating-label ${blogError?.blogCategory && "text-danger"}`} htmlFor="">
                    Category
                  </label>
                  <select
                    name="blogCategory"
                    className="form-control"
                    {...blogRegister("blogCategory", {
                      required: getBlogValue("blogStatus") == "Draft" ? false : true
                    })}
                  >
                    <option value=""></option>
                    {catList?.map((cat) => (
                      <option key={cat?.value} value={cat?.value}>
                        {cat?.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className=" col-3">
                  <label className={`floating-label ${blogError?.publishedDate && "text-danger"}`} htmlFor="">
                    Publish Date
                  </label>
                  <input
                    name="publishedDate"
                    type="date"
                    className="form-control"
                    placeholder=""
                    {...blogRegister("publishedDate", {
                      required: getBlogValue("blogStatus") == "Draft" ? false : true
                    })}
                  />
                </div>
                <div className=" col-3">
                  <label className={`floating-label ${blogError?.blogCategory && "text-danger"}`} htmlFor="">
                    Popular
                  </label>
                  <select
                    name="isPopular"
                    className="form-control"
                    {...blogRegister("isPopular", {
                      required: getBlogValue("blogStatus") == "Draft" ? false : true
                    })}
                  >
                    <option value={""}></option>
                    <option value={false}>NO</option>
                    <option value={true}>YES</option>
                  </select>
                </div>
                <div className="col-3">
                  <div className="form-group">
                  <label className={`floating-label ${blogError?.minutesRead && "text-danger"}`} htmlFor="">
                    Minutes Read
                  </label>
                  <input
                    name="minutesRead"
                    type="text"
                    className="form-control"
                    {...blogRegister("minutesRead", {
                      required: getBlogValue("blogStatus") == "Draft" ? false : true
                    })}
                  />
                  </div>
                </div>
                <div className=" col-6">
                  <label className={`floating-label ${blogError?.blogStatus && "text-danger"}`} htmlFor="">
                    Status
                  </label>
                  <select
                    name="blogStatus"
                    className="form-control"
                    {...blogRegister("blogStatus", {
                      required: getBlogValue("blogStatus") == "Draft" ? false : true
                    })}
                  >
                    {statusArr?.map((item) => (
                      <option value={item?.value}>{item?.label}</option>
                    ))}
                  </select>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className={`floating-label ${blogError?.slug && "text-danger"}`} htmlFor="">
                      SLUG
                    </label>
                    <input
                      name="slug"
                      type="text"
                      className="form-control"
                      placeholder=""
                      {...blogRegister("slug", {
                        required: getBlogValue("blogStatus") == "Draft" ? false : true
                      })}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label className="floating-label" htmlFor="">
                      Add Tags
                    </label>
                    <div style={{ display: "flex" }}>
                      <input
                        type="text"
                        name="tags"
                        {...blogRegister("tags")}
                        className="form-control"
                        onKeyDown={(e) => {
                          if (e?.code === "Enter") {
                            addTagToBlog();
                          }
                        }}
                      />
                      <a
                        onClick={() => addTagToBlog()}
                      >
                        <img
                          style={{ height: "30px", width: "30px" }}
                          src={add}
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <ul className="tags tags-fill">
                    {blogDetail?.tag?.map((item, index) => (
                      <li>
                        <a>
                          <span>{item}</span>{" "}
                          <i
                            onClick={() => {
                              const tempSkill = [...blogDetail?.tag];
                              tempSkill.splice(index, 1);
                              if (blogDetail?.tag?.length == 1) {
                                getBlogValue("blogTags", "")
                              } else {
                                getBlogValue("blogTags", tempSkill)
                              }
                              setBlogDetail({
                                ...blogDetail,
                                tag: tempSkill
                              })

                            }}
                          >
                            <Icon name="modal-close" />
                          </i>
                        </a>
                      </li>
                    ))}
                  </ul>
                  <br />
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className={`floating-label ${blogError?.authorDescription && "text-danger"}`} htmlFor="">
                      Author's Description
                    </label>
                    <textarea
                      name="authorDescription"
                      type="text"
                      className="form-control"
                      placeholder=""
                      {...blogRegister("authorDescription", {
                        required: getBlogValue("blogStatus") == "Draft" ? false : true
                      })}
                    />
                    <br />
                  </div>
                </div>
                <div className="col-3">
                <div className="form-group">
                  <label className={`floating-label ${blogError?.numOfLikes && "text-danger"}`} htmlFor="">
                    No. Of Likes
                  </label>
                  <input
                      name="numOfLikes"
                      type="text"
                      className="form-control"
                      placeholder=""
                      {...blogRegister("numOfLikes")}
                    />
                  <br />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label className={`floating-label ${blogError?.numOfDisLikes && "text-danger"}`} htmlFor="">
                    No. Of Dislikes
                  </label>
                  <input
                      name="numOfDisLikes"
                      type="text"
                      className="form-control"
                      placeholder=""
                      {...blogRegister("numOfDisLikes")}
                    />
                  <br />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                <Select
                // isLoadingSkillList={false}
                // isSearchable={true}
                isMulti
                closeMenuOnSelect={false}
                maxMenuHeight={300}
                value={relatedArr}
                options={blogNameIdList?.data?.map((item) => ({
                  value: item?._id,
                  label: item?.blogTitle,
                }))}
                onChange={(e) =>
                  {setRelatedArr(e)
                  console.log(e)}
                }
              />
                </div>
              </div>
                <div className="col-12">
                  <Modal
                    open={isPreview}
                    title={"PREVIEW"}
                    content={renderBlogContent}
                    onClose={() => setPreview(false)}
                    isFooterView={false}
                  />
                  <Modal
                    open={blogBodyCode}
                    title={"HTML CODE"}
                    content={renderBlogHtmlContent}
                    onClose={() => setBlogBodyCode(false)}
                    isFooterView={false}
                  />
                  <Modal
                    open={helmetOpen}
                    title={"HEAD TAGS"}
                    content={renderHelmetContent}
                    onClose={closeHelmetContent}
                    isFooterView={false}
                  // onCancel={closeHelmetContent}
                  // onDone={closeHelmetContent} 
                  />
                  <a className="submitBtn" onClick={() => setPreview(true)}>
                    Preview
                  </a>
                  <div className="text-right">
                    <a className="submitBtn" onClick={() => openBlogCode()}>
                      Open HTML
                    </a><br /><br />
                    <a className="submitBtn" onClick={() => setHelmetOpen(true)}>
                      Open HELMET
                    </a>
                  </div>
                </div>
                <div className="col-12">
                  <br />
                  <div className="editors-new">
                    <div className={`col-12 ${blogError?.blogContent && "form-required-quill"}`}>
                      <Controller
                        control={blogControll}
                        name='blogContent'
                        {...blogRegister("blogContent", {
                          required: getBlogValue("blogStatus") == "Draft" ? false : true
                        })}
                        render={({ field }) => (
                          <ReactQuill
                            style={{ width: 1000, margin: "auto", marginTop: 50, }}
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            value={getBlogValue("blogContent") ? getBlogValue("blogContent") : ""}
                            placeholder={"write your blog here..."}
                            scrollingContainer={"true"}
                            onChange={(e) => field.onChange(e)}

                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="bottom-button text-right">
                <button className="cancelBtn" type="button" onClick={navBack}>
                  cancel
                </button>
                <button className="cancelBtn" type="button" onClick={handleDelete}>
                  {deleteBlogNewLoading ? <ClipLoader color="#F15D3B" size={20} /> : "DELETE"}
                </button>
                <button className="submitBtn themeButton" type="button" onClick={blogSubmit(handleSubmitBlog)}>
                  {false ? (
                    <ClipLoader color="#ffffff" size={20} />
                  ) : (
                    "SUBMIT"
                  )}
                </button>
              </div>{" "}
            </form>
          )
            :
            (
              <Fragment>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="floating-label" htmlFor="">
                        Question
                      </label>
                      <input
                        name="question"
                        type="text"
                        className="form-control"
                        placeholder="Write the question here!"
                        onChange={handleChangeQues}
                        value={questionPair?.question}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <textarea
                      name="answer"
                      type="text"
                      className="form-control"
                      placeholder="Write the Answer here!"
                      onChange={handleChangeQues}
                      value={questionPair?.answer}
                      id=""
                      rows="6"
                    />
                    <br />
                  </div>
                  <a className="cancelBtn" onClick={() => setQuestionPair({ question: "", answer: "" })}>
                    Cancel
                  </a>
                  <a className="submitBtn" onClick={() => edit.isEdit ? editQuestionPair() : addQuestionPair()}>
                    Add
                  </a>
                </div>
                <div className="table-div dev-page-table">
                  <br />
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="column0">S.No</th>
                        <th className="column1">Question Name</th>
                        <th className="column1">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {blogDetails?.faqs?.map((dev, index) => (
                        <tr key={dev._id} className="dev-hover">
                          <td className="column0">{index + 1}</td>
                          <td
                            className="column1 subQues"
                            onClick={() => editQuestion(index)}
                          >
                            {dev?.question || "-"}
                          </td>
                          <td
                            className="column0"
                            onClick={() => deleteQuestion(index)}
                          >
                            <Icon name="delete" />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <br />
                </div>
              </Fragment>
            )}
          <br />
        </div>
      </div>
    </Fragment>
  );
};

export default EditBlog;
