import { take, takeEvery, takeLatest } from "redux-saga/effects";
import * as types from "../action";
import {
  getUserSaga,
  loginSaga,
  signUpSaga,
  forgetPasswordSaga,
  resetPasswordSaga,
  logoutSaga,
  emailVerificationSaga,
  resendEmailVerificationSaga,
  loginWithLinkdinSaga,
} from "./authentication";
import {
  getFaqListsSaga,
  changePasswordSaga,
  changeEmailSaga,
  getAllFaqGroupSaga,
  getJobListsSaga,
  getBlogCategoriesSaga,
  getAllBlogsByIdSaga,
  getBlogDetailSaga,
  getRecentBlogsSaga,
  getCountrySaga,
  getStateSaga,
  getCitySaga,
  contactUsSaga,
  getBlogsSaga,
  getSpotlightedCandidatesSaga,
  checkAuthenticationSaga,
  getBlogCatOpenSaga,
  getBlogOpenSaga,
  getBlogByIdOpenSaga,
  getRecentFiveBlogSaga,
  getPopularBlogsSaga,
  getBlogBySlugOpenSaga,
  getJobByIdSaga,
  chatGPTStandardEngSaga,
  getMaintenanceOneMessageSaga,
  getPopularJobsSaga,
  getJobByCategorySaga,
  getJobByJobIdSaga,
  uploadMultiImageSaga,
  getDevInfoForReviewSaga,
  giveRatingDevReviewSaga,
  getReviewStatusSaga,
  uploadVideoSaga,
  uploadVideoWithoutDeleteSaga,
  getSkillByCategoryDevSaga,
  getDevSkillTestResultSaga,
  showOpenFaqsSaga,
  getNotificationSaga,
  convertImageSaga,
  updateTermSaga,
  clearNewFeatureSaga,
  getNewFeatureSaga,
  getSiteMapUrlSaga,
  getPricingPlanSaga,
  startPhonePayPaymentSaga,
  clearNotificationSaga,
  startStripePaymentSaga,
  paymentCheckoutSaga,
  getCompanyJobsListSaga,
  addDevSkillSaga,
} from "./common";
import {
  getDeveloperListsSaga,
  devUpdateStatusSaga,
  getAdminSkillListsSaga,
  updateSkillStatusSaga,
  addStackSaga,
  editStackSaga,
  getAdminStackListsSaga,
  updateStackStatusSaga,
  refreshXobinAssessmentSaga,
  renderAllHackerrankAssessmentsSaga,
  addSkillSaga,
  editSkillSaga,
  renderTopicListSaga,
  getAllIconsSaga,
  uploadIconsSaga,
  deleteStackSaga,
  deleteSkillSaga,
  getDeveloperProfileSaga,
  updateProfileStatusSaga,
  resultParsingSaga,
  setMemberPasswordSaga,
  addUserSaga,
  getUserByIdSaga,
  updateUserSaga,
  deleteUserSaga,
  userListingSaga,
  getDevResultSaga,
  getCategoryListSaga,
  addCategoryBlogSaga,
  deleteCategoryBlogSaga,
  updateCategoryBlogSaga,
  getBlogListSaga,
  postBlogSaga,
  updateBlogSaga,
  deleteBlogSaga,
  addFaqGroupSaga,
  addFaqSaga,
  updateFaqSaga,
  updateFaqGroupSaga,
  deleteFaqSaga,
  deleteFaqGroupSaga,
  addQuestionSaga,
  englishAllQuestionSaga,
  getAllJobsSaga,
  addJobSaga,
  editJobSaga,
  deleteJobSaga,
  adminDashboardStepOneSaga,
  getAllToolsSaga,
  deleteToolSaga,
  updateToolSaga,
  addToolSaga,
  updateUserStatus,
  getClientListAdminSaga,
  getClientDetailAdminSaga,
  getJobListByClientAdminSaga,
  getJobFullClientAdminSaga,
  blogsSyncupSaga,
  updateSpotlightedCandidatesSaga,
  getAllEmailTemplateSaga,
  deleteEmailTemplateSaga,
  addEmailTemplateSaga,
  updateEmailTemplateSaga,
  getEmailTemplateByIdSaga,
  getEmailHistorySaga,
  getClientListAdminShortListedSaga,
  getClientListAdminHiredSaga,
  getAdminDevAcademicSaga,
  updateAdminDevAcademicSaga,
  saveAdminDevAcademicSaga,
  deleteAdminDevAcademicSaga,
  postBlogCatSaga,
  deleteBlogCatSaga,
  updateBlogCatSaga,
  getAdminDevCertificationSaga,
  updateAdminDevCertificationSaga,
  saveAdminDevCertificationSaga,
  deleteAdminDevCertificationSaga,
  postBlogNewSaga,
  deleteBlogNewSaga,
  updateBlogNewSaga,
  getAdminDeveloperSocialProfileSaga,
  saveAdminDeveloperSocialProfileSaga,
  updateAdminDeveloperSocialProfileSaga,
  getAdminDevEmployementSaga,
  saveAdminDevEmployementSaga,
  updateAdminDevEmployementSaga,
  deleteAdminDevEmployementSaga,
  getAdminDevProjectSaga,
  saveAdminDevProjectSaga,
  updateAdminDevProjectSaga,
  deleteAdminDevProjectSaga,
  finalResumeCallSaga,
  getAdminDevToolsSaga,
  saveAdminDevToolsSaga,
  updateAdminDevToolsSaga,
  deleteAdminDevToolsSaga,
  getAdminDevSkillsSaga,
  saveAdminDevSkillsSaga,
  updateAdminDevSkillsSaga,
  deleteAdminDevSkillsSaga,
  getAdminDevAchievementSaga,
  saveAdminDevAchievementSaga,
  updateAdminDevAchievementSaga,
  deleteAdminDevAchievementSaga,
  updateAdminDevShortDescSaga,
  getResumeAdminSaga,
  approvalUpdatesAcceptRejectSaga,
  adminResumeparseSaga,
  deleteEnglishQuestionSaga,
  updateEnglishQuestionSaga,
  verifyDevEmailSaga,
  getQueryListSaga,
  deleteQuerySaga,
  updateQuerySaga,
  approveDevEmailSaga,
  unapproveDevEmailSaga,
  getEnglishResultSaga,
  updateAdminDevEnglishSaga,
  getAdminStackOrSkillSaga,
  getAdminDevSkillResultsSaga,
  updateAdminTestSkillSaga,
  allBlogNameIdSaga,
  saveAdminCreateTest,
  getAdminTestListsSaga,
  deleteUserProfileSaga,
  blockUserProfileSaga,
  getSitemapTypesSaga,
  postSitemapTypesSaga,
  updateSitemapTypesSaga,
  deleteSitemapTypesSaga,
  deleteProfileImageSaga,
  getSpotlightHistorySaga,
  getMaintenanceMessageSaga,
  updateMaintenanceStatusSaga,
  addMaintenanceMessageSaga,
  updateMaintenanceMessageSaga,
  deleteMaintenanceSaga,
  gagetMaintenanceOneMessageSaga,
  getSpeakDevSaga,
  getInterviewListSaga,
  getJobCatListSaga,
  addJobCatSaga,
  deleteJobCatSaga,
  updateJobCatSaga,
  // setApprovedJobStatus,
  jobApprovedOrRejectSaga,
  getAllPerkAndBenefitSaga,
  savePerkAndBenefitSaga,
  removePerkAndBenefitSaga,
  updatePerkAndBenefitSaga,
  updateAdminJobStatus,
  getClientProfileDetailsSaga,
  updateFaqGroupStatusSaga,
  updateFaqStatusSaga,
  updateAssessmentNameSaga,
  renderAllHackerrankAssessmentsNoLimitSaga,
  updateEmailSettingStatusSaga,
  getSkillCategory,
  getSkillCategoryLists,
  getStackCategoryLists,
  createSkillsCategory,
  updateSkillsCategory,
  getAllEmailSlugsSaga,
  updatePlagiarismSaga,
  getEmailCountSaga,
  sendJobMailSaga,
  getClientEmailListsSaga,
  getJobTitleListsSaga,
  getPlanListsSaga,
  getFeatureListsSaga,
  getAddOnListsSaga,
  addFeaturePlanSaga,
  updateFeaturePlanSaga,
  addPricingPlanSaga,
  updatePricingPlanSaga,
  saveAddOnAdminSaga,
  updateAddOnAdminSaga,
  updateAddOnStatusAdminSaga,
  updatePlanStatusAdminSaga,
  getPricingSlabSaga,
  addPricingSlabSaga,
  updatePricingSlabSaga,
  getPendingSkillSaga,
  updatePendingSkillSaga,
} from "./admin";
import {
  dashboardStepOneSaga,
  getStepCompletedStatusSaga,
  getResumeSaga,
  proficiencyTestLinkSaga,
  proficiencyTestResultSaga,
  englishTestResultSaga,
  skillListSaga,
  getDevSkillListSaga,
  getAllSkillsListSaga,
  manageDevSkillSaga,
  updateAvailableFromSaga,
  postDevStackSaga,
  devChooseSkillsSaga,
  deleteDevSKillSaga,
  stackListSaga,
  stackListChosenSaga,
  finalizeResumeCallSaga,
  uploadProfileImageSaga,
  resumeParsingSaga,
  getResumeParserDataSaga,
  uploadImageSaga,
  startTestSaga,
  englishTestSaga,
  postResultDataSaga,
  getEnglishTestResultSaga,
  askForApprovalSaga,
  getDevStatusSaga,
  getResultDevSaga,
  getAppliedJobSaga,
  getShortlistedJobSaga,
  getLatestJobListSaga,
  getVettedSkillSaga,
  applyNewJob,
  getEnglishTestStatusSaga,
  updateEnglishTestStatusSaga,
  postEnglishTestStatusSaga,
  getJobFullClientDevSaga,
  getDevAcademicSaga,
  saveDevAcademicSaga,
  updateDevAcademicSaga,
  deleteDevAcademicSaga,
  getDevCertificationSaga,
  getProfileStatusSaga,
  saveDevCertificationSaga,
  deleteDevCertificationSaga,
  updateDevCertificationSaga,
  getCreateProfileSaga,
  getDevSocialProfileSaga,
  saveDevSocialProfileSaga,
  updateDevSocialProfileSaga,
  getDevEmployementSaga,
  saveDevEmployementSaga,
  updateDevEmployementSaga,
  deleteDevEmployementSaga,
  getDevprojectSaga,
  saveDevprojectSaga,
  updateDevprojectSaga,
  deleteDevprojectSaga,
  getAllToolSaga,
  removeDevProjectImage,
  getDevToolsSaga,
  saveDevToolsSaga,
  updateDevToolsSaga,
  deleteDevToolsSaga,
  getDevSkillsSaga,
  saveDevSkillsSaga,
  updateDevSkillsSaga,
  deleteDevSkillsSaga,
  getDevAchivementSaga,
  saveDevAchivementSaga,
  updateDevAchivementSaga,
  deleteDevAchivementSaga,
  updateDevShortDescSaga,
  approvalUpdateSaga,
  getCurrentEmploymentSaga,
  getApprovalUpdateSaga,
  devChooseSkillsGetSaga,
  getResumeOpenSaga,
  updatePageTourSaga,
  getDevReviewSaga,
  saveJobFavouriteSaga,
  getFavouriteJobSaga,
  updateBasicDetailsSaga,
  devActivationSaga,
  getActivationSaga,
  deleteDevReviewSaga,
  createDevReviewSaga,
  resendDevReviewSaga,
  newFeatureClickedSaga,
  getDevVideoSaga,
  fetchReportSaga,
  getInterviewsDataDevSaga,
  setInterviewSlotDevSaga,
  getSkillTestStepDataSaga,
  chooseSkillTestSaga,
  chooseStackTestsSaga,
  removeStackTestSaga,
  removeUnattemptedSkillSaga,
  updateEngActivitySaga,
  englishTestCheckSaga,
  fresherSaga,
  sendOTPSaga,
  verifyOTPSaga,
  applyNewJobMultiSaga,
  rescheduleInterviewByDevSaga,
  cancelInterviewByDevSaga,
} from "./developer";

import {
  clientSignupSaga,
  getSavedProfileSaga,
  getHiredCanditates,
  getClientALlJobLists,
  getClientDevLists,
  addNewJobPost,
  getClientJob,
  updateClientJobs,
  getAllSkillClientSaga,
  clientDirectContacts,
  getDevDetails,
  speakWithDev,
  getClientJobDetails,
  removeFromShortListsClient,
  removeFromHiredListsClient,
  deleteClientJobsSaga,
  getAllStackClientSaga,
  assignToJobSaga,
  unassignToJobSaga,
  topFiveSkillsSaga,
  updateShortLists,
  saveProfileSaga,
  saveClientHiringSkill,
  getSelectedSkillsStacks,
  updateClientJobStatus,
  unsavedProfileSaga,
  getClientALlJobTitlesSaga,
  saveCompanyProfileSaga,
  getAppliedForJobSaga,
  getShortlistedForJobSaga,
  getCompanyProfileSaga,
  getAllJobOfClientSaga,
  updateAppliedJobStatusSaga,
  getClientFirstEntrySaga,
  updateClientFirstEntrySaga,
  updateViewProfileClient,
  clearViewFilterSaga,
  updateFirstCompanyProfile,
  getActivationSagaClient,
  getCompanyUserSaga,
  inviteCompanyUserSaga,
  clientUserActivateDeactivateSaga,
  getCompanyAllActiveUserSaga,
  scheduleInterviewSaga,
  getInterviewsDataSaga,
  rescheduleInterviewSaga,
  cancelInterviewSaga,
  acceptInterviewRequestSaga,
  jobRefreshSaga,
  clearClientJobComment,
  updateClientJobComment,
  saveClientJobComment,
  deleteClientJobComment,
  getDevFilterSaga,
  updateSnoozeSaga,
  getSubscriptionSummarySaga,
  getClientPaymentHistorySaga,
  cancelActivePlanSaga,
  verifyPageRestrictionSaga,
  deleteEmailSaga,
} from "./client";

function* mySaga() {
  yield takeEvery(types.VERIFY_OTP, verifyOTPSaga);
  yield takeEvery(types.SEND_OTP, sendOTPSaga);
  yield takeEvery(types.GET_SKILL_TEST_STEP_DATA, getSkillTestStepDataSaga);
  yield takeEvery(types.GET_SKILL_BY_CATEGORY_DEV, getSkillByCategoryDevSaga);
  yield takeEvery(types.REMOVE_UNATTEMPTED_SKILL, removeUnattemptedSkillSaga);
  yield takeEvery(types.REMOVE_STACK_TEST, removeStackTestSaga);
  yield takeEvery(types.CHOOSE_SKILL_TEST, chooseSkillTestSaga);
  yield takeEvery(types.CHOOSE_STACK_TESTS, chooseStackTestsSaga);
  yield takeEvery(types.GET_DEV_SKILL_TEST_RESULT, getDevSkillTestResultSaga);
  yield takeEvery(types.SHOW_OPEN_FAQS, showOpenFaqsSaga);

  //Dev Reviews
  yield takeEvery(types.NEW_FEATURE_CLICKED, newFeatureClickedSaga);
  yield takeEvery(types.GET_DEV_REVIEW_STATUS, getReviewStatusSaga);
  yield takeEvery(types.GET_DEV_REVIEW_LISTS, getDevReviewSaga);
  yield takeEvery(types.GET_DEV_INFO_FOR_REVIEW, getDevInfoForReviewSaga);
  yield takeEvery(types.DELETE_DEV_REVIEW, deleteDevReviewSaga);
  yield takeEvery(types.CREATE_DEV_REVIEW, createDevReviewSaga);
  yield takeEvery(types.GIVE_RATING_DEV_REVIEW, giveRatingDevReviewSaga);
  yield takeEvery(types.RESEND_DEV_REVIEW, resendDevReviewSaga);

  //Authentication
  yield takeEvery(types.GET_RESUME_OPEN, getResumeOpenSaga);
  yield takeEvery(types.GET_USER, getUserSaga);
  yield takeEvery(types.LOGIN, loginSaga);
  yield takeEvery(types.SIGN_UP, signUpSaga);
  yield takeEvery(types.FORGET_PASSWORD, forgetPasswordSaga);
  yield takeEvery(types.RESET_PASSWORD, resetPasswordSaga);
  yield takeEvery(types.LOGOUT, logoutSaga);
  yield takeEvery(types.EMAIL_VERIFICATION, emailVerificationSaga);
  yield takeEvery(types.RESEND_EMAIL_VERIFICATION, resendEmailVerificationSaga);

  //Maintenance message
  yield takeEvery(
    types.GET_MAINTENANCE_ONE_MESSAGE,
    getMaintenanceOneMessageSaga
  );
  yield takeEvery(types.GET_MAINTENANCE_MESSAGE, getMaintenanceMessageSaga);
  yield takeEvery(types.UPDATE_MAINTENANCE_STATUS, updateMaintenanceStatusSaga);
  yield takeEvery(types.ADD_MAINTENANCE_MESSAGE, addMaintenanceMessageSaga);
  yield takeEvery(
    types.UPDATE_MAINTENANCE_MESSAGE,
    updateMaintenanceMessageSaga
  );
  yield takeEvery(types.DELETE_MAINTENANCE_MESSAGE, deleteMaintenanceSaga);

  //Common
  yield takeEvery(types.ADD_DEV_SKILL, addDevSkillSaga);
  yield takeEvery(types.GET_COMPANY_JOBS, getCompanyJobsListSaga);
  yield takeEvery(types.GET_NEW_FEATURE, getNewFeatureSaga);
  yield takeEvery(types.NEW_FEATURE, clearNewFeatureSaga);
  yield takeEvery(types.GET_POPULAR_BLOGS, getPopularBlogsSaga);
  yield takeEvery(types.RECENT_FIVE_BLOGS, getRecentFiveBlogSaga);
  yield takeEvery(types.GET_FAQ_LISTS, getFaqListsSaga);
  yield takeEvery(types.CHANGE_PASSWORD, changePasswordSaga);
  yield takeEvery(types.CHANGE_EMAIL, changeEmailSaga);
  yield takeEvery(types.GET_ALL_FAQ_GROUP, getAllFaqGroupSaga);
  yield takeEvery(types.GET_JOB_LIST, getJobListsSaga);
  yield takeEvery(types.GET_BLOG_CATEGORIES, getBlogCategoriesSaga);
  yield takeEvery(types.GET_ALL_BLOG_BY_ID, getAllBlogsByIdSaga);
  yield takeEvery(types.GET_JOB_BY_ID_LIST, getJobByIdSaga);
  yield takeEvery(types.GET_RECENT_BLOGS, getRecentBlogsSaga);
  yield takeEvery(types.GET_COUNTRY, getCountrySaga);
  yield takeEvery(types.GET_STATE, getStateSaga);
  yield takeEvery(types.GET_CITY, getCitySaga);
  yield takeEvery(types.CONTACT_US_POST, contactUsSaga);
  yield takeLatest(types.GET_BLOGS, getBlogsSaga);
  yield takeEvery(types.GET_BLOG_DETAIL, getBlogDetailSaga);
  yield takeEvery(
    types.GET_SPOTLIGHTED_CANDIDATES,
    getSpotlightedCandidatesSaga
  );
  yield takeEvery(types.CHECK_AUTHENTICATION_USER, checkAuthenticationSaga);
  yield takeEvery(types.GET_BLOG_CAT_OPEN, getBlogCatOpenSaga);
  yield takeEvery(types.GET_BLOG_OPEN, getBlogOpenSaga);
  yield takeEvery(types.GET_BLOG_BY_ID_OPEN, getBlogByIdOpenSaga);
  yield takeEvery(types.GET_BLOG_BY_SLUG_OPEN, getBlogBySlugOpenSaga);
  yield takeEvery(types.UPDATE_TERM_CONDITION, updateTermSaga);

  //Sitemap
  yield takeEvery(types.GET_SPOTLIGHT_HISTORY, getSpotlightHistorySaga);
  yield takeEvery(types.GET_SITEMAP_TYPE, getSitemapTypesSaga);
  yield takeEvery(types.POST_SITEMAP_TYPE, postSitemapTypesSaga);
  yield takeEvery(types.UPDATE_SITEMAP_TYPE, updateSitemapTypesSaga);
  yield takeEvery(types.DELETE_SITEMAP_TYPE, deleteSitemapTypesSaga);
  yield takeEvery(
    types.UPDATE_EMAIL_SETTING_STATUS,
    updateEmailSettingStatusSaga
  );
  yield takeEvery(types.LOGIN_LINKEDIN, loginWithLinkdinSaga);

  //Admin
  yield takeEvery(types.GET_CLIENT_EMAIL, getClientEmailListsSaga);
  yield takeEvery(types.GET_JOB_TITLE_LISTS, getJobTitleListsSaga);
  yield takeEvery(types.GET_EMAIL_COUNT, getEmailCountSaga);
  yield takeEvery(
    types.RENDER_ALL_HACKER_RANK_ASSESSMENT_NO_LIMIT,
    renderAllHackerrankAssessmentsNoLimitSaga
  );
  yield takeEvery(types.UPDATE_ASSESSMENT_NAME, updateAssessmentNameSaga);
  yield takeEvery(types.GET_SPEAK_WITH_DEV_LIST, getSpeakDevSaga);
  yield takeEvery(types.UPDATE_QUERY, updateQuerySaga);
  yield takeEvery(types.DELETE_QUERY, deleteQuerySaga);
  yield takeEvery(types.GET_QUERIES_LIST, getQueryListSaga);
  yield takeEvery(types.VERIFY_DEV_EMAIL, verifyDevEmailSaga);
  yield takeEvery(types.APPROVE_DEV_EMAIL, approveDevEmailSaga);
  yield takeEvery(types.UNAPPROVE_DEV_EMAIL, unapproveDevEmailSaga);
  yield takeEvery(types.GET_RESUME_ADMIN, getResumeAdminSaga);
  yield takeEvery(types.DELETE_BLOG_NEW, deleteBlogNewSaga);
  yield takeEvery(types.UPDATE_BLOG_NEW, updateBlogNewSaga);
  yield takeEvery(types.POST_BLOG_NEW, postBlogNewSaga);
  yield takeEvery(types.POST_BLOG_CAT, postBlogCatSaga);
  yield takeEvery(types.DELETE_BLOG_CAT, deleteBlogCatSaga);
  yield takeEvery(types.UPDATE_BLOG_CAT, updateBlogCatSaga);
  yield takeEvery(
    types.UPDATE_SPOTLIGHTED_CANDIDATES,
    updateSpotlightedCandidatesSaga
  );
  yield takeEvery(types.JOB_APPROVED_OR_REJECT, jobApprovedOrRejectSaga);
  yield takeEvery(types.UPDATE_FAQ_GROUP_STATUS, updateFaqGroupStatusSaga);
  yield takeEvery(types.UPDATE_FAQ_STATUS, updateFaqStatusSaga);
  yield takeLatest(types.GET_DEVELOPER_LIST, getDeveloperListsSaga);
  yield takeEvery(types.DEV_UPDATE_STATUS, devUpdateStatusSaga);
  yield takeEvery(types.GET_ADMIN_SKILL_LIST, getAdminSkillListsSaga);
  yield takeEvery(types.UPDATE_SKILL_STATUS, updateSkillStatusSaga);
  yield takeEvery(types.ADD_STACK, addStackSaga);
  yield takeEvery(types.EDIT_STACK, editStackSaga);
  yield takeEvery(types.GET_ADMIN_STACK_LIST, getAdminStackListsSaga);
  yield takeEvery(types.UPDATE_STACK_STATUS, updateStackStatusSaga);
  yield takeEvery(types.REFRESH_XOBIN_ASSESSMENT, refreshXobinAssessmentSaga);
  yield takeEvery(
    types.RENDER_ALL_HACKER_RANK_ASSESSMENT,
    renderAllHackerrankAssessmentsSaga
  );
  yield takeEvery(types.ADMIN_ALL_TOOLS, getAllToolsSaga);
  yield takeEvery(types.ADMIN_DELETE_TOOL, deleteToolSaga);
  yield takeEvery(types.ADMIN_ADD_TOOL, addToolSaga);
  yield takeEvery(types.ADD_SKILL, addSkillSaga);
  yield takeEvery(types.EDIT_SKILL, editSkillSaga);
  yield takeEvery(types.RENDER_TOPIC_LIST, renderTopicListSaga);
  yield takeEvery(types.GET_ALL_ICONS, getAllIconsSaga);
  yield takeEvery(types.ADMIN_UPDATE_TOOL, updateToolSaga);
  yield takeEvery(types.UPLOAD_ICONS, uploadIconsSaga);
  yield takeEvery(types.DELETE_STACK, deleteStackSaga);
  yield takeEvery(types.DELETE_SKILL, deleteSkillSaga);
  yield takeEvery(types.GET_DEVELOPER_PROFILE, getDeveloperProfileSaga);
  yield takeEvery(types.UPDATE_PROFILE_STATUS, updateProfileStatusSaga);
  yield takeEvery(types.RESULT_PARSING, resultParsingSaga);
  yield takeEvery(types.SET_MEMBER_PASSWORD, setMemberPasswordSaga);
  yield takeEvery(types.ADD_USER, addUserSaga);
  yield takeEvery(types.UPDATE_USER_STATUS, updateUserStatus);

  yield takeEvery(types.RESUME_PARSING_ADMIN, adminResumeparseSaga);

  yield takeEvery(types.GET_INTERVIEW_LIST, getInterviewListSaga);
  yield takeEvery(types.GET_USER_BY_ID, getUserByIdSaga);
  yield takeEvery(types.UPDATE_USER, updateUserSaga);
  yield takeEvery(types.DELETE_USER, deleteUserSaga);
  yield takeEvery(types.USER_LISTING, userListingSaga);
  yield takeEvery(types.GET_DEV_RESULT, getDevResultSaga);
  yield takeEvery(types.GET_CATEGORY_LIST, getCategoryListSaga);
  yield takeEvery(types.ADD_CATEGORY_BLOG, addCategoryBlogSaga);
  yield takeEvery(types.DELETE_CATEGORY_BLOG, deleteCategoryBlogSaga);
  yield takeEvery(types.UPDATE_CATEGORY_BLOG, updateCategoryBlogSaga);
  yield takeEvery(types.GET_BLOG_LIST, getBlogListSaga);
  yield takeEvery(types.ALL_BLOG_NAME_AND_ID, allBlogNameIdSaga);
  yield takeEvery(types.POST_BLOG, postBlogSaga);
  yield takeEvery(types.UPDATE_BLOG, updateBlogSaga);
  yield takeEvery(types.DELETE_BLOG, deleteBlogSaga);
  yield takeEvery(types.ADD_FAQ_GROUP, addFaqGroupSaga);
  yield takeEvery(types.ADD_FAQ, addFaqSaga);
  yield takeEvery(types.UPDATE_FAQ, updateFaqSaga);
  yield takeEvery(types.UPDATE_FAQ_GROUP, updateFaqGroupSaga);
  yield takeEvery(types.DELETE_FAQ, deleteFaqSaga);
  yield takeEvery(types.DELETE_FAQ_GROUP, deleteFaqGroupSaga);
  yield takeEvery(types.ADD_QUESTION, addQuestionSaga);
  yield takeEvery(types.ENGLISH_ALL_QUESTION, englishAllQuestionSaga);
  yield takeEvery(types.DELETE_ENGLISH_QUESTION, deleteEnglishQuestionSaga);
  yield takeEvery(types.UPDATE_ENGLISH_QUESTION, updateEnglishQuestionSaga);
  yield takeEvery(types.GET_ALL_JOB, getAllJobsSaga);
  yield takeEvery(types.ADD_JOB, addJobSaga);
  yield takeEvery(types.EDIT_JOB, editJobSaga);
  yield takeEvery(types.DELETE_JOB, deleteJobSaga);
  yield takeEvery(types.ADMIN_DASHBOARD_STEP_ONE, adminDashboardStepOneSaga);
  yield takeEvery(types.GET_CLIENT_LIST_ADMIN, getClientListAdminSaga);
  yield takeEvery(types.GET_CLIENT_DETAIL_ADMIN, getClientDetailAdminSaga);
  yield takeEvery(
    types.GET_JOB_LIST_BY_CLIENT_ADMIN,
    getJobListByClientAdminSaga
  );
  yield takeEvery(types.GET_JOB_FULL_CLIENT_ADMIN, getJobFullClientAdminSaga);
  yield takeEvery(types.BLOGS_SYNCUP, blogsSyncupSaga);

  yield takeEvery(types.GET_ADMIN_TEMPLATE_LIST, getAllEmailTemplateSaga);
  yield takeEvery(types.DELETE_ADMIN_TEMPLATE_LIST, deleteEmailTemplateSaga);
  yield takeEvery(types.POST_ADMIN_TEMPLATE_LIST, addEmailTemplateSaga);
  yield takeEvery(types.UPDATE_ADMIN_TEMPLATE_LIST, updateEmailTemplateSaga);
  yield takeEvery(types.GET_ADMIN_TEMPLATE_BY_ID, getEmailTemplateByIdSaga);
  yield takeEvery(types.GET_ADMIN_EMAIL_HISTORY, getEmailHistorySaga);
  yield takeEvery(
    types.GET_ADMIN_CLIENT_SHORTLIST,
    getClientListAdminShortListedSaga
  );
  yield takeEvery(types.GET_ADMIN_CLIENT_HIRED, getClientListAdminHiredSaga);
  yield takeEvery(types.GET_ADMIN_DEV_ACADEMIC, getAdminDevAcademicSaga);
  yield takeEvery(types.UPDATE_ADMIN_DEV_ACADEMIC, updateAdminDevAcademicSaga);
  yield takeEvery(types.SAVE_ADMIN_DEV_ACADEMIC, saveAdminDevAcademicSaga);
  yield takeEvery(types.DELETE_ADMIN_DEV_ACADEMIC, deleteAdminDevAcademicSaga);
  yield takeEvery(
    types.GET_ADMIN_DEV_CERTIFICATION,
    getAdminDevCertificationSaga
  );
  yield takeEvery(
    types.UPDATE_ADMIN_DEV_CERTIFICATION,
    updateAdminDevCertificationSaga
  );
  yield takeEvery(
    types.SAVE_ADMIN_DEV_CERTIFICATION,
    saveAdminDevCertificationSaga
  );
  yield takeEvery(
    types.DELETE_ADMIN_DEV_CERTIFICATION,
    deleteAdminDevCertificationSaga
  );
  yield takeEvery(
    types.GET_ADMIN_DEV_SOCIAL_PROFILE,
    getAdminDeveloperSocialProfileSaga
  );
  yield takeEvery(
    types.SAVE_ADMIN_DEV_SOCIAL_PROFILE,
    saveAdminDeveloperSocialProfileSaga
  );
  yield takeEvery(
    types.UPDATE_ADMIN_DEV_SOCIAL_PROFILE,
    updateAdminDeveloperSocialProfileSaga
  );
  yield takeEvery(types.GET_ADMIN_DEV_EMPLOYEMENT, getAdminDevEmployementSaga);
  yield takeEvery(
    types.SAVE_ADMIN_DEV_EMPLOYEMENT,
    saveAdminDevEmployementSaga
  );
  yield takeEvery(
    types.UPDATE_ADMIN_DEV_EMPLOYEMENT,
    updateAdminDevEmployementSaga
  );
  yield takeEvery(
    types.DELETE_ADMIN_DEV_EMPLOYEMENT,
    deleteAdminDevEmployementSaga
  );
  yield takeEvery(types.GET_ADMIN_DEV_PROJECT, getAdminDevProjectSaga);
  yield takeEvery(types.SAVE_ADMIN_DEV_PROJECT, saveAdminDevProjectSaga);
  yield takeEvery(types.UPDATE_ADMIN_DEV_PROJECT, updateAdminDevProjectSaga);
  yield takeEvery(types.DELETE_ADMIN_DEV_PROJECT, deleteAdminDevProjectSaga);
  yield takeEvery(types.FINAL_RESUME_ADMIN_CALL, finalResumeCallSaga);
  yield takeEvery(types.GET_ADMIN_DEV_TOOLS, getAdminDevToolsSaga);
  yield takeEvery(types.SAVE_ADMIN_DEV_TOOLS, saveAdminDevToolsSaga);
  yield takeEvery(types.UPDATE_ADMIN_DEV_TOOLS, updateAdminDevToolsSaga);
  yield takeEvery(types.DELETE_ADMIN_DEV_TOOLS, deleteAdminDevToolsSaga);
  yield takeEvery(types.GET_ADMIN_DEV_SKILLS, getAdminDevSkillsSaga);
  yield takeEvery(types.SAVE_ADMIN_DEV_SKILLS, saveAdminDevSkillsSaga);
  yield takeEvery(types.DELETE_ADMIN_DEV_SKILLS, deleteAdminDevSkillsSaga);
  yield takeEvery(types.UPDATE_ADMIN_DEV_SKILLS, updateAdminDevSkillsSaga);

  yield takeEvery(types.GET_ADMIN_DEV_ACHIEVEMENT, getAdminDevAchievementSaga);
  yield takeEvery(
    types.SAVE_ADMIN_DEV_ACHIEVEMENT,
    saveAdminDevAchievementSaga
  );
  yield takeEvery(
    types.DELETE_ADMIN_DEV_ACHIEVEMENT,
    deleteAdminDevAchievementSaga
  );
  yield takeEvery(
    types.UPDATE_ADMIN_DEV_ACHIEVEMENT,
    updateAdminDevAchievementSaga
  );
  yield takeEvery(
    types.UPDATE_ADMIN_DEV_SHORT_DESC,
    updateAdminDevShortDescSaga
  );
  yield takeEvery(
    types.PROFILE_UPDATE_REQUEST_APPROVE_REJECT,
    approvalUpdatesAcceptRejectSaga
  );
  yield takeEvery(types.GET_ADMIN_DEV_ENGLISH_RESULT, getEnglishResultSaga);
  yield takeEvery(
    types.UPDATE_ADMIN_DEV_ENGLISH_RESULT,
    updateAdminDevEnglishSaga
  );
  yield takeEvery(types.GET_ADMIN_STACK_SKILL, getAdminStackOrSkillSaga);
  yield takeEvery(
    types.GET_ADMIN_DEV_SKILL_RESULT,
    getAdminDevSkillResultsSaga
  );
  yield takeEvery(types.UPDATE_ADMIN_SKILL_TEST, updateAdminTestSkillSaga);
  yield takeEvery(types.CREATE_SKILL_TEST, saveAdminCreateTest);
  yield takeEvery(types.GET_SKILL_TEST, getAdminTestListsSaga);
  yield takeEvery(types.DELETE_USER_PROFILE, deleteUserProfileSaga);
  yield takeEvery(types.BLOCK_USER_PROFILE, blockUserProfileSaga);
  yield takeEvery(types.DELETE_PROFILE_IMAGE, deleteProfileImageSaga);
  yield takeEvery(types.GET_ALL_PERK_AND_BENEFIT, getAllPerkAndBenefitSaga);
  yield takeEvery(types.SAVE_PERK_AND_BENEFIT, savePerkAndBenefitSaga);
  yield takeEvery(types.DELETE_PERK_AND_BENEFIT, removePerkAndBenefitSaga);
  yield takeEvery(types.UPDATE_PERK_AND_BENEFIT, updatePerkAndBenefitSaga);
  yield takeEvery(types.UPDATE_ADMIN_JOB_STATUS, updateAdminJobStatus);
  yield takeEvery(types.SEND_JOB_MAIL, sendJobMailSaga);
  yield takeEvery(types.GET_PRICING_PLAN, getPricingPlanSaga);

  //developer
  yield takeEvery(types.DASHBOARD_STEP_ONE, dashboardStepOneSaga);
  yield takeEvery(types.GET_STEP_COMPLETED_STATUS, getStepCompletedStatusSaga);
  yield takeEvery(types.GET_RESUME, getResumeSaga);
  yield takeEvery(types.GET_PROFILE_STATUS, getProfileStatusSaga);
  yield takeEvery(types.PROFICIENCY_TEST_LINK, proficiencyTestLinkSaga);
  yield takeEvery(types.PROFICIENCY_TEST_RESULT, proficiencyTestResultSaga);
  yield takeEvery(types.ENGLISH_TEST_RESULT, englishTestResultSaga);
  yield takeEvery(types.SKILL_LIST, skillListSaga);
  yield takeEvery(types.GET_DEV_SKILL_LIST, getDevSkillListSaga);
  yield takeEvery(types.GET_ALL_SKILL_LIST, getAllSkillsListSaga);
  yield takeEvery(types.MANAGE_DEV_SKILL, manageDevSkillSaga);
  yield takeEvery(types.UPDATE_AVAILABLE_FROM, updateAvailableFromSaga);
  yield takeEvery(types.POST_DEV_STACK, postDevStackSaga);
  yield takeEvery(types.DEV_CHOOSE_SKILL, devChooseSkillsSaga);
  yield takeEvery(types.DEV_CHOOSE_SKILL_GET, devChooseSkillsGetSaga);
  yield takeEvery(types.DELETE_DEV_SKILL, deleteDevSKillSaga);
  yield takeEvery(types.STACK_LIST, stackListSaga);
  yield takeEvery(types.STACK_LIST_CHOSEN, stackListChosenSaga);
  yield takeEvery(types.FINALIZE_RESUME_CALL, finalizeResumeCallSaga);
  yield takeEvery(types.UPLOAD_PROFILE_IMAGE, uploadProfileImageSaga);
  yield takeEvery(types.RESUME_PARSING, resumeParsingSaga);
  yield takeEvery(types.GET_RESUME_PARSER_DATA, getResumeParserDataSaga);
  yield takeEvery(types.UPLOAD_IMAGE, uploadImageSaga);
  yield takeEvery(types.UPLOAD_VIDEO, uploadVideoSaga);
  yield takeEvery(
    types.UPLOAD_VIDEO_WITHOUT_DELETE,
    uploadVideoWithoutDeleteSaga
  );
  yield takeEvery(types.GET_DEV_VIDEO, getDevVideoSaga);
  yield takeEvery(types.UPLOAD_MULTI_IMAGE, uploadMultiImageSaga);
  yield takeEvery(types.START_TEST, startTestSaga);
  yield takeEvery(types.ENGLISH_TEST, englishTestSaga);
  yield takeEvery(types.ENGLISH_TEST_CHECK, englishTestCheckSaga);
  yield takeEvery(types.SAVE_ENGLISH_ACTIVITY, updateEngActivitySaga);
  yield takeEvery(types.POST_RESULT_DATA, postResultDataSaga);
  yield takeEvery(types.GET_ENGLISH_TEST_RESULT, getEnglishTestResultSaga);
  yield takeEvery(types.ASK_FOR_APPROVAL, askForApprovalSaga);
  yield takeEvery(types.GET_DEV_STATUS, getDevStatusSaga);
  yield takeEvery(types.GET_RESULT_DEV, getResultDevSaga);
  yield takeEvery(types.GET_APPLIED_JOB, getAppliedJobSaga);
  yield takeEvery(types.GET_SHORT_LIST_JOB, getShortlistedJobSaga);
  yield takeEvery(types.GET_LATEST_JOB_LIST, getLatestJobListSaga);
  yield takeEvery(types.GET_VETTED_SKILL, getVettedSkillSaga);
  yield takeEvery(types.APPLY_NEW_JOB, applyNewJob);
  yield takeEvery(types.APPLY_NEW_MULTI_JOB, applyNewJobMultiSaga);
  yield takeEvery(types.FRESHER, fresherSaga);
  yield takeEvery(types.GET_ENGLISH_TEST_STATUS, getEnglishTestStatusSaga);
  yield takeEvery(
    types.UPDATE_ENGLISH_TEST_STATUS,
    updateEnglishTestStatusSaga
  );
  yield takeEvery(types.UPDATE_PLAGIARISM_STATUS, updatePlagiarismSaga);
  yield takeEvery(types.GET_ALL_EMAILS_SLUGS, getAllEmailSlugsSaga);
  yield takeEvery(types.SAVE_FAVOURITE_JOB, saveJobFavouriteSaga);
  yield takeEvery(types.GET_FAVOURITE_JOB, getFavouriteJobSaga);
  yield takeEvery(types.POST_ENGLISH_TEST_STATUS, postEnglishTestStatusSaga);
  yield takeEvery(types.GET_JOB_FULL_CLIENT_DEV, getJobFullClientDevSaga);
  yield takeEvery(types.GET_DEV_ACADEMIC, getDevAcademicSaga);
  yield takeEvery(types.SAVE_DEV_ACADEMIC, saveDevAcademicSaga);
  yield takeEvery(types.UPDATE_DEV_ACADEMIC, updateDevAcademicSaga);
  yield takeEvery(types.DELETE_DEV_ACADEMIC, deleteDevAcademicSaga);
  yield takeEvery(types.GET_DEV_CERTIFICATION, getDevCertificationSaga);
  yield takeEvery(types.SAVE_DEV_CERTIFICATION, saveDevCertificationSaga);
  yield takeEvery(types.DELETE_DEV_CERTIFICATION, deleteDevCertificationSaga);
  yield takeEvery(types.UPDATE_DEV_CERTIFICATION, updateDevCertificationSaga);
  yield takeEvery(types.GET_CREATE_PROFILE, getCreateProfileSaga);
  yield takeEvery(types.GET_DEV_SOCIAL_PROFILE, getDevSocialProfileSaga);
  yield takeEvery(types.SAVE_DEV_SOCIAL_PROFILE, saveDevSocialProfileSaga);
  yield takeEvery(types.UPDATE_DEV_SOCIAL_PROFILE, updateDevSocialProfileSaga);
  yield takeEvery(types.GET_DEV_EMPLOYEMENT, getDevEmployementSaga);
  yield takeEvery(types.SAVE_DEV_EMPLOYEMENT, saveDevEmployementSaga);
  yield takeEvery(types.UPDATE_DEV_EMPLOYEMENT, updateDevEmployementSaga);
  yield takeEvery(types.DELETE_DEV_EMPLOYEMENT, deleteDevEmployementSaga);
  yield takeEvery(types.GET_DEV_PROJECT, getDevprojectSaga);
  yield takeEvery(types.SAVE_DEV_PROJECT, saveDevprojectSaga);
  yield takeEvery(types.UPDATE_DEV_PROJECT, updateDevprojectSaga);
  yield takeEvery(types.DELETE_DEV_PROJECT, deleteDevprojectSaga);
  yield takeEvery(types.GET_DEV_ALL_TOOLS, getAllToolSaga);
  yield takeEvery(types.REMOVE_DEV_PROJECT_IMAGE, removeDevProjectImage);
  yield takeEvery(types.GET_DEV_TOOLS, getDevToolsSaga);
  yield takeEvery(types.SAVE_DEV_TOOLS, saveDevToolsSaga);
  yield takeEvery(types.UPDATE_DEV_TOOLS, updateDevToolsSaga);
  yield takeEvery(types.DELETE_DEV_TOOLS, deleteDevToolsSaga);
  yield takeEvery(types.GET_DEV_SKILLS, getDevSkillsSaga);
  yield takeEvery(types.SAVE_DEV_SKILLS, saveDevSkillsSaga);
  yield takeEvery(types.UPDATE_DEV_SKILLS, updateDevSkillsSaga);
  yield takeEvery(types.DELETE_DEV_SKILLS, deleteDevSkillsSaga);
  yield takeEvery(types.GET_DEV_ACHIEVEMENT, getDevAchivementSaga);
  yield takeEvery(types.SAVE_DEV_ACHIEVEMENT, saveDevAchivementSaga);
  yield takeEvery(types.UPDATE_DEV_ACHIEVEMENT, updateDevAchivementSaga);
  yield takeEvery(types.DELETE_DEV_ACHIEVEMENT, deleteDevAchivementSaga);
  yield takeEvery(types.UPDATE_DEV_DESCRIPTION, updateDevShortDescSaga);
  yield takeEvery(types.APPROVAL_UPDATE, approvalUpdateSaga);
  yield takeEvery(types.GET_APPROVAL_UPDATE, getApprovalUpdateSaga);
  yield takeEvery(types.GET_CURRENT_EMPLOYMENT, getCurrentEmploymentSaga);
  yield takeEvery(types.OPENAI_STANDARD_ENGLISH, chatGPTStandardEngSaga);
  yield takeEvery(types.UPDATE_PAGE_TOUR, updatePageTourSaga);
  yield takeEvery(types.FETCH_REPORT, fetchReportSaga);
  yield takeEvery(types.GET_PLAN_LISTS, getPlanListsSaga);
  yield takeEvery(types.GET_FEATURE_LIST, getFeatureListsSaga);
  yield takeEvery(types.GET_ADD_ON_LISTS, getAddOnListsSaga);
  yield takeEvery(types.ADD_PRICING_FEATURE, addFeaturePlanSaga);
  yield takeEvery(types.UPDATE_PRICING_FEATURE, updateFeaturePlanSaga);
  yield takeEvery(types.ADD_PRICING_PLAN, addPricingPlanSaga);
  yield takeEvery(types.UPDATE_PRICING_PLAN, updatePricingPlanSaga);
  yield takeEvery(types.SAVE_ADD_ON, saveAddOnAdminSaga);
  yield takeEvery(types.UPDATE_ADD_ON, updateAddOnAdminSaga);
  yield takeEvery(types.UPDATE_ADD_ON_STATUS, updateAddOnStatusAdminSaga);
  yield takeEvery(types.UPDATE_PLAN_STATUS, updatePlanStatusAdminSaga);
  //Client
  yield takeEvery(types.GET_DEV_FILTER, getDevFilterSaga);
  yield takeEvery(types.UPDATE_SNOOZE, updateSnoozeSaga);
  yield takeEvery(types.GET_CLIENT_FIRST_ENTRY, getClientFirstEntrySaga);
  yield takeEvery(types.UPDATE_CLIENT_FIRST_ENTRY, updateClientFirstEntrySaga);
  yield takeEvery(types.UPDATE_CLIENT_VIEW_PROFILE, updateViewProfileClient);
  yield takeEvery(types.CLEAR_VIEW_FILTER, clearViewFilterSaga);
  yield takeEvery(
    types.UPDATE_FIRST_COMPANY_PROFILE,
    updateFirstCompanyProfile
  );
  yield takeEvery(types.CLIENT_SIGNUP, clientSignupSaga);
  yield takeEvery(types.GET_CLIENT_SAVED_PROFILE, getSavedProfileSaga);
  yield takeEvery(types.GET_CLIENT_HIRED_CANDIDATES, getHiredCanditates);
  yield takeEvery(types.GET_CLIENT_ALL_JOBLISTS, getClientALlJobLists);
  yield takeEvery(types.GET_CLIENT_ALL_JOBTITLES, getClientALlJobTitlesSaga);
  yield takeEvery(types.GET_CLIENT_DEV_LISTS, getClientDevLists);
  yield takeEvery(types.ADD_NEW_JOB_POST, addNewJobPost);
  yield takeEvery(types.GET_CLIENT_JOB, getClientJob);
  yield takeEvery(types.UPDATE_CLIENT_JOB, updateClientJobs);
  yield takeEvery(types.DELETE_CLIENT_JOB, deleteClientJobsSaga);
  yield takeEvery(types.GET_CLIENT_SKILL_LISTS, getAllSkillClientSaga);
  yield takeEvery(types.GET_CLIENT_STACK_LISTS, getAllStackClientSaga);
  yield takeEvery(types.CLIENT_DIRECT_CONTACT, clientDirectContacts);
  yield takeEvery(types.GET_DEV_DETAILS, getDevDetails);
  yield takeEvery(types.SPEAK_WITH_DEV, speakWithDev);
  yield takeEvery(types.GET_CLIENT_JOB_DETAIL, getClientJobDetails);
  yield takeEvery(
    types.REMOVE_FROM_SHORT_LIST_CLIENT,
    removeFromShortListsClient
  );
  yield takeEvery(
    types.REMOVE_FROM_HIRED_LIST_CLIENT,
    removeFromHiredListsClient
  );
  yield takeEvery(types.ASSIGN_TO_JOB, assignToJobSaga);
  yield takeEvery(types.UNASSIGN_TO_JOB, unassignToJobSaga);
  yield takeEvery(types.GET_TOP_FIVE_SKILL, topFiveSkillsSaga);
  yield takeEvery(types.UPDATE_SHORT_LISTS, updateShortLists);
  yield takeEvery(types.SAVE_PROFILE, saveProfileSaga);
  yield takeEvery(types.SAVE_CLIENT_HIRING_SKILLS, saveClientHiringSkill);
  yield takeEvery(types.GET_SELECTED_SKILLS, getSelectedSkillsStacks);
  yield takeEvery(types.UPDATE_CLIENT_JOB_STATUS, updateClientJobStatus);
  yield takeEvery(types.GET_SHORTLISTED_FOR_JOB, getShortlistedForJobSaga);
  yield takeEvery(types.GET_APPLIED_FOR_JOB, getAppliedForJobSaga);
  yield takeEvery(types.UNSAVED_PROFILE, unsavedProfileSaga);
  yield takeEvery(types.UPDATE_APPLIED_JOB_STATUS, updateAppliedJobStatusSaga);
  yield takeEvery(types.CLEAR_PROFILE_FEEDBACK, clearClientJobComment);
  yield takeEvery(types.UPDATE_CANDIDATE_COMMENT, updateClientJobComment);
  yield takeEvery(types.ADD_CANDIDATE_COMMENT, saveClientJobComment);
  yield takeEvery(types.DELETE_CANDIDATE_COMMENT, deleteClientJobComment);
  yield takeEvery(types.GET_JOB_CAT, getJobCatListSaga);
  yield takeEvery(types.ADD_JOB_CAT, addJobCatSaga);
  yield takeEvery(types.DELETE_JOB_CAT, deleteJobCatSaga);
  yield takeEvery(types.UPDATE_JOB_CAT, updateJobCatSaga);
  yield takeEvery(types.CLIENT_EMAIL_AUTO, getActivationSagaClient);
  yield takeEvery(types.GET_POPULAR_JOB, getPopularJobsSaga);
  yield takeEvery(types.GET_JOB_BY_CAT, getJobByCategorySaga);
  yield takeEvery(types.GET_JOB_BY_ID, getJobByJobIdSaga);
  yield takeEvery(types.GET_COMPANY_PROFILE, getCompanyProfileSaga);
  yield takeEvery(types.SAVE_COMPANY_PROFILE, saveCompanyProfileSaga);
  yield takeEvery(types.GET_ALL_JOB_OF_CLIENT, getAllJobOfClientSaga);
  yield takeEvery(types.UPDATE_BASIC_DETAIL, updateBasicDetailsSaga);
  yield takeEvery(types.DEV_ACTIVATION, devActivationSaga);
  yield takeEvery(types.GET_DEV_EMAIL_AUTO, getActivationSaga);
  yield takeEvery(types.GET_COMPANY_USER, getCompanyUserSaga);
  yield takeEvery(types.CLIENT_INVITE_USER, inviteCompanyUserSaga);
  yield takeEvery(types.GET_SKILL_CATEGORY, getSkillCategory);
  yield takeEvery(types.GET_SKILL_CATEGORY_LISTS, getSkillCategoryLists);
  yield takeEvery(types.GET_STACK_CATEGORY_LISTS, getStackCategoryLists);
  yield takeEvery(types.CREATE_SKILLS_CATEGORY, createSkillsCategory);
  yield takeEvery(types.UPDATE_SKILLS_CATEGORY, updateSkillsCategory);
  yield takeEvery(
    types.CLIENT_ACTIVATE_DEACTIVATE_USER,
    clientUserActivateDeactivateSaga
  );
  yield takeEvery(types.COMPANY_ALL_ACTIVE_USER, getCompanyAllActiveUserSaga);
  yield takeEvery(
    types.CHECK_CLIENT_RESTRICTION_PAGE,
    verifyPageRestrictionSaga
  );

  yield takeEvery(types.START_PAYMENT_PHONE_PAY, startPhonePayPaymentSaga);
  yield takeEvery(types.START_PAYMENT_STRIPE, startStripePaymentSaga);
  //Interview
  yield takeEvery(types.SCHEDULE_INTERVIEW, scheduleInterviewSaga);
  yield takeEvery(types.RESCHEDULE_INTERVIEW, rescheduleInterviewSaga);
  yield takeEvery(types.CANCEL_INTERVIEW, cancelInterviewSaga);
  yield takeEvery(types.ACCEPT_INTERVIEW, acceptInterviewRequestSaga);
  yield takeEvery(types.GET_INTERVIEWS_DATA, getInterviewsDataSaga);
  //Developer
  yield takeEvery(
    types.RESCHEDULE_INTERVIEW_BY_DEV,
    rescheduleInterviewByDevSaga
  );
  yield takeEvery(types.CANCEL_INTERVIEW_BY_DEV, cancelInterviewByDevSaga);
  yield takeEvery(types.GET_INTERVIEWS_DATA_DEV, getInterviewsDataDevSaga);
  yield takeEvery(types.SET_INTERVIEWS_SLOT_DEV, setInterviewSlotDevSaga);
  yield takeEvery(types.GET_NOTIFICATION, getNotificationSaga);
  yield takeEvery(types.CLEAR_NOTIFICATION, clearNotificationSaga);
  yield takeEvery(types.JOB_REFRESH, jobRefreshSaga);
  yield takeEvery(types.CONVERT_IMAGE, convertImageSaga);
  yield takeEvery(types.GET_SITEMAP_URL, getSiteMapUrlSaga);
  yield takeEvery(types.GET_SUMMARY_SUBSCRIPTION, getSubscriptionSummarySaga);
  yield takeEvery(
    types.GET_CLIENT_PAYMENT_HISTORY,
    getClientPaymentHistorySaga
  );
  yield takeEvery(types.GET_CANCEL_ACTIVE_PLAN, cancelActivePlanSaga);
  yield takeEvery(types.PAYMENT_CHECKOUT, paymentCheckoutSaga);
  yield takeEvery(types.DELETE_EMAIL, deleteEmailSaga);
  yield takeEvery(types.GET_PRICING_SLAB, getPricingSlabSaga);
  yield takeEvery(types.ADD_PRICING_SLAB, addPricingSlabSaga);
  yield takeEvery(types.UPDATE_PRICING_SLAB, updatePricingSlabSaga);
  yield takeEvery(types.GET_PENDING_SKILL,getPendingSkillSaga)
  yield takeEvery(types.UPDATE_PENDING_SKILL,updatePendingSkillSaga)
}

export default mySaga;
