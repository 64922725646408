export const getTouch = "Get in Touch"; 
export const fillForm = "Fill in the given form and one of Olibr’s representatives will get in touch with you shortly. Please feel free to call or write to us for immediate assistance.";
export const home = "Home";
export const contact =  "Contact Us";
export const contactOrSales = "Contact Our Sales Team";
export const contactHeading = "Contact us for a personalized demo";
export const fullName = "Full Name"; 
export const email = "Developer Support";
export const emailHeading2 = "Client Support";
export const mobile = "Mobile";
export const subject = "Subject";
export const generalQuestions = "General Questions";
export const wantToHire = "I want to hire";
export const message = "Message";
export const emailAddress = "support@olibr.com";
export const emailClient = "client.support@olibr.com";
export const salesInq = "sales Inquiries";
export const phnno = "+91 8769831985"