import React from "react";
import { Icon } from "../../components/icon";
const Acknowledgement = () => {
  return (
    <div className="loading-div">
    <div className="loading-box">
      <div className="unsub-noti">
        <Icon name={"rating-done"} />
        <p style={{marginTop: "10px"}}>Thank you for devoting your time and sharing your invaluable feedback with us.</p>
      </div>
    </div>
    </div>
  );
};

export default Acknowledgement;
