import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import AboutUs from "./all-helmets/AboutUs";
import Blogs from "./all-helmets/Blogs";
import ClientHomePage from "./all-helmets/ClientHomePage";
import ClientSignUp from "./all-helmets/ClientSignUp";
import Comingsoon from "./all-helmets/Comingsoon";
import Contact from "./all-helmets/Contact";
import DeveloperHomePage from "./all-helmets/DeveloperHomePage";
import ErrorPage from "./all-helmets/ErrorPage";
import Faqs from "./all-helmets/Faqs";
import ForgotPassword from "./all-helmets/ForgotPassword";
import Jobs from "./all-helmets/Jobs";
import Login from "./all-helmets/Login";
import PrivacyPolicy from "./all-helmets/PrivacyPolicy";
import ResendEmail from "./all-helmets/ResendEmail";
import SignUp from "./all-helmets/SignUp";
import Terms from "./all-helmets/Terms";
import JobDetail from "./all-helmets/JobDetail";
import CandidateProfile from "./all-helmets/CandidateProfile";

const MetaTagSEO = ({ helmetName, jobdetailprop, devdetails }) => {
  const [url, setUrl] = useState("");
  useEffect(() => {
    let base_url = "https://olibr.com";
    if (window?.location?.href?.includes("https://test")) {
      base_url = "https://test.olibr.com";
    } else if (window?.location?.href?.includes("https://improved")) {
      base_url = "https://improved.olibr.com";
    }
    setUrl(base_url);
  }, []);

  switch (helmetName) {
    case "about-us":
      return <AboutUs baseUrl={url} />;
    case "blog-page":
      return <Blogs baseUrl={url} />;
    case "client-homepage":
      return <ClientHomePage baseUrl={url} />;
    case "client-signup":
      return <ClientSignUp baseUrl={url} />;
    case "coming-soon":
      return <Comingsoon baseUrl={url} />;
    case "contact-us":
      return <Contact baseUrl={url} />;
    case "developer-homepage":
      return <DeveloperHomePage baseUrl={url} />;
    case "error-page":
      return <ErrorPage baseUrl={url} />;
    case "faq-page":
      return <Faqs baseUrl={url} />;
    case "forgot-password":
      return <ForgotPassword baseUrl={url} />;
    case "jobs":
      return <Jobs baseUrl={url} />;
    case "job-detail":
      return <JobDetail baseUrl={url} jobdetail={jobdetailprop} />;
    case "login":
      return <Login baseUrl={url} />;
    case "privacy-policy":
      return <PrivacyPolicy baseUrl={url} />;
    case "resend-email":
      return <ResendEmail baseUrl={url} />;
    case "signup":
      return <SignUp baseUrl={url} />;
    case "terms-and-conditions":
      return <Terms baseUrl={url} />;
    case "candidate-profile":
      return <CandidateProfile baseUrl={url} devDetails={devdetails} />;
  }
};

export default MetaTagSEO;
