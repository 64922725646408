import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import AllInterviewCalendar from "./AllInterviewCalendar";
import "./Calendar.css";
import { Icon } from "../../../components/icon";
import InterviewTable from "./InterviewTable";
import { useDispatch, useSelector } from "react-redux";
import { getInterviewsData } from "../../../service/action/client";
import { ClipLoader } from "react-spinners";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function Interviews({ interviewData }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);
  const [isViewOnlyToday, setIsViewOnlyToday] = useState(false);
  const [monthFiter, setMonthFilter] = useState(false);
  const [todayDate, setTodayDate] = useState(new Date());
  const [interviewsData, setInterviewData] = useState();
  const clientStoreState = useSelector((state) => state.clientReducer);

  const { state, pathname } = useLocation();

  useEffect(() => {
    if (state) {
      state?.viewData && setInterviewData(state?.viewData);
    } else if (interviewData) {
      setInterviewData(interviewData);
    }
  }, [state, interviewData]);

  const dateWiseFormat = (unFormatedData) => {
    const dateWiseData = {};
    if (!isNaN(parseInt(Object.keys(unFormatedData)?.[0]))) {
      unFormatedData?.forEach((item) => {
        const interview_date = item.interviewData.slots;
        if (interview_date?.length > 0) {
          const interview_start_date = interview_date[0]?.from;
          const dateKey = `${new Date(interview_start_date).getFullYear()}-${
            new Date(interview_start_date).getMonth() + 1
          }-${new Date(interview_start_date).getDate()}`;
          if (dateWiseData[dateKey]) {
            dateWiseData[dateKey].push(item);
          } else {
            dateWiseData[dateKey] = [{ ...item }];
          }
        }
      });
    }
    return dateWiseData;
  };

  const formattedData = () => {
    if (clientStoreState?.interviewsData) {
      let final_interview_data = [];
      clientStoreState?.interviewsData.forEach((item) => {
        if (item.interviewData.status !== "canceled") {
          final_interview_data.push(item);
        }
      });
      const date_Wise_Data = dateWiseFormat(final_interview_data);
      setInterviewData(date_Wise_Data);
    } else {
      setInterviewData({});
    }
  };

  useEffect(() => {
    if (pathname === "/client/all-Interviews") {
      clientStoreState?.interviewsData?.length > 0 &&
      formattedData();
    }
  }, [clientStoreState?.interviewsData]);

  const fetchToday = (today_date) => {
    let now = new Date(today_date);
    dispatch(
      getInterviewsData(
        navigate,
        `?month=${now.getMonth()}&year=${now.getFullYear()}&filter=true`
      )
    );
  };

  const onTabClik = (activeTab) => {
    const now = new Date();
    setCurrentTab(activeTab);
    dispatch(
      getInterviewsData(
        navigate,
        `?month=${now.getMonth()}&year=${now.getFullYear()}&filter=false`
      )
    );
    setMonthFilter(false);
  };

  const onViewTodayOrMonthClik = (isViewOnly) => {
    setIsViewOnlyToday(isViewOnly);
    if (isViewOnly) {
      setTodayDate(new Date());
      fetchToday(new Date());
    }
  };

  const onMonthChange = (month) => {
    if (month < 1) {
      setTodayDate(new Date(new Date(todayDate).setDate(-1)));
      fetchToday(new Date(new Date(todayDate).setDate(-1)));
    } else {
      setTodayDate(new Date(new Date(todayDate).setDate(+32)));
      fetchToday(new Date(new Date(todayDate).setDate(+32)));
    }
    setIsViewOnlyToday(false);
    setMonthFilter(true);
  };

  const onClickViewMore = (date) => {
    setTodayDate(new Date(date));
    fetchToday(new Date(date));
    setCurrentTab(1);
  };

  return (
    <div className="interview-calendar">
      <header className="calendar-header">
        <div className="header-top">
          <h2>{`${
            monthNames[todayDate.getMonth()]
          } ${todayDate.getFullYear()}`}</h2>
        </div>
        <div className="header-bottom">
          <div className="header-left flex-row-item">
            <Icon name="plain-calendar" />
            <div
              className={isViewOnlyToday ? "current" : ""}
              onClick={() => onViewTodayOrMonthClik(true)}
              style={{ cursor: "pointer" }}
            >
              Today
            </div>
            <Icon
              name="prev-arrow"
              className="cursor"
              onClick={() => onMonthChange(-1)}
            />
            <div
              className={monthFiter ? "current" : ""}
              onClick={() => onViewTodayOrMonthClik(false)}
              style={{ color: monthFiter ? "#3BA0AA" : "#2C384AF2" }}
            >
              {`${monthNames[todayDate.getMonth()]} ${todayDate.getFullYear()}`}
            </div>
            <Icon
              name="next-arrow"
              className="cursor"
              onClick={() => onMonthChange(1)}
            />
            {/* <Icon name="arrow-right"/> */}
          </div>
          {/* <div className="header-right">
              <div className="calendar-search">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search interview event date"
                />
                <i>C</i>
              </div>
              <div className="calendar-search">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search candidate or job title etc"
                />
                <i>S</i>
              </div>
            </div> */}
        </div>
      </header>
      <div className="calendar">
        <div className="calendar-tabs">
          <ul>
            <li
              className={currentTab === 0 ? "active-tab" : "inactive-tab"}
              onClick={() => onTabClik(0)}
            >
              {/* <Icon name="calendar-view" style={{ color: currentTab === 0 ? "purple" : "red" }}  /> */}
              <Icon
                name={"calender-view"}
                color={currentTab === 0 ? "#3BA0AA" : "rgba(44, 56, 74, 0.95)"}
              />
              <span>Calendar View</span>
            </li>
            <li
              className={currentTab === 1 ? "active-tab" : "inactive-tab"}
              onClick={() => onTabClik(1)}
            >
              {/* <Icon name="list-view" /> */}
              <Icon
                name={"list-view"}
                color={currentTab === 0 ? "rgba(44, 56, 74, 0.95)" : "#3BA0AA"}
              />
              <span>List View</span>
            </li>
          </ul>
        </div>
        {clientStoreState?.isInterviewDataLoading ? (
          <div
            style={{ width: "100%", textAlign: "center", marginBottom: "30px" }}
          >
            <ClipLoader />
          </div>
        ) : (
          <>
            {currentTab === 0 && interviewsData && (
              <AllInterviewCalendar
                data={interviewsData}
                month={todayDate.getMonth()}
                year={todayDate.getFullYear()}
                onClickViewMore={onClickViewMore}
              />
            )}
            {currentTab === 1 && interviewsData && (
              <InterviewTable
                data={interviewsData}
                activeTab={"upcoming"}
                isAll={true}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}
