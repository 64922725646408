import React from 'react'
import Tooltips from '../../../components/tooltip/tooltips/Tooltips';
import { Icon } from '../../../components/icon';
import { DateSortingDataTable } from '../../../components/functions';
import DateFormate from '../../../common/DateFormate';

const ColumnLists = (updateSpotLight, profileUpdateValue, setSkillTestResult) => {
  const columns = [
    {
      name: "S.No",
      cell: (row, index) => (
        index + 1
      ),
      width: "80px",
    },
    {
      name: "Name",
      selector: "fName",
      width: "130px",
      cell: (row) => (
        <span>
          {row?.fName + " " + row?.lName}</span>
      ),
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      width: "120px",
    },
    {
      name: "Profile Status",
      selector: "status",
      sortable: true,
    },
    {
      name: "Last Login",
      selector: "lastLogin",
      width: "160px",
      cell: (row) => (
        <span>
          {
            row?.lastLogin && (new Date(row?.lastLogin)?.toDateString() == "Invalid Date" ? "" : new Date(row?.lastLogin)?.toDateString())
          }
        </span>
      ),
      sortFunction: (a, b) => DateSortingDataTable(a, b, "lastLogin"),
      sortable: true,
    },
    {
      name: "Registration Date",
      selector: "registerDate",
      width: "160px",
      cell: (row) => (
        <span>
          {DateFormate(row?.createdAt)}
        </span>
      ),
      sortFunction: (a, b) => DateSortingDataTable(a, b, "createdAt"),
      sortable: true,
    },
    {
      name: "Spotlight",
      selector: "spotLight",
      cell: (row) => (
        <span
          onClick={() => {
            updateSpotLight(row?._id, !row?.spotLight, row?.devStatus);
          }}
        >
          <input
            type="checkbox"
            name="switch"
            onChange={(e) => {
              e.stopPropagation();
            }}
            checked={row.spotLight ? true : false}
          />
          <label className="label" htmlFor="switch3"></label>
        </span>
      ),
    },
    {
      name: "Direct Update",
      cell: (row) => (
        <span>
          {row?.profileDirectApprovedBy && (
            <Tooltips

              content={
                <div>
                  <div>Direct Approval</div>
                  <div>Approved by: {row?.profileDirectApprovedBy}</div>
                  <div>
                    Approved on:{" "}
                    {new Date(row?.directApprovedOn)?.toLocaleDateString()}
                  </div>
                </div>
              }
              direction="top2"
            >
              <Icon name="direct-approval" />
            </Tooltips>
          )}
          {row?.emailDirectVerifiedBy && (
            <Tooltips
              content={
                <div>
                  <div>Direct Email verify</div>
                  <div>Verified by: {row?.emailDirectVerifiedBy}</div>
                  <div>
                    Verified on:{" "}
                    {new Date(row?.directVerifiedOn)?.toLocaleDateString()}
                  </div>
                </div>
              }
              direction="top2"
            >
              <Icon name="direct-email-verify" />
            </Tooltips>
          )}
          {row?.engTestDirectClearedBy && (
            <Tooltips
              content={
                <div>
                  <div>Direct English test</div>
                  <div>Cleared by: {row?.engTestDirectClearedBy}</div>
                  <div>
                    Cleared on:{" "}
                    {new Date(
                      row?.directEngTestClearedOn
                    )?.toLocaleDateString()}
                  </div>
                </div>
              }
              direction="top2"
            >
              <Icon name="direct-english-test" />
            </Tooltips>
          )}
          {row?.skillTestDirectClearedBy && (
            <Tooltips
              content={
                <div>
                  <div>Direct Skill Test</div>
                  <div>Cleared by: {row?.skillTestDirectClearedBy}</div>
                  <div>
                    Cleared on:{" "}
                    {new Date(
                      row?.directSkillTestClearedOn
                    )?.toLocaleDateString()}
                  </div>
                </div>
              }
              direction="top2"
            >
              <Icon name="direct-skill_test" />
            </Tooltips>
          )}
        </span>
      ),
    },
    {
      name: "Number Verified",
      sortable: true,
      cell: (row) => (
        <span className='text-center' style={{width:"100%"}}>
         { row?.numberVerified && <Icon name="otp-verified" />}
        </span>
      ),
      width: "170px",
    },
    {
      name: "Profile Updates",
      selector: "approvalUpdates",
      cell: (row) => (
        <button
          className="editbtnView"
          style={{ color: "#ffffff" }}
          onClick={(e) => {
            e.stopPropagation();
            profileUpdateValue(row?.approvalUpdates);
          }}
        >
          {row?.approvalUpdates?.length ? "Pending" : "None"}
        </button>
      ),
    },
    {
      name: "Skills",
      button: true,
      cell: (row) => (
        <button
          className="editbtnView"
          style={{ color: "#ffffff" }}
          onClick={(e) => {
            e.stopPropagation();
            setSkillTestResult({ modal: true, devId: row?._id });
          }}
        >
          Result
        </button>
      ),
    },
  ];

  return columns;
}

export default ColumnLists