import { Fragment, useContext, useRef } from "react";
import UserImage from "../../../../assets/img/user-image.jpg";
import FresherTag from "../../../../assets/img/fresher-tag.png";
import { Icon } from "../../../../components/icon";
import add from "../../../../assets/img/icons/add_icon.svg";
import { ClipLoader, ScaleLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "./finalResume.css";
import PhotoGuideline from "../../../common/finalizeResume/photo_guideline/photoGuideline";
import { olibrToast } from "../../../../components/toaster";
import Modal from "../../../../components/modal/ModalBox";
import { useSelector, useDispatch } from "react-redux";
import {
  getDevVideoAction,
  setBackTab,
  uploadProfileImage,
} from "../../../../service/action/developer";
import Select from "react-select";
import BadgeIcon from "../../../../assets/img/icons/badge-icon.svg";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  deleteAdminDevAcademic,
  getAdminDevAcademic,
  getAdminDevCertification,
  saveAdminDevAcademic,
  updateAdminDevAcademic,
  deleteAdminDevCertification,
  updateAdminDevCertification,
  saveAdminDevCertification,
  getAdminDevSocialProfile,
  saveAdminDevSocialProfile,
  updateAdminDevSocialProfile,
  getAdminDevEmployement,
  updateAdminDevEmployement,
  saveAdminDevEmployement,
  deleteAdminDevEmployement,
  getAdminDevProject,
  getAdminSkillLists,
  getAllTools,
  getAdminStackLists,
  getAdminDevResult,
  deleteAdminDevProject,
  updateAdminDevProject,
  saveAdminDevProject,
  getAdminDevTools,
  saveAdminDevTools,
  deleteAdminDevTools,
  updateAdminDevTools,
  getAdminDevSkills,
  updateAdminDevSkills,
  saveAdminDevSkills,
  deleteAdminDevSkills,
  getAdminDevAchievement,
  updateAdminDevAchievement,
  saveAdminDevAchievement,
  deleteAdminDevAchievement,
  updateAdminDevShortDesciption,
  deleteProfileImage,
} from "../../../../service/action/admin";
import ProfilePreviewCommon from "../../../../components/profile-preview/profilePreview";
import { monthArr } from "../../../../common/constant/monthArr";
import "./finalResume.css";
import { Controller, useForm } from "react-hook-form";
import {
  chatGPTStandardEngAction,
  uploadMultiImageAction,
  uploadVideoAction,
} from "../../../../service/action/common";
import {
  devAChievementTip,
  devToolsTip,
  employementTip,
  educationTip,
  skillsTip,
  socialProfileTip,
  tellYourStoryTip,
  certificationsTip,
  profileGuidlinesTip,
} from "../../../../components/tooltip/ToolTipContent";
import Tooltip from "../../../../components/tooltip/Tooltip";
import Tooltips from "../../../../components/tooltip/tooltips/Tooltips";
import OlibrEditor from "../../../../components/editor";
import SelectOption from "../../../../components/select/SelectOption";
import DateObject from "react-date-object";
import {
  checkPermission,
  findSkillTime,
  formDateDateYear,
  readOnlyCheck,
} from "../../../../components/functions";
import { MainContext } from "../../../../context/MainContext";
import PercentageLoaderContent from "../../../../components/percentageLoader/PercentageLoaderContent";
import AdditionalProject from "./component/AdditionalProject";
import ProfilePreviewNew from "../../../../components/new-profilepreview/ProfilePreview";

const storyDummy =
  "I am a Senior Java Developer with 5+ years of active work experience within Agile processes. I have strong operational knowledge of Springboot and Hilla frameworks, and integration tools such as Gitlab, Jenkins, Cruise Control, and Bamboo. Coding and release management are my strong suit and I have worked on tight deadlines in all phases of production with successful outcomes. I bring to the table my ingenious analytical and problem-solving capabilities that have averted many disasters and expedited the production process in my past jobs. Teamwork, seamless communication, proper documentation, and enforcing the engineering standards are some of the facilities that come naturally to me with my years of experience in the field.";

const FinalResume = ({ devIsFresher }) => {
  const dispatch = useDispatch();
  const {
    user: { access },
  } = useContext(MainContext);
  const [fullRead, setFullRead] = useState({});
  const [isProfileView, setProfileView] = useState(false);
  const [userImage, setUserImage] = useState(null);
  const [guideLine, setGuideLine] = useState(false);
  const [userData, setUserData] = useState({});
  const uploadImgRef = useRef();
  const uploadProjectImageRef = useRef();
  const [stillWorking, setStillWorking] = useState(false);
  const {
    register: academicRegister,
    setValue: setAcademicValue,
    handleSubmit: academicSubmit,
    getValues: getAcademicValue,
    reset: resetAcademicForm,
    control: academicControl,
    formState: { errors: academicError },
  } = useForm();

  const {
    register: emplmntRegister,
    setValue: setEmplmntValue,
    handleSubmit: emplmntSubmit,
    getValues: getEmplmntValue,
    setError: setEmplmntError,
    reset: resetEmplmntForm,
    control: emplmntControl,
    formState: { errors: emplmntError },
  } = useForm();

  const {
    register: projectRegister,
    setValue: setProjectValue,
    handleSubmit: projectSubmit,
    getValues: getProjectValue,
    reset: resetProjectForm,
    setError: setProjectError,
    control: projectControl,
    formState: { errors: projectError },
  } = useForm();

  const {
    register: certfctRegister,
    setValue: setCertfctValue,
    handleSubmit: certfctSubmit,
    reset: resetCertfctForm,
    control: certfctControl,
    formState: { errors: certfctError },
  } = useForm();

  const {
    register: skillRegister,
    setValue: setSkillValue,
    handleSubmit: skillSubmit,
    reset: resetSkillForm,
    getValues: getSkillValue,
    formState: { errors: skillError },
  } = useForm();

  const {
    register: toolRegister,
    setValue: setToolValue,
    handleSubmit: toolSubmit,
    reset: resetToolForm,
    getValues: getToolValue,
    control: toolControl,
    formState: { errors: toolError },
  } = useForm();

  const {
    register: achivementRegister,
    setValue: setAchievementValue,
    handleSubmit: achivementSubmit,
    reset: resetAchivementForm,
    getValues: getAchivementValue,
    formState: { errors: achivementError },
  } = useForm();

  const {
    register: chatGPTRegister,
    setValue: setChatGPTValue,
    handleSubmit: chatGPTSubmit,
    getValues: getChatGPTValue,
    reset: resetChatGPTForm,
    control: chatGPTControl,
    formState: { errors: chatGPTError },
  } = useForm();

  const {
    resultDevData,
    devUser,
    adminDevAcademic,
    adminDevCertification,
    adminDevSocialProfileData,
    adminDevEmployementeData,
    adminDevProjectData,
    toolList,
    isAdminDevProFileLoad,
    skillList,
    stackList,
    adminDevToolData,
    adminChooseSkill,
    adminDevAchievementData,
    devUserLoading,
    isSaveAcademic,
    isUpdateEmplmt,
    isProjectSubmit,
    isCertificateSubmit,
    isSkillSubmit,
    isToolSubmit,
    isAchievementSubmit,
  } = useSelector((state) => state.adminReducer);
  const { isDevProjectImageLoading, devProjectImage, userImageUrl, devVideo } =
    useSelector((state) => state.developerReducer);
  const { openAiSdtEnglish, stdEngLoading, uploadVideoLoad } = useSelector(
    (state) => state.commonReducer
  );
  const [approvalStatus, setApprovalStatus] = useState("");

  const [content, setContent] = useState({
    current: "",
    improved: "",
    contentName: "",
  });
  const [allTools, setAllTools] = useState([]);

  const [devProjectDetails, setDevProjectDetails] = useState({
    modal: false,
    edit: false,
    image: [],
    skills: [],
  });

  useEffect(() => {
    let temparr = [];
    let arr1 = [];
    arr1 = adminDevToolData?.map((item) => item?.toolName + item?.toolIcon);
    let arr2 = [];
    arr2 = toolList?.data?.filter(
      (item) => !arr1?.includes(item?.toolsName + item?.toolsIcon)
    );
    arr2?.map((item) => {
      temparr?.push({
        label: item?.toolsName,
        value: item?._id,
        toolExp: "temp",
        toolIcon: item?.toolsIcon,
        toolName: item?.toolsName,
      });
    });
    setAllTools(temparr);
  }, [toolList]);

  useEffect(() => {
    if (userData?._id) {
      dispatch(getAdminDevCertification(navigate, { devId: userData?._id }));
      dispatch(getAdminDevSocialProfile(navigate, { devId: userData?._id }));
      dispatch(getAdminDevEmployement(navigate, { devId: userData?._id }));
      dispatch(getAdminDevProject(navigate, { devId: userData?._id }));
      dispatch(getAdminDevTools(navigate, { devId: userData?._id }));
      dispatch(getAdminDevSkills(navigate, { devId: userData?._id }));
      dispatch(getAdminDevAchievement(navigate, { devId: userData?._id }));
      dispatch(getAdminDevAcademic(navigate, { devId: userData?._id }));
      dispatch(getDevVideoAction(navigate, { devId: userData?._id }));
    }
    dispatch(
      getAdminStackLists("stack", navigate, { page: 0, limit: 0, search: "" })
    );
    dispatch(
      getAdminSkillLists("skill", navigate, { page: 0, limit: 0, search: "" })
    );
    fetchResumeInfo();
    userData?._id && dispatch(getAdminDevResult(userData?._id, navigate));
  }, [userData?._id]);


  useEffect(() => {
    if(devUser[0]){
      setUserData(devUser[0]);
      setUserImage(devUser[0]?.userImage)
    }
  }, [devUser]);

  const handleAcadmicInfoAdd = () => {
    setAcademicValue("devId", userData?._id);
    setAcadmicDetail({ ...acadmicDetail, modal: true });
  };

  const handleAcadmicInfoCancel = () => {
    setAcadmicDetail({ modal: false, edit: false });
    resetAcademicForm();
  };

  const [isPreview, setPreview] = useState(false);
  const [deleteFunction, setDeleteFunction] = useState({
    functionName: null,
    parameters: null,
  });
  const fetchResumeInfo = () => {
    setDevShortDesc({
      devTellUrStory: userData?.devTellUrStory,
    });
    setUserImage(userData?.userImage);

    setApprovalStatus(userData?.status);
  };

  useEffect(() => {
    devProjectImage && devProjectDetails?.image?.length
      ? setDevProjectDetails({
        ...devProjectDetails,
        image: [...devProjectDetails?.image, ...devProjectImage],
      })
      : setDevProjectDetails({ ...devProjectDetails, image: devProjectImage });
  }, [devProjectImage]);

  useEffect(() => {
    let finalData = [];
    resultDevData?.data?.forEach((item) => {
      let findItem = {};

      finalData?.some((element, index) => {
        if (
          element?.testId === item?.testId
          //  && element?.email === item.email
        ) {
          findItem = { element, index };
          return true;
        }
      });

      if (findItem?.element) {
        if (finalData[findItem?.index]?.attemptCount < item?.attemptCount) {
          finalData[findItem?.index] = item;
        }
      } else {
        finalData.push(item);
      }
    });

    const newArr = finalData?.filter((item) => item?.isPassed && (!item.plagiarism));
    setResultResp(newArr);
    // let newArr = resultDevData?.data?.filter(item => item?.isPassed)
    // setResultResp(newArr);
  }, [resultDevData]);

  const [resultResp, setResultResp] = useState([]);

  const [allSkills, setAllSkills] = useState([]);

  useEffect(() => {
    let arr = [];
    arr = skillList?.data?.map((skill) => ({
      value: skill?.skillTestIcon,
      label: skill?.skillName,
    }));
    setAllSkills(arr);
  }, [skillList]);

  const fetchAllSkillLists = () => {
    dispatch(
      getAdminSkillLists("skill", navigate, {
        page: 0,
        limit: 0,
        search: "",
      })
    );
  };

  useEffect(() => {
    fetchAllSkillLists();
    fetchResumeInfo();
    fetchAllTools();
    resetChatGPTForm();
  }, []);

  // const handleOpenAiModal = (data, contentName) => {
  //   if (!data) {
  //     if (contentName == "employement") {
  //       setEmplmntError('aboutRole', { type: 'custom', message: 'Role is required' })
  //       return false;
  //     } else if (contentName == "project") {
  //       setProjectError("description", { type: 'custom', message: 'Description is  required' })
  //       return false;
  //     } else if (contentName == "tellYourStory") {
  //       return false
  //     }

  //   }
  //   resetChatGPTForm();
  //   setContent({ current: data, contentName })
  //   setOpenAiModalOpen(true)
  // }

  const handleInputChatGPT = (value) => {
    dispatch(
      chatGPTStandardEngAction(navigate, {
        content: value + content.current,
      })
    );
  };

  const navigate = useNavigate();
  const postResume = () => {
    dispatch(
      updateAdminDevShortDesciption(
        navigate,
        { devId: userData?._id },
        devShortDesc
      )
    );
  };

  const handleAcademicInfoDelete = (academicId) => {
    dispatch(
      deleteAdminDevAcademic(
        navigate,
        { id: academicId },
        { devId: userData?._id }
      )
    );
  };
  const handleEmpInfoDelete = (employementId) => {
    dispatch(
      deleteAdminDevEmployement(
        navigate,
        { id: employementId },
        { devId: userData?._id }
      )
    );
  };
  const handleCertInfoDelete = (certificaionId) => {
    dispatch(
      deleteAdminDevCertification(
        navigate,
        { id: certificaionId },
        { devId: userData?._id }
      )
    );
  };

  const handleProjectInfoDelete = (projectId) => {
    dispatch(
      deleteAdminDevProject(
        navigate,
        { id: projectId },
        { devId: userData?._id }
      )
    );
  };

  const handleCertInfoAdd = () => {
    setCertfctValue("devId", userData?._id);
    setCertDetails({ edit: false, modal: true });
  };

  const handleCertInfoCancel = () => {
    resetCertfctForm();
    setCertDetails({ edit: false, modal: false });
  };

  const [certDetails, setCertDetails] = useState({
    edit: false,
    index: "",
  });

  const editCertInfo = (devAcad) => {
    setCertfctValue("certificateName", devAcad?.certificateName);
    setCertfctValue("certInstitution", devAcad?.certInstitution);
    devAcad?.startDate &&
      setCertfctValue("startDate", new Date(devAcad?.startDate));
    devAcad?.endDate && setCertfctValue("endDate", new Date(devAcad?.endDate));
    setCertfctValue("id", devAcad?._id);
    setCertDetails({ edit: true, modal: true });
  };
  const [acadmicDetail, setAcadmicDetail] = useState({
    edit: false,
    index: "",
  });

  const editAcadInfo = (devAcad) => {
    setAcademicValue("institution", devAcad?.institution);
    setAcademicValue("specialization", devAcad?.specialization);
    devAcad?.startDate &&
      setAcademicValue("startDate", new Date(devAcad?.startDate));
    devAcad?.endDate && setAcademicValue("endDate", new Date(devAcad?.endDate));
    setAcademicValue("id", devAcad?._id);
    setAcadmicDetail({ modal: true, edit: true });
  };

  const handleToolInfoDelete = (toolId) => {
    dispatch(
      deleteAdminDevTools(navigate, { id: toolId }, { devId: userData?._id })
    );
  };

  const [toolDetails, setToolDetails] = useState({
    edit: false,
    index: "",
  });

  const editToolInfo = (tool) => {
    setToolValue("toolId", tool?._id);
    setToolValue("tools", {
      label: tool?.toolName,
      toolExp: tool?.toolExp,
      toolName: tool?.toolName,
      toolIcon: tool?.toolIcon,
    });
    setToolDetails({ modal: true, edit: true });
  };

  const handleToolInfoAdd = () => {
    resetToolForm();
    setToolDetails({ modal: true, edit: false });
  };
  const handleToolInfoCancel = () => {
    resetToolForm();
    setToolDetails({ modal: false, edit: false });
  };
  const [devShortDesc, setDevShortDesc] = useState({
    devTellUrStory: "",
  });

  const openGuideLine = () => {
    setGuideLine(true);
  };

  const closeGuideLine = () => {
    setGuideLine(false);
  };

  const onUploadProfileImage = async (e) => {
    let formData = new FormData();
    formData.append("imageFile", e.target.files[0]);
    if (e.target.files[0]?.size > 1024 * 1024) {
      olibrToast.error("Please use image of size less than 1MB");
    } else {
      dispatch(uploadProfileImage({
        formData,
        devId: userData?._id,
      }, navigate, userImage));

    }
    e.target.value = null;
  };

  const onUploadProjectImage = async (e) => {
    let formData = new FormData();
    for (let i = 0; i < e?.target?.files?.length; i++) {
      if (e.target.files[i]?.size > 1024 * 1024) {
        olibrToast.error("Please use images of size less than 1MB each.");
        return false;
      }
      formData.append("imageFiles", e.target.files[i]);
    }
    dispatch(uploadMultiImageAction(formData, navigate));
    e.target.value = null;
  };

  const [empDetails, setEmpDetails] = useState({
    edit: false,
    index: "",
  });

  const handleEmpInfoCancel = () => {
    setEmpDetails({ modal: false, edit: false });
    resetEmplmntForm();
  };

  const editEmploymentInfo = (item, index) => {
    setEmplmntValue("designation", item?.designation);
    setEmplmntValue("companyName", item?.companyName);
    item?.from && setEmplmntValue("from", new Date(item?.from));
    item?.to && setEmplmntValue("to", new Date(item?.to));
    setEmplmntValue("stillWorking", item?.stillWorking);
    setEmplmntValue("techUsed", item?.techUsed);
    setEmplmntValue("toolUsed", item?.toolUsed);
    setEmplmntValue("aboutRole", item?.aboutRole);
    setEmplmntValue("designation", item?.designation);
    setEmplmntValue("id", item?._id);
    setStillWorking(item?.stillWorking);
    setEmpDetails({ modal: true, edit: true });
  };

  const projectInfoModalClose = () => {
    setProjectValue("skills", "");
    resetProjectForm();
    setDevProjectDetails({
      modal: false,
      edit: false,
      image: [],
      skills: [],
    });
  };

  const handleProjectDetailsEdit = (projectDetail) => {
    setProjectValue("id", projectDetail?._id);
    setProjectValue("empId", projectDetail?.empId);
    setProjectValue("clientName", projectDetail?.clientName);
    setProjectValue("name", projectDetail?.name);
    setProjectValue("isPortfolio", projectDetail?.isPortfolio);
    setProjectValue("role", projectDetail?.role);
    setProjectValue("delTime", projectDetail?.delTime);
    setProjectValue("Backend", projectDetail?.Backend);
    setProjectValue("numOfProf", projectDetail?.numOfProf);
    setProjectValue("projectIndus", projectDetail?.projectIndus);
    setProjectValue("techTool", projectDetail?.techTool);
    setProjectValue("description", projectDetail?.description);
    setProjectValue("skills", projectDetail?.skills);
    setProjectValue("image", projectDetail?.image);
    setProjectValue("uniKey", projectDetail?.uniKey);
    setProjectValue("responsibilities",projectDetail?.responsibilities)
    setDevProjectDetails({
      ...devProjectDetails,
      image: projectDetail?.image,
      skills: projectDetail?.skills,
      edit: true,
      modal: true,
    });
  };

  const openEmpInfoModal = () => {
    setEmplmntValue("devId", userData?._id);
    setStillWorking(false);
    setEmpDetails({ ...empDetails, modal: true });
  };

  const openProjectModal = (item) => {
    setProjectValue("empId", item._id);
    setDevProjectDetails({
      ...devProjectDetails,
      modal: true,
    });
  };

  const checkCertValid = (data) => {
    if (new Date(data?.startDate) > new Date(data?.endDate)) {
      olibrToast.error("Please Fill Dates in correct order");
      return false;
    }
    if (certDetails?.edit) {
      dispatch(
        updateAdminDevCertification(
          navigate,
          { devId: userData?._id },
          data,
          setCertDetails,
          resetCertfctForm
        )
      );
    } else {
      dispatch(
        saveAdminDevCertification(
          navigate,
          data,
          setCertDetails,
          resetCertfctForm
        )
      );
    }
  };

  const checkAcadValid = (data) => {
    if (acadmicDetail?.edit) {
      dispatch(
        updateAdminDevAcademic(
          navigate,
          userData?._id,
          data,
          setAcadmicDetail,
          resetAcademicForm
        )
      );
    } else {
      dispatch(
        saveAdminDevAcademic(
          navigate,
          data,
          setAcadmicDetail,
          resetAcademicForm
        )
      );
    }
  };
  const checkToolValid = (data) => {
    let error = false;
    if (toolDetails?.edit) {
      devUser?.devTools?.map((item) => {
        if (item?.toolName === data?.tools?.toolName) {
          olibrToast?.error(`${item?.toolName} tool is already exists`);
          error = true;
          return false;
        }
      });
      !error && delete data?.tools?.label;
      !error && delete data?.tools?.value;
      !error &&
        dispatch(
          updateAdminDevTools(
            navigate,
            { devId: userData?._id },
            {
              ...data?.tools,
              id: getToolValue("toolId"),
            },
            setToolDetails,
            resetToolForm
          )
        );
    } else {
      devUser?.length &&
        devUser[0]?.devTools?.map((item) => {
          data?.tools?.map((itm) => {
            if (item?.toolName === itm?.toolName) {
              olibrToast?.error(`${item?.toolName} tool is already exists`);
              error = true;
              return false;
            }
          });
        });
      let tempObj = [];
      data?.tools?.map((item) => {
        tempObj?.push({
          toolName: item?.toolName,
          toolIcon: item?.toolIcon,
          toolExp: item?.toolExp,
        });
      });
      !error &&
        dispatch(
          saveAdminDevTools(
            navigate,
            {
              devId: userData?._id,
            },
            tempObj,
            setToolDetails,
            resetToolForm
          )
        );
    }
  };

  const DeletePreview = () => {
    setPreview(!isPreview);
  };

  function deleteConfirmation() {
    switch (deleteFunction.functionName) {
      case "handleAcademicInfoDelete":
        handleAcademicInfoDelete(deleteFunction.parameters);
        setPreview(!isPreview);
        break;
      case "handleEmpInfoDelete":
        handleEmpInfoDelete(deleteFunction.parameters);
        setPreview(!isPreview);
        break;
      case "handleProjectInfoDelete":
        handleProjectInfoDelete(deleteFunction.parameters);
        setPreview(!isPreview);
        break;
      case "handleCertInfoDelete":
        handleCertInfoDelete(deleteFunction.parameters);
        setPreview(!isPreview);
        break;
      case "handleToolInfoDelete":
        handleToolInfoDelete(deleteFunction.parameters);
        setPreview(!isPreview);
        break;
      case "handleSkillInfoDelete":
        handleSkillInfoDelete(deleteFunction.parameters);
        setPreview(!isPreview);
        break;
      case "handleAchievementInfoDelete":
        handleAchievementInfoDelete(deleteFunction.parameters);
        setPreview(!isPreview);
        break;
      case "profileimage":
        dispatch(deleteProfileImage(navigate, userData?._id, setPreview));
        break;
      default:
        return false;
    }
  }
  const devEmpModalSubmit = (data) => {
    if (empDetails?.edit) {
      let toolUsed = [];
      let techUsed = [];
      data?.techUsed?.map((item) => {
        techUsed?.push({ label: item?.label, value: item?.value });
      });
      data?.toolUsed?.map((item) => {
        toolUsed?.push({ label: item?.label, value: item?.value });
      });
      dispatch(
        updateAdminDevEmployement(
          navigate,
          { devId: userData?._id },
          { ...data, techUsed, toolUsed },
          setEmpDetails,
          resetEmplmntForm
        )
      );
    } else {
      dispatch(
        saveAdminDevEmployement(
          navigate,
          data,
          setEmpDetails,
          resetEmplmntForm,
          { devId: userData?._id }
        )
      );
    }
  };

  const handleEmpContent = () => {
    return (
      <div style={{ display: "flex" }}>
        <form>
          <Modal
            open={openAiModalOpen}
            title={"Improve Content"}
            onClose={closeSuggestionBox}
            onCancel={closeSuggestionBox}
            onDone={chatGPTSubmit(StoryImprovementFunc)}
            content={openAiSuggestionContent}
          />
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label
                  className={`floating-label ${emplmntError?.designation && "text-danger"
                    }`}
                  htmlFor=""
                >
                  Designation*
                </label>
                <input
                  type="text"
                  {...emplmntRegister("designation", {
                    required: true,
                  })}
                  name="designation"
                  className={`form-control ${emplmntError?.designation && "form-required"
                    }`}
                  placeholder="Designation"
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label
                  className={`floating-label ${emplmntError?.companyName && "text-danger"
                    }`}
                  htmlFor=""
                >
                  Company Name*
                </label>
                <input
                  type="text"
                  {...emplmntRegister("companyName", {
                    required: true,
                  })}
                  name="companyName"
                  className={`form-control ${emplmntError?.companyName && "form-required"
                    }`}
                  placeholder="Company Name"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div
                className={`form-group ${emplmntError?.from && "date-required"
                  }`}
              >
                <label
                  className={`floating-label ${emplmntError?.from && "text-danger"
                    }`}
                  htmlFor=""
                >
                  From
                </label>
                <Controller
                  control={emplmntControl}
                  name="from"
                  {...emplmntRegister("from", {
                    required: true,
                  })}
                  render={({ field }) => (
                    <ReactDatePicker
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                      placeholderText="Start Date"
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-6">
              <div
                className={`form-group ${emplmntError?.to && "date-required"}`}
                style={{
                  visibility: stillWorking ? "hidden" : "visible",
                }}
              >
                <label
                  className={`floating-label ${emplmntError?.to && "text-danger"
                    }`}
                  htmlFor=""
                >
                  To
                </label>
                <Controller
                  control={emplmntControl}
                  name="to"
                  {...emplmntRegister("to", {
                    required: getEmplmntValue("stillWorking") ? false : true,
                  })}
                  render={({ field }) => (
                    <ReactDatePicker
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                      placeholderText="End Date"
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-5">
                  <span>Still Working Here</span>
                </div>
                <span
                  onClick={() => {
                    setEmplmntValue(
                      "stillWorking",
                      !getEmplmntValue("stillWorking")
                    );
                    setStillWorking(!stillWorking);
                  }}
                >
                  <input
                    {...emplmntRegister("stillWorking")}
                    type="checkbox"
                    name="stillWorking"
                    checked={getEmplmntValue("stillWorking")}
                  />
                  <label className="label" htmlFor="switch3"></label>
                </span>
              </div>
              <div className="row">
                <div
                  className={`col-6 ${emplmntError?.techUsed && "select-required"
                    }`}
                >
                  <label
                    htmlFor=""
                    className={`floating-label ${emplmntError?.techUsed && "text-danger"
                      }`}
                  >
                    Technologies
                  </label>
                  <Controller
                    control={emplmntControl}
                    name="techUsed"
                    {...emplmntRegister("techUsed", {
                      required: getEmplmntValue("toolUsed") ? false : true,
                    })}
                    render={({ field }) => (
                      <SelectOption
                        menuPortalTarget={document.body}
                        optionArray={!!allSkills?.length ? allSkills : []}
                        getValue={getEmplmntValue}
                        getValueName={"techUsed"}
                        fieldProp={field}
                        isMulti={true}
                        searchable={true}
                      />
                    )}
                  />
                </div>
                <div
                  className={`col-6 ${emplmntError?.toolUsed && "select-required"
                    }`}
                >
                  <label
                    htmlFor=""
                    className={`floating-label ${emplmntError?.toolUsed && "text-danger"
                      }`}
                  >
                    Tools
                  </label>
                  <Controller
                    control={emplmntControl}
                    name="toolUsed"
                    {...emplmntRegister("toolUsed", {
                      required: getEmplmntValue("techUsed") ? false : true,
                    })}
                    render={({ field }) => (
                      <SelectOption
                        menuPortalTarget={document.body}
                        optionArray={
                          !!toolList?.data?.length
                            ? toolList?.data?.map((tool) => ({
                              value: tool?.toolsIcon,
                              label: tool?.toolsName,
                            }))
                            : []
                        }
                        getValue={getEmplmntValue}
                        getValueName={"toolUsed"}
                        fieldProp={field}
                        isMulti={true}
                        searchable={true}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <div className="row">
                  <div className="col-10">
                    <label
                      className={`${emplmntError?.aboutRole && "text-danger"}`}
                      htmlFor=""
                      style={{ zIndex: 0 }}
                    >
                      Describe your current role*
                    </label>
                  </div>
                  {/* <div className="col-2 text-right">
                    <span
                      className=""
                      title="Enhance the Language of Your Role"
                      onClick={() => handleOpenAiModal(getEmplmntValue('aboutRole'), "employement")}
                    >
                      <Icon name="improve-icon" />
                    </span>
                  </div> */}
                </div>

                <Controller
                  control={emplmntControl}
                  name="aboutRole"
                  {...emplmntRegister("aboutRole", {
                    required: true,
                  })}
                  render={({ field }) => (
                    <OlibrEditor
                      value={
                        getEmplmntValue("aboutRole")
                          ? getEmplmntValue("aboutRole")
                          : ""
                      }
                      onChange={(value) => field.onChange(value)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="col-12">
            <div className="bottom-button">
              <button
                className="cancelBtn"
                type="button"
                onClick={handleEmpInfoCancel}
              >
                Cancel
              </button>
              <button
                onClick={emplmntSubmit(devEmpModalSubmit)}
                className="submitBtn themeButton"
              >
                {isUpdateEmplmt ? (
                  <ClipLoader size={15} color="#FFFFFF" />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  const [customLightBox, setCustomLightBox] = useState(false);
  const closeCustomLightBox = () => {
    setCustomLightBox(false);
  };
  const customLightContent = () => {
    return (
      <Fragment>
        <div>
          <section class="carousel" aria-label="carousel" Tabindex="0">
            <a class="carousel__skip-link" href="#skip-link">
              Carousel überspringen
            </a>
            <div class="slides">
              {devProjectDetails?.image?.map((item) => (
                <div
                  class="slides-item slide-1"
                  id={item}
                  aria-label="slide 1 of 5"
                  tabindex="0"
                >
                  <img src={item} alt="" />
                </div>
              ))}
            </div>
            <div class="carousel__nav">
              {devProjectDetails?.image?.map((item, index) => (
                <a
                  class="slider-nav"
                  href={`#${item}`}
                  aria-label="Go to slide 5"
                >
                  {index + 1}
                </a>
              ))}
            </div>
          </section>
        </div>
      </Fragment>
    );
  };

  const addSkillInProject = () => {
    if (!getProjectValue("projectSkill")) {
      olibrToast.error("please enter skill name!");
      return false;
    }
    if (devProjectDetails?.skills && devProjectDetails?.skills?.length > 0) {
      const check = devProjectDetails?.skills?.filter(
        (item) =>
          item?.toUpperCase() == getProjectValue("projectSkill")?.toUpperCase()
      );
      if (check?.length > 0) {
        olibrToast.error("tag already exists!");
        return false;
      } else {
        setProjectValue("skills", [
          ...getProjectValue("skills"),
          getProjectValue("projectSkill"),
        ]);
        setDevProjectDetails({
          ...devProjectDetails,
          skills: [
            ...devProjectDetails?.skills,
            getProjectValue("projectSkill"),
          ],
        });
      }
    } else {
      setProjectValue("skills", [getProjectValue("projectSkill")]);
      setDevProjectDetails({
        ...devProjectDetails,
        skills: getProjectValue("skills"),
      });
    }
    setProjectValue("projectSkill", "");
  };

  const addSkillInTechSkill = () => {
    if (!getSkillValue("skills")) {
      olibrToast.error("please enter skill name!");
      return false;
    }
    if (skillDetail?.skills && skillDetail?.skills?.length > 0) {
      const check = skillDetail?.skills?.filter(
        (item) => item?.toUpperCase() == getSkillValue("skills")?.toUpperCase()
      );
      if (check?.length > 0) {
        olibrToast.error("tag already exists!");
        return false;
      } else {
        setSkillValue("tagArr", [
          ...getSkillValue("tagArr"),
          getSkillValue("skills"),
        ]);
        setSkillDetails({
          ...skillDetail,
          skills: [...skillDetail?.skills, getSkillValue("skills")],
        });
      }
    } else {
      setSkillValue("tagArr", [getSkillValue("skills")]);
      setSkillDetails({ ...skillDetail, skills: [getSkillValue("skills")] });
    }
    setSkillValue("skills", "");
  };

  const handleProjectContent = () => {
    return (
      <form>
        <Modal
          open={openAiModalOpen}
          title={"Improve Content"}
          onClose={closeSuggestionBox}
          onCancel={closeSuggestionBox}
          onDone={chatGPTSubmit(StoryImprovementFunc)}
          content={openAiSuggestionContent}
        />
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label
                className={`floating-label ${projectError?.name && "text-danger"
                  }`}
                htmlFor=""
              >
                Project Name*
              </label>
              <input
                type="text"
                {...projectRegister("name", {
                  required: true,
                })}
                name="name"
                className={`form-control ${projectError?.name && "form-required"
                  }`}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label
                className={`floating-label ${projectError?.clientName && "text-danger"
                  }`}
                htmlFor=""
              >
                Client Name
              </label>
              <input
                type="text"
                {...projectRegister("clientName")}
                name="clientName"
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label
                className={`floating-label ${projectError?.role && "text-danger"
                  }`}
                htmlFor=""
              >
                Role*
              </label>
              <input
                type="text"
                {...projectRegister("role", { required: true })}
                name="role"
                className={`form-control ${projectError?.role && "form-required"
                  }`}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="floating-label" htmlFor="">
                Project Industry
              </label>
              <input
                type="text"
                name="projectIndus"
                {...projectRegister("projectIndus")}
                className="form-control"
              />
            </div>
          </div>
          {/* <div className="col-6">
            <div className="form-group">
              <label className="floating-label" htmlFor="">
                Backend Technology Used
              </label>
              <input
                type="text"
                {...projectRegister("Backend")}
                name="Backend"
                className="form-control"
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="floating-label" htmlFor="">
                Number of Professionals
              </label>
              <input
                type="text"
                {...projectRegister("numOfProf")}
                name="numOfProf"
                className="form-control"
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="floating-label" htmlFor="">
                Delivery Time (in days)
              </label>
              <input
                type="text"
                {...projectRegister("delTime")}
                name="delTime"
                className="form-control"
              />
            </div>
          </div> */}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <div className="row">
                <div className="col-10">
                  <label
                    className={`floating-label ${projectError?.description && "text-danger"
                      }`}
                    htmlFor=""
                  >
                    Project Description*
                  </label>
                </div>
              </div>

              <Controller
                control={projectControl}
                name="description"
                {...projectRegister("description", {
                  required: true,
                })}
                render={({ field }) => (
                  <OlibrEditor
                    value={
                      getProjectValue("description")
                        ? getProjectValue("description")
                        : ""
                    }
                    onChange={(value) => field.onChange(value)}
                  />
                )}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <div className="row">
                <div className="col-10">
                  <label
                    className={`floating-label ${projectError?.responsibilities && "text-danger"
                      }`}
                    htmlFor=""
                  >
                    Roles and Responsibilities*
                  </label>
                </div>
              </div>

              <Controller
                control={projectControl}
                name="responsibilities"
                {...projectRegister("responsibilities", {
                  required: true,
                })}
                render={({ field }) => (
                  <OlibrEditor
                    value={
                      getProjectValue("responsibilities")
                        ? getProjectValue("responsibilities")
                        : ""
                    }
                    onChange={(value) => field.onChange(value)}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label
                className={`floating-label ${projectError?.skills ? "text-danger" : ""
                  }`}
                htmlFor=""
              >
                Add Skills*
              </label>
              <input
                {...projectRegister("skills", { required: true })}
                name="skills"
                type="hidden"
              />
              <div style={{ display: "flex" }}>
                <input
                  type="text"
                  {...projectRegister("projectSkill")}
                  name="projectSkill"
                  onKeyDown={(e) => {
                    if (e?.code === "Enter") {
                      addSkillInProject();
                    }
                  }}
                  className="form-control"
                />
                <a onClick={() => addSkillInProject()}>
                  <Icon name="add-icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <ul className="tags tags-fill">
          {devProjectDetails?.skills?.map((item, index) => (
            <li>
              <a>
                <span>{item}</span>{" "}
                <i
                  onClick={() => {
                    const tempSkill = [...devProjectDetails?.skills];
                    tempSkill.splice(index, 1);
                    if (devProjectDetails?.skills?.length == 1) {
                      setProjectValue("skills", "");
                    } else {
                      setProjectValue("skills", tempSkill);
                    }
                    setDevProjectDetails({
                      ...devProjectDetails,
                      skills: tempSkill,
                    });
                  }}
                >
                  <Icon name="modal-close" />
                </i>
              </a>
            </li>
          ))}
        </ul>
        <br />

        <div className="row">
          <div className="col-12">
            <a
              className="file-icon"
              onClick={() => uploadProjectImageRef.current.click()}
            >
              {isDevProjectImageLoading ? (
                <ScaleLoader color="#3BA0AA" height={10} />
              ) : (
                "Upload project image"
              )}
            </a>
          </div>
          <div className="col-12">
            <br />
            <Modal
              size="100%"
              open={customLightBox}
              title={"Project Images"}
              onClose={closeCustomLightBox}
              content={() => customLightContent()}
              isFooterView={false}
            />

            {devProjectDetails?.image?.map((imgUrl, index) => (
              <Fragment>
                <div className="portfolio-images">
                  <figure>
                    <img
                      key={index + Math.random()}
                      onClick={() => {
                        setCustomLightBox(true);
                      }}
                      src={imgUrl}
                      alt="Project Image"
                    />
                  </figure>
                </div>
                <span
                  onClick={() => removeImg(index)}
                  style={{
                    position: "relative",
                    bottom: "60px",
                    right: "15px",
                  }}
                >
                  <Icon name="modal-close" />
                </span>
              </Fragment>
            ))}
          </div>
        </div>
        <br />
        <br />
        <div className="col-12">
          <div className="bottom-button">
            <button
              className="cancelBtn"
              type="button"
              onClick={projectInfoModalClose}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={projectSubmit(handleDevProjectSubmit)}
              className="submitBtn themeButton"
            >
              {isProjectSubmit ? (
                <ClipLoader size={15} color="#FFFFFF" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </form>
    );
  };

  const removeImg = (index) => {
    let arr = devProjectDetails?.image?.filter((item, ind) => index != ind);
    setDevProjectDetails({ ...devProjectDetails, image: arr });
  };

  const handleDevProjectSubmit = (data) => {
    data?.hasOwnProperty("projectSkill") && delete data?.projectSkill;
    if (devProjectDetails?.edit) {
      let tempData = {
        image: devProjectDetails?.image ? devProjectDetails?.image : [],
        devId: userData?._id,
      };
      dispatch(
        updateAdminDevProject(
          navigate,
          { ...data, ...tempData },
          setDevProjectDetails,
          resetProjectForm
        )
      );
    } else {
      let tempData = {
        devId: userData?._id,
        techTool: [],
        isPortfolio: false,
        image: devProjectDetails?.image ? devProjectDetails?.image : [],
      };
      dispatch(
        saveAdminDevProject(
          navigate,
          { ...data, ...tempData },
          setDevProjectDetails,
          resetProjectForm
        )
      );
    }
  };

  const handleCertContent = () => {
    return (
      <form>
        <div style={{ display: "flex" }}>
          <div className="row">
            <div className="form-group col-6">
              <input
                type="text"
                className={`form-control col-12 ${certfctError?.certificateName && "form-required"
                  }`}
                placeholder="Certficate Name"
                name="certificateName"
                {...certfctRegister("certificateName", {
                  required: true,
                })}
              />
            </div>

            <div className="form-group col-6">
              <input
                type="text"
                className="form-control col-12"
                placeholder="Name of The Institution"
                name="certInstitution"
                {...certfctRegister("certInstitution")}
              />
            </div>

            <div
              className={`form-group col-6  ${certfctError?.startDate && "date-required"
                }`}
            >
              <Controller
                control={certfctControl}
                name="startDate"
                {...certfctRegister("startDate")}
                render={({ field }) => (
                  <ReactDatePicker
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    placeholderText="Start Date"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                  />
                )}
              />
            </div>
            <div
              className={`form-group col-6  ${certfctError?.endDate && "date-required"
                }`}
            >
              <Controller
                control={certfctControl}
                name="endDate"
                {...certfctRegister("endDate")}
                render={({ field }) => (
                  <ReactDatePicker
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    placeholderText="End Date"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                  />
                )}
              />
            </div>
          </div>
        </div>
        <br />
        <div className="col-12">
          <div className="bottom-button">
            <button
              className="cancelBtn"
              type="button"
              onClick={handleCertInfoCancel}
            >
              Cancel
            </button>
            <button
              onClick={certfctSubmit(checkCertValid)}
              className="submitBtn themeButton"
            >
              {isCertificateSubmit ? (
                <ClipLoader size={15} color="#FFFFFF" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </form>
    );
  };

  const handleToolContent = () => {
    return (
      <form>
        <div style={{ display: "flex" }}>
          <div
            className={`form-group col-12 ${toolError?.tools && "select-required"
              }`}
          >
            <Controller
              control={toolControl}
              name="tools"
              {...toolRegister("tools", {
                required: true,
              })}
              render={({ field }) => (
                <SelectOption
                  isMulti={!toolDetails.edit}
                  optionArray={!!allTools?.length ? allTools : []}
                  getValue={getToolValue}
                  getValueName={"tools"}
                  fieldProp={field}
                  searchable={true}
                />
              )}
            />
          </div>
        </div>
        <br />
        <div className="col-12">
          <div className="bottom-button">
            <button
              className="cancelBtn"
              type="button"
              onClick={handleToolInfoCancel}
            >
              Cancel
            </button>
            <button
              onClick={toolSubmit(checkToolValid)}
              className="submitBtn themeButton"
            >
              {isToolSubmit ? (
                <ClipLoader size={15} color="#FFFFFF" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </form>
    );
  };

  const handleAchievementInfoDelete = (achId) => {
    dispatch(
      deleteAdminDevAchievement(
        navigate,
        { id: achId },
        { devId: userData?._id }
      )
    );
  };

  const fetchAllTools = () => {
    dispatch(
      getAllTools(navigate, {
        page: 0,
        limit: 0,
        search: "",
      })
    );
  };

  const regexPattern = {
    gitHub:
      /^(https?:\/\/)?([a-z]{0,10}\.)?github\.(com)|(io)\/[a-zA-Z0-9_-]{1,25}\/?$/i,
    linkedin:
      /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/i,
    facebook:
      /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/i,
    twitter: /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/,
  };

  const socialLinkValid = (platform, link) => {
    if (regexPattern[platform].test(link) || link == "") {
      if (socialObject?.id) {
        dispatch(
          updateAdminDevSocialProfile(
            navigate,
            { devId: userData?._id },
            socialObject
          )
        );
      } else {
        let tempObj = {
          gitHub: socialObject?.gitHub ? socialObject?.gitHub : "",
          linkedin: socialObject?.linkedin ? socialObject?.linkedin : "",
          facebook: socialObject?.facebook ? socialObject?.facebook : "",
          twitter: socialObject?.twitter ? socialObject?.twitter : "",
          devId: userData?._id,
        };
        dispatch(saveAdminDevSocialProfile(navigate, tempObj));
      }
    } else {
      olibrToast.error(`Please Enter a Valid Link for ${platform}!!!`);
    }
  };

  const handleAchievementInfoCancel = () => {
    resetAchivementForm();
    setAchievementDetails({ modal: false, edit: false });
  };

  const handleAchContent = () => {
    return (
      <form>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label
                className={`floating-label ${achivementError?.title && "text-danger"
                  }`}
                htmlFor=""
              >
                Achievement
              </label>
              <textarea
                rows={6}
                {...achivementRegister("title", {
                  required: true,
                })}
                className={`form-control ${achivementError?.title && "form-required"
                  }`}
                name="title"
              ></textarea>
            </div>
          </div>
        </div>
        <br />
        <div className="col-12">
          <div className="bottom-button">
            <button
              className="cancelBtn"
              type="button"
              onClick={handleAchievementInfoCancel}
            >
              Cancel
            </button>
            <button
              onClick={achivementSubmit(handleAchievementInfoInstert)}
              className="submitBtn themeButton"
            >
              {isAchievementSubmit ? (
                <ClipLoader size={15} color="#FFFFFF" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </form>
    );
  };

  const handleAchievementInfoAdd = (value) => {
    setAchievementDetails({ ...achievementDetails, modal: true });
  };

  const handleAchievementInfoInstert = (data) => {
    if (achievementDetails.edit) {
      dispatch(
        updateAdminDevAchievement(
          navigate,
          { devId: userData?._id },
          {
            id: getAchivementValue("id"),
            title: data?.title,
          },
          setAchievementDetails,
          resetAchivementForm
        )
      );
    } else {
      dispatch(
        saveAdminDevAchievement(
          navigate,
          {
            title: data?.title,
            devId: userData?._id,
          },
          setAchievementDetails,
          resetAchivementForm
        )
      );
    }
  };

  const [achievementDetails, setAchievementDetails] = useState({
    edit: false,
    index: "",
  });

  const editAchievementInfo = (item) => {
    setAchievementValue("title", item?.title);
    setAchievementValue("id", item?._id);
    setAchievementDetails({ modal: true, edit: true });
  };

  const handleSkillInfoAdd = () => {
    setSkillValue("devId", userData?._id);
    setSkillDetails({ modal: true, edit: false });
  };

  const [skillDetail, setSkillDetails] = useState({
    edit: false,
    index: "",
  });

  const editSkillInfo = (item) => {
    setSkillValue("tagArr", item?.tagArr);
    setSkillValue("title", item?.title);
    setSkillValue("id", item?._id);
    setSkillDetails({ modal: true, edit: true, skills: item?.tagArr });
  };

  const handleSkillContent = () => {
    return (
      <form>
        <div className="row">
          <div className="form-group col-12">
            <label
              className={`floating-label ${skillError?.title && "text-danger"}`}
            >
              Title*
            </label>
            <input
              type="text"
              className={`form-control col-12 ${skillError?.title && "form-required"
                }`}
              name="title"
              {...skillRegister("title", { required: true })}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label
                className={`floating-label ${skillError?.tagArr && "text-danger"
                  }`}
                htmlFor=""
              >
                Add Skills
              </label>
              <input
                {...skillRegister("tagArr", { required: true })}
                name="skills"
                type="hidden"
              />
              <div style={{ display: "flex" }}>
                <input
                  type="text"
                  {...skillRegister("skills")}
                  name="skills"
                  onKeyDown={(e) => {
                    if (e?.code === "Enter") {
                      addSkillInTechSkill();
                    }
                  }}
                  className={`form-control ${skillError?.tagArr && "form-required"
                    }`}
                />

                <a onClick={() => addSkillInTechSkill()}>
                  <img
                    style={{ height: "30px", width: "30px" }}
                    src={add}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <ul className="tags tags-fill">
          {skillDetail?.skills?.map((item, index) => (
            <li>
              <a>
                <span>{item}</span>{" "}
                <i
                  onClick={() => {
                    const tempSkill = [...skillDetail?.skills];
                    tempSkill.splice(index, 1);
                    if (skillDetail?.skills?.length == 1) {
                      setSkillValue("tagArr", "");
                    } else {
                      setSkillValue("tagArr", tempSkill);
                    }
                    setSkillDetails({
                      ...skillDetail,
                      skills: tempSkill,
                    });
                  }}
                >
                  <Icon name="modal-close" />
                </i>
              </a>
            </li>
          ))}
        </ul>
        <br />
        <div className="col-12">
          <div className="bottom-button">
            <button
              className="cancelBtn"
              type="button"
              onClick={handleSkillInfoCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={skillSubmit(handleSkillInfoInstert)}
              className="submitBtn themeButton"
            >
              {isSkillSubmit ? (
                <ClipLoader size={15} color="#FFFFFF" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </form>
    );
  };

  const handleSkillInfoCancel = () => {
    resetSkillForm();
    setSkillDetails({ modal: false, edit: false, skills: [] });
  };
  const handleSkillInfoDelete = (toolId) => {
    dispatch(
      deleteAdminDevSkills(navigate, { id: toolId }, { devId: userData?._id })
    );
  };

  const handleSkillInfoInstert = (data) => {
    console.log("skillDetail", data);
    data?.hasOwnProperty("skills") && delete data?.skills;
    if (skillDetail?.edit) {
      dispatch(
        updateAdminDevSkills(
          navigate,
          { devId: userData?._id },
          data,
          setSkillDetails,
          resetSkillForm
        )
      );
    } else {
      dispatch(
        saveAdminDevSkills(navigate, data, setSkillDetails, resetSkillForm)
      );
    }
  };

  const handleAcadContent = () => {
    return (
      <form>
        <div className="row">
          <div className="form-group col-6">
            <input
              {...academicRegister("institution", {
                required: true,
              })}
              type="text"
              className={`form-control col-12 ${academicError?.institution && "form-required"
                }`}
              placeholder="Name Of The Institute"
              name="institution"
            />
          </div>
          <div className="form-group col-6">
            <input
              {...academicRegister("specialization", {
                required: true,
              })}
              type="text"
              className={`form-control col-12 ${academicError?.specialization && "form-required"
                }`}
              placeholder="Specialization"
              name="specialization"
            />
          </div>
          <div
            className={`form-group col-6 ${academicError?.startDate && "date-required"
              }`}
          >
            <Controller
              control={academicControl}
              name="startDate"
              {...academicRegister("startDate", {
                required: true,
              })}
              render={({ field }) => (
                <ReactDatePicker
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                  placeholderText="Start Date"
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                />
              )}
            />
          </div>
          <div
            className={`form-group col-6 ${academicError?.endDate && "date-required"
              }`}
          >
            <Controller
              control={academicControl}
              name="endDate"
              {...academicRegister("endDate", {
                required: true,
              })}
              render={({ field }) => (
                <ReactDatePicker
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                  placeholderText="End Date"
                  dateFormat="MM/yyyy"
                  minDate={
                    getAcademicValue("startDate") &&
                    new Date(getAcademicValue("startDate"))
                  }
                  showMonthYearPicker
                />
              )}
            />
          </div>
        </div>
        <br />
        <br />
        <div className="col-12">
          <div className="bottom-button">
            <button
              className="cancelBtn"
              type="button"
              onClick={handleAcadmicInfoCancel}
            >
              Cancel
            </button>
            <button
              onClick={academicSubmit(checkAcadValid)}
              className="submitBtn themeButton"
            >
              {isSaveAcademic ? (
                <ClipLoader size={15} color="#FFFFFF" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </form>
    );
  };

  const [socialObject, setSocialObject] = useState({
    id: "",
    gitHub: "",
    linkedin: "",
    facebook: "",
    twitter: "",
  });

  useEffect(() => {
    if (adminDevSocialProfileData) {
      setSocialObject({
        id: adminDevSocialProfileData?._id,
        gitHub: adminDevSocialProfileData?.gitHub,
        linkedin: adminDevSocialProfileData?.linkedin,
        facebook: adminDevSocialProfileData?.facebook,
        twitter: adminDevSocialProfileData?.twitter,
      });
    } else {
      setSocialObject({
        id: "",
        gitHub: "",
        linkedin: "",
        facebook: "",
        twitter: "",
      });
    }
  }, [adminDevSocialProfileData]);

  useEffect(() => {
    if (openAiSdtEnglish?.data?.length) {
      setContent({ ...content, improved: openAiSdtEnglish?.data });
    }
  }, [openAiSdtEnglish]);

  const [openAiModalOpen, setOpenAiModalOpen] = useState(false);
  const closeSuggestionBox = () => {
    setOpenAiModalOpen(false);
    resetChatGPTForm();
    setContent({});
  };
  const StoryImprovementFunc = () => {
    switch (content?.contentName) {
      case "tellYourStory":
        setDevShortDesc({ devTellUrStory: content?.improved });
        dispatch(
          updateAdminDevShortDesciption(
            navigate,
            { devId: userData?._id },
            { devTellUrStory: content?.improved },
            closeSuggestionBox
          )
        );

        break;
      case "project":
        setProjectValue("description", content?.improved);
        closeSuggestionBox();
        break;
      case "employement":
        setEmplmntValue("aboutRole", content?.improved);
        closeSuggestionBox();
        break;
    }
  };

  const openAiSuggestionContent = () => {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label
                className={`floating-label ${chatGPTError?.chattGptValue && "text-danger"
                  }`}
              >
                Select *
              </label>
              <Controller
                control={chatGPTControl}
                name="chattGptValue"
                {...chatGPTRegister("chattGptValue", {
                  required: true,
                })}
                render={({ field }) => (
                  <Select
                    styles={{ zIndex: 11111 }}
                    devListLoading={false}
                    value={
                      getChatGPTValue("chattGptValue")
                        ? getChatGPTValue("chattGptValue")
                        : []
                    }
                    options={[
                      {
                        label: "Improve Clarity",
                        value: "Improve the flow and clarity:",
                      },
                      {
                        label: "Improve Clarity. Results in bullets points",
                        value:
                          "Improve the flow and clarity.create sentence lists in  bullets(o) points:",
                      },
                    ]}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChatGPT(e.value);
                    }}
                  />
                )}
              />
            </div>
            {stdEngLoading ? (
              <div className="form-group text-center">
                <ClipLoader size={15} color="#3ba0aa" />
              </div>
            ) : (
              content?.improved && (
                <div className="form-group">
                  <label className="floating-label" style={{ zIndex: 0 }}>
                    OPEN AI
                  </label>

                  <textarea
                    rows={6}
                    className="form-control"
                    value={content?.improved}
                  />
                </div>
              )
            )}
          </div>
        </div>
      </>
    );
  };

  const [toolTipOpen, setToolTipOpen] = useState(false);
  const [toolTipContent, setToolTipContent] = useState({});

  const closeToolTip = () => {
    setToolTipOpen(false);
    setToolTipContent({});
  };
  const openTheToolTip = (content) => {
    setToolTipOpen(true);
    setToolTipContent(content);
  };

  const uploadVideoRef = useRef();
  const [videoPercentage, setVideoPercentage] = useState(0);
  const onUploadIntroductoryVideo = (e) => {
    let formData = new FormData();
    formData.append("videoFile", e.target.files[0]);
    if (e.target.files[0]?.size > 150 * 1024 * 1024) {
      olibrToast.error("Please use video of size less than 150MB");
    } else {
      console.log("userId deevId", { devId: userData?._id });
      dispatch(
        uploadVideoAction(formData, navigate, setVideoPercentage, {
          devId: userData?._id,
        })
      );
    }
    e.target.value = null;
  };
  const [frameOpen, setFrameOpen] = useState(false);

  const handleFrameContent = () => {
    return (
      <Fragment>
        {frameOpen && (
          <iframe src={devVideo} width="100%" height="500"></iframe>
        )}
      </Fragment>
    );
  };
  const closeFrameModal = () => {
    setFrameOpen(false);
  };

  const openVideoPreview = () => {
    if (!!devVideo) {
      setFrameOpen(true);
    } else {
      olibrToast.error("No Preview Available!");
    }
  };

  const uploadVideoLogic = () => {
    uploadVideoRef.current.click();
  };

  return (
    <Fragment>
      <Tooltip
        title={toolTipContent?.title}
        tooltipText={toolTipContent?.content}
        open={toolTipOpen}
        onClose={closeToolTip}
      />
      { isProfileView ? (
        <Fragment>
          {userData?._id && (
            <ProfilePreviewNew
              developerId={userData?._id}
              onClose={() => {
                setProfileView(false);
              }}
            />
          )}
        </Fragment>
      ) : (
        <Fragment>
          <form>
            <PercentageLoaderContent
              uploadLoading={uploadVideoLoad}
              uploadPercentage={videoPercentage}
            />
            <Modal
              size="100%"
              open={frameOpen}
              onClose={closeFrameModal}
              content={handleFrameContent}
              isFooterView={false}
              title={"Introductory Video"}
            />
            <Modal
              open={openAiModalOpen}
              title={"Improve Content"}
              onClose={closeSuggestionBox}
              onCancel={closeSuggestionBox}
              onDone={StoryImprovementFunc}
              content={openAiSuggestionContent}
            />
            <div className="create-profile">
              <input
                ref={uploadVideoRef}
                type="file"
                onChange={onUploadIntroductoryVideo}
                className="input-file"
                accept=".mp4, .webm, .mkv, .mov"
              />
              <input
                ref={uploadImgRef}
                type="file"
                onChange={onUploadProfileImage}
                className="input-file"
                accept=".png, .jpeg, .jpg"
              />
              <input
                ref={uploadProjectImageRef}
                type="file"
                onChange={onUploadProjectImage}
                className="input-file"
                accept=".png, .jpeg, .jpg"
                multiple
              />

              <div className="user-profile-block">
                <div className="user-img">
                  {isAdminDevProFileLoad ? (
                    <div className="img-loader">
                      <ClipLoader color="#3ba0aa" />
                    </div>
                  ) : (
                    <>
                      {devIsFresher && (
                        <img
                          src={FresherTag}
                          alt="fresher"
                          className="fresher"
                        />
                      )}
                      <img
                        src={userImage ? userImage : UserImage}
                        alt="Profile image"
                      />
                    </>
                  )}
                  <div className="upload-user">
                    <figure>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (checkPermission(readOnlyCheck(access)))
                            return false;
                          uploadImgRef.current.click();
                        }}
                      >
                        <Icon name="photo-camera" />
                      </span>
                      {userImage && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (checkPermission(readOnlyCheck(access)))
                              return false;
                            setDeleteFunction({
                              functionName: "profileimage",
                              parameters: userData,
                            });
                            DeletePreview();
                          }}
                        >
                          <Tooltips content="Delete" direction="top">
                            <Icon name="delete" />
                          </Tooltips>
                        </span>
                      )}
                    </figure>
                  </div>
                </div>
                <div className="user-detail">
                  <div className="col-6">
                    <Modal
                      open={guideLine}
                      title={"Profile Photo Guidelines"}
                      onClose={closeGuideLine}
                      content={() => <PhotoGuideline />}
                      isFooterView={false}
                    />
                  </div>
                  <div className="col-6">
                    <strong>Profile Status: </strong>{" "}
                    <span
                      style={{
                        color:
                          approvalStatus == "approved" ? "#679B31" : "#F15C3B",
                      }}
                    >
                      {String(approvalStatus).toUpperCase()}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <span
                  className="photo-guideline"
                  onClick={() => openGuideLine()}
                >
                  Photo Guideline
                </span>
                <br />
                <br />
              </div>
              <div className="block-header">
                <h4>
                  Introductory Video
                  {/* <span onClick={() => openTheToolTip(tellYourStoryTip)}> */}
                  {/* <Icon name="photo-camera" /> */}
                  {/* </span> */}
                </h4>
              </div>
              <div>
                Upload Your Introductory video{" "}
                <span className="link" onClick={uploadVideoLogic}>
                  Click here
                </span>
                <br />
                <span className="link" onClick={openVideoPreview}>
                  PREVIEW
                </span>
              </div>
              <div className="block-header">
                <h4>
                  Tell your story
                  <span onClick={() => openTheToolTip(tellYourStoryTip)}>
                    <Icon name="info-button" />
                  </span>
                </h4>
              </div>
              <textarea
                rows={10}
                placeholder={storyDummy}
                className={`${!devShortDesc?.devTellUrStory && "form-required"
                  }`}
                onChange={(e) =>
                  setDevShortDesc({ devTellUrStory: e.target.value })
                }
                value={devShortDesc?.devTellUrStory}
              />
              <div className="text-right">
                {/* <span
                  className="tellIcon"
                  style={{
                    textAlign: "right",
                    textTransform: "uppercase",
                    color: "#3ba0aa",
                    fontWeight: "500",
                    marginRight: "10px",
                    marginTop: "10px",
                  }}
                  title="Update Tell Your Story"
                  onClick={() => {
                    devShortDesc?.devTellUrStory && postResume();
                  }}
                > */}
                <button
                  type="button"
                  title="Update Tell Your Story"
                  onClick={() => {
                    if (checkPermission(readOnlyCheck(access))) return false;
                    devShortDesc?.devTellUrStory && postResume();
                  }}
                  className="submitBtn"
                  style={{ marginTop: "10px" }}
                >
                  Save
                </button>

                {/* </span> */}
              </div>
            </div>
            <div className="create-profile">
              <div className="block-header">
                <h4>
                  Education
                  <span
                    onClick={() => {
                      openTheToolTip(educationTip);
                    }}
                  >
                    <Icon name="info-button" />
                  </span>
                </h4>
                <a
                  className="link-circle"
                  onClick={() => {
                    if (checkPermission(readOnlyCheck(access))) return false;
                    handleAcadmicInfoAdd();
                  }}
                >
                  <Icon name="add-icon" />
                </a>
              </div>
              <Modal
                open={acadmicDetail?.modal}
                title={
                  acadmicDetail?.edit
                    ? "Edit Academic Information"
                    : "Add Academic Information"
                }
                onClose={handleAcadmicInfoCancel}
                content={handleAcadContent}
                onCancel={handleAcadmicInfoCancel}
                isFooterView={false}
              />
              <div
                style={{
                  display: adminDevAcademic?.length == 0 ? "none" : "block",
                }}
              >
                <table className="step-table">
                  <thead>
                    <tr>
                      <th>Name of The Institution</th>
                      <th>Specialization</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {adminDevAcademic?.map(
                      (devAcad, index) =>
                        (devAcad?.institution || devAcad?.specialization) && (
                          <tr key={index + Math.random()}>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={devAcad.institution}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={devAcad.specialization}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={formDateDateYear(devAcad.startDate)}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={formDateDateYear(devAcad.endDate)}
                                disabled
                              />
                            </td>
                            <td>
                              <span style={{ display: "flex" }}>
                                <a
                                  onClick={() => {
                                    if (checkPermission(readOnlyCheck(access)))
                                      return false;
                                    editAcadInfo(devAcad);
                                  }}
                                >
                                  <Icon name="edit" />
                                </a>
                                <a
                                  style={{ marginLeft: "5px" }}
                                  onClick={() => {
                                    if (checkPermission(readOnlyCheck(access)))
                                      return false;
                                    setDeleteFunction({
                                      functionName: "handleAcademicInfoDelete",
                                      parameters: devAcad?._id,
                                    });
                                    DeletePreview();
                                  }}
                                >
                                  <Icon name="delete" />
                                </a>
                              </span>
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <Modal
              open={isPreview}
              title={"Are you sure?"}
              onClose={DeletePreview}
              onDelete={() => deleteConfirmation()}
              content={() => (
                <p>Are you sure you want to delete developer profile image?.</p>
              )}
              onCancel={() => setPreview(!isPreview)}
              confirmDelete={true}
              isFooterView={true}
            />

            <div className="create-profile">
              <div className="block-header">
                <h4>
                  Employment Information
                  <span onClick={() => openTheToolTip(employementTip)}>
                    <Icon name="info-button" />
                  </span>
                </h4>
                <a
                  onClick={(e) => {
                    if (checkPermission(readOnlyCheck(access))) return false;
                    openEmpInfoModal();
                  }}
                  className="link-circle"
                >
                  <Icon name="add-icon" />
                </a>
              </div>
              <Modal
                open={empDetails?.modal}
                title={`${empDetails?.edit ? "Edit" : "Add"
                  } Employment Information`}
                onClose={handleEmpInfoCancel}
                content={handleEmpContent}
                onCancel={handleEmpInfoCancel}
                isFooterView={false}
              />

              <div className="timeline">
                {adminDevEmployementeData?.map((empInfo, index) => {
                  return(
                    <form className="timeline-form">
                      <div className="timeline-event">
                        <span className="timeline-dot"></span>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h4 className="timeline-title">
                            {empInfo?.designation}
                          </h4>
                          <div className="row">
                            <a
                              className=""
                              onClick={() => {
                                if (checkPermission(readOnlyCheck(access)))
                                  return false;
                                editEmploymentInfo(empInfo, index);
                              }}
                            >
                              <Icon name="edit" />
                            </a>
                            <a
                              className=""
                              style={{ marginLeft: "5px" }}
                              onClick={() => {
                                if (checkPermission(readOnlyCheck(access)))
                                  return false;
                                setDeleteFunction({
                                  functionName: "handleEmpInfoDelete",
                                  parameters: empInfo?._id,
                                });
                                DeletePreview();
                              }}
                            >
                              <Icon name="delete" />
                            </a>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <span className="timeline-subtitle">
                              {empInfo?.companyName}
                            </span>
                          </div>
                          <div className="col-6 text-right">
                            <span className="timeline-subtitle text-right">
                              {formDateDateYear(empInfo?.from)}
                              {" - "}
                              {empInfo?.stillWorking
                                ? "PRESENT"
                                : formDateDateYear(empInfo?.to)}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-2">
                            {empInfo?.techUsed?.length != 0 && (
                              <span>Technologies</span>
                            )}
                          </div>
                          <div className="col-10">
                            <ul className="icon-tags-finalize">
                              {empInfo?.techUsed?.map((item) => (
                                <li>
                                  <a>
                                    <img src={item?.value} alt="" />{" "}
                                    {item?.label}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="col-2">
                            {empInfo?.toolUsed?.length != 0 && (
                              <span>Tools</span>
                            )}
                          </div>
                          <div className="col-10">
                            <ul className="icon-tags-finalize">
                              {empInfo?.toolUsed?.map((item) => (
                                <li>
                                  <a>
                                    <img src={item?.value} alt="" />{" "}
                                    {item?.label}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div
                          className={`fullHeight-perm ${fullRead[index] ? "" : "fullHeight"
                            }`}
                          dangerouslySetInnerHTML={{
                            __html: empInfo?.aboutRole,
                          }}
                        ></div>
                        {empInfo?.aboutRole?.length >= 300 && (
                          <a
                            className="read-more"
                            onClick={() => {
                              const temp = { ...fullRead };
                              temp[index] = !temp[index];
                              setFullRead(temp);
                            }}
                          >
                            {fullRead[index] ? "less" : "more"}
                          </a>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="block-sub-heading">
                            <h5 className="">
                              {adminDevProjectData?.filter(
                                (proItem, index) =>
                                  (proItem?.devEmpId &&
                                    proItem?.devEmpId === empInfo._id) ||
                                  (proItem?.uniKey &&
                                    proItem?.uniKey === empInfo.uniKey)
                              ).length == 0
                                ? ""
                                : "Projects"}
                            </h5>
                            <a
                              className="link"
                              onClick={(e) => {
                                if (checkPermission(readOnlyCheck(access)))
                                  return false;
                                openProjectModal(empInfo);
                              }}
                            >
                              Add Project <Icon name="arrow-right" />
                            </a>
                          </div>
                          <div className="project-blocks">
                            {adminDevProjectData?.map(
                              (proItem, index) =>
                                // <div className="card-wrap">
                                proItem?.empId === empInfo._id && (
                                  <>
                                    <div className="project-block">
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <h5 style={{ display: "flex" }}>
                                          {proItem?.name}
                                        </h5>
                                        <span style={{ display: "flex" }}>
                                          <a
                                            onClick={() => {
                                              if (
                                                checkPermission(
                                                  readOnlyCheck(access)
                                                )
                                              )
                                                return false;
                                              handleProjectDetailsEdit(proItem);
                                            }}
                                          >
                                            <Icon name="edit" />
                                          </a>
                                          <a
                                            style={{ marginLeft: "5px" }}
                                            onClick={() => {
                                              if (
                                                checkPermission(
                                                  readOnlyCheck(access)
                                                )
                                              )
                                                return false;
                                              setDeleteFunction({
                                                functionName:
                                                  "handleProjectInfoDelete",
                                                parameters: proItem?._id,
                                              });
                                              DeletePreview();
                                            }}
                                          >
                                            <Icon name="delete" />
                                          </a>
                                        </span>
                                      </div>
                                      <span
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {proItem?.url}
                                      </span>
                                      <div
                                        className="project-desc-fixed"
                                        dangerouslySetInnerHTML={{
                                          __html: proItem?.description,
                                        }}
                                      ></div>
                                      <ul className="icon-tags-finalize">
                                        {proItem?.skills?.map((devSkill) => (
                                          <li
                                            style={{
                                              display: "inline-block",
                                              gap: "8px",
                                            }}
                                          >
                                            <a style={{ display: "flex" }}>
                                              {devSkill}
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                      {/* <div className="attached">
                                        <i></i>
                                        <span>{`Attached ${
                                          proItem?.devProjectImage?.length
                                            ? proItem?.devProjectImage?.length
                                            : 0
                                        } screenshots`}</span>
                                      </div> */}
                                    </div>
                                  </>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  ) 
                })}
                <Modal
                  open={devProjectDetails?.modal}
                  title={` ${devProjectDetails?.edit ? "Edit" : "Add"} Project`}
                  onClose={projectInfoModalClose}
                  content={handleProjectContent}
                  onCancel={projectInfoModalClose}
                  isFooterView={false}
                />
              </div>
            </div>
            <AdditionalProject
                userData={userData}
            />
            <div className="create-profile">
              <div className="block-header">
                <h4>
                  Certifications
                  <span onClick={() => openTheToolTip(certificationsTip)}>
                    <Icon name="info-button" />
                  </span>
                </h4>
                <a
                  className="link-circle"
                  onClick={() => {
                    if (checkPermission(readOnlyCheck(access))) return false;
                    handleCertInfoAdd();
                  }}
                >
                  <Icon name="add-icon" />
                </a>
              </div>
              <Modal
                open={certDetails?.modal}
                title={
                  certDetails.edit ? "Edit Certificate" : "Add Certificate"
                }
                onClose={handleCertInfoCancel}
                content={handleCertContent}
                onCancel={handleCertInfoCancel}
                isFooterView={false}
              />

              <div
                className=""
                style={{
                  display:
                    adminDevCertification?.length == 0 ? "none" : "block",
                }}
              >
                <table className="step-table">
                  <thead>
                    <tr>
                      <th>Certificate Name</th>
                      <th>Institution</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {adminDevCertification?.map(
                      (devCert, index) =>
                        devCert?.certificateName && (
                          <tr key={index + Math.random()}>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={devCert?.certificateName}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={devCert?.certInstitution}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={formDateDateYear(devCert?.startDate)}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={formDateDateYear(devCert?.endDate)}
                                disabled
                              />
                            </td>
                            <td>
                              <span style={{ display: "flex" }}>
                                <a
                                  onClick={() => {
                                    if (checkPermission(readOnlyCheck(access)))
                                      return false;
                                    editCertInfo(devCert);
                                  }}
                                >
                                  <Icon name="edit" />
                                </a>
                                <a
                                  style={{ marginLeft: "5px" }}
                                  onClick={() => {
                                    if (checkPermission(readOnlyCheck(access)))
                                      return false;
                                    setDeleteFunction({
                                      functionName: "handleCertInfoDelete",
                                      parameters: devCert?._id,
                                    });
                                    DeletePreview();
                                  }}
                                >
                                  <Icon name="delete" />
                                </a>
                              </span>
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <Modal
              open={toolDetails?.modal}
              title={toolDetails?.edit ? `Edit Tool` : `Add Tool`}
              onClose={handleToolInfoCancel}
              content={handleToolContent}
              onCancel={handleToolInfoCancel}
              isFooterView={false}
            />
            <Modal
              open={skillDetail?.modal}
              title={skillDetail.edit ? `Edit Skill` : `Add Skill`}
              onClose={handleSkillInfoCancel}
              content={handleSkillContent}
              onCancel={handleSkillInfoCancel}
              isFooterView={false}
            />
            <div className="create-profile">
              <div className="row">
                {/* <div className="col-6"> */}
                  <>
                    {/* <div className="block-header small">
                      <h4>
                        Skills
                        <span onClick={() => openTheToolTip(skillsTip)}>
                          <Icon name="info-button" />
                        </span>
                      </h4>
                      <a
                        className="link-circle"
                        onClick={() => {
                          if (checkPermission(readOnlyCheck(access)))
                            return false;
                          handleSkillInfoAdd();
                        }}
                      >
                        <Icon name="add-icon" />
                      </a>
                    </div> */}

                    {
                      // <>
                      //   <div className="skill-table">
                      //     {adminChooseSkill?.length !== 0 &&
                      //       adminChooseSkill?.map((item, index) => (
                      //         <>
                      //           <div
                      //             style={{
                      //               display: "flex",
                      //               justifyContent: "space-between",
                      //             }}
                      //           >
                      //             <div className="tech-skill-title">
                      //               <strong>{item?.title}</strong>
                      //             </div>
                      //             <div className="">
                      //               <a
                      //                 className=""
                      //                 onClick={() => {
                      //                   if (
                      //                     checkPermission(readOnlyCheck(access))
                      //                   )
                      //                     return false;
                      //                   editSkillInfo(item);
                      //                 }}
                      //               >
                      //                 <Icon name="edit" />
                      //               </a>
                      //               <a
                      //                 className=""
                      //                 style={{ marginLeft: "5px" }}
                      //                 onClick={() => {
                      //                   if (
                      //                     checkPermission(readOnlyCheck(access))
                      //                   )
                      //                     return false;
                      //                   setDeleteFunction({
                      //                     functionName: "handleSkillInfoDelete",
                      //                     parameters: item?._id,
                      //                   });
                      //                   DeletePreview();
                      //                 }}
                      //               >
                      //                 <Icon name="delete" />
                      //               </a>
                      //             </div>
                      //           </div>
                      //           <ul className="tags tags-fill">
                      //             {item?.tagArr?.map((devSkill) => (
                      //               <li>
                      //                 <a>{devSkill}</a>
                      //               </li>
                      //             ))}
                      //           </ul>
                      //         </>
                      //       ))}
                      //   </div>
                      // </>
                    }
                  </>
                {/* </div> */}
                <div className="col-12">
                  <>
                    <div className="block-header small">
                      <h4>
                        Tools
                        <span onClick={() => openTheToolTip(devToolsTip)}>
                          <Icon name="info-button" />
                        </span>
                      </h4>
                      <a
                        className="link-circle"
                        onClick={() => {
                          if (checkPermission(readOnlyCheck(access)))
                            return false;
                          handleToolInfoAdd();
                        }}
                      >
                        <Icon name="add-icon" />
                      </a>
                    </div>
                    {adminDevToolData?.length !== 0 && (
                      <div className="skill-table">
                        <table
                          width="100%"
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <tbody>
                            {adminDevToolData?.map(
                              (toolItem, index) =>
                                toolItem?.toolName && (
                                  <tr>
                                    <td>
                                      <span className="align-middle">
                                        <span style={{ display: "flex" }}>
                                          <img
                                            src={toolItem?.toolIcon}
                                            alt=""
                                            width={20}
                                          />
                                          &nbsp; {toolItem?.toolName}
                                        </span>
                                      </span>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                    </td>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <a
                                          onClick={() => {
                                            if (
                                              checkPermission(
                                                readOnlyCheck(access)
                                              )
                                            )
                                              return false;
                                            editToolInfo(toolItem);
                                          }}
                                        >
                                          <Icon name="edit" />
                                        </a>
                                        <a
                                          style={{ marginLeft: "5px" }}
                                          onClick={() => {
                                            if (
                                              checkPermission(
                                                readOnlyCheck(access)
                                              )
                                            )
                                              return false;
                                            setDeleteFunction({
                                              functionName:
                                                "handleToolInfoDelete",
                                              parameters: toolItem?._id,
                                            });
                                            DeletePreview();
                                          }}
                                        >
                                          <Icon name="delete" />
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                )
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>

            <div className="create-profile">
              {/* <form> */}
              <div className="row">
                <div className="col-6">
                  <div className="block-header">
                    <h4>
                      Social Profiles
                      <span onClick={() => openTheToolTip(socialProfileTip)}>
                        <Icon name="info-button" />
                      </span>
                    </h4>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label className="floating-label" htmlFor="">
                          GitHub
                        </label>
                        <input
                          type="url"
                          className="form-control"
                          value={socialObject?.gitHub}
                          disabled={readOnlyCheck(access)}
                          onChange={(e) =>
                            setSocialObject({
                              ...socialObject,
                              gitHub: e.target.value,
                            })
                          }
                          onBlur={() =>
                            socialObject?.gitHub &&
                            socialLinkValid("gitHub", socialObject?.gitHub)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="floating-label" htmlFor="">
                          LinkedIn
                        </label>
                        <input
                          type="url"
                          className="form-control"
                          disabled={readOnlyCheck(access)}
                          value={socialObject?.linkedin}
                          onChange={(e) =>
                            setSocialObject({
                              ...socialObject,
                              linkedin: e.target.value,
                            })
                          }
                          onBlur={() =>
                            socialObject?.linkedin &&
                            socialLinkValid("linkedin", socialObject?.linkedin)
                          }
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <label className="floating-label" htmlFor="">
                          Twitter
                        </label>
                        <input
                          type="url"
                          className="form-control"
                          disabled={readOnlyCheck(access)}
                          value={socialObject?.twitter}
                          onChange={(e) =>
                            setSocialObject({
                              ...socialObject,
                              twitter: e.target.value,
                            })
                          }
                          onBlur={() =>
                            socialObject?.twitter &&
                            socialLinkValid("twitter", socialObject?.twitter)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="floating-label" htmlFor="">
                          Facebook
                        </label>
                        <input
                          type="url"
                          disabled={readOnlyCheck(access)}
                          className="form-control"
                          value={socialObject?.facebook}
                          onChange={(e) =>
                            setSocialObject({
                              ...socialObject,
                              facebook: e.target.value,
                            })
                          }
                          onBlur={() =>
                            socialObject?.facebook &&
                            socialLinkValid("facebook", socialObject?.facebook)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="block-header">
                    <h4>
                      Achievements
                      <span onClick={() => openTheToolTip(devAChievementTip)}>
                        <Icon name="info-button" />
                      </span>
                    </h4>
                    <a
                      className="link-circle"
                      onClick={() => {
                        if (checkPermission(readOnlyCheck(access)))
                          return false;
                        handleAchievementInfoAdd();
                      }}
                    >
                      <Icon name="add-icon" />
                    </a>
                  </div>
                  <div className="skill-table">
                    {/* <h5>Others</h5> */}
                    {adminDevAchievementData?.map((item, index) => (
                      <div className="row">
                        <div className="col-9 tech-skill-title">
                          <ul className="list-text-new">
                            <li>
                              <span>{item?.title}</span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-3 text-right">
                          <a
                            className=""
                            onClick={() => {
                              if (checkPermission(readOnlyCheck(access)))
                                return false;
                              editAchievementInfo(item);
                            }}
                          >
                            <Icon name="edit" />
                          </a>
                          <a
                            className=""
                            style={{ marginLeft: "5px" }}
                            onClick={() => {
                              if (checkPermission(readOnlyCheck(access)))
                                return false;
                              setDeleteFunction({
                                functionName: "handleAchievementInfoDelete",
                                parameters: item?._id,
                              });
                              DeletePreview();
                            }}
                          >
                            <Icon name="delete" />
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <Modal
                  open={achievementDetails?.modal}
                  title={
                    achievementDetails.edit
                      ? "Edit Achievement"
                      : "Add Achievement"
                  }
                  onClose={handleAchievementInfoCancel}
                  content={handleAchContent}
                  onCancel={handleAchievementInfoCancel}
                  isFooterView={false}
                />
              </div>
              {/* </form> */}
            </div>
            {resultResp?.length != 0 && (
              <div className="create-profile">
                <div className="row">
                  <div className="col-12">
                    <>
                      <div className="block-header small">
                        <h4>Vetted Skills</h4>
                      </div>
                      <p>You have cleared following skills</p>
                      <div className="vetted-skills">
                        {resultResp?.map((resultItem) => (
                          <div className="vetted-skill">
                            <span className="badge">
                              <img src={BadgeIcon} alt="Badge" />
                            </span>
                            <h5>{resultItem?.testName}</h5>
                            <div className="skill-graph">
                              <div className="single-chart">
                                <svg
                                  viewBox="0 0 36 36"
                                  className={`circular-chart ${resultItem?.isPassed ? "green" : "red"
                                    }`}
                                >
                                  <path
                                    className="circle-bg"
                                    d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                                  />
                                  <path
                                    className="circle"
                                    stroke-dasharray={`${parseInt(
                                      resultItem?.percentageScore
                                    )}, 100`}
                                    d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                                  />
                                  <text x="18" y="20.35" className="percentage">
                                    {parseInt(resultItem?.obtainedScore)}/
                                    {parseInt(resultItem?.maxScore)}
                                  </text>
                                </svg>
                              </div>
                            </div>
                            <span className="align-middle">
                              <strong>Time Taken:</strong>{" "}
                              <span>
                                { findSkillTime(
                                    resultItem?.testDuration||
                                      resultItem?.subject,
                                      skillList?.data,
                                      stackList?.data
                                    )}
                              </span>
                            </span>
                          </div>
                        ))}
                      </div>
                    </>
                  </div>
                </div>
              </div>
            )}
            <div className="navigation">
              <button
                onClick={() => dispatch(setBackTab())}
                className="link prev disabled"
              >
                <i className="arrow-left"></i> Back
              </button>
              <button
                onClick={() => setProfileView(true)}
                className="link prev"
              >
                Profile Preview
              </button>
            </div>
          </form>
        </Fragment>
      )}
    </Fragment>
  );
};

export default FinalResume;
