export const rateParams = {
  problemSolving: 0,
  leaderShip: 0,
  adaptability: 0,
  professionalism: 0,
  communication: 0,
  };

 export const ratingName = {
  problemSolving: "Problem Solving",
  leaderShip: "Leadership",
  adaptability: "Adaptability/Flexibility ",
  professionalism: "Professionalism",
  communication: "Communication",
  };