import { ScaleLoader, ClipLoader } from "react-spinners";
import { ReactComponent as PlusIcon } from "../../assets/img/icons/plus.svg";
import { ReactComponent as EditIcon } from "../../assets/img/icons/edit_icon.svg";
import { ReactComponent as ArrowRight } from "../../assets/img/icons/arrow-right.svg";
import { ReactComponent as PhotoCamera } from "../../assets/img/icons/photo-camera.svg";
import { ReactComponent as ModalClose } from "../../assets/img/icons/close.svg";
import { ReactComponent as DeleteIcon } from "../../assets/img/icons/delete_icon.svg";
import { ReactComponent as UploadIcon } from "../../assets/img/icons/cloud-arrow-down-solid.svg";
import Checked from "../../assets/img/icons/checked.svg";
// import { ReactComponent as CalendarIcon } from "../../assets/img/icons/calendar-outline.svg";
import Calendar from "./calendar";
import CalendarView from "./calendar-view";
import ListView from "./list-view";
// import { ReactComponent as StepOne } from "../../assets/img/step-1.svg";
// import { ReactComponent as StepTwo } from "../../assets/img/step-2.svg";
// import { ReactComponent as StepThree } from "../../assets/img/step-3.svg";
// import { ReactComponent as StepFour } from "../../assets/img/step-4.svg";
// import { ReactComponent as StepFive } from "../../assets/img/step-5.svg";
import TestImage from "../../assets/img/icons/english.webp";
import SuccessTest from "../../assets/img/icons/success-test.svg";
import FailTest from "../../assets/img/icons/fail-test.svg";
import clearedTest from "../../assets/img/icons/clearedTest.svg";
import FailedTest from "../../assets/img/icons/failedTest.svg";
import { ReactComponent as SearchIcon } from "../../assets/img/icons/search.svg";
import SearchIconSVG from "../../assets/img/icons/search.svg";
import CreateProfile from "../../assets/img/icons/user-filled.svg";
import Projector from "../../assets/img/icons/projector.svg";
import SkillTest from "../../assets/img/icons/skills.svg";
import UserIcon from "../../assets/img/icons/user.svg";
import { ReactComponent as Suitcase } from "../../assets/img/icons/suitcase.svg";
import { ReactComponent as ShoppingList } from "../../assets/img/icons/shopping-list.svg";
import Dashboard from "../../assets/img/icons/dashboard.svg";
import { ReactComponent as Briefcase } from "../../assets/img/icons/briefcase.svg";
import { ReactComponent as Approved } from "../../assets/img/icons/approved.svg";
import { ReactComponent as ThumbNail } from "../../assets/img/icons/thumb.svg";
import cancel from "../../assets/img/icons/cancel_icon.svg";
import PageNotFound from "../../assets/img/404-error.jpg";
import LogoFodorTravel from "../../assets/img/logos/fodors-travel.svg";
import LogoInternetBrands from "../../assets/img/logos/internet-brands.svg";
import LogoLawyers from "../../assets/img/logos/lawyers.svg";
import LogoOfficite from "../../assets/img/logos/officite.svg";
import LogoTherapySites from "../../assets/img/logos/therapysites.svg";
import LogoErgomotion from "../../assets/img/logos/ergomotions.svg";
import LogoOnline from "../../assets/img/logos/online-practice.png";
import OlibrLogo from "../../assets/img/logo.png";
import OlibrFavicon from "../../assets/img/favicon.png";
import AwsIcon from "../../assets/img/icons/aws-icon.svg";
import ContactList from "../../assets/img/icons/contact-list.png";
import ShortlistedProf from "../../assets/img/icons/shortlisted-prof.svg";
import JobClient from "../../assets/img/icons/jobs-client.svg";
import Settings from "../../assets/img/icons/settings.svg";
import searchProfile from "../../assets/img/icons/search-profile.svg";
import skillT from "../../assets/img/icons/skill-t.svg";
import MdLogo from "../../assets/img/clients/md.png";
import CarDirectLogo from "../../assets/img/clients/cardirect.png";
import FodorsTravelLogo from "../../assets/img/clients/fodors-travel.png";
import LogoPulsePoint from "../../assets/img/logos/pulsePoint.png";
import InternetBrandsLogo from "../../assets/img/clients/internet-brands.png";
import LawyersLogo from "../../assets/img/clients/lawyers.png";
import OfficiteLogo from "../../assets/img/clients/officite.png";
import Nolo from "../../assets/img/clients/nolo.png";
import PulsePoint from "../../assets/img/clients/pulsepoint.png";
import JobExp from "../../assets/img/icons/exp.svg";
import JobTime from "../../assets/img/icons/job-time.svg";
import ReFresh from "../../assets/img/icons/refresh.svg";
import RefreshRed from "../../assets/img/icons/refresh-red.svg";
import devJob from "../../assets/img/dev-job.png";

import FullStackIcon from "./../../assets/img/icons/fullstack.svg";
import DigitalIcon from "./../../assets/img/icons/digital.svg";
import DesignIcon from "./../../assets/img/icons/design.svg";
import BackendDeveloperIcon from "./../../assets/img/icons/backend-developer.svg";
import SoftwareDeveloperIcon from "./../../assets/img/icons/software-developer.svg";

import CreamOfCrop from "./../../assets/img/icons/cream-of-crop.svg";
import FullTimeWork from "./../../assets/img/icons/full-time-work.svg";
import IndustryExpert from "./../../assets/img/icons/industry-expert.svg";
import CommunicationSkills from "./../../assets/img/icons/communication-skills.svg";
import FlexibleWork from "./../../assets/img/icons/flexible-work.svg";
import SkillTestCard from "./../../assets/img/icons/skill-test.svg";

import WhyFullTime from "./../../assets/img/icons/full-time.svg";
import WhyProfile from "./../../assets/img/icons/profile.svg";
import WhyPerks from "./../../assets/img/icons/perks.svg";
import WhyRemoteLocation from "./../../assets/img/icons/remote.svg";
import WhyTopClient from "./../../assets/img/icons/clients.svg";
import whyCareer from "./../../assets/img/icons/career.svg";
import shareWhatsapp from "./../../assets/img/icons/share-whatsapp.svg";
import shareFacebook from "./../../assets/img/icons/share-facebook.svg";
import shareLinkedIn from "./../../assets/img/icons/share-linkedin.svg";
import shareInsta from "./../../assets/img/icons/share-instagram.svg";
import shareTwitter from "./../../assets/img/icons/share-twitter.svg";
import sharePintrest from "./../../assets/img/icons/pinterest-share.svg";
import disLikeIcon from "./../../assets/img/icons/like-icon.png";
import LikeIcon from "./../../assets/img/icons/dislike-icon.png";
import Improve from "./../../assets/img/icons/improve.png";
import Regenerate from "./../../assets/img/icons/ai.svg";
import IButton from "./../../assets/img/icons/info-tool.svg";
import CategoryBlog from "./../../assets/img/icons/categories-blog.png";
import DocBlog from "./../../assets/img/icons/document-blog.png";
import olibrHelps from "./../../assets/img/icons/olibr-helps.png";
import shareProfile from "./../../assets/img/icons/share-profile.svg";
import updateFinIcon from "./../../assets/img/icons/update-final.png";
import tourIcon from "./../../assets/img/icons/browser.png";
import bellIcon from "./../../assets/img/icons/bell.png";
import refresh from "./../../assets/img/icons/refresh.png";
import rightArrowWhite from "./../../assets/img/icons/right-arrow-white.png";
import IBLOGO from "./../../assets/img/logos/IBlogo.png";
import WEBMD from "./../../assets/img/logos/webmd.png";
import CARSDIRECT from "./../../assets/img/logos/carsdirect.png";
import FODORS from "./../../assets/img/logos/fodTravels.png";
import LAWYERS from "./../../assets/img/logos/lawyers.png";
import NOLO from "./../../assets/img/logos/nolo.png";
import OFFICITELOGO from "./../../assets/img/logos/officiteLogo.png";
import PULSEPOINT from "./../../assets/img/logos/pulsePoint.png";

import angularjob from "./../../assets/img/logos/angularjob.png";
import awsjob from "./../../assets/img/logos/awsjob.png";
import cppjob from "./../../assets/img/logos/cppjob.png";
import kubernetesjob from "./../../assets/img/logos/kubernetesjob.png";
import nextjsjob from "./../../assets/img/logos/nextjsjob.png";
import nodejsjob from "./../../assets/img/logos/nodejsjob.png";
import pythonjob from "./../../assets/img/logos/pythonjob.png";
import reactjob from "./../../assets/img/logos/reactjob.png";
import tsjob from "./../../assets/img/logos/tsjob.png";
import tcsIcon from "./../../assets/img/logos/tcsIcon.png";
import popIcon from "./../../assets/img/logos/popIcon.png";
import jobAvatar from "./../../assets/img/jobavatar.png";
import Suitcase2 from "./../../assets/img/icons/suitcase.svg";
import SuitcaseNew from "./../../assets/img/icons/suitcase_new.svg";
import DirectApproval from "./../../assets/img/icons/direct-approval.svg";
import DirectEmailVerify from "./../../assets/img/icons/direct-email-verification.svg";
import DirectEnglishTestClear from "./../../assets/img/icons/english-test.svg";
import DirectSkillTestClear from "./../../assets/img/icons/skill-test-2.svg";

import ClientCompanyPro from "./../../assets/img/icons/client-company-profile.svg";
import ClientPostJob from "./../../assets/img/icons/client-post-job.svg";
import ClientSearchTal from "./../../assets/img/icons/client-search-talent.svg";
import ClientDash from "./../../assets/img/icons/client-dashboard.svg";
import StarJob from "./../../assets/img/icons/Star-job.svg";
import Excel from "./../../assets/img/icons/excel.svg";
import CopyLink from "./../../assets/img/icons/copy-link.svg";
import UpdateRequest from "./../../assets/img/icons/update-request.svg";
import ReviewStar from "./../../assets/img/icons/review-star.svg";
import Resend from "./../../assets/img/icons/resend.svg";
import Unsubscribe from "./../../assets/img/icons/Unsubscribed.svg";
import RatingDone from "./../../assets/img/icons/rating-done.svg";
import ReviewGraphic from "./../../assets/img/icons/review-graphic.svg";
import ProfileView from "./../../assets/img/icons/profile-view.png";
import DetailedPassed from "./../../assets/img/icons/DetailedPassed.svg";
import DetailedFailed from "./../../assets/img/icons/DetailedFailed.svg";
import Suspicious from "./../../assets/img/icons/suspicious.svg";
import IntroVideo from "./../../assets/img/icons/video.png";
import DownloadProfile from "./../../assets/img/icons/download-profile.svg";
import VideoIcon from "./../../assets/img/icons/video-icon.svg";
import VideoBanner from "./../../assets/img/icons/video-banner.svg";
import CandiIcon from "./../../assets/img/icons/candi-job.svg";
import OtpVerify from "./../../assets/img/icons/otp-verify.svg";
import otpVerified from "./../../assets/img/icons/otp-verified.svg";
import { ReactComponent as UserIconSvg } from "../../assets/img/icons/user-profile-icon.svg";
import { ReactComponent as SkillSvg } from "../../assets/img/icons/skill-t.svg";
import { ReactComponent as SaveJob } from "./../../assets/img/icons/job.svg";
import { ReactComponent as DevManagement } from "./../../assets/img/icons/dev-managment.svg";
import { ReactComponent as SkillManagement } from "./../../assets/img/icons/skill-management.svg";
import { ReactComponent as PerksBenifits } from "./../../assets/img/icons/perks-benifits.svg";
import { ReactComponent as UserManagement } from "./../../assets/img/icons/user-management.svg";
import { ReactComponent as ClientManagement } from "./../../assets/img/icons/client-management.svg";
import { ReactComponent as JobManagement } from "./../../assets/img/icons/job-management.svg";
import { ReactComponent as Faq } from "./../../assets/img/icons/faq.svg";
import { ReactComponent as EnglishTest } from "./../../assets/img/icons/english-test-new.svg";
import { ReactComponent as EmailAutumation } from "./../../assets/img/icons/email-autumation.svg";
import { ReactComponent as DirectUpdate } from "./../../assets/img/icons/direct-update.svg";
import { ReactComponent as CreateSkillTest } from "./../../assets/img/icons/create-skill-test.svg";
import { ReactComponent as MaintenanceMessage } from "./../../assets/img/icons/maintenance-message.svg";
import { ReactComponent as SpotlightHistory } from "./../../assets/img/icons/spotlight-history.svg";
import { ReactComponent as InterviewRequest } from "./../../assets/img/icons/interview-request.svg";
import { ReactComponent as InterviewList } from "./../../assets/img/icons/interview-list.svg";
import { ReactComponent as EmailHistory } from "./../../assets/img/icons/email-history.svg";
import { ReactComponent as Query } from "./../../assets/img/icons/query.svg";
import { ReactComponent as DashboardNew }  from "../../assets/img/icons/dashboard.svg";
import  { ReactComponent as ReviewIcon } from "./../../assets/img/icons/my-review.svg";
import { ReactComponent as VideoSidebar } from "./../../assets/img/icons/video-profile.svg";
import { ReactComponent as SaveProfile } from "./../../assets/img/icons/saved-profile.svg";
import { ReactComponent as Candidate } from "./../../assets/img/icons/candidate.svg";
import { ReactComponent as YourJob } from "./../../assets/img/icons/your-job.svg";
import { ReactComponent as DirectContact } from "./../../assets/img/icons/direct-contact.svg";
import { ReactComponent as SearchTalent } from "./../../assets/img/icons/search-talent.svg";
import { ReactComponent as BlockUser } from "./../../assets/img/icons/block-user.svg";

import { SVG } from "./SVG";
import { ReactComponent as NextArrow } from "./../../assets/img/icons/next.svg";
import { ReactComponent as PrevArrow } from "./../../assets/img/icons/prev.svg";
import Relocate  from "./../../assets/img/icons/relocate.svg";

import { ReactComponent as PlainCalendar } from "./../../assets/img/icons/plain-calendar.svg";

import { ReactComponent as AddUser }  from "../../assets/img/icons/make-admin.svg";
import { ReactComponent as CrossIcon }  from "../../assets/img/icons/crossmark.svg";
import { ReactComponent as CheckIcon }  from "../../assets/img/icons/checkmark.svg";
import { ReactComponent as EditUser }  from "../../assets/img/icons/edit-info.svg";
import { ReactComponent as ResendInvite }  from "../../assets/img/icons/resend-invite.svg";

import { ReactComponent as Subscription }  from "../../assets/img/icons/subscription.svg";
import { ReactComponent as PdfIcon }  from "../../assets/img/icons/pdf-icon.svg";

import HackerrankIcon from "../../assets/img/logos/hackerrank-logo.png";
import HackerearthIcon from "../../assets/img/logos/hackerearth-logo.png";
import HackerrankLogo from "../../assets/img/logos/hackerrank_logo.svg";
import HackerearthLogo from "../../assets/img/logos/hackerearth-full.png";
import HackerEarthTest from "../../assets/img/logos/hackerr_earth_test.png"
import ClockIcon  from "../../assets/img/icons/clock.png";

import LogoPartner1 from "../../assets/img/clients/1-1.png";
import LogoPartner2 from "../../assets/img/clients/1-2.png";
import LogoPartner3 from "../../assets/img/clients/1-3.png";
import LogoPartner4 from "../../assets/img/clients/1-4.png";
import LogoPartner5 from "../../assets/img/clients/1-5.png";
import LogoPartner6 from "../../assets/img/clients/1-6.png";
import LogoPartner7 from "../../assets/img/clients/1-7.png";

export const Icon = (props) => {
  switch (props.name) {
    case "relocate":
      return <img src={Relocate} height="13px"/>
    case "otp-verified":
      return <img src={otpVerified} alt="" />;
    case "otp-verify":
      return <img src={OtpVerify} alt="" />;
    case "direct-contact":
      return <DirectContact/>;
    case "search-talent":
      return <SearchTalent />;
    case "your-job":
      return <YourJob />;
    case "candidate":
      return <Candidate />;
    case "save-profile":
      return <SaveProfile />;
    case "video-sidebar":
      return <VideoSidebar />;
    case "review":
      return <ReviewIcon />;
    case "dashboard-icon":
      return  <DashboardNew/>;
    case "query":
      return <Query/>
    case "email-history":
      return <EmailHistory/>
    case "interview-list":
      return <InterviewList/>
    case "interview-request":
      return <InterviewRequest/>
    case "spotlight-history":
      return <SpotlightHistory/>
    case "maintenance-message":
      return <MaintenanceMessage/>
    case "create-skill-test":
      return <CreateSkillTest />
    case "direct-update":
      return <DirectUpdate/>
    case "email-autumation":
      return <EmailAutumation />
    case "english-test":
      return <EnglishTest/>
    case "faq":
      return <Faq/>
    case "job-management":
      return <JobManagement />
    case "client-management":
      return <ClientManagement />
    case "user-management":
      return <UserManagement />
    case "perks-benifits":
      return <PerksBenifits/>
    case "dev-management":
      return <DevManagement/>
    case "skill-management":
      return <SkillManagement/>
  
    case "save-job-icon":
      return <SaveJob />;
    case "skill-test-new":
      return <SkillSvg />;
    case "user-profile-icon":
      return <UserIconSvg />;
    case "copy-link":
      return <img src={CopyLink} />;
      
  
    case "intro-video-banner":
      return <img src={VideoBanner} />;
    case "download-profile":
      return (
        // <img
        //   src={DownloadProfile}
        //   style={{ verticalAlign: "middle" }}
        //   height={"20px"}
        //   width={"20px"}
        // />
        <img
          src={"https://ik.imagekit.io/olibr/icons/tr:w-20,h-20/download-profile.svg"}
          style={{ verticalAlign: "middle" }}
          height={"20px"}
          width={"20px"}
        />
      );
    case "video-icon":
      return (
        <img
          src={VideoIcon}
          style={{ verticalAlign: "middle" }}
          height={"20px"}
          width={"20px"}
        />
      );
    case "candi-icon":
      return <img src={CandiIcon} height={24} width={24}/>;
    case "detail-passed":
      return <img src={DetailedPassed} />;
    case "detail-failed":
      return <img src={DetailedFailed} />;
    case "suspicious":
      return <img src={Suspicious} />;
    case "intro-video":
      return <img src={IntroVideo} />;
    case "profile-view":
      return <img src={ProfileView} />;
    case "resend":
      return <img src={Resend} alt="" height={20} {...props} />;
    case "review-graphic":
      return <img src={ReviewGraphic} />;
    case "rating-done":
      return <img src={RatingDone} />;
    case "unsubs":
      return <img src={Unsubscribe} />;
    case "evaluation-toolkit":
      return <SVG url="https://ik.imagekit.io/olibr/icons/tr:w-15,h-15/skills-stacks.svg"/>;
    case "review-star":
      return <img src={ReviewStar} alt="" />;
    case "email-footer":
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-15,h-15/email-footer.svg" alt="" />;
    case "update-request":
      // return <img src={UpdateRequest} alt="" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-20,h-20/update-request.svg" alt="update request" />;
    case "client-postjob":
      return <img src={ClientPostJob} alt="" />;
    case "client-search-tal":
      return <img src={ClientSearchTal} alt="" />;
    case "client-dash":
      return <img src={ClientDash} alt="" />;
    case "comp-pro":
      return <img src={ClientCompanyPro} alt="" />;
    case "star-job":
      return <img src={StarJob} alt="" />;
    case "excel":
      return <img src={Excel} alt="" {...props} />;
    case "suitcase-new":
      // return <img src={SuitcaseNew} alt="" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-16,h-14/suitcase_new.svg" alt="" />;
    case "job-avatar":
      return <img src={jobAvatar} alt="" width={80} height={40} />;
    case "pop-icon":
      return <img src={popIcon} alt="" />;
    case "tcs-icon":
      return <img src={tcsIcon} alt="" />;
    case "angularjob":
      return <img src={angularjob} alt="" />;
    case "awsjob":
      return <img src={awsjob} alt="" />;
    case "cppjob":
      return <img src={cppjob} alt="" />;
    case "kubernetesjob":
      return <img src={kubernetesjob} alt="" />;
    case "nextjsjob":
      return <img src={nextjsjob} alt="" />;
    case "nodejsjob":
      return <img src={nodejsjob} alt="" />;
    case "pythonjob":
      return <img src={pythonjob} alt="" />;
    case "reactjob":
      return <img src={reactjob} alt="" />;
    case "tsjob":
      return <img src={tsjob} alt="" />;

    case "ibjob":
      return <img src={IBLOGO} alt="" />;
    case "webmdjob":
      return <img src={WEBMD} alt="" />;
    case "carsjob":
      return <img src={CARSDIRECT} alt="" />;
    case "fodorsjob":
      return <img src={FODORS} alt="" />;
    case "lawyersjob":
      return <img src={LAWYERS} alt="" />;
    case "nolojob":
      return <img src={NOLO} alt="" />;
    case "officitejob":
      return <img src={OFFICITELOGO} alt="" />;
    case "pulsejob":
      return <img src={PULSEPOINT} alt="" />;
    case "update-finalize-icon":
      return <img src={updateFinIcon} alt="" />;
    case "share-profile":
      return (
        // <img
        //   height={19}
        //   width={19}
        //   style={{ verticalAlign: "middle" }}
        //   src={shareProfile}
        //   alt=""
        // />
        <img
          height={19}
          width={19}
          style={{ verticalAlign: "middle" }}
          src={"https://ik.imagekit.io/olibr/icons/tr:w-19,h-19/share-profile.svg"}
          alt="share profile"
        />
      );
    case "olibr-helps":
      return <img src={olibrHelps} alt="" />;
    case "category-blog":
      return <img src={CategoryBlog} alt="" />;
    case "document-blog":
      return <img src={DocBlog} alt="" />;
    case "info-button":
      // return <img src={IButton} alt="" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-32,h-32/info-tool.svg" alt="info" />;
    case "imp-blog":
      return <img src={Improve} alt="" />;
    case "improve-icon":
      return <img width={"30px"} height={"30px"} src={Regenerate} alt="" />;
    case "dislike-icon":
      return <img width={48} height={48} src={disLikeIcon} alt="" />;
    case "like-icon":
      return <img width={48} height={48} src={LikeIcon} alt="" />;
    case "share-pintrest":
      return <img src={sharePintrest} alt="" />;
    case "share-insta":
      return <img src={shareInsta} alt="" />;
    case "share-facebook":
      return <img src={shareFacebook} alt="" />;
    case "share-whatsapp":
      return <img src={shareWhatsapp} alt="" />;
    case "share-linkedin":
      return <img src={shareLinkedIn} alt="" />;
    case "share-twitter":
      return <img src={shareTwitter} alt="" />;
    /* start here */
    case "cream-of-crop":
      // return <img src={CreamOfCrop} alt="" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/cream-of-crop.svg" alt="Cream of the crop" />;
    case "full-time-work":
      // return <img src={FullTimeWork} alt="" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/full-time-work.svg" alt="Full time commitment" />;
    case "industry-exp":
      // return <img src={IndustryExpert} alt="" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/industry-expert.svg" alt="Industry experts" />;
    case "commu-skills":
      // return <img src={CommunicationSkills} alt="" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/communication-skills.svg" alt="Good communication skills" />;
    case "flexi-hours":
      // return <img src={FlexibleWork} alt="" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/flexible-work.svg" alt="Working in tech field" />;
    case "coding-test":
      // return <img src={SkillTestCard} alt="" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/skill-test.svg" alt="Coding skill tests" />;
    /* till here */

    case "why-fulltime":
      // return <img src={WhyFullTime} alt="" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/full-time.svg" alt="Full time engagements" />;
    case "better-profile":
      // return <img src={WhyProfile} alt="" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/profile.svg" alt="Better profile visibility" />;
    case "best-perks":
      // return <img src={WhyPerks} alt="" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/perks.svg" alt="Premium pay and perks" />;
    case "remote-location":
      // return <img src={WhyRemoteLocation} alt="" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/remote.svg" alt="Experience exclusivity" />;
    case "top-clients":
      // return <img src={WhyTopClient} alt="" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/clients.svg" alt="Work with best clients" />;
    case "career-growth":
      // return <img src={whyCareer} alt="" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/career.svg" alt="Accelerate your career" />;

    case "revised-icon":
      return (
        <img
          src="https://ik.imagekit.io/olibr/icons/tr:w-480,h-546/Revised.webp"
          alt="We Are The Trendsetters Of The Remote Hiring Wave!"
          loading="lazy"
        />
      );
    case "free-trial":
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/free-trial.svg" alt="Two-week free trial period" width="60" height="60" />;
    case "job-posting-icon":
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/job-posting.svg" alt="Thoroughly vetted developers" width="60" height="60" />;
    case "inter-icon":
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/interview.svg" alt="Thoroughly vetted developers" width="60" height="60" />;
    case "developer-icon":
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/developer.svg" alt="Thoroughly vetted developers" width="60" height="60" />;
    case "fullstack-icon":
      // return <img src={FullStackIcon} alt="Full Stack Developer" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/fullstack.svg" alt="Full Stack Developer" width="60" height="60" />;
    case "digi-icon":
      // return <img src={DigitalIcon} alt="Digital Marketing Experts" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/digital.svg" alt="Digital Marketing Experts" width="60" height="60" />;
    case "software-icon":
      // return <img src={SoftwareDeveloperIcon} alt="Software Developers" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/software-developer.svg" alt="Software Developers" width="60" height="60" />;
    case "design-icon":
      // return <img src={DesignIcon} alt="Designers" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/design.svg" alt="Designers" width="60" height="60" />;
    case "backenddev-icon":
      // return <img src={BackendDeveloperIcon} alt="Designers" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/backend-developer.svg" alt="Designers" width="60" height="60" />;
    case "commu-icon":
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/commu.svg" alt="Vast developer community" width="60" height="60" />;
    case "provesucc-icon":
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/proven.svg" alt="Proven success of distributed teams" width="60" height="60" />;
    case "custsol-icon":
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/customize.svg" alt="Customized Solutions" width="60" height="60" />;
    case "quickscale-icon":
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/scale.svg" alt="Quick scalability" width="60" height="60" />;
    case "remoteon-icon":
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/support.svg" alt="Remote onboarding &amp; support" width="60" height="60" />;
    case "highret-icon":
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/higher.svg" alt="Higher retention" width="60" height="60" />;
    case "overlapus-icon":
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/time.svg" alt="Overlap with the US time" width="60" height="60" />;
    case "secandcomp-icon":
      return <img src={"https://ik.imagekit.io/olibr/icons/tr:w-60,h-60/lock.svg"} alt="Security &amp; Compliance " width="60" height="60" />;
    case "skill-t":
      return <img src={skillT} alt="" />;
    case "add-icon":
      // return <img src={add} alt="" width="30px" height="30px" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-30,h-30/add_icon.svg" alt="add icon" />;
    case "shortlisted-prof":
      return <img src={ShortlistedProf} alt="" />;
    case "job-client":
      return <img src={JobClient} alt="" />;
    case "plus":
      return <PlusIcon />;
    case "cancel-icon":
      return <img src={cancel} alt="cancel" />;
    case "settings":
      return <img src={Settings} alt="cancel" />;
    case "edit":
      return <EditIcon height={24} width={24} />;
    case "arrow-right":
      return <ArrowRight />;
      case "next-arrow":
      return <NextArrow  {...props}/>;
      case "prev-arrow":
      return <PrevArrow {...props}/>;
    case "photo-camera":
      return <PhotoCamera />;
    case "modal-close":
      return <ModalClose {...props} />;
    case "delete":
      return <DeleteIcon height={24} width={24} {...props} />;
    case "upload":
      return <UploadIcon />;
    case "loader":
      return <ScaleLoader color="#3BA0AA" />;
    case "clip-loader":
      return <ClipLoader color="#3BA0AA" />;
    case "calender":
      return <Calendar {...props} />;
    case "calender-view":
      return <CalendarView {...props} />;
    case "list-view":
      return <ListView {...props} />;
    // case "step-one":
    //   return <StepOne />;
    // case "step-two":
    //   return <StepTwo />;
    // case "step-three":
    //   return <StepThree />;
    // case "step-four":
    //   return <StepFour />;
    // case "step-five":
    //   return <StepFive />;
    case "block-user":
      return <BlockUser {...props} />
    case "test-image":
      return <img src={TestImage} alt="" />;
   
    case "search-profile":
      return <img src={searchProfile} alt="" />;
    case "success-test":
      return <img src={SuccessTest} alt="" />;
    case "fail-test":
      return <img src={FailTest} alt="" />;
    case "cleared-test":
      // return <img src={clearedTest} alt="" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-24,h-24/clearedTest.svg" alt="cleared test" />;
    case "failed-test":
      // return <img src={FailedTest} alt="" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-24,h-24/failedTest.svg" alt="failed test" />;
    case "create-prof":
      // return <img src={CreateProfile} alt="" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-16,h-16/user-filled.svg" alt="user profile icon" />;
    case "search-icon":
      return <SearchIcon />;
    case "search-icon-small":
      return <img src={SearchIconSVG} alt="" />;
    case "skill-dash":
      return <img src={SkillTest} alt="" />;
    case "projector":
      return <img src={Projector} alt="" />;
    case "check":
      return <img src={Checked} alt="" />;
    case "user-icon":
      return <img src={UserIcon} alt="" />;
    case "suitcase":
      return <Suitcase />;
    case "briefcase":
      return <Briefcase />;
    case "approved":
      return <Approved />;
    case "dashboard":
      return <img src={Dashboard} alt="" />;
    case "shopping-list":
      return <ShoppingList />;
    case "thumb-nail":
      return <ThumbNail />;
    case "error404":
      return <img src={PageNotFound} alt="" />;
    case "bridge-gap":
      return <img src="https://ik.imagekit.io/olibr/assets/tr:w-555,h-352/bridge-the-gap.svg" alt="Bridge the gap" />;
    case "what-olibr-do":
      return <img src="https://ik.imagekit.io/olibr/assets/tr:w-453,h-453/olibr-do.webp" alt="What Olibr do" />;
    case "how-does-work":
      return <img src="https://ik.imagekit.io/olibr/assets/tr:w-428,h-427/olibr-work.webp" alt="How Olibr works" />;
    case "developer":
      return <img src="https://ik.imagekit.io/olibr/assets/tr:w-77,h-77/developer.svg" alt="Developer" />;
    case "company":
      return <img src="https://ik.imagekit.io/olibr/assets/tr:w-84,h-79/company.svg" alt="Company" />;
    case "logo-trusted":
      return <img src={LogoPulsePoint} alt="" />;
    case "logo-ft":
      return <img src={LogoFodorTravel} alt="" />;
    case "logo-ib":
      return <img src={LogoInternetBrands} alt="" />;
    case "logo-erg":
      return <img src={LogoErgomotion} alt="" />;
    case "logo-lawyers":
      return <img src={LogoLawyers} alt="" />;
    case "logo-officite":
      return <img src={LogoOfficite} alt="" />;
    case "logo-therapy":
      return <img src={LogoTherapySites} alt="" />;
    case "logo-online":
      return <img src={LogoOnline} alt="" />;
    case "logo-olibr":
      return <img src={OlibrLogo} alt="Olibr Logo" />;
    case "login-network":
      // return <img src={LoginNetwork} alt="Login netwrok" />;
      return <img src="https://ik.imagekit.io/olibr/assets/tr:w-712,h-295/lo-si-left.webp" alt="global talent" />;
    case "olibr-favicon":
      return <img src={OlibrFavicon} alt="Login netwrok" />;
    case "aws-icon":
      return <img src={AwsIcon} alt="AWS Icon" />;
    case "shor-list":
      return <img src={ContactList} alt="Shortlisted Profile" />;
    case "logout":
      // return <img src={logoutPop} alt="" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-16,h-16/logoutPopup.svg" alt="logout popup icon" />;
    case "setting":
      // return <img src={settingPop} alt="" />;
      return <img src="https://ik.imagekit.io/olibr/icons/tr:w-16,h-16/settingPopup.svg" alt="setting popup icon" />;
    case "md-logo":
      return <img src={MdLogo} alt="MD Logo" />;
    case "internet-brands-logo":
      return <img src={InternetBrandsLogo} alt="Internet Brands Logo" />;
    case "car-direct-logo":
      return <img src={CarDirectLogo} alt="Car Direct Logo" />;
    case "fodors-travel-logo":
      return <img src={FodorsTravelLogo} alt="Fodors Travel Logo" />;
    case "nolo-logo":
      return <img src={Nolo} alt="Nolo Logo" />;
    case "pulse-point-logo":
      return <img src={PulsePoint} alt="Pulse point Logo" />;
    case "lawyers-logo":
      return <img src={LawyersLogo} alt="Lawyers Logo" />;
    case "officite-logo":
      return <img src={OfficiteLogo} alt="Officite Logo" />;
    case "exp":
      return <img src={JobExp} alt="exp" />;
    case "time":
      return <img src={JobTime} alt="time" />;
    case "refresh":
      return <img src={ReFresh} alt="refresh" {...props} />;
    case "refresh-red":
      return <img src={RefreshRed} alt="refresh" {...props} />;

    case "job-image":
      return <img src={devJob} alt="Job Image" />;
    case "tour-icon":
      return <img src={tourIcon} alt="Tour Icon" height={"16px"} />;
    case "bell-icon":
      return <img src={bellIcon} alt="Bell Icon" height={"16px"} />;
    case "refresh-png":
      return <img src={refresh} alt="Review" />;
    case "right-arrow-white":
      return <img src={rightArrowWhite} alt="right-arrow-white" />;
    case "suitcase-primary":
      return <img src={Suitcase2} alt="Suitecase" width={15} height={15} />;
    case "direct-approval":
      return (
        <img src={DirectApproval} alt="Suitecase" width={15} height={15} />
      );
    case "direct-email-verify":
      return (
        <img src={DirectEmailVerify} alt="Suitecase" width={15} height={15} />
      );
    case "direct-english-test":
      return (
        <img
          src={DirectEnglishTestClear}
          alt="Suitecase"
          width={15}
          height={15}
        />
      );
    case "direct-skill_test":
      return (
        <img
          src={DirectSkillTestClear}
          alt="Suitecase"
          width={15}
          height={15}
        />
      );

    case "login-icon":
      return <SVG url="https://ik.imagekit.io/olibr/icons/tr:w-15,h-15/Log-icon.svg"/>
    case "search-job":
      return <SVG url="https://ik.imagekit.io/olibr/icons/tr:w-15,h-15/Search-job-icon.svg"/>
    
    case "plain-calendar":
      return <PlainCalendar />;
    case "add-user":
        return  <AddUser />;
    case "cross-icon":
      return  <CrossIcon />;
    case "check-icon":
      return  <CheckIcon />; 
    case "edit-user":
      return  <EditUser />;
    case "resend-invite":
      return  <ResendInvite />;
    case "subscription":
        return  <Subscription height={18} />;
    case "pdf-icon":
      return  <PdfIcon />;
    case "clock-icon":
      return  <img src={ClockIcon} alt="" width={20} />;
    case "hackerrank-icon":
      return  <img src={HackerrankIcon} alt="" width={24} />;
    case "hackerearth-icon":
      return  <img src={HackerearthIcon} alt="" width={24} />;
    case "hackerrank-logo":
      return  <img src={HackerrankLogo} alt="" />;
    case "hackerearth-logo":
      return  <img src={HackerearthLogo} alt="" width={100} />;
      case "hackerearth-logo-test":
        return  <img src={HackerEarthTest} alt="" width={100} />;
    case "logo-partner1":
      return <img src={LogoPartner1} alt="" />;
    case "logo-partner2":
      return <img src={LogoPartner2} alt="" />;
    case "logo-partner3":
      return <img src={LogoPartner3} alt="" />;
    case "logo-partner4":
      return <img src={LogoPartner4} alt="" />;
    case "logo-partner5":
      return <img src={LogoPartner5} alt="" />;
    case "logo-partner6":
      return <img src={LogoPartner6} alt="" />;
    case "logo-partner7":
      return <img src={LogoPartner7} alt="" />;



    default:
      return null;
  }
};
