import { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { MainContext } from "../../context/MainContext";
import "./client-signup/authentication.css";
import { Icon } from "../../components/icon";
import { checkAuthentication } from "../../service/action/common/index";
import { login } from "../../service/action/authentication";
import ClipLoaderComp from "../../components/clipLoader/ClipLoader";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { setUser, user } = useContext(MainContext);
  const [resendVerify, setResendVerify] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loginLoading } = useSelector((state) => state.authenticationReducer);

  useEffect(() => {
    dispatch(checkAuthentication(navigate, user?.userType));
  }, [user, dispatch, navigate]);

  const onSubmit = (data) => {
    dispatch(login(data, navigate, setUser, setResendVerify));
  };

  return (
    <div
      className="login-page"
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        paddingTop:"100px"
      }}
    >
      <div className="login-right" style={{minWidth:"400px"}}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="login-form">
            <div className="login-form-header">
              <figure>
                <Icon name="olibr-favicon" />
              </figure>
              <div className="login-form-text">
                <h3>Login Now</h3>
              </div>
            </div>
            <div className="form-group">
              <input
                type="email"
                placeholder="Email"
                {...register("email", {
                  required: true,
                  pattern: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
                })}
                name="email"
              />
              <small className="text-danger">
                {errors.email && "Email is required & valid."}
              </small>
            </div>
            <div className="form-group mb5">
              <input
                type="password"
                placeholder="Password"
                name="password"
                {...register("password", {
                  required: true,
                  minLength: 6,
                })}
              />
              <small className="text-danger">
                {errors.password &&
                  "Password is required & must be 6 character long."}
              </small>
            </div>
            <div className="form-group">
              {resendVerify ? (
                <Link
                  to="/resend-verification-email"
                  className="link forgot-pass"
                >
                  Resend Email
                </Link>
              ) : (
                <Link to="/forgot-password" className="forgot-pass link">
                  Forgot Password?
                </Link>
              )}
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-full"
                disabled={loginLoading}
              >
                {loginLoading ? (
                  <ClipLoaderComp color={"white"} size={20} />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
