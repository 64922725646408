import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { faqOG } from "../imageLinks";
import NoFollow from "../../../common/staticData/seo/NoFollow";

const Faqs = ({ baseUrl }) => {
  return (
    <Helmet>
      <title>Olibr | Frequently asked questions by software developers</title>
      <meta charset="UTF-8" />
      <meta
        name="title"
        content="Olibr | Frequently asked questions by software developers"
      />
      <meta
        name="description"
        content="Olibr's frequently asked questions (FAQs) for developers and hiring companies. Get answers to all your questions related to our job offerings and placements"
      ></meta>
      <link rel="canonical" href={`${baseUrl}/faqs`} />
      <meta
        name="keywords"
        content="FAQs by olibr.com, tech blogs, olibr, olibr.com, Hiring developers, remote software developers, work-from-home developers, wfh, remote jobs, IT jobs, US based IT companies, US Jobs, US IT Jobs"
      />
        <meta name="robots" content={NoFollow()}></meta>
      <meta name="language" content="english"></meta>
      <html lang="en" />
      <meta name="url" content={`${baseUrl}/faqs`} />
      <meta name="identifier-URL" content={`${baseUrl}/faqs`}></meta>
      <meta name="referrer" content="origin-when-crossorigin"></meta>
      <link rel="image_src" href={faqOG} />
      <meta name="copyright" content="olibr.com" />

      <meta
        property="og:title"
        content="Olibr | Frequently asked questions by software developers"
      />
      <meta
        property="og:description"
        content="Olibr's frequently asked questions (FAQs) for developers and hiring companies. Get answers to all your questions related to our job offerings and placements"
      />
      <meta property="og:url" content={`${baseUrl}/faqs`} />
      <meta property="og:image" content={faqOG} />
      <meta property="og:site_name" content="Olibr" />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@olibr" />
      <meta property="twitter:domain" content="olibr.com" />
      <meta property="twitter:url" content={`${baseUrl}/faqs`} />
      <meta
        name="twitter:title"
        content="Olibr | Frequently asked questions by software developers"
      />
      <meta
        name="twitter:description"
        content="Olibr's frequently asked questions (FAQs) for developers and hiring companies. Get answers to all your questions related to our job offerings and placements "
      />
      <meta name="twitter:image" content={faqOG} />

      <meta property="og:type" content="business.business" />
      <meta
        property="business:contact_data:street_address"
        content="26 Arrowhead Ln"
      />
      <meta property="business:contact_data:locality" content="Menlo Park" />
      <meta property="business:contact_data:region" content="CA" />
      <meta property="business:contact_data:postal_code" content="94025" />
      <meta property="business:contact_data:country_name" content="US" />

      {/* ------Apple Devices ------ */}
      <meta
        name="apple-mobile-web-app-title"
        content="Olibr | Frequently asked questions by software developers"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-touch-fullscreen" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black"></meta>

      {/* ----------JSON LD Schema for SEO------ */}
      <script type="application/ld+json">
        {JSON.stringify(
          // ------breadcrumbs--
          {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: `${baseUrl}/`,
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "FAQ's",
                item: `${baseUrl}/faqs`,
              },
            ],
          }
        )}
      </script>
      {/* ---------------------------- */}
      <script type="application/ld+json">
        {JSON.stringify(
          // Speakable schema----
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            name: "Frequently asked questions by Developers and Clients",
            speakable: {
              "@type": "SpeakableSpecification",
              xpath: [
                "/html/head/title",
                "/html/head/meta[@name='description']/@content",
              ],
            },
            url: `${baseUrl}/faqs`,
          }
        )}
      </script>
      {/* ----------------------------- */}
      <script type="application/ld+json">
        {JSON.stringify(
          // FAQ----
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [
              {
                "@type": "Question",
                name: "What kind of developers does Olibr hire?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Olibr hires developers for various job types including front-end, back-end, full-stack, database, mobile, DevOps, etc., based on tech roles, skills, and seniority. We have requirements for over 20 technologies including Python, React/Node, TypeScript, AWS Lambda, Java, Angular, TypeScript, React Native, PHP, Ruby, and more.",
                },
              },
              {
                "@type": "Question",
                name: "Where is Olibr based?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Olibr is based out of the US. However, Olibr doesn’t believe in geographical limitations and aims to connect top talent with top US companies.",
                },
              },
              {
                "@type": "Question",
                name: "How is Olibr different from other remote job websites?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Our committed approach to finding you full-time placements, with long-term professional security and career growth, in top US firms makes us different from others. We also offer extra perks and benefits such as medical insurance, paid vacation, and more.",
                },
              },
              {
                "@type": "Question",
                name: "What is Olibr?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Olibr is a US-based job placement platform that offers full-time remote job opportunities to highly skilled developers in top US firms.",
                },
              },
              {
                "@type": "Question",
                name: "Who can join Olibr?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Exceptionally talented developers with 3+ years of experience who are ready to work full-time or 40 hours/week and can spare time to get through our vetting process can join us.",
                },
              },
              {
                "@type": "Question",
                name: "Can fresh graduates apply with Olibr?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Olibr ideally hires remote developers with 3+ years of experience. However, if you think you have got the right talent and skills to be an exceptional developer, do apply with us and take our tests. We offer the right kind of opportunity to the right talent!",
                },
              },
              {
                "@type": "Question",
                name: "Should I be an engineering graduate to join Olibr?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Olibr doesn’t insist much on requiring a professional qualification while hiring developers. If you are a skilled developer with the top 1% of the developers’ skills, then you are at the place. However, a professional degree always looks better on your resume and increases your chances of getting hired for the right position.",
                },
              },
              {
                "@type": "Question",
                name: "How do Olibr source candidates?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Olibr’s sourcing process is entirely planned and designed by leading industry experts. Olibr makes use of the best data sourcing methods and automated systems to find and build a database of highly skilled remote developers. Our exhaustive and pre-vetted platform enables our clients/companies to pick and choose the cream of the crop that aligns with their business needs.",
                },
              },
              {
                "@type": "Question",
                name: "What is Olibr’s vetting process?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Olibr has an exhaustive vetting process which includes a technical skills test, communication skills test, and an interview. Olibr developers are expected to share their comprehensive resumes detailing their roles in each project, the specific tasks they have carried out, and the skills they have used to accomplish their tasks in the projects. Olibr developers are expected to go through tests that will assess their skills in programming languages, algorithms, system designs, specialization, frameworks, etc.",
                },
              },
              {
                "@type": "Question",
                name: "How can I upload my resume on the Olibr website?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "The first step to uploading your resume is signing up on the Olibr website. Once you sign up, you will be guided to your account dashboard. In the account dashboard, on the left-hand side, you will see a listing named, Your Olibr Profile. Once you click on that, you will be taken to a page called Personal Information. Here, you can upload your resume and click on the autofill option to fill in your other personal details.",
                },
              },
              {
                "@type": "Question",
                name: "How can I finalize my profile?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "After you pass the test, you will be navigated to a section called, ‘Finalize my resume’. This section is the perfect place for you to display your skills and projects you have worked on in past jobs front and center for our clients to see. Once you click on the Finalize my resume option and get to the page, you will see the option to edit your profile. Olibr would have auto-filled a few sections of your profile from your resume that you had uploaded while signing up. Now, you will have to complete the details that have not been added to your profile. The more details you’ll add about the technical projects you have worked on and your specific role in those projects the better your chances will be of getting pulled in by top companies.",
                },
              },
              {
                "@type": "Question",
                name: "How can I sign up with Olibr?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Signing up with Olibr is easy! Just follow the below-given steps ·       Upload your resume and fill in your name, location, skills, and salary details  ·       Take our English proficiency and skills evaluation tests·       That’s it! You are ready to be placed with the best US company",
                },
              },
              {
                "@type": "Question",
                name: "Do you provide screening, vetting, and testing services for developers outside of the Olibr community?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "We only offer these services for the developers signing up with the Olibr community.",
                },
              },
              {
                "@type": "Question",
                name: "Do Olibr developers speak fluent English?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Yes, Olibr developers speak fluent English. They go through rigorous communication skills tests and interview with us which helps us assess and understand their fluency in the English language.",
                },
              },
              {
                "@type": "Question",
                name: "What is a deep developer profile?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "In order to work remotely with a candidate, you need to have a full understanding of their professional and personal background. The regular job portals may not have that kind of information to share with you. However, with Olibr, all the developers are thoroughly vetted not only for their skills and talent but also for their personality and other personal backgrounds. We don’t just share random profiles based on a few keywords that fit in with your position. Our in-house experts understand your requirements perfectly and then make use of the AI to find and match the profiles that best align with your business needs. We extend unmatched transparent access to all candidates’ profiles to you. You can gauge their skills, acumen, proficiency, etc. by looking at their profiles and conducting an interview with them.",
                },
              },
              {
                "@type": "Question",
                name: "What skills requirements/positions can I fill in through Olibr?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Olibr developers are skilled in over 20 latest technologies that help fulfill your business needs. Some of our most sought-after skills are - ReactJS - Node.js  - Java,  - Python,  - Docker - Typescript - AWS  - React Native  - JavaScript  - PostgreSQL",
                },
              },
              {
                "@type": "Question",
                name: "Does Olibr have any system in place to track developers’ work and progress?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Yes, Olibr has automated software in place that helps track time and progress made by the developers on a task or project every single day. It gives an in-depth insight into productivity, hours worked, and deadlines met by the developer. Better access to your developer’s daily work will help you manage and prioritize their schedule to align with the most important business needs.  We also have a system in place for regular and uninterrupted virtual daily stand-ups.",
                },
              },
              {
                "@type": "Question",
                name: "What happens if I’m not happy with Olibr developers?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Olibr developers are the cream of the crop and have only made a place in our community after a rigorous assessment and vetting process. However, we understand that sometimes a developer you choose may not perfectly fit into the vision and work you are hiring them for. Hence, to give our clients a further chance, we offer every client a two-week free trial period to work with the developer you have selected. If you are satisfied with the developer’s work, you can hire them on a permanent basis. If you find the developer unsatisfactory for your position, then you can have us find and screen more profiles for you.",
                },
              },
            ],
          }
        )}
      </script>
      {/* --------------------------- */}
      <script type="application/ld+json">
        {JSON.stringify(
          // -------Website/webpage schema------
          {
            "@context": "https://www.schema.org",
            "@type": "WebSite",
            name: "Frequently asked questions by Developers and Clients",
            url: `${baseUrl}/faqs`,
          }
        )}
      </script>
    </Helmet>
  );
};

export default Faqs;
