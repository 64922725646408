import axios from "axios";
import api from "./apiInstance";

//SKILL TEST STEP;
export const getDevSkillTestResultApi = (data) =>
  api.get(
    `/dev-tech-result?candidateId=${data?.candidateId}&testId=${data?.testId}&isHackerearth=${data?.isHackerearth}`
  );

export const removeUnattemptedSkillApi = (objectId) =>
  api.post(`/remove-unattempted-assessment?assessmentId=${objectId}`);

export const removeStackTestApi = (stackId) =>
  api.post(`/remove-stack?stackObjectId=${stackId}`);

export const sendOTPApi = (data) => api.post(`/dev/send-sms`, data);

export const verifyOTPApi = (data) => api.post(`/dev/verify-otp`, data);

export const chooseStackTestsApi = (data) => api.post(`/choose-stack`, data);

export const chooseSkillTestApi = (skillId) =>
  api.post(`/choose-skill-test?chosenSkillId=${skillId}`);

export const getSkillTestStepDataApi = () => api.get("/skill-test-step");

export const getNotificationApi = () => api.get("/notification");

export const clearNoficationApi = () => api.get("/clear-notification");

export const jobRefreshApi = (jobId) =>
  api.get(`/client/job-refresh?jobId=${jobId}`);

export const devFilterClient = (pageData, filter) =>
  api.post(
    `/client/dev-list?page=${pageData?.page}&limit=${pageData?.limit}`,
    filter
  );
export const updateDevSnoozeApi = () => api.get(`/dev/dev-snooze`);

export const subscriptionSummaryApi = () => api.get(`/client/get-active-plans`);
export const clientPaymentHistoryApi = (clientId) =>
  api.get(`/client/payment-details?clientId=${clientId}`);
export const cancelPlanApi = () => api.get("/client/cancel-plan");
export const clientRestrictionApi = (payload) =>
  api.get(`/client/restrict-client?actionPage=${payload?.page}`);
  export const deleteEmailAPI = (payload) =>
  api.post(`/client/delete-entries`,payload?.data);
//Developer Reviews
export const getDevReviewStatusApi = (data) =>
  api.post("/get-review-status", data);
export const getDevReviewLists = () => api.get("/dev/get-developer-reviews");
export const getDevInfoForReviewApi = (devId) =>
  api.get(`/get-basic-dev-info/${devId}`);
export const deleteDevReviewApi = (reviewId) =>
  api.delete(`/dev/delete-review-request/${reviewId.reviewId}`);
export const createDevReviewApi = (data) =>
  api.post(`/dev/send-review-request`, data);
export const resendDevReviewApi = (data) =>
  api.put(`/dev/resend-review-request/${data}`);
export const giveRatingDevReviewApi = (data) => api.put(`/give-ratings`, data);

export const uploadVideoWithoutDeleteApi = (data, setVideoPercentage) =>
  api.post(`/upload-video-no-delete`, data, {
    onUploadProgress: (data) => {
      setVideoPercentage(Math.round((data.loaded / data.total) * 100));
    },
  });
export const uploadVideoApi = (data, userId, setVideoPercentage) =>
  api.post(`/upload-video?userId=${userId?.devId}`, data, {
    onUploadProgress: (load) => {
      setVideoPercentage(Math.round((load.loaded / load.total) * 100));
    },
  });
export const getDevVideoApi = (data) =>
  api.get(`/get-dev-video?devId=${data?.devId}`);
export const showOpenFaqsApi = () => api.get(`/open-faqs`);
export const getAllEmailSlugsApi = () => api.get(`/admin/email-title`);
export const updatePlagiarismApi = (data) =>
  api.post(`/admin/update-plagiarism-status`, data);

export const getJobByIdApi = (jobId) => api.get(`/job-details/${jobId}`);

//export const getJobByIdApi = (jobId) => api.get(`/job-details/${jobId}`);
export const getJobByCategoryApi = (catId, data, pageData, search) =>
  catId
    ? api.post(
        `/job-list?catId=${catId}&page=${pageData?.page}&limit=${pageData?.limit}&search=${search}`,
        data
      )
    : api.post(
        `/job-list?page=${pageData?.page}&limit=${pageData?.limit}&search=${search}`,
        data
      );
export const getPopularJobsApi = () => api.get(`/popular-jobs`);

export const getJobCatApi = () => api.get("/admin/job-categories");
export const postJobCatApi = (data) => api.post("/admin/job-categories", data);
export const updateJobCatApi = (jobCatId, data) =>
  api.put(`/admin/job-categories/${jobCatId}`, data);
export const deleteJobCatApi = (jobCatId) =>
  api.delete(`/admin/job-categories/${jobCatId}`);

export const chatGPTStandardEngApi = (data) =>
  api.post(`/openai/standard-eng`, data);
export const getResumeOpenApi = (userId) =>
  api.get(`/get-resume-open/${userId}`);
export const getFaqLists = () => api.get("/all-faq-lists");
export const changePassword = (data) => api.put("/change-password", data);
export const changeEmail = (email) => api.put("/change-email", { email });
export const getAllFaqGroup = (data) => api.post("/faq-group-lists", data);
export const getJobLists = (pageData) =>
  pageData
    ? api.get(
        `/job-post?status=open&page=${pageData?.page}&limit=${pageData?.limit}`
      )
    : api.get("/job-post?status=open");
export const getRecentBlogs = () => api.get(`/blog`);
export const getCountry = () => api.get(`/get-country`);
export const getState = (data) => api.post(`/get-state`, data);
export const getCity = (data) => api.post(`/get-city`, data);
export const ContactUs = (data) => api.post(`/contact-us-form`, data);
export const allBlogNameIdApi = () => api.get(`/all-blog-name-id`);
export const getSpotlightedCandidates = () =>
  api.get(`/get-spotlight-candidates`);

export const getBlogCatOpenApi = () => api.get(`/blog-category`);
export const getBlogOpenApi = () => api.get(`/blog-list`);
export const getBlogByIdOpenApi = (blogId) => api.get(`/blog-detail/${blogId}`);
export const getBlogBySlugOpenApi = (data) => api.post(`/blog-slug`, data);
export const getJobByIdOpenApi = (blogId) => api.get(`/job-detail/${blogId}`);

//Blog
export const getBlogs = (searchValue, currentBlogCategory) =>
  api.get(
    `/blogs?search=${searchValue}&currentCategory=${currentBlogCategory}`
  );
export const getBlogCategories = () => api.get("/get-blogcategories");
export const getAllBlogsById = (data) => api.get(`/blogs-by-parentId/${data}`);
export const getBlogDetail = (slug) => api.get(`/get-blog/${slug}`);
export const getRecentFiveBlogApi = () => api.get(`/blogs-recent`);
export const getPopularBlogApi = () => api.get(`/blogs-popular`);

//User Auth Api

export const newFeatureClickedApi = () => api.put("/dev/new-feature-seen");
export const getUser = () => api.get("/get-user");
export const login = (data) => api.post("/login", data);
export const signUp = (data) => api.post("/sign-up", data);
export const forgetPassword = (data) => api.put("/forget-password", data);
export const resetPassword = (data) => api.put("/reset-password", data);
export const logout = () => {
  return api.get("/logout");
};
export const emailVerification = (data) =>
  api.post("/email-verification", data);
export const resendEmailVerification = (data) =>
  api.post("/resend-email-verification", data);

export const loginLinkidin = () => api.get("/auth/linkedin");

//SITEMAP APIS
export const getSitemapTypesApi = () => {
  return api.get("/admin/get-sitemap-type");
};
export const postSitemapTypesApi = (data) =>
  api.post("/admin/add-sitemap-type", data);
export const updateSitemapTypesApi = (data, sitemapTypeId) =>
  api.put(`/admin/update-sitemap-type/${sitemapTypeId}`, data);
export const deleteSitemapTypesApi = (sitemapTypeId) =>
  api.delete(`/admin/delete-sitemap-type/${sitemapTypeId}`);

//MAINTENANCE MESSAGE
export const getMaintenanceOneApi = () => api.get("/maintenance-message");
export const getMaintenanceApi = () =>
  api.get("/admin/get-maintenance-message");
export const addMaintenanceApi = (data) =>
  api.post("/admin/add-maintenance-message", data);
export const deleteMaintenanceApi = (messageId) =>
  api.delete(`/admin/delete-maintenance-message/${messageId}`);
export const updateMaintenanceApi = (data) =>
  api.put("/admin/update-maintenance-message", data);
export const updateMaintenanceStatusApi = (data) =>
  api.put("/admin/update-maintenance-status", data);

// update 4th tab status
export const updatePageTour = () => api.put("/dev/update-page-tour");

//Admin Api
export const getAllPerksBenefit = (pageData, forType) =>
  forType !== "open"
    ? api.get(
        `admin/perks-and-benifits?page=${pageData?.page}&limit=${pageData?.limit}`
      )
    : api.get(
        `/perks-and-benifits?page=${pageData?.page}&limit=${pageData?.limit}`
      );

export const savePerkBenefints = (data) =>
  api.post("/admin/perks-and-benifits", data);
export const deletePerkBenefints = (id) =>
  api.delete(`/admin/perks-and-benifits?id=${id}`);
export const updatePerkBenefints = (id, data) =>
  api.put(`/admin/perks-and-benifits?id=${id}`, data);
export const getInterviewListApi = (pageData) =>
  api.get(
    `/interview/interview-list?limit=${
      pageData.limit ? pageData.limit : 100
    }&offset=${pageData.offset ? pageData.offset : 0}`
  );
export const getSpotlightHistoryApi = () => api.get("/admin/spotlight-history");

export const getQueriesListApi = (pageData) =>
  api.get(
    `/admin/contact-us-queries?page=${pageData.page}&limit=${pageData.limit}`
  );

export const updateQueryApi = (data) =>
  api.put(`/admin/update-query-status`, data);

export const deleteQueryApi = (id) =>
  api.delete(`/admin/delete-contact-query/${id}`);

export const updateSpotlightedCandidates = (data) =>
  api.put(`/admin/update-spotlight`, data);
export const getDeveloperLists = (data, limit, page, term = "") =>
  api.post(
    `/admin/developer-lists?page=${page}&term=${term.trim()}&limit=${limit}`,
    data
  );
export const devUpdateStatus = (data) => api.put("/admin/update-status", data);
export const getAdminSkillLists = (skillType, pageData) =>
  api.get(
    `/admin/skill-lists?type=${skillType}&limit=${pageData?.limit}&page=${pageData?.page}&search=${pageData?.search}`
  );
export const updateSkillStatus = (data) =>
  api.put("/admin/update-skill-status", data);
export const addStack = (data) => api.post("/admin/add-stack", data);
export const editStack = (data) => api.put("/admin/edit-stack", data);
export const getAdminStackLists = (data, pageData) =>
  api.get(
    `/admin/stack-lists?page=${pageData.page}&limit=${pageData.limit}&search=${pageData.search}`
  );
// api.get(
//   `/admin/stack-lists?page=${pageData.page}&limit=${pageData.limit}&search=${pageData.search}`,
//   dat
// );
export const updateAssessmentNameApi = (data) =>
  api.put("/admin/update-assessment-name-hackerrank", data);
export const updateStackStatus = (data) =>
  api.put("/admin/update-stack-status", data);
export const refreshXobinAssessment = () =>
  api.get("/admin/refresh-xobin-assessment");
export const renderAllHackerrankAssessments = (offset) =>
  api.get(`/admin/hackerrank-assessment-lists?limit=100&offset=${offset}`);

export const renderAllHackerrankAssessmentsNoLimit = () =>
  api.get(`/admin/hackerrank-assessment-lists-no-limit-all`);

export const addSkill = (data) => api.post("/admin/add-skill", data);
export const editSkill = (data) => api.put("/admin/edit-skill", data);
export const renderTopicList = () => api.get(`/admin/topic-list`);
export const getAllIcons = () => api.get(`/admin/icons`);
export const getAllTools = (data) =>
  api.get(
    `/admin/tools-lists?page=${data.page}&limit=${data.limit}&search=${data.search}`
  );
export const deleteTool = (data) => api.delete(`/admin/delete-tools/${data}`);
export const updateTool = (data) => api.put("/admin/update-tools", data);
export const addTool = (data) => api.post("/admin/add-tools", data);
export const uploadIcons = (data) => api.post(`/admin/upload-icon`, data);
export const deleteStack = (stackId) =>
  api.delete(`/admin/stack?stackId=${stackId}`);
export const deleteSkill = (skillId) =>
  api.delete(`/admin/skill?skillId=${skillId}`);
export const getDeveloperProfile = (devId) =>
  api.get(`/admin/dev-profile?devId=${devId}`);
export const updateProfileStatus = (data) =>
  api.post("/admin/update-status-application", data);
export const resultParsing = (data) => api.post("/admin/convert-to-json", data);
export const setMemberPassword = (data) =>
  api.post(`/admin/set-member-password`, data);
export const addUser = (data) => api.post(`/admin/add-member`, data);
export const getUserById = (userId) => api.get(`/admin/get-member/${userId}`);
export const updateUser = ({ userId, data }) =>
  api.put(`/admin/update-member/${userId}`, data);
export const updateUserStatusAPI = (data) =>
  api.put(`/admin/disable-user/${data}`);
export const deleteUser = (userId) =>
  api.delete(`/admin/delete-member/${userId}`);
export const userListing = (data) =>
  api.get(
    `/admin/member-list?page=${data.page}&limit=${data.limit}&search=${data.search}`
  );
export const getResumeAdmin = (userId) =>
  api.get(`/dev/get-resume?userId=${userId}`);
export const getDevResult = (userId) =>
  api.get(`/dev/tech-test-results?userId=${userId}`);
export const getCategoryList = () => api.get("/admin/blogcategory-list");
export const addCategoryBlog = (data) =>
  api.post("/admin/add-blogcategory", data);
export const deleteCategoryBlog = (categoryId) =>
  api.delete(`/admin/delete-blogcategory/${categoryId}`);
export const updateCategoryBlog = ({ categoryId, data }) =>
  api.put(`/admin/update-blogcategory/${categoryId}`, data);
export const getBlogList = () => api.get(`/admin/blog`);
export const postBlog = (data) => api.post(`/admin/blog`, data);
export const updateBlog = (BlogId, data) =>
  api.put(`/admin/blog/${BlogId}`, data);
export const deleteBlog = (blogId) => api.delete(`/admin/blog/${blogId}`);
export const addFaqGroup = (data) => api.post("/admin/add-faq-group", data);
export const addFaq = (data) => api.post("/admin/add-faq", data);
export const updateFaq = (data) => api.put("/admin/edit-faq", data);
export const deleteFaq = (data) => api.delete("/admin/delete-faq", { data });
export const updateFaqGroup = (data) => api.put("/admin/edit-faq-group", data);
export const deleteFaqGroup = (data) =>
  api.delete("/admin/delete-faq-group", { data });
export const addQuestion = (data) =>
  api.post("/admin/add-english-question", data);
export const updateEnglishQuestion = (data) =>
  api.put("/admin/update-english-question", data);
export const deleteEnglishQuestion = (data) =>
  api.delete(`/admin/delete-english-question/${data}`);
export const englishAllQuestion = () => api.get("/admin/english-questions");

export const getAllJobs = (data, filterObj) =>
  api.post(
    `/admin/get-job-post?page=${data.page}&limit=${data.limit}&search=${data.search}`,
    filterObj
  );
export const addJob = (data) => api.post("/admin/job-post", data);
export const editJob = (data) => api.post("/admin/edit-job-post", data);
export const deleteJob = (data) =>
  api.delete(`/admin/job-post?status=open&jobId=${data}`);
export const adminDashboardStepOne = ({ devId, data }) =>
  api.put(`/admin/dashboard-step-one?devId=${devId}`, data);
export const getClientListAdmin = (data, limit, page, term = "") =>
  api.post(
    `/admin/get-all-client-lists?page=${page}&term=${term.trim()}&limit=${limit}`,
    data
  );
export const getClientListAdminShortListed = (clientId) =>
  api.get(`/admin/get-shortlisted-candidate-list?clientId=${clientId}`);
export const getClientListAdminHired = (clientId) =>
  api.get(`/admin/get-hired-candidate-list?clientId=${clientId}`);

export const getClientDetailAdmin = (clientId) =>
  api.get(`/admin/get-client-details?clientId=${clientId}`);
export const getAdminDevAcademic = (data) =>
  api.get(`/dev/dev-academic-info?devId=${data?.devId}`);
export const updateAdminDevAcademic = (devId, data) =>
  api.put(`/dev/dev-academic-info?devId=${devId}`, data);
export const saveAdminDevAcademic = (data) =>
  api.post(`/dev/dev-academic-info`, data);
export const deleteAdminDevAcademic = (data, devId) =>
  api.delete(`/dev/dev-academic-info?devId=${devId?.devId}`, { data: data });
export const resumeParsingAdmin = (data, devId) =>
  api.post(`/dev/resume-parsing?devId=${devId?.devId}`, data);
export const saveJobFavourit = (id) => api.put(`/dev/favorite-jobs/${id}`);

export const getFavJob = () => api.get("/dev/favourit-job");
export const fetchReportApi = (data) =>
  api.get(
    `/dev-assessment-report?testId=${data.testId}&candidateId=${data.candidateId}`
  );
export const updateBasicDetails = (data) =>
  api.patch("/dev/update-developer-details", data);

export const devActivation = (data) => api.put("/unsubscribe", data);

export const getDevActivation = () => api.get("/dev/email-settings");
// api.put("/dev/update")

//Profile update request
export const updateEmailSettingStatusApi = (data) =>
  api.put(`/admin/update-email-setting-status`, data);

export const approvalUpdatesAccept = (data) =>
  api.post(`/approval-updates/accept`, data);
export const approvalUpdatesReject = (data) =>
  api.post(`/approval-updates/reject`, data);

export const getAdminDevCertification = (data) =>
  api.get(`/dev/dev-certification?devId=${data.devId}`);
export const updateAdminDevCertification = (devId, data) =>
  api.put(`/dev/dev-certification?devId=${devId.devId}`, data);
export const saveAdminDevCertification = (data) =>
  api.post(`/dev/dev-certification`, data);
export const deleteAdminDevCertification = (data, devId) =>
  api.delete(`/dev/dev-certification?devId=${devId?.devId}`, { data: data });

export const getAdminDevSocialProfile = (data) =>
  api.get(`/dev/social-profiles?devId=${data.devId}`);
export const saveAdminDevSocialProfile = (data) =>
  api.post(`/dev/social-profiles`, data);
export const updateAdminDevSocialProfile = (devId, data) =>
  api.put(`/dev/social-profiles?devId=${devId.devId}`, data);

export const getAdminDevEmployement = (data) =>
  api.get(`/dev/dev-employment?devId=${data.devId}`);
export const updateAdminDevEmployement = (devId, data) =>
  api.put(`/dev/dev-employment?devId=${devId.devId}`, data);
export const saveAdminDevEmployement = (data) =>
  api.post(`/dev/dev-employment`, data);
export const deleteAdminDevEmployement = (data, devId) =>
  api.delete(`/dev/dev-employment?devId=${devId?.devId}`, { data: data });

export const getAdminDevProject = (data) =>
  api.get(`/dev/dev-project?devId=${data.devId}`);
export const updateAdminDevProject = (data,isAdditionProject) =>
isAdditionProject?
api.put(`/dev/additional-dev-project`, data)
: api.put(`/dev/dev-project`, data);
export const saveAdminDevProject = (data,isAdditionProject) => 
isAdditionProject?
api.post(`/dev/additional-dev-project`, data)
:api.post(`/dev/dev-project`, data);
export const deleteAdminDevProject = (data, devId,isAdditionProject) =>
isAdditionProject?
api.delete(`/dev/additional-dev-project?devId=${devId?.devId}`, { data: data })
:api.delete(`/dev/dev-project?devId=${devId?.devId}`, { data: data });
export const getSkillByCategoryDevApi = () => api.get(`/skill-by-category-dev`);
export const getAdminDevTools = (data) =>
  api.get(`/dev/dev-tools?devId=${data.devId}`);
export const updateAdminDevTools = (devId, data) =>
  api.put(`/dev/dev-tools?devId=${devId.devId}`, data);
export const saveAdminDevTools = (devId, data) =>
  api.post(`/dev/dev-tools?devId=${devId?.devId}`, data);
export const deleteAdminDevTools = (data, devId) =>
  api.delete(`/dev/dev-tools?devId=${devId?.devId}`, { data: data });

export const getAdminDevSkills = (data) =>
  api.get(`/dev/dev-techskill?devId=${data.devId}`);
export const updateAdminDevSkills = (devId, data) =>
  api.put(`/dev/dev-techskill?devId=${devId.devId}`, data);
export const saveAdminDevSkills = (data) =>
  api.post(`/dev/dev-techskill`, data);
export const deleteAdminDevSkills = (data, devId) =>
  api.delete(`/dev/dev-techskill?devId=${devId?.devId}`, { data: data });

export const getAdminDevAchievement = (data) =>
  api.get(`/dev/dev-achievements?devId=${data.devId}`); //active
export const updateAdminDevAchievement = (devId, data) =>
  api.put(`/dev/dev-achievements?devId=${devId.devId}`, data);
export const saveAdminDevAchievement = (data) =>
  api.post(`/dev/dev-achievements`, data);
export const deleteAdminDevAchievement = (data, devId) =>
  api.delete(`/dev/dev-achievements?devId=${devId?.devId}`, { data: data });

export const updateAdminDevShortDesc = (devId, data) =>
  api.put(`/dev/dev-tell-your-story?devId=${devId.devId}`, data);

export const getJobListByClientAdmin = (clientId, pageData) =>
  api.get(`/admin/get-client-jobs-by-clientId?clientId=${clientId}`);

export const getJobFullClientAdmin = (jobid) =>
  api.get(`/admin/get-job-details/${jobid}`);

export const blogsSyncupApi = () => api.get(`/admin/blogs-sync`);

export const deleteEmailTemplate = (data) =>
  api.delete(`/admin/email-templates/${data}`);
export const addEmailTemplate = (data) =>
  api.post("/admin/email-templates", data);
export const getAllEmailTemplate = () => api.get("/admin/email-templates");

export const updateEmailTemplate = (templateId, data) =>
  api.put(`/admin/email-templates/${templateId}`, data);
export const getEmailTemplateById = (templateId) =>
  api.get(`/admin/email-templates/${templateId}`);
export const getEmailHistory = (data, pageData) =>
  api.post(
    `/admin/email-history?page=${pageData?.page}&limit=${pageData?.limit}`,
    data
  );
export const verifyDevEmailApi = (data) =>
  api.post(`/bulk-email-verification`, data);
export const approveDevEmailApi = (data) =>
  api.post(`/bulk-profile-approval`, data);

export const unapproveDevEmailApi = (data) =>
  api.post("/bulk-profile-unapproval", data);

export const getEnglishResultAdmin = (data) =>
  api.get(`/admin/english-test-result?devEmail=${data.devEmail}`);
export const saveEnglishResultAdmin = (data) =>
  api.post(`/admin/clear/english-test`, data);
export const getAdminSkillOrStack = (data) =>
  api.get(
    `/admin/get-skill-stack?skilltype=${data.skillType}&skillid=${data.value}`
  );
export const getAdminDevSkillResult = (email) =>
  api.get(`/dev/tech-test-results?email=${email}`);
export const updateAdminSkillTest = (data) =>
  api.post("/admin/clear/skill-test", data);
export const postBlogNewApi = (data) => api.post(`/admin/blog`, data);
export const deleteBlogNewApi = (blogId) => api.delete(`/admin/blog/${blogId}`);
export const updateBlogNewApi = (catId, data) =>
  api.put(`/admin/blog/${catId}`, data);
export const deleteProfileImage = (devId) =>
  api.delete(`admin/delete-profile-image?devId=${devId}`);
export const postBlogCatApi = (data) => api.post(`/admin/blog-category`, data);
export const deleteBlogCatApi = (data) =>
  api.delete(`/admin/blog-category/${data}`);
export const updateBlogCatApi = (catId, data) =>
  api.put(`/admin/blog-category/${catId}`, data);
export const saveCreateSkills = (data) => api.post("admin/saveTestSkill", data);
export const getCreateSkills = () => api.get("admin/checkSkillTest");
export const deleteUserProfile = (userId, userType) =>
  api.delete(`/delete-user-profile?userId=${userId}&userType=${userType}`);
export const blockUserProfile = (data) =>
  api.delete(
    `/admin/block-user-profile?userId=${data.userId}&company=${data.company}`
  );
export const updateAdminJobStatusApi = (data) =>
  api.put("/admin/update-admin-job-status", data);
//Developer Api
export const dashboardStepOne = (data) => {
  const endPoint =
    !!data && !!data.region && data.region === "US"
      ? "/dev/dashboard-step-one-us"
      : "/dev/dashboard-step-one";
  delete data.region;
  return api.post(endPoint, data);
};
export const getStepCompletedStatus = () =>
  api.get("/dev/get-step-completed-status");
export const getResume = (userId, jobId) =>
  jobId && userId
    ? api.get(`/dev/get-resume?userId=${userId}&jobId=${jobId}`)
    : userId
    ? api.get(`/dev/get-resume?userId=${userId}`)
    : api.get(`/dev/get-resume`);
export const getProfileStatus = () => api.get("/dev/profile-status");
export const proficiencyTestLink = () => api.get("/dev/proficiency-test-link");
export const proficiencyTestResult = () =>
  api.get("/dev/proficiency-test-result");
export const englishTestResult = () => api.get("/dev/proficiency-test-result");
export const skillList = () => api.get(`/skill-lists`);
export const getDevSkillList = () => api.get("/dev/get-dev-skills");
export const getAllSkillsList = () => api.get("/dev/get-all-skills");
export const manageDevSkill = (data) => api.post("/dev/manage-dev-skill", data);
export const updateAvailableFrom = (data) =>
  api.patch("/dev/update-available-date", data);
export const postDevStack = (data) => api.post("/dev/dev-choose-stack ", data);
export const devChooseSkills = (data) => api.put("/dev/choose-skills", data);
export const devChooseSkillsGet = () => api.get("/dev/dev-choose-skills");
export const deleteDevSKill = (data) =>
  api.delete("/dev/remove-dev-skill", { data });
export const stackList = () => api.get("/user-stack-lists");
export const stackListChosen = () => api.get("/dev/user-stack-list");
export const finalizeResumeCall = (data) =>
  api.post("/dev/save-resume-data", data);
export const uploadProfileImage = (data, prevUrl) =>
  api.post(
    `/dev/upload-profile-image?devId=${data?.devId}&prevUrl=${prevUrl}`,
    data?.formData
  );
export const resumeParsing = (data) => {
  console.log("this this");
  let token = localStorage.getItem("token");
  return api.post("/dev/resume-parsing", data);
};
export const getResumeParserData = () => api.get("/dev/get-resume-parser-data");
export const getCreateProfileData = () => {
  // console.log("this one");
  let token = localStorage.getItem("token");
  return api.get("/dev/dashboard-step-one");
};
export const uploadImage = (data, prevUrl) =>
  api.post(`/upload-image?prevUrl=${prevUrl}`, data);
export const uploadMultiImageApi = (data) =>
  api.post("/upload-multi-image", data);
export const startTest = (data) =>
  api.get(
    `/start-test?assessmentId=${data.assessmentId}&skillOrStackId=${data.skillOrStackId}`
  );
export const englishTest = ({ mcq, long }) =>
  api.get(`/dev/english-question?mcq=${mcq}&long=${long}`);

export const updateEngActivity = (data) =>
  api.put("dev/english-test/activity", data);
export const postResultData = (data) =>
  api.post("/dev/english-test-result", data);
export const getEnglishTestResult = () => {
  return api.get("/dev/english-test-result");
};
export const askForApproval = () => api.get(`/dev/submit-for-approval`);
export const getDevStatus = () => api.get(`/dev/check-status`);
export const getResultDev = (userId) =>
  userId
    ? api.get(`/dev/tech-test-results?userId=${userId}`)
    : api.get(`/dev/tech-test-results`);

export const getJobFullClientDev = (jobid) =>
  api.get(`/dev/get-job-details/${jobid}`);

export const getDevAcademic = () => api.get("/dev/dev-academic-info");
export const saveDevAcademic = (data) =>
  api.post("/dev/dev-academic-info", data);
export const updateDevAcademic = (data) =>
  api.put("/dev/dev-academic-info", data);
export const deleteDevAcademic = (data) =>
  api.delete("/dev/dev-academic-info", { data: data });

export const getDevCertification = () => api.get("/dev/dev-certification");
export const saveDevCertification = (data) =>
  api.post("dev/dev-certification", data);
export const updateDevCertification = (data) =>
  api.put("dev/dev-certification", data);
export const deleteDevCertification = (data) =>
  api.delete("/dev/dev-certification", { data: data });

export const getDevSocialProfile = () => api.get("/dev/social-profiles");
export const saveDevSocialProfile = (data) =>
  api.post("/dev/social-profiles", data);
export const updateDevSocialProfile = (data) =>
  api.put("/dev/social-profiles", data);
export const deleteDevSocialProfile = (data) =>
  api.delete("/dev/social-profiles", { data: data });

export const getDevEmployement = () => api.get("/dev/dev-employment");
export const saveDevEmployement = (data) =>
  api.post("/dev/dev-employment", data);
export const updateDevEmployement = (data) =>
  api.put("/dev/dev-employment", data);
export const deleteDevEmployement = (data) =>
  api.delete("/dev/dev-employment", { data: data });

export const getDevProject = (data) =>
  data?.devId
    ? api.get(`/dev/dev-project?devId=${data.devId}`)
    : api.get(`/dev/dev-project`);
export const saveDevProject = (data) => api.post("/dev/dev-project", data);
export const updateDevProject = (data) => api.put("/dev/dev-project", data);
export const deleteDevProject = (data) =>
  api.delete("/dev/dev-project", { data: data });

export const getDevTools = () => api.get("/dev/dev-tools");
export const saveDevTools = (data) => api.post("/dev/dev-tools", data);
export const updateDevTools = (data) => api.put("/dev/dev-tools", data);
export const deleteDevTools = (data) =>
  api.delete("/dev/dev-tools", { data: data });

export const getDevSkills = () => api.get("/dev/dev-techskill");
export const saveDevSkills = (data) => api.post("/dev/dev-techskill", data);
export const updateDevSkills = (data) => api.put("/dev/dev-techskill", data);
export const deleteDevSkills = (data) =>
  api.delete("/dev/dev-techskill", { data: data });

export const getDevAchievement = () => api.get("/dev/dev-achievements");
export const getCurrentemployment = () =>
  api.get("/dev/dev-employment-working");
export const saveDevAchievement = (data) =>
  api.post("/dev/dev-achievements", data);
export const updateDevAchievement = (data) =>
  api.put("/dev/dev-achievements", data);
export const deleteDevAchievement = (data) =>
  api.delete("/dev/dev-achievements", { data: data });

export const updateDevShortDesc = (data) =>
  api.put("/dev/dev-tell-your-story", data);

export const getDevAllTools = () => api.get("/dev/tools-lists");

export const approvalUpdateApi = (data) =>
  api.post("/approval-updates/create", data);
export const getApprovalUpdateApi = (email) =>
  api.get(`/dev/approval-updates?email=${email}`);

//This static data will be replace with api route after api work done from backend
export const getAppliedJob = (data) =>
  api.get(`/dev/get-applied-jobs?page=${data.page}&limit=${data.limit}`);
export const getShortlistedJob = (data) =>
  api.get(`/dev/get-shortlisted-jobs?page=${data.page}&limit=${data.limit}`);
export const getLatestJobList = (data) =>
  api.get(`/dev/latest-job-list?page=${data.page}&limit=${data.limit}`);
export const getVettedSkill = () => api.get("dev/get-vetted-skills");
export const addNewJobAPI = (data) => api.post("/dev/applied-job", data);
export const addNewJobMultiAPI = (data) =>
  api.post("/dev/applied-job-multiple", data);
export const fresherAPI = (userId) =>
  api.put(`/update-fresher?userId=${userId}`);
export const getEnglishTestStatus = () => api.get("/dev/english-test-status");
export const updateEnglishTestStatus = (data) =>
  api.put("/dev/english-test-status", data);
export const postEnglishTestStatus = (data) =>
  api.post("/dev/english-test-status", data);

export const getAppliedForJobApi = (jobId) =>
  api.get(`/client/get-applied-for-job/${jobId}`);
export const getShortlistedForJobApi = (jobId) =>
  api.get(`/client/get-shortlisted-for-job/${jobId}`);

//client API
export const getClientFirstEntryApi = () => api.get("/client/get-first-entry");
export const updateClientFirstEntryApi = () =>
  api.put("/client/set-first-entry");
export const updateClientViewProfile = (devId) =>
  api.put(`/client/update-view-profile?devId=${devId}`);
export const clearViewFilter = () => api.delete("/client/clear-view-profile");
export const getClientEmailSetting = () => api.get("/client/email-settings");

export const updateClientCompnyFirst = () =>
  api.put("/client/update-client-first");

export const getClientProfile = () => api.get("/client/get-client-profile");
export const saveCompanyProfile = (data, clientId) =>
  api.post(`/company-profile?clientId=${clientId}`, data);

export const getSkillCategoryApi = () => api.get("/categories");
export const getSkillCategoryListsApi = (type) =>
  api.get(`/skills-by-category?type=${type}`);
export const getStackCategoryListsApi = () => api.get("/stacks-by-category");
export const getEmailCountApi = (filter) => api.post("admin/dashboard", filter);
export const sendJobMailApi = (email) => api.post("admin/sendmail/jobs", email);
export const getEmailListsApi = () => api.get("/admin/client-emails");
export const getJobTitleListsApi = (data) =>
  api.get(
    `/admin/client-job/title?clientId=${data?.value}&email=${data?.label}`
  );
export const getPlanListsApi = () => api.get("/admin/get-all-plans");
export const getFeatureListsApi = () => api.get("/get-all-features");
export const getPricingSlabApi = () => api.get("/admin/pricing-slabs");
export const addPricingSlabApi = (payload) => api.post("/admin/pricing-slabs",payload?.data);
export const updatePricingSlabApi = (payload) => api.put(`/admin/pricing-slabs/${payload?.id}`,payload?.data);
export const getAddOnListsApi = (data) =>
  data?.page == "open"
    ? api.get(`/get-all-addons?country=${data?.country}`)
    : api.get("/admin/get-all-addons");
export const saveAddOnAdminApi = (data) =>
  api.post("/admin/create-addon", data);
export const updateAddOnAdminApi = (data) =>
  api.put("/admin/update-addon", data);
export const updatePlanStatusApi = (data) =>
  api.post("/admin/change-plan-status", data);
export const updateAddOnStatusApi = (data) =>
  api.post("/admin/change-addon-status", data);
export const addPlanFeatureApi = (data) =>
  api.post("/admin/create-feature", data);
export const updatePlanFeatureApi = (data) =>
  api.put("/admin/update-feature", data);

export const addPricingPlanApi = (data) => api.post("/admin/create-plan", data);
export const updatePricingPlanApi = (data) =>
  api.put("/admin/update-plan", data);

export const createSkillsCategoryApi = (data) =>
  api.post("/admin/create-category", data);
export const updateSkillsCategoryApi = (data) =>
  api.put("/admin/update-category", data);
export const inviteCompanyUserApi = (data) =>
  api.post("/client/user-invite", data);

export const clientUserActivateDeactivateApi = (data) =>
  api.post("/client/active-deactive-client-user", data);

export const getAllJobOfClient = (clientId) =>
  api.get(`/client/get-all-job?clientId=${clientId}`);

export const getCompanyProfile = (clientId, id) =>
  id
    ? api.get(`/company-profile/open?id=${id}&clientId=${clientId}`)
    : api.get(`/company-profile?clientId=${clientId}`);
export const clientSignupApi = (data) => api.post("/sign-up", data);
export const deleteClientJobs = (data) =>
  api.delete(`/client/delete-job/${data}`);

export const getSavedProfile = (data) =>
  api.get(
    `/client/saved-profile-list?page=${data?.page}&limit=${data?.limit}&clientId=${data.clientId}`
  );

export const getClientHiredClient = (data) =>
  api.get(
    `/client/hired-candidate-profile-list?page=${data.page}&limit=${data.limit}`
  );

export const getClientAllJobList = (data, id = null) =>
  id
    ? api.get(`/get-posted-jobs?page=${data.page}&limit=${data.limit}&id=${id}`)
    : api.get(
        `/client/get-posted-jobs?page=${data.page}&limit=${data.limit}&status=${data.status}&clientId=${data.clientId}`
      );

export const getClientAllJobTitlesApi = () =>
  api.get(`/client/get-jobs-titles`);

export const setJobApprovedOrReject = (jobData) =>
  api.put(`/admin/job-approved-or-reject/${jobData.jobId}`, jobData.data);

export const getClientDevList = (data, filterData) =>
  // api.post(
  //   `/client/search-dev?page=${data.page}&limit=${data.limit}`,
  //   filterData
  // );
  api.post(
    `/client/search-dev?page=${data.page}&limit=${data.limit}`,
    filterData
  );

export const addNewJobPostAPI = (data) => api.post("/client/add-job", data);

export const updateClientJob = (data) =>
  api.post("/client/get-job-details", data);

export const updateClientJobData = (data) =>
  api.put("/client/update-client-job", data);

export const getAllSkillsListClient = (type, search) =>
  api.get(`/client/skill-lists?type=${type}&search=${search}`);
export const getAllStacksListClient = (search) =>
  api.get(`/client/stack-lists?search=${search}`);

export const getSpeakDevListApi = () =>
  api.get(`/interview/get-interview-request`);

export const clientDirectContact = (data) =>
  api.post("/client/client-direct-contact", data);

export const getDeveloperData = (devId) =>
  api.post("/client/get-dev-profile", devId);

export const speakWithDeveloper = (data) =>
  api.post("/client/speak-with-dev", data);

export const getClientJobDetail = (data, pageData) =>
  api.post(
    `/client/get-job-with-applicants?page=${pageData.page}&limit=${pageData.limit}`,
    data
  );

export const removeFromShortListsClients = (devId) =>
  api.delete(`/client/delete-shortlisted-profile/${devId}`);
export const removeFromHiredListsClients = (devId) => ({
  data: {
    statusValue: 200,
    statusCode: "SUCCESS",
    message: "Remove from Hired successfully...",
  },
});

export const assignToJob = (data) =>
  api.post("/client/assign-job-to-dev", data);

export const unassignToJob = (data) =>
  api.post("/client/unassign-job-to-dev", data);

export const companyUser = (company, pageData) =>
  api.get(
    `/client/company-user?company=${company}&page=${pageData?.page}&limit=${pageData?.limit}&search=${pageData.search}`
  );

export const companyAllActiveUser = (company) =>
  api.get(`/client/company-all-active-user?company=${company}`);

export const topFiveSkills = () => ({
  data: [
    {
      skillIcon:
        "https://olibr.s3.ap-south-1.amazonaws.com/resume/37b6e6d1e70aba851664798082592.svg",
      skillName: "SQL (Advance)",
      skillId: "632410f3f94112f92e84ae59",
    },
    {
      skillIcon:
        "https://olibr.s3.ap-south-1.amazonaws.com/resume/765f798371cfdac71661250258215.svg",
      skillName: "C++",
      skillId: "6300c5230338203ed16e36c3",
    },
    {
      skillIcon:
        "https://olibr.s3.ap-south-1.amazonaws.com/resume/1245ba1ee30ff5181661250298513.svg",
      skillName: "Java Intermediate",
      skillId: "6300c4090338203ed16e369d",
    },
    {
      skillIcon:
        "https://olibr.s3.ap-south-1.amazonaws.com/resume/66eca2668e6535a41661250280865.svg",
      skillName: "Django Intermediate",
      skillId: "6300c4f70338203ed16e36b7",
    },
    {
      skillIcon:
        "https://olibr.s3.ap-south-1.amazonaws.com/resume/271577e4347cef991662032505651.svg",
      skillName: "Python Intermediate",
      skillId: "63073e5a1a6b7c651818bccf",
    },
  ],
});

export const updateShortList = (data) =>
  api.put("/client/save-shortlist-profile", data);

export const saveProfile = (data) => api.post("/client/save-profile", data);
export const saveClientHiringSkills = (data) =>
  api.put("/client/save-hiringSkills", data);

export const getSelectedSkills = () =>
  api.get("/client/get-saved-hiringSkills");
export const updateClientsJobStatus = (data) =>
  api.put("/client/update-client-job-status", data);
export const unsavedProfile = (devId) =>
  api.put(`/client/unsaved-profile/${devId}`);

export const updateAppliedJobStatus = (data) =>
  data?.feedBack
    ? api.put(
        `/client/applied-job-status/?appliedId=${data._id}&status=${data.status}`,
        { feedBack: data?.feedBack }
      )
    : api.put(
        `/client/applied-job-status/?appliedId=${data._id}&status=${data.status}`
      );

export const clearJobComment = (id) =>
  api.put(`/client/clear-job-comment?appliedId=${id}`);
export const UpdateJobComment = (id, data) =>
  api.put(`client/update-candidate/comment?appliedId=${id}`, data);
export const saveJobComment = (id, data) =>
  api.put(`client/add-candidate/comment?appliedId=${id}`, data);
export const deleteJobComment = (id, data) =>
  api.put(`client/delete-candidate/comment?appliedId=${id}`, data);

export const updateFaqStatusApi = (data) =>
  api.put(`/admin/update-faq-status`, data);

export const updateFaqGroupStatusApi = (data) =>
  api.put(`/admin/update-faq-group-status`, data);

//Interview
export const saveScheduleInterview = (data) =>
  api.patch("/client/scheduleInterview", data);
export const saveScheduleInterviewByDev = (data) =>
  api.patch("/dev/scheduleInterview", data);
export const cancelScheduleInterviewByDev = (data) =>
  api.patch("/dev/cancelInterview", data);

export const cancelScheduleInterview = (data) =>
  api.patch("/client/cancelInterview", data);
export const acceptInterviewRequest = (data) =>
  api.patch("/client/acceptInterview", data);
export const getScheduledInterviews = (data) =>
  api.get("/client/getInterviewsData" + data);
export const acceptInterviews = (data) =>
  api.patch("/client/acceptInterview" + data);

export const getScheduledInterviewsDev = (data) =>
  api.get("/dev/getInterviewsData" + data);

export const setInterviewSlotDev = (data) =>
  api.patch("/dev/setInterviewSlotDev", data);

export const convertImageApi = (data) => api.post("/convert-image", data);
export const updateTermApi = () => api.put("/update-term-condition");
export const clearFeatureApi = () => api.put("/clear-new-fature");
export const getFeatureApi = () => api.get("/new-fature");
export const getSitemapUrlApi = (forType) =>
  api.get(`/sitemap?forType=${forType}`);
export const getPricingPlanApi = (payload) =>
  payload?.excludeActive
    ? api.get(
        `/client/get-all-plans?country=${payload?.country}&billingInterval=${payload?.planType}&actionPage=${payload?.actionPage}`
      )
    : api.get(
        `/get-all-plans?country=${payload?.country}&billingInterval=${payload?.planType}&actionPage=${payload?.actionPage}`
      );

export const startPhonePayPaymentApi = (data) =>
  api.post("/client/phonepe/process-payment", data);
export const startStripePaymentApi = (data) =>
  api.post("/client/stripe/process-payment", data);
export const checkoutPlanAPI = (data) =>
  api.post("/client/checkout", data?.data);
export const getCompanyJobsAPI = (payload) =>
  api.get(
    `/get-company-jobs?page=${payload?.pageData.page}&limit=${payload?.pageData?.limit}&clientId=${payload?.clientId}`
  );
  export const addDevSkillAPI = (data) =>
  api.post(`/dev-skills-info?userId=${data?.devId}`, data?.data);
  export const getPendingSkillAPI = () =>api.get("/admin/approval-skill-list")
  export const updatePendingSkillApi = (data) =>api.post("/admin/approve-skill",data)