import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { devActivation } from "../../service/action/developer";
import { useEffect } from "react";
import { BarLoader } from "react-spinners";
import "./index.css";
import { Icon } from "../icon";
const UnsubscribeComponent = () => {
  const { userId, emailType } = useParams();

  const { isSubscriptionLoading } = useSelector(
    (state) => state.developerReducer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!userId && !!emailType) {
      let data = {
        userId,
        emailType,
        status: false,
      };
      dispatch(devActivation(navigate, data));
    }
  }, [userId, emailType]);

  return (
    <div className="loading-div">
      {isSubscriptionLoading ? (
        <div className="loading-box">
          <BarLoader width={"80%"} height={"10px"} color="#3ba0aa" />
        </div>
      ) : (
        <div className="loading-box">
          <div className="unsub-noti">
            <Icon name={"unsubs"} />
            <h1>Unsubscribed!</h1>
            <p>
              You have successfully unsubcribed the this email notification!
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default UnsubscribeComponent;
