import React from 'react'

const Feedback = ({message}) => {
  return (
    <div>
        <p>
            {message}
        </p>
    </div>
  )
}

export default Feedback