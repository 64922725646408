import React, { Fragment, useState } from 'react'
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { Icon } from '../icon';
import { olibrToast } from '../toaster';
import Modal from '../modal/ModalBox';

const OlibrShareLink = ({isOpen, baseUrl, title, onClose}) => {

    const shareContent = () => {
        let sendurl =
          baseUrl;
          const copyLink=async ()=>{
            await navigator.clipboard.writeText(sendurl);
            olibrToast.success("Link Copied!")
          }
        return (
          <Fragment>
            <div className="social-share-buttons">
              <span>
                <WhatsappShareButton
                  style={{ height: "64px", width: "64px", position: "absolute" }}
                  url={sendurl}
                />
                <WhatsappIcon size={64} round={true} />
              </span>
              <span>
                <FacebookShareButton
                  style={{ height: "64px", width: "64px", position: "absolute" }}
                  url={sendurl}
                />
                <FacebookIcon size={64} round={true} />
              </span>
              <span>
                <LinkedinShareButton
                  style={{ height: "64px", width: "64px", position: "absolute" }}
                  url={sendurl}
                />
                <LinkedinIcon size={64} round={true} />
              </span>
              <span>
                <EmailShareButton
                  style={{ height: "64px", width: "64px", position: "absolute" }}
                  url={sendurl}
                />
                <EmailIcon size={64} round={true} />
              </span>
    
              <span className="copy-link-btn" onClick={copyLink}>
              <Icon name="copy-link" /> Copy Link</span>
            </div>
          </Fragment>
        );
    };

  return (
    <Fragment>
        <Modal
        open={isOpen}
        title={title ? title : ""}
        onClose={onClose}
        content={shareContent}
        isFooterView={false}
      />
    </Fragment>
  )
}

export default OlibrShareLink