import React from "react";
import ArrowRight from "../../assets/img/icons/arrow-right.svg";
import BlogImg from "../../assets/img/blog.jpg";

const Blogsection = ({ recentBlogs }) => {

  return (
    <section className="blog">
      <div className="container">
        <div className="blog-container" id="homeblogcard">
          <h2 className="section-title">Our Blog</h2>
          <h4 className="section-sub-title">
            Get in-depth insight into recent trends and innovation in business
            and technology through well-researched articles by industry experts
          </h4>
          {/* <div className="blog-left">
            <div className="blog-card-home">
              <a href={recentBlogs?.[0]?.blogLink}>
                <figure className="blog-img">
                  <img
                    height={210}
                    src={
                      !!recentBlogs?.[0]?.coverCity
                        ? recentBlogs?.[0]?.coverCity
                        : BlogImg
                    }
                    alt=""
                  />
                </figure>
                <div className="blog-block">
                  <div className="blogger">
                    <figure className="blogger-img">
                      <img src={UserImg} alt="" />
                    </figure>
                    <div className="blogger-detail-bottom">
                      <span>{recentBlogs?.[0]?.authorName}</span>
                      <p>{recentBlogs?.[0]?.authorDesg}</p>
                    </div>
                  </div>
                  <div className="blog-content">
                    <h3>{recentBlogs?.[0]?.blogTitle}</h3>
                    <div
                      className="blog-cont"
                      dangerouslySetInnerHTML={{
                        __html: recentBlogs?.[0]?.blogContent
                      }}
                    ></div>
                  </div>
                  <div className="blog-post">
                    <span className="blog-date">
                      {new Date(recentBlogs?.[0]?.publishedDate).toDateString()}
                    </span>
                    <a className="link">
                      <img src={ArrowRight} alt="" />
                    </a>
                  </div>
                </div>
              </a>
            </div>
          </div> */}
          <div className="blog-right">
            {recentBlogs?.map(
              (blogItem, i) =>
                (
                  <div
                    className="blog-card-home"
                    key={blogItem?.id}
                  >
                    <a href={blogItem?.blogLink}>
                <figure className="blog-img">
                  <img
                    height={210}
                    src={
                      // `https://ik.imagekit.io/olibr/blogimages/`blogItem?.coverImage.split("/").pop()
                      // !!blogItem?.coverImage
                      //   ? blogItem?.coverImage
                      //   : BlogImg
                      blogItem?.coverImage
                      ? `https://ik.imagekit.io/olibr/blogimages/${blogItem.coverImage.split("/").pop()}`
                      : ""
                    }
                    alt=""
                    loading="lazy"
                  />
                </figure>
                <div className="blog-block">
                  <div className="blogger">
                    <figure className="blogger-img">
                      <img src="https://ik.imagekit.io/olibr/assets/tr:w-50,h-50/user-image.jpg" alt="" />
                    </figure>
                    <div className="blogger-detail-bottom">
                      <span>{blogItem?.authorName}</span>
                      <p>{blogItem?.authorDesg}</p>
                    </div>
                  </div>
                  <div className="blog-content">
                    <h3>{blogItem?.blogTitle}</h3>
                    <div
                      className="blog-cont"
                      dangerouslySetInnerHTML={{
                        __html: blogItem?.blogContent,
                      }}
                    ></div>
                  </div>
                  <div className="blog-post">
                    <span className="blog-date">
                      {new Date(blogItem?.publishedDate).toDateString()}
                    </span>
                    <a className="link">
                      <img src="https://ik.imagekit.io/olibr/icons/tr:w-17,h-10/arrow-right.svg" alt="" />
                    </a>
                  </div>
                </div>
              </a>
                  </div>
                )
            )}
          </div>
          <div className="btn-block">
            <a href="https://olibr.com/blog" className="link">
              See More <img src="https://ik.imagekit.io/olibr/icons/tr:w-17,h-10/arrow-right.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blogsection;
