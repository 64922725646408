import * as types from "../index";

export const signUp = (data, navigate) => ({
  type: types.CLIENT_SIGNUP,
  navigate,
  data,
});
export const clientSavedProfileList = (navigate, pageData,count=false) => ({
  type: types.GET_CLIENT_SAVED_PROFILE,
  navigate,
  count,
  pageData,
});


export const updateViewProfile = (navigate, devId, user, setUser) => ({
  type: types.UPDATE_CLIENT_VIEW_PROFILE,
  navigate,
  devId,
  setUser,
  user,
});
export const clearFilterView = (navigate, reset) => ({
  type: types.CLEAR_VIEW_FILTER,
  navigate,
  reset,
});
export const clientHiredCandidates = (navigate, pageData) => ({
  type: types.GET_CLIENT_HIRED_CANDIDATES,
  navigate,
  pageData,
});
export const companyUserListing = (navigate, company, pageData) => ({
  type: types.GET_COMPANY_USER,
  navigate,
  company,
  pageData,
});
export const getAllActiveUserAction = (navigate, company) => ({
  type: types.COMPANY_ALL_ACTIVE_USER,
  navigate,
  company
});
export const getAllClientJobLists = (navigate, pageData, id) => ({
  type: types.GET_CLIENT_ALL_JOBLISTS,
  navigate,
  pageData,
  id,
});

export const jobRefreshAction = (navigate, jobId, pageData)=>({
  type: types.JOB_REFRESH,
  jobId,
  pageData,
  navigate
})
export const userActivateDeactivateAction = (navigate, data, onDone) => ({
  type: types.CLIENT_ACTIVATE_DEACTIVATE_USER,
  navigate,
  data,
  onDone,
});
export const getAllClientJobTitles = (navigate) => ({
  type: types.GET_CLIENT_ALL_JOBTITLES,
  navigate,
});

export const getAppliedForJobAction = (jobId, navigate) => ({
  type: types.GET_APPLIED_FOR_JOB,
  jobId,
  navigate,
});

export const getAllJobOfClientAction = (navigate, clientId) => ({
  type: types.GET_ALL_JOB_OF_CLIENT,
  navigate,
  clientId,
});

export const getShortlistedForJobAction = (jobId, navigate) => ({
  type: types.GET_SHORTLISTED_FOR_JOB,
  jobId,
  navigate,
});

export const updateClientJobStatus = (navigate, setCloseConfirm, status) => ({
  type: types.UPDATE_CLIENT_JOB_STATUS,
  navigate,
  setCloseConfirm,
  status,
});

export const getClientDevLists = (
  navigate,
  pageData,
  filterData,
  setModal = null,
  firstTime=false,
  clearFilter=false
) => ({
  type: types.GET_CLIENT_DEV_LISTS,
  navigate,
  pageData,
  filterData,
  setModal,
  firstTime,
  clearFilter
});

export const unsavedProfileAction = (
  navigate,
  fetchSavedProfileData,
  devId
) => ({
  type: types.UNSAVED_PROFILE,
  navigate,
  fetchSavedProfileData,
  devId,
});

export const getTopFiveSkills = (navigate) => ({
  type: types.GET_TOP_FIVE_SKILL,
  navigate,
});
export const getClientJob = (navigate, jobId,setQuestion) => ({
  type: types.GET_CLIENT_JOB,
  navigate,
  jobId,
  setQuestion
});

export const getAllSkillClient = (navigate, dataType, search = "") => ({
  type: types.GET_CLIENT_SKILL_LISTS,
  navigate,
  dataType,
  search,
});

export const ClearClientProfileComment = (navigate,id,setFeedBack,getData) => ({
  type: types.CLEAR_PROFILE_FEEDBACK,
  navigate,
  id,
  setFeedBack,
  getData
});


export const getAllStackClient = (navigate, search) => ({
  type: types.GET_CLIENT_STACK_LISTS,
  navigate,
  search,
});

export const saveClientHiringSkill = (navigate, data) => ({
  type: types.SAVE_CLIENT_HIRING_SKILLS,
  navigate,
  data,
});

export const updateNewJobClient = (data, navigate) => ({
  type: types.UPDATE_CLIENT_JOB,
  navigate,
  data,
});
export const addNewJobClient = (data, navigate) => ({
  type: types.ADD_NEW_JOB_POST,
  navigate,
  data,
});
export const clientDirectContact = (navigate, data) => ({
  type: types.CLIENT_DIRECT_CONTACT,
  navigate,
  data,
});

export const getDevDetails = (navigate, devId) => ({
  type: types.GET_DEV_DETAILS,
  navigate,
  devId,
});
export const speakWithDev = (navigate, data) => ({
  type: types.SPEAK_WITH_DEV,
  navigate,
  data,
});
export const getClientJobDetails = (navigate, jobId, pageData = null) => ({
  type: types.GET_CLIENT_JOB_DETAIL,
  navigate,
  jobId,
  pageData,
});

export const removeFromShortlist = (navigate, devId, jobId = null) => ({
  type: types.REMOVE_FROM_SHORT_LIST_CLIENT,
  navigate,
  devId,
  jobId,
});
export const removeFromClientHired = (navigate, devId, pageData) => ({
  type: types.REMOVE_FROM_HIRED_LIST_CLIENT,
  navigate,
  devId,
  pageData,
});

export const deleteClientJobs = (navigate, data) => ({
  type: types.DELETE_CLIENT_JOB,
  navigate,
  data,
});

export const assignToJob = (navigate, fetchSavedProfileData, data) => ({
  type: types.ASSIGN_TO_JOB,
  navigate,
  fetchSavedProfileData,
  data,
});

export const unassignToJob = (navigate, fetchSavedProfileData, data) => ({
  type: types.UNASSIGN_TO_JOB,
  navigate,
  fetchSavedProfileData,
  data,
});

export const updateShortLists = (navigate, userName, data) => ({
  type: types.UPDATE_SHORT_LISTS,
  navigate,
  userName,
  data,
});

export const updateAppliedJobStatus = (
  navigate,
  getAppliedCandidateList,
  data,
  socket,
  closeModal=false
) => ({
  type: types.UPDATE_APPLIED_JOB_STATUS,
  navigate,
  getAppliedCandidateList,
  data,
  socket,
  closeModal
});

export const updateCandidateComments = (navigate,id,data,getData) => ({
  type: types.UPDATE_CANDIDATE_COMMENT,
  navigate,
  getData,
  id,
  data
});

export const addCandidateComments = (navigate,id,data,getData) => ({
  type: types.ADD_CANDIDATE_COMMENT,
  navigate,
  getData,
  id,
  data
});

export const deleteCandidateComments = (navigate,id,data,getData) => ({
  type: types.DELETE_CANDIDATE_COMMENT,
  navigate,
  getData,
  id,
  data
});
export const saveProfile = (
  navigate,
  userName,
  devId,
  pageData,
  filterData = null
) => ({
  type: types.SAVE_PROFILE,
  navigate,
  userName,
  devId,
  pageData,
  filterData,
});


export const getSelectedSkillOrStack = (navigate) => ({
  type: types.GET_SELECTED_SKILLS,
  navigate,
});

export const getCompanyProfile = (navigate, clientId, id) => ({
  type: types.GET_COMPANY_PROFILE,
  navigate,
  clientId,
  id,
});
export const saveCompanyProfile = (navigate, data,clientId) => ({
  type: types.SAVE_COMPANY_PROFILE,
  navigate,
  data,
  clientId
});
export const updateVisitFirstCompnyProfile = () => ({
  type: types.UPDATE_FIRST_COMPANY_PROFILE,
});
export const getClientFirstEntryAction = (navigate) => ({
  type: types.GET_CLIENT_FIRST_ENTRY,
  navigate,
});

export const updateClientFirstEntryAction = (navigate) => ({
  type: types.UPDATE_CLIENT_FIRST_ENTRY,
  navigate,
});

export const getClientEmailSettings = (navigate) => ({
  type: types.CLIENT_EMAIL_AUTO,
  navigate,
});

export const deleteUserProfile = (
  navigate,
  userId,
  closeModal,
  filter,
  pageData,
  userType = "client"
) => ({
  type: types.DELETE_USER_PROFILE,
  navigate,
  userId,
  filter,
  pageData,
  closeModal,
  userType,
  isRoutingDisable: true,
});

export const inviteUserAction = (navigate, data, modalState) => ({
  type: types.CLIENT_INVITE_USER,
  navigate,
  data,
  modalState,
});

//Interview
export const getInterviewsData = (navigate, filters) => ({
  type: types.GET_INTERVIEWS_DATA,
  navigate,
  filters,
});

export const scheduleInterview = (id, navigate, interviewData, socket) => ({
  type: types.SCHEDULE_INTERVIEW,
  id,
  navigate,
  interviewData,
  socket
});

export const acceptInterview = (navigate, slot, onSlotAcceptingDone,socket) => ({
  type: types.ACCEPT_INTERVIEW,
  navigate,
  slot,
  onSlotAcceptingDone,
  socket
});

export const rescheduleInterview = (id, navigate, interviewData, socket) => ({
  type: types.RESCHEDULE_INTERVIEW,
  id,
  navigate,
  interviewData,
  socket
});

export const cancelInterview = (id, navigate, interviewData, socket) => ({
  type: types.CANCEL_INTERVIEW,
  id,
  navigate,
  interviewData,
  socket
});

export const DevFilterLists = (navigate,pageData,filter,firstTime) => ({
  type: types.GET_DEV_FILTER,
  navigate,
  pageData,
  firstTime,
  filter,
});

export const getSubscriptionSummary = (navigate,cb) => ({
  type: types.GET_SUMMARY_SUBSCRIPTION,
  navigate,
  cb
});
export const getClientPaymentHistory = (navigate,clientId) => ({
  type: types.GET_CLIENT_PAYMENT_HISTORY,
  navigate,
  clientId
});

export const cancelActivePlanAction = (navigate) => ({
  type: types.GET_CANCEL_ACTIVE_PLAN,
  navigate,
});

export const checkRestrictionClient=(payload)=>({
 type:types.CHECK_CLIENT_RESTRICTION_PAGE,
 payload
})
export const deleteEmailAction=(payload)=>({
  type:types.DELETE_EMAIL,
  payload
 })