import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { aboutusOG, contactUsOG } from "../imageLinks";
import NoFollow from "../../../common/staticData/seo/NoFollow";

const Contact = ({ baseUrl }) => {
  return (
    <Helmet>
      <title>Contact Us for enquiries | Olibr</title>

      <meta charset="UTF-8" />
      <meta name="title" content="Contact Us for enquiries | Olibr" />

      <meta
        name="description"
        content="Contact us if you have any questions about the services we offer at Olibr."
      ></meta>
      <link rel="canonical" href={`${baseUrl}/contact-us`} />
      <meta name="robots" content={NoFollow()}></meta>

      <meta
        name="keywords"
        content="Contact Us, Contact Olibr, Contact us to register and hire remote developers, hire developers, Contact us for jobs,"
      />

      <meta name="language" content="english"></meta>
      <html lang="en" />
      <meta name="url" content={`${baseUrl}/contact-us`} />
      <meta name="identifier-URL" content={`${baseUrl}/contact-us`}></meta>
      <meta name="referrer" content="origin-when-crossorigin"></meta>
      <link rel="image_src" href={contactUsOG} />
      <meta name="copyright" content="olibr.com" />

      <meta property="og:title" content="Contact Us for enquiries | Olibr" />
      <meta
        property="og:description"
        content="Contact us if you have any questions about the services we offer at Olibr"
      />
      <meta property="og:url" content={`${baseUrl}/contact-us`} />
      <meta property="og:image" content={contactUsOG} />
      <meta property="og:site_name" content="Olibr" />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@olibr" />
      <meta property="twitter:domain" content="olibr.com" />
      <meta property="twitter:url" content={`${baseUrl}/contact-us`} />
      <meta name="twitter:title" content="Contact Us for enquiries | Olibr" />
      <meta
        name="twitter:description"
        content="Contact us if you have any questions about the services we offer at Olibr"
      />
      <meta name="twitter:image" content={contactUsOG} />

      <meta property="og:type" content="business.business" />
      <meta
        property="business:contact_data:street_address"
        content="26 Arrowhead Ln"
      />
      <meta property="business:contact_data:locality" content="Menlo Park" />
      <meta property="business:contact_data:region" content="CA" />
      <meta property="business:contact_data:postal_code" content="94025" />
      <meta property="business:contact_data:country_name" content="US" />

      {/* ------Apple Devices ------ */}
      <meta
        name="apple-mobile-web-app-title"
        content="Contact Us for enquiries | Olibr"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-touch-fullscreen" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black"></meta>

      {/* ----------JSON LD Schema for SEO------ */}

      <script type="application/ld+json">
        {JSON.stringify(
          // Breadcrumbs schema--
          {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: `${baseUrl}/`,
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Contact Us",
                item: `${baseUrl}/contact-us`,
              },
            ],
          }
        )}
      </script>

      {/* ------------------------------- */}
      {/* ----------JSON LD Schema for SEO------ */}

      <script type="application/ld+json">
        {JSON.stringify(
          // ----Speakable schema----
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            name: "Contact us for General enquiries, jobs, Hiring employees and registrations ",
            speakable: {
              "@type": "SpeakableSpecification",
              xpath: [
                "/html/head/title",
                "/html/head/meta[@name='description']/@content",
              ],
            },
            url: `${baseUrl}/contact-us`,
          }
        )}
      </script>
      {/* ----------------------------- */}
      {/* ----------JSON LD Schema for SEO------ */}

      <script type="application/ld+json">
        {JSON.stringify(
          //  Organisation schema---
          {
            "@context": "https://schema.org",

            "@type": "Organization",

            name: "Olibr.com",

            url: `${baseUrl}/contact-us`,

            logo: "%PUBLIC_URL%/olibr-full-logo.png",

            contactPoint: {
              "@type": "ContactPoint",

              telephone: "000000000",

              contactType: "technical support",
            },

            sameAs: [
              "https://www.facebook.com/olibrcom",

              "https://www.linkedin.com/company/olibrcom/",

              "https://twitter.com/olibr",

              "https://www.instagram.com/olibr",

              "https://www.youtube.com/@olibr",

              "https://in.pinterest.com/olibr/",

              "",

              "https://www.olibr.com/",
            ],
          }
        )}
      </script>
      {/* ----------------------------- */}
      {/* ----------JSON LD Schema for SEO------ */}

      <script type="application/ld+json">
        {JSON.stringify(
          // ------website/webpage schema-----
          {
            "@context": "https://www.schema.org",

            "@type": "WebSite",

            name: "Contact us for General enquiries, jobs, Hiring employees and registrations ",

            url: `${baseUrl}/contact-us`,
          }
        )}
      </script>
    </Helmet>
  );
};

export default Contact;
