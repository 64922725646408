import React, { useState } from 'react';
import PropTypes from 'prop-types';

const AchivementSection = ({ devAchievement }) => {
  const [expanded, setExpanded] = useState(false);

  // const toggleExpanded = () => {
  //   setExpanded(!expanded);
  // };

  const toggleExpanded = (itemId) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [itemId]: !prevExpanded[itemId],
    }));
  };

  return !!devAchievement?.length && (
    <div className="resume-outer margin-top-40">
      <div className="upper-title">
        <h4 className="ach-name">Achievements</h4>
      </div>
      <div className="resume-block">
        <div className="inner achievements">
          <div className="text">
            <ul className="list-style-two margin-top-10">
              {devAchievement?.map((itm, id) => (
                <li key={id}>
                  {/* {expanded || itm.title.split(/\s+/).length <= 50
                    ? itm.title
                    : `${itm.title.split(/\s+/).slice(0, 50).join(' ')}... `}
                  {itm.title.split(/\s+/).length > 50 && (
                    <button onClick={toggleExpanded} className='theme-color-txt'>
                      {expanded ? '\u00A0Read Less' : 'Read More'}
                    </button>
                  )} */}
                  {itm.title.split(" ").length > 50 && !expanded[itm._id]
                      ? itm.title.split(" ").slice(0, 50).join(" ") + "..."
                      : itm.title}
                    {itm.title.split(" ").length > 50 && (
                      <button
                        className="theme-color-txt"
                        onClick={() => toggleExpanded(itm._id)}
                      >
                        {expanded[itm._id]
                          ? "\u00A0Read Less"
                          : "\u00A0Read More"}
                      </button>
                    )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

AchivementSection.propTypes = {
  devAchievement: PropTypes.array,
};

export default AchivementSection;