import React, { useEffect, useState } from "react";
import LabelInputText from "../common/LabelInputText";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCity } from "../../service/action/common";
import { Controller } from "react-hook-form";
import SelectOption from "../select/SelectOption";
import { changeFormat, changeToNum } from "../functions";
import OlibrDatePicker from "../olibr-datepicker";

const UpdateForm = ({
  slug,
  errors,
  register,
  setValue,
  getValues,
  watch,
  resumeData,
  control,
}) => {
  const { stateList, cityList } = useSelector((state) => state.commonReducer);
  const [city, setCity] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState([]);
  useEffect(() => {
    let tempObj = [];
    if (stateList?.length > 0) {
      stateList?.map((itm) => {
        tempObj?.push({ value: itm?.isoCode, label: itm?.name });
      });
    }
    setState(tempObj);
  }, [stateList]);

  const stateChange = watch("devState");
  const devCity = watch("devCity");
  useEffect(() => {
    if (stateChange) {
      dispatch(
        getCity({ countryCode: "IN", stateCode: stateChange?.value, navigate })
      );
    }
  }, [stateChange]);

  useEffect(() => {
    let tempArr = [];
    cityList?.map((item) => {
      tempArr.push({ value: item?.name, label: item?.name });
    });

    setCity(tempArr);
  }, [cityList]);
  const content = () => {
    if (slug == "email") {
      return (
        <div className="col-12">
          <LabelInputText
            label="Email"
            name="devEmail"
            isDisabled={true}
            isError={errors?.devEmail}
            otherInfo={{
              ...register("devEmail"),
            }}
          />
        </div>
      );
    } else if (slug == "number") {
      return (
        <div className="col-12">
          <label style={{ zIndex: "1000" }} className={`floating-label`}>
            Mobile Number
          </label>
          <input
            {...register("number", {
              minLength: 10,
              maxLength: 10,
              pattern: /^[0-9]+$/i,
            })}
            name="number"
            type="text"
            disabled={true}
            minLength={10}
            maxLength={10}
            className="form-control"
          />
        </div>
      );
    } else if (slug == "password") {
      return (
        <>
          <div className="col-4">
            <input
              {...register("oldpassword", { required: true })}
              type="password"
              placeholder="Current Password"
              className={`${errors?.oldpassword && "form-required"}`}
            />
          </div>
          <div className="col-4">
            <input
              {...register("password", { required: true })}
              type="password"
              className={`${errors?.password && "form-required"}`}
              placeholder="New Password"
            />
          </div>
          <div className="col-4">
            <input
              {...register("confirmPassword", {
                required: true,
                validate: (val) => {
                  if (watch("password") != val) {
                    return "Your passwords do no match";
                  }
                },
              })}
              type="password"
              className={`${errors?.confirmPassword && "form-required"}`}
              placeholder="Confirm Password"
            />
          </div>
        </>
      );
    } else if (slug == "address") {
      setValue("devAddress", resumeData?.devAddress);
      setValue("devAddress2", resumeData?.devAddress2);
      setValue("devPinCode", resumeData?.devPinCode);
      return (
        <>
          <div className="col-6">
            <LabelInputText
              label="Address 1*"
              name="devAddress"
              isError={errors?.devAddress}
              otherInfo={{
                ...register("devAddress", { required: true }),
              }}
            />
          </div>
          <div className="col-6">
            <LabelInputText
              label="Address 2"
              name="devAddress"
              isError={errors?.devAddress2}
              otherInfo={{
                ...register("devAddress2", { required: true }),
              }}
            />
          </div>
          <div className="col-6">
            <label className="floating-label">
              <span className={`${errors?.devState ? "text-danger" : ""}`}>
                State*
              </span>
            </label>
            <Controller
              control={control}
              name="devState"
              {...register("devState", { required: true })}
              render={({ field }) => (
                <SelectOption
                  optionArray={state}
                  fieldProp={field}
                  state={stateChange}
                  setState={setValue}
                  fieldName="devCity"
                  closeMenuOnSelect={true}
                  searchable={true}
                />
              )}
            />
          </div>
          <div className="col-6">
            <label className="floating-label">
              <span className={`${errors?.devCity ? "text-danger" : ""}`}>
                City*
              </span>
            </label>
            {/* <select
                            {...register("devCity", { required: true })}
                        >
                            <option></option>
                            {
                                cityList?.map((itm) => (
                                    <option value={itm?.name}
                                        selected={itm?.name == resumeData?.devCity}
                                    >
                                        {itm?.name}</option>
                                ))
                            }

                        </select> */}
            <Controller
              control={control}
              name="devCity"
              {...register("devCity", { required: true })}
              render={({ field }) => (
                <SelectOption
                  optionArray={city}
                  fieldProp={field}
                  state={devCity}
                  closeMenuOnSelect={true}
                  searchable={true}
                />
              )}
            />
          </div>
          <div className="col-6">
            <LabelInputText
              label="Pincode*"
              isError={errors?.devPinCode}
              maxLength={6}
              otherInfo={register("devPinCode", {
                required: true,
                pattern: /^[0-9]+$/i,
              })}
            />
          </div>
        </>
      );
    } else if (slug == "salary") {
      setValue("devCSalar", resumeData?.devCSalar);
      setValue("devESalary", resumeData?.devESalary);
      return (
        <>
          <div className="col-6">
            <LabelInputText
              label={"Current Salary*"}
              isError={errors.devCSalar}
              otherInfo={register("devCSalar", {
                required: true,
                pattern: /^[0-9 ,.]+$/i,
              })}
              handleOnBlur={() =>
                changeFormat("devCSalar", getValues("devCSalar"))
              }
              handleOnFocus={() =>
                changeToNum("devCSalar", getValues("devCSalar"))
              }
            />
          </div>
          <div className="col-6">
            <LabelInputText
              label={"Expected Salary*"}
              isError={errors.devCSalar}
              otherInfo={register("devESalary", {
                required: true,
                pattern: /^[0-9 ,.]+$/i,
              })}
              handleOnBlur={() =>
                changeFormat("devESalary", getValues("devESalary"))
              }
              handleOnFocus={() =>
                changeToNum("devESalary", getValues("devESalary"))
              }
            />
          </div>
        </>
      );
    } else if (slug == "devTimeZone") {
      return (
        <div className="col-12">
          <label
            className={`floating-label ${errors?.devTimeZone && "text-danger"}`}
          >
            Preferred Timezone*
          </label>
          <select
            {...register("devTimeZone", { required: true })}
            name="devTimeZone"
            className="form-control"
            //  disabled={
            //    resumeStatus == "pending" || resumeStatus == "approved"
            //  }
          >
            <option value=""></option>
            <option value="Overlapping Hours">Overlapping Hours</option>
            <option value="Indian Standard Time">Indian Standard Time</option>
            <option value="Flexible">Flexible</option>
          </select>
        </div>
      );
    } else if (slug == "noticePeriod") {
      const noticePeriod = watch("devNoticePeriod");
      const devAvlFrom = watch("devAvlFrom");
      return (
        <>
          <div className="col-6">
            <div className="form-group">
              <label
                className={`floating-label ${
                  errors?.devNoticePeriod && "text-danger"
                }`}
              >
                Notice period (Days)
              </label>
              <select
                className="form-control col-12"
                {...register("devNoticePeriod", { required: !devAvlFrom })}
                disabled={devAvlFrom}
              >
                <option value={""}></option>
                <option value={"Available"}>Available</option>
                <option value={"15 Days"}>15 Days</option>
                <option value={"30 Days"}>30 Days</option>
                <option value={"45 Days"}>45 Days</option>
                <option value={"60 Days"}>60 Days</option>
                <option value={"90 Days"}>90 Days</option>
              </select>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label
                className={`floating-label ${
                  errors?.devAvlFrom && "text-danger"
                }`}
              >
                Available From
              </label>

              <Controller
                control={control}
                {...register("devAvlFrom", {
                  required: noticePeriod ? false : true,
                })}
                name="devAvlFrom"
                render={({ field }) => (
                  <OlibrDatePicker
                    onChange={(e) => {
                      if (!e) {
                        setValue("devAvlFrom", "");
                      } else {
                        field.onChange(new Date(e));
                      }
                    }}
                    disabled={noticePeriod}
                    value={devAvlFrom ? new Date(devAvlFrom) : ""}
                    minDate={new Date()}
                  />
                )}
              />
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <div className="container">
      <div className="row">{content()}</div>
    </div>
  );
};

export default UpdateForm;
