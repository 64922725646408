import React from 'react'
import DateFormate from '../../../../common/DateFormate'
import { splitString } from '../../../../components/functions'
import { Icon } from '../../../../components/icon'

const CommentLists = ({commentsList,comments,setComments,setNewComment}) => {
  return (    
        !!commentsList?.length && (
            <table className="table">
            <thead>
              <tr>
                <th className="column1" >Date</th>
                <th className="column1" >Message</th>
                <th className="column1" >Action</th>
              </tr>
            </thead>
            <tbody>
              {commentsList?.map((data, index) =>data?.message && (
                <tr key={data?._id} className="dev-hover"
                onClick={()=>{
                  setNewComment(true)
                  setComments({...comments,current:data?.message,_id:data?._id})}}
                >
                  <td className="column1">{ data?.date && DateFormate(data?.date)}</td>
                  <td className="column1">{splitString(74,data?.message)}</td>
                  <td className="column1">
                    <span>
                        <Icon name="edit"/>
                    </span>
                    </td>
                </tr>
              ))}
            </tbody>
          </table>
        )
  )
}

export default CommentLists