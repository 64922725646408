import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { monthArr } from "../../../../common/constant/monthArr";
import { allBlogNameAndIdAction } from "../../../../service/action/admin";
import { getBlogCatOpen } from "../../../../service/action/common";

const CommonList = ({ blogListArray, isLoading }) => {

  // const dispatch = useDispatch(); 
  // const navigate = useNavigate();
  // const handleBlogInfo = (blogId) => {
  //   navigate(`/admin/edit-blog/${blogId}`);
  // };

  // const { blogCatOpenList } = useSelector(
  //   (state) => state.commonReducer
  // );
  
  // useEffect(() => {
  //   dispatch(getBlogCatOpen(navigate));
  //   dispatch(allBlogNameAndIdAction(navigate));
  // }, [navigate, dispatch])

  // const [catList, setCatList] = useState([]);
  // useEffect(() => {
  //   let arr = {};
  //   blogCatOpenList?.data?.map(item => {arr[`${item?._id}`] = item?.categoryName});
  //   setCatList(arr);
  // }, [blogCatOpenList])
  return (
    <Fragment>
      {isLoading ? (
        <div style={{ textAlign: "center" }}>
          {" "}
          <ClipLoader color="#3BA0AA" />{" "}
        </div>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th className="column0">S.No</th>
              <th className="column1">Title</th>
              <th className="column1">Author</th>
              <th className="column1">Posted On</th>
            </tr>
          </thead>
          <tbody>
            {blogListArray?.map((dev, index) => (
              <tr
                key={dev._id}
                // onClick={() => handleBlogInfo(dev?._id)}
                className="dev-hover"
              >
                <td
                  className="column0"
                  // onClick={() => handleBlogInfo(dev?._id)}
                >
                  {index + 1}
                </td>
                <td
                  className="column1"
                  // onClick={() => handleBlogInfo(dev?._id)}
                >
                  {dev?.blogTitle}
                </td>
                <td
                  className="column1"
                  // onClick={() => handleBlogInfo(dev?._id)}
                >
                  {dev?.authorName || "-"}
                </td>
               
                <td
                  className="column1 prof-stat"
                  // onClick={() => handleBlogInfo(dev?._id)}
                >
                  {dev?.publishedDate?.split("T")[0]?.split("-")[2]}{" "}
                  {
                    monthArr[
                      parseInt(
                        dev?.publishedDate?.split("T")[0]?.split("-")[1]
                      ) - 1
                    ]
                  }{" "}
                  {dev?.publishedDate?.split("T")[0]?.split("-")[0]}
                </td>

                
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Fragment>
  );
};

export default CommonList;
