import { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "../../../components/icon";
import { forgetPassword } from "../../../service/action/authentication";
import ClipLoaderComp from "../../../components/clipLoader/ClipLoader";

const ForgotPassword = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [resendEmail, SetResendEmail] = useState(false);

  const onSubmit = async (data) => {
    dispatch(forgetPassword(data, SetResendEmail, reset));
  };

  const {forgetPasswordLoading} = useSelector((state) => state.authenticationReducer)

  return (
      <div className="login-page"
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        paddingTop:"100px"
      }}
      >
          <div className="login-right">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="login-form">
                <div className="login-form-header">
                  <figure>
                    <Icon name="olibr-favicon" />
                  </figure>
                  <div className="login-form-text">
                    <h3>Forgot Password</h3>
                    <p>
                      Already have an account?{" "}
                      <Link to="/" className="link">
                        Login Here
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    placeholder="Email"
                    {...register("email", {
                      required: true,
                      pattern: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
                    })}
                    name="email"
                  />
                  <small className="text-danger">
                    {errors.email && "Email is required & valid."}
                  </small>
                </div>

                <div className="form-group">
                  {resendEmail ? (
                    <Link
                      to="/resend-verification-email"
                      className="link forgot-pass"
                    >
                      RESEND EMAIL
                    </Link>
                  ) : (
                    ""
                  )}
                </div>

                <div className="form-group">
                  <button type="submit" className="btn btn-full" disabled={forgetPasswordLoading}>
                    {forgetPasswordLoading ? <ClipLoaderComp color={"white"} size={20} /> : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
      </div>
  );
};

export default ForgotPassword;
