import { Fragment } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const OurGallery = ({ data }) => {
  const imgSlider = () => {
    const images = [];
    data?.companyGallery
      ?.filter((itm) => itm)
      ?.map((item) => {
        images?.push({ original: item, thumbnail: item });
      });
    return (
      <ImageGallery
        items={images}
        showPlayButton={false}
        showFullscreenButton={false}
      />
    );
  };

  return (
    <Fragment>
      {!!data?.companyGallery?.filter((itm) => itm)?.length && (
        <div className="section-gallery" id="gallery">
          <h2>
            <span className="section-category">Our Gallery</span>
          </h2>
          <div className="gallery">{imgSlider()}</div>
        </div>
      )}
    </Fragment>
  );
};

export default OurGallery;
