import React from 'react'

const PageTour = ({ open, skip, data, next, id = "#", nextBtn = "Next", prev = false, className ,total=11}) => {
    return (
        <>
            {
                open && (
                    <div className={`c-box c-box--arrow-top ${className}`}>
                        <div className="num"><span>{data?.srNo}</span> / {total}</div>
                        <h2>{data?.title}</h2>
                        <p>{data?.desc}</p>
                        <div className="row">
                            <div className="col-8">
                                <span className="skip" onClick={skip}>
                                    Skip
                                </span>
                            </div>
                            <div className="col-4">
                                {
                                    prev && (
                                        <a href={id} className="btn" onClick={prev}>{"Prev"}</a>
                                    )
                                }
                                <a href={id} className="btn" onClick={next}>{nextBtn}</a>
                            </div>
                        </div>
                    </div>
                )
            }

        </>
    )
}

export default PageTour