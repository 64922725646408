import { Fragment } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "../../../components/icon";
import { signUp } from "../../../service/action/authentication";
import MetaTagSEO from "../../../components/seo/MetaTagSEO";
import ClipLoaderComp from "../../../components/clipLoader/ClipLoader";

const UserPassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useParams();
  const onSubmit = (data) => {
    const newData = {
      password: data.password,
      userId,
      userType: "Admin",
      fName: data.fName
    };
    dispatch(signUp(newData, navigate));
  };

  const { signUpLoading } = useSelector((state) => state.authenticationReducer);

  return (
    <Fragment>
      <MetaTagSEO helmetName="signup" />
      <div className="login-page">
        <div className="login-block">
          <div className="login-left">
            <h1>Exclusive Network of</h1>
            <h5>
              <span>Best Talent</span>
            </h5>
            <Icon name="login-network" />
          </div>
          <div className="login-right">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="login-form">
                <div className="login-form-header">
                  <figure>
                    <Icon name="olibr-favicon" />
                  </figure>
                  <div className="login-form-text">
                    <h3>Join Our Talent Account</h3>
                    <p>
                      Already have an account?{" "}
                      <Link to="/login" className="link">
                        Login Here
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control ${
                      errors?.fName && "form-required"
                    }`}
                    placeholder="User Name"
                    name="fName"
                    {...register("fName", {
                      required: true,
                    })}
                  />
                  <small className="text-danger">
                    {errors.fName && "User name is required."}
                  </small>
                </div>
                <div className="form-group mb5">
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    {...register("password", {
                      required: true,
                      minLength: 6,
                    })}
                  />
                  <small className="text-danger">
                    {errors.password &&
                      "Password is required & must be 6 character long."}
                  </small>
                </div>
                <div className="form-group mb5" style={{ marginTop: "15px" }}>
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    name="cpassword"
                    {...register("cpassword", {
                      required: true,
                      minLength: 6,
                      validate: (val) => {
                        if (watch("password") !== val) {
                          return "Your passwords do no match";
                        }
                      },
                    })}
                  />
                  <small className="text-danger">
                    {errors.cpassword && "Password doesn't match."}
                  </small>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-full">
                    {signUpLoading ? (
                      <ClipLoaderComp color={"white"} size={20} />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserPassword;
