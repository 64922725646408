import React from 'react'
import SelectOption from '../../../components/select/SelectOption';
import { cityArrFunc, stateArrFunc } from '../../../components/functions';
import OlibrDatePicker from '../../../components/olibr-datepicker';

const Filters = ({clearFilter,setFilterOpen,handleFilter,setFilter,filter,cityList,stateList,skillTestFilter,skillList,stackList,profTestFilter,profileStatusFilter,profileStepCompFilter}) => {
    return (
        <>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label className="floating-label" style={{ zIndex: 0 }}>
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={filter?.fName}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      fName: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label className="floating-label" style={{ zIndex: 0 }}>
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={filter?.lName}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      lName: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label className="floating-label" style={{ zIndex: 0 }}>
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={filter?.email}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      email: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label
                  className="floating-label"
                  style={{
                    background: "white",
                    zIndex: 1,
                    padding: "3px 5px 0px 5px",
                  }}
                >
                  Skill
                </label>
                <SelectOption
                  searchable={true}
                  optionArray={
                    skillList?.data?.length > 0
                      ? skillList?.data?.map((item) => ({
                        value: item._id,
                        label: item.skillName,
                      }))
                      : []
                  }
                  state={filter?.devChooseSkills ? filter?.devChooseSkills : []}
                  onChangeFunction={(e) => {
                    setFilter({ ...filter, devChooseSkills: e });
                  }}
                  isMulti={true}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label
                  className="floating-label"
                  style={{
                    background: "white",
                    zIndex: 1,
                    padding: "3px 5px 0px 5px",
                  }}
                >
                  Stack
                </label>
                <SelectOption
                  searchable={true}
                  optionArray={
                    stackList?.data?.length > 0
                      ? stackList?.data?.map((item) => ({
                        value: item._id,
                        label: item.stackName,
                      }))
                      : []
                  }
                  state={filter?.stackLists ? filter?.stackLists : []}
                  onChangeFunction={(e) => {
                    setFilter({ ...filter, stackLists: e });
                  }}
                  isMulti={true}
                />
              </div>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-12">
              <div className="form-group">
                <label className="floating-label">Introductory Video</label>
                <SelectOption
                  optionArray={devVideoStatus}
                  state={devVideoStatus?.find(
                    (item) => item?.value == filter?.devVideo
                  )}
                  onChangeFunction={(e) => {
                    setFilter({
                      ...filter,
                      devVideo: e?.value,
                    });
                  }}
                  closeMenuOnSelect={true}
                />
              </div>
            </div> */}
            <div className="col-12">
              <div className="form-group">
                <label className="floating-label">Proficiency test</label>
                <SelectOption
                  optionArray={profTestFilter}
                  state={profTestFilter?.find(
                    (item) => item?.value == filter?.devEnglishTest
                  )}
                  onChangeFunction={(e) => {
                    setFilter({
                      ...filter,
                      devEnglishTest: e?.value,
                    });
                  }}
                  closeMenuOnSelect={true}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label className="floating-label">Skill Tests</label>
                <SelectOption
                  optionArray={skillTestFilter}
                  state={skillTestFilter?.find(
                    (item) => item?.value == filter?.devTechTestResult
                  )}
                  onChangeFunction={(e) => {
                    setFilter({
                      ...filter,
                      devTechTestResult: e?.value,
                    });
                  }}
                  closeMenuOnSelect={true}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label className="floating-label">Profile status</label>
                <SelectOption
                  optionArray={profileStatusFilter}
                  state={profileStatusFilter?.find(
                    (item) => item?.value == filter?.status
                  )}
                  onChangeFunction={(e) => {
                    setFilter({
                      ...filter,
                      status: e?.value,
                    });
                  }}
                  closeMenuOnSelect={true}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label className="new-label" style={{ zIndex: 0 }}>
                  Available from
                </label>
                <OlibrDatePicker
                  onChange={(e) => setFilter({ ...filter, devAvlFrom: e })}
                  value={filter?.devAvlFrom ? filter?.devAvlFrom : ""}
                  placeholder={"Available From"}
                  minDate={new Date()}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label className="new-label">Registered On</label>
                <OlibrDatePicker
                  onChange={(e) => setFilter({ ...filter, registrationDate: e })}
                  value={filter?.registrationDate ? filter?.registrationDate : ""}
                  placeholder={"Registered On"}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label className="new-label">Registered From</label>
                <OlibrDatePicker
                  onChange={(e) => setFilter({ ...filter, date_from: e })}
                  value={filter?.date_from ? filter?.date_from : ""}
                  placeholder={"Registered From"}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label className="new-label">To</label>
                <OlibrDatePicker
                  onChange={(e) => setFilter({ ...filter, date_to: e })}
                  value={filter?.date_to ? filter?.date_to : ""}
                  placeholder={"To"}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label className="floating-label" style={{ zIndex: 0 }}>
                  Designation
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={filter?.devDesg}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      devDesg: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label className="new-label" style={{ zIndex: 0 }}>
                  Test Taken From
                </label>
                <OlibrDatePicker
                  onChange={(e) => setFilter({ ...filter, date_test_from: e })}
                  value={filter?.date_test_from ? filter?.date_test_from : ""}
                  placeholder={"From"}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label className="new-label" style={{ zIndex: 0 }}>
                  To
                </label>
                <OlibrDatePicker
                  onChange={(e) => setFilter({ ...filter, date_test_to: e })}
                  value={filter?.date_test_to ? filter?.date_test_to : ""}
                  placeholder={"To"}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label className="floating-label">Profile step completed</label>
                <SelectOption
                  optionArray={profileStepCompFilter}
                  state={profileStepCompFilter?.find(
                    (item) => item?.value === filter?.devStepCompleted
                  )}
                  onChangeFunction={(e) => {
                    setFilter({
                      ...filter,
                      devStepCompleted: e?.value,
                    });
                  }}
                  closeMenuOnSelect={true}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label className="new-label">State</label>
                <SelectOption
                  optionArray={stateArrFunc(stateList)}
                  state={stateArrFunc(stateList)?.find(
                    (item) => item?.value === filter?.state
                  )}
                  onChangeFunction={(e) => {
                    setFilter({
                      ...filter,
                      state: e.value,
                    });
                  }}
                  searchable={true}
                  closeMenuOnSelect={true}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label className="new-label">City</label>
                <SelectOption
                  optionArray={cityArrFunc(cityList)}
                  state={cityArrFunc(cityList)?.find(
                    (item) => item?.value === filter?.city
                  )}
                  onChangeFunction={(e) => {
                    setFilter({
                      ...filter,
                      city: e.value,
                    });
                  }}
                  searchable={true}
                  closeMenuOnSelect={true}
                />
              </div>
            </div>
            <div className="col-3 verified-no">
              <div className="form-group">
                <label  className="new-label">Number Verified</label>
                <span>
                  <input type="checkbox"
                    onChange={(e) => {
                        setFilter({
                          ...filter,
                          numberVerified: !filter?.numberVerified,
                        });
                      }}
                    checked={filter?.numberVerified ? true : false}
                    name="switch" />
                </span>
              </div>
            </div>
            <div className="col-3 verified-no">
              <div className="form-group">
                <label className="new-label">Dev Reviews</label>
                <span>
                  <input 
                   onChange={(e) => {
                    setFilter({
                      ...filter,
                      devReview: !filter?.devReview,
                    });
                  }}
                  checked={filter?.devReview ?true:false} 
                  type="checkbox" name="switch" />
                </span>
              </div>
            </div>
            <div className="col-3 verified-no">
              <div className="form-group">
                <label className="new-label">Profile Snoozed</label>
                <span>
                  <input 
                   onChange={(e) => {
                    setFilter({
                      ...filter,
                      isProfileSnooze: !filter?.isProfileSnooze,
                    });
                  }}
                  checked={filter?.isProfileSnooze ?true:false} 
                  type="checkbox" name="switch" />
                </span>
              </div>
            </div>
            {/* <div className="col-12">
              <label className="new-label">Experience</label>
            </div>
            <div className="col-6">
              <div className="form-group">
                <select
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      fromExpRange: e.target.value,
                    });
                  }}
                  placeholder="From"
                >
                  <option value={""}>{"From"}</option>
                  {new Array(99).fill("d").map((item, index) => (
                    <option value={index + 1}>{index + 1}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <select
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      toExpRange: e.target.value,
                    });
                  }}
                  placeholder="To"
                >
                  <option value={""}>{"To"}</option>
                  {new Array(99).fill("d").map((item, index) => (
                    <option value={index + 1}>{index + 1}</option>
                  ))}
                </select>
              </div>
            </div> */}
          </div>
          <br />
          <div className="col-12">
            <div className="bottom-button">
              <button className="cancelBtn" onClick={() => clearFilter()}>
                CLEAR FILTERS
              </button>
              <button className="cancelBtn" onClick={() => setFilterOpen(false)}>
                CANCEL
              </button>
              <button className="submitBtn themeButton" onClick={handleFilter}>
                APPLY
              </button>
            </div>
          </div>
          <br />
        </>
      );
}

export default Filters