import React from "react"

const LabelInputText = ({
    handleOnBlur = false, handleOnFocus = false,
    label = "", name = "", isDisabled = false,
    type = "text", isError = false,inputClass="", ...rest


}) => {
    return <div className="form-group">
        <label className={label&&"floating-label"}>
            <span className={`${isError ? "text-danger" : ""}`}>{label}</span>
        </label>
        <input
            name={name}
            type={type}
            className={`form-control ${inputClass}`}
            disabled={isDisabled}
            {...rest.otherInfo}
            {...rest}
            onBlur={(e) => {
                !!handleOnBlur && handleOnBlur(e)
            }}
            onFocus={(e) => {
                !!handleOnFocus && handleOnFocus(e)
            }}
        />
        <small className="text-danger"></small>
    </div>
}

export default LabelInputText