import React from "react";
import { useState } from "react";
import Modal from "../modal/ModalBox";
import ProjectModalContent from "./ProjectModal";
import PropTypes from "prop-types"
const ProjectCard = ({project}) => {
    const [openModal, setModal] = useState(false);
    return (
        <div className="candidate-block project-cards">
            <div className="inner-box job-block">
                <h4>{project?.name || project?.projectName}</h4>
                <p className="designation">{project?.url || project?.role}</p>
                <p className="project-desc" dangerouslySetInnerHTML={{__html:project?.description ||project?.projectDescription}}></p>
                <ul className="project-tags job-other-info margin-bottom-20">
                    {
                        project?.skills?.map((itm,index)=>(
                            <li className="time" key={index}>{itm}</li>
                        ))
                    }
                   
                </ul>
                {/* <Link href="#-"> */}
                  <span className="btn-title theme-color-txt pointer" onClick={() => setModal(true)}>View Details</span>
                {/* </Link> */}

                <Modal
                content={() => <ProjectModalContent project={project}/>}
                open={openModal}
                onClose={() => setModal(false)}
                onDone={() => setModal(false)}
                successBtnTitle="SEND"
                size="md"
                hasHeader={true}
                isFooterView={false}
                isCloseAble={true}
              />
            </div>
        </div>
    )
}
ProjectCard.propTypes={
    project:PropTypes.object
}
export default ProjectCard;