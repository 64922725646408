import React from "react";
import Router from "./routes/Router";
import MainContextWrapper from "./context/MainContextWrapper";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { store } from "./service/store";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";

const App = () => {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <MainContextWrapper>
        {/* <link rel="preload" fetchpriority="high" as="image" href="https://ik.imagekit.io/olibr/assets/tr:w-480,h-500/olibr-banner.webp" />
        <link rel="preload" fetchpriority="high" as="image" href="https://ik.imagekit.io/olibr/assets/tr:w-736,h-533/abstract-right-bg.webp" />
        <link rel="preload" fetchpriority="high" as="image" href="https://ik.imagekit.io/olibr/assets/tr:w-736,h-533/abstract-left-bg.webp" /> */}
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
          <Router />
          <ToastContainer
            hideProgressBar
            position="top-center"
            limit={1}
            newestOnTop={true}
            autoClose={2000}
          />
        </MainContextWrapper>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
