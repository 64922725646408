import * as types from "../";

export const getUser = (developerId, navigate, setUser) => ({
  type: types.GET_USER,
  developerId,
  navigate,
  setUser,
});

export const login = (loginData, navigate, setUser, setResendVerify) => ({
  type: types.LOGIN,
  loginData,
  navigate,
  setUser,
  setResendVerify,
});

export const signUp = (signUpData, navigate, setResend) => ({
  type: types.SIGN_UP,
  signUpData,
  navigate,
  setResend,
});

export const forgetPassword = (email, setResendEmail, reset) => ({
  type: types.FORGET_PASSWORD,
  email,
  setResendEmail,
  reset,
});

export const resetPassword = (params, navigate, reset) => ({
  type: types.RESET_PASSWORD,
  params, 
  navigate, 
  reset
});

export const logout = (navigate, setUser, socket, userId) => ({
  type: types.LOGOUT,
  navigate,
  setUser,
  socket,
  userId
});

export const emailVerification = (params, navigate, setColorName) => ({
  type: types.EMAIL_VERIFICATION,
  params, 
  navigate, 
  setColorName
});

export const resendEmailVerification = (email, navigate, reset) => ({
  type: types.RESEND_EMAIL_VERIFICATION,
  email, 
  navigate, 
  reset
});

export const loginWithLinkDin=(navigate)=>({
   type: types.LOGIN_LINKEDIN,
  navigate, 
})

export const checkUser=(tokens)=>({
  type: types.CHECK_USER,
})