import { Fragment } from "react";
import LinkedinIcon from "./../../assets/img/company-profile/linkedin.svg";
import { filterWeb } from "../../common/FilterWebsite";

const CompanyMembers = ({ data }) => {
  return (
    <Fragment>
      {!!data?.managementTeam?.filter(
        (itm) => itm?.memberName && itm?.memberDesg && itm?.memberImage
      )?.length && (
        <div className="members-section" id="team">
          <h2>
            <span className="section-category">Our Leadership</span>
            <span className="section-title">
              Leaders in business transformation
            </span>
          </h2>
          <div className="member-list">
            <ul>
              {data?.managementTeam?.map((item) => item?.memberName && (
                <li>
                  {item?.linkedInUrl && (
                    <div className="linkedin-circle">
                      <a href={filterWeb(item?.linkedInUrl)}>
                        <img style={{ cursor: "pointer" }} src={LinkedinIcon} />
                      </a>
                    </div>
                  )}
                  <figure>
                    <img src={item?.memberImage} alt="" />
                  </figure>
                  <h4>{item?.memberName}</h4>
                  <p>{item?.memberDesg}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CompanyMembers;
