import "./JobManagement.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../components/icon";
import {
  getAllJobs,
  addJob,
  editJob,
  deleteJob,
  getAdminSkillLists,
  getAdminStackLists,
  getJobCatAction,
  getAllIcons,
  sendJobMail,
} from "../../../service/action/admin/index";
import { useDispatch, useSelector } from "react-redux";
import JobLists from "./JobLists";
import AddJob from "./AddJob";
import EditJob from "./EditJob";
import Modal from "../../../components/modal/ModalBox";
import Filter from "./Filter";
import { CheckApplyFilter } from "../../../common/CheckApplyFilter";
import { MainContext } from "../../../context/MainContext";
import { checkPermission, readOnlyCheck } from "../../../components/functions";

function JobManagement() {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(false);
  const {
    user: { access },
    user
  } = useContext(MainContext);
  const readOnly = readOnlyCheck(access, "Job Management");
  const readOnlyEmail = user?.access?.filter(
    (item) => item?.name == "Send Job Email" && item?.readOnly !== true
  );

  const navigate = useNavigate();
  const [pageData, setPageData] = useState({
    page: 1,
    limit: 17,
    search: "",
  });
  const [location, setLocation] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [addJobAdmin, setAddJobAdmin] = useState(false);
  const [editJobAdmin, setEditJobAdmin] = useState({
    edit: false,
    data: {},
  });
  const [filterObj, setFilterObj] = useState({
    jobTitle: "",
    companyName: "",
    jobStatus: "",
    fromDate: "",
    toDate: "",
  });
  useEffect(() => {
    dispatch(
      getAdminSkillLists("profile", navigate, {
        page: 0,
        limit: 1000,
        search: "",
      })
    );
    dispatch(
      getAdminStackLists("stack", navigate, {
        page: 0,
        limit: 1000,
        search: "",
      })
    );
    dispatch(getJobCatAction(navigate));
    dispatch(getAllIcons(navigate));
    
  }, []);

  const { jobCatList, allJobLists, isJobListsLoading } = useSelector(
    (state) => state.adminReducer
  );
  const { socket } = useSelector((state) => state.commonReducer);
  const [categArr, setCategArr] = useState([]);
  const [categObj, setCategObj] = useState({});
  useEffect(() => {
    let arr = jobCatList?.data?.map((item) => ({
      value: item?._id,
      label: item?.categoryName,
    }));
    let obj = {};
    if (arr) {
      for (let i = 0; i < arr.length; i++) {
        obj = { ...obj, [`${arr?.[i]?.value}`]: arr?.[i]?.label };
      }
    }
    setCategArr(arr);
    setCategObj(obj);
  }, [jobCatList]);

  const handleGetAllJob = (pageData, clearJob = false, isClear = false) => {
    dispatch(
      getAllJobs(navigate, pageData, isClear ? {} : filterObj, clearJob)
    );
  };

  useEffect(() => {
    handleGetAllJob(pageData, false);
  }, []);

  const closeModal = () => {
    setAddJobAdmin(false);
  };
  const closeEditModal = () => {
    setEditJobAdmin({ edit: false });
  };

  const handleEdit = (jobItem) => {
    if (checkPermission(readOnly)) return false;
    setEditJobAdmin({ data: jobItem, edit: true });
  };

  const handleDeleteJob = () => {
    dispatch(
      deleteJob(
        navigate,
        editJobAdmin?.data?._id,
        setEditJobAdmin,
        setDeletePreview
      )
    );
  };

  const [skillJobIcon, setSkillJobIcon] = useState("");

  const onHandleSubmit = (data) => {
    data?.hasOwnProperty("tag") && delete data?.tag;
    let s = data?.jobTitle;
    let newstr = "";
    let arr = s.split(" ");
    arr.forEach((item, index) => {
      newstr += index ? `-${item}` : item;
    });
    let jobCatt = data?.jobCategory?.value;
    let isPop = data?.isPopular?.value;
    let isOli = data?.isOlibrJob?.value;
    addJobAdmin === true
      ? dispatch(
        addJob(
          {
            ...data,
            image: skillJobIcon,
            jobCategory: jobCatt,
            isPopular: isPop,
            isOlibrJob: isOli,
            // slug: data?.slug ? data?.slug : newstr,
            jobLocation: location,
          },
          setAddJobAdmin,
          navigate,
          socket
        )
      )
      : dispatch(
        editJob(
          {
            ...data,
            jobCategory: jobCatt,
            isPopular: isPop,
            isOlibrJob: isOli,
            image: skillJobIcon,
            jobLocation: location,
          },
          setEditJobAdmin,
          navigate
        )
      );
  };

  const handleSearch = (e) => {
    handleGetAllJob({ ...pageData, page: 1, search: e.target.value }, true);
    setPageData({ ...pageData, page: 1, search: e.target.value });
  };

  const [deletePreview, setDeletePreview] = useState(false);
  const [isReachedOnFirst, setIsReachedOnFirst] = useState(false);
  const [isReachedOnLast, setIsReachedOnLast] = useState(false);

  useEffect(() => {
    const itemIndex = allJobLists?.data?.findIndex(
      (jobItem) => jobItem._id === editJobAdmin?.data?._id
    );

    if (itemIndex === 0) {
      !isReachedOnFirst && setIsReachedOnFirst(true);
    } else {
      isReachedOnFirst && setIsReachedOnFirst(false);
    }

    if (itemIndex === allJobLists?.data?.length - 1) {
      !isReachedOnLast && setIsReachedOnLast(true);
    } else {
      isReachedOnLast && setIsReachedOnLast(false);
    }
  }, [editJobAdmin?.data]);

  const onPrevious = () => {
    if (!isJobListsLoading) {
      const itemIndex = allJobLists?.data?.findIndex(
        (jobItem) => jobItem._id === editJobAdmin?.data?._id
      );
      if (itemIndex > 0) {
        setEditJobAdmin({
          data: allJobLists?.data?.[itemIndex - 1],
          edit: true,
        });
      }
    }
  };

  const onNext = () => {
    if (!isJobListsLoading) {
      const itemIndex = allJobLists?.data?.findIndex(
        (jobItem) => jobItem._id === editJobAdmin?.data?._id
      );
      if (itemIndex < allJobLists?.data?.length - 1) {
        setEditJobAdmin({
          data: allJobLists?.data?.[itemIndex + 1],
          edit: true,
        });
      }
    }
  };

  const clearFilter = () => {
    setPageData({
      ...pageData,
      page: 1,
    });
    setFilterObj({
      jobTitle: "",
      companyName: "",
      jobStatus: "",
      fromDate: "",
      toDate: "",
    });
    handleGetAllJob({ page: 1, limit: 13 }, true, true);
    setFilter(false);
  };

  const applyFilter = () => {
    setPageData({
      ...pageData,
      page: 1,
    });
    handleGetAllJob({ page: 1, limit: 13 }, true);
    setFilter(false);
  };

const resetJobRef=useRef()
  return (
    <>
      <Modal
        open={filter}
        title={"Filter"}
        content={() => (
          <Filter filterObj={filterObj} setFilterObj={setFilterObj} />
        )}
        onClose={clearFilter}
        onCancel={clearFilter}
        onDone={applyFilter}
      />{" "}
      <div className="main-div-developer">
        <div
          className={`main-div ${editJobAdmin?.edit || addJobAdmin ? "" : "main-div-addition"
            }`}
        >
          <div
            className="main-div-tab"
            style={{
              display:
                addJobAdmin == true && editJobAdmin?.edit == false
                  ? "block"
                  : "none",
            }}
          >
            <AddJob
              location={location}
              setLocation={setLocation}
              onHandleSubmit={onHandleSubmit}
              closeModal={closeModal}
              addJobAdmin={addJobAdmin}
              categList={categArr}
              editJobAdmin={editJobAdmin}
              resetJobRef={resetJobRef}
            />
          </div>

          <div
            className="main-div-tab"
            style={{
              display:
                addJobAdmin == false && editJobAdmin?.edit == true
                  ? "block"
                  : "none",
            }}
          >
            <EditJob
              location={location}
              onPrevious={onPrevious}
              onNext={onNext}
              isReachedOnFirst={isReachedOnFirst}
              isReachedOnLast={isReachedOnLast}
              setLocation={setLocation}
              editJobAdmin={editJobAdmin}
              jobData={editJobAdmin?.data}
              handleDeleteJob={handleDeleteJob}
              closeModal={closeEditModal}
              onHandleSubmit={onHandleSubmit}
              categList={categArr}
              setSkillJobIcon={setSkillJobIcon}
              deletePreview={deletePreview}
              setDeletePreview={setDeletePreview}
            />
          </div>
          <div
            className="blog-list"
            style={{
              display: addJobAdmin || editJobAdmin?.edit ? "none" : "block",
            }}
          >
            <div className="block-header flex-space-between-row">
              <h4 className="">All Jobs</h4>

              <div className="flex-row-item">
                <span>
                  {CheckApplyFilter(filterObj) && (
                    <a
                      className="subQues color-orange mr5"
                      onClick={clearFilter}
                    >
                      REMOVE FILTERS
                    </a>
                  )}
                  <a className="subQues" onClick={() => setFilter(true)}>
                    FILTERS
                  </a>
                </span>
                <span
                  onClick={() => {
                    if (checkPermission(readOnly)) return false;
                    setAddJobAdmin(true);
                    resetJobRef.current?.click();
                    setEditJobAdmin({ edit: false });
                  }}
                >
                  <Icon name="add-icon" />
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                <span
                  className="submitBtn"
                  onClick={() => {
                    if (checkPermission(readOnly)) return false;
                    navigate("/admin/job-categories");
                  }}
                >
                  Category List
                </span>{" "}

                {!!selectedData?.length && <span
                  className="submitBtn"
                  onClick={() => {
                    let data=[]
                    selectedData?.map((itm)=>{
                      data?.push(itm?._id)
                    })
                    dispatch(sendJobMail(navigate,data))
                  }}
                >
                  Send Mail
                </span>}
              </div>
              <div className="search-block col-4">
                <input
                  type="text"
                  className="form-control"
                  value={pageData.search}
                  placeholder="Search Job Titles or Category"
                  onChange={handleSearch}
                />
                <a className="search-btn">
                  <Icon name="search-icon" />
                </a>
              </div>
            </div>
            <br />
            <div className={`table`}>
              <JobLists
                handleGetAllJob={handleGetAllJob}
                pageData={pageData}
                handleEdit={handleEdit}
                setPageData={setPageData}
                categObj={categObj}
                readOnly={readOnly}
                selectable={readOnlyEmail?.length}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                resetJobRef={resetJobRef}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JobManagement;
