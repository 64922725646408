import { Fragment } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Icon } from "../../../components/icon";
import { resetPassword } from "../../../service/action/authentication";
import "./../client-signup/authentication.css";
const ResetPassword = () => {
  const {
    register,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const onSubmit = async (data) => {
    params.newPassword = data.newPassword;
    dispatch(resetPassword(params, navigate, reset))
  };

  return (
    <Fragment>
      <div className="login-page">
        <header className="login-header">
          <Link to="/">
            {/* <img src={OlibrLogo} alt="Olibr Logo" /> */}
            <Icon name="logo-olibr" />
          </Link>
        </header>
        <div className="login-block">
          <div className="login-left">
            <h4>Exclusive Network of</h4>
            <h5>
              <span>Best Talents</span>
            </h5>
            <Icon name="login-network" />
          </div>
          <div className="login-right">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="login-form">
                <div className="login-form-header">
                  <figure>
                  <Icon name="olibr-favicon" />
                  </figure>
                  <div className="login-form-text">
                    <h3>Reset Password</h3>
                    <p>
                      Already have an account?{" "}
                      <Link to="/login" className="link">
                        Login Here
                      </Link>
                    </p>
                  </div>
                </div>

                <div className="form-group mb5">
                  <input
                    type="password"
                    placeholder="New Password"
                    name="newPassword"
                    {...register("newPassword", {
                      required: true,
                      minLength: 6,
                    })}
                  />
                  <small className="text-danger">
                    {errors.newPassword &&
                      "Password is required & must be 6 character long."}
                  </small>
                </div>
                <div className="form-group mb5" style={{ marginTop: "15px" }}>
                  <input
                    type="password"
                    placeholder="New Confirm Password"
                    name="cpassword"
                    {...register("cpassword", {
                      required: true,
                      minLength: 6,
                      validate: (val) => {
                        if (watch("newPassword") !== val) {
                          return "Your passwords do no match";
                        }
                      },
                    })}
                  />
                  <small className="text-danger">
                    {errors.cpassword && "Password doesn't match."}
                  </small>
                </div>

                <div className="form-group" style={{ marginTop: "15px" }}>
                  <button type="submit" className="btn btn-full">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="copyright">© Copyright Olibr All rights reserved.</div>
      </div>
    </Fragment>
  );
}

export default ResetPassword