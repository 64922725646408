import { monthArr } from './constant/monthArr'

const DateFormate = (item, type=false) => {
    if (!item) {
        return " ";
    }

    if(item?.toString()?.includes("GMT")){
        return new Date(item).toDateString().split(' ').slice(1).join(' ');
    }
   
    const date = item?.split("T")[0]?.split("-")[2] + " " +monthArr[parseInt(item?.split("T")[0]?.split("-")[1]) - 1]+ " " + item?.split("T")[0]?.split("-")[0]
    if (type == "withTime") {
        const dates = new Date(item)
        return date.concat(` ${dates?.toLocaleTimeString()}`)
    }
    return date
}

export default DateFormate