import React, { Fragment } from "react";
import TestImage from "../../../../assets/img/icons/english.webp";
import "./test.css";
import { useState ,useEffect} from "react";
import { getDateFormate } from "../../../../components/functions";

const ProficiencyTest = ({ userData, onBack, onNext }) => {
  const [result, setResult] = useState({})
  useEffect(() => {
    setResult(userData?.devEnglishTest[userData?.devEnglishTest?.length -1])
  }, [userData])


  return (
    <Fragment>
      <div className="wizard-block">
        <div className="block-header">
          <h4>English Proficiency Test</h4>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-4">
              <figure>
                <img src={TestImage} alt="English Proficiency Test" />
              </figure>
            </div>
            <div className="col-4" />
            <div className="col-4">
              {result ? (
                <div>
                  <div>Total questions : 20</div>
                  <div>Correct : {result?.correct}</div>
                  <div>
                    Result :{" "}
                    {result?.isPassed ? "Pass" : "Fail"}
                  </div>
                  <div>Attempt Count: {result?.attemptCount}</div>
                  <div>Test Taken : {getDateFormate(result?.attemptAt)}</div>
                </div>
              ) : (
                <div className="not-attempt">Not Attempted</div>
              )}
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="navigation">
        <i
          className="arrow-left"
          style={{ cursor: "pointer" }}
          onClick={onBack}
        ></i>
        <button onClick={onNext} className="link next">
          Next
        </button>
      </div>
    </Fragment>
  );
};

export default ProficiencyTest;
