import React from 'react'
import { Icon } from '../../../icon'

const NoticePeriod = ({openModal,resumeData,DateFormate}) => {
  return (
    <div className="setting-card">
        <div className="row">
          <div className="col-4">
            <h3>
              Preferred Time Zone
              <span onClick={() => openModal("devTimeZone")}>
                <Icon name="edit" />
              </span>
            </h3>
            <p>{resumeData?.devTimeZone ? resumeData?.devTimeZone : "N/A"}</p>
          </div>
          <div className="col-4">
            <h3>
              Notice Period (Days)
              <span onClick={() => openModal("noticePeriod")}>
                <Icon name="edit" />
              </span>
            </h3>
            <p>
              {resumeData?.devNoticePeriod
                ? resumeData?.devNoticePeriod
                : "N/A"}
            </p>
          </div>
          <div className="col-4">
            <h3>
              Available From
              <span onClick={() => openModal("noticePeriod")}>
                <Icon name="edit" />
              </span>
            </h3>
            <p>
              {resumeData?.devAvlFrom
                ? DateFormate(resumeData?.devAvlFrom)
                : "N/A"}
            </p>
          </div>
        </div>
      </div>
  )
}

export default NoticePeriod