export const addJob = "Add Job"
export const positionName = "Position Name"
export const jobType = "Job Type"
export const location = "Location"
export const category = "Category"
export const postedOn = "Posted On"
export const olibrJob = "Olibr Job"
export const addTags = "Add Tags"
export const shortDescription = "Short Description"
export const recruitCRMLink = "Recruit CRM Link"
export const jobStatus = "Job Status"
export const deleteText = "Delete"
export const cancel = "cancel"
export const submit = "submit"
export const position = "Position"
export const statusText = "Status"
export const publishedOn = "Published On"
export const tags = "Tags"