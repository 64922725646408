export const paraOne = `It's time to take your work to a whole new dimension. You've already
been creating magic through codes and programs, but it's now time to
add a new layer to that magic - a layer that is uniquely YOU. We're
inviting you to introduce yourselves to the Olibr community in a way
that is more personal and engaging.`;

export const paraTwo = `Creating a short introduction video not only humanizes your profile, but it also allows you to express your personality, your passions, and your enthusiasm for the work you do. It's your chance to inspire others, to showcase your journey, and to create a deeper connection with the community.`;

export const paraThree = `Remember, the most memorable developers are not just remembered for their codes, they are remembered for their stories, their insights, and their shared experiences. So get creative, be yourself, and let's get this camera rolling!`;

export const instructionBullets = [
  {
    title: "Prepare Your Content",
    para: "Draft a short script of what you're going to say. You might want to include your name, your role, your coding interests, projects you're proud of, and maybe even a fun fact about yourself!",
  },
  {
    title: "Setting Up Your Space",
    para: "Choose a quiet, well-lit place where you can record your video. Make sure there isn't anything too distracting in the background.",
  },
  {
    title: "Record Your Video",
    para: "You can use a smartphone or a webcam to record your video. When recording, make sure to look directly at the camera - this creates a sense of direct communication with your audience. Keep your video within 2-3 minutes.",
  },
  {
    title: "Editing Your Video (Optional)",
    para: "If you want, you can use simple video editing tools to edit your video, add text, images, or background music. There are many free tools available online like iMovie (for iOS) or OpenShot (for Windows, Mac, and Linux). Else, leave the editing to us.",
  },
  {
    title: "Review",
    para: "Watch your video. Does it reflect your personality? Is the audio clear? If you're satisfied, it's ready to go!",
  },
  {
    title: "Upload Your Video",
    para: "Once you're ready, upload your video to Olibr by following the prompts on the video upload page. Make sure your video file is in a supported format (.mp4, .mov, .avi, etc.).",
  },
];

export const congratsVideo = `Congratulations! You've just added a personal touch to your profile.
Your video will help inspire others in the community, and who knows,
you might just inspire yourself too.`

export const lights = "Lights, camera, action! It's time to make your mark, Olibr developers. Let's get those cameras rolling!"

export const creatingVideo = `Creating a video doesn't have to be complicated. Here's a simple
guide on how you can create your video introduction:`