import React from "react";
import "./OlibrDatePicker.css";
import ReactDatePicker from "react-datepicker";

const OlibrDatePicker = ({className, onChange, value, placeholder, isMonthOnly=false, disabled=false, minDate, fieldProp,maxDate=new Date("9999/12/12")}) => {
  return (
    <ReactDatePicker
      onChange={onChange}
      selected={!!value ? value : ""}
      placeholderText={placeholder}
      disabled={disabled}
      dateFormat={"yyyy-MM-dd"}
      showMonthYearPicker={isMonthOnly}
      minDate={minDate}
      isClearable={true}
      maxDate={maxDate}
      className={className}

    />
  );
};

export default OlibrDatePicker;
