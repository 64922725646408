import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import DateFormate from "../../../../common/DateFormate";
import { Fragment } from "react";
const DownloadInvoice = ({ data, user }) => {
  const logo = "https://ik.imagekit.io/olibr/assets/tr:w-150,h-49/logo.png";
  const Hr = ({ style = {} }) => (
    <View
      style={{
        width: "90%",
        margin: 4,
        ...style,
        marginLeft: 0,
        height: 0,
        border: "0.3px solid #444444",
      }}
    ></View>
  );

  return (
    <Document>
      <Page style={{ backgroundColor: "white" }}>
        <View
          style={{
            fontSize: 10,
            display: "flex",
            padding: 25,
            flexDirection: "row",
          }}
        >
          <View style={{ height: 100, width: "40%", color: "#444444" }}>
            <Text>BILLED TO:</Text>
            <Text style={{ fontSize: 10, marginTop: 5 }}>
              Client Name : {user?.fName} {user?.lName}
            </Text>
          </View>
          <View style={{ height: 100, width: "60%" }}>
            <Text style={{ textAlign: "right", marginRight: 24 }}>
              PRODUCT(S) PROVIDED BY :
            </Text>
            <Image
              source={logo}
              style={{ height: 27, width: 70, marginLeft: 160, marginTop: 2 }}
            />
            <Text
              style={{
                textAlign: "right",
                marginRight: 55,
                marginTop: 10,
                color: "#444444",
              }}
            >
              Olibr Resourcing Pvt Ltd
            </Text>
            <Text
              style={{
                textAlign: "right",
                marginRight: 70,
                marginTop: 6,
                color: "#444444",
              }}
            >
             PAN : AADCO9517C
            </Text>
            <Text
              style={{
                textAlign: "right",
                marginRight: 38,
                marginTop: 6,
                color: "#444444",
              }}
            >
              GST#: 06AADCO9517C1ZL
            </Text>
          </View>
        </View>
        <View style={{ color: "#444444", marginLeft: 25 }}>
          {/* Invoice Details */}
          <Text>Invoice</Text>
          <Hr />

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: 11,
              marginTop: 10,
            }}
          >
            <Text style={{ color: "#444444", fontSize: 10, width: 100 }}>
              Invoice Number:
            </Text>
            <Text>{data?.id}</Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: 11,
              marginTop: 4,
            }}
          >
            <Text style={{ color: "#444444", fontSize: 10, width: 100 }}>
              Invoice Date:
            </Text>
            <Text>{DateFormate(new Date())}</Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: 11,
              marginTop: 4,
            }}
          >
            <Text style={{ color: "#444444", fontSize: 10, width: 100 }}>
              Billing Method:
            </Text>
            <Text>{data?.method}</Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: 11,
              marginTop: 4,
            }}
          >
            <Text style={{ color: "#444444", fontSize: 10, width: 100 }}>
              Payment Currency:
            </Text>
            <Text>{data?.currency}</Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: 11,
              marginTop: 4,
            }}
          >
            <Text style={{ color: "#444444", fontSize: 10, width: 100 }}>
              Payment Date:
            </Text>
            <Text>{DateFormate(data?.invoiceDate)}</Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: 11,
              marginTop: 4,
            }}
          >
            <Text style={{ color: "#444444", fontSize: 10, width: 100 }}>
              Activation Date:
            </Text>
            <Text>{DateFormate(data?.invoiceDate)}</Text>
          </View>

          <Hr style={{ marginTop: 20 }} />
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: 11,
              marginTop: 6,
              fontWeight: "bold",
            }}
          >
            <Text style={{ marginLeft: 140, width: 200 }}>PRODUCT</Text>
            <Text></Text>
            <Text>AMOUNT</Text>
          </View>

          <Hr style={{ marginTop: 10 }} />
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: 10,
              marginTop: 6,
              fontWeight: "bold",
            }}
          >
            <Text style={{ marginLeft: 140, width: 120 }}>
              {data?.planName}
            </Text>
            <Text style={{width:80}}>{data?.currency}</Text>
            <Text>{data?.subscriptionCost}</Text>
          </View>
          <Hr style={{ marginTop: 10 }} />

          {data?.addon?.map((item, index) => (
            <Fragment key={index}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: 10,
                  marginTop: 6,
                  fontWeight: "bold",
                }}
              >
                <Text style={{ marginLeft: 140, width: 120 }}>
                  {item?.addonName}
                </Text>
                <Text style={{marginLeft:0,width:80}}>{data?.currency}</Text>
                <Text>{item?.addonCost}</Text>
              </View>
              <Hr style={{ marginTop: 10 }} />
            </Fragment>
          ))}
          {data?.gstAmount && <Fragment>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: 10,
              marginTop: 6,
              fontWeight: "bold",
            }}
          >
            <Text style={{ marginLeft: 140, width: 120 }}>
              GST AMOUNT
            </Text>
            <Text style={{width:80}}>{data?.currency}</Text>
            <Text>{data?.gstAmount}</Text>
          </View>
          <Hr style={{ marginTop: 10 }} />
          </Fragment>}

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: 10,
              marginTop: 26,
              fontWeight: "bold",
            }}
          >
            <Text style={{ marginLeft: 140, width: 120 }}>
              PURCHASES
            </Text>
            <Text style={{width:80}}>{data?.currency}</Text>
            <Text>{data?.amount}</Text>
          </View>
          <Hr style={{ marginTop: 10 }} />
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: 10,
              marginTop: 6,
              fontWeight: "bold",
            }}
          >
            <Text style={{ marginLeft: 100, width: 160 }}>
            TOTAL AT CHECKOUT 
            </Text>
            <Text style={{width:80}}>{data?.currency}</Text>
            <Text>{data?.amount}</Text>
          </View>


         
        </View>
        <View>
        <Text style={{ color: "#444444", fontSize: 10,padding:40,marginTop:200}}>If you have any questions regarding this invoice, please feel free to reach out us at client.support@olibr.com</Text>
        </View>
      </Page>
    </Document>
  );
};

export default DownloadInvoice;
