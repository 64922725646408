import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router'
import { getSkillWithCategory, getStackWithCategory } from '../../../service/action/admin';
import { useState } from 'react';
import SkillLists from './SkillLists';
import "./../../profile-preview/SkillTestResult.css"
import "./SelectSkill.css"
import StackLists from './StackLists';
import { checkExistAllSkill, getSkillIdInCategory, removeArrFromArry } from '../../functions';
import ClipLoaderComp from '../../clipLoader/ClipLoader';
const SelectSkill = ({
    activeTab,
    selectedSkill,
    setSelectedSkill,
    devActivations,
    allSkill,
    setAllSkill,
    setAllStacks,
    allStack,
    saveSkills

}) => {

    const { skillWithCategory, stackWithCategory } = useSelector((state) => state?.adminReducer)

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSkillWithCategory(navigate))
        dispatch(getStackWithCategory(navigate))
    }, [])
    const handleSkillSelected = (id,skillType) => {
        if (selectedSkill?.includes(id)) {
            let filterArr = selectedSkill.filter((itm) => itm != id)
            setSelectedSkill(filterArr)
            saveSkills(filterArr,skillType)
        } else {
            setSelectedSkill([...selectedSkill, id])
            saveSkills([...selectedSkill, id],skillType)
        }
    }
    const handleSkillClass = (id) => {
        if (selectedSkill?.includes(id)) {
            return "heighlight";
        }
    }

    const handleSkillOrStack = (skillType) => {
        let skills = skillType == "all-skill" ? allSkill : allStack;
        if (!checkExistAllSkill(selectedSkill, skills)) {
            setSelectedSkill([...selectedSkill, ...skills])
            saveSkills([...selectedSkill, ...skills],skillType)
        } else {
            let arr = removeArrFromArry(selectedSkill, skills)
            setSelectedSkill(arr)
            saveSkills(arr,skillType)
        }
    }



    useEffect(() => {
        setAllSkill(getSkillIdInCategory(skillWithCategory))
    }, [skillWithCategory])



    useEffect(() => {
        setAllStacks(getSkillIdInCategory(stackWithCategory))
    }, [stackWithCategory])


    const filterStackAndSkill = (arr) => {
       let filters = arr?.filter((itm) => itm != "all-skill" && itm != "all-stack");
        return filters;
    }
    const handleSkillStackGet = () => {
        if (devActivations?.selectedSkillsStacks?.length && allSkill?.length && allStack?.length) {
            const checkSkill = devActivations?.selectedSkillsStacks?.includes("all-skill");
            const checkStack = devActivations?.selectedSkillsStacks?.includes("all-stack");
            let skills = []

            if (checkSkill) {
                let data=filterStackAndSkill(devActivations?.selectedSkillsStacks)
                skills.push(...[...data, ...allSkill])
            }
            if (checkStack) {
                let data=filterStackAndSkill(devActivations?.selectedSkillsStacks)
                skills.push(...[...data, ...allStack])
            }
            if (!checkSkill && !checkStack) {
                setSelectedSkill(devActivations?.selectedSkillsStacks)
            } else {
                setSelectedSkill(skills)
            }
        }

       
    }
    useEffect(() => {
        handleSkillStackGet()
    }, [devActivations, allSkill, allStack])


    return (
        <div className="client-searh-skill">
            {
                activeTab == "Skill" ?
                    <SkillLists
                        handleSkillClass={handleSkillClass}
                        handleSkillSelected={handleSkillSelected}
                        skillWithCategory={skillWithCategory}
                        handleSkillOrStack={handleSkillOrStack}
                        selectedSkill={selectedSkill}
                        allSkill={allSkill}
                    /> :
                    <StackLists
                        handleSkillClass={handleSkillClass}
                        handleSkillSelected={handleSkillSelected}
                        stackWithCategory={stackWithCategory}
                        handleSkillOrStack={handleSkillOrStack}
                        selectedSkill={selectedSkill}
                        allStack={allStack}
                    />
            }
          



        </div>
    )
}

export default SelectSkill