import React from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { clientSavedProfileList } from '../../../service/action/client';
import { Icon } from '../../../components/icon';

const Warning = ({user}) => {
  const dispatch=useDispatch();
  const navigate=useNavigate();
  useEffect(()=>{
   dispatch(clientSavedProfileList(navigate,{page:1,limit:1},true))
  },[])
  return (
    <>
    {/* {
        user?.newCompanyProfile && (
            <div class="client-message">To connect with the best talent on Olibr, we request you to create a compelling company profile. This is essential to post job opportunities and engage prospective candidates effectively.</div>
        )
    }
    {
        user?.existCompanyProfile && (
            <div class="client-message"><strong>Attention valued clients:</strong> To maintain seamless job posting, please create your company profile by June 20th. Post this date, job postings will require a completed profile for activation.</div>
        )
    } */}
    {
      !user?.companyProfilePublish && (
        <div class="client-message">To connect with the best talent on Olibr, we request you to create a compelling company profile.
        This is essential to post job opportunities and engage prospective candidates effectively.</div>
      )
    }
   
    </>
  )
}

export default Warning