import React, { useState } from "react";
import { Fragment } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Modal from "../../../../components/modal/ModalBox";
import { deleteBlogCat, updateBlogCat } from "../../../../service/action/admin";

const CategoryList = ({ isLoading }) => {
  const { blogCatOpenList } = useSelector((state) => state.commonReducer);
  const {
    register: categoryRegister,
    handleSubmit: categorySubmit,
    setValue: setCategoryValue,
    getValues:getCategoryValue,
    reset: resetCategoryForm,
    formState: { errors: categoryError },
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalContent = () => {
    return (
      <form>
        <div className="row">
          <div className="col-12">
            <label className={`floating-label ${categoryError?.categoryName && "text-danger"}`}>Category Name*</label>
            <input
              type="text"
              className="form-control"
              name="categoryName"
              {
              ...categoryRegister("categoryName", {
                required: true
              })
              }
            />
          </div>
          <div className="col-12">
            <label className={`floating-label ${categoryError?.categoryDesc && "text-danger"}`}>Category Description</label>
            <textarea
              className="form-control"
              name="categoryDesc"
              {
              ...categoryRegister("categoryDesc", {
                required: true
              })
              }
            />
          </div>
        </div>
        <br />
        <div className="col-12">
          <div className="bottom-button">
            <button className="cancelBtn" type="button" onClick={handleDeleteCategory}>
              Delete
            </button>
            <button className="cancelBtn" type="button" onClick={handleModalClose}>
              Cancel
            </button>
            <button
              onClick={categorySubmit(handleUpdateCategory)}
              className="submitBtn themeButton">
              {
                false ? <ClipLoader size={15} color="#FFFFFF" /> : "Submit"
              }
            </button>
          </div>
        </div>
      </form>
    );
  };

  const handleDeleteCategory = () => {
    dispatch(deleteBlogCat(getCategoryValue("id"),setModalOpen, navigate));
  };

  const handleUpdateCategory = (data) => {
    dispatch(updateBlogCat(data?.id, data, setModalOpen,navigate));
  };

  const handleUpdateStatus = (item) => {
    dispatch(
      updateBlogCat(item?._id, {
        ...item,
        categoryStatus: !item?.categoryStatus,
      })
    );
  };

  const handleModalOpen = (item) => {
    resetCategoryForm();
    setCategoryValue("categoryDesc",item?.categoryDesc)
    setCategoryValue("categoryName",item?.categoryName)
    setCategoryValue("categoryStatus",item?.categoryStatus)
    setCategoryValue("id",item?._id)
    setModalOpen(true);
  };

  return (
    <Fragment>
      <Modal
        open={modalOpen}
        title={"Blog Category"}
        onClose={handleModalClose}
        content={handleModalContent}
        isDeletable={true}
        isFooterView={false}
      />
      {isLoading ? (
        <div style={{ textAlign: "center" }}>
          {" "}
          <ClipLoader color="#3BA0AA" />{" "}
        </div>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th className="column0">S.No</th>
              <th className="column1">Category Name</th>
              <th className="column1">Description</th>
              <th className="column1">Status</th>
            </tr>
          </thead>
          <tbody>
            {blogCatOpenList?.data?.map((dev, index) => (
              <tr key={dev._id} className="dev-hover">
                <td className="column0">{index + 1}</td>
                <td className="column1" onClick={() => handleModalOpen(dev)}>
                  {dev?.categoryName}
                </td>
                <td className="column1">{dev?.categoryDesc || "-"}</td>
                <td className="column4" onClick={() => handleUpdateStatus(dev)}>
                  <input
                    type="checkbox"
                    name="switch"
                    onChange={(e) => e.preventDefault()}
                    checked={dev?.categoryStatus}
                  />
                  <label className="label" htmlFor="switch3"></label>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Fragment>
  );
};

export default CategoryList;
