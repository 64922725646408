
export const commonTerms = [
    {
        title: "INTRODUCTION",
        content: [
            {
                subTitle: '1. <b>Agreement</b> These "Terms of use" (hereinafter, "Terms") are a binding contract between Olibr Resourcing Private Limited (hereinafter referred to as "Olibr" or "We" or "Us" or "our") having our principal place of business at Gurgaon, Harayana, India, and You (a User of the Services in any of the following capacities)',
                lists: [
                    "Recruiter (being a person who is interested in recruitment related services)",
                    "Candidate (being a person who is a jobseeker)",
                    "User (being a person interested in any other service offered by Olibr, includes Recruiters and Candidates)"
                ]
            },
            {
                subTitle: '2. This contract applies to the Services, being recruitment management, job advertisement, job application, and other services that User may avail of through the following channels: ',
                lists: [
                    "Olibr web platform, tools and services accessible via the same Websites – <a class='link' href='https://olibr.com/'>www.Olibr.com</a> ",
                    "Candidate (being a person who is a jobseeker)",
                    "User (being a person interested in any other service offered by Olibr, includes Recruiters and Candidates)"
                ]
            }, {
                subTitle: "3. Please read these Terms very carefully before using our Services. By registering for, accessing or using Olibr.com or any of our Services You warrant and represent that you are authorised by the User and have the full capacity to enter into these Terms on behalf of the User. If You do not agree to these Terms, then you may not access or use the Website, Mobile App or any Services available through the same."
            }
        ]
    },
    {
        title: "SCOPE OF THE SERVICES AND FEATURES ",
        content: [
            {
                subTitle: '1. <b>As a recruiter</b>',
                lists: [
                    "In consideration for the payment of the Fees, Olibr shall provide the User with the Services and features as described herein and on the Website, which permit the User to browse, screen, track Candidates and manage its own hiring process and provide the facility of storing the User's recruitment data. Such features would typically include but not limited to create and publish job openings, browse, search and view Candidate's profiles, post the published job opening on job boards, compare Candidate profiles, communicate with shortlisted Candidates throughout the hiring process, collaborate with other users of Olibr.",
                    "Free Trial Subscriptions: Users may be granted a limited access to Olibr at no cost, solely for the purpose of offering a preview of the features and Services available through Olibr.com. The Free Trial Subscription Plan can be converted to any paid Subscription Plan upon payment of the Fee. At the completion of the Free Trial continued access to Olibr and the Services will be subject to payment of Fees. Olibr is under no obligation to return any data entered into or generated through Olibr to the User.",
                ]
            },
            {
                subTitle: '2. <b>As a candidate </b> ',
                lists: [
                    "Olibr provides the Candidates Services and features described herein and on the Website which permit the Candidate to post resumes, browse and apply for jobs posted by other users, communicate and collaborate with other Users of Olibr.",
                    "You (Candidate) understand and agree that neither Olibr is liable, nor offers any guarantee nor warranties that there would be a satisfactory outcome or any outcome at all in the nature of a invitation to interview or job offer by reason of uploading your resume in the Services. ",
                    "It falls under your responsibility as a developer to conduct the necessary due diligence when evaluating and accepting offers from companies registered on Olibr. This includes, but is not limited to, verifying the legitimacy of the company, the authenticity of the offer, and understanding the terms and conditions involved. "
                ]
            }, {
                subTitle: "3. <b>Usage </b>",
                lists: [
                    "Olibr may from time to time add, modify or suspend (temporarily or permanently) the provision of the Services upon notice to the User in the manner herein mentioned. Olibr has sole and complete discretion to modify the Subscription Plans as and when it deems fit. Any changes in modifications in the Services or Subscription Plans shall be intimated to the User in accordance with these Terms. By continuing to access or use the Service after receiving such notice, you agree to be bound by the revised Terms.",
                    "Both Parties, Olibr and the User are independent contractor and nothing in these Terms is intended to, or shall be deemed to, establish any partnership, joint venture, employer-employee or agency between the Olibr and the User, appoint either party as the agent of the other, nor authorise either party to make or enter into any commitments for or on behalf of the other party. Olibr's obligations shall be limited only to the provision of the Services and do not in any way include acting on the User's behalf.",
                ]
            }
        ]
    },
    {
        title: "REGISTERING FOR AN ACCOUNT",
        content: [
            {
                subTitle: '1. <b>User Account, Verification of Profiles </b>',
                lists: [
                    'In order to create a User Account, You must enter a genuine email id (preferably official email id if you are a "Recruiter"), cellphone number and other details requested. You warrant that the details entered by you are true and valid.',
                    "Your User Account belongs to you. As between the You and others (including your employer), your User Account belongs to you. However, if the Services were purchased by another party for you to use, the party paying for such Service has the right to control access to and get reports on your use of such paid Service; however, they do not have rights to your personal account",
                    "Olibr shall conduct a brief verification of the details entered by you to confirm their validity. In case Olibr suspects that any details entered by you are not genuine, or you have created a false account, Olibr shall delete, suspend and delete your User Account, along with any data in that account, without refunds, if any.",
                    "To be eligible to use our Services, you must be at least 18 years of age (and/or of any such age as required in your country for you to be authorised to use our Services). Olibr does not wish to create any legal relationship with a minor through these Services and shall not incur any liability for violation by the User or other user of this clause. By using this Site, you warrant that you possess the legal authority to create a binding legal obligation.",
                    "By signing up for creating a Olibr User Account, you warrant that you are authorised by the User/Hiring Company, and are not already restricted by Olibr or any other law or order in force from using the Services."
                ]
            },
            {
                subTitle: '2. <b>Subscription Plans and Subscription Term </b>',
                lists: [
                    "Subscription Plans are monthly, quarterly or yearly plans designed by Olibr. Subscription Plans are subject to change at the discretion of Olibr. Any such changes are notified on the Services in accordance with these Terms.",
                    "Subscription Term means In case of a Free Trial: The time period from the date on which the User registered for the Free trial until the date of expiry of such Free Trial ",
                    "In case of premium accounts (paid accounts): The time period from the date on which the User registered and paid for the premium account until the date on which the User Plan ends or when the Plan is cancelled, whichever is earlier. "
                ]
            },

        ]
    },
    {
        title: "FEES, PAYMENTS, RENEWAL AND REFUND FOR USERS ",
        content: [
            {
                subTitle:"1. <b>Fee</b> Except in case of Free Trials, all amounts associated with the User's Account are due in full and payable in advance (in the beginning of the Subscription Term) and in the manner described on the Website. All such amounts are based on the Subscription Plan chosen by the User."
            },
            {
                subTitle: "2. <b>Payment</b> Methods Fees may be paid by the User through various methods of payment provided for by Olibr through its Services ",
            },
            {
                subTitle:'3. <b>Refunds</b> All Fees paid by the User are non-refundable. User acknowledges and agrees that no refunds shall be paid on account of opting out, cancellation, non-use or partial use of the Services'
            },
            {
                subTitle:"4. <b>Taxes</b> All amounts mentioned are exclusive of GST and other taxes. User is responsible for paying all the applicable taxes. Olibr shall, if it has the legal obligation to do so, include such amounts in the Invoice. "
            }

        ]
    },
    {
        title: "USER RIGHTS AND PERMISSIBLE USE ",
        content: [
            {
                subTitle: '1. <b>As a recruiter </b>',
                lists: [
                    "During the Subscription Term, User shall have the limited, non-exclusive, and revocable right to access and use the Service for its internal business and hiring purposes. User shall be fully responsible for use of the Service through User Account by its representatives or any third parties. These rights have been conferred upon by Olibr to the User subject to these Terms.",
                    "The Services shall be used only for real vacancies in real organisations. Advertisement for fake jobs or collection of resumes for any other purpose than hiring for actual vacancies amounts to violation of applicable laws and is prohibited under these Terms ",
                    "Users should only use the Services to fill jobs at the company that they are authorized to hire for, as an employee or a hiring agent. Recruitment Agents, who are not an employee of the Company for which they wish to hire are required to disclose the same to the jobseekers.",
                    "User shall not operate a User Account for which it does not have the legally authority. Violation of this clause shall warrant account deactivation or termination. No refunds or account data will be given in such cases",
                    "User acknowledges and agrees that all the personal data of Candidates that it has access to or which is disclosed to it under these Terms shall be used in a lawful manner, in accordance with the principles of the European Union General Data Protection Regulation (EU-GDPR) and strictly for purposes specified under these Terms. Recruiters shall not use the personal data of Candidates in any manner that is inconsistent with these Terms or applicable laws.",
                    "The Services allow the User to upload and/or enter personal data of persons that are not registered on the Olibr Platform, the User agrees and warrants that it has obtained lawful and legitimate consent from such persons before uploading and/or entering their personal data on the Olibr platform."
                ]
            },
            {
                subTitle: "2. <b>As a candidate</b",
                        lists: [
                            "During the Subscription Term, User shall have the limited, non-exclusive and revocable right to access and use the Service for the purpose of job seeking, communicating with potential recruiters and collaborating with other users. These rights have been conferred upon by Olibr to the User subject to these Terms",
                            "User shall create an account under his/her real name and provide a recent profile picture that is of his/her likeness alone. Use of any other profile pictures may lead to suspension of your account. User shall provide accurate data while signing up with Olibr in accordance with the form layout.",
                            'The Services allow messaging and sharing of information in many ways. All Information and content that User shares or posts may be seen by other Users or, if public, by Visitors. Olibr provides the User the facility to change such settings, and will make reasonable efforts to honour the choices User makes about who can see content or information. User acknowledges that activities, such as applying for a job, are by default private, only visible to the addressee(s).',
                            "Olibr is not obligated to publish any information or content on its Service and can remove it in its sole discretion, with or without notice",
                            "User may not imply or state any endorsement or affiliation with Olibr except as a user of Olibr. ",
                            "The Services shall be used in compliance with all applicable laws including, without limitation, all intellectual property laws as well as all regulatory requirements. Olibr may delete your content without assigning any reason if it has reason to believe that these Terms or any applicable laws have been violated by posting of such content.",
                            {
                                title:"<b>User shall not </b>",
                                list:[
                                    "Do any act to cause disruption of the Services, or other User's use of the Services ",
                                    "Misuse the Services by engagement or facilitation of unlawful, illegal, fraudulent, harmful or unethical conduct, or do anything in connection with unlawful, illegal, fraudulent or harmful purpose or activity is strictly prohibited, including activities such as attempts to scrape data, cause damage to other users, is strictly illegal and will lead to permanent account suspension without any refunds ",
                                    "Republish material from the website; Sell, rent or sub-license material from the website; Show any material from the website in public; Reproduce, duplicate, copy or otherwise exploit material on this website for a commercial purpose other than what is allowed under these terms and conditions; Edit or otherwise modify any material on the website; Redistribute material from this website (except for content specifically and expressly made available for distribution) ",
                                ]
                            },
                            "User is responsible for all the content uploaded within and the communications sent through its User Account. User agrees not to communicate any message or post any content that is may be libellous, harmful to minors, constitutes pornography, infringes intellectual property rights of any other party, contains any harmful materials such as computer viruses, or would otherwise give rise to civil liability, or that constitutes or encourages conduct that could constitute a criminal offense under any applicable law or regulation",
                            'User may not use the Service in a way that harms us or our advertisers, affiliates, or customers, or use any portion of the Service as a destination linked from any unsolicited bulk messages or unsolicited commercial messages ("spam"). You may not use any automated process or service to access and/or use the Service or use any unauthorized means to modify or reroute, or attempt to modify or reroute, the Service. You may not damage, disable, overburden, or impair the Service (or the network(s) connected to the Service or interfere with anyone\'s use and enjoyment of the Service. You must not conduct any systematic or automated data collection activities on or in relation to Olibr'
                        ]
                   
            }
        ]
    },
    {
        title: "TERM, TERMINATION AND OPTING OUT ",
        content: [
            {
                subTitle: "1. <b>Terms </b>All terms and conditions in these Terms shall begin and be in force (except the obligations pertaining to Confidentiality, Intellectual Property Rights and Indemnity) till the expiration of the Subscription Term unless terminated earlier. ",
            },
            {
                subTitle:"2. <b>Renewal </b> Upon the expiry of the current Subscription Term, the Subscription Plan shall renew automatically for a Subscription Term equivalent in length to the then expiring Subscription Term. User acknowledges and agrees that, unless the User terminates its User Account its credit card will be charged automatically for the applicable Fee. In case the User wishes to cancel the Subscription Plan, it should exercise this option through their User Account or contact Olibr before the renewal is performed by Olibr. Should the User not exercise its option to cancel the renewal and is charged, Olibr shall not provide any refunds."
            },
            {
                subTitle:"3. Olibr may terminate or suspend the User's account or ability to use the Service, in whole or in part, at its sole discretion, for any or no reason, and without notice or liability of any kind, in cases such as violation of these Terms. Any such termination or suspension could prevent the User from accessing the Service and User content."
            },
            {
                subTitle:"4. User may opt out of using the Service and cancel its User Account at any time, with or without cause and in such case, the User shall contact Olibr through the support section of the Websites. User acknowledges and agrees that no refunds shall be paid on account of opting out, cancellation, non-use or partial use of the Services"
            },
            {
                subTitle:"5. Any amounts owed by either party prior to termination remain owed after termination"
            },
            {
                subTitle:"6. User should back up/export all of its content before the Termination of Services or cancellation of its account. Olibr shall neither be responsible for returning any of the User's content nor be liable any loss of data on such Termination of the Services or cancellation of the User's Account in accordance with these Terms"
            }

        ]
    },
    {
        title: "THIRD PARTY SERVICES ",
        content: [
            {
                subTitle:"1. In order to give to its Users a better service, Olibr may partner with third party vendors to provide certain services such as services for background checks, eligibility tests for Candidates, etc. Olibr cannot and does not take any responsibility and does not make any warranty on behalf of such third party services. ",
                
            },

        ]
    },
    {
        title: "DATA PROTECTION AND SERVICES ",
        content: [
            {
                subTitle: "1. The treatment of all Personal Information is governed by our Privacy Policy, which is incorporated by reference into these Terms ",
      
            },
            {
                subTitle:"2. Olibr shall act on the request of the User and forthwith remove any logos/details displayed on the Services. However, Olibr shall not be responsible for removal of such details from third parties such as other jobs sites, Google Search Index, etc.",
            }

        ]
    },
    {
        title: "INTELLECTUAL PROPERTY AND PROPRIETARY RIGHTS",
        content: [
            {
                subTitle: "1. Except for the Rights mentioned herein, all rights, title and interest in and to all the trademarks, copyrights, inventions, trade secrets, domain names, know-how and any other intellectual property and/or proprietary rights in or related to the Olibr web platform, mobile app or other Service(s), including the Website, and any part of it (collectively referred to as \"Intellectual Property Rights\") shall belong to and remain exclusively with Olibr Resourcing Private Limited. ",
            },
            {
                subTitle:"2. Olibr Resourcing Private Limited is the owner or the licensee of any/all Intellectual Property Rights in the Website, and the content or material published on it, including but not limited to text, graphics, articles, photographs, images, illustrations which works are protected by copyright laws. User shall not use any part of such content for commercial purposes without our/our licensor's prior consent vide a license to use. User shall abide by all copyright notices, trademark rules, information, and restrictions contained in any such content or data accessed through the Services, and shall not use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell or otherwise exploit for any purposes whatsoever any such content or other proprietary rights not owned by you without the express prior written consent of Olibr",
            },
            {
                subTitle:"3. The product and service names, and logos used or displayed on the Service(s) or Website are registered or unregistered trademarks which are intellectual property that belongs to Olibr and the User may only use such trademarks to identify itself as a user of the Service(s) which it has subscribed to.",
            },
            {
                subTitle:"4. <b>- Only if you are using the services as a Recruiter or a Candidate</b> All content that is uploaded to the Services by or on behalf of the User shall be the User's property and Olibr shall claim no intellectual property rights over such content. As between the User and Olibr, you own the content and information that you submit or post to the Services and you are only granting Olibr the following non-exclusive license: A worldwide, transferable and sub-licensable right to use, copy, modify, distribute, publish, and process, information and content that you provide through the Services, without any further consent, notice and/or compensation to you or others. These rights are limited in the following ways:",
                lists:[
                    "User may end this license for specific content by deleting such content from the Services, or generally by closing its account, except (a) to the extent you shared it with others as part of the Service and they copied or stored it and (b) for the reasonable time it takes to remove from backup and other systems;",
                    "User agrees that any job postings made on Olibr are available to general public through internet search and hereby consents to publishing its posts beyond the Service on other websites. User also acknowledges that other members and/or visitors may access and share the content and information, consistent with the Account settings and degree of connection with them; ",
                    "Olibr may edit and make formatting changes to your content (such as translating it, modifying the size, layout or file type or removing metadata), we will not modify the meaning of User's expression; ",
                    "User agrees to only provide content or information if that does not violate the law, any agreement in force, nor anyone's rights, including a person's right to privacy. User also agrees that your profile information will be truthful and you shall use your legal name and actual photo for the profile. Olibr reserves the right to suspend or terminate its account with us, if there is any fraud or misrepresentation found in your content and information which you provided to us ",
                ]
            },
            {
                subTitle:"5. Olibr shall also have a royalty-free, worldwide, transferable, sub-licensable, irrevocable and perpetual license to incorporate into the Service(s) or Website or otherwise use any suggestions, enhancement requests, recommendations or other feedback that it receives from or on behalf of the User.",
            },

        ]
    },
    {
        title: "CONFIDENTIALITY",
        content: [
            {
                subTitle:"1. All information designated as \"confidential\" that is disclosed by User to Olibr shall not be used or disclosed for any purpose outside the scope of these Terms, except when required by the process of law or with the User's prior permission. Such information shall not include information which",
                lists:[
                    "To the extent such information is in the public domain ",
                    "To the extent such information is required or requested to be disclosed by any applicable Law or any applicable regulatory requirements",
                    "To the extent that such information is later acquired by such Party from a source not obligated to any other Party, or its Affiliates to keep such Information confidential.",
                    "To the extent that such information was already known or in the lawful possession of a Party prior to disclosure by any other Party. ",
                    "To the extent that any information, materially similar to the Information, shall have been independently developed by a Party without reference to any Information furnished by any other Party."
                ]
            },
            {
                subTitle:"2. User agrees that Olibr may disclose Information to its employees, consultants, agents or advisors who have a strict need to know such information solely for the purpose of performing Olibr's obligations under these Terms and only to those who are obligated to maintain the confidentiality of such information"
            },

        ]
    },
    {
        title: "LIMITATION OF LIABILITY ",
        content: [
            {
                subTitle: '1. To the extent permitted by law, Olibr expressly excludes all liability for any indirect, incidental, special, consequential, punitive or exemplary damages, however, caused including but not limited to, damages for loss of profits, goodwill, or other intangible losses (even if Olibr has been advised of the possibility of such damages including, without limitation, loss of business, lost profits or revenue.), if such liability results from any of the following',
                lists: [
                    "The use or the inability to use the Services ",
                    "Statements or conduct of any third party on the Service",
                    "The cost of procurement of substitute services resulting from any information or services purchased or obtained or messages received or transactions entered into through or from the Service "
                ]
            },
            {
                subTitle:"2. Notwithstanding the above, the maximum total liability arising from or in connection with the provision of the Services or these Terms whether arising in contract, tort or otherwise, shall not exceed a sum equivalent to the total Fees received by Olibr from the User for a period of twelve (12) months preceding the event giving rise to such liability "
            },
            {
                subTitle:"3. Olibr shall not be liable for any loss or injury to anyone resulting from unauthorised tampering, hacking, or other unauthorized access of the service or User's Account. "
            },
            {
                subTitle:"4. Olibr uses reasonable efforts to ensure that the Services are available at all times. User acknowledges that Services interruptions may occur due to maintenance, upgrades and emergency repairs or due to a failure of telecommunications links and equipment. User agrees that Olibr will not be liable to itself or any other party for any loss caused in the event of such interruptions. "
            }

        ]
    },
    {
        title: "DISCLAIMERS OF WARRANTIES",
        content: [
            {
                subTitle: '1. Olibr provides the Service on "as-is" and "as-available" basis, with all faults and as available with no guarantee to the accuracy or timeliness of information available from the Service. Olibr gives no express warranties, guarantees or conditions and exclude any implied warranties. We also disclaim any warranties of merchantability, fitness for a particular purpose or non-infringement. Olibr has no responsibility for any harm to your computer system, loss or corruption of data, or other harm that results from your access to or use of the Service. ',
            },
            {
                subTitle:"2. OLIBR DOES NOT WARRANT THAT THE INFORMATION AVAILABLE ON THE SERVICES IS FREE OF ERRORS. Olibr does not generally review content provided by its Users (Users or Candidates) and the Users are personally liable for any information or content provided by them. By using the Services, User acknowledges that may encounter content or information that might be inaccurate, incomplete, delayed, misleading, illegal, offensive or otherwise harmful. User acknowledges and agrees that Olibr is not responsible for content found find on Olibr or for any damages as result of User's use of or reliance on such content. "
            },
            {
                subTitle:"3. Olibr's Services are limited to displaying such Candidate, from amongst those registered, that fits the requirements posted by the User. User acknowledges that this does not guarantee that it will find a suitable candidate or that Candidates employed by the User through the Services will be suitable for the vacancy and Olibr disclaims all liability with regard to the same "
            },
            {
                subTitle:"4. Similarly, Olibr neither guarantees nor offers any warranty about the credentials or bona fides of the prospective employer which will view/download the content provided/fed by the Candidate and will not be liable for the same "
            },
            {
                subTitle:"5. Similarly, Olibr neither guarantees nor offers any warranty about the credentials or bona fides of the prospective employer which will view/download the content provided/fed by the Candidate and will not be liable for the same ",
            },
            {
                subTitle:"6. The content on our Website, Mobile App or other Service is provided for general information only and is not intended to amount to advice on which you should rely. User must obtain professional advice before taking, or refraining from, any action on the basis of such content. No information or advice obtained by you from us or through the Services or Websites shall create any warranty not expressly stated in these Terms "
            },
            {
                subTitle:"7. Olibr does not warrant that the functions or features (including but not limited to mechanisms for the downloading and uploading of content) will be uninterrupted, secure, or free of errors "
            },
            {
                subTitle:"8. Olibr does not warrant that any defects will be corrected "
            },
            {
                subTitle:"9. Olibr does not warrant that the services or server(s) that make the Services available are free of viruses or other harmful components "
            }

        ]
    },
    {
        title: "INDEMNITY",
        content: [
            {
                subTitle: "1. User agrees to indemnify, defend, and hold harmless, Olibr and its respective officers, directors, employees, members, shareholders, or representatives (and all successors and assigns of any of the foregoing), from and against any claim or demand, liabilities, losses, actions, causes of action, costs and expenses, including but not limited to attorneys' fees, made by any third party in connection with or arising out of User's use of the service, or infringement of any intellectual property of another's data in connection with the use of the Service. User's connection to the Services, violation of these Terms or Olibr's Privacy Policy, violation of an applicable law, submission, posting, or transmission of User's content to the Services, and/or violation of any rights of another. Olibr reserves the right, at our own expense, to assume the exclusive defense and control of such disputes, and in any event User agrees to cooperate in asserting any available defences ",
            },
            {
                subTitle:"2. In no event will Olibr have any obligations or liability under this section arising from: (i) use of the Service in a modified form or in combination with materials not furnished by Olibr; or (ii) any content, information, or data provided by Candidates, or other third parties. THIS INDEMNITY IS User'S ONLY REMEDY UNDER THIS User AGREEMENT FOR ANY VIOLATION BY Olibr OF ANY THIRD PARTY'S INTELLECTUAL PROPERTY RIGHTS "
            }

        ]
    },
    {
        title: "GOVERNING LAW AND JURISDICTION",
        content: [
            {
                subTitle: '1. The jurisdiction of any action arising out of or under these Terms shall lie exclusively with the Courts at Gurgaon, India ',
            },
            {
                subTitle:"2. The Service is controlled and operated by Olibr Resourcing Pvt. Ltd. from India, and is not intended to subject Olibr Resourcing Pvt. Ltd. to the laws or jurisdiction of any territory other than that of India. Olibr Resourcing Pvt. Ltd. does not represent or warrant that the Service or any part thereof is appropriate or available for use in any particular jurisdiction. Those who choose to access the Service do so on their own initiative and at their own risk, and are responsible for complying with all local laws, rules and regulations. Olibr Resourcing Pvt. Ltd. may limit the Service's availability, in whole or in part, to any person, geographic area or jurisdiction we choose, at any time and in Olibr's sole discretion "
            }

        ]
    },
    {
        title: "MISCELLANEOUS",
        content: [
            {
                subTitle: '1. <b>Notices</b> User agrees that we may provide notices to you as (i) A banner notice on the Service (ii) An email sent to an address provided by the User (iii) Through other means including mobile number, telephone, or mail. User agrees to keep its contact information up to date and that Olibr will not be liable for any action or damages that rise from incorrect or out-dated information ',
              
            },
            {
                subTitle:"2. <b>Modifications</b> Olibr may, in its sole discretion revise these Terms from time to time, without prior notice in the interest of bettering and furthering our Services. The latest copy will always be available on our website and the onus shall lie on User to keep yourself informed of changes from time to time. If changes to this Terms are made, that in our sole discretion, are material, we will notify you either by posting a notice of such changes and/or via the email associated with your Account. By continuing to access or use the Service after those changes become effective, you agree to be bound by the revised Terms"
            },
            {
                subTitle:"3. <b>Severability </b>All provisions of this Agreement shall be severable and no such provisions shall be affected by the invalidity of any other provision to the extent that such invalidity does not also render such other provisions invalid. In the event of the invalidity of any provision, this Agreement shall be interpreted and enforced as if all the provisions thereby rendered invalid were not contained herein. If any provision of this Agreement shall be susceptible to two interpretations, one of which would render the provision invalid and the other of which would cause the provision to be valid, such provision shall be deemed to have the meaning which would cause it to be valid. If any provision of this Agreement shall be prohibited by or adjudicated by a court to be unlawful, void or unenforceable such provision shall to the extent required, be severed from this Agreement and rendered ineffective as far as possible without modifying the remaining provisions of this Agreement and shall not in any way affect any other provisions or the validity or enforcement of this Agreement  "
            },
            {
                subTitle:"4. <b>Waiver </b>Any Party's delay or failure to exercise any right, power or privilege under this Agreement shall not operate as a waiver; nor shall any single or partial exercise of any right, power of privilege preclude any other or further exercise thereof. Any waiver of a right, power or privilege under this Agreement on any single occasion, shall not prevent a Party from subsequently, on any subsequent occasion, requiring and enforcing compliance with the obligations under this Agreement"
            },
            {
                subTitle:"5. <b>Complaints, Notices and Feedback </b> We respect the intellectual property rights of others and require that information posted by on Olibr be accurate and not in violation of the intellectual property rights or other rights of third parties. If you find any user or content that is not accurate or truthful, you can email us any complaints on info@Olibr.com. "
            }

        ]
    },
    {
        title: 'ADDITIONAL TERMS FOR "PERSONAL ASSISTANCE SERVICE"',
        content: [
            {
                subTitle: '1. Olibr offers an optional "Personal Assistance Service", (hereafter called as "Package") - an optional premium service for the purpose of identifying and locating the requisite talent and candidate for designated posts, as requested by employers wanting to use said service. If you are using this service, you agree to be bound by additional terms that will be shared with you separately. In case of any differences, the Additional Terms will supersede the terms described in this document.',

            },

        ]
    },



]