import * as types from "../../action";

const initialState = {
  isLoading: false,
  isSignup: false,

  //IT WILL BE REMVOE AFTER ALL API INTEGRATE
  clientShortLists: [],
  clientTopShortlist: [],
  hiredCandidates: [],
  clientAllJoblists: {},
  clientDevLists: [],
  clientJobData: [],
  allSkills: [],
  isJoblistLoading: false,
  isHiredCandatest: false,
  isClientShortlist: false,
  devDetails: [],
  isDevDetails: false,
  ClientJobDetails: {},
  isClientJobDetails: false,
  clientProfile: {},
  clientSkillList: [],
  clientStackList: [],
  topFiveSkills: [],
  assignToJobData: {},
  isDevLoading: false,
  skillListLoading: false,
  stackListsLoading: false,
  clientSelectSkills: [],
  contactUsPost: {},
  totalShortListedCount: 0,
  clientJobOperation: "",
  clientJobId: "",
  applicant: 0,
  clientFirstEntry: false,

  //candidate
  allAppliedJob: [],
  isAllAppliedJobLoading: false,
  devActivations: {},
  companyUserData: [],
  isCompanyUserLoading: false,
  isClientUserInviting: false,
  isClientStatusChanging: false,
  allActiveUser: [],
  allActiveUserLoading: false,
  isJobRefreshing: false,
  isInterviewDataLoading: false,
  interviewsData: [],
  devInterviewsData: [],
  devFilterData: {},
};

export const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CLIENT_SIGNUP_START:
      return { ...state, isSignup: true };
    case types.CLIENT_SIGNUP_SUCCESS:
      return { ...state, isSignup: false };
    case types.CLIENT_SIGNUP_FAIL:
      return { ...state, isSignup: false };

    case types.CLIENT_GET_PROFILE_START:
      return { ...state, isLoading: true };
    case types.CLIENT_GET_PROFILE_SUCCESS:
      return { ...state, isLoading: false, clientProfile: action.data.data[0] };
    case types.CLIENT_GET_PROFILE_FAIL:
      return { ...state, isLoading: false };

    case types.GET_CLIENT_SAVED_PROFILE_START:
      return { ...state };
    case types.GET_CLIENT_SAVED_PROFILE_SUCCESS:
      const counts = action?.data?.data?.totalData
        ? action?.data?.data?.totalData
        : state.totalShortListedCount;
      if (action?.limit == 4) {
        return {
          ...state,
          isClientShortlist: false,
          clientTopShortlist: action?.data?.data,
          totalShortListedCount: counts,
        };
      }
      return {
        ...state,
        isClientShortlist: false,
        clientShortLists: action?.data?.data,
        totalShortListedCount: counts,
      };

    case types.GET_CLIENT_SAVED_PROFILE_ONLY:
      return { ...state, totalShortListedCount: action?.data?.totalData };
    case types.GET_CLIENT_SAVED_PROFILE_FAIL:
      return { ...state, isClientShortlist: false, clientShortLists: [] };

    case types.GET_CLIENT_HIRED_CANDIDATES_START:
      return { ...state, isHiredCandatest: true, hiredCandidates: [] };
    case types.GET_CLIENT_HIRED_CANDIDATES_SUCCESS:
      return {
        ...state,
        isHiredCandatest: false,
        hiredCandidates: action?.data,
      };
    case types.GET_CLIENT_HIRED_CANDIDATES_FAIL:
      return { ...state, isHiredCandatest: false, hiredCandidates: [] };

    case types.GET_CLIENT_ALL_JOBLISTS_START:
      return { ...state, isJoblistLoading: true, clientJobOperation: "" };
    case types.GET_CLIENT_ALL_JOBLISTS_SUCCESS:
      return {
        ...state,
        isJoblistLoading: false,
        isJobRefreshing: false,
        clientAllJoblists: action?.data,
      };
    case types.GET_CLIENT_ALL_JOBLISTS_FAIL:
      return { ...state, isJobRefreshing: false, isJoblistLoading: false };

    case types.GET_CLIENT_ALL_JOBTITLES_START:
      return { ...state, isJoblistLoading: true, clientAllJobTitles: [] };
    case types.GET_CLIENT_ALL_JOBTITLES_SUCCESS:
      return {
        ...state,
        isJoblistLoading: false,
        clientAllJobTitles: action?.data?.data,
      };
    case types.GET_CLIENT_ALL_JOBTITLES_FAIL:
      return { ...state, isJoblistLoading: false, clientAllJobTitles: [] };

    case types.GET_APPLIED_FOR_JOB_START:
      return { ...state, iscandAppLoading: true, allAppliedJob: [] };
    case types.GET_APPLIED_FOR_JOB_SUCCESS:
      const applied_job = {};
      applied_job[action?.data?.data?.[0]?.jobId?._id] = {
        appliedCandidate: action?.data?.data,
        jobTitle: action?.data?.data?.[0]?.jobId?.jobTitle,
      };
      return { ...state, iscandAppLoading: false, allAppliedJob: applied_job };
    case types.GET_APPLIED_FOR_JOB_FAIL:
      return { ...state, iscandAppLoading: false, allAppliedJob: [] };

    case types.GET_SHORTLISTED_FOR_JOB_START:
      return { ...state, iscandShortLoading: true, allAppliedJob: [] };
    case types.GET_SHORTLISTED_FOR_JOB_SUCCESS:
      const shorlist_profile = {};
      shorlist_profile[action?.data?.data?.[0]?.jobId?._id] = {
        appliedCandidate: action?.data?.data,
        jobTitle: action?.data?.data?.[0]?.jobId?.jobTitle,
      };
      return {
        ...state,
        iscandShortLoading: false,
        allAppliedJob: shorlist_profile,
      };
    case types.GET_SHORTLISTED_FOR_JOB_FAIL:
      return { ...state, iscandShortLoading: false, allAppliedJob: [] };

    case types.GET_ALL_JOB_OF_CLIENT_START:
      return { ...state, isAllAppliedJobLoading: true };
    case types.GET_ALL_JOB_OF_CLIENT_SUCCESS:
      return {
        ...state,
        isAllAppliedJobLoading: false,
        allAppliedJob: action?.data?.data,
      };
    case types.GET_ALL_JOB_OF_CLIENT_FAIL:
      return { ...state, isAllAppliedJobLoading: false, allAppliedJob: [] };

    case types.GET_CLIENT_DEV_LISTS_START:
      if (action?.clearFilter) {
        return { ...state };
      }
      return { ...state, isDevLoading: true };
    case types.GET_CLIENT_DEV_LISTS_SUCCESS:
      let tempArr = { ...state.clientDevLists };
      if (!!tempArr?.data?.length && !action?.firstTime) {
        let tempArrs = [...tempArr?.data, ...action?.data?.data];
        tempArr.data = tempArrs;
      } else {
        tempArr = action?.data;
      }
      return { ...state, isDevLoading: false, clientDevLists: tempArr };
    case types.GET_CLIENT_DEV_LISTS_FAIL:
      return { ...state, isDevLoading: false };

    case types.UNSAVED_PROFILE_SUCCESS:
      const tempDev = { ...state.clientDevLists };
      let index = tempDev.data.findIndex((itm) => itm?._id == action?.devId);
      if (index >= 0) {
        tempDev.data[index].is_saved_profile = false;
      }
      return { ...state, clientDevLists: tempDev };

    case types.GET_CLIENT_SKILL_LISTS_START:
      return { ...state, skillListLoading: true };
    case types.GET_CLIENT_SKILL_LISTS_SUCCESS:
      return {
        ...state,
        skillListLoading: false,
        clientSkillList: action?.data?.data,
      };
    case types.GET_CLIENT_SKILL_LISTS_FAIL:
      return { ...state, skillListLoading: false };

    case types.GET_TOP_FIVE_SKILL_START:
      return { ...state, isLoading: true };
    case types.GET_TOP_FIVE_SKILL_SUCCESS:
      return { ...state, isLoading: false, topFiveSkills: action?.data };
    case types.GET_TOP_FIVE_SKILL_FAIL:
      return { ...state, isLoading: false };

    case types.GET_CLIENT_STACK_LISTS_START:
      return { ...state, stackListsLoading: true };
    case types.GET_CLIENT_STACK_LISTS_SUCCESS:
      return {
        ...state,
        stackListsLoading: false,
        clientStackList: action?.data?.data,
      };
    case types.GET_CLIENT_STACK_LISTS_FAIL:
      return { ...state, stackListsLoading: false };

    case types.GET_CLIENT_JOB_START:
      return { ...state, isLoading: true, clientJobData: [] };
    case types.GET_CLIENT_JOB_SUCCESS:
      return {
        ...state,
        isLoading: false,
        clientJobData: action?.data?.jobDetails,
      };
    case types.GET_CLIENT_JOB_FAIL:
      return { ...state, isLoading: false };

    case types.GET_ALL_SKILL_LIST_START:
      return { ...state, isLoading: true };
    case types.GET_ALL_SKILL_LIST_SUCCESS:
      return { ...state, isLoading: false, allSkills: action.data };
    case types.GET_ALL_SKILL_LIST_FAIL:
      return { ...state, isLoading: false };

    case types.GET_DEV_DETAILS_START:
      return { ...state, isDevDetails: true, devDetails: [] };
    case types.GET_DEV_DETAILS_SUCCESS:
      return { ...state, isDevDetails: false, devDetails: action?.data?.data };
    case types.GET_DEV_DETAILS_FAIL:
      return { ...state, isDevDetails: false };

    case types.GET_CLIENT_JOB_DETAIL_START:
      return { ...state, isClientJobDetails: true, ClientJobDetails: [] };
    case types.GET_CLIENT_JOB_DETAIL_SUCCESS:
      return {
        ...state,
        isClientJobDetails: false,
        ClientJobDetails: action?.data,
      };
    case types.GET_CLIENT_JOB_DETAIL_FAIL:
      return { ...state, isClientJobDetails: false };

    case types.ASSIGN_TO_JOB_START:
      return { ...state, isLoading: true };
    case types.ASSIGN_TO_JOB_SUCCESS:
      return { ...state, isLoading: false };
    case types.ASSIGN_TO_JOB_FAIL:
      return { ...state, isLoading: false };

    case types.GET_SELECTED_SKILLS_START:
      return { ...state, isLoading: true, clientSelectSkills: [] };
    case types.GET_SELECTED_SKILLS_SUCCESS:
      return { ...state, isLoading: false, clientSelectSkills: action?.data };
    case types.GET_SELECTED_SKILLS_FAIL:
      return { ...state, isLoading: false, clientSelectSkills: [] };

    case types.REMOVE_FROM_SHORT_LIST_CLIENT_SUCCESS:
      if (action?.shortList) {
        return {
          ...state,
          isLoading: false,
          clientShortLists: {
            data: state.clientShortLists?.data?.filter(
              (item) => item?._id !== action?.devId
            ),
          },
          totalShortListedCount: state.totalShortListedCount - 1,
        };
      }
      if (action?.devLists) {
        const devIndexNo = state.clientDevLists?.data?.findIndex(
          (item) => item?._id === action?.devId
        );
        const tempDevData = [...state.clientDevLists?.data];
        tempDevData[devIndexNo].is_saved_profile = false;
        return {
          ...state,
          isLoading: false,
          clientDevLists: { data: tempDevData },
          totalShortListedCount: state.totalShortListedCount - 1,
        };
      }
      if (action?.jobDetail) {
        const devIndexNo = state.ClientJobDetails?.applicantList?.findIndex(
          (item) => item?._id === action?.devId
        );
        const tempDevData = [...state.ClientJobDetails?.applicantList];
        tempDevData[devIndexNo].updateJob = "applied";
        return {
          ...state,
          isLoading: false,
          ClientJobDetails: {
            applicantList: tempDevData,
            jobDetails: state?.ClientJobDetails?.jobDetails,
          },
          totalShortListedCount: state.totalShortListedCount - 1,
        };
      }

      return {
        ...state,
        isLoading: false,
        devChooseSkill: [action?.data?.data, ...state.devChooseSkill],
      };
    case types.SAVE_PROFILE_SUCCESS:
      if (action.searchPage) {
        const tempDevData = { ...state.clientDevLists };

        const devIndexNo = tempDevData?.data?.findIndex(
          (item) => item?._id === action?.devId
        );
        tempDevData.data[devIndexNo].is_saved_profile = true;
        return {
          ...state,
          clientDevLists: tempDevData,
          totalShortListedCount: state.totalShortListedCount + 1,
        };
      }
    case types.UNSAVED_PROFILE_SUCCESS:
      return {
        ...state,
        totalShortListedCount: state.totalShortListedCount - 1,
      };
    case types.UPDATE_SHORT_LISTS_SUCCESS:
      const devIndexNo = state.ClientJobDetails?.applicantList?.findIndex(
        (item) => item?._id === action?.devId
      );

      const tempDevData = [...state.ClientJobDetails?.applicantList];
      tempDevData[devIndexNo].updateJob = "shortlisted";
      return {
        ...state,
        isLoading: false,
        ClientJobDetails: {
          applicantList: tempDevData,
          jobDetails: state?.ClientJobDetails?.jobDetails,
        },
        totalShortListedCount: state.totalShortListedCount + 1,
      };
    case types.ADD_NEW_JOB_POST_SUCCCESS:
      const addJobData = state?.clientAllJoblists?.data?.length
        ? [...state?.clientAllJoblists?.data]
        : [];
      addJobData?.unshift(action.data);
      return {
        ...state,
        clientAllJoblists: { ...state.clientAllJoblists, data: addJobData },
        clientJobOperation: "",
      };

    case types.UPDATE_CLIENT_JOB_SUCCESS:
      const clientJobIndexNo = state.clientAllJoblists?.data?.findIndex(
        (item) => item?._id === action?.data?._id
      );
      const tempjobData = [...state.clientAllJoblists?.data];
      tempjobData[clientJobIndexNo] = {
        ...action?.data,
        devAppliedCount: state?.applicant || 0,
      };
      return {
        ...state,
        clientAllJoblists: { data: tempjobData },
        clientJobOperation: "",
      };

    case types.UPDATE_CLIENT_JOB_STATUS_SUCCESS:
      const clientJobIndexNumber = state.clientAllJoblists?.data?.findIndex(
        (item) => item?._id === action?.status?.id
      );
      const tempJobData = [...state.clientAllJoblists?.data];
      tempJobData[clientJobIndexNumber].isActive = action?.status?.isActive;
      return {
        ...state,
        clientAllJoblists: { ...state.clientAllJoblists, data: tempJobData },
      };

    case types.DELETE_CLIENT_JOB_SUCCESS:
      return {
        ...state,
        clientAllJoblists: {
          data: state.clientAllJoblists?.data.filter(
            (item) => item?._id !== action?.jobId
          ),
        },
      };

    case types.GET_COMPANY_PROFILE_START:
      return { ...state, profileLoading: true, clientProfile: {} };
    case types.GET_COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        profileLoading: false,
        clientProfile: action?.data?.data[0],
      };
    case types.GET_COMPANY_PROFILE_FAIL:
      return { ...state, profileLoading: false, clientProfile: {} };

    case types.GET_CLIENT_FIRST_ENTRY_START:
      return { ...state, isLoading: true };
    case types.GET_CLIENT_FIRST_ENTRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        clientFirstEntry: action?.data?.data?.firstEntry,
      };
    case types.GET_CLIENT_FIRST_ENTRY_FAIL:
      return { ...state, isLoading: false };

    case types.UPDATE_CLIENT_FIRST_ENTRY_START:
      return { ...state, isLoading: true };
    case types.UPDATE_CLIENT_FIRST_ENTRY_SUCCESS:
      return { ...state, isLoading: false };
    case types.UPDATE_CLIENT_FIRST_ENTRY_FAIL:
      return { ...state, isLoading: false };

    case types.SAVE_COMPANY_PROFILE_START:
      if (action?.status == "publish")
        return { ...state, publishLoading: true };
      return { ...state, draftLoading: true };

    case types.SAVE_COMPANY_PROFILE_SUCCESS:
      if (action?.data?.status == "publish")
        return {
          ...state,
          draftLoading: false,
          clientProfile: { ...action?.data, status: "publish" },
          publishLoading: false,
        };
      return { ...state, draftLoading: false, publishLoading: false };
    case types.SAVE_COMPANY_PROFILE_FAIL:
      return { ...state, draftLoading: false, publishLoading: false };

    case types.CLIENT_EMAIL_AUTO_START:
      return { ...state };
    case types.CLIENT_EMAIL_AUTO_SUCCESS:
      return { ...state, devActivations: action?.data };
    case types.CLIENT_EMAIL_AUTO_FAIL:
      return { ...state };

    case types.CLIENT_ACTIVATION_SUCCESS:
      let tempObjects = { ...state.devActivations };
      tempObjects?.data?.map((item, index) => {
        if (item?.slug == action?.data?.emailType) {
          tempObjects.data[index].status = action?.data?.status;
        }
      });
      return { ...state, devActivations: tempObjects };

    case types.GET_COMPANY_USER_START:
      return { ...state, isCompanyUserLoading: true };
    case types.GET_COMPANY_USER_SUCCESS:
      return {
        ...state,
        companyUserData: action.data,
        isCompanyUserLoading: false,
      };
    case types.GET_COMPANY_USER_FAIL:
      return { ...state, isCompanyUserLoading: false };

    case types.CLIENT_INVITE_USER_START:
      return { ...state, isClientUserInviting: true };
    case types.CLIENT_INVITE_USER_SUCCESS:
      return { ...state, isClientUserInviting: false };
    case types.CLIENT_INVITE_USER_FAIL:
      return { ...state, isClientUserInviting: false };

    case types.CLIENT_ACTIVATE_DEACTIVATE_USER_START:
      return { ...state, isClientStatusChanging: true };
    case types.CLIENT_ACTIVATE_DEACTIVATE_USER_SUCCESS:
      return { ...state, isClientStatusChanging: false };
    case types.CLIENT_ACTIVATE_DEACTIVATE_USER_FAIL:
      return { ...state, isClientStatusChanging: false };

    case types.COMPANY_ALL_ACTIVE_USER_START:
      return { ...state, allActiveUserLoading: true, allActiveUser: [] };
    case types.COMPANY_ALL_ACTIVE_USER_SUCCESS:
      return {
        ...state,
        allActiveUserLoading: false,
        allActiveUser: action?.data,
      };
    case types.COMPANY_ALL_ACTIVE_USER_FAIL:
      return { ...state, allActiveUserLoading: false, allActiveUser: [] };

    //Interview
    case types.GET_INTERVIEWS_DATA_START:
      return { ...state, isInterviewDataLoading: true, interviewsData: [] };
    case types.GET_INTERVIEWS_DATA_SUCCESS:
      return {
        ...state,
        isInterviewDataLoading: false,
        interviewsData: action?.data,
      };
    case types.GET_INTERVIEWS_DATA_FAIL:
      return {
        ...state,
        isInterviewDataLoading: false,
        interviewsData: [],
      };
    case types.GET_INTERVIEWS_DATA_DEV_START:
      return { ...state, isInterviewDataLoading: true, devInterviewsData: [] };
    case types.GET_INTERVIEWS_DATA_DEV_SUCCESS:
      return {
        ...state,
        isInterviewDataLoading: false,
        devInterviewsData: action?.data,
      };
    case types.GET_INTERVIEWS_DATA_DEV_FAIL:
      return {
        ...state,
        isInterviewDataLoading: false,
        devInterviewsData: [],
      };
    case types.SET_INTERVIEWS_SLOT_DEV_START:
      return { ...state, isInterviewSlotLoading: true };
    case types.SET_INTERVIEWS_SLOT_DEV_SUCCESS:
      return {
        ...state,
        isInterviewSlotLoading: false,
      };
    case types.SET_INTERVIEWS_SLOT_DEV_FAIL:
      return {
        ...state,
        isInterviewSlotLoading: false,
      };

    case types.JOB_REFRESH_START:
      return { ...state, isJobRefreshing: true };

    case types.GET_DEV_FILTER_START:
      return { ...state, loadingDevFilter: true };
    case types.GET_DEV_FILTER_SUCCESS:
      if (action?.firstTime) {
        return {
          ...state,
          loadingDevFilter: false,
          devFilterData: action?.data,
        };
      } else {
        let tempData = { ...state?.devFilterData };
        let tempARR = [...tempData.data, ...action?.data?.data];
        tempData.data = tempARR;
        return { ...state, loadingDevFilter: false, devFilterData: tempData };
      }
    case types.GET_DEV_FILTER_FAIL:
      return { ...state, loadingDevFilter: false };

    case types.GET_SUMMARY_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionSummary: action?.data?.data,
      };

    case types.GET_CLIENT_PAYMENT_HISTORY_START:
      return {
        ...state,
        isHistoryLoading: true,
      };
    case types.GET_CLIENT_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        isHistoryLoading: false,
        paymentHistory: action?.data,
      };
    case types.GET_CLIENT_PAYMENT_HISTORY_FAIL:
      return {
        ...state,
        isHistoryLoading: false,
      };

      case types.DELETE_EMAIL_START:
        return {
          ...state,
          isDeleteEmail: true,
        };
      case types.DELETE_EMAIL_SUCCESS:
        return {
          ...state,
          isDeleteEmail: false,
        };
      case types.DELETE_EMAIL_FAIL:
        return {
          ...state,
          isDeleteEmail: false,
        };


      

    default:
      return { ...state };
  }
};
