import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { clientHomePageOG } from "../imageLinks";
import NoFollow from "../../../common/staticData/seo/NoFollow";

const ClientHomePage = ({ baseUrl }) => {
  return (
    <Helmet>
      <title>Hire Full-Time vetted software developers & engineers</title>

      <meta charset="UTF-8" />
      <meta
        name="title"
        content="Hire Full-Time vetted software developers & engineers"
      />
      <meta
        name="description"
        content="Olibr - Our platform provides a way for candidates to apply for software developer jobs and recruiters to hire top vetted software engineers in the US & India."
      ></meta>

      <link rel="canonical" href={`${baseUrl}/`} />

      <meta
        name="keywords"
        content="online jobs work from home, Remote software engineers, online work at home jobs, Remote Jobs, Top US jobs companies, jobs for work from home, working from home jobs, near me part time jobs, a job for part time"
      />
         <meta name="robots" content={NoFollow()}></meta>
      <meta name="language" content="english"></meta>
      <html lang="en" />

      <meta name="url" content={`${baseUrl}/`} />
      <meta name="identifier-URL" content={`${baseUrl}/`}></meta>
      <meta name="referrer" content="origin-when-crossorigin"></meta>
      <link rel="image_src" href={clientHomePageOG} />
      <meta name="copyright" content="olibr.com" />

      {/* ----Social Media Open Graph--------- */}
      <meta
        property="og:title"
        content="Hire Full-Time vetted software developers & engineers"
      />
      <meta property="og:site_name" content="Olibr" />
      <meta property="og:url" content={`${baseUrl}/`} />
      <meta
        property="og:description"
        content="Olibr - Our platform provides a way for candidates to apply for software developer jobs and recruiters to hire top vetted software engineers in the US & India."
      />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={clientHomePageOG} />

      {/* ------ Twitter Cards--------- */}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@olibr" />
      <meta property="twitter:domain" content="olibr.com" />
      <meta property="twitter:url" content={`${baseUrl}/`} />
      <meta
        name="twitter:title"
        content="Hire Full-Time vetted software developers & engineers"
      />
      <meta
        name="twitter:description"
        content="Olibr - Our platform provides a way for candidates to apply for software developer jobs and recruiters to hire top vetted software engineers in the US & India."
      />
      <meta name="twitter:image" content={clientHomePageOG} />

      <meta property="og:type" content="business.business" />
      <meta
        property="business:contact_data:street_address"
        content="26 Arrowhead Ln"
      />
      <meta property="business:contact_data:locality" content="Menlo Park" />
      <meta property="business:contact_data:region" content="CA" />
      <meta property="business:contact_data:postal_code" content="94025" />
      <meta property="business:contact_data:country_name" content="US" />

      {/* ------Apple Devices ------ */}

      <meta
        name="apple-mobile-web-app-title"
        content="Hire Full-Time vetted software developers & engineers"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-touch-fullscreen" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black"></meta>

      {/* ----------JSON LD Schema for SEO------ */}

      <script type="application/ld+json">
        {JSON.stringify(
          // ------oraganisation schema ------
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Olibr.com",
            url: `${baseUrl}/`,
            logo: "%PUBLIC_URL%/olibr-full-logo.png",
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "000000000",
              contactType: "technical support",
            },
            sameAs: [
              "https://www.facebook.com/olibrcom",
              "https://www.linkedin.com/company/olibrcom/",
              "https://twitter.com/olibr",
              "https://www.instagram.com/olibr",
              "https://www.youtube.com/@olibr",
              "https://in.pinterest.com/olibr/",
              "",
              `${baseUrl}/`,
            ],
          }
        )}
      </script>
      {/* ---------------------------- */}
      <script type="application/ld+json">
        {JSON.stringify(
          // -----Website/webpage Schema:----

          {
            "@context": "https://schema.org/",
            "@type": "WebSite",
            name: "Olibr.com",
            url: `${baseUrl}/`,
            potentialAction: {
              "@type": "SearchAction",
              target: `${baseUrl}/{search_term_string}`,
              "query-input": "required name=search_term_string",
            },
          }
        )}
      </script>
      {/* ------------------------------ */}
      <script type="application/ld+json">
        {JSON.stringify(
          //  ---- Local Business Schema:-----

          {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            name: "Olibr",
            image: clientHomePageOG,
            "@id": "",
            url: `${baseUrl}/`,
            telephone: "0000000000",
            priceRange: "N/A",
            address: {
              "@type": "PostalAddress",
              streetAddress: "26 Arrowhead Ln",
              addressLocality: "Menlo Park",
              addressRegion: "CA",
              postalCode: "94025",
              addressCountry: "US",
            },
            geo: {
              "@type": "GeoCoordinates",
              latitude: 37.469217,
              longitude: -122.2046455,
            },
            sameAs: [
              "https://www.facebook.com/olibrcom",
              "https://twitter.com/olibr",
              "https://www.instagram.com/olibr",
              "https://www.youtube.com/@olibr",
              "https://www.linkedin.com/company/olibrcom/",
              "https://in.pinterest.com/olibr/",
              `${baseUrl}/`,
            ],
          }
        )}
      </script>
      {/* ------------------------- */}
      <script type="application/ld+json">
        {JSON.stringify(
          // -----Speakable Schema-----

          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            name: "Best platform for remote Jobs",
            speakable: {
              "@type": "SpeakableSpecification",
              xpath: [
                "/html/head/title",
                "/html/head/meta[@name='description']/@content",
              ],
            },
            url: `${baseUrl}/`,
          }
        )}
      </script>
      {/* ------------------------------- */}
      <script type="application/ld+json">
        {JSON.stringify(
          // ------------FAQ Schema-------
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [
              {
                "@type": "Question",
                name: "Why should I hire developers from Olibr?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Olibr has a vast network of pre-vetted, seasoned developers ready to work full-time from their remote locations. Our deep matching and extensive vetting process source all the data required to vet developers to match them with US job market standards. Our AI matches and finds the best fit for your business requirements from our large community of vetted developers. Many leading US companies are hiring with us for our proven success in distributed teams, quick scalability, higher retention, security and compliance, and complete remote onboarding & support.",
                },
              },
              {
                "@type": "Question",
                name: "What is the process to hire a developer from Olibr?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Hiring developers with Olibr is easy. You get access to our large database of vetted developers. You can shortlist the candidates that best suit your business requirements and we will arrange an interview for you with the shortlisted candidates. Secondly, if you don’t find what you are looking for in our database, you can contact us and share your specific requirements. Our experts will assess your needs and look for candidates that fit your job requirements. They will send you the shortlisted candidates and you can pick your best fit to interview and hire. Lastly, Olibr gives you the liberty to do your own job posting on our website. Suitable candidates can apply for the position directly and you can choose from the applied list.",
                },
              },
              {
                "@type": "Question",
                name: "What type of work engagements does Olibr offer?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Olibr only offers long-term full-time job engagements between clients and developers.",
                },
              },
              {
                "@type": "Question",
                name: "Can Olibr build up a team for us?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Yes, Olibr can help you scale up your team. You will just have to share your requirements with us, and we will find you candidates that are perfectly suitable to scale up your dream team. You also get access to our large database of developers to choose from. Shortlist your preferred candidates and inform us so that we can arrange an interview for you with the candidates. You also can post jobs directly on our website and select candidates accordingly.",
                },
              },
              {
                "@type": "Question",
                name: "How quickly can I hire an Olibr developer?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Hiring a dedicated full-time developer takes time. Unlike freelancers who are readily available and work on multiple projects at a time, dedicated full-time developers are looking for the best opportunity that offers career growth, stability, and other benefits. Remember, you are at Olibr to hire senior skilled developers. They are the cream of the crop and transitioning from one position to another involves certain rules and procedures. As they are dedicated employees, they are expected to serve a 1-3 months’ notice period in their current positions so either you will have to give them the time to serve their notice period or buy out the candidate’s notice period.",
                },
              },
            ],
          }
        )}
      </script>
      {/* ------------------------ */}
    </Helmet>
  );
};

export default ClientHomePage;
