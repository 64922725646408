import { put, call } from "redux-saga/effects";
import * as types from "../../action";
import { olibrToast } from "../../../components/toaster";
import {
  getFaqLists,
  changePassword,
  changeEmail,
  getAllFaqGroup,
  getJobLists,
  getBlogCategories,
  getAllBlogsById,
  getBlogDetail,
  getRecentBlogs,
  logout,
  getCountry,
  getState,
  getCity,
  ContactUs,
  getBlogs,
  getSpotlightedCandidates,
  getResume,
  getClientProfile,
  getUser,
  getBlogCatOpenApi,
  getBlogOpenApi,
  getBlogByIdOpenApi,
  getRecentFiveBlogApi,
  getPopularBlogApi,
  getBlogBySlugOpenApi,
  getResumeOpenApi,
  getJobByIdOpenApi,
  chatGPTStandardEngApi,
  getMaintenanceOneApi,
  getJobByIdApi,
  getJobByCategoryApi,
  getPopularJobsApi,
  uploadMultiImageApi,
  getDevInfoForReviewApi,
  giveRatingDevReviewApi,
  getDevReviewStatusApi,
  uploadVideoApi,
  getDevVideoApi,
  uploadVideoWithoutDeleteApi,
  getSkillByCategoryDevApi,
  getDevSkillTestResultApi,
  getSkillTestStepDataApi,
  showOpenFaqsApi,
  getNotificationApi,
  approvalUpdateApi,
  getDeveloperProfile,
  convertImageApi,
  updateTermApi,
  clearFeatureApi,
  getFeatureApi,
  getSitemapUrlApi,
  getPricingPlanApi,
  startPhonePayPaymentApi,
  clearNoficationApi,
  startStripePaymentApi,
  checkoutPlanAPI,
  getCompanyJobsAPI,
  addDevSkillAPI
} from "../../api";


export function* getDevSkillTestResultSaga({ data, navigate, setModal, devId }) {
  try {
    yield put({ type: types.GET_DEV_SKILL_TEST_RESULT_START });
    const response = yield call(getDevSkillTestResultApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_DEV_SKILL_TEST_RESULT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        const resp = yield call(getSkillTestStepDataApi);
        yield put({
          type: types.GET_DEV_SKILL_TEST_RESULT_SUCCESS,
          data: response.data,
        });
        setModal(response.data.data);
        yield put({
          type: types.GET_SKILL_TEST_STEP_DATA_SUCCESS,
          data: resp?.data,
        });
        if(!!devId){
          const profileResp = yield call(getDeveloperProfile, devId);
          yield put({
            type: types.GET_DEVELOPER_PROFILE_SUCCESS,
            data: profileResp?.data?.data,
          });

        }
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.GET_DEV_SKILL_TEST_RESULT_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.GET_DEV_SKILL_TEST_RESULT_FAIL });
  }
}

export function* getReviewStatusSaga({ data, navigate }) {
  try {
    yield put({
      type: types.GET_DEV_REVIEW_STATUS_START,
    });
    const response = yield call(getDevReviewStatusApi, data);
    if (response.data.status === 401) {
      olibrToast.error(response?.data?.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_DEV_REVIEW_STATUS_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_DEV_REVIEW_STATUS_FAIL,
    });
  }
}
export function* getDevInfoForReviewSaga({ devId, navigate }) {
  try {
    yield put({
      type: types.GET_DEV_INFO_FOR_REVIEW_START,
    });
    const response = yield call(getDevInfoForReviewApi, devId);
    if (response.data.status === 401) {
      olibrToast.error(response?.data?.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_DEV_INFO_FOR_REVIEW_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_DEV_INFO_FOR_REVIEW_FAIL,
    });
  }
}

export function* giveRatingDevReviewSaga({ data, navigate }) {
  try {
    yield put({ type: types.GIVE_RATING_DEV_REVIEW_START });
    const response = yield call(giveRatingDevReviewApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.GIVE_RATING_DEV_REVIEW_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.GIVE_RATING_DEV_REVIEW_SUCCESS,
          data: response.data,
        });
        navigate("/acknowledgement");
      } else {
        yield put({ type: types.GIVE_RATING_DEV_REVIEW_FAIL });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.GIVE_RATING_DEV_REVIEW_FAIL });
  }
}

export function* uploadVideoSaga({
  data,
  navigate,
  userId,
  setVideoPercentage,
}) {
  try {
    yield put({ type: types.UPLOAD_VIDEO_START });
    const response = yield call(
      uploadVideoApi,
      data,
      userId,
      setVideoPercentage
    );
    if (response.data.status === 401) {
      yield put({ type: types.UPLOAD_VIDEO_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      const resp = yield call(getDevVideoApi, userId);
      yield put({
        type: types.UPLOAD_VIDEO_SUCCESS,
        data: response?.data?.payload,
      });
      yield put({
        type: types.GET_DEV_VIDEO_SUCCESS,
        data: resp?.data,
      });
      setVideoPercentage(0);
      olibrToast.success("Video Uploaded!");
    }
  } catch (error) {
    olibrToast.error("Something Went Wrong!");
    yield put({ type: types.UPLOAD_VIDEO_FAIL });
  }
}

export function* uploadVideoWithoutDeleteSaga({
  data,
  navigate,
  setVideoPercentage,
  fromVideoTab
}) {
  try {
    yield put({ type: types.UPLOAD_VIDEO_WITHOUT_DELETE_START });
    const response = yield call(
      uploadVideoWithoutDeleteApi,
      data,
      setVideoPercentage
    );
    if (response.data.status === 401) {
      yield put({ type: types.UPLOAD_VIDEO_WITHOUT_DELETE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      let videoLink = response?.data?.payload?.filename; 
      console.log("videoLink", videoLink);
      if(fromVideoTab){
        let newValue = {};
        let slug = "devVideo";
        newValue[`${slug}`] = videoLink;
        let postData = {
          slug: slug,
          newValue: newValue,
        }
        console.log(postData);
        const resp = yield call(approvalUpdateApi, postData);
        if(resp.data.statusCode === "SUCCESS"){
          yield put({
            type: types.GET_APPROVAL_UPDATE_SUCCESS,
            data: resp?.data,
          });
          yield put({ type: types.APPROVAL_UPDATE_SUCCESS });
        }
      }
      yield put({
        type: types.UPLOAD_VIDEO_WITHOUT_DELETE_SUCCESS,
        data: response?.data?.payload,
      });
      olibrToast.success("Video Uploaded!");
      setVideoPercentage(0);
    }
  } catch (error) {
    olibrToast.error("Something Went Wrong!");
    yield put({ type: types.UPLOAD_VIDEO_WITHOUT_DELETE_FAIL });
  }
}
export function* uploadMultiImageSaga({ data, navigate ,cb}) {
  try {
    yield put({ type: types.UPLOAD_MULTI_IMAGE_START });
    const response = yield call(uploadMultiImageApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPLOAD_MULTI_IMAGE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.UPLOAD_MULTI_IMAGE_SUCCESS,
        data: response?.data?.payload,
      });
      cb && cb(response)
    }
  } catch (error) {
    yield put({ type: types.UPLOAD_MULTI_IMAGE_FAIL });
  }
}

export function* showOpenFaqsSaga({ navigate }) {
  try {
    yield put({ type: types.SHOW_OPEN_FAQS_START });
    const response = yield call(showOpenFaqsApi);
    if (response.data.status === 401) {
      yield put({ type: types.SHOW_OPEN_FAQS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.SHOW_OPEN_FAQS_SUCCESS,
        data: response.data.data,
      });
    }
  } catch (error) {
    yield put({ type: types.SHOW_OPEN_FAQS_FAIL });
  }
}

export function* getPopularJobsSaga({ navigate }) {
  try {
    yield put({ type: types.GET_POPULAR_JOB_START });
    const response = yield call(getPopularJobsApi);
    if (response.data.status === 401) {
      yield put({ type: types.GET_POPULAR_JOB_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_POPULAR_JOB_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_POPULAR_JOB_FAIL });
  }
}

export function* getJobByCategorySaga({
  navigate,
  catId,
  data,
  pageData,
  filter,
  search,
}) {
  try {
    yield put({ type: types.GET_JOB_BY_CAT_START });
    const response = yield call(
      getJobByCategoryApi,
      catId,
      data,
      pageData,
      search
    );
    if (response.data.status === 401) {
      yield put({ type: types.GET_JOB_BY_CAT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (filter) {
        yield put({
          type: types.GET_JOB_BY_CAT_FILTER_SUCCESS,
          data: response?.data,
        });
      } else {
        yield put({
          type: types.GET_JOB_BY_CAT_SUCCESS,
          data: response?.data,
        });
      }
    }
  } catch (error) {
    yield put({ type: types.GET_JOB_BY_CAT_FAIL });
  }
}

export function* getJobByJobIdSaga({ jobId, navigate }) {
  try {
    yield put({ type: types.GET_JOB_BY_ID_START });
    const response = yield call(getJobByIdApi, jobId);
    if (response.data.status === 401) {
      yield put({ type: types.GET_JOB_BY_ID_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if(Object.keys(response?.data?.data).length){
        yield put({
          type: types.GET_JOB_BY_ID_SUCCESS,
          data: response.data,
        });
      }else{
       navigate("/error-404")
      }
    }
  } catch (error) {
    yield put({ type: types.GET_JOB_BY_ID_FAIL });
  }
}

export function* contactUsSaga({ data, navigate }) {
  try {
    yield put({ type: types.CONTACT_US_POST_START });
    const response = yield call(ContactUs, data);
    if (response.data.statusCode === "FAIL") {
      yield put({ type: types.CONTACT_US_POST_FAIL });
      olibrToast.error(response?.data?.message);
      // navigate("/login");
    } else {
      yield put({ type: types.CONTACT_US_POST_SUCCESS, data: response?.data });
      olibrToast.success(response?.data?.message);
    }
  } catch (error) {
    yield put({ type: types.CONTACT_US_POST_FAIL });
  }
}

export function* getCitySaga({ data, navigate }) {
  try {
    yield put({ type: types.GET_CITY_START });
    const response = yield call(getCity, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_CITY_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_CITY_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.GET_CITY_FAIL });
  }
}

export function* getStateSaga({ data, navigate }) {
  try {
    yield put({ type: types.GET_STATE_START });
    const response = yield call(getState, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_STATE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_STATE_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.GET_STATE_FAIL });
  }
}

export function* getCountrySaga({ navigate }) {
  try {
    yield put({ type: types.GET_COUNTRY_START });
    const response = yield call(getCountry);
    if (response.data.status === 401) {
      yield put({ type: types.GET_COUNTRY_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_COUNTRY_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.GET_COUNTRY_FAIL });
  }
}

export function* getFaqListsSaga() {
  try {
    yield put({ type: types.GET_FAQ_LISTS_START });
    const response = yield call(getFaqLists);
    yield put({
      type: types.GET_FAQ_LISTS_SUCCESS,
      data: response?.data?.data,
    });
  } catch (error) {
    yield put({ type: types.GET_FAQ_LISTS_FAIL });
  }
}

export function* changePasswordSaga({ data, navigate, closeModal }) {
  try {
    yield put({ type: types.CHANGE_PASSWORD_START });
    const response = yield call(changePassword, data);
    yield put({ type: types.CHANGE_PASSWORD_SUCCESS, data: response.data });
    if (response?.data?.statusCode === "SUCCESS") {
      olibrToast.success(response?.data?.message);
      closeModal();
      yield put({ type: types.LOGOUT_SUCCESS, data: response?.data?.data });
      // yield call(logout);
      // navigate("/");
    } else if (response?.data?.statusCode === "FAIL") {
      olibrToast.error(response?.data?.message);
    }
  } catch (error) {
    yield put({ type: types.CHANGE_PASSWORD_FAIL });
  }
}

export function* changeEmailSaga({ email, navigate }) {
  try {
    yield put({ type: types.CHANGE_EMAIL_START });
    const response = yield call(changeEmail, email);
    if (response?.data?.statusCode === "SUCCESS") {
      olibrToast.success(response?.data?.message);
      yield put({ type: types.CHANGE_EMAIL_SUCCESS, data: response.data });
      yield call(logout);
      navigate("/");
    } else if (response?.data?.statusCode === "FAIL") {
      olibrToast.error(response?.data?.message);
    }
  } catch (error) {
    yield put({ type: types.CHANGE_EMAIL_FAIL });
  }
}

export function* getAllFaqGroupSaga({ data, navigate }) {
  try {
    yield put({ type: types.GET_ALL_FAQ_GROUP_START });
    const response = yield call(getAllFaqGroup, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ALL_FAQ_GROUP_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_ALL_FAQ_GROUP_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.GET_ALL_FAQ_GROUP_FAIL });
  }
}

export function* getJobListsSaga({ navigate,pageData}) {
  try {
    yield put({ type: types.GET_JOB_LIST_START });
    const response = yield call(getJobLists,pageData);
    yield put({ type: types.GET_JOB_LIST_SUCCESS, data: response?.data?.data });
  } catch (error) {
    yield put({ type: types.GET_JOB_LIST_FAIL });
  }
}

export function* getBlogsSaga({ searchValue, currentBlogCategory, navigate }) {
  try {
    yield put({ type: types.GET_BLOGS_START });
    const response = yield call(getBlogs, searchValue, currentBlogCategory);
    if (response.data.status === 401) {
      yield put({ type: types.GET_BLOGS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_BLOGS_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.GET_BLOGS_FAIL });
  }
}

export function* getBlogCategoriesSaga({ navigate }) {
  try {
    yield put({ type: types.GET_BLOG_CATEGORIES_START });
    const response = yield call(getBlogCategories);
    if (response.data.status === 401) {
      yield put({ type: types.GET_BLOG_CATEGORIES_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_BLOG_CATEGORIES_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_BLOG_CATEGORIES_FAIL });
  }
}

export function* getJobByIdSaga({ data, navigate }) {
  try {
    yield put({ type: types.GET_JOB_BY_ID_LIST_START });
    const response = yield call(getJobByIdOpenApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_JOB_BY_ID_LIST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_JOB_BY_ID_LIST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_JOB_BY_ID_LIST_FAIL });
  }
}

export function* getAllBlogsByIdSaga({ data, navigate }) {
  try {
    yield put({ type: types.GET_ALL_BLOG_BY_ID_START });
    const response = yield call(getAllBlogsById, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ALL_BLOG_BY_ID_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ALL_BLOG_BY_ID_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ALL_BLOG_BY_ID_FAIL });
  }
}

export function* getRecentFiveBlogSaga({ navigate }) {
  try {
    yield put({ type: types.RECENT_FIVE_BLOGS_START });
    const response = yield call(getRecentFiveBlogApi);
    if (response.data.status === 401) {
      yield put({ type: types.RECENT_FIVE_BLOGS_FAIL });
    } else {
      yield put({
        type: types.RECENT_FIVE_BLOGS_SUCCESS,
        data: response.data.data,
      });
    }
  } catch (error) {
    yield put({ type: types.RECENT_FIVE_BLOGS_FAIL });
  }
}

export function* getBlogDetailSaga({ slug, navigate }) {
  try {
    yield put({ type: types.GET_BLOG_DETAIL_START });
    const response = yield call(getBlogDetail, slug);
    if (response.data.status === 401) {
      yield put({ type: types.GET_BLOG_DETAIL_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_BLOG_DETAIL_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.GET_BLOG_DETAIL_FAIL });
  }
}

export function* getRecentBlogsSaga(navigate) {
  try {
    yield put({ type: types.GET_RECENT_BLOGS_START });
    const response = yield call(getRecentBlogs);
    if (response.data.status === 401) {
      yield put({ type: types.GET_RECENT_BLOGS_FAIL });
      olibrToast.error("Blogs Could not be fetched!!!");
      navigate("/login");
    } else {
      yield put({ type: types.GET_RECENT_BLOGS_SUCCESS, data: response });
    }
  } catch (error) {
    yield put({ type: types.GET_RECENT_BLOGS_FAIL });
  }
}

export function* getPopularBlogsSaga(navigate) {
  try {
    yield put({ type: types.GET_POPULAR_BLOGS_START });
    const response = yield call(getPopularBlogApi);
    if (response.data.status === 401) {
      yield put({ type: types.GET_POPULAR_BLOGS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_POPULAR_BLOGS_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.GET_POPULAR_BLOGS_FAIL });
  }
}

export function* getSpotlightedCandidatesSaga(navigate) {
  try {
    yield put({ type: types.GET_SPOTLIGHTED_CANDIDATES_START });
    const response = yield call(getSpotlightedCandidates);
    if (response.data.status === 401) {
      yield put({ type: types.GET_SPOTLIGHTED_CANDIDATES_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_SPOTLIGHTED_CANDIDATES_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_SPOTLIGHTED_CANDIDATES_FAIL });
  }
}

export function* checkAuthenticationSaga({ navigate, userType }) {
  try {
    yield put({ type: types.CHECK_AUTHENTICATION_USER_START });
    switch (userType) {
      case "Client":
        const responseClient = yield call(getClientProfile);
        if (responseClient?.data?.statusValue === 200) {
          olibrToast.error("You have not access!")
        }
        break;
      case "Developer":
        const responseDeveloper = yield call(getResume);
        if (responseDeveloper?.data?.statusValue === 200) {
          olibrToast.error("You have not access!")
        }
        break;

      case "Admin":
        const responseAdmin = yield call(getUser);
        if (responseAdmin?.data?.statusValue === 200) {
          navigate("/admin/dashboard");
        }
        break;
      default:
        break;
    }
  } catch (error) {}
}

export function* getBlogCatOpenSaga(navigate) {
  try {
    yield put({ type: types.GET_BLOG_CAT_OPEN_START });
    const response = yield call(getBlogCatOpenApi);
    if (response.data.status === 401) {
      yield put({ type: types.GET_BLOG_CAT_OPEN_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_BLOG_CAT_OPEN_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.GET_BLOG_CAT_OPEN_FAIL });
  }
}

export function* getBlogOpenSaga(navigate) {
  try {
    yield put({ type: types.GET_BLOG_OPEN_START });
    const response = yield call(getBlogOpenApi);
    if (response.data.status === 401) {
      yield put({ type: types.GET_BLOG_OPEN_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_BLOG_OPEN_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.GET_BLOG_OPEN_FAIL });
  }
}

export function* getBlogByIdOpenSaga({ blogId, navigate }) {
  try {
    yield put({ type: types.GET_BLOG_BY_ID_OPEN_START });
    const response = yield call(getBlogByIdOpenApi, blogId);
    if (response.data.status === 401) {
      yield put({ type: types.GET_BLOG_BY_ID_OPEN_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_BLOG_BY_ID_OPEN_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_BLOG_BY_ID_OPEN_FAIL });
  }
}

export function* getBlogBySlugOpenSaga({ data, navigate }) {
  try {
    yield put({ type: types.GET_BLOG_BY_SLUG_OPEN_START });
    const response = yield call(getBlogBySlugOpenApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_BLOG_BY_SLUG_OPEN_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_BLOG_BY_SLUG_OPEN_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_BLOG_BY_SLUG_OPEN_FAIL });
  }
}

export function* chatGPTStandardEngSaga({ data, navigate }) {
  try {
    yield put({ type: types.OPENAI_STANDARD_ENGLISH_START });
    const response = yield call(chatGPTStandardEngApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.OPENAI_STANDARD_ENGLISH_FAIL });
      olibrToast.error(response?.data?.message);
      navigate("/login");
    } else {
      yield put({
        type: types.OPENAI_STANDARD_ENGLISH_SUCCESS,
        data: response?.data,
      });
      olibrToast.success(response?.data?.message);
    }
  } catch (error) {
    yield put({ type: types.OPENAI_STANDARD_ENGLISH_FAIL });
  }
}

export function* getMaintenanceOneMessageSaga({ navigate }) {
  try {
    yield put({ type: types.GET_MAINTENANCE_ONE_MESSAGE_START });
    const response = yield call(getMaintenanceOneApi);
    if (response.data.status === 401) {
      yield put({ type: types.GET_MAINTENANCE_ONE_MESSAGE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_MAINTENANCE_ONE_MESSAGE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_MAINTENANCE_ONE_MESSAGE_FAIL });
  }
}

export function* getSkillByCategoryDevSaga({ navigate }) {
  try {
    yield put({ type: types.GET_SKILL_BY_CATEGORY_DEV_START });
    const response = yield call(getSkillByCategoryDevApi);
    if (response.data.status === 401) {
      yield put({ type: types.GET_SKILL_BY_CATEGORY_DEV_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_SKILL_BY_CATEGORY_DEV_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_SKILL_BY_CATEGORY_DEV_FAIL });
  }
}

export function* getNotificationSaga({ navigate }) {
  try {
    yield put({ type: types.GET_NOTIFICATION_START });
    const response = yield call(getNotificationApi);
    if (response.data.status === 401) {
      yield put({ type: types.GET_NOTIFICATION_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_NOTIFICATION_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_NOTIFICATION_FAIL });
  }
}

export function* clearNotificationSaga({ navigate }) {
  try {
    yield put({ type: types.CLEAR_NOTIFICATION_START });
    const response = yield call(clearNoficationApi);
    if (response.data.status === 401) {
      yield put({ type: types.CLEAR_NOTIFICATION_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.CLEAR_NOTIFICATION_SUCCESS,
      });
    }
  } catch (error) {
    yield put({ type: types.CLEAR_NOTIFICATION_FAIL });
  }
}

export function* convertImageSaga({ navigate, data }) {
  try {
    yield put({ type: types.CONVERT_IMAGE_START });
    const response = yield call(convertImageApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.CONVERT_IMAGE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.CONVERT_IMAGE_SUCCESS,
        data: response?.data?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.CONVERT_IMAGE_FAIL });
  }
}

export function* updateTermSaga({ navigate,setModal }) {
  try {
    yield put({ type: types.UPDATE_TERM_CONDITION_START });
    const response = yield call(updateTermApi);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_TERM_CONDITION_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      setModal(false)
      yield put({
        type: types.UPDATE_TERM_CONDITION_SUCCESS,
        data: response?.data?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.UPDATE_TERM_CONDITION_FAIL });
  }
}
export function* clearNewFeatureSaga({ navigate,setModal }) {
  try {
    yield put({ type: types.NEW_FEATURE_START });
    const response = yield call(clearFeatureApi);
    if (response.data.status === 401) {
      yield put({ type: types.NEW_FEATURE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.NEW_FEATURE_SUCCESS });
      setModal(false)
    }
  } catch (error) {
    yield put({ type: types.NEW_FEATURE_FAIL });
  }
}

export function* getNewFeatureSaga({ navigate }) {
  try {
    yield put({ type: types.GET_NEW_FEATURE_START });
    const response = yield call(getFeatureApi);
    if (response.data.status === 401) {
      yield put({ type: types.GET_NEW_FEATURE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_NEW_FEATURE_SUCCESS,data:response?.data?.data });
    }
  } catch (error) {
    yield put({ type: types.GET_NEW_FEATURE_FAIL });
  }
}

export function* getSiteMapUrlSaga({ navigate ,forType}) {
  try {
    yield put({ type: types.GET_SITEMAP_URL_START });
    const response = yield call(getSitemapUrlApi,forType);
    if (response.data.status === 401) {
      yield put({ type: types.GET_SITEMAP_URL_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_SITEMAP_URL_SUCCESS,data:response?.data?.data });
    }
  } catch (error) {
    yield put({ type: types.GET_SITEMAP_URL_FAIL });
  }
}


export function* getPricingPlanSaga({ navigate ,payload}) {
  try {
    yield put({ type: types.GET_PRICING_PLAN_START });
    const response = yield call(getPricingPlanApi,payload);
    if (response.data.status === 401) {
      yield put({ type: types.GET_PRICING_PLAN_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_PRICING_PLAN_SUCCESS,data:response?.data?.data });
    }
  } catch (error) {
    yield put({ type: types.GET_PRICING_PLAN_SUCCESS });
  }
}


export function* startPhonePayPaymentSaga({ navigate ,payload,cb}) {
  try {
    yield put({ type: types.START_PAYMENT_PHONE_PAY_START });

    const response = yield call(startPhonePayPaymentApi,payload);
    if (response.data.status === 401) {
      yield put({ type: types.START_PAYMENT_PHONE_PAY_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      response?.data?.message && olibrToast.success(response?.data?.message)
      cb && cb(response?.data)
      yield put({ type: types.START_PAYMENT_PHONE_PAY_SUCCESS,data:response?.data?.data });
    }
  } catch (error) {
    yield put({ type: types.START_PAYMENT_PHONE_PAY_FAIL });
  }
}



export function* startStripePaymentSaga({ navigate ,payload,cb}) {
  try {
    yield put({ type: types.START_PAYMENT_STRIPE_START });
    const response = yield call(startStripePaymentApi,payload);
    if (response.data.status === 401) {
      yield put({ type: types.START_PAYMENT_STRIPE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      cb && cb(response?.data)
      yield put({ type: types.START_PAYMENT_STRIPE_SUCCESS,data:response?.data?.data });
    }
  } catch (error) {
    yield put({ type: types.START_PAYMENT_STRIPE_FAIL });
  }
}

export function* paymentCheckoutSaga({ payload}) {
  try {
    yield put({ type: types.PAYMENT_CHECKOUT_START });
    const response = yield call(checkoutPlanAPI,payload);
    if (response.data.status === 401) {
      yield put({ type: types.PAYMENT_CHECKOUT_FAIL });
      olibrToast.error(response.data.message);
      payload?.navigate("/login");
    } else {
      payload?.cb && payload?.cb(response?.data)
    }
  } catch (error) {
    
    yield put({ type: types.PAYMENT_CHECKOUT_FAIL });
  }
}


export function* getCompanyJobsListSaga({ payload}) {
  try {
    yield put({ type: types.GET_COMPANY_JOBS_START });
    const response = yield call(getCompanyJobsAPI,payload);
    if (response.data.status === 401) {
      yield put({ type: types.GET_COMPANY_JOBS_FAIL });
      olibrToast.error(response.data.message);
      payload?.navigate("/login");
    } else {
      yield  put({type:types.GET_COMPANY_JOBS_SUCCESS,data:response?.data})
    }
  } catch (error) {
    
    yield put({ type: types.GET_COMPANY_JOBS_FAIL });
  }
}

export function* addDevSkillSaga({ payload}) {
  try {
    yield put({ type: types.ADD_DEV_SKILL_START });
    const response = yield call(addDevSkillAPI,payload);
    if (response.data.status === 401) {
      yield put({ type: types.ADD_DEV_SKILL_FAIL });
      olibrToast.error(response.data.message);
      payload?.navigate("/login");
    } else {
      payload?.cb && payload?.cb(response)
      yield  put({type:types.ADD_DEV_SKILL_SUCCESS,data:response?.data})
    }
  } catch (error) {
    
    yield put({ type: types.ADD_DEV_SKILL_FAIL });
  }
}

