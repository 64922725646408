import badphotocomposition from  '../../../../assets/img/Bad-Photo-Composition.webp';
import goodphotocomposition from '../../../../assets/img/good-photo-composition.webp';
import badphotosetting from '../../../../assets/img/bad-photo-setting.webp';
import goodphotosetting from '../../../../assets/img/good-photo-setting.webp';
import badphotocropping from '../../../../assets/img/bad-photo-cropping.webp';
import goodphotocropping from '../../../../assets/img/good-photo-cropping.webp';
import distractingbackground from '../../../../assets/img/distracting-background.webp';
import focusonface from '../../../../assets/img/focus-on-face.webp';
import idea from '../../../../assets/img/idea.png'
import "./photoGuideline.css";

export default function PhotoGuideline()
{
    return(
        <>
        <div className="container">
            <div className="row">
                <div className="col-12">
                <h3 className="photoheading">Tips To Upload A Perfect Profile Picture</h3>
                <p>Someone wise has rightly said: Every picture tells a story. And your profile picture is the story you want to share with the clients looking to hire you. Then why settle for a random, imperfect profile picture when you can upload a photo that would help you with your narrative?<br></br> <br></br>
            
                A great profile picture distinctively depicts more of who you are and what you can professionally bring onboard. So, to help you appear picture perfect and stand out before the potential clients, we are here sharing a few tips and guidelines which you can keep a note of while uploading your profile picture. We want to help you put your best foot forward!</p>
                </div>
                <div className="col-12">
                    <div className="feature-info">
                        <h1>Upload a Professional Photo</h1>
                        <p>Upload a photo in your best professional outfit. Your photo is the first impression you are putting out there for the companies looking to hire you. You don’t want to upload a picture you took while partying, traveling, or lazing around at home. So, ensure to take an appropriate photo in appropriate outfit. Also, put forward your best attitude.</p>
                    </div>
                    <div className="feature-info">
                        <h1>Choose a Perfect Lighting</h1>
                        <p>Dark or blurred photos can be a huge turn off for the clients. Ensure that you click your perfect shot under perfect lighting. Let the recruiters see your positive, glowing face.</p>
                    </div>
                    <div className="feature-info">
                        <h1>Choose a Professional Background</h1>
                        <p>A plain and simple background is a perfect background for a professional photo. You don’t want to post a profile picture with some dark shadows in the backdrop that could obscure your face.</p>
                    </div>
                    <div className="feature-info">
                        <h1>No full-length images</h1>
                        <p>Focus just on your face while taking your photo. You can zoom out the background if needed. Your features should stand out sharp when taking a professional photograph.
Maintain a pleasant facial expression {"\n"} You don’t want to come across as grumpy, serious, or rude, do you? Look pleasant whom the clients would love to have onboard. </p>
                    </div>
                    <div className="feature-info">
                        <h1>Maintain a pleasant facial expression</h1>
                        <p>You don’t want to come across as grumpy or rude, do you? Look pleasant whom the clients would want to have onboard.</p>
                    </div>
                </div>
                <div className="col-12">
                    <div className="pro-tips"><span><img src={idea} alt="idea"/></span>Pro Tips</div>
                    <p>If you feel you have followed these primary tips before uploading your profile picture, then you are good to go! Now let your profile picture and your remarkable resume do the talking!</p>
                </div>
            </div>
            <div className="row">
                    <div className="col-12"> <h3 className="photoheading pad-bottom-20">Dos vs. Don’ts Examples</h3></div>
            </div>
            <div className="row">
                <div className="col-4">
                    <div className="photo-block">
                      <img src={badphotocomposition} alt="image name"/>
                      <h4>Bad photo composition</h4>
                      <p>Poor light. Not looking at camera.</p>
                    </div>
                </div>
                <div className="col-4">
                    <div className="photo-block">
                      <img src={goodphotocomposition} alt="image name"/>
                      <h4>Good photo composition</h4>
                      <p>Good lighting. Facing the camera.</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <div className="photo-block">
                      <img src={badphotosetting} alt="image name"/>
                      <h4>Bad photo setting</h4>
                      <p>Too much body. Cluttered workspace.</p>
                    </div>
                </div>
                <div className="col-4">
                    <div className="photo-block">
                      <img src={goodphotosetting} alt="image name"/>
                      <h4>‍Good photo setting</h4>
                      <p>Framed into her face. Simple background.</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <div className="photo-block">
                      <img src={badphotocropping} alt="image name"/>
                      <h4>Bad photo cropping</h4>
                      <p>Full upper body. Face not clearly visible.</p>
                    </div>
                </div>
                <div className="col-4">
                    <div className="photo-block">
                      <img src={goodphotocropping} alt="image name"/>
                      <h4>‍Good photo cropping</h4>
                      <p>Cropped around face. Clean background.</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <div className="photo-block">
                      <img src={distractingbackground} alt="image name"/>
                      <h4>Distracting background</h4>
                      <p>Casual Attitude and Picture</p>
                    </div>
                </div>
                <div className="col-4">
                    <div className="photo-block">
                      <img src={focusonface} alt="image name"/>
                      <h4>‍‍Focus on face</h4>
                      <p>Professional attire. pleasant face.</p>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}