import React, { useState, Fragment, useEffect } from "react";
import { Icon } from "../../../components/icon";
import Modal from "../../../components/modal/ModalBox";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { deleteSitemapTypeAction, getSitemapTypeAction, postSitemapTypeAction, updateSitemapTypeAction } from "../../../service/action/admin";
// import fs from 'fs'
// var fs = require('fs');
const SitemapTypes = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {sitemapTypeList, sitemapTypeUpdate, sitemapTypeDelete, sitemapTypePost } = useSelector((state) => state.adminReducer);
  const [isTypeOpen, setTypeOpen] = useState(false);

  const writeXMLFunction = (filename) => {

  }

  useEffect(() => {
    if(sitemapTypePost?.statusCode === "SUCCESS"){
        setTypeOpen(false);
    }
  }, [sitemapTypePost])
  useEffect(() => {
    if(sitemapTypeDelete?.statusCode === "SUCCESS"){
        setTypeOpen(false);
    }
  }, [sitemapTypeDelete])
  useEffect(() => {
    if(sitemapTypeUpdate?.statusCode === "SUCCESS"){
        setTypeOpen(false);
    }
  }, [sitemapTypeUpdate])

  const closeTypeModal = () => {
    setTypeOpen(false);
    setPostObject({
        label: "",
        value: "",
        edit: false,
        _id: ""
      })
  };
  const handleSubmitType = () => {
    const obj = {label: postObj?.label, value: postObj?.value, siteMapData: postObj?.siteMapData};
    console.log(obj);
    postObj?.edit ? dispatch(updateSitemapTypeAction(obj, postObj?._id, navigate)) : dispatch(postSitemapTypeAction(obj, navigate)); 
  };

  useEffect(() => {
    dispatch(getSitemapTypeAction(navigate));
  }, [navigate, dispatch])

  const [postObj, setPostObject] = useState({
    label: "",
    value: "",
    siteMapData: "",
    edit: false,
    _id: ""
  });

  const handleChange = (e) => {
    setPostObject({...postObj, [e.target.name] : e.target.value});
  } 

  const handleTypeContent = () => {
    return (
      <Fragment>
        <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label
              className={`floating-label`}
            >
              Map-Type Name
            </label>
            <input
              name="label"
              type="text"
              className="form-control"
              placeholder=""
              value={postObj?.label}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label
              className={`floating-label`}
            >
              Map-Type Slug
            </label>
            <input
              name="value"
              type="text"
              className="form-control"
              placeholder=""
              value={postObj?.value}
              onChange={handleChange}
            //   disabled={postObj?.edit ? true : false}
            />
          </div>
        </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label
              className={`floating-label`}
            >
              Sitemap Data 
            </label>
            <textarea
              name="siteMapData"
              type="text"
              className="form-control"
              rows={10}
              value={postObj?.siteMapData}
              onChange={handleChange}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  const EditType = (item) => {
    setPostObject({...postObj, label: item?.label, value: item?.value, edit: true, _id: item?._id, siteMapData: item?.siteMapData});
    setTypeOpen(true);
  }

  const handleDelete = () => {
    dispatch(deleteSitemapTypeAction(postObj?._id, navigate));
  }

  return (
    <div className="main-div">
      <Modal
        open={isTypeOpen}
        title={`${postObj?.edit ? "EDIT" : "ADD"} SITEMAP TYPE`}
        onClose={closeTypeModal}
        onCancel={closeTypeModal}
        onDone={handleSubmitType}
        isDeletable={postObj?.edit ? true : false}
        onDelete={handleDelete}
        content={handleTypeContent}
      />
      <div className="main-div-developer">
        <div className="create-profile">
          <div className="block-header">
            <h4 className="">SITEMAP TYPES</h4>
            <span onClick={() => setTypeOpen(true)}>
              <Icon name="add-icon" />
            </span>
          </div>

          <table className="table">
            <thead>
              <tr>
                <th className="column0">S.No</th>
                <th className="column1">Sitemap Name</th>
                <th className="column1">Sitemap Slug</th>
                <th className="column1">last Updated On</th>
                <th className="column1">Created On</th>
              </tr>
            </thead>
            <tbody>
              {sitemapTypeList?.data?.map((item, index) => (
                <tr>
                <td className="column1" onClick={() => EditType(item)}>
                  {index+1}
                </td>
                <td className="column1" onClick={() => EditType(item)}>
                  {item?.label}
                </td>
                <td className="column1" onClick={() => EditType(item)}>
                  {item?.value}
                </td>
                <td className="column1" onClick={() => EditType(item)}>
                  {new Date(item?.updatedAt).toDateString()}
                </td>
                <td className="column1" onClick={() => EditType(item)}>
                  {new Date(item?.createdAt).toDateString()}
                </td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SitemapTypes;
