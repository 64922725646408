import React, { useEffect, useState } from "react";
import BudgetCheck from "./BudgetCheck";
import { preventMinus } from "../../common/preventMinus";
import { formatBudget } from "../functions";

const BudgetInput = ({
  budgetMin,
  errors,
  hideBudgetToDev,
  setHideBudgetToDev,
  country,
  register,
  budgetMax,
  setValue,
}) => {
  const [isINR, setIsInr] = useState(true);
  const handleInputChange = (event) => {
    setValue(event.target.name, event.target.value.replace(/[^0-9.]/g, ""));
  };
  const handleInputBlur = (event) => {
    if (country?.[0]?.countryCode == "IN") {
      setValue(event.target.name, formatBudget(event.target.value));
    }
  };

  useEffect(() => {
    if (country?.length > 1 || country?.[0]?.countryCode != "IN") {
      setIsInr(false);
    }else{
      setIsInr(true);
    }
  }, [country]);

  useState(() => {
    if (isINR) {
      setValue("budgetMax", formatBudget(budgetMax));
      setValue("budgetMin", formatBudget(budgetMin));
    }
  }, [isINR]);
  return (
    <div className="col-4">
      <div className="row">
        <div className="col-12">
          <div className="form-group fromto">
            <div className="row">
              <div className="col-3">
                <label
                  className={
                    errors.budgetMin || errors.budgetMax ? "text-danger" : ""
                  }
                >
                  Budget<small>(Annual)</small>*
                </label>
              </div>
              <BudgetCheck
                hideBudgetToDev={hideBudgetToDev}
                setHideBudgetToDev={setHideBudgetToDev}
              />
            </div>

            <div className="row">
              <div className="col-5">
                <span
                  className="currency-sign"
                  dangerouslySetInnerHTML={{
                    __html: isINR ? "&#8377;" : "$",
                  }}
                ></span>
                <input
                  type="number"
                  maxLength={isINR ? 5 : 10}
                  name="budgetMin"
                  step="0.01"
                  onInput={handleInputChange}
                  placeholder={isINR ? "Lacs" : ""}
                  onKeyDown={preventMinus}
                  className="form-control budgetMin"
                  {...register("budgetMin", {
                    required: !!budgetMax,
                    valueAsNumber: true,
                    onBlur: (e) => handleInputBlur(e),
                    validate: (value) =>
                      parseFloat(value) < parseFloat(budgetMax),
                  })}
                />
              </div>
              <div className="col-2">To</div>
              <div className="col-5">
                <span
                  className="currency-sign"
                  dangerouslySetInnerHTML={{
                    __html: isINR ? "&#8377;" : "$",
                  }}
                ></span>
                <input
                  type="number"
                  maxLength={isINR ? 5 : 10}
                  onKeyDown={preventMinus}
                  onInput={handleInputChange}
                  name="budgetMax"
                  step="0.01"
                  placeholder={isINR ? "Lacs" : ""}
                  className="form-control budgetMin"
                  {...register("budgetMax", {
                    required: !!budgetMin,
                    onBlur: (e) => handleInputBlur(e),
                    valueAsNumber: true,
                    validate: (value) =>
                      parseFloat(value) > parseFloat(budgetMin),
                  })}
                />
                {parseInt(budgetMin) >= parseInt(budgetMax) && (
                  <label className="text-danger">
                    Budget should be greater than min value!
                  </label>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BudgetInput;
