import { StyleSheet } from "@react-pdf/renderer";
import { Font } from '@react-pdf/renderer';
import popinMidium from "../../../../assets/fonts/Poppins-Medium.ttf"
import popinLight from "../../../../assets/fonts/Poppins-Light.ttf"
import popinRegular from "../../../../assets/fonts/Poppins-Regular.ttf"

Font.register({
    family: 'Poppins-Medium',
    fonts: [
      { src: popinMidium, fontWeight: 'normal' },
      
    ],
  });

  Font.register({
    family: 'Poppins-Light',
    fonts: [
      { src: popinLight, fontWeight: 'normal' },
    ],
  });
  Font.register({
    family: 'Poppins-Regular',
    fonts: [
      { src: popinRegular, fontWeight: 'normal' },
    ],
  });
const ProfileView = StyleSheet.create({
    padd: {
        padding: "0px 25px 0px 15px"
    },
    page: {
        backgroundColor: 'white'
    },
    profileTop: {
        padding: "10px 10px 0",
        width: "100%"
    },
    bgImageProfile: {
        borderRadius: "6px",
    },
    profileTopContainer: {
        flexDirection: 'row',
        height: "140px",
        position: "absolute",
        left: '30px',
        right: '30px',
        top: 40
    },
    profileTopLeft: {
        borderWidth: "7px",
        borderColor: "#fff",
        width: '25%',
        height: "100%",
    },
    profileTopRight: {
        width: '75%',
        height: "100%",
        padding: "3px 20px 0",
        backgroundColor:"#fff"
    },
    userProfileImage: {
        height: "180px",
    },
    logoBrandProfile: {
        height: "27px",
        width: "27px",
        position: "absolute",
        bottom: "0px"
    },
    devNameTitle:{
        color: "#000",
        fontFamily: "Poppins-Medium",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: 1.5,
        textTransform: "capitalize",
    },
    devDesignation:{
        color: "#242c33",
        fontFamily: "Poppins-Light",
        fontSize: "11px",
        fontWeight: 500,
        textTransform: "capitalize",
    },
    basicDetails:{
        flexDirection: 'row',
        height: "20px",
    },
    basicDetailsFirst:{
        flexDirection:"row",
        width:"24%"
    },
    basicDetailsSecond:{
        flexDirection:"row",
        width:"42%"
    },
    basicDetailsThird:{
        flexDirection:"row",
        width:"34%"
    },
    locationIcon:{
        width:"7px",
        padding:"1px",
        marginTop:"1px"

    },
    locationText:{
        color: "#000",
        fontFamily:"Poppins-Regular",
        fontSize:"8px",
        marginLeft:"3px"
    },
    emailIcon:{
        height:"6px",
        marginTop:"3px"
    },
    callIcon:{
        height:"7px",
        marginTop:"2px"
    },
    noticePeriod:{
        fontSize:"9px",
        fontFamily: "Poppins-Medium",
        fontWeight: 400,
    },
    shortDesc:{
        fontSize:"9px",
        fontFamily: "Poppins-Light",
        fontWeight: 400,
        paddingTop:"10px",
        height:"70px",
        overflow:"hidden",
    },
    bannerBetween: {
        marginTop: "60px",
        padding: "10px",
        height: "40px"
      },

});

export default ProfileView