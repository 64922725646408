import React from "react";

const BudgetCheck = ({
  hideBudgetToDev,
  setHideBudgetToDev,
  className = "",
}) => {
  return (
    <div
      className={`col-9 text-right hide-budget ${className}`}
      // style={{ position: "relative", top: "60px", fontSize: '12px' }}
    >
      <span className="span-1">
        <input
          type="checkbox"
          name="switch3"
          onChange={(e) => e.preventDefault()}
          checked={hideBudgetToDev}
        />
        <label
          onClick={(e) => {
            e.stopPropagation();
            setHideBudgetToDev(!hideBudgetToDev);
          }}
          className="label"
          htmlFor="switch3"
        ></label>
      </span>{" "}
      <span className="span-2">Hide From Candidates</span>
    </div>
  );
};

export default BudgetCheck;
