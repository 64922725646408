import { StyleSheet } from "@react-pdf/renderer";
import { Font } from '@react-pdf/renderer';
import popinMidium from "../../../../assets/fonts/Poppins-Medium.ttf"
import popinLight from "../../../../assets/fonts/Poppins-Light.ttf"
import popinRegular from "../../../../assets/fonts/Poppins-Regular.ttf"


Font.register({
  family: 'Poppins-Medium',
  fonts: [
    { src: popinMidium, fontWeight: 'normal' },

  ],
});

Font.register({
  family: 'Poppins-Light',
  fonts: [
    { src: popinLight, fontWeight: 'normal' },
  ],
});
Font.register({
  family: 'Poppins-Regular',
  fonts: [
    { src: popinRegular, fontWeight: 'normal' },
  ],
});
export const Project = StyleSheet.create({
  heading: {
    fontSize: "12px",
    fontFamily: "Poppins-Regular",
    marginLeft:"10px"
  },
  mainBox:{
    height:"100px",
    width:"445px",
  },
  bgBanner:{
    width:"425px",
    margin:"10px",
    position:"absolute",
    marginTop:"18px"
  },
  left:{
    height:"200px",
    width:"250px",
  },
  right:{
    height:"200px",
    width:"150px",
  },
  projectTitle:{
    fontSize:"14px",
    margin:"70px 18px"
  },
  projectImg:{
    height:"88px",
    width:"100px",
    margin:"20px 40px"
  },
  description:{
    backgroundColor:"#f6fbfe",
    width:"425px",
    marginTop:"30px",
    marginLeft:"10px",
    
  },
  role:{
    fontSize:"10px",
    marginLeft:"10px"
  },
  roleTitle:{
    color:"#393e43",
    fontFamily: "Poppins-Regular"
  }
})