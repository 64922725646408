import React, { Fragment, useContext, useEffect, useState } from "react";
import { Icon } from "../../../components/icon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    addMaintenanceStatusAction,
  deleteMaintenanceMessageAction,
  getMaintenanceAction,
  updateMaintenanceMessageAction,
  updateMaintenanceStatusAction,
} from "../../../service/action/admin";
import Modal from "../../../components/modal/ModalBox";
import { olibrToast } from "../../../components/toaster";
import { MainContext } from "../../../context/MainContext";
import { checkPermission, readOnlyCheck } from "../../../components/functions";
const Maintenance = () => {
  const {user:{access}}=useContext(MainContext)
  const readOnly=readOnlyCheck(access,"Maintenance Message")

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { getMaintenanceMessage, updateMaintenanceMessage } = useSelector((state) => state.adminReducer);

  useEffect(() => {
    dispatch(getMaintenanceAction(navigate));
  }, [dispatch, navigate]);

  const [bool, setBool] = useState(false);
  useEffect(() => {
    let boole=false;
    for(let i=0;i<getMaintenanceMessage?.data?.length;i++){
        if(getMaintenanceMessage?.data?.[i]?.switchOn){
            console.log(getMaintenanceMessage?.data?.[i]?.switchOn, i);
            boole=true;
            break;
        }
    }
    setBool(boole);
  }, [getMaintenanceMessage]);

  const onChangeStatus = (messageId, switchOn) => {
    if(switchOn && bool){
        olibrToast.error("Only One Message can be switched on at once")
    }
    else{
        dispatch(
          updateMaintenanceStatusAction({
            messageId,
            switchOn,
          })
        );
    }
  };

  const [modalOpen, setModalOpen] = useState({ isOpen: false, edit: false });

  const closeModal = () => {
    setModalOpen({ isOpen: false, edit: false });
    setMessage("");
    setMessageId("");
};

const openModal = (item) => {
      setModalOpen({ isOpen: true, edit: true });
        setMessage(item?.message)
        setMessageId(item?._id)
  }

  const deleteMessage = () => {
    dispatch(deleteMaintenanceMessageAction(messageId, navigate, closeModal()));
  }

  const submitMessage = () => {
    const obj = {
        message
    }
    modalOpen.edit ? dispatch(updateMaintenanceMessageAction({...obj, messageId}, navigate, closeModal())) : dispatch(addMaintenanceStatusAction(obj, navigate, closeModal()));
  };

  const [message, setMessage] = useState("")
  const [messageId, setMessageId] = useState("")
  const modalContent = () => {
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
          <div className="form-group">
            <label
              className={`floating-label`}
            >
              Message*
            </label>
            <textarea
            rows={10}
              name="message"
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="form-control"
            />
          </div>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Modal
        open={modalOpen.isOpen}
        title={`${modalOpen.edit ? "EDIT" : "ADD"} MESSAGE`}
        onClose={closeModal}
        onCancel={closeModal}
        onDone={submitMessage}
        content={modalContent}
        isDeletable={modalOpen.edit}
        onDelete={deleteMessage}
      />
      <div className="main-div-developer">
        <div className="main-div">
          <div className="create-profile">
            <div className="block-header">
              <h4>Maintenance Message</h4>
              <span onClick={() => {
                if (checkPermission(readOnly)) return false
                setModalOpen({ isOpen: true, edit: false })}} >
                <Icon name="add-icon" />
              </span>
            </div>
            <br />
            <div className="table-div">
              <div className="table-small">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="column0">S.No</th>
                      <th className="column1">Message</th>
                      <th className="column1">Switch</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getMaintenanceMessage?.data?.map((item, i) => (
                      <Fragment key={item?._id + Math.random()}>
                        <tr className="faqdiv">
                          <td className="subQues sno">{i + 1}</td>
                          <td className="subQues" onClick={()=>{
                            if (checkPermission(readOnly)) return false
                            openModal(item)}} >{item?.message}</td>
                          <td
                            className="column4"
                            onClick={(e) => {
                              if (checkPermission(readOnly)) return false
                              e.stopPropagation();
                              onChangeStatus(item?._id, !item?.switchOn);
                            }}
                          >
                            <input
                              type="checkbox"
                              name="switch"
                              onChange={(e) => e.preventDefault()}
                              checked={item?.switchOn}
                            />
                            <label className="label" htmlFor="switch3"></label>
                          </td>
                        </tr>
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Maintenance;
