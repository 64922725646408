import { PDFDownloadLink } from "@react-pdf/renderer";
import DateFormate from "../../../../common/DateFormate";
import { Icon } from "../../../../components/icon";
import DownloadInvoice from "../download-pdf/DownloadInvoice";

const PaymentHistoryColumn = (user) => {
  const columns = [
    {
      name: "ID",
      selector: "id",
      width: "200px",
    },
    {
      name: "Product",
      selector: "planName",
      sortable: true,
    },
    {
      name: "Amount",
      selector: "amount",
      sortable: true,
      cell: (row) => row?.currency + " " + row?.amount,
    },
    {
      name: "Status",
      selector: "txnAmount",
      sortable: true,
      cell: (row) => (
        <span
          className={
            row?.status == "COMPLETED"
              ? "text-success"
              : "text-danger"
          }
        >
          {row?.status ||"Pending"}
        </span>
      ),
    },
    {
      name: "Date",
      selector: "txnDate",
      sortable: true,
      cell: (row) => DateFormate(row?.invoiceDate),
    },
    {
      name: "Method",
      selector: "method",
      sortable: true,
    },
    {
      name: "Invoice",
      sortable: true,
      cell: (row) =><td style={{cursor:"pointer"}}
      > <PDFDownloadLink
      fileName={`${Math.floor(Math.random() * 9000000) + 1000000}_invoice.pdf`}
      document={<DownloadInvoice data={row} user={user}/>}
    >
      <Icon name="pdf-icon" />
    </PDFDownloadLink></td>
    },
  ];
  return columns;
};

export default PaymentHistoryColumn;
