import { StyleSheet } from "@react-pdf/renderer";
import { Font } from '@react-pdf/renderer';
import popinMidium from "../../../../assets/fonts/Poppins-Medium.ttf"
import popinLight from "../../../../assets/fonts/Poppins-Light.ttf"
import popinRegular from "../../../../assets/fonts/Poppins-Regular.ttf"


Font.register({
  family: 'Poppins-Medium',
  fonts: [
    { src: popinMidium, fontWeight: 'normal' },

  ],
});

Font.register({
  family: 'Poppins-Light',
  fonts: [
    { src: popinLight, fontWeight: 'normal' },
  ],
});
Font.register({
  family: 'Poppins-Regular',
  fonts: [
    { src: popinRegular, fontWeight: 'normal' },
  ],
});
export const Tool = StyleSheet.create({
    rightLayout: {
    fontSize: "12px",
    width:"445px",
    fontFamily: "Poppins-Regular",
    marginLeft:"10px",
    flexDirection:"row",
    flexWrap: "wrap"
  },
  toolName:{
  paddingLeft:"10px"
  },
  toolTitle:{
    border:"1px solid",
    padding:"2px 10px",
    margin:"1px 5px",
    fontSize:"8px",
    backgroundColor:"#f6fbfe",
    borderRadius:"8px"
  }
 
})