import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import SelectOption from "../../../../components/select/SelectOption";
import { useDispatch, useSelector } from "react-redux";
import { isCoreArray } from "../../../developer/review/constants";
import OlibrEditor from "../../../../components/editor";
import Select from "react-select";
import {
  checkPermission,
  readOnlyCheck,
} from "../../../../components/functions";
import { MainContext } from "../../../../context/MainContext";
import { ScaleLoader } from "react-spinners";
import {
  getAllIcons,
  getPendingSkillAction,
  updatePendingSkillAction,
  uploadIcons,
} from "../../../../service/action/admin";
import { useNavigate } from "react-router-dom";
import { olibrToast } from "../../../../components/toaster";

const EditSkills = ({ updatePendingSkill, skillDetail, setSkillDetail }) => {
  const [assessmentLists, setAssessmentList] = useState([]);
  const {
    assessmentList,
    topicList,
    skillIcons,
    isIconUploading,
    testSkills,
    uploadIcon,
  } = useSelector((state) => state.adminReducer);
  const uploadIconRef = useRef();
//   const [editorText, setEditorText] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState([]);
  const {
    register: skillRegister,
    handleSubmit: skillSubmit,
    setValue: setSkillValue,
    // getValues: getSkillValue,
    // control: skillControll,
    // reset: resetSkillForm,
    formState: { errors: skillError },
  } = useForm();

  const dispatch = useDispatch();
  const {
    user: { access },
  } = useContext(MainContext);
  const [skillObj, setSkillObj] = useState({
    skillTestIcon:
      "https://e7.pngegg.com/pngimages/980/714/png-clipart-computer-icons-code-symbol-coding-miscellaneous-angle.png",
  });

  useEffect(() => {
    if (skillDetail) {
      setSkillValue("skillName", skillDetail?.skillName);
      setSkillValue("skillId", skillDetail?._id);
    }
  }, [skillDetail]);

  useEffect(() => {
    // dispatch(renderAllHackerrankAssessments(navigate));
    // dispatch(getSkillTest(navigate));
    // dispatch(renderTopicList(navigate));
    dispatch(getAllIcons(navigate));
    // dispatch(getSkillCategory(navigate));
    // dispatch(getSkillWithCategory(navigate, "all"));
  }, [navigate, dispatch]);
  useEffect(() => {
    if (assessmentList && testSkills) {
      let tempAssess = [];
      let testSkill = [];
      assessmentList?.map((item) => {
        tempAssess?.push({ value: item?.id, label: item?.name });
      });

      testSkills?.map((item) => {
        testSkill?.push({ value: item?.id, label: item?.name });
      });
      let arr = [...tempAssess, ...testSkill];
      arr.sort((a, b) => {
        let fa = a?.label?.toLowerCase(),
          fb = b?.label?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      setAssessmentList(arr);
    }
  }, [assessmentList, testSkills]);

  const handleUpdate = (data) => {
    let tempData = {
      ...data,
      skillTestIcon: skillObj?.skillTestIcon,
      isApproved: true,
    };
    const callBack = () => {
        olibrToast.success("Approved Successfully");
      setSkillDetail({});
      dispatch(getPendingSkillAction(navigate));
    };
    dispatch(
      updatePendingSkillAction(navigate, { data: tempData, cb: callBack })
    );
  };
  useEffect(() => {
    uploadIcon &&
      Object.keys(uploadIcon)?.length > 0 &&
      setSkillObj({
        skillTestIcon: uploadIcon?.icons?.[uploadIcon?.icons?.length - 1],
      });
  }, [uploadIcon]);

  const onUploadIcon = (e) => {
    let formData = new FormData();
    formData.append("imageFile", e?.target?.files[0]);
    if (e?.target?.files[0]?.size > 1024 * 1024) {
      olibrToast.error("Please use image of size less than 1MB");
    } else {
      dispatch(uploadIcons(formData, navigate));
    }
    e.target.value = null;
  };
  return (
    <form>
      <input
        style={{ display: "none" }}
        type="submit"
        ref={updatePendingSkill}
        onClick={skillSubmit(handleUpdate)}
      />
      <div className="form-group">
        <label
          htmlFor=""
          className={`${skillError?.skillName && "text-danger"} new-label`}
        >
          Skill Name*
        </label>
        <input
          name="skillName"
          {...skillRegister("skillName", { required: true })}
          type="text"
          className="form-control"
        />
      </div>
      {/* <div className="form-group select-group">
        <label className="new-label">
          Select skills test associated with
        </label>
        <Controller
          control={skillControll}
          name="skills"
          {...skillRegister("skills")}
          render={({ field }) => (
            <SelectOption
              optionArray={assessmentLists}
              fieldProp={field}
              getValue={getSkillValue}
              getValueName={"skills"}
              closeMenuOnSelect={true}
              searchable={true}
            />
          )}
        />
      </div>
      <div className="form-group select-group">
        <label className="new-label">Select topic</label>
        <Controller
          control={skillControll}
          name="topic"
          {...skillRegister("topic")}
          render={({ field }) => (
            <SelectOption
              optionArray={topicList?.default?.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              fieldProp={field}
              getValue={getSkillValue}
              getValueName={"topic"}
              closeMenuOnSelect={true}
              searchable={true}
            />
          )}
        />
      </div>
      <div className="form-group select-group">
        <label className="new-label">Category</label>
        <Controller
          control={skillControll}
          name="category"
          {...skillRegister("category")}
          render={({ field }) => (
            <SelectOption
              optionArray={skillCategory?.map((item) => ({
                value: item?._id,
                label: item?.name,
              }))}
              fieldProp={field}
              getValue={getSkillValue}
              getValueName={"category"}
              closeMenuOnSelect={true}
              searchable={true}
            />
          )}
        />
      </div>
      <div className="form-group select-group">
        <label className={`new-label ${skillError?.isCore && "text-danger"}`}>
          Select Type
        </label>
        <Controller
          control={skillControll}
          name="isCore"
          {...skillRegister("isCore")}
          render={({ field }) => (
            <SelectOption
              optionArray={isCoreArray}
              fieldProp={field}
              getValue={getSkillValue}
              getValueName={"isCore"}
              closeMenuOnSelect={true}
              searchable={true}
            />
          )}
        />
      </div>
      <div className="row">
        <div className="col-4">
          <div className="form-group">
            <label className="new-label">Skill Test Time</label>
            <input
              name="skillTestTime"
              {...skillRegister("skillTestTime")}
              type="number"
              min={0}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-4">
          <div className="form-group">
            <label
              htmlFor=""
              className={`${skillError?.maxScore && "text-danger"} new-label`}
            >
              Max score
            </label>
            <input
              {...skillRegister("maxScore")}
              name="maxScore"
              type="number"
              min={0}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-4">
          <div className="form-group">
            <label
              htmlFor=""
              className={`${
                skillError?.cutOffScore && "text-danger"
              } new-label`}
            >
              Cutoff score
            </label>
            <input
              {...skillRegister("cutOffScore")}
              name="cutOffScore"
              type="number"
              min={0}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label
              htmlFor=""
              className={`${
                skillError?.skillGroupName && "text-danger"
              } new-label`}
            >Group Name</label>
            <input
              {...skillRegister("skillGroupName")}
              name="skillGroupName"
              type="text"
              min={0}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label
              htmlFor=""
              className={`${
                skillError?.skillLevel && "text-danger"
              } new-label`}
            >Skill Level</label>
            <input
              {...skillRegister("skillLevel")}
              name="skillLevel"
              type="number"
              min={0}
              className="form-control"
            />
          </div>
        </div>
      </div> */}

      {/* <div className="form-group">
        <label
          htmlFor=""
          className={`floating-label ${
            error?.includes("description") && "text-danger"
          }`}
        >
          Description *
        </label>
        <OlibrEditor
          value={editorText ? editorText : ""}
          onChange={(e) => setEditorText(e)}
        />
      </div> */}
        <input
          ref={uploadIconRef}
          type="file"
          onChange={onUploadIcon}
          className="input-file"
          accept=".png, .jpeg, .jpg, .svg"
        />
      <div className="row-flex">
        <div className="form-group">
          <label className="new-label">Choose icon</label>
          <Select
            isLoadingSkillList={false}
            isSearchable={false}
            maxMenuHeight={200}
            value={{
              value: skillObj?.skillTestIcon,
              label: (
                <div style={{ width: "200px", display: "flex" }}>
                  {" "}
                  <img
                    src={skillObj?.skillTestIcon}
                    height="30px"
                    width="30px"
                    alt=""
                  />{" "}
                </div>
              ),
            }}
            menuPlacement={"top"}
            options={skillIcons?.map((icon) => ({
              value: icon,
              label: (
                <div style={{ width: "200px", display: "flex" }}>
                  {" "}
                  <img src={icon} height="30px" width="30px" alt="" />{" "}
                </div>
              ),
            }))}
            onChange={(e) => setSkillObj({ skillTestIcon: e?.value })}
          />
        </div>
        <a
          href="#-"
          className="file-icon"
          onClick={() => {
            // if (checkPermission(readOnlyCheck(access, "Skills Management")))
            //   return false;
            uploadIconRef.current.click();
          }}
        >
          {isIconUploading ? (
            <ScaleLoader color="#3BA0AA" height={10} />
          ) : (
            "Upload icon"
          )}
        </a>
      </div>
    </form>
  );
};

export default EditSkills;
