import React from "react";
import { Icon } from "../../../components/icon";

const SubscriptionBanner = ({message,setSubscriptionModal}) => {

  return message &&(
    <>
      <div className="subscription-banner">
        <Icon name="clock-icon" />
        <small>{message}</small>
        <button type="" class="btn btn-orange"
        onClick={()=>setSubscriptionModal(true)}
        >
          Upgrade
        </button>
     
      </div>

     
    </>
  );
};

export default SubscriptionBanner;
