import React, { Fragment, useEffect, useState } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import OlibrCarousel from "../../components/carousel";
import { olibrToast } from "../../components/toaster";
import { MainContext } from "../../context/MainContext";
import { getJobLists } from "../../service/action/common";
import { applyNewJob, getProfileStatus } from "../../service/action/developer";
import ArrowRight from "./../../assets/img/icons/arrow-right.svg";
import CalendarIcon from "./../../assets/img/icons/calendar-outline.svg";
import "./../developer/dashboard/commonDashboard/commonDashboard.css";
import { Icon } from "../../components/icon";
import JobLists from "../../components/joblists/JobLists";

const JobSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const {
  //   user: { userType },
  // } = useContext(MainContext);
  
  const {user} = useContext(MainContext)
  const { jobListResp } = useSelector((state) => state.commonReducer);


  useEffect(() => {
    dispatch(getJobLists(navigate,{page:1,limit:5}));
  }, [dispatch, navigate]);

  useEffect(()=>{
    if (user?.userType === "Developer") {
      dispatch(getProfileStatus(navigate));
    }
  },[user]);


  return (
    <section id="dashboard-adj" className="jobs">
      <div className="container">
        <h2 className="jobs__title">
        Explore The Latest Software Engineer Jobs{" "}
          <span className="link-right">
            <Link to="/jobs" className="link">
              Explore More Jobs <img src={ArrowRight} alt="" />
            </Link>
          </span>
        </h2>
        <h4 className="jobs__sub-title"></h4>
        <JobLists
        mainJobList={{data:jobListResp}}
        hideCheckBox={true}
        />
        {/* <OlibrCarousel> */}
          {/* {approvedJobList?.map((job, index) => index<5 && (
            <Fragment>
              <div className="row" onClick={() => onHandleClick(job?._id)}>
              <div className="col-12">
                <div className="jl-logo">
                  <span>
                    <img
                      src={job?.jobLogo}
                      width={50}
                      style={{ padding: "5px" }}
                      alt=""
                    />
                  </span>
                </div>
                <div className="job-listing-section">
                  <h4>{job?.jobTitle}</h4>
                  <div className="row job-det">
                    <div className="col-3">
                      <p>
                        <span>
                          <Icon name="suitcase-new" />
                        </span>
                        {job?.organisation}
                      </p>
                    </div>
                    <div className="col-2">
                      <p>
                        <span>&#9632;</span> {job?.minExpRange}-{job?.maxExpRange} Yrs
                      </p>
                    </div>
                    <div className="col-2">
                      <p>
                        <span>&#9632;</span>{" "}
                        {job?.jobLocation ? job?.jobLocation : "Remote"}
                      </p>
                    </div>
                    <div className="col-2">
                      <p>
                        <span>&#9632;</span>{" "}
                        {new Date()
                          .toDateString(job?.postDate)
                          .toUpperCase()
                          .substring(4)}{" "}
                      </p>{" "}
                    </div>
                    <div className="col-3 jl-btn">
                      <span className="link" onClick={() => handleJobApply(job)}>APPLY NOW</span>
                      <a href="#">&#9733;</a>
                    </div>
                  </div>

                  <div className="col-12">
                    <ul className="skill">
                      {job?.tags?.map((skillTag, i) => (
                        <li key={i + Math.random()}>{skillTag}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            </Fragment>
          ))} */}
        {/* </OlibrCarousel> */}
      </div>
    </section>
  );
};

export default JobSection;
