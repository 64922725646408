import React, { useState, useEffect, Fragment, useContext } from "react";
import { Icon } from "../../../components/icon";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  checkRestrictionClient,
  getAllActiveUserAction,
  getAllClientJobLists,
  jobRefreshAction,
  updateClientJobStatus,
} from "../../../service/action/client";
import { ClipLoader } from "react-spinners";
import "./ViewAllJob.css";
import "../ClientMain.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { getJobCatAction } from "../../../service/action/admin";
import CloseIcon from "./../../../assets/img/company-profile/close.svg";
import SelectCheck from "./../../../assets/img/company-profile/select-check.svg";
import { olibrToast } from "../../../components/toaster";
import Modal from "../../../components/modal/ModalBox";
import Tooltips from "../../../components/tooltip/tooltips/Tooltips";
import { MainContext } from "../../../context/MainContext";
import SelectOption from "../../../components/select/SelectOption";
import { checkMultipleJob, getJobLinkUrl } from "../../../components/functions";
import OlibrShareLink from "../../../components/share-link";
import JobListSkill from "../../../components/joblists/JobListSkill";

const ViewAllJobs = () => {
  const {
    isJoblistLoading,
    isJobRefreshing,
    clientAllJoblists,
    allActiveUser,
  } = useSelector((state) => state.clientReducer);
  const {
    user: { companyProfilePublish, clientCurrentRole, email },
  } = useContext(MainContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const [userId, setUserId] = useState(clientId);
  const [drop, setDrop] = useState("");
  const [isScrolling, setIsScrolling] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [pageData, setPageData] = useState({
    page: 1,
    limit: 10,
    search: "",
  });

  useEffect(() => {
    dispatch(getJobCatAction(navigate));
  }, []);

  useEffect(() => {
    clientId && setUserId(clientId);
  }, [clientId]);

  const onHandleUserSelection = (user) => {
    setSelectedUser(user);
    setUserId(user?.value);
    isScrolling && setIsScrolling(false);
  };

  useEffect(() => {
    email &&
      dispatch(getAllActiveUserAction(navigate, email?.split("@")?.pop()));
  }, [email]);

  useEffect(() => {
    if(clientCurrentRole==="admin" && !selectedUser && !clientId){
    dispatch(getAllClientJobLists(navigate, { ...pageData, clientId: "all" }));
    }else if(clientCurrentRole || clientId){
      dispatch(getAllClientJobLists(navigate, { ...pageData, clientId: userId}));
    }    
  }, [pageData.page, pageData.limit, userId,clientCurrentRole]);

  const [closeConfirm, setCloseConfirm] = useState();

  const handleJobStatus = (status, currentStatus, id) => {
    status !== currentStatus &&
      dispatch(
        updateClientJobStatus(
          navigate,
          status === "closed" ? setCloseConfirm : null,
          {
            status,
            id,
          }
        )
      );
    dispatch(getAllClientJobLists(navigate, pageData));
  };

  const handleJobClose = () => {
    handleJobStatus(
      closeConfirm?.status,
      closeConfirm?.currentStatus,
      closeConfirm?.id
    );
  };

  const onJobRefresh = (jobId) => {
    dispatch(jobRefreshAction(navigate, jobId, pageData));
  };

  const fetchMoreData = () => {
    !isScrolling && setIsScrolling(true);
    setPageData({
      ...pageData,
      limit: pageData?.limit + 10,
    });
  };

  const handleAddJob = (jobId = false) => {
    if (!companyProfilePublish && clientCurrentRole !== "user") {
      olibrToast.error("Company Profile is Required");
      return false;
    } else {
      let routes = jobId ? `/client/edit-job/${jobId}` : "/client/add-job";
      let payload = {
        routes,
        navigate,
        page: "add-job",
      };
      dispatch(checkRestrictionClient(payload));
    }
  };

  const editJob = (e, item) => {
    e.stopPropagation();
    item.isApproved
      ? olibrToast.error("You can't update an approved job.")
      : handleAddJob(item?._id);
  };

  const breakIntoFortyChar = (a) => {
    let b = [];
    for (var i = 40; i < a.length; i += 40) {
      b.push(a.slice(i - 40, i));
    }
    b.push(a.slice(a.length - (40 - (a.length % 40))));
    return b.map((item) => <div>{item}</div>);
  };

  const [shareOpen, setShareOpen] = useState(false);
  const [shareLink, setShareLink] = useState(false);

  const openShareJob = (jobSlug) => {
    setShareOpen(true);
    let str = getJobLinkUrl(jobSlug);
    setShareLink(str);
  };

  return (
    <Fragment>
      <Modal
        open={closeConfirm?.isOpen}
        title={"Jobs once closed cannot be reopened."}
        onClose={() => setCloseConfirm({ isOpen: false })}
        onDone={handleJobClose}
        onCancel={() => setCloseConfirm({ isOpen: false })}
        isFooterView={true}
      />
      <OlibrShareLink
        isOpen={shareOpen}
        baseUrl={shareLink}
        onClose={() => setShareOpen(false)}
        title={"Share Job"}
      />
      <div className="row">
        <div className="col-12">
          <div className="dash-block cjm">
            <div className="card padding-bottom">
              {!clientId && (
                <div className="card-header">
                  <h4>Jobs List</h4>
                  {clientCurrentRole == "admin" && (
                    <div className="user-selection-box">
                      <SelectOption
                        optionArray={[
                          { _id: "all", email: "All" },
                          ...allActiveUser,
                        ]?.map((item) => ({
                          value: item?._id,
                          label: item?.email,
                        }))}
                        closeMenuOnSelect={true}
                        state={
                          selectedUser
                            ? selectedUser
                            : { value: "all", label: "All" }
                        }
                        placeholder={"Select user"}
                        onChangeFunction={onHandleUserSelection}
                      />
                    </div>
                  )}
                  <a
                    className="link-circle link"
                    onClick={() => handleAddJob(false)}
                  >
                    <Icon name="add-icon" />
                  </a>
                </div>
              )}
              {isJoblistLoading && !isScrolling ? (
                <div style={{ textAlign: "center" }}>
                  {" "}
                  <ClipLoader color="#3BA0AA" />{" "}
                </div>
              ) : (
                <InfiniteScroll
                  dataLength={
                    clientAllJoblists?.data
                      ? clientAllJoblists?.data?.length
                      : 0
                  }
                  height="60vh"
                  next={fetchMoreData}
                  hasMore={
                    clientAllJoblists?.totalDataCounts <=
                    clientAllJoblists?.data?.length
                      ? false
                      : true
                  }
                  loader={
                    isJoblistLoading && (
                      <div style={{ textAlign: "center" }}>
                        {" "}
                        <ClipLoader color="#3BA0AA" />{" "}
                      </div>
                    )
                  }
                >
                  <div className="job-listing">
                    <div className="hiring-list">
                      {clientAllJoblists?.data?.length > 0 ? (
                        <>
                          {clientAllJoblists?.data?.map((item) => (
                            <div className="job-positions">
                              <span className="company-brand-logo">
                                <img
                                  src={item?.jobLogo}
                                  alt="Comany Brand Logo"
                                />
                              </span>
                              <input type="checkbox" />
                              <div className="company-profile">
                                <div className="profile-deatil">
                                  <div className="flex-row-item">
                                    <h3
                                      onClick={() =>
                                        !clientId &&
                                        navigate(
                                          `/client/job-detail-client/${item?._id}`
                                        )
                                      }
                                      style={{
                                        width: "fit-content",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {item?.jobTitle}{" "}
                                    </h3>
                                    {(item.status == "pending" ||
                                      item.status == "rejected") &&
                                      !clientId && (
                                        <span
                                          className="cursor"
                                          onClick={(e) => editJob(e, item)}
                                        >
                                          <Icon name="edit" />
                                        </span>
                                      )}
                                    {item?.isApproved &&
                                      item?.status == "open" && (
                                        <>
                                          <span
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              openShareJob(item?.slug);
                                            }}
                                          >
                                            <Icon name="share-profile" />
                                          </span>
                                          {item?.jobCounter && (
                                            <span
                                              className="flex-row-item"
                                              data-tooltip={"Refresh"}
                                            >
                                              {isJobRefreshing ? (
                                                <ClipLoader
                                                  color="#ff2d55"
                                                  size={20}
                                                />
                                              ) : (
                                                <Icon
                                                  name="refresh-red"
                                                  className={"cursor"}
                                                  width={18}
                                                  onClick={() =>
                                                    onJobRefresh(item._id)
                                                  }
                                                />
                                              )}
                                            </span>
                                          )}
                                        </>
                                      )}
                                  </div>
                                  <ul className="company-profile-list">
                                    <li>
                                      <span className="breafcase">
                                        {item?.organisation}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="">
                                        {item?.minExpRange} -{" "}
                                        {item?.maxExpRange} Yrs
                                      </span>
                                    </li>
                                    <li>
                                      <span className="">
                                        {item?.jobLocation
                                          ? item?.jobLocation == "On Site" ||
                                            item?.jobLocation == "Hybrid"
                                            ? checkMultipleJob(item)
                                            : item?.jobLocation
                                          : "Remote"}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="">
                                        {new Date(
                                          item?.postDate || item?.createdAt
                                        )
                                          .toDateString()
                                          .toUpperCase()
                                          .substring(4)}
                                      </span>
                                    </li>
                                  </ul>
                                  <ul className="services-lists">
                                    {clientAllJoblists?.perksAndBenifits?.map(
                                      (item) => (
                                        <li>
                                          <span
                                            className="icon-round"
                                            data-tooltip={item?.perkName}
                                          >
                                            <img src={item?.iconUrl} alt="" />
                                          </span>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                  <div className="client-jobs">
                                  <JobListSkill 
                                  skill={item?.devChooseSkills}
                                  stack={item?.devChooseStacks}
                                  />
                                  </div>
                                  {/* <ul className="icon-tags">
                                    {item?.devChooseSkills?.map((item) => (
                                      <li key={item?.skillId}>
                                        <a>
                                          <img
                                            src={item?.skillTestIcon}
                                            alt=""
                                          />{" "}
                                          {item?.skillName}
                                          {item?.isRequired && (
                                            <i
                                              class="fa fa-star required-skill-star"
                                              style={{ color: "#ff4000" }}
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </a>
                                      </li>
                                    ))}
                                    {item?.devChooseStacks?.map((item) => (
                                      <li key={item?.stackId}>
                                        <a>
                                          <img src={item?.stackIcon} alt="" />{" "}
                                          {item?.stackName}
                                          {item?.isRequired && (
                                            <i
                                              class="fa fa-star required-skill-star"
                                              style={{ color: "#ff4000" }}
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </a>
                                      </li>
                                    ))}
                                  </ul> */}
                                  {/* <ul className="tech-list">
                                    {item?.tags?.map((li) => (
                                      <li>{li}</li>
                                    ))}
                                  </ul> */}
                                </div>
                                <div className="info-right">
                                  {item?.isApproved && (
                                    <div class="text-right">
                                      <span
                                        class="applynow"
                                        style={{ color: "rgb(59, 160, 170)" }}
                                      >
                                        APPROVED
                                      </span>
                                    </div>
                                  )}
                                  <div
                                    className={
                                      item._id === drop &&
                                      item.status !== "pending" &&
                                      item.status !== "rejected" &&
                                      !clientId
                                        ? "dropdown-active"
                                        : "dropdown"
                                    }
                                    onClick={(e) => {
                                      e?.stopPropagation();
                                      drop ? setDrop("") : setDrop(item._id);
                                    }}
                                  >
                                    {/* breakIntoFortyChar */}
                                    <a href="#" className="reject-tooltip-s">
                                      {item.status === "rejected" ? (
                                        <Tooltips
                                          content={
                                            <div>
                                              {breakIntoFortyChar(
                                                item?.feedback
                                              )}
                                            </div>
                                          }
                                          direction="top2"
                                        >
                                          <div className="job-drop-capitalize">
                                            {item.status}
                                          </div>
                                        </Tooltips>
                                      ) : (
                                        <span className="job-drop-capitalize">
                                          {item.status
                                            ? item.status
                                            : "Select Status"}
                                        </span>
                                      )}
                                    </a>
                                    <div className="dropdown-content">
                                      <ul className="popupList">
                                        <li
                                          className={`popUpListItem1 pop-flex ${
                                            item?.status === "open"
                                              ? "selected-bg"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            item.status === "closed"
                                              ? olibrToast.error(
                                                  "You can't re-open a closed job."
                                                )
                                              : handleJobStatus(
                                                  "open",
                                                  item.status,
                                                  item._id
                                                );
                                          }}
                                        >
                                          <span>OPEN</span>{" "}
                                          <img
                                            src={
                                              item?.status === "open"
                                                ? SelectCheck
                                                : CloseIcon
                                            }
                                            alt="Selected"
                                          />
                                        </li>
                                        <li
                                          className={`popUpListItem2 pop-flex ${
                                            item?.status === "paused"
                                              ? "selected-bg"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            item.status === "closed"
                                              ? olibrToast.error(
                                                  "You can't pause a closed job."
                                                )
                                              : handleJobStatus(
                                                  "paused",
                                                  item.status,
                                                  item._id
                                                );
                                          }}
                                        >
                                          <span>PAUSE</span>{" "}
                                          <img
                                            src={
                                              item?.status === "paused"
                                                ? SelectCheck
                                                : CloseIcon
                                            }
                                            alt="Closed"
                                          />
                                        </li>
                                        <li
                                          className={`popUpListItem3 pop-flex ${
                                            item?.status === "closed"
                                              ? "selected-bg"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            item?.status !== "closed" &&
                                            setCloseConfirm({
                                              isOpen: true,
                                              status: "closed",
                                              currentStatus: item.status,
                                              id: item._id,
                                            })
                                          }
                                        >
                                          <span>CLOSE</span>{" "}
                                          <img
                                            src={
                                              item?.status === "closed"
                                                ? SelectCheck
                                                : CloseIcon
                                            }
                                            alt="Closed"
                                          />
                                        </li>
                                      </ul>
                                    </div>
                                  </div>

                                  <div className="applicants">
                                    <span
                                      data-tooltip="Total Applicants"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        !clientId &&
                                          navigate(
                                            `/client/job-applicants/${item?._id}`
                                          );
                                      }}
                                    >
                                      {item?.devAppliedCount}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="bottom-info">
                                <div class="job-time">
                                  {item?.jobCounter && (
                                    <span className="comment-date">
                                      <span>
                                        Job closing in {item?.jobCounter}{" "}
                                        {parseInt(item?.jobCounter) > 1
                                          ? "days"
                                          : "day"}
                                      </span>{" "}
                                    </span>
                                  )}
                                </div>

                                <div class="job-view client-job-views">
                                  {item?.viewedCount || 0} views
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        "No data"
                      )}
                    </div>
                  </div>
                </InfiniteScroll>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ViewAllJobs;
