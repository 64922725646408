import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { monthArr } from "../../../common/constant/monthArr";
import { getJobListByClientAdmin } from "../../../service/action/admin";
import InfiniteScroll from "react-infinite-scroll-component";
import { Icon } from "../../../components/icon";

const JobListOfClient = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const { clientJobListAdmin, isClientJobListAdminLoading } = useSelector(
    (state) => state.adminReducer
  );
  const [pageData, setPageData] = useState({
    limit: 20,
    page: 1,
  });

  const navigateToJob = (clientId) => {
    navigate(`/admin/job-desc/${clientId}`);
  };

  useEffect(() => {
    dispatch(getJobListByClientAdmin(clientId, pageData, navigate));
  }, [pageData]);

  const fetchMoreData = () => {
    setPageData({ ...pageData, limit: pageData?.limit + 10 });
  };

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <Fragment>
      <div className="main-div-developer">
        <div className="skills-management">
          <div className="block-header flex-row-center space-between">
            <h4>Jobs List</h4>
            <div className="cursor" onClick={handleGoBack}>
              <Icon name="modal-close" />
            </div>
          </div>
          <InfiniteScroll
            height={"60vh"}
            dataLength={
              clientJobListAdmin?.data ? clientJobListAdmin?.data?.length : 0
            }
            next={fetchMoreData}
            hasMore={
              clientJobListAdmin?.data?.length <
              clientJobListAdmin?.totalDataCount
                ? true
                : false
            }
            loader={
              isClientJobListAdminLoading && (
                <div style={{ textAlign: "center" }}>
                  {" "}
                  <ClipLoader color="#3BA0AA" />{" "}
                </div>
              )
            }
          >
            <table className="table">
              <thead>
                <tr>
                  <th className="column0">S.No</th>
                  <th className="column1">Job Title</th>
                  <th className="column1">Preferred Time</th>
                  <th className="column1">Budget</th>
                  <th className="column1">Budget Type</th>
                  <th className="column1">Posted On</th>
                </tr>
              </thead>
              <tbody>
                {clientJobListAdmin?.data?.map((job, index) => (
                  <tr key={job?._id} className="dev-hover">
                    <td className="column0">
                      {/* {(page - 1) * limit + (index + 1)} */} {index + 1}
                    </td>
                    <td
                      className="column1"
                      onClick={() => navigateToJob(job?._id)}
                    >
                      {job?.jobTitle}
                    </td>
                    <td className="column1">{job?.workTiming}</td>
                    <td className="column1">{job?.budgetMin}-{job?.budgetMax}</td>
                    <td className="column1">{job?.budgetFor}</td>
                    <td className="column1">
                      {job?.postDate?.split("T")[0]?.split("-")[2]}{" "}
                      {
                        monthArr[
                          parseInt(
                            job?.postDate?.split("T")[0]?.split("-")[1]
                          ) - 1
                        ]
                      }{" "}
                      {job?.postDate?.split("T")[0]?.split("-")[0]}
                    </td>
                  </tr>
                ))}
                {clientJobListAdmin?.data?.length <= 0 && (
                  <p>No Jobs found...</p>
                )}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </div>
    </Fragment>
  );
};

export default JobListOfClient;
