import React from "react";
import PropTypes from "prop-types";
const AwardReview = ({ devCertificates }) => {
  return (
    !!devCertificates?.length && (
      <div className="resume-outer theme-yellow">
        <div className="upper-title">
          <h4>Certificates</h4>
        </div>
        {devCertificates?.map((itm, index) => (
          <div key={index} className="resume-block">
            <div className="inner">
              <span className="name">
                {itm?.certInstitution
                  ? itm.certInstitution[0]
                  : itm.certificateName[0]}
              </span>
              <div className="title-box">
                <div className="info-box">
                  <h3>{itm?.certificateName}</h3>
                  <span>{itm?.certInstitution}</span>
                </div>
                {(itm?.endDate || itm?.startDate) && (
                  <div className="edit-box">
                    <span className="year">
                      {itm?.startDate &&
                        new Date(itm?.startDate)?.getFullYear() + " - "}{" "}
                      {itm?.endDate && new Date(itm?.endDate)?.getFullYear()}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  );
};
AwardReview.propTypes = {
  devCertificates: PropTypes?.array,
};
export default AwardReview;
