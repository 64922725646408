import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { devHomepageOG } from "../imageLinks";
import NoFollow from "../../../common/staticData/seo/NoFollow";

const DeveloperHomePage = ({ baseUrl }) => {
  return (
    <Helmet>
      <title>Get placed in leading global software jobs through our pool</title>

      <meta charset="UTF-8" />
      <meta
        name="title"
        content="Get placed in leading global software jobs through our pool"
      />
      <meta
        name="description"
        content="Olibr offers Software Jobs & opportunities for developers/engineers with top US Companies. Full-time long-term jobs and flexible work hours."
      ></meta>
      <link rel="canonical" href={`${baseUrl}/developer-homepage`} />
      <meta
        name="keywords"
        content="olibr, olibr.com, Hiring developers, remote software developers, work-from-home developers, wfh, remote jobs, IT jobs, US based IT companies, US Jobs, US IT Jobs"
      />
        <meta name="robots" content={NoFollow()}></meta>

      <meta name="language" content="english"></meta>
      <html lang="en" />
      <meta name="url" content={`${baseUrl}/developer-homepage`} />
      <meta
        name="identifier-URL"
        content={`${baseUrl}/developer-homepage`}
      ></meta>
      <meta name="referrer" content="origin-when-crossorigin"></meta>
      <link rel="image_src" href={devHomepageOG} />
      <meta name="copyright" content="olibr.com" />

      <meta
        property="og:title"
        content="Get placed in leading global software jobs through our pool"
      />
      <meta
        property="og:description"
        content="Olibr offers Software Jobs & opportunities for developers/engineers with top US Companies. Full-time long-term jobs and flexible work hours."
      />
      <meta property="og:url" content={`${baseUrl}/developer-homepage`} />
      <meta property="og:image" content={devHomepageOG} />
      <meta property="og:site_name" content="Olibr" />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@olibr" />
      <meta property="twitter:domain" content="olibr.com" />
      <meta property="twitter:url" content={`${baseUrl}/developer-homepage`} />
      <meta
        name="twitter:title"
        content="Get placed in leading global software jobs through our pool"
      />
      <meta
        name="twitter:description"
        content="Olibr offers Software Jobs & opportunities for developers/engineers with top US Companies. Full-time long-term jobs and flexible work hours."
      />
      <meta name="twitter:image" content={devHomepageOG} />

      <meta property="og:type" content="business.business" />
      <meta
        property="business:contact_data:street_address"
        content="26 Arrowhead Ln"
      />
      <meta property="business:contact_data:locality" content="Menlo Park" />
      <meta property="business:contact_data:region" content="CA" />
      <meta property="business:contact_data:postal_code" content="94025" />
      <meta property="business:contact_data:country_name" content="US" />

      {/* ------Apple Devices ------ */}

      <meta
        name="apple-mobile-web-app-title"
        content="Get placed in leading global software jobs through our pool"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-touch-fullscreen" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black"></meta>

      {/* ----------JSON LD Schema for SEO------ */}

      <script type="application/ld+json">
        {JSON.stringify(
          // --------breadcrumbs------
          {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: `${baseUrl}/`,
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Developers Homepage",
                item: `${baseUrl}/developer-homepage`,
              },
            ],
          }
        )}
      </script>
      {/* --------------------- */}
      <script type="application/ld+json">
        {JSON.stringify(
          // ----speakbale---
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            name: "Join Olibr for full-time remote software jobs in the US",
            speakable: {
              "@type": "SpeakableSpecification",
              xpath: [
                "/html/head/title",
                "/html/head/meta[@name='description']/@content",
              ],
            },
            url: `${baseUrl}/developer-homepage`,
          }
        )}
      </script>
      {/* ---------------------- */}
      <script type="application/ld+json">
        {JSON.stringify(
          // ----organisation----

          {
            "@context": "https://schema.org",

            "@type": "Organization",

            name: "Olibr.com",

            url: `${baseUrl}/developer-homepage`,

            logo: "%PUBLIC_URL%/olibr-full-logo.png",

            contactPoint: {
              "@type": "ContactPoint",

              telephone: "000000000",

              contactType: "technical support",
            },

            sameAs: [
              "https://www.facebook.com/olibrcom",

              "https://www.linkedin.com/company/olibrcom/",

              "https://twitter.com/olibr",

              "https://www.instagram.com/olibr",

              "https://www.youtube.com/@olibr",

              "https://in.pinterest.com/olibr/",

              "",

              "https://www.olibr.com/",
            ],
          },

          {
            "@context": "https://www.schema.org",

            "@type": "WebSite",

            name: "Join Olibr for full-time remote software jobs in the US",

            url: `${baseUrl}/developer-homepage`,
          }
        )}
      </script>
      {/* --------------------------- */}
      <script type="application/ld+json">
        {JSON.stringify(
          // -----------webpage/website------
          {
            "@context": "https://www.schema.org",

            "@type": "WebSite",

            name: "Join Olibr for full-time remote software jobs in the US",

            url: `${baseUrl}/developer-homepage`,
          }
        )}
      </script>
    </Helmet>
  );
};

export default DeveloperHomePage;
