import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
// );

import { hydrate, render } from "react-dom";
import ReactGA from "react-ga4";
import ScrollToTop from "./common/ScrollToTop";

ReactGA.initialize("G-T1XJG0ZLT0");
 
const APP = ( <BrowserRouter>
  <App />
  <ScrollToTop/>
</BrowserRouter>)

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(APP , rootElement);
} else {
  render(APP, rootElement);
}


reportWebVitals();

reportWebVitals();
