import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  devActivation,
  getDevEmailAutomation,
} from "../../service/action/developer";
import SelectSkill from "./Developer/SelectSkill";
import { getState } from "../../service/action/common";
import SkillCategory from "./Developer/SkillCategory";
import DateFormate from "../../common/DateFormate";
import { checkExistAllSkill, removeArrFromArry } from "../functions";
import BasicDetails from "./Developer/component/BasicDetails";
import AddressDetail from "./Developer/component/AddressDetail";
import SallaryDetals from "./Developer/component/SallaryDetals";
import NoticePeriod from "./Developer/component/NoticePeriod";
import Reallocate from "./Developer/component/Reallocate";
import Snooze from "./Developer/component/Snooze";

const Developer = ({ openModal, resumeData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { devActivations, skillResp, stackResp } = useSelector(
    (state) => state?.developerReducer
  );
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [activeTab, setActiveTab] = useState("Skill");
  const [skillShown, setSkillShown] = useState(false);
  const [allSkill, setAllSkill] = useState([]);
  const [allStack, setAllStacks] = useState([]);
  const handleActivation = (slug, val) => {
    let payload = {
      emailType: slug,
      userId: resumeData?._id,
      status: val,
    };
    if (slug === "new_job_posted_for_dev") {
      payload.selectedSkillsStacks =
        [...skillResp, ...stackResp].length <= selectedSkill?.length
          ? ["all"]
          : selectedSkill;
    }
    dispatch(devActivation(navigate, payload));
  };

  useEffect(() => {
    dispatch(getState({ countryCode: "IN" }, navigate));
    dispatch(getDevEmailAutomation(navigate));
  }, []);

  useEffect(() => {
    if (
      devActivations?.data?.filter(
        (itm) => itm?.slug == "new_job_posted_for_dev"
      )?.length
    ) {
      setSkillShown(true);
    } else {
      setSkillShown(false);
    }
  }, [devActivations]);

  const saveSkills = (skillArr, skillType) => {
    let skills = [...new Set(skillArr)];
    let skillExst = checkExistAllSkill([...new Set(skills)], allSkill);
    let stackExst = checkExistAllSkill([...new Set(skills)], allStack);
    let finalArray = [];
    if (skillExst) {
      finalArray = removeArrFromArry(skills, allSkill);
      finalArray?.push("all-skill");
    } else {
      finalArray = skills;
    }
    if (stackExst) {
      finalArray = removeArrFromArry(finalArray, allStack);
      finalArray?.push("all-stack");
    }

    let payload = {
      emailType: "new_job_posted_for_dev",
      userId: resumeData?._id,
      status: devActivations?.data?.[0]?.status
        ? devActivations?.data?.[0]?.status
        : false,
      selectedSkillsStacks: finalArray,
    };
    dispatch(devActivation(navigate, payload));
  };

  return (
    <div id="account-setting">
      <BasicDetails resumeData={resumeData} openModal={openModal} />
      <AddressDetail resumeData={resumeData} openModal={openModal} />
      <SallaryDetals resumeData={resumeData} openModal={openModal} />
      <NoticePeriod
        DateFormate={DateFormate}
        resumeData={resumeData}
        openModal={openModal}
      />
      <Reallocate relocate={resumeData?.relocate} />
      <Snooze resumeData={resumeData} />

      {!!devActivations?.data?.length && (
        <div className="setting-card setting-jobs-skill">
          <h3>Email Alerts</h3>
          {devActivations?.data?.map(
            (item) =>
              item?.slug !== "new_job_posted_for_dev" && (
                <>
                  <h4>{item?.settingTitle}</h4>
                  <div className="row">
                    <div className="col-11">
                      <p className="noti">{item?.settingSubTitle}</p>
                    </div>
                    <div className="col-1">
                      <span
                        onClick={() =>
                          handleActivation(item?.slug, !item?.status)
                        }
                      >
                        <input
                          type="checkbox"
                          name="switch"
                          checked={!!item?.status}
                        />
                        <label className="label" htmlFor="switch3"></label>
                      </span>
                    </div>
                  </div>
                </>
              )
          )}
          {devActivations?.data?.map(
            (item) =>
              item?.slug == "new_job_posted_for_dev" && (
                <>
                  <h4>{item?.settingTitle}</h4>
                  <div className="row">
                    <div className="col-11">
                      <p className="noti">{item?.settingSubTitle}</p>
                    </div>
                    <div className="col-1">
                      <span
                        onClick={() =>
                          handleActivation(item?.slug, !item?.status)
                        }
                      >
                        <input
                          type="checkbox"
                          name="switch"
                          checked={!!item?.status}
                        />
                        <label className="label" htmlFor="switch3"></label>
                      </span>
                    </div>
                  </div>
                </>
              )
          )}
          {skillShown && (
            <>
              <SkillCategory
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />

              <SelectSkill
                activeTab={activeTab}
                selectedSkill={selectedSkill}
                setSelectedSkill={setSelectedSkill}
                devActivations={devActivations}
                allSkill={allSkill}
                setAllSkill={setAllSkill}
                setAllStacks={setAllStacks}
                allStack={allStack}
                saveSkills={saveSkills}
              />
            </>
          )}
        </div>
      )}
      <div className="container">
        <div className="row">
          <div className="col-12 text-right">
            <button
              className="btn"
              onClick={() => navigate("/developer/dashboard")}
            >
              back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Developer;
