import { Image, View, Text, Svg } from '@react-pdf/renderer'
import React, { Fragment } from 'react'

import EmployementIcon from "../../../assets/img/icons/employment-icon-png.png";
import { EmployementStyle } from './css/EmployementStyle';
import { awsPngToImage, formDateDateYear } from '../../functions';
import EmpDescription from './EmpDescription';
import EmpProject from './EmpProject';
const Employement = ({ devEmployment, projects }) => {
    return (
        Array.isArray(devEmployment) && devEmployment?.map((itm, index) => (
            <Fragment key={index}>
                <View style={EmployementStyle.employetmentMain}>
                    <View style={EmployementStyle.emplLayoutLeft} >
                        {
                            index == 0 && (
                                <>
                                    <View>
                                        <Image source={EmployementIcon} style={EmployementStyle.employementIcon} />
                                    </View>
                                    <View style={EmployementStyle.emplLayoutLists}>
                                        <Text style={EmployementStyle.empHeading}> Employement</Text>
                                    </View>
                                </>
                            )
                        }
                    </View>

                    <View style={EmployementStyle.emplLayoutRight}>
                        <View style={EmployementStyle.emplDetails}>
                            <Text style={EmployementStyle.circle}></Text>
                            <Text style={EmployementStyle.emplName}>{itm?.designation}</Text>
                            <View style={EmployementStyle.companyNameView}>
                                <View style={EmployementStyle.companyNameLeft}> <Text style={EmployementStyle.companyName}>{itm?.companyName}</Text></View>
                                <View  style={EmployementStyle.companyNameRight}> <Text style={EmployementStyle.companyPresent}>{formDateDateYear(itm?.from)} {" - "}
                                    {itm?.stillWorking
                                        ? "PRESENT"
                                        : formDateDateYear(itm?.to)}</Text></View>
                            </View>
                        </View>
                        {
                            itm?.techUsed?.length && (
                                <View style={EmployementStyle.technology}>
                                    <View style={EmployementStyle.technologyLeft}>
                                        <Text style={EmployementStyle.technologyHeading}>Technology</Text>
                                    </View>

                                    {
                                        itm?.techUsed?.map((tech, ind) => (
                                            <View style={EmployementStyle.technologyRight}>
                                                <View style={EmployementStyle.technologySkill}>
                                                    <View style={EmployementStyle.technichalSkillIcon}>
                                                        {awsPngToImage(tech?.value) && (<Image style={EmployementStyle.technichalSkillImg} src={awsPngToImage(tech?.value)} />)}
                                                    </View>
                                                    <View >
                                                        <Text style={EmployementStyle.technichalSkillText}>{tech?.label}</Text>
                                                    </View>

                                                </View>
                                            </View>
                                        ))
                                    }
                                </View>
                            )
                        }

                        {
                            itm?.toolUsed?.length && (
                                <View style={EmployementStyle.tools}>
                                    <View style={EmployementStyle.technologyLeft}>
                                        <Text style={EmployementStyle.technologyHeading}>Tools</Text>
                                    </View>
                                    {
                                        itm?.toolUsed?.map((itm) => (
                                            <View style={EmployementStyle.technologyRight}>
                                                <View style={EmployementStyle.technologySkill}>
                                                    <View style={EmployementStyle.technichalSkillIcon}>
                                                       {awsPngToImage(itm?.value) && <Image style={EmployementStyle.technichalSkillImg} src={awsPngToImage(itm?.value)} />}
                                                    </View>
                                                    <View >
                                                        <Text style={EmployementStyle.technichalSkillText}>{itm?.label}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        ))
                                    }

                                </View>
                            )
                        }

                        <EmpDescription content={itm?.aboutRole} />
                        <EmpProject projects={projects} empId={itm?._id} />
                    </View>

                </View>
            </Fragment>

        ))
    )
}

export default Employement