import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import SelectOption from '../../../select/SelectOption';
import { useDispatch, useSelector } from 'react-redux';
import { filterStateExists, stateArrFunc } from '../../../functions';
import { useNavigate } from 'react-router-dom';
import { updateBasicDetails } from '../../../../service/action/developer';

const Reallocate = ({ relocate }) => {
    const {
        register,
        setValue,
        getValues,
        watch,
        control,
        formState: { errors },
    } = useForm();

    const { stateList } = useSelector((state) => state.commonReducer);
    const [selectedState, setSelectedState] = useState([]);
    const [anyWhere,setAnyWhere]=useState(false)
    
    
    const handleRemove=(index)=>{
      let newArr=[...selectedState]
      newArr= newArr.filter((itm,ind)=>index!==ind)
      dispatch(updateBasicDetails(navigate, {relocate:{locations:newArr,anyWhere:false}}))
      setSelectedState(newArr)
    }

    const navigate=useNavigate();
    const dispatch=useDispatch();

    useEffect(()=>{
        if(Array.isArray(relocate?.locations)){
            setSelectedState(relocate?.locations)
        }
        setAnyWhere(!!relocate?.anyWhere)
    },[relocate])

    const close=()=>{
        setAnyWhere(!anyWhere)
    }
    const handleActivation=()=>{
        dispatch(updateBasicDetails(navigate, {relocate:{anyWhere:!anyWhere,locations:[]}},close))
    }
    const handleChangeUpdate=(state)=>{
        setSelectedState([...selectedState,...state])
        dispatch(updateBasicDetails(navigate, {relocate:{locations:[...selectedState,...state],anyWhere:false}}))
    }
    return (
        <div className="setting-card">
            <h3>Open To Relocate</h3>
            <div className="row">
                <div className="col-11">
                    <h5 className="noti reloacte-head">Anywhere in India</h5>
                </div>
                <div className="col-1">
                    <span onClick={handleActivation}>
                        <input type="checkbox" name="switch" onChange={(e) => e.preventDefault()} checked={anyWhere} /><label className="label" htmlFor="switch3" ></label>
                    </span>
                </div>
                <div className="col-12">
                    <Controller
                        control={control}
                        name="relocate"
                        {...register("relocate", {
                            required: true,
                        })}
                        render={({ field }) => (
                            <SelectOption
                                searchable={true}
                                optionArray={stateArrFunc(filterStateExists(stateList,selectedState))}
                                onChangeFunction={(state)=>handleChangeUpdate(state)}
                                getValue={getValues}
                                disabled={selectedState?.length===3 || anyWhere}
                                closeMenuOnSelect={true}
                                getValueName={"relocate"}
                                placeholder={"Tell us your location preferences"}
                                isMulti={true}
                                className={"custom-react-select"}
                                isTranshparent={true}
                            />
                        )}
                    />
                </div>
                <div className='col-12 relocate-area'>
                    <ul>
                        {
                            selectedState?.map((itm,index) => (
                                <li>
                                    {itm?.label} <span onClick={()=>handleRemove(index)}>&#x2715;</span>
                                </li>
                            ))
                        }

                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Reallocate