

export const CheckApplyFilter = (data) => {
   
    let filter = false;
    for (let x in data) {
        if (typeof data[x] == "string" && data[x]) {
            return filter = true;
        } else if (data[x] && Object.prototype.toString.call(data[x]) === "[object Date]") {
            console.log("string")
            return filter = true;
        } else if (typeof data[x] == "object" && data[x] && Array.isArray(data[x]) && data[x].length) {
            return filter = true
        } else if (typeof data[x] == "object" &&  data[x] && !(Array.isArray(data[x])) && Object.keys(data[x]).length) {
            return filter = true
        } else if (typeof data[x] == "boolean" && data[x]) {
            return filter = true
        } else if (typeof data[x] == "number" && data[x]) {
            return filter = true
        }
    }
    return filter;
}