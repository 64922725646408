import React, { createRef, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageTour from '../../../components/page-tour/PageTour';
import { ClipLoader } from 'react-spinners';
import { useState } from 'react';

const AwardAndRecog = ({awardRecognitions,setAwardRecognitions, imgIndex, uploadImages, pageTour,  skipPageTour, companyProfile, setPageTour }) => {
    const { clientId } = useParams();
    const {
        isawAwrdImageLoading,
        awardImageReducer
    } = useSelector((state) => state?.developerReducer);
  



    const [awardImg, setElRefs] = useState([]);
    useEffect(() => {
        if (awardImageReducer && Object?.keys(awardImageReducer)?.length) {
          let clone = [...awardRecognitions];
          let obj = clone[awardImageReducer?.index];
          obj.awardImage = awardImageReducer?.image;
          setAwardRecognitions([...clone]);
        }
      }, [awardImageReducer]);
    useEffect(() => {
      setElRefs((elRefs) =>
        Array(awardRecognitions.length)
          .fill()
          .map((_, i) => elRefs[i] || createRef()),
      );
    }, [awardRecognitions]);

    const handleTeamChange = (index, e) => {
        let clone = [...awardRecognitions];
        let obj = clone[index];
        obj.title = e.target.value;
        clone[index] = obj;
        setAwardRecognitions([...clone]);
    };
    const addAward = () => {
        if (awardRecognitions?.length >= 4) {
            return false
        }
        setAwardRecognitions([
            ...awardRecognitions,
            {
                awardImage: "",
                title: "",
            },
        ]);
    };

    const removeAward = (index) => {
        if (awardRecognitions?.length) {
            let clone = [...awardRecognitions];
            let obj = clone?.filter((item, ind) => ind != index);
            setAwardRecognitions(obj);
        }
    };

    return (
        <><br/>
            <h4 className="form-title" id="team">
                <span>Awards & Recognitions</span>{" "}
                <a className="plus-icon" onClick={addAward}>
                    +
                </a>
            </h4>
            <PageTour
                open={pageTour == "team"}
                data={companyProfile?.team}
                next={() => setPageTour("gallery")}
                className="team-section"
                skip={skipPageTour}
                total={6}
                id="#benefit"
            />
            {awardRecognitions?.map((item, index) => (
                <div className="row">
                    <div className="col-12">
                        <div className="add-team" >
                            <div className="add-form" style={{ width: "94%" }}>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>
                                                Title
                                            </label>
                                            <input
                                                type="text"
                                                value={item?.title}
                                                name="memberName"
                                                maxLength={38}
                                                onChange={(e) =>
                                                    handleTeamChange(index, e)
                                                }
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group adornment">
                                            <label>
                                                Image
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control`}
                                                name="awardImage"
                                                disabled={clientId ? false : true}
                                            />
                                            <img
                                                className="input-img"
                                                src={
                                                    awardRecognitions[index]?.awardImage
                                                }
                                            />
                                            <input
                                                ref={awardImg[index]}
                                                type="file"
                                                onChange={(e) =>
                                                    uploadImages(
                                                        e,
                                                        "awardImage",
                                                        index
                                                    )
                                                }
                                                className={`input-file`}
                                                accept=".png, .jpeg, .jpg, .svg"
                                            />
                                            <a
                                                className="browseImage browseImageNew"
                                            onClick={() =>
                                                awardImg[index].current.click()
                                            }
                                            >
                                                {isawAwrdImageLoading &&
                                                    imgIndex == index ? (
                                                    <ClipLoader
                                                        size={20}
                                                        color="#3BA0AA"
                                                    />
                                                ) : (
                                                    "Upload"
                                                )}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="actions">
                                <a
                                    className="minus-icon"
                                    onClick={() => removeAward(index)}
                                >
                                    -
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

        </>
    )
}

export default AwardAndRecog