import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses }  from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";

export default function LinearTestResult({status="",value}) {

  const TotalLinearProgress = styled(LinearProgress)(() => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#f1f1f9',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#1371f0',
    },
  }));

  const DefaultLinearProgress = styled(LinearProgress)(() => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#f1f1f9',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#343c42',
    },
  }));

  return (
    <Box sx={{ width: '100%'}}>
      {
        status === "passed" ? <TotalLinearProgress variant="determinate" value={value} /> :
         <DefaultLinearProgress variant="determinate" value={value} />
      }
    </Box>
  );
}

LinearTestResult.propTypes = {
  status: PropTypes.string,
  value:PropTypes.number
}