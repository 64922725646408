import React, { Fragment } from "react";
import MetaTagSEO from "../../components/seo/MetaTagSEO";

const ComingSoon = () => {
  return (
    <Fragment>
      <MetaTagSEO helmetName="coming-soon" />
      <h1>ComingSoon</h1>
    </Fragment>
  );
};

export default ComingSoon;
