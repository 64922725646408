import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { blogOG } from "../imageLinks";
import NoFollow from "../../../common/staticData/seo/NoFollow";

const Blogs = ({ baseUrl }) => {
  return (
    <Helmet>
      <title>Olibr Blogs - Technology, jobs, IT blogs and articles </title>

      <meta charset="UTF-8" />

      <meta
        name="title"
        content="Olibr Blogs - Technology, jobs, IT blogs and articles "
      />

      <meta
        name="description"
        content="Follow Olibr blog for insights on new technologies, office work trends, offshore team management, and best software development practices by industry experts."
      ></meta>

      <link rel="canonical" href={`${baseUrl}/blog`} />
      <meta
        name="keywords"
        content="blogs by olibr.com, tech blogs, olibr, olibr.com, Hiring developers, remote software developers, work-from-home developers, wfh, remote jobs, IT jobs, US based IT companies, US Jobs, US IT Jobs"
      />
        <meta name="robots" content={NoFollow()}></meta>

      <meta name="language" content="english"></meta>
      <html lang="en" />
      <meta name="url" content={`${baseUrl}/blog`} />
      <meta name="identifier-URL" content={`${baseUrl}/blog`}></meta>
      <meta name="referrer" content="origin-when-crossorigin"></meta>

      <link rel="image_src" href={blogOG} />
      <meta name="copyright" content="olibr.com" />
      <meta
        property="og:title"
        content="Olibr Blogs - Technology, jobs, IT blogs and articles "
      />
      <meta
        property="og:description"
        content="Follow Olibr blog for insights on new technologies, office work trends, offshore team management, and best software development practices by industry experts."
      />
      <meta property="og:url" content={`${baseUrl}/blog`} />
      <meta property="og:image" content={blogOG} />
      <meta property="og:site_name" content="Olibr" />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@olibr" />
      <meta property="twitter:domain" content="olibr.com" />
      <meta property="twitter:url" content={`${baseUrl}/blog`} />
      <meta
        name="twitter:title"
        content="Olibr Blogs - Technology, jobs, IT blogs and articles "
      />
      <meta
        name="twitter:description"
        content="Follow Olibr blog for insights on new technologies, office work trends, offshore team management, and best software development practices by industry experts."
      />
      <meta name="twitter:image" content={blogOG} />

      <meta property="og:type" content="business.business" />
      <meta
        property="business:contact_data:street_address"
        content="26 Arrowhead Ln"
      />
      <meta property="business:contact_data:locality" content="Menlo Park" />
      <meta property="business:contact_data:region" content="CA" />
      <meta property="business:contact_data:postal_code" content="94025" />
      <meta property="business:contact_data:country_name" content="US" />

      {/* ------Apple Devices ------ */}

      <meta
        name="apple-mobile-web-app-title"
        content="Olibr Blogs - Technology, jobs, IT blogs and articles "
      />

      <meta name="apple-mobile-web-app-capable" content="yes" />

      <meta name="apple-touch-fullscreen" content="yes" />

      <meta name="apple-mobile-web-app-status-bar-style" content="black"></meta>

      {/* ----------JSON LD Schema for SEO------ */}

      <script type="application/ld+json">
        {JSON.stringify(
          // ------------breadcrumbs------
          {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: baseUrl,
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Blogs",
                item: `${baseUrl}/blog`,
              },
            ],
          }
        )}
      </script>
      {/* -------------------------- */}
      <script type="application/ld+json">
        {JSON.stringify(
          //  speakable-----
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            name: "Olibr Blog | Remote Work & Technology Trends",
            speakable: {
              "@type": "SpeakableSpecification",
              xpath: [
                "/html/head/title",
                "/html/head/meta[@name='description']/@content",
              ],
            },
            url: `${baseUrl}/blog`,
          }
        )}
      </script>
      {/* --------------------------------- */}
      <script type="application/ld+json">
        {JSON.stringify(
          // webpage/website----

          {
            "@context": "https://www.schema.org",

            "@type": "WebSite",

            name: "Olibr Blog | Remote Work & Technology Trends",

            url: `${baseUrl}/blog`,
          }
        )}
      </script>
      {/* ------------------------------- */}
    </Helmet>
  );
};

export default Blogs;
