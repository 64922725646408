import React, { Fragment, useEffect, useState } from "react";
import Filters from "./side-comp/Filters";
import FeatTech from "./side-comp/FeatTech";
import MainBox from "./side-comp/MainBox";
import DetailBox from "./side-comp/DetailBox";
import PopularJob from "./side-comp/PopularJob";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getJobByCategoryAction,
  getJobByJobIdAction,
  getPopularJobsAction,
} from "../../../service/action/common";
import { getJobCatAction } from "../../../service/action/admin";
import SearchBar from "./header/SearchBar";
import "../../jobs/Jobs.css"
const BaseComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobId } = useParams();
  const { popularJobs, jobByCatList, jobByIdDetail, jobByJobIdLoading } = useSelector(
    (state) => state.commonReducer
  );

  const [popularJob, setPopulrJob] = useState([])
  const { jobCatList } = useSelector((state) => state.adminReducer);
  const { appliedJob } = useSelector((state) => state.developerReducer);
  const [currentJobCategory, setCurrentJobCategory] = useState(null);
  const [filter, setFilter] = useState({
    jobTitle: "",
    state: "",
    city: "",
    jobLocation: "",
  });
  const [pageData, setPageData] = useState({
    page: 1,
    limit: 10,
  })

  const getJobLists=(
    clearPrev=true,
    page=pageData,
    cat=currentJobCategory,
    filt=filter, 
    searchs=search
    )=>{
    dispatch(getJobByCategoryAction(navigate, cat, filt, page, clearPrev,searchs))
  }

  useEffect(() => {
    getJobLists()
  }, [])


  const [search, setSearch] = useState("")

  const fetchMoreData = () => {
    getJobLists(false,{ ...pageData,page: pageData?.page + 1})
    setPageData({
      ...pageData,
      page: pageData?.page + 1
    })
  }


  useEffect(() => {
    setPopulrJob(popularJobs)
  }, [popularJobs])
  useEffect(() => {
    dispatch(getPopularJobsAction(navigate));
    dispatch(getJobCatAction(navigate));
  }, [navigate, dispatch]);

  useEffect(() => {
    if (!!jobId) {
      dispatch(getJobByJobIdAction(jobId, navigate));
    }
  }, [jobId]);

  const [appliedArr, setAppliedArr] = useState([])
  useEffect(() => {
    let arr = [];
    arr = appliedJob?.data?.map((item) => item?.jobId?._id)
    setAppliedArr(arr);
  }, [appliedJob])

  const [catList, setCatList] = useState([]);
  useEffect(() => {
    let arr = [];
    arr = jobCatList?.data?.map((item) => ({
      id: item?._id,
      catName: item?.categoryName,
    }));
    setCatList(arr);
  }, [jobCatList]);

  const handleCategory = (catId) => {
    getJobLists(true,{ ...pageData, page: 1 },catId)
    setPageData({ ...pageData, page: 1 })
    setCurrentJobCategory(catId);
  };

  const submitSearch = () => {
    getJobLists(true,{ ...pageData, page: 1 })
    setPageData({ ...pageData, page: 1 })
  }

  const clearSearch = () => {
    getJobLists(true,{ ...pageData, page: 1 },currentJobCategory,filter,"")
    setSearch("");
    setPageData({ ...pageData, page: 1 })
  }
  return (
    <Fragment>
      {" "}
      <div className="wrapper">
        <div id="job-list">
          {" "}
          {!jobId && (
            <Fragment>
              {" "}
              <div className="job-banner">
                <div className="">
                  <SearchBar setSearch={setSearch} clearFilter={clearSearch} search={search} submitSearch={submitSearch}
                  />
                </div>
              </div>
              <div className="container-fluide search-cat-list">
                <div className="container">
                  <div className="scroll-box">
                  <div
                    className={`item ${!currentJobCategory ? "active" : ""}`}
                    onClick={() => handleCategory(null)}
                  >
                    All{" "}
                  </div>

                  {" "}
                 
                  {catList?.map((item) => (
                    <div
                      className={`item ${currentJobCategory == item?.id ? "active" : ""
                        }`}
                      onClick={() => handleCategory(item?.id)}
                    >
                      {item?.catName}{" "}
                    </div>
                  ))}
                  {" "}
                  </div>
                </div>
                {" "}
              </div>
              {" "}
            </Fragment>
          )}
          {" "}
          
          <div className="container">
            <div className="row">
              <div className="col-3 order">
                {jobId && <PopularJob popularJobList={popularJob} />}
                {!jobId && (
                  <Filters
                    setFilter={setFilter}
                    filter={filter}
                    pageData={pageData}
                    currentJobCategory={currentJobCategory}
                    setCurrentJobCategory={setCurrentJobCategory}
                    getJobLists={getJobLists}
                    search={search}
                    setPageData={setPageData}
                  />
                )}
                <FeatTech />{" "}
              </div>
              {" "}
              <div className="col-9">
                {" "}
                <div className="job-card-holder" id="jobs">
                  {" "}
                  {!jobId &&
                    jobCatList?.data
                      ?.filter((item) => item?._id == currentJobCategory)
                      .map((item) => (
                        <div>
                          <h3>{item?.categoryName}</h3>{" "}
                          <div className="grid-margin">
                            <div className="row">
                              <div className="col-9">
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  {!jobId && <MainBox mainJobList={jobByCatList} appliedArr={appliedArr} fetchMoreData={fetchMoreData} />}
                  {jobId && (
                    <DetailBox
                      currentCat={currentJobCategory}
                      catList={catList}
                      jobDetail={jobByIdDetail?.data}
                      appliedArr={appliedArr}
                      isJobLoading={jobByJobIdLoading}
                    />
                  )}
                </div>
                {/* <Trending />{" "} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BaseComponent;
