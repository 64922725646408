import { Fragment } from "react";
import ShortlistedDeveloper from "../../client/shortlisted/ShortListed";

const SavedProfile = ({ }) => {
  return (
    <Fragment>
        <ShortlistedDeveloper />
    </Fragment>
  );
};

export default SavedProfile;
