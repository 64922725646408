import React, { Fragment, useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ClipLoader, ScaleLoader } from "react-spinners";
import {
    getAllPerkAndBenefit,
    deleteTool,
    updateTool,
    addTool,
    uploadIcons,
    savePerkAndBenefit,
    deletePerkAndBenefit,
    updatePerkAndBenefit,
} from "../../../service/action/admin";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/modal/ModalBox";
import { Icon } from "../../../components/icon";
import { olibrToast } from "../../../components/toaster";
import { useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import { MainContext } from "../../../context/MainContext";
import { checkPermission, readOnlyCheck } from "../../../components/functions";

const PerkBenefit = () => {
    const dispatch = useDispatch();
    const {user:{access}}=useContext(MainContext)
    const readOnly=readOnlyCheck(access,"Perks and Benifits")
    const {
        register: perkRegister,
        handleSubmit: perkSubmit,
        setValue: setPerkValue,
        getValues: getPerkValue,
        control: perkControl,
        reset: resetPerkForm,
        formState: { errors: perkError },
    } = useForm();
    const {
        isIconUploading,
        uploadIcon,
        perkAndBenefit,
    } = useSelector((state) => state.adminReducer);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const uploadIconRef = useRef();

    const [pageData, setPageData] = useState({
        page: 1,
        limit: 20,
        search: "",
    });
    const handleSearch = (e) => {
        dispatch(
            getAllPerkAndBenefit(navigate, { ...pageData, page: 1, search: e.target.value })
        );
        setPageData({ ...pageData, search: e.target.value });
    };
    useEffect(() => {
        dispatch(getAllPerkAndBenefit(navigate, pageData));
    }, [pageData.limit, dispatch, navigate]);

    const fetchMoreData = () => {
        setPageData({
            ...pageData,
            limit: pageData?.limit + 10
        })
    }

    const closeModal = () => {
        resetPerkForm();
        setOpen(false);
        setIsEdit(false);
        setPerkObj({
            perkName: "",
            iconUrl: "",
        });
    };


    const openEditModal = () => {
        setIsEdit(true);
        setOpen(true);
    };

    const [perkObj, setPerkObj] = useState({
        perkName: "",
        iconUrl: "",
    });

    const handleEditTool = (item) => {
        openEditModal();
        resetPerkForm();
        setPerkObj({
            id: item._id,
            iconUrl: item?.iconUrl,
        });
        setPerkValue("perkName", item?.perkName)
       
    };

    useEffect(() => {
        uploadIcon && Object.keys(uploadIcon).length > 0 &&
            setPerkObj({
                ...perkObj,
                iconUrl: uploadIcon?.icons?.[uploadIcon?.icons?.length - 1],
            });
    }, [uploadIcon]);

    const handleContent = () => {
        return (
            <form>
                <div className="form-group">
                    <label htmlFor="" className={`floating-label ${perkError?.perkName && "text-danger"}`}>
                        Perk Name*
                    </label>
                    <input
                        name="perkName"
                        {
                        ...perkRegister("perkName", {
                            required: true
                        })
                        }
                        type="text"
                        className="form-control"
                    />
                </div>

                <div className="row-flex text-center">
                    <input
                        ref={uploadIconRef}
                        type="file"
                        onChange={onUploadIcon}
                        className="input-file"
                        accept=".png, .jpeg, .jpg, .svg"
                    />
                    <a
                        className="file-icon"
                        onClick={() => uploadIconRef.current.click()}
                    >
                        {isIconUploading ? (
                            <ScaleLoader color="#3BA0AA" height={10} />
                        ) : (
                            "Upload icon"
                        )}
                    </a>
                    <img src={perkObj?.iconUrl}
                        height={30}
                    />
                    {/* <div className="form-group"> */}

                    {/* <img
                            src="https://olibr.s3.ap-south-1.amazonaws.com/resume/aa087db9ea2c07bf1664798600447.svg"
                            height={30}
                        /> */}
                    {/* <label htmlFor="" className="floating-label">
                            Choose icon
                        </label> */}
                    {/* </div> */}



                </div>
            </form>
        );
    };

    const onUploadIcon = async (e) => {
        let formData = new FormData();
        formData.append("imageFile", e.target.files[0]);
        if (e.target.files[0]?.size > 1024 * 1024) {
            olibrToast.error("Please use image of size less than 1MB");
        }
        else {
            dispatch(uploadIcons(formData, navigate));
        }
        e.target.value = null;
    };

    const addPerkBenefit = (data) => {
        const newObj = {
            perkName:data?.perkName,
            iconUrl:perkObj?.iconUrl

        }
        isEdit
            ? dispatch(updatePerkAndBenefit(navigate, newObj,perkObj?.id, closeModal))
            : dispatch(savePerkAndBenefit(navigate, newObj, closeModal));
    };

    return (
        <Fragment>
            <Modal
                title={isEdit ? "Edit Perk and Benefit" : "Add Perk and Benefit"}
                open={open}
                onDone={perkSubmit(addPerkBenefit)}
                onCancel={closeModal}
                onClose={closeModal}
                content={handleContent}
            />
            <div className="skills-management">
                <div className="block-header">
                    <h4 className="">Perk and Benefit</h4>
                    <span
                        onClick={() => {
                            if (checkPermission(readOnly)) return false
                            setOpen(true);
                        }}
                    >
                        {" "}
                        <Icon name="add-icon" />{" "}
                    </span>
                </div>

                {/* <div className="row">
                    <div className="col-8"></div>
                    <div className="search-block col-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search Tool"
                            value={pageData.search}
                            onChange={handleSearch}
                        />
                        <a className="search-btn">
                            <Icon name="search-icon" />
                        </a>
                    </div>
                </div> */}
                <br />
                {/* <InfiniteScroll
                    dataLength={
                        perkAndBenefit ? perkAndBenefit?.length : 0
                    }
                    next={fetchMoreData}
                    height={"40vh"}
                    hasMore={
                        perkAndBenefit?.totalDataCount <= perkAndBenefit?.length
                            ? false
                            : true
                    }
                    loader={
                        isLoadingAllTools && (
                            <div style={{ textAlign: "center" }}>
                                {" "}
                                <ClipLoader color="#3BA0AA" />{" "}
                            </div>
                        )
                    }
                > */}
                <div className="table-div">
                    <div style={{ display: "flex", flexWrap: "wrap" }}>

                        {

                            Array.isArray(perkAndBenefit) && perkAndBenefit?.map((item, index) => (
                                <Fragment key={index}>
                                    <li
                                        className="test-list"
                                        onClick={() =>{
                                            if (checkPermission(readOnly)) return false
                                             handleEditTool(item)}}
                                    >
                                        <div className="test-card grey">
                                            <div className="skill-img">
                                                <figure>
                                                    <img src={item?.iconUrl} width={"30px"} alt="" />
                                                </figure>
                                            </div>
                                            <div className="skill-detail">
                                                <h6 title={"title"}>{item?.perkName}</h6>
                                                <a
                                                    className="link"
                                                    onClick={(e) => {
                                                        if (checkPermission(readOnly)) return false
                                                        e.stopPropagation();
                                                        dispatch(
                                                            deletePerkAndBenefit(navigate, item._id)
                                                        );
                                                    }}
                                                >
                                                    Remove
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </Fragment>
                            ))}
                        {Array.isArray(perkAndBenefit) && perkAndBenefit?.length > 0 ? (
                            ""
                        ) : (
                            <tr><p>No Perk & Benefit found...</p></tr>
                        )}

                    </div>
                </div>
                {/* </InfiniteScroll> */}
            </div>
        </Fragment>
    );
};

export default PerkBenefit;
