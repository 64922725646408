import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import { MainContext } from "../../../context/MainContext";
import { debounce } from "lodash";
import { olibrToast } from "../../../components/toaster";
import { Icon } from "../../../components/icon";
import Modal from "../../../components/modal/ModalBox";
import Dashboard from "../profile-view/dashboard";
import { useDispatch, useSelector } from "react-redux";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  getDeveloperLists,
  getDeveloperProfile,
  getAdminStackLists,
  getAdminSkillLists,
  updateProfileStatus,
  updateSpotLightStatus,
  searchStartAction,
  deleteDevProfile,
  fresherAction,
} from "../../../service/action/admin";
import ProfileUpdate from "./profileUpdate";
import "./devManagement.css";
import { Helmet } from "react-helmet-async";
import SkillResult from "../../../components/skilltest-result/SkillResult";
import DevLists from "./DevLists";
import SelectOption from "../../../components/select/SelectOption";
import {
  devVideoStatus,
  profTestFilter,
  profileStatusFilter,
  profileStepCompFilter,
  skillTestFilter,
} from "../../../common/constant";
import OlibrDatePicker from "../../../components/olibr-datepicker";
import { getCity, getState } from "../../../service/action/common";
import {
  checkPermission,
  cityArrFunc,
  readOnlyCheck,
  stateArrFunc,
} from "../../../components/functions";
import CommentsHistory from "./CommentsHistory";
import Filters from "./Filters";

const DevManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    user,
    user: { access },
  } = useContext(MainContext);

  const [developerId, setDeveloperId] = useState(
    localStorage.getItem("developerId")
  );
  const [pageData, setPageData] = useState({
    page: 1,
    limit: 18,
    search: "",
  });
  const {
    devListLoading,
    devLists,
    devUser,
    stackList,
    skillList,
    isDeleteProfile,
  } = useSelector((state) => state.adminReducer);

  const { stateList, cityList } = useSelector((state) => state.commonReducer);

  const [feedBack, setFeedback] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);
  const [profileUpdateOpen, setProfileUpdateOpen] = useState(false);
  const [commentsHistoryView, setCommentsHistoryView] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [isFilterApply, setFilterApply] = useState(false);
  const [devProfileModal, setDevProfileModal] = useState(false);
  const [isReachedOnFirst, setIsReachedOnFirst] = useState(false);
  const [isReachedOnLast, setIsReachedOnLast] = useState(false);
  const [skillTestResult, setSkillTestResult] = useState({
    devId: "",
    modal: false,
  });
  const [devDetals, setDevDetails] = useState({
    modal: false,
    devId: "",
  });
  const [filter, setFilter] = useState({
    fName: "",
    lName: "",
    email: "",
    status: "all",
    devDesg: "",
    devStepCompleted: "",
    devEnglishTest: [],
    devCity: "",
    devTechTestResult: "",
    devAvlFrom: "",
    devCountryCode: "",
    devState: "",
    stackLists: [],
    devChooseSkills: [],
    chooseSkillNames: [],
    stackListNames: [],
    devSkills: [],
    registrationDate: "",
    date_from: "",
    date_to: "",
    date_test_from: "",
    date_test_to: "",
    city: "",
    state: "",
    // devVideo: "",
    // fromExpRange: "",
    // toExpRange: "",
  });

  const cleanObj = (obj) => {
    return new Promise(function (resolve, reject) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName]?.length < 1
        ) {
          delete obj[propName];
        }
      }
      resolve(obj);
    });
  };

  const checkValidation = async (filterData) => {
    const cleanFilterValue = await cleanObj(filterData);
    return (
      Boolean(cleanFilterValue?.fName) ||
      Boolean(cleanFilterValue?.lName) ||
      Boolean(cleanFilterValue?.email) ||
      Boolean(cleanFilterValue?.devDesg) ||
      Boolean(cleanFilterValue?.devStepCompleted || cleanFilterValue?.devStepCompleted ===0) ||
      Boolean(cleanFilterValue?.devEnglishTest) ||
      Boolean(cleanFilterValue?.devCity) ||
      Boolean(cleanFilterValue?.devTechTestResult) ||
      Boolean(cleanFilterValue?.devAvlFrom) ||
      Boolean(cleanFilterValue?.devCountryCode) ||
      Boolean(cleanFilterValue?.devState) ||
      Boolean(cleanFilterValue?.stackLists) ||
      Boolean(cleanFilterValue?.devChooseSkills) ||
      Boolean(cleanFilterValue?.devSkills) ||
      Boolean(cleanFilterValue?.registrationDate) ||
      Boolean(cleanFilterValue?.date_from) ||
      Boolean(cleanFilterValue?.date_to) ||
      Boolean(cleanFilterValue?.city) ||
      Boolean(cleanFilterValue?.state) ||
      Boolean(cleanFilterValue?.numberVerified) ||
      Boolean(cleanFilterValue?.devReview) ||
      // Boolean(cleanFilterValue?.fromExpRange) ||
      // Boolean(cleanFilterValue?.toExpRange) ||
      Boolean(cleanFilterValue?.status !== "all")
    );
  };

  const checkFilterApply = async () => {
    let checkFltr = await checkValidation(filter);
    setFilterApply(checkFltr);
  };
  useEffect(() => {
    checkFilterApply();
  });

  const fetchDevLists = async (
    filterData,
    limit,
    page,
    term = search,
    apiType
  ) => {
    const cleanFilterValue = await cleanObj(filterData);
    let boolVal = await checkValidation(filterData);
    dispatch(
      getDeveloperLists(
        cleanFilterValue,
        navigate,
        boolVal && limit == 0 ? 0 : limit,
        boolVal && page == 0 ? 0 : page,
        term,
        apiType
      )
    );
  };

  useEffect(() => {
    dispatch(
      getAdminSkillLists("profile", navigate, {
        page: 0,
        limit: 1000,
        search: "",
      })
    );
    dispatch(
      getAdminStackLists("stack", navigate, {
        page: 0,
        limit: 1000,
        search: "",
      })
    );
    checkSession();
    dispatch(getState({ countryCode: "IN" }, navigate));
  }, []);

  const checkSession = () => {
    if (!user) {
      navigate("/login");
    }
  };

  const [deleteAccess, setDeleAccess] = useState(false);
  const [exportDataAccess, setExportDataAccess] = useState(false);

  useEffect(() => {
    const isDeleteAccess = user?.access?.filter(
      (item) => item?.name == "DeleteDevProfile" && item?.status == true
    );
    const isExportDataAccess = user?.access?.filter(
      (item) => item?.name == "ExportDevData" && item?.status == true
    );

    if (isDeleteAccess?.length || user?.access?.length < 1) {
      setDeleAccess(true);
    }

    if (isExportDataAccess?.length || user?.access?.length < 1) {
      setExportDataAccess(true);
    }
  }, [user]);

  useEffect(() => {
    if (filter?.state) {
      dispatch(
        getCity({ countryCode: "IN", stateCode: filter?.state }, navigate)
      );
    }
  }, [filter?.state]);

  const handleDevInfo = (devId) => {
    setDeveloperId(devId);
    dispatch(getDeveloperProfile(devId, navigate, setDevProfileModal));
  };

  const [devIsFresher, setDevIsFresher] = useState(false);

  useEffect(() => {
    devUser?.[0]?.isFresher &&
      devUser?.[0]?.isFresher !== undefined &&
      devUser?.[0]?.isFresher !== "undefined"
      ? setDevIsFresher(true)
      : setDevIsFresher(false);
  }, [devUser?.[0]?._id]);

  const onFresherClick = (userId) => {
    dispatch(fresherAction(userId, navigate));
    setDevIsFresher(!devIsFresher);
  };

  useEffect(() => {
    const itemIndex = devLists?.data?.findIndex(
      (devItem) => devItem._id === devUser[0]?._id
    );

    if (itemIndex === 0) {
      !isReachedOnFirst && setIsReachedOnFirst(true);
    } else {
      isReachedOnFirst && setIsReachedOnFirst(false);
    }

    if (itemIndex === devLists?.data?.length - 1) {
      !isReachedOnLast && setIsReachedOnLast(true);
    } else {
      isReachedOnLast && setIsReachedOnLast(false);
    }
  }, [devUser[0]?._id]);

  const onPrevious = () => {
    if (!devListLoading) {
      const itemIndex = devLists?.data?.findIndex(
        (devItem) => devItem._id === devUser[0]?._id
      );
      if (itemIndex > 0) {
        handleDevInfo(devLists?.data?.[itemIndex - 1]?._id);
      }
    }
  };

  const onNext = () => {
    if (!devListLoading) {
      const itemIndex = devLists?.data?.findIndex(
        (devItem) => devItem._id === devUser[0]?._id
      );
      if (itemIndex < devLists?.data?.length - 1) {
        handleDevInfo(devLists?.data?.[itemIndex + 1]?._id);
      }
    }
  };

  const handleFilter = () => {
    let obj = { ...filter };
    fetchDevLists(obj, limit, 1, "", "filter");
    setFilterOpen(false);
  };

  const deleteConfirmation = (devId) => {
    setDevDetails({ devId, modal: true });
  };

  const cancelDelete = () => {
    setDevProfileModal(true);
    setDevDetails({});
  };

  const closeModal = () => {
    setDevProfileModal(false);
    setDevDetails({});
  };

  const deleteProfile = () => {
    dispatch(
      deleteDevProfile(
        navigate,
        devDetals?.devId,
        closeModal,
        filter,
        pageData,
        "dev"
      )
    );
  };

  const fetchMoreData = () => {
    // setLimit(limit + 10);
    fetchDevLists(filter, limit, page + 1);
    setPage(page + 1);
  };
  const handleApprovedOrReject = async (userName, devId, status) => {
    const feedbackData = await cleanObj({ devId, status, feedBack });
    if (feedbackData?.status === "rejected" && !feedbackData?.feedBack) {
      olibrToast.error("Feedback is required");
      return null;
    }
    dispatch(
      updateProfileStatus(
        userName,
        feedbackData,
        navigate,
        page,
        limit,
        filter,
        setDevProfileModal
      )
    );
    setFeedbackOpen(false);
    setFeedback("");
  };
  const [search, setSearch] = useState("");

  const handleContentDev = () => {
    return (
      <>
        <div className="modal-header-dev-man">
          {!!deleteAccess && !readOnlyCheck(access) && (
            <div data-tooltip={"Delete profile"} className="flex-start-button">
              <Icon
                name="delete"
                onClick={() => deleteConfirmation(devUser[0]?._id)}
              />
            </div>
          )}
          <div
            style={{
              textAlign: devUser?.[0]?.status === "pending" ? "left" : "center",
              width: devUser?.[0]?.status === "pending" ? "100%" : "",
            }}
          >
            <h4 className={`modal-title-dev-man`}>
              {devUser?.[0]?.fName
                ? `${devUser?.[0]?.fName} ${devUser?.[0]?.lName}`
                : "Developer's Profile"}
            </h4>
          </div>
          <div className="flex-right-box vh-center">
            <div className="vh-center-row ">
              <input
                type="checkbox"
                id="isFresher"
                name="isFresher"
                className="input-check"
                checked={devIsFresher}
                value={devUser?.[0]?.isFresher}
                onChange={() => onFresherClick(devUser?.[0]?._id)}
              />
              <label for="isFresher" style={{ marginBottom: "0px" }}>
                {" "}
                Fresher
              </label>
            </div>
            {devUser?.[0]?.feedBack?.length > 0 && (
              <button
                className="submitBtn themeButton"
                onClick={handleCommentsHistoryView}
              >
                View Comments
              </button>
            )}
            {devUser?.[0]?.status === "pending" && (
              <div className="bottom-button">
                <button
                  className="cancelBtn"
                  onClick={() => {
                    if (checkPermission(readOnlyCheck(access))) return false;
                    setDevProfileModal(false);
                    setFeedbackOpen(true);
                  }}
                >
                  Reject
                </button>
                <button
                  className="submitBtn themeButton"
                  onClick={() => {
                    if (checkPermission(readOnlyCheck(access))) return false;
                    handleApprovedOrReject(
                      `${devUser[0]?.fName} ${devUser[0]?.lName}`,
                      devUser[0]?._id,
                      "approved"
                    );
                  }}
                >
                  Approved
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="modal-body-dev-man" style={{}}>
          <Dashboard
            user={devUser}
            filterValue={filter}
            devIsFresher={devIsFresher}
            limit={limit}
            page={page}
          />
        </div>
      </>
    );
  };

  const handleContentFeedback = () => {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="floating-label">Feedback</label>
              <textarea
                type="text"
                rows={5}
                className="form-control"
                value={feedBack}
                onChange={(e) => setFeedback(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="bottom-button">
          <button
            className="cancelBtn"
            onClick={() => handleApprovedOrReject("", developerId, "rejected")}
          >
            Reject
          </button>
        </div>
        <br />
      </>
    );
  };

  const clearFilter = () => {
    setFilter({
      fName: "",
      lName: "",
      email: "",
      status: "all",
      devDesg: "",
      devStepCompleted: "",
      devEnglishTest: [],
      devCity: "",
      devTechTestResult: "",
      devAvlFrom: "",
      devCountryCode: "",
      devState: "",
      stackLists: [],
      devChooseSkills: [],
      devSkills: [],
      registrationDate: "",
      date_from: "",
      date_to: "",
      date_test_from: "",
      date_test_to: "",
      state: "",
      city: "",
      numberVerified: false,
      devReview: false,
      // fromExpRange: "",
      // toExpRange: "",
    });
    setPage(1);
    dispatch(
      getDeveloperLists({ status: "all" }, navigate, limit, 1, search, "filter")
    );
  };


  const handleCloseFilterOpen = () => {
    setFilterOpen(false);
  };

  const [requestedValue, setRequestedValue] = useState({});

  const handleCloseDevOpen = () => {
    setDevProfileModal(false);
    localStorage.removeItem("developerId");
    navigate("/admin/dev-management");
  };

  const profileUpdateValue = (approvalUpdates) => {
    setRequestedValue(approvalUpdates);
    setProfileUpdateOpen(!profileUpdateOpen);
  };

  const handleUpdateAndClose = () => {
    setProfileUpdateOpen(!profileUpdateOpen);
  };

  const handleCommentsHistoryView = () => {
    setCommentsHistoryView(!commentsHistoryView);
  };

  const handleCloseFeedbackOpen = () => {
    setFeedbackOpen(false);
  };

  //FOR PAGINATION
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(18);
  const updateSpotLight = (devId, spotLight, status) => {
    if (readOnlyCheck(access)) {
      olibrToast.info("You don't have access");
      return false;
    }
    if (status.toUpperCase() == "APPROVED") {
      dispatch(
        updateSpotLightStatus({ devId: devId, spotLight: spotLight }, navigate)
      );
    } else {
      olibrToast.error("Developer profile is not approved");
    }
  };

  useEffect(() => {
    fetchDevLists(filter, limit, page);
  }, []);

  const debounced = useRef(
    debounce((newSearchValue) => {
      setPage(1);
      fetchDevLists(filter, limit, 1, newSearchValue, "filter");
    }, 1000)
  );

  const onSearchChange = (event) => {
    setSearch(event.target.value);
    !devListLoading && dispatch(searchStartAction());
    debounced.current(event.target.value);
  };

  const handleImportExcel = () => {
    if (devLists?.data?.length > 0) {
      const json_data = devLists.data.map((item) => {
        return {
          name: item.name,
          number: item.number,
          email: item.email,
          devStatus: item.devStatus,
          spotLight: item.spotLight,
          devState: item.devState,
          devCity: item.devCity,
          registrationDate: new Date(
            item.registrationDate
          ).toLocaleDateString(),
          lastLogin:
            item.lastLogin && new Date(item.lastLogin).toLocaleDateString(),
          skillTestDirectClearedBy: item.skillTestDirectClearedBy,
          emailDirectVerifiedBy: item.emailDirectVerifiedBy,
          engTestDirectClearedBy: item.engTestDirectClearedBy,
        };
      });

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const ws = XLSX.utils.json_to_sheet(json_data);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "devData.xlsx");
    }
  };

  return (
    <Fragment>
      <Modal
        title={"Skill Test"}
        open={skillTestResult?.modal}
        onCancel={() => setSkillTestResult({ modal: false, devId: "" })}
        onDone={() => setSkillTestResult({ modal: false, devId: "" })}
        onClose={() => setSkillTestResult({ modal: false, devId: "" })}
        content={() => SkillResult(skillTestResult?.devId)}
      />
      <Helmet>
        <title>Admin Dashboard - Olibr.com</title>
      </Helmet>
      <div className="main-div-developer">
        <div className="skills-management">
          <div className="block-header flex-space-between-row">
            <h4 className="">Developers</h4>
            <div className="flex-row-item">
              <span>
                {isFilterApply === false ? (
                  ""
                ) : (
                  <a
                    className="subQues color-orange mr5"
                    onClick={() => clearFilter()}
                  >
                    REMOVE FILTERS
                  </a>
                )}
                <a className="subQues" onClick={() => setFilterOpen(true)}>
                  FILTERS
                </a>
              </span>
              {exportDataAccess && (
                <Icon name="excel" onClick={handleImportExcel} />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-8">
              <p>Total Records: ({devLists?.totalDataCount})</p>
            </div>
            <div className="search-block col-4">
              <input
                type="text"
                className="form-control"
                value={search}
                placeholder="Search Users"
                onChange={onSearchChange}
              />
              <a className="search-btn">
                <Icon name="search-icon" />
              </a>
            </div>
          </div>
          <br />

          <DevLists
            fetchMoreData={fetchMoreData}
            setSkillTestResult={setSkillTestResult}
            handleDevInfo={handleDevInfo}
            profileUpdateValue={profileUpdateValue}
            updateSpotLight={updateSpotLight}
          />

          <Modal
            open={feedbackOpen}
            title={"Feedback"}
            onClose={handleCloseFeedbackOpen}
            isFooterView={false}
            content={handleContentFeedback}
          />
          <Modal
            open={devProfileModal}
            size={"100%"}
            isPreNext={true}
            onPrevious={onPrevious}
            onNext={onNext}
            isReachedOnFirst={isReachedOnFirst}
            isReachedOnLast={isReachedOnLast}
            onClose={handleCloseDevOpen}
            isFooterView={false}
            content={handleContentDev}
          />
          <Modal
            open={devDetals?.modal}
            title={"Delete Profile"}
            onClose={cancelDelete}
            onDelete={() => deleteProfile()}
            isDeleteLoading={isDeleteProfile}
            content={() => (
              <p>Are you sure you want to delete developer profile?</p>
            )}
            onCancel={cancelDelete}
            confirmDelete={true}
            isFooterView={true}
          />
          <Modal
            open={filterOpen}
            title={"Filter"}
            onClose={handleCloseFilterOpen}
            isFooterView={false}
            content={()=>
              <Filters
              clearFilter={clearFilter}
              setFilterOpen={setFilterOpen}
              handleFilter={handleFilter}
              setFilter={setFilter}
              filter={filter}
              cityList={cityList}
              stateList={stateList}
              skillTestFilter={skillTestFilter}
              skillList={skillList}
              stackList={stackList}
              profTestFilter={profTestFilter}
              profileStatusFilter={profileStatusFilter}
              profileStepCompFilter={profileStepCompFilter}
            />
            }
          />
          <Modal
            open={profileUpdateOpen}
            title={"Profile Updates"}
            onClose={handleUpdateAndClose}
            isFooterView={false}
            content={() => (
              <ProfileUpdate
                approvalUpdates={requestedValue}
                onClose={handleUpdateAndClose}
              />
            )}
          />
          <Modal
            open={commentsHistoryView}
            title={
              devUser?.[0]?.fName
                ? `${devUser?.[0]?.fName} ${devUser?.[0]?.lName}`
                : "Comments History"
            }
            onClose={handleCommentsHistoryView}
            isFooterView={false}
            content={() => <CommentsHistory data={devUser?.[0]?.feedBack} />}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default DevManagement;
