import { Fragment } from "react";
import { useSelector } from "react-redux";

const PerksAndBenefits = ({ data }) => {
  const { perkAndBenefit } = useSelector((state) => state?.adminReducer);

  const checkPerk = (id) => {
    const item = perkAndBenefit?.find((itm) => itm?._id == id);
    return item?.perkName && (
      <li>
        <figure>
          <img src={item?.iconUrl} alt="" />
        </figure>
        <h4>{item?.perkName}</h4>
      </li>
    );
  };

  return (
    <Fragment>
      {!!data?.perks?.length && (
        <section className="section-benifits" id="features">
          <h2>
            <span className="section-category">perks &amp; benefits</span>
          </h2>
          <div className="benifits">
            <ul>{data?.perks?.map((item) => checkPerk(item))}</ul>
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default PerksAndBenefits;
