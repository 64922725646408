import React, { useEffect, useState } from "react";

 

export const SVG = (props) => {
  const [svg, setSvg] = useState(null);
  useEffect(() => {
    fetch(props?.url)
      .then((res) => res.text())
      .then(setSvg)
  }, [props.url]);
  return (
    <div
      className={props?.className}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  );

};

 