import React from "react";
import { Icon } from "../../components/icon";

const CannotRate = ({status}) => {

    const obj = {
        deleted : "The review request has been deleted by the User",
        done: "Feedback Already Provided"
    }

  return (
    <div className="loading-div">
    <div className="loading-box">
      <div className="unsub-noti">
        {status === "done" && (<Icon name={"rating-done"} />)}
        <p> {obj[String(status)]}</p>
      </div>
    </div>
    </div>
  );
};

export default CannotRate;
