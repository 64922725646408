import React, { useEffect } from "react";
import Modal from "../../../components/modal/ModalBox";
import TwoPlansModal from "../../client/subscription/TwoPlansModal";
import OnePlanModal from "../../client/subscription/OnePlanModal";
import { getSubscriptionSummary } from "../../../service/action/client";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkFreePlan } from "../../../components/functions";

const PricingModal = ({ openModal, setModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subscriptionSummary } = useSelector((state) => state.clientReducer);
  useEffect(() => {
    dispatch(getSubscriptionSummary(navigate));
  }, [dispatch,navigate]);

  return (
    <Modal
      open={openModal}
      hasHeader={true}
      content={() =>
        (checkFreePlan(subscriptionSummary?.planName) || subscriptionSummary?.isCanceled === "Cancelled")?(
          <TwoPlansModal />
        ) : (
          <OnePlanModal />
        )
      }
      isCloseAble={true}
      onClose={() => setModal(false)}
      size="90%"
      isFooterView={false}
    />
  );
};

export default PricingModal;
