import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Icon } from "../../../../components/icon";
import Modal from "../../../../components/modal/ModalBox";
import { useDispatch, useSelector } from "react-redux";
import { getAllFaqGroup } from "../../../../service/action/common/index";
import { useContext } from "react";
import { MainContext } from "../../../../context/MainContext";
import {
  checkPermission,
  readOnlyCheck,
} from "../../../../components/functions";
import { getUser } from "../../../../service/action/authentication";
import AddFaqForm from "../faq-common/AddFaq";
import AddGroupCategory from "../faq-common/AddGroupCategory";
import FaqCommon from "../faq-common";

const FaqTabs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getFaqList = (faqFor) => {
    dispatch(getAllFaqGroup({ faqFor: faqFor, userType: "Admin" }, navigate));
  };
  useEffect(() => {
    dispatch(getUser(navigate));
    getFaqList("Developer");
    getFaqList("Client");
    getFaqList("Pricing");
  }, []);

  const {
    user: { access },
  } = useContext(MainContext);
  const readOnly = readOnlyCheck(access, "FAQs");
  const { faqGroupLists } = useSelector((state) => state.commonReducer);

  const [faqModal, setFaqModal] = useState(false);
  const [faqGroupModal, setFaqGroupModal] = useState(false);

  const [faqTab, setfaqTab] = useState("developer");
  const [faqForType, setFaqForType] = useState("Developer");

  const [faqGroupListResDev, setFaqGroupListResDev] = useState([]);
  const [faqGroupListResClient, setFaqGroupListResClient] = useState([]);
  const [faqGroupListResPricing, setFaqGroupListResPricing] = useState([]);

  function closeAddFaqGroupModal() {
    setFaqGroupModal(false);
  }
  const handleClick = (tabName) => {
    setfaqTab(tabName);
  };
  useEffect(() => {
    if (faqGroupLists && Object.keys(faqGroupLists).length > 0) {
      if (
        faqGroupLists.developer &&
        Object.keys(faqGroupLists.developer).length > 0
      ) {
        setFaqGroupListResDev(faqGroupLists.developer.data);
      }
      if (
        faqGroupLists.client &&
        Object.keys(faqGroupLists.client).length > 0
      ) {
        setFaqGroupListResClient(faqGroupLists.client.data);
      }
      if (
        faqGroupLists.pricing &&
        Object.keys(faqGroupLists.pricing).length > 0
      ) {
        setFaqGroupListResPricing(faqGroupLists.pricing.data);
      }
    }
  }, [faqGroupLists]);

  useEffect(() => {
    setFaqGroupListResClient(faqGroupListResClient);
    setFaqGroupListResDev(faqGroupListResDev);
    setFaqGroupListResPricing(faqGroupListResPricing);
  }, [faqGroupListResClient, faqGroupListResDev, faqGroupListResPricing]);

  return (
    <>
      <div className="main-div-tab">
        <div
          style={{ display: faqModal ? "block" : "none" }}
          className="toggleAddFaq"
        >
          <Modal
            open={faqGroupModal}
            title={"Add FAQ Group"}
            onClose={closeAddFaqGroupModal}
            isFooterView={false}
            content={() => (
              <AddGroupCategory
                setFaqGroupModal={setFaqGroupModal}
                closeAddFaqGroupModal={closeAddFaqGroupModal}
              />
            )}
          />
          <AddFaqForm
            setFaqModal={setFaqModal}
            setFaqGroupModal={setFaqGroupModal}
            faqForType={faqForType}
            setFaqForType={setFaqForType}
            faqGroupListResDev={faqGroupListResDev}
            faqGroupListResClient={faqGroupListResClient}
            faqGroupListResPricing={faqGroupListResPricing}
            getFaqList={getFaqList}
          />
        </div>
        <div
          className="main-faq-list"
          style={{ display: !faqModal ? "block" : "none" }}
        >
          <div className="tabContainer">
            <button
              className={faqTab == "developer" ? "tabBtn-active" : "tabBtn"}
              onClick={() => handleClick("developer")}
            >
              For Developers
            </button>
            <button
              className={faqTab == "client" ? "tabBtn-active" : "tabBtn"}
              onClick={() => handleClick("client")}
            >
              For Clients
            </button>
            <button
              className={faqTab == "pricing" ? "tabBtn-active" : "tabBtn"}
              onClick={() => handleClick("pricing")}
            >
              For Pricing
            </button>
          </div>
          <div className="faq-management">
            <br />
            <div className="block-header">
              <h4 className="">FAQs</h4>
              <span
                onClick={() => {
                  if (checkPermission(readOnly)) return false;
                  // resetFaqForm();
                  setFaqModal(true);
                }}
              >
                <Icon name="add-icon" />
              </span>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="form-group"></div>
              </div>
            </div>
          </div>
          <div>
            {faqTab == "developer" && (
              <FaqCommon
                getFaqList={getFaqList}
                faqFor="Developer"
                FAQ={faqGroupListResDev}
              />
            )}
            {faqTab == "client" && (
              <FaqCommon
                getFaqList={getFaqList}
                faqFor="Client"
                FAQ={faqGroupListResClient}
              />
            )}
            {faqTab == "pricing" && (
              <FaqCommon
                getFaqList={getFaqList}
                faqFor="Pricing"
                FAQ={faqGroupListResPricing}
              />
            )}
          </div>
          <br />
          <br />
        </div>
      </div>
    </>
  );
};

export default FaqTabs;
