import React, { useContext, useEffect, useState } from "react";
import OlibrFlagDropDown from "../../../components/dropdown/olibrFlagDropdown";
import "../../pricing-page/pricing-page.css";
import "./subscription.css";
import {
  CountryFlagOption,
  formatIndianNumber,
  percentageMinus,
  returnFetureList,
} from "../../../components/functions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getFeatureAction } from "../../../service/action/admin";
import {
  getPrincingPlanAction,
  paymentCheckOuActiont,
  startPaymentPhonePay,
  startPaymentStripe,
} from "../../../service/action/common";
import AddOns from "../../../components/pricing/AddOns";
import { MainContext } from "../../../context/MainContext";
import { olibrToast } from "../../../components/toaster";

const OnePlanModal = () => {
  const {user}=useContext(MainContext)
  const [countryCode, setCountryCode] = useState("IN");
  const [isAnnualPlan, setAnnualPlan] = useState(true);
  const [planList, setPlanList] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const states = ["pricing-tabs-active", "pricing-tabs-inactive"];
  const [pricingTab, setpricingTab] = useState(true);
  const { allPlanList } = useSelector((state) => state.commonReducer);
  const { fetureList } = useSelector((state) => state.adminReducer);
  const [addOnCheckLists, setAddOnCheckedLists] = useState([]);
  const [addOnData, setAddOnData] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});

  const handleClick = () => {
    setAnnualPlan(!isAnnualPlan);
    setpricingTab(!pricingTab);
  };
  const changeCountry = (value) => {
    setAddOnData([]);
    setSelectedPlan({});
    setAddOnCheckedLists([]);
    setCountryCode(value);
  };
  useEffect(() => {
    dispatch(getFeatureAction(navigate));
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(
      getPrincingPlanAction(navigate, {
        excludeActive: true,
        country: countryCode,
        planType: isAnnualPlan ? "Annual" : "Monthly",
      })
    );
  }, [dispatch, navigate, countryCode, isAnnualPlan]);

  useEffect(() => {
    if (allPlanList?.length) {
      setPlanList(allPlanList[0]);
      setSelectedPlan(allPlanList[0]);
    }
  }, [allPlanList]);


  const handleBuy = () => {
    let obj = { subscriptionPlanId: selectedPlan?._id, addons: addOnData };
    const callBackCheckout = (res) => {
      olibrToast.success(res?.message);
        localStorage.setItem("checkout", JSON.stringify(res));
        navigate("/payment-overview");
     
    };
    dispatch(
      paymentCheckOuActiont({ navigate, data: obj, cb: callBackCheckout })
    );
  };

  useEffect(() => {
    if (user?.userType === "Client") {
      setCountryCode(user?.region);
    }
  }, [user]);

  return (
    <div className="one-plan-modal pricing-page">
      <div className="pricing-header">
        <div className="left-col">
          <h3>{planList?.name}</h3>
          <h4>
            {countryCode == "IN" ? "₹" : "$"}
            {parseInt(planList?.price) &&
            parseInt(planList?.discountPercentage) > 0 ? (
              <span>
                {" "}
                {planList?.billingInterval != "Annual"
                  ? formatIndianNumber(planList?.price)
                  : formatIndianNumber(parseInt(planList?.price / 12))}
              </span>
            ) : (
              <span></span>
            )}
            {parseInt(planList?.price) == 0 && <span>0</span>}

            {!!parseInt(planList?.price) > 0 && (
              <>
                <span className="big">
                  {percentageMinus(
                    planList?.price,
                    planList?.discountPercentage,
                    planList?.billingInterval
                  )}
                </span>
                /Month
              </>
            )}
          </h4>
        </div>
        <div className="right-col">
          <small>{planList?.description}</small>
          <br></br>
          {/* <small>*Save upto 20% in Annual Plan</small> */}
        </div>
      </div>

      <div className="row pricing-modal-top">
        <div className="col-6">
          <div className="buttonFor">
            <span
              className={`${
                states[pricingTab ? 0 : 1]
              } pricing-tab faqTabCursor`}
              onClick={handleClick}
            >
              Annual
            </span>
            <span
              className={`${
                states[!pricingTab ? 0 : 1]
              } pricing-tab faqTabCursor`}
              onClick={handleClick}
            >
              Monthly
            </span>
          </div>
        </div>
        <div className="col-3">
          <OlibrFlagDropDown
            options={CountryFlagOption("IN-US")}
            value={countryCode}
            onSelect={(value) => changeCountry(value)}
            defaultValue="IN"
            isIsoCode={false}
            disabled={user?.userType === "Client"}
          />
        </div>
      </div>

      <div className="pricing-body">
        <ul
          dangerouslySetInnerHTML={{
            __html: returnFetureList(planList, fetureList),
          }}
        ></ul>
      </div>

      <AddOns
        addOnCheckLists={addOnCheckLists}
        setAddOnCheckedLists={setAddOnCheckedLists}
        setAddOnData={setAddOnData}
        addOnData={addOnData}
        country={countryCode}
        selectedPlan={selectedPlan}
      />
    <p>Tax Description : <span>18% GST applicable</span></p>
      <button className="btn upgrade-plan" type="button" onClick={handleBuy}>
        Upgrade to Assisted Search
      </button>
    </div>
  );
};

export default OnePlanModal;
