import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ProfilePreviewCommon from '../../components/profile-preview/profilePreview'

const ResumeOpen = () => {
  const { devId } = useParams();
  const [id, setId] = useState(null);
  useEffect(() => {
    let x = devId.split("-");
    setId(x[0])
  }, [devId])
  return (
    <Fragment>
      <div className="wrapper">
        <div className="container">
          {id && (<ProfilePreviewCommon userId={null} openUserId={id} />)}
        </div>
      </div>
    </Fragment>
  )
}

export default ResumeOpen