import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { updateDevEnglishResult } from "../../../service/action/admin";

const EnglishTestClear = ({ HandleDropdwon,readOnly }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { devAdminEnglishResult, saveEnglishClearance } = useSelector(
    (state) => state.adminReducer
  );

  const {
    register: englishRegister,
    setValue: setEnglishValue,
    handleSubmit: englishSubmit,
    reset: resetEnglishForm,
    formState: { errors: englishError },
  } = useForm();
  useEffect(() => {
    devAdminEnglishResult &&
      setEnglishValue("name", devAdminEnglishResult?.devName || "");
    setEnglishValue("status", devAdminEnglishResult?.status || "");
    setEnglishValue("correct", devAdminEnglishResult?.correct || 0);
  }, [devAdminEnglishResult]);

  const onSubmit = (data) => {
    dispatch(
      updateDevEnglishResult(
        {
          email: data?.email,
        },
        navigate
      )
    );
  };
  useEffect(() => {
    resetEnglishForm();
  }, []);
  return (
    <form>
      <div className="container">
        <div className="create-profile">
          <div className="block-header">
            <h4>Clear English Test</h4>
          </div>
          <HandleDropdwon />
          <div className="row">
            <div className="col-6">
              <div className="">
                <label
                  className={`new-label ${
                    englishError?.email && "text-danger"
                  }`}
                >
                  Emails*
                </label>
                <input
                  type="text"
                  {...englishRegister("email", {
                    required: true,
                  })}
                  name="email"
                  placeholder="Developer Email"
                  readOnly={readOnly}
                />
              </div>
              <br />
            </div>
            <div className="col-6">
              <div className="">
                <label className="new-label">Status</label>
                <input
                  type="text"
                  {...englishRegister("status")}
                  disabled={true}
                  value={"Passed"}
                  placeholder="Pass"
                />
              </div>
              <br />
            </div>
            <div className="col-12 text-right">
              <button
                className="submitBtn themeButton min-width177"
                disabled={saveEnglishClearance || readOnly}
                onClick={englishSubmit(onSubmit)}
              >
                {saveEnglishClearance ? (
                  <ClipLoader size={15} color="#FFFFFF" />
                ) : (
                  "Update Result"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EnglishTestClear;
