import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getInterviewListAction } from "../../../service/action/admin";

const InterviewList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { interviewListReducer } = useSelector((state) => state.adminReducer);

  useEffect(() => {
    dispatch(getInterviewListAction(navigate, { limit: 100, offset: 0 }));
  }, [navigate, dispatch]);

  useEffect(() => {
    console.log(interviewListReducer);
  }, [interviewListReducer]);

  const obj = {
    new: "NEW",
    ended: "COMPLETED",
    active: "ON GOING",
    paused: "PAUSED",
  };

  const resultStatus = (status) => {
    return status === null ? "RESULT PENDING" : status ? "PASSED" : "FAILED";
  };

  return (
    <Fragment>
      <div className="main-div-developer">
        <div className="create-profile">
          <div className="block-header">
            <h4 className="">Interview List</h4>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th className="column0">S.No</th>
                <th className="column1">TITLE</th>
                <th className="column1">DEV EMAIL</th>
                <th className="column1">DEV NAME</th>
                <th className="column1">INTERVIEWERS</th>
                <th className="column1">REPORT</th>
                <th className="column1">RESULT STATUS</th>
                <th className="column1">STATUS</th>
              </tr>
            </thead>
            <tbody>
              {interviewListReducer?.data?.data?.map((item, i) => (
                <Fragment key={item?.id}>
                  <tr className="faqdiv">
                    <td className="subQues sno">{i + 1}</td>
                    <td className="subQues">{item?.title}</td>
                    <td className="subQues">{item?.candidate?.email}</td>
                    <td className="subQues">{item?.candidate?.name}</td>
                    <td className="subQues">
                      {item?.interviewers?.map((interviewer) => (
                        <button
                          //   disabled
                          className="skillname stack-skillname"
                          key={interviewer?.email}
                        >
                          <span>{interviewer?.email}</span>
                        </button>
                      ))}
                    </td>
                    <td className="subQues">
                      <a
                        href={item?.report_url}
                        target="_blank"
                        className="link"
                      >
                        SEE REPORT
                      </a>
                    </td>
                    <td className="postedOn">
                      {resultStatus(item?.thumbs_up)}
                    </td>
                    <td className="column4">{obj[`${item?.status}`]}</td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export default InterviewList;
