import React, { useState } from 'react'

const CustomIframe = ({
  children,
  ...props
}) => {
  const [contentRef, setContentRef] = useState(null)

  return (
    <iframe {...props} ref={setContentRef}/>
  )
}

export default CustomIframe;