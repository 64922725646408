import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { frequentlyAsked } from '../../common/constant/homeClient'
import AccordionMenu from '../developer/home/Accordion'

const FaqSectionContent = ({accordionData}) => {
  const [titleOpen,setTitleOpen]=useState("");
  return (
    <div className="container">
            <div className="faq__text">
              <h2 className="faq__title">{frequentlyAsked}</h2>
              <div className="accordion">
                {accordionData?.map(({ faqQues, faqAnswer,oneFaqStatus }) => oneFaqStatus && (
                  <AccordionMenu
                    title={faqQues}
                    content={faqAnswer}
                    setTitleOpen={setTitleOpen}
                    titleOpen={titleOpen}

                  />
                ))}
              </div>
              <div className="btn-block">
                <Link to="/faqs" className="link">
                  VIEW MORE FAQs
                </Link>
              </div>
            </div>
            <div className="faq__img">
              <figure>
                <img
                  src="https://ik.imagekit.io/olibr/assets/tr:w-418,h-347/faq.webp"
                  alt="Are You Looking To Work With Top US Companies? "
                  loading="lazy"
                />
              </figure>
            </div>
          </div>
  )
}

export default FaqSectionContent