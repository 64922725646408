import React, { useEffect, useState } from 'react'
import AddDevSkills from './AddDevSkills'
import { filterSkill } from '../functions';
import { useDispatch, useSelector } from 'react-redux';
import { addDevSkillAction } from '../../service/action/common';
import { getDeveloperProfile } from '../../service/action/admin';
import { useNavigate } from 'react-router-dom';

const SelectCustomSkill = ({nextBtnRef,handleActiveStatus}) => {
    const dispatch = useDispatch();
    const navigate=useNavigate()
    const [userData,setUserData]=useState({})

    const [selectedSkill,setSelectedSkill]=useState([])
    const [errors, setError] = useState([]);
    const [openHelpDrawer, setHelpDrawer] = useState(false);
    const {devUser}=useSelector((state)=>state.adminReducer)

    useEffect(()=>{
    if(devUser && devUser?.length){
      setUserData(devUser[0])
    }
    },[devUser])

  
    
  
    const submit = async () => {
      const callBack = () => {
        dispatch(getDeveloperProfile(userData?._id, navigate));
        handleActiveStatus(3);
      };

      const filteredData = filterSkill(selectedSkill);
      let tempError = [];
      for await (let [index, item] of filteredData.entries()) {
        if (!item?.skillId) {
          tempError.push({ index: index, keyName: "skillId" });
        }
        if (!item?.experiency) {
          tempError.push({ index: index, keyName: "experiency" });
        }
        if (!item?.proficiencyLevel) {
          tempError.push({ index: index, keyName: "proficiencyLevel" });
        }
        if (!item?.skillId) {
          tempError.push({ index: index, keyName: "skillId" });
        }
        if (!item?.lastUsed) {
          tempError.push({ index: index, keyName: "lastUsed" });
        }
  
      }
      if (tempError.length) {
        setError(tempError);
      } else {
        setError([]);
        dispatch(
          addDevSkillAction({ data: { payload: filteredData }, devId:userData?._id,cb: callBack })
        );
      }
    };
  return (
    <div>
        <AddDevSkills 
        userData={userData}
        errors={errors}
        selectedSkill={selectedSkill}
        setSelectedSkill={setSelectedSkill}
        setHelpDrawer={setHelpDrawer}
        openHelpDrawer={openHelpDrawer}
        />
        <button
         onClick={()=>submit()}
          title={"Submit"}
          type="button"
          ref={nextBtnRef}
        ></button>
    </div>
  )
}

export default SelectCustomSkill