import React, { Fragment } from "react";
import { langArr, logoArr } from "../jobListConst";
import { Icon } from "../../../../components/icon";

const FeatTech = () => {
  return (
    <Fragment>
      <div className="grid-margin">Featured Technologies</div>
      <div className="contact-info">
        <div className="grid-container-2">
          {langArr?.map((lang) => (
            <div class="item1">
              <Icon name={lang} />
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default FeatTech;
