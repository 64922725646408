import React, { useEffect, useState, useContext } from "react";
import { Icon } from "../../../components/icon";
import { Fragment } from "react";
import { MainContext } from "../../../context/MainContext";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import {
  getNotification,
  clearNotification,
  setNotificationData,
} from "../../../service/action/common";

const Notification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const { user } = useContext(MainContext);
  const { notificationData, socket, notiDeleting } = useSelector(
    (state) => state.commonReducer
  );

  useEffect(() => {
    // if (user?.userType === "Developer" || user?.userType === "Client") {
      dispatch(getNotification(navigate));
      socket?.emit("setup", user?._id);
    // }
  }, [user]);

  useEffect(() => {
    if (notificationData?.length < 1) {
      setNotificationOpen(false);
    }
  }, [notificationData]);

  useEffect(() => {
    //Job post real-time notification
    socket?.on("jobPostNoti", (data) => {
      data && dispatch(setNotificationData(data));
    });

    //Shortlisted real-time notification
    socket?.on("shortlistedNoti", (data) => {
      data && dispatch(setNotificationData(data));
    });

    //interview
    // For developer
    socket?.on("interviewSchedule", (data) => {
      data && dispatch(setNotificationData(data));
    });
    socket?.on("interviewRescheduleRequestAccept", (data) => {
      data && dispatch(setNotificationData(data));
    });
    socket?.on("interviewCancel", (data) => {
      data && dispatch(setNotificationData(data));
    });
    socket?.on("interviewReschedule", (data) => {
      data && dispatch(setNotificationData(data));
    });

    //For Client
    socket?.on("interviewRequestAccept", (data) => {
      data && dispatch(setNotificationData(data));
    });
    socket?.on("interviewDeclined", (data) => {
      data && dispatch(setNotificationData(data));
    });
    socket?.on("interviewRescheduleRequest", (data) => {
      data && dispatch(setNotificationData(data));
    });
  }, []);

  const onNotificationSeen = (notiId) => {
    const latest_noti_data = [...notificationData];
    const seenJobIndex = latest_noti_data?.findIndex(
      (item) => item?._id === notiId
    );
    if (seenJobIndex >= 0) {
      if (!latest_noti_data[seenJobIndex]?.isSeen) {
        latest_noti_data[seenJobIndex].isSeen = true;
        latest_noti_data?.length > 0 &&
          dispatch(setNotificationData(latest_noti_data));
        socket?.emit("notificationSeen", {
          notificationId: notiId,
          userId: user?._id,
        });
      }
      window.open(
        `${window.location.origin}/jobs/${latest_noti_data?.[seenJobIndex]?.jobId?.slug}`,
        "_blank"
      );
    }
  };

  const onNotificationClear = (event) => {
    event?.stopPropagation();
    dispatch(clearNotification(navigate));
  };

  const notificationTitle = (title) => {
    return title
      ?.replace(/\w+/g, function (txt) {
        // uppercase first letter and add rest unchanged
        return txt?.charAt(0)?.toUpperCase() + txt?.substr(1);
      })
      ?.replaceAll("-", " ");
  };

  return (
    <Fragment>
      {user?.userType === "Developer" || true ? (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          onClick={(e) => {
            e?.preventDefault();
            notificationData?.length > 0 &&
              setNotificationOpen(!isNotificationOpen);
          }}
          id="notification"
          href="#"
        >
          <span>
            <Icon name="bell-icon" />
            {notificationData?.filter((item) => !item?.isSeen)?.length > 0 && (
              <sup className="notification-number">
                <span className="circle">
                  {notificationData?.filter((item) => !item?.isSeen)?.length
                    ? notificationData?.filter((item) => !item?.isSeen)?.length
                    : 0}
                </span>
              </sup>
            )}
          </span>
          {isNotificationOpen && (
            <div className="noti-box">
              <div
                className="noti-header"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingRight: "10px",
                }}
              >
                <span>Notifications</span>
                <button
                  onClick={onNotificationClear}
                  style={{
                    backgroundColor: "#3ba0aa",
                    color: "#ffffff",
                    padding: "3px 5px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    minWidth: "50px",
                  }}
                >
                  {notiDeleting ? (
                    <ClipLoader size={12} color="#ffffff" />
                  ) : (
                    "Clear"
                  )}
                </button>
              </div>
              <ul className="p0">
                {notificationData?.map((item, index) => {
                  let notiTitle = item?.type;
                  switch (item?.type) {
                    case "shortlisted":
                      notiTitle = "Profile Shortlisted for";
                      break;
                    case "jobPost":
                      notiTitle = "New Job Alert";
                      break;
                    default:
                      notiTitle = notificationTitle(item?.type);
                  }
                  return (
                    <li
                      key={item?._id}
                      className={`${
                        !item?.isSeen ? "selected job-post-noti" : ""
                      } ${item?.isSeen ? "seen-noti" : `${item?.type}-noti`}`}
                      onClick={() => onNotificationSeen(item?._id)}
                    >
                      <strong>
                        {`${notiTitle} :`}
                        {item?.jobId?.jobTitle} / {item?.jobId?.jobType}
                        {!item?.isSeen && (
                          <span className="noti-dot">&#x2022;</span>
                        )}
                      </strong>
                      <span className="date">
                        {new Date().toDateString(item?.jobId?.postDate)}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </a>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Notification;
