import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getClientPaymentHistory } from "../../../service/action/client";
import DataTables from "../../../components/data-tables/DataTables";
import PaymentHistoryColumn from "./column/PaymentHistoryColumn";
import { useContext } from "react";
import { MainContext } from "../../../context/MainContext";

const PaymentDetails = ({clientId=""}) => {
  const { paymentHistory, isHistoryLoading } = useSelector(
    (state) => state.clientReducer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getClientPaymentHistory(navigate,clientId));
  }, [dispatch, navigate]);


 const {user}=useContext(MainContext)
  return (
    <div className="table-container">
      <table className="payment-details">
        <DataTables
          columns={PaymentHistoryColumn(user)}
          listData={paymentHistory}
          Infinit={true}
          // fetchMoreData={fetchMoreData}
          listLoading={isHistoryLoading}
        />
      </table>
    </div>
  );
};

export default PaymentDetails;
