import * as React from "react";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

export default function CircularProgressResult({ result }) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: "#f1f1f9",
        }}
        size={120}
        thickness={2}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: "#1371f0",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={120}
        thickness={2}
        value={result?.percentageScore}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="#1967d2"
          fontSize={16}
          fontFamily={"Poppins"}
          fontWeight={500}
        >
          {/* {result?.obtainedScore.toFixed(1)} / {result?.maxScore} */}
          {result?.obtainedScore % 1 === 0
            ? result?.obtainedScore.toFixed(0)
            : result?.obtainedScore.toFixed(1)}{" "}
          / {result?.maxScore}
        </Typography>
      </Box>
    </Box>
  );
}
CircularProgressResult.propTypes = {
  result: PropTypes.any,
};
