import React from 'react'
import { EmployementStyle } from './css/EmployementStyle'
import EmployementIcon from "../../../assets/img/icons/certification-icon.png";
import { Image, Text, View } from '@react-pdf/renderer';
import { Educations } from './css/Educations';
import { Certification } from './css/Certification';
import { formDateDateYear } from '../../functions';
const Certifications = ({ data }) => {
    return (
        Array.isArray(data) && data?.length && (
            <View style={{ ...EmployementStyle.employetmentMain, marginTop: "20px" }}>
                <View style={EmployementStyle.emplLayoutLeft} >
                    <View>
                        <Image source={EmployementIcon} style={EmployementStyle.employementIcon} />
                    </View>
                    <View style={EmployementStyle.emplLayoutLists}>
                        <Text style={EmployementStyle.empHeading}> Certifications</Text>
                    </View>
                </View>
                <View>
                    {
                        data?.map((item)=>(
                            <View style={Certification.rightLayout}>
                            <View style={Educations.left}>
                                <Text>{item?.certificateName}</Text>
                                <Text style={Educations.gray}>{item?.certInstitution}</Text>
                            </View>
                            <View style={Educations.right}>
                                <Text>{formDateDateYear(item?.startDate) } - {formDateDateYear(item?.endDate)}</Text>
                            </View>
                        </View>
                        ))
                    }
                 
                </View>
            </View>
        )
    )
}

export default Certifications