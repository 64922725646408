import React from 'react'
import TestResultCard from './TestResultCard'
import { useSelector } from 'react-redux'
const VettedSkills = () => {
  const {resultDevData}=useSelector((state)=>state.adminReducer)
  const { skillResp, stackResp } = useSelector(
    (state) => state.developerReducer
  );
  const fetchIconByTestId = (iconId) => {
    let url = skillResp?.find((itm) => itm?._id == iconId) || stackResp?.find((itm) => itm?._id == iconId)
    return url = url?.skillTestIcon || url?.stackIcon
}

  return !!resultDevData?.data?.length && (
    <div className="sidebar-widget">
    <h4 className="widget-title">Vetted Skills</h4>
    <div className="widget-content">
        {
            resultDevData?.data?.map((item,id)=> item?.isPassed && (
                <TestResultCard fetchIconByTestId={fetchIconByTestId} result={item} key={id}/>
            ))
        }
     
    </div>
  </div>
  )
}
export default VettedSkills