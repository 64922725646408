import React, { Fragment } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import add from "../../../assets/img/icons/add_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { addEmailTemplate } from "../../../service/action/admin";
import { useEffect } from "react";
import { Icon } from "../../../components/icon";
import { freqNum } from "../../../common/constant/admin/emailAuto";
import { useForm } from "react-hook-form";

const AddTemplate = () => {
  const {
    register: templeteRegister,
    handleSubmit: templeteSubmit,
    reset: resetTempleteForm,
    getValues,
    watch,
    formState: { errors: templeteError },
  } = useForm();

  const { postTemplateList } = useSelector((state) => state.adminReducer);
  const [inputList, setInputList] = useState([{ freq: "" }]);
  const handleClose=()=>{
    resetTempleteForm();
    setInputList([{freq:""}])

  }
  const onHandleSubmit = (data) => {
    let arr = [];
    arr = inputList?.map((item) => item?.freq);
    dispatch(addEmailTemplate({ ...data, frequency: arr },navigate,handleClose));
  };

  useEffect(() => {
    resetTempleteForm();
  }, [])
  useEffect(() => {
    setPostObj({
      frequency: [],
    });
  }, [postTemplateList]);


  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navBack = () => {
    navigate("/admin/email-management");
  };

  const [postObj, setPostObj] = useState({
    frequency: []
  });

  watch("content");

  const handleInputChange = (data, index) => {
    const { name, value } = data.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const removeFreq = (i) => {
    let dummyArr = [...inputList];
    dummyArr.splice(i, 1);
    setInputList(dummyArr);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { freq: "" }]);
  };


  return (
    <form>
      <div className="toggleAddFaq">
        <div className="create-profile">
          <div className="block-header">
            <h4 className="">Add Template</h4>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="">
                <label className={`floating-label ${templeteError?.title && "text-danger"} `}>Template Title*</label>
                <input
                  name="title"
                  type="text"
                  className="form-control"
                  placeholder=""
                  {
                  ...templeteRegister("title", {
                    required: true
                  })
                  }
                />
              </div>
            </div>
            <div className="col-6">
              <div className="">
                <label className={`floating-label ${templeteError?.subject && "text-danger"} `}>Subject*</label>
                <input
                  name="subject"
                  type="text"
                  className="form-control"
                  placeholder=""
                  {
                  ...templeteRegister("subject", {
                    required: true
                  })
                  }
                />
              </div>
            </div>

            <div className="col-6">
              <div className="form-group">
                <label className={`floating-label ${templeteError?.slug && "text-danger"} `}>
                  Slug*
                </label>
                <input
                  name="slug"
                  type="text"
                  className="form-control"
                  {
                  ...templeteRegister("slug", {
                    required: true
                  })
                  }
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label className={`floating-label ${templeteError?.settingTitle && "text-danger"} `}>
                  Setting Title*
                </label>
                <input
                  name="settingTitle"
                  type="text"
                  className="form-control"
                  {
                  ...templeteRegister("settingTitle", {
                    required: true
                  })
                  }
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label className={`floating-label ${templeteError?.settingSubTitle && "text-danger"} `}>
                  Setting Sub-title*
                </label>
                <input
                  name="settingSubTitle"
                  type="text"
                  className="form-control"
                  {
                  ...templeteRegister("settingSubTitle", {
                    required: true
                  })
                  }
                />
              </div>
            </div>

            <div className="col-6">
              {inputList?.map((item, index) => (
                <div className="form-group" key={index + Math.random()}>
                  <label className="floating-label" htmlFor="">
                    Frequency {freqNum[index]} {"(days)"}
                  </label>
                  <div style={{ display: "flex" }}>
                    <input
                      name="freq"
                      type="number"
                      className="form-control"
                      onChange={(e) => handleInputChange(e, index)}
                      value={inputList[index].freq}
                    />
                    {index == inputList.length - 1 && (
                      <a onClick={handleAddClick}>
                        <img
                          style={{ height: "30px", width: "30px" }}
                          src={add}
                          alt=""
                        />
                      </a>
                    )}
                    {(index !== 0 || inputList.length !== 1) && (
                      <a onClick={() => removeFreq(index)}>
                        <Icon name="delete" />
                      </a>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div
              className="email col-12"
              dangerouslySetInnerHTML={{ __html: getValues("content") }}
            ></div>

            <div className=" col-12 FAQeditor">
              <label className={`floating-label ${templeteError?.content && "text-danger"} `}>
                Template*
              </label>
              <textarea
                rows={20}
                id=""
                name="content"
                {
                ...templeteRegister("content", {
                  required: true
                })
                }
              />
              <br />
              <div className="bottom-button text-right">
                <submit className="cancelBtn" type='button' onClick={navBack}>
                  cancel
                </submit>
                <submit
                  className="submitBtn themeButton"
                  onClick={templeteSubmit(onHandleSubmit)}
                >
                  submit
                </submit>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddTemplate;
