import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./editor.css";

const OlibrEditor = ({ value, onChange, placeholder, disabled = false }) => {
  const modules = {
    toolbar: [["bold", "italic", "underline"], [{ list: "bullet" }]],
  };

  return (
    <ReactQuill
      style={{ width: "100%", margin: "auto" }}
      theme="snow"
      modules={modules}
      value={value}
      placeholder={placeholder}
      scrollingContainer={"true"}
      disabled={disabled}
      onChange={onChange}
    />
  );
};

export default OlibrEditor;
