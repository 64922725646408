import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { rescheduleInterview } from "../../../service/action/client";
import { olibrToast } from "../../../components/toaster";
import { Icon } from "../../../components/icon";

export default function ScheduleInterview(props) {
  const formDataInit = {
    interviewLink: "",
    message: "",
    requiredAttendence: [],
    interviewData: [
      {
        id: 1,
        from: new Date(new Date().getTime() + 150 * 60000),
        to: new Date(new Date().getTime() + 180 * 60000),
      },
    ],
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { socket } = useSelector((state) => state.commonReducer);
  const { data, title } = props;
  const [formData, setFormData] = useState(formDataInit);
  const [interviewType, setInterviewType] = useState("regular");
  const [attendence, setAttendence] = useState();
  const [isReschedule, setIsReschedule] = useState(false);

  useEffect(() => {
    data?.status === "canceled"
      ? setIsReschedule(false)
      : setIsReschedule(true);
    setFormData(formDataInit);
  }, [data]);

  useEffect(()=>{
    setInterviewType(data?.interviewData?.interviewType || "regular")
  },[data?.interviewData?.interviewType])

  const addInterviewData = () => {
    let max = 0;
    formData.interviewData.map((i) => {
      if (i.id > max) max = i.id;
    });
    let copy = { ...formData };
    copy.interviewData.push({
      id: max + 1,
      from: new Date(
        new Date(formData.interviewData[max - 1].from).getTime() + 30 * 60000
      ),
      to: new Date(
        new Date(formData.interviewData[max - 1].to).getTime() + 30 * 60000
      ),
    });
    setFormData(copy);
  };

  const subtractInterviewData = (id) => {
    let copy = { ...formData };
    let interviewData = copy.interviewData.filter((i) => i.id != id);
    copy.interviewData = interviewData;
    setFormData(copy);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.message) {
      dispatch(
        rescheduleInterview(
          data._id,
          navigate,
          { ...formData, interviewType },
          socket
        )
      );
      props.setRescheduleModalView(false);
    } else {
      olibrToast.error("Message is required.");
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  if (!data) return <ClipLoader />;
  else
    return (
      <div>
        {!isReschedule && title}
        <div
          className="shortlisted-block card applicants-modal"
          style={{ background: "#fff", border: "none" }}
        >
          {isReschedule && (
            <div
              className="job-rquirement"
              style={{ position: "relative", marginTop: "20px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingBottom: "20px",
                }}
              >
                <div
                  style={{
                    gap: "15px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    flexDirection: "row",
                    position: "absolute",
                    top: "-23px",
                    marginLeft: "-5px",
                    width: "330px"
                  }}
                >
                  <div
                    className="flex-center"
                    style={{ gap: "2px", cursor: "pointer", height:"20px" }}
                    onClick={() => setInterviewType("coding")}
                  >
                    <input
                      type="radio"
                      className="modal-radio"
                      checked={interviewType === "coding"}
                    />
                    <span>Coding Interview</span>
                  </div>
                  <div
                    className="flex-center"
                    style={{ gap: "2px", cursor: "pointer", height:"20px"  }}
                    onClick={() => setInterviewType("regular")}
                  >
                    <input
                      type="radio"
                      className="modal-radio"
                      checked={interviewType === "regular"}
                    />
                    <span>Regular Interview</span>
                  </div>
                </div>
                <a
                  className="plus-icon"
                  style={{ top: "-22px" }}
                  onClick={
                    formData.interviewData.length <= 2 ? addInterviewData : null
                  }
                >
                  +
                </a>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="job-rquirement-form">
                  <div className="row">
                    {formData.interviewData.map((d) => {
                      return (
                        <>
                          <div className="col-5">
                            <div className="form-group">
                              <label>
                                From
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <DatePicker
                                selected={new Date(d.from)}
                                minDate={new Date()}
                                minTime={
                                  new Date(d.from).getDate()  - new Date().getDate() !== 0 ? 
                                  new Date().setHours(0, 0, 0) :
                                  new Date().getTime() + 2 * 60 * 60 * 1000
                                }
                                maxTime={setHours(setMinutes(new Date(), 59), 23)}
                                onChange={(from) => {
                                  let copy = { ...formData };
                                  copy.interviewData.map((i) => {
                                    if (i.id == d.id) {
                                      i.from = from;
                                      i.to = new Date(
                                        new Date(from).getTime() + 30 * 60000
                                      );
                                    }
                                  });
                                  setFormData(copy);
                                }}
                                showTimeSelect
                                dateFormat="Pp"
                                onKeyDown={(e) => {
                                  e.preventDefault();
                               }}
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <label>
                                To
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <DatePicker
                                selected={new Date(d.to)}
                                minDate={new Date()}
                                minTime={
                                  new Date(d.from).getDate()  - new Date().getDate() !== 0 ? 
                                  new Date().setHours(0, 0, 0) :
                                  new Date().getTime() + 2 * 60 * 60 * 1000
                                }
                                maxTime={setHours(setMinutes(new Date(), 59), 23)}
                                onChange={(to) => {
                                  let copy = { ...formData };
                                  copy.interviewData.map((i) => {
                                    if (i.id == d.id) {
                                      if (
                                        new Date(to).getTime() >
                                        new Date(i.from).getTime()
                                      ) {
                                        i.to = to;
                                      } else {
                                        olibrToast.error(
                                          "To date should be greater than from date."
                                        );
                                      }
                                    }
                                  });
                                  setFormData(copy);
                                }}
                                showTimeSelect
                                dateFormat="Pp"
                                onKeyDown={(e) => {
                                  e.preventDefault();
                               }}
                              />
                            </div>
                          </div>
                          <div className="col-1">
                            <a
                              className="minus-icon"
                              style={{ top: "25px", float: "right" }}
                              onClick={() => {
                                formData?.interviewData?.length > 1 &&
                                  subtractInterviewData(d.id);
                              }}
                            >
                              -
                            </a>
                          </div>
                        </>
                      );
                    })}
                    {interviewType !== "coding" && <div className="col-12">
                      <div className="form-group">
                        <label>Add other attendees</label>
                        <textarea
                          style={{ resize: "none", minHeight: "40px" }}
                          value={attendence}
                          placeholder="Add other attendees"
                          rows={1}
                          onChange={(e) => setAttendence(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              if (validateEmail(e.target.value)) {
                                e.preventDefault();
                                setFormData({
                                  ...formData,
                                  requiredAttendence:
                                    formData?.requiredAttendence
                                      ? [
                                          ...formData?.requiredAttendence,
                                          e.target.value,
                                        ]
                                      : [e.target.value],
                                });
                                setAttendence("");
                              } else {
                                e.preventDefault();
                                olibrToast.error(
                                  "Please enter valid email address."
                                );
                              }
                            }
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                        }}
                      >
                        {formData?.requiredAttendence?.map((item, index) => (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "5px 10px",
                              boxSizing: "border-box",
                              border: "1px solid black",
                              borderRadius: "20px",
                              gap: "20px",
                            }}
                          >
                            <span>{item}</span>
                            <Icon
                              name="modal-close"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                const tempData = [
                                  ...formData?.requiredAttendence,
                                ];
                                tempData.splice(index, 1);
                                setFormData({
                                  ...formData,
                                  requiredAttendence: tempData ? tempData : [],
                                });
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>}
                    <div className="col-12">
                      <div className="form-group">
                        <label>
                          Message<span style={{ color: "red" }}>*</span>
                        </label>
                        <textarea
                          value={formData.message}
                          placeholder="Message"
                          rows={5}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              message: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btn-block">
                  {isReschedule && (
                    <button className="btn" type="submit">
                      Reschedule
                    </button>
                  )}
                </div>
              </form>
            </div>
          )}
          <div className="btn-block">
            {!isReschedule && (
              <button
                className="btn"
                type="button"
                onClick={() => setIsReschedule(true)}
              >
                Reschedule
              </button>
            )}
          </div>
        </div>
      </div>
    );
}
