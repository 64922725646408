export const pageTourConst={
    camera:{
        srNo:1,
        title:"Photo",
        desc:"Take a selfie with a plain background, good lighting, pleasant facial expression, and camera focused on you; avoid  passport-size photos."
    },
    tellYourStory:{
        srNo:2,
        title:"Tell Your Story ",
        desc:"Summarize Your Professional Experience In 50 Words Or Less For A Concise And Impactful Representation Of Your Qualifications. Utilize ChatGPT To Enhance The Language.  "
    },
    saveShortDesc:{
        srNo:3,
        title:"Update Tell Your Story",
        desc:"Use This Button To Update Your 'Story'"
    },
    education:{
        srNo:4,
        title:"Education",
        desc:"List your educational history, including both your graduation and post-graduation (if any) degrees, with start and end years for a comprehensive overview of your educational background."
    },
    employment:{
        srNo:5,
        title:"Employment",
        desc:"Provide A Comprehensive Professional Profile By Mentioning Your Job Title, Complete Company Name, Employment Duration (Start And End Dates), Technologies/Tools Used, And Using Bullet Points To Outline Roles And Responsibilities For Each Job Held. "
    },
    project:{
        srNo:6,
        title:"Add Projects",
        desc:"Demonstrate your technical expertise and practical experience by detailing your involvement in projects for each company, including project name, industry domain, team size, your role, and utilized skills."
    },
    certification:{
        srNo:7,
        title:"Certifications ",
        desc:"Include Any Certifications You Have Received Along With Their Start And End Dates To Highlight Your Professional Development And Expertise In Your Field."
    },
    skills:{
        srNo:8,
        title:"Skills",
        desc:"List All Your Technical Skills To Showcase Your Expertise And Give Potential Employers A Comprehensive Understanding Of Your Capabilities."
    },
    tools:{
        srNo:9,
        title:"Tools",
        desc:"Use the provided drop-down box to list the names of tools you have used, such as Jira, AWS Cloud, etc. to highlight your technical proficiency. "
    },
    socialProfile:{
        srNo:10,
        title:"Social Profiles",
        desc:"Include your social profiles, such as LinkedIn, GitHub, Twitter, and Facebook, to provide a comprehensive view of your online presence and professional network."
    },
    achievements:{
        srNo:11,
        title:"Achievements",
        desc:"Showcase your achievements by listing them in your professional and educational capacities to demonstrate your successes and contributions."
    },

   
}

export const companyProfile={
     profileBanner:{
        srNo:1,
        title:"Profile Banner Tag Line",
        desc:"Boost appeal - add a catchy tagline to your company profile!"
    },
    aboutCompany:{
        srNo:2,
        title:"About Company",
        desc:"Highlight your unique journey - detail your company's story in the 'About Us' section."
    },
    perkAndBenefit:{
        srNo:3,
        title:"Perks and Benefits",
        desc:"Showcase your benefits! Select all perks offered to be showcased on your job posts and attract top talent."
    },
    team:{
        srNo:4,
        title:"Add Management Team",
        desc:"Showcase your leadership by adding your management team's details here."
    },
    imageGallery:{
        srNo:5,
        title:"Image Gallery",
        desc:"Upload pictures that showcase your office environment and culture. Make your company stand out!"
    },
    socialMedia:{
        srNo:6,
        title:"Add Social Media",
        desc:"Include your social media links to enhance your company's online visibility."
    }
}