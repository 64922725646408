export const emailSlug=
    {
        "eng_test_pass_mail": "English Proficiency Test - Passed",
        "dev_profile_rejected_mail": "Developer Profile Rejected",
        "tech_test_reminder_slug": "Reminder: Skill Test Window Open",
        "eng_test_fail_mail": "English Proficiency Test - Fail",
        "welcome_mail": "First Template: Welcome Email",
        "dev_profile_approved_mail": "Developer Profile Approved",
        "dev_update_requested_mail": "Developer Profile Update Requested",
        "skill_test_pass_mail": "When Developer Passes the Skill Test",
        "skill_test_fail_third_mail": "Developer failed final attempt",
        "dev_update_approval_accepted_mail": "Profile Update Accepted",
        "eng_test_reminder_slug": "Reminder email to inform the English test window is open for them to attempt again",
        "skill_test_fail_first_mail": "Skill test failed for the first time",
        "verification_mail": "Verification Mail",
        "dev_update_approval_rejected_mail": "Profile Update Request Rejected",
        "eng_test_reminder_after_setup": "Reminder email for taking English Proficiency Test",
        "skill_test_fail_second_mail": "Developer Failed for second time",
        "finalize_resume_mail": "Profile is Finalized and submitted",
        "spotlight_candidate": "Update Spotlight",
        "client_job_approved": "CLIENT JOB APPROVED",
        "direct_contact_email_client": "DIRECT CONTACT QUERY",
        "new_job_posted_for_dev": "NEW JOB POSTED FOR DEV",
        "job_rejected_feedback": "JOB REJECTED WITH FEEDBACK",
        "welcome_mail_client": "WELCOME CLIENT EMAIL",
        "client_register_email_to_support": "Client Registered",
        "client_job_posted_notification_to_admin": "CLIENT JOB Sent for approval",
        "client_job_posting_acknowledgement": "Client Job Posting Acknowledgement.",
        "finalize_resume_support": "FINALIZE RESUME (SUPPORT)",
        "client_signup_accept": "Client Signup redirection",
        "review_request_to_third_party": "Dev sending review request",
        "review_given_by_third_party": "Review given by third party",
        "dev_profile_shortlisted": "Dev Profile Shortlisted",
        "job_closing_reminder": "Job closing reminder",
        "job_closing_email": "Job closing email",
        "admin_user_invite": "Admin User Invite",
        "direct_contact_email_client_to_support": "Direct contact acknowledgement to support",
        "dev_introductory_video_uploaded": "Developer Introductory Video Uploaded",
        "dev_applied_to_job_mail_to_client": "Developer applied to your job",
        "dev_video_approved": "Video Approved",
        "developer_video_rejected": "Developer Video Rejected",
        "eng_test_fail_final_mail":"English Proficiency Test - Fail"
        
    }
