import * as types from "../../action";

const initialState = {
  userInfo: {},
  isLoading: false,
  loginData: {},

  //IT WILL BE REMVOE AFTER ALL API INTEGRATE
  tempData: {},
};

export const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_START:
      return { ...state, isLoading: true, userInfo: {} };
    case types.GET_USER_SUCCESS:
      return { ...state, isLoading: false, userInfo: action.data.data };
    case types.GET_USER_FAIL:
      return { ...state, isLoading: false };

    case types.LOGIN_START:
      return { ...state, loginLoading: true, loginData: {} };
    case types.LOGIN_SUCCESS:
      return { ...state, loginLoading: false, loginData: action.data };
    case types.LOGIN_FAIL:
      return { ...state, loginLoading: false, loginData: {} };

    case types.SIGN_UP_START:
      return { ...state, signUpLoading: true, tempData: {} };
    case types.SIGN_UP_SUCCESS:
      return { ...state, signUpLoading: false, tempData: action.payload };
    case types.SIGN_UP_FAIL:
      return { ...state, signUpLoading: false };

    case types.FORGET_PASSWORD_START:
      return { ...state, forgetPasswordLoading: true, tempData: {} };
    case types.FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgetPasswordLoading: false,
        tempData: action.payload,
      };
    case types.FORGET_PASSWORD_FAIL:
      return { ...state, forgetPasswordLoading: false };

    case types.RESET_PASSWORD_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.RESET_PASSWORD_SUCCESS:
      return { ...state, isLoading: false, tempData: action.payload };
    case types.RESET_PASSWORD_FAIL:
      return { ...state, isLoading: false };

    case types.LOGOUT_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.LOGOUT_SUCCESS:
      return { ...state, isLoading: false, tempData: action.payload };
    case types.LOGOUT_FAIL:
      return { ...state, isLoading: false };

    case types.EMAIL_VERIFICATION_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.EMAIL_VERIFICATION_SUCCESS:
      return { ...state, isLoading: false, tempData: action.payload };
    case types.EMAIL_VERIFICATION_FAIL:
      return { ...state, isLoading: false };

    case types.LOGOUT_START:
      return { ...state, isLogout: true, tempData: {} };
    case types.LOGOUT_SUCCESS:
      return { ...state, isLogout: false, tempData: action.payload };
    case types.LOGOUT_FAIL:
      return { ...state, isLogout: false };

    default:
      return { ...state };
  }
};
