import React from 'react'
import { useState } from 'react'

const ClientViewQuestion = ({ question,setQuestionModal }) => {
    const [openCollapes, setOpenCollapse] = useState(0)
    const handleCollapseble = (index) => {
        if (openCollapes === index) {
            setOpenCollapse(-1)
        } else {
            setOpenCollapse(index)
        }
    }
    return (
        <div className='questionaries-area'>
            <div className='row header-section'>
                <div className='col-12 text-center'>
                    <p className='header-title'>Questionnaire for candidates</p>
                </div>

            </div>
            <div className='box-items question-list-dev'>
              
                    {
                        question?.map((item, index) =>item?.answer && (
                            <div className='box-collapse-container'>
                            <div className='headers'>
                                <table className='table'>
                                    <thead>
                                        <tr onClick={()=>handleCollapseble(index)}>
                                            <th >
                                                <span className='minus-1'
                                                >{
                                                        openCollapes !== index ? "+" : "-"
                                                    }</span><span>{item?.question}</span>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody className={openCollapes !== index && "hide"}>
                                        
                                                <tr>
                                                    <td>{item?.answer}</td>
                                                </tr>
                                        
                                    </tbody>

                                </table>
                            </div>
                            </div>
                        ))
                    }

               

            </div>

            <div className='row'>
                <div className='col-12 text-right '>
                    <button className='submitBtn' onClick={()=>setQuestionModal(false)}>Close</button>
                </div>
            </div>

        </div>
    )
}

export default ClientViewQuestion