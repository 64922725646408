import React, { Fragment, useState, useEffect, useContext } from "react";
import "./CompanyProfile.css";
import {
  getCompanyProfile,
} from "../../service/action/client";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAllPerkAndBenefit } from "../../service/action/admin";
import { ClipLoader } from "react-spinners";
import { MainContext } from "../../context/MainContext";
import AboutUs from "./AboutUs";
import CompanyProfileHeader from "./CompanProfileHeader";
import ContactUs from "./ContactUs";
import ComapnyMembers from "./CompanyMembers";
import OurGallery from "./OurGallery";
import PerksAndBenefits from "./PerksAndBenefits";
import Banner from "./Banner";
import Hiring from "./Hiring";
import AwardsRecognitins from "./AwardsRecognitins";
import { getCompanyJobsList } from "../../service/action/common";

const CompanyProfile = ({profileData, setPublish, publish }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientProfile, profileLoading } = useSelector(
    (state) => state?.clientReducer
  );
  const { companyJobs } = useSelector(
    (state) => state?.commonReducer
  );
  // companyJobs
  const [data, setData] = useState({});
  const { user } = useContext(MainContext);
  const [client, setClient] = useState({
    email: "",
    number: "",
  });

  const { companyId } = useParams();

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyProfile(navigate, undefined, companyId));
    }
    dispatch(getAllPerkAndBenefit(navigate, "open"));
  }, [companyId]);

  useEffect(() => {
    setClient({
      email: user?.email,
      number: user?.number,
    });
  }, [user]);

  useEffect(() => {
    setData(profileData);
  }, [profileData]);

  useEffect(() => {
    setData(clientProfile);
    if (clientProfile?.clients?.length)
      setClient({
        email: clientProfile?.clients[0]?.email,
        number: clientProfile?.clients[0]?.number,
      });
    if (clientProfile?.userId) {
      dispatch(
        getCompanyJobsList(
          {
            pageData:{ page: 1, limit: 2 },
            clientId:clientProfile?.userId || user?._id}
        )
      );
    }
  }, [clientProfile]);

  return (
    <Fragment>
      {profileLoading ? (
        <div style={{ textAlign: "center" }}>
          {" "}
          <ClipLoader size={20} color="#3BA0AA" />{" "}
        </div>
      ) : (
        <div className="company">
          
          <CompanyProfileHeader
            onEditProfile={() => setPublish({ open: false, first: false })}
            data={data}
            publish={publish}
          />
          <Banner data={data} />
          <AboutUs data={data} />
          <OurGallery data={data} />
          <PerksAndBenefits data={data} />
          <ComapnyMembers data={data} />
          <AwardsRecognitins
            data={data?.awardAndRecognition}
            glassdoorLink={data?.employeeRating}
          />
          <Hiring jobData={companyJobs} />
          {user?.userType &&
            (
              (user?.userType == "Developer" &&
                user?.devStatus == "approved") ||
              user?.userType == "Client" ||
              user?.userType == "Admin"
            )&&(<ContactUs data={data} clientData={client} />)}
        </div>
      )}
    </Fragment>
  );
};

export default CompanyProfile;
