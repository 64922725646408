import * as types from "../../action";

const initialState = {
  resumeData: {},
  isLoading: false,
  isProfileImageLoading: false,
  isProfileParsingLoading: false,
  testLoading: false,
  stackLoading: true,
  userImageUrl: "",
  parseResume: [],
  skillResp: [],
  allSkills: [],
  stackResp: [],
  stackListChoosenData: {},
  devStatus: {},
  resultDevData: [],
  chooseSkill: [],
  isResumeLoading: false,
  englishTestStatus: [],
  clientFullJobDev: {},
  isClientFullJobDevLoading: false,
  isProfileStatusLoading: false,
  profileStatus: null,
  englishTestResult: null,
  englishResultLoading: false,

  //Developer dashboard
  appliedJob: [],
  shortListedJob: [],
  latestJobList: [],
  vettedSkill: [],
  isLatestJobLoading: false,
  isAppliedJobLoading: false,
  isShortlistJobLoad: false,
  devAcademicData: [],
  devCertificationData: [],
  createProfileData: {},
  isCreateProfileLoading: false,
  devSocialProfileData: [],
  englishQuestions: [],
  isEnglishQuestionsLoading: false,
  isApprovalUpdating: false,
  currentEmployment: {},
  devEmployementData: [],
  devAllToolLists: [],
  devProjectData: [],
  //IT WILL BE REMVOE AFTER ALL API INTEGRATE
  devProjectImage: [],
  isProjectImageLoading: false,
  tempData: {},
  devTools: [],
  devChooseSkill: [],
  devAchivementData: [],
  userData: {},
  activeTab: 0,
  tabTitle: "",
  isSaveAcademic: false,
  isUpdateEmplmt: false,
  isSaveproject: false,
  companyGallerys: "",
  companyLogos: "",
  companyCoverImages: "",
  memberImage: {},
  isLogoLoading: false,
  isMembrImgLoading: false,
  favJob: [],
  devActivations: {},
  devReviews: [],
  getDevVideoLoad: false,
  getSkillTestStepData: {},
  isSubmitQuesLoading: false,
  setAwardRecognitions:{}
};

export const developerReducer = (state = initialState, action) => {
  switch (action.type) {
 
    case types.VERIFY_OTP_START:
      return { ...state, verifyOtpLoading: true, verifyOtpData: {}}
    case types.VERIFY_OTP_SUCCESS:
      return { ...state, verifyOtpLoading: false, verifyOtpData: action?.data}
    case types.VERIFY_OTP_FAIL:
      return { ...state, verifyOtpLoading: false, verifyOtpData: {}}
 
    case types.SEND_OTP_START:
      return { ...state, sendOtpLoading: true, sendOtpData: {}}
    case types.SEND_OTP_SUCCESS:
      return { ...state, sendOtpLoading: false, sendOtpData: action?.data}
    case types.SEND_OTP_FAIL:
      return { ...state, sendOtpLoading: false, sendOtpData: {}}

    case types.REMOVE_UNATTEMPTED_SKILL_START:
      return { ...state, removeUnattemptedLoading: true, removeUnattemptedData: {} }
    case types.REMOVE_UNATTEMPTED_SKILL_SUCCESS:
      return { ...state, removeUnattemptedLoading: false, removeUnattemptedData: action?.data }
    case types.REMOVE_UNATTEMPTED_SKILL_FAIL:
      return { ...state, removeUnattemptedLoading: false, removeUnattemptedData: {} }

    case types.REMOVE_STACK_TEST_START:
      return { ...state, removeStackTestLoading: true, removeStackTest: {} }
    case types.REMOVE_STACK_TEST_SUCCESS:
      return { ...state, removeStackTestLoading: false, removeStackTest: action?.data }
    case types.REMOVE_STACK_TEST_FAIL:
      return { ...state, removeStackTestLoading: false, removeStackTest: {} }

    case types.CHOOSE_STACK_TESTS_START:
      return { ...state, chooseStackTestsLoading: true, chooseStackTests: {} }
    case types.CHOOSE_STACK_TESTS_SUCCESS:
      return { ...state, chooseStackTestsLoading: false, chooseStackTests: action?.data }
    case types.CHOOSE_STACK_TESTS_FAIL:
      return { ...state, chooseStackTestsLoading: false, chooseStackTests: {} }

    case types.CHOOSE_SKILL_TEST_START:
      return { ...state, chooseSkillTestLoading: true, chooseSkillTest: {} }
    case types.CHOOSE_SKILL_TEST_SUCCESS:
      return { ...state, chooseSkillTestLoading: false, chooseSkillTest: action?.newChosenSkills }
    case types.CHOOSE_SKILL_TEST_FAIL:
      return { ...state, chooseSkillTestLoading: false, chooseSkillTest: {} }

    case types.GET_SKILL_TEST_STEP_DATA_START:
      return { ...state, getSkillTestStepDataLoading: true, getSkillTestStepData: {} }
    case types.GET_SKILL_TEST_STEP_DATA_SUCCESS:
      return { ...state, getSkillTestStepDataLoading: false, getSkillTestStepData: action?.data }
    case types.GET_SKILL_TEST_STEP_DATA_FAIL:
      return { ...state, getSkillTestStepDataLoading: false, getSkillTestStepData: {} }

    case types.GET_DEV_VIDEO_START:
      return { ...state, getDevVideoLoad: true, devVideo: "" }
    case types.GET_DEV_VIDEO_SUCCESS:
      return { ...state, getDevVideoLoad: false, devVideo: action?.data?.data }
    case types.GET_DEV_VIDEO_FAIL:
      return { ...state, getDevVideoLoad: false, devVideo: "" }

    case types.NEW_FEATURE_CLICKED_START:
      return { ...state, isFeatureClicked: true }
    case types.NEW_FEATURE_CLICKED_SUCCESS:
      return { ...state, isFeatureClicked: false }
    case types.NEW_FEATURE_CLICKED_FAIL:
      return { ...state, isFeatureClicked: false }

    case types.CREATE_DEV_REVIEW_START:
      return { ...state, isReviewCreateLoad: true }
    case types.CREATE_DEV_REVIEW_SUCCESS:
      return { ...state, isReviewCreateLoad: false }
    case types.CREATE_DEV_REVIEW_FAIL:
      return { ...state, isReviewCreateLoad: false }

    case types.RESEND_DEV_REVIEW_START:
      return { ...state, isReviewResendLoad: true }
    case types.RESEND_DEV_REVIEW_SUCCESS:
      return { ...state, isReviewResendLoad: false }
    case types.RESEND_DEV_REVIEW_FAIL:
      return { ...state, isReviewResendLoad: false }

    case types.DELETE_DEV_REVIEW_START:
      return { ...state, isReviewDeleteLoad: true }
    case types.DELETE_DEV_REVIEW_SUCCESS:
      return { ...state, isReviewDeleteLoad: false }
    case types.DELETE_DEV_REVIEW_FAIL:
      return { ...state, isReviewDeleteLoad: false }

    case types.GET_DEV_REVIEW_LISTS_START:
      return { ...state, isReviewLoad: true, devReviews: [] }
    case types.GET_DEV_REVIEW_LISTS_SUCCESS:
      return { ...state, isReviewLoad: false, devReviews: action?.data?.data }
    case types.GET_DEV_REVIEW_LISTS_FAIL:
      return { ...state, isReviewLoad: false, devReviews: [] }

    case types.DASHBOARD_STEP_ONE_START:
      return { ...state, isLoading: true, userData: {} };
    case types.DASHBOARD_STEP_ONE_SUCCESS:
      return { ...state, isLoading: false, userData: action?.data, activeTab: action.next };
    case types.DASHBOARD_STEP_ONE_FAIL:
      return { ...state, isLoading: false };

    case types.SET_TAB_TITLE:
      return { ...state, tabTitle: action.data };
    case types.SET_BACK_TAB:
      return { ...state, activeTab: state.activeTab - 1 };
    case types.SET_NEXT_TAB:
      return { ...state, activeTab: state.activeTab + 1 };
    case types.SET_ACTIVE_TAB:
      console.log("action", action?.data)
      return { ...state, activeTab: action.data };

    case types.GET_STEP_COMPLETED_STATUS_START:
      return { ...state, isLoading: true,  };
    case types.GET_STEP_COMPLETED_STATUS_SUCCESS:
      return { ...state, isLoading: false, devStepCompleted: action?.data?.data?.devStepCompleted };
    case types.GET_STEP_COMPLETED_STATUS_FAIL:
      return { ...state, isLoading: false };

    case types.DEV_CHOOSE_SKILL_GET_START:
      return { ...state, isLoading: true, devChooseSkillGetResp: {} };
    case types.DEV_CHOOSE_SKILL_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        devChooseSkillGetResp: action?.data,
      };
    case types.DEV_CHOOSE_SKILL_GET_FAIL:
      return { ...state, isLoading: false };

    case types.APPROVAL_UPDATE_START:
      return { ...state, isApprovalUpdating: true };
    case types.APPROVAL_UPDATE_SUCCESS:
      return { ...state, isApprovalUpdating: false };
    case types.APPROVAL_UPDATE_FAIL:
      return { ...state, isApprovalUpdating: false };

    case types.GET_RESUME_START:
      return { ...state, isResumeLoading: true, resumeData: {} };
    case types.GET_RESUME_SUCCESS:
      return {
        ...state,
        isResumeLoading: false,
        profileStatus: action?.status ? action?.status : state?.profileStatus,
        resumeData: action?.data?.data,  //active
      };
    case types.GET_RESUME_FAIL:
      return { ...state, isResumeLoading: false, resumeData: {} };

    case types.GET_RESUME_OPEN_START:
      return { ...state, isResumeLoading: true, resumeData: {} };
    case types.GET_RESUME_OPEN_SUCCESS:
      return {
        ...state,
        isResumeLoading: false,
        resumeData: action?.data?.data[0]
      };
    case types.GET_RESUME_OPEN_FAIL:
      return { ...state, isResumeLoading: false, resumeData: {} };

    case types.GET_PROFILE_STATUS_START:
      return { ...state, isProfileStatusLoading: true, profileStatus: null };
    case types.GET_PROFILE_STATUS_SUCCESS:
      return {
        ...state,
        isProfileStatusLoading: false,
        profileStatus: action?.data?.data,
      };
    case types.GET_PROFILE_STATUS_FAIL:
      return { ...state, isProfileStatusLoading: false, profileStatus: null };

    case types.GET_CREATE_PROFILE_START:
      return { ...state, isCreateProfileLoading: true, createProfileData: {} };
    case types.GET_CREATE_PROFILE_SUCCESS:
      return {
        ...state,
        isCreateProfileLoading: false,
        createProfileData: action?.data?.data,
      };
    case types.GET_CREATE_PROFILE_FAIL:
      return { ...state, isCreateProfileLoading: false };

    case types.PROFICIENCY_TEST_LINK_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.PROFICIENCY_TEST_LINK_SUCCESS:
      return { ...state, isLoading: false, tempData: action.payload };
    case types.PROFICIENCY_TEST_LINK_FAIL:
      return { ...state, isLoading: false };

    case types.PROFICIENCY_TEST_RESULT_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.PROFICIENCY_TEST_RESULT_SUCCESS:
      return { ...state, isLoading: false, tempData: action.payload };
    case types.PROFICIENCY_TEST_RESULT_FAIL:
      return { ...state, isLoading: false };

    case types.ENGLISH_TEST_RESULT_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.ENGLISH_TEST_RESULT_SUCCESS:
      return { ...state, isLoading: false, tempData: action.payload };
    case types.ENGLISH_TEST_RESULT_FAIL:
      return { ...state, isLoading: false };

    case types.SKILL_LIST_START:
      return { ...state, isLoading: true, skillResp: [] };
    case types.SKILL_LIST_SUCCESS:
      return { ...state, isLoading: false, skillResp: action?.data?.data };
    case types.SKILL_LIST_FAIL:
      return { ...state, isLoading: false };

    case types.GET_DEV_SKILL_LIST_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.GET_DEV_SKILL_LIST_SUCCESS:
      return { ...state, isLoading: false, tempData: action.payload };
    case types.GET_DEV_SKILL_LIST_FAIL:
      return { ...state, isLoading: false };

    case types.GET_ALL_SKILL_LIST_START:
      return { ...state, isLoading: true, allSkills: [] };
    case types.GET_ALL_SKILL_LIST_SUCCESS:
      return { ...state, isLoading: false, allSkills: action?.data?.data };
    case types.GET_ALL_SKILL_LIST_FAIL:
      return { ...state, isLoading: false };

    case types.MANAGE_DEV_SKILL_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.MANAGE_DEV_SKILL_SUCCESS:
      return { ...state, isLoading: false, tempData: action.payload };
    case types.MANAGE_DEV_SKILL_FAIL:
      return { ...state, isLoading: false };

    case types.UPDATE_AVAILABLE_FROM_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.UPDATE_AVAILABLE_FROM_SUCCESS:
      return { ...state, isLoading: false, tempData: action.payload };
    case types.UPDATE_AVAILABLE_FROM_FAIL:
      return { ...state, isLoading: false };

    case types.POST_DEV_STACK_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.POST_DEV_STACK_SUCCESS:
      return { ...state, isLoading: false, tempData: action.payload };
    case types.POST_DEV_STACK_FAIL:
      return { ...state, isLoading: false };

    case types.DEV_CHOOSE_SKILL_START:
      return { ...state, isLoading: false };
    case types.DEV_CHOOSE_SKILL_SUCCESS:
      return { ...state, isLoading: false, chooseSkill: action.data };
    case types.DEV_CHOOSE_SKILL_FAIL:
      return { ...state, isLoading: false };

    case types.DELETE_DEV_SKILL_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.DELETE_DEV_SKILL_SUCCESS:
      return { ...state, isLoading: false, tempData: action.payload };
    case types.DELETE_DEV_SKILL_FAIL:
      return { ...state, isLoading: false };

    case types.STACK_LIST_START:
      return { ...state, stackLoading: true };
    case types.STACK_LIST_SUCCESS:
      return { ...state, stackLoading: false, stackResp: action?.data?.data };
    case types.STACK_LIST_FAIL:
      return { ...state, stackLoading: false };

    case types.STACK_LIST_CHOSEN_START:
      return { ...state, isLoading: true, stackListChoosenData: {} };
    case types.STACK_LIST_CHOSEN_SUCCESS:
      return { ...state, isLoading: false, stackListChoosenData: action.data };
    case types.STACK_LIST_CHOSEN_FAIL:
      return { ...state, isLoading: false };

    case types.FINALIZE_RESUME_CALL_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.FINALIZE_RESUME_CALL_SUCCESS:
      return { ...state, isLoading: false, tempData: action.payload };
    case types.FINALIZE_RESUME_CALL_FAIL:
      return { ...state, isLoading: false };

    case types.UPLOAD_PROFILE_IMAGE_START:
      return { ...state, isProfileImageLoading: true, userImageUrl: "" };
    case types.UPLOAD_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        isProfileImageLoading: false,
        resumeData: { ...state.resumeData, userImage: action?.data?.payload?.filename }
      };
    case types.UPLOAD_PROFILE_IMAGE_FAIL:
      return { ...state, isProfileImageLoading: false, userImageUrl: "" };

    case types.RESUME_PARSING_START:
      return { ...state, isProfileParsingLoading: true, parseResume: [] };
    case types.RESUME_PARSING_SUCCESS:
      return {
        ...state,
        isProfileParsingLoading: false,
        parseResume: action.data,
      };
    case types.RESUME_PARSING_FAIL:
      return { ...state, isProfileParsingLoading: false };

    case types.GET_RESUME_PARSER_DATA_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.GET_RESUME_PARSER_DATA_SUCCESS:
      return { ...state, isLoading: false, tempData: action.payload };
    case types.GET_RESUME_PARSER_DATA_FAIL:
      return { ...state, isLoading: false };

    case types.UPLOAD_IMAGE_START:
      if (action?.case == "logo") {
        return { ...state, isLogoLoading: true };
      } else if (action?.case == "memberImage") {
        return { ...state, isMembrImgLoading: true };
      }else if (action?.case == "awardImage"){
        return { ...state, isawAwrdImageLoading: true };
      }

    case types.UPLOAD_IMAGE_SUCCESS:
      if (action?.case == "gallery") {
        return {
          ...state,
          isDevProjectImageLoading: false,
          companyGallerys: action?.data?.filename,
        };
      } else if (action?.case == "logo") {
        return {
          ...state,
          isLogoLoading: false,

          companyLogos: action?.data?.filename,
        };
      } else if (action?.case == "memberImage") {
        return {
          ...state,
          isMembrImgLoading: false,
          memberImage: { index: action?.indexNo, image: action?.data?.filename },
        };
      }else if (action?.case == "awardImage"){
        return {
          ...state,
          isawAwrdImageLoading: false,
          awardImageReducer: { index: action?.indexNo, image: action?.data?.filename },
        };
      }else {
        return {
          ...state,
          isDevProjectImageLoading: false,
          devProjectImage: [action?.data?.filename],
        };
      }

    case types.UPLOAD_IMAGE_FAIL:
      return { ...state, isDevProjectImageLoading: false, isLogoLoading: false, isMembrImgLoading: false };

    case types.UPLOAD_MULTI_IMAGE_START:
      return { ...state, isDevProjectImageLoading: true };
    case types.UPLOAD_MULTI_IMAGE_SUCCESS:
      return {
        ...state,
        isDevProjectImageLoading: false,
        devProjectImage: [...action?.data?.filename],
      };
    case types.UPLOAD_MULTI_IMAGE_FAIL:
      return { ...state, isDevProjectImageLoading: false };

    case types.START_TEST_START:
      return { ...state, testLoading: action.testId, tempData: {} };
    case types.START_TEST_SUCCESS:
      return { ...state, testLoading: false, tempData: action.payload };
    case types.START_TEST_FAIL:
      return { ...state, testLoading: false };

    case types.ENGLISH_TEST_START:
      return {
        ...state,
        isEnglishQuestionsLoading: true,
        englishQuestions: [],
        // englishTestResult: null,
      };
    case types.ENGLISH_TEST_SUCCESS:
      return {
        ...state,
        isEnglishQuestionsLoading: false,
        englishQuestions: action.data,
        englishTestTime: action?.testTime,
        prevEngQuestion: action?.prevQuestion
      };
    case types.ENGLISH_TEST_FAIL:
      return { ...state, isEnglishQuestionsLoading: false };

    case types.POST_RESULT_DATA_START:
      return { ...state, englishResultLoading: true, englishTestResultAfterSubmission: null };
    case types.POST_RESULT_DATA_SUCCESS:
      return {
        ...state,
        englishResultLoading: false,
        englishTestResultAfterSubmission: action.data,
      };
    case types.POST_RESULT_DATA_FAIL:
      return { ...state, englishResultLoading: false, englishTestResultAfterSubmission: null };
    case types.POST_RESULT_DATA_CLOSED_CLICK:
      return { ...state, englishResultLoading: false, englishTestResultAfterSubmission: null };


    case types.ENGLISH_TEST_CHECK_FAIL:
      let result = action?.result ? action?.result : state.englishTestResult
      return { ...state, englishTestResult: result }

    case types.GET_ENGLISH_TEST_RESULT_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.GET_ENGLISH_TEST_RESULT_SUCCESS:
      return { ...state, isLoading: false, activeTab: state.activeTab, englishTestResult: action?.data?.data };
    case types.GET_ENGLISH_TEST_RESULT_FAIL:
      return { ...state, isLoading: false };

    case types.ASK_FOR_APPROVAL_START:
      return { ...state, askForApprovalLoading: true, tempData: {} };
    case types.ASK_FOR_APPROVAL_SUCCESS:
      return { ...state, askForApprovalLoading: false, tempData: action.payload };
    case types.ASK_FOR_APPROVAL_FAIL:
      return { ...state, askForApprovalLoading: false };

    case types.GET_DEV_STATUS_START:
      return { ...state, isLoading: true, devStatus: {} };
    case types.GET_DEV_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        devStatus: action?.data?.payload?.status,
      };
    case types.GET_DEV_STATUS_FAIL:
      return { ...state, isLoading: false };

    case types.GET_RESULT_DEV_START:
      return { ...state, isLoading: true, resultDevData: [] };
    case types.GET_RESULT_DEV_SUCCESS:
      return { ...state, isLoading: false, resultDevData: action?.data?.data };
    case types.GET_RESULT_DEV_FAIL:
      return { ...state, isLoading: false };

    case types.GET_APPLIED_JOB_START:
      return { ...state, isAppliedJobLoading: true };
    case types.GET_APPLIED_JOB_SUCCESS:
      return { ...state, isAppliedJobLoading: false, appliedJob: action?.data };
    case types.GET_APPLIED_JOB_FAIL:
      return { ...state, isAppliedJobLoading: false };

    case types.GET_SHORT_LIST_JOB_START:
      return { ...state, isShortlistJobLoad: true };
    case types.GET_SHORT_LIST_JOB_SUCCESS:
      return {
        ...state,
        isShortlistJobLoad: false,
        shortListedJob: action?.data,
      };
    case types.GET_SHORT_LIST_JOB_FAIL:
      return { ...state, isShortlistJobLoad: false };

    case types.GET_LATEST_JOB_LIST_START:
      return { ...state, isLatestJobLoading: true };
    case types.GET_LATEST_JOB_LIST_SUCCESS:
      return {
        ...state,
        isLatestJobLoading: false,
        latestJobList: action?.data,
      };
    case types.GET_LATEST_JOB_LIST_FAIL:
      return { ...state, isLatestJobLoading: false };

    case types.GET_VETTED_SKILL_START:
      return { ...state, isLoading: true, vettedSkill: [] };
    case types.GET_VETTED_SKILL_SUCCESS:
      return { ...state, isLoading: false, vettedSkill: action.data };
    case types.GET_VETTED_SKILL_FAIL:
      return { ...state, isLoading: false };

    case types.GET_ENGLISH_TEST_STATUS_START:
      return { ...state, isLoading: true, englishTestStatus: [] };
    case types.GET_ENGLISH_TEST_STATUS_SUCCESS:
      return { ...state, isLoading: false, englishTestStatus: action.data };
    case types.GET_ENGLISH_TEST_STATUS_FAIL:
      return { ...state, isLoading: false };

    case types.UPDATE_ENGLISH_TEST_STATUS_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.UPDATE_ENGLISH_TEST_STATUS_SUCCESS:
      return { ...state, isLoading: false, tempData: action.data };
    case types.UPDATE_ENGLISH_TEST_STATUS_FAIL:
      return { ...state, isLoading: false };

    case types.POST_ENGLISH_TEST_STATUS_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.POST_ENGLISH_TEST_STATUS_SUCCESS:
      return { ...state, isLoading: false, tempData: action.data };
    case types.POST_ENGLISH_TEST_STATUS_FAIL:
      return { ...state, isLoading: false };

    case types.GET_JOB_FULL_CLIENT_DEV_START:
      return {
        ...state,
        isClientFullJobDevLoading: true,
        clientFullJobDev: {},
      };
    case types.GET_JOB_FULL_CLIENT_DEV_SUCCESS:
      return {
        ...state,
        isClientFullJobDevLoading: false,
        clientFullJobDev: action.data,
      };
    case types.GET_JOB_FULL_CLIENT_DEV_FAIL:
      return { ...state, isClientFullJobDevLoading: false };

    case types.GET_DEV_ACADEMIC_START:
      return { ...state };
    case types.GET_DEV_ACADEMIC_SUCCESS:
      return { ...state, devAcademicData: action?.data?.data };
    case types.GET_DEV_ACADEMIC_FAIL:
      return { ...state };

    case types.GET_CURRENT_EMPLOYMENT_SUCCESS:
      return { ...state, currentEmployment: action?.data?.data?.[0] };

    case types.GET_DEV_CERTIFICATION_START:
      return { ...state };
    case types.GET_DEV_CERTIFICATION_SUCCESS:
      return { ...state, devCertificationData: action?.data?.data };
    case types.GET_DEV_CERTIFICATION_FAIL:
      return { ...state };

    case types.GET_DEV_SOCIAL_PROFILE_START:
      return { ...state };
    case types.GET_DEV_SOCIAL_PROFILE_SUCCESS:
      return { ...state, devSocialProfileData: action?.data?.data[0] };
    case types.GET_DEV_SOCIAL_PROFILE_FAIL:
      return { ...state };

    case types.GET_DEV_EMPLOYEMENT_START:
      return { ...state, isLoading: true };
    case types.GET_DEV_EMPLOYEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        devEmployementData: action?.data?.data,
      };
    case types.GET_DEV_EMPLOYEMENT_FAIL:
      return { ...state, isLoading: false };

    case types.GET_ALL_TOOLS_START:
      return { ...state, isLoading: true };
    case types.GET_ALL_TOOLS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        devAllToolLists: action?.data?.data,
      };
    case types.GET_ALL_TOOLS_FAIL:
      return { ...state, isLoading: false };

    case types.GET_DEV_PROJECT_START:
      return { ...state, isLoading: true };
    case types.GET_DEV_PROJECT_SUCCESS:
      return { ...state, isLoading: false, devProjectData: action?.data?.data };
    case types.GET_DEV_PROJECT_FAIL:
      return { ...state, isLoading: false };

    case types.REMOVE_DEV_PROJECT_IMAGE_SUCCESS:
      return { ...state, isLoading: false, devProjectImage: [] };

    case types.GET_DEV_TOOLS_START:
      return { ...state, isLoading: true };
    case types.GET_DEV_TOOLS_SUCCESS:
      return { ...state, isLoading: false, devTools: action?.data?.data };

    case types.GET_DEV_SKILLS_START:
      return { ...state, isLoading: true };
    case types.GET_DEV_SKILLS_SUCCESS:
      return { ...state, isLoading: false, devChooseSkill: action?.data?.data };
    case types.GET_DEV_SKILLS_FAIL:
      return { ...state, isLoading: false };

    case types.GET_DEV_ACHIEVEMENT_START:
      return { ...state, isLoading: true };
    case types.GET_DEV_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        devAchivementData: action?.data?.data,
      };
    case types.GET_DEV_ACHIEVEMENT_FAIL:
      return { ...state, isLoading: false };

    case types.UPDATE_DEV_ACADEMIC_START:
      return { ...state, isSaveAcademic: true }
    case types.UPDATE_DEV_ACADEMIC_SUCCESS:
      const devAcademic = [...state.devAcademicData];
      devAcademic[
        devAcademic.findIndex((item) => item?._id === action?.data?.data?._id)
      ] = action?.data?.data;
      return { ...state, isSaveAcademic: false, devAcademicData: devAcademic };
    case types.UPDATE_DEV_ACADEMIC_FAIL:
      return { ...state, isSaveAcademic: false }

    case types.SAVE_DEV_ACADEMIC_START:
      return {
        ...state,
        isSaveAcademic: true,
      };
    case types.SAVE_DEV_ACADEMIC_SUCCESS:
      return {
        ...state,
        isSaveAcademic: false,
        devAcademicData: [action?.data?.data, ...state.devAcademicData],
      };
    case types.SAVE_DEV_ACADEMIC_FAIL:
      return {
        ...state,
        isSaveAcademic: false,
      };

    case types.DELETE_DEV_ACADEMIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        devAcademicData: state.devAcademicData.filter(
          (item) => item?._id !== action?.data
        ),
      };

    case types.SAVE_DEV_CERTIFICATION_START:
      return { ...state, isCertfctSave: true }
    case types.SAVE_DEV_CERTIFICATION_SUCCESS:
      return {
        ...state,
        isCertfctSave: false,
        devCertificationData: [
          action?.data?.data,
          ...state.devCertificationData,
        ],
      };
    case types.SAVE_DEV_CERTIFICATION_FAIL:
      return { ...state, isCertfctSave: false }

    case types.UPDATE_DEV_CERTIFICATION_START:
      return { ...state, isCertfctSave: true }
    case types.UPDATE_DEV_CERTIFICATION_SUCCESS:
      const devCertificates = [...state.devCertificationData];
      devCertificates[
        devCertificates.findIndex(
          (item) => item?._id === action?.data?.data?._id
        )
      ] = action?.data?.data;
      return {
        ...state, isCertfctSave: false, devCertificationData: devCertificates,
      };
    case types.UPDATE_DEV_CERTIFICATION_FAIL:
      return { ...state, isCertfctSave: false }

    case types.DELETE_DEV_CERTIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        devCertificationData: state.devCertificationData.filter(
          (item) => item?._id !== action?.data
        ),
      };
    case types.SAVE_DEV_EMPLOYEMENT_START:
      return { ...state, isUpdateEmplmt: true }
    case types.SAVE_DEV_EMPLOYEMENT_SUCCESS:
      return {
        ...state,
        isUpdateEmplmt: false,
      };
    case types.SAVE_DEV_EMPLOYEMENT_FAIL:
      return { ...state, isUpdateEmplmt: true }


    case types.UPDATE_DEV_EMPLOYEMENT_START:
      return { ...state, isUpdateEmplmt: true }
    case types.UPDATE_DEV_EMPLOYEMENT_SUCCESS:
      return { ...state, isUpdateEmplmt: false };
    case types.UPDATE_DEV_EMPLOYEMENT_START:
      return { ...state, isUpdateEmplmt: false }

    case types.DELETE_DEV_EMPLOYEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        devEmployementData: state.devEmployementData.filter(
          (item) => item?._id !== action?.data
        ),
      };

    case types.SAVE_DEV_PROJECT_START:
      return { ...state, isSaveproject: true }
    case types.SAVE_DEV_PROJECT_SUCCESS:
      return {
        ...state,
        isSaveproject: false,
        devProjectData: [action?.data?.data, ...state.devProjectData],
      };
    case types.SAVE_DEV_PROJECT_FAIL:
      return { ...state, isSaveproject: false }

    case types.UPDATE_DEV_PROJECT_START:
      return { ...state, isSaveproject: true }
    case types.UPDATE_DEV_PROJECT_SUCCESS:
      const devProject = [...state.devProjectData];
      devProject[
        devProject.findIndex((item) => item?._id === action?.data?.data?._id)
      ] = action?.data?.data;
      return { ...state, isSaveproject: false, devProjectData: devProject };
    case types.UPDATE_DEV_PROJECT_FAIL:
      return { ...state, isSaveproject: false }

    case types.DELETE_DEV_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        devProjectData: state.devProjectData.filter(
          (item) => item?._id !== action?.data
        ),
      };

    case types.SAVE_DEV_SKILLS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        devChooseSkill: [action?.data?.data, ...state.devChooseSkill],
      };
    case types.UPDATE_DEV_SKILLS_SUCCESS:
      const devSkill = [...state.devChooseSkill];
      devSkill[
        devSkill.findIndex((item) => item?._id === action?.data?.data?._id)
      ] = action?.data?.data;
      return { ...state, isLoading: false, devChooseSkill: devSkill };
    case types.DELETE_DEV_SKILLS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        devChooseSkill: state.devChooseSkill.filter(
          (item) => item?._id !== action?.data
        ),
      };

    case types.SAVE_DEV_TOOLS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        devTools: [...action?.data?.data, ...state.devTools],
      };
    case types.UPDATE_DEV_TOOLS_SUCCESS:
      const devTool = [...state.devTools];
      devTool[
        devTool.findIndex((item) => item?._id === action?.data?.data?._id)
      ] = action?.data?.data;
      return { ...state, isLoading: false, devTools: devTool };
    case types.DELETE_DEV_TOOLS_SUCCESS:
      let tempDevData = { ...state.resumeData };
      let tempTool = [];
      if (tempDevData?.devTools) {
        tempTool = tempDevData?.devTools?.filter((item) => item?._id !== action?.data)
      }
      return {
        ...state,
        isLoading: false,
        devTools: state.devTools.filter((item) => item?._id !== action?.data),
        resumeData: { ...state.resumeData, devTools: tempTool }
      };

    case types.SAVE_DEV_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        devAchivementData: [action?.data?.data, ...state.devAchivementData],
      };
    case types.UPDATE_DEV_ACHIEVEMENT_SUCCESS:
      const devAchievement = [...state.devAchivementData];
      devAchievement[
        devAchievement.findIndex(
          (item) => item?._id === action?.data?.data?._id
        )
      ] = action?.data?.data;
      return { ...state, isLoading: false, devAchivementData: devAchievement };
    case types.DELETE_DEV_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        devAchivementData: state.devAchivementData.filter(
          (item) => item?._id !== action?.data
        ),
      };

    case types.DELETE_PROFILE_IMAGE_SUCCESS:
      return { ...state, userImageUrl: null }

    case types.UPDATE_PAGE_TOUR_SUCCESS:
      const tempUsers = state.resumeData;
      tempUsers.firstOnFourthStep = true;
      return { ...state, resumeData: tempUsers }

    case types.GET_FAVOURITE_JOB_SUCCESS:
      return { ...state, favJob: action?.data?.data }

    case types.UPDATE_BASIC_DETAIL_SUCCESS:
      let tempActionData = { ...state?.resumeData, ...action?.data }
      return { ...state, resumeData: tempActionData }

    case types.DEV_ACTIVATION_SUCCESS:
      let tempObject = { ...state.devActivations }
      tempObject?.data?.map((item, index) => {
        if (item?.slug == action?.data?.emailType) {
          tempObject.data[index].status = action?.data?.status;
          // if (action?.data?.selectedSkillsStacks?.length) {
          tempObject.selectedSkillsStacks = action?.data?.selectedSkillsStacks
          // }

        }
      })
      return { ...state, devActivations: tempObject }

    case types.GET_DEV_EMAIL_AUTO_SUCCESS:
      return { ...state, devActivations: action?.data }
    case types.UPDATE_DEV_DESCRIPTION_SUCCESS:
      return { ...state, resumeData: { ...state.resumeData, devTellUrStory: action?.data?.devTellUrStory } }

    case types.SET_ACTIVE_AFTER_LOGIN:
      return { ...state, activeTab: 0 }

    case types.APPLY_NEW_MULTI_JOB_START:
      return { ...state, isSubmitQuesLoading: true }
    case types.APPLY_NEW_MULTI_JOB_SUCCESS:
      return { ...state, isSubmitQuesLoading: false }
    case types.APPLY_NEW_MULTI_JOB_FAIL:
      return { ...state, isSubmitQuesLoading: false }

    case types.APPLY_NEW_JOB_START:
      return { ...state, isSubmitQuesLoading: true }
    case types.APPLY_NEW_JOB_SUCCESS:
      return { ...state, isSubmitQuesLoading: false }
    case types.APPLY_NEW_JOB_FAIL:
      return { ...state, isSubmitQuesLoading: false }

    case types.CLEAR_RESUME_PROFILE:
      return {...state,resumeData:{}}

    default:
      return { ...state, isLoading: false };
  }
};
