import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../components/icon";
import Modal from "../../../components/modal/ModalBox";

const optionArr = [
  { label: "", value: "" },
  { label: "BLOG SITEMAP XML", value: "blog_sitemap_xml" },
  { label: "SITEMAP XML", value: "sitemap_xml" },
  { label: "WEBSITE SITEMAP XML", value: "website_sitemap_xml" },
];

const Sitemap = () => {

  const navigate = useNavigate();

  const [sitemapType, setSitemapType] = useState("");
  const [mapChange, setMapChange] = useState({
    blog_sitemap_xml: "",
    sitemap_xml: "",
    website_sitemap_xml: "",
  });

  const handleSubmitSiteMap = () => {
    console.log(sitemapType, "----", mapChange[`${sitemapType}`]);
  };

  const [isMapOpen, setMapOpen] = useState(false);

  const closeMapModal = () => {
    setMapOpen(false);
  };

  const handleMapContent = () => {
    return (
      <Fragment>
        <input type="text" />
      </Fragment>
    );
  };
  const handleEditSitemap = () => {};

  return (
    <Fragment>
      <Modal
        open={isMapOpen}
        title={"ADD SITEMAP"}
        onClose={closeMapModal}
        onCancel={closeMapModal}
        onDone={handleSubmitSiteMap}
        content={handleMapContent}
      />
      <div className="main-div">
        <div className="main-div-developer">
          <div className="create-profile">
            <div className="block-header">
              <h4 className="">SITEMAPS</h4>
              <span onClick={() => setMapOpen(true)}>
              <Icon name="add-icon" />
              </span>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th className="column0">S.No</th>
                  <th className="column1">Sitemap Type</th>
                  <th className="column1">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="column1" onClick={() => handleEditSitemap()}>
                    {"-"}
                  </td>
                  <td className="column1" onClick={() => handleEditSitemap()}>
                    {"-"}
                  </td>
                  <td className="column1" onClick={() => handleEditSitemap()}>
                    {"-"}
                  </td>
                </tr>
              </tbody>
            </table>
          <div className="text-right" style={{marginTop: "20px"}}>
          <span className="submitBtn" onClick={() => navigate("/admin/sitemap-types")}>
            Manage Map Types List
          </span>
          </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Sitemap;
