import React from "react";
import PageTour from "../../../components/page-tour/PageTour";
import { Icon } from "../../../components/icon";

const ImageAndGallery = ({
  companyGallery,
  removeGallery,
  pageTour,
  companyProfile,
  setPageTour,
  skipPageTour,
  galleryImage,
  uploadImages,
}) => {
  return (
    <>
      <h4 className="form-title">
        Add images showcasing your company's office environment and culture
      </h4>
      <PageTour
        open={pageTour == "gallery"}
        data={companyProfile.imageGallery}
        next={() => setPageTour("social")}
        className="team-section"
        skip={skipPageTour}
        total={6}
        id="#social"
      />
      <div className="row">
        <div className="col-12">
          <div className="add-img-block">
            <ul className="img-list">
              <li
                onClick={() => galleryImage?.current?.click()}
                onChange={(e) => uploadImages(e, "gallery")}
              >
                <a className="add-img">
                  <span>+</span>
                </a>
                <input
                  type="file"
                  accept=".png, .jpeg, .jpg, .svg"
                  ref={galleryImage}
                  className="input-file"
                  multiple
                />
              </li>
              {companyGallery
                ?.filter((item) => item)
                ?.map((item, index) => (
                  <li key={index}>
                    <span
                      className="delete"
                      onClick={() => removeGallery(item)}
                    >
                      <Icon name="delete" />
                    </span>
                    <img src={item} alt="" />
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageAndGallery;
