import React from "react";
import { Fragment } from "react";
import { Icon } from "../../../../../components/icon";

const DetailedReportTable = ({ questions }) => {
  const timeTakenFormatted = (time) => {
    if (!time) {
      return "";
    }
    let min = parseInt(time / 60);
    let sec = parseInt(time % 60);

    return min + " min " + sec + " sec";
  };

  return (
    <Fragment>
      <div className="dash-block">
        <div className="card">
          <div className="card-header">
            <h4>Questions</h4>
          </div>
          <table
            className="dev-table"
            width="100%"
            border="0"
            id="detail-report"
            cellPadding="0"
            cellSpacing="0"
          >
            {Object.values(questions ? questions : {})?.length && (
              <Fragment>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Question</th>
                    <th>Type</th>
                    <th>Time Taken</th>
                    <th>Scores</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {Object.values(questions ? questions : {})?.map(
                    (item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <div className="developer-details">
                            <div>
                              <h5>{item?.name}</h5>
                            </div>
                          </div>
                        </td>
                        <td>
                          {String(item?.type ? item?.type : "").toUpperCase()}
                        </td>
                        <td>{timeTakenFormatted(item?.time_taken)}</td>
                        <td>
                          {parseFloat(item?.score ? item?.score : 0).toFixed(1)}
                          {" / "}
                          {item?.max_score}
                        </td>
                        <td>
                          <Icon
                            name={
                              item?.answered ? "detail-passed" : "detail-failed"
                            }
                          />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Fragment>
            )}
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export default DetailedReportTable;
