import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { aboutusOG } from "../imageLinks";
import NoFollow from "../../../common/staticData/seo/NoFollow";

const AboutUs = ({ baseUrl }) => {
  return (
    <Helmet>
      <title>About Olibr - Placing developers with Top IT companies in US</title>

      <meta charset="UTF-8" />

      <meta
        name="title"
        content="About Olibr - Placing developers with Top IT companies in US"
      />
      <meta
        name="description"
        content="Olibr.com connects the world's best Software developers & engineers to the world's best companies. Learn more about Olibr, our leadership, our mission, & vision."
      ></meta>
      <link rel="canonical" href={`${baseUrl}/about-us`} />

      <meta
        name="keywords"
        content="About Olibr, About the company, why to choose olibr, jobs for work from home, fastest jobs, US IT Companies"
      />
      <meta name="robots" content={NoFollow()}></meta>

      <meta name="language" content="english"></meta>

      <html lang="en" />

      <meta name="url" content={`${baseUrl}/about-us`} />

      <meta name="identifier-URL" content={`${baseUrl}/about-us`}></meta>

      {/* <meta name='subtitle' content='about us -- learn more'></meta> */}

      <meta name="referrer" content="origin-when-crossorigin"></meta>

      <link rel="image_src" href={aboutusOG} />

      <meta name="copyright" content="olibr.com" />

      {/* ----Social Media Open Graph--------- */}

      <meta
        property="og:description"
        content="Olibr.com connects the world's best Software developers & engineers to the world's best companies. Learn more about Olibr, our leadership, our mission, & vision."
      />
      <meta property="og:site_name" content="Olibr" />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@olibr" />
      <meta property="twitter:domain" content="olibr.com" />
      <meta property="twitter:url" content={`${baseUrl}/about-us`} />
      <meta
        name="twitter:title"
        content="About Olibr - Placing developers with Top IT companies in US"
      />
      <meta
        name="twitter:description"
        content="Olibr.com connects the world's best Software developers & engineers to the world's best companies. Learn more about Olibr, our leadership, our mission, & vision."
      />
      <meta name="twitter:image" content={aboutusOG} />

      <meta property="og:type" content="business.business" />
      <meta
        property="og:title"
        content="About Olibr - Placing developers with Top IT companies in US"
      />
      <meta property="og:url" content={`${baseUrl}/about-us`} />
      <meta property="og:image" content={aboutusOG} />
      <meta
        property="business:contact_data:street_address"
        content="26 Arrowhead Ln"
      />
      <meta property="business:contact_data:locality" content="Menlo Park" />
      <meta property="business:contact_data:region" content="CA" />
      <meta property="business:contact_data:postal_code" content="94025" />
      <meta property="business:contact_data:country_name" content="US" />

      {/* ------Apple Devices ------ */}

      <meta
        name="apple-mobile-web-app-title"
        content="About Olibr - Placing developers with Top IT companies in US"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-touch-fullscreen" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black"></meta>

      {/* ----------JSON LD Schema for SEO------ */}

      <script type="application/ld+json">
        {JSON.stringify(
          // breadcrumbs schema
          {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: `${baseUrl}/`,
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "About Us",
                item: `${baseUrl}/about-us`,
              },
            ],
          }
        )}
      </script>
      {/* -------------------------- */}
      <script type="application/ld+json">
        {JSON.stringify(
          // ------oraganisation schema ------
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Olibr.com",
            url: `${baseUrl}/about-us`,
            logo: "%PUBLIC_URL%/olibr-full-logo.png",
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "000000000",
              contactType: "technical support",
            },
            sameAs: [
              "https://www.facebook.com/olibrcom",
              "https://www.linkedin.com/company/olibrcom/",
              "https://twitter.com/olibr",
              "https://www.instagram.com/olibr",
              "https://www.youtube.com/@olibr",
              "https://in.pinterest.com/olibr/",
              "",
              baseUrl,
            ],
          }
        )}
      </script>
      {/* --------------------------- */}
      <script type="application/ld+json">
        {JSON.stringify(
          // website/website schema--
          {
            "@context": "https://www.schema.org",
            "@type": "WebSite",
            name: " About Olibr.com | Placing Developers with Top software & IT companies in US & world",
            url: `${baseUrl}/about-us`,
          },
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            name: "About us - Olibr | Best remote jobs platform",
            speakable: {
              "@type": "SpeakableSpecification",
              xpath: [
                "/html/head/title",
                "/html/head/meta[@name='description']/@content",
              ],
            },
            url: `${baseUrl}/about-us`,
          }
        )}
      </script>
      {/* ------------------------------ */}
      <script type="application/ld+json">
        {JSON.stringify(
          // Speakable ---
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            name: "About us - Olibr | Best remote jobs platform",
            speakable: {
              "@type": "SpeakableSpecification",
              xpath: [
                "/html/head/title",
                "/html/head/meta[@name='description']/@content",
              ],
            },
            url: `${baseUrl}/about-us`,
          }
        )}
      </script>
      {/* ------------------------ */}
      <script type="application/ld+json">
        {JSON.stringify(
          // ------FAQ Schema---
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [
              {
                "@type": "Question",
                name: "What is Olibr?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Olibr is a US-based job placement platform that offers full-time remote job opportunities to highly skilled developers in top US firms.",
                },
              },
              {
                "@type": "Question",
                name: "What kind of developers does Olibr hire?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Olibr hires seasoned developers for various job types including front-end, back-end, full-stack, database, mobile, DevOps, etc., based on tech roles, skills, and seniority. We have requirements for over 20 technologies including Python, React/Node, TypeScript, AWS Lambda, Java, Angular, TypeScript, React Native, PHP, Ruby, and more",
                },
              },
              {
                "@type": "Question",
                name: "Where is Olibr based?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Olibr is based in the US. However, Olibr doesn’t believe in geographical limitations and aims to connect top talent with top US companies.",
                },
              },
              {
                "@type": "Question",
                name: "How is Olibr different from other remote job websites?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Our committed approach to finding you full-time remote placements, with long-term professional security and career growth, in top US firms makes us different from others. We also offer extra perks and benefits such as medical insurance, paid vacation, performance incentives, and more that make the remote work experience a successful one.",
                },
              },
            ],
          }
        )}
      </script>
    </Helmet>
  );
};

export default AboutUs;
