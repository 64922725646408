export const testimonialContent = [
    {
      id: 1,
      feedback: "Outstanding Support!",
      feedbackText: `I haven't faced any challenges using Olibr. Even if you encounter any, the team is incredibly cooperative. If you provide them with a clear job description, they promptly post your job. As a result, you receive a substantial number of relevant applicants.`,
      // avatar: "src/assets/img/user-img.jpg",
      avatar: "https://ik.imagekit.io/olibr/assets/tr:w-70,h-70/samvida-search.png",
      name: "Priyanka Sharma",
      designation: "Samvida Search",
    },
    {
      id: 2,
      feedback: "Great Quality!",
      feedbackText: `Nice portal to find the technology and experience candidates. With score card in every skillset is a plus. Should recommend all of the recruiter to go ahead with this as I am getting the profile relevant in all aspects.`,
      avatar: "https://ik.imagekit.io/olibr/assets/tr:w-70,h-70/nsr.png",
      name: "Jamuna Singh",
      designation: "NSR Information Systems",
    },
    {
      id: 3,
      feedback: "Exceptionally Streamlined!",
      feedbackText: `I'm thrilled to share my satisfaction with Olibr's exceptional services in our recent hiring efforts. As a Hiring Manager, finding the right talent is paramount, and Olibr has been a game-changer.\n
 
      From the start, Olibr's hiring tool streamlined our recruitment process with its intuitive interface and robust features. It simplified collaboration, making the entire hiring journey efficient.
       
      The Olibr team's support and responsiveness were exemplary. They promptly addressed queries and ensured a positive experience.
       
      In conclusion, I wholeheartedly recommend Olibr to organizations seeking to enhance their hiring process. Its innovative features and outstanding support have been instrumental in securing top talent. Olibr is our invaluable partner in building a high-performing workforce.
       
      Thank you, Olibr, for revolutionizing our hiring approach!`,
      avatar: "https://ik.imagekit.io/olibr/assets/tr:w-70,h-70/journyfront.png",
      name: "Ganesh Pasarkar",
      designation: "Journyfront",
    },
  ];
  