import React from 'react'
import { Fragment } from 'react'
import PropTypes from "prop-types"
const VideoModal = ({link}) => {
        return (
          <Fragment>
              <iframe
                src={link ? link : ""}
                width="100%"
                height="500"
                className='modal-body-two margin-top-20'
              ></iframe>
          </Fragment>
      
  )
}
VideoModal.propTypes={
    link:PropTypes.string
}
export default VideoModal