import DateFormate from "../../../common/DateFormate";
import { DateSortingDataTable } from "../../../components/functions";

const ColumnLists = (emailSlugs) => {
  const columns = [
    {
        name: "S.No",
        selector: "sNo",
        cell:(row,index)=>index+1,
        width:"100px"
    }, {
        name: "To",
        selector: "userId.email",
        sortable: true,
        cell:(row)=> !!row?.supportEmail ? row?.supportEmail : row?.userId?.email
    }, {
        name: "Email Type",
        selector: "slug",
        sortable: true,
        cell:(row)=>emailSlugs[row?.slug]

    }, {
        name: "Sent On",
        selector: "sentAt",
        sortable: true,
        cell:(row)=>DateFormate(row?.sentAt,"withTime"),
        sortFunction:(a,b)=> DateSortingDataTable(a,b,"sentAt"),
    },
   
];
return columns;
}

export default ColumnLists