import React, { Fragment, useEffect, useState } from "react";

const JobDetailSkill = ({ skill, stack }) => {
  const [requiredSkill, setRequiredSkill] = useState([]);
  const [optionalSkill, setOptionalSkill] = useState([]);

  useEffect(() => {
    let tempRqrSkill = [];
    let tempOptnSkill = [];
    skill?.map((itm) => {
      if (itm?.isRequired) {
        tempRqrSkill.push(itm);
      } else {
        tempOptnSkill.push(itm);
      }
    });
    setOptionalSkill(tempOptnSkill);
    setRequiredSkill(tempRqrSkill);
  }, [skill]);
  return (
    <Fragment>
     {(!!optionalSkill?.length || !!requiredSkill?.length )&& <div className="job-list-skill">
       {( !!requiredSkill?.length ) && <div className="row">
          <div className="col-2">
            <h5 className="heading">Must Clear All</h5>
          </div>
          <div className="col-10">
            <ul className="icon-tags">
              {requiredSkill?.map((item, index) => (
                <li key={item?.skillId}>
                  <a>
                    <img src={item?.skillTestIcon} alt="" /> {item?.skillName}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>}
        {(!!optionalSkill?.length && !!requiredSkill?.length ) && (
          <div className="custom-skill-circle text-center">
            <span class="circle" >AND</span>
            <hr />
          </div>
        )}

       {!!optionalSkill?.length && <div className="row" style={{marginTop:(!!optionalSkill?.length && !!requiredSkill?.length ) && "13px"}}>
          <div className="col-2">
            <h5 className="heading">Must Clear Any One</h5>
          </div>
          <div className="col-10">
            <ul className="icon-tags">
              {optionalSkill?.map((item, index) => (
                <li key={item?.skillId}>
                  <a>
                    <img src={item?.skillTestIcon} alt="" /> {item?.skillName}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>}
      </div>}
      {(!!stack?.length &&  (!!optionalSkill?.length || !!requiredSkill?.length)) && (<div className="custom-skill-circle text-center">
        <span class="circle">OR</span>
        <hr />
      </div>)}
      {!!stack?.length && <div className="job-list-skill" style={{ marginTop: 18 }}>
        <div className="row">
          <div className="col-2">
            <h5 className="heading">Must Clear Any One</h5>
          </div>
          <div className="col-10">
            <ul className="icon-tags">
              {stack?.map((item, index) => (
                <li key={item?.stackId}>
                  <a>
                    <img src={item?.stackIcon} alt="" /> {item?.stackName}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>}
    </Fragment>
  );
};

export default JobDetailSkill;
