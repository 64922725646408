import Button from "../common/Button";
import { Icon } from "../icon";
import "./ModalBox.css";
import { ClipLoader } from "react-spinners";

const Modal = ({
  title,
  titleImage,
  onDone,
  onCancel,
  onClose,
  onDelete,
  onPrevious,
  isReachedOnFirst,
  isReachedOnLast,
  onNext,
  open,
  content,
  isTest = false,
  isEnglishQuestionsLoading,
  isCancelAble = true,
  isCloseAble = true,
  isFooterView = true,
  isDeletable = false,
  hasHeader = true,
  size = "60%",
  confirmDelete = false,
  isDeleteLoading = false,
  buttonTitle = "Done",
  isPreNext = false,
  isDeleteIcon = false,
  isDoneLoading = false,
  contentClass="",
  zIndex=1060
}) => {
  return (
    <div className={`modal fade ${open ? "show" : ""}`} style={{zIndex}}>
      <div className="modal-dialog">
        {/* <div className="modal-content" style={{ width: size, margin: "auto" }}> */}
        <div className="modal-content" style={{ width: size, margin: "auto" }}>
          {hasHeader && (
            <div className="modal-header">
              {isPreNext ? (
                <div className="pre-next-btn">
                  <span onClick={onPrevious}>
                    {isReachedOnFirst ? "" : "< PREVIOUS"}
                  </span>
                  <h4 className="modal-title">{title}</h4>
                  <span onClick={onNext}>
                    {isReachedOnLast ? "" : "NEXT >"}
                  </span>
                </div>
              ) : (
                <>
                  {titleImage && (
                    <img
                      src={titleImage}
                      alt=""
                      height={35}
                      style={{
                        border: "1px solid #e2e2e2",
                        borderRadius: "50px",
                        padding: "2px",
                        marginRight: "10px",
                      }}
                    />
                  )}
                  <h4 className="modal-title">{title}</h4>
                </>
              )}
              {isCloseAble && (
                <div className="btn-close-box flex-row-item">
                  {(isDeletable || confirmDelete) && isDeleteIcon && (
                    <Icon name="delete" onClick={onDelete} />
                  )}
                  <Button
                    type="button"
                    className="btn-close"
                    onClick={onClose}
                    label={<Icon name="modal-close" />}
                  />
                </div>
              )}
            </div>
          )}
          {content && <div className={`modal-body ${contentClass}`}>{content()}</div>}
          {isFooterView && (
            <div className="modal-footer">
              <div className="bottom-button-modal">
                {(isDeletable || confirmDelete) && !isDeleteIcon && (
                  <a className="cancelBtn" onClick={onDelete}>
                    {isDeleteLoading ? (
                      <ClipLoader size={15} color="#F15D3B" />
                    ) : (
                      "Delete"
                    )}
                  </a>
                )}
                {isCancelAble && (
                  <a className="cancelBtn" onClick={onCancel}>
                    Cancel
                  </a>
                )}
                {!confirmDelete && (
                  <a className="submitBtn themeButton" onClick={onDone}>
                    {isTest ? (
                      <>
                        {isEnglishQuestionsLoading ? (
                          <ClipLoader size={15} color="#FFFFFF" />
                        ) : (
                          "Start"
                        )}
                      </>
                    ) : isDoneLoading ? (
                      <ClipLoader size={15} color="#FFFFFF" />
                    ) : (
                      buttonTitle
                    )}
                  </a>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
