import React from "react";

const ReviewHead = () => {
  return (
    <thead>
      <tr>
        <th className="column">
          <div className="serialNumber">S.No</div>
        </th>
        <th className="column">
          <div className=" left-aligned">Name</div>
        </th>
        <th className="column">
          <div className=" left-aligned">Email</div>
        </th>
        <th className="column">
          <div className=" left-aligned">Designation</div>
        </th>
        <th className="column">
          <div className=" left-aligned">Company Name</div>
        </th>
        <th className="column">
          <div className=" left-aligned">Status</div>
        </th>
        <th className="column">
          <div className=" left-aligned">Action</div>
        </th>
      </tr>
    </thead>
  );
};

export default ReviewHead;
