import React, { Fragment, useEffect, useState } from "react";
import downArrow from "../../../assets/img/icons/down-arrow.png";
import arrowRight from "../../../assets/img/icons/arrow-right.png";
import { checkExistAllSkill } from "../../functions";

const SkillLists = ({
  handleSkillOrStack,
  handleSkillSelected,
  handleSkillClass,
  skillWithCategory,
  selectedSkill,
  allSkill,
}) => {
  const [openIndex, setOpenIndex] = useState([]);
  const handleOpeClose = (index) => {
    let checkindex = openIndex.includes(index);
    if (checkindex) {
      let filters = openIndex.filter((itm) => itm != index);
      setOpenIndex(filters);
    } else {
      setOpenIndex([...openIndex, index]);
    }
  };
  const [allLists, setAllLists] = useState([]);
  useEffect(() => {
    let others = [];
    let withoutCat = [];
    skillWithCategory?.map((itm, index) => {
      let cat = Object?.keys(itm)?.[0];
      if (cat?.toLocaleLowerCase()?.includes("other")) {
        others = itm;
      } else {
        withoutCat.push(itm);
      }
    });
    if (Object?.keys(others)?.length) {
      withoutCat.push(others)
    }
    setAllLists(withoutCat);
  }, [skillWithCategory]);

  return (
    <Fragment>
      {!!allLists?.length && (
        <div className="container-fluide">
          <div className="row">
            <div className="col-6">
              <h3>Skills</h3>
            </div>
            <div className="col-6 text-right">
              <h3 className="select-text">
                <span>
                  <input
                    type="checkbox"
                    checked={checkExistAllSkill(selectedSkill, allSkill)}
                    value={checkExistAllSkill(selectedSkill, allSkill)}
                    onChange={() => handleSkillOrStack("all-skill")}
                  />
                </span>
                SELECT ALL
              </h3>
            </div>
          </div>
        </div>
      )}
      {allLists?.map((itm, index) => (
        <div className="result-box">
          <div className="row">
            <div className="col-6" onClick={() => handleOpeClose(index)}>
              <h3>{Object?.keys(itm)?.[0]}</h3>
            </div>
            <div
              className="col-6 text-right"
              onClick={() => handleOpeClose(index)}
            >
              <span className="collapse-button-opens">
                <img
                  src={openIndex?.includes(index) ? downArrow : arrowRight}
                />
              </span>
            </div>
            <div
              className="col-12 open-skill-details"
              style={{ display: openIndex?.includes(index) ? "" : "none" }}
            >
              <ul>
                {itm[Object?.keys(itm)?.[0]]?.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => handleSkillSelected(item?._id, "all-skill")}
                    className={handleSkillClass(item?._id)}
                    style={{ cursor: "pointer" }}
                  >
                    <span>
                      <img src={item?.skillTestIcon} alt="AWS Lambda Logo" />
                      {item?.skillName}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default SkillLists;
