
const AboutUs = ({ data }) => {
  const coverTemplate = data?.coverTemplate;
  return (
    data?.aboutCompany && (
      <div className="about-section">
        <div className="about-banner">
          <img
            src={
              coverTemplate == "dark-blue" ? "https://ik.imagekit.io/olibr/assets/tr:w-650,h-650/dark-blue.svg" : coverTemplate == "blue" ? "https://ik.imagekit.io/olibr/assets/tr:w-650,h-650/about-blue.svg" : "https://ik.imagekit.io/olibr/assets/tr:w-650,h-650/about-sky.svg"
            }
            alt="About Banner"
            height={"650px"}
            width={"650px"}
          />
        </div>
        <div className="about-content">

          <h2>
            <span className="section-category">About Us</span>
          </h2>
          <div className="row">
            {!!data?.industryDomain && (
              <div className="col-8">
                <p>  <span className="company-title">Industry domain : </span>{data?.industryDomain} </p>
              </div>
            )
            }

            {
              !!data?.foundedIn && (
                <div className="col-4">
                  <p>  <span className="company-title">Founded : </span>{new Date(data?.foundedIn)?.getFullYear()} </p>
                </div>
              )
            }
            {
              data?.companySize && (
                <div className="col-6">
                <p>  <span className="company-title">Company Size : </span>{data?.companySize} </p>
              </div>
              )
            }
         
          </div>
          <p>{data?.aboutCompany}</p>
        </div>
      </div>
    )
  );
};
export default AboutUs;
