import React, { Fragment, useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./Stacks.css";
import { ScaleLoader } from "react-spinners";
import {
  getAdminStackLists,
  getAdminSkillLists,
  updateStackStatus,
  editStack,
  addStack,
  deleteStack,
  uploadIcons,
  getSkillTest,
  renderAllHackerrankAssessments,
  getSkillCategory,
  getStackWithCategory,
  updateSkillsCategory,
} from "../../../../service/action/admin";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "../../../../components/icon";
import Modal from "../../../../components/modal/ModalBox";
import { olibrToast } from "../../../../components/toaster";
import { Controller, useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import SelectOption from "../../../../components/select/SelectOption";
import DataTables from "../../../../components/data-tables/DataTables";
import ColumnLists from "./ColumnLists";
import { MainContext } from "../../../../context/MainContext";
import { checkPermission, readOnlyCheck } from "../../../../components/functions";
import { isCoreArray } from "../../../developer/review/constants";
import OlibrEditor from "../../../../components/editor";

const Stacks = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    stackList,
    isStackLoading,
    skillList,
    assessmentList,
    skillIcons,
    isIconUploading,
    testSkills,
    skillCategory,
    stackWithCategory,
    isStackSaveLoading
  } = useSelector((state) => state.adminReducer);

  const {
    register: stackRegister,
    handleSubmit: stackSubmit,
    setValue: setStackValue,
    getValues: getStackValue,
    control: stackControll,
    reset: resetStackForm,
    formState: { errors: stackError },
  } = useForm();

  const uploadStackIconRef = useRef();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [assessmentLists, setAssessmentList] = useState([]);
  const { user: { access } } = useContext(MainContext)
  const [editorText,setEditorText]=useState("")
  const [error,setError]=useState([])
  const [pageData, setPageData] = useState({
    page: 1,
    limit: 13,
    search: "",
  });
  const [groupDetails, setGroupDetails] = useState({
    modal: false,
    edit: false,
    name: "",
    id: "",
  })

  const [deleteIdModal, setDeleteIdModal] = useState({
    deleteModalOpen: false,
    deleteId: "",
  });
  const [stackObj, setStackObj] = useState({
    stackIcon:
      "https://e7.pngegg.com/pngimages/980/714/png-clipart-computer-icons-code-symbol-coding-miscellaneous-angle.png",
  });
  const filterSkills = (selectSkills) => {
    let result = []
    for (let x of selectSkills) {
      let tempskills = skillList?.data?.find((itm) => itm?._id == x)
      if (tempskills) {
        result?.push(tempskills)
      }

    }
    return result;
  }
  const openModal = () => {
    resetStackForm();
    setEditorText("")
    setError([])
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
    resetStackForm();
    //here it was true earlier but that was causing a bug that if we immediately add stack after editing a stack, it remained edit, not add.
    isEdit && setIsEdit(false);
    setStackObj({
      stackIcon:
        "https://e7.pngegg.com/pngimages/980/714/png-clipart-computer-icons-code-symbol-coding-miscellaneous-angle.png",
    });
  };
  const [search, setSearch] = useState("")

  const handleEdit = (stack, category) => {
    resetStackForm();
    setIsEdit(true);
    setEditorText("")
    setError([])
    setStackValue("stackName", stack?.stackName);
    setStackValue("isCore", isCoreArray?.find((item) => item?.value === stack?.isCore));
    setStackValue("stack", {
      value: stack?.aId,
      label: stack?.aName,
    });
    let tempObj = {}
    skillCategory?.map((item) => {
      if (item?.name == category) {
        tempObj = {
          value: item?._id,
          label: item?.name
        }
      }
    })
    setStackValue("category", tempObj);



    const tempSkill = filterSkills(stack?.skills)?.map((item) => ({
      value: item?._id,
      label: item?.skillName,
    }));
    setStackValue("skills", tempSkill);
    setStackValue("stackTestTime", stack?.stackTestTime);
    setStackValue("maxScore", stack?.maxScore);
    setStackValue("cutOffScore", stack?.cutOffScore);
    setEditorText(stack?.description)
    setStackObj({
      stackId: stack?._id,
      stackIcon: stack?.stackIcon,
    });

    setDeleteIdModal({
      ...deleteIdModal,
      deleteId: stack?._id,
    });
    setModal(true);
  };

  const updateStatus = async (skill, category) => {
    let statusValue = "Inactive";
    if (skill?.stackStatus !== "Active") {
      statusValue = "Active";
    }
    const newData = {
      stackId: skill?._id,
      stackStatus: statusValue,
    };
    dispatch(updateStackStatus(newData, navigate, category));
  };
  useEffect(() => {
    dispatch(getAdminSkillLists("skill", navigate));
    dispatch(renderAllHackerrankAssessments(navigate));
    dispatch(getSkillTest(navigate));
    dispatch(getSkillCategory(navigate))
    dispatch(getStackWithCategory(navigate))
  }, [dispatch, navigate]);

  // useEffect(() => {
  //   dispatch(getAdminStackLists("stack", navigate, pageData));
  // }, [pageData.page, pageData.limit]);

  useEffect(() => {
    if (assessmentList && testSkills) {
      let tempAssess = [];
      let testSkill = [];
      assessmentList?.map((item) => {
        tempAssess?.push({ value: item?.id, label: item?.name });
      });

      testSkills?.map((item) => {
        testSkill?.push({ value: item?.id, label: item?.name });
      });
      let arr = [...tempAssess, ...testSkill];
      arr.sort((a, b) => {
        let fa = a?.label?.toLowerCase(),
          fb = b?.label?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      setAssessmentList(arr);
    }
  }, [assessmentList, testSkills]);

  const handleSubmit = (data) => {
    if(!editorText ||  editorText=="<p><br></p>"){
      setError(["description"])
      return false
    }else{
      setError([])
    }
    const newData = {
      ...stackObj,
      isCore: data?.isCore?.value,
      category: data?.category?.value,
      stackTestTime: data?.stackTestTime,
      stackName: data?.stackName,
      maxScore: data?.maxScore,
      cutOffScore: data?.cutOffScore,
      skills: data?.skills.map((item) => item.value),
      assessmentId: data?.stack?.value?.toString(),
      assessmentName: data?.stack?.label,
      description:editorText,
    };
    isEdit
      ? dispatch(
        editStack(
          newData,
          navigate,
          closeModal,
        )
      )
      : dispatch(
        addStack(
          newData,
          navigate,
          closeModal
        )
      );
  };


  const deleteStackModalOpen = () => {
    setModal(false);
    setDeleteIdModal({
      ...deleteIdModal,
      deleteModalOpen: true,
    });
  };

  const deleteStackModalClose = () => {
    setDeleteIdModal({
      deleteModalOpen: false,
      deleteId: "",
    });
  };

  const handleStackDelete = () => {
    dispatch(deleteStack(deleteIdModal.deleteId));
    deleteStackModalClose();
    closeModal();
  };

  const onUploadIcon = async (e) => {
    let formData = new FormData();
    formData.append("imageFile", e.target.files[0]);
    if (e.target.files[0]?.size > 1024 * 1024) {
      olibrToast.error("Please use image of size less than 1MB");
    } else {
      dispatch(uploadIcons(formData, navigate));
    }
    e.target.value = null;
  };

  const handleNext = () => {
    setPageData({ ...pageData, page: pageData.page + 1 });
  };
  const handlePrev = () => {
    setPageData({ ...pageData, page: pageData.page - 1 });
  };

  const deleteContent = () => {
    return (
      <>
        <div className="form-group">
          <p>Are you sure you want to delete this Stack?</p>
        </div>
      </>
    );
  };

  const editCategory = (category) => {
    let catId;
    skillCategory?.map((item) => {
      if (item?.name == category) {
        catId = item?._id
      }
    })
    setGroupDetails({
      id: catId,
      edit: true,
      name: category,
      modal: true
    })

  }


  const handleContentFunc = () => {
    return (
      <>
        <div className="form-group">
          <label
            htmlFor=""
            className={`${stackError?.stackName && "text-danger"} new-label`}
          >
            Stack Name*
          </label>
          <input
            {...stackRegister("stackName", {
              required: true,
            })}
            type="text"
            className="form-control"
          />
        </div>
        <div className="form-group select-group">
          <label
            htmlFor=""
            className={`${stackError?.skills && "text-danger"} new-label`}
          >
            Select skills*
          </label>
          <Controller
            control={stackControll}
            name="skills"
            {...stackRegister("skills", {
              required: true,
            })}
            render={({ field }) => (
              <SelectOption
                isMulti={true}
                optionArray={
                  skillList?.data?.length > 0
                    ? skillList?.data?.map((item) => ({
                      value: item?._id,
                      label: item?.skillName,
                    }))
                    : []
                }
                fieldProp={field}
                getValue={getStackValue}
                getValueName={"skills"}
                closeMenuOnSelect={false}
                searchable={true}
              />
            )}
          />
        </div>
        <div className="form-group select-group">
          <label
            htmlFor=""
            className={`${stackError?.stack && "text-danger"} new-label`}
          >
            Select skills test associated with*
          </label>
          <Controller
            control={stackControll}
            name="stack"
            {...stackRegister("stack", {
              required: true,
            })}
            render={({ field }) => (
              <Fragment>
                <SelectOption
                  // isMulti={true}
                  optionArray={assessmentLists}
                  fieldProp={field}
                  getValue={getStackValue}
                  getValueName={"stack"}
                  closeMenuOnSelect={true}
                  searchable={true}
                />
              </Fragment>
            )}
          />
        </div>
        <div className="form-group select-group">
          <label className="new-label">Category</label>
          <Controller
            control={stackControll}
            name="category"
            {...stackRegister("category")}
            render={({ field }) => (
              <SelectOption
                optionArray={skillCategory?.map((item) => ({
                  value: item?._id,
                  label: item?.name,
                }))}
                fieldProp={field}
                getValue={getStackValue}
                getValueName={"category"}
                closeMenuOnSelect={true}
                searchable={true}
              />
            )}
          />
        </div>
        <div className="form-group select-group">
          <label className={`new-label ${stackError?.isCore && "text-danger"
            }`}>Select Type</label>
          <Controller
            control={stackControll}
            name="isCore"
            {...stackRegister("isCore")}
            render={({ field }) => (
              <SelectOption
                optionArray={isCoreArray}
                fieldProp={field}
                getValue={getStackValue}
                getValueName={"isCore"}
                closeMenuOnSelect={true}
                searchable={true}
              />
            )}
          />
        </div>
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <label
                htmlFor=""
                className={`${stackError?.stackTestTime && "text-danger"
                  } new-label`}
              >
                Stack Test Time*
              </label>
              <input
                {...stackRegister("stackTestTime", {
                  required: true,
                })}
                name="stackTestTime"
                type="number"
                min={0}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label
                htmlFor=""
                className={`${stackError?.maxScore && "text-danger"
                  } new-label`}
              >
                Max score*
              </label>
              <input
                {...stackRegister("maxScore", {
                  required: true,
                })}
                name="maxScore"
                type="number"
                min={0}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label
                htmlFor=""
                className={`${stackError?.cutOffScore && "text-danger"
                  } new-label`}
              >
                Cutoff score*
              </label>
              <input
                {...stackRegister("cutOffScore", {
                  required: true,
                })}
                name="cutOffScore"
                type="number"
                min={0}
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="" className={`floating-label ${error?.includes("description") && "text-danger"}`}>
            Description *
          </label>
          <OlibrEditor
            value={editorText ? editorText : ""}
            onChange={(e) => setEditorText(e)}
          />
        </div>


        <div className="row-flex">
          <div className="form-group select-group">
            <label className="new-label">Choose icon</label>
            <SelectOption
              optionArray={skillIcons?.map((icon) => ({
                value: icon,
                label: (
                  <div style={{ width: "200px", display: "flex" }}>
                    {" "}
                    <img src={icon} height="30px" width="30px" />{" "}
                  </div>
                ),
              }))}
              menuPlacement={"top"}
              state={{
                value: stackObj?.stackIcon,
                label: (
                  <div style={{ width: "200px", display: "flex" }}>
                    {" "}
                    <img
                      src={stackObj?.stackIcon}
                      height="30px"
                      width="30px"
                    />{" "}
                  </div>
                ),
              }}
              closeMenuOnSelect={false}
              onChangeFunction={onIconSelect}
            />
          </div>
          <a
            className="file-icon"
            onClick={() => uploadStackIconRef.current.click()}
          >
            {isIconUploading ? (
              <ScaleLoader color="#3BA0AA" height={10} />
            ) : (
              "Upload icon"
            )}
          </a>
        </div>
      </>
    );
  };

  const handleSearch = (e) => {
    setSearch(e.target.value)
  };


  const onIconSelect = (e) => {
    setStackObj({ ...stackObj, stackIcon: e.value });
  };

  const handleAddCategory = () => {
    let tempObj = { categoryId: groupDetails?.id, categoryName: groupDetails?.name }
    dispatch(updateSkillsCategory(navigate, tempObj, setGroupDetails))



  }

  const groupContent = () => (
    <>
      <div className="form-group">
        <label
          htmlFor=""
          className={`floating-label`}
        >
          Group Name
        </label>
        <input
          value={groupDetails?.name}
          onChange={(e) => setGroupDetails({
            ...groupDetails,
            name: e.target.value
          })}
          type="text"
          className="form-control"
        />
      </div>
    </>
  )


  return (
    <Fragment>
      <div className="main-div">
        <input
          ref={uploadStackIconRef}
          type="file"
          onChange={onUploadIcon}
          className="input-file"
          accept=".png, .jpeg, .jpg, .svg"
        />
        <Modal
          open={deleteIdModal.deleteModalOpen}
          title={"Delete Stack"}
          onClose={deleteStackModalClose}
          onDone={handleStackDelete}
          onCancel={deleteStackModalClose}
          content={deleteContent}
        />

        <div className="skills-management">
          <div className="block-header">
            <h4 className="">Stacks</h4>
            <span onClick={
              () => {
                if (checkPermission(readOnlyCheck(access, "Skills Management"))) return false
                openModal()
              }}>
              {" "}
              <Icon name="add-icon" />{" "}
            </span>
          </div>
          <div className="row">
            <div className="col-8"></div>
            <div className="search-block col-4">
              <input
                type="text"
                className="form-control"
                value={search}
                placeholder="Search Stacks..."
                onChange={handleSearch}
              />
              <a className="search-btn">
                <Icon name="search-icon" />
              </a>
            </div>
          </div>
          <br />
          <div className="table-div">
            <table className="table">
              <thead>
                <tr>
                  <th className="column">
                    <div className="serialNumber">
                      S.No
                    </div>
                  </th>
                  <th className="column">Name</th>
                  <th className="column">Skill</th>
                  <th className="column">Status</th>
                </tr>
              </thead>
              <tbody>
                {
                  stackWithCategory?.map((itm, index) => (
                    <>
                      <tr key={index} className="faqdiv" onClick={() => {
                        if (checkPermission(readOnlyCheck(access, "Skills Management"))) return false
                        editCategory(Object?.keys(itm)?.[0])
                      }}
                      >
                        <td className="titleQues sno" ><b>{index + 1}</b></td>
                        <td className="titleQues"><b>{Object?.keys(itm)?.[0]}</b></td>
                      </tr>

                      {
                        itm[Object?.keys(itm)?.[0]]?.filter(
                          (items) => items?.stackName?.toLowerCase()?.includes(search?.toLowerCase()) || items?.aName?.toLowerCase()?.includes(search?.toLowerCase())
                        )?.map((skill) => (
                          <tr onClick={() => {
                            if (checkPermission(readOnlyCheck(access, "Skills Management"))) return false
                            handleEdit(skill, Object?.keys(itm)?.[0])
                          }}>
                            <td className="titleQues sno"></td>
                            <td className="titleQues">
                              <span> {skill?.stackName}</span>
                            </td>
                            <td className="titleQues" >
                              {
                                filterSkills(skill?.skills)?.map((skills) => (
                                  <button
                                    className="skillname stack-skillname"
                                  >
                                    <span>{skills?.skillName}</span>
                                    <Icon name="cancel-icon" />
                                  </button>
                                ))
                              }

                            </td>
                            <td className="titleQues" >
                              <span>
                                <input
                                  type="checkbox"
                                  name="switch"
                                  onChange={(e) => e.preventDefault()}
                                  checked={skill?.stackStatus === "Active"}
                                />
                                <label
                                  onClick={(e) => {
                                    if (checkPermission(readOnlyCheck(access, "Skills Management"))) return false
                                    e.stopPropagation();
                                    updateStatus(skill, Object?.keys(itm)?.[0]);
                                  }}
                                  className="label"
                                  htmlFor="switch2"
                                ></label>
                              </span>
                            </td>

                          </tr>
                        ))
                      }
                    </>
                  ))}
              </tbody>
            </table>
          </div>
          {/* <DataTables
            columns={ColumnLists(updateSatus)}
            fetchMoreData={fetchMoreData}
            listLoading={isStackLoading}
            listData={stackList}
            Infinit={true}
            onRowClick={(row) => handleEdit(row)}
          /> */}

          <Modal
            open={groupDetails?.modal}
            title={`${groupDetails?.edit ? "Edit" : "Add"} Group Name`}
            content={groupContent}
            onClose={() => setGroupDetails({})}
            onCancel={() => setGroupDetails({})}
            onDone={handleAddCategory}
          />

          <Modal
            open={modal}
            title={`${isEdit ? "Edit" : "Add"} Stack`}
            onDone={stackSubmit(handleSubmit)}
            onClose={closeModal}
            onCancel={closeModal}
            isDeletable={isEdit}
            isDeleteIcon={true}
            onDelete={deleteStackModalOpen}
            content={handleContentFunc}
            isDoneLoading={isStackSaveLoading}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Stacks;
