import React from "react";
import PropTypes from "prop-types";
const EducationSection = ({ devAcademic }) => {
  return (
    !!devAcademic?.length && (
      <div className="resume-outer theme-red">
        <div className="upper-title">
          <h4>Education</h4>
        </div>
        {devAcademic?.map((itm, id) => (
          <div key={id} className="resume-block">
            <div className="inner">
              {/* <span className="name">{itm?.institution[0]}</span> */}
              <span className="name">
                {itm?.institution ? itm.institution[0] : itm.specialization[0]}
              </span>
              <div className="title-box">
                <div className="info-box">
                  <h3>{itm?.specialization}</h3>
                  <span>{itm?.institution}</span>
                </div>
                <div className="edit-box">
                  <span className="year">
                    {!!itm?.startDate &&
                      new Date(itm?.startDate)?.getFullYear()}{" "}
                    {!!itm?.endDate && " - "}{" "}
                    {!!itm?.endDate && new Date(itm?.endDate)?.getFullYear()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}

        {/* <!-- End Resume BLock --> */}
      </div>
    )
  );
};
EducationSection.propTypes = {
  devAcademic: PropTypes.object,
};
export default EducationSection;
