import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import SlabCard from "./SlabCard";
import { getPricingSlabAction } from "../../../../service/action/admin";

const PricingSlabList = ({setPricingSlab,setData}) => {
    const {pricingSlab}=useSelector((state)=>state.adminReducer)
  const dispatch = useDispatch();
  const navigate=useNavigate();

  useEffect(()=>{
    dispatch(getPricingSlabAction(navigate))
  },[])

  return  (
    <Fragment>
       <div className="row" id="candidate">
        <div className="col-12">
          <div
            className={`dash-block shortlisted-wrap padding-unset`}
          >
            <div className="card">
                {/* <div className="card-header job-head">
                  <h4>job detail</h4>
                  
                  <div className="header-right">
                    <a
                      className="link"
                      href="#-"
                    >
                      Add New Job
                    </a>
                  </div>
                </div> */}
                   {pricingSlab?.map((itm)=><SlabCard setData={setData} setPricingSlab={setPricingSlab} data={itm}/>)}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PricingSlabList;
