import { Image, Text, View } from '@react-pdf/renderer'
import React from 'react'
import { SkillResultCss } from '../css/SkillResultCss'
import hackerRank from "../../../../assets/img/icons/hackerrank_logo.png";
import hackerEarth from "../../../../assets/img/logos/hackerr_earth_test.png";
import VettedSkillIcon from "../../../../assets/img/icons/badge-icon.png";
import { awsPngToImage, findSkillTime, getPercentage } from '../../../functions';
import DateArea from './DateArea';
import TestScore from './TestScore';

const SkillResult = ({data,skillList,stackLists}) => {

    const fetchIconByTestId = (iconId) => {
        let url = skillList?.find((itm) => itm?._id == iconId) || stackLists?.find((itm) => itm?._id == iconId)
        return url = url?.skillTestIcon || url?.stackIcon
    }

    const brandLogo=data?.isHackerearth?hackerEarth:hackerRank

    return (
        <View style={SkillResultCss.container}>
            <View style={SkillResultCss.header}>
                <View style={SkillResultCss.itemsLeft}>
                    <Image source={VettedSkillIcon} style={SkillResultCss.vettedSkillIcon} />
                    <View style={SkillResultCss.itemsNestedLeft}>
                    {awsPngToImage(fetchIconByTestId(data?.subject)) &&  (<Image source={awsPngToImage(fetchIconByTestId(data?.subject))} style={SkillResultCss.leftSkillIcon} />)}
                    </View>
                    <View style={SkillResultCss.itemsNestedRight}>
                        <Text style={SkillResultCss.skillTitle}>{data?.testName}</Text>
                    </View>
                </View>
                <View style={SkillResultCss.itemsRight}>
                    <View style={SkillResultCss.itemsNestedRightLogo}>
                        <Image source={brandLogo} style={SkillResultCss.brandLogo} />
                    </View>
                </View>
            </View>
            <View style={SkillResultCss.mainWhiteBox}>
                <View style={SkillResultCss.leftWhiteBox}>
                    <View style={SkillResultCss.resultCirlce}>
                        <Text style={SkillResultCss.resultCirlceScore}>{parseInt(data?.obtainedScore)}/{parseInt(data?.maxScore)}</Text>
                    </View>
                </View>
                <View style={SkillResultCss.rightWhiteBox}>
                    <DateArea date={data}/>
                    
                    <View style={{marginTop:"-15px"}}>
                    <View style={SkillResultCss.timeArea}>
                        <View>
                            <Text style={SkillResultCss.times}>Time :  
                            <Text style={{ color: "#f0a039" }}>{findSkillTime(data?.testDuration || data?.subject,skillList,stackLists)} </Text>
                            </Text>
                            </View>
                    </View>
                        <TestScore title="Test Score" width={100} obtainedScore={data?.maxScore}/>
                        <TestScore title="Your Score" width={data?.percentageScore} obtainedScore={data?.obtainedScore}/>
                        {
                            getPercentage(data?.cutOffScore, data?.maxScore) && (
                                <TestScore title="Cutoff Score" width={getPercentage(data?.cutOffScore, data?.maxScore)} obtainedScore={data?.cutOffScore}/>
                            )
                        }
                      
                        <TestScore title="Total" bg={"#72bf44"} width={data?.percentageScore} obtainedScore={parseInt(data?.percentageScore)+"%"} />
                    </View>
                </View>
            </View>

        </View>
    )
}

export default SkillResult