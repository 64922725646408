import InstagramIcon from "./../../assets/img/company-profile/instagram.svg";
import TwitterIcon from "./../../assets/img/company-profile/twitter.svg";
import FacebookIcon from "./../../assets/img/company-profile/facebook.svg";
import EmailIcon from "./../../assets/img/company-profile/email.svg";
import PhoneIcon from "./../../assets/img/company-profile/telephone.svg";
import MapIcon from "./../../assets/img/company-profile/map.svg";
import YoutubeIcon from "./../../assets/img/company-profile/youtube.svg";
import LinkedinIcon from "./../../assets/img/company-profile/linkedin.svg";
import { filterWeb } from "../../common/FilterWebsite";
import { useEffect, useState } from "react";

const ContactUs = ({ data, clientData }) => {
  
  const onViewWebsite = (webUrl) => {
    const Web_Url = webUrl?.includes("http") ? webUrl : `https://${webUrl}`;
    webUrl && window.open(Web_Url);
  };
  const [clients,setClient]=useState({})

  useEffect(()=>{
   setClient(data?.clients?.[0])
  },[data])
  return (
    <div className="company-contact" id="contact">
      <h2>
        {(data?.companyWebsite ||
          clients?.number ||
          clientData?.companyAddress ||
          clients?.email) && (
          <span className="section-title">Contact Us</span>
        )}
        {data?.companyWebsite && (
          <a
            onClick={() => onViewWebsite(data?.companyWebsite)}
            href={`#`}
            className="link"
          >
            View Website
          </a>
        )}
      </h2>
      <div className="contact-details">
        <div className="contacts">
          {clients?.email && (
            <div className="contact">
              <figure>
                <img src={EmailIcon} alt="Email Address" />
              </figure>
              <div className="contact-detail">
                <h4>Email</h4>
                <a href="#">{clients?.email}</a>
              </div>
            </div>
          )}
          {clients?.number && (
            <div className="contact">
              <figure>
                <img src={PhoneIcon} alt="Contact Number" />
              </figure>
              <div className="contact-detail">
                <h4>Phone No.</h4>
                <div className="contact-no">
                  <a href="#">{clients?.number}</a>
                </div>
              </div>
            </div>
          )}
          {data?.companyAddress && (
            <div className="contact">
              <figure>
                <img src={MapIcon} alt="Address Location" />
              </figure>
              <div className="contact-detail">
                <h4>Address</h4>
                <address>{data?.companyAddress}</address>
              </div>
            </div>
          )}
        </div>
        <ul className="social-links">
          {data?.socialMediaLinks?.youtube && (
            <li>
              <a href={`${filterWeb(data?.socialMediaLinks?.youtube)}`}>
                <img src={YoutubeIcon} alt="" />
              </a>
            </li>
          )}
          {data?.socialMediaLinks?.linkedIn && (
            <li>
              <a href={`${filterWeb(data?.socialMediaLinks?.linkedIn)}`}>
                <img src={LinkedinIcon} alt="" />
              </a>
            </li>
          )}
          {data?.socialMediaLinks?.instagram && (
            <li>
              <a href={`${filterWeb(data?.socialMediaLinks?.instagram)}`}>
                <img src={InstagramIcon} alt="" />
              </a>
            </li>
          )}
          {data?.socialMediaLinks?.twitter && (
            <li>
              <a href={`${filterWeb(data?.socialMediaLinks?.twitter)}`}>
                <img src={TwitterIcon} alt="" />
              </a>
            </li>
          )}
          {data?.socialMediaLinks?.facebook && (
            <li>
              <a href={`${filterWeb(data?.socialMediaLinks?.facebook)}`}>
                <img src={FacebookIcon} alt="" />
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ContactUs;
