import { Fragment } from "react";
import JobLists from "../../components/joblists/JobLists";

const Hiring = ({ jobData }) => {
  return (
    <Fragment>
      {!!jobData?.data?.length && (
        <section className="hiring" id="jobs">
          <h2>
            <span className="section-category">We’re hiring</span>
            <span className="section-title">
              We believe that each one of us should be able to find our dream
              job
            </span>
          </h2>
          <JobLists
            mainJobList={jobData}
            appliedArr={[]}
            handleCheckBox={() => console.log("checknox")}
            hideCheckBox={true}
          />
        </section>
      )}
    </Fragment>
  );
};

export default Hiring;
