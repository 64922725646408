import React, { useEffect, useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import FailedTest from "../../assets/img/icons/failedTest.svg";
import ClearedTest from "../../assets/img/icons/clearedTest.svg";
import OlibrLogo from "../../assets/img/logo.png";
import { getAdminDevResult } from '../../service/action/admin';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import Modal from '../modal/ModalBox';
const SkillResult = (devId) => {
    const [skillResp, setSkillResp] = useState([]);
    const [stackResp, setStackResp] = useState([]);
    const [resultModal, setResultModal] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { skillTestLoading, resultDevData, skillList, stackList } = useSelector((state) => state.adminReducer)
    const [resultCard, setResultCard] = useState({});

    const [resultResp, setResultResp] = useState([]);
    useEffect(() => {
        setSkillResp(skillList?.data ? skillList?.data : []);
    }, [skillList]);

    useEffect(() => {
        setStackResp(stackList?.data ? stackList?.data : []);
    }, [stackList]);

    useEffect(() => {
        dispatch(getAdminDevResult(devId, navigate));
    }, [devId])

    useEffect(() => {
        let finalData = [];
        resultDevData?.data?.forEach((item) => {
            let findItem = {};

            finalData?.some((element, index) => {
                if (element?.testId === item?.testId 
                    // && element?.email === item.email
                     ) {
                    findItem = { element, index };
                    return true;
                }
            });

            if (findItem?.element) {
                if (finalData[findItem?.index]?.attemptCount < item?.attemptCount) {
                    finalData[findItem?.index] = item;
                }
            } else {
                finalData.push(item);
            }
        });

        let arrNew = [];
        arrNew = finalData?.filter(
            (item) => item?.status === "passed" || item?.status === "failed"
        );
        setResultResp(arrNew);
    }, [resultDevData]);


    const handleResultModal = (Skill) => {
        setResultModal(true);
        setResultCard(Skill);
    };

    const [title, setTitle] = useState("");
    const handleResultChangeModal = () => {
        setResultModal(false);
        setTitle("");
        setResultCard({});
    };

    const handleContent = (skill) => {
        setTitle(skill?.testName);
        return (
            <>
                <div id="skill-result">
                    <div className="olibr-brand">
                        <img src={OlibrLogo} alt="olibr logo" />{" "}
                    </div>
                    <div className="row">
                        <div className="skill-graph col-3">
                            <div className="skill-graph">
                                <div className="single-chart">
                                    <svg
                                        viewBox="0 0 36 36"
                                        className={`circular-chart ${skill?.isPassed ? "green" : "red"
                                            }`}
                                    >
                                        <path
                                            className="circle-bg"
                                            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
                                        />
                                        <path
                                            className="circle"
                                            stroke-dasharray={`${skill?.percentageScore}, 100`}
                                            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
                                        />
                                        <text x="18" y="20.35" className="percentage">
                                            {parseInt(skill?.obtainedScore)}/{skill?.maxScore}
                                        </text>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        {skill?.isPassed ? (
                            <Fragment>
                                <div
                                    style={{
                                        color: "#3ba0aa",
                                    }}
                                    className="col-8 pass"
                                >
                                    Congratulations! You nailed it!
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div
                                    style={{
                                        color: "#F15C3B",
                                    }}
                                    className="col-8 fail"
                                >
                                    {skill?.message}
                                </div>
                            </Fragment>
                        )}

                        <br />
                    </div>
                    <div className="skill-test-outer">
                        <>
                            <div className="row-result">
                                <span className="test-left-col summary">Summary</span>
                                <span className="test-right-col"></span>
                            </div>
                            <div className="row-result upper-line">
                                <span className="test-left-col">Maximum Score</span>
                                <span className="test-right-col">{skill?.maxScore}</span>
                            </div>
                            <div className="row-result upper-line">
                                <span className="test-left-col">Obtained Score</span>
                                <span className="test-right-col">{skill?.obtainedScore}</span>
                            </div>
                            <div className="row-result upper-line">
                                <span className="test-left-col">Date</span>
                                <span className="test-right-col">
                                {skill?.attemptEndedAt ? new Date(skill?.attemptEndedAt).toDateString().substring(4) : new Date(skill?.createdAt).toDateString().substring(4)}
                                </span>
                            </div>
                            <div className="row-result upper-line">
                                <span className="test-left-col">CutOff Score</span>
                                <span className="test-right-col">{skill?.cutOffScore}</span>
                            </div>
                            <div className="row-result upper-line">
                                <span className="test-left-col">Total</span>
                                <span
                                    className="test-right-col"
                                    style={{
                                        color: skill?.isPassed ? "#679B31" : "#F15C3B",
                                    }}
                                >
                                    {parseInt(skill?.percentageScore)} %
                                </span>
                            </div>
                        </>
                    </div>
                    <br />
                </div>
            </>
        );
    };

    return (
        <>
            <Modal
                open={resultModal}
                title={title}
                onClose={handleResultChangeModal}
                content={() => handleContent(resultCard)}
                isFooterView={false}
            />
            {
                skillTestLoading && (
                    <div style={{ textAlign: "center" }}>
                        {" "}
                        <ClipLoader color="#3BA0AA" />{" "}
                    </div>
                )
            }
            {
                !skillTestLoading && !resultResp?.length && (
                    <p>Not Attempt</p>
                )
            }
            <div className="choose-stack">
                <ul className="test-list">
                    {resultResp?.map((skill) =>
                        [...skillResp, ...stackResp]
                            ?.filter(
                                (items) =>
                                    skill?.testId == items?.aId ||
                                    skill?.testId == items?.assessmentId
                            )
                            ?.map((item) => (
                                <>
                                    <li
                                        key={item?._id}
                                        className="test-list"
                                        onClick={() => handleResultModal(skill, item)}
                                    >
                                        <div className="test-card">
                                            <div className="skill-img">
                                                <figure>
                                                    <img
                                                        src={
                                                            item?.skillTestIcon
                                                                ? item?.skillTestIcon
                                                                : item?.stackIcon
                                                        }
                                                        width={"50px"}
                                                        alt="skill"
                                                    />
                                                </figure>
                                            </div>
                                            <div className="skill-detail">
                                                <h5>
                                                    {item?.skillName
                                                        ? item?.skillName
                                                        : item?.stackName}
                                                </h5>
                                                <p>
                                                    {item?.skillTestTime
                                                        ? item?.skillTestTime
                                                        : item?.stackTestTime}{" "}
                                                    Minutes
                                                </p>
                                                <a
                                                    className="link"
                                                    style={{
                                                        color: !skill?.isPassed
                                                            ? "#F15C3B"
                                                            : "#679B31",
                                                    }}
                                                >
                                                    {!skill?.isPassed ? "FAILED" : "PASSED"}
                                                </a>
                                            </div>
                                            <div>
                                                {!skill?.isPassed ? (
                                                    <img src={FailedTest} alt="" />
                                                ) : (
                                                    <img src={ClearedTest} alt="" />
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                </>
                            ))
                    )}
                </ul>
            </div>
        </>
    )
}

export default SkillResult