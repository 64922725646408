import { StyleSheet } from "@react-pdf/renderer";
import { Font } from '@react-pdf/renderer';
import popinMidium from "../../../../assets/fonts/Poppins-Medium.ttf"
import popinLight from "../../../../assets/fonts/Poppins-Light.ttf"
import popinRegular from "../../../../assets/fonts/Poppins-Regular.ttf"


Font.register({
  family: 'Poppins-Medium',
  fonts: [
    { src: popinMidium, fontWeight: 'normal' },

  ],
});

Font.register({
  family: 'Poppins-Light',
  fonts: [
    { src: popinLight, fontWeight: 'normal' },
  ],
});
Font.register({
  family: 'Poppins-Regular',
  fonts: [
    { src: popinRegular, fontWeight: 'normal' },
  ],
});
export const VettedSkill = StyleSheet.create({
   mainBox:{
    width:"95%",
    height:"140px",
    borderRadius:"5px",
    backgroundColor:"#d4f1f4",
    margin:"1px 5px"
   },
   circle:{
    height:"80px",
    width:"80px",
    borderWidth:"10px",
    borderRadius:"50%",
    margin:"2px auto",
    borderColor:"black"
   }
 
})