export const accordionData = [
  {
    title: "What is Olibr?",
    content: `Olibr is a US-based job placement platform that offers full-time remote job opportunities to seasoned developers in top US firms.`,
  },

  {
    title: "Where is Olibr based?",
    content: `Olibr is a US-based deep jobs platform. Olibr doesn’t believe in geographical limitations and aims to connect top talent with top US companies.`,
  },
  {
    title: "What kind of developers does Olibr hire?",
    content: `Olibr hires developers for various job types including front-end, back-end, full-stack, database, mobile, DevOps, etc., based on tech roles, skills, and seniority. We have requirements for over 20 technologies including Python, React/Node, TypeScript, AWS Lambda, Java, Angular, TypeScript, React Native, PHP, AWS, Ruby, and more.`,
  },
  {
    title: "How is Olibr different from other remote based job websites?",
    content: `Our committed approach to finding you full-time placements with long-term professional security and career growth in top US firms makes us different from others. We also offer extra perks and benefits such as medical insurance, paid vacation, paid leave, and more. `,
  },
  {
    title: "How many rounds do I need to clear before selection?",
    content: `Once you register with us and upload your resume, you must go through the following primary tests: an English aptitude test, a technical skills assessment including a tech stack, and live coding tests. And voila! You belong to the Olibr tribe!`,
  },
  {
    title: "Can fresh graduates apply with Olibr?",
    content: `Olibr ideally hires remote developers with 3+ years of experience. However, if you think you have the right talent and skills to be an exceptional developer,  apply with us and take our tests. We offer the right kind of opportunity to the right talent!`,
  },
];

export const profileEditOption = [
  { value: "tellYourStory", label: "Tell your story" },
  { value: "newEmployment", label: "Add New Employment" },
  { value: "editEmployment", label: "Edit Current Employment" },
  { value: "skills", label: "Add skills" },
  { value: "tool", label: "Add Tools" },
  { value: "certification", label: "Add Certification" },
  { value: "achievement", label: "Add Achievement" },
  { value: "socialProfile", label: "Add Social Profile" },
  { value: "devVideo", label: "Introductory Video" },
];

export const jobTypes = [
  { value: "Remote", label: "Remote" },
  { value: "On Site", label: "On Site" },
  { value: "Hybrid", label: "Hybrid" },
];

export const jobTypesNew = [
  { value: "Full-Time", label: "Full-Time" },
  { value: "Part-Time", label: "Part-Time" },
  { value: "Contractual", label: "Contractual" },
  { value: "Freelancer", label: "Freelancer" },
];

export const skillTestFilter = [
  {
    value: ["initiated", "passed", "failed"],
    label: "Attempted",
  },
  { value: ["passed"], label: "Passed" },
  { value: ["failed"], label: "Failed" },
  { value: ["not_attempted"], label: "Not Attempted" },
  {value: ["result_not_viewed"], label : "Result Not Viewed"},
  { value: ["plagiarism"], label: "Plagiarism Found" },
]

export const profileStatusFilter = [
  { value: "all", label: "All" },
  { value: "initial", label: "Initial" },
  { value: "approved", label: "Approved" },
  { value: "pending", label: "Pending" },
  { value: "rejected", label: "Reject" },
  { value: "reapproval", label: "Reapproval" },
]

export const clientProfileStatusFilter = [
  { value: "New Account", label: "New Account" },
  { value: "Active", label: "Active" }
]

export const profileStepCompFilter = [
  { value: "", label: "All" },
  { value: 0, label: 0 },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
]

export const profTestFilter = [
  {
    value: ["Not Passed", "Passed", "Not Attend","Failed"],
    label: "All",
  },
  { value: ["Passed"], label: "Passed" },
  { value: ["Not Passed"], label: "Failed" },
  { value: ["Not Passed", "Passed"], label: "Attempted" },
  { value: ["Not Attend"], label: "Not Attempted" },
];


export const devVideoStatus = [
  { value: "pending", label: "Pending" },
  { value: "approved", label: "Approved" },
  { value: "uploaded", label: "Uploaded" },
]

export const websiteRegex =
/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

export const faqForArr = [
  { value: "Developer", label: "Developer" },
  { value: "Client", label: "Client" },
  { value: "Pricing", label: "Pricing" },
]

export const timeZones = [
  {value: "Overlapping Hours", label: "Overlapping Hours"},
  {value: "Indian Standard Time", label: "Indian Standard Time"},
  {value: "Flexible", label: "Flexible"}
] 

export const profLevelsArr = [
  {value: "Expert", label: "Expert"},
  {value: "Beginner", label: "Beginner"},
  {value: "Intermediate", label: "Intermediate"}
]

export const profLevelsArrLev = [
  { value: 3, label: "Expert" },
  { value: 1, label: "Beginner" },
  { value: 2, label: "Intermediate" },
];
export const clientFilterArr = [
  { value: "", label: "Availability" },
  { value: "Immediate", label: "Immediate" },
  { value: "30", label: "Within 30 Days" },
  { value: "60", label: "Within 60 Days" },
  { value: "90", label: "Within 90 Days" },
]

export const clientFilterEdu = [
  { value: "", label: "Select" },
  { value: "graduation", label: "Graduation" },
  { value: "post-graduation", label: "Post Graduation" },
  { value: "diploma", label: "Diploma" },
  { value: "post-diploma", label: "Post Diploma" },
]
export const valueBoolean = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
]

export const noticeArr = [
  {value: "Available", label: "Available"},
  {value: "15 Days", label: "15 Days"},
  {value: "30 Days", label: "30 Days"},
  {value: "45 Days", label: "45 Days"},
  {value: "60 Days", label: "60 Days"},
  {value: "90 Days", label: "90 Days"},
]
export const clientFilter={
  devAvailable:"",
  search:"",
  devDesg:"",
  companyName:"",
  skills:[],
  devState:"",
  devCity:"",
  stacks:[],
  devAvlFrom:"",
  devMinExperience:"",
  devMaxExperience:"",
  devESalaryFrom:"",
  devESalaryTo:"",  
  relocation:false,
  education:""
}
export const clientDevFilter={
  email:[],
  // skillList:[],
  // stackList:[],
  dateTestFrom:"",
  dateTestTo  :"",
  jobId:""
}
