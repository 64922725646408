import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { Icon } from "../../../components/icon";
import {
  getAllEmailTemplate,
  updateEmailSettingStatusAction,
  updateEmailTemplate,
} from "../../../service/action/admin";
import { useContext } from "react";
import { MainContext } from "../../../context/MainContext";
import { checkPermission, readOnlyCheck } from "../../../components/functions";

const EmailAutomation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user: { access } } = useContext(MainContext)
  const readOnly = readOnlyCheck(access, "Email Automation")

  const { getTemplateList, isEmailTempLoadinf } = useSelector(
    (state) => state.adminReducer
  );

  const handleNav = () => {
    navigate("/admin/template");
  };
  const handleNavEdit = (templateId) => {
    navigate(`/admin/template-edit/${templateId}`);
  };

  const handleStatusChange = (templateId, statusBool) => {
    dispatch(updateEmailTemplate(templateId, { status: statusBool }));
  };

  const handleEmailSettingStatus = (templateId, status, templateFor) => {
    let finalData = { templateId, status, templateFor }
    dispatch(updateEmailSettingStatusAction(finalData, navigate));
  }

  useEffect(() => {
    dispatch(getAllEmailTemplate(navigate));
  }, [navigate, dispatch]);
  return (
    <div className="main-div">
      <div className="main-div-developer">
        <div className="create-profile">
          <br />
          <div className="block-header">
            <h4 className="">Templates</h4>
            <span onClick={() => {
              if (checkPermission(readOnly)) return false
              handleNav();
            }}>
              <Icon name="add-icon" />
            </span>
          </div>
          <div className="row">
            <div className="col-8"></div>
            <div className="search-block col-4"></div>
          </div>
          <br />

          <div className="table-div">
            <table className="table">
              <thead>
                <tr>
                  <th className="column0">S.No</th>
                  <th className="column1">Title</th>
                  <th className="column1">Subject</th>
                  <th className="column1">Status</th>
                  <th className="column1">For Dev</th>
                  <th className="column1">For Client</th>
                </tr>
              </thead>
              <tbody>
                {isEmailTempLoadinf && (
                  <div style={{ textAlign: "center" }}>
                    {" "}
                    <ClipLoader color="#3BA0AA" />{" "}
                  </div>
                )}
                {getTemplateList?.data?.map((item, i) => (
                  <Fragment key={item?._id}>
                    <tr className="faqdiv">
                      <td className="subQues sno">{i + 1}</td>
                      <td
                        className="subQues"
                        onClick={() =>{
                          if (checkPermission(readOnly)) return false
                          handleNavEdit(item?._id)}}
                      >
                        {item?.title}
                      </td>
                      <td
                        className="postedOn"
                        onClick={() =>{
                          if (checkPermission(readOnly)) return false
                          handleNavEdit(item?._id)}}
                      >
                        {item?.subject}
                      </td>
                      <td
                        className="column4"
                        onClick={() =>{
                          if (checkPermission(readOnly)) return false
                          handleStatusChange(item?._id, !item?.status)
                        }}
                      >
                        <input
                          type="checkbox"
                          name="switch"
                          onChange={(e) => e.preventDefault()}
                          checked={item?.status}
                        />
                        <label className="label" htmlFor="switch3"></label>
                      </td>
                      <td
                        className="column4"
                        onClick={() =>{
                          if (checkPermission(readOnly)) return false
                          handleEmailSettingStatus(item?._id, !item?.devSettingStatus, "devSettingStatus")
                        }}
                      >
                        <input
                          type="checkbox"
                          name="switch"
                          onChange={(e) => e.preventDefault()}
                          checked={item?.devSettingStatus}
                        />
                        <label className="label" htmlFor="switch3"></label>
                      </td>
                      <td
                        className="column4"
                        onClick={() =>{
                          if (checkPermission(readOnly)) return false
                          handleEmailSettingStatus(item?._id, !item?.clientSettingStatus, "clientSettingStatus")
                        }}
                      >
                        <input
                          type="checkbox"
                          name="switch"
                          onChange={(e) => e.preventDefault()}
                          checked={item?.clientSettingStatus}
                        />
                        <label className="label" htmlFor="switch3"></label>
                      </td>
                    </tr>
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailAutomation;
