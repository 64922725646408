import React, { Fragment, useEffect, useState } from 'react'
import { checkArrayExists, compareTwoArray } from '../functions'
import ClipLoaderComp from '../clipLoader/ClipLoader'

const ApplyAllQuestionForm = ({ data, applyJobWithQuestion,isLoading, closeQuesModal }) => {
    const [question, setQuestion] = useState([])
    const [errors, setErrors] = useState([])
    useEffect(() => {
        setQuestion(data)
    }, [data])

    const handleChanges = (e, index, innerIndex) => {
        let tempArr = [...question]
        tempArr[index].questionLists[innerIndex].answer = e.target.value;
        setQuestion(tempArr)
    }

    const handleSubmit = () => {
        let tempArr = [...question]
        let tempError = []
        tempArr?.map((item, index) => {
            item?.questionLists?.map((itm, innerIndex) => {
                if (itm?.isMandatory && !itm?.answer) {
                    tempError.push([index,innerIndex])
                }else {
                    tempError = tempError.filter((itm) =>!compareTwoArray(itm, [index,innerIndex]))
                }
            })
        })
        if (tempError.length) {
            setErrors(tempError)
            return false;
        }
        applyJobWithQuestion(tempArr)
    }
    return (
        <div className='questionaries-area'>
            <div className='row header-section'>
                <div className='col-12 text-center'>
                    <p className='header-title'>Answer the Following questions</p>
                </div>

            </div>
            <div className='box-items question-list-dev'>
                {
                    question?.map((item, index) => (
                        <>
                            <p className='list' style={{ fontWeight: "bold" }}>{item?.jobName}</p>
                            <>
                                {
                                    item?.questionLists?.map((itm, innerIndex) => (
                                        <Fragment>
                                            <p className='list'>{itm?.isMandatory && <span className='starText'>*</span>}{itm?.question} </p>
                                            <input type="text" className={`input ${checkArrayExists(errors,[index,innerIndex]) && "border-red"}`} onChange={(e) => handleChanges(e, index, innerIndex)} />
                                        </Fragment>
                                    ))

                                }
                            </>

                        </>
                    ))
                }


            </div>

            <div className='row'>
                <div className='col-12 text-right '>
                    <button className='cancelBtn' onClick={closeQuesModal}>Cancel</button>
                    <button className='submitBtn' onClick={handleSubmit}>{
                        isLoading? <ClipLoaderComp size={15} color="#FFFFFF" />:"Submit"
                    }
                        
                        </button>
                </div>
            </div>

        </div>
    )
}

export default ApplyAllQuestionForm