import React, { useEffect, useState } from "react";
import TestMonial from "./TestMonial";
import { getAllFaqGroup } from "../../../service/action/common";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ContactUsForm from "../../contact/ContactUsForm";

const PricingFooter = ({countryName}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openFaq, setOpenFaq] = useState(0);
  const { faqGroupLists } = useSelector((state) => state.commonReducer);
  const [data, setData] = useState([]);
  useEffect(() => {
    dispatch(getAllFaqGroup({ faqFor: "Pricing" ,country:countryName}, navigate));

  }, [dispatch, navigate,countryName]);


  useEffect(() => {
    if (faqGroupLists?.pricing?.data) {
      setData(faqGroupLists?.pricing?.data?.[0]?.faqLists);
    }
  }, [faqGroupLists]);

  const handleOpenFaq=(index)=>{
    if(index===openFaq){
      setOpenFaq(-1)
    }else{
      setOpenFaq(index)
    }
  }

  return (
    <>
      <TestMonial />
      <section class="faq-section">
        <div className="container">
          <div className="sec-title">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div class="acc-wrapper">
            {/* col-right */}
            {data?.map((item, index) => (
              <div class="col-left">
                <div class="acc-item">
                  <div class="question">
                    <i onClick={()=>handleOpenFaq(index)} class={`fa fa-solid ${openFaq === index ? "fa-minus": "fa-plus"}`}></i>
                    <p  onClick={()=>handleOpenFaq(index)}>{item?.faqQues}</p>
                  </div>
                  <div
                    class={`answer ${openFaq === index ? "show" : "hide"}`}
                    dangerouslySetInnerHTML={{ __html: item?.faqAnswer }}
                  ></div>
                  <hr></hr>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
     <ContactUsForm forUser="Client"/>
  
    </>
  );
};

export default PricingFooter;
