import React, { useRef, useState } from "react";
import Modal from "../../../../../components/modal/ModalBox";
import { Icon } from "../../../../../components/icon";
import ProjectContent from "./ProjectContent";
import { deleteAdminDevProject, getDeveloperProfile } from "../../../../../service/action/admin";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { checkPermission, readOnlyCheck } from '../../../../../components/functions';

const AdditionalProject = ({ userData }) => {
  const [devProjectDetails, setDevProjectDetails] = useState({
    modal: false,
    edit: false,
    image: [],
    skills: [],
  });
  const [projectDetail,setProjectDetail]=useState(false)
  const closeModalRef = useRef();
  const editProjectRef=useRef()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteFunction, setDeleteFunction] = useState({
    functionName: null,
    parameters: null,
  });
  const [isPreview, setPreview] = useState(false);
  const DeletePreview = () => {
    setPreview(!isPreview);
  };
  const callBackDelete=()=>{
    dispatch(getDeveloperProfile(userData?._id, navigate));
    setPreview(false);
  }

  const handleProjectInfoDelete = () => {
    dispatch(
      deleteAdminDevProject(
        navigate,
        { id: deleteFunction.parameters },
        { devId: userData?._id },
        true,
        callBackDelete
      )
    );
 
  };
  return (
    <div className="create-profile">
      <Modal
        open={isPreview}
        title={"Are you sure?"}
        onClose={DeletePreview}
        onDelete={() => handleProjectInfoDelete()}
        content={() => (
          <p>Are you sure you want to delete Addition Project?.</p>
        )}
        onCancel={() => setPreview(!isPreview)}
        confirmDelete={true}
        isFooterView={true}
      />
      <Modal
        open={devProjectDetails?.modal}
        title={` ${
          devProjectDetails?.edit ? "Edit" : "Add"
        } Additional Project`}
        onClose={() => closeModalRef.current?.click()}
        content={() => (
          <ProjectContent
            devProjectDetails={devProjectDetails}
            setDevProjectDetails={setDevProjectDetails}
            closeModalRef={closeModalRef}
            editProjectRef={editProjectRef}
            projectDetail={projectDetail}
            userData={userData}
          />
        )}
        onCancel={() => closeModalRef.current?.click()}
        isFooterView={false}
      />
      <div className="block-header">
        <h4>
          Additional Project
          {/* <span onClick={() => openTheToolTip(certificationsTip)}>
          <Icon name="info-button" />
        </span> */}
        </h4>
        <a
          href="#-"
          className="link-circle"
          onClick={() => {
            //   if (checkPermission(readOnlyCheck(access))) return false   ;
            setDevProjectDetails({ modal: true, edit: false });
          }}
        >
          <Icon name="add-icon" />
        </a>
      </div>
      {/* <Modal
      open={certDetails?.modal}
      title={
        certDetails.edit ? "Edit Certificate" : "Add Certificate"
      }
      onClose={handleCertInfoCancel}
      content={handleCertContent}
      onCancel={handleCertInfoCancel}
      isFooterView={false}
    /> */}

      <div
        className=""
        style={{
          display:
            userData?.devAdditionalProject?.length == 0 ? "none" : "block",
        }}
      >
        <div className="project-blocks">
          {userData?.devAdditionalProject?.map((proItem, index) => (
            // <div className="card-wrap"> (
            <>
              <div className="project-block">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h5 style={{ display: "flex" }}>{proItem?.projectName}</h5>
                  <span style={{ display: "flex" }}>
                    <a
                      href="#-"
                      onClick={() => {
                        setProjectDetail(proItem)
                        editProjectRef.current?.click()
                      }}
                    >
                      <Icon name="edit" />
                    </a>
                    <a
                      href="#-"
                      style={{ marginLeft: "5px" }}
                      onClick={() => {
                        setDeleteFunction({
                          functionName: "handleProjectInfoDelete",
                          parameters: proItem?._id,
                        });
                        DeletePreview();
                      }}
                    >
                      <Icon name="delete" />
                    </a>
                  </span>
                </div>
                <a
                  style={{ textTransform: "capitalize" }}
                  className="link"
                  href={proItem?.projectLinks?.[0]}
                >
                  {proItem?.projectLinks?.[0]}
                </a>
                <div
                  className="project-desc-fixed"
                  dangerouslySetInnerHTML={{
                    __html: proItem?.projectDescription,
                  }}
                ></div>
                <ul className="icon-tags-finalize">
                  {proItem?.skills?.map((devSkill) => (
                    <li
                      style={{
                        display: "inline-block",
                        gap: "8px",
                      }}
                    >
                      <a style={{ display: "flex" }}>{devSkill}</a>
                    </li>
                  ))}
                </ul>
                {/* <div className="attached">
                                        <i></i>
                                        <span>{`Attached ${
                                          proItem?.devProjectImage?.length
                                            ? proItem?.devProjectImage?.length
                                            : 0
                                        } screenshots`}</span>
                                      </div> */}
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdditionalProject;
