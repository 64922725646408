import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import SelectOption from "../../../../components/select/SelectOption";
const SelectSkillCustom = ({allSkillLists, watch, register, setValue, errors, control }) => {
  const [showSkillDetail, setShowSkillDetail] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState([]);

  useEffect(() => {
    if (watch?.jobSkills?.length) {
      setSelectedSkill(watch?.jobSkills);
    } else {
      setSelectedSkill([]);
    }
  }, [watch?.jobSkills]);

  const handleSkillCheckBox = (index) => {
    let temp = [...selectedSkill];
    temp[index].isRequired = !temp[index]?.isRequired;
    setSelectedSkill(temp);
  };

  const handleExperience = (e, index) => {
    let input = e.target.value;
    let temp = [...selectedSkill];
    if (input > 0 && 99 > input) {
      temp[index].experiency = input != 0 ? +input : 0;
    } else if (input == "" || input == " ") {
      temp[index].experiency = "";
    }
    setSelectedSkill(temp);
  };

  const handleOpenCard = (index) => {
    if (showSkillDetail.includes(index)) {
      let filter = showSkillDetail.filter((itm) => itm !== index);
      setShowSkillDetail(filter);
    } else {
      setShowSkillDetail([...showSkillDetail, index]);
    }
  };

  const blurHandle = (e, index) => {
    let input = e.target.value;
    let temp = [...selectedSkill];
    if (input > 0) {
      if(input==1){
        temp[index].experiency = input + " Year";
      }else{
        temp[index].experiency = input + " Years";
      }
     
    } else {
      temp[index].experiency = input;
    }
    setSelectedSkill(temp)
  };
  const focusHandle = (e, index) => {
    let input = e.target.value;
    let temp = [...selectedSkill];
    if (input?.includes(" Years")) {
      let newValue=input.replace(" Years","");
      temp[index].experiency = newValue;
    }else if(input?.includes(" Year")){
      let newValue=input.replace(" Year","");
      temp[index].experiency = newValue;
    }else {
      temp[index].experiency = input;
    }
    setSelectedSkill(temp)
    
  };
  return (
    <form
      className="default-form add-job-form step-two-form"
      onSubmit={(e) => e.preventDefault()}
    >
      {/* <div className="row"> */}
        <div className="form-group">
          <label className={errors?.jobSkills && "text-danger"}>
            Add Skills<span className="required-field">*</span>
          </label>
          <Controller
            control={control}
            name="jobSkills"
            {...register("jobSkills", { required: true })}
            render={() => (
              <SelectOption
                createble={true}
                optionArray={allSkillLists || []}
                closeMenuOnSelect={true}
                onChangeFunction={(e) => setValue("jobSkills", e)}
                state={selectedSkill}
                searchable={true}
                className="job-select margin-bottom-10"
                bgColor="white"
                smallSize={true}
                isMulti={true}
              />
            )}
          />
          {/* <a className="link-btn link-btn-small" href="#">
            ADD
          </a> */}
          <small>Select checkbox for mandatory skill</small>
          <div className="skill-group row">
            {selectedSkill?.map((item, index) => (
              <div className="col-3 skill-field" key={index}>
                <p
                  className="flex-field pointer"
                  onClick={() => handleOpenCard(index)}
                >
                  {item?.label}
                  <i
                    className={`fa fa-solid ${
                      showSkillDetail.includes(index)
                        ? "fa-angle-up"
                        : "fa-angle-down"
                    }`}
                  ></i>
                </p>
                {showSkillDetail.includes(index) && (
                  <div className="skill-detail">
                    <label  className="pointer flex-start">
                      <input
                        type="checkbox"
                        id="req"
                        checked={selectedSkill[index]?.isRequired}
                        className="pointer"
                        onClick={() => handleSkillCheckBox(index)}
                      />
                      Required
                    </label>
                    <input
                      type="text"
                      className="skill-input-client"
                      value={selectedSkill[index]?.experiency}
                      onChange={(e) => handleExperience(e, index)}
                      placeholder="Min Experience"
                      onFocus={(e) => focusHandle(e, index)}
                      onBlur={(e) => blurHandle(e, index)}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      {/* </div> */}
    </form>
  );
};
export default SelectSkillCustom;
