import React from "react";
import { Icon } from "../../../icon";
import Modal from "../../../modal/ModalBox";
import OtpContent from "../../../otp-component/OtpContent";
import { getResume, sendOTPAction } from "../../../../service/action/developer";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const BasicDetails = ({ resumeData, openModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sentOtp, setSentOtp] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();

  const setOTP = () => {
    setSentOtp(true);
  };

  const closeModal = () => {
    dispatch(getResume());
  };
  const onVerifyClick = (newNumber = false) => {
    setPhoneNumber(newNumber ? newNumber : phoneNumber);
    let full_number = {
      phoneNumber:
        resumeData?.devCountryCode + (newNumber ? newNumber : phoneNumber),
    };
    dispatch(sendOTPAction(full_number, navigate, setOTP));
  };

  const openOtpModal = () => {
    setSentOtp(false);
    setOtpModal(true);
    setPhoneNumber(resumeData?.number);
  };
  return (
    <div className="setting-card">
      <Modal
        title={"OTP Verification"}
        size="40%"
        isFooterView={false}
        open={otpModal}
        content={() => (
          <OtpContent
            countryCode={resumeData?.devCountryCode}
            sentOtp={sentOtp}
            changeNo={true}
            phoneNumber={phoneNumber}
            onVerifyClick={onVerifyClick}
            otpOpen={otpModal}
            setOtpOpen={setOtpModal}
            closeModal={closeModal}
          />
        )}
        onClose={() => setOtpModal(false)}
        onCancel={() => setOtpModal(false)}
      />
      <h2>
        Hi {resumeData?.fName} {resumeData?.lName}
      </h2>
      <div className="row">
        <div className="col-4">
          <p>{resumeData?.email ? resumeData?.email : "N/A"}</p>
        </div>
        <div className="col-4">
          <h3>
            Phone Number
            {resumeData?.numberVerified && (
              <a className="browseImage verified-number">
                <Icon name="otp-verified" />
              </a>
            )}
            {resumeData?.numberVerified ? (
              <span onClick={openOtpModal}>
                <Icon name="edit" />
              </span>
            ) : (
              <a href="#-" onClick={openOtpModal} className="setting-verify">
                VERIFY
              </a>
            )}
          </h3>

          <p>
            {" "}
            {resumeData?.number ? (
              <> {"+ 91 ".concat(resumeData?.number)}</>
            ) : (
              "N/A"
            )}
          </p>
        </div>
        <div className="col-4">
          <h3>
            Password
            <span onClick={() => openModal("password")}>
              <Icon name="edit" />
            </span>
          </h3>
          <p>********</p>
        </div>
      </div>
    </div>
  );
};

export default BasicDetails;
