import { StyleSheet } from "@react-pdf/renderer";
import { Font } from '@react-pdf/renderer';
import popinMidium from "../../../../assets/fonts/Poppins-Medium.ttf"
import popinLight from "../../../../assets/fonts/Poppins-Light.ttf"
import popinRegular from "../../../../assets/fonts/Poppins-Regular.ttf"


Font.register({
    family: 'Poppins-Medium',
    fonts: [
        { src: popinMidium, fontWeight: 'normal' },

    ],
});

Font.register({
    family: 'Poppins-Light',
    fonts: [
        { src: popinLight, fontWeight: 'normal' },
    ],
});
Font.register({
    family: 'Poppins-Regular',
    fonts: [
        { src: popinRegular, fontWeight: 'normal' },
    ],
});
export const SkillResultCss = StyleSheet.create({
    header: {
        flexDirection: "row"
    },
    itemsLeft: {
        height: "30px",
        width: "75%",
        flexDirection: "row",
    },
    itemsRight: {
        height: "30px",
        width: "25%",
        flexDirection: "row",
    },
    itemsNestedLeft: {
        height: "100%",
        width: "15%",
    },
    skillTitle: {
        fontSize: "10px",
        marginTop: "6px"
    },
    leftSkillIcon: {
        height: "20px",
        width: "20px",
        margin: "6px 5px",
        fontFamily: "Poppins-Light"
    },
    vettedSkillIcon: {
        marginTop: "-10px",
        width: "18px",
        height: "18px"
    },
    itemsNestedRightLogo: {
        width: "100%"
    },
    itemsNestedRightLeft: {
        width: "15%"
    },
    brandLogo: {
        width: "70px",
        height: "15px",
        marginTop: "5px"
    },
    downArrowIcon: {
        height: "13px",
        width: "13px",
    },
    mainWhiteBox: {
        width: "95%",
        height: "190px",
        backgroundColor: "white",
        borderRadius: "6px",
        flexDirection: "row",
        margin: "10px auto"
    },
    leftWhiteBox: {
        width: "30%",
        height: "100%",
    },
    rightWhiteBox: {
        width: "70%",
        height: "100%",
    },
    resultCirlce: {
        height: "65px",
        width: "65px",
        backgroundColor: "#d9f5fa",
        borderRadius: "50%",
        margin: "20px auto"
    },
    resultCirlceScore: {
        textAlign: "center",
        marginTop: "23px",
        fontFamily: 'Poppins-Medium',
        color: "#28777e"
    },
    dateArea: {
        width: "100%",
        height: "20px",
        marginTop:"10px",
        justifyContent: "flex-end",
        flexDirection: "row"
    },
    dateText: {
        fontSize: "9px",
        marginTop: "-1px",
        marginRight:"10px",
        fontFamily:"Poppins-Regular"
    },
    dateIcon: {
        width: "9px",
        height: "9px",
        marginTop: "1px",
        marginRight: "2px"
    },
    timeArea:{
        height:"15px",

        width:"100%",
    },
    times:{
        fontSize:"9px",
        fontFamily:"Poppins-Regular"
    }
   

})