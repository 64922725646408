import "../Faqs/FaqClient";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../../components/icon";
import "../Faqs/Faq.css";
import Modal from "../../../../components/modal/ModalBox";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFaq,
  updateFaqGroup,
  deleteFaq,
  deleteFaqGroup,
  updateFaqGroupStatusAction,
  updateFaqStatusAction,
} from "../../../../service/action/admin/index";
import { Controller, useForm } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import OlibrEditor from "../../../../components/editor";
import {
  checkPermission,
  readOnlyCheck,
} from "../../../../components/functions";
import { useContext } from "react";
import { MainContext } from "../../../../context/MainContext";

const FaqCommon = (props) => {
  const {
    register: faqRegister,
    handleSubmit: faqSubmit,
    setValue: setFaqValue,
    getValues: getFaqValue,
    control: faqControll,
    reset: resetFaqForm,
    formState: { errors: faqError },
  } = useForm();

  const {
    register: faqGroupRegister,
    handleSubmit: faqGroupSubmit,
    setValue: setFaqGroupValue,
    getValues: getFaqGroupValue,
    control: faqGroupControll,
    reset: resetFaqGroupForm,
    formState: { errors: faqGroupError },
  } = useForm();
  const {
    user: { access },
  } = useContext(MainContext);
  const readOnly = readOnlyCheck(access, "FAQs");

  const dispatch = useDispatch();
  const { faqGroupLists } = useSelector((state) => state.commonReducer);
  const navigate = useNavigate();
  const { FAQ, faqFor, getFaqList } = props;
  const [clientState, setClientState] = useState(FAQ);

  const [faqModal, setFaqModal] = useState(false);
  const [faqGroupModal, setFaqGroupModal] = useState(false);

  useEffect(() => {
    if (
      faqFor === "Client" &&
      Object.keys(faqGroupLists).length > 0 &&
      Object.keys(faqGroupLists.client).length > 0
    ) {
      setClientState(faqGroupLists.client.data);
    }
    if (
      faqFor === "Developer" &&
      Object.keys(faqGroupLists).length > 0 &&
      Object.keys(faqGroupLists.developer).length > 0
    ) {
      setClientState(faqGroupLists.developer.data);
    }
    if (
      faqFor === "Pricing" &&
      Object.keys(faqGroupLists).length > 0 &&
      Object.keys(faqGroupLists.pricing).length > 0
    ) {
      setClientState(faqGroupLists.pricing.data);
    }
  }, [faqFor, faqGroupLists]);

  const closeEditGroupModal = () => {
    resetFaqGroupForm();
    setFaqGroupModal(false);
  };

  const closeEditModal = () => {
    resetFaqForm();
    setFaqModal(false);
  };

  const updateFaqs = (data) => {
    dispatch(updateFaq(data, faqFor, setFaqModal, callBack, navigate));
  };
  const [currentGroupIndex, setCurrentGroupIndex] = useState(-1);
  const [indexList, setIndexList] = useState([]);
  const [targetIndex, setTargetIndex] = useState(-1);

  function callBack(){
    getFaqList(faqFor);
    setTargetIndex(-1);
    resetFaqForm();
    resetFaqGroupForm();
    resetFaqForm();
    setFaqModal(false);
  };
  const updateFaqGroups = (data) => {
    let allData = [...clientState];
    let currentSrNo = allData[currentGroupIndex - 1];
    currentSrNo = currentSrNo?.srNo;
    let targetObj = null;
    if (targetIndex) {
      targetObj = allData[targetIndex - 1];
      targetObj = {
        srNo: targetObj?.srNo,
        _id: targetObj?._id,
      };
    }

    const newData = {
      ...data,
      currentSrNo,
      targetObj,
      faqFor,
    };
    dispatch(updateFaqGroup(newData, setFaqGroupModal, callBack, navigate));
  };

  const deleteFaqs = () => {
    const newObj = {
      faqGroupId: getFaqValue("faqGroupId"),
      faqId: getFaqValue("faqId"),
    };
    dispatch(deleteFaq(navigate, newObj, faqFor, setFaqModal, callBack));
  };

  const deleteFaqGroups = () => {
    const newObj = {
      faqGroupId: getFaqGroupValue("faqGroupId"),
    };
    dispatch(
      deleteFaqGroup(navigate, newObj, faqFor, setFaqGroupModal, callBack)
    );
  };

  const editFaqGroupNameFunction = (item, srNo) => {
    setCurrentGroupIndex(srNo);
    resetFaqForm();
    resetFaqGroupForm();
    setFaqGroupModal(true);
    setFaqGroupValue("faqGroupId", item._id);
    setFaqGroupValue("faqTitle", item.faqTitle);
  };

  const editFaqFunction = (item, sub) => {
    resetFaqForm();
    setFaqValue("faqGroupId", item?._id);
    setFaqValue("faqId", sub?._id);
    setFaqValue("faqQues", sub?.faqQues);
    setFaqValue("faqAnswer", sub?.faqAnswer);
    setFaqModal(true);
  };

  const [search, setSearch] = useState("");

  const handleEditGroupContent = () => {
    return (
      <form>
        <div className="row">
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="" className="floating-label">
                Change Sr. No
              </label>
              <select onChange={(e) => setTargetIndex(e.target.value)}>
                {indexList?.map((index) => (
                  <option selected={currentGroupIndex == index} value={index}>
                    {index}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-9">
            <div className="form-group">
              <label htmlFor="" className="floating-label">
                Category Name
              </label>
              <input
                type="text"
                className={`form-control ${
                  faqGroupError?.faqTitle && "form-required"
                }`}
                placeholder="Question"
                name="faqTitle"
                {...faqGroupRegister("faqTitle", {
                  required: true,
                })}
              />
            </div>
          </div>
        </div>

        <br />
        <div className="bottom-button">
          <button
            onClick={closeEditGroupModal}
            type="button"
            className="cancelBtn"
          >
            cancel
          </button>
          <button
            className="submitBtn themeButton"
            onClick={faqGroupSubmit(updateFaqGroups)}
          >
            submit
          </button>
        </div>
      </form>
    );
  };

  const handleEditfaqContent = () => {
    return (
      <form>
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <label className="floating-label">Faq Category</label>

              <select
              {...faqRegister("newGroupCategory")}
              > <option value={null}></option>
                {clientState
                  ?.filter((faq) =>
                    String(faq.faqTitle)
                      .toLowerCase()
                  )
                  .map((item) => (
                    <option value={item?._id}>{item?.faqTitle}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-8">
            <div className="form-group">
              <label className="floating-label">Title</label>
              <input
                type="text"
                className={`form-control ${
                  faqError?.faqQues && "form-required"
                }`}
                name="faqQues"
                placeholder="Question"
                {...faqRegister("faqQues", {
                  required: true,
                })}
              />
            </div>
          </div>
        </div>
        <div className={`FAQeditor ${faqError?.faqAnswer && "form-required"}`}>
          <Controller
            control={faqControll}
            name="faqAnswer"
            {...faqRegister("faqAnswer", {
              required: true,
            })}
            render={({ field }) => (
              <OlibrEditor
                value={getFaqValue("faqAnswer") ? getFaqValue("faqAnswer") : ""}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </div>
        <br />
        <div className="bottom-button">
          <button onClick={closeEditModal} type="button" className="cancelBtn">
            cancel
          </button>
          <button
            className="submitBtn themeButton"
            onClick={faqSubmit(updateFaqs)}
          >
            submit
          </button>
        </div>
      </form>
    );
  };

  const updateFaqStatus = (faqGroupId, faqId, oneFaqStatus) => {
    dispatch(
      updateFaqStatusAction(
        { faqGroupId, faqId, oneFaqStatus },
        navigate,
        faqFor
      )
    );
  };

  const updateFaqGroupStatus = (status, id) => {
    dispatch(
      updateFaqGroupStatusAction({ status, faqGroupId: id }, navigate, faqFor)
    );
  };
  useEffect(() => {
    let tempIndexList = [];
    clientState?.map((_, index) => {
      tempIndexList.push(index + 1);
    });
    setIndexList(tempIndexList);
  }, [currentGroupIndex]);

  return (
    <>
      <div className="main-div">
        <div className="faq-management">
          <br />
          <div className="row">
            <div className="col-8"></div>
            <div className="search-block col-4">
              <input
                type="text"
                className="form-control"
                value={search}
                placeholder="Search FAQs"
                onChange={(e) => setSearch(e.target.value)}
              />
              <a className="search-btn">
                <Icon name="search-icon" />
              </a>
            </div>
          </div>
          <br />

          <div className="table-div">
            <table className="table">
              <thead>
                <tr>
                  <th className="column">
                    <div className="serialNumber">S.No</div>
                  </th>
                  <th className="column">Title</th>
                  <th className="column">Status</th>
                </tr>
              </thead>
              <tbody>
                {clientState
                  ?.filter((faq) =>
                    String(faq.faqTitle)
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  )
                  .map((item) => (
                    <>
                      <tr key={item._id} className="faqdiv">
                        <td className="titleQues sno">{item?.srNo}</td>
                        <td
                          className="titleQues"
                          onClick={() => {
                            if (checkPermission(readOnly)) return false;
                            editFaqGroupNameFunction(item, item?.srNo);
                          }}
                        >
                          {item.faqTitle}
                        </td>
                        <td
                          className="column4"
                          onClick={() => {
                            if (checkPermission(readOnly)) return false;
                            updateFaqGroupStatus(
                              !(item?.faqStatus === "Active"),
                              item?._id
                            );
                          }}
                        >
                          <input
                            type="checkbox"
                            name="switch"
                            onChange={(e) => e.preventDefault()}
                            checked={item?.faqStatus === "Active"}
                          />
                          <label className="label" htmlFor="switch3"></label>
                        </td>
                      </tr>
                      {item?.faqLists
                        ?.filter((faq) =>
                          String(faq.faqQues)
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        )
                        .map((sub) => (
                          <tr key={sub._id} className="faqdiv">
                            <td></td>
                            <td
                              className="subQues"
                              onClick={() => {
                                if (checkPermission(readOnly)) return false;
                                editFaqFunction(item, sub);
                              }}
                            >
                              {sub.faqQues}
                            </td>
                            <td
                              className="column4"
                              onClick={() => {
                                if (checkPermission(readOnly)) return false;
                                updateFaqStatus(
                                  item?._id,
                                  sub?._id,
                                  !sub?.oneFaqStatus
                                );
                              }}
                            >
                              <input
                                type="checkbox"
                                name="switch"
                                onChange={(e) => e.preventDefault()}
                                checked={sub?.oneFaqStatus}
                              />
                              <label
                                className="label"
                                htmlFor="switch3"
                              ></label>
                            </td>
                          </tr>
                        ))}
                    </>
                  ))}
              </tbody>
            </table>
            <Modal
              open={faqModal}
              title={"Edit FAQ"}
              isDeletable={true}
              isDeleteIcon={true}
              onDelete={() => deleteFaqs()}
              onClose={closeEditModal}
              isFooterView={false}
              content={handleEditfaqContent}
            />
            <Modal
              open={faqGroupModal}
              title={"Edit FAQ Group"}
              isDeletable={true}
              isDeleteIcon={true}
              onDelete={() => deleteFaqGroups()}
              onClose={closeEditGroupModal}
              content={handleEditGroupContent}
              isFooterView={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqCommon;
