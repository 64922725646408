import { Fragment, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { olibrToast } from "../../../components/toaster";
import { approvalUpdateAction } from "../../../service/action/developer";
import Employment from "./Employment";
import Tooltip from "../../../components/tooltips/Tooltip";
import { regexPattern } from "./SocialRegexPattern";
import { Icon } from "../../../components/icon";
import Select from "react-select";
import SelectOption from "../../../components/select/SelectOption";

const storyDummy =
  "I am a Senior Java Developer with 5+ years of active work experience within Agile processes. I have strong operational knowledge of Springboot and Hilla frameworks, and integration tools such as Gitlab, Jenkins, Cruise Control, and Bamboo. Coding and release management are my strong suit and I have worked on tight deadlines in all phases of production with successful outcomes. I bring to the table my ingenious analytical and problem-solving capabilities that have averted many disasters and expedited the production process in my past jobs. Teamwork, seamless communication, proper documentation, and enforcing the engineering standards are some of the facilities that come naturally to me with my years of experience in the field.";

const ProfileUpdateRequest = () => {
  const dispatch = useDispatch();
  const [allSkill, setAllSkills] = useState([]);
  const navigate = useNavigate();
  const [tellYourStory, setTellYourStory] = useState();
  const [newEmployment, setNewEmployment] = useState({
    designation: "",
    companyName: "",
    from: "",
    to: "",
    stillWorking: false,
    techUsed: [],
    toolUsed: [],
    aboutRole: "",
  });

  const [currentEmployment, setCurrentEmployment] = useState({
    designation: "",
    companyName: "",
    from: "",
    to: "",
    stillWorking: false,
    techUsed: [],
    toolUsed: [],
    aboutRole: "",
  });

  const [skills, setSkills] = useState({
    title: "",
    tagArr: [],
  });
  const [skill, setSkill] = useState("");
  const [tool, setTool] = useState([]);
  const [certification, setCertification] = useState({});
  const [achievement, setAchievement] = useState({});
  const [socialProfile, setSocialProfile] = useState({});
  const { isApprovalUpdating, allSkills, devAllToolLists } = useSelector(
    (state) => state.developerReducer
  );
  const { dataForApproval } = useSelector((state) => state.commonReducer);
  const [allUpdateRequest, setAllRequestedValue] = useState({});
  const [selectedOption, setSelectedOption] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (dataForApproval?.length > 0) {
      let all_update_request = {};
      dataForApproval.forEach((element) => {
        switch (element.slug) {
          case "tellYourStory":
            setTellYourStory(element.newValue);
            break;
          case "newEmployment":
            setNewEmployment(element.newValue);
            break;
          case "editEmployment":
            setCurrentEmployment(element.newValue);
            break;
          case "skills":
            setSkills(element.newValue);
            break;
          case "tool":
            let toolArr = [];
            toolArr = element?.newValue?.map((item) => ({
              value: item?.toolIcon,
              label: item?.toolName,
            }));
            // console.log(element?.newValue, "{}{}{}{}{]")
            setTool(toolArr);
            break;
          case "certification":
            setCertification(element.newValue);
            break;
          case "achievement":
            setAchievement(element.newValue);
            break;
          case "socialProfile":
            setSocialProfile(element.newValue);
            break;
        }
        all_update_request[`${element.slug}`] = {
          value: element.newValue,
          comment: element?.comment,
        };
      });
      const allSlug = Object.keys(all_update_request);
      if (allSlug?.length > 0) {
        const optionData = allSlug?.map((item) => {
          const firstName = item.match(/[a-z][a-z]+/g)?.[0];
          const first_name =
            firstName?.charAt(0).toUpperCase() + firstName?.slice(1);
          const second_name = item.match(/[A-Z][a-z]+|[0-9]+/g)?.join(" ");
          return {
            value: item,
            label: second_name ? `${first_name} ${second_name}` : first_name,
          };
        });
        setSelectedOption("");
        setOptions(optionData);
      }
      setAllRequestedValue(all_update_request);
    }
  }, [dataForApproval]);

  useEffect(() => {
    let arr = [];
    arr = allSkills?.map((skill) => ({
      value: skill?.skillTestIcon,
      label: skill?.skillName,
    }));
    setAllSkills(arr);
  }, [allSkills]);

  useEffect(() => {
    console.log("element", dataForApproval);
  }, [dataForApproval]);

  const socialLinkValid = (platform, link) => {
    if (regexPattern[platform].test(link) || link == "") {
      const tempObj = { ...socialProfile };
      tempObj[`${platform}`] = link;
      setSocialProfile(tempObj);
    } else {
      link && olibrToast.error(`Please Enter a Valid Link for ${platform}!!!`);
    }
  };

  const handleSkillContent = () => {
    return (
      <>
        <div className="row">
          <div className="form-group col-12">
            <label className="floating-label">Title</label>
            <input
              type="text"
              className="form-control col-12"
              value={skills?.title}
              disabled={!allUpdateRequest?.skills?.comment}
              onChange={(e) =>
                setSkills({
                  ...skills,
                  title: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="floating-label" htmlFor="">
                Add Skills
              </label>
              <div style={{ display: "flex" }}>
                <input
                  type="text"
                  value={skill}
                  onChange={(e) => setSkill(e.target.value)}
                  disabled={!allUpdateRequest?.skills?.comment}
                  className="form-control"
                />
                <a
                  onClick={() => {
                    skill &&
                    skills?.tagArr
                      ?.filter(
                        (item) =>
                          String(item).toLowerCase().trim() ==
                          String(skill).toLowerCase().trim()
                      )
                      .includes(String(skill).toLowerCase().trim())
                      ? olibrToast.error("tag already exists!")
                      : setSkills({
                          ...skills,
                          tagArr: [...skills.tagArr, String(skill).trim()],
                        });
                    setSkill("");
                  }}
                  className=""
                >
                  <Icon name="add-icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <ul className="tags tags-fill">
          {skills?.tagArr?.map((item, index) => (
            <li key={Math.random()}>
              <a>
                <span>{item}</span>{" "}
                <i
                  onClick={() => {
                    const tempSkill = [...skills?.tagArr];
                    tempSkill.splice(index, 1);
                    setSkills({
                      ...skills,
                      tagArr: tempSkill,
                    });
                  }}
                >
                  <Icon name="modal-close" />
                </i>
              </a>
            </li>
          ))}
        </ul>
      </>
    );
  };

  const [optionArr, setOptionArr] = useState([]);
  useEffect(() => {
    let optionArr = [];
    optionArr = devAllToolLists?.map((item) => ({
      toolName: item?.toolsName,
      toolIcon: item?.toolsIcon,
      toolExp: "temp",
    }));
    console.log(optionArr);
    setOptionArr(optionArr);
  }, [devAllToolLists]);

  const handleToolContent = () => {
    return (
      <div style={{ display: "flex" }}>
        <div className="form-group col-12">
        <SelectOption
                  isMulti={true}
                  optionArray={!!optionArr?.length ? optionArr : []}
                  state={tool}
                  searchable={true}
                  disabled={true}
                />
        </div>
      </div>
    );
  };

  const filterMonth = (date) => {
    let x = date;
    x = x?.split("-");
    if (x?.length <= 2) {
      return date;
    } else {
      let newDate = x?.[0] + "-" + x?.[1];
      return newDate;
    }
  };

  const handleCertContent = () => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <div className="row">
            <div className="form-group col-6">
              <input
                type="text"
                className="form-control col-12"
                placeholder="Certficate Name"
                value={certification?.certificateName}
                disabled={!allUpdateRequest?.certification?.comment}
                onChange={(e) =>
                  setCertification({
                    ...certification,
                    certificateName: e.target.value,
                  })
                }
              />
            </div>

            <div className="form-group col-6">
              <input
                type="text"
                className="form-control col-12"
                placeholder="Name of The Institution"
                value={certification?.certInstitution}
                disabled={!allUpdateRequest?.certification?.comment}
                onChange={(e) =>
                  setCertification({
                    ...certification,
                    certInstitution: e.target.value,
                  })
                }
              />
            </div>

            <div className="form-group col-6">
              <input
                type="month"
                className={`form-control col-12 ${
                  certification?.startDate ? "" : "placeholder"
                }`}
                placeholder="Start Date"
                value={filterMonth(certification?.startDate)}
                disabled={!allUpdateRequest?.certification?.comment}
                onChange={(e) =>
                  setCertification({
                    ...certification,
                    startDate: e.target.value,
                  })
                }
              />
            </div>

            <div className="form-group col-6">
              <input
                type="month"
                className={`form-control col-12 ${
                  certification?.endDate ? "" : "placeholder"
                }`}
                placeholder="End Date"
                value={filterMonth(certification?.endDate)}
                disabled={!allUpdateRequest?.certification?.comment}
                onChange={(e) =>
                  setCertification({
                    ...certification,
                    endDate: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const setValue = (value, slug) => {
    if (value) {
      const newValue = {};
      newValue[`${slug}`] = value;
      dispatch(
        approvalUpdateAction(navigate, {
          slug: slug,
          newValue: newValue,
        })
      );
    } else {
      olibrToast.error("Please enter value");
    }
  };

  const handleSubmit = () => {
    switch (selectedOption) {
      case "tellYourStory":
        setValue(tellYourStory, selectedOption);
        break;
      case "skills":
        if (skills.title && skills?.tagArr?.length >= 1) {
          const newValue = {};
          newValue[`${selectedOption}`] = skills;
          dispatch(
            approvalUpdateAction(navigate, {
              slug: selectedOption,
              newValue: newValue,
            })
          );
          setSkills({});
        } else {
          if (!skills.title) {
            olibrToast.error("Please enter title");
          } else {
            olibrToast.error("Please add atleast one skill");
          }
        }
        break;
      case "tool":
        if (tool?.length > 0) {
          const newValue = {};
          let finalToolArr = [];
          finalToolArr = tool?.map((item) => ({
            toolName: item?.label,
            toolIcon: item?.value,
            toolExp: "temp",
          }));
          newValue[`${selectedOption}`] = finalToolArr;
          dispatch(
            approvalUpdateAction(navigate, {
              slug: selectedOption,
              newValue: newValue,
            })
          );
          setTool([]);
        } else {
          olibrToast.error("Please select tool.");
        }
        break;
      case "certification":
        if (Object.keys(certification)?.length >= 4) {
          const newValue = {};
          newValue[`${selectedOption}`] = certification;
          dispatch(
            approvalUpdateAction(navigate, {
              slug: selectedOption,
              newValue: newValue,
            })
          );
          setAchievement({});
        } else {
          olibrToast.error("All field is required.");
        }
        break;
      case "achievement":
        if (achievement?.title) {
          const newValue = {};
          newValue[`${selectedOption}`] = { title: [achievement?.title] };
          dispatch(
            approvalUpdateAction(navigate, {
              slug: selectedOption,
              newValue: newValue,
            })
          );
          setAchievement({});
        } else {
          olibrToast.error("Please enter value");
        }
        break;
      case "socialProfile":
        if (Object.keys(socialProfile)?.length > 0) {
          const newValue = {};
          newValue[`${selectedOption}`] = socialProfile;
          dispatch(
            approvalUpdateAction(navigate, {
              slug: selectedOption,
              newValue: newValue,
            })
          );
          setSocialProfile({});
        } else {
          olibrToast.error("Please enter atleast one value");
        }
        break;
    }
  };

  const handleSubmitEmpInfo = (value) => {
    delete value["uniKey"];
    delete value["devId"];
    delete value["createdAt"];
    value["stillWorking"] && delete value["to"];
    const finalValue = Object.keys(value).filter((item) => !value[item]);
    finalValue.forEach((blankItem) => delete value[blankItem]);
    if (Object.values(value).every((v) => v || v === false)) {
      let finalObj = value;
      let finalObjTool = [];
      finalObjTool = finalObj?.toolUsed?.map((item) => ({
        label: item?.label,
        value: item?.value,
      }));
      value["toolUsed"] = finalObjTool;

      finalObjTool = [];
      finalObjTool = finalObj?.techUsed?.map((item) => ({
        label: item?.label,
        value: item?.value,
      }));
      value["techUsed"] = finalObjTool;

      const newValue = {};
      newValue[selectedOption] = value;
      dispatch(
        approvalUpdateAction(navigate, {
          slug: selectedOption,
          newValue: newValue,
        })
      );
    } else {
      olibrToast.error("Every field are required.");
    }
  };

  return (
    <Fragment>
      <form>
        <div className="create-profile">
          <select
            className="form-control col-12"
            placeholder="Select Field"
            onChange={(e) => setSelectedOption(e.target.value)}
            value={selectedOption}
          >
            <option value={""}>Select field </option>
            {options?.map((item) => (
              <option value={item.value}>
                {item.label === "Edit Employment"
                  ? "Current Employment"
                  : item.label}
              </option>
            ))}
          </select>
        </div>
        {selectedOption === "tellYourStory" && (
          <div className="create-profile">
            <div className="block-header between-center">
              <h4>Tell your story </h4>
              <h3>
                {allUpdateRequest?.tellYourStory?.comment ? (
                  <Tooltip
                    title={"Reject"}
                    titleColor={"red"}
                    tooltipText={allUpdateRequest?.tellYourStory?.comment}
                  />
                ) : (
                  <span style={{ color: "red" }}>Pending</span>
                )}
              </h3>
            </div>
            <textarea
              rows={10}
              placeholder={storyDummy}
              onChange={(e) => setTellYourStory(e.target.value)}
              disabled={!allUpdateRequest?.tellYourStory?.comment}
              value={tellYourStory}
            />
          </div>
        )}
        {selectedOption === "achievement" && (
          <div className="create-profile">
            <div className="block-header between-center">
              <h4>Achievements</h4>
              <h3>
                {allUpdateRequest?.achievement?.comment ? (
                  <Tooltip
                    title={"Reject"}
                    titleColor={"red"}
                    tooltipText={allUpdateRequest?.achievement?.comment}
                  />
                ) : (
                  <span style={{ color: "red" }}>Pending</span>
                )}
              </h3>
            </div>
            <textarea
              rows={10}
              placeholder={"Enter achievement"}
              onChange={(e) => setAchievement({ title: e.target.value })}
              disabled={!allUpdateRequest?.achievement?.comment}
              value={achievement.title}
            />
          </div>
        )}
        {(selectedOption === "newEmployment" ||
          selectedOption === "editEmployment") && (
          <Employment
            newEmployment={
              selectedOption === "newEmployment"
                ? newEmployment
                : currentEmployment
            }
            handleSubmit={handleSubmitEmpInfo}
            edit={true}
            comment={allUpdateRequest?.[selectedOption]?.comment}
            setNewEmployment={
              selectedOption === "newEmployment"
                ? setNewEmployment
                : setCurrentEmployment
            }
            allSkill={allSkill}
            defaultValue={allUpdateRequest?.[selectedOption]?.value}
            isFooter={
              allUpdateRequest?.[selectedOption]?.comment ? true : false
            }
          />
        )}
        {selectedOption === "certification" && (
          <div className="create-profile">
            <div className="block-header between-center">
              <h4>Certifications</h4>
              <h3>
                {allUpdateRequest?.certification?.comment ? (
                  <Tooltip
                    title={"Reject"}
                    titleColor={"red"}
                    tooltipText={allUpdateRequest?.certification?.comment}
                  />
                ) : (
                  <span style={{ color: "red" }}>Pending</span>
                )}
              </h3>
            </div>
            {handleCertContent()}
          </div>
        )}
        {selectedOption === "tool" && (
          <div className="create-profile">
            <div className="block-header small between-center">
              <h4>Tools</h4>
              <h3>
                {allUpdateRequest?.tool?.comment ? (
                  <Tooltip
                    title={"Reject"}
                    titleColor={"red"}
                    tooltipText={allUpdateRequest?.tool?.comment}
                  />
                ) : (
                  <span style={{ color: "red" }}>Pending</span>
                )}
              </h3>
            </div>
            {handleToolContent()}
          </div>
        )}
        {selectedOption === "skills" && (
          <div className="create-profile">
            <div className="row">
              {selectedOption === "skills" && (
                <div className="col-12">
                  <div className="block-header small between-center">
                    <h4>Skills</h4>
                    <h3>
                      {allUpdateRequest?.skills?.comment ? (
                        <Tooltip
                          title={"Reject"}
                          titleColor={"red"}
                          tooltipText={allUpdateRequest?.skills?.comment}
                        />
                      ) : (
                        <span style={{ color: "red" }}>Pending</span>
                      )}
                    </h3>
                  </div>
                  {handleSkillContent()}
                </div>
              )}
            </div>
          </div>
        )}
        {selectedOption === "socialProfile" && (
          <div className="create-profile">
            <div className="row">
              <div className="col-12">
                <div className="block-header between-center">
                  <h4>Social Profiles</h4>
                  <h3>
                    {allUpdateRequest?.socialProfile?.comment ? (
                      <Tooltip
                        title={"Reject"}
                        titleColor={"red"}
                        tooltipText={allUpdateRequest?.socialProfile?.comment}
                      />
                    ) : (
                      <span style={{ color: "red" }}>Pending</span>
                    )}
                  </h3>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="floating-label" htmlFor="">
                        GitHub
                      </label>
                      <input
                        type="url"
                        className="form-control"
                        value={socialProfile?.gitHub}
                        disabled={!allUpdateRequest?.socialProfile?.comment}
                        onChange={(e) =>
                          setSocialProfile({
                            ...socialProfile,
                            gitHub: e.target.value,
                          })
                        }
                        onBlur={() =>
                          socialLinkValid("gitHub", socialProfile?.gitHub)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="floating-label" htmlFor="">
                        LinkedIn
                      </label>
                      <input
                        type="url"
                        className="form-control"
                        value={socialProfile?.linkedin}
                        disabled={!allUpdateRequest?.socialProfile?.comment}
                        onChange={(e) =>
                          setSocialProfile({
                            ...socialProfile,
                            linkedin: e.target.value,
                          })
                        }
                        onBlur={() =>
                          socialLinkValid("linkedin", socialProfile?.linkedin)
                        }
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group">
                      <label className="floating-label" htmlFor="">
                        Twitter
                      </label>
                      <input
                        type="url"
                        className="form-control"
                        value={socialProfile?.twitter}
                        disabled={!allUpdateRequest?.socialProfile?.comment}
                        onChange={(e) =>
                          setSocialProfile({
                            ...socialProfile,
                            twitter: e.target.value,
                          })
                        }
                        onBlur={() =>
                          socialLinkValid("twitter", socialProfile?.twitter)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="floating-label" htmlFor="">
                        Facebook
                      </label>
                      <input
                        type="url"
                        className="form-control"
                        value={socialProfile?.facebook}
                        disabled={!allUpdateRequest?.socialProfile?.comment}
                        onChange={(e) =>
                          setSocialProfile({
                            ...socialProfile,
                            facebook: e.target.value,
                          })
                        }
                        onBlur={() =>
                          socialLinkValid("facebook", socialProfile?.facebook)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {selectedOption &&
          selectedOption !== "newEmployment" &&
          selectedOption !== "editEmployment" && (
            <div className="bottom-button mt30 text-right">
              <a className="submitBtn themeButton" onClick={handleSubmit}>
                {isApprovalUpdating ? (
                  <ClipLoader size={15} color="#FFFFFF" />
                ) : (
                  "Submit"
                )}
              </a>
            </div>
          )}
      </form>
    </Fragment>
  );
};

export default ProfileUpdateRequest;
