import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const BannerSection = ({heading, classname, name}) => {
  return (
    <Fragment>
      <div className={classname}>
        <h1>{heading}</h1>
      </div>
      <div className="olibr-breadcrumbs">
        <div className="container">
          <ul className="ul-breadcrumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>&rsaquo;</li>
            <li>{name}</li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default BannerSection;
