import React, { useRef, useState } from "react";
import { Icon } from "../../../components/icon";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { olibrToast } from "../../../components/toaster";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import {
  jobApprovedOrRejectAction,
  updateAdminJobStatus,
  uploadIcons,
} from "../../../service/action/admin";
import { useNavigate } from "react-router-dom";
import Modal from "../../../components/modal/ModalBox";
import JobDetail from "../../../components/joblists/JobDetail";
import OlibrEditor from "../../../components/editor";
import SelectOption from "../../../components/select/SelectOption";
import { jobTypes, jobTypesNew, valueBoolean } from "../../../common/constant";
import {
  filterFrontUrlEnv,
  getOptionJobSkills,
  mergeSkillStack,
  optionalRequireSkill,
} from "../../../components/functions";
import CloseIcon from "../../../assets/img/company-profile/close.svg";
import SelectCheck from "../../../assets/img/company-profile/select-check.svg";
import Tooltips from "../../../components/tooltip/tooltips/Tooltips";
import { preventMinus } from "../../../common/preventMinus";
import Questionaries from "../../../components/questionaries/Questionaries";
import QuestionariLists from "../../../components/questionaries/QuestionariLists";
import CompanyMultipeJob from "../../../components/CompanyMultipeJob";
import BudgetInput from "../../../components/budget/BudgetInput";
import MultipleJobCountry from "../../../components/multiple-job-country/MultipleJobCountry";
import SelectCustomSkill from "../../../components/select/SelectCustomSkill";
import SelectSkillCustom from "./compoenent/SelectSkillCustom";
import AssesmentTitle from "./compoenent/AssesmentTitle";

const EditJob = ({
  jobData,
  editJobAdmin,
  handleDeleteJob,
  closeModal,
  onHandleSubmit,
  categList,
  setSkillJobIcon,
  setLocation,
  location,
  deletePreview,
  setDeletePreview,
  onPrevious,
  onNext,
  isReachedOnFirst,
  isReachedOnLast,
}) => {
  const {
    getValues,
    register: jobRegister,
    setValue: setJobValue,
    handleSubmit: jobSubmit,
    getValues: getJobValue,
    reset: resetJobForm,
    control: jobControl,
    watch,
    formState: { errors: jobError },
  } = useForm();
  const [tags, setTags] = useState([]);
  const [questionaries, setQuestionaries] = useState({
    modal: false,
    data: [],
  });
  const [country, setCountry] = useState([
    {
      countryCode: "IN",
      countryName: "India",
    },
  ]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [feedback, setFeedback] = useState();
  const [requiredSkill, setRequiredSkill] = useState([]);
  const [optionalSkill, setOptionalSkill] = useState([]);
  const [feedbackModalState, setFeedbackModalState] = useState();
  const [jobAddress, setJobAddress] = useState([
    {
      state: "",
      city: "",
      stateName: "",
    },
  ]);
  const [errorsAddress, setErrors] = useState([]);
  const getWatch=watch();

  useEffect(() => {
    resetJobForm();
  }, [editJobAdmin]);

  const budgetMin = watch("budgetMin");
  const budgetMax = watch("budgetMax");
  const minExpRange = watch("minExpRange");
  const maxExpRange = watch("maxExpRange");
  const jobLogo = watch("jobLogo");
  const [allSkillLists, setAllSkillLists] = useState([]);
  useEffect(() => {
    if (jobData) {
      setJobValue("jobLogo", jobData?.companyLogo?jobData?.companyLogo:jobData?.jobLogo);
      setJobValue("hideBudgetToDev", jobData?.hideBudgetToDev);
      setJobValue("jobSkills", jobData?.jobSkills);
      setJobValue("jobId", jobData?._id);
      setJobValue("jobTitle", jobData?.jobTitle);
      setJobValue("jobLocation", jobData?.jobLocation);
      setJobValue(
        "jobCategory",
        categList?.find((item) => item?.value === jobData?.jobCategory)
      );
      setJobValue("tags", jobData?.tags);
      setJobValue("editorText", jobData?.editorText);
      setJobValue("shortDesc", jobData?.shortDesc);
      setJobValue(
        "isOlibrJob",
        valueBoolean?.find((item) => item?.value === jobData?.isOlibrJob)
      );
      setJobValue("workTiming", jobData?.workTiming);
      jobData?.postDate &&
        setJobValue(
          "postDate",
          new Date(jobData?.postDate).toISOString().split("T")[0]
        );
      setJobValue("postedBy", jobData?.postedBy);
      setJobValue("companyWebsite", jobData?.companyWebsite);
      setJobValue("budget", jobData?.budget);
      setJobValue("budgetMin", jobData?.budgetMin);
      setJobValue("budgetMax", jobData?.budgetMax);
      setJobValue("budgetFor", jobData?.budgetFor);
      if(jobData?.jobSkills?.length){
        setJobValue("jobSkills", getOptionJobSkills(allSkillLists,jobData?.jobSkills));
      }
      setJobValue("organisation", jobData?.organisation);
      let stacks_arr = jobData?.devChooseStacks?.map((item) => ({
        ...item,
        value: item?._id,
        label: item?.stackName,
      }));
      
      setJobValue("jobType", jobData?.jobType);
      setRequiredSkill(optionalRequireSkill(jobData?.devChooseSkills, true));
      setOptionalSkill(optionalRequireSkill(jobData?.devChooseSkills, false));
      setJobValue("devChooseStacks", stacks_arr);
      setJobValue("companyAddress", jobData?.companyAddress);
      setJobValue("minExpRange", parseInt(jobData?.minExpRange));
      setJobValue("maxExpRange", parseInt(jobData?.maxExpRange));
      setJobValue("aboutCompany", jobData?.aboutCompany);
      setJobValue(
        "isPopular",
        valueBoolean?.find((item) => item?.value === jobData?.isPopular)
      );
      setJobValue("employerRole", jobData?.employerRole);
      setSkillJobIcon(jobData?.image);
      setTags(jobData?.tags);
      setLocation(jobData?.jobLocation);
      setJobAddress(jobData?.jobAddress);
      setQuestionaries({
        modal: false,
        data: jobData?.questionaries,
      });

      setHideBudgetToDev(jobData?.hideBudgetToDev);
      if (jobData?.jobCountry?.length) {
        setCountry(jobData?.jobCountry);
      } else {
        setCountry([{ countryCode: "IN", countryName: "India" }]);
      }
    }
  }, [jobData]);

  // const fetchCities = (stateCode) => {
  //   dispatch(getCity({ countryCode: "IN", stateCode: stateCode }, navigate));
  // };

  const { stackList, skillList, skillIcons, isIconUploading, uploadIcon } =
    useSelector((state) => state.adminReducer);
  const [allStackLists, setAllStackLists] = useState([]);
  
  const [hideBudgetToDev, setHideBudgetToDev] = useState(false);

  const uploadIconRef = useRef();

  const onUploadIcon = async (e) => {
    let formData = new FormData();
    formData.append("imageFile", e.target.files[0]);
    if (e.target.files[0]?.size > 1024 * 1024) {
      olibrToast.error("Please use image of size less than 1MB");
    } else {
      dispatch(uploadIcons(formData, navigate));
    }
    e.target.value = null;
  };

  useEffect(() => {
    setJobValue("jobLogo", uploadIcon?.icons?.[uploadIcon?.icons?.length - 1]);
  }, [uploadIcon]);

  useEffect(() => {
    let arr2 = [];
    arr2 = stackList?.data?.map((skill) => ({
      value: skill?._id,
      label: skill?.stackName,
      stackId: skill?._id,
      stackName: skill?.stackName,
      stackIcon: skill?.stackIcon,
    }));
    setAllStackLists(arr2);
  }, [stackList]);
  useEffect(() => {
    let arr = [];
    arr = skillList?.data?.map((skill) => ({
      value: skill?._id,
      label: skill?.skillName,
      skillId: skill?._id,
      skillName: skill?.skillName,
      skillTestIcon: skill?.skillTestIcon,
    }));
    setAllSkillLists(arr);
  }, [skillList]);

  const addTagOnEnter = () => {
    if (getJobValue("tag")) {
      const check = tags?.filter(
        (item) => item?.toUpperCase() == getJobValue("tag")?.toUpperCase()
      );
      if (check.length) {
        olibrToast.error("Tag already exist");
      } else {
        if (getJobValue("tags")) {
          setJobValue("tags", [...getJobValue("tags"), getJobValue("tag")]);
          setTags([...tags, getJobValue("tag")]);
        } else {
          setJobValue("tags", [getJobValue("tag")]);
          setTags([getJobValue("tag")]);
        }
      }
      setJobValue("tag", "");
    } else {
      olibrToast.error("Please enter tag name");
    }
  };

  const handleSubmit = (data) => {
    let check = jobAddress.every((item) => item.state);
    let tempSkill = [];
    if (data?.jobSkills?.length) {
      data?.jobSkills?.map((itm) => {
        let experience = itm?.experiency;
        if (typeof experience == "string") {
          experience = experience.replace(" Years", "");
          experience = experience.replace(" Year", "");
          experience = parseInt(experience);
        }
        tempSkill.push({
          experiency: experience,
          isRequired: itm?.isRequired,
          skillId: itm?.skillId,
          skillName: itm?.label,
        });
      });
      data.jobSkills = tempSkill;
    }
    if (location != "Remote" && !check) {
      setErrors([jobAddress.findIndex((itm) => !itm?.state)]);
      return false;
    } else {
      setErrors([]);
    }
    if (
      data?.budgetMin &&
      parseInt(data?.budgetMin) > parseInt(data?.budgetMax)
    ) {
      return false;
    } else {
      onHandleSubmit({
        ...data,
        devChooseSkills: [...optionalSkill, ...mergeSkillStack(requiredSkill)],
        hideBudgetToDev,
        budgetMin: data?.budgetMin ? data?.budgetMin?.toString() : "",
        budgetMax: data?.budgetMax ? data?.budgetMax?.toString() : "",
        minExpRange: data?.minExpRange?.toString(),
        maxExpRange: data?.maxExpRange?.toString(),
        jobAddress,
        jobCountry: country,
        questionaries: questionaries?.data,
      });
    }
  };

  const [preview, setPreview] = useState({
    open: false,
    jobDetails: {},
  });
  const [drop, setDrop] = useState("");

  const breakIntoFortyChar = (a) => {
    let b = [];
    for (var i = 40; i < a.length; i += 40) {
      b.push(a.slice(i - 40, i));
    }
    b.push(a.slice(a.length - (40 - (a.length % 40))));
    return b.map((item) => <div>{item}</div>);
  };

  const [currentJobStatus, setCurrentJobStatus] = useState(jobData?.status);
  useEffect(() => {
    setCurrentJobStatus(jobData?.status);
  }, [jobData]);

  const handleJobStatus = (status, currentStatus, id) => {
    if (status !== currentStatus) {
      dispatch(
        updateAdminJobStatus(navigate, {
          status,
          id,
        })
      );
      setCurrentJobStatus(status);
    }
  };

  const jobPreview = () => {
    setPreview({
      open: !preview?.open,
      jobDetails: {
        jobTitle: getJobValue("jobTitle"),
        jobCategory: getJobValue("jobCategory"),
        organisation: getJobValue("organisation"),
        maxExpRange: getJobValue("maxExpRange"),
        minExpRange: getValues("minExpRange"),
        budgetMin: getJobValue("budgetMin"),
        budgetMax: getJobValue("budgetMax"),
        postedBy: getJobValue("postedBy"),
        companyWebsite: getJobValue("companyWebsite"),
        employerRole: getJobValue("employerRole"),
        aboutCompany: getJobValue("aboutCompany"),
        jobLogo: getJobValue("jobLogo"),
        companyAddress: getJobValue("companyAddress"),
        postDate: getJobValue("postDate"),
        devChooseSkills: [...optionalSkill, ...mergeSkillStack(requiredSkill)],
        devChooseStacks: getJobValue("devChooseStacks"),
        editorText: getJobValue("editorText"),
        shortDesc: getJobValue("shortDesc"),
        hideBudgetToDev,
        jobLocation: location,
        jobCountry: country,
        jobAddress,
      },
    });
  };

  const handleRejectJobPost = () => {
    feedback
      ? dispatch(
          jobApprovedOrRejectAction(
            { jobId: jobData?._id, data: { feedback } },
            navigate,
            setFeedbackModalState
          )
        )
      : olibrToast.error("Please enter feedback.");
  };

  const clearJobAddress = () => {
    setJobAddress([
      {
        city: "",
        state: "",
      },
    ]);
  };
  const feedbackContent = () => {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="floating-label">Feedback</label>
              <textarea
                rows={5}
                type="text"
                className="form-control"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="bottom-button">
          <span className="cancelBtn" onClick={handleRejectJobPost}>
            Reject
          </span>
        </div>
        <br />
      </>
    );
  };

  const handleAddQuestionries = (data) => {
    setQuestionaries({ modal: false, data });
  };
  const clearQuestion = (index, innerIndex) => {
    let originArr = [...questionaries.data];
    originArr[index].questionLists[innerIndex].isAdded = false;
    originArr[index].questionLists[innerIndex].isMandatory = false;
    setQuestionaries({
      modal: false,
      data: originArr,
    });
  };
  const cancelQuestion = () => {
    setQuestionaries({
      ...questionaries,
      modal: false,
    });
  };

  const copyLink = async () => {
    await navigator.clipboard.writeText(
      filterFrontUrlEnv("jobs/" + jobData?.slug)
    );
    olibrToast.success("Link Copied!");
  };

  return (
    <form>
      <Modal
        open={questionaries?.modal}
        hasHeader={false}
        content={() => (
          <Questionaries
            handleSubmit={handleAddQuestionries}
            cancelBtn={cancelQuestion}
            setQuestionaries={setQuestionaries}
            questionaries={questionaries}
          />
        )}
        isCancelAble={false}
        size="100%"
        contentClass="questionaries-header"
        isFooterView={false}
      />
      <Modal
        open={deletePreview}
        title={"Are you sure?"}
        onClose={() => setDeletePreview(false)}
        onDelete={handleDeleteJob}
        content={() => <p>Are you sure you want to delete this job?.</p>}
        onCancel={() => setDeletePreview(false)}
        confirmDelete={true}
        isFooterView={true}
      />
      <Modal
        title="Job Preview"
        open={preview?.open}
        content={() => <JobDetail jobDetail={preview?.jobDetails} />}
        size="100%"
        isFooterView={false}
        onClose={() =>
          setPreview({
            open: false,
          })
        }
      />
      <Modal
        open={feedbackModalState?.isOpen}
        title={"Feedback"}
        onClose={() => setFeedbackModalState({ isOpen: false })}
        isFooterView={false}
        content={feedbackContent}
      />
      <div className="shortlisted-wrap dash-block">
        <div className="shortlisted-block card">
          <div className="job-rquirement">
            <h4 className="job-rquirement-title">
              <div className="job-mange-flex-row">
                <div>EDIT JOB</div>
                <span className="pre-next-btn-text" onClick={onPrevious}>
                  {isReachedOnFirst ? "" : "< Previous"}
                </span>
                <div>
                  <div className="company-profile">
                    <div className="info-right" style={{ padding: 0 }}>
                      <div
                        className={
                          jobData?._id === drop &&
                          currentJobStatus !== "pending" &&
                          currentJobStatus !== "rejected"
                            ? "dropdown-active"
                            : "dropdown"
                        }
                        style={{ marginBottom: 0 }}
                        onClick={(e) => {
                          e?.stopPropagation();
                          drop ? setDrop("") : setDrop(jobData?._id);
                        }}
                      >
                        {/* breakIntoFortyChar */}
                        <a href="#-" className="reject-tooltip-s">
                          {currentJobStatus === "rejected" ? (
                            <Tooltips
                              content={
                                <div>
                                  {breakIntoFortyChar(jobData?.feedback)}
                                </div>
                              }
                              direction="top2"
                            >
                              <div
                                className="job-drop-capitalize"
                                style={{ backgroundColor: "#ffffff" }}
                              >
                                {currentJobStatus}
                              </div>
                            </Tooltips>
                          ) : (
                            <span className="job-drop-capitalize">
                              {currentJobStatus
                                ? currentJobStatus
                                : "Select Status"}
                            </span>
                          )}
                        </a>
                        <div className="dropdown-content">
                          <ul className="popupList">
                            <li
                              className={`popUpListItem1 pop-flex ${
                                currentJobStatus === "open" ? "selected-bg" : ""
                              }`}
                              onClick={() => {
                                currentJobStatus === "closed"
                                  ? olibrToast.error(
                                      "You can't re-open a closed job."
                                    )
                                  : handleJobStatus(
                                      "open",
                                      currentJobStatus,
                                      jobData?._id
                                    );
                              }}
                            >
                              <span>OPEN</span>{" "}
                              <img
                                src={
                                  currentJobStatus === "open"
                                    ? SelectCheck
                                    : CloseIcon
                                }
                                alt="Selected"
                              />
                            </li>
                            <li
                              className={`popUpListItem2 pop-flex ${
                                currentJobStatus === "paused"
                                  ? "selected-bg"
                                  : ""
                              }`}
                              onClick={() => {
                                currentJobStatus === "closed"
                                  ? olibrToast.error(
                                      "You can't pause a closed job."
                                    )
                                  : handleJobStatus(
                                      "paused",
                                      currentJobStatus,
                                      jobData?._id
                                    );
                              }}
                            >
                              <span>PAUSE</span>{" "}
                              <img
                                src={
                                  currentJobStatus === "paused"
                                    ? SelectCheck
                                    : CloseIcon
                                }
                                alt="Closed"
                              />
                            </li>
                            <li
                              className={`popUpListItem3 pop-flex ${
                                currentJobStatus === "closed"
                                  ? "selected-bg"
                                  : ""
                              }`}
                              onClick={() => {
                                currentJobStatus !== "closed" &&
                                  handleJobStatus(
                                    "closed",
                                    currentJobStatus,
                                    jobData?._id
                                  );
                              }}
                            >
                              <span>CLOSE</span>{" "}
                              <img
                                src={
                                  currentJobStatus === "closed"
                                    ? SelectCheck
                                    : CloseIcon
                                }
                                alt="Closed"
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <span className="pre-next-btn-text" onClick={onNext}>
                  {isReachedOnLast ? "" : "Next >"}
                </span>
                <div className="flex-row-item">
                  <Icon name="delete" onClick={() => setDeletePreview(true)} />
                  <div onClick={() => closeModal()}>
                    <Icon name="modal-close" />
                  </div>
                </div>
              </div>
            </h4>
            <div className="job-rquirement-form">
              <div className="row">
                {(currentJobStatus == "closed" ||
                  currentJobStatus == "open") && (
                  <div className="col-12">
                    <div className="form-group adornment">
                      <label className="" htmlFor="">
                        Job Link
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="tag"
                        value={filterFrontUrlEnv("jobs/" + jobData?.slug)}
                      />
                      <a className="browseImage" onClick={copyLink}>
                        Copy
                      </a>
                    </div>
                  </div>
                )}

                <div className="col-4">
                  <div className="form-group">
                    <label className={`${jobError?.jobTitle && "text-danger"}`}>
                      Job Title*
                    </label>
                    <input
                      {...jobRegister("jobTitle", { required: true })}
                      type="text"
                      name="jobTitle"
                      className="form-control"
                      placeholder="React Native Developer (Backend)"
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label
                      className={`${jobError?.jobCategory && "text-danger"}`}
                    >
                      Category*
                    </label>
                    <Controller
                      control={jobControl}
                      name="jobCategory"
                      {...jobRegister("jobCategory", { required: true })}
                      render={({ field }) => (
                        <SelectOption
                          optionArray={categList ? categList : []}
                          fieldProp={field}
                          getValue={getValues}
                          getValueName={"jobCategory"}
                          closeMenuOnSelect={false}
                          themeStyle={true}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label
                      className={
                        jobError.minExpRange || jobError.maxExpRange
                          ? "text-danger"
                          : ""
                      }
                    >
                      Experience (Years)*
                    </label>
                    <div className="row">
                      <div className="col-5">
                        <div className="form-group">
                          <input
                            type="tel"
                            maxLength={2}
                            onKeyDown={preventMinus}
                            {...jobRegister("minExpRange", {
                              required: true,
                              valueAsNumber: true,
                              validate: (value) =>
                                parseInt(value) < parseInt(maxExpRange),
                            })}
                            name="minExpRange"
                            className="form-control"
                            placeholder="Min"
                          />
                        </div>
                      </div>
                      <div className="col-2">To</div>
                      <div className="col-5">
                        <div className="form-group">
                          <input
                            type="tel"
                            maxLength={2}
                            onKeyDown={preventMinus}
                            name="maxExpRange"
                            {...jobRegister("maxExpRange", {
                              required: true,
                              valueAsNumber: true,
                              validate: (value) =>
                                parseInt(value) > parseInt(minExpRange),
                            })}
                            className="form-control"
                            placeholder="Max"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label
                      className={`${jobError?.organisation && "text-danger"}`}
                    >
                      Company Name*
                    </label>
                    <input
                      {...jobRegister("organisation", { required: true })}
                      type="text"
                      name="organisation"
                      className="form-control"
                      placeholder="Company Name"
                    />
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-group">
                    <label
                      className={`${jobError?.isPopular && "text-danger"}`}
                    >
                      Popular*
                    </label>
                    <Controller
                      control={jobControl}
                      name="isPopular"
                      {...jobRegister("isPopular")}
                      render={({ field }) => (
                        <SelectOption
                          optionArray={valueBoolean}
                          fieldProp={field}
                          getValue={getValues}
                          getValueName={"isPopular"}
                          closeMenuOnSelect={false}
                          themeStyle={true}
                        />
                      )}
                    />
                  </div>
                </div>

                <BudgetInput
                  budgetMin={budgetMin}
                  errors={jobError}
                  hideBudgetToDev={hideBudgetToDev}
                  country={country}
                  register={jobRegister}
                  budgetMax={budgetMax}
                  setValue={setJobValue}
                  setHideBudgetToDev={setHideBudgetToDev}
                />
                <div className="col-2">
                  <div className="form-group">
                    <label
                      className={`${jobError?.jobLocation && "text-danger"}`}
                    >
                      Job Location*
                    </label>
                    <SelectOption
                      optionArray={jobTypes}
                      state={jobTypes?.find((item) => item?.value === location)}
                      onChangeFunction={(e) => {
                        setLocation(e?.value);
                        setCountry([
                          {
                            countryCode: "IN",
                            countryName: "India",
                          },
                        ]);
                        if (e?.value == "Remote") {
                          clearJobAddress();
                        }
                      }}
                      closeMenuOnSelect={true}
                      themeStyle={true}
                    />
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-group">
                    <label
                      className={`${jobError?.jobLocation && "text-danger"}`}
                    >
                      Job Type*
                    </label>
                    <SelectOption
                      optionArray={jobTypesNew}
                      onChangeFunction={(e) => setJobValue("jobType", e?.value)}
                      state={{ label: getWatch?.jobType, value: getWatch?.jobType }}
                      closeMenuOnSelect={true}
                      themeStyle={true}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <MultipleJobCountry
                      location={location}
                      country={country}
                      setCountry={setCountry}
                      className=""
                      setJobAddress={setJobAddress}
                    />
                    <div className="col-8">
                      <div className="row">
                        {(location === "On Site" || location === "Hybrid") && (
                          <>
                            <CompanyMultipeJob
                              jobAddress={jobAddress}
                              setErrors={setErrors}
                              errorsAddress={errorsAddress}
                              jobError={jobError}
                              setJobAddress={setJobAddress}
                              setCountry={setCountry}
                              country={country}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group adornment">
                  <SelectSkillCustom
                      setValue={setJobValue}
                      watch={getWatch}
                      register={jobRegister}
                      errors={jobError}
                      control={jobControl}
                      allSkillLists={allSkillLists}
                    />
                    {/* <label className="" htmlFor="">
                      Add Tech Tags
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="tag"
                      {...jobRegister("tag")}
                      onKeyDown={(e) => {
                        if (e?.code === "Enter") {
                          e.preventDefault();
                          addTagOnEnter();
                        }
                      }}
                    />
                    <a className="browseImage" onClick={() => addTagOnEnter()}>
                      Add Tag
                    </a> */}
                  </div>
                </div>

                <ul className="tags tags-fill col-12">
                  {tags?.map((item, index) => (
                    <li>
                      <a>
                        <span>{item}</span>{" "}
                        <i
                          onClick={() => {
                            const tempSkill = [...tags];
                            tempSkill.splice(index, 1);
                            if (tags?.length === 1) {
                              setJobValue("tags", "");
                            } else {
                              setJobValue("tags", tempSkill);
                            }

                            setTags(tempSkill);
                          }}
                        >
                          <Icon name="modal-close" />
                        </i>
                      </a>
                    </li>
                  ))}
                </ul>
                <AssesmentTitle setJobValue={setJobValue} getWatch={getWatch} />
                <div className="col-12">
                  <SelectCustomSkill
                    jobControl={jobControl}
                    register={jobRegister}
                    allSkillLists={allSkillLists}
                    getValues={getValues}
                    allStackLists={allStackLists}
                    watch={watch}
                    setValue={setJobValue}
                    setRequiredSkill={setRequiredSkill}
                    setOptionalSkill={setOptionalSkill}
                    requiredSkill={requiredSkill}
                    optionalSkill={optionalSkill}
                  />
                </div>

                <div className="col-12">
                  <div className="form-group adornment">
                    <label>Company Logo*</label>
                    <input
                      type="text"
                      className={`form-control ${
                        jobError?.jobLogo && "form-required"
                      }`}
                      disabled={true}
                    />
                    <img className="input-img" src={jobLogo} />
                    <input
                      ref={uploadIconRef}
                      type="file"
                      onChange={onUploadIcon}
                      className={`input-file ${
                        jobError?.jobLogo && "form-required"
                      }`}
                      accept=".png, .jpeg, .jpg, .svg"
                    />
                    <a
                      className="browseImage browseImageNew"
                      onClick={() => uploadIconRef.current.click()}
                    >
                      {isIconUploading ? (
                        <ClipLoader size={20} color="#3BA0AA" />
                      ) : (
                        "Upload Cover"
                      )}
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label
                      className={`${jobError?.shortDesc && "text-danger"}`}
                    >
                      Job Description*
                    </label>
                    <Controller
                      control={jobControl}
                      name="shortDesc"
                      {...jobRegister("shortDesc", {
                        required: true,
                      })}
                      render={({ field }) => (
                        <OlibrEditor
                          value={getJobValue("shortDesc")}
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label
                      className={`${jobError?.editorText && "text-danger"}`}
                    >
                      Job Responsiblities*
                    </label>
                    <Controller
                      control={jobControl}
                      name="editorText"
                      {...jobRegister("editorText", {
                        required: true,
                      })}
                      render={({ field }) => (
                        <OlibrEditor
                          value={getJobValue("editorText")}
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row add-job-form ">
                <div className="col-12 text-right">
                  <p
                    onClick={() =>
                      setQuestionaries({ ...questionaries, modal: true })
                    }
                  >
                    <span className="plus">+</span>
                    <span className="add-head">
                      Add Questionnaire for candidates
                    </span>
                  </p>
                </div>
                <QuestionariLists
                  data={questionaries?.data}
                  clearQuestion={clearQuestion}
                />
              </div>
              <h4 className="job-rquirement-title">EMPLOYER INFO</h4>
              <div className="row">
                <div className="col-3">
                  <div className="form-group">
                    <label className={`${jobError?.postedBy && "text-danger"}`}>
                      Posted By*
                    </label>
                    <input
                      {...jobRegister("postedBy", {
                        required: true,
                      })}
                      type="text"
                      name="postedBy"
                      className="form-control"
                      placeholder="Posted By"
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <label
                      className={`${jobError?.companyWebsite && "text-danger"}`}
                    >
                      Company Website
                    </label>
                    <input
                      {...jobRegister("companyWebsite", {
                        pattern:
                          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                      })}
                      type="text"
                      name="companyWebsite"
                      className="form-control"
                      placeholder="Company Website"
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <label
                      className={`${jobError?.companyAddress && "text-danger"}`}
                    >
                      Company Address
                    </label>
                    <input
                      {...jobRegister("companyAddress")}
                      type="text"
                      name="companyAddress"
                      className="form-control"
                      placeholder="Company Address"
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <label
                      className={jobError.employerRole ? "text-danger" : ""}
                    >
                      Designation*
                    </label>

                    <input
                      {...jobRegister("employerRole", {
                        required: true,
                      })}
                      name="employerRole"
                      type="text"
                      className="form-control"
                      placeholder="Employer Role"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label
                      className={`${jobError?.aboutCompany && "text-danger"}`}
                    >
                      About Company*
                    </label>
                    <textarea
                      name="aboutCompany"
                      rows={5}
                      {...jobRegister("aboutCompany")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-block justify-content-end">
              {!jobData?.isApproved && (
                <button
                  className="cancelBtn"
                  type="button"
                  onClick={() => setFeedbackModalState({ isOpen: true })}
                >
                  Reject
                </button>
              )}
              <a className="btn" onClick={jobSubmit(handleSubmit)}>
                Submit
              </a>
              <a className="btn" onClick={jobPreview}>
                Preview
              </a>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditJob;
