import React from 'react'
import { Icon } from '../../../icon'

const AddressDetail = ({openModal,resumeData}) => {
  return (
    <div className="setting-card">
        <div className="row">
          <div className="col-12">
            <h3>
              Address
              <span onClick={() => openModal("address")}>
                <Icon name="edit" />
              </span>
            </h3>
            <p>
              {resumeData?.devAddress && resumeData?.devAddress?.concat(",")}{" "}
              {resumeData?.devAddress2 && resumeData?.devAddress2?.concat(",")}{" "}
              {resumeData?.devCity && resumeData?.devCity?.concat(",")}{" "}
              {resumeData?.devState && resumeData?.devState?.concat(",")}{" "}
              {resumeData?.devPinCode}
            </p>
          </div>
        </div>
      </div>
  )
}

export default AddressDetail