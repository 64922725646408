import { Fragment, useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../components/icon";
import { olibrToast } from "../../../components/toaster";
import {
  approvalUpdateAction,
  getAllSkillsList,
  currentEmploymentAction,
  getAllTools,
  getDevSocialProfile,
} from "../../../service/action/developer";
import Employment from "./Employment";
import { regexPattern } from "./SocialRegexPattern";
import { profileEditOption } from "../../../common/constant";
import { MainContext } from "../../../context/MainContext";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import SelectOption from "../../../components/select/SelectOption";
import { uploadVideoWithoutDeleteAction } from "../../../service/action/common";
import { useRef } from "react";
import PercentageLoaderContent from "../../../components/percentageLoader/PercentageLoaderContent";

const storyDummy =
  "I am a Senior Java Developer with 5+ years of active work experience within Agile processes. I have strong operational knowledge of Springboot and Hilla frameworks, and integration tools such as Gitlab, Jenkins, Cruise Control, and Bamboo. Coding and release management are my strong suit and I have worked on tight deadlines in all phases of production with successful outcomes. I bring to the table my ingenious analytical and problem-solving capabilities that have averted many disasters and expedited the production process in my past jobs. Teamwork, seamless communication, proper documentation, and enforcing the engineering standards are some of the facilities that come naturally to me with my years of experience in the field.";

const EditProfile = () => {

  const {videoTobeSent, uploadVideoLoad} = useSelector((state) => state.commonReducer);
  const {
   user : {userType}
  } = useContext(MainContext);


  const dispatch = useDispatch();
  const [allSkill, setAllSkills] = useState([]);
  const navigate = useNavigate();
  const [selectedField, setEditField] = useState();

  const {
    register: videoRegister,
    setValue: setVideoValue,
    handleSubmit: videoSubmit,
    getValues: getVideoValue,
    reset: resetVideoForm,
    watch: videoWatch,
  } = useForm();

  const vidWatch = videoWatch("devVideo")

  const {
    register: descRegister,
    setValue: setDescValue,
    handleSubmit: descSubmit,
    getValues: getDescValue,
    reset: resetDescForm,
    control: descControl,
    formState: { errors: descError },
  } = useForm();

  const {
    register: achievementRegister,
    handleSubmit: achievementSubmit,
    formState: { errors: achievementError },
  } = useForm();

  const {
    register: certfctRegister,
    setValue: setCertfctValue,
    handleSubmit: certfctSubmit,
    reset: resetCertfctForm,
    control: certfctControl,
    formState: { errors: certfctError },
  } = useForm();

  const {
    register: toolRegister,
    setValue: setToolValue,
    handleSubmit: toolSubmit,
    reset: resetToolForm,
    getValues: getToolValue,
    control: toolControl,
    formState: { errors: toolError },
  } = useForm();

  const {
    register: skillRegister,
    setValue: setSkillValue,
    handleSubmit: skillSubmit,
    reset: resetSkillForm,
    getValues: getSkillValue,
    formState: { errors: skillError },
  } = useForm();

  const uploadVideoRef = useRef();

  const uploadVideoLogic = () => {
    uploadVideoRef.current.click()
}
  const [videoPercentage, setVideoPercentage] = useState(0);
  const onUploadIntroductoryVideo = (e) => {
    let formData = new FormData();
    formData.append("videoFile", e.target.files[0]);
    if (e.target.files[0]?.size > 150 * 1024 * 1024) {
      olibrToast.error("Please use video of size less than 150MB");
    } else {
      dispatch(uploadVideoWithoutDeleteAction(formData, navigate, setVideoPercentage));
    }
    e.target.value = null;
  };

  useEffect(() => {
    setVideoValue("devVideo", videoTobeSent);
  }, [videoTobeSent])

  const [socialProfile, setSocialProfile] = useState({});
  const [addedData, setAddedData] = useState([]);
  const [skillDetail, setSkillDetails] = useState([]);
  const {
    isApprovalUpdating,
    devSocialProfileData,
    allSkills,
    devAllToolLists,
    currentEmployment,
    resumeData,
  } = useSelector((state) => state.developerReducer);
  const { dataForApproval } = useSelector((state) => state.commonReducer);

  useEffect(() => {
    const added_Data = dataForApproval.map((item) => item.slug);
    setAddedData(added_Data);
  }, [dataForApproval]);

  useEffect(() => {
    !isApprovalUpdating && setEditField("");
  }, [isApprovalUpdating]);

  const handleFieldChange = (e) => {
    setEditField(e.target.value);
  };

  useEffect(() => {
    if (userType === "Developer") {
      dispatch(getDevSocialProfile(navigate));
      dispatch(getAllTools(navigate));
      dispatch(getAllSkillsList(navigate));
      dispatch(currentEmploymentAction(navigate));
      
    }
  }, [userType]);

  useEffect(() => {
    let arr = [];
    arr = allSkills?.map((skill) => ({
      value: skill?.skillTestIcon,
      label: skill?.skillName,
    }));
    setAllSkills(arr);
  }, [allSkills]);

  const addSkillInTechSkill = () => {
    if (!getSkillValue("skills")) {
      olibrToast.error("please enter skill name!");
      return false;
    }
    if (skillDetail && skillDetail?.length > 0) {
      const check = skillDetail?.filter((item) => item?.toUpperCase() == getSkillValue("skills")?.toUpperCase())
      if (check?.length > 0) {
        olibrToast.error("tag already exists!")
        return false;
      } else {
        setSkillValue("tagArr", [...getSkillValue("tagArr"), getSkillValue("skills")]);
        setSkillDetails([...skillDetail, getSkillValue("skills")])
      }
    } else {
      setSkillValue("tagArr", [getSkillValue("skills")])
      setSkillDetails([getSkillValue("skills")])
    }
    setSkillValue("skills", "")
  };

  const handleSkillContent = () => {
    return (
      <form>
        <div className="row">
          <div className="form-group col-12">
            <label className={`floating-label ${skillError?.title && "text-danger"}`}>Title</label>
            <input
              type="text"
              name="title"
              className={`form-control col-12 ${skillError?.title && "form-required"}`}
              {
              ...skillRegister("title", { required: true })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className={`floating-label ${skillError?.tagArr && "text-danger"}`} htmlFor="">
                Add Skills
              </label>
              <input
                {...skillRegister("tagArr", { required: true })}
                name="skills"
                type="hidden"
              />
              <div style={{ display: "flex" }}>
                <input
                  type="text"
                  {...skillRegister("skills")}
                  name="skills"
                  className={`form-control ${skillError?.tagArr && "form-required"}`}
                  onKeyDown={(e) => {
                    if (e?.code === "Enter") {
                      addSkillInTechSkill();
                    }
                  }}
                />
                <a
                  onClick={() => addSkillInTechSkill()}
                >
                  <Icon name="add-icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <ul className="tags tags-fill">
          {skillDetail?.map((item, index) => (
            <li>
              <a>
                <span>{item}</span>{" "}
                <i
                  onClick={() => {
                    const tempSkill = skillDetail?.length ? [...skillDetail] : [];
                    tempSkill.splice(index, 1);
                    if (skillDetail?.length == 1) {
                      setSkillValue("tagArr", "")
                    } else {
                      setSkillValue("tagArr", tempSkill)
                    }
                    setSkillDetails(tempSkill)
                  }}
                >
                  <Icon name="modal-close" />
                </i>
              </a>
            </li>
          ))}
        </ul>
        <br />
        <div className="col-12">
          <div className="bottom-button">
            <button
              type="button"
              onClick={skillSubmit(handleSubmit)}
              className="submitBtn themeButton">
              {
                isApprovalUpdating ? <ClipLoader size={15} color="#FFFFFF" /> : "Submit"
              }
            </button>
          </div>
        </div>
      </form>
    );
  };

  const [optionArr, setOptionArr] = useState([]);
  useEffect(() => {
    let arr1 = [];
    arr1 = resumeData?.devTools?.map((item) => item?.toolName + item?.toolIcon);
    let arr2 = [];
    arr2 = devAllToolLists?.filter(
      (item) => !arr1?.includes(item?.toolsName + item?.toolsIcon)
    );
    let optionArr = [];
    optionArr = arr2?.map((item) => ({
      label: item?.toolsName,
      value: item?.toolsIcon,
    }));
    setOptionArr(optionArr);
  }, [devAllToolLists]);

  const handleToolContent = () => {
    return (
      <form>
        <div style={{ display: "flex" }}>
          <div className={`form-group col-12 ${toolError?.tools && "select-required"}`}>
            <Controller
              control={toolControl}
              name='tools'
              {...toolRegister("tools", {
                required: true,
              })}
              render={({ field }) => (
                <SelectOption
                  isMulti={true}
                  optionArray={!!optionArr?.length ? optionArr : []}
                  getValue={getToolValue}
                  getValueName={"tools"}
                  fieldProp={field}
                  searchable={true}
                />
              )}
            />
          </div>
        </div>
        <br />
        <div className="col-12">
          <div className="bottom-button">
            <button
              onClick={toolSubmit(handleSubmit)}
              className="submitBtn themeButton">
              {
                isApprovalUpdating ? <ClipLoader size={15} color="#FFFFFF" /> : "Submit"
              }
            </button>
          </div>
        </div>
      </form>
    );
  };

  const handleCertContent = () => {
    return (
      <form>
        <div style={{ display: "flex" }}>
          <div className="row">
            <div className="form-group col-6">
              <input
                type="text"
                className={`form-control col-12 ${certfctError?.certificateName && "form-required"}`}
                placeholder="Certficate Name"
                name="certificateName"
                {...certfctRegister("certificateName", {
                  required: true,
                })}
              />
            </div>

            <div className="form-group col-6">
              <input
                type="text"
                className={`form-control col-12 ${certfctError?.certInstitution && "form-required"}`}
                placeholder="Name of The Institution"
                name="certInstitution"
                {...certfctRegister("certInstitution")}
              />
            </div>

            <div className={`form-group col-6  ${certfctError?.startDate && "date-required"}`}>

              <Controller
                control={certfctControl}
                name='startDate'
                {...certfctRegister("startDate")}
                render={({ field }) => (
                  <ReactDatePicker
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    placeholderText="Start Date"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                  />
                )}
              />


            </div>
            <div className={`form-group col-6  ${certfctError?.endDate && "date-required"}`}>
              <Controller
                control={certfctControl}
                name='endDate'
                {...certfctRegister("endDate")}
                render={({ field }) => (
                  <ReactDatePicker
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    placeholderText="End Date"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker

                  />
                )}
              />

            </div>
          </div>
        </div>
        <br />
        <div className="col-12">
          <div className="bottom-button">
            <button
              onClick={certfctSubmit(handleSubmit)}
              className="submitBtn themeButton">
              {
                isApprovalUpdating ? <ClipLoader size={15} color="#FFFFFF" /> : "Submit"
              }
            </button>
          </div>
        </div>
      </form>
    );
  };

  const socialLinkValid = (platform, link) => {
    if (regexPattern[platform].test(link) || link == "") {
      const tempObj = { ...socialProfile };
      tempObj[`${platform}`] = link;
      setSocialProfile(tempObj);
    } else {
      link && olibrToast.error(`Please Enter a Valid Link for ${platform}!!!`);
    }
  };

  const setValue = (value, slug) => {
    if (value) {
      const newValue = {};
      newValue[`${slug}`] = value;
      dispatch(
        approvalUpdateAction(navigate, {
          slug: slug,
          newValue: newValue,
        })
      );
    } else {
      olibrToast.error("Please enter value");
    }
  };

  const handleSubmit = (data) => {
    switch (selectedField) {
      case "devVideo":
        console.log(data)
        setValue(data?.devVideo, selectedField);
        break;
      case "tellYourStory":
        setValue(data?.tellYourStory, selectedField);
        break;
      case "skills":
        const skillValue = {};
        skillValue[`${selectedField}`] =  { title: data?.title, tagArr: data?.tagArr } ;
        dispatch(
          approvalUpdateAction(navigate, {
            slug: selectedField,
            newValue: skillValue,
          })
        );
        break;
      case "tool":
        let err = false;
        resumeData?.devTools?.map((item) => {
          data?.tools?.map((itm) => {
            if (item?.toolName == itm?.label) {
              olibrToast.error(`${item?.toolName}tool is already exists`);
              err = true;
              return false;
            }
          });
        });
        const toolValue = {};
        let finalToolArr = [];
        finalToolArr = data?.tools?.map((item) => ({
          toolName: item?.label,
          toolIcon: item?.value,
          toolExp: "temp",
        }));
        toolValue[`${selectedField}`] = finalToolArr;
        !err &&
          dispatch(
            approvalUpdateAction(navigate, {
              slug: selectedField,
              newValue: toolValue,
            })
          );

        break;
      case "certification":
        const certValue = {};
        certValue[`${selectedField}`] = data;
        dispatch(
          approvalUpdateAction(navigate, {
            slug: selectedField,
            newValue: certValue,
          })
        );
        break;
      case "achievement":
        const newValue = {};
        newValue[`${selectedField}`] = { title: [data?.achievement] };
        dispatch(
          approvalUpdateAction(navigate, {
            slug: selectedField,
            newValue: newValue,
          })
        );
        break;
      case "socialProfile":
        if (socialProfile && Object.keys(socialProfile)?.length > 0) {
          const newValue = {};
          newValue[`${selectedField}`] = socialProfile;
          dispatch(
            approvalUpdateAction(navigate, {
              slug: selectedField,
              newValue: newValue,
            })
          );
          setSocialProfile({});
        } else {
          olibrToast.error("Please enter atleast one value");
        }
        break;
    }
  };

  const handleSubmitEmpInfo = (data) => {
    data?.stillWorking && delete data["to"];
 
      let finalObj = data;
      let finalObjTool = [];
      finalObjTool = finalObj?.toolUsed?.map((item) => ({
        label: item?.label,
        value: item?.value,
      }));
      data["toolUsed"] = finalObjTool;

      finalObjTool = [];
      finalObjTool = finalObj?.techUsed?.map((item) => ({
        label: item?.label,
        value: item?.value,
      }));
      data["techUsed"] = finalObjTool;

      const newValue = {};
      newValue[selectedField] = data;
      dispatch(
        approvalUpdateAction(navigate, {
          slug: selectedField,
          newValue: newValue,
        })
      );
   
  };

  return (
    <Fragment>
      <form>
      <PercentageLoaderContent
            uploadLoading={uploadVideoLoad}
            uploadPercentage={videoPercentage}
          />
        <div className="create-profile">
          <select
            className="form-control col-12"
            placeholder="Select Field"
            onChange={handleFieldChange}
            value={selectedField}
          >
            <option value={""}>Select field </option>
            {profileEditOption.map((item) => {
              if (
                !addedData.includes(item.value) &&
                item.value !== "editEmployment"
              ) {
                if (item.value === "socialProfile") {
                  if (
                    (devSocialProfileData &&
                      Object.keys(devSocialProfileData) < 4) ||
                    !devSocialProfileData
                  ) {
                    return <option value={item.value}>{item.label}</option>;
                  }
                } else {
                  return <option value={item.value}>{item.label}</option>;
                }
              } else if (
                !addedData.includes(item.value) &&
                item.value === "editEmployment"
              ) {
                if (currentEmployment && Object?.keys(currentEmployment)?.length > 0) {
                  return <option value={item.value}>{item.label}</option>;
                }
              }
            })}
          </select>
        </div>

        {selectedField === "devVideo" && (
          <Fragment>
            <div className="create-profile">
              <div className="block-header">
                <h4>Introductory Video</h4>
              </div>
                <div className="row">
                  <div className="col-12">
                      <span className="link" style={{color: !!vidWatch ? "#e2e2e2" : ""}} onClick={() => !!vidWatch ? olibrToast.error("Please press submit!") :  uploadVideoLogic()}>UPLOAD VIDEO</span>
                  </div>
                </div>
            </div>
            <div>
            <input
                ref={uploadVideoRef}
                type="file"
                onChange={onUploadIntroductoryVideo}
                className="input-file"
                accept=".mp4, .webm, .mkv, .mov"
              />
              <div className="col-12">
              <div className="bottom-button">
                <button
                  onClick={videoSubmit(handleSubmit)}
                  className="submitBtn themeButton">
                  {
                    isApprovalUpdating ? <ClipLoader size={15} color="#FFFFFF" /> : "Submit"
                  }
                </button>
              </div>
            </div>
            </div>
          </Fragment>
        )}

        {selectedField === "tellYourStory" && (
          <form>
            <div className="create-profile">
              <div className="block-header">
                <h4>Tell your story </h4>
              </div>
              <textarea

                rows={10}
                placeholder={storyDummy}
                name="tellYourStory"
                className={`form-control col-12 ${descError?.tellYourStory && "form-required"}`}
                {
                ...descRegister("tellYourStory", { required: true })
                }
                defaultValue={resumeData?.devTellUrStory}
              />
            </div>
            <br />
            <div className="col-12">
              <div className="bottom-button">
                <button
                  onClick={descSubmit(handleSubmit)}
                  className="submitBtn themeButton">
                  {
                    isApprovalUpdating ? <ClipLoader size={15} color="#FFFFFF" /> : "Submit"
                  }
                </button>
              </div>
            </div>
          </form>
        )}

        {selectedField === "achievement" && (
          <form>
            <div className="create-profile">
              <div className="block-header">
                <h4>Achievements</h4>
              </div>
              <textarea
                rows={10}
                className={`form-control col-12 ${achievementError?.achievement && "form-required"}`}
                {
                ...achievementRegister("achievement", { required: true })
                }
                name="achievement"
                placeholder={"Enter achievement"}

              />
            </div>
            <br />
            <div className="col-12">
              <div className="bottom-button">
                <button
                  onClick={achievementSubmit(handleSubmit)}
                  className="submitBtn themeButton">
                  {
                    isApprovalUpdating ? <ClipLoader size={15} color="#FFFFFF" /> : "Submit"
                  }
                </button>
              </div>
            </div>
          </form>
        )}

        {selectedField === "newEmployment" && (
          <Employment
            handleSubmit={handleSubmitEmpInfo}
            allSkill={allSkill}
            newEmployments={true}
          />
        )}

        {selectedField === "editEmployment" && (
          <Employment
            handleSubmit={handleSubmitEmpInfo}
            allSkill={allSkill}
            defaultValue={currentEmployment}
          />
        )}

        {selectedField === "certification" && (
          <div className="create-profile">
            <div className="block-header">
              <h4>Certifications</h4>
            </div>
            {handleCertContent()}
          </div>
        )}

        {selectedField === "tool" && (
          <div className="create-profile">
            <div className="block-header small">
              <h4>Tools</h4>
            </div>
            {handleToolContent()}
          </div>
        )}

        {selectedField === "skills" && (
          <div className="create-profile">
            <div className="row">
              {selectedField === "skills" && (
                <div className="col-12">
                  <div className="block-header small">
                    <h4>Skills</h4>
                  </div>
                  {handleSkillContent()}
                </div>
              )}
            </div>
          </div>
        )}

        {selectedField === "socialProfile" && (
          <div className="create-profile">
            <div className="row">
              <div className="col-12">
                <div className="block-header">
                  <h4>Social Profiles</h4>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="floating-label" htmlFor="">
                        GitHub
                      </label>
                      <input
                        type="url"
                        className="form-control"
                        value={socialProfile?.gitHub}
                        disabled={devSocialProfileData?.gitHub}
                        onChange={(e) =>
                          setSocialProfile({
                            ...socialProfile,
                            gitHub: e.target.value,
                          })
                        }
                        onBlur={() =>
                          socialLinkValid("gitHub", socialProfile?.gitHub)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="floating-label" htmlFor="">
                        LinkedIn
                      </label>
                      <input
                        type="url"
                        className="form-control"
                        value={socialProfile?.linkedin}
                        disabled={devSocialProfileData?.linkedin}
                        onChange={(e) =>
                          setSocialProfile({
                            ...socialProfile,
                            linkedin: e.target.value,
                          })
                        }
                        onBlur={() =>
                          socialLinkValid("linkedin", socialProfile?.linkedin)
                        }
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group">
                      <label className="floating-label" htmlFor="">
                        Twitter
                      </label>
                      <input
                        type="url"
                        className="form-control"
                        value={socialProfile?.twitter}
                        disabled={devSocialProfileData?.twitter}
                        onChange={(e) =>
                          setSocialProfile({
                            ...socialProfile,
                            twitter: e.target.value,
                          })
                        }
                        onBlur={() =>
                          socialLinkValid("twitter", socialProfile?.twitter)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="floating-label" htmlFor="">
                        Facebook
                      </label>
                      <input
                        type="url"
                        className="form-control"
                        value={socialProfile?.facebook}
                        disabled={devSocialProfileData?.facebook}
                        onChange={(e) =>
                          setSocialProfile({
                            ...socialProfile,
                            facebook: e.target.value,
                          })
                        }
                        onBlur={() =>
                          socialLinkValid("facebook", socialProfile?.facebook)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {selectedField &&
          selectedField !== "devVideo" &&
          selectedField !== "tellYourStory" &&
          selectedField !== "editEmployment" &&
          selectedField !== "certification" &&
          selectedField !== "achievement" &&
          selectedField !== "tool" &&
          selectedField !== "skills" &&
          selectedField !== "newEmployment" && (
            <div className="bottom-button mt30 text-right">
              <a className="submitBtn themeButton" onClick={handleSubmit}>
                {isApprovalUpdating ? (
                  <ClipLoader size={15} color="#FFFFFF" />
                ) : (
                  "Submit"
                )}
              </a>
            </div>
          )}
      </form>
    </Fragment>
  );
};

export default EditProfile;
