import React from 'react'
import PageTour from '../../../components/page-tour/PageTour'

const SocialMediaField = ({register,handleSocialChange,companyProfile,pageTour,setPageTour,skipPageTour,socialMediaLinks}) => {
    return (
        <>
            <h4 className="form-title">social media</h4>
            <PageTour
                open={pageTour == "social"}
                data={companyProfile.socialMedia}
                next={() => setPageTour("")}
                className="team-section"
                skip={skipPageTour}
                nextBtn="Finish"
                total={6}
                id="#social"
            />
            <div className="row" id="social">
                <div className="col-6">
                    <div className="form-group">
                        <label>LinkedIn</label>
                        <input
                            type="text"
                            name="linkedIn"
                            value={socialMediaLinks?.linkedIn}
                            onChange={handleSocialChange}
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label>Facebook</label>
                        <input
                            type="text"
                            onChange={handleSocialChange}
                            name="facebook"
                            value={socialMediaLinks?.facebook}
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="form-group">
                        <label>Twitter</label>
                        <input
                            type="text"
                            onChange={handleSocialChange}
                            value={socialMediaLinks?.twitter}
                            name="twitter"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label>Instagram</label>
                        <input
                            type="text"
                            value={socialMediaLinks?.instagram}
                            onChange={handleSocialChange}
                            name="instagram"
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="form-group">
                        <label>Youtube</label>
                        <input
                            type="text"
                            value={socialMediaLinks?.youtube}
                            onChange={handleSocialChange}
                            name="youtube"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label>
                            Glassdoor link
                        </label>
                        <input
                            type="text"
                            
                            {...register("employeeRating")}
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SocialMediaField