import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { loginOG } from "../imageLinks";
import NoFollow from "../../../common/staticData/seo/NoFollow";

const Login = ({ baseUrl }) => {
  return (
    <Helmet>
      <title>Login and apply for software jobs from the listed vacancies</title>
      <meta
        name="title"
        content="Login and apply for software jobs from the listed vacancies"
      />
      <meta charset="UTF-8" />
      <meta
        name="description"
        content="Login to Olibr job portal and apply for long-term software job opportunities at top US companies from the listed job vacancies. Login today!"
      ></meta>
      <link rel="canonical" href={`${baseUrl}/login`} />
      <meta
        name="keywords"
        content="Login Page, olibr.com, tech jobs, olibr, olibr.com, Hiring developers, remote software developers, work-from-home developers, wfh, remote jobs, IT jobs, US based IT companies, US Jobs, US IT Jobs"
      />
         <meta name="robots" content={NoFollow()}></meta>
      <meta name="language" content="english"></meta>
      <html lang="en" />
      {/* <meta name="url" content={`${baseUrl}/login`} /> */}
      {/* <meta name="identifier-URL" content={`${baseUrl}/login`}></meta> */}
      <meta name="referrer" content="origin-when-crossorigin"></meta>
      <link rel="image_src" href={loginOG} />
      <meta name="copyright" content="olibr.com" />
      <meta
        property="og:title"
        content="Login and apply for software jobs from the listed vacancies"
      />
      <meta
        property="og:description"
        content="Login to Olibr job portal and apply for long-term software job opportunities at top US companies from the listed job vacancies. Login today!"
      />
      <meta property="og:url" content={`${baseUrl}/login`} />
      <meta property="og:image" content={loginOG} />
      <meta property="og:site_name" content="Olibr" />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@olibr" />
      <meta property="twitter:domain" content="olibr.com" />
      <meta property="twitter:url" content={`${baseUrl}/login`} />
      <meta
        name="twitter:title"
        content="Login and apply for software jobs from the listed vacancies"
      />
      <meta
        name="twitter:description"
        content="Login to Olibr job portal and apply for long-term software job opportunities at top US companies from the listed job vacancies. Login today!"
      />
      <meta name="twitter:image" content={loginOG} />

      <meta property="og:type" content="business.business" />
      <meta
        property="business:contact_data:street_address"
        content="26 Arrowhead Ln"
      />
      <meta property="business:contact_data:locality" content="Menlo Park" />
      <meta property="business:contact_data:region" content="CA" />
      <meta property="business:contact_data:postal_code" content="94025" />
      <meta property="business:contact_data:country_name" content="US" />
      {/* ------Apple Devices ------ */}
      <meta
        name="apple-mobile-web-app-title"
        content="Login and apply for software jobs from the listed vacancies"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-touch-fullscreen" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black"></meta>
      {/* ----------JSON LD Schema for SEO------ */}
      <script type="application/ld+json">
        {/* {JSON.stringify(
          // breadcrumbs---
          {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: `${baseUrl}/`,
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Login to Olibr",
                item: `${baseUrl}/login`,
              },
            ],
          }
        )} */}
      </script>
      {/* ----------------------------- */}
      <script type="application/ld+json">
        {/* {JSON.stringify(
          // speakable--
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            name: "Login & apply for the best remote jobs in top US companies",
            speakable: {
              "@type": "SpeakableSpecification",
              xpath: [
                "/html/head/title",
                "/html/head/meta[@name='description']/@content",
              ],
            },
            url: `${baseUrl}/login`,
          }
        )} */}
      </script>
      {/* ------------------------------ */}
      {/* <script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://www.schema.org",

            "@type": "WebSite",

            name: "Login & apply for the best remote jobs in top US companies",

            url: `${baseUrl}/login`,
          }
        )}
      </script> */}
    </Helmet>
  );
};

export default Login;
