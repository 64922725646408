import React, { useEffect, useState } from 'react'
import ClipLoaderComp from '../clipLoader/ClipLoader'
const DevQuestionForm = ({ isLoading, data, applyJobWithQuestion, closeQuesModal }) => {
    const [question, setQuestion] = useState([])
    const [errors, setErrors] = useState([])
    useEffect(() => {

        let tempArr = []
        data?.map((item) => {
            item?.questionLists?.map((itm) => {
                if (itm?.isAdded) {
                    tempArr?.push({ ...itm, answer: "" })
                }
            })
        })
        setQuestion(tempArr)
    }, [data])

    const handleChanges = (e, index) => {
        let tempArr = [...question]
        tempArr[index].answer = e.target.value;
        setQuestion(tempArr)
    }

    const handleSubmit = () => {
        let tempArr = [...question]
        let tempError = []
        tempArr?.map((item, index) => {
            if (item?.isMandatory && !item?.answer) {
                tempError.push(index)
            } else {
                tempError = tempError.filter((itm) => itm !== index)
            }
        })
        if (tempError.length) {
            setErrors(tempError)
            return false;
        }
        applyJobWithQuestion(tempArr)
    }
    return (
        <div className='questionaries-area'>
            <div className='row header-section'>
                <div className='col-12 text-center'>
                    <p className='header-title'>Answer the Following questions</p>
                </div>

            </div>
            <div className='box-items question-list-dev'>
                {
                    question?.map((itm, index) => itm?.isAdded && (
                        <>
                            <p className='list'>{itm?.isMandatory && <span className='starText'>*</span>}{itm?.question} </p>
                            <input type="text" className={`input ${errors?.includes(index) && "border-red"}`} onChange={(e) => handleChanges(e, index)} value={question[index].answer} />
                        </>
                    ))
                }


            </div>

            <div className='row'>
                <div className='col-12 text-right '>
                    <button className='cancelBtn' onClick={closeQuesModal}>Cancel</button>
                    <button className='submitBtn' onClick={handleSubmit}>
                        {isLoading ? <ClipLoaderComp size={15} color="#FFFFFF" /> : "Submit"}
                    </button>
                </div>
            </div>

        </div>
    )
}

export default DevQuestionForm