import DateFormate from '../../../common/DateFormate';
import { DateSortingDataTable } from '../../../components/functions';
const ColumnLists = () => {
  const columns = [
    {
        name: "S.No",
        selector: "sNo",
        width: "97px",
        cell:(row,index)=>index+1
    }, {
        name: "Name",
        selector: "fName",
        sortable: true,
        width: "150px",
        cell:(row)=>row?.fName?.concat(` ${row?.lName}`)
    }, {
        name: "Email",
        selector: "email",
        sortable: true,
        width: "120px",

    }, {
        name: "Company name",
        selector: "clientCompanyName",
        sortable: true,
        width: "150px",
    },
    {
        name: "Status",
        selector: "status",
        sortable: true,
        width: "150px",
        cell:(row)=>row?.isBlocked ? "Blocked": row?.status
    },
    {
        name: "Registration date",
        selector: "createdAt",
        width: "120px",
        sortable: true,
        cell:(row)=>DateFormate(row?.createdAt),
        sortFunction:(a,b)=> DateSortingDataTable(a,b,"createdAt"),
    },
    {
        name: "Last Login",
        selector: "lastLogin",
        width: "130px",
        sortable: true,
        cell:(row)=>DateFormate(row?.lastLogin),
        sortFunction:(a,b)=> DateSortingDataTable(a,b,"lastLogin"),
    },
    {
        name: "Jobs Posted",
        sortable: true,
        width: "110px",
        cell:(row)=>row?.jobPostedCount ? row?.jobPostedCount:"0"
    },
    {
        name: "Shortlisted",
        selector: "shortlistedCount",
        sortable: true,
        width: "120px",
        cell:(row)=>row?.shortlistedCount ? row?.shortlistedCount:"0"
    },
    {
        name: "Hired",
        selector: "hiredDev",
        sortable: true,
        width: "110px",
        cell:(row)=>row?.hiredCount ? row?.hiredCount:"0"
     
    },
];
return columns;
}

export default ColumnLists