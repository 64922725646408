// import DarkBlue from "./../../assets/img/company-profile/dark-blue-banner.jpg";
// import BlueBanner from "./../../assets/img/company-profile/blue-banner.jpg";
// import SkyBanner from "./../../assets/img/company-profile/sky-banner.jpg";
const Banner = ({ data }) => {
  const template = data?.coverTemplate;
  return (
    <data className="banner-section">
      <div className="company-banner">
        {/* <img src={ template == "dark-blue" ? DarkBlue : template == "blue" ? BlueBanner : SkyBanner } alt="Company Banner" /> */}
        <img src={ template == "dark-blue" ? "https://ik.imagekit.io/olibr/assets/tr:w-1108,h:312/dark-blue-banner.jpg" : template == "blue" ? "https://ik.imagekit.io/olibr/assets/tr:w-1108,h:312/blue-banner.jpg" : "https://ik.imagekit.io/olibr/assets/tr:w-1108,h-312/sky-banner.jpg" } alt="Company Banner" />
        <h2>{data?.companyTitle}</h2>
      </div>
    </data>
  );
};

export default Banner;
