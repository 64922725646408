import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import FresherTag from "../../assets/img/fresher-tag.png";
// import BadgeIcon from "../../assets/img/icons/badge-icon.svg";
import UserImage from "../../assets/img/user-image.jpg";
import { ReactComponent as ArrowRight } from "../../assets/img/icons/arrow-right.svg";
import { Icon } from "../icon";
// import ProjectIcon from "../../assets/img/project-icon.svg";
import EditProfile from "../../view/developer/profile/EditProfile";
import {
  getProfileStatus,
  getResume,
  getResumeOpenAction,
  skillList,
  stackList,
} from "../../service/action/developer";
import ProfileUpdateRequest from "../../view/developer/profile/ProfileUpdateRequest";
import {
  convertImageAction,
  getApprovalUpdateAction,
} from "../../service/action/common";
import { MainContext } from "../../context/MainContext";
import { monthArr } from "../../common/constant/monthArr";
import Modal from "../modal/ModalBox";
import "./profilePreview.css";
import "react-image-lightbox/style.css";
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import Button from "../common/Button";
import CopyLink from "../../assets/img/icons/copy-link.svg";
import { olibrToast } from "../toaster";
import MetaTagSEO from "../seo/MetaTagSEO";
import ReviewCard from "../review-card/ReviewCard";
import {
  checkExistPlarismObj,
  checkLocationExist,
  countryFlagCode,
  formDateDateYear,
  helightSearch,
  relocateFilter,
} from "../functions";
import SkillTestResult from "./SkillTestResult";
import { PDFProfilePreview } from "./custom-pdf/PDFProfilePreview";
import { PDFDownloadLink } from "@react-pdf/renderer";
const ProfilePreviewCommon = ({
  openUserId,
  userId,
  onClose,
  profileStatus,
  searchedData = {},
  jobId=false
}) => {
  const {user}=useContext(MainContext)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [restricted,setRestricted]=useState(true)
  const {
    user: { userType, email },
  } = useContext(MainContext);
  const [shareOpen, setShareOpen] = useState(false);
  const [heighLightData, setDevData] = useState([]);
  const { skillResp, stackResp, resumeData, isResumeLoading } = useSelector(
    (state) => state.developerReducer
  );

  useEffect(() => {
    if (Object.keys(resumeData)?.length) {
      if (searchedData?.length) {
        helightSearch(resumeData, searchedData, setDevData);
      } else {
        setDevData(resumeData);
      }
    }
  }, [resumeData,searchedData]);

  const [url, setUrl] = useState("");
  useEffect(() => {
    let base_url = "http://localhost:3000";
    if (window?.location?.href?.includes("https://dev")) {
      base_url = "https://dev.olibr.com";
    } else if (window?.location?.href?.includes("https://test")) {
      base_url = "https://test.olibr.com";
    } else if (window?.location?.href?.includes("https://olibr")) {
      base_url = "https://olibr.com";
    } else if (window?.location?.href?.includes("https://improved")) {
      base_url = "https://improved.olibr.com";
    } else if (window?.location?.href?.includes("https://www")) {
      base_url = "https://www.olibr.com";
    } else {
      base_url = "http://localhost:3000";
    }
    setUrl(base_url);
  }, [shareOpen]);

  const { dataForApproval } = useSelector((state) => state.commonReducer);
  const { devId } = useParams();

  const getDevProfile=()=>{
    dispatch(getResume(userId || devId, navigate,jobId));
  }

  useEffect(() => {
    let windowUrl = window.location.pathname;
    if (userType && windowUrl !== "/") {
      getDevProfile()
    }
    dispatch(stackList(navigate));
    dispatch(skillList(navigate));
  }, []);

  useEffect(() => {
    if (
      userType === "Developer" &&
      window.location.href.includes("/developer")
    ) {
      dispatch(getProfileStatus(navigate));
      !heighLightData?.fName && getDevProfile();
      dispatch(getApprovalUpdateAction(navigate, email));
    }
  }, [userType]);

  useEffect(() => {
    if (userId == null && openUserId) {
      dispatch(getResumeOpenAction(openUserId, navigate));
    }
  }, [openUserId]);

  useEffect(() => {
    if (!openUserId && !userType && (userId || devId)) {
      getDevProfile()
    }
  }, [userId, devId]);

  const [fullStory, setFullStory] = useState(false);

  const [project, setProject] = useState({});
  const [projectModal, setProjectModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openRequestModal, setOpenRequestModal] = useState(false);

  const openProjectModal = (proj) => {
    setProject(proj);
    setProjectModal(true);
  };
  const closeProjectModal = () => {
    setProject({});
    setProjectModal(false);
    setTitle("");
  };

  const handleEditModalState = () => {
    setOpenEditModal(!openEditModal);
  };

  const handleRequestModalState = () => {
    setOpenRequestModal(!openRequestModal);
  };

  const [title, setTitle] = useState("");

  const [LightBoxObj, setLightBox] = useState({
    indexImg: 0,
    isOpen: false,
    imageArr: [],
  });

  const [customLightBox, setCustomLightBox] = useState(false);
  const closeCustomLightBox = () => {
    setCustomLightBox(false);
  };
  const removeRestriction=()=>{
    olibrToast.error("Please upgrade your plan")
    setRestricted(false)
  }

  useEffect(()=>{
   if(user?.isSubscriptionExpired){
    setRestricted(true)
   }else{
    setRestricted(false)
   }
  },[user])
  const customLightContent = () => {
    return (
      <Fragment>
        <div>
          <section class="carousel" aria-label="carousel" Tabindex="0">
            <a class="carousel__skip-link" href="#skip-link">
              Carousel überspringen
            </a>
            <div class="slides">
              {project?.image?.map((item) => (
                <div
                  class="slides-item slide-1"
                  id={item}
                  aria-label="slide 1 of 5"
                  tabindex="0"
                >
                  <img src={item} alt="" />
                </div>
              ))}
            </div>
            <div class="carousel__nav">
              {project?.image?.map((item, index) => (
                <a
                  class="slider-nav"
                  href={`#${item}`}
                  aria-label="Go to slide 5"
                >
                  {index + 1}
                </a>
              ))}
            </div>
          </section>
        </div>
      </Fragment>
    );
  };

  const handleProjectContent = () => {
    return (
      <>
        <div className="project-popup">
          <div className="project-title-section">
            <div className="row">
              <div className="col-7">
                <h2 dangerouslySetInnerHTML={{ __html: project?.name }}></h2>
              </div>
              <div className="col-5">
                {/* <img
                  src={ProjectIcon}
                  alt="Developer Project image showcase"
                  className="pro-pad"
                /> */}
                <img
                  src={
                    "https://ik.imagekit.io/olibr/assets/tr:w-207,h-150/project-icon.svg"
                  }
                  alt="Developer Project image showcase"
                  className="pro-pad"
                />
              </div>
            </div>
            <div className="project-primary-info">
              <div className="row">
                <div className="col-4">
                  <h6>Role</h6>
                  <p dangerouslySetInnerHTML={{ __html: project?.url }}></p>
                </div>
                {project?.projectIndus && (
                  <div className="col-4">
                    <h6>Project Industry</h6>
                    <p>{project?.projectIndus}</p>
                  </div>
                )}
                {project?.Backend && (
                  <div className="col-4">
                    <h6>Backend</h6>
                    <p>{project?.Backend}</p>
                  </div>
                )}
                {project?.numOfProf && (
                  <div className="col-4">
                    <h6>Number Of Professionals</h6>
                    <p>{project?.numOfProf}</p>
                  </div>
                )}
                {project?.delTime && (
                  <div className="col-4">
                    <h6>Delivery Time</h6>
                    <p>{project?.delTime}</p>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-12">
                  <h6>Description</h6>
                  <div
                    className="proj-desc"
                    dangerouslySetInnerHTML={{ __html: project?.description }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <ul className="tags tags-fill">
            {project?.skill?.map((item) => (
              <li key={Math.random()}>
                <a style={{ display: "flex" }}>{item}</a>
              </li>
            ))}
          </ul>
          <Modal
            size="100%"
            open={customLightBox}
            title={"Project Images"}
            onClose={closeCustomLightBox}
            content={() => customLightContent()}
            isFooterView={false}
          />
          {project?.image?.map((imgUrl) => (
            <div key={Math.random()} className="portfolio-images">
              <div
                className="portfolio-img"
                style={{ marginTop: "15px", display: "flex" }}
              >
                <figure>
                  <img
                    onClick={() => {
                      setCustomLightBox(true);
                    }}
                    src={imgUrl }
                    alt="Project Image"
                  />
                </figure>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const returnDate = (date) => {
    if (date == "<mark>Available</mark>") {
      return date;
    }
    const x = new Date(date).getTime();
    if (x <= Date.now()) {
      return "Available";
    } else {
      return ` 
          ${date?.split("T")?.[0].split("-")[2]}
          ${monthArr[parseInt(date?.split("T")?.[0].split("-")[1]) - 1]}
          ${date.split("T")?.[0].split("-")[0]}
          `;
    }
  };

  const shareContent = () => {
    let sendurl =
      url +
      "/resume/" +
      heighLightData?._id +
      "-" +
      heighLightData?.fName +
      "-" +
      heighLightData?.lName;

    const copyLink = async () => {
      await navigator.clipboard.writeText(sendurl);
      olibrToast.success("Link Copied!");
    };
    return (
      <Fragment>
        <div className="social-share-buttons">
          <span>
            <WhatsappShareButton
              style={{ height: "64px", width: "64px", position: "absolute" }}
              url={sendurl}
            />
            <WhatsappIcon size={64} round={true} />
          </span>
          <span>
            <FacebookShareButton
              style={{ height: "64px", width: "64px", position: "absolute" }}
              url={sendurl}
            />
            <FacebookIcon size={64} round={true} />
          </span>
          {/* <span>
            <TwitterShareButton
              style={{ height: "64px", width: "64px", position: "absolute" }}
              url={sendurl}
            />
            <TwitterIcon size={64} round={true} />
          </span> */}
          {/* <span>
            <TelegramShareButton
              style={{ height: "64px", width: "64px", position: "absolute" }}
              url={sendurl}
            />
            <TelegramIcon size={64} round={true} />
          </span> */}
          <span>
            <LinkedinShareButton
              style={{ height: "64px", width: "64px", position: "absolute" }}
              url={sendurl}
            />
            <LinkedinIcon size={64} round={true} />
          </span>
          <span>
            <EmailShareButton
              style={{ height: "64px", width: "64px", position: "absolute" }}
              url={sendurl}
            />
            <EmailIcon size={64} round={true} />
          </span>

          <span className="copy-link-btn" onClick={copyLink}>
            <img src={CopyLink} />
            Copy Link
          </span>
        </div>
      </Fragment>
    );
  };
  const [resultModal, setResultModal] = useState(false);
  const [titleRes, setTitleRes] = useState(false);
  const [resultCard, setResultCard] = useState({ skill: {} });
  const resultModalClose = () => {
    setResultModal(false);
    setResultCard({ ...resultCard, skill: {} });
    setTitleRes("");
  };
  const handleContent = (skill) => {
    setTitle(skill?.testName);
    return (
      <>
        <div id="skill-result">
          <div className="olibr-brand">
            <Icon name="logo-olibr" />
          </div>
          <div className="row">
            <div className="skill-graph col-3">
              <div className="skill-graph">
                <div className="single-chart">
                  <svg
                    viewBox="0 0 36 36"
                    className={`circular-chart ${"green"}`}
                  >
                    <path
                      className="circle-bg"
                      d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                      className="circle"
                      stroke-dasharray={`${parseInt(
                        skill?.percentageScore
                      )}, 100`}
                      d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <text x="18" y="20.35" className="percentage">
                      {parseInt(skill?.obtainedScore)}/
                      {parseInt(skill?.maxScore)}
                    </text>
                  </svg>
                </div>
              </div>
            </div>

            <div
              style={{
                color: "#3ba0aa",
              }}
              className="col-8 pass"
            >
              Congratulations! You nailed it!
            </div>
            <br />
          </div>
          <div className="skill-test-outer">
            <>
              <div className="row-result">
                <span className="test-left-col summary">Summary</span>
                <span className="test-right-col"></span>
              </div>
              <div className="row-result upper-line">
                <span className="test-left-col">Test Score</span>
                <span className="test-right-col">
                  {parseInt(skill?.maxScore)}
                </span>
              </div>
              <div className="row-result upper-line">
                <span className="test-left-col">Your Score</span>
                <span className="test-right-col">
                  {parseInt(skill?.obtainedScore)}
                </span>
              </div>
              <div className="row-result upper-line">
                <span className="test-left-col">Date</span>
                <span className="test-right-col">
                  {skill?.attemptEndedAt
                    ? new Date(skill?.attemptEndedAt)
                        .toDateString()
                        .substring(4)
                    : new Date(skill?.createdAt).toDateString().substring(4)}
                </span>
              </div>
              <div className="row-result upper-line">
                <span className="test-left-col">CutOff Score</span>
                <span className="test-right-col">{skill?.cutOffScore}</span>
              </div>
              <div className="row-result upper-line">
                <span className="test-left-col">Total</span>
                <span
                  className="test-right-col"
                  style={{
                    color: skill?.isPassed ? "#679B31" : "#F15C3B",
                  }}
                >
                  {parseInt(skill?.percentageScore)} %
                </span>
              </div>
            </>
          </div>
          <br />
        </div>
      </>
    );
  };
  const [frameOpen, setFrameOpen] = useState(false);

  const handleFrameContent = () => {
    return (
      <Fragment>
        {frameOpen && (
          <iframe
            src={!!heighLightData?.devVideo ? heighLightData?.devVideo : ""}
            width="100%"
            height="500"
          ></iframe>
        )}
      </Fragment>
    );
  };
  const closeFrameModal = () => {
    setFrameOpen(false);
  };

  const openFrameLogic = () => {
    if (!!heighLightData?.devVideo) {
      setFrameOpen(true);
    } else {
      olibrToast.info("No Preview Available!");
    }
  };

  const [download, setDownload] = useState(false);

  //TEMP CODE

  const { base64Image } = useSelector((state) => state.commonReducer);
  // console.log("base 64 image ---", base64Image)
  const [userImage, setUserImage] = useState();
  useEffect(() => {
    if (resumeData?.userImage) {
      dispatch(
        convertImageAction(navigate, { imageUrl: resumeData?.userImage })
      );
    } else {
      setUserImage(UserImage);
    }
  }, [resumeData]);

  useEffect(() => {
    if (base64Image) {
      const blob = new Blob([base64Image], { type: "image/png" });
      let data = URL.createObjectURL(blob);
      setUserImage(base64Image);
    } else {
      setUserImage(null);
    }
  }, [base64Image]);

  return (
    <>
      {window?.location?.href?.includes("/resume") && (
        <MetaTagSEO
          helmetName={"candidate-profile"}
          devdetails={heighLightData}
        />
      )}
      <Modal
        size="100%"
        open={frameOpen}
        onClose={closeFrameModal}
        content={handleFrameContent}
        isFooterView={false}
        title={"Introductory Video"}
      />
      <Modal
        open={resultModal}
        title={titleRes}
        isFooterView={false}
        onClose={() => resultModalClose()}
        content={() => handleContent(resultCard?.skill)}
      />
      <Modal
        open={projectModal}
        title={title}
        onClose={closeProjectModal}
        content={handleProjectContent}
        isFooterView={false}
        onCancel={closeProjectModal}
      />
      <Modal
        open={openEditModal}
        title={"Edit Profile"}
        onClose={handleEditModalState}
        content={() => <EditProfile />}
        isFooterView={false}
      />
      <Modal
        open={openRequestModal}
        title={"Profile Update Request"}
        onClose={handleRequestModalState}
        content={() => <ProfileUpdateRequest />}
        isFooterView={false}
      />
      <Modal
        open={shareOpen}
        title={"Share Profile"}
        onClose={() => setShareOpen(false)}
        content={shareContent}
        isFooterView={false}
      />

      {/* {
        userImage 
        && stackResp?.length && skillResp?.length
         && (
          <PdfConverter
            heighLightData={heighLightData}
            userImage={userImage}
            stackLists={stackResp}
            skillList={skillResp}
            setLoader={setDownload}
          />
        )
      } */}
      {isResumeLoading ? (
        <div className="loader-container text-center">
          <ClipLoader color="#3BA0AA" />
        </div>
      ) : (
        <div className="new-profile-wrap">
        {restricted &&  <button className="btn restriction-btn" onClick={removeRestriction}>View</button>}
          <div className={`profile-top ${restricted && "blur-6px"}`}>
            <div
              className={`new-profile-img ${
                userType === "Client" && "new-profile-img-after"
              }`}
            >
              {isLoading ? (
                <div className="img-loader">
                  <Icon name="clip-loader" />
                </div>
              ) : (
                <>
                  {heighLightData?.isFresher && (
                    <img src={FresherTag} alt="fresher" className="fresher" />
                  )}
                  <img
                    src={
                      resumeData?.userImage ? resumeData?.userImage
                        : UserImage
                    }
                    height={272}
                    width={272}
                    className="profile"
                    alt="Profile"
                  />
                </>
              )}
              {userType === "Client" && (
                <p className="text-center">
                  <strong>Last Active: </strong>{" "}
                  {new Date(
                    heighLightData?.lastLogin || heighLightData?.createdAt
                  ).toLocaleDateString("en-US", {
                    month: "long",
                    year: "numeric",
                    day: "numeric",
                  })}
                </p>
              )}
              {heighLightData?.devVideo &&
                heighLightData?.devVideo !==
                  "https://ik.imagekit.io/olibr/olibr-s3/" && (
                  <p
                    className="share-video-icon"
                    title="Show Video"
                    onClick={openFrameLogic}
                  >
                    <Icon name="video-icon" />
                  </p>
                )}
            </div>
            <div className="new-profile-details pad0">
              <h5 className="user-name">
                {heighLightData?.fName} {userType ? heighLightData?.lName : ""}{" "}
                {/* active */}
                {!user?.isSubscriptionExpired && <span title="Share Profile" onClick={() => setShareOpen(true)}>
                  <Icon name="share-profile" />
                </span>}
                {/* {Object.keys(resumeData)?.length && !user?.isSubscriptionExpired &&
                userImage &&
                stackResp?.length &&
                skillResp?.length ? (
                  <PDFDownloadLink
                    onClick={() => setDownload(true)}
                    fileName={`olibr-${
                      Math.floor(Math.random() * 9000000) + 1000000
                    }`}
                    document={
                      <PDFProfilePreview
                        resumeData={resumeData}
                        userImage={userImage}
                        stackLists={stackResp}
                        skillList={skillResp}
                        setLoader={setDownload}
                      />
                    }
                  >
                    <span
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      title="Download Profile"
                    >
                      {download ? (
                        <ClipLoader color="#3BA0AA" size="15px" />
                      ) : (
                        <Icon name="download-profile" />
                      )}
                    </span>
                  </PDFDownloadLink>
                ) : (
                  ""
                )} */}
                <span className="user-status">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    {profileStatus && (
                      <span>
                        <strong>Status:</strong>{" "}
                        <span
                          className={`color-${
                            resumeData?.status !== "approved"
                              ? "orange"
                              : "green"
                          } txt-trans`}
                        >
                          {resumeData?.status}
                        </span>
                      </span>
                    )}

                    <div className="update-req">
                      {resumeData?.status === "approved" &&
                        userType === "Developer" && (
                          <span
                            data-tooltip="Edit"
                            id="profile-edit"
                            onClick={handleEditModalState}
                          >
                            <Icon name="edit" />
                          </span>
                        )}
                      {resumeData?.status === "approved" &&
                        dataForApproval?.length > 0 && (
                          <span
                            className="update-req-span"
                            data-tooltip="Update Request"
                            onClick={handleRequestModalState}
                          >
                            <Icon name="update-request" />
                          </span>
                        )}
                    </div>
                  </div>
                </span>
              </h5>
              <p
                className="user-designation"
                dangerouslySetInnerHTML={{
                  __html: heighLightData?.devDesg,
                }}
              ></p>
              {(heighLightData?.devState || heighLightData?.devCity) && (
                <div className="user-c">
                  <div className="row">
                    <div
                      className={`col-${
                        userType == "Client" ||
                        resumeData?.status === "approved"
                          ? 5
                          : 12
                      }`}
                    >
                      <p className="user-location">
                        <span>
                          {/* <img src={LocationIcon} height="16px" /> */}
                          <img
                            src={countryFlagCode(resumeData?.devCountryCode)}
                            height="16"
                          />
                        </span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ` ${heighLightData?.devCity}, ${heighLightData?.devState}`,
                          }}
                        ></span>
                      </p>
                    </div>

                    {(userType === "Client" ||
                      resumeData?.status === "approved") && (
                      <div className="col-7">
                        <p className="user-location">
                          <span>
                            {/* <img src={ShortEmailIcon} height="13px" /> */}
                            <img
                              src="https://ik.imagekit.io/olibr/icons/tr:w-17,h-13/short-email.svg"
                              height="13"
                            />
                          </span>
                          {resumeData?.email }
                        </p>
                      </div>
                    )}
                    {(userType === "Client" ||
                      resumeData?.status === "approved") && (
                      <div className="col-5">
                        <p className="user-location">
                          <span>
                            {/* <img src={CtcIcon} height="16px" /> */}
                            {
                              resumeData?.devCountryCode!="91"?"$"
                              : <img
                              src="https://ik.imagekit.io/olibr/icons/tr:w-15,h-16/ctc-icon.svg"
                              height="16"
                            />
                            }
                         
                          </span>
                          {heighLightData?.devCSalar} PA
                        </p>
                      </div>
                    )}
                    {(userType === "Client" ||
                      resumeData?.status === "approved") && (
                      <div className="col-7">
                        <p className="user-location">
                          <span>
                            {/* <img src={ShortCallIcon} height="13px" /> */}
                            <img
                              src="https://ik.imagekit.io/olibr/icons/tr:w-13,h-13/short-call.svg"
                              height="13"
                            />
                          </span>
                          {`+${resumeData?.devCountryCode}`}{" "}
                          {resumeData?.number }
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="candidate-avaiable">
                {heighLightData?.devAvlFrom ? (
                  <>
                    {!returnDate(heighLightData?.devAvlFrom)?.includes(
                      "Available"
                    ) && <strong>Available From :</strong>}{" "}
                    <span
                      style={{
                        color: returnDate(heighLightData?.devAvlFrom)?.includes(
                          "Available"
                        )
                          ? "#679B31"
                          : "",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: returnDate(heighLightData?.devAvlFrom),
                      }}
                    ></span>
                  </>
                ) : (
                  <>
                    {heighLightData?.devNoticePeriod !== "Available" && (
                      <strong>Notice Period :</strong>
                    )}{" "}
                    <span
                      style={{
                        color:
                          heighLightData?.devNoticePeriod === "Available"
                            ? "#679B31"
                            : "",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: heighLightData?.devNoticePeriod,
                      }}
                    ></span>
                  </>
                )}
              </div>
              <br />
              {userType === "Client" &&
                checkLocationExist(heighLightData?.relocate) && (
                  <div className="candidate-avaiable">
                    <strong>Open to Relocation </strong> <br />
                    <p className="user-location">
                      <span>
                        <img
                          src="https://ik.imagekit.io/olibr/icons/tr:w-10,h-14/location.svg"
                          height="16"
                        />
                      </span>
                      {relocateFilter(heighLightData?.relocate)}
                    </p>
                  </div>
                )}

              <p className="tellurstory">
                {fullStory ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: heighLightData?.devTellUrStory,
                    }}
                  />
                ) : (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: heighLightData?.devTellUrStory?.substring(0, 400),
                    }}
                  ></span>
                )}{" "}
                {heighLightData?.devTellUrStory?.length >= 400 ? (
                  <a
                    className="read-more"
                    onClick={() => setFullStory(!fullStory)}
                  >
                    ....
                    {<span
                      dangerouslySetInnerHTML={{ __html: fullStory }}
                    ></span> ? (
                      "less"
                    ) : (
                      "more"
                    )}
                  </a>
                ) : (
                  ""
                )}
              </p>
            </div>
          </div>

          <ul className="icon-tags">
            {!!heighLightData?.devTechTestResult?.length &&
              heighLightData?.devTechTestResult?.map((skill) =>
                [...skillResp, ...stackResp]
                  ?.filter(
                    (items) =>
                      skill.testId == items.aId ||
                      skill.testId == items.assessmentId
                  )
                  ?.map(
                    (item) =>
                      checkExistPlarismObj(skill) && (
                        <li key={Math.random()}>
                          <a>
                            <img
                              src={
                                item?.skillTestIcon
                                  ? item?.skillTestIcon
                                  : item?.stackIcon
                              }
                              alt=""
                            />{" "}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: skill?.testName
                                  ? skill?.testName
                                  : skill?.testName,
                              }}
                            ></span>
                          </a>
                        </li>
                      )
                  )
              )}
          </ul>
          {userType !== "Developer" && (
            <div className="row">
              <div className="col-12">
                <div className="hirenow-bg">
                  {userType !== "Client" && (
                    <>
                      <h4>{heighLightData?.fName} is available for hire</h4>
                      <Button
                        className="themeButton"
                        onClick={() =>
                          userType === "Client"
                            ? navigate(
                                `/client/shortlisted-profile/${heighLightData?._id}`
                              )
                            : navigate("/sign-up-client")
                        }
                        label={"Hire Now"}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="container pad0" id="profile-preview">
            <div className="row">
              {heighLightData?.devTechTestResult?.length != 0 && (
                <>
                  <div className="col-3 skill-result-heading">
                    <h4>
                      <i className="h-icon">
                        {/* <img src={VettedSkillIcon} alt="Vetted Skill" /> */}
                        <img
                          src="https://ik.imagekit.io/olibr/icons/tr:w-24,h-32/vetted-skill-icon.svg"
                          alt="Vetted Skill"
                        />
                      </i>
                      Vetted Skills
                    </h4>
                  </div>
                  <div className="col-9 skill-result-detail">
                    {!!skillResp?.length && !!stackResp?.length && (
                      <SkillTestResult
                        data={heighLightData?.devTechTestResult}
                        skillList={skillResp}
                        stackLists={stackResp}
                        userType={userType}
                      />
                    )}
                  </div>
                </>
              )}

              {heighLightData?.devEmployment?.length > 0 && (
                <>
                  <div className="col-3">
                    <h4>
                      <i className="h-icon">
                        {/* <img src={EmploymentIcon} alt="Employment" /> */}
                        <img
                          src="https://ik.imagekit.io/olibr/icons/tr:w-24,h-19/employment-icon.svg"
                          alt="Employment"
                        />
                      </i>
                      Employment
                    </h4>
                  </div>
                  <div className="col-9">
                    <div className="timeline">
                      {heighLightData?.devEmployment?.map(
                        (empInfo, index) =>
                          empInfo?.companyName &&
                          empInfo?.designation && (
                            <div key={index} className="timeline-event">
                              <span className="timeline-dot"></span>
                              <h4 className="timeline-title">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: empInfo?.designation,
                                  }}
                                ></span>
                              </h4>
                              <div className="row">
                                <div className="col-6">
                                  <span className="timeline-subtitle">
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: empInfo?.companyName,
                                      }}
                                    ></span>
                                  </span>
                                </div>
                                <div className="col-6 text-right">
                                  <span className="timeline-subtitle text-right">
                                    {formDateDateYear(empInfo?.from)}
                                    {" - "}
                                    {empInfo?.stillWorking
                                      ? "PRESENT"
                                      : formDateDateYear(empInfo?.to)}
                                  </span>
                                </div>
                                <br />
                                <br />
                              </div>
                              {empInfo?.techUsed?.length > 0 && (
                                <div className="row mb-10">
                                  <div className="col-2">Technology</div>
                                  <div className="col-10">
                                    <ul className="icon-tags-finalize">
                                      {empInfo?.techUsed?.map((item) => (
                                        <li key={Math.random()}>
                                          <a>
                                            <img src={item?.value} alt="" />{" "}
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: item?.label,
                                              }}
                                            ></span>
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              )}
                              {empInfo?.toolUsed?.length > 0 && (
                                <div className="row mb-10">
                                  <div className="col-2">Tools</div>
                                  <div className="col-10">
                                    <ul className="icon-tags-finalize">
                                      {empInfo?.toolUsed?.map((item, index) => (
                                        <li key={index}>
                                          <a>
                                            <img src={item?.value} alt="" />{" "}
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: item?.label,
                                              }}
                                            ></span>
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              )}
                              <div
                                className="fullHeight-perm"
                                dangerouslySetInnerHTML={{
                                  __html: empInfo?.aboutRole,
                                }}
                              ></div>
                              {heighLightData?.devProjectDetails?.filter(
                                (proj) => proj.empId == empInfo._id
                              )?.length !== 0 && (
                                <>
                                  <h5 className="sub-sub-title"></h5>
                                  <div className="project-blocks">
                                    {heighLightData?.devProjectDetails?.map(
                                      (project, index) =>
                                        project?.empId === empInfo._id && (
                                          <div
                                            key={index}
                                            className="project-block"
                                            style={{
                                              flex: "1 0 33.333%",
                                              maxWidth: "calc(33.333% - 10px)",
                                            }}
                                          >
                                            <h5
                                              dangerouslySetInnerHTML={{
                                                __html: project?.name,
                                              }}
                                            ></h5>
                                            <span
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                              dangerouslySetInnerHTML={{
                                                __html: project?.url,
                                              }}
                                            ></span>
                                            <div
                                              className="project-desc-fixed"
                                              dangerouslySetInnerHTML={{
                                                __html: project?.description,
                                              }}
                                            ></div>
                                            <ul className="icon-tags-finalize">
                                              {project?.skills?.map(
                                                (devSkill, index) => (
                                                  <li key={index}>
                                                    <a>{devSkill}</a>
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                            <div className="project-block-footer">
                                              <a
                                                className="link"
                                                onClick={() => {
                                                  openProjectModal(project);
                                                  setLightBox({
                                                    ...LightBoxObj,
                                                    imageArr: project?.image,
                                                  });
                                                }}
                                              >
                                                <ArrowRight />
                                              </a>
                                            </div>
                                          </div>
                                        )
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </>
              )}

              {heighLightData?.devTechSkills?.length > 0 && (
                <>
                  <div className="col-3">
                    <h4>
                      <i className="h-icon">
                        {/* <img src={SkillIcon} alt="Skill" /> */}
                        <img
                          src="https://ik.imagekit.io/olibr/icons/tr:w-24,h-26/skill-icon.svg"
                          alt="Skill"
                        />
                      </i>
                      Skills
                    </h4>
                  </div>
                  <div className="col-9">
                    <div className="skill-table">
                      {heighLightData?.devTechSkills?.length > 0 &&
                        heighLightData?.devTechSkills?.map((item, index) => (
                          <div key={index}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div className="tech-skill-title">
                                <strong
                                  dangerouslySetInnerHTML={{
                                    __html: item?.title,
                                  }}
                                ></strong>
                              </div>
                            </div>
                            <ul className="tags tags-fill">
                              {item?.tagArr?.map((devSkill, index) => (
                                <li key={index}>
                                  <a
                                    dangerouslySetInnerHTML={{
                                      __html: devSkill,
                                    }}
                                  ></a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              )}

              {heighLightData?.devTools?.length > 0 && (
                <>
                  <div className="col-3">
                    <h4>
                      <i className="h-icon">
                        {/* <img src={ToolIcon} alt="Tools" /> */}
                        <img
                          src="https://ik.imagekit.io/olibr/icons/tr:w-24,h-26/tool-icon.svg"
                          alt="Tools"
                        />
                      </i>
                      Tools
                    </h4>
                  </div>
                  <div className="col-9">
                    <div className="skill-table">
                      <ul className="icon-tags-finalize">
                        {heighLightData?.devTools?.map(
                          (item, index) =>
                            item?.toolName && (
                              <li key={index}>
                                <a>
                                  <img src={item?.toolIcon} alt="" />{" "}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: item?.toolName,
                                    }}
                                  ></span>
                                </a>
                              </li>
                            )
                        )}
                      </ul>
                      {/* <table
                        width="100%"
                        border="0"
                        cellSpacing="0"
                        cellPadding="0"
                      >
                        <tbody>
                          {heighLightData?.devTools?.map(
                            (toolItem) =>
                              toolItem?.toolName && (
                                <tr key={Math.random()}>
                                  <td>
                                    <span className="align-middle">
                                      <span style={{ display: "flex" }}>
                                        <img
                                          src={toolItem?.toolIcon}
                                          alt=""
                                          width={20}
                                        />
                                        &nbsp; {toolItem?.toolName}
                                      </span>
                                    </span>
                                  </td>
                                  <td></td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </table> */}
                    </div>
                  </div>
                </>
              )}

              {heighLightData?.devAcademic?.length > 0 && (
                <>
                  <div className="col-3">
                    <h4>
                      <i className="h-icon">
                        {/* <img src={EducationIcon} alt="Education" /> */}
                        <img
                          src="https://ik.imagekit.io/olibr/icons/tr:w-24,h-21/education-icon.svg"
                          alt="Education"
                        />
                      </i>
                      Education
                    </h4>
                  </div>

                  <div className="col-9">
                    <table
                      className="skill-table certifications"
                      width="100%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        {heighLightData?.devAcademic?.map((acadItem, index) => (
                          <tr key={index}>
                            <td>
                              <strong>{acadItem?.specialization}</strong>
                              <span>{acadItem?.institution}</span>
                            </td>
                            <td>
                              {formDateDateYear(acadItem?.startDate)}
                              {"-"}
                              {formDateDateYear(acadItem?.endDate)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}

              {heighLightData?.devCertificates?.length > 0 && (
                <>
                  <div className="col-3">
                    <h4>
                      <i className="h-icon">
                        {/* <img src={CertificationIcon} alt="Certifications" /> */}
                        <img
                          src="https://ik.imagekit.io/olibr/icons/tr:w-24,h-26/certification-icon.svg"
                          alt="Certifications"
                        />
                      </i>
                      Certifications
                    </h4>
                  </div>
                  <div className="col-9">
                    <table
                      className="skill-table certifications"
                      width="100%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        {heighLightData?.devCertificates?.map(
                          (certItem, index) =>
                            certItem?.certificateName && (
                              <tr key={index}>
                                <td>
                                  <strong>{certItem?.certificateName}</strong>
                                  <span>{certItem?.certInstitution}</span>
                                </td>
                                <td>
                                  {formDateDateYear(certItem?.startDate)}
                                  {"-"}
                                  {formDateDateYear(certItem?.endDate)}
                                </td>
                              </tr>
                            )
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              )}

              {heighLightData?.devAchievement?.length > 0 && (
                <>
                  <div className="col-3">
                    <h4>
                      <i className="h-icon">
                        {/* <img src={AchievementIcon} alt="Achievement" /> */}
                        <img
                          src="https://ik.imagekit.io/olibr/icons/tr:w-24,h-26/achievement-icon.svg"
                          alt="Achievement"
                        />
                      </i>
                      Achievements
                    </h4>
                  </div>
                  <div className="col-9">
                    <div className="skill-table">
                      {heighLightData?.devAchievement?.map((item, index) => (
                        <div key={index} className="row">
                          <div className="col-12 tech-skill-title">
                            <ul className="list-text-new">
                              <li>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item?.title,
                                  }}
                                ></span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
              {heighLightData?.devReviews?.filter(
                (review) => review?.status === "done"
              ).length > 0 && (
                <Fragment>
                  <div className="col-3">
                    <h4>
                      <i className="h-icon">
                        {/* <img src={CertificationIcon} alt="Certifications" /> */}
                        <img
                          src="https://ik.imagekit.io/olibr/icons/tr:w-24,h-26/certification-icon.svg"
                          alt="Certifications"
                        />
                      </i>
                      Reviews
                    </h4>
                  </div>
                  <div className="col-9">
                    {heighLightData?.devReviews?.map(
                      (review) =>
                        review?.status === "done" && (
                          <ReviewCard review={review} />
                        )
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          </div>
          {!(
            resumeData?.status === "approved" &&
            window.location.href.includes("/developer")
          ) && (
            <div className="navigation">
              {(window.location.href.includes("/developer") ||
                window.location.href.includes("/admin") ||
                window.location.href.includes("/client")) && (
                <a
                  onClick={() => (onClose ? onClose() : navigate(-1))}
                  className="link prev disabled"
                >
                  <i className="arrow-left"></i> Back
                </a>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ProfilePreviewCommon;
