import { useState, useEffect, useRef } from "react";
import "./olibrFlagDropdown.css";

const OlibrFlagDropDown = ({ options, value, onSelect, defaultValue ,isIsoCode=true,disabled=false}) => {
  const dropdownRef = useRef();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    if (defaultValue && isIsoCode) {
      const current_val = options.find(
        (item) => parseInt(item.value) === parseInt(defaultValue)
      );
      setSelectedItem(current_val);
    }else{
      const current_val = options.find(
        (item) => item.value === defaultValue
      );
      setSelectedItem(current_val);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (value && isIsoCode) {
      const current_val = options.find(
        (item) => parseInt(item.value) === parseInt(value)
      );
      setSelectedItem(current_val);
    }else{
      const current_val = options.find(
        (item) => item.value === value
      );
      setSelectedItem(current_val);
    }
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!dropdownRef?.current?.contains(event.target)) {
        setOpenDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const onChipClick = (e) => {
    e?.stopPropagation();
    if(!disabled){
      setOpenDropdown(!openDropdown);
    }
   
  };

  const onLabelClick = (item) => {
    onSelect(item.value);
    onChipClick();
  };

  return (
    <div class="dropdown-olibr" ref={dropdownRef}>
      <div type="button" class="dropbtn-olibr" onClick={onChipClick}>
        {selectedItem?.chipLabel}
      </div>
      {openDropdown && (
        <div class="dropdown-content-olibr">
          {options.map((item) => (
            <div onClick={() => onLabelClick(item)}>{item.label}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OlibrFlagDropDown;
