import React from "react";
import ReviewStar from "../../assets/img/icons/review-star.svg";
import UserImage from "../../assets/img/user-image.jpg";
import { Icon } from "../icon";
import { rateParams, ratingName } from "../../view/reviews/constant";

const ReviewCard = ({ review }) => {
  const findAvgStars = (params) => {
    let i = 0,
      j = 0;
    Object.keys(params)?.forEach((param) => {
      i += params[param];
      j++;
    });
    const spans = Array.from({ length: parseInt(i / j) }, (_, index) => (
      <span className="fa fa-star rating-checked rating-checked-no-padding"></span>
    ));
    return spans;
  };
  return (
    <div id="review-panel">
      <div className="row">
        <div className="col-12">
          <div className="text-center">
            <Icon name="review-graphic" />
          </div>
          <p className="message">{review?.comment}</p>
          <ul className="user-pointer">
            {Object.keys(review?.rateParams)?.map((param) => (
              <li>
                {ratingName[`${param}`]}{" "}
                <span>
                  <Icon name="review-star" /> {review?.rateParams[param]}
                </span>
              </li>
            ))}
            {/* <li>
              Multi Tasking <span>img 4.5</span>
            </li> */}
          </ul>
          <h4>{review?.receiverName}</h4>
          <p className="designation">{review?.receiverDesg}</p>

          <div className="row">
            <div className="col-6">
              <span className="comment-date">
                {" "}
                <i style={{ display: "inline-block", verticalAlign: "sub" }}>
                  <Icon name="refresh" />
                </i>{" "}
                <span>
                  {new Date(review?.ratedOn)
                    .toDateString()
                    .substring(
                      4,
                      new Date(review?.ratedOn).toDateString().length
                    )}
                </span>{" "}
              </span>
            </div>
            <div className="col-6 text-right">
              {findAvgStars(review?.rateParams)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
