import React from 'react'
import { EmployementStyle } from './css/EmployementStyle'
import EmployementIcon from "../../../assets/img/icons/employment-icon-png.png";
import { Image, Path, Svg, Text, View } from '@react-pdf/renderer';
import { Educations } from './css/Educations';
import { VettedSkill } from './css/VettedSkill';
import SkillResult from './skill-result/SkillResult';
import { checkExistPlarismObj, checkExists } from '../../functions';
const VettedSkills = ({ data, skillList, stackLists }) => {
    
    return (
        Array.isArray(data) && data?.length && checkExists(data) && (
            <View style={{ ...EmployementStyle.employetmentMain, marginTop: "10px" }}>
                <View style={EmployementStyle.emplLayoutLeft} >
                    <View>
                        <Image source={EmployementIcon} style={EmployementStyle.employementIcon} />
                    </View>
                    <View style={EmployementStyle.emplLayoutLists}>
                        <Text style={EmployementStyle.empHeading}> Vetted Skills</Text>
                    </View>
                </View>
                <View style={{ ...Educations.rightLayout, marginLeft: "-6px", flexWrap: "wrap" }}>
                    {
                        data?.map((itm, index) => checkExistPlarismObj(itm) && (
                            <View key={index} style={{ ...VettedSkill.mainBox, marginTop: index !== 0 ? 13 : 2 }}>
                                <SkillResult data={itm} skillList={skillList} stackLists={stackLists} />
                            </View>
                        ))
                    }
                </View>
            </View>
        )
    )
}

export default VettedSkills