
export const customDataTable = {
    rows: {
        style: {
            // override the all row
        },
    },
    headCells: {  // override the all cell
        style: {
            color: "#000",
            fontWeight: "bold",
            backgroundColor: "#f6f7fc"
        },
    },
    cells: {
        style: {
            // override the cell padding for data cells
        },
    },
};