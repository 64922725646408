import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const OldJobUrl = () => {
    const navigate = useNavigate()
    const {jobId}=useParams()
    useEffect(() => {
        jobId?navigate(`/jobs/${jobId}`):navigate("/jobs")
    }, [])
}

export default OldJobUrl