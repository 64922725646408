import { put, call } from "redux-saga/effects";
import * as types from "../../action";
import { olibrToast } from "../../../components/toaster";
import { checkCoreSkillPassed } from "../../../components/functions/index.js";
import {
  dashboardStepOne,
  getStepCompletedStatus,
  getResume,
  proficiencyTestLink,
  proficiencyTestResult,
  englishTestResult,
  skillList,
  getDevSkillList,
  getAllSkillsList,
  manageDevSkill,
  updateAvailableFrom,
  postDevStack,
  devChooseSkills,
  deleteDevSKill,
  stackList,
  stackListChosen,
  finalizeResumeCall,
  uploadProfileImage,
  resumeParsing,
  getResumeParserData,
  uploadImage,
  startTest,
  englishTest,
  postResultData,
  getEnglishTestResult,
  askForApproval,
  getDevStatus,
  getResultDev,
  getAppliedJob,
  getShortlistedJob,
  getLatestJobList,
  getVettedSkill,
  addNewJobAPI,
  getEnglishTestStatus,
  updateEnglishTestStatus,
  postEnglishTestStatus,
  getJobFullClientDev,
  getDevAcademic,
  saveDevAcademic,
  updateDevAcademic,
  deleteDevAcademic,
  getDevCertification,
  getProfileStatus,
  saveDevCertification,
  deleteDevCertification,
  updateDevCertification,
  getCreateProfileData,
  getDevSocialProfile,
  updateDevSocialProfile,
  saveDevSocialProfile,
  getDevEmployement,
  saveDevEmployement,
  updateDevEmployement,
  deleteDevEmployement,
  getDevAllTools,
  getDevProject,
  saveDevProject,
  updateDevProject,
  deleteDevProject,
  getDevTools,
  saveDevTools,
  updateDevTools,
  deleteDevTools,
  getDevSkills,
  saveDevSkills,
  updateDevSkills,
  deleteDevSkills,
  getDevAchievement,
  saveDevAchievement,
  updateDevAchievement,
  deleteDevAchievement,
  updateDevShortDesc,
  approvalUpdateApi,
  getCurrentemployment,
  getApprovalUpdateApi,
  devChooseSkillsGet,
  getResumeOpenApi,
  updatePageTour,
  getDevReviewLists,
  saveJobFavourit,
  getFavJob,
  updateBasicDetails,
  devActivation,
  getDevActivation,
  deleteDevReviewApi,
  createDevReviewApi,
  resendDevReviewApi,
  newFeatureClickedApi,
  getDevVideoApi,
  fetchReportApi,
  getScheduledInterviewsDev,
  setInterviewSlotDev,
  getSkillTestStepDataApi,
  chooseSkillTestApi,
  chooseStackTestsApi,
  removeStackTestApi,
  removeUnattemptedSkillApi,
  updateEngActivity,
  fresherAPI,
  sendOTPApi,
  verifyOTPApi,
  addNewJobMultiAPI,
  saveScheduleInterviewByDev,
  cancelScheduleInterviewByDev,
} from "../../api";

export function* newFeatureClickedSaga({ navigate }) {
  try {
    yield put({ type: types.NEW_FEATURE_CLICKED_START });
    const response = yield call(newFeatureClickedApi);
    if (response.data.status === 401) {
      yield put({ type: types.NEW_FEATURE_CLICKED_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.NEW_FEATURE_CLICKED_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.NEW_FEATURE_CLICKED_FAIL });
  }
}

export function* getDevVideoSaga({ navigate, data }) {
  try {
    yield put({
      type: types.GET_DEV_VIDEO_START,
    });
    const response = yield call(getDevVideoApi, data);
    if (response.data.status === 401) {
      olibrToast.error(response?.data?.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_DEV_VIDEO_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_DEV_VIDEO_FAIL,
    });
  }
}

export function* getSkillTestStepDataSaga({ navigate }) {
  try {
    yield put({
      type: types.GET_SKILL_TEST_STEP_DATA_START,
    });
    const response = yield call(getSkillTestStepDataApi);
    if (response.data.status === 401) {
      olibrToast.error(response?.data?.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_SKILL_TEST_STEP_DATA_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_SKILL_TEST_STEP_DATA_FAIL,
    });
  }
}

export function* chooseStackTestsSaga({ data, navigate, setModal, jumpBack }) {
  try {
    yield put({ type: types.CHOOSE_STACK_TESTS_START });
    const response = yield call(chooseStackTestsApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.CHOOSE_STACK_TESTS_FAIL });
      navigate("/login");
    } else {
      if (response.data.statusCode === "SUCCESS") {
        const resp = yield call(getSkillTestStepDataApi);
        jumpBack && jumpBack();
        yield put({
          type: types.GET_SKILL_TEST_STEP_DATA_SUCCESS,
          data: resp?.data,
        });
        yield put({
          type: types.CHOOSE_STACK_TESTS_SUCCESS,
          data: response.data,
        });
        setModal(false);
      } else {
        olibrToast.error(response.data.message);
      }
    }
  } catch (error) {
    olibrToast.error(error.message);
    yield put({ type: types.CHOOSE_STACK_TESTS_FAIL });
  }
}

export function* removeUnattemptedSkillSaga({ objectId, navigate }) {
  try {
    yield put({ type: types.REMOVE_UNATTEMPTED_SKILL_START });
    const response = yield call(removeUnattemptedSkillApi, objectId);
    if (response.data.status === 401) {
      yield put({ type: types.REMOVE_UNATTEMPTED_SKILL_FAIL });
      navigate("/login");
    } else {
      if (response.data.statusCode === "SUCCESS") {
        const resp = yield call(getSkillTestStepDataApi);
        yield put({
          type: types.GET_SKILL_TEST_STEP_DATA_SUCCESS,
          data: resp?.data,
        });
        yield put({
          type: types.REMOVE_UNATTEMPTED_SKILL_SUCCESS,
          data: response.data,
        });
      } else {
        olibrToast.error(response.data.message);
      }
    }
  } catch (error) {
    olibrToast.error(error.message);
    yield put({ type: types.REMOVE_UNATTEMPTED_SKILL_FAIL });
  }
}

export function* removeStackTestSaga({ stackId, navigate }) {
  try {
    yield put({ type: types.REMOVE_STACK_TEST_START });
    const response = yield call(removeStackTestApi, stackId);
    if (response.data.status === 401) {
      yield put({ type: types.REMOVE_STACK_TEST_FAIL });
      navigate("/login");
    } else {
      if (response.data.statusCode === "SUCCESS") {
        const resp = yield call(getSkillTestStepDataApi);
        yield put({
          type: types.GET_SKILL_TEST_STEP_DATA_SUCCESS,
          data: resp?.data,
        });
        yield put({
          type: types.REMOVE_STACK_TEST_SUCCESS,
          data: response.data,
        });
      } else {
        olibrToast.error(response.data.message);
      }
    }
  } catch (error) {
    olibrToast.error(error.message);
    yield put({ type: types.REMOVE_STACK_TEST_FAIL });
  }
}

export function* chooseSkillTestSaga({ skillId, navigate, jumpTop }) {
  try {
    yield put({ type: types.CHOOSE_SKILL_TEST_START });
    const response = yield call(chooseSkillTestApi, skillId);
    if (response.data.status === 401) {
      yield put({ type: types.CHOOSE_SKILL_TEST_FAIL });
      navigate("/login");
    } else {
      if (response.data.statusCode === "SUCCESS") {
        const resp = yield call(getSkillTestStepDataApi);
        yield put({
          type: types.GET_SKILL_TEST_STEP_DATA_SUCCESS,
          data: resp?.data,
        });
        yield put({
          type: types.CHOOSE_SKILL_TEST_SUCCESS,
          data: response.data,
        });
      } else {
        jumpTop && jumpTop();
        olibrToast.error(response.data.message);
      }
    }
  } catch (error) {
    jumpTop && jumpTop();
    olibrToast.error(error.message);
    yield put({ type: types.CHOOSE_SKILL_TEST_FAIL });
  }
}

export function* getDevReviewSaga({ navigate }) {
  try {
    yield put({
      type: types.GET_DEV_REVIEW_LISTS_START,
    });
    const response = yield call(getDevReviewLists);
    if (response.data.status === 401) {
      olibrToast.error(response?.data?.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_DEV_REVIEW_LISTS_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_DEV_REVIEW_LISTS_FAIL,
    });
  }
}

export function* deleteDevReviewSaga({ reviewId, navigate, closeModal }) {
  try {
    yield put({ type: types.DELETE_DEV_REVIEW_START });
    const response = yield call(deleteDevReviewApi, reviewId);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_DEV_REVIEW_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        const resp = yield call(getDevReviewLists);
        yield put({
          type: types.DELETE_DEV_REVIEW_SUCCESS,
          data: response.data,
        });
        yield put({
          type: types.GET_DEV_REVIEW_LISTS_SUCCESS,
          data: resp.data,
        });
        closeModal();
      } else {
        yield put({ type: types.DELETE_DEV_REVIEW_FAIL });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_DEV_REVIEW_FAIL });
  }
}

export function* resendDevReviewSaga({ data, navigate }) {
  try {
    yield put({ type: types.RESEND_DEV_REVIEW_START });
    console.log("saga");
    const response = yield call(resendDevReviewApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.RESEND_DEV_REVIEW_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        const resp = yield call(getDevReviewLists);
        yield put({
          type: types.RESEND_DEV_REVIEW_SUCCESS,
          data: response.data,
        });
        yield put({
          type: types.GET_DEV_REVIEW_LISTS_SUCCESS,
          data: resp.data,
        });
      } else {
        yield put({ type: types.RESEND_DEV_REVIEW_FAIL });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.RESEND_DEV_REVIEW_FAIL });
  }
}

export function* createDevReviewSaga({ data, navigate, closeModal }) {
  try {
    yield put({ type: types.CREATE_DEV_REVIEW_START });
    const response = yield call(createDevReviewApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.CREATE_DEV_REVIEW_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        const resp = yield call(getDevReviewLists);
        yield put({
          type: types.CREATE_DEV_REVIEW_SUCCESS,
          data: response.data,
        });
        yield put({
          type: types.GET_DEV_REVIEW_LISTS_SUCCESS,
          data: resp.data,
        });
        closeModal();
      } else {
        yield put({ type: types.CREATE_DEV_REVIEW_FAIL });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.CREATE_DEV_REVIEW_FAIL });
  }
}

export function* dashboardStepOneSaga({ data, navigate, next }) {
  try {
    yield put({ type: types.DASHBOARD_STEP_ONE_START });
    const response = yield call(dashboardStepOne, data);
    if (response.data.status === 401) {
      yield put({ type: types.DASHBOARD_STEP_ONE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else if (response.data.statusValue === 400) {
      yield put({ type: types.DASHBOARD_STEP_ONE_FAIL });
      olibrToast.error(response.data.message);
    } else {
      yield put({
        type: types.DASHBOARD_STEP_ONE_SUCCESS,
        data: {
          ...response?.data?.data,
          devEmail: data?.devEmail,
          fName: data?.fName,
          lName: data?.lName,
          devDesg: data?.devDesg,
        },
        next,
      });
    }
  } catch (error) {
    yield put({ type: types.DASHBOARD_STEP_ONE_FAIL });
  }
}

export function* getStepCompletedStatusSaga({ navigate }) {
  try {
    yield put({ type: types.GET_STEP_COMPLETED_STATUS_START });
    const response = yield call(getStepCompletedStatus);
    if (response.data.status === 401) {
      yield put({ type: types.GET_STEP_COMPLETED_STATUS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_STEP_COMPLETED_STATUS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_STEP_COMPLETED_STATUS_FAIL });
  }
}

export function* getResumeSaga({ userId, navigate,jobId }) {
  try {
    yield put({ type: types.GET_RESUME_START });
    const response = yield call(getResume, userId,jobId);
    if (response.data.status === 401) {
      yield put({ type: types.GET_RESUME_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_RESUME_SUCCESS, data: response?.data });
    }
  } catch (error) {
    yield put({ type: types.GET_RESUME_FAIL });
  }
}

export function* getResumeOpenSaga({ userId, navigate }) {
  try {
    yield put({ type: types.GET_RESUME_OPEN_START });
    const response = yield call(getResumeOpenApi, userId);
    if (response.data.status === 401) {
      yield put({ type: types.GET_RESUME_OPEN_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_RESUME_OPEN_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.GET_RESUME_OPEN_FAIL });
  }
}

export function* getProfileStatusSaga({ navigate }) {
  try {
    yield put({ type: types.GET_PROFILE_STATUS_START });
    const response = yield call(getProfileStatus);
    if (response.data.status === 401) {
      yield put({ type: types.GET_PROFILE_STATUS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_PROFILE_STATUS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_PROFILE_STATUS_FAIL });
  }
}

export function* proficiencyTestLinkSaga({ navigate }) {
  try {
    yield put({ type: types.PROFICIENCY_TEST_LINK_START });
    const response = yield call(proficiencyTestLink);
    if (response.data.status === 401) {
      yield put({ type: types.PROFICIENCY_TEST_LINK_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.PROFICIENCY_TEST_LINK_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.PROFICIENCY_TEST_LINK_FAIL });
  }
}

export function* proficiencyTestResultSaga({ navigate }) {
  try {
    yield put({ type: types.PROFICIENCY_TEST_RESULT_START });
    const response = yield call(proficiencyTestResult);
    if (response.data.status === 401) {
      yield put({ type: types.PROFICIENCY_TEST_RESULT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.PROFICIENCY_TEST_RESULT_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.PROFICIENCY_TEST_RESULT_FAIL });
  }
}

export function* englishTestResultSaga(navigate) {
  try {
    yield put({ type: types.GET_ENGLISH_TEST_RESULT_START });
    const response = yield call(englishTestResult);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ENGLISH_TEST_RESULT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ENGLISH_TEST_RESULT_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ENGLISH_TEST_RESULT_FAIL });
  }
}

export function* skillListSaga({ navigate }) {
  try {
    yield put({ type: types.SKILL_LIST_START });
    const response = yield call(skillList);
    if (response.data.status === 401) {
      yield put({ type: types.SKILL_LIST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.SKILL_LIST_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.SKILL_LIST_FAIL });
  }
}

export function* getDevSkillListSaga({ navigate }) {
  try {
    yield put({ type: types.GET_DEV_SKILL_LIST_START });
    const response = yield call(getDevSkillList);
    if (response.data.status === 401) {
      yield put({ type: types.GET_DEV_SKILL_LIST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_DEV_SKILL_LIST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_DEV_SKILL_LIST_FAIL });
  }
}

export function* getAllSkillsListSaga({ navigate }) {
  try {
    yield put({ type: types.GET_ALL_SKILL_LIST_START });
    const response = yield call(getAllSkillsList);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ALL_SKILL_LIST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ALL_SKILL_LIST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ALL_SKILL_LIST_FAIL });
  }
}

export function* manageDevSkillSaga({ data, navigate }) {
  try {
    yield put({ type: types.MANAGE_DEV_SKILL_START });
    const response = yield call(manageDevSkill, data);
    if (response.data.status === 401) {
      yield put({ type: types.MANAGE_DEV_SKILL_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.MANAGE_DEV_SKILL_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.MANAGE_DEV_SKILL_FAIL });
  }
}

export function* updateAvailableFromSaga({ data, navigate }) {
  try {
    yield put({ type: types.UPDATE_AVAILABLE_FROM_START });
    const response = yield call(updateAvailableFrom, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_AVAILABLE_FROM_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusText === "SUCCESS") {
        yield put({
          type: types.UPDATE_AVAILABLE_FROM_SUCCESS,
          data: response.data,
        });
        olibrToast.success(response?.data?.message);
      } else if (response?.data?.statusText === "FAIL") {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_AVAILABLE_FROM_FAIL });
  }
}

export function* postDevStackSaga({ data, navigate }) {
  try {
    yield put({ type: types.POST_DEV_STACK_START });
    const response = yield call(postDevStack, data);
    if (response.data.status === 401) {
      yield put({ type: types.POST_DEV_STACK_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      const stackChoosen = yield call(stackListChosen);
      yield put({
        type: types.STACK_LIST_CHOSEN_SUCCESS,
        data: stackChoosen.data,
      });
      yield put({ type: types.POST_DEV_STACK_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.POST_DEV_STACK_FAIL });
  }
}

export function* devChooseSkillsSaga({ data, skillFor, navigate }) {
  try {
    yield put({ type: types.DEV_CHOOSE_SKILL_START });
    const response = yield call(devChooseSkills, data);
    if (response.data.status === 401) {
      yield put({ type: types.DEV_CHOOSE_SKILL_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response.data.statusCode === "SUCCESS") {
        // yield call(skillList);
        // yield put({
        //   type: types.DEV_CHOOSE_SKILL_SUCCESS,
        //   data: data.devChooseSkills,
        // });
        if (skillFor.skillFor === "delete") {
          olibrToast.success("removed");
        }
      } else if (response.data.statusCode === "FAIL") {
        // olibrToast.error("At least 1 skill is required");
      }
    }
  } catch (error) {
    yield put({ type: types.DEV_CHOOSE_SKILL_FAIL });
  }
}
export function* applyNewJob({ data, navigate, closeModal }) {
  try {
    yield put({ type: types.APPLY_NEW_JOB_START });
    const response = yield call(addNewJobAPI, data);
    if (response.data.status === 401) {
      yield put({ type: types.APPLY_NEW_JOB_FAIL });
      navigate("/login");
    } else {
      if (response.data.statusCode === "SUCCESS") {
        closeModal && closeModal();
        const resp = yield call(getAppliedJob, {});
        yield put({ type: types.APPLY_NEW_JOB_SUCCESS, data: response.data });
        yield put({ type: types.GET_APPLIED_JOB_SUCCESS, data: resp?.data });
        olibrToast.success(response.data.message);
      } else {
        olibrToast.error(response.data.message);
        yield put({ type: types.APPLY_NEW_JOB_FAIL });
      }
    }
  } catch (error) {
    olibrToast.error(error.message);
    yield put({ type: types.APPLY_NEW_JOB_FAIL });
  }
}

export function* applyNewJobMultiSaga({ data, navigate, closeModal }) {
  try {
    yield put({ type: types.APPLY_NEW_MULTI_JOB_START });
    const response = yield call(addNewJobMultiAPI, data);
    if (response.data.status === 401) {
      yield put({ type: types.APPLY_NEW_MULTI_JOB_FAIL });
      navigate("/login");
    } else {
      if (response.data.statusCode === "SUCCESS") {
        closeModal && closeModal();
        const resp = yield call(getAppliedJob, {});
        yield put({
          type: types.APPLY_NEW_MULTI_JOB_SUCCESS,
          data: response.data,
        });
        yield put({ type: types.GET_APPLIED_JOB_SUCCESS, data: resp?.data });
        olibrToast.success(response.data.message);
      } else {
        olibrToast.error(response.data.message);
        yield put({ type: types.APPLY_NEW_MULTI_JOB_FAIL });
      }
    }
  } catch (error) {
    olibrToast.error(error.message);
    yield put({ type: types.APPLY_NEW_MULTI_JOB_FAIL });
  }
}

export function* sendOTPSaga({ data, navigate, cb }) {
  try {
    yield put({ type: types.SEND_OTP_START });
    const response = yield call(sendOTPApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.SEND_OTP_FAIL });
      navigate("/login");
    } else {
      if (response.data.statusCode === "SUCCESS") {
        cb();
        yield put({ type: types.SEND_OTP_SUCCESS, data: response.data });
        // olibrToast.success(response.data.message);
      } else {
        olibrToast.error(response.data.message);
      }
    }
  } catch (error) {
    olibrToast.error(error.message);
    yield put({ type: types.SEND_OTP_FAIL });
  }
}

export function* verifyOTPSaga({ data, navigate, cb, pageType }) {
  try {
    yield put({ type: types.VERIFY_OTP_START });
    const response = yield call(verifyOTPApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.VERIFY_OTP_FAIL });
      navigate("/login");
    } else {
      if (response.data.statusCode === "SUCCESS") {
        cb();
        yield put({ type: types.VERIFY_OTP_SUCCESS, data: response.data });
        let resp = {};
        if (pageType === "settings") {
          resp = yield call(getResume);
          yield put({ type: types.GET_RESUME_SUCCESS, data: resp?.data });
        } else {
          resp = yield call(getCreateProfileData);
          yield put({
            type: types.GET_CREATE_PROFILE_SUCCESS,
            data: resp.data,
          });
        }
        // olibrToast.success(response.data.message);
      } else {
        yield put({ type: types.VERIFY_OTP_FAIL });
        olibrToast.error(response.data.message);
      }
    }
  } catch (error) {
    olibrToast.error(error.message);
    yield put({ type: types.VERIFY_OTP_FAIL });
  }
}

export function* fresherSaga({ userId, navigate }) {
  try {
    const response = yield call(fresherAPI, userId);
    if (response.data.status === 401) {
      navigate("/login");
    } else {
      if (response.data.statusText === "SUCCESS") {
        olibrToast.success(response.data.message);
      } else {
        olibrToast.error(response.data.message);
      }
    }
  } catch (error) {
    olibrToast.error(error.message);
  }
}

export function* deleteDevSKillSaga({ data, navigate }) {
  try {
    yield put({ type: types.DELETE_DEV_SKILL_START });
    const response = yield call(deleteDevSKill, data);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_DEV_SKILL_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.DELETE_DEV_SKILL_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.DELETE_DEV_SKILL_FAIL });
  }
}

export function* stackListSaga({ navigate }) {
  try {
    yield put({ type: types.STACK_LIST_START });
    const response = yield call(stackList);
    if (response.data.status === 401) {
      yield put({ type: types.STACK_LIST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.STACK_LIST_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.STACK_LIST_FAIL });
  }
}

export function* devChooseSkillsGetSaga({ navigate }) {
  try {
    yield put({ type: types.DEV_CHOOSE_SKILL_GET_START });
    const response = yield call(devChooseSkillsGet);
    if (response.data.status === 401) {
      yield put({ type: types.DEV_CHOOSE_SKILL_GET_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.DEV_CHOOSE_SKILL_GET_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.DEV_CHOOSE_SKILL_GET_FAIL });
  }
}

export function* stackListChosenSaga({ navigate }) {
  try {
    yield put({ type: types.STACK_LIST_CHOSEN_START });
    const response = yield call(stackListChosen);
    if (response.data.status === 401) {
      yield put({ type: types.STACK_LIST_CHOSEN_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.STACK_LIST_CHOSEN_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.STACK_LIST_CHOSEN_FAIL });
  }
}

export function* finalizeResumeCallSaga({ data, navigate }) {
  try {
    yield put({ type: types.FINALIZE_RESUME_CALL_START });
    const response = yield call(finalizeResumeCall, data);
    if (response.data.status === 401) {
      yield put({ type: types.FINALIZE_RESUME_CALL_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        olibrToast.success(response?.data?.message);
        yield put({
          type: types.FINALIZE_RESUME_CALL_SUCCESS,
          data: response?.data,
        });
      } else {
        olibrToast.error(response?.data?.message);
        yield put({
          type: types.FINALIZE_RESUME_CALL_FAIL,
        });
      }
    }
  } catch (error) {
    yield put({ type: types.FINALIZE_RESUME_CALL_FAIL });
  }
}

export function* uploadProfileImageSaga({ data, navigate, prevUrl }) {
  try {
    yield put({ type: types.UPLOAD_PROFILE_IMAGE_START });
    const response = yield call(uploadProfileImage, data, prevUrl);
    if (response.data.status === 401) {
      yield put({ type: types.UPLOAD_PROFILE_IMAGE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (data?.devId)
        yield put({
          type: types.UPDATE_ADMIN_DEV_PROFILE_IMAGE_SUCCESS,
          data: response.data,
        });
      yield put({
        type: types.UPLOAD_PROFILE_IMAGE_SUCCESS,
        data: response.data,
      });
      olibrToast?.success(response?.data?.message);
    }
  } catch (error) {
    yield put({ type: types.UPLOAD_PROFILE_IMAGE_FAIL });
  }
}

export function* resumeParsingSaga({ data, navigate }) {
  //current
  try {
    yield put({ type: types.RESUME_PARSING_START });
    const response = yield call(resumeParsing, data);
    if (response.data.status === 401) {
      yield put({ type: types.RESUME_PARSING_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response.data.statusValue === 200) {
        olibrToast.success(response.data.message);
        yield put({ type: types.RESUME_PARSING_SUCCESS, data: response.data });
      } else {
        olibrToast.error(response.data.message);
      }
    }
  } catch (error) {
    yield put({ type: types.RESUME_PARSING_FAIL });
  }
}

export function* getResumeParserDataSaga({ navigate }) {
  try {
    yield put({ type: types.GET_RESUME_PARSER_DATA_START });
    const response = yield call(getResumeParserData);
    if (response.data.status === 401) {
      yield put({ type: types.GET_RESUME_PARSER_DATA_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_RESUME_PARSER_DATA_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_RESUME_PARSER_DATA_FAIL });
  }
}

export function* getCreateProfileSaga({ navigate, setTab }) {
  try {
    yield put({ type: types.GET_CREATE_PROFILE_START });
    const response = yield call(getCreateProfileData);
    if (response.data.status === 401) {
      yield put({ type: types.GET_CREATE_PROFILE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      console.log("response", response?.data?.data?.devStepCompleted);
      if (setTab) {
        let steps = response?.data?.data?.devStepCompleted;
        if (steps > 1) {
          if (
            steps > 2 &&
            checkCoreSkillPassed(response?.data?.data?.devTechTestResult)
          ) {
            setTab(3);
          } else {
            setTab(steps);
          }
        }
      }
      yield put({
        type: types.GET_CREATE_PROFILE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_CREATE_PROFILE_FAIL });
  }
}

export function* uploadImageSaga({
  data,
  prevUrl,
  navigate,
  forType,
  indexNo,
}) {
  try {
    yield put({ type: types.UPLOAD_IMAGE_START, case: forType });
    const response = yield call(uploadImage, data, prevUrl);
    if (response.data.status === 401) {
      yield put({ type: types.UPLOAD_IMAGE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (forType) {
        yield put({
          type: types.UPLOAD_IMAGE_SUCCESS,
          case: forType,
          indexNo,
          data: response?.data?.payload,
        });
      } else {
        yield put({
          type: types.UPLOAD_IMAGE_SUCCESS,
          data: response?.data?.payload,
        });
      }
    }
  } catch (error) {
    yield put({ type: types.UPLOAD_IMAGE_FAIL });
  }
}

export function* startTestSaga({ data, navigate }) {
  try {
    yield put({ type: types.START_TEST_START, testId: data.assessmentId });
    const response = yield call(startTest, data);
    if (response.data.status === 401) {
      yield put({ type: types.START_TEST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.payload?.test_link) {
        window.open(response?.data?.payload.test_link, "_SELF");
      } else {
        olibrToast.error(
          response?.data?.message
            ? response?.data?.message
            : "Test is not available now."
        );
      }
      yield put({ type: types.START_TEST_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.START_TEST_FAIL });
  }
}

export function* englishTestSaga({ mcq, long, navigate }) {
  try {
    yield put({ type: types.ENGLISH_TEST_START });
    const response = yield call(englishTest, { mcq, long });
    if (response.data.status === 401) {
      yield put({ type: types.ENGLISH_TEST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "ALREADY_TAKEN") {
        olibrToast.info(response?.data?.message);
        yield put({ type: types.ENGLISH_TEST_FAIL });
        navigate("/developer/profile");
      } else {
        yield put({
          type: types.ENGLISH_TEST_SUCCESS,
          data: response?.data?.data,
          testTime: response?.data?.testTime,
          prevQuestion: response?.data?.prevQuestion,
        });
      }
    }
  } catch (error) {
    yield put({ type: types.ENGLISH_TEST_FAIL });
  }
}

export function* englishTestCheckSaga({
  mcq,
  long,
  navigate,
  closeInstruction,
}) {
  try {
    yield put({ type: types.ENGLISH_TEST_CHECK_START });
    const response = yield call(englishTest, { mcq, long });
    if (response.data.status === 401) {
      yield put({ type: types.ENGLISH_TEST_CHECK_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "ALREADY_TAKEN") {
        olibrToast.info(response?.data?.message);
        yield put({
          type: types.ENGLISH_TEST_CHECK_FAIL,
          result: response?.data?.result,
        });
        closeInstruction();
      } else {
        navigate("/developer/english-test/test");
      }
    }
  } catch (error) {
    yield put({ type: types.ENGLISH_TEST_CHECK_FAIL });
  }
}

export function* updateEngActivitySaga({ navigate, data }) {
  try {
    yield put({ type: types.SAVE_ENGLISH_ACTIVITY_START });
    const response = yield call(updateEngActivity, data);
    if (response.data.status === 401) {
      yield put({ type: types.SAVE_ENGLISH_ACTIVITY_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    }
  } catch (error) {
    yield put({ type: types.SAVE_ENGLISH_ACTIVITY_FAIL });
  }
}
export function* postResultDataSaga({ data, navigate }) {
  try {
    yield put({ type: types.POST_RESULT_DATA_START });
    const response = yield call(postResultData, data);
    if (response.data.status === 401) {
      yield put({ type: types.POST_RESULT_DATA_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.POST_RESULT_DATA_SUCCESS,
        data: response?.data?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.POST_RESULT_DATA_FAIL });
  }
}

export function* getEnglishTestResultSaga({ navigate }) {
  try {
    yield put({ type: types.GET_ENGLISH_TEST_RESULT_START });
    const response = yield call(getEnglishTestResult);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ENGLISH_TEST_RESULT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ENGLISH_TEST_RESULT_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ENGLISH_TEST_RESULT_FAIL });
  }
}

export function* askForApprovalSaga({ notificationMsg, navigate }) {
  try {
    yield put({ type: types.ASK_FOR_APPROVAL_START });
    const response = yield call(askForApproval);
    if (response.data.status === 401) {
      yield put({ type: types.ASK_FOR_APPROVAL_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      olibrToast.success(response.data.message);
      // window.provesrc.display("information", null, {
      //   message: `${notificationMsg} submitted his profile for Approval`,
      // });
      const getResponse = yield call(getResume);
      yield put({
        type: types.GET_RESUME_SUCCESS,
        status: "pending",
        data: getResponse?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.ASK_FOR_APPROVAL_FAIL });
  }
}

export function* getDevStatusSaga({ navigate }) {
  try {
    yield put({ type: types.GET_DEV_STATUS_START });
    const response = yield call(getDevStatus);
    if (response.data.status === 401) {
      yield put({ type: types.GET_DEV_STATUS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_DEV_STATUS_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.GET_DEV_STATUS_FAIL });
  }
}

export function* getResultDevSaga({ userId, navigate }) {
  try {
    yield put({ type: types.GET_RESULT_DEV_START });
    const response = yield call(getResultDev, userId);
    if (response.data.status === 401) {
      yield put({ type: types.GET_RESULT_DEV_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_RESULT_DEV_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.GET_RESULT_DEV_FAIL });
  }
}

export function* getAppliedJobSaga({ navigate, pageData }) {
  try {
    yield put({ type: types.GET_APPLIED_JOB_START });
    const response = yield call(getAppliedJob, pageData);
    if (response.data.status === 401) {
      yield put({ type: types.GET_APPLIED_JOB_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_APPLIED_JOB_SUCCESS, data: response?.data });
    }
  } catch (error) {
    yield put({ type: types.GET_APPLIED_JOB_FAIL });
  }
}

export function* getShortlistedJobSaga({ navigate, pageData }) {
  try {
    yield put({ type: types.GET_SHORT_LIST_JOB_START });
    const response = yield call(getShortlistedJob, pageData);
    if (response.data.status === 401) {
      yield put({ type: types.GET_SHORT_LIST_JOB_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_SHORT_LIST_JOB_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_SHORT_LIST_JOB_FAIL });
  }
}

export function* getLatestJobListSaga({ navigate, pageData }) {
  try {
    yield put({ type: types.GET_LATEST_JOB_LIST_START });
    const response = yield call(getLatestJobList, pageData);
    if (response.data.status === 401) {
      yield put({ type: types.GET_LATEST_JOB_LIST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_LATEST_JOB_LIST_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_LATEST_JOB_LIST_FAIL });
  }
}

export function* getVettedSkillSaga({ navigate }) {
  try {
    yield put({ type: types.GET_VETTED_SKILL_START });
    const response = yield call(getVettedSkill);
    if (response.data.status === 401) {
      yield put({ type: types.GET_VETTED_SKILL_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_VETTED_SKILL_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.GET_VETTED_SKILL_FAIL });
  }
}

export function* getEnglishTestStatusSaga({ navigate }) {
  try {
    yield put({ type: types.GET_ENGLISH_TEST_STATUS_START });
    const response = yield call(getEnglishTestStatus);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ENGLISH_TEST_STATUS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ENGLISH_TEST_STATUS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ENGLISH_TEST_STATUS_FAIL });
  }
}

export function* updateEnglishTestStatusSaga({ navigate, data }) {
  try {
    yield put({ type: types.UPDATE_ENGLISH_TEST_STATUS_START });
    const response = yield call(updateEnglishTestStatus, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_ENGLISH_TEST_STATUS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.UPDATE_ENGLISH_TEST_STATUS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.UPDATE_ENGLISH_TEST_STATUS_FAIL });
  }
}

export function* postEnglishTestStatusSaga({ navigate, data }) {
  try {
    yield put({ type: types.POST_ENGLISH_TEST_STATUS_START });
    const response = yield call(postEnglishTestStatus, data);
    if (response.data.status === 401) {
      yield put({ type: types.POST_ENGLISH_TEST_STATUS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.POST_ENGLISH_TEST_STATUS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.POST_ENGLISH_TEST_STATUS_FAIL });
  }
}

export function* getJobFullClientDevSaga({ jobId, navigate }) {
  try {
    yield put({ type: types.GET_JOB_FULL_CLIENT_DEV_START });
    const response = yield call(getJobFullClientDev, jobId);
    if (response.data.status === 401) {
      yield put({ type: types.GET_JOB_FULL_CLIENT_DEV_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_JOB_FULL_CLIENT_DEV_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_JOB_FULL_CLIENT_DEV_FAIL });
  }
}

export function* getDevAcademicSaga({ navigate }) {
  try {
    yield put({ type: types.GET_DEV_ACADEMIC_START });
    const response = yield call(getDevAcademic);
    if (response.data.status === 401) {
      yield put({ type: types.GET_DEV_ACADEMIC_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_DEV_ACADEMIC_SUCCESS, data: response?.data });
    }
  } catch (error) {
    yield put({ type: types.GET_DEV_ACADEMIC_FAIL });
  }
}

export function* saveDevAcademicSaga({
  navigate,
  data,
  setAcadmicDetail,
  reset,
}) {
  try {
    yield put({ type: types.SAVE_DEV_ACADEMIC_START });
    const response = yield call(saveDevAcademic, data);
    if (response.data.status === 401) {
      yield put({ type: types.SAVE_DEV_ACADEMIC_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.SAVE_DEV_ACADEMIC_SUCCESS,
          data: response?.data,
        });
        // olibrToast.success(response?.data?.message);
        setAcadmicDetail({ modal: false, edit: false });
        reset();
      } else {
        yield put({ type: types.SAVE_DEV_ACADEMIC_FAIL, data: response?.data });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.SAVE_DEV_ACADEMIC_FAIL });
  }
}

export function* updateDevAcademicSaga({
  navigate,
  data,
  setAcadmicDetail,
  reset,
}) {
  try {
    yield put({ type: types.UPDATE_DEV_ACADEMIC_START });
    const response = yield call(updateDevAcademic, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_DEV_ACADEMIC_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_DEV_ACADEMIC_SUCCESS,
          data: response?.data,
        });
        // olibrToast.success(response?.data?.message);
        setAcadmicDetail({ modal: false, edit: false });
        reset();
      } else {
        yield put({
          type: types.UPDATE_DEV_ACADEMIC_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_DEV_ACADEMIC_FAIL });
  }
}

export function* deleteDevAcademicSaga({ navigate, data }) {
  try {
    yield put({ type: types.DELETE_DEV_ACADEMIC_START });
    const response = yield call(deleteDevAcademic, data);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_DEV_ACADEMIC_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.DELETE_DEV_ACADEMIC_SUCCESS,
          data: data?.id,
        });
        olibrToast.success(response?.data?.message);
      } else {
        yield put({
          type: types.DELETE_DEV_ACADEMIC_SUCCESS,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_DEV_ACADEMIC_FAIL });
  }
}

export function* getDevCertificationSaga({ navigate }) {
  try {
    yield put({ type: types.GET_DEV_CERTIFICATION_START });
    const response = yield call(getDevCertification);
    if (response.data.status === 401) {
      yield put({ type: types.GET_DEV_CERTIFICATION_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_DEV_CERTIFICATION_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_DEV_CERTIFICATION_FAIL });
  }
}

export function* saveDevCertificationSaga({
  navigate,
  data,
  setDetails,
  resetForm,
}) {
  try {
    yield put({ type: types.SAVE_DEV_CERTIFICATION_START });
    const response = yield call(saveDevCertification, data);
    if (response.data.status === 401) {
      yield put({ type: types.SAVE_DEV_CERTIFICATION_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.SAVE_DEV_CERTIFICATION_SUCCESS,
          data: response?.data,
        });
        // olibrToast.success(response?.data?.message);
        setDetails({ modal: false, edit: false });
        resetForm();
      } else {
        yield put({
          type: types.SAVE_DEV_CERTIFICATION_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.SAVE_DEV_CERTIFICATION_FAIL });
  }
}

export function* deleteDevCertificationSaga({ navigate, data }) {
  try {
    yield put({ type: types.DELETE_DEV_CERTIFICATION_START });
    const response = yield call(deleteDevCertification, data);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_DEV_CERTIFICATION_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.DELETE_DEV_CERTIFICATION_SUCCESS,
          data: data?.id,
        });
        olibrToast.success(response?.data?.message);
      } else {
        yield put({
          type: types.DELETE_DEV_CERTIFICATION_FAIL,
          data: response?.data,
        });
        // olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_DEV_CERTIFICATION_FAIL });
  }
}

export function* updateDevCertificationSaga({
  navigate,
  data,
  setDetails,
  resetForm,
}) {
  try {
    yield put({ type: types.UPDATE_DEV_CERTIFICATION_START });
    const response = yield call(updateDevCertification, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_DEV_CERTIFICATION_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_DEV_CERTIFICATION_SUCCESS,
          data: response?.data,
        });
        // olibrToast.success(response?.data?.message);
        setDetails({ modal: false, edit: false });
        resetForm();
      } else {
        yield put({
          type: types.UPDATE_DEV_CERTIFICATION_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_DEV_CERTIFICATION_FAIL });
  }
}

export function* getDevSocialProfileSaga({ navigate }) {
  try {
    yield put({ type: types.GET_DEV_SOCIAL_PROFILE_START });
    const response = yield call(getDevSocialProfile);
    if (response.data.status === 401) {
      yield put({ type: types.GET_DEV_SOCIAL_PROFILE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_DEV_SOCIAL_PROFILE_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_DEV_SOCIAL_PROFILE_FAIL });
  }
}

export function* saveDevSocialProfileSaga({ navigate, data }) {
  try {
    yield put({ type: types.SAVE_DEV_SOCIAL_PROFILE_START });
    const response = yield call(saveDevSocialProfile, data);
    if (response.data.status === 401) {
      yield put({ type: types.SAVE_DEV_SOCIAL_PROFILE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.SAVE_DEV_SOCIAL_PROFILE_SUCCESS,
          data: response?.data,
        });
        // const getResponse = yield call(getDevSocialProfile);
        // yield put({
        //   type: types.GET_DEV_SOCIAL_PROFILE_SUCCESS,
        //   data: getResponse?.data,
        // });
        // olibrToast.success(response?.data?.message);
      } else {
        yield put({
          type: types.SAVE_DEV_SOCIAL_PROFILE_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.SAVE_DEV_CERTIFICATION_FAIL });
  }
}
export function* updateDevSocialProfileSaga({ navigate, data }) {
  try {
    yield put({ type: types.UPDATE_DEV_SOCIAL_PROFILE_START });
    const response = yield call(updateDevSocialProfile, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_DEV_SOCIAL_PROFILE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_DEV_SOCIAL_PROFILE_SUCCESS,
          data: response?.data,
        });
        const getResponse = yield call(getDevSocialProfile);
        yield put({
          type: types.GET_DEV_SOCIAL_PROFILE_SUCCESS,
          data: getResponse?.data,
        });
        // olibrToast.success(response?.data?.message);
      } else {
        yield put({
          type: types.UPDATE_DEV_SOCIAL_PROFILE_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_DEV_SOCIAL_PROFILE_FAIL });
  }
}
export function* getDevEmployementSaga({ navigate }) {
  try {
    yield put({ type: types.GET_DEV_EMPLOYEMENT_START });
    const response = yield call(getDevEmployement);
    if (response.data.status === 401) {
      yield put({ type: types.GET_DEV_EMPLOYEMENT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_DEV_EMPLOYEMENT_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_DEV_EMPLOYEMENT_FAIL });
  }
}
export function* saveDevEmployementSaga({
  navigate,
  data,
  setEmpDetails,
  resetForm,
}) {
  try {
    yield put({ type: types.SAVE_DEV_EMPLOYEMENT_START });
    const response = yield call(saveDevEmployement, data);
    if (response.data.status === 401) {
      yield put({ type: types.SAVE_DEV_EMPLOYEMENT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        const resp = yield call(getDevEmployement);
        yield put({
          type: types.SAVE_DEV_EMPLOYEMENT_SUCCESS,
          data: response?.data,
        });
        yield put({
          type: types.GET_DEV_EMPLOYEMENT_SUCCESS,
          data: resp?.data,
        });
        // olibrToast.success(response?.data?.message);
        setEmpDetails({ modal: false, edit: false });
        resetForm();
      } else {
        yield put({
          type: types.SAVE_DEV_EMPLOYEMENT_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.SAVE_DEV_EMPLOYEMENT_FAIL });
  }
}
export function* updateDevEmployementSaga({
  navigate,
  data,
  setEmpDetails,
  resetForm,
}) {
  try {
    yield put({ type: types.UPDATE_DEV_EMPLOYEMENT_START });
    const response = yield call(updateDevEmployement, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_DEV_EMPLOYEMENT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        const resp = yield call(getDevEmployement);
        yield put({
          type: types.UPDATE_DEV_EMPLOYEMENT_SUCCESS,
          data: response?.data,
        });
        yield put({
          type: types.GET_DEV_EMPLOYEMENT_SUCCESS,
          data: resp?.data,
        });
        // olibrToast.success(response?.data?.message);
        setEmpDetails({ modal: false, edit: false });
        resetForm();
      } else {
        yield put({
          type: types.UPDATE_DEV_EMPLOYEMENT_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_DEV_EMPLOYEMENT_FAIL });
  }
}
export function* deleteDevEmployementSaga({ navigate, data }) {
  try {
    yield put({ type: types.DELETE_DEV_EMPLOYEMENT_START });
    const response = yield call(deleteDevEmployement, data);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_DEV_EMPLOYEMENT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.DELETE_DEV_EMPLOYEMENT_SUCCESS,
          data: data?.id,
        });
        olibrToast.success(response?.data?.message);
      } else {
        yield put({
          type: types.DELETE_DEV_EMPLOYEMENT_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_DEV_EMPLOYEMENT_FAIL });
  }
}

export function* getDevprojectSaga({ navigate, data }) {
  try {
    yield put({ type: types.GET_DEV_PROJECT_START });
    const response = yield call(getDevProject, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_DEV_PROJECT_FAIL });
      olibrToast.error(response?.data?.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_DEV_PROJECT_SUCCESS, data: response?.data });
    }
  } catch (error) {
    yield put({ type: types.GET_DEV_PROJECT_FAIL });
  }
}
export function* saveDevprojectSaga({ navigate, data, setProject, resetForm }) {
  try {
    yield put({ type: types.SAVE_DEV_PROJECT_START });
    const response = yield call(saveDevProject, data);
    if (response.data.status === 401) {
      yield put({ type: types.SAVE_DEV_PROJECT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.SAVE_DEV_PROJECT_SUCCESS,
          data: response?.data,
        });

        // olibrToast.success(response?.data?.message);
        setProject({ image: [], skills: [], modal: false, edit: false });
        resetForm();
      } else {
        yield put({ type: types.SAVE_DEV_PROJECT_FAIL, data: response?.data });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.SAVE_DEV_PROJECT_FAIL });
  }
}
export function* updateDevprojectSaga({
  navigate,
  data,
  setProject,
  resetForm,
}) {
  try {
    yield put({ type: types.UPDATE_DEV_PROJECT_START });
    const response = yield call(updateDevProject, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_DEV_PROJECT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_DEV_PROJECT_SUCCESS,
          data: response?.data,
        });
        // olibrToast.success(response?.data?.message);
        setProject({ image: [], skills: [], modal: false, edit: false });
        resetForm();
      } else {
        yield put({
          type: types.UPDATE_DEV_PROJECT_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_DEV_PROJECT_FAIL });
  }
}
export function* deleteDevprojectSaga({ navigate, data }) {
  try {
    yield put({ type: types.DELETE_DEV_PROJECT_START });
    const response = yield call(deleteDevProject, data);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_DEV_PROJECT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.DELETE_DEV_PROJECT_SUCCESS,
          data: data?.id,
        });
        // const getResponse = yield call(getDevProject);
        // yield put({ type: types.GET_DEV_PROJECT_SUCCESS, data: getResponse?.data });
        olibrToast.success(response?.data?.message);
      } else {
        yield put({
          type: types.DELETE_DEV_PROJECT_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_DEV_PROJECT_FAIL });
  }
}

export function* getAllToolSaga({ navigate }) {
  try {
    yield put({ type: types.GET_ALL_TOOLS_START });
    const response = yield call(getDevAllTools);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ALL_TOOLS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_ALL_TOOLS_SUCCESS, data: response?.data });
    }
  } catch (error) {
    yield put({ type: types.GET_ALL_TOOLS_FAIL });
  }
}

export function* removeDevProjectImage() {
  yield put({ type: types.REMOVE_DEV_PROJECT_IMAGE_SUCCESS });
}

export function* getDevToolsSaga({ navigate }) {
  try {
    yield put({ type: types.GET_DEV_TOOLS_START });
    const response = yield call(getDevTools);
    if (response.data.status === 401) {
      yield put({ type: types.GET_DEV_TOOLS_FAIL });
      olibrToast.error(response?.data?.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_DEV_TOOLS_SUCCESS, data: response?.data });
    }
  } catch (error) {
    yield put({ type: types.GET_DEV_TOOLS_FAIL });
  }
}

export function* saveDevToolsSaga({ navigate, data, setDetails, resetForm }) {
  try {
    yield put({ type: types.SAVE_DEV_TOOLS_START });
    const response = yield call(saveDevTools, data);
    if (response.data.status === 401) {
      yield put({ type: types.SAVE_DEV_TOOLS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({ type: types.SAVE_DEV_TOOLS_SUCCESS, data: response?.data });
        // olibrToast.success(response?.data?.message);
        resetForm();
        setDetails({ modal: false, edit: false });
      } else {
        yield put({ type: types.SAVE_DEV_TOOLS_FAIL, data: response?.data });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.SAVE_DEV_TOOLS_FAIL });
  }
}

export function* updateDevToolsSaga({ navigate, data, setDetails, resetForm }) {
  try {
    yield put({ type: types.UPDATE_DEV_TOOLS_START });
    const response = yield call(updateDevTools, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_DEV_TOOLS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_DEV_TOOLS_SUCCESS,
          data: response?.data,
        });
        resetForm();
        setDetails({ modal: false, edit: false });
        // olibrToast.success(response?.data?.message);
      } else {
        yield put({ type: types.UPDATE_DEV_TOOLS_FAIL, data: response?.data });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_DEV_TOOLS_FAIL });
  }
}

export function* deleteDevToolsSaga({ navigate, data }) {
  try {
    yield put({ type: types.DELETE_DEV_TOOLS_START });
    const response = yield call(deleteDevTools, data);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_DEV_TOOLS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.DELETE_DEV_TOOLS_SUCCESS,
          data: data?.id,
        });
        olibrToast.success(response?.data?.message);
      } else {
        yield put({
          type: types.DELETE_DEV_TOOLS_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_DEV_TOOLS_FAIL });
  }
}

export function* getDevSkillsSaga({ navigate }) {
  try {
    yield put({ type: types.GET_DEV_SKILLS_START });
    const response = yield call(getDevSkills);
    if (response.data.status === 401) {
      yield put({ type: types.GET_DEV_SKILLS_FAIL });
      olibrToast.error(response?.data?.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_DEV_SKILLS_SUCCESS, data: response?.data });
    }
  } catch (error) {
    yield put({ type: types.GET_DEV_SKILLS_FAIL });
  }
}

export function* saveDevSkillsSaga({ navigate, data, setDetails, resetForm }) {
  try {
    yield put({ type: types.SAVE_DEV_SKILLS_START });
    const response = yield call(saveDevSkills, data);
    if (response.data.status === 401) {
      yield put({ type: types.SAVE_DEV_SKILLS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.SAVE_DEV_SKILLS_SUCCESS,
          data: response?.data,
        });
        // olibrToast.success(response?.data?.message);
        setDetails({
          modal: false,
          edit: false,
          skills: [],
        });
        resetForm();
      } else {
        yield put({ type: types.SAVE_DEV_SKILLS_FAIL, data: response?.data });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.SAVE_DEV_SKILLS_FAIL });
  }
}

export function* updateDevSkillsSaga({
  navigate,
  data,
  setDetails,
  resetForm,
}) {
  try {
    yield put({ type: types.UPDATE_DEV_SKILLS_START });
    const response = yield call(updateDevSkills, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_DEV_SKILLS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_DEV_SKILLS_SUCCESS,
          data: response?.data,
        });
        // olibrToast.success(response?.data?.message);
        setDetails({
          modal: false,
          edit: false,
          skills: [],
        });
        resetForm();
      } else {
        yield put({ type: types.UPDATE_DEV_SKILLS_FAIL, data: response?.data });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_DEV_SKILLS_FAIL });
  }
}

export function* deleteDevSkillsSaga({ navigate, data }) {
  try {
    yield put({ type: types.DELETE_DEV_SKILLS_START });
    const response = yield call(deleteDevSkills, data);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_DEV_SKILLS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.DELETE_DEV_SKILLS_SUCCESS,
          data: data.id,
        });

        olibrToast.success(response?.data?.message);
      } else {
        yield put({
          type: types.DELETE_DEV_SKILLS_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_DEV_SKILLS_FAIL });
  }
}

export function* getDevAchivementSaga({ navigate }) {
  try {
    yield put({ type: types.GET_DEV_ACHIEVEMENT_START });
    const response = yield call(getDevAchievement);
    if (response.data.status === 401) {
      yield put({ type: types.GET_DEV_ACHIEVEMENT_FAIL });
      olibrToast.error(response?.data?.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_DEV_ACHIEVEMENT_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_DEV_ACHIEVEMENT_FAIL });
  }
}

export function* saveDevAchivementSaga({
  navigate,
  data,
  setDetails,
  resetForm,
}) {
  try {
    yield put({ type: types.SAVE_DEV_ACHIEVEMENT_START });
    const response = yield call(saveDevAchievement, data);
    if (response.data.status === 401) {
      yield put({ type: types.SAVE_DEV_ACHIEVEMENT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.SAVE_DEV_ACHIEVEMENT_SUCCESS,
          data: response?.data,
        });
        // olibrToast.success(response?.data?.message);
        setDetails({
          modal: false,
          edit: false,
        });
        resetForm();
      } else {
        yield put({
          type: types.SAVE_DEV_ACHIEVEMENT_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.SAVE_DEV_ACHIEVEMENT_FAIL });
  }
}

export function* updateDevAchivementSaga({
  navigate,
  data,
  setDetails,
  resetForm,
}) {
  try {
    yield put({ type: types.UPDATE_DEV_ACHIEVEMENT_START });
    const response = yield call(updateDevAchievement, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_DEV_ACHIEVEMENT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_DEV_ACHIEVEMENT_SUCCESS,
          data: response?.data,
        });
        // olibrToast.success(response?.data?.message);
        setDetails({
          modal: false,
          edit: false,
        });
        resetForm();
      } else {
        yield put({
          type: types.UPDATE_DEV_ACHIEVEMENT_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_DEV_ACHIEVEMENT_FAIL });
  }
}

export function* deleteDevAchivementSaga({ navigate, data }) {
  try {
    yield put({ type: types.DELETE_DEV_ACHIEVEMENT_START });
    const response = yield call(deleteDevAchievement, data);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_DEV_ACHIEVEMENT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.DELETE_DEV_ACHIEVEMENT_SUCCESS,
          data: data?.id,
        });
        olibrToast.success(response?.data?.message);
      } else {
        yield put({
          type: types.DELETE_DEV_ACHIEVEMENT_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_DEV_ACHIEVEMENT_FAIL });
  }
}

export function* updateDevShortDescSaga({ navigate, data, closeModal }) {
  try {
    yield put({ type: types.UPDATE_DEV_DESCRIPTION_START });
    const response = yield call(updateDevShortDesc, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_DEV_DESCRIPTION_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_DEV_DESCRIPTION_SUCCESS,
          data: data,
        });
        olibrToast.success(response?.data?.message);
        closeModal();
      } else {
        yield put({
          type: types.UPDATE_DEV_DESCRIPTION_FAIL,
          data: response?.data,
        });
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_DEV_DESCRIPTION_FAIL });
  }
}

export function* approvalUpdateSaga({ navigate, data }) {
  try {
    yield put({ type: types.APPROVAL_UPDATE_START });
    const response = yield call(approvalUpdateApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.APPROVAL_UPDATE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        olibrToast.success(response?.data?.message);
        yield put({
          type: types.GET_APPROVAL_UPDATE_SUCCESS,
          data: response?.data,
        });
        yield put({ type: types.APPROVAL_UPDATE_SUCCESS });
      } else {
        yield put({ type: types.APPROVAL_UPDATE_FAIL, data: response?.data });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.APPROVAL_UPDATE_FAIL });
  }
}

export function* getApprovalUpdateSaga({ navigate, email }) {
  console.log("hitttt approval");
  try {
    yield put({ type: types.GET_APPROVAL_UPDATE_START });
    const response = yield call(getApprovalUpdateApi, email);
    if (response.data.status === 401) {
      yield put({ type: types.GET_APPROVAL_UPDATE_FAIL });
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.GET_APPROVAL_UPDATE_SUCCESS,
          data: response?.data,
        });
      } else {
        yield put({ type: types.GET_APPROVAL_UPDATE_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.GET_APPROVAL_UPDATE_FAIL });
  }
}

export function* getCurrentEmploymentSaga({ navigate }) {
  try {
    const response = yield call(getCurrentemployment);
    if (response.data.status === 401) {
      olibrToast.error(response?.data?.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_CURRENT_EMPLOYMENT_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* updatePageTourSaga({ navigate, setPage }) {
  const response = yield call(updatePageTour);
  if (response.data.status === 401) {
    olibrToast.error(response?.data?.message);
    navigate("/login");
  } else {
    if (response?.data?.statusValue == 200) {
      yield put({
        type: types.UPDATE_PAGE_TOUR_SUCCESS,
      });
      setPage({ open: false, module: "" });
    }
  }
}

export function* fetchReportSaga({ navigate, data, setModal }) {
  try {
    yield put({
      type: types.FETCH_REPORT_START,
    });
    const response = yield call(fetchReportApi, data);
    if (response.data.status === 401) {
      olibrToast.error(response?.data?.message);
      yield put({
        type: types.FETCH_REPORT_FAIL,
      });
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.FETCH_REPORT_SUCCESS,
          data: response?.data,
        });
        setModal(true);
      } else {
        yield put({
          type: types.FETCH_REPORT_FAIL,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({
      type: types.FETCH_REPORT_FAIL,
    });
  }
}

export function* saveJobFavouriteSaga({ navigate, id, userId, obj, setObj }) {
  try {
    yield put({
      type: types.SAVE_FAVOURITE_JOB_START,
    });
    const response = yield call(saveJobFavourit, id);
    if (response.data.status === 401) {
      olibrToast.error(response?.data?.message);
      navigate("/login");
    } else {
      let tmpArr = { ...obj };
      let singleObj = tmpArr?.data?.find((item) => item?._id == id);
      let index = tmpArr?.data?.findIndex((item) => item?._id == id);
      if (singleObj?.fevUsersIds?.includes(userId)) {
        let tempId = singleObj?.fevUsersIds?.filter((item) => item != userId);
        singleObj.fevUsersIds = tempId;
        tmpArr.data[index] = singleObj;
        olibrToast?.success("Job remove from favourite list");
      } else {
        singleObj?.fevUsersIds?.push(userId);
        tmpArr.data[index] = singleObj;
        olibrToast?.success("Job added in favourite list");
      }
      setObj(tmpArr);

      yield put({
        type: types.SAVE_FAVOURITE_JOB_SUCCESS,
        data: response?.data,
        id,
      });
    }
  } catch (error) {
    yield put({
      type: types.SAVE_FAVOURITE_JOB_FAIL,
    });
  }
}

export function* getFavouriteJobSaga({ navigate }) {
  try {
    yield put({
      type: types.GET_FAVOURITE_JOB_START,
    });
    const response = yield call(getFavJob);
    if (response.data.status === 401) {
      olibrToast.error(response?.data?.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_FAVOURITE_JOB_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_FAVOURITE_JOB_FAIL,
    });
  }
}

export function* updateBasicDetailsSaga({ navigate, data, closeModal }) {
  try {
    yield put({
      type: types.UPDATE_ADMIN_DEV_ACADEMIC_START,
    });
    const response = yield call(updateBasicDetails, data);
    if (response.data.status === 401) {
      olibrToast.error(response?.data?.message);
      navigate("/login");
    }
    if (response?.data?.statusValue === 200) {
      olibrToast.success("Data updated successfully");
      yield put({
        type: types.UPDATE_BASIC_DETAIL_SUCCESS,
        data,
      });
      closeModal();
    }
  } catch (error) {
    yield put({
      type: types.UPDATE_BASIC_DETAIL_FAIL,
    });
  }
}

export function* devActivationSaga({ navigate, data, userType }) {
  try {
    yield put({ type: types.DEV_ACTIVATION_START });
    const response = yield call(devActivation, data);
    if (response.data.status === 401) {
      olibrToast.error(response?.data?.message);
      navigate("/login");
    }
    if (userType == "client") {
      yield put({ type: types.CLIENT_ACTIVATION_SUCCESS, data });
    } else {
      yield put({ type: types.DEV_ACTIVATION_SUCCESS, data });
    }
  } catch (error) {
    yield put({ type: types.DEV_ACTIVATION_FAIL });
  }
}

export function* getInterviewsDataDevSaga({ navigate, filters }) {
  try {
    yield put({ type: types.GET_INTERVIEWS_DATA_DEV_START });
    const response = yield call(getScheduledInterviewsDev, filters);
    if (response?.data?.statusValue === 400) {
      olibrToast.error(response.data.message);
      yield put({ type: types.GET_INTERVIEWS_DATA_DEV_FAIL });
    } else if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.GET_INTERVIEWS_DATA_DEV_FAIL });
    } else {
      yield put({
        type: types.GET_INTERVIEWS_DATA_DEV_SUCCESS,
        data: response?.data?.interviewsData,
      });
    }
  } catch (error) {
    olibrToast.error(error?.message);
    yield put({ type: types.GET_INTERVIEWS_DATA_DEV_FAIL });
  }
}

export function* rescheduleInterviewByDevSaga({ id, navigate, interviewData, socket }) {
  try {
    yield put({ type: types.RESCHEDULE_INTERVIEW_START });
    const response = yield call(saveScheduleInterviewByDev, {
      id,
      interviewData,
    });
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.RESCHEDULE_INTERVIEW_FAIL });
    } else {
      if (response.data.statusValue === 400) {
        olibrToast.error(response.data.message);
        yield put({ type: types.RESCHEDULE_INTERVIEW_FAIL });
      } else {
        olibrToast.success(response.data.message);
        socket?.emit("interview-reschedule-request", {
          jobId: response.data.notiData.jobId,
          devId: response.data.notiData.devId,
          clientId: response.data.notiData.clientId
        });
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();
        yield put({
          type: types.GET_INTERVIEWS_DATA_DEV,
          navigate,
          filters: `?month=${currentMonth}&year=${currentYear}`,
        });
      }
    }
  } catch (error) {
    yield put({ type: types.RESCHEDULE_INTERVIEW_FAIL });
  }
}

export function* cancelInterviewByDevSaga({ id, navigate, interviewData, socket }) {
  try {
    yield put({ type: types.CANCEL_INTERVIEW_START });
    const response = yield call(cancelScheduleInterviewByDev, {
      id,
      interviewData,
    });
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.CANCEL_INTERVIEW_FAIL });
    } else {
      olibrToast.success(response.data.message);
      socket?.emit("interview-declined", {
        jobId: response.data.notiData.jobId,
        devId: response.data.notiData.devId,
        clientId: response.data.notiData.clientId
      });
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();
      yield put({
        type: types.GET_INTERVIEWS_DATA_DEV,
        navigate,
        filters: `?month=${currentMonth}&year=${currentYear}`,
      });
    }
  } catch (error) {
    yield put({ type: types.CANCEL_INTERVIEW_FAIL });
  }
}

export function* setInterviewSlotDevSaga({
  navigate,
  slot,
  onSlotAcceptingDone,
  socket
}) {
  try {
    yield put({ type: types.SET_INTERVIEWS_SLOT_DEV_START });
    const response = yield call(setInterviewSlotDev, slot);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      //navigate("/login");
      onSlotAcceptingDone();
      yield put({ type: types.SET_INTERVIEWS_SLOT_DEV_FAIL });
    } else {
      if (response.data.statusValue === 400) {
        olibrToast.error(response.data.message);
        yield put({ type: types.SET_INTERVIEWS_SLOT_DEV_FAIL });
      } else {
      olibrToast.success(response.data.message);
      socket?.emit("interview-request-accept", {
        jobId: response.data.notiData.jobId,
        devId: response.data.notiData.devId,
        clientId: response.data.notiData.clientId
      });
      onSlotAcceptingDone();
      yield put({
        type: types.SET_INTERVIEWS_SLOT_DEV_SUCCESS,
        data: null,
      });
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();
      yield put({
        type: types.GET_INTERVIEWS_DATA_DEV,
        navigate,
        filters: `?month=${currentMonth}&year=${currentYear}`,
      });
    }
    }
  } catch (error) {
    onSlotAcceptingDone();
    yield put({ type: types.SET_INTERVIEWS_SLOT_DEV_FAIL });
  }
}
export function* getActivationSaga({ navigate }) {
  try {
    yield put({ type: types.GET_DEV_EMAIL_AUTO_START });
    const response = yield call(getDevActivation);
    if (response.data.status === 401) {
      olibrToast.error(response?.data?.message);
      navigate("/login");
    }
    if (response?.status === 200) {
      yield put({
        type: types.GET_DEV_EMAIL_AUTO_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_DEV_EMAIL_AUTO_FAIL });
  }
}
