import React, { Fragment } from "react";
import AccordionFAQ from "../faqs/Accordion";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";

const Skill = () => {
  const { skillWithCategory, isSkillWithCat } = useSelector(
    (state) => state?.adminReducer
  );

  const [titleOpen, setTitleOpen] = useState("");
  const [allLists, setAllLists] = useState([]);

  // useEffect(() => {
  //   let others = [];
  //   let withoutCat = [];
  //   skillWithCategory?.map((itm, index) => {
  //     let cat = Object?.keys(itm)?.[0];
  //     if (cat?.toLocaleLowerCase()?.includes("other")) {
  //       others = itm;
  //     } else {
  //       withoutCat.push(itm);
  //     }
  //   });
  //   withoutCat.push(others);
  //   setAllLists(withoutCat);
  // }, [skillWithCategory]);
  // const arr = [0, 1];

  useEffect(() => {
    let others = [];
    let withoutCat = [];
    skillWithCategory?.map((itm, index) => {
      let cat = Object?.keys(itm)?.[0];
      if (cat?.toLocaleLowerCase()?.includes("other")) {
        others = itm;
      } else {
        withoutCat.push(itm);
      }
    });
    if (Object?.keys(others)?.length) {
      withoutCat.push(others);
    }
    setAllLists(withoutCat);
  }, [skillWithCategory]);
  const arr = [0, 1];

  const Loader = () => (
    <div style={{ textAlign: "center" }}>
      {" "}
      <ClipLoader color="#3BA0AA" height={10} />
    </div>
  );
  return (
    <Fragment>
      {isSkillWithCat ? (
        <Loader />
      ) : (
        <div className="row">
            <div className="col-12">
              {Array.isArray(allLists) &&
                allLists
                  ?.map((item) => (
                    <>
                      <div key={item?._id}>
                        <div>
                          <div
                            className="faq-heading"
                          >
                            {!!Object?.keys(item)?.[0] &&
                              Object?.keys(item)?.[0]}
                          </div>
                          
                            {item[Object?.keys(item)?.[0]]?.map(
                              (items, index) => (
                                <>
                                <div className="accordion-faq">
                                    <AccordionFAQ
                                      key={items?._id}
                                      title={items?.skillName}
                                      content={items?.description}
                                      setTitleOpen={setTitleOpen}
                                      titleOpen={titleOpen}
                                      image={items?.skillTestIcon}
                                      fullWidth={true}
                                    />
                                    </div>
                                  </>
                                )
                              )}
                        </div>
                      </div>
                      {/* )} */}
                    </>
                  ))}
            </div>
        </div>
      )}
    </Fragment>
  );
};

export default Skill;
