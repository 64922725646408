import React from 'react'
import CommentLists from './CommentLists'
import { useState } from 'react'
import { olibrToast } from '../../../../components/toaster'

const CommentsC = ({ commentsList, comments, setComments, updateComment, addComments, deleteComment, viewOnly = false }) => {
    const [newComment, setNewComment] = useState(false)
    const [erorrs, setErrors] = useState(false)
    const handleAddMore = () => {
        if (!newComment) {
            setNewComment(true)
            return false;
        } else if (!comments?.current) {
            setErrors(true)
            return false;
        }
        let checkExist = comments?.all?.find((itm) => itm?.message == comments?.current)
        if (checkExist) {
            olibrToast.error("Already message exists")
            return false;
        }
        setErrors(false)
        addComments()
    }

    const updateMessage = () => {
        if (!comments?.current) {
            setErrors(true)
            return false;
        } else {
            setErrors(false)
        }
        let checkExist = comments?.all?.find((itm) => itm?.message == comments?.current)
        if (checkExist) {
            olibrToast.error("Already message exists")
            return false;
        }
        updateComment()

    }
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <CommentLists deleteComment={deleteComment} commentsList={commentsList} comments={comments} setComments={setComments} setNewComment={setNewComment} />
                </div>

                {

                    (!!newComment || !commentsList?.length) && (
                        <>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className={`floating-label ${erorrs && "text-danger"}`}>Message</label>

                                    <textarea
                                        type="text"
                                        rows={5}
                                        className="form-control"
                                        value={comments?.current}
                                        disabled={viewOnly}
                                        onChange={(e) => setComments({ ...comments, current: e.target.value })}
                                    />
                                </div>
                            </div>

                        </>
                    )

                }
                {
                    !viewOnly && (
                        <div className="col-12 text-right">
                            <br />
                            {
                                comments?._id && (
                                    <button
                                        className="submitBtn sm-btn-candidates"
                                        onClick={updateMessage}
                                    >
                                        Update
                                    </button>
                                )
                            }


                            <button
                                className="submitBtn sm-btn-candidates"
                                onClick={handleAddMore}
                            >
                                Add
                            </button>

                        </div>
                    )
                }




            </div>


        </>
    )
}

export default CommentsC