import React, { Fragment, createRef, useContext, useEffect, useState } from "react";
import "./CompanyProfile.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAllPerkAndBenefit } from "../../service/action/admin";
import {
  getCompanyProfile,
  saveCompanyProfile,
  updateVisitFirstCompnyProfile,
} from "../../service/action/client";
import { useForm } from "react-hook-form";
import { useRef } from "react";
import { uploadImage } from "../../service/action/developer";
import { MainContext } from "../../context/MainContext";
import { regexPattern } from "../developer/profile/SocialRegexPattern";
import { olibrToast } from "../../components/toaster";
import { ClipLoader } from "react-spinners";
import CompanyProfile from "./CompanyProfile";
import Modal from "../../components/modal/ModalBox";
import { companyProfile } from "../common/finalizeResume/constant/pageTourConstant";
import { getCity, getState, uploadMultiImageAction } from "../../service/action/common";
import ManagementTeam from "./editable/ManagementTeam";
import PerkAndBenefit from "./editable/PerkAndBenefit";
import ImageAndGallery from "./editable/ImageAndGallery";
import SocialMediaField from "./editable/SocialMediaField";
import BasicDetailsForm from "./editable/BasicDetailsForm";
const CompanyProfileDetail = ({ hideTour = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const { perkAndBenefit } = useSelector((state) => state?.adminReducer);

  const { clientProfile, draftLoading, publishLoading } = useSelector(
    (state) => state?.clientReducer
  );
  const {
    companyLogos,
    memberImage,
    isLogoLoading,
    devProjectImage
  } = useSelector((state) => state?.developerReducer);
  const [publish, setPublish] = useState({
    open: false,
    first: true,
  });
  const [preview, setPreview] = useState({
    data: {},
    preview: false,
  });
  const { user } = useContext(MainContext);

  const { register, getValues, handleSubmit, setValue, watch, control } = useForm();

  useEffect(() => {
    dispatch(getAllPerkAndBenefit(navigate));
    dispatch(getCompanyProfile(navigate, clientId));
  }, [clientId]);
  useEffect(() => {
    fetchStates("IN");
  }, [])

  useEffect(() => {
    if (user?.clientCurrentRole && user?.clientCurrentRole !== "admin") {
      navigate("/client/client-dashboard");
    }
  }, [user]);

  const [perks, setPerks] = useState([]);
  const [managementTeam, setManagementTeam] = useState([
    {
      memberName: "",
      memberDesg: "",
      memberImage: "",
      linkedInUrl: "",
    },
  ]);
  const [companyLogo, setcompanyLogo] = useState("");

  const [companyGallery, setCompanyGallery] = useState([]);
  const [socialMediaLinks, setSocialMediaLinks] = useState({});
  const [imgIndex, setImgIndex] = useState(-1);
  const [errors, setError] = useState([]);
  const [pageTour, setPageTour] = useState("");
  const [adresses, setCompanyAdress] = useState([{
    state: "",
    city: ""
  }])
  const [awardRecognitions, setAwardRecognitions] = useState([{
    awardImg: "",
    title: ""
  }])
  const fetchCities = (stateCode) => {
    dispatch(getCity({ countryCode: "IN", stateCode: stateCode }, navigate));
  };
  const handleStateChange = (e, index) => {
    let tempState = [...adresses];
    tempState[index].state = e?.value;
    tempState[index].city = ""
    fetchCities(e?.value);
    setCompanyAdress(tempState)
  }





  const commonObj = (data, draftPublish) => {
    return {
      ...data,
      companyLogo,
      perks,
      managementTeam,
      companyGallery: companyGallery?.filter((item) => item),
      socialMediaLinks,
      status: draftPublish,
    };
  };

  const socialLinkValid = (platform, link) => {
    if (regexPattern[platform].test(link) || link == "" || link == undefined) {
      regexPattern[platform].test(link);
      return true;
    } else {
      link && olibrToast.error(`Please Enter a Valid Link for ${platform}!!!`);
      return false;
    }
  };

  const checkSocialErro = () => {
    let linkdin = socialLinkValid("linkedin", socialMediaLinks?.linkedIn);
    let facebook = socialLinkValid("facebook", socialMediaLinks?.facebook);
    let twitter = socialLinkValid("twitter", socialMediaLinks?.twitter);
    let instagram = socialLinkValid("instagram", socialMediaLinks?.instagram);
    let youtube = socialLinkValid("youtube", socialMediaLinks?.youtube);
    if (linkdin && facebook && twitter && instagram && youtube) {
      return true;
    }
  };

  const fetchStates = (countryCode) => {
    dispatch(getState({ countryCode }, navigate));
  };

  const removeError = (errName) => {
    let tempError = errors?.filter((item) => item != errName);
    setError(tempError);
  };
  const checkTeamManagement = () => {
    let errorLists = [];
    if (!managementTeam?.length) {
      return [];
    }
    if (!managementTeam[0].memberName) {
      errorLists?.push("memberName");
    }
    if (!managementTeam[0].memberDesg) {
      errorLists?.push("memberDesg");
    }
    if (!managementTeam[0].memberImage) {
      errorLists?.push("memberImage");
    }
    if (!socialLinkValid("linkedin", managementTeam[0].linkedInUrl)) {
      errorLists?.push("teamLinkedIn");
    }
    return errorLists;
  };

  const saveAsDraft = (data) => {
    if (checkSocialErro()) {
      let number=data?.number?.slice(-10)
      dispatch(saveCompanyProfile(navigate, commonObj({
        ...data, location: adresses,
        number,
        companySize: data?.companySize?.label,
        awardAndRecognition: awardRecognitions
      }, "draft"), clientId));
      setError([]);
    }
  };
  const publishProfile = (data) => {
    let errorLists = [];
    if (!companyLogo) {
      errorLists?.push("companyLogo");
    }
    if (!data?.companyAddress) {
      errorLists?.push("companyAddress");
    }
    if (!data?.aboutCompany) {
      errorLists?.push("aboutCompany");
    }
    if (!data?.aboutCompany) {
      errorLists?.push("aboutCompany");
    }
    if (
      !data?.companyWebsite ||
      !socialLinkValid("website", data?.companyWebsite)
    ) {
      errorLists?.push("companyWebsite");
    }
    if (!data?.companyTitle) {
      errorLists?.push("companyTitle");
    }
    if(data?.employeeRating){
      socialLinkValid("glassdoor",data?.employeeRating)
    }
    let tempError = checkTeamManagement();

    setError([...tempError, ...errorLists]);
    let number=data?.number?.slice(-10)
    if ([...tempError, ...errorLists]?.length) {
      return false;
    } else {
      if (checkSocialErro()) {
        dispatch(saveCompanyProfile(navigate, commonObj({
          ...data,
          number,
          location: adresses,
          companySize: data?.companySize?.label,
          awardAndRecognition: awardRecognitions
        }, "publish"), clientId));
      }
    }
  };

  useEffect(() => {
    if (clientProfile && Object?.keys(clientProfile)?.length) {
      setPerks(clientProfile?.perks);
      setCompanyGallery(clientProfile?.companyGallery);
      setSocialMediaLinks(clientProfile?.socialMediaLinks);
      setValue("companyWebsite", clientProfile?.companyWebsite);
      setValue("aboutCompany", clientProfile?.aboutCompany);
      setValue("companyTitle", clientProfile?.companyTitle);
      setValue("companyAddress", clientProfile?.companyAddress);
      setValue("_id", clientProfile?._id);
      setValue("coverTemplate", clientProfile?.coverTemplate);
      setValue("clientCompanyName", clientProfile?.clientCompanyName);
      clientProfile?.foundedIn && setValue("foundedIn", new Date(clientProfile?.foundedIn));
      setValue("companySize", clientProfile?.companySize);
      setValue("industryDomain", clientProfile?.industryDomain);
      setValue("employeeRating", clientProfile?.employeeRating)
      if(clientProfile?.awardAndRecognition?.length) {
        setAwardRecognitions(clientProfile?.awardAndRecognition)
      }
      setCompanyAdress(clientProfile?.location)
      setcompanyLogo(clientProfile?.companyLogo);
      let tempTeam = [];
      clientProfile?.managementTeam?.map((item) => {
        tempTeam?.push({
          memberName: item?.memberName,
          memberDesg: item?.memberDesg,
          memberImage: item?.memberImage,
          linkedInUrl: item?.linkedInUrl,
        });
      });
      setManagementTeam(tempTeam);
      if (clientProfile?.status == "publish" && publish?.first) {
        setPublish({ open: true, first: false });
      }

      if (clientProfile?.clients) {
        setValue("number","+"+clientProfile?.clients[0]?.devCountryCode+" " + clientProfile?.clients[0]?.number);
      }
    }
  }, [clientProfile]);

  useEffect(() => {
    setValue("clientCompanyName", user?.clientCompanyName);
    let tempNumber=user?.number?"+"+user?.devCountryCode+" "+ user?.number:""
    setValue("number",tempNumber);
    if (!user?.clientVisitCompanyFirst && !hideTour) {
      setPageTour("banner");
    } else {
      setPageTour("");
    }
  }, [user]);


  useEffect(() => {
    setcompanyLogo(companyLogos);
  }, [companyLogos]);

  const checkSelected = (id) => {
    return perks?.includes(id) ? "selected" : "";
  };



  const uploadImages = async (e, forType, indexNo = -1) => {
    if (forType == "memberImage") {
      setImgIndex(indexNo);
    }
    if (forType == "gallery") {
      let formData = new FormData();
      for (let i = 0; i < e?.target?.files?.length; i++) {
        formData.append("imageFiles", e.target.files[i]);
      }
      dispatch(uploadMultiImageAction(formData, navigate))
    } else {
      let formData = new FormData();
      formData.append("imageFile", e.target.files[0]);
      if (forType == "logo") {
        dispatch(uploadImage(formData, companyLogo, navigate, forType, indexNo));
      } else if (forType == "awardImage") {
        dispatch(uploadImage(formData, "", navigate, forType, indexNo));
      } else {
        dispatch(uploadImage(formData, managementTeam[indexNo]?.memberImage, navigate, forType, indexNo));
      }
    }
    e.target.value = null;
  };
 
  useEffect(() => {
    removeError("companyLogo");
  }, [companyLogo]);

  useEffect(() => {
    removeError("memberImage");
  }, [memberImage]);
  useEffect(() => {
    setCompanyGallery([...companyGallery, ...devProjectImage])
  }, [devProjectImage]);

  const handleSocialChange = (e) => {
    setSocialMediaLinks({
      ...socialMediaLinks,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (Object?.keys(memberImage)?.length) {
      let clone = [...managementTeam];
      let obj = clone[memberImage?.index];
      obj.memberImage = memberImage?.image;
      setManagementTeam([...clone]);
    }
  }, [memberImage]);

  const removeGallery = (img) => {
    let tempImg = companyGallery?.filter((item) => item != img);
    setCompanyGallery(tempImg);
  };

  const handlePerk = (id) => {
    let tempPerk = perks?.length ? [...perks] : [];
    if (tempPerk?.includes(id)) {
      setPerks(tempPerk?.filter((item) => item != id));
    } else {
      perks?.length ? setPerks([...perks, id]) : setPerks([id]);
    }
  };

  const handlePreview = (data) => {
    if (preview?.preview) {
      setPreview({
        data: {},
        preview: false,
      });
    } else {
      setPreview({
        data: commonObj({...data,location: adresses,
          companySize: preview?.companySize?.label,
          awardAndRecognition: awardRecognitions}, "publish"),
        preview: true,
      });
    }
  };

  const galleryImage = useRef();
  const companyLogoImg = useRef();
  const [memberImg, setElRefs] = useState([]);
  useEffect(() => {
    setElRefs((elRefs) =>
      Array(managementTeam.length)
        .fill()
        .map((_, i) => elRefs[i] || createRef()),
    );
  }, [managementTeam]);
  const coverTemplate = watch("coverTemplate");

  const skipPageTour = () => {
    dispatch(updateVisitFirstCompnyProfile());
    setPageTour("");
  };


  return (
    <div className="shortlisted-wrap dash-block dash-placement">
       <h3 className="skillmh ">
       Your company page is visible to all developers and the public. It's crucial to ensure it's exceptionally well-crafted and represents your brand effectively.{" "}
          </h3>
      <div className="shortlisted-block card">
        {publish?.open ? (
          <CompanyProfile
            profileData={preview?.data}
            userType={"client"}
            publish={publish}
            setPublish={setPublish}
          />
        ) : (
          <Fragment>
            <div className="App">
              <Modal
                onClose={() => setPreview({ preview: false, data: {} })}
                open={preview?.preview}
                title={"Company Profile Preview"}
                size={"100%"}
                content={() => (
                  <CompanyProfile
                    profileData={preview?.data}
                    userType={"client"}
                  />
                )}
                isFooterView={false}
              />
              <div className="wrap">
                <div className="main-div">
                  <div className="main-div-developer">
                    <div className="create-profile company-profile">
                      <form>
                        <BasicDetailsForm
                          adresses={adresses}
                          register={register}
                          errors={errors}
                          companyLogo={companyLogo}
                          companyLogoImg={companyLogoImg}
                          uploadImages={uploadImages}
                          isLogoLoading={isLogoLoading}
                          pageTour={pageTour}
                          companyProfile={companyProfile}
                          setPageTour={setPageTour}
                          skipPageTour={skipPageTour}
                          control={control}
                          handleStateChange={handleStateChange}
                          setCompanyAdress={setCompanyAdress}
                          fetchCities={fetchCities}
                          coverTemplate={coverTemplate}
                          imgIndex={imgIndex}
                          awardRecognitions={awardRecognitions}
                          setAwardRecognitions={setAwardRecognitions}
                          setValue={setValue}
                          watch={watch}
                          clientProfile={clientProfile}
                        />

                        <hr className="hr" />
                        <PerkAndBenefit
                          pageTour={pageTour}
                          setPageTour={setPageTour}
                          perkAndBenefit={perkAndBenefit}
                          checkSelected={checkSelected}
                          skipPageTour={skipPageTour}
                          handlePerk={handlePerk}
                        />

                        <hr className="hr" />
                        <ManagementTeam
                          pageTour={pageTour}
                          managementTeam={managementTeam}
                          skipPageTour={skipPageTour}
                          companyProfile={companyProfile}
                          setPageTour={setPageTour}
                          errors={errors}
                          memberImg={memberImg}
                          removeError={removeError}
                          uploadImages={uploadImages}
                          imgIndex={imgIndex}
                          setManagementTeam={setManagementTeam}
                          checkTeamManagement={checkTeamManagement}
                          setError={setError}
                        />

                        <hr className="hr" />

                        <ImageAndGallery
                          pageTour={pageTour}
                          companyProfile={companyProfile}
                          setPageTour={setPageTour}
                          skipPageTour={skipPageTour}
                          galleryImage={galleryImage}
                          uploadImages={uploadImages}
                          companyGallery={companyGallery}
                          removeGallery={removeGallery}
                        />
                        <hr className="hr" />
                        <SocialMediaField
                          pageTour={pageTour}
                          setPageTour={setPageTour}
                          skipPageTour={skipPageTour}
                          socialMediaLinks={socialMediaLinks}
                          companyProfile={companyProfile}
                          handleSocialChange={handleSocialChange}
                          register={register}
                        />

                        <div className="row">
                          <div className="col-12">
                            <div className="btn-block justify-content-right">
                              {clientProfile?.status != "publish" && (
                                <button
                                  className="btn mw130"
                                  onClick={handleSubmit(saveAsDraft)}
                                >
                                  {draftLoading ? (
                                    <ClipLoader size={15} color="#FFFFFF" />
                                  ) : (
                                    "Save as draft"
                                  )}
                                </button>
                              )}

                              <button
                                className="btn mw130"
                                onClick={handleSubmit(publishProfile)}
                              >
                                {publishLoading ? (
                                  <ClipLoader size={15} color="#FFFFFF" />
                                ) : clientProfile?.status == "publish" ? (
                                  "Update"
                                ) : (
                                  "Publish"
                                )}
                              </button>
                              <button
                                className="btn mw130"
                                onClick={handleSubmit(handlePreview)}
                              >
                                Profile Preview
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default CompanyProfileDetail;
