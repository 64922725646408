import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatIndianNumber } from "../functions";
import {
  startPaymentPhonePay,
  startPaymentStripe,
} from "../../service/action/common";
import { getUser } from "../../service/action/authentication";
import { MainContext } from "../../context/MainContext";
import ClipLoaderComp from "../clipLoader/ClipLoader";
import DateFormate from "../../common/DateFormate";

const PaymentOverview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checkoutData, setCheckoutData] = useState();
  const { isStartPayment } = useSelector((state) => state.commonReducer);
  const { user } = useContext(MainContext);
  const callBack = (res) => {
    if (res?.redirectUrl) {
      window.open(res?.redirectUrl, "_self");
    }
  };

  const continuePayment = () => {
    if (user?.region == "IN") {
      dispatch(startPaymentPhonePay(navigate, checkoutData?.payload, callBack));
    } else {
      dispatch(startPaymentStripe(navigate, checkoutData?.payload, callBack));
    }
  };

  useEffect(() => {
    let checkoutData = localStorage.getItem("checkout");
    if (checkoutData) {
      setCheckoutData(JSON.parse(checkoutData));
    } else {
      navigate("/pricing");
    }
    dispatch(getUser(navigate));
  }, []);

  useEffect(() => {
    console.log("isStartPayment", isStartPayment);
  }, [isStartPayment]);
  return (
    <div className="payment-overview">
      <div className="subscription-plan">
        <img
          src="https://ik.imagekit.io/olibr/assets/tr:w-150,h-49/logo.png"
          alt="Olibr logo"
        />
        <div id="account-setting">
          <div className="setting-card">
            <h3>OLIBR SUBSCRIPTION</h3>
            <hr></hr>
            <div className="detailed-info">
              <div className="table-container">
                <table className="payment-details">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Qty</th>
                      <th className="text-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!checkoutData?.data?.subscriptionName &&<tr>
                      <td>
                        {checkoutData?.data?.subscriptionName}
                        <span style={{ fontSize: "11px", marginLeft: "10px" }}>
                          <b>Valid From</b>{" "}
                          {DateFormate(
                            checkoutData?.data?.subscriptionStartDate
                          )}
                          <b> To </b>
                          {DateFormate(checkoutData?.data?.subscriptionEndDate)}
                        </span>
                        <br />
                        {checkoutData?.data?.message && (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: checkoutData?.data?.message,
                            }}
                          ></span>
                        )}
                      </td>
                      <td>1</td>
                      <td className="text-right">
                        {checkoutData?.data?.currency}{" "}
                        {formatIndianNumber(
                          checkoutData?.data?.subscriptionCost
                        )}
                      </td>
                    </tr>}
                    {checkoutData?.data?.addonPurchaseDetails?.map(
                      (item, index) => (
                        <tr key={index}>
                          <td>Add Custom Test</td>

                          <td>{item?.addonQuantity}</td>
                          <td className="text-right">
                            {checkoutData?.data?.currency}{" "}
                            {formatIndianNumber(item?.addonCost)}
                          </td>
                        </tr>
                      )
                    )}
                    <tr className="bold">
                      <td>Tax GST(18%)</td>
                      <td></td>
                      <td className="text-right">
                        {checkoutData?.data?.currency}{" "}
                        {formatIndianNumber(checkoutData?.data?.taxAmount) || 0}
                      </td>
                    </tr>
                    <tr className="bold">
                      <td>Total Amount</td>
                      <td></td>
                      <td className="text-right">
                        {checkoutData?.data?.currency}{" "}
                        {formatIndianNumber(checkoutData?.data?.finalAmount)}
                      </td>
                    </tr>

                    <tr className="spacer">
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr className="total-amount">
                      <td>Amount To be paid now</td>
                      <td></td>
                      <td className="text-right">
                        {checkoutData?.data?.currency}{" "}
                        {formatIndianNumber(checkoutData?.data?.finalAmount)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <ul>
                <li>Amount calculated on Pro-rata basis</li>
                <li>
                  Tax mentioned may vary. The final tax amount will be reflected
                  in your invoice.
                </li>
              </ul>
              <h3 className="skillmh">
                You will be redirected to our secured payment gateway where you
                can complete your transaction
              </h3>
              <div className="flex-group flex-end">
                <button className="btn btn-orange" onClick={() => navigate(-1)}>
                  Cancel
                </button>
                <button
                  disabled={isStartPayment}
                  className="btn"
                  onClick={continuePayment}
                >
                  Proceed To Pay
                  {isStartPayment && (
                    <ClipLoaderComp color={"#fff"} size={14} />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentOverview;
