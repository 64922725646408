import { Fragment } from "react";
import ViewAllJobs from "../../client/jobs/ViewAllJobs";

const PostedJobs = ({ }) => {
  return (
    <Fragment>
        <ViewAllJobs/>
    </Fragment>
  );
};

export default PostedJobs;
