import React from "react";
import { Icon } from "../../../../components/icon";
import { getCountryName } from "../../../../components/functions";

const ColumnAddOn = (changeStatus,handleEdit) => {
  const columns = [
    {
      name: "S.No",
      selector: "sNo",
      width: "97px",
      cell: (_, index) => index + 1,
    },
   
    {
      name: "Add-ons  Name",
      selector: "name",
      sortable: true,
      width:"300px",
      cell: (row) => (
        <div>
          <span className="link">{row?.name}</span> <br/>
          <span>{row?.description}</span>
        </div>
      ),
    },
    {
        name: "Price",
        selector: "price",
        width: "97px",
        cell: (row) => <span>{row?.currency+""+row?.price}</span>
      },
      {
        name: "Country Name",
        selector: "country",
        cell: (row) => <span>{getCountryName(row?.country)}</span>,
      },
    {
      name: "Status",
      selector: "active",
      sortable: true,
      cell: (row) => (
        <span>
          <input
            type="checkbox"
            name="switch"
            onChange={(e) => e.preventDefault()}
            checked={row?.isActive}
          />
          <label
            onClick={(e) => {
              e.stopPropagation();
              changeStatus(row);
            }}
            className="label"
            htmlFor="switch3"
          ></label>
        </span>
      ),
    },
    {
      name: "Action",
      sortable: true,
      width: "140px",
      cell: (row) => (
        <span onClick={(e)=>handleEdit(e,row)}>
          <Icon name="edit" />
        </span>
      ),
    },
  ];
  return columns;
};

export default ColumnAddOn;
