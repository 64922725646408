import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import UserAvitar from "./../../../assets/img/company-profile/company-member-1.jpg";
import { scheduleInterview } from "../../../service/action/client";
import { Icon } from "../../../components/icon";
import { olibrToast } from "../../../components/toaster";

export default function ScheduleInterview() {
  const formDataInit = {
    interviewLink: "",
    message: "",
    requiredAttendence: [],
    interviewData: [
      {
        id: 1,
        from: new Date(new Date().getTime() + 150 * 60000),
        to: new Date(new Date().getTime() + 180 * 60000),
      },
    ],
  };
  const navigate = useNavigate();
  const { socket } = useSelector((state) => state.commonReducer);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [formData, setFormData] = useState(formDataInit);
  const [interviewType, setInterviewType] = useState("regular");
  const [attendence, setAttendence] = useState();

  useEffect(() => {
    setData(state?.jobData);
  }, [state]);

  const checkIcon = (subject) => {
    let checkIcon =
      state?.clientSkillList?.find((item) => item?._id == subject) ||
      state?.clientStackList?.find((item) => item?._id == subject);
    return checkIcon?.skillTestIcon || checkIcon?.stackIcon;
  };

  const checkSkillStackIcon = (data) => {
    return data?.map(
      (item, index) =>
        item?.isPassed && (
          <li key={index}>
            <a>
              {item?.subject && (
                <img src={checkIcon(item?.subject)} alt="AWS Icon" />
              )}
              {item?.testName}
            </a>
          </li>
        )
    );
  };

  const addInterviewData = () => {
    let max = 0;
    formData.interviewData.map((i) => {
      if (i.id > max) max = i.id;
    });
    let copy = { ...formData };
    copy.interviewData.push({
      id: max + 1,
      from: new Date(
        new Date(formData.interviewData[max - 1].from).getTime() + 30 * 60000
      ),
      to: new Date(
        new Date(formData.interviewData[max - 1].to).getTime() + 30 * 60000
      ),
    });
    setFormData(copy);
  };

  const subtractInterviewData = (id) => {
    if (formData?.interviewData?.length > 1) {
      let copy = { ...formData };
      let interviewData = copy.interviewData.filter((i) => i.id != id);
      copy.interviewData = interviewData;
      setFormData(copy);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.message) {
      interviewType
        ? dispatch(
            scheduleInterview(
              data?._id,
              navigate,
              {
                ...formData,
                interviewType: interviewType,
              },
              socket
            )
          )
        : olibrToast.error("Please select interview type.");
    } else {
      olibrToast.error("Message is required.");
    }
  };

  const onClose = () => {
    navigate(-1);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  if (!data) return <ClipLoader />;
  else
    return (
      <div className="row">
        <div className="col-12">
          <div className="dash-block shortlisted-wrap">
            <div className="card padding-bottom">
              <div className="card-header job-head">
                <h4>Setup Interview</h4>
                <Icon name="modal-close" className="cursor" onClick={onClose} />
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="job-category">
                    <div className="user-img">
                      <figure>
                        <img
                          src={
                            !!data?.devId?.userImage
                              ? data?.devId?.userImage
                              : UserAvitar
                          }
                          alt="User Image"
                        />
                      </figure>
                    </div>
                    <div className="user-details">
                      <div className="block-title">
                        <h4>
                          <span>
                            <span className="fit-content">
                              {data?.devId?.fName + " " + data?.devId?.lName}
                            </span>
                          </span>
                          <small>{data?.devId?.devDesg}</small>
                        </h4>
                      </div>
                      <ul className="icon-tags">
                        {checkSkillStackIcon(data?.devId?.devTechTestResult)}
                      </ul>
                      <div className="availablity">Available Now </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="shortlisted-block card"
                  style={{ background: "#fff", borderRadius: "5px" }}
                >
                  <div
                    className="job-rquirement"
                    style={{ position: "relative" }}
                  >
                    <h4 className="job-rquirement-title">Create Interview</h4>
                    <div
                      style={{
                        width: "100%",
                        gap: "10px",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        flexDirection: "row",
                        position:"absolute",
                        top:"11px"
                      }}
                    >
                      <div
                        className="flex-center"
                        style={{
                          gap: "2px",
                          cursor: "pointer",
                          height: "20px",
                          maxWidth: "160px"
                        }}
                        onClick={() => setInterviewType("coding")}
                      >
                        <input
                          type="radio"
                          className="modal-radio"
                          checked={interviewType === "coding"}
                        />
                        <span>Coding Interview</span>
                      </div>
                      <div
                        className="flex-center"
                        style={{
                          gap: "2px",
                          cursor: "pointer",
                          height: "20px",
                          maxWidth: "160px"
                        }}
                        onClick={() => setInterviewType("regular")}
                      >
                        <input
                          type="radio"
                          className="modal-radio"
                          checked={interviewType === "regular"}
                        />
                        <span>Regular Interview</span>
                      </div>
                    </div>
                    <a
                      className="plus-icon"
                      style={{ top: "52px" }}
                      onClick={
                        formData.interviewData.length <= 2
                          ? addInterviewData
                          : null
                      }
                    >
                      +
                    </a>
                    <form onSubmit={handleSubmit}>
                      <div className="job-rquirement-form">
                        <div className="row">
                          {formData.interviewData.map((d, index) => {
                            return (
                              <>
                                <div className="col-5">
                                  <div className="form-group">
                                    <label>
                                      From
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <DatePicker
                                      selected={new Date(d.from)}
                                      minDate={new Date()}
                                      minTime={
                                        new Date(d.from).getDate()  - new Date().getDate() !== 0 ? 
                                        new Date().setHours(0, 0, 0) :
                                        new Date().getTime() + 2 * 60 * 60 * 1000
                                      }
                                      maxTime={setHours(setMinutes(new Date(), 59), 23)}
                                      onChange={(from) => {
                                        let copy = { ...formData };
                                        copy.interviewData.map((i) => {
                                          if (i.id == d.id) {
                                            i.from = from;
                                            i.to = new Date(
                                              new Date(from).getTime() +
                                                30 * 60000
                                            );
                                          }
                                        });
                                        setFormData(copy);
                                      }}
                                      showTimeSelect
                                      dateFormat="Pp"
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                     }}
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group">
                                    <label>
                                      To
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <DatePicker
                                      selected={new Date(d.to)}
                                      minDate={new Date()}
                                      minTime={
                                        new Date(d.from).getDate()  - new Date().getDate() !== 0 ? 
                                        new Date().setHours(0, 0, 0) :
                                        new Date().getTime() + 2 * 60 * 60 * 1000
                                      }
                                      maxTime={setHours(setMinutes(new Date(), 59), 23)}
                                      onChange={(to) => {
                                        let copy = { ...formData };
                                        copy.interviewData.map((i) => {
                                          if (i.id == d.id) {
                                            if (
                                              new Date(to).getTime() >
                                              new Date(i.from).getTime()
                                            ) {
                                              i.to = to;
                                            } else {
                                              olibrToast.error(
                                                "To date should be greater than from date."
                                              );
                                            }
                                          }
                                        });
                                        setFormData(copy);
                                      }}
                                      showTimeSelect
                                      dateFormat="Pp"
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                     }}
                                    />
                                  </div>
                                </div>
                                <div className="col-1">
                                  <a
                                    className="minus-icon"
                                    style={{ top: "25px", float: "right" }}
                                    onClick={() => subtractInterviewData(d.id)}
                                  >
                                    -
                                  </a>
                                </div>
                              </>
                            );
                          })}
                          {interviewType !== "coding" && 
                          <div className="col-12" style={{ margin: "10px 0" }}>
                            <div
                              className="form-group"
                              style={{ position: "relative" }}
                            >
                              <label>Add other attendees</label>
                              <textarea
                                style={{
                                  resize: "none",
                                  minHeight: "20px",
                                  fontFamily: "Poppins",
                                }}
                                value={attendence}
                                placeholder="Enter email and click on add button"
                                rows={1}
                                onChange={(e) => setAttendence(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    if (validateEmail(e.target.value)) {
                                      e.preventDefault();
                                      setFormData({
                                        ...formData,
                                        requiredAttendence:
                                          formData?.requiredAttendence
                                            ? [
                                                ...formData?.requiredAttendence,
                                                e.target.value,
                                              ]
                                            : [e.target.value],
                                      });
                                      setAttendence("");
                                    } else {
                                      e.preventDefault();
                                      olibrToast.error(
                                        "Please enter valid email address."
                                      );
                                    }
                                  }
                                }}
                              />
                              <span
                                className="browseImage"
                                onClick={() => {
                                  if (attendence) {
                                    if (validateEmail(attendence)) {
                                      setFormData({
                                        ...formData,
                                        requiredAttendence:
                                          formData?.requiredAttendence
                                            ? [
                                                ...formData?.requiredAttendence,
                                                attendence,
                                              ]
                                            : [attendence],
                                      });
                                      setAttendence("");
                                    } else {
                                      olibrToast.error(
                                        "Please enter valid email address."
                                      );
                                    }
                                  }
                                }}
                              >
                                Add
                              </span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "10px",
                                margin: "-3px 0 10px",
                              }}
                            >
                              {formData?.requiredAttendence?.map(
                                (item, index) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      padding: "5px 10px",
                                      boxSizing: "border-box",
                                      border: "1px solid #e0eaf5",
                                      borderRadius: "20px",
                                      background: "#edf5fd",
                                      gap: "20px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    <span>{item}</span>
                                    <Icon
                                      name="modal-close"
                                      style={{ cursor: "pointer" }}
                                      width="10px"
                                      onClick={() => {
                                        const tempData = [
                                          ...formData?.requiredAttendence,
                                        ];
                                        tempData.splice(index, 1);
                                        setFormData({
                                          ...formData,
                                          requiredAttendence: tempData
                                            ? tempData
                                            : [],
                                        });
                                      }}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          </div>}
                          <div className="col-12">
                            <div className="form-group">
                              <label>
                                Message<span style={{ color: "red" }}>*</span>
                              </label>
                              <textarea
                                value={formData.message}
                                placeholder="Message"
                                style={{ fontFamily: "Poppins" }}
                                rows={5}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    message: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="btn-block justify-content-end">
                        <button className="btn" type="submit">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
