import { Image, Text, View } from '@react-pdf/renderer'
import React from 'react'
import { Project } from './css/Project'
import banner from "../../../assets/img/project-theme-bg.png"
import ProjectIcon from "../../../assets/img/project-icon.png";
import EmpDescription from './EmpDescription';
const EmpProject = ({ projects,empId }) => {
    return (

        !!projects?.length && (
            projects?.map((project) => empId==project?.empId && (
                <>
                    <View style={Project.mainBox}>
                        <Text style={Project.heading}>Projects</Text>
                        <Image source={banner} style={Project.bgBanner} />
                        <View style={{ flexDirection: "row" }}>
                            <View style={Project.left}>
                                <Text style={Project.projectTitle}>{project?.name}</Text>
                            </View>
                            <View style={Project.right}>
                                <Image source={ProjectIcon} style={Project.projectImg} />
                            </View>
                        </View>
                    </View>
                    <View style={Project.description}>
                        <View><Text style={Project.role}>Role - <Text style={Project.roleTitle}>{project?.url}</Text></Text></View>
                        <EmpDescription content={project?.description} />
                    </View>
                </>
            ))
        )


    )
}

export default EmpProject