import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addFaqGroup } from '../../../../service/action/admin';
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';
import { faqForArr } from '../../../../common/constant';

const AddGroupCategory = ({setFaqGroupModal,closeAddFaqGroupModal}) => {
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const {
    register: faqGroupRegister,
    handleSubmit: faqGroupSubmit,
    control: faqGroupControll,
    reset: resetFaqGroupForm,
    formState: { errors: faqGroupError },
  } = useForm();

  const addFaqGroupSubmit = (data) => {
    const newData = {
      faqTitle: data?.faqTitle,
      faqFor: data?.faqFor?.value,
    };
    dispatch(
      addFaqGroup(newData, setFaqGroupModal, resetFaqGroupForm, navigate)
    );
  };

  return (
    <form>
    <div className="form-group">
      <label htmlFor="" className="floating-label">
        Group Category
      </label>
      <Controller
        control={faqGroupControll}
        name="faqFor"
        {...faqGroupRegister("faqFor", {
          required: true,
        })}
        render={({ field }) => (
          <Select
            isLoading={false}
            isSearchable={false}
            maxMenuHeight={100}
            options={faqForArr}
            onChange={(e) => field.onChange(e)}
          />
        )}
      />
    </div>

    <div className="form-group">
      <label
        htmlFor=""
        className={`floating-label addon ${
          faqGroupError?.faqTitle && "text-danger"
        }`}
      >
        Group Title*
      </label>
      <input
        type="text"
        className="form-control"
        {...faqGroupRegister("faqTitle", {
          required: true,
        })}
      />
    </div>
    <br />
    <div className="bottom-button text-right">
      <button
        className="cancelBtn"
        type="button"
        onClick={closeAddFaqGroupModal}
      >
        cancel
      </button>
      <button
        className="submitBtn themeButton"
        onClick={faqGroupSubmit(addFaqGroupSubmit)}
      >
        {false ? <ClipLoader color="#ffffff" size={20} /> : "SUBMIT"}
      </button>
    </div>
  </form>
  )
}

export default AddGroupCategory