import React from "react";
import OlibrEditor from "../../../../components/editor";
import SelectOption from "../../../../components/select/SelectOption";
import { faqForArr } from "../../../../common/constant";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addFaq } from "../../../../service/action/admin";

const AddFaqForm = ({
  setFaqGroupModal,
  faqForType,
  setFaqForType,
  faqGroupListResDev,
  setFaqModal,
  faqGroupListResClient,
  faqGroupListResPricing,
  getFaqList
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register: faqRegister,
    handleSubmit: faqSubmit,
    setValue: setFaqValue,
    getValues: getFaqValue,
    control: faqControll,
    reset: resetFaqForm,
    formState: { errors: faqError },
  } = useForm();

  const closeAddForm=()=>{
    resetFaqForm()
    getFaqList(faqForType)
  }

  const addFaqSubmit = (data) => {
    const postData = {
      faqGroupId: data?.faqGroup?.value,
      faqQues: data.faqQues,
      faqAnswer: data.faqAnswer,
    };
    dispatch(addFaq(navigate, postData, faqForType, setFaqModal, closeAddForm));
  };
  function closeAddFaqModal() {
    resetFaqForm();
    setFaqModal(false);
  }

  useEffect(() => {
    resetFaqForm();
  }, [faqForType]);

  return (
    <form>
      <div className="faq-management">
        <br />
        <div className="block-header">
          <h4 className="">Add FAQ</h4>
          <a
            className="themeButton add-group-btn"
            onClick={() => {
              setFaqGroupModal(true);
              resetFaqForm();
            }}
          >
            Add Group
          </a>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label className="floating-label">FAQ for</label>
              <SelectOption
                optionArray={faqForArr}
                state={faqForArr?.find((item) => item?.value == faqForType)}
                onChangeFunction={(e) => {
                  setFaqForType(e?.value);
                }}
                closeMenuOnSelect={true}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group ">
              <label
                className={`floating-label ${
                  faqError?.faqGroup && "text-danger"
                }`}
              >
                FAQ Group
              </label>
              <Controller
                control={faqControll}
                name="faqGroup"
                {...faqRegister("faqGroup", {
                  required: true,
                })}
                render={({ field }) => (
                  <SelectOption
                    optionArray={
                      faqForType == "Developer"
                        ? faqGroupListResDev.map((item) => ({
                            value: item._id,
                            label: item.faqTitle,
                          }))
                        : faqForType == "Client"
                        ? faqGroupListResClient?.map((item) => ({
                            value: item._id,
                            label: item.faqTitle,
                          }))
                        : faqGroupListResPricing?.map((item) => ({
                          value: item._id,
                          label: item.faqTitle,
                        }))
                    }
                    fieldProp={field}
                    getValue={getFaqValue}
                    getValueName={"faqGroup"}
                    closeMenuOnSelect={true}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label
                className={`floating-label addon ${
                  faqError?.faqQues && "text-danger"
                }`}
                for=""
              >
                Question*
              </label>
              <input
                type="text"
                className="form-control fields"
                name="faqQues"
                {...faqRegister("faqQues", {
                  required: true,
                })}
              />
            </div>
          </div>
        </div>
        <br />
        <div className={`FAQeditor ${faqError?.faqAnswer && "form-required"}`}>
          <Controller
            control={faqControll}
            name="faqAnswer"
            {...faqRegister("faqAnswer", {
              required: true,
            })}
            render={({ field }) => (
              <OlibrEditor
                value={getFaqValue("faqAnswer") ? getFaqValue("faqAnswer") : ""}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </div>
        <br />
        <div className="bottom-button">
          <button
            onClick={closeAddFaqModal}
            type="button"
            className="cancelBtn"
          >
            cancel
          </button>
          <button
            className="submitBtn themeButton"
            onClick={faqSubmit(addFaqSubmit)}
          >
            submit
          </button>
        </div>
        <br />
      </div>
    </form>
  );
};

export default AddFaqForm;
