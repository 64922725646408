import React from "react";
import { Icon } from "../../../../components/icon";

const ColumnFeature = (onEdit) => {
  const columns = [
    {
      name: "S.No",
      selector: "sNo",
      width: "97px",
      cell: (_, index) => index + 1,
    },
    {
      name: "Feature Name",
      selector: "feature",
      sortable: true,
      cell: (row) => (
        <div>
          <span className="link">{row?.title}</span> <br/>
          <span>{row?.description}</span>
        </div>
      ),
    },

    // {
    //   name: "Status",
    //   selector: "active",
    //   sortable: true,
    //   cell: (row) => (
    //     <span>
    //       <input
    //         type="checkbox"
    //         name="switch"
    //         onChange={(e) => e.preventDefault()}
    //         checked={row?.active}
    //       />
    //       <label
    //         onClick={(e) => {
    //           e.stopPropagation();
    //           changeStatus(row);
    //         }}
    //         className="label"
    //         htmlFor="switch3"
    //       ></label>
    //     </span>
    //   ),
    // },
    {
      name: "Action",
      sortable: true,
      width: "140px",
      cell: (row) => (
        <span onClick={()=>onEdit(row)} style={{cursor:"ponter"}}>
          <Icon name="edit" />
        </span>
      ),
    },
  ];
  return columns;
};

export default ColumnFeature;
