import React from 'react'
import PageTour from '../../../components/page-tour/PageTour'

const PerkAndBenefit = ({handlePerk,companyProfile,pageTour,setPageTour,perkAndBenefit,checkSelected,skipPageTour}) => {
  return (
    <div className="row" id="benefit">
    <div className="col-12">
      <h4 className="form-title">
        Add Perks &amp; benefits
      </h4>
      <PageTour
        open={pageTour == "perk-benefit"}
        data={companyProfile?.perkAndBenefit}
        next={() => setPageTour("team")}
        skip={skipPageTour}
        total={6}
        id="#benefit"
      />
      <div className="benifits">
        <ul>
          {perkAndBenefit?.map((item, index) => (
            <li
              key={index}
              className={checkSelected(item?._id)}
              onClick={() => handlePerk(item?._id)}
            >
              <figure>
                <img src={item?.iconUrl} alt="" />
              </figure>
              <h4>{item?.perkName}</h4>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
  )
}

export default PerkAndBenefit