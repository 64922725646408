import React from 'react'
import { ClipLoader } from 'react-spinners';

const ClipLoaderComp = ({color, size}) => {
  return (
    <div style={{textAlign: "center"}}>
        <ClipLoader color={color} size={size}/>
    </div>
  )
}

export default ClipLoaderComp;