import React, { Fragment, useState } from "react";

import { getCity,getState } from "../../../../service/action/common";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import { cityArrFunc, stateArrFunc } from "../../../../components/functions";
import SelectOption from "../../../../components/select/SelectOption";
const Filters = ({getJobLists,setPageData, currentJobCategory, setFilter, filter, pageData,search }) => {
  const navigate = useNavigate();
  const { stateList, cityList } = useSelector((state) => state.commonReducer);
  
  const dispatch = useDispatch(); 



  const emptyObj = () => {
    setFilter({
      jobTitle: "",
      jobLocation: "",
      city: "",
      state: "",
    });
  };

  useEffect(()=>{
    dispatch(getState({ countryCode: "IN" }, navigate));
  },[])

  useEffect(() => {
    if (filter?.state) {
      dispatch(
        getCity({ countryCode: "IN", stateCode: filter?.state }, navigate)
      );
    }
  }, [filter?.state]);


  const clearFilter = () => {
    emptyObj();
    getJobLists(true,{ page: 1, limit: pageData?.limit },currentJobCategory,{},search)
    setPageData({ page: 1, limit: pageData?.limit })
  };

  const [isFilterApply, setFilterApply] = useState(false);

  useEffect(() => {

    let apply = false;

    for (const [key, item] of Object.entries(filter)) {
      if (item) apply = true;
    }

    apply ? setFilterApply(true) : setFilterApply(false); 
  }, [filter]);

  const cleanObj = (obj) => {
    return new Promise(function (resolve, reject) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName]?.length < 1
        ) {
          delete obj[propName];
        }
      }

      resolve(obj);
    });
  };

  const handleFilter = async () => {
    const cleanFilterValue = await cleanObj(filter);
    getJobLists(true,{ page: 1, limit: 10 },currentJobCategory,cleanFilterValue,search)
    setPageData({ page: 1, limit: 10 })
  };

  const handleChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  return (
    <Fragment>
      {" "}
      <div className="search-filter-box">
        <p className="filters">Filter By</p>{" "}
        <p className="filter" onClick={clearFilter}>
          {isFilterApply && "CLEAR FILTER"}{" "}
        </p>
        {" "}
        <div className="filter-row">
          <label>Skills or Title</label>
          {" "}
          <input
            type="text"
            name="jobTitle"
            value={filter.jobTitle}
            onChange={handleChange}
            className="form-control"
            placeholder="Enter Skill or Title Like Java"
          />
        </div>
        <div className="filter-row">
          <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>State</label>
              <SelectOption
                optionArray={stateArrFunc(stateList)}
                state={stateArrFunc(stateList)?.find(
                  (item) => item?.value === filter?.state
                )}
                onChangeFunction={(e) => {
                  setFilter({
                    ...filter,
                    state: e.value,
                  });
                }}
                searchable={true}
                closeMenuOnSelect={true}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>City</label>
              <SelectOption
                optionArray={cityArrFunc(cityList)}
                state={cityArrFunc(cityList)?.find(
                  (item) => item?.value === filter?.city
                )}
                onChangeFunction={(e) => {
                  setFilter({
                    ...filter,
                    city: e.value,
                  });
                }}
                searchable={true}
                closeMenuOnSelect={true}
              />
            </div>
          </div>
          </div>
        </div>
        <div className="filter-row">
          <label>Job Type</label>{" "}
          <select
            className="form-control"
            name="jobLocation"
            value={filter?.jobLocation}
            onChange={handleChange} // {...jobRegister("jobLocation")}
          >
            <option value=""></option>{" "}
            {[
              { value: "Remote", label: "Remote" },
              { value: "On Site", label: "Onsite" },
              { value: "Hybrid", label: "Hybrid" },
            ].map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}{" "}
              </option>
            ))}
          </select>
        </div>
        <div className="filter-row">
          <a class="submitBtn themeButton" onClick={handleFilter}>
            Apply Filter
          </a>
        </div>
      </div>
    </Fragment>
  );
};

export default Filters;
