import { put, call } from "redux-saga/effects";
import * as types from "../../action";
import { olibrToast } from "../../../components/toaster";
import {
  signUp,
  getSavedProfile,
  getClientHiredClient,
  getClientAllJobList,
  getClientDevList,
  addNewJobPostAPI,
  updateClientJob,
  updateClientJobData,
  getAllSkillsListClient,
  clientDirectContact,
  getDeveloperData,
  speakWithDeveloper,
  getClientJobDetail,
  removeFromShortListsClients,
  removeFromHiredListsClients,
  deleteClientJobs,
  getAllStacksListClient,
  assignToJob,
  unassignToJob,
  topFiveSkills,
  updateShortList,
  saveProfile,
  saveClientHiringSkills,
  getSelectedSkills,
  updateClientsJobStatus,
  unsavedProfile,
  getClientAllJobTitlesApi,
  saveCompanyProfile,
  getAppliedForJobApi,
  getShortlistedForJobApi,
  getCompanyProfile,
  getAllJobOfClient,
  updateAppliedJobStatus,
  getClientFirstEntryApi,
  updateClientFirstEntryApi,
  updateClientViewProfile,
  clearViewFilter,
  updateClientCompnyFirst,
  getClientEmailSetting,
  companyUser,
  inviteCompanyUserApi,
  clientUserActivateDeactivateApi,
  companyAllActiveUser,
  saveScheduleInterview,
  getScheduledInterviews,
  cancelScheduleInterview,
  jobRefreshApi,
  clearJobComment,
  UpdateJobComment,
  saveJobComment,
  deleteJobComment,
  acceptInterviewRequest,
  saveScheduleInterviewByDev,
  devFilterClient,
  updateDevSnoozeApi,
  subscriptionSummaryApi,
  clientPaymentHistoryApi,
  cancelPlanApi,
  clientRestrictionApi,
  deleteEmailAPI,
} from "../../api";

export function* clientSignupSaga({ data, navigate }) {
  try {
    yield put({ type: types.CLIENT_SIGNUP_START });
    const response = yield call(signUp, data);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.CLIENT_SIGNUP_FAIL });
    } else {
      if (response.data.statusValue === 400) {
        olibrToast.error(response.data.message);
        yield put({ type: types.CLIENT_SIGNUP_FAIL });
      } else {
        olibrToast.success(response.data.message);
        yield put({ type: types.CLIENT_SIGNUP_SUCCESS, data: response });
        navigate("/login");
      }
    }
  } catch (error) {
    yield put({ type: types.CLIENT_SIGNUP_FAIL });
  }
}

export function* getSavedProfileSaga({ navigate, pageData, count }) {
  try {
    yield put({ type: types.GET_CLIENT_SAVED_PROFILE_START });
    const response = yield call(getSavedProfile, pageData);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.GET_CLIENT_SAVED_PROFILE_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        if (count) {
          yield put({
            type: types.GET_CLIENT_SAVED_PROFILE_ONLY,
            data: response?.data,
          });
        } else {
          yield put({
            type: types.GET_CLIENT_SAVED_PROFILE_SUCCESS,
            limit: pageData?.limit,
            data: response,
          });
        }
      } else {
        yield put({ type: types.GET_CLIENT_SAVED_PROFILE_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.GET_CLIENT_SAVED_PROFILE_FAIL });
  }
}

export function* getHiredCanditates({ navigate, pageData }) {
  try {
    yield put({ type: types.GET_CLIENT_HIRED_CANDIDATES_START });
    const response = yield call(getClientHiredClient, pageData);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.GET_CLIENT_HIRED_CANDIDATES_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.GET_CLIENT_HIRED_CANDIDATES_SUCCESS,
          data: response,
        });
      } else {
        yield put({ type: types.GET_CLIENT_HIRED_CANDIDATES_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.GET_CLIENT_HIRED_CANDIDATES_FAIL });
  }
}

export function* getClientALlJobLists({ navigate, pageData, id }) {
  try {
    console.log("get client all job list hit ------");
    yield put({ type: types.GET_CLIENT_ALL_JOBLISTS_START });
    const response = yield call(getClientAllJobList, pageData, id);

    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.GET_CLIENT_ALL_JOBLISTS_FAIL });
    } else {
      yield put({
        type: types.GET_CLIENT_ALL_JOBLISTS_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_CLIENT_ALL_JOBLISTS_FAIL });
  }
}

export function* getClientALlJobTitlesSaga({ navigate }) {
  try {
    yield put({ type: types.GET_CLIENT_ALL_JOBTITLES_START });
    const response = yield call(getClientAllJobTitlesApi);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.GET_CLIENT_ALL_JOBTITLES_FAIL });
    } else {
      yield put({
        type: types.GET_CLIENT_ALL_JOBTITLES_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_CLIENT_ALL_JOBTITLES_FAIL });
  }
}

export function* getAppliedForJobSaga({ jobId, navigate }) {
  try {
    yield put({ type: types.GET_APPLIED_FOR_JOB_START });
    const response = yield call(getAppliedForJobApi, jobId);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.GET_APPLIED_FOR_JOB_FAIL });
    } else {
      yield put({
        type: types.GET_APPLIED_FOR_JOB_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_APPLIED_FOR_JOB_FAIL });
  }
}

export function* getShortlistedForJobSaga({ jobId, navigate }) {
  try {
    yield put({ type: types.GET_SHORTLISTED_FOR_JOB_START });
    const response = yield call(getShortlistedForJobApi, jobId);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.GET_SHORTLISTED_FOR_JOB_FAIL });
    } else {
      yield put({
        type: types.GET_SHORTLISTED_FOR_JOB_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_SHORTLISTED_FOR_JOB_FAIL });
  }
}

export function* deleteClientJobsSaga({ data, navigate }) {
  try {
    yield put({ type: types.DELETE_CLIENT_JOB_START });
    const response = yield call(deleteClientJobs, data);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_CLIENT_JOB_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({ type: types.DELETE_CLIENT_JOB_SUCCESS, jobId: data });
        olibrToast.success(response?.data?.message);
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.DELETE_CLIENT_JOB_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_CLIENT_JOB_FAIL });
  }
}

export function* getClientDevLists({
  navigate,
  pageData,
  filterData,
  setModal,
  firstTime,
  clearFilter,
}) {
  try {
    yield put({ type: types.GET_CLIENT_DEV_LISTS_START, clearFilter });
    console.log(":clearFilter", clearFilter);
    const response = yield call(getClientDevList, pageData, filterData);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.GET_CLIENT_DEV_LISTS_FAIL });
    } else {
      yield put({
        type: types.GET_CLIENT_DEV_LISTS_SUCCESS,
        data: response?.data,
        firstTime,
      });
      if (setModal != null) {
        setModal(false);
      }
    }
  } catch (error) {
    yield put({ type: types.GET_CLIENT_DEV_LISTS_FAIL });
  }
}

export function* addNewJobPost({ data, navigate }) {
  try {
    yield put({ type: types.ADD_NEW_JOB_POST_START });
    const response = yield call(addNewJobPostAPI, data);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.ADD_NEW_JOB_POST_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        olibrToast.success(response?.data?.message);
        navigate(-1);
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.ADD_NEW_JOB_POST_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.ADD_NEW_JOB_POST_FAIL });
  }
}

export function* getClientJob({ navigate, jobId, setQuestion }) {
  try {
    yield put({ type: types.GET_CLIENT_JOB_START });
    const response = yield call(updateClientJob, jobId);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.GET_CLIENT_JOB_FAIL });
    } else {
      let question = response?.data?.jobDetails?.[0]?.questionaries;
      setQuestion && !!question && setQuestion({ data: question });
      yield put({ type: types.GET_CLIENT_JOB_SUCCESS, data: response?.data });
    }
  } catch (error) {
    yield put({ type: types.GET_CLIENT_JOB_FAIL });
  }
}

export function* updateClientJobs({ data, navigate }) {
  try {
    yield put({ type: types.UPDATE_CLIENT_JOB_START });
    const response = yield call(updateClientJobData, data);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.UPDATE_CLIENT_JOB_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        // olibrToast.success(response?.data?.message);
        navigate(-1);
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.UPDATE_CLIENT_JOB_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_CLIENT_JOB_FAIL });
  }
}

export function* getAllSkillClientSaga({ navigate, dataType, search }) {
  try {
    yield put({ type: types.GET_CLIENT_SKILL_LISTS_START });
    const response = yield call(getAllSkillsListClient, dataType, search);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.GET_CLIENT_SKILL_LISTS_FAIL });
    } else {
      yield put({
        type: types.GET_CLIENT_SKILL_LISTS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_CLIENT_SKILL_LISTS_FAIL });
  }
}

export function* getAllStackClientSaga({ navigate, search }) {
  try {
    yield put({ type: types.GET_CLIENT_STACK_LISTS_START });
    const response = yield call(getAllStacksListClient, search);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.GET_CLIENT_STACK_LISTS_FAIL });
    } else {
      yield put({
        type: types.GET_CLIENT_STACK_LISTS_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_CLIENT_STACK_LISTS_FAIL });
  }
}

export function* clientDirectContacts({ navigate, data }) {
  try {
    yield put({ type: types.CLIENT_DIRECT_CONTACT_START });
    const response = yield call(clientDirectContact, data);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.CLIENT_DIRECT_CONTACT_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.CLIENT_DIRECT_CONTACT_SUCCESS,
          data: response,
        });
        navigate("/client/thankyou");
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.CLIENT_DIRECT_CONTACT_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.CLIENT_DIRECT_CONTACT_FAIL });
  }
}

export function* getDevDetails({ navigate, devId }) {
  try {
    yield put({ type: types.GET_DEV_DETAILS_START });
    const response = yield call(getDeveloperData, devId);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.GET_DEV_DETAILS_FAIL });
    } else {
      yield put({ type: types.GET_DEV_DETAILS_SUCCESS, data: response?.data });
    }
  } catch (error) {
    yield put({ type: types.GET_DEV_DETAILS_FAIL });
  }
}

export function* speakWithDev({ navigate, data }) {
  try {
    yield put({ type: types.SPEAK_WITH_DEV_START });
    const response = yield call(speakWithDeveloper, data);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.SPEAK_WITH_DEV_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        navigate("/client/thankyou");
        yield put({ type: types.SPEAK_WITH_DEV_SUCCESS, data: response });
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.SPEAK_WITH_DEV_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.SPEAK_WITH_DEV_FAIL });
  }
}

export function* getClientJobDetails({ navigate, jobId, pageData }) {
  try {
    yield put({ type: types.GET_CLIENT_JOB_DETAIL_START });
    const response = yield call(getClientJobDetail, jobId, pageData);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.GET_CLIENT_JOB_DETAIL_FAIL });
    } else {
      yield put({
        type: types.GET_CLIENT_JOB_DETAIL_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_CLIENT_JOB_DETAIL_FAIL });
  }
}

export function* removeFromShortListsClient({ navigate, devId, jobId }) {
  try {
    yield put({ type: types.REMOVE_FROM_SHORT_LIST_CLIENT_START });
    const response = yield call(removeFromShortListsClients, devId);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.REMOVE_FROM_SHORT_LIST_CLIENT_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        if (!jobId) {
          yield put({
            type: types.REMOVE_FROM_SHORT_LIST_CLIENT_SUCCESS,
            shortList: true,
            devId,
          });
        }
        if (jobId) {
          yield put({
            type: types.REMOVE_FROM_SHORT_LIST_CLIENT_SUCCESS,
            jobDetail: true,
            devId,
          });
        }
        olibrToast.success(response?.data?.message);
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.REMOVE_FROM_SHORT_LIST_CLIENT_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.REMOVE_FROM_SHORT_LIST_CLIENT_FAIL });
  }
}

export function* removeFromHiredListsClient({ navigate, devId, pageData }) {
  try {
    yield put({ type: types.REMOVE_FROM_SHORT_LIST_CLIENT_START });
    const response = yield call(removeFromHiredListsClients, devId);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.REMOVE_FROM_SHORT_LIST_CLIENT_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.REMOVE_FROM_SHORT_LIST_CLIENT_SUCCESS,
          data: response?.data,
        });
        const resHiredLists = yield call(getClientHiredClient, pageData);
        yield put({
          type: types.GET_CLIENT_HIRED_CANDIDATES_SUCCESS,
          data: resHiredLists,
        });
        olibrToast.success(response?.data?.message);
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.REMOVE_FROM_SHORT_LIST_CLIENT_FAIL });
  }
}

export function* assignToJobSaga({ navigate, fetchSavedProfileData, data }) {
  try {
    let tempJob = { ...data };
    delete tempJob?.jobTitle;
    yield put({ type: types.ASSIGN_TO_JOB_START });
    const response = yield call(assignToJob, tempJob);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.ASSIGN_TO_JOB_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        fetchSavedProfileData();
        // olibrToast.success(response?.data?.message);
        yield put({ type: types.ASSIGN_TO_JOB_SUCCESS });
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.ASSIGN_TO_JOB_FAIL });
  }
}

export function* unassignToJobSaga({ navigate, fetchSavedProfileData, data }) {
  try {
    const response = yield call(unassignToJob, data);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.UNASSIGN_TO_JOB_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        fetchSavedProfileData();
        // olibrToast.success(response?.data?.message);
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UNASSIGN_TO_JOB_FAIL });
  }
}

export function* topFiveSkillsSaga({ navigate }) {
  try {
    yield put({ type: types.GET_TOP_FIVE_SKILL_START });
    const response = yield call(topFiveSkills);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.GET_TOP_FIVE_SKILL_FAIL });
    } else {
      yield put({
        type: types.GET_TOP_FIVE_SKILL_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_TOP_FIVE_SKILL_SUCCESS });
  }
}

export function* updateShortLists({ navigate, data }) {
  try {
    yield put({ type: types.UPDATE_SHORT_LISTS_START });
    const response = yield call(updateShortList, data);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.UPDATE_SHORT_LISTS_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_SHORT_LISTS_SUCCESS,
          devId: data?.devId,
        });
        olibrToast.success(response?.data?.message);
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.SAVE_PROFILE_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_SHORT_LISTS_FAIL });
  }
}

export function* saveProfileSaga({ navigate, devId }) {
  try {
    yield put({ type: types.SAVE_PROFILE_START });
    const response = yield call(saveProfile, devId);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.SAVE_PROFILE_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        // window.provesrc.display("information", null, {
        //   message: `${userName}’s profile has been shortlisted.`,
        // });
        yield put({
          type: types.SAVE_PROFILE_SUCCESS,
          searchPage: true,
          devId: devId?.devId,
        });
        // olibrToast.success(response?.data?.message);
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.SAVE_PROFILE_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.SAVE_PROFILE_FAIL });
  }
}

export function* saveClientHiringSkill({ navigate, data }) {
  try {
    yield put({ type: types.SAVE_CLIENT_HIRING_SKILLS_START });
    const response = yield call(saveClientHiringSkills, data);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.SAVE_CLIENT_HIRING_SKILLS_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({ type: types.GET_SELECTED_SKILLS_START });
        olibrToast.success(response?.data?.message);
        navigate("/client/search-developer");
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.SAVE_CLIENT_HIRING_SKILLS_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.SAVE_CLIENT_HIRING_SKILLS_FAIL });
  }
}
export function* getSelectedSkillsStacks({ navigate }) {
  try {
    yield put({ type: types.GET_SELECTED_SKILLS_START });
    const response = yield call(getSelectedSkills);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.GET_SELECTED_SKILLS_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.GET_SELECTED_SKILLS_SUCCESS,
          data: response?.data?.data[0],
        });
      } else {
        yield put({ type: types.GET_SELECTED_SKILLS_FAIL });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.GET_SELECTED_SKILLS_FAIL });
  }
}

export function* updateClientJobStatus({ navigate, status, setCloseConfirm }) {
  try {
    yield put({ type: types.UPDATE_CLIENT_JOB_STATUS_START });
    const response = yield call(updateClientsJobStatus, status);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.UPDATE_CLIENT_JOB_STATUS_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        setCloseConfirm?.({ isOpen: false });
        yield put({ type: types.UPDATE_CLIENT_JOB_STATUS_SUCCESS, status });
        // olibrToast.success(response?.data?.message);
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.UPDATE_CLIENT_JOB_STATUS_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_CLIENT_JOB_STATUS_FAIL });
  }
}

export function* unsavedProfileSaga({
  devId,
  fetchSavedProfileData,
  navigate,
}) {
  try {
    yield put({ type: types.UNSAVED_PROFILE_START });
    const response = yield call(unsavedProfile, devId);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.UNSAVED_PROFILE_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        fetchSavedProfileData && fetchSavedProfileData();
        yield put({
          type: types.UNSAVED_PROFILE_SUCCESS,
          devId: devId,
        });
        // olibrToast.success(response?.data?.message);
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.UNSAVED_PROFILE_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.UNSAVED_PROFILE_FAIL });
  }
}
export function* clearClientJobComment({ navigate, id, setFeedBack, getData }) {
  try {
    yield put({ type: types.UPDATE_APPLIED_JOB_STATUS_START });
    const response = yield call(clearJobComment, id);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.UPDATE_APPLIED_JOB_STATUS_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        setFeedBack("");
        getData();
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.UPDATE_APPLIED_JOB_STATUS_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_APPLIED_JOB_STATUS_FAIL });
  }
}

export function* updateClientJobComment({ navigate, id, data, getData }) {
  try {
    yield put({ type: types.UPDATE_CANDIDATE_COMMENT_START });
    const response = yield call(UpdateJobComment, id, data);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.UPDATE_CANDIDATE_COMMENT_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        getData();
        yield put({ type: types.UPDATE_CANDIDATE_COMMENT_SUCCESS });
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.UPDATE_CANDIDATE_COMMENT_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_CANDIDATE_COMMENT_FAIL });
  }
}

export function* saveClientJobComment({ navigate, id, data, getData }) {
  try {
    yield put({ type: types.ADD_CANDIDATE_COMMENT_START });
    const response = yield call(saveJobComment, id, data);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.ADD_CANDIDATE_COMMENT_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        getData(response?.data?.data);
        yield put({ type: types.ADD_CANDIDATE_COMMENT_SUCCESS });
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.ADD_CANDIDATE_COMMENT_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.ADD_CANDIDATE_COMMENT_FAIL });
  }
}

export function* deleteClientJobComment({ navigate, id, data, getData }) {
  try {
    yield put({ type: types.DELETE_CANDIDATE_COMMENT_START });
    const response = yield call(deleteJobComment, id, data);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.DELETE_CANDIDATE_COMMENT_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        getData(response?.data?.data);
        yield put({ type: types.DELETE_CANDIDATE_COMMENT_SUCCESS });
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.DELETE_CANDIDATE_COMMENT_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_CANDIDATE_COMMENT_FAIL });
  }
}

export function* updateAppliedJobStatusSaga({
  data,
  navigate,
  getAppliedCandidateList,
  socket,
  closeModal,
}) {
  try {
    yield put({ type: types.UPDATE_APPLIED_JOB_STATUS_START });
    const response = yield call(updateAppliedJobStatus, data);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.UPDATE_APPLIED_JOB_STATUS_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        if (data?.status === "shortlisted") {
          socket?.emit("shortlisted", {
            jobId: response?.data?.jobId,
            devId: data?.devId,
          });
        }
        yield put({ type: types.UPDATE_APPLIED_JOB_STATUS_SUCCESS });
        getAppliedCandidateList();
        closeModal && closeModal();
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.UPDATE_APPLIED_JOB_STATUS_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_APPLIED_JOB_STATUS_FAIL });
  }
}

export function* saveCompanyProfileSaga({ data, navigate, clientId }) {
  try {
    yield put({ type: types.SAVE_COMPANY_PROFILE_START, status: data?.status });
    const response = yield call(saveCompanyProfile, data, clientId);
    if (response.data.status === 401) {
      yield put({ type: types.SAVE_COMPANY_PROFILE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        olibrToast?.success(
          response?.data?.message?.includes("draft")
            ? "Saved"
            : response?.data?.message
        );
        yield put({
          type: types.SAVE_COMPANY_PROFILE_SUCCESS,
          data,
        });
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.SAVE_COMPANY_PROFILE_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.SAVE_COMPANY_PROFILE_FAIL });
  }
}

export function* getAllJobOfClientSaga({ navigate, clientId }) {
  try {
    yield put({ type: types.GET_ALL_JOB_OF_CLIENT_START });
    const response = yield call(getAllJobOfClient, clientId);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ALL_JOB_OF_CLIENT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.GET_ALL_JOB_OF_CLIENT_SUCCESS,
          data: response.data,
        });
      } else {
        yield put({ type: types.GET_ALL_JOB_OF_CLIENT_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.GET_ALL_JOB_OF_CLIENT_FAIL });
  }
}

export function* getCompanyProfileSaga({ navigate, clientId, id }) {
  try {
    yield put({ type: types.GET_COMPANY_PROFILE_START });
    const response = yield call(getCompanyProfile, clientId, id);
    if (response.data.status === 401) {
      yield put({ type: types.GET_COMPANY_PROFILE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        let responses = response?.data?.data[0];
        if (id && responses == undefined) {
          navigate(-1);
        } else if (id && responses?.status != "publish") {
          navigate(-1);
        } else {
          yield put({
            type: types.GET_COMPANY_PROFILE_SUCCESS,
            data: response?.data,
          });
        }
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.GET_COMPANY_PROFILE_FAIL });
      }
    }
  } catch (error) {
    navigate(-1);
    yield put({ type: types.GET_COMPANY_PROFILE_FAIL });
  }
}

export function* getClientFirstEntrySaga({ navigate }) {
  try {
    yield put({ type: types.GET_CLIENT_FIRST_ENTRY_START });
    const response = yield call(getClientFirstEntryApi);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.GET_CLIENT_FIRST_ENTRY_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.GET_CLIENT_FIRST_ENTRY_SUCCESS,
          data: response.data,
        });
      } else {
        yield put({ type: types.GET_CLIENT_FIRST_ENTRY_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.GET_CLIENT_FIRST_ENTRY_FAIL });
  }
}
export function* updateFirstCompanyProfile() {
  try {
    yield put({ type: types.UPDATE_FIRST_COMPANY_PROFILE_START });
    yield call(updateClientCompnyFirst);
    yield put({ type: types.UPDATE_FIRST_COMPANY_PROFILE_SUCCESS });
  } catch (error) {
    yield put({ type: types.UPDATE_FIRST_COMPANY_PROFILE_FAIL });
  }
}

export function* updateClientFirstEntrySaga({ navigate }) {
  try {
    yield put({ type: types.UPDATE_CLIENT_FIRST_ENTRY_START });
    const response = yield call(updateClientFirstEntryApi);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.UPDATE_CLIENT_FIRST_ENTRY_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_CLIENT_FIRST_ENTRY_SUCCESS,
          data: response.data,
        });
      } else {
        yield put({ type: types.UPDATE_CLIENT_FIRST_ENTRY_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_CLIENT_FIRST_ENTRY_FAIL });
  }
}

export function* updateViewProfileClient({ navigate, devId, user, setUser }) {
  try {
    yield put({ type: types.UPDATE_CLIENT_VIEW_PROFILE_START });
    const response = yield call(updateClientViewProfile, devId);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.UPDATE_CLIENT_VIEW_PROFILE_FAIL });
    } else {
      setUser({
        ...user,
        clientViewProfile: response?.data?.data?.clientViewProfile,
      });
      yield put({
        type: types.UPDATE_CLIENT_VIEW_PROFILE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.UPDATE_CLIENT_FIRST_ENTRY_FAIL });
  }
}

export function* clearViewFilterSaga({ navigate, reset }) {
  try {
    yield put({ type: types.CLEAR_VIEW_FILTER_START });
    const { data } = yield call(clearViewFilter);
    if (data.status === 401) {
      olibrToast.error(data.message);
      navigate("/login");
      yield put({ type: types.CLEAR_VIEW_FILTER_FAIL });
    } else {
      olibrToast.success(data?.message);
      reset();
    }
  } catch (error) {
    yield put({ type: types.CLEAR_VIEW_FILTER_FAIL });
  }
}

export function* getActivationSagaClient({ navigate }) {
  try {
    yield put({ type: types.CLIENT_EMAIL_AUTO_START });
    const response = yield call(getClientEmailSetting);
    if (response.data.status === 401) {
      olibrToast.error(response?.data?.message);
      navigate("/login");
    }
    if (response?.status === 200) {
      yield put({
        type: types.CLIENT_EMAIL_AUTO_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.CLIENT_EMAIL_AUTO_FAIL });
  }
}

export function* getCompanyUserSaga({ navigate, company, pageData }) {
  try {
    yield put({ type: types.GET_COMPANY_USER_START });
    const response = yield call(companyUser, company, pageData);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.GET_COMPANY_USER_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.GET_COMPANY_USER_SUCCESS,
          limit: pageData?.limit,
          data: response?.data,
        });
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.GET_COMPANY_USER_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.GET_CLIENT_SAVED_PROFILE_FAIL });
  }
}

export function* inviteCompanyUserSaga({ data, navigate, modalState }) {
  try {
    yield put({ type: types.CLIENT_INVITE_USER_START });
    const response = yield call(inviteCompanyUserApi, data);
    !data.resendEmail && modalState?.("close");
    if (response.data.status === 401) {
      yield put({ type: types.CLIENT_INVITE_USER_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        if (data.id && !data.resendEmail) {
          navigate("/client/client-dashboard");
        }
        olibrToast?.success(response?.data?.message);
        yield put({ type: types.CLIENT_INVITE_USER_SUCCESS });
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.CLIENT_INVITE_USER_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.CLIENT_INVITE_USER_FAIL });
  }
}

export function* clientUserActivateDeactivateSaga({ data, navigate, onDone }) {
  try {
    yield put({ type: types.CLIENT_ACTIVATE_DEACTIVATE_USER_START });
    const response = yield call(clientUserActivateDeactivateApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.CLIENT_ACTIVATE_DEACTIVATE_USER_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      onDone?.();
      if (response?.data?.statusCode === "SUCCESS") {
        olibrToast?.success(response?.data?.message);
        yield put({ type: types.CLIENT_ACTIVATE_DEACTIVATE_USER_SUCCESS });
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.CLIENT_ACTIVATE_DEACTIVATE_USER_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.CLIENT_ACTIVATE_DEACTIVATE_USER_FAIL });
  }
}

export function* getCompanyAllActiveUserSaga({ navigate, company }) {
  try {
    yield put({ type: types.COMPANY_ALL_ACTIVE_USER_START });
    const response = yield call(companyAllActiveUser, company);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.COMPANY_ALL_ACTIVE_USER_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.COMPANY_ALL_ACTIVE_USER_SUCCESS,
          data: response?.data?.data,
        });
      } else {
        yield put({ type: types.COMPANY_ALL_ACTIVE_USER_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.COMPANY_ALL_ACTIVE_USER_FAIL });
  }
}

export function* scheduleInterviewSaga({ id, navigate, interviewData, socket }) {
  try {
    yield put({ type: types.SCHEDULE_INTERVIEW_START });
    const response = yield call(saveScheduleInterview, { id, interviewData });
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.SCHEDULE_INTERVIEW_FAIL });
    } else {
      olibrToast.success(response.data.message);
      if(response.data.notiData) {
        socket?.emit("interview-schedule", {
          jobId: response.data.notiData.jobId,
          devId: response.data.notiData.devId,
          clientId: response.data.notiData.clientId
        });
      }
      navigate("/client/client-dashboard");
    }
  } catch (error) {
    yield put({ type: types.SCHEDULE_INTERVIEW_FAIL });
  }
}

export function* rescheduleInterviewSaga({ id, navigate, interviewData, socket }) {
  try {
    yield put({ type: types.RESCHEDULE_INTERVIEW_START });
    const response = yield call(saveScheduleInterview, { id, interviewData });
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.RESCHEDULE_INTERVIEW_FAIL });
    } else {
      if (response.data.statusValue === 400) {
        olibrToast.error(response.data.message);
        yield put({ type: types.RESCHEDULE_INTERVIEW_FAIL });
      } else {
        olibrToast.success(response.data.message);
        if(response.data.notiData) {
          socket?.emit("interview-reschedule", {
            jobId: response.data.notiData.jobId,
            devId: response.data.notiData.devId,
            clientId: response.data.notiData.clientId
          });
        }
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();
        yield put({
          type: types.GET_INTERVIEWS_DATA,
          navigate,
          filters: `?month=${currentMonth}&year=${currentYear}`,
        });
      }
    }
  } catch (error) {
    yield put({ type: types.RESCHEDULE_INTERVIEW_FAIL });
  }
}

export function* cancelInterviewSaga({ id, navigate, interviewData, socket }) {
  try {
    yield put({ type: types.CANCEL_INTERVIEW_START });
    const response = yield call(cancelScheduleInterview, { id, interviewData });
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.CANCEL_INTERVIEW_FAIL });
    } else {
      olibrToast.success(response.data.message);
      socket?.emit("interview-cancel", {
        jobId: response.data.notiData.jobId,
        devId: response.data.notiData.devId,
        clientId: response.data.notiData.clientId
      });
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();
      yield put({
        type: types.GET_INTERVIEWS_DATA,
        navigate,
        filters: `?month=${currentMonth}&year=${currentYear}`,
      });
    }
  } catch (error) {
    yield put({ type: types.CANCEL_INTERVIEW_FAIL });
  }
}

export function* acceptInterviewRequestSaga({
  navigate,
  slot,
  onSlotAcceptingDone,
  socket
}) {
  try {
    yield put({ type: types.ACCEPT_INTERVIEW_START });
    const response = yield call(acceptInterviewRequest, slot);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      onSlotAcceptingDone();
      yield put({ type: types.ACCEPT_INTERVIEW_FAIL });
    } else {
      if (response.data.statusValue === 400) {
        olibrToast.error(response.data.message);
        yield put({ type: types.ACCEPT_INTERVIEW_FAIL });
      } else {
        olibrToast.success(response.data.message);
        socket?.emit("interview-reschedule-request-accept", {
          jobId: response.data.notiData.jobId,
          devId: response.data.notiData.devId,
          clientId: response.data.notiData.clientId
        });
        onSlotAcceptingDone();
        yield put({
          type: types.ACCEPT_INTERVIEW_SUCCESS,
          data: null,
        });
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();
        yield put({
          type: types.GET_INTERVIEWS_DATA,
          navigate,
          filters: `?month=${currentMonth}&year=${currentYear}`,
        });
      }
    }
  } catch (error) {
    onSlotAcceptingDone();
    yield put({ type: types.ACCEPT_INTERVIEW_FAIL });
  }
}

export function* getInterviewsDataSaga({ navigate, filters }) {
  try {
    yield put({ type: types.GET_INTERVIEWS_DATA_START });
    const response = yield call(getScheduledInterviews, filters);
    if (response?.data?.statusValue === 400) {
      olibrToast.error(response.data.message);
      yield put({ type: types.GET_INTERVIEWS_DATA_FAIL });
    } else if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.GET_INTERVIEWS_DATA_FAIL });
    } else {
      yield put({
        type: types.GET_INTERVIEWS_DATA_SUCCESS,
        data: response?.data?.interviewsData,
      });
    }
  } catch (error) {
    olibrToast.error(error?.message);
    yield put({ type: types.GET_INTERVIEWS_DATA_FAIL });
  }
}

export function* jobRefreshSaga({ navigate, jobId, pageData }) {
  try {
    yield put({ type: types.JOB_REFRESH_START });
    const response = yield call(jobRefreshApi, jobId);
    if (response.data.status === 401) {
      yield put({ type: types.JOB_REFRESH_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_CLIENT_ALL_JOBLISTS,
        navigate,
        pageData,
        id: null,
      });
      olibrToast.success(response.data.message);
    }
  } catch (error) {
    yield put({ type: types.JOB_REFRESH_FAIL });
  }
}

export function* getDevFilterSaga({ navigate, pageData, filter, firstTime }) {
  try {
    yield put({ type: types.GET_DEV_FILTER_START });
    const response = yield call(devFilterClient, pageData, filter);
    if (response.data.status === 401) {
      yield put({ type: types.GET_DEV_FILTER_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_DEV_FILTER_SUCCESS,
        navigate,
        data: response?.data,
        firstTime: firstTime,
      });
    }
  } catch (error) {
    
    yield put({ type: types.GET_DEV_FILTER_FAIL });
  }
}

export function* updateSnoozeSaga({ navigate, setChecked }) {
  try {
    yield put({ type: types.UPDATE_SNOOZE_START });
    const response = yield call(updateDevSnoozeApi);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_SNOOZE_START });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      setChecked(response?.data?.isProfileSnooze);
      yield put({
        type: types.UPDATE_SNOOZE_SUCCESS,
        navigate,
      });
    }
  } catch (error) {
    yield put({ type: types.UPDATE_SNOOZE_FAIL });
  }
}

export function* getSubscriptionSummarySaga({ navigate,cb }) {
  try {
    yield put({ type: types.GET_SUMMARY_SUBSCRIPTION_START });
    const response = yield call(subscriptionSummaryApi);
    if (response.data.status === 401) {
      yield put({ type: types.GET_SUMMARY_SUBSCRIPTION_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      cb && cb()
      yield put({
        type: types.GET_SUMMARY_SUBSCRIPTION_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_SUMMARY_SUBSCRIPTION_FAIL });
  }
}

export function* getClientPaymentHistorySaga({ navigate ,clientId}) {
  try {
    yield put({ type: types.GET_CLIENT_PAYMENT_HISTORY_START });
    const response = yield call(clientPaymentHistoryApi,clientId);
    if (response.data.status === 401) {
      yield put({ type: types.GET_CLIENT_PAYMENT_HISTORY_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_CLIENT_PAYMENT_HISTORY_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_CLIENT_PAYMENT_HISTORY_FAIL });
  }
}

export function* cancelActivePlanSaga({ navigate }) {
  try {
    yield put({ type: types.GET_CANCEL_ACTIVE_PLAN_START });
    const response = yield call(cancelPlanApi);
    if (response.data.status === 401) {
      yield put({ type: types.GET_CANCEL_ACTIVE_PLAN_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      olibrToast.success(response?.data?.message)
      const responseSub = yield call(subscriptionSummaryApi);
      yield put({
        type: types.GET_SUMMARY_SUBSCRIPTION_SUCCESS,
        data: responseSub?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_CANCEL_ACTIVE_PLAN_FAIL });
  }
}

export function* verifyPageRestrictionSaga({ payload }) {
  try {
    const response = yield call(clientRestrictionApi,payload);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      payload?.navigate && payload?.navigate("/login");
    } else {
      if (response.data.statusValue === 200) {
        payload?.cb && payload?.cb()
        payload?.routes && payload?.navigate(payload?.routes);
      } else {
        olibrToast.error(response?.data?.message);
        payload?.errorRoute && payload?.navigate(payload?.errorRoute);
      }
    }
  } catch (error) {
    olibrToast.error("Network Issue");
  }
}


export function* deleteEmailSaga({ payload }) {
  try {
    yield put({ type: types.DELETE_EMAIL_START });
    const response = yield call(deleteEmailAPI,payload);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      payload?.navigate && payload?.navigate("/login");
      yield put({ type: types.DELETE_EMAIL_FAIL });
    } else {
      if (response.data.statusValue === 200) {
        payload?.cb && payload?.cb()
        yield put({ type: types.DELETE_EMAIL_SUCCESS });
        olibrToast.success(response.data.message)
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.DELETE_EMAIL_FAIL });
      }
    }
  } catch (error) {
    olibrToast.error("Network Issue");
    yield put({ type: types.DELETE_EMAIL_FAIL });
  }
}
