import React, { useEffect } from 'react'
import SelectOption from '../../../components/select/SelectOption';
import { useState } from 'react';
import OlibrDatePicker from '../../../components/olibr-datepicker';


const Filter = ({ filterObj, setFilterObj }) => {
    const jobStatus = ["open", "closed", "paused", "pending", "rejected"];    return (
        <div className='container'>
            <div className="row">
                <div className="col-6">
                    <div className="form-group">
                        <label
                            className="floating-label"
                            style={{
                                background: "white",
                                zIndex: 1,
                                padding: "3px 5px 0px 5px",
                            }}
                        >
                            Status
                        </label>
                        <SelectOption
                            searchable={true}
                            optionArray={jobStatus?.map((item) => ({
                                value: item,
                                label: item,
                            }))
                            }
                            state={filterObj?.jobStatus ? { label: filterObj?.jobStatus, value: filterObj?.jobStatus } : null}
                            onChangeFunction={(e) => {
                                setFilterObj({
                                    ...filterObj,
                                    jobStatus: e?.value

                                })
                            }}
                            closeMenuOnSelect={true}
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label className="floating-label" style={{ zIndex: 0 }}>
                            Job Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={filterObj?.jobTitle}
                            onChange={(e) =>
                                setFilterObj({
                                    ...filterObj,
                                    jobTitle: e.target.value,
                                })
                            }
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label className="floating-label" style={{ zIndex: 0 }}>
                            Company Name
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={filterObj?.companyName}
                            onChange={(e) =>
                                setFilterObj({
                                    ...filterObj,
                                    companyName: e.target.value,
                                })
                            }
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label className="new-label">Posted From</label>
                        <OlibrDatePicker
                            onChange={(e) => setFilterObj({ ...filterObj, fromDate: e })}
                            value={filterObj?.fromDate ? filterObj?.fromDate : ""}
                            placeholder={"Posted From"}
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label className="new-label">To</label>
                        <OlibrDatePicker
                            onChange={(e) => setFilterObj({ ...filterObj, toDate: e })}
                            value={filterObj?.toDate ? filterObj?.toDate : ""}
                            placeholder={"To"}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filter