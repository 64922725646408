import React from "react";
import "../../../../src/view/pricing-page/pricing-page.css";
import { useNavigate } from "react-router";

const PaymentSuccess = () => {
    const navigate=useNavigate();
  return (
    <div className="payment-result">
      <div className="payment-msg payment-success">
        <i className="fa fa-solid fa-check-circle"></i>
        <h1>payment successfully done!</h1>
        <p>
          We are delighted to inform you that we have received your payment.
        </p>
      </div>
      <div>
        <button class="btn" onClick={()=>navigate("/client/subscription")} >
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccess;
