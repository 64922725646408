import React, {  useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postBlogCat } from "../../../service/action/admin";
import CategoryList from "./blog-listing-common/CategoryList";

const catList = [
  {
    categoryName: "Category 1",
    categoryDesc: "Lorem Ipsum Text",
    categoryStatus: true,
  },
  {
    categoryName: "Category 2",
    categoryDesc: "Lorem Ipsum Text is here",
    categoryStatus: false,
  },
];


const BlogCatList = () => {

  const {
    register: categoryRegister,
    handleSubmit: categorySubmit,
    reset: resetCategoryForm,
    formState: { errors: categoryError },
  } = useForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(()=>{
    resetCategoryForm();
  },[])
  const handleSubmitCat = (data) => {
    dispatch(postBlogCat(navigate, { ...data, categoryStatus: true }, resetCategoryForm));
  }


  return (
    <form>
      <div className="main-div-developer">
        <div className="skills-management">
          <div className="block-header">
            <h4 className="">Add Category</h4>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="">
                <label className={`floating-label ${categoryError?.categoryName && "text-danger"}`}>Category Name*</label>
                <input
                  name="categoryName"
                  type="text"
                  className="form-control"
                  placeholder=""
                  {
                  ...categoryRegister("categoryName", {
                    required: true
                  })
                  }
                />
              </div>
            </div>
            <div className="col-12">
              <div className="">
                <label className={`floating-label ${categoryError?.categoryDesc && "text-danger"}`}>Category Description*</label>
                <textarea
                  name="categoryDesc"
                  type="text"
                  className="form-control"
                  placeholder=""
                  rows={3}
                  {
                  ...categoryRegister("categoryDesc", {
                    required: true
                  })
                  }
                />
              </div>
            </div>
            <div className="col-12 text-right">
              <br />
              <a className="submitBtn " onClick={() => navigate(-1)} >Cancel</a><spam> </spam>
              <a className="submitBtn" onClick={categorySubmit(handleSubmitCat)} >SUBMIT</a>
            </div>
          </div>
          <br />
          <div className="block-header">
            <h4>Blog Categories</h4>
          </div>
          <div className="table-div dev-page-table">
            <CategoryList isLoading={false} CategoryList={catList} />
          </div>
        </div>
        <br />
        <div>
        </div>
      </div>
    </form>
  );
};

export default BlogCatList;
