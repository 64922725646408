import React, { Fragment, useEffect, useRef } from "react";
import "./otp.css";
import { useState } from "react";
import { OtpDigit } from "./OtpDigit";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { Icon } from "../icon";
import { olibrToast } from "../toaster";

export const OtpGroup = ({
  inputValues,
  setInputValues,
  onSubmit,
  onResend,
  changeNo,
  sentOtp = false,
  phoneNumber = "",
  countryCode=""
}) => {
  const { verifyOtpLoading } = useSelector((state) => state.developerReducer);
  const [newNumber,setNewNumber]=useState()
  const handleInputChange = (inputId, value) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [inputId]: value,
    }));
  };
  const handleSubmit = () => {
    onSubmit();
  };

  const onResendClick = () => {
    onResend();
    setSeconds(59);
  };

  useEffect(()=>{
    setNewNumber(phoneNumber)
  },[phoneNumber])

  const [seconds, setSeconds] = useState(59);
  useEffect(() => {
    if(sentOtp){
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }
  }, [seconds,sentOtp]);

  const handleChange=(event)=> {
    // Get the entered value from the input field
    let inputValue = event.target.value;

    // Remove non-numeric characters
    inputValue = inputValue.replace(/[^0-9]/g, '');

    // Restrict the length to a maximum of 10 digits
    if (inputValue.length <= 10) {
      setNewNumber(inputValue);
    }

    // Update the state with the sanitized value
   
  };

  const handleResend=()=>{
    if(newNumber?.length ===10){
    onResend(newNumber)
    }else{
      olibrToast.error("Enter correct number")
    }
  }


  return (
    <>
      <Fragment>
        <div style={{ textAlign: "center" }}>
          <div>
            <Icon name="otp-verify" />
          </div>
          {changeNo && (
            <div className="number-verification">
                  <input type="number" value={newNumber} onChange={handleChange}/>
                  <a onClick={handleResend} href="#-" className="link">
                    Send OTP
                  </a>
                </div>
          )}

          {sentOtp && (
            <p>
              Enter the OTP sent to <strong>+{countryCode} {phoneNumber}</strong>
            </p>
          )}
          <div
            id="OTPInputGroup"
            className={"digitGroup"}
            data-autosubmit="true"
          >
            <OtpDigit
              id="input1"
              value={inputValues.input1}
              onValueChange={handleInputChange}
              previousId={null}
              handleSubmit={handleSubmit}
              nextId="input2"
              disabled={!sentOtp}
            />
            <OtpDigit
              id="input2"
              value={inputValues.input2}
              onValueChange={handleInputChange}
              previousId="input1"
              handleSubmit={handleSubmit}
              nextId="input3"
              disabled={!sentOtp}
            />
            <OtpDigit
              id="input3"
              value={inputValues.input3}
              onValueChange={handleInputChange}
              previousId="input2"
              handleSubmit={handleSubmit}
              nextId="input4"
              disabled={!sentOtp}
            />
            <OtpDigit
              id="input4"
              value={inputValues.input4}
              onValueChange={handleInputChange}
              previousId="input3"
              handleSubmit={handleSubmit}
              nextId="input5"
              disabled={!sentOtp}
            />
            <OtpDigit
              id="input5"
              value={inputValues.input5}
              onValueChange={handleInputChange}
              previousId="input4"
              handleSubmit={handleSubmit}
              nextId="input6"
              disabled={!sentOtp}
            />
            <OtpDigit
              id="input6"
              value={inputValues.input6}
              onValueChange={handleInputChange}
              previousId="input5"
              handleSubmit={handleSubmit}
              disabled={!sentOtp}
            />
          </div>
          {
            sentOtp && (
            <div>
            Didn't recieve OTP?{" "}
            <button
              style={{
                color: "#F57A2B",
                outline: "none",
                border: "none",
                background: "#fff",
              }}
              type="button"
              onClick={() => onResendClick()}
              disabled={seconds > 0}
              className="cursor"
            >
              {!seconds ? "RESEND" : seconds}
            </button>
          </div>)}
          <div>
            <span
              className="btn"
              onClick={() => !verifyOtpLoading && sentOtp && onSubmit()}
            >
              {verifyOtpLoading ? (
                <ClipLoader color="#fff" size={20} />
              ) : (
                "Verify & Continue"
              )}
            </span>
          </div>
        </div>
      </Fragment>
    </>
  );
};
