import React, { Fragment, useContext, useEffect, useState } from "react";
import { Icon } from "../../../../components/icon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  applyNewJob, applyNewMultijob,
} from "../../../../service/action/developer";
import { MainContext } from "../../../../context/MainContext";
import { olibrToast } from "../../../../components/toaster";
import JobLists from "../../../../components/joblists/JobLists";
import MetaTagSEO from "../../../../components/seo/MetaTagSEO";
import Modal from "../../../../components/modal/ModalBox";
import ApplyAllQuestionForm from "../../../../components/questionaries/ApplyAllQuestionForm";
import { checkDevCountry, checkQuestionLists, checkQuestionaries } from "../../../../components/functions";
const MainBox = ({ mainJobList, appliedArr, fetchMoreData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useContext(MainContext);
  const {isSubmitQuesLoading}=useSelector((state)=>state?.developerReducer)
  const [questionDetail, setQuestionDetail] = useState({
    modal: false,
    question: [],
    data: {}
  })
  const [applyData, setApplyData] = useState([]);
  const closeQuesModal=()=>{
    setQuestionDetail({
      modal:false,
      data:"",
      question:[]
    })
    setApplyData([])
  }

  const handleJobApply = () => {
  
    if (!user?.userType) {
      navigate("/login");
    } else if(!user?.numberVerified){
      olibrToast.error("To apply, please verify your phone number. You can do this by visiting the settings page and completing the verification process.")
    } else {
      if (user?.userType === "Developer" && user?.devStatus == "approved") {
        let devId = user?._id;
        let tempCategory=[];
        let countryNotExists=false;
        applyData?.map((item)=>{
          if(!checkDevCountry(user,item)){
          countryNotExists=true
          return false
          }
          if(item?.questionaries && checkQuestionaries(item?.questionaries)){
            let tempObj=[]
            item?.questionaries?.map((itm)=>{
              itm?.questionLists?.map((qu)=> qu?.isAdded &&(
                tempObj.push({...qu,answer:""})
              ))
            })
            tempCategory?.push({
              jobName:item?.jobTitle,
              jobId:item?._id,
              questionLists:tempObj
            })
          }else{
            tempCategory?.push({
              jobId:item?._id,
            })
          }
        })
        if(countryNotExists){
          return false
        }

        if(checkQuestionLists(tempCategory)){
          setQuestionDetail({
            data:{ devId, jobId: applyData },
            questionLists:tempCategory,
            modal:true
          })
        }else{
          dispatch(applyNewJob({ devId, jobId: applyData }, navigate,closeQuesModal));
        }
      } else {
        olibrToast.error(
          "Your Olibr profile is not complete yet. You will be eligible to apply once your profile is complete"
        );
      }
    }
  };


  const handleCheckBox = (jobId) => {
    let checkExist = applyData?.includes(jobId);
    if (checkExist) {
      const filters = applyData.filter((item) => item !== jobId);
      setApplyData(filters);
    } else {
      setApplyData([...applyData, jobId]);
    }
  };

 

  const applyJobWithQuestion=(data)=>{
    dispatch(applyNewMultijob({ ...questionDetail?.data,devAnswer:data }, navigate,closeQuesModal));
  }


  return (
    <Fragment>
        <Modal
        open={questionDetail?.modal}
        hasHeader={false}
        content={() => <ApplyAllQuestionForm isLoading={isSubmitQuesLoading} closeQuesModal={closeQuesModal} applyJobWithQuestion={applyJobWithQuestion} data={questionDetail?.questionLists}/>}
        isCancelAble={false}
        size="60%"
        contentClass="questionaries-header"
        isFooterView={false}

      />
      <MetaTagSEO helmetName="jobs" />

      {applyData?.length > 0 && (
        <div className="job-actions">
          <div className="txt">
            <p>You Have Selected {applyData?.length} Jobs</p>
          </div>
          <div className="btn-block">
            <span className="btn" onClick={handleJobApply}>
              Apply All
            </span>
            <span className="btn btn-sm bg-red-button"
              onClick={() => setApplyData([])}
            >Cancel</span>
          </div>
        </div>
      )}
      {/* <InfiniteScroll
        dataLength={mainJobList?.data ? mainJobList?.data?.length : 0}
        next={fetchMoreData}
        hasMore={mainJobList?.totalJobPosts <= mainJobList?.data?.length ? false : true
        }
      > */}
        <JobLists
          appliedArr={appliedArr}
          applyData={applyData}
          mainJobList={mainJobList}
          handleCheckBox={handleCheckBox}
          fetchMoreData={fetchMoreData}
        />
        {
          !mainJobList?.data?.length && (
            <p>No records found</p>
          )
        }
      {/* </InfiniteScroll> */}
    </Fragment>
  );
};

export default MainBox;
