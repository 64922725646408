import React, { Fragment } from 'react'
import {backHome, gohome} from "../../common/constant/error";
import { Link, useNavigate } from 'react-router-dom';
import PageNotFound from "./../../assets/img/404-error.jpg";
import { useEffect } from 'react';
import "./ErrorPage.css"
import MetaTagSEO from '../../components/seo/MetaTagSEO';

const ErrorPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/error-404");
    }, [navigate])

  return (
    <Fragment>
      <MetaTagSEO helmetName="error-page" />
      <div className="page-not-found">
        <div className="error-block">
          <figure>
            <img src={PageNotFound} alt="404 page not found" />
          </figure>
          <p>{gohome}</p>
          <div className="btn-block">
            <Link to="/" className="btn">
              {backHome}
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ErrorPage