import React, { Fragment, useContext, useEffect, useState } from "react";
import { MainContext } from "../../../../context/MainContext";
import { olibrToast } from "../../../../components/toaster";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { applyNewJob, getAppliedJob } from "../../../../service/action/developer";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";

import JobDetail from "../../../../components/joblists/JobDetail";
import MetaTagSEO from "../../../../components/seo/MetaTagSEO";
import Modal from "../../../../components/modal/ModalBox";
import DevQuestionForm from "../../../../components/questionaries/DevQuestionForm";
import { checkDevCountry, checkQuestionaries, checkSkillPassed, filterFrontUrlEnv } from "../../../../components/functions";

const DetailBox = ({
  jobDetail,
  catList,
  appliedArr,
  isJobLoading,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [categoryName, setCategoryName] = useState("");
  useEffect(() => {
    let arr = catList?.filter((item) => item?.id == jobDetail?.jobCategory);
    setCategoryName(arr?.[0]?.catName);
  }, [jobDetail]);

  const { user, user: { devStatus } } = useContext(MainContext);
  const [questionDetail, setQuestionDetail] = useState({
    modal: false,
    question: [],
    data: {}
  })

  useEffect(()=>{
    if(user?.userType=="Developer"){
    dispatch(getAppliedJob(navigate))
    }
  },[user])

  const handleJobApply = (jobs) => {
    if (!user?.userType) {
      navigate("/login");
    } else if (user?.userType !== "Developer") {
      toast.error(
        `You are logged in as a ${user.userType?.toLowerCase()}. Please log out and log in as a developer to apply.`
      );
    } else if(!user?.numberVerified){
      olibrToast.error("To apply, please verify your phone number. You can do this by visiting the settings page and completing the verification process.")
    } else {
      if(!checkDevCountry(user,jobs))
      return false
      if (user?.userType === "Developer" && devStatus == "approved"
      //  && checkSkillPassed(user?.devTechTestResult, jobs?.devChooseSkills, jobs?.devChooseStacks)
       ) {
      if (jobs?.questionaries?.length && checkQuestionaries(jobs?.questionaries)) {
          let jobArr = [];
          let devId = user?._id;
          let jobId = jobDetail?._id;
          jobArr.push(jobId);
          let clientId = jobDetail?.clientId || "";
          setQuestionDetail({
            modal: true,
            question: jobs?.questionaries,
            data: { devId, jobId: jobArr, clientId }
          })
        } else {
          dispatch(applyNewJob({ devId: user?._id, jobId: [jobDetail?._id], clientId: jobDetail?.clientId, devAnswer: [] }, navigate, closeQuesModal));
        }
      } else {
        olibrToast.error(
          "Your Olibr profile is not complete yet. You will be eligible to apply once your profile is complete"
        );
      }
    }
  };

  const navigateOtherJob = (prev) => {
    if (prev) {
      navigate(`/jobs/${jobDetail?.prevId}`);
    } else {
      navigate(`/jobs/${jobDetail?.nextId}`);
    }
  };

  const handleCompanyDetail = (companySlug) => {
      if (companySlug) {
        window.open(filterFrontUrlEnv(`company-profile/${companySlug}`));
      }
      }

  const closeQuesModal = () => {
    setQuestionDetail({
      modal: false,
      data: "",
      question: []
    })
  }
  const applyJobWithQuestion = (data) => {
    dispatch(applyNewJob({ ...questionDetail?.data, devAnswer: data }, navigate, closeQuesModal));
  }

  return (
    <Fragment>
      <Modal
        open={questionDetail?.modal}
        hasHeader={false}
        content={() => <DevQuestionForm closeQuesModal={closeQuesModal} applyJobWithQuestion={applyJobWithQuestion} data={questionDetail?.question} />}
        isCancelAble={false}
        size="60%"
        contentClass="questionaries-header"
        isFooterView={false}

      />
      <MetaTagSEO helmetName={"job-detail"} jobdetailprop={jobDetail} />
      {isJobLoading ? (
        <div
          style={{ textAlign: "center", height: "50vh", alignItems: "center" }}
        >
          <ClipLoader color="#3ba0aa" />
        </div>
      ) : (
        <div>
          <div className="row next-prev-job">
            <div className="col-2">
              {jobDetail?.prevId && (
                <span onClick={() => navigateOtherJob(1)}>{"< PREVIOUS"}</span>
              )}
            </div>
            <div className="col-8" style={{ textAlign: "center" }}>
              <span onClick={() => navigate("/jobs")}>JOB LIST</span>
            </div>
            <div className="col-2 text-right">
              {jobDetail?.nextId && (
                <span onClick={() => navigateOtherJob(0)}>{"NEXT >"}</span>
              )}
            </div>

            {/* <span>{"NEXT >"}</span> */}
          </div>
          <JobDetail
            apply={handleJobApply}
            details={handleCompanyDetail}
            appliedArr={appliedArr}
            jobDetail={jobDetail}
            categoryName={categoryName}
          />
        </div>
      )}
    </Fragment>
  );
};

export default DetailBox;
