import React, { useEffect } from "react";
import { Fragment } from "react";
import { useRef } from "react";
import { useState } from "react";
import Modal from "../../../components/modal/ModalBox";
import { getApprovalUpdateAction, uploadVideoWithoutDeleteAction } from "../../../service/action/common";
import { useNavigate } from "react-router-dom";
import { olibrToast } from "../../../components/toaster";
import { useDispatch, useSelector } from "react-redux";
import { getDevVideoAction } from "../../../service/action/developer";
import {
  congratsVideo,
  creatingVideo,
  instructionBullets,
  lights,
  paraOne,
  paraThree,
  paraTwo,
} from "../../../common/constant/videoInstructions";
import PercentageLoaderContent from "../../../components/percentageLoader/PercentageLoaderContent";
import { MainContext } from "../../../context/MainContext";
import { useContext } from "react";

const VideoBody = () => {
  const uploadVideoRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    user: { email },
  } = useContext(MainContext);

  const { devVideo } = useSelector((state) => state.developerReducer);
  const { uploadVideoLoad, dataForApproval } = useSelector((state) => state.commonReducer);
  useEffect(() => {
    dispatch(getDevVideoAction(navigate));
    dispatch(getApprovalUpdateAction(navigate, email));
  }, []);
  console.log("datafor aprroval", dataForApproval);

  const [videoPercentage, setVideoPercentage] = useState(0);
  const onUploadIntroductoryVideo = (e) => {
    let formData = new FormData();
    formData.append("videoFile", e.target.files[0]);
    if (e.target.files[0]?.size > 150 * 1024 * 1024) {
      olibrToast.error("Please use video of size less than 150MB");
    } else {
      dispatch(uploadVideoWithoutDeleteAction(formData, navigate, setVideoPercentage, true));
    }
    e.target.value = null;
  };
  const [frameOpen, setFrameOpen] = useState(false);

  const isVideoUploaded = () => {
    let app_updates = dataForApproval?.filter((item) => item?.slug === "devVideo");
    // if(!!devVideo){
    //   return devVideo;
    // }
    // else
    if(!!app_updates){
      return app_updates?.[0]?.newValue;
    }
    return "";
  }

  const handleFrameContent = () => {
    return (
      <Fragment>
        {frameOpen && (
          <iframe
            src={!!devVideo ? devVideo : ""}
            width="100%"
            height="500"
          ></iframe>
        )}
      </Fragment>
    );
  };
  const closeFrameModal = () => {
    setFrameOpen(false);
  };

  const openVideoPreview = () => {
    let checkApproval = dataForApproval?.filter((item) => item?.slug === "devVideo").length
    if (!!devVideo) {
      setFrameOpen(true);
    } else if(!!checkApproval){
      olibrToast.info("You will get to see the video once it is approved by the Admin.");
    } else {
      olibrToast.error("No Preview Available!");
    }
  };

  const uploadVideoLogic = () => {
    let checkApproval = dataForApproval?.filter((item) => item?.slug === "devVideo").length
    // if (!!devVideo) {
    //   olibrToast.info(
    //     `Please go to profile page and update your video from 'Edit' button on top right`
    //   );
    // } else 
    if(!!checkApproval){
      olibrToast.info(
        `Your Video is under review.`
      );
    } else {
      uploadVideoRef.current.click();
    }
  };

  return (
    <Fragment>
      <Modal
        size="80%"
        open={frameOpen}
        onClose={closeFrameModal}
        content={handleFrameContent}
        isFooterView={false}
        title={"Introductory Video"}
      />
      <PercentageLoaderContent
            uploadLoading={uploadVideoLoad}
            uploadPercentage={videoPercentage}
          />
      <input
        ref={uploadVideoRef}
        type="file"
        onChange={onUploadIntroductoryVideo}
        className="input-file"
        accept=".mp4, .webm, .mkv, .mov"
      />
      <div className="container">
        <div className="video-banner-bg">
          <div className="videobanner-text">
            <h3>Add a video profile to boost your hiring chances</h3>
            <button
              className="themeBtn submitBtn redButton"
              onClick={uploadVideoLogic}
            >
              Add Video
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="video-inst">
              <h4>Hey there, brilliant minds of Olibr!</h4>
              <p>{paraOne}</p>
              <p>{paraTwo}</p>
              <p>{paraThree}</p>
              <div className="col-12 video-inst-box">
                <div style={{ textAlign: "center" }}>
                  <span>
                    <h4>Instructions for creating a video</h4>
                  </span>
                </div>
                <p>{creatingVideo}</p>
                <ul>
                  {instructionBullets?.map((item) => (
                    <Fragment>
                      <li>{item?.title}</li>
                      <p>{item?.para}</p>
                    </Fragment>
                  ))}
                </ul>
              </div>
              <p>{congratsVideo}</p>
              <p>{lights}</p>
              <p>
                <strong>Note: </strong>The video should be about 90 seconds long
                and it should be less than 150 MB.
              </p>
            </div>
          </div>
        </div>
        <div style={{ display: "block", textAlign: "center" }}>
          <span
            className="link"
            style={{
              display: "inline",
              color: isVideoUploaded() && "#e2e2e2",
              cursor: "pointer",
            }}
            onClick={uploadVideoLogic}
          >
            UPLOAD &nbsp;&nbsp;
          </span>
          {" | "}
          <span
            className="link"
            style={{
              display: "inline",
              color: !devVideo && "#e2e2e2",
              cursor: "pointer",
            }}
            onClick={openVideoPreview}
          >
            &nbsp;&nbsp;PREVIEW
          </span>
        </div>
      </div>
    </Fragment>
  );
};

export default VideoBody;
