import {
  category,
  position,
  publishedOn,
  statusText,
} from "../../../common/staticData/contentWriter/jobManagement";
import React from "react";
import Tooltips from "../../../components/tooltip/tooltips/Tooltips";
import { DateSortingDataTable } from "../../../components/functions";

const ColumnLists = (categObj, handleApprovalConfirm,openApplicants) => {
  const columns = [
    {
      name: "S.No",
      selector: "sNo",
      width: "97px",
      cell: (row, index) => index + 1,
    },
    {
      name: position,
      selector: "jobTitle",
      sortable: true,
      width: "200px",
    },
    {
      name: "Location",
      selector: "jobLocation",
      sortable: true,
      width: "120px",
      cell: (row) => {
        if (row?.jobAddress?.length > 1) {
          return "Multiple";
        } else if (row?.jobAddress?.length === 1 && row?.jobAddress[0]?.city) {
          return String(
            row?.jobAddress[0]?.city + ", " + row?.jobAddress[0]?.state
          );
        }
        return null;
      },
    },
    {
      name: "Skills/Stacks",
      selector: "devChooseSkills",
      sortable: true,
      width: "300px",
      cell: (row) => (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {row?.devChooseSkills?.map((tagItem) => (
            <span>{tagItem?.skillName},&nbsp;</span>
          ))}
          {row?.devChooseStacks?.map((tagItem) => (
            <span>{tagItem?.stackName},&nbsp;</span>
          ))}
        </div>
      ),
    },
    {
      name: "Applicants",
      selector: "applicantsCount",
      sortable: true,
      cell:(row)=>(
        <span 
        style={{cursor:"pointer"}}
        onClick={(e)=>openApplicants(e,row?.clientId,row?._id,row?.applicantsCount)}
        >
          {row?.applicantsCount}
        </span>
      ),
      width: "120px",
    },
    {
      name: "Shortlisted",
      selector: "shortlistedCount",
      sortable: true,
      width: "120px",
    },
    {
      name: "Company Name",
      selector: "organisation",
      width: "160px",
      sortable: true,
      // cell: (row) => (
      //     categObj?.[row?.jobCategory]
      // )
    },
    {
      name: category,
      selector: "jobCategory",
      width: "120px",
      sortable: true,
      cell: (row) => categObj?.[row?.jobCategory],
    },
    {
      name: publishedOn,
      selector: "createdAt",
      width: "130px",
      sortable: true,
      cell: (row) =>
        new Date(row?.createdAt).toDateString().split(" ").slice(1).join(" "),
      sortFunction: (a, b) => DateSortingDataTable(a, b, "createdAt"),
    },
    {
      name: "Approved",
      selector: "clientId",
      sortable: true,
      cell: (row) =>
        !!row?.clientId ? (
          <span>
            <input
              type="checkbox"
              name="switch"
              onChange={(e) => e.preventDefault()}
              checked={row?.isApproved}
            />
            <label
              onClick={(e) => {
                e.stopPropagation();
                handleApprovalConfirm(row);
              }}
              className="label"
              htmlFor="switch3"
            ></label>
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: statusText,
      selector: "status",
      sortable: true,
      width: "140px",
      cell: (row) => (
        <span
          style={{
            color: row?.status == "open" ? "#679B31" : "#F15C3B",
          }}
        >
          {row.status === "rejected" && row.feedback ? (
            <Tooltips
              content={
                <div>
                  <div>Rejected by: {row?.rejectedBy}</div>
                  <div>
                    Rejected on:
                    {new Date(row?.rejectedOn)?.toDateString()}
                  </div>
                  <div>
                    Feedback:
                    {row.feedback}
                  </div>
                </div>
              }
              direction="top2"
            >
              {row?.status?.toUpperCase()}
            </Tooltips>
          ) : (
            <span>{row?.status?.toUpperCase()}</span>
          )}
        </span>
      ),
    },
  ];
  return columns;
};

export default ColumnLists;
