import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ClipLoader } from "react-spinners";
import {
  getAdminSkillLists,
  getAdminStackLists,
  getAdminSkillResult,
  updateAdminSkillTest,
} from "../../../service/action/admin";
import SelectOption from "../../../components/select/SelectOption";
const SkillTestClear = ({ HandleDropdwon,readOnly }) => {
  const pageData = {
    page: 0,
    limit: 0,
    search: "",
  };

  const {
    register: skillRegister,
    setValue: setSkillValue,
    handleSubmit: skillSubmit,
    getValues: getSkillValue,
    control: skillControl,
    reset: resetSkillForm,
    watch,
    formState: { errors: skillError },
  } = useForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { skillList, stackList, devSkillTestResult, isUpdateLoading } =
    useSelector((state) => state.adminReducer);
  const [allSkills, setAllSkills] = useState([]);


  const obtainedScore=watch("obtainedScore");
  const cutOffScore=watch("cutOffScore");

  useEffect(() => {
    dispatch(getAdminSkillLists("profile", navigate, pageData));
    dispatch(getAdminStackLists("stack", navigate, pageData));
  }, []);
  useEffect(() => {
    let tempSkill = [];
    skillList?.data &&
      skillList?.data.map((item) => {
        tempSkill.push({
          _id: item._id,
          value: item.aId,
          label: item?.skillName,
          skillType: "skill",
        });
      });

    setAllSkills([...allSkills, ...tempSkill]);
  }, [skillList]);

  useEffect(() => {
    let tempSkill = [];
    stackList?.data &&
      stackList?.data.map((item) => {
        tempSkill.push({
          _id: item._id,
          value: item.assessmentId,
          label: item?.stackName,
          skillType: "stack",
        });
      });
    setAllSkills([...allSkills, ...tempSkill]);
  }, [stackList]);

  const handleEmail = () => {
    getSkillValue("email") &&
      dispatch(getAdminSkillResult(navigate, getSkillValue("email")));
  };

  const handleSubmit = (data) => {
    const tempdata = {
      ...data,
      testId: data?.skills?.value,
      testName: data?.skills?.label,
      newSkill: data?.skills?.__isNew__,
    };

    dispatch(updateAdminSkillTest(navigate, tempdata));
  };
  useEffect(() => {
    resetSkillForm();
  }, []);
  return (
    <form>
      <div className="container">
        <div className="create-profile">
          <div className="block-header">
            <h4>Clear Skill Test</h4>
          </div>
          <HandleDropdwon />
          <div className="row">
            <div className="col-12">
              <div className="">
                <label
                  className={`new-label ${skillError?.email && "text-danger"}`}
                >
                  Emails*
                </label>
                <input
                  type="text"
                  {...skillRegister("email", {
                    required: true,
                  })}
                  onBlur={handleEmail}
                  name="email"
                  placeholder="Developer Email"
                />
              </div>
              <br />
            </div>
            <div className="col-6">
              <div className="">
                <label
                  className={`new-label ${skillError?.skills && "text-danger"}`}
                >
                  Skill*
                </label>
                <Controller
                  control={skillControl}
                  name="skills"
                  {...skillRegister("skills", {
                    required: true,
                  })}
                  render={({ field }) => (
                    <SelectOption
                      searchable={true}
                      optionArray={allSkills.sort()}
                      getValue={getSkillValue}
                      fieldProp={field}
                      getValueName={"skills"}
                    />
                    // <Select
                    //     isClearable
                    //     menuPortalTarget={document.body}
                    //     styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    //     options={allSkills}
                    //     value={getSkillValue("skills") ? getSkillValue("skills") : []}
                    //     onChange={(e) => field.onChange(e)}
                    // />
                  )}
                />
              </div>
              <br />
            </div>

            <div className="col-6">
              <div className="">
                <label
                  className={`new-label ${
                    skillError?.cutOffScore && "text-danger"
                  }`}
                >
                  Cutt-Off Score*
                </label>
                <input
                  type="number"
                  {...skillRegister("cutOffScore", {
                    required: true,
                  })}
                  name="cutOffScore"
                  placeholder="Cutt-Off Score"
                />
              </div>
              <br />
            </div>
            <div className="col-6">
              <div className="">
                <label
                  className={`new-label ${
                    skillError?.maxScore && "text-danger"
                  }`}
                >
                  Max Score*
                </label>
                <input
                  type="number"
                  {...skillRegister("maxScore", {
                    required: true,
                  })}
                  name="maxScore"
                  placeholder="Max Score"
                />
              </div>
              <br />
            </div>
            <div className="col-6">
              <div className="">
                <label
                  className={`new-label ${
                    skillError?.obtainedScore && "text-danger"
                  }`}
                >
                  Obtained Score*
                </label>
                <input
                  type="number"
                  name="obtainedScore"
                  {...skillRegister("obtainedScore", {
                    required: true,
                  })}
                  placeholder="Obtained Score"
                />
              </div>
              <br />
            </div>

            <div className="col-12 text-right">
              <button
                className="submitBtn themeButton"
                disabled={isUpdateLoading || readOnly || (parseInt(cutOffScore) > parseInt(obtainedScore))}
                onClick={skillSubmit(handleSubmit)}
              >
                {isUpdateLoading ? (
                  <ClipLoader size={15} color="#FFFFFF" />
                ) : (
                  "Submit"
                )}
              </button>
              &nbsp;&nbsp;
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SkillTestClear;
