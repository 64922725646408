import React from "react";
import PropTypes from "prop-types";
import ImageProject from "../../assets/img/project-modal.png";
const ProjectModalContent = ({ project }) => {
  return (
    <div className="news-block-two project-modal tnc-section">
      <div className="inner-box step-item">
        <div className="content-box">
          <h2 className="name">{project?.name || project?.projectName}</h2>
        </div>
        <div className="image">
          <img
            src={ImageProject}
            width={243}
            height={176}
            alt=""
          />
        </div>
      </div>
      <div className="row margin-top-30">
        {project?.url && <div className="col-4 margin-bottom-30">
          <h6>Role</h6>
          <p>{project?.url || project?.role}</p>
        </div>}
       {project?.clientName &&  <div className="col-4">
          <h6>Client Name</h6>
          <p>{project?.clientName}</p>
        </div>}
        {project?.projectIndus && (
          <div className="col-4">
            <h6>Project Industry</h6>
            <p>{project?.projectIndus}</p>
          </div>
        )}
        <div className="col-12 text-box desc">
          <h6>Description</h6>
          <div
            className="margin-top-10"
            dangerouslySetInnerHTML={{
              __html: project?.description || project?.projectDescription,
            }}
          ></div>
        </div>
       {!!project?.responsibilities && <div className="col-12 text-box">
          <h6>Roles & Responsibilities</h6>
          <div className="margin-top-10">
            <ul className="list-style-two"  dangerouslySetInnerHTML={{
              __html: project?.responsibilities,
            }}></ul>
          </div>
        </div>}
      {!!project?.skills?.length &&  <div className="col-12 text-box job-block">
          <h6>Skills Used</h6>
          <ul className="project-tags job-other-info margin-top-10">
            {project?.skills?.map((itm, index) => (
              <li className="time" key={index}>
                {itm}
              </li>
            ))}
          </ul>
        </div>}
      {!!project?.projectLinks?.some((itm)=>itm) && <div className="col-12 text-box margin-top-10">
          <h6>Project Links</h6>
          {project?.projectLinks?.map((itm,key)=>(
            <div className="margin-top-10" key={key}>
            <a href={itm} target="_blank" rel="noreferrer">{itm}</a>            
          </div>
          ))}
          
        </div>}
       {!!project?.image?.length &&  <div className="col-12 text-box margin-top-10">
          <h6>Gallery</h6>
          <div className="margin-top-10 portfolio-gallery">
           
             {project?.image?.map((item,key)=>(
              <div className="portfolio-image resume-block" key={key}>
              <img src={item} alt="" />
              </div>
             )) }
           
          </div>
        </div>}
      </div>
    </div>
  );
};
ProjectModalContent.propTypes = {
  project: PropTypes.object,
};

export default ProjectModalContent;
