import React, { Fragment, useEffect, useRef, useState } from "react";
import Modal from "../../../../../components/modal/ModalBox";
import { Controller, useForm } from "react-hook-form";
import OlibrEditor from "../../../../../components/editor";
import { Icon } from "../../../../../components/icon";
import { olibrToast } from "../../../../../components/toaster";
import { ClipLoader, ScaleLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import {
  getDeveloperProfile,
  updateAdminDevProject,
} from "../../../../../service/action/admin";
import { saveAdminDevProject } from "../../../../../service/action/admin";
import { useNavigate } from "react-router-dom";
import { uploadMultiImageAction } from "../../../../../service/action/common";

const ProjectContent = ({
  projectDetail,
  editProjectRef,
  userData,
  setDevProjectDetails,
  devProjectDetails,
  closeModalRef,
}) => {
  // const [openAiModalOpen,setOpenAiModalOpen]=useState(false)
  const {
    register: projectRegister,
    setValue: setProjectValue,
    handleSubmit: projectSubmit,
    getValues: getProjectValue,
    reset: resetProjectForm,
    // setError: setProjectError,
    control: projectControl,
    formState: { errors: projectError },
  } = useForm();
  const { isDevProjectImageLoading } = useSelector(
    (state) => state.developerReducer
  );
  const { isProjectSubmit } = useSelector((state) => state.adminReducer);
  const uploadProjectImageRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addSkillInProject = () => {
    if (!getProjectValue("projectSkill")) {
      olibrToast.error("please enter skill name!");
      return false;
    }
    if (devProjectDetails?.skills && devProjectDetails?.skills?.length > 0) {
      const check = devProjectDetails?.skills?.filter(
        (item) =>
          item?.toUpperCase() == getProjectValue("projectSkill")?.toUpperCase()
      );
      if (check?.length > 0) {
        olibrToast.error("tag already exists!");
        return false;
      } else {
        setProjectValue("skills", [
          ...getProjectValue("skills"),
          getProjectValue("projectSkill"),
        ]);
        setDevProjectDetails({
          ...devProjectDetails,
          skills: [
            ...devProjectDetails?.skills,
            getProjectValue("projectSkill"),
          ],
        });
      }
    } else {
      setProjectValue("skills", [getProjectValue("projectSkill")]);
      setDevProjectDetails({
        ...devProjectDetails,
        skills: getProjectValue("skills"),
      });
    }
    setProjectValue("projectSkill", "");
  };

  const [customLightBox, setCustomLightBox] = useState(false);
  const closeCustomLightBox = () => {
    setCustomLightBox(false);
  };
  const customLightContent = () => {
    return (
      <Fragment>
        <div>
          <section class="carousel" aria-label="carousel" Tabindex="0">
            <a class="carousel__skip-link" href="#skip-link">
              Carousel überspringen
            </a>
            <div class="slides">
              {devProjectDetails?.image?.map((item) => (
                <div
                  class="slides-item slide-1"
                  id={item}
                  aria-label="slide 1 of 5"
                  tabindex="0"
                >
                  <img src={item} alt="" />
                </div>
              ))}
            </div>
            <div class="carousel__nav">
              {devProjectDetails?.image?.map((item, index) => (
                <a
                  class="slider-nav"
                  href={`#${item}`}
                  aria-label="Go to slide 5"
                >
                  {index + 1}
                </a>
              ))}
            </div>
          </section>
        </div>
      </Fragment>
    );
  };

  const removeImg = (index) => {
    let arr = devProjectDetails?.image?.filter((_, ind) => index != ind);
    setDevProjectDetails({ ...devProjectDetails, image: arr });
  };

  const projectInfoModalClose = () => {
    setProjectValue("skills", "");
    resetProjectForm();
    setDevProjectDetails({
      modal: false,
      edit: false,
      image: [],
      skills: [],
    });
  };

  const callBackSave = () => {
    dispatch(getDeveloperProfile(userData?._id, navigate));
    resetProjectForm();
  };

  const handleDevProjectSubmit = (data) => {
    data?.hasOwnProperty("projectSkill") && delete data?.projectSkill;
    if (devProjectDetails?.edit) {
      let tempData = {
        image: devProjectDetails?.image ? devProjectDetails?.image : [],
        devId: userData?._id,
      };
      dispatch(
        updateAdminDevProject(
          navigate,
          { ...data, ...tempData },
          setDevProjectDetails,
          callBackSave,
          true
        )
      );
    } else {
      let tempData = {
        devId: userData?._id,
        image: devProjectDetails?.image ? devProjectDetails?.image : [],
      };
      dispatch(
        saveAdminDevProject(
          navigate,
          { ...data, ...tempData },
          setDevProjectDetails,
          callBackSave,
          true
        )
      );
    }
  };

  const callBack = (res) => {
    if (res?.data?.payload?.filename) {
      if (devProjectDetails?.image?.length) {
        setDevProjectDetails({
            ...devProjectDetails,
            image: [...devProjectDetails?.image,...res?.data?.payload?.filename],
          });
      } else {
        setDevProjectDetails({
          ...devProjectDetails,
          image: res?.data?.payload?.filename,
        });
      }
    }
  };

  const onUploadProjectImage = async (e) => {
    let formData = new FormData();
    for (let i = 0; i < e?.target?.files?.length; i++) {
      if (e.target.files[i]?.size > 1024 * 1024) {
        olibrToast.error("Please use images of size less than 1MB each.");
        return false;
      }
      formData.append("imageFiles", e.target.files[i]);
    }
    dispatch(uploadMultiImageAction(formData, navigate, callBack));
    e.target.value = null;
  };
  const handleProjectDetailsEdit = () => {
    setDevProjectDetails({
      ...devProjectDetails,
      edit: true,
      modal: true,
    });
  };

  useEffect(() => {
    if (projectDetail) {
      setProjectValue("id", projectDetail?._id);
      setProjectValue("projectName", projectDetail?.projectName);
      setProjectValue("clientName", projectDetail?.clientName);
      setProjectValue("role", projectDetail?.role);
      setProjectValue("projectLinks", projectDetail?.projectLinks[0]);
      setProjectValue("projectDescription", projectDetail?.projectDescription);
      setProjectValue("responsibilities", projectDetail?.responsibilities);
      setProjectValue("skills", projectDetail?.skills);
      setDevProjectDetails({
        ...devProjectDetails,
        image: projectDetail?.image,
        skills: projectDetail?.skills,
      });
    }
  }, [projectDetail]);

  return (
    <form>
      {/* <Modal
              open={openAiModalOpen}
              title={"Improve Content"}
              onClose={closeSuggestionBox}
              onCancel={closeSuggestionBox}
              onDone={chatGPTSubmit(StoryImprovementFunc)}
              content={openAiSuggestionContent}
            /> */}

      <input
        type="hidden"
        ref={closeModalRef}
        onClick={() => projectInfoModalClose()}
      />
      <input
        type="hidden"
        ref={editProjectRef}
        onClick={handleProjectDetailsEdit}
      />
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label
              className={`floating-label ${
                projectError?.projectName && "text-danger"
              }`}
              htmlFor=""
            >
              Project Name*
            </label>
            <input
              type="text"
              {...projectRegister("projectName", {
                required: true,
              })}
              name="projectName"
              className={`form-control ${
                projectError?.projectName && "form-required"
              }`}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label
              className={`floating-label ${
                projectError?.name && "text-danger"
              }`}
              htmlFor=""
            >
              Client Name*
            </label>
            <input
              type="text"
              {...projectRegister("clientName", {
                required: true,
              })}
              name="clientName"
              className={`form-control ${
                projectError?.clientName && "form-required"
              }`}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label
              className={`floating-label ${projectError?.url && "text-danger"}`}
              htmlFor=""
            >
              Role*
            </label>
            <input
              type="text"
              {...projectRegister("role", { required: true })}
              name="role"
              className={`form-control ${
                projectError?.role && "form-required"
              }`}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="floating-label" htmlFor="">
              Project Links
            </label>
            <input
              type="text"
              name="projectLinks"
              {...projectRegister("projectLinks")}
              className="form-control"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <div className="row">
              <div className="col-10">
                <label
                  className={`floating-label ${
                    projectError?.projectDescription && "text-danger"
                  }`}
                  htmlFor=""
                >
                  Project Description*
                </label>
              </div>
            </div>

            <Controller
              control={projectControl}
              name="projectDescription"
              {...projectRegister("projectDescription", {
                required: true,
              })}
              render={({ field }) => (
                <OlibrEditor
                  value={
                    getProjectValue("projectDescription")
                      ? getProjectValue("projectDescription")
                      : ""
                  }
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <div className="row">
              <div className="col-10">
                <label
                  className={`floating-label ${
                    projectError?.description && "text-danger"
                  }`}
                  htmlFor=""
                >
                  Roles and Responsibilities*
                </label>
              </div>
            </div>

            <Controller
              control={projectControl}
              name="responsibilities"
              {...projectRegister("responsibilities", {
                required: true,
              })}
              render={({ field }) => (
                <OlibrEditor
                  value={
                    getProjectValue("responsibilities")
                      ? getProjectValue("responsibilities")
                      : ""
                  }
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label
              className={`floating-label ${
                projectError?.skills ? "text-danger" : ""
              }`}
              htmlFor=""
            >
              Add Skills*
            </label>
            <input
              {...projectRegister("skills", { required: true })}
              name="skills"
              type="hidden"
            />
            <div style={{ display: "flex" }}>
              <input
                type="text"
                {...projectRegister("projectSkill")}
                name="projectSkill"
                onKeyDown={(e) => {
                  if (e?.code === "Enter") {
                    addSkillInProject();
                  }
                }}
                className="form-control"
              />
              <a href="#-" onClick={() => addSkillInProject()}>
                <Icon name="add-icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <ul className="tags tags-fill">
        {devProjectDetails?.skills?.map((item, index) => (
          <li>
            <a href="#-">
              <span>{item}</span>{" "}
              <i
                onClick={() => {
                  const tempSkill = [...devProjectDetails?.skills];
                  tempSkill.splice(index, 1);
                  if (devProjectDetails?.skills?.length == 1) {
                    setProjectValue("skills", "");
                  } else {
                    setProjectValue("skills", tempSkill);
                  }
                  setDevProjectDetails({
                    ...devProjectDetails,
                    skills: tempSkill,
                  });
                }}
              >
                <Icon name="modal-close" />
              </i>
            </a>
          </li>
        ))}
      </ul>
      <br />
      <input
        ref={uploadProjectImageRef}
        type="file"
        onChange={onUploadProjectImage}
        className="input-file"
        accept=".png, .jpeg, .jpg"
        multiple
      />
      <div className="row">
        <div className="col-12">
          <a
            href="#-"
            className="file-icon"
            onClick={() => uploadProjectImageRef.current.click()}
          >
            {isDevProjectImageLoading ? (
              <ScaleLoader color="#3BA0AA" height={10} />
            ) : (
              "Upload project image"
            )}
          </a>
        </div>
        <div className="col-12">
          <br />
          <Modal
            size="100%"
            open={customLightBox}
            title={"Project Images"}
            onClose={closeCustomLightBox}
            content={() => customLightContent()}
            isFooterView={false}
          />

          {devProjectDetails?.image?.map((imgUrl, index) => (
            <Fragment>
              <div className="portfolio-images">
                <figure>
                  <img
                    key={index + Math.random()}
                    onClick={() => {
                      setCustomLightBox(true);
                    }}
                    src={imgUrl}
                    alt="Project"
                  />
                </figure>
              </div>
              <span
                onClick={() => removeImg(index)}
                style={{
                  position: "relative",
                  bottom: "60px",
                  right: "15px",
                }}
              >
                <Icon name="modal-close" />
              </span>
            </Fragment>
          ))}
        </div>
      </div>
      <br />
      <br />
      <div className="col-12">
        <div className="bottom-button">
          <button
            className="cancelBtn"
            type="button"
            onClick={projectInfoModalClose}
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={projectSubmit(handleDevProjectSubmit)}
            className="submitBtn themeButton"
          >
            {isProjectSubmit ? (
              <ClipLoader size={15} color="#FFFFFF" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ProjectContent;
