import { put, call } from "redux-saga/effects";
import * as types from "../../action";
import { olibrToast } from "../../../components/toaster";
import {
  getUser,
  login,
  signUp,
  forgetPassword,
  resetPassword,
  logout,
  emailVerification,
  resendEmailVerification,
  loginLinkidin,
} from "../../api";

export function* getUserSaga({ developerId, navigate, setUser }) {
  try {
    yield put({ type: types.GET_USER_START });
    const { data } = yield call(getUser);
    localStorage.setItem("userType", data?.data?.userType);
    data?.statusValue === 400 && !developerId && navigate("/");
    data?.statusValue === 400 && developerId && navigate("/login");
    data?.statusValue === 200 &&
      data?.data?.userType !== "Developer" &&
      data?.data?.userType !== "Admin" &&
      data?.data?.userType !== "Client" &&
      navigate("/content-writer/faqs");
    if (data?.statusText === "SUCCESS") {
      setUser(data?.data);
      localStorage.setItem("user", data?.data);
      yield put({ type: types.GET_USER_SUCCESS, data });
    }
  } catch (error) {
    yield put({ type: types.GET_USER_FAIL });
  }
}

export function* loginSaga({ loginData, navigate, setUser, setResendVerify }) {
  try {
    yield put({ type: types.LOGIN_START });
    const { data } = yield call(login, loginData);
    localStorage.setItem("userType", data?.userType);
    if (data?.statusCode === "SUCCESS") {
      localStorage.setItem("token", data?.accessToken);
      setResendVerify(false);
      const userData = yield call(getUser, data?.accessToken);
      localStorage.setItem("user", userData?.data?.data);
      setUser(userData?.data?.data);
      yield put({ type: types.LOGIN_SUCCESS,data: userData });
      if (data?.userType === "Admin") {
        navigate("/admin/dashboard");
      } else if (data?.userType === "Developer") {
        yield put({ type: types.SET_ACTIVE_AFTER_LOGIN})
        navigate("/developer/dashboard");
      } else if (data?.userType === "Client") {
        navigate("/client/client-dashboard");
      } else if (data?.userType === "Content Writer") {
        navigate("/content-writer/faqs");
      }
    } else if (data?.statusCode === "FAIL") {
      if (data?.statusValue === 402) {
        setResendVerify(true);
      } else {
        setResendVerify(false);
      }

      olibrToast.error(data?.message);

      yield put({ type: types.LOGIN_FAIL });
    } else {
      olibrToast.error("Something Went Wrong");
    }
  } catch (error) {
    olibrToast.error(error.message);

    yield put({ type: types.LOGIN_FAIL });
  }
}

export function* signUpSaga({ signUpData, navigate, setResend }) {
  try {
    yield put({ type: types.SIGN_UP_START });
    const { data } = yield call(signUp, signUpData);
    localStorage.setItem("userType", data?.data?.userType);
    setResend?.(true);
    if (data?.statusCode === "SUCCESS") {
      olibrToast.success(data?.message);
      window.lintrk('track', { conversion_id: 15057625 });
      (signUpData?.userType === "Developer" || signUpData?.userId) &&
        // window.provesrc.display("information", null, {
        //   message: `${signUpData?.email?.substring(
        //     0,
        //     signUpData?.email?.indexOf("@")
        //   )} has registered on Olibr.`,
        // });
        navigate("/login");
      yield put({ type: types.SIGN_UP_SUCCESS, data });
    } else if (data?.statusCode === "FAIL") {
      olibrToast.error(data?.message);
      if(signUpData?.userId) {
        navigate("/login");
      }
      yield put({ type: types.SIGN_UP_FAIL });
    } else {
      olibrToast.error("Something Went Wrong!");
    }
  } catch (error) {
    yield put({ type: types.SIGN_UP_FAIL });
  }
}

export function* forgetPasswordSaga({ email, setResendEmail, reset }) {
  try {
    yield put({ type: types.FORGET_PASSWORD_START });
    const { data } = yield call(forgetPassword, email);
    setResendEmail(true);
    if (data.statusCode === "SUCCESS") {
      olibrToast.success(data?.message);
      yield put({ type: types.FORGET_PASSWORD_SUCCESS });
      reset();
    } else if (data.statusCode === "FAIL") {
      olibrToast.error(data?.message);
    } else {
      olibrToast.error("Went Something Wrong");
    }
    yield put({ type: types.FORGET_PASSWORD_FAIL });
  } catch (error) {
    console.log(error.message);
    yield put({ type: types.FORGET_PASSWORD_FAIL });
  }
}

export function* resetPasswordSaga({ params, navigate, reset }) {
  try {
    const { data } = yield call(resetPassword, params);
    if (data?.statusCode === "SUCCESS") {
      olibrToast.success(data?.message);
      reset();
      navigate("/login");
    } else if (data?.statusCode === "FAIL") {
      olibrToast.error(data?.message);
    } else {
      olibrToast.error("Went Something Wrong");
    }
  } catch (error) {
    console.log(error.message);
  }
}

export function* logoutSaga({ navigate, setUser, socket, userId }) {
  try {
    yield put({ type: types.LOGOUT_START });
    const { data } =yield call(logout);
    if(data?.statusValue==200){
      yield put({ type: types.LOGOUT_SUCCESS });
      socket?.emit("logout", userId)
      localStorage.removeItem("token");
      localStorage.removeItem("userType");
      localStorage.removeItem("user");
      navigate("/");
      setUser({});
    }    
  } catch (error) {
    yield put({ type: types.LOGOUT_FAIL });
    console.log(error.message);
  }
}

export function* emailVerificationSaga({ params, navigate, setColorName }) {
  try {
    const { data } = yield call(emailVerification, params);
    if (data?.statusCode === "SUCCESS") {
      olibrToast.success(data?.message);
      setColorName("green");
    } else if (data?.statusCode === "FAIL") {
      olibrToast.error(data?.message);
    } else {
      olibrToast.error("Something Went Wrong.");
    }
    navigate("/login");
  } catch (error) {
    console.log(error.message);
  }
}

export function* resendEmailVerificationSaga({ email, navigate, reset }) {
  try {
    const { data } = yield call(resendEmailVerification, email);
    if (data?.statusCode === "SUCCESS") {
      olibrToast.success(data.message);
      navigate("/login");
      reset();
    } else if (data?.statusCode === "FAIL") {
      olibrToast.error(data?.message);
    } else {
      olibrToast.error("Something Went Wrong!");
    }
  } catch (error) {
    console.log(error.message);
  }
}


export function* loginWithLinkdinSaga({navigate }) {
  try {
    const data = yield call(loginLinkidin);    
  } catch (error) {
    console.log(error.message);
  }
}