export const logoArr = [
  "ibjob",
  "webmdjob",
  "carsjob",
  "fodorsjob",
  "lawyersjob",
  "nolojob",
  "officitejob",
  "pulsejob",
];

export const langArr = [
  "angularjob",
  "awsjob",
  "cppjob",
  "kubernetesjob",
  "nextjsjob",
  "nodejsjob",
  "pythonjob",
  "reactjob",
  "tsjob",
];

export const jobArr = [
  {
    jobTitle: "MIT AIML | AI & ML: Leading Business Growth - MIT Professional",
    company: "Robosoft Technologies",
    minExp: "2",
    maxExp: "7",
    jobType: "Remote",
    postDate: "1 Jan 2023",
  },
  {
    jobTitle: "Publicis Sapient - Senior Associate - React.js/Javascript",
    company: "Wayne Industries",
    minExp: "3",
    maxExp: "5",
    jobType: "Remote",
    postDate: "12 Jan 2023",
  },
  {
    jobTitle:
      "Publicis Sapient - Senior React.js Developer - Frontend Architecture",
    company: "Stark Industries",
    minExp: "6",
    maxExp: "8",
    jobType: "Remote",
    postDate: "10 Feb 2023",
  },
  {
    jobTitle: "MIT AIML | AI & ML: Leading Business Growth - MIT Professional",
    company: "Robosoft Technologies",
    minExp: "2",
    maxExp: "7",
    jobType: "Remote",
    postDate: "1 Jan 2023",
  },
  {
    jobTitle: "Publicis Sapient - Senior Associate - React.js/Javascript",
    company: "Wayne Industries",
    minExp: "3",
    maxExp: "5",
    jobType: "Remote",
    postDate: "12 Jan 2023",
  },
  {
    jobTitle:
      "Publicis Sapient - Senior React.js Developer - Frontend Architecture",
    company: "Stark Industries",
    minExp: "6",
    maxExp: "8",
    jobType: "Remote",
    postDate: "10 Feb 2023",
  },
];

export const desc =
  "Tracxn is a Bangalore-based product company providing a research and deal sourcing platform for Venture Capital, Private Equity, Corp Dev, and professionals working around the startup ecosystem. We are a team of 800+ working professionals serving customers across the globe. Our clients include Funds like Andreessen Horowitz, Matrix Partners, GGV Capital, and Large Corporates such as Citi, Embraer & Ferrero.";

export const postedBy =
  "TeamLease Digital is a subsidiary of TeamLease Services Limited, which offers technology staffing services in IT, ITeS, Telecom, Engineering, EdTech, HealthTech and Gaming industries. Formed in 2018 as a result of consolidation of five separate organisations - NichePro, KeyStone, ASAP, eCentricHR, IMSI and Evolve, who were the market leaders in their respective niche segments, TeamLease Digital has today emerged as one of the largest technology professional staffing services providers in the country.";
