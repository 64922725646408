import { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import Tooltip from "../../../components/tooltips/Tooltip";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import OlibrEditor from "../../../components/editor";
import SelectOption from "../../../components/select/SelectOption";

const Employment = ({
  handleSubmit,
  defaultValue,
  allSkill,
  isFooter = true,
  isAdminEdit = false,
  onReject,
  isUpdating = false,
  edit = false,
  comment,
  isRejectLoading,
  editEmploymentRequest,
  isNewEmployment,
}) => {
  const { devAllToolLists, isApprovalUpdating } = useSelector(
    (state) => state.developerReducer
  );

  const {
    register: emplmntRegister,
    setValue: setEmplmntValue,
    handleSubmit: emplmntSubmit,
    getValues: getEmplmntValue,
    reset: resetEmplmntForm,
    control: emplmntControl,
    formState: { errors: emplmntError },
  } = useForm();
  const [newEmployment, setNewEmployment] = useState();
  const [stillWorking, setStillWorking] = useState(false);

  useEffect(() => {
    if (isNewEmployment) {
      setNewEmployment(defaultValue);
    }
  }, [isNewEmployment]);

  useEffect(() => {
    if (newEmployment) {
      setEmplmntValue("designation", newEmployment?.designation);
      setEmplmntValue("companyName", newEmployment?.companyName);
      newEmployment?.from &&
        setEmplmntValue("from", new Date(newEmployment?.from));
      newEmployment?.to && setEmplmntValue("to", new Date(newEmployment?.to));
      setEmplmntValue("stillWorking", newEmployment?.stillWorking);
      setEmplmntValue("techUsed", newEmployment?.techUsed);
      setEmplmntValue("toolUsed", newEmployment?.toolUsed);
      setEmplmntValue("aboutRole", newEmployment?.aboutRole);
      setEmplmntValue("designation", newEmployment?.designation);
      setEmplmntValue("_id", newEmployment?._id);
      setStillWorking(newEmployment?.stillWorking);
    }
  }, [newEmployment]);

  const onEmploymentSelect = (event) => {
    const selectedEmp = editEmploymentRequest.find(
      (item) => item._id === event.target.value
    );
    setNewEmployment(selectedEmp?.newValue);
  };

  return (
    <form>
      {!isNewEmployment && (
        <div className="create-profile">
          <select
            className="form-control col-12"
            placeholder="Select Field"
            onChange={onEmploymentSelect}
          >
            <option value={""}>Select Employment </option>
            {editEmploymentRequest?.map((item) => (
              <option value={item._id}>{item?.newValue?.companyName}</option>
            ))}
          </select>
        </div>
      )}
      {newEmployment && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="create-profile">
            <div className="block-header between-center">
              <h4>Employment Information</h4>
              {edit && (
                <h3>
                  {comment ? (
                    <Tooltip
                      title={"Reject"}
                      titleColor={"red"}
                      tooltipText={comment}
                    />
                  ) : (
                    <span style={{ color: "red" }}>Pending</span>
                  )}
                </h3>
              )}
            </div>
            <div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label
                      className={`floating-label ${
                        emplmntError?.designation && "text-danger"
                      }`}
                      htmlFor=""
                    >
                      Designation*
                    </label>
                    <input
                      type="text"
                      {...emplmntRegister("designation", {
                        required: true,
                      })}
                      disabled={!isFooter}
                      className={`form-control col-12 ${
                        emplmntError?.designation && "form-required"
                      }`}
                      placeholder="Designation"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label
                      className={`floating-label ${
                        emplmntError?.companyName && "text-danger"
                      }`}
                      htmlFor=""
                    >
                      Company Name*
                    </label>
                    <input
                      {...emplmntRegister("companyName", {
                        required: true,
                      })}
                      name="companyName"
                      type="text"
                      className={`form-control col-12 ${
                        emplmntError?.companyName && "form-required"
                      }`}
                      placeholder="Company Name"
                      disabled={!isFooter}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div
                    className={`form-group ${
                      emplmntError?.from && "date-required"
                    }`}
                  >
                    <label
                      className={`floating-label ${
                        emplmntError?.from && "text-danger"
                      }`}
                      htmlFor=""
                    >
                      From
                    </label>
                    <Controller
                      control={emplmntControl}
                      name="from"
                      {...emplmntRegister("from", {
                        required: true,
                      })}
                      render={({ field }) => (
                        <ReactDatePicker
                          onChange={(date) =>
                            field.onChange(
                              new Date(
                                new Date(date).getTime() + 60 * 60 * 24 * 1000
                              )
                            )
                          }
                          selected={field.value}
                          placeholderText="Start Date"
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          disabled={!isFooter}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className={`form-group ${
                      emplmntError?.to && "date-required"
                    }`}
                    style={{
                      visibility: stillWorking ? "hidden" : "visible",
                    }}
                  >
                    <label
                      className={`floating-label ${
                        emplmntError?.to && "text-danger"
                      }`}
                      htmlFor=""
                    >
                      To
                    </label>
                    <Controller
                      control={emplmntControl}
                      name="to"
                      {...emplmntRegister("to", {
                        required: getEmplmntValue("stillWorking")
                          ? false
                          : true,
                      })}
                      render={({ field }) => (
                        <ReactDatePicker
                          onChange={(date) =>
                            field.onChange(
                              new Date(
                                new Date(date).getTime() +
                                  60 * 60 * 24 * 1000 * 30
                              )
                            )
                          }
                          selected={field.value}
                          placeholderText="End Date"
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          maxDate={new Date()}
                          disabled={!isFooter}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-3">
                      <span>Still Working: </span>
                    </div>
                    <span
                      onClick={() => {
                        if (isFooter) {
                          setEmplmntValue(
                            "stillWorking",
                            !getEmplmntValue("stillWorking")
                          );
                          setStillWorking(!stillWorking);
                        }
                      }}
                    >
                      <input
                        {...emplmntRegister("stillWorking")}
                        type="checkbox"
                        name="stillWorking"
                        checked={getEmplmntValue("stillWorking")}
                        disabled={!isFooter}
                      />
                      <label className="label" htmlFor="switch3"></label>
                    </span>
                  </div>
                  <div className="row">
                    <div
                      className={`col-6 ${
                        emplmntError?.techUsed && "select-required"
                      }`}
                    >
                      <label
                        htmlFor=""
                        className={`floating-label ${
                          emplmntError?.techUsed && "text-danger"
                        }`}
                      >
                        Technologies
                      </label>
                      <Controller
                        control={emplmntControl}
                        name="techUsed"
                        {...emplmntRegister("techUsed", {
                          required: getEmplmntValue("toolUsed") ? false : true,
                        })}
                        render={({ field }) => (
                          <SelectOption
                            menuPortalTarget={document.body}
                            optionArray={!!allSkill?.length ? allSkill : []}
                            getValue={getEmplmntValue}
                            getValueName={"techUsed"}
                            fieldProp={field}
                            isMulti={true}
                            searchable={true}
                            disabled={!isFooter}
                          />
                        )}
                      />
                    </div>
                    <div
                      className={`col-6 ${
                        emplmntError?.toolUsed && "select-required"
                      }`}
                    >
                      <label
                        htmlFor=""
                        className={`floating-label ${
                          emplmntError?.toolUsed && "text-danger"
                        }`}
                      >
                        Tools
                      </label>
                      <Controller
                        control={emplmntControl}
                        name="toolUsed"
                        {...emplmntRegister("toolUsed", {
                          required: getEmplmntValue("techUsed") ? false : true,
                        })}
                        render={({ field }) => (
                          <SelectOption
                            menuPortalTarget={document.body}
                            optionArray={
                              !!devAllToolLists?.length
                                ? devAllToolLists.map((tool) => ({
                                    value: tool?.toolsIcon,
                                    label: tool?.toolsName,
                                  }))
                                : []
                            }
                            getValue={getEmplmntValue}
                            getValueName={"toolUsed"}
                            fieldProp={field}
                            isMulti={true}
                            searchable={true}
                            disabled={!isFooter}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label
                      className={`${emplmntError?.aboutRole && "text-danger"}`}
                      htmlFor=""
                      style={{ zIndex: 0 }}
                    >
                      Describe your current role*
                    </label>
                    <Controller
                      control={emplmntControl}
                      name="aboutRole"
                      {...emplmntRegister("aboutRole", {
                        required: true,
                      })}
                      render={({ field }) => (
                        <OlibrEditor
                          value={
                            getEmplmntValue("aboutRole")
                              ? getEmplmntValue("aboutRole")
                              : ""
                          }
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isFooter && (
            <div className="bottom-button mt30 text-right">
              {isAdminEdit && (
                <button
                  type="button"
                  className="cancelBtn"
                  onClick={() => onReject(newEmployment?._id)}
                >
                  {isRejectLoading ? (
                    <ClipLoader size={15} color="#f15d3b" />
                  ) : (
                    "Reject"
                  )}
                </button>
              )}
              <button
                className="submitBtn themeButton"
                onClick={emplmntSubmit(handleSubmit)}
              >
                {isApprovalUpdating || isUpdating ? (
                  <ClipLoader size={15} color="#FFFFFF" />
                ) : isAdminEdit ? (
                  "Accept"
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          )}
        </div>
      )}
    </form>
  );
};

export default Employment;
