import { Link, useNavigate } from "react-router-dom";
import { Icon } from "../../../../components/icon";

const PopularJob = ({ popularJobList }) => {
  const jobTitle = (title) => {
    let result = title.slice(0, 55);
    result += (title?.length > 55) ? "..." : ""
    return result;
  }

  const handleNavigate=(job)=>{
     job?.slug?navigate(`/jobs/${job?.slug}`):navigate(`/jobs/${job?._id}`)
  }
  const navigate = useNavigate()
  return (
    <div className="popular-job ">
      <div class="grid-margin">Popular Jobs</div>
      {popularJobList?.data?.map((job) => (
        <div className="pj-row"
          onClick={()=>handleNavigate(job)}
        >
          <div className="row">
            <div className="col-3">
              <div className="logo">
                {job?.jobLogo ? (
                  <img src={job?.jobLogo} alt="" />
                ) : (
                  <Icon name="pop-icon" />
                )}
              </div>
            </div>
            <div className="col-9 job-det-popular">
              <h4>{jobTitle(job?.jobTitle)}</h4>
              <p>
                <span> &#9679;</span> {job?.budgetMin}-{job?.budgetMax}  Lacs PA
                <span style={{ marginLeft: "9px" }}> &#9679;</span> {job?.minExpRange}-{job?.maxExpRange} Years
              </p>

              {/* <Link to={`/job-list/${job?._id}`}>View Details</Link> */}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PopularJob;
