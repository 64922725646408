
import { useSelector } from "react-redux";
import DataTables from "../../../components/data-tables/DataTables";
import ColumnLists from "./ColumnLists";
function DevLists({
  fetchMoreData,
  setSkillTestResult,
  handleDevInfo,
  profileUpdateValue,
  updateSpotLight,
}) {
  const { devLists, devListLoading } = useSelector(
    (state) => state?.adminReducer
  );

  return (
      <DataTables
        columns={ColumnLists(updateSpotLight,profileUpdateValue,setSkillTestResult)}
        listData={devLists}
        Infinit={true}
        listLoading={devListLoading}
        onRowClick={(row)=>handleDevInfo(row?._id)}
        fetchMoreData={fetchMoreData}
      />
  );
}

export default DevLists;
