import { useState, useEffect } from "react";
import { MainContext } from "./MainContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUser } from "../service/action/authentication";

export default function MainContextWrapper(props) {
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const pathValue = pathname?.split("/") ? pathname?.split("/") : [];
  useEffect(() => {
    const developerId = pathValue?.[pathValue?.length - 1];
    if (pathValue?.[pathValue?.length - 2] === "dev-management") {
      developerId &&
        localStorage.setItem("developerId", pathValue?.[pathValue?.length - 1]);
    }
    if (localStorage.getItem("user"))
      localStorage.setItem("user", localStorage.getItem("user"));
    const outerRoute = [
      "reset-password",
      "verify-email",
      "about-us",
      "contact-us",
      "job-detail",
      "job-list",
      "faqs",
      "blog",
      "blog-detail",
      "privacy-policy",
      "term-and-conditions",
      "developer-homepage",
      "sign-up-client",
    ].filter((item) => window.location.href.includes(item));
    if (
      !(
        outerRoute.length > 0 ||
        window.location.href === "http://localhost:3000/" ||
        window.location.href === "https://olibr.com/" ||
        window.location.href === "https://test.olibr.com/" ||
        window.location.href === "https://dev.olibr.com/" ||
        window.location.href === "https://improved.olibr.com/"
      ) &&
      developerId
    ) {
      localStorage.getItem("token") &&
        dispatch(getUser(developerId, navigate, setUser));
    }
  }, [dispatch, navigate, setUser]);

  return (
    <MainContext.Provider value={{ user, setUser }}>
      {props.children}
    </MainContext.Provider>
  );
}
