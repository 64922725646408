import React, {
  Fragment,
  useRef,
  useEffect,
  useState,
  useContext,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { ReactComponent as ModalClose } from "../../../../assets/img/icons/close.svg";
import { Controller, useForm } from "react-hook-form";
import { Icon } from "../../../../components/icon";
import {
  profile,
  designation,
} from "../../../../common/constant/profileView/createProfile";
import { olibrToast } from "../../../../components/toaster";
import {
  dashboardStepOne,
  adminResumeParsing,
} from "../../../../service/action/admin";
import { getCity, getState } from "../../../../service/action/common";
import { countryCodesWithFlag } from "../../../../common/constant/countryCodesFlag";
import OlibrFlagDropDown from "../../../../components/dropdown/olibrFlagDropdown";
import { MainContext } from "../../../../context/MainContext";
import CustomIframe from "../../../common/userProfile/CustomIframe";
import LabelInputText from "../../../../components/common/LabelInputText";
import Button from "../../../../components/common/Button";
import SelectOption from "../../../../components/select/SelectOption";
import { noticeArr, timeZones } from "../../../../common/constant";
import OlibrDatePicker from "../../../../components/olibr-datepicker";
import { changeFormatSalary, readOnlyCheck, removeComma } from "../../../../components/functions";

const CreateProfileComponent = ({
  onNext,
  nextBtnRef,

  page,  filterValue,
  limit,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPreview, setPreview] = useState(false);
  const [devData, setDevData] = useState({});
  const [resumeStatus, setResumeStatus] = useState("");
  const { user: { access } } = useContext(MainContext)
  const { devUser, isResumeLoading, adminResumeData, devUserLoading } =
    useSelector((state) => state.adminReducer);
  const { countryList, stateList, cityList } = useSelector(
    (state) => state.commonReducer
  );
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    watch,
    control: profileControl,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setDevData(devUser[0]);
  }, [devUser]);
  const uploadResumeRef = useRef();
  const [stateArr, setStateArr] = useState([]);
  const [cityArr, setCityArr] = useState([]);
  useEffect(() => {
    let arr = stateList?.map((state) => ({
      label: state?.name,
      value: state?.isoCode,
    }));
    setStateArr(arr);
  }, [stateList]);
  useEffect(() => {
    let arr = cityList?.map((state) => ({
      label: state?.name,
      value: state?.name,
    }));
    setCityArr(arr);
  }, [cityList]);

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = (countryCode) => {
    dispatch(getState({ countryCode: "IN" }, navigate));
  };
  const fetchCities = (stateCode) => {
    dispatch(getCity({ countryCode: "IN", stateCode: stateCode }, navigate));
  };
  const setValueNew = (data, value) => {
    setValue(value, data);
  };

  const watchNoticePeriod = watch("devNoticePeriod", "");
  const watchDevAvlFrom = watch("devAvlFrom", "");

  useEffect(() => {
    setValueNew(
      adminResumeData?.EmploymentHistory?.Positions?.[0]?.JobTitle?.Raw,
      "devDesg"
    );
    setValueNew(
      adminResumeData?.ContactInformation?.CandidateName?.GivenName,
      "fName"
    );
    setValueNew(
      adminResumeData?.ContactInformation?.CandidateName?.FamilyName,
      "lName"
    );
    setValueNew(
      adminResumeData?.ContactInformation?.Telephones?.[0]?.SubscriberNumber,
      "number"
    );
    setValueNew(
      adminResumeData?.ContactInformation?.Telephones?.[0]
        ?.InternationalCountryCode,
      "devCountryCode"
    );
    setValueNew(
      adminResumeData?.ContactInformation?.Location?.Regions?.[0],
      "devCity"
    );
    setValueNew(
      adminResumeData?.ContactInformation?.Location?.PostalCode,
      "devPinCode"
    );
    setValueNew(
      adminResumeData?.ContactInformation?.Location?.StreetAddressLines?.[0],
      "devAddress"
    );
    setValueNew(
      adminResumeData?.ContactInformation?.Location?.StreetAddressLines?.[1],
      "devAddress2"
    );
  }, [adminResumeData]);

  const handleResumeParsing = (e) => {
    let formData = new FormData();
    formData.append("resume", e.target.files[0]);
    dispatch(
      adminResumeParsing(
        formData,
        navigate,
        { devId: devData?._id },
        setResumeStatus
      )
    );
    e.target.value = null;
  };
  useEffect(() => {
    if (devData) {
      setValueNew(devData?.devDesg, "devDesg");
      setValueNew(devData?.lName, "lName");
      setValueNew(devData?.fName, "fName");
      setValueNew(devData?.email, "email");
      setValueNew(devData?.number, "number");
      setValueNew(devData?.devCountryCode, "devCountryCode");
      setValueNew(devData?.devAddress, "devAddress");
      setValueNew(devData?.devAddress2, "devAddress2");
      changeFormatSalary("devCSalar", devData?.devCSalar, setValue)
      changeFormatSalary("devESalary", devData?.devESalary, setValue)

      setValueNew(
        timeZones?.find((item) => item?.value === devData?.devTimeZone),
        "devTimeZone"
      );
      setValueNew(devData?.devPinCode, "devPinCode");
      setValueNew(
        noticeArr?.find((item) => item?.value == devData?.devNoticePeriod),
        "devNoticePeriod"
      );
      if (devData?.devAvlFrom) {
        setValue("devNoticePeriod", "");
        new Date(devData?.devAvlFrom)?.getTime() > new Date()?.getTime()
          ? setValueNew(new Date(devData?.devAvlFrom), "devAvlFrom")
          : setValueNew(new Date(), "devAvlFrom");
      }
      // setValueNew(new Date(devData?.devAvlFrom), "devAvlFrom");
      setResumeStatus(devData?.devResume);
      setValueNew(devData?.userImage, "userImage");

      if (stateList?.length > 0) {
        setValueNew(devData?.devState, "devState");
        fetchCities(devData?.devState);
      }
    }
  }, [devData]);

  useEffect(() => {
    if (devData && cityList?.length > 0) {
      setValueNew(devData?.devCity, "devCity");
    }
  }, [cityList]);

  useEffect(() => {
    if (devData && stateList?.length > 0) {
      setValueNew(devData?.devState, "devState");
      fetchCities(devData?.devState);
    }
  }, [stateList]);
  const uploadResumeStatus = () => {
    if (resumeStatus == "pending") {
      olibrToast.info(
        `You Can Not Update Your Resume as your Current profile Status is pending! `
      );
    } else {
      uploadResumeRef.current.click();
    }
  };

  const cleanObj = (obj) => {
    return new Promise(function (resolve, reject) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName]?.length < 1
        ) {
          delete obj[propName];
        }
      }
      resolve(obj);
    });
  };

  const onSubmit = async (data) => {
    if (readOnlyCheck(access)) {
      olibrToast.info("You don't have permission")
      return false;
    }
    if (data?.devNoticePeriod?.value == "" && data.devAvlFrom == "") {
      return false;
    }
    if (!resumeStatus) {
      olibrToast.error("Please upload resume!");
      return false;
    }
    const cleanFilterValue = await cleanObj(filterValue);
    let dtz = data?.devTimeZone?.value;
    let dnp = data?.devNoticePeriod?.value;
    const devCSalar = removeComma(data?.devCSalar);
    const devESalary = removeComma(data?.devESalary);
    dispatch(
      dashboardStepOne(
        devData?._id,
        { ...data, devCSalar, devESalary, devTimeZone: dtz, devNoticePeriod: dnp ? dnp : "" },
        cleanFilterValue,
        page,
        limit,
        navigate
      )
    );
    setDevData(data);
    onNext();
  };

  const handlePreview = () => {
    const fileType = devData?.devResume?.split(".")?.pop();
    if (devData?.devResume) {
      if (fileType === "pdf") {
        setPreview(!isPreview);
      } else {
        let a = document.createElement("a");
        a.href = devData?.devResume;
        a.download = `resume.${fileType}`;
        a.click();
      }
    } else {
      olibrToast.error("Resume isn't uploaded.");
    }
  };

  const [optionData, setOptionData] = useState([]);

  useEffect(() => {
    const option_data = countryCodesWithFlag.map((item) => {
      return {
        value: item?.dial_code?.replace("+", ""),
        label: (
          <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
            <img
              src={`data:image/png;base64, ${item.flag}`}
              height="10px"
              style={{ marginTop: "2px" }}
              alt={item.name}
            />{" "}
            <span
              style={{ fontSize: "10px" }}
            >{`${item.name} ${item.dial_code}`}</span>
          </div>
        ),
        chipLabel: (
          <div style={{ position: "absolute", top: "9px" }}>
            <img
              src={`data:image/png;base64, ${item.flag}`}
              height="18px"
              alt={item.name}
            />
          </div>
        ),
      };
    });
    setOptionData(option_data);
  }, [countryCodesWithFlag]);

  const [isIndia, setIndia] = useState("");

  useEffect(() => {
    setIndia(devData?.region === "IN");
    setValueNew(devData?.region === "IN" ? 91 : 1, "devCountryCode");
  }, [devData?.region]);


  const changeToNum = (sal, txt) => {
    {
      let str = txt.split(".");
      let newStr = str[0].split(",");
      let dummyStr = "";
      newStr.forEach((item) => (dummyStr += item));
      setValue(sal, dummyStr);
    }
  };

  const viewResume = (resume) => {
    const fileType = resume?.split(".")?.pop();
    const fileUrl =
      fileType === "pdf"
        ? resume
        : `${resume &&
        `https://docs.google.com/gview?url=${resume}&embedded=true`
        }`;
    return (
      <CustomIframe
        src={fileUrl}
        allowFullScreen={true}
        style={{
          width: "100%",
          height: "100%",
          minHeight: "500px",
        }}
      />
    );
  };
  watch("devCity");

  return (
    <Fragment>
      {devUserLoading ? (
        <div className="flex-center">
          <ClipLoader color="#3BA0AA" />{" "}
        </div>
      ) : (
        <>
          <input
            ref={uploadResumeRef}
            type="file"
            onChange={handleResumeParsing}
            className="input-file"
            accept=".pdf,.doc, .docx"
          />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="create-profile">
              <div className="block-header">
                <h4>{profile}</h4>
              </div>
              <div className="container">
                <div
                  className="upload-resume"
                  onClick={() => uploadResumeStatus()}
                >
                  <span className="tab-icon">
                    {isResumeLoading ? (
                      <Icon name="loader" />
                    ) : (
                      <>
                        {devData?.devResume ? (
                          <Icon name="check" />
                        ) : (
                          <Icon name="upload" />
                        )}{" "}
                      </>
                    )}
                  </span>
                  <span style={{ display: "flex", flexDirection: "row" }}>
                    {devData?.devResume
                      ? `Re upload your CV / `
                      : `Upload Your CV / `}{" "}
                    {devData?.devResume ? (
                      <a
                        className="link"
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePreview();
                        }}
                      >
                        preview
                      </a>
                    ) : (
                      "Resume Here"
                    )}
                  </span>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="floating-label">
                        <span>{!errors.devDesg && designation}</span>
                        <span className="text-danger">
                          {errors.devDesg && "Designation*"}
                        </span>
                      </label>
                      <input
                        {...register("devDesg", {
                          required: true,
                        })}
                        name="devDesg"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <label className="floating-label">
                            <span className={errors.fName && "text-danger"}>
                              First Name*
                            </span>
                          </label>
                          <input
                            {...register("fName", {
                              required: true,
                              pattern: /^[A-Z a-z]+$/i,
                            })}
                            name="fName"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label className="floating-label">
                            <span className={errors.lName && "text-danger"}>
                              Last Name*
                            </span>
                          </label>
                          <input
                            {...register("lName", {
                              required: true,
                              pattern: /^[A-Z a-z]+$/i,
                            })}
                            name="lName"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <label className="floating-label">
                            <span className={errors.email && "text-danger"}>
                              Email*
                            </span>
                          </label>
                          <input
                            {...register("email", {
                              required: true,
                              pattern: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
                            })}
                            name="email"
                            type="email"
                            className="form-control"
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label
                            className="floating-label"
                            style={{ zIndex: 10 }}
                          >
                            <span className={errors.number && "text-danger"}>
                              Mobile Number*
                            </span>
                          </label>
                          <div className="flex-group">
                            <OlibrFlagDropDown
                              options={optionData}
                              value={getValues("devCountryCode")}
                              onSelect={(value) =>
                                setValueNew(value, "devCountryCode")
                              }
                              defaultValue={isIndia ? 91 : 1}
                            />
                            <input
                              {...register("number", {
                                required: true,
                                minLength: 10,
                                maxLength: 10,
                                pattern: /^[0-9]+$/i,
                              })}
                              name="number"
                              type="text"
                              minLength={10}
                              maxLength={10}
                              className="form-control"
                            />
                            {
                              devData?.numberVerified && (
                                <a className="browseImage browseNoTopAdmin">
                                  <Icon name="otp-verified" />
                                </a>
                              )
                            }

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {devData?.region === "IN" && (
                    <>
                      <div className="col-6">
                        <LabelInputText
                          label="Address 1*"
                          name="devAddress"
                          isError={errors.devAddress}
                          otherInfo={{
                            ...register("devAddress", { required: true }),
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <LabelInputText
                          label="Address 2"
                          name="devAddress2"
                          isError={errors.devAddress2}
                          otherInfo={{
                            ...register("devAddress2", { required: false }),
                          }}
                        />
                      </div>
                    </>
                  )}
                  <div className="col-6">
                    <label className="floating-label">
                      <span>{!errors.devState && "State"}</span>
                      <span className="text-danger">
                        {errors.devState && "State*"}
                      </span>
                    </label>
                    {/* <select
                      {...register("devState", {})}
                      name="devState"
                      onChange={(e) => {
                        setValue("devState", e.target.value);
                        fetchCities(e.target.value);
                        setValue("devCity", "");
                      }}
                      className="form-control"
                    >
                      {stateList?.map((state) => (
                        <option value={state?.isoCode}>{state?.name}</option>
                      ))}
                    </select> */}
                    <SelectOption
                      optionArray={stateArr}
                      state={stateArr?.find(
                        (item) => item?.value === getValues("devState")
                      )}
                      onChangeFunction={(e) => {
                        setValue("devState", e?.value);
                        fetchCities(e?.value);
                        setValue("devCity", "");
                      }}
                      closeMenuOnSelect={true}
                      searchable={true}
                    />
                  </div>

                  <div className="col-3">
                    <label className="floating-label">
                      <span>{!errors.devCity && "City"}</span>
                      <span className="text-danger">
                        {errors.devCity && "City*"}
                      </span>
                    </label>
                    <SelectOption
                      optionArray={cityArr}
                      state={cityArr?.find(
                        (item) => item?.value == getValues("devCity")
                      )}
                      onChangeFunction={(e) => {
                        setValue("devCity", e?.value);
                      }}
                      closeMenuOnSelect={true}
                      searchable={true}
                    />
                  </div>
                  <div className="col-3">
                    <LabelInputText
                      label={devData?.region === "IN" ? "PinCode" : "ZipCode"}
                      name="devPinCode*"
                      isError={errors.devPinCode}
                      maxLength={6}
                      otherInfo={register("devPinCode", {
                        required: true,
                        pattern: /^[0-9]+$/i,
                      })}
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-6">
                        <div className="row">
                          {devData?.region === "IN" && (
                            <div className="col-12">
                              <LabelInputText
                                label={"Current Salary*"}
                                name="devCSalar"
                                isError={errors.devCSalar}
                                otherInfo={register("devCSalar", {
                                  required: true,
                                  pattern: /^[0-9 ,.]+$/i,
                                })}
                                handleOnBlur={() =>
                                  changeFormatSalary(
                                    "devCSalar",
                                    getValues("devCSalar"),
                                    setValue
                                  )
                                }
                                handleOnFocus={() =>
                                  changeToNum(
                                    "devCSalar",
                                    getValues("devCSalar")
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className={
                          devData?.region === "IN" ? "col-6" : "col-12"
                        }
                      >
                        <LabelInputText
                          label={"Expected Salary*"}
                          name="devESalary"
                          isError={errors.devESalary}
                          otherInfo={register("devESalary", {
                            required: true,
                            pattern: /^[0-9 ,.]+$/i,
                          })}
                          handleOnBlur={() =>
                            changeFormatSalary("devESalary", getValues("devESalary"), setValue)
                          }
                          handleOnFocus={() =>
                            changeToNum("devESalary", getValues("devESalary"), setValue)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    {devData?.region === "IN" && (
                      <div className="form-group">
                        <label className="floating-label">
                          <span>
                            {!errors.devTimeZone && "Preferred Timezone*"}
                          </span>
                          <span className="text-danger">
                            {errors.devTimeZone && "Preferred Timezone*"}
                          </span>
                        </label>
                        <Controller
                          control={profileControl}
                          name="devTimeZone"
                          {...register("devTimeZone", { required: true })}
                          render={({ field }) => (
                            <SelectOption
                              disabled={
                                resumeStatus == "pending" ||
                                resumeStatus == "approved"
                              }
                              fieldProp={field}
                              optionArray={timeZones}
                              getValue={getValues}
                              getValueName={"devTimeZone"}
                              closeMenuOnSelect={true}
                            />
                          )}
                        />
                        {/* <select
                          {...register("devTimeZone", { required: true })}
                          name="devTimeZone"
                          className="form-control"
                        >
                          <option value=""></option>
                          <option value="Indian Standard Time">
                            Overlapping Hours
                          </option>
                          <option value="US Standard Time">
                            Indian Standard Time
                          </option>
                          <option value="Global Standard Time">Flexible</option>
                        </select> */}
                      </div>
                    )}
                    {devData?.region === "US" && (
                      <div className="form-group">
                        <label className="floating-label">
                          <span>{!errors.devRatePer && "Rate Per*"}</span>
                          <span className="text-danger">
                            {errors.devRatePer && "Rate Per*"}
                          </span>
                        </label>
                        <select
                          {...register("devRatePer", { required: true })}
                          name="devRatePer"
                          className="form-control"
                        >
                          <option value=""></option>
                          <option value="Hourly">Hourly</option>
                          <option value="Monthly">Monthly</option>
                          <option value="Annual">Annual</option>
                        </select>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <label className="floating-label">
                            <span>
                              {!errors.devNoticePeriod && "Notice period"}
                            </span>
                            <span className="text-danger">
                              {errors.devNoticePeriod && "Notice period*"}
                            </span>
                          </label>
                          <Controller
                            control={profileControl}
                            name="devNoticePeriod"
                            {...register("devNoticePeriod", {
                              required:
                                getValues("devAvlFrom") &&
                                  !getValues("devNoticePeriod")
                                  ? false
                                  : true,
                            })}
                            render={({ field }) => (
                              <SelectOption
                                placeholder={"Notice Period"}
                                optionArray={noticeArr}
                                fieldProp={field}
                                getValue={getValues}
                                getValueName={"devNoticePeriod"}
                                isClearable={true}
                                closeMenuOnSelect={true}
                                disabled={watchDevAvlFrom}
                              />
                            )}
                          />
                          {/* <select
                            className="form-control col-12"
                            {...register("devNoticePeriod", {
                              required:
                                getValues("devAvlFrom") &&
                                !getValues("devNoticePeriod")
                                  ? false
                                  : true,
                            })}
                            disabled={watchDevAvlFrom}
                          >
                            <option value={null}></option>
                            <option value={"Available"}>Available </option>
                            <option value={"15 Days"}>15 Days</option>
                            <option value={"30 Days"}>30 Days</option>
                            <option value={"45 Days"}>45 Days</option>
                            <option value={"60 Days"}>60 Days</option>
                            <option value={"90 Days"}>90 Days</option>
                            
                          </select> */}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label className="floating-label">
                            <span>
                              {!errors.devAvlFrom && "Available From"}
                            </span>
                            <span className="text-danger">
                              {errors.devAvlFrom && "Available From*"}
                            </span>
                          </label>
                          <Controller
                            control={profileControl}
                            {...register("devAvlFrom", {
                              required:
                                getValues("devNoticePeriod") &&
                                  !getValues("devAvlFrom")
                                  ? false
                                  : true,
                            })}
                            name="devAvlFrom"
                            render={({ field }) => (
                              <OlibrDatePicker
                                onChange={(e) => {
                                  if (!e) {
                                    setValue("devAvlFrom", "");
                                  } else {
                                    field.onChange(new Date(e));
                                  }
                                }}
                                disabled={watchNoticePeriod}
                                value={watchDevAvlFrom}
                                minDate={new Date()}
                              />
                            )}
                          />
                          {/* <input
                         {...register("devAvlFrom", {
                           required:
                             (!!getValues("devNoticePeriod") == true &&
                             !!getValues("devAvlFrom") == false)
                               ? false
                               : true,
                         })}
                         name="devAvlFrom"
                         type="date"
                         disabled={watchNoticePeriod}
                         className="form-control"
                         /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="mod"
                  style={{
                    display: isPreview && devData?.devResume ? "block" : "none",
                  }}
                >
                  <div className="modal fade show" id="AddSkills">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">Resume</h4>
                          <button
                            type="button"
                            className="btn-close"
                            onClick={handlePreview}
                          >
                            <ModalClose />
                          </button>
                        </div>
                        <div className="modal-body">
                          {devData?.devResume && viewResume(devData?.devResume)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="note">
                <strong>Note :</strong> We have auto-filled data from your
                resume. Please{" "}
                <a className="link" onClick={handlePreview}>
                  preview
                </a>{" "}
                and edit if necessary. Once done, proceed to the next steps.
              </p>
            </div>
            <div className="navigation">
              <Link to="" className="link prev disabled">
                <i className="arrow-left"></i>
              </Link>
              <Button
                ref={nextBtnRef}
                type="submit"
                className="link next"
                onClick={() => console.log(errors)}
                label={
                  <>
                    Next <i className="arrow-right"></i>
                  </>
                }
              />
            </div>
          </form>
        </>
      )}
    </Fragment>
  );
};

export default CreateProfileComponent;
