import React from 'react'
import DataTables from '../../../components/data-tables/DataTables'
import ColumnLists from './ColumnLists'
import { useEffect } from 'react'
import { getEmailCount } from '../../../service/action/admin'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import OlibrDatePicker from '../../../components/olibr-datepicker'

const DashboardAdmin = () => {
    const { emailCount,isEmailList } = useSelector((state) => state.adminReducer)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [filter, setFilter] = useState({
        date_from: "",
        date_to: "",
    })
    useEffect(() => {
        if(localStorage.getItem("token")) {
        dispatch(getEmailCount(navigate,filter))
        } else {
            navigate("/");
        }
    }, [filter])

    return (
        <div className='main-div-developer'>
            <div className="block-header">
                <div className='row'>
                    <div className='col-6'>
                        <h4 className="">Email List</h4>
                    </div>
                    <div className='col-6'>
                        <div className='row'>
                            <div className='col-4'>
                                <span className='filter-title'>Filter By Date</span>
                            </div>
                            <div className='col-4'>
                                <OlibrDatePicker
                                    onChange={(e) => setFilter({ ...filter, date_from: e })}
                                    value={filter?.date_from ? filter?.date_from : ""}
                                    placeholder="From"
                                />
                            </div>
                            <div className='col-4'>
                                <OlibrDatePicker
                                    onChange={(e) => setFilter({ ...filter, date_to: e })}
                                    value={filter?.date_to ? filter?.date_to : ""}
                                    placeholder="To"
                                    disabled={!filter?.date_from}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DataTables
                columns={ColumnLists()}
                listData={emailCount}
                Infinit={true}
                listLoading={isEmailList}
            />
            {/* <table className='table'>
                <thead>
                    <tr>
                        <th>S,No</th>
                        <th>Email Title</th>
                        <th>No. of Developers</th>
                        <th>No. of Email</th>
                    </tr>
                </thead>
                    <tr>
                        <td>S,No</td>
                        <td>Email Title</td>
                        <td>No. of Developers</td>
                        <td>No. of Email</td>
                    </tr>
            </table> */}
        </div>
    )
}

export default DashboardAdmin