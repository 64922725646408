import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinearTestResult from "./TestResultLinear";
// import HackerrankResultLogo from "../../../public/images/resource/hackerrank_logo.svg";
import TestResultCircular from "./TestResultCircle";
import PropTypes from "prop-types";
// import { getDateFormate, getPercentage } from "../../common/function";
import { getDateFormate,getPercentage } from "../../functions";
import moment from "moment";
const ExpandMore = styled((props) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function TestResultCard({fetchIconByTestId, result }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const HackerrankResultLogo="https://olibr.s3.ap-south-1.amazonaws.com/skillsandstacks/cfcc09c2620de8f31700217485863.png"

  return (
    <Card sx={{ maxWidth: 345 }} className="margin-bottom-30 test-result-card">
      <CardHeader
        avatar={<Avatar src={fetchIconByTestId(result?.subject)} aria-label="test logo"></Avatar>}
        title={result?.testName}
        subheader={`Date: ${moment(result?.attemptEndedAt).format("DD MM YYYY")}`}
      />
      <CardActions disableSpacing>
        <img
          src={HackerrankResultLogo}
          alt="hackerrank result icon"
          width={60}
          height={10}
          className="hr-img"
        />
        <ExpandMore
          // expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <div className="result-body-top margin-bottom-20">
            <TestResultCircular result={result} />
            <div className="test-duration">
              Time: <span> {result?.testDuration} m</span>
            </div>
          </div>
          <div className="result-body-bottom">
            <div className="para">
              <p className="cat-name">Test Score</p>
              <LinearTestResult />
              <p className="score">{result?.maxScore}</p>
            </div>
            <div className="para">
              <p className="cat-name">Your Score</p>
              <LinearTestResult value={result?.percentageScore} />
              {/* <p className='score'>{result?.obtainedScore}</p> */}
              <p className="score">
                {result?.obtainedScore % 1 === 0
                  ? result?.obtainedScore.toFixed(0)
                  : result?.obtainedScore.toFixed(2)}
              </p>
            </div>
            <div className="para">
              <p className="cat-name">Cutoff Score</p>
              <LinearTestResult
                value={getPercentage(result?.cutOffScore, result?.maxScore)}
              />
              <p className="score">{result?.cutOffScore}</p>
            </div>
            <div className="para final-result">
              <p className="cat-name">Total</p>
              <LinearTestResult status="passed" />
              <p className="score">{result?.percentageScore}%</p>
            </div>
          </div>
        </CardContent>
      </Collapse>
    </Card>
  );
}
TestResultCard.propTypes = {
  result: PropTypes.object,
};
