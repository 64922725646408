import "./tooltip.css";
import HelpGraphic from "../../assets/img/icons/help-graphic.webp"
import Help from "../../assets/img/icons/help-bg.webp"

const Tooltip = ({ title, tooltipText, open, onClose }) => {
  const hideModal = (e) => {
    if (!document.getElementById('mySidenav').contains(e.target)) {
      onClose();
    }
  }

  return (
    <>
      <div className={`${open ? "mainDiv" : "hide"}`} onClick={hideModal}>
        <div id="mySidenav" className="sidenav" style={{ width: open ? `35%` : `0px` }}>
          <img style={{ width: "100%", position: "absolute" }} src={Help} alt="" />
          <div className="row" style={{ position: "relative" }}>
            <div className="col-6 title-tooltip"><h2>{title}</h2></div>
            <div className="col-5 text-right"><img src={HelpGraphic} alt="" /></div>
          </div>
          {/* <div className="helptip">
          HELP TIP
        </div> */}
          <div className="toolTipText" dangerouslySetInnerHTML={{ __html: tooltipText }}>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tooltip;
