import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSpotlightHistoryAction } from "../../../service/action/admin";
import ColumnLists from "./ColumnLists";
import DataTables from "../../../components/data-tables/DataTables";

const SpotlightHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getSpotlightHistoryAction(navigate));
  }, [navigate, dispatch]);

  const { spotLightHistoryListLoading, spotLightHistoryList } = useSelector(
    (state) => state.adminReducer
  );

 

  return (
    <Fragment>
      <div className="main-div-developer">
        <div className="main-div">
          <div className="create-profile">
            <br />
            <div className="block-header">
              <h4 className="">Spotlight History</h4>
            </div>
            <br />
            <div className="table-div">
              <div className="table-small">
              <DataTables
                  columns={ColumnLists()}
                  listData={spotLightHistoryList}
                  listLoading={spotLightHistoryListLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SpotlightHistory;
