import * as React from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import PropTypes from "prop-types";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
  fontFamily: "Jost",
  color: "green",
}));

const ChipsArray = ({ chipData }) => {
  return (
    <ul className="job-other-info chip-array">
      {chipData?.map((data) => {
        let avatar = (
          <Avatar
            alt="Icon"
            src={
              data?.skillTestIcon ||
              data?.stackIcon ||
              data?.value ||
              data?.toolIcon
            }
            sx={{ width: "14px", height: "14px" }}
          />
        );
        return (
          <ListItem key={data.key}>
            <Chip
              avatar={avatar}
              label={
                data.skillName ||
                data.stackName ||
                data?.label ||
                data?.toolName
              }
              variant="outlined"
              size="small"
              sx={{
                fontFamily: "Poppins",
                fontSize: "13px",
                paddingLeft: "4px",
                paddingRight: "4px",
              }}
            />
            {data?.isRequired && <i className="fas fa-asterisk req-skill"></i>}
          </ListItem>
        );
      })}
    </ul>
  );
};

ChipsArray.propTypes = {
  chipData: PropTypes.any,
};

export default ChipsArray;
