import { Fragment, useState } from "react";

import { useNavigate } from "react-router-dom";

import { getResume, updateBasicDetails } from "../../service/action/developer";
import { useDispatch, useSelector } from 'react-redux'

import { useForm } from "react-hook-form";
import Modal from "../modal/ModalBox";
import { useEffect } from "react";
import UpdateForm from "./UpdateForm";
import Developer from "./Developer";
import AdminClient from "./AdminClient";
import { useContext } from "react";
import { MainContext } from "../../context/MainContext";
import Client from "./Client";
import { changePassword } from "../../service/action/common";

const Settings = () => {
    const { resumeData } = useSelector((state) => state.developerReducer);
    const {stateList } = useSelector((state) => state.commonReducer);
    const { user } = useContext(MainContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getResume())
    }, [])
    
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        reset,
        watch,
        control,
        formState: { errors },

    } = useForm();

    const [editDetail, setEditDetail] = useState({
        modal: false,
        slug: ""
    })

    const openModal = (slug) => {
        reset();
        if(slug=="noticePeriod"){
            setValue("devNoticePeriod",resumeData?.devNoticePeriod)
            setValue("devAvlFrom",resumeData?.devAvlFrom)
        }else if(slug=="devTimeZone"){
            setValue("devTimeZone",resumeData?.devTimeZone)
        }else if(slug=="address"){
            let obj=stateList?.find((item)=>item?.isoCode==resumeData?.devState)
            setValue("devState",{value:obj?.isoCode,label:obj?.name})
            setValue("devCity",{value:resumeData?.devCity,label:resumeData?.devCity})
        }
        setEditDetail({
            slug,
            modal: true
        })

        
    }

    const closeModal = () => {
        reset();
        setEditDetail({
            slug: "",
            modal: false
        })
    }

    const modalTitle = (slug) => {
        if (slug == "devTimeZone") {
            return "TIME ZONE"
        } else if (slug == "noticePeriod") {
            return "NOTICE PERIOD"
        } else {
            return slug?.toUpperCase();
        }
    }

    const handleContent = () => {
        let userType = localStorage.getItem("userType");
        if (userType == "Developer" && window.location.href.includes("/developer/dashboard/")) {

            return (
                <Developer
                    openModal={openModal}
                    resumeData={resumeData}
                />
            )
        } else if (userType == "Client"  && window.location.href.includes("/content-writer/")) {
            return (<Client openModal={openModal} />)
        } else if (userType == "Admin" && window.location.href.includes("/admin")) {
            return (<AdminClient />)
        }
        else{
            navigate("/login");
        }
    }

    const handleUpdate = (data) => {
        if (editDetail?.slug == "password") {
            delete data?.confirmPassword;
            dispatch(changePassword({
                password: data?.oldpassword,
                newPassword: data?.password
            },
                navigate, closeModal))
            return false;
        }
        if (user?.userType == "Developer") {
            if(data?.devCity || data?.devState){
                 data={...data,devCity:data?.devCity.value,devState:data?.devState.value}
            }
            dispatch(updateBasicDetails(navigate, data, closeModal))
        } else if (user?.userType == "Client") {
            console.log("working for client")
        }
    }
    return (
        <Fragment>
            <div className="landing-wrap">
                <Modal
                    title={`UPDATE ${modalTitle(editDetail?.slug)}`}
                    open={editDetail?.modal}
                    onClose={closeModal}
                    content={() => <UpdateForm
                        slug={editDetail?.slug}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                        getValues={getValues}
                        watch={watch}
                        resumeData={resumeData}
                        control={control}
                    />}
                    buttonTitle="Update"
                    onCancel={closeModal}
                    onDone={handleSubmit(handleUpdate)}

                />

                {handleContent()}




            </div>


        </Fragment>
    )
}

export default Settings;