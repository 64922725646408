import React from "react";
import Testimonial4 from "../Testimonials";
import { Icon } from "../../../components/icon";
import { logoArr } from "../../../common/constant/homeClient";

const TestMonial = () => {
  const renderLogo = (arr) => {
    return arr.map((nameImg, index) => (
      <li key={index}>
        <span>
          <Icon name={nameImg} />
        </span>
      </li>
    ));
  };

  return (
    <>
      <section className="testimonial-section style-two alternate">
        <div className="auto-container">
          {/* <!-- Sec Title --> */}
          <div className="sec-title light text-center">
            <h2>Testimonials From Our Customers</h2>
          </div>

          <div className="carousel-outer" data-aos="fade-up">
            {/* <!-- Testimonial Carousel --> */}
            <div className="testimonial-carousel-three gap-x25">
              <Testimonial4 />
            </div>
          </div>
          {/* End .carousel-outer */}
        </div>
        {/* End auto-container */}
      </section>
      <section className="partner-logo">
        <div className="auto-container">
          <ul>{renderLogo(logoArr)}</ul>
        </div>
      </section>
    </>
  );
};

export default TestMonial;
