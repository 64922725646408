import React from 'react'
import { Fragment } from 'react'
import { changePassword, changeEmail } from "../../service/action/common";
import { olibrToast } from "../toaster";
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateAvailableFrom } from '../../service/action/developer';
const AdminClient = ({resumeData,user}) => {

    const userType = localStorage.getItem('userType')
   
    const dispatch=useDispatch();
    const navigate=useNavigate();

    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newMail, setNewMail] = useState(user?.email)
    const [devAvlFrom, setDevAvlFrom] = useState();
    const handleChangePassword = async () => {
        if (!password || !newPassword) {
            olibrToast.warning("Please fill the value.")
            return null
        }
        dispatch(changePassword({password, newPassword},navigate))
        setPassword('')
        setNewPassword('')
    }

    const handleChangeEmail = async () => {
        if (/\S+@\S+\.\S+/.test(newMail) != true) {
            olibrToast.warning("Invalid Email.")
            return false;
        }

        if (newMail === user.email) {
            olibrToast.warning("Your account already registered with this mail.")
            return null
        } else if (!newMail) {
            olibrToast.warning("Please enter email.")
        } else {
            dispatch(changeEmail(newMail, navigate))
        }
        setNewMail('');
    }

    const handleAvailableFrom = async () => {
        if (!devAvlFrom) {
            olibrToast.warning("Please select date")
            return null
        }                           
        dispatch(updateAvailableFrom({devAvlFrom: new Date(devAvlFrom).toISOString().split("T")[0] }, navigate))                           
    }
  return (
    <Fragment>
         <div className="create-profile">
                    <div className="block-header">
                        <h4>Account Settings</h4>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <input
                                type="email"
                                value={newMail}
                                placeholder="Email"
                                onChange={(e) => setNewMail(e.target.value)}
                            />
                        </div>

                        <div className="col-6">
                            <div className="bottom-button">
                                <button
                                    className="submitBtn themeButton"
                                    onClick={handleChangeEmail}
                                >Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="create-profile">
                    <div className="block-header">
                        <h4>Change Password</h4>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <input
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                                placeholder="Current Password" />
                        </div>
                        <div className="col-6">
                            <input
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                type="password"
                                placeholder="New Password" />
                        </div>
                        <br />
                        <br />
                        <div className="col-12">
                            <div className="bottom-button">
                                <button className="submitBtn themeButton" onClick={handleChangePassword}>Change Password</button>
                            </div>
                        </div>
                    </div>
                </div>
                {userType === 'Developer' &&
                    <div className="create-profile">
                        <div className="block-header">
                            <h4>Available from</h4>
                        </div>
                        <div className="row">

                            <div className="col-6">
                                <input
                                    min={new Date().toISOString().split("T")[0]}
                                    type="date"
                                    value={devAvlFrom && new Date(devAvlFrom).toISOString().split("T")[0]}
                                    defaultValue={resumeData.devAvlFrom && new Date(resumeData.devAvlFrom).toISOString().split("T")[0]}
                                    onChange={(e) => setDevAvlFrom(e.target.value)}
                                    placeholder="Available from" />
                            </div>
                            <div className="col-6">
                                <div className="bottom-button">
                                    <button className="submitBtn themeButton" onClick={handleAvailableFrom}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>}
                <br></br><br></br>
    </Fragment>
  )
}

export default AdminClient