import React from "react";
import { Controller, useForm } from "react-hook-form";
import SelectOption from "../../../../components/select/SelectOption";
import {
  SpecificCountry,
  planTypeConstant,
} from "../../../../common/constant/commonData";
import {
  countryCodeOption,
  getCountryOption,
} from "../../../../components/functions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAddOnListAction,
  saveAddOnAction,
  updateAddOnAction,
} from "../../../../service/action/admin";
import { useEffect } from "react";

const AddOnPlanForm = ({ setOpenAddOn, data }) => {
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const watchValue = watch();
  useEffect(() => {
    if (data) {
      setValue("name", data?.name);
      setValue("description", data?.description);
      setValue("country", getCountryOption(data?.country));
      setValue("price", data?.price);
      setValue("currency", data?.currency);
      setValue("quantityPerAddon", data?.quantityAllowed);
      setValue("addonId", data?._id);
      setValue("tax",data?.tax)
      setValue("addonType",data?.addonType)
    } else {
      setValue("name", "");
      setValue("description", "");
      setValue("country", "");
      setValue("price", "");
      setValue("currency", "");
      setValue("quantityPerAddon", "");
      setValue("addonId", "");
      setValue("tax","")
    }
  }, [data, setValue, reset]);

  const callBack = () => {
    reset();
    dispatch(getAddOnListAction(navigate));
    setOpenAddOn(false);
  };

  const handleSave = (values) => {
    let tempObj = {
      ...values,
      country: values?.country?.value,
      currency: values?.country?.value == "IN" ? "INR" : "USD",
    };
    if (values?.addonId) {
      dispatch(updateAddOnAction(navigate, tempObj, callBack));
    } else {
      delete tempObj?.addonId;
      dispatch(saveAddOnAction(navigate, tempObj, callBack));
    }
  };
const addonType=[{
  value:"Assessment Credits",
  label:"Assessment Credits"
},{
  value:"Custom Test",
  label:"Custom Test"
}
]
  return (
    <div className="job-rquirement">
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label className={errors?.name && "text-danger"}>
              Add-ons Name*
            </label>
            <input
              type="text"
              {...register("name", { required: true })}
              className="form-control"
              placeholder="Add-ons Name*"
            />
          </div>
        </div>

        <div className="col-6">
          <label className={errors?.country && "text-danger"}>Country*</label>
          <Controller
            control={control}
            name="country"
            {...register("country", { required: true })}
            render={({ field }) => (
              <SelectOption
                themeStyle={true}
                optionArray={countryCodeOption(SpecificCountry)}
                fieldProp={field}
                getValue={getValues}
                getValueName={"country"}
                closeMenuOnSelect={true}
              />
            )}
          />
        </div>
        <div className="col-12">
          <div className="form-group">
            <label className={errors?.description && "text-danger"}>
              Add-ons Description*
            </label>
            <input
              {...register("description", { required: true })}
              type="text"
              className="form-control"
              placeholder="Add-ons Description*"
            />
          </div>
        </div>

        <div className="col-6">
          <div className="form-group">
            <label className={errors?.price && "text-danger"}>Price*</label>
            <input
              {...register("price", { required: true })}
              type="text"
              className="form-control"
              placeholder={watchValue?.country?.value=="IN"?"₹":"$"}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className={errors?.quantityPerAddon && "text-danger"}>
              Quantity Per Add-ons*
            </label>
            <input
              {...register("quantityPerAddon", { required: true })}
              type="text"
              className="form-control"
              placeholder="Quantity Per Add-ons*"
            />
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <label>
              Tax
            </label>
            <input
              {...register("tax")}
              type="text"
              className="form-control"
              placeholder="Tax"
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label>
              Add-On Type
            </label>
           <SelectOption
           optionArray={addonType}
           state={watchValue?.addonType?{value:watchValue?.addonType,label:watchValue?.addonType}:{}}
           onChangeFunction={(e)=>setValue("addonType",e?.value)}
           closeMenuOnSelect={true}
           />
          </div>
        </div>
        <div className="col-12 text-right">
          <button className="btn" onClick={handleSubmit(handleSave)}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddOnPlanForm;
