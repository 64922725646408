export   const accordionData = [
    {
      title: "What is Olibr?",
      content: `Olibr is a US-based job placement platform that offers full-time remote job opportunities to seasoned developers in top US firms.`,
    },

    {
      title: "Where is Olibr based?",
      content: `Olibr is a US-based deep jobs platform. Olibr doesn’t believe in geographical limitations and aims to connect top talent with top US companies.`,
    },
    {
      title: "What kind of developers does Olibr hire?",
      content: `Olibr hires developers for various job types including front-end, back-end, full-stack, database, mobile, DevOps, etc., based on tech roles, skills, and seniority. We have requirements for over 20 technologies including Python, React/Node, TypeScript, AWS Lambda, Java, Angular, TypeScript, React Native, PHP, AWS, Ruby, and more.`,
    },
    {
      title: "How is Olibr different from other remote based job websites?",
      content: `Our committed approach to finding you full-time placements with long-term professional security and career growth in top US firms makes us different from others. We also offer extra perks and benefits such as medical insurance, paid vacation, paid leave, and more. `,
    },
    {
      title: "How many rounds do I need to clear before selection?",
      content: `Once you register with us and upload your resume, you must go through the following primary tests: an English aptitude test, a technical skills assessment including a tech stack, and live coding tests. And voila! You belong to the Olibr tribe!`,
    },
    {
      title: "Can fresh graduates apply with Olibr?",
      content: `Olibr ideally hires remote developers with 3+ years of experience. However, if you think you have the right talent and skills to be an exceptional developer,  apply with us and take our tests. We offer the right kind of opportunity to the right talent!`,
    },
  ];

export const whyJoinHead = "Why Join Olibr?"  
export const whoCanBeHead = "Who Can Be An Olibr Developer?"  

export const whyjoin = [
    {
        title: "Vetted Opportunities",
        para: "Only reputable companies seeking serious talent are onboarded, so you're exposed to quality opportunities.",
        iconName: "provesucc-icon"
    },
    {
        title: "Tech-Focused",
        para: "Olibr is designed specifically for tech professionals, ensuring that job listings and platform features cater to your unique needs.",
        iconName: "developer-icon"
    },
    {
        title: "Skill Verification",
        para: "Stand out with your verified skills through rigorous HackerRank tests, elevating your market appeal.", 
        iconName: "remote-location"
    },
    {
        title: "Better Profile Visibility",
        para: "We place your profile front and center of top employers. You get quick placements as they have easy access to your profile.",
        iconName: "better-profile"
    },
    {
        title: "Experience Exclusivity",
        para: "Join the elite Olibr community, featuring only the top 5% of exceptional developers. ",
        iconName: "top-clients"
    },
    {
        title: "Premium Pay and Perks",
        para: "Olibr developers enjoy exceptional packages, including medical insurance, paid leaves, and more.",
        iconName: "best-perks"
    },
    {
        title: "Direct Engagements",
        para: "Avoid the clutter. Engage directly with potential employers, making the hiring process more efficient.",
        iconName: "why-fulltime"
    },
    {
        title: "Transparent Process",
        para: "Open database access ensures clarity and transparency in the hiring process.",
        iconName: "coding-test"
    },
    {
        title: "Live Coding Interviews",
        para: "Showcase your coding prowess in real-time, allowing for a dynamic evaluation that goes beyond the resume.", 
        iconName: "remoteon-icon"
    }
]
export const whoCanBe = [
    {
        title: "Cream Of The Crop",
        para: "Are you a top performer in your field? Join Olibr, where we connect exceptional talent with leading companies, ensuring a perfect match.",
        iconName: "cream-of-crop"
    },
    {
        title: "Full-Time Commitment",
        para: "Bid farewell to short-term or freelance gigs. Embrace stability with 40 hours/week of dedicated work.",
        iconName: "full-time-work"
    },
    {
        title: "Industry Experts",
        para: "Have 3+ years of relevant experience in your work field.",
        iconName: "industry-exp"
    },
    {
        title: "Good Communication Skills",
        para: "Since you will be working with top companies, having excellent communication skills is a prerequisite.",
        iconName: "commu-skills"
    },
    {
        title: "Working in Tech field",
        para: "You are pursuing your career in tech field and working.",
        iconName: "flexi-hours"
    },
    {
        title: "Coding Skill Tests",
        para: "Be ready to spare a few hours to take our specially curated tests.", 
        iconName: "coding-test"
    }
]