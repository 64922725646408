import React from 'react'
import { EmployementStyle } from './css/EmployementStyle'
import EmployementIcon from "../../../assets/img/icons/achievement-icon.png";
import { Image, Text, View } from '@react-pdf/renderer';
import { Educations } from './css/Educations';
import EmpDescription from './EmpDescription';
const Achievement = ({ data }) => {

    return (
        Array.isArray(data) && data?.length && (
            <View style={{ ...EmployementStyle.employetmentMain, marginTop: "10px" }}>
                <View style={EmployementStyle.emplLayoutLeft} >
                    <View>
                        <Image source={EmployementIcon} style={EmployementStyle.employementIcon} />
                    </View>
                    <View style={EmployementStyle.emplLayoutLists}>
                        <Text style={EmployementStyle.empHeading}> Achievement</Text>
                    </View>
                </View>
                <View style={Educations.rightLayout}>
                    <View style={{ margin: "-5px 0px" }}>
                        {
                            data?.map((itms,index)=>(
                                <View key={index}>
                                <EmpDescription content={itms?.title} />
                            </View>
                            ))
                        }
                       
                    </View>
                </View>

            </View>
        )
    )
}

export default Achievement