import React from "react";
import { useParams } from "react-router-dom";

const RequestDetail = () => {
  const { requestId } = useParams();
  //   return (
  //     <Fragment>
  //       <div className="main-div-developer">
  //         <div className="skills-management">
  //           <div className="block-header">
  //             <h4>Interview Detail</h4>
  //           </div>

  //           <div className="row">
  //             <div className="col-4">
  //               <div className="title-head">
  //                 {/* {clientDetailAdmin?.data?.[0]?.fullName} */}
  //               </div>
  //               <span className="title-p">
  //                 {/* {clientDetailAdmin?.data?.[0]?.country
  //                   ? clientDetailAdmin?.data?.[0]?.country
  //                   : "USA"} */}
  //               </span>
  //             </div>
  //             <div className="col-4">
  //               <div className="title-head">Mobile</div>
  //               <span className="title-p">
  //                 {/* {clientDetailAdmin?.data?.[0]?.number} */}
  //               </span>
  //             </div>
  //             <div className="col-4">
  //               <div className="title-head">Company Name</div>
  //               <span className="title-p">
  //                 {/* {clientDetailAdmin?.data?.[0]?.companyName} */}
  //               </span>
  //             </div>
  //           </div>
  //           <br />
  //           <div className="row">
  //             <div className="col-4">
  //               <div className="title-head">Email</div>
  //               <span className="title-p">
  //                 {/* {clientDetailAdmin?.data?.[0]?.email} */}
  //               </span>
  //             </div>
  //             <div className="col-4">
  //               <div className="title-head">Account Created</div>
  //               <span className="title-p">
  //                 {new Date(clientDetailAdmin?.data?.[0]?.createdAt)
  //                   ?.toDateString()
  //                   ?.substring(4)
  //                   ?.toLocaleUpperCase()}
  //               </span>
  //             </div>
  //             <div className="col-4">
  //               <div className="title-head">Job Posted</div>
  //               <span className="title-p">
  //                 {clientDetailAdmin?.data?.[0]?.jobs_count}
  //               </span>
  //             </div>
  //           </div>
  //           <br />
  //           <div className="row">
  //             <div className="col-4">
  //               <a className="link" onClick={() => jobListNav()}>
  //                 All Jobs
  //               </a>
  //             </div>
  //           </div>
  //           <br />
  //           <div className="main-div-tab">
  //             <div className="tabContainer">
  //               {tabArr?.map((item, index) => (
  //                 <span
  //                   key={index}
  //                   className={selectedTab == index ? states[0] : states[1]}
  //                   onClick={() => handleClick(index)}
  //                 >
  //                   {item}
  //                 </span>
  //               ))}
  //             </div>
  //             <div>{compArr[selectedTab]}</div>
  //           </div>
  //         </div>
  //       </div>
  //     </Fragment>
  //   );
};

export default RequestDetail;
