
export const slugArray = [
  { label: "JOB POSTED ACKNOWLEDGEMENT TO ADMIN", value: "client_job_posting_acknowledgement" },
  { label: "JOB POSTED TO ADMIN", value: "client_job_posted_notification_to_admin" },
  { label: "CLIENT REGISTRATION (TO SUPPORT)", value: "client_register_email_to_support" },
  { label: "WELCOME MAIL (CLIENT)", value: "welcome_mail_client" },
  { label: "JOB POST REJECTED", value: "job_rejected_feedback" },
  { label: "NEW JOB POSTED (DEV)", value: "new_job_posted_for_dev" },
  { label: "DIRECT CONTACT MAIL", value: "direct_contact_email_client" },
  { label: "CLIENT JOB APPROVED", value: "client_job_approved" },

  { label: "WELCOME MAIL", value: "welcome_mail" },
  { label: "VERIFICATION MAIL", value: "verification_mail" },
  { label: "ENGLISH TEST FAIL MAIL", value: "eng_test_fail_mail" },
  { label: "ENGLISH TEST PASS MAIL", value: "eng_test_pass_mail" },

  { label: "SKILL TEST PASSED", value: "skill_test_pass_mail" },
  { label: "SKILL TEST FAILED FIRST TIME", value: "skill_test_fail_first_mail" },
  { label: "SKILL TEST FAILED SECOND TIME", value: "skill_test_fail_second_mail" },
  { label: "SKILL TEST FAILED THIRD TIME", value: "skill_test_fail_third_mail" },

  { label: "PROFILE APPROVED", value: "dev_profile_approved_mail" },
  { label: "PROFILE REJECTED", value: "dev_profile_rejected_mail" },
  { label: "PROFILE UPDATE REQUESTED", value: "dev_update_requested_mail" },
  { label: "PROFILE UPDATE ACCEPTED", value: "dev_update_approval_accepted_mail" },
  { label: "PROFILE UPDATE REJECTED", value: "dev_update_approval_rejected_mail" },

  { label: "PROFILE SETUP MAIL SLUG", value: "profile_setup_mail" },
  { label: "FINALIZE RESUME", value: "finalize_resume_mail" },
  { label: "FINALIZE RESUME (TO SUPPORT)", value: "finalize_resume_support" },

  { label: "ENGLISH TEST WINDOW OPEN", value: "eng_test_reminder_slug" },
  { label: "SKILL TEST REMINDER", value: "tech_test_reminder_slug" },
  { label: "ENGLISH TEST REMINDER", value: "eng_test_reminder_after_setup" },
  { label: "SPOTLIGHT CANDIDATE", value: "spotlight_candidate" },
  { label: "DIRECT CONTACT EMAIL CLIENT TO SUPPORT", value: "direct_contact_email_client_to_support" },
  { label: "REVIEW REQUEST TO THIRD PARTYSLUG", value: "review_request_to_third_party" },
  { label: "REVIEW GIVEN BY THIRD PARTY SLUG", value: "review_given_by_third_party" },
  { label: "CLIENT USER INVITATION ACCEPT", value: "client_signup_accept" },
  { label: "ADMIN USER INVITATION ACCEPT", value: "admin_user_invite" },
  { label: "JOB CLOSING REMINDER", value: "job_closing_reminder" },
  { label: "JOB CLOSING EMAIL", value: "job_closing_email" },
  { label: "DEV PROFILE SHORTLISTED ", value: "dev_profile_shortlisted" },
  { label: "DEV APPLIED TO JOB MAIL TO CLIENT ", value: "dev_applied_to_job_mail_to_client" },
  { label: "DEV INTRO VIDEO UPLOADED SLUG ", value: "dev_introductory_video_uploaded" },
];



export const slugObj = {
  direct_contact_email_client_to_support: "DIRECT CONTACT EMAIL CLIENT TO SUPPORT",
  review_request_to_third_party: "REVIEW REQUEST TO THIRD PARTY SLUG",
  review_given_by_third_party: "REVIEW GIVEN BY THIRD PARTY SLUG",
  client_signup_accept: "CLIENT USER INVITATION ACCEPT",
  admin_user_invite: "ADMIN USER INVITATION_ACCEPT",
  job_closing_reminder: "JOB CLOSING REMINDER",
  job_closing_email: "JOB CLOSING EMAIL",
  dev_profile_shortlisted: "DEV PROFILE SHORTLISTED",
  dev_applied_to_job_mail_to_client: "DEV APPLIED TO JOB MAIL TO CLIENT",
  dev_introductory_video_uploaded: "DEV INTRO VIDEO UPLOADED SLUG",
  welcome_mail: "WELCOME MAIL",
  verification_mail: "VERIFICATION MAIL",
  eng_test_fail_mail: "ENGLISH TEST FAIL MAIL",
  eng_test_pass_mail: "ENGLISH TEST PASS MAIL",

  skill_test_pass_mail: "SKILL TEST PASSED",
  skill_test_fail_first_mail: "SKILL TEST FAILED FIRST TIME",
  skill_test_fail_second_mail: "SKILL TEST FAILED SECOND TIME",
  skill_test_fail_third_mail: "SKILL TEST FAILED THIRD TIME",

  dev_profile_approved_mail: "PROFILE APPROVED",
  dev_profile_rejected_mail: "PROFILE REJECTED",
  dev_update_approval_accepted_mail: "PROFILE UPDATE ACCEPTED",
  dev_update_approval_rejected_mail: "PROFILE UPDATE REJECTED",
  dev_update_requested_mail: "PROFILE UPDATE REQUEST",

  profile_setup_mail: "PROFILE SETUP MAIL SLUG",
  finalize_resume_mail: "FINALIZE RESUME MAIL",
  finalize_resume_support: "FINALIZE RESUME (TO SUPPORT)",

  eng_test_reminder_slug: "ENGLISH TEST WINDOW OPEN",
  tech_test_reminder_slug: "SKILL TEST REMINDER",
  eng_test_reminder_after_setup: "ENGLISH TEST REMINDER",

  spotlight_candidate: "SPOTLIGHT CANDIDATE",
  client_job_posting_acknowledgement: "JOB POSTED ACKNOWLEDGEMENT TO ADMIN",
  client_job_posted_notification_to_admin: "JOB POSTED TO ADMIN",
  client_register_email_to_support: "CLIENT REGISTRATION (TO SUPPORT)",
  welcome_mail_client: "WELCOME MAIL (CLIENT)",
  job_rejected_feedback: "JOB POST REJECTED",
  new_job_posted_for_dev: "NEW JOB POSTED (DEV)",
  direct_contact_email_client: "DIRECT CONTACT MAIL",
  client_job_approved: "CLIENT JOB APPROVED"
}

export const freqNum = ["First", "Second", "Third", "Fourth", "Fifth", "Sixth", "Seventh"];