import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Modal from "../../../components/modal/ModalBox";
import { olibrToast } from "../../../components/toaster";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTools,
  profileUpdateApproveRejectAction,
  profileUpdateStatusResetAction,
} from "../../../service/action/admin";
import Employment from "../../developer/profile/Employment";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AdditionalProject from "./profile-updates/AdditionalProject";
import Project from "./profile-updates/Project";

const ProfileUpdate = ({ approvalUpdates, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allUpdateRequest, setAllRequestedValue] = useState({});
  const [updateRequestOption, setRequestOption] = useState([{}]);
  const [selectedOption, setSelectedOption] = useState("");
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [rejectedEmpId, setRejectedEmp] = useState();
  const [allSkills, setAllSkills] = useState([]);
  const [updatedValue, setUpdatedValue] = useState();

  const {
    toolList,
    skillList,
    isUpdating,
    profileUpdateStatus,
    isRejectLoading,
  } = useSelector((state) => state.adminReducer);
  const [selectedTool, setSelectedTool] = useState([]);
  const [allTools, setAllTools] = useState([]);
  const [editEmploymentRequest, setEmploymentRequest] = useState([]);
  useEffect(() => {
    let temparr = [];
    for (let x = 0; x < approvalUpdates?.length; x++) {
      if (approvalUpdates[x].slug === "tool") {
        if (approvalUpdates[x].newValue?.length > 0) {
          approvalUpdates[x].newValue?.map((item) => {
            temparr?.push({ label: item?.toolName, value: item?.toolIcon });
          });
        }
      }
    }
    setSelectedTool(temparr);
  }, [approvalUpdates]);

  useEffect(() => {
    let temparr = [];
    toolList?.data?.map((item) => {
      temparr?.push({ label: item?.toolsName, value: item?._id });
    });
    setAllTools(temparr);
  }, [toolList]);

  const fetchAllTools = () => {
    dispatch(
      getAllTools(navigate, {
        page: 0,
        limit: 0,
        search: "",
      })
    );
  };

  const handleResetProfileUpdateStatus = () => {
    dispatch(profileUpdateStatusResetAction());
  };

  useEffect(() => {
    handleResetProfileUpdateStatus();
    fetchAllTools();
    // fetchAllSkillLists();
  }, []);

  useEffect(() => {
    let updated_option = updateRequestOption.filter(
      (item) => item.value !== selectedOption
    );
    if (profileUpdateStatus === "success") {
      setSelectedOption("");
      handleResetProfileUpdateStatus();
      setRequestOption(updated_option);
      if (updated_option.length < 1) {
        olibrToast.success("All request updated successfully.");
        onClose();
      }
    }
  }, [profileUpdateStatus]);

  useEffect(() => {
    let arr = [];
    arr = skillList?.data?.map((skill) => ({
      value: skill?.skillTestIcon,
      label: skill?.skillName,
    }));
    setAllSkills(arr);
  }, [skillList]);

  useEffect(() => {
    const editEmployment = [];
    if (approvalUpdates?.length > 0) {
      let all_update_request = {};
      approvalUpdates.forEach((element) => {
        if (element.slug === "editEmployment") {
          editEmployment.push({
            _id: element?._id,
            newValue: element?.newValue,
          });
        } else {
          all_update_request[`${element.slug}`] = {
            _id: element?._id,
            newValue: element?.newValue,
          };
        }
      });

      const optionData = Object.keys(all_update_request).map((item) => {
        const firstName = item.match(/[a-z][a-z]+/g)?.[0];
        const first_name =
          firstName?.charAt(0).toUpperCase() + firstName?.slice(1);
        const second_name = item.match(/[A-Z][a-z]+|[0-9]+/g)?.join(" ");
        return {
          value: item,
          label: second_name ? `${first_name} ${second_name}` : first_name,
        };
      });
      setSelectedOption("");
      setRequestOption(optionData);
      setAllRequestedValue(all_update_request);
      setEmploymentRequest(editEmployment);
    }
  }, [approvalUpdates]);

  const handleCloseFeedbackOpen = () => {
    setFeedbackOpen(false);
  };

  const handleRequestAccept = (id) =>
    dispatch(profileUpdateApproveRejectAction({ id, updatedValue }, navigate));

  const handleSelectedOption = (event) => {
    setUpdatedValue(allUpdateRequest?.[event.target.value]?.newValue);
    setSelectedOption(event.target.value);
  };

  const handleFeedbackSend = (id) => {
    if (feedback) {
      const empItem = editEmploymentRequest.find(
        (item) => item.newValue?._id === rejectedEmpId
      );

      rejectedEmpId && selectedOption === "editEmployment"
        ? dispatch(
            profileUpdateApproveRejectAction(
              { id: empItem?._id, feedback },
              navigate
            )
          )
        : dispatch(
            profileUpdateApproveRejectAction({ id, feedback }, navigate)
          );

      setFeedbackOpen(false);
      setFeedback("");
      if (rejectedEmpId) {
        setEmploymentRequest((preState) =>
          preState?.filter((itm) => itm?.newValue?._id !== rejectedEmpId)
        );
        setRejectedEmp("");
      }
    } else {
      olibrToast.error("Feedback is required.");
    }
  };

  const handleSubmitEmpInfo = (data) => {
    data?.stillWorking && delete data["to"];

    let finalObj = data;
    let finalObjTool = [];
    finalObjTool = finalObj?.toolUsed?.map((item) => ({
      label: item?.label,
      value: item?.value,
    }));
    data["toolUsed"] = finalObjTool;

    finalObjTool = [];
    finalObjTool = finalObj?.techUsed?.map((item) => ({
      label: item?.label,
      value: item?.value,
    }));
    data["techUsed"] = finalObjTool;

    const empItem = editEmploymentRequest.find(
      (item) => item.newValue?._id === data?._id
    );
    if (selectedOption === "newEmployment") {
      dispatch(
        profileUpdateApproveRejectAction(
          {
            id: allUpdateRequest?.[`${selectedOption}`]?._id,
            updatedValue: data,
          },
          navigate
        )
      );
    } else {
      dispatch(
        profileUpdateApproveRejectAction(
          {
            id: empItem?._id,
            updatedValue: data,
          },
          navigate
        )
      );

      setEmploymentRequest((preState) =>
        preState?.filter((itm) => itm?.newValue?._id !== data._id)
      );
    }
  };

  const handleContentFeedback = () => {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="floating-label">Feedback</label>
              <textarea
                type="text"
                rows={5}
                className="form-control"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="bottom-button">
          <button
            className="cancelBtn"
            onClick={() =>
              handleFeedbackSend(allUpdateRequest?.[`${selectedOption}`]?._id)
            }
          >
            Send
          </button>
        </div>
        <br />
      </>
    );
  };

  const handleCertContent = () => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <div className="row">
            <div className="form-group col-6">
              <input
                type="text"
                className="form-control col-12"
                placeholder="Certficate Name"
                onChange={(e) =>
                  setUpdatedValue({
                    ...updatedValue,
                    certificateName: e.target.value,
                  })
                }
                value={updatedValue?.certificateName}
              />
            </div>

            <div className="form-group col-6">
              <input
                type="text"
                className="form-control col-12"
                placeholder="Name of The Institution"
                onChange={(e) =>
                  setUpdatedValue({
                    ...updatedValue,
                    certInstitution: e.target.value,
                  })
                }
                value={updatedValue?.certInstitution}
              />
            </div>

            <div className="form-group col-6">
              <ReactDatePicker
                selected={
                  updatedValue?.startDate
                    ? new Date(updatedValue?.startDate)
                    : null
                }
                onChange={(date) =>
                  setUpdatedValue({
                    ...updatedValue,
                    startDate: new Date(
                      new Date(date).getTime() + 60 * 60 * 24 * 1000
                    ),
                  })
                }
                placeholderText="Start Date"
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </div>
            <div className="form-group col-6">
              <ReactDatePicker
                selected={
                  updatedValue?.endDate ? new Date(updatedValue?.endDate) : null
                }
                onChange={(date) =>
                  setUpdatedValue({
                    ...updatedValue,
                    endDate: new Date(
                      new Date(date).getTime() + 60 * 60 * 24 * 1000 * 30
                    ),
                  })
                }
                placeholderText="Start Date"
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleToolContent = () => {
    return (
      <div style={{ display: "flex" }}>
        <div className="form-group col-12">
          <Select
            isSearchable={false}
            isMulti
            maxMenuHeight={300}
            closeMenuOnSelect={false}
            value={selectedTool}
            options={allTools}
            onChange={(e) => {
              setSelectedTool(e);
            }}
          />
        </div>
      </div>
    );
  };

  const handleSkillContent = () => {
    return (
      <>
        <div className="row">
          <div className="form-group col-12">
            <label className="floating-label">Title</label>
            <input
              type="text"
              className="form-control col-12"
              onChange={(e) =>
                setUpdatedValue({ ...updatedValue, title: e.target.value })
              }
              value={updatedValue?.title}
            />
          </div>
        </div>
        <ul className="tags tags-fill">
          {updatedValue?.tagArr?.map((item, index) => (
            <li key={Math.random()}>
              <a>
                <span>{item}</span>{" "}
              </a>
            </li>
          ))}
        </ul>
      </>
    );
  };

  const [frameOpen, setFrameOpen] = useState(false);

  const handleFrameContent = () => {
    return (
      <Fragment>
        {frameOpen && (
          <iframe
            src={!!updatedValue ? updatedValue : ""}
            width="100%"
            height="500"
          ></iframe>
        )}
      </Fragment>
    );
  };
  const closeFrameModal = () => {
    setFrameOpen(false);
  };

  return (
    <div>
      <Modal
        open={feedbackOpen}
        title={"Feedback"}
        onClose={handleCloseFeedbackOpen}
        isFooterView={false}
        content={handleContentFeedback}
      />
      <div className="create-profile">
        <select
          className="form-control col-12"
          placeholder="Select Field"
          onChange={handleSelectedOption}
          value={selectedOption}
        >
          <option value={""}>Select field </option>
          {updateRequestOption?.map((item) => (
            <option value={item.value}>{item.label}</option>
          ))}
          {editEmploymentRequest?.length > 0 && (
            <option value={"editEmployment"}>{"Edit Employment"}</option>
          )}
        </select>
      </div>
      {selectedOption === "additionalProject" && (
        <AdditionalProject approvalUpdates={approvalUpdates} />
      )}
      {selectedOption === "project" && (
        <Project approvalUpdates={approvalUpdates} />
      )}
      {selectedOption === "devVideo" && (
        <div className="create-profile">
          <div className="col-12">
            <Modal
              size="100%"
              open={frameOpen}
              onClose={closeFrameModal}
              content={handleFrameContent}
              isFooterView={false}
              title={"Introductory Video"}
            />
            <div className="block-header">
              <h4>Introductory Video </h4>
            </div>
            <span className="link" onClick={() => setFrameOpen(true)}>
              Preview
            </span>
          </div>
        </div>
      )}
      {selectedOption === "tellYourStory" && (
        <div className="col-12">
          <div className="block-header">
            <h4>Tell your story </h4>
          </div>
          <textarea
            rows={10}
            value={updatedValue}
            onChange={(e) => setUpdatedValue(e.target.value)}
          />
        </div>
      )}

      {selectedOption === "achievement" && (
        <div className="col-12">
          <div className="block-header">
            <h4>Achievements</h4>
          </div>
          <textarea
            rows={10}
            placeholder={"Enter achievement"}
            onChange={(e) =>
              setUpdatedValue({ ...updatedValue, title: e.target.value })
            }
            value={updatedValue?.title}
          />
        </div>
      )}

      {(selectedOption === "newEmployment" ||
        selectedOption === "editEmployment") && (
        <Employment
          allSkill={allSkills}
          defaultValue={updatedValue}
          isNewEmployment={selectedOption === "newEmployment"}
          editEmploymentRequest={editEmploymentRequest}
          handleSubmit={handleSubmitEmpInfo}
          onReject={(empId) => {
            setRejectedEmp(empId);
            setFeedbackOpen(true);
          }}
          isFooter={true}
          isAdminEdit={true}
          isUpdating={isUpdating}
          isRejectLoading={isRejectLoading}
        />
      )}

      {selectedOption === "certification" && (
        <div className="create-profile">
          <div className="block-header">
            <h4>Certifications</h4>
          </div>
          {handleCertContent()}
        </div>
      )}

      {selectedOption === "tool" && (
        <div className="col-12">
          <div className="block-header small">
            <h4>Tools</h4>
          </div>
          {handleToolContent()}
        </div>
      )}

      {selectedOption === "skills" && (
        <div className="create-profile">
          <div className="row">
            <div className="col-12">
              <div className="block-header small">
                <h4>Skills</h4>
              </div>
              {handleSkillContent()}
            </div>
          </div>
        </div>
      )}

      {selectedOption === "userImage" && (
        <div className="create-profile">
          <div className="row">
            <div className="col-12">
              <div className="block-header small">
                <h4>User Image</h4>
              </div>
              <img src={updatedValue} />
            </div>
          </div>
        </div>
      )}

      {selectedOption === "socialProfile" && (
        <div className="create-profile">
          <div className="row">
            {updatedValue && (
              <div className="col-12">
                <div className="block-header">
                  <h4>Social Profiles</h4>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="floating-label" htmlFor="">
                        GitHub
                      </label>
                      <input
                        type="url"
                        className="form-control"
                        onChange={(e) =>
                          setUpdatedValue({
                            ...updatedValue,
                            gitHub: e.target.value,
                          })
                        }
                        value={updatedValue?.gitHub}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="floating-label" htmlFor="">
                        LinkedIn
                      </label>
                      <input
                        type="url"
                        className="form-control"
                        onChange={(e) =>
                          setUpdatedValue({
                            ...updatedValue,
                            linkedIn: e.target.value,
                          })
                        }
                        value={updatedValue?.linkedin}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="floating-label" htmlFor="">
                        Twitter
                      </label>
                      <input
                        type="url"
                        className="form-control"
                        onChange={(e) =>
                          setUpdatedValue({
                            ...updatedValue,
                            twitter: e.target.value,
                          })
                        }
                        value={updatedValue?.twitter}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="floating-label" htmlFor="">
                        Facebook
                      </label>
                      <input
                        type="url"
                        className="form-control"
                        onChange={(e) =>
                          setUpdatedValue({
                            ...updatedValue,
                            facebook: e.target.value,
                          })
                        }
                        value={updatedValue?.facebook}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {!(
        selectedOption === "newEmployment" ||
        selectedOption === "editEmployment"
      ) && (
        <div className="col-12">
          {selectedOption && (
            <div className="bottom-button text-right tm15">
              <button
                className="cancelBtn"
                onClick={() => setFeedbackOpen(true)}
              >
                Reject
              </button>
              <button
                className="submitBtn themeButton"
                onClick={() =>
                  handleRequestAccept(
                    allUpdateRequest?.[`${selectedOption}`]?._id
                  )
                }
              >
                {isUpdating ? (
                  <ClipLoader size={15} color="#FFFFFF" />
                ) : (
                  "Accept"
                )}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProfileUpdate;
