import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFeatureAction } from "../../../../service/action/admin";
import { Fragment } from "react";
import DataTables from "../../../../components/data-tables/DataTables";
import ColumnFeature from "./ColumnFeature";

const FeatureList = ({setData,setAddFeature}) => {
  const dispatch = useDispatch();
  const { fetureList } = useSelector((state) => state.adminReducer);
  useEffect(() => {
    dispatch(getFeatureAction());
  }, []);

  const onEdit = (rowData) => {
    setData(rowData)
    setAddFeature(true)
  };

  return fetureList &&(
    <Fragment>
      <DataTables
        columns={ColumnFeature(onEdit)}
        listData={{ data: fetureList }}
        onRowClick={(item) => onEdit(item)}
      />
    </Fragment>
  );
};

export default FeatureList;
