import React, { useState } from "react";
import ChipsArray from "./common/ChipsArray";
import PropTypes from "prop-types";
import { getDateFormate, getDateMoment } from "../functions";
import ProjectCard from "./ProjectCards";
const Employement = ({ devData }) => {
  // const [expanded, setExpanded] = useState(null);

  // const toggleExpanded = () => {
  //   setExpanded(!expanded);
  // };

  const [expandedBlocks, setExpandedBlocks] = useState({});

  const toggleExpanded = (id) => {
    setExpandedBlocks((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <div className="resume-outer theme-blue">
      <div className="upper-title">
        <h4>Work & Experience</h4>
      </div>

      {/* <!-- Start Resume BLock --> */}
      {devData?.devEmployment?.map((itm, id) => (
        <div key={id} className="resume-block">
          <div className="inner">
            {/* <span className="name">{itm?.companyName[0]}</span> */}
            <span className="name">
              {itm?.companyName ? itm?.companyName[0] : itm?.designation[0]}
            </span>
            <div className="title-box">
              <div className="info-box">
                <h3>{itm?.designation}</h3>
                <span>{itm?.companyName}</span>
              </div>
              <div className="edit-box">
                <span className="year">
                  {itm?.from && getDateMoment(itm?.from,"monthYear")} -
                  {itm?.stillWorking
                    ? " PRESENT"
                    : itm?.to && getDateMoment(itm?.to,"monthYear")}
                </span>
              </div>
            </div>
            {/* <div className="tech-stack">
              <h4>Technology</h4>
              <ChipsArray chipData={itm?.techUsed} />
            </div> */}
            {itm.techUsed.length > 0 && (
              <div className="tech-stack">
                <h4>Technology</h4>
                <ChipsArray chipData={itm.techUsed} />
              </div>
            )}
            {/* <div className="tech-stack">
              <h4>Tools</h4>
              <ChipsArray chipData={itm?.toolUsed} />
            </div> */}
            {itm?.toolUsed.length > 0 && (
              <div className="tech-stack">
                <h4>Tools</h4>
                <ChipsArray chipData={itm.toolUsed} />
              </div>
            )}
            <div className="text margin-top-10 margin-bottom-10">
              {/* <div
                className="list-style-two"
                dangerouslySetInnerHTML={{ __html: itm?.aboutRole }}
              ></div> */}
              {/* <div
                className="list-style-two work-desc"
                dangerouslySetInnerHTML={{
                  __html: expanded ? itm?.aboutRole : itm?.aboutRole.length > 400
                  ? itm?.aboutRole.slice(0, 400) + "..."
                  : itm?.aboutRole
                }}
              />
              {itm?.aboutRole.length > 400 && (
                <span onClick={toggleExpanded} className="theme-color-txt pointer">
                  {expanded ? " Read Less" : " Read More"}
                </span>
              )} */}
              <div
                className="list-style-two work-desc"
                dangerouslySetInnerHTML={{
                  __html: expandedBlocks[id]
                    ? itm?.aboutRole
                    : itm?.aboutRole.length > 400
                    ? itm?.aboutRole.slice(0, 400) + "..."
                    : itm?.aboutRole,
                }}
              />
              {itm?.aboutRole.length > 400 && (
                <span
                  onClick={() => toggleExpanded(id)}
                  className="theme-color-txt pointer"
                >
                  {expandedBlocks[id] ? " Read Less" : " Read More"}
                </span>
              )}
            </div>
            {itm?.url && (
              <div className="sub-title margin-bottom-10">
                <a className="sec-title">{itm?.url}</a>
              </div>
            )}

            <div className="role-related-projects">
              {devData?.devProjectDetails?.map(
                (project, index) =>
                  project?.empId === itm._id && (
                    <ProjectCard project={project} key={index} />
                  )
              )}
            </div>
          </div>
        </div>
      ))}

      {/* <!-- End Resume BLock --> */}
    </div>
  );
};
Employement.propTypes = {
  devData: PropTypes.object,
};

export default Employement;
