import React from 'react'
import { SkillResultCss } from '../css/SkillResultCss'
import DateIcon from "../../../../assets/img/icons/date-icon.png"
import { Image, Text, View } from '@react-pdf/renderer'
import DateFormate from '../../../../common/DateFormate'
const DateArea = ({date}) => {
    return (
      
            <View style={SkillResultCss.dateArea}>
                <View>
                    <Image src={DateIcon} style={SkillResultCss.dateIcon} />
                </View>
                <View>
                    <Text style={SkillResultCss.dateText}>Date : {DateFormate(date?.attemptEndedAt)}</Text>
                </View>
            </View>
    )
}

export default DateArea