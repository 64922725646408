export const aboutusOG = "https://olibr.s3.ap-south-1.amazonaws.com/resume/3c6b1ad1dde88abc1674221641097.jpg";
export const blogOG = "https://olibr.s3.ap-south-1.amazonaws.com/resume/727d7769ddf457031674221680605.jpg";
export const clientSignupOG = "https://olibr.s3.ap-south-1.amazonaws.com/resume/d7888889be03096e1674221703819.jpg";
export const comingSoonOG = "https://olibr.s3.ap-south-1.amazonaws.com/resume/4054e23100ac4b711674221737118.jpg";
export const contactUsOG = "https://olibr.s3.ap-south-1.amazonaws.com/resume/c2fb34d25ee148f31674221758701.jpg";
export const devHomepageOG = "https://olibr.s3.ap-south-1.amazonaws.com/resume/ba0f381213a381a81674221800944.jpg";
export const errorPageOG = "https://olibr.s3.ap-south-1.amazonaws.com/resume/def5921cb8644ab51674221833689.jpg";
export const faqOG = "https://olibr.s3.ap-south-1.amazonaws.com/resume/674bf4264fc565da1674221859825.jpg";
export const forgotPasswordOG = "https://olibr.s3.ap-south-1.amazonaws.com/resume/4c8947003af9cfce1674221884148.jpg";
export const clientHomePageOG = "https://olibr.s3.ap-south-1.amazonaws.com/resume/a7cb766456cbba501674221917626.jpg";
export const jobListOG = "https://olibr.s3.ap-south-1.amazonaws.com/resume/b163b0c33d9de4f41674221955059.jpg";
export const loginOG = "https://olibr.s3.ap-south-1.amazonaws.com/resume/c6186965eb2907921674221977381.jpg";
export const privacyPolicyOG = "https://olibr.s3.ap-south-1.amazonaws.com/resume/d3952ecc58666e231674222008798.jpg";
export const resendVerificationOG = "https://olibr.s3.ap-south-1.amazonaws.com/resume/6ca9c9033e719e191674222029345.jpg";
export const resetPasswordOG = "https://olibr.s3.ap-south-1.amazonaws.com/resume/e59edeb2e3b219ff1674222056668.jpg";
export const signUpOG = "https://olibr.s3.ap-south-1.amazonaws.com/resume/481043cfdff479231674222086136.jpg";
export const termsAndConditionOG = "https://olibr.s3.ap-south-1.amazonaws.com/resume/d3a4a06feec9ec121674222111300.jpg";
export const verificationEmailOG = "https://olibr.s3.ap-south-1.amazonaws.com/resume/0bc45842bf5e81481674222130997.jpg";