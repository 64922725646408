import React from 'react';

const CustomIndicatorSeparator = ({isTranshparent}) => {
  return (
    isTranshparent && (
    <div style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
      <span style={{ marginRight: '-20px' }}><i class="fa fa-search" style={{color: "#6f7276",    fontSize: "18px"}} aria-hidden="true"></i></span>
    </div>
    )
  );
};

export default CustomIndicatorSeparator;