export let questionriesConstant = [
    {
        questionName: "General Questions",
        questionLists: [
            {
                question: "Why are you interested in this role, and what motivates you about our company?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question: "Can you describe your experience with remote work, including any tools you have used?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question: "How do you prioritize and manage multiple tasks or projects simultaneously?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"How do you approach continuous learning and professional development?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Describe a situation where you had to adapt to significant changes in your work environment or project.",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"How do you balance quality with efficiency in your work?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Can you explain how you handle feedback and criticism in a professional setting?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"What strategies do you use to maintain focus and productivity when working remotely?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Describe a team project you have worked on and your contribution to its success.",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"What are your career goals, and how does this position align with them?",
                isMandatory: false,
                isAdded:false,
            }
        ]
    },
    {
        questionName: "Technical/Skill-Based Questions:",
        questionLists: [
            {
                question:"Can you describe your experience with [specific technology/tool/language]?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Please provide an example of a project where you used [specific skill or technology].",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"How do you keep up with the latest trends or updates in [specific field/technology]?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Explain a complex problem you've solved using [specific technology/tool/language], and detail the steps you took.",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"How do you approach troubleshooting and problem-solving when encountering a technical issue?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Describe your experience in integrating various technologies or platforms in a project.",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"What methodologies (e.g., Agile, Scrum) have you worked with, and how do you apply them to your projects?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Can you provide an example of a successful collaboration with other departments (such as marketing or sales) on a tech project?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"What tools or frameworks do you prefer for [specific task or development area], and why?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"How do you ensure the security and privacy of user data in your development practices?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Describe a situation where you had to optimize code or system performance. What was the challenge, and how did you address it?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"How do you ensure the quality and maintainability of your code? Are there any specific practices or tools you use?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Explain your experience with cross-platform development and how you ensure consistency across different devices or browsers.",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Can you describe your approach to documentation and how you ensure that your work is understood by others on the team?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"How do you keep track of emerging technologies in your field, and how do you determine whether to adopt them in your work?",
                isMandatory: false,
                isAdded:false,
            }
        ]
    },
    {
        questionName: "Role-Specific Questions",
        questionLists: [
            {
                question: "(For Customer Service Roles) How do you handle an irate customer while maintaining professionalism?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question: "(For Managerial Roles) Can you describe a situation where you had to lead a team through a challenging project?",
                isMandatory: false,
                isAdded:false,
            }
        ]
    },
    {
        questionName: "Behavioral Questions",
        questionLists: [
            {
                question: "Can you tell us about a time when you faced a significant challenge at work and how you resolved it?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question: "How do you approach collaboration in a remote team environment?",
                isMandatory: false,
                isAdded:false,
            },
        ],
    },
    {
        questionName: "Availability & Logistics",
        questionLists: [
            {
                question: "Are you available to work in the time zone specified for this position?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question: "Are you open to [part-time/full-time/contract] work?",
                isMandatory: false,
                isAdded:false,
            }
        ],
    },
    {
        questionName: "Cultural Fit",
        questionLists: [
            {
                question: "What type of company culture do you thrive in, and how do you see yourself fitting into our organization?",
                isMandatory: false,
                isAdded:false,
            }
        ]
    },
    {
        questionName: "Legal & Compliance",
        questionLists: [
            {
                question: "Are you legally authorized to work in [specific country/region]?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question: "Do you require any accommodations to perform the essential functions of this role?",
                isMandatory: false,
                isAdded:false,
            }
        ],
    },
    {
        questionName: "Technical/Skill-Based Questions",
        questionLists: [
            {
                question:"How do you approach continuous learning and professional development?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"How do you balance quality with efficiency in your work?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Can you explain how you handle feedback and criticism in a professional setting?",
                isMandatory: false,
                isChecked: false
            },
            {
                question:"What strategies do you use to maintain focus and productivity when working remotely?",
                isMandatory: false,
                isChecked: false
            },
            {
                question:"Describe a team project you have worked on and your contribution to its success. What are your career goals, and how does this position align with them?",
                isMandatory: false,
                isChecked: false
            },
        ]
    },{
        questionName: "Custom Questions:",
        questionLists: [],
    }
]
export let questionriesConstantCopy = [
    {
        questionName: "General Questions",
        questionLists: [
            {
                question: "Why are you interested in this role, and what motivates you about our company?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question: "Can you describe your experience with remote work, including any tools you have used?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question: "How do you prioritize and manage multiple tasks or projects simultaneously?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"How do you approach continuous learning and professional development?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Describe a situation where you had to adapt to significant changes in your work environment or project.",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"How do you balance quality with efficiency in your work?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Can you explain how you handle feedback and criticism in a professional setting?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"What strategies do you use to maintain focus and productivity when working remotely?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Describe a team project you have worked on and your contribution to its success.",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"What are your career goals, and how does this position align with them?",
                isMandatory: false,
                isAdded:false,
            }
        ]
    },
    {
        questionName: "Technical/Skill-Based Questions:",
        questionLists: [
            {
                question:"Can you describe your experience with [specific technology/tool/language]?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Please provide an example of a project where you used [specific skill or technology].",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"How do you keep up with the latest trends or updates in [specific field/technology]?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Explain a complex problem you've solved using [specific technology/tool/language], and detail the steps you took.",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"How do you approach troubleshooting and problem-solving when encountering a technical issue?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Describe your experience in integrating various technologies or platforms in a project.",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"What methodologies (e.g., Agile, Scrum) have you worked with, and how do you apply them to your projects?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Can you provide an example of a successful collaboration with other departments (such as marketing or sales) on a tech project?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"What tools or frameworks do you prefer for [specific task or development area], and why?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"How do you ensure the security and privacy of user data in your development practices?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Describe a situation where you had to optimize code or system performance. What was the challenge, and how did you address it?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"How do you ensure the quality and maintainability of your code? Are there any specific practices or tools you use?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Explain your experience with cross-platform development and how you ensure consistency across different devices or browsers.",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Can you describe your approach to documentation and how you ensure that your work is understood by others on the team?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"How do you keep track of emerging technologies in your field, and how do you determine whether to adopt them in your work?",
                isMandatory: false,
                isAdded:false,
            }
        ]
    },
    {
        questionName: "Role-Specific Questions",
        questionLists: [
            {
                question: "(For Customer Service Roles) How do you handle an irate customer while maintaining professionalism?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question: "(For Managerial Roles) Can you describe a situation where you had to lead a team through a challenging project?",
                isMandatory: false,
                isAdded:false,
            }
        ]
    },
    {
        questionName: "Behavioral Questions",
        questionLists: [
            {
                question: "Can you tell us about a time when you faced a significant challenge at work and how you resolved it?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question: "How do you approach collaboration in a remote team environment?",
                isMandatory: false,
                isAdded:false,
            },
        ],
    },
    {
        questionName: "Availability & Logistics",
        questionLists: [
            {
                question: "Are you available to work in the time zone specified for this position?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question: "Are you open to [part-time/full-time/contract] work?",
                isMandatory: false,
                isAdded:false,
            }
        ],
    },
    {
        questionName: "Cultural Fit",
        questionLists: [
            {
                question: "What type of company culture do you thrive in, and how do you see yourself fitting into our organization?",
                isMandatory: false,
                isAdded:false,
            }
        ]
    },
    {
        questionName: "Legal & Compliance",
        questionLists: [
            {
                question: "Are you legally authorized to work in [specific country/region]?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question: "Do you require any accommodations to perform the essential functions of this role?",
                isMandatory: false,
                isAdded:false,
            }
        ],
    },
    {
        questionName: "Technical/Skill-Based Questions",
        questionLists: [
            {
                question:"How do you approach continuous learning and professional development?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"How do you balance quality with efficiency in your work?",
                isMandatory: false,
                isAdded:false,
            },
            {
                question:"Can you explain how you handle feedback and criticism in a professional setting?",
                isMandatory: false,
                isChecked: false
            },
            {
                question:"What strategies do you use to maintain focus and productivity when working remotely?",
                isMandatory: false,
                isChecked: false
            },
            {
                question:"Describe a team project you have worked on and your contribution to its success. What are your career goals, and how does this position align with them?",
                isMandatory: false,
                isChecked: false
            },
           
        ],
    },
    {
        questionName: "Custom Questions:",
        questionLists: [],
    }
]
