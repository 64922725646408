import React from 'react';
// import BecomeDeveloper from "./../../assets/img/olibr-developer.webp";
const HowCanYouJoin = () => {
  return (
    <section className="how-join even-bg mb-40">
          <div className="container">
            <div className="how-join__img">
              <figure>
                {/* <img src={BecomeDeveloper} alt="" /> */}
                <img src="https://ik.imagekit.io/olibr/assets/tr:w-420,h-309/olibr-developer.webp" alt="Become Olibr Developer" />
              </figure>
            </div>
            <div className="how-join__text">
              <h2 className="how-join__title">How to Become an Olibr Developer</h2>
              <ol className="timeline-list">
                <li>
                  <h4>Set up your profile</h4>
                  <p>
                  Upload your resume and provide your name, location, skills, and salary details. 
                  </p>
                </li>
                <li>
                  <h4>Take our tests</h4>
                  <p>Complete our coding and English proficiency assessments.</p>
                </li>
                <li>
                  <h4>Complete your profile</h4>
                  <p>
                  Complete your profile by presenting your work history, projects, and resume details in a polished and comprehensive manner. 
                  </p>
                </li>
                <li>
                  <h4>Secure a position with top companies</h4>
                  <p>
                  Attend online interviews with clients and land your dream job! 
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </section>

  )
}

export default HowCanYouJoin