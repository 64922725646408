import React from "react";
import { Chip } from "@mui/material";
import PropTypes from "prop-types";
const Skills = ({ result }) => {
  return (
    !!result?.length && (
      <div className="sidebar-widget">
        <h4 className="widget-title">Skills</h4>
        <div className="widget-content">          
            <div  className="margin-bottom-20">
              <div className="skills-div">
              {result?.map((itm, id) => (
                  <Chip
                  key={id}
                    label={itm?.skillName+" - "+itm?.experiency+(itm?.experiency<=1?" year":" years")}
                    variant="outlined"
                    size="small"
                    sx={{
                      backgroundColor: "white",
                      ml: 1,
                      fontFamily: "Jost",
                      borderColor: "#1371f0",
                      color: "#1371f0",
                      borderWidth: "1.5px",
                      fontWeight: "500"
                    }}
                  />
                  ))}
              </div>
            </div>
       
        </div>
      </div>
    )
  );
};
Skills.propTypes = {
  result: PropTypes.array,
};
export default Skills;
