import { StyleSheet } from "@react-pdf/renderer";
import { Font } from '@react-pdf/renderer';
import popinMidium from "../../../../assets/fonts/Poppins-Medium.ttf"
import popinLight from "../../../../assets/fonts/Poppins-Light.ttf"
import popinRegular from "../../../../assets/fonts/Poppins-Regular.ttf"


Font.register({
  family: 'Poppins-Medium',
  fonts: [
    { src: popinMidium, fontWeight: 'normal' },

  ],
});

Font.register({
  family: 'Poppins-Light',
  fonts: [
    { src: popinLight, fontWeight: 'normal' },
  ],
});
Font.register({
  family: 'Poppins-Regular',
  fonts: [
    { src: popinRegular, fontWeight: 'normal' },
  ],
});
export const TestProgress = StyleSheet.create({
    container:{
        flexDirection:"row"
    },
    progressbar:{
        width:"164px",
        height:"6px",
        margin:"4px 6px",
        borderRadius:"50%",
        backgroundColor:"#e5e5e5"
    },
    title:{
        fontSize:"9px",
        fontFamily:"Poppins-Regular"
    },
    duration:{
        fontSize:"10px",
        fontFamily:"Poppins-Regular"
    }
 
})