import { emailSlug } from "../../../common/constant/emailSlug";


const ColumnLists = () => {
  const columns = [
    {
      name: "S.No",
      cell:(row,index)=>(
        index+1
      ),
      width: "80px",
    },
    {
      name: "Email Title",
      selector: "title",
      sortable: true,
      cell:(data)=>emailSlug[data?.title]
    },
    {
      name: "No. of Developers",
      selector: "devCount",
      sortable: true,
    },
    {
      name: "No. of Email",
      selector: "mailCount",
      sortable: true,
    }
  ];

  return columns;
}

export default ColumnLists