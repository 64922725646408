import { olibrToast } from "../toaster";
import { uploadIcons } from "../../service/action/admin";
import DateObject from "react-date-object";
import api from "../../service/api/apiInstance";
import { monthArr } from "../../common/constant/monthArr";
import {
  allCountryCodesWithFlag,
  countryCodesWithFlag,
} from "../../common/constant/countryCodesFlag";
import { SpecificCountry } from "../../common/constant/commonData";
import moment from "moment";
export const changeFormat = (sal, value, setValue) => {
  const txt = value.trim();
  if (txt == "") {
    setValue(sal, "");
  } else if (isNaN(txt)) {
    olibrToast.error("Contains Characters other than numbers");
  } else {
    let x = parseInt(txt);
    setValue(sal, x.toLocaleString("es-US") + ".00");
  }
};

export const changeToNum = (sal, txt, setValue) => {
  let str = txt.split(".");
  let newStr = str[0].split(",");
  let dummyStr = "";
  newStr.forEach((item) => (dummyStr += item));
  setValue(sal, dummyStr);
};

export const getStackList = (stacks, setStack) => {
  if (!!stacks) {
    let arr = [];
    arr = stacks?.map((skill) => ({
      value: skill?._id,
      label: skill?.stackName,
      stackId: skill?._id,
      stackName: skill?.stackName,
      stackIcon: skill?.stackIcon,
    }));
    setStack(arr);
  }
};

export const getSkillList = (skills, setSkills) => {
  if (!!skills) {
    let arr = [];
    arr = skills?.map((skill) => ({
      value: skill?._id,
      label: skill?.skillName,
      skillId: skill?._id,
      skillName: skill?.skillName,
      skillTestIcon: skill?.skillTestIcon,
    }));
    setSkills(arr);
  }
};

export const getStackListArr = (stacks) => {
  if (!!stacks) {
    let arr = [];
    arr = stacks?.map((skill) => ({
      value: skill?._id,
      label: skill?.stackName,
      stackId: skill?._id,
      stackName: skill?.stackName,
      stackIcon: skill?.stackIcon,
    }));
    return arr;
  }
  return [];
};

export const getSkillListArr = (skills) => {
  if (!!skills) {
    let arr = [];
    arr = skills?.map((skill) => ({
      value: skill?.skillTestIcon,
      label: skill?.skillName,
      skillId: skill?._id,
      skillName: skill?.skillName,
      skillTestIcon: skill?.skillTestIcon,
    }));
    return arr;
    // setSkills(arr);
  }
  return [];
};

export const addTagOnEnter = (
  tags,
  setTags,
  getValues,
  setValue,
  name = "tag"
) => {
  {
    if (tags) {
      const check = tags?.filter(
        (item) => item?.toUpperCase() == getValues("tag")?.toUpperCase().trim()
      );
      if (!!check?.length) {
        olibrToast.error("Tag already exist");
      } else {
        if (!!tags.length) {
          setTags([...tags, getValues("tag").trim()]);
        } else {
          setTags([getValues("tag").trim()]);
        }
      }
      setValue("tag", "");
    } else {
      olibrToast.error("Please enter tag name");
    }
  }
};

export const onUploadIconMethod = (e, dispatch, navigate) => {
  let formData = new FormData();
  formData.append("imageFile", e.target.files[0]);
  console.log(formData);
  console.log(e.target.files[0]);
  if (e.target.files[0]?.size > 1024 * 1024) {
    olibrToast.error("Please use image of size less than 1MB");
  } else {
    dispatch(uploadIcons(formData, navigate));
  }
  e.target.value = null;
};

export const filterWeb = (web) => {
  if (!web?.includes("http")) {
    return "https://" + web;
  } else {
    return web;
  }
};

export const stateArrFunc = (stateList) => {
  return stateList?.map((item) => ({
    value: item?.isoCode,
    label: item?.name,
    stateName: item?.name,
  }));
};
export const cityArrFunc = (cityList) => {
  return cityList?.map((item) => ({ value: item?.name, label: item?.name }));
};
export const countryCodeOption = (country) => {
  return country?.map((item) => ({
    value: item?.iso,
    label: item?.country,
  }));
};
export const DateSortingDataTable = (rowA, rowB, keyName) => {
  const a = rowA[keyName] && new Date(rowA[keyName]);
  const b = rowB[keyName] && new Date(rowB[keyName]);

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const formDateDateYear = (date) => {
  if (date) {
    let daten = new DateObject(date);
    let month = daten.format("MMMM");
    let year = daten.format("YYYY");
    return month
      ?.slice(0, 3)
      .concat(" " + year)
      .toUpperCase();
  } else {
    return "";
  }
};

export const getJobLinkUrl = (slug) => {
  let str = String(window.location.origin + "/jobs/" + slug);
  return str;
};

export const readOnlyCheck = (access, module = "Dev Management") => {
  return access?.some((itm) => itm.name == module && itm?.readOnly);
};

export const checkPermission = (readOnly) => {
  if (readOnly) {
    olibrToast.info("You don't have permission");
    return readOnly;
  }
};

export const checkMultipleJob = (data, stateList = []) => {
  if (data?.jobAddress?.length) {
    if (data?.jobAddress?.length > 1) {
      return data?.jobAddress?.length > 1 && "Multiple";
    }
    let cityName =
      data?.jobAddress[0]?.city + ", " + data?.jobAddress?.[0]?.stateName;
    if (cityName?.length > 8) {
      return cityName?.substring(0, 8) + "...";
    } else {
      return cityName;
    }
  }
};

export const getJobLocation = (data) => {
  if (data?.jobAddress?.length) {
    let jobString = [];
    data?.jobAddress?.map((itm, index) => {
      jobString.push(itm?.state + `${!!itm?.state && ", "}` + itm?.city + "/");
    });
    return jobString?.join("");
  }
};

export const filterMinutBySecond = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  if (seconds) return minutes + " m " + seconds + "s";
  return minutes + " m ";
};

export const getPercentage = (ctScore, tScore) => {
  if (ctScore && tScore) {
    return (ctScore / tScore) * 100;
  } else {
    return null;
  }
};

export const getSkillIdInCategory = (skillOrStack) => {
  let tempSkillId = [];
  skillOrStack?.map((itm) => {
    itm[Object?.keys(itm)?.[0]]?.map((item) => tempSkillId?.push(item?._id));
  });
  return tempSkillId;
};

export const removeArrFromArry = (arr, removableArray) => {
  let finalArray = [];
  for (let a of arr) {
    if (!removableArray?.includes(a)) {
      finalArray.push(a);
    }
  }
  return finalArray;
};

export const checkExistAllSkill = (selected, all) => {
  let result = true;
  for (let a of all) {
    if (selected?.includes(a)) {
      result = true;
    } else {
      result = false;
      return false;
    }
  }
  return result;
};

export const checkCoreSkillPassed = (result) => {
  let checkExists = result?.filter(
    (reslt) => reslt?.isPassed && reslt?.isCore
  ).length;
  return checkExists;
};

export const checkCompletedevStep = (indexNo, data, setTab, result) => {
  if (indexNo == 0) {
    setTab(indexNo);
    return true;
  }
  if (indexNo == 1 && data > 0) {
    setTab(indexNo);
    return true;
  }
  if (indexNo == 2) {
    if (data > 1) {
      setTab(indexNo);
      return true;
    } else if (data == 1) {
      olibrToast.error("Proficiency test is required");
      setTab(data);
      return true;
    }
  }
  if (indexNo == 3) {
    if (data > 2 && checkCoreSkillPassed(result)) {
      setTab(indexNo);
      return true;
    } else if (data == 3) {
      olibrToast.error(
        "You should pass atleast one CORE skill to go to the next step!"
      );
      setTab(data - 1);
      return true;
    } else if (data == 2) {
      olibrToast.error(
        "You should pass atleast one CORE skill to go to the next step!"
      );
      setTab(data);
      return true;
    } else if (data == 1) {
      olibrToast.error("Proficiency test is required");
      setTab(data);
    }
  }
  return false;
};

export const awsPngToImage = async (url) => {
  try {
    if (url && !url.toUpperCase(".SVG")) {
      const response = await api.post("/convert-image", { imageUrl: url });
      return response?.data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    console.error("Error loading images:", error.message);
  }
};

export const imageToBase64 = (imageURL) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageURL;
    img.crossOrigin = "anonymous";
    img.onload = () => {
      const canvas = document.createElement("canvas");
      console.log("canvas", canvas);
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      const base64String = canvas.toDataURL("image/*"); // You can adjust the format as needed
      resolve(base64String);
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
};

export const splitString = (lengths = 74, message) => {
  if (message?.length) {
    if (message?.length > lengths) {
      return message?.substring(0, lengths) + "...";
    }
    return message;
  }
};
export const getFileExtensions = (url) => {
  const filename = url.substring(url.lastIndexOf("/") + 1);
  const extension = filename.split(".").pop();
  return extension;
};

export const findSkillTime = (id, skillList, stackLists) => {
  if (isNaN(id)) {
    let result =
      skillList?.find((itm) => itm?._id == id) ||
      stackLists?.find((itm) => itm?._id == id);
    if (result?.skillTestTime) {
      return parseInt(result?.skillTestTime) + " min";
    } else if (result?.stackTestTime) {
      return parseInt(result?.stackTestTime) + " min";
    }
  } else {
    if (id > 500) {
      return id / 60 + " min";
    } else {
      return id + " min";
    }
  }
};

export const returnDate = (date) => {
  const x = new Date(date).getTime();
  if (x <= Date.now()) {
    return "Available";
  } else {
    return (
      <>
        {date?.split("T")?.[0].split("-")[2]}{" "}
        {monthArr[parseInt(date?.split("T")?.[0].split("-")[1]) - 1]}{" "}
        {date.split("T")?.[0].split("-")[0]}
      </>
    );
  }
};

export const questionariesFilterData = (data) => {
  let filteredData = [];
  data?.map((item) => {
    let questionLists = [];
    item?.questionLists?.map((itm) => {
      if (itm?.isAdded) {
        questionLists.push(itm);
      }
    });
    if (questionLists.length) {
      filteredData.push({
        questionName: item?.questionName,
        questionLists,
      });
    }
  });
  return filteredData;
};

export const checkChecked = (questionName, innerQuestion, selected) => {
  let checked = false;
  selected?.data?.map((itm) => {
    if (questionName == itm?.questionName) {
      itm?.questionLists?.map((item) => {
        if (innerQuestion == item?.question) {
          checked = true;
        } else {
          checked = false;
        }
      });
    }
  });
  return checked;
};

export const checkQuestionaries = (question) => {
  let result = false;
  question?.map((itm) => {
    let newResult = itm?.questionLists?.some((item) => item?.isAdded);
    if (newResult) {
      result = true;
      return false;
    }
  });
  return result;
};

export const checkExistsAnswer = (data) => {
  let result = data?.some((item) => item?.answer);
  return result;
};

export const clearQuestionaries = (data) => {
  Array.isArray(data) &&
    data.map((item, index) => {
      item.questionLists.map((itm, innerIndex) => {
        if (index !== data.length - 1) {
          data[index].questionLists[innerIndex].isAdded = false;
          data[index].questionLists[innerIndex].isChecked = false;
          data[index].questionLists[innerIndex].isMandatory = false;
        } else {
          data[index].questionLists = [];
        }
      });
    });

  return data;
};

export const compareTwoArray = (arr1, arr2) => {
  if (arr1.length < 1) {
    return false;
  }
  return JSON.stringify(arr1) == JSON.stringify(arr2);
};

export const checkJobApplied = (jobId, applidJobLists) => {
  return applidJobLists?.some((itm) => itm?._id == jobId);
};
export const checkArrayExists = (array, singleArray) => {
  let result = false;
  array?.map((itm) => {
    if (JSON.stringify(itm) == JSON.stringify(singleArray)) {
      result = true;
    }
  });
  return result;
};

export const checkQuestionLists = (arr) => {
  let result = false;
  for (let index = 0; index < arr?.length; index++) {
    result = arr[index]?.questionLists != undefined;
    if (result) {
      break;
    }
  }
  return result;
};
export const checkExists = (arr) => {
  return arr?.some(
    (itm) => (itm?.testDuration ? !itm?.plagiarism : true) && itm?.isPassed
  );
};
export const checkExistPlarismObj = (obj) => {
  return (obj?.testDuration ? !obj?.plagiarism : true) && obj?.isPassed;
};

export const filterUrlEnv = (url) => {
  let base_url = "http://localhost:9000/v1";

  if (window?.location?.href?.includes("https://dev")) {
    base_url = "https://api.dev.olibr.com/v1";
  } else if (window?.location?.href?.includes("https://test")) {
    base_url = "https://api.test.olibr.com/v1";
  } else if (window?.location?.href?.includes("https://improved")) {
    base_url = "https://api.improved.olibr.com/v1";
  } else if (window?.location?.href?.includes("https://admin.olibr")) {
    base_url = "https://olibr.com/api/v1";
  } else if (window?.location?.href?.includes("https://www")) {
    base_url = "https://olibr.com/api/v1";
  }

  return base_url + "/" + url;
};

export const filterFrontUrlEnv = (url) => {
  let base_url = "http://localhost:3000";

  if (window?.location?.href?.includes("https://dev")) {
    base_url = "https://dev.olibr.com";
  } else if (window?.location?.href?.includes("https://test")) {
    base_url = "https://test.olibr.com/";
  } else if (window?.location?.href?.includes("https://improved")) {
    base_url = "https://improved.olibr.com";
  } else if (window?.location?.href?.includes("https://olibr")) {
    base_url = "https://olibr.com";
  } else if (window?.location?.href?.includes("https://www")) {
    base_url = "https://www.olibr.com";
  }

  return base_url + "/" + url;
};
export const linkdinLogin = (url, title, w, h) => {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;
  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;
  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  window.open(
    filterUrlEnv(url),
    title,
    `scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
  );
};

export const getDateFormate = (date) => {
  if (date) {
    return (
      <>
        {date?.split("T")?.[0].split("-")[2]}{" "}
        {monthArr[parseInt(date?.split("T")?.[0].split("-")[1]) - 1]}{" "}
        {date.split("T")?.[0].split("-")[0]}
      </>
    );
  }
};

export const generateSitemap = (routes) => {
  let header = `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">`;
  let footer = `</urlset>`;
  let bodyData;
  for (let x of routes) {
    bodyData += `<url>
              <loc>${filterFrontUrlEnv(x)}</loc>
              <lastmod>2023-09-20T11:14:17+00:00</lastmod>
              <priority>1</priority>
          </url>`;
  }
  if (bodyData) {
    bodyData = bodyData.replace("undefined", "");
    bodyData = header + bodyData + footer;
  }
  return bodyData;
};

export const TwoLineDesc = (data) => {
  let x = data;
  x = x.replaceAll("<ul>", "");
  x = x.replaceAll("</ul>", "");
  x = x.replaceAll("<li>", "");
  x = x.split("</li>");
  if (x.length) {
    x = x.slice(0, 2);
    x = x.toString(", ");
  }
  return x;
};

export const checkSkillPassed = (passedArray, skillLists, stackLists) => {
  // passedArray is devTechtestResult
  // skillLists is devChooseSkills in job
  // stackLists is devChooseStacks in job

  let requiredSkill = [];
  let reqquiredStack = [];
  let isPassed = false;
  let requiredArray = [];
  if (!!skillLists?.length) {
    for (let item of skillLists) {
      if (item?.isRequired) {
        requiredSkill.push(item);
      }
    }
  }

  if (!!requiredSkill?.length) {
    for (let item of requiredSkill) {
      if (passedArray?.some((itm) => itm?.subject == item?.skillId)) {
        isPassed = true;
      } else {
        requiredArray.push(item?.skillName);
      }
    }
  }

  if (!!stackLists?.length) {
    for (let item of stackLists) {
      if (item?.isRequired) {
        reqquiredStack.push(item);
      }
    }
  }
  if (!!reqquiredStack?.length) {
    for (let item of reqquiredStack) {
      if (passedArray?.some((itm) => itm?.subject == item?.stackId)) {
        isPassed = true;
      } else {
        requiredArray.push(item?.stackName);
      }
    }
  }
  if (!!requiredArray?.length) {
    olibrToast.error(`Clear the ${requiredArray.join(", ")} test to apply.`, {
      width: "460px",
    });
    return false;
  }
  return true;
};

export const relocateFilter = (relocate) => {
  let location = [];
  relocate?.locations?.map((itm) => location.push(itm?.label));
  return !!relocate?.anyWhere ? "Anywhere" : location.join(", ");
};

export const filterStateExists = (stateData, selectedState) => {
  let state = [];
  for (let st of stateData) {
    if (!selectedState?.some((itm) => itm?.label == st?.name)) {
      state?.push(st);
    }
  }
  return state;
};
export const checkLocationExist = (relocate) => {
  return !!relocate?.locations?.length || !!relocate?.anyWhere;
};

export const handleSelectedSkills = (clientSelectSkills, setFilter, filter) => {
  let tempSkill = [];
  let tempStack = [];
  clientSelectSkills?.hiringStacks?.map((item) => {
    tempStack.push({ value: item?._id, label: item?.stackName });
  });
  clientSelectSkills?.hiringSkills?.map((item, index) => {
    tempSkill.push({ value: item?._id, label: item?.skillName });
  });

  if (tempSkill?.length < 1 && tempStack?.length < 1) {
    return false;
  }
  setFilter({
    ...filter,
    stackLists: [...filter?.stackLists, ...tempStack],
    devChooseSkills: [...filter.devChooseSkills, ...tempSkill],
  });
};

export const handleTopFiveClient = (
  isExists,
  skillId,
  skillName,
  filter,
  setFilter
) => {
  if (isExists) {
    const filterSkills = (item) => {
      return item?.value != skillId;
    };
    const filterSkill = filter?.skills?.filter(filterSkills);
    setFilter({
      ...filter,
      skills: filterSkill,
    });
  } else {
    setFilter({
      ...filter,
      skills: [...filter.skills, { value: skillId, label: skillName }],
    });
  }
};

export const returnStackSkillName = (stackArr) => {
  let stackName = [];
  for (let arr of stackArr) {
    if (arr?.label) {
      stackName?.push(arr?.label);
    } else {
      stackName?.push(arr);
    }
  }
  return stackName;
};
const returnCompleteForm = (lengthDiff, salary) => {
  if (lengthDiff > 0) {
    const zeros = "0".repeat(lengthDiff);
    return `${salary}${zeros}`;
  } else {
    return salary;
  }
};

export const returnSalaryForm = (salary) => {
  if (!!salary && !salary.includes(".")) {
    let lengthDiff = !!salary && 6 - salary.length;
    return returnCompleteForm(lengthDiff, salary);
  }
  salary = !!salary && salary.split(".");
  salary = !!salary.length && salary[0];
  if (salary) {
    salary = salary.replaceAll(",", "");
  }
  let lengthDiff = !!salary && 6 - salary.length;

  return returnCompleteForm(lengthDiff, salary);
};

export const changeFormatSalary = (sal, value, setValue) => {
  if (value) {
    let txt = value.trim();
    const cleanedInput = txt.replace(/,/g, ".");
    const number = parseFloat(cleanedInput);

    if (!isNaN(number)) {
      if (number >= 10000000) {
        setValue(sal, number.toLocaleString("en-IN"));
      } else if (number >= 100000) {
        setValue(sal, number.toLocaleString("en-IN"));
      } else {
        // Convert the number to lakh format (multiply by 100,000)
        const lakhValue = number * 100000;
        // return lakhValue.toLocaleString('en-IN');
        setValue(sal, lakhValue.toLocaleString("en-IN"));
      }
    } else {
      return "Invalid input";
    }

    // if (txt == "" || value[0] == ",") {
    //   setValue(sal, "");
    // } else if (/[a-zA-Z.]/.test(txt)) {
    //   olibrToast.error("Contains Characters other than numbers");
    // } else {
    //   let x = parseInt(txt);
    //   setValue(sal, x.toLocaleString("en-IN") + ".00");
    // }
  }
};

export const removeComma = (value) => {
  if (value?.includes(",")) {
    return value.replaceAll(",", "");
  }
};
function upperCase(data) {
  return data.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
    return str.toUpperCase();
  });
}

export const applieFilterClientSearch = (filter, setFilter, stateList) => {
  let tempArr = [];
  let minSal;
  let minExp;
  let maxSal = false;
  let maxExp = false;
  for (let x in filter) {
    if (typeof filter[x] == "string" && filter[x]) {
      if (x === "devState") {
        tempArr.push({
          payloadValue: filter[x],
          value: x,
          label: stateList?.find((itm) => itm?.isoCode === filter[x])?.name,
        });
      } else if (x === "devESalaryFrom") {
        minSal = `${filter[x]} LPA`;
      } else if (x === "devESalaryTo") {
        maxSal = true;
        tempArr.push({
          payloadValue: filter[x],
          value: x,
          label: !minSal ? `${filter[x]} LPA` : minSal + ` - ${filter[x]} LPA`,
        });
      } else if (x === "devMinExperience") {
        minExp = `${filter[x]} yrs`;
      } else if (x === "devMaxExperience") {
        maxExp = true;
        tempArr.push({
          payloadValue: filter[x],
          value: x,
          label: !minExp ? `${filter[x]} yrs` : minExp + ` - ${filter[x]} yrs`,
        });
      } else if (x === "devAvailable") {
        if (isNaN(filter[x])) {
          tempArr.push({ payloadValue: filter[x], value: x, label: filter[x] });
        } else {
          tempArr.push({
            payloadValue: filter[x],
            value: x,
            label: `With in ${filter[x]} days`,
          });
        }
      } else {
        tempArr.push({ value: x, label: filter[x], payloadValue: filter[x] });
      }
    } else if (
      filter[x] &&
      Object.prototype.toString.call(filter[x]) === "[object Date]"
    ) {
      tempArr.push({ value: x, label: filter[x], payloadValue: filter[x] });
    } else if (
      typeof filter[x] == "object" &&
      filter[x] &&
      Array.isArray(filter[x]) &&
      filter[x].length
    ) {
      tempArr.push({ value: x, label: filter[x], payloadValue: filter[x] });
    } else if (
      typeof filter[x] == "object" &&
      filter[x] &&
      !Array.isArray(filter[x]) &&
      Object.keys(filter[x]).length
    ) {
      tempArr.push({ value: x, label: filter[x], payloadValue: filter[x] });
    } else if (typeof filter[x] == "boolean" && filter[x]) {
      if (x === "relocation") {
        tempArr.push({
          value: x,
          label: "Open To Relocation",
        });
      } else {
        tempArr.push({ value: x, label: filter[x], payloadValue: filter[x] });
      }
    } else if (typeof filter[x] == "number" && filter[x]) {
      tempArr.push({ value: x, label: filter[x], payloadValue: filter[x] });
    }
  }
  if (filter["devESalaryFrom"] && !maxSal) {
    tempArr.push({
      value: "devESalaryFrom",
      label: `${filter["devESalaryFrom"]} LPA`,
      payloadValue: filter["devESalaryFrom"],
    });
  }
  if (filter["devMinExperience"] && !maxExp) {
    tempArr.push({
      value: "devMinExperience",
      label: `${filter["devMinExperience"]} yrs`,
      payloadValue: filter["devMinExperience"],
    });
  }
  setFilter(tempArr);
};
const escapedSearch = (search) =>
  search.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
export const helightSearch = (resumeData, searchedData, setDevData) => {
  let tempString = JSON.stringify(resumeData);
  // for (let keyWords of searchedData) {
  //   if(keyWords?.value==="search"){
  //     let arrSearch=keyWords?.payloadValue.split(",")
  //     for (let search of arrSearch) {
  //       if(search?.length>1 || search?.toLowerCase()==="c"){
  //       const regex = new RegExp(escapedSearch(search), 'gi');
  //       tempString = tempString.replaceAll(regex, `<mark>${search?.trim()}</mark>`);
  //     }
  //   }
  //   }else if(Array.isArray(keyWords?.payloadValue)){
  //     for (let search of keyWords?.payloadValue) {
  //       const regex = new RegExp(escapedSearch(search?.label), 'gi');
  //       tempString = tempString.replaceAll(regex, `<mark>${search?.label?.trim()}</mark>`);
  //     }
  //   }else{
  //     let regex;
  //     if(keyWords?.value==="relocation"){
  //     }else if(keyWords?.value==="devAvailable"){
  //       if(isNaN(keyWords?.payloadValue)){
  //         tempString =  tempString.replace(/"devAvlFrom":"[^"]*"/g, '"devAvlFrom":"<mark>Available</mark>"');
  //       }else{
  //       regex = new RegExp((keyWords?.payloadValue+" Days").trim(), 'gi');
  //       tempString = tempString.replaceAll(regex, `<mark>${(keyWords?.payloadValue+" Days")}</mark>`);
  //       }
  //     }else if(isNaN(keyWords?.payloadValue && keyWords?.payloadValue>1 || keyWords?.label?.toLowerCase()==="c")){
  //       regex =  new RegExp(escapedSearch(keyWords?.payloadValue), 'gi');
  //       tempString = tempString.replaceAll(regex, `<mark>${keyWords?.payloadValue?.trim()}</mark>`);
  //     }else if(keyWords?.value==="devAvailable" && !isNaN(keyWords?.payloadValue)){
  //       regex = new RegExp((keyWords?.payloadValue+" Days").trim(), 'gi');
  //       tempString = tempString.replaceAll(regex, `<mark>${(keyWords?.payloadValue+" Days")}</mark>`);
  //     }else if(typeof keyWords?.payloadValue==="string"){
  //       if(keyWords?.label?.length>1 || keyWords?.label?.toLowerCase()==="c"){
  //       regex = new RegExp(keyWords?.label?.trim(), 'gi');
  //       tempString = tempString.replaceAll(regex, `<mark>${keyWords?.label?.trim()}</mark>`);
  //       }
  //     }

  //   }
  // }
  setDevData(JSON.parse(tempString));
};
export const returnStateValue = (index, stateList, jobAddress) => {
  let formatedArr = stateList?.filter(
    (itm) => itm?.isoCode == jobAddress[index].state
  );
  formatedArr = formatedArr?.map((itms) => {
    return { label: itms?.name, value: itms?.value };
  });
  return formatedArr;
};

export const countryFlag = (countryCode) => {
  countryCode = countryCode ? countryCode : "IN";
  let country = allCountryCodesWithFlag.find((itm) => itm.code == countryCode);
  if (country) {
    return `data:image/png;base64, ${country.flag}`;
  }
  return "";
};
export const countryFlagCode = (countryCode) => {
  countryCode = countryCode ? countryCode : "91";
  let country = allCountryCodesWithFlag.find(
    (itm) => itm.dial_code == "+" + countryCode
  );
  if (country) {
    return `data:image/png;base64, ${country.flag}`;
  }
  return "";
};
export const capitalizeFirstLetter = (str) => {
  if (str == "accepted") {
    return "Active";
  }
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export function returnStateName(stateList, stateCode) {
  let tempArr = stateList?.find((state) => state?.isoCode == stateCode);
  if (tempArr?.name) {
    return tempArr?.name;
  }
  return "";
}

export const formatBudget = (value, digitNo = 2) => {
  let tempValue = [];
  if (typeof value == "string") {
    tempValue = value.split(".");
  } else if (typeof value == "number") {
    tempValue = String(value)?.split(".");
  }
  if (tempValue?.[0]?.length > digitNo) {
    value = tempValue[0]?.slice(0, digitNo);
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue)) {
      return numericValue.toFixed(digitNo);
    }
    return value;
  } else {
    return value;
  }
};

export const checkDevCountry = (user, jobs) => {
  if (Array.isArray(jobs?.jobCountry)) {
    let requiredCountry = [];
    for (let job of jobs?.jobCountry) {
      if (job?.countryCode == user?.region) {
        return true;
      } else {
        requiredCountry.push(job?.countryName);
      }
    }
    if (requiredCountry?.length) {
      olibrToast.error(
        `Only developers from the ${requiredCountry?.toLocaleString()} are eligible to apply for this job.`
      );
      return false;
    }
  }
  return true;
};

export const CountryFlagOption = (countryLimit) => {
  let country =
    countryLimit === "IN-US" ? countryCodesWithFlag : allCountryCodesWithFlag;
  return country?.map((item) => {
    return {
      value: item?.code,
      label: (
        <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <img
            src={`data:image/png;base64, ${item.flag}`}
            height="10px"
            style={{ marginTop: "2px" }}
            alt={item.name}
          />{" "}
          <span style={{ fontSize: "10px" }}>{item.name} </span>
        </div>
      ),
      chipLabel: (
        <div style={{ position: "absolute", top: "9px" }}>
          <img
            src={`data:image/png;base64, ${item.flag}`}
            height="18px"
            alt={item.name}
          />
        </div>
      ),
    };
  });
};

export const featureOption = (data) => {
  return data?.map((item) => {
    return {
      value: item?._id,
      label: item?.title,
    };
  });
};

export const featureOptionById = (fetureList, featureIds) => {
  let selectedData = [];
  if (Array.isArray(featureIds)) {
    for (let id of featureIds) {
      let findArr = fetureList?.find((feature) => id == feature?._id);
      if (findArr) {
        selectedData.push({
          value: findArr?._id,
          label: findArr?.title,
        });
      }
    }
  }
  return selectedData;
};
export const formatePlanObj = (data) => {
  let newobj = {
   
    taxDescription: data?.taxDescription,
    tax: parseInt(data?.tax),
    isUpgradeable: data?.isUpgradeable || false,
    name: data?.name,
    description: data?.description,
    featureIds: data?.feature?.map((itm) => itm?.value),
    limitations: {
      maxJobPostings: parseInt(data?.maxJobPostings),
      maxUsers: parseInt(data?.maxUsers),
      enhanceJobVisibility: data?.enhanceJobVisibility,
      databaseAccess: data?.databaseAccess,
      assessmentCredits:data?.assessmentCredits?parseInt(data?.assessmentCredits):0,
    },
    billingInterval: data?.billingInterval?.value,
    price: parseInt(data?.price),
    currency: data?.country?.value === "IN" ? "INR" : "USD",
    country: data?.country?.value,
    discountPercentage: data?.discountPercentage
      ? parseInt(data?.discountPercentage)
      : 0,
  };
  if (data?.subscriptionPlanId) {
    newobj.subscriptionPlanId = data?.subscriptionPlanId;
  }
  return newobj;
};

export const getCountryOption = (code) => {
  let country = SpecificCountry?.find((itm) => itm.iso == code);
  if (country) {
    return { label: country?.country, value: country?.iso };
  }
};
export const percentageMinus = (
  originalPrice,
  discountPercentage,
  billingInterval = "Monthly"
) => {
  if (discountPercentage < 0 || discountPercentage > 100) {
    return formatIndianNumber(originalPrice);
  }

  var discountFactor = 1 - discountPercentage / 100;
  var discountedPrice = originalPrice * discountFactor;
  if (billingInterval == "Annual") {
    discountedPrice = discountedPrice / 12;
  }

  return formatIndianNumber(parseInt(discountedPrice));
};

export const totalPrice = (
  addOnPrice = 0,
  originalPrice,
  discountPercentage,
  billingInterval = "Monthly"
) => {
  if (discountPercentage < 0 || discountPercentage > 100) {
    originalPrice = originalPrice + (addOnPrice || 0);
    return formatIndianNumber(originalPrice);
  }

  var discountFactor = 1 - discountPercentage / 100;
  var discountedPrice = originalPrice * discountFactor;
  if (billingInterval == "Annual") {
    discountedPrice = discountedPrice / 12;
  }
  originalPrice = parseInt(discountedPrice) + (addOnPrice || 0);
  return formatIndianNumber(originalPrice);
};
export const returnFetureClass = (pricingData, id) => {
  let check = pricingData?.feature?.some((itm) => itm?.value == id);
  if (check) {
    return "light-txt";
  }
  return "";
};

export const returnFetureList = (pricingData, fetureList) => {
  let maxJobPostings =
    pricingData?.maxJobPostings ||
    pricingData?.limitations?.maxJobPostings ||
    0;
  let maxUsers =
    pricingData?.maxUsers || pricingData?.limitations?.maxUsers || 0;
  let selectedLi = `   <li>${
    maxJobPostings == 0
      ? "Unlimited Job Posts"
      : maxJobPostings + " Active Job Post"
  } </li>
 <li>${maxUsers == 0 ? "Unlimited Platform Users" : maxUsers + " User"} </li>`;
  let crossLi = "";

  if (pricingData && fetureList) {
    for (let item of fetureList) {
      let check = pricingData?.feature?.some((itm) => itm?.value == item?._id);
      let check2 = pricingData?.featureIds?.some((itm) => itm == item?._id);
      if (check || check2) {
        selectedLi =
          selectedLi +
          `<li>${item?.title}
        <small>${item?.description}</small> 
        </li>`;
      } else {
        crossLi =
          crossLi +
          `<li class="light-txt">${item?.title}
        <small>${item?.description}</small> 
        </li>`;
      }
    }
  }
  return selectedLi + crossLi;
};

export const returnAddOnTotal = (addOn, addOnData) => {
  let checkData = addOnData?.find((item) => item?.addonId == addOn?._id);
  if (checkData) {
    return checkData?.addonQuantity * addOn?.price;
  }
  return addOn?.price;
};

export const returnAddOnQuantity = (addOn, addOnData) => {
  let checkData = addOnData?.find((item) => item?.addonId == addOn?._id);
  if (checkData) {
    return checkData?.addonQuantity;
  }
  return 1;
};

export const getCountryName = (code) => {
  let country = SpecificCountry?.find((itm) => itm.iso == code);
  if (country) {
    return country?.country;
  }
};
export const checkFreePlan = (planName) => {
  return (
    planName?.toLowerCase()?.includes("free") ||
    planName?.toLowerCase()?.includes("trial")
  );
};

export function formatIndianNumber(number) {
  // Convert the number to a string
  if (number) {
    const numberString = number.toLocaleString("en-IN");
    return numberString;
  }
}
export const optionalRequireSkill = (skill, required) => {
  let tempSkill = [];
  if (skill) {
    for (let item of skill) {
      if (item?.isRequired == required) {
        tempSkill.push({
          value: item?.skillId,
          label: item?.skillName,
          skillId: item?.skillId,
          skillName: item?.skillName,
          skillTestIcon: item?.skillTestIcon,
          isRequired: item?.isRequired,
        });
      }
    }
  }

  return tempSkill;
};
export const mergeSkillStack = (skills) => {
  let tempRequiredSkill = [];
  if (skills)
    for (let skill of skills) {
      tempRequiredSkill.push({
        ...skill,
        isRequired: true,
      });
    }
  return tempRequiredSkill;
};

export const getStringSplit = (strings, no) => {
  if (!strings) {
    return "";
  }
  return strings?.slice(0, no) + (strings?.length > no ? " ..." : "");
};

export const getOptionJobSkills = (allskill, selected) => {
  let tempOption = [];
  for (let item of selected) {
    let findData = allskill?.find((itm) => itm?.value == item?.skillId);
    if (findData) {
      tempOption.push({
        ...item,
        label: findData?.label,
        value: item?.skilId,
      });
    }
  }
  return tempOption;
};

export const filterSkill = (data) => {
  let skills = [];
  if (data) {
    data?.map((itm) => {
      // if (itm?.skill?.value) {
      let skillId = itm?.skill?.value || "";
      let skillName = itm?.skill?.label || "";
      let experiency = itm?.experiency ? parseInt(itm?.experiency) : "";
      let lastUsed = itm?.lastUsed ? moment(itm?.lastUsed) : "";
      let proficiencyLevel = itm?.proficiencyLevel?.value;
      skills.push({
        skillId,
        experiency,
        lastUsed,
        proficiencyLevel,
        skillName,
        isNewSkill:itm?.skill?.__isNew__ || false
      });
      // }
    });
  }
  return skills;
};


export const getPrevDateDiff = (review) => {
  return new Date(review?.ratedOn)
    .toDateString()
    .substring(4, new Date(review?.ratedOn).toDateString().length);
};

export const getDevLinkUrl = (slug) => {
  if (typeof window !== "undefined") {
    let str = String(window.location.origin + "/resume/" + slug);
    return str;
  }
};

export const returnAddonListArr=(data)=>{
  let lebels=[];
  if(!data?.length){
    return []
  }else{

    data?.map((item)=>{
      lebels.push({
        value:item?._id,
        label:item?.name
      })
    })
  }
  return lebels;
}

export const getDateMoment = (date,formate) => {
  if(formate=="monthYear"){
    return moment(date).format("MMM YYYY");
  }else if(formate=="onlyYear"){
    return moment(date).format("YYYY");
  }else {
    return moment(date).format("MMM DD YYYY");
  }
  
};