import React from "react";
import { Controller, useForm } from "react-hook-form";
import SelectOption from "../../../../components/select/SelectOption";
import {
  SpecificCountry,
  planTypeConstant,
} from "../../../../common/constant/commonData";
import {
  countryCodeOption,
  featureOption,
  featureOptionById,
  formatePlanObj,
  getCountryOption,
} from "../../../../components/functions";
import PricingBox from "../../../../components/pricing/PricingBox";
import { useDispatch, useSelector } from "react-redux";
import { addPricingPlanAction, getPlanListsAction, updatePricingPlanAction } from "../../../../service/action/admin";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const AddEditPlan = ({setAddPlan,data}) => {
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const { fetureList } = useSelector((state) => state.adminReducer);
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const enhanceJobVisibility = watch("enhanceJobVisibility");
  const databaseAccess = watch("databaseAccess");
  const isUpgradeable=watch("isUpgradeable")
  const country=watch("country")
  const pricingData=watch()
  useEffect(()=>{
    if(data){
       setValue("name",data?.name)
       setValue("description",data?.description)
       setValue("feature",featureOptionById(fetureList,data?.featureIds))
       setValue("maxJobPostings",data?.limitations?.maxJobPostings)
       setValue("maxUsers",data?.limitations?.maxUsers)
       setValue("enhanceJobVisibility",data?.limitations?.enhanceJobVisibility)
       setValue("databaseAccess",data?.limitations?.databaseAccess)
       setValue("billingInterval",{value:data?.billingInterval,label:data?.billingInterval})
       setValue("country",getCountryOption(data?.country))
       setValue("price",data?.price)
       setValue("currency",data?.currency)
       setValue("subscriptionPlanId",data?._id)
       setValue("discountPercentage",data?.discountPercentage)
       setValue("isUpgradeable",data?.isUpgradeable)
       setValue("taxDescription",data?.taxDescription)
       setValue("tax",data?.tax)
       setValue("assessmentCredits",data?.limitations?.assessmentCredits)
    }else{
       reset()
       setValue("name","")
       setValue("maxJobPostings","")
       setValue("maxUsers","")
       setValue("price","")
       setValue("discountPercentage","")
       setValue("subscriptionPlanId","")
       setValue("taxDescription","")
       setValue("tax","")
       setValue("assessmentCredits","")
    }

  },[data,setValue,reset,fetureList,])

  const callBack=()=>{
    dispatch(getPlanListsAction());
    setAddPlan(false)
  }

  const handleSave = (values) => {
    if (values?.subscriptionPlanId) {
        dispatch(updatePricingPlanAction(navigate,formatePlanObj(values),callBack))
    } else {
      dispatch(addPricingPlanAction(navigate,formatePlanObj(values),callBack))
    }
  };
  return (
    <div className="job-rquirement admin-modal">
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label className={errors?.name && "text-danger"}>Plan Name*</label>
            <input
              type="text"
              {...register("name", { required: true })}
              className="form-control"
              placeholder="Assisted Search"
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label>
              Plan Description*
            </label>
            <input
              type="text"
              {...register("description")}
              className="form-control"
              placeholder="Plan Description"
            />
          </div>
        </div>
        <div className="col-3">
          <label className={errors?.billingInterval && "text-danger"}>
            Select Plan theme*
          </label>
          <Controller
            control={control}
            name="billingInterval"
            {...register("billingInterval", { required: true })}
            render={({ field }) => (
              <SelectOption
                themeStyle={true}
                optionArray={planTypeConstant ? planTypeConstant : []}
                fieldProp={field}
                getValue={getValues}
                getValueName={"billingInterval"}
                closeMenuOnSelect={true}
              />
            )}
          />
        </div>
        <div className="col-3">
          <label className={errors?.country && "text-danger"}>Country*</label>
          <Controller
            control={control}
            name="country"
            {...register("country", { required: true })}
            render={({ field }) => (
              <SelectOption
                themeStyle={true}
                optionArray={countryCodeOption(SpecificCountry)}
                fieldProp={field}
                getValue={getValues}
                getValueName={"country"}
                closeMenuOnSelect={true}
              />
            )}
          />
        </div>
        <div className="col-3">
          <div className="form-group">
            <label className={errors?.price && "text-danger"}>Price*</label>
            <input
              {...register("price", { required: true })}
              type="text"
              className="form-control"
              placeholder={country?.value=="IN"?"₹":"$"}
            />
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <label>
              Discount
            </label>
            <input
              {...register("discountPercentage")}
              type="text"
              className="form-control"
              placeholder="%"
            />
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <label>
              Tax
            </label>
            <input
              {...register("tax")}
              type="text"
              className="form-control"
              placeholder="Tax"
            />
          </div>
        </div>
        <div className="col-9">
          <div className="form-group">
            <label>
              Tax Description
            </label>
            <input
              type="text"
              {...register("taxDescription")}
              className="form-control"
              placeholder="Tax Description"
            />
          </div>
        </div>
        <div className="col-12">
          <label className={errors?.feature && "text-danger"}>
            Select Feature*
          </label>
          <Controller
            control={control}
            name="feature"
            {...register("feature", { required: true })}
            render={({ field }) => (
              <SelectOption
                optionArray={featureOption(fetureList)}
                fieldProp={field}
                getValue={getValues}
                getValueName={"feature"}
                closeMenuOnSelect={true}
                isMulti={true}
              />
            )}
          />
        </div>
      </div>
      <label style={{ margin: "6px 0px" }}>Select Limitation</label>
      <div className="row">
        <div className="col-3">
          <div className="form-group">
            <label className={errors?.maxJobPostings && "text-danger"}>
              Max Job Postings*
            </label>
            <input
              {...register("maxJobPostings", { required: true })}
              type="text"
              className="form-control"
              placeholder="Max Job Postings"
            />
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <label className={errors?.maxUsers && "text-danger"}>
              Max Users*
            </label>
            <input
              {...register("maxUsers", { required: true })}
              type="text"
              className="form-control"
              placeholder="Max Users*"
            />
          </div>
        </div>
        <div className="col-3 limitation">
          <label>Enhance Job Visibility</label>
          <span className="hide-budget">
            <input
              type="checkbox"
              name="switch"
              {...register("enhanceJobVisibility")}
              onChange={(e) => e.preventDefault()}
              checked={enhanceJobVisibility}
            />
            <label
              onClick={(e) => {
                e.stopPropagation();
                setValue("enhanceJobVisibility", !enhanceJobVisibility);
              }}
              className="label"
              htmlFor="switch3"
            ></label>
          </span>
        </div>
        <div className="col-3 limitation">
          <label>Database Access</label>
          <span className="hide-budget">
            <input
              type="checkbox"
              name="switch"
              onChange={(e) => e.preventDefault()}
              checked={databaseAccess}
            />
            <label
              onClick={(e) => {
                e.stopPropagation();
                setValue("databaseAccess", !databaseAccess);
              }}
              className="label"
              htmlFor="switch3"
            ></label>
          </span>
        </div>
        <div className="col-3 limitation">
          <label>Updagradable</label>
          <span className="hide-budget">
            <input
              type="checkbox"
              name="switch"
              onChange={(e) => e.preventDefault()}
              checked={isUpgradeable}
            />
            <label
              onClick={(e) => {
                e.stopPropagation();
                setValue("isUpgradeable", !isUpgradeable);
              }}
              className="label"
              htmlFor="switch3"
            ></label>
          </span>
        </div>
        <div className="col-3">
        <div className="form-group">
            <label className={errors?.assessmentCredits && "text-danger"}>
            Assessment Credits*
            </label>
            <input
              
              {...register("assessmentCredits", { required: true })}
              type="number"
              className="form-control"
              placeholder="Max Users*"
            />
          </div>
        
        </div>
        <div className="col-12 text-right">
          <button className="btn" onClick={handleSubmit(handleSave)}>
            {data?._id?"Update":"Submit"}
          </button>
        </div>
      </div>
      <div className="pricing-page">
      <div className="pricing-container row">
      <PricingBox pricingData={pricingData} fetureList={fetureList}/>
      </div>
      </div>
    </div>
  );
};

export default AddEditPlan;
