import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPlanListsAction, updatePlanStatusAction } from "../../../../service/action/admin";
import { Fragment } from "react";
import DataTables from "../../../../components/data-tables/DataTables";
import ColumnPricingPlan from "./ColumnPricingPlan";
import { useNavigate } from "react-router-dom";

const PricingPlanLists = ({setAddPlan,setData}) => {
  const dispatch = useDispatch();
  const navigate=useNavigate();

  const getList=()=>{
    dispatch(getPlanListsAction());
  }
  const { planLists } = useSelector((state) => state.adminReducer);
  useEffect(() => {
    getList()
  }, []);

  const handleEdit = (data) => {
        setData(data)
        setAddPlan(true)
  };

  const changeStatus = (data) => {
    let tempObj={planId:data?._id,status:!data?.isActive}
    dispatch(updatePlanStatusAction(navigate,tempObj,getList))
  };
  return planLists&& (
    <Fragment>
      <DataTables
        columns={ColumnPricingPlan(changeStatus,handleEdit)}
        listData={{ data: planLists }}
        onRowClick={(item) => handleEdit(item)}
      />
    </Fragment>
  );
};

export default PricingPlanLists;
