import Sidebar from "./sidebar";
import Header from "./header";
import { useContext, useEffect, useState } from "react";
import PricingModal from "./client/PricingModal";
import { MainContext } from "../../context/MainContext";

const AppLayout = (props) => {
  const [isCollapsed, setCollapsed] = useState(false);
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const { user } = useContext(MainContext);


  return (
    <div className="App">
      {user?.userType == "Client" && user?.clientCurrentRole == "admin" && subscriptionModal && (
        <PricingModal
          openModal={subscriptionModal}
          setModal={setSubscriptionModal}
        />
      )}

      <div className="wrap">
        {isCollapsed && (
          <span
            className="collapse-button-open"
            onClick={() => setCollapsed(false)}
          >
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
          </span>
        )}
        <Sidebar isCollapsed={isCollapsed} setCollapsed={setCollapsed} />
        <Header
          isCollapsed={isCollapsed}
          setSubscriptionModal={setSubscriptionModal}
        />
        <main className={isCollapsed ? "main-collapsed" : "main"}>
          {props.child}
        </main>
      </div>
    </div>
  );
};

export default AppLayout;
