import React, { useContext, useEffect, useState } from "react";
import "./ProfilePreview.css";
import ProfileHeader from "./ProfileHeader";
import { useDispatch, useSelector } from "react-redux";
// import {
//   getDevProfileAction,
//   getSkillResultAction,
// } from "../../service/action/common";
import AboutSection from "./AboutSection";
import MyReviews from "./MyReviews";
import VettedSkills from "./skill-result/VettedSkills";
import Skills from "./Skills";
import ToolSection from "./ToolSection";
import SocialMediaLink from "./SocialMediaLink";
import PropTypes from "prop-types";
import ClipLoader from "../clipLoader/ClipLoader";
import { useNavigate } from "react-router-dom";
import { MainContext } from "../../context/MainContext";
import { getResultDev, getResume, skillList, stackList } from "../../service/action/developer";
import { getAdminDevResult } from "../../service/action/admin";
// import DownloadPDFCompoenent from "./download-pdf/DownloadPDFCompoenent";
// import HandleNavigation from "./header/HandleNavigation";
// import {
//   currentEmploymentAction,
//   getAllSkillsListAction,
//   getAllToolsAction,
//   getDevSocialProfileAction,
// } from "../../service/action/developer";
const ProfilePreviewNew = ({
  developerId,
  setEdit = false,
  reapprovalEdit = false,
  // showNextPrev = false,
  // onNext = false,
  // onPrev = false,
  isSavedProfile = false,
  onSaveProfile = false,
  // rejectWithFeedback = false,
  // rejectWithoutFeedBack = false,
  // isFirstItemDisplay = false,
  // isLastItemDisplay = false,
  // showShortlistedReject = false,
  isViewSaveProfile = true,
  // devProfile,
}) => {
  const router = useNavigate();
  const dispatch = useDispatch();
  // const dispatch = useDispatch();
  // const [id, setDevId] = useState("");
  // const { devId } = router.query;
  const [devData, setUserData] = useState({});

  const { resumeData } = useSelector((state) => state.developerReducer);
  const { user, isResumeLoading } = useContext(MainContext);
  useEffect(() => {
    if (resumeData) {
      setUserData(resumeData);
    }
  }, [resumeData]);

  useEffect(() => {
    dispatch(getResume(developerId, router))
    dispatch(stackList(router));
    dispatch(skillList(router));;
  }, []);

  // useEffect(() => {
  //   if (id || developerId) {
  //     dispatch(getDevProfileAction({ devId: id || developerId }));
  //     dispatch(getSkillResultAction({ devId: id || developerId }));
  //   }
  // }, [id, developerId]);

  const handleAddReview = () => {
    router("/developer/my-review");
  };

  useEffect(() => {
    if (user?._id) {
      dispatch(getAdminDevResult(developerId, router));
    }
  }, [user]);
  
  const PreviewContent = () => (
    <>
      {/* <span className="header-span"></span> */}
      <section className="candidate-detail-section profile-preview">
        {/* <DownloadPDFCompoenent
        devData={devData}
        setEdit={setEdit}
        reapprovalEdit={reapprovalEdit}
        
      /> */}
        <ProfileHeader
          devData={devData}
          setEdit={setEdit}
          reapprovalEdit={reapprovalEdit}
          isSavedProfile={isSavedProfile}
          onSaveProfile={onSaveProfile}
          isViewSaveProfile={isViewSaveProfile}
        />
        <div className="candidate-detail-outer">
          <div className="auto-container">
            <div className="row">
              <AboutSection devData={devData} />
              <div className="sidebar-column col-4">
                <aside className="sidebar-custom">
                  <VettedSkills devData={devData} />
                  <Skills result={devData?.devSkillsInfo} />
                  <ToolSection result={devData?.devTools} />
                  <SocialMediaLink result={devData?.devSocialProfile} />
                  {user?.userType === "Developer" && (
                    <div className="call-to-action-four ">
                      <h5>My Reviews</h5>
                      <p>
                        Boost your Olibr profile! Request genuine reviews from
                        current or past managers via work email. Beware, fake
                        reviews risk platform disbarment.
                      </p>
                      <div
                        onClick={handleAddReview}
                        className="theme-btn btn-style-one bg-blue pointer"
                      >
                        <span className="btn-title">Add Review Now</span>
                      </div>
                      <div
                        className="image"
                        style={{
                          backgroundImage: "url(/images/resource/ads-bg-4.png)",
                        }}
                      ></div>
                    </div>
                  )}
                </aside>
              </div>
              <MyReviews devReviews={devData?.devReviews} />
            </div>
          </div>
        </div>
      </section>
    </>
  );

  return (
    <>
      {/* {showNextPrev ? (
        <HandleNavigation
          nextBtn={onNext}
          prevBtn={onPrev}
          devId={developerId}
          rejectWithoutFeedBack={rejectWithoutFeedBack}
          rejectWithFeedback={rejectWithFeedback}
          onSaveProfile={onSaveProfile}
          isFirstItemDisplay={isFirstItemDisplay}
          isLastItemDisplay={isLastItemDisplay}
          showShortlistedReject={showShortlistedReject}
          devProfile={devProfile}
          child={
            <>
              {isDevLoading ? (
                <div className="text-center" style={{ minHeight: "500px" }}>
                  <ClipLoader color={"#1371f0"} size={20} />
                </div>
              ) : (
                <PreviewContent />
              )}
            </>
          }
        />
      ) : ( */}
      <>
        {isResumeLoading ? (
          <div className="text-center" style={{ minHeight: "500px" }}>
            <ClipLoader color={"#1371f0"} size={20} />
          </div>
        ) : (
          <PreviewContent />
        )}
      </>
      {/* )} */}
    </>
  );
};
ProfilePreviewNew.propTypes = {
  developerId: PropTypes.string,
  setEdit: PropTypes.any,
  reapprovalEdit: PropTypes.bool,
  showNextPrev: PropTypes.bool,
  isFirstItemDisplay: PropTypes.bool,
  isLastItemDisplay: PropTypes.bool,
  onNext: PropTypes.bool,
  onPrev: PropTypes.bool,
  showShortlistedReject: PropTypes.bool,
  isSavedProfile: PropTypes.bool,
  onSaveProfile: PropTypes.func,
  rejectWithFeedback: PropTypes.func,
  rejectWithoutFeedBack: PropTypes.func,
  devProfile: PropTypes.any,
  isViewSaveProfile: PropTypes.bool,
};

export default ProfilePreviewNew;
