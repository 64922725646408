import React, { Fragment, useEffect, useRef, useState } from "react";
import Modal from "../../../../components/modal/ModalBox";
import { Controller, useForm } from "react-hook-form";
import OlibrEditor from "../../../../components/editor";
import { Icon } from "../../../../components/icon";
import { ScaleLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { olibrToast } from "../../../../components/toaster";
const Project = ({ approvalUpdates }) => {
  const { isDevProjectImageLoading } = useSelector(
    (state) => state.developerReducer
  );
  const {
    register: projectRegister,
    setValue: setProjectValue,
    handleSubmit: projectSubmit,
    getValues: getProjectValue,
    reset: resetProjectForm,
    setError: setProjectError,
    control: projectControl,
    watch,
    formState: { errors: projectError },
  } = useForm();

  const uploadProjectImageRef = useRef();
  const [customLightBox, setCustomLightBox] = useState(false);
  const closeCustomLightBox = () => {
    setCustomLightBox(false);
  };

  const devProjectDetails = watch();

  useEffect(() => {
    if (approvalUpdates?.length) {
      let tempAdditional = approvalUpdates?.find(
        (item) => item?.slug == "project"
      );

      if (tempAdditional) {
        tempAdditional = tempAdditional?.newValue;
        setProjectValue("clientName", tempAdditional?.clientName);
        setProjectValue("description", tempAdditional?.description);
        setProjectValue("name", tempAdditional?.name);
        setProjectValue("projectIndus", tempAdditional?.projectIndus);
        setProjectValue("responsibilities", tempAdditional?.responsibilities);
        setProjectValue("role", tempAdditional?.role);
        setProjectValue("skills", tempAdditional?.skills);
        setProjectValue("image", tempAdditional?.image);
      }
    }
  }, [approvalUpdates]);
  const addSkillInProject = () => {
    if (!getProjectValue("projectSkill")) {
      olibrToast.error("please enter skill name!");
      return false;
    }
    if (devProjectDetails?.skills && devProjectDetails?.skills?.length > 0) {
      const check = devProjectDetails?.skills?.filter(
        (item) =>
          item?.toUpperCase() == getProjectValue("projectSkill")?.toUpperCase()
      );
      if (check?.length > 0) {
        olibrToast.error("tag already exists!");
        return false;
      } else {
        setProjectValue("skills", [
          ...getProjectValue("skills"),
          getProjectValue("projectSkill"),
        ]);
        setProjectValue({
          ...devProjectDetails,
          skills: [
            ...devProjectDetails?.skills,
            getProjectValue("projectSkill"),
          ],
        });
      }
    } else {
      setProjectValue("skills", [getProjectValue("projectSkill")]);
      setProjectValue({
        ...devProjectDetails,
        skills: getProjectValue("skills"),
      });
    }
    setProjectValue("projectSkill", "");
  };

  const removeImg = (index) => {
    let arr = devProjectDetails?.image?.filter((item, ind) => index != ind);
    setProjectValue({ ...devProjectDetails, image: arr });
  };

  const projectInfoModalClose = () => {
    setProjectValue("skills", "");
    resetProjectForm();
    setProjectValue({
      modal: false,
      edit: false,
      image: [],
      skills: [],
    });
  };
  const customLightContent = () => {
    return (
      <Fragment>
        <div>
          <section class="carousel" aria-label="carousel" Tabindex="0">
            <a class="carousel__skip-link" href="#skip-link">
              Carousel überspringen
            </a>
            <div class="slides">
              {devProjectDetails?.image?.map((item) => (
                <div
                  class="slides-item slide-1"
                  id={item}
                  aria-label="slide 1 of 5"
                  tabindex="0"
                >
                  <img src={item} alt="" />
                </div>
              ))}
            </div>
            <div class="carousel__nav">
              {devProjectDetails?.image?.map((item, index) => (
                <a
                  class="slider-nav"
                  href={`#${item}`}
                  aria-label="Go to slide 5"
                >
                  {index + 1}
                </a>
              ))}
            </div>
          </section>
        </div>
      </Fragment>
    );
  };

  return (
    <form>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label
              className={`floating-label ${
                projectError?.name && "text-danger"
              }`}
              htmlFor=""
            >
              Project Name*
            </label>
            <input
              type="text"
              {...projectRegister("name", {
                required: true,
              })}
              name="name"
              className={`form-control ${
                projectError?.name && "form-required"
              }`}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label
              className={`floating-label ${
                projectError?.clientName && "text-danger"
              }`}
              htmlFor=""
            >
              Client Name
            </label>
            <input
              type="text"
              {...projectRegister("clientName")}
              name="clientName"
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label
              className={`floating-label ${
                projectError?.role && "text-danger"
              }`}
              htmlFor=""
            >
              Role*
            </label>
            <input
              type="text"
              {...projectRegister("role", { required: true })}
              name="role"
              className={`form-control ${
                projectError?.role && "form-required"
              }`}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="floating-label" htmlFor="">
              Project Industry
            </label>
            <input
              type="text"
              name="projectIndus"
              {...projectRegister("projectIndus")}
              className="form-control"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <div className="row">
              <div className="col-10">
                <label
                  className={`floating-label ${
                    projectError?.description && "text-danger"
                  }`}
                  htmlFor=""
                >
                  Project Description*
                </label>
              </div>
            </div>

            <Controller
              control={projectControl}
              name="description"
              {...projectRegister("description", {
                required: true,
              })}
              render={({ field }) => (
                <OlibrEditor
                  value={
                    getProjectValue("description")
                      ? getProjectValue("description")
                      : ""
                  }
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <div className="row">
              <div className="col-10">
                <label
                  className={`floating-label ${
                    projectError?.responsibilities && "text-danger"
                  }`}
                  htmlFor=""
                >
                  Roles and Responsibilities*
                </label>
              </div>
            </div>

            <Controller
              control={projectControl}
              name="responsibilities"
              {...projectRegister("responsibilities", {
                required: true,
              })}
              render={({ field }) => (
                <OlibrEditor
                  value={
                    getProjectValue("responsibilities")
                      ? getProjectValue("responsibilities")
                      : ""
                  }
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label
              className={`floating-label ${
                projectError?.skills ? "text-danger" : ""
              }`}
              htmlFor=""
            >
              Add Skills*
            </label>
            <input
              {...projectRegister("skills", { required: true })}
              name="skills"
              type="hidden"
            />
            <div style={{ display: "flex" }}>
              <input
                type="text"
                {...projectRegister("projectSkill")}
                name="projectSkill"
                onKeyDown={(e) => {
                  if (e?.code === "Enter") {
                    addSkillInProject();
                  }
                }}
                className="form-control"
              />
              <a onClick={() => addSkillInProject()}>
                <Icon name="add-icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <ul className="tags tags-fill">
        {console.log("devProjectDetails",devProjectDetails)}
        {devProjectDetails?.skills?.map((item, index) => item && (
          <li>
             {console.log("devProjectDetails2",devProjectDetails)}
            <a>
              <span>{item}</span>{" "}
              <i
                onClick={() => {
                  if (devProjectDetails?.skills?.length > 0) {
                    const tempSkill = [...devProjectDetails?.skills];
                    tempSkill.splice(index, 1);
                    if (devProjectDetails?.skills?.length == 1) {
                      setProjectValue("skills", []);
                    } else {
                      setProjectValue("skills", tempSkill);
                    }
                    setProjectValue({
                      ...devProjectDetails,
                      skills: tempSkill,
                    });
                  }
                }}
              >
                <Icon name="modal-close" />
              </i>
            </a>
          </li>
        ))}
      </ul>
      <br />

      <div className="row">
        <div className="col-12">
          <a
            className="file-icon"
            onClick={() => uploadProjectImageRef.current.click()}
          >
            {isDevProjectImageLoading ? (
              <ScaleLoader color="#3BA0AA" height={10} />
            ) : (
              "Upload project image"
            )}
          </a>
        </div>
        <div className="col-12">
          <br />
          <Modal
            size="100%"
            open={customLightBox}
            title={"Project Images"}
            onClose={closeCustomLightBox}
            content={() => customLightContent()}
            isFooterView={false}
          />

          {devProjectDetails?.image?.map((imgUrl, index) => (
            <Fragment>
              <div className="portfolio-images">
                <figure>
                  <img
                    key={index + Math.random()}
                    onClick={() => {
                      setCustomLightBox(true);
                    }}
                    src={imgUrl}
                    alt="Project Image"
                  />
                </figure>
              </div>
              <span
                onClick={() => removeImg(index)}
                style={{
                  position: "relative",
                  bottom: "60px",
                  right: "15px",
                }}
              >
                <Icon name="modal-close" />
              </span>
            </Fragment>
          ))}
        </div>
      </div>
      <br />
      <br />
    </form>
  );
};

export default Project;
