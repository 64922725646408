const specificFunction = [
  {
    label: "Send Job Email",
    value: "Send Job Email",
  },
  {
    label: "Delete Developer Profile",
    value: "DeleteDevProfile",
  },
  {
    label: "Delete Client Profile",
    value: "DeleteClientProfile",
  },
  {
    label:"Block Client Profile",
    value: "BlockClientProfile"
  },
  {
    label: "Export Developer Data",
    value: "ExportDevData",
  },
  {
    label: "Export Client Data",
    value: "ExportClientData",
  },
];
const adminRouteLinks = [
  {
    link: "/admin/dashboard",
    name: "Dashboard",
    icon: "dashboard",
  },
  {
    link: "/admin/dev-management",
    name: "Dev Management",
    icon: "dev-management",
  },
  {
    link: "/admin/pricing-plan",
    name: "Pricing Plan",
    icon: "dashboard",
  },
  // {
  //   link: "/admin/test-result",
  //   name: "Test Result",
  //   icon: <Icon name="user-icon" />,
  // },
  {
    link: "/admin/skill-management",
    name: "Skills Management",
    icon: "skill-management",
  },
  {
    link: "/admin/perks-and-benifits",
    name: "Perks and Benifits",
    icon: "perks-benifits",
  },
  {
    link: "/admin/user-management",
    name: "User Management",
    icon: "user-management",
  },
  {
    link: "/admin/client-management",
    name: "Client Management",
    icon: "client-management",
  },
  {
    link: "/content-writer/jobs",
    name: "Job Management",
    icon: "job-management",
  },
  {
    link: "/content-writer/faqs",
    name: "FAQs",
    icon: "faq",
  },
  {
    link: "/admin/blog",
    name: "Blog",
    icon: "shortlisted-prof",
  },
  {
    link: "/content-writer/english-test",
    name: "English Test",
    icon: "english-test",
  },
  {
    link: "/admin/email-management",
    name: "Email Automation",
    icon: "email-autumation",
  },
  {
    link: "/admin/direct-update",
    name: "Direct Update",
    icon: "direct-update",
  },
  {
    link: "/admin/create-skill-test",
    name: "Create Skill Test",
    icon: "create-skill-test",
  },
  {
    link: "/admin/maintenance",
    name: "Maintenance Message",
    icon: "maintenance-message",
  },
  {
    link: "/admin/spotlight-history",
    name: "Spotlight History",
    icon: "spotlight-history",
  },
  {
    link: "/admin/interview-request",
    name: "Interview Requests",
    icon: "interview-request",
  },
  {
    link: "/admin/interview-list",
    name: "Interview List",
    icon: "interview-list",
  },
  {
    link: "/admin/email-history",
    name: "Email History",
    icon: "email-history",
  },
  {
    link: "/admin/sitemap-management",
    name: "SiteMap Generator",
    icon: "shortlisted-prof",
  },
  {
    link: "/admin/contact-us-queries",
    name: "Queries",
    icon: "query",
  },
  // {
  //   link: "/admin/settings",
  //   name: "Settings",
  //   icon: <Icon name="settings" />,
  // },
];

export const routeLinks = {
  Admin: adminRouteLinks,
  Developer: [
    {
      link: "/developer/dashboard",
      name: "Dashboard",
      icon: "dashboard-icon",
    },
    {
      link: "/developer/profile",
      name: "Profile",
      icon: "user-profile-icon",
    },
    {
      link: "/developer/skill-test",
      name: "Skill Test",
      icon: "skill-test-new",
    },
    {
      link: "/developer/save-job",
      name: "Saved Jobs",
      icon: "save-job-icon",
    },
    {
      link: "/developer/review",
      name: "My Reviews",
      icon: "review",
    },
    {
      link: "/developer/video",
      name: "Video Introduction",
      icon: "video-sidebar",
    },
  ],
  Client: [
    {
      link: "/client/client-dashboard",
      name: "Dashboard",
      icon: "dashboard-icon",
    },
    {
      link: "/client/company-profile",
      name: "Company Profile",
      icon: "shortlisted-prof",
    },
    {
      link: "/client/user-management",
      name: "User Management",
      icon: "user-management",
    },
    {
      link: "/client/saved-profiles",
      name: "Saved Profiles",
      icon: "save-profile",
    },
    {
      link: "/client/candidates",
      name: "Applicants",
      icon: "candidate",
    },
    {
      link: "/client/interviewCalender",
      name: "Calendar",
      icon: "calender",
    },
    {
      link: "/client/posted-job",
      name: "Your Jobs",
      icon: "your-job",
    },
    {
      link: "/client/search-developer",
      name: "Search Talent",
      icon: "search-talent",
    },
    // {
    //   link: "/client/direct-contact",
    //   name: "Direct Contact",
    //   icon: "direct-contact",
    // },
    {
      link: "/client/test-tracker",
      name: "Test Tracker",
      icon: "shortlisted-prof",
    },
    // {
    //   link: "/client/settings",
    //   name: "Settings",
    //   icon: "settings",
    // },
  ],
};

export const optionSelect = adminRouteLinks?.map((item) => item?.name);
export const specificFunctions = specificFunction;
