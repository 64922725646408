import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "../icon";
import { ReactComponent as StarJob } from "./../../assets/img/icons/Star-job.svg";
// import ctcIcon from "../../assets/img/icons/ctc-icon.svg";
// import yrexpIcon from "../../assets/img/icons/yr-exp.svg";
import { useContext } from "react";
import { MainContext } from "../../context/MainContext";
import {
  applyNewJob,
  getAppliedJob,
  saveJobFavourit,
} from "../../service/action/developer";
import { useDispatch, useSelector } from "react-redux";
import { olibrToast } from "../toaster";
// import LocationIcon from "../../assets/img/icons/location.svg";
import Modal from "../modal/ModalBox";
import { ClipLoader } from "react-spinners";
import InfiniteScroll from "react-infinite-scroll-component";
import OlibrShareLink from "../share-link";
import {
  checkDevCountry,
  checkJobApplied,
  checkMultipleJob,
  checkQuestionaries,
  checkSkillPassed,
  countryFlag,
  filterFrontUrlEnv,
} from "../functions";
import Feedback from "../Feedback";
// import FeedbackIcon from "../../assets/img/icons/feedback.svg"
import DevQuestionForm from "../questionaries/DevQuestionForm";
import FeedbackIcon from "../../assets/img/icons/feedback.png";
import CountryFlag from "./CountryFlag";
import JobListSkill from "./JobListSkill";
const JobLists = ({
  mainJobList,
  appliedArr,
  handleCheckBox,
  hideCheckBox = false,
  hideApply = false,
  hideSave = false,
  applyData = [],
  fetchMoreData,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useContext(MainContext);
  const [jobData, setJobData] = useState([]);
  const { appliedJob, isSubmitQuesLoading } = useSelector(
    (state) => state.developerReducer
  );
  const { stateList } = useSelector((state) => state.commonReducer);
  const { jobByCatIdLoading } = useSelector((state) => state?.commonReducer);
  const [applyJob, setApplyJob] = useState([]);

  useEffect(() => {
    let arr = [];
    arr = appliedJob?.data?.map((item) => item?.jobId?._id);
    setApplyJob(arr);
  }, [appliedJob]);

  useEffect(() => {
    if (user && Object.keys(user)?.length) {
      dispatch(getAppliedJob(navigate));
    }
  }, [user]);

  useEffect(() => {
    appliedArr?.length && setApplyJob(appliedArr);
  }, [appliedArr]);

  useEffect(() => {
    mainJobList && setJobData(mainJobList);
  }, [mainJobList]);

  const jobTitle = (title) => {
    let result = title?.slice(0, 70);
    result += title?.length > 70 ? "..." : "";
    return result;
  };

  const handleSaveJob = (e, id) => {
    e.stopPropagation();
    if (user?.userType == undefined || user?.userType == "Developer") {
      dispatch(saveJobFavourit(navigate, id, user?._id, jobData, setJobData));
    }
  };
  const [questionDetail, setQuestionDetail] = useState({
    modal: false,
    question: [],
    data: {},
  });
  const handleJobApply = (jobId, job) => {
    if (!user?.userType) {
      navigate("/login");
    } else if (!user?.numberVerified) {
      olibrToast.error(
        "To apply, please verify your phone number. You can do this by visiting the settings page and completing the verification process."
      );
    } else {
      if (!checkDevCountry(user, job)) return false;
      if (
        user?.userType === "Developer" &&
        user?.devStatus == "approved" 
        // &&
        // checkSkillPassed(
        //   user?.devTechTestResult,
        //   job?.devChooseSkills,
        //   job?.devChooseStacks
        // )
      ) {
        if (
          job?.questionaries?.length &&
          checkQuestionaries(job?.questionaries)
        ) {
          setQuestionDetail({
            modal: true,
            question: job?.questionaries,
            data: { devId: user?._id, jobId: [jobId] },
          });
        } else {
          dispatch(
            applyNewJob(
              { devId: user?._id, jobId: [jobId], devAnswer: [] },
              navigate
            )
          );
        }
      } else {
        olibrToast.error(
          "Your Olibr profile is not complete yet. You will be eligible to apply once your profile is complete"
        );
      }
    }
  };

  const handleCompanyDetail = (companySlug) => {
    if (companySlug) {
      window.open(filterFrontUrlEnv(`company-profile/${companySlug}`));
    }
  };

  const [jobOpen, setJobOpeDetails] = useState({
    modal: false,
    content: "",
  });

  const Content = () => {
    return (
      <div className="job-desc-block">
        <h4>
          Description{" "}
          {/* <span className="jl-btn">
                <a href="#">&#9733;</a>
              </span> */}
        </h4>
        <p dangerouslySetInnerHTML={{ __html: jobOpen?.content }}></p>
      </div>
    );
  };

  const handleOpenDesc = (shortDesc) => {
    setJobOpeDetails({
      content: shortDesc,
      modal: true,
    });
  };

  const [feedback, setFeedback] = useState({
    message: "",
    open: false,
  });
  const showJobDetail = (slug, id) => {
    if (slug) {
      navigate(`/jobs/${slug}`);
    } else {
      navigate(`/jobs/${id}`);
    }
  };

  const checkFeedBack = (e, data) => {
    e?.stopPropagation();
    if (!!data?.jobProfileFeedBack && data?.devId == user?._id)
      setFeedback({
        open: true,
        message: data?.jobProfileFeedBack,
      });
  };
  const closeModal = () => {
    setFeedback({
      open: false,
      message: "",
    });
  };

  const closeQuesModal = () => {
    setQuestionDetail({
      modal: false,
      data: "",
      question: [],
    });
  };
  const applyJobWithQuestion = (data) => {
    dispatch(
      applyNewJob(
        { ...questionDetail?.data, devAnswer: data },
        navigate,
        closeQuesModal
      )
    );
  };

  const [shareOpen, setShareOpen] = useState(false);
  const [shareLink, setShareLink] = useState("");
  return (
    <Fragment>
      <Modal
        open={questionDetail?.modal}
        hasHeader={false}
        content={() => (
          <DevQuestionForm
            isLoading={isSubmitQuesLoading}
            closeQuesModal={closeQuesModal}
            applyJobWithQuestion={applyJobWithQuestion}
            data={questionDetail?.question}
          />
        )}
        isCancelAble={false}
        size="60%"
        contentClass="questionaries-header"
        isFooterView={false}
      />
      <Modal
        open={jobOpen?.modal}
        isFooterView={false}
        content={() => <Content />}
        onClose={() => setJobOpeDetails({ modal: false, content: "" })}
      />
      <OlibrShareLink
        isOpen={shareOpen}
        baseUrl={shareLink}
        onClose={() => setShareOpen(false)}
        title={"Share Job"}
      />

      <Modal
        title={"Feedback"}
        onClose={closeModal}
        open={feedback?.open}
        content={() => <Feedback message={feedback?.message} />}
        isFooterView={false}
      />

      <InfiniteScroll
        dataLength={jobData?.data ? jobData?.data?.length : 0}
        next={fetchMoreData}
        style={{ overflow: "unset" }}
        hasMore={jobData?.totalJobPosts <= jobData?.data?.length ? false : true}
      >
        {jobData?.data?.map((job, index) => (
          <div className="row" key={index}>
            <div className="col-12">
              <div className="jl-logo">
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCompanyDetail(job?.companySlug);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={job?.jobLogo}
                    width={50}
                    style={{ padding: "5px" }}
                    alt=""
                  />
                </span>
              </div>
              {!hideCheckBox && (
                <div className="input-checkbox">
                  <input
                    type="checkbox"
                    className=""
                    disabled={applyJob?.includes(job?._id)}
                    checked={checkJobApplied(job?._id, applyData)}
                    onClick={() => handleCheckBox(job)}
                  />
                </div>
              )}

              <div className="job-listing-section">
                <div className="row">
                  <div className="col-9">
                    <h4
                      onClick={(e) => {
                        e.stopPropagation();
                        showJobDetail(job?.slug, job?._id);
                      }}
                      style={{
                        width: "fit-content",
                        cursor: "pointer",
                        display: "inline",
                      }}
                    >
                      {jobTitle(job?.jobTitle)}
                    </h4>{" "}
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setShareLink(
                          String(window.location.origin + "/jobs/" + job?.slug)
                        );
                        setShareOpen(true);
                      }}
                    >
                      <Icon name="share-profile" />
                    </span>
                  </div>

                  {!hideApply && (
                    <div className="col-3">
                      <div className="text-right">
                        {applyJob?.includes(job?._id) ? (
                          <>
                            <span
                              onClick={(e) => checkFeedBack(e, job)}
                              className="applynow"
                              style={{ color: "#3BA0AA", cursor: "pointer" }}
                            >
                              {!!job?.jobProfileFeedBack && (
                                <span data-tooltip={"Feedback"}>
                                  <img
                                    src={FeedbackIcon}
                                    className="job-feedback"
                                    onClick={(e) => checkFeedBack(e, job)}
                                  />
                                </span>
                              )}
                              APPLIED
                            </span>
                          </>
                        ) : (
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              handleJobApply(job?._id, job);
                            }}
                            className="applynow"
                            style={{ color: "#3BA0AA", cursor: "pointer" }}
                          >
                            APPLY NOW
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="row job-det">
                  <div className="col-3">
                    <p
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCompanyDetail(job?.companySlug);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <span>
                        <Icon name="suitcase-new" />
                      </span>
                      {job?.organisation}
                    </p>
                  </div>
                  <div className="col-2">
                    <p>
                      <span>
                        {/* <img
                          src={yrexpIcon}
                          style={{ width: "18px", height: "18px" }}
                        /> */}
                        <img
                          src={
                            "https://ik.imagekit.io/olibr/icons/tr:w-18,h-15/yr-exp.svg"
                          }
                          style={{ width: "18px", height: "18px" }}
                        />
                      </span>{" "}
                      {job?.minExpRange} - {job?.maxExpRange} Years
                    </p>
                  </div>
                  {!job?.hideBudgetToDev &&
                    !!job?.budgetMin &&
                    !!job?.budgetMax && (
                      <div className="col-3">
                        <p>
                          <span>
                            <img
                              src={
                                "https://ik.imagekit.io/olibr/icons/tr:w-18,h-15/ctc-icon.svg"
                              }
                              style={{ width: "18px", height: "18px" }}
                            />
                          </span>{" "}
                          {
                          (job?.jobCountry?.length < 1 ||
                            job?.jobCountry?.[0]?.countryCode == "IN" ||
                            job?.jobCountry?.countryCode == "IN")
                            ?job?.budgetMin +
                            " - " +
                            job?.budgetMax +
                            " Lacs PA"
                            : "$" +
                            job?.budgetMin +
                            " - " +
                            job?.budgetMax +
                            " PA"
                            }
                        </p>
                      </div>
                    )}

                  <div className="col-2">
                    <p>
                      <span className="vertical-bottom">
                        <CountryFlag jobCountry={job?.jobCountry} />
                      </span>
                      {job?.jobLocation
                        ? job?.jobLocation == "On Site" ||
                          job?.jobLocation == "Hybrid"
                          ? checkMultipleJob(job, stateList)
                          : job?.jobLocation
                        : "Remote"}
                    </p>{" "}
                  </div>
                  <div
                    className="col-2 jl-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenDesc(job?.shortDesc);
                    }}
                  >
                    <div>
                      <Icon name="info-button" />
                    </div>
                    <div
                      data-tooltip={
                        job?.fevUsersIds?.includes(user?._id)
                          ? "Remove from favourite"
                          : "Add to Favourite"
                      }
                    >
                      {!hideSave && (
                        <StarJob
                          fill="current"
                          data-tooltip={"Save Job"}
                          onClick={(e) => handleSaveJob(e, job?._id)}
                          className={`save-job ${job?.fevUsersIds?.includes(user?._id)
                            ? "save-job-active"
                            : ""
                            }`}
                        />
                      )}
                    </div>
                  </div>
                </div>
                  {/* <ul className="icon-tags">
                    {job?.devChooseSkills?.map((item) => (
                      <li key={item?.skillId}>
                        <a>
                          <img src={item?.skillTestIcon} alt="" />{" "}
                          {item?.skillName}
                          {item?.isRequired && (
                            <i
                              class="fa fa-star required-skill-star"
                              style={{ color: "#ff4000" }}
                              aria-hidden="true"
                            ></i>
                          )}
                        </a>
                      </li>
                    ))}
                    {job?.devChooseStacks?.map((item) => (
                      <li key={item?.stackId}>
                        <a>
                          <img src={item?.stackIcon} alt="" /> {item?.stackName}
                          {item?.isRequired && (
                            <i
                              class="fa fa-star required-skill-star"
                              style={{ color: "#ff4000" }}
                              aria-hidden="true"
                            ></i>
                          )}
                        </a>
                      </li>
                    ))}
                  </ul> */}
                  <JobListSkill
                   skill={job?.devChooseSkills}
                   stack={job?.devChooseStacks}
                  />
                {/* <div className="col-12">
                  <ul className="skill">
                    {job?.tags?.map((skillTag, i) => (
                      <li key={i + Math.random()}>{skillTag}</li>
                    ))}
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        ))}
      </InfiniteScroll>
      {jobByCatIdLoading && (
        <div className="loader-container text-center">
          <ClipLoader color="#3BA0AA" />
        </div>
      )}
    </Fragment>
  );
};

export default JobLists;
