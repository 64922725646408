import React from "react";

const Listing = ({ jobCatList, setModalState, setPostData }) => {
  const clickEdit = (item) => {
    setPostData({
      catTags: item?.catTags,
      categoryName: item?.categoryName,
      catId: item?._id,
    });
    setModalState({
      isEdit: true,
      isOpen: true,
    });
  };
  return (
    <table className="table-blog">
      <thead>
        <tr>
          <th className="column">
            <div className="serialNumber">S.No</div>
          </th>
          <th className="column">
            <div className=" left-aligned">Category Name</div>
          </th>

          <th className="column">
            <div className="left-aligned">TAGS</div>
          </th>
        </tr>
      </thead>
      <tbody>
        {jobCatList?.data?.map((item, i) => (
          <tr
            className="faqdiv hover-row"
            onClick={() => clickEdit(item)}
            // onClick={() => handleEdit(item)}
          >
            <td className="sno">
              {/* {(pageData.page - 1) * pageData.limit + (i + 1)} */}
              {i + 1}
            </td>
            <td className="subQues">{item?.categoryName}</td>

            <td className="postedOn">
              {item?.catTags?.map((tagItem) => (
                <span>{tagItem},&nbsp;</span>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Listing;
