import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { jobListOG } from "../imageLinks";
import NoFollow from "../../../common/staticData/seo/NoFollow";

const Jobs = ({ baseUrl }) => {
  return (
    <Helmet>
      <title>New software developer jobs in USA companies | Olibr Jobs</title>
      <meta charset="UTF-8" />
      <meta
        name="title"
        content="New software developer jobs in USA companies | Olibr Jobs"
      />
      <meta
        name="description"
        content="Welcome to Olibr Jobs, where you’ll find the best software developer jobs and IT roles. Apply now, and work with top USA companies."
      ></meta>
      <link rel="canonical" href={`${baseUrl}/jobs`} />
      <meta
        name="keywords"
        content="Software Developer Jobs, Software Developer Jobs USA, jobs, olibr.com, tech jobs, olibr, olibr.com, Hiring developers, remote software developers, work-from-home developers, wfh, remote jobs, IT jobs, US based IT companies, US Jobs, US IT Jobs"
      />
         <meta name="robots" content={NoFollow()}></meta>

      <meta name="language" content="english"></meta>
      <html lang="en" />
      <meta name="url" content={`${baseUrl}/jobs`} />
      <meta name="identifier-URL" content={`${baseUrl}/jobs`}></meta>
      <meta name="referrer" content="origin-when-crossorigin"></meta>
      <link rel="image_src" href={jobListOG} />
      <meta name="copyright" content="olibr.com" />

      <meta
        property="og:title"
        content="New software developer jobs in USA companies | Olibr Jobs"
      />
      <meta
        property="og:description"
        content="Welcome to Olibr Jobs, where you’ll find the best software developer jobs and IT roles. Apply now, and work with top USA companies."
      />
      <meta property="og:url" content={`${baseUrl}/jobs`} />
      <meta property="og:image" content={jobListOG} />
      <meta property="og:site_name" content="@Olibr" />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@olibr" />
      <meta property="twitter:domain" content="olibr.com" />
      <meta property="twitter:url" content={`${baseUrl}/jobs`} />
      <meta
        name="twitter:title"
        content="New software developer jobs in USA companies | Olibr Jobs"
      />
      <meta
        name="twitter:description"
        content="Welcome to Olibr Jobs, where you’ll find the best software developer jobs and IT roles. Apply now, and work with top USA companies."
      />
      <meta name="twitter:image" content={jobListOG} />

      <meta property="og:type" content="business.business" />
      <meta
        property="business:contact_data:street_address"
        content="26 Arrowhead Ln"
      />
      <meta property="business:contact_data:locality" content="Menlo Park" />
      <meta property="business:contact_data:region" content="CA" />
      <meta property="business:contact_data:postal_code" content="94025" />
      <meta property="business:contact_data:country_name" content="US" />

      {/* ------Apple Devices ------ */}
      <meta
        name="apple-mobile-web-app-title"
        content="New software developer jobs in USA companies | Olibr Jobs"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-touch-fullscreen" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black"></meta>
      {/* ----------JSON LD Schema for SEO------ */}

      <script type="application/ld+json">
        {JSON.stringify(
          // ------organisation - employment agency -----
          {
            "@context": "https://schema.org",
            "@type": "EmploymentAgency",
            name: "olibr.com",
            image: "",
            "@id": "",
            url: "",
            telephone: "",
            address: {
              "@type": "PostalAddress",
              streetAddress: "26 Arrowhead Ln",
              addressLocality: "Menlo Park, CA",
              postalCode: "94025",
              addressCountry: "US",
            },
            sameAs: [
              "https://www.facebook.com/olibrcom",
              "https://twitter.com/olibr",
              "https://www.linkedin.com/company/olibrcom/",
              "https://olibr.com/",
              "https://www.instagram.com/olibr",
              "https://www.youtube.com/@olibr",
              "",
            ],
            department: {
              "@type": "EmploymentAgency",
              name: "Olibr.com",
              image: "",
              telephone: "",
            },
          }
        )}
      </script>

      {/* ------------ */}
      <script type="application/ld+json">
        {JSON.stringify(
          //  breadcrumbs----------
          {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: `${baseUrl}/`,
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Apply for Jobs - Jobs List",
                item: `${baseUrl}/jobs`,
              },
            ],
          }
        )}
      </script>
      {/* --------------------- */}
      <script type="application/ld+json">
        {JSON.stringify(
          // Speakable ---
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            name: "Apply for Jobs - Jobs List",
            speakable: {
              "@type": "SpeakableSpecification",
              xpath: [
                "/html/head/title",
                "/html/head/meta[@name='description']/@content",
              ],
            },
            url: "https://olibr.com/jobs",
          }
        )}
      </script>
      {/* --------------- */}
      <script type="application/ld+json">
        {JSON.stringify(
          // --- webpage------
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            name: "Find a remote jobs list that matches your skills on Olibr",
            speakable: {
              "@type": "SpeakableSpecification",
              xpath: [
                "/html/head/title",
                "/html/head/meta[@name='description']/@content",
              ],
            },
            url: `${baseUrl}/jobs`,
          }
        )}
      </script>
    </Helmet>
  );
};

export default Jobs;
