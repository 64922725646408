import React from 'react'
// import Styles from "./otp.css"

export const OtpDigit = ({ id,disabled=false, previousId, nextId, value, onValueChange, handleSubmit }) => {
    
    const handleKeyUp = (e) => {
        
        if (e.keyCode === 8 || e.keyCode === 37) {
            const prev = document.getElementById(previousId);
            if (prev) {
                prev.select();
            }
        } else if (
            (e.keyCode >= 48 && e.keyCode <= 57)  ||
            e.keyCode === 39 
        ) {
            const next = document.getElementById(nextId);
            if (next) {
                next.select();
            } else {
                const inputGroup = document.getElementById('OTPInputGroup');
                if (inputGroup && inputGroup.dataset['autosubmit']) {
                    handleSubmit();
                }
            }
        }
    }
    return (
        <input
            id={id}
            name={id}
            type="text"
            disabled={disabled}
            // className={Styles.DigitInput}
            value={value}
            maxLength="1"
            onChange={(e) => onValueChange(id, e.target.value)}
            onKeyUp={handleKeyUp}
        />
    );
};