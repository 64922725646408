import React from "react";
import PropTypes from "prop-types";
const SocialMediaLink = ({ result }) => {
  return (
    (!!result?.facebook ||
      !!result?.gitHub ||
      !!result?.twitter ||
      !!result?.linkedin) && (
      <div className="sidebar-widget social-media-widget">
        <h4 className="widget-title">Social media</h4>
        <div className="widget-content">
          <div className="social-links">
            {!!result?.facebook && (
              <a
                href={result?.facebook}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-brands fa-facebook"></i>
              </a>
            )}

            {!!result?.gitHub && (
              <a
                href={result?.gitHub}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-brands fa-github"></i>
              </a>
            )}

            {!!result?.twitter && (
              <a
                href={result?.twitter}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-brands fa-twitter"></i>
              </a>
            )}

            {!!result?.linkedin && (
              <a
                href={result?.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-brands fa-linkedin"></i>
              </a>
            )}
          </div>
        </div>
      </div>
    )
  );
};
SocialMediaLink.propTypes = {
  result: PropTypes.object,
};
export default SocialMediaLink;
