import React from "react";
import "./SkillExp.css";
import { Fragment } from "react";
import NavBar from "../../components/skill-faq-render/NavBar";
import { useState } from "react";
import Skill from "./Skill";
import Stack from "./Stack";
import { getSkillWithCategory, getStackWithCategory } from "../../service/action/admin";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const SkillExp = () => {
  const [tab, setTab] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    dispatch(getStackWithCategory(navigate));
    dispatch(getSkillWithCategory(navigate))
}, [])
 

  const handleClick = () => {
    setTab(!tab);
  };
  const tabsArr = ["SKILLS", "STACKS"];
  const states = ["faq-tabs-active", "faq-tabs"];

  return (
    <Fragment>
      <div className="wrapper">
        <NavBar tab={tab} onClick={handleClick} />
        <div className="container">
          <br />
          <div className="buttonFor">
            <span
              className={`${states[tab ? 0 : 1]} faqTabCursor`}
              onClick={handleClick}
            >
              {tabsArr[0]}
            </span>
            <span
              className={`${states[!tab ? 0 : 1]} faqTabCursor`}
              onClick={handleClick}
            >
              {tabsArr[1]}
            </span>
          </div>

          <div id="skill-explanation">
          {tab ? <Skill /> : <Stack/>}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SkillExp;
