import * as types from "../";

export const sendOTPAction = (data, navigate, cb) => ({
  type: types.SEND_OTP,
  data,
  navigate,
  cb
})

export const verifyOTPAction = (data, navigate, cb, pageType) => ({
  type: types.VERIFY_OTP,
  data,
  navigate,
  cb,
  pageType
})

export const removeUnattemptedSkillAction = (objectId, navigate) => ({
  type: types.REMOVE_UNATTEMPTED_SKILL,
  objectId,
  navigate,
})

export const removeStackTestAction = (stackId, navigate) => ({
  type: types.REMOVE_STACK_TEST,
  stackId,
  navigate
})

export const chooseStackTestAction = (data, navigate, setModal,jumpBack) => ({
  type: types.CHOOSE_STACK_TESTS,
  data,
  navigate,
  setModal,
  jumpBack
})

export const chooseSkillTestAction = (skillId, navigate,jumpTop=false) => ({
  type: types.CHOOSE_SKILL_TEST,
  skillId,
  navigate,
  jumpTop
})

export const getSkillTestStepDataAction = (navigate) => ({
  type: types.GET_SKILL_TEST_STEP_DATA,
  navigate,
});

export const getDevVideoAction = (navigate, data) => ({
  type: types.GET_DEV_VIDEO,
  navigate,
  data
});

export const newFeatureClickedAction = (navigate) => ({
  type: types.NEW_FEATURE_CLICKED,
  navigate,
});

export const getDevReviewAction = (navigate) => ({
  type: types.GET_DEV_REVIEW_LISTS,
  navigate
})

export const createDevReviewAction = (data ,navigate, closeModal) => ({
  type: types.CREATE_DEV_REVIEW,
  data,
  navigate,
  closeModal
})

export const resendDevReviewAction = (data ,navigate) => ({
  type: types.RESEND_DEV_REVIEW,
  data,
  navigate,
})

export const deleteDevReviewAction = (reviewId, navigate, closeModal) => ({
  type: types.DELETE_DEV_REVIEW,
  reviewId,
  navigate,
  closeModal
})

export const dashboardStepOne = (data, navigate, next) => ({
  type: types.DASHBOARD_STEP_ONE,
  data,
  navigate,
  next,
});

export const getStepCompletedStatus = (navigate) => ({
  type: types.GET_STEP_COMPLETED_STATUS,
  navigate,
});

export const getResume = (userId, navigate,jobId=false) => ({
  type: types.GET_RESUME,
  userId,
  navigate,
  jobId
});

export const getProfileStatus = (navigate) => ({
  type: types.GET_PROFILE_STATUS,
  navigate,
});

export const getCreateProfileData = (navigate,setTab=null) => ({
  type: types.GET_CREATE_PROFILE,
  navigate,
  setTab
});

export const proficiencyTestLink = (navigate) => ({
  type: types.PROFICIENCY_TEST_LINK,
  navigate,
});

export const proficiencyTestResult = (navigate) => ({
  type: types.PROFICIENCY_TEST_RESULT,
  navigate,
});

export const englishTestResult = (navigate) => ({
  type: types.ENGLISH_TEST_RESULT,
  navigate,
});

export const skillList = (navigate) => ({
  type: types.SKILL_LIST,
  navigate,
});

export const getDevSkillList = (navigate) => ({
  type: types.GET_DEV_SKILL_LIST,
  navigate,
});

export const getAllSkillsList = (navigate) => ({
  type: types.GET_ALL_SKILL_LIST,
  navigate,
});

export const manageDevSkill = (data, navigate) => ({
  type: types.MANAGE_DEV_SKILL,
  data,
  navigate,
});

export const updateAvailableFrom = (data, navigate) => ({
  type: types.UPDATE_AVAILABLE_FROM,
  data,
  navigate,
});

export const postDevStack = (data, navigate) => ({
  type: types.POST_DEV_STACK,
  data,
  navigate,
});

export const devChooseSkills = (data, skillFor, navigate) => ({
  type: types.DEV_CHOOSE_SKILL,
  data,
  skillFor,
  navigate,
});

export const deleteDevSKill = (data, navigate) => ({
  type: types.DELETE_DEV_SKILL,
  data,
  navigate,
});

export const stackList = (navigate) => ({
  type: types.STACK_LIST,
  navigate,
});

export const clearResume=()=>({
  type: types.CLEAR_RESUME_PROFILE,
})
export const stackListChosen = (navigate) => ({
  type: types.STACK_LIST_CHOSEN,
  navigate,
});

// export const finalizeResumeCall = (data, navigate) => ({
//   type: types.FINALIZE_RESUME_CALL,
//   data,
//   navigate,
// });

export const uploadProfileImage = (data, navigate,prevUrl=null) => ({
  type: types.UPLOAD_PROFILE_IMAGE,
  data,
  navigate,
  prevUrl
});

export const resumeParsing = (data, navigate) => ({
  type: types.RESUME_PARSING,
  data,
  navigate,
});

export const getResumeParserData = (navigate) => ({
  type: types.GET_RESUME_PARSER_DATA,
  navigate,
});

export const uploadImage = (data,prevUrl, navigate, forType = null, indexNo = -1) => ({
  type: types.UPLOAD_IMAGE,
  data,
  forType,
  navigate,
  indexNo,
  prevUrl
});

export const startTest = (data, navigate) => ({
  type: types.START_TEST,
  data,
  navigate,
});

export const englishTest = (mcq, long, navigate) => ({
  type: types.ENGLISH_TEST,
  mcq,
  long,
  navigate,
});

export const checkEnglishTest=(navigate,mcq, long,closeInstruction) => ({
  type: types.ENGLISH_TEST_CHECK,
  mcq,
  long,
  navigate,
  closeInstruction
});
export const updateEnglishActivity=(navigate,data)=>({
  type:types.SAVE_ENGLISH_ACTIVITY,
  navigate,
  data
})
export const postResultData = (data, navigate) => ({
  type: types.POST_RESULT_DATA,
  data,
  navigate,
});

export const getEnglishTestResult = (navigate) => ({
  type: types.GET_ENGLISH_TEST_RESULT,
  navigate,
});

export const askForApproval = (notificationMsg, navigate) => ({
  type: types.ASK_FOR_APPROVAL,
  notificationMsg,
  navigate,
});

export const updatePageTour = (navigate, setPage) => ({
  type: types.UPDATE_PAGE_TOUR,
  setPage,
  navigate
})

export const getDevStatus = (navigate) => ({
  type: types.GET_DEV_STATUS,
  navigate,
});

export const getResultDev = (navigate, userId = null) => ({
  type: types.GET_RESULT_DEV,
  userId,
  navigate,
});

export const getAppliedJob = (navigate, pageData={}) => ({
  type: types.GET_APPLIED_JOB,
  navigate,
  pageData,
});

export const applyNewJob = (data, navigate,closeModal=null) => ({
  type: types.APPLY_NEW_JOB,
  navigate,
  data,
  closeModal
});

export const applyNewMultijob = (data, navigate,closeModal=null) => ({
  type: types.APPLY_NEW_MULTI_JOB,
  navigate,
  data,
  closeModal
});


export const getShortlistedJob = (navigate, pageData) => ({
  type: types.GET_SHORT_LIST_JOB,
  navigate,
  pageData,
});

export const getLatestJobList = (navigate, pageData) => ({
  type: types.GET_LATEST_JOB_LIST,
  navigate,
  pageData,
});

export const getVettedSkill = (navigate) => ({
  type: types.GET_VETTED_SKILL,
  navigate,
});

export const devChooseSkillsGet = (navigate) => ({
  type: types.DEV_CHOOSE_SKILL_GET,
  navigate,
});

export const getEnglishTestStatus = (navigate) => ({
  type: types.GET_ENGLISH_TEST_STATUS,
  navigate,
});

export const updateEnglishTestStatus = (navigate, data) => ({
  type: types.UPDATE_ENGLISH_TEST_STATUS,
  navigate,
  data,
});

export const postEnglishTestStatus = (navigate, data) => ({
  type: types.POST_ENGLISH_TEST_STATUS,
  navigate,
  data,
});

export const getJobFullClientDev = (jobId, navigate) => ({
  type: types.GET_JOB_FULL_CLIENT_DEV,
  jobId,
  navigate,
});

export const getDevAcademic = (navigate) => ({
  type: types.GET_DEV_ACADEMIC,
  navigate,
});

export const saveDevAcademic = (navigate, data, setAcadmicDetail, reset) => ({
  type: types.SAVE_DEV_ACADEMIC,
  setAcadmicDetail,
  reset,
  navigate,
  data,
});

export const updateDevAcademic = (navigate, data, setAcadmicDetail, reset) => ({
  type: types.UPDATE_DEV_ACADEMIC,
  setAcadmicDetail,
  reset,
  navigate,
  data,
});

export const deleteDevAcademic = (navigate, data) => ({
  type: types.DELETE_DEV_ACADEMIC,
  navigate,
  data,
});

export const getDevCertification = (navigate) => ({
  type: types.GET_DEV_CERTIFICATION,
  navigate,
});

export const saveDevCertification = (
  navigate,
  data,
  setDetails,
  resetForm
) => ({
  type: types.SAVE_DEV_CERTIFICATION,
  navigate,
  data,
  setDetails,
  resetForm,
});

export const updateDevCertification = (
  navigate,
  data,
  setDetails,
  resetForm
) => ({
  type: types.UPDATE_DEV_CERTIFICATION,
  navigate,
  data,
  setDetails,
  resetForm,
});
export const deleteDevCertification = (navigate, data) => ({
  type: types.DELETE_DEV_CERTIFICATION,
  navigate,
  data,
});

export const getDevSocialProfile = (navigate) => ({
  type: types.GET_DEV_SOCIAL_PROFILE,
  navigate,
});

export const saveDevSocialProfile = (navigate, data) => ({
  type: types.SAVE_DEV_SOCIAL_PROFILE,
  navigate,
  data,
});

export const updateDevSocialProfile = (navigate, data) => ({
  type: types.UPDATE_DEV_SOCIAL_PROFILE,
  navigate,
  data,
});
export const deleteDevSocialProfile = (navigate, data) => ({
  type: types.DELETE_DEV_SOCIAL_PROFILE,
  navigate,
  data,
});

export const getDevEmployement = (navigate) => ({
  type: types.GET_DEV_EMPLOYEMENT,
  navigate,
});

export const saveDevEmployement = (
  navigate,
  data,
  setEmpDetails,
  resetForm
) => ({
  type: types.SAVE_DEV_EMPLOYEMENT,
  navigate,
  data,
  setEmpDetails,
  resetForm,
});

export const updateDevEmployement = (
  navigate,
  data,
  setEmpDetails,
  resetForm
) => ({
  type: types.UPDATE_DEV_EMPLOYEMENT,
  navigate,
  data,
  setEmpDetails,
  resetForm,
});
export const deleteDevEmployement = (navigate, data) => ({
  type: types.DELETE_DEV_EMPLOYEMENT,
  navigate,
  data,
});

export const getDevProject = (navigate, data) => ({
  type: types.GET_DEV_PROJECT,
  navigate,
  data,
});

export const saveDevProject = (navigate, data, setProject, resetForm) => ({
  type: types.SAVE_DEV_PROJECT,
  navigate,
  data,
  setProject,
  resetForm,
});

export const updateDevProject = (navigate, data, setProject, resetForm) => ({
  type: types.UPDATE_DEV_PROJECT,
  navigate,
  data,
  setProject,
  resetForm,
});
export const deleteDevProject = (navigate, data) => ({
  type: types.DELETE_DEV_PROJECT,
  navigate,
  data,
});

export const getAllTools = (navigate) => ({
  type: types.GET_DEV_ALL_TOOLS,
  navigate,
});

export const removeDevProjectImage = () => ({
  type: types.REMOVE_DEV_PROJECT_IMAGE,
});

export const getDevTools = (navigate) => ({
  type: types.GET_DEV_TOOLS,
  navigate,
});

export const saveDevTools = (navigate, data, setDetails, resetForm) => ({
  type: types.SAVE_DEV_TOOLS,
  navigate,
  data,
  setDetails,
  resetForm,
});

export const updateDevTools = (navigate, data, setDetails, resetForm) => ({
  type: types.UPDATE_DEV_TOOLS,
  navigate,
  data,
  setDetails,
  resetForm,
});
export const deleteDevTools = (navigate, data) => ({
  type: types.DELETE_DEV_TOOLS,
  navigate,
  data,
});

export const getDevSkills = (navigate) => ({
  type: types.GET_DEV_SKILLS,
  navigate,
});

export const saveDevSkills = (navigate, data, setDetails, resetForm) => ({
  type: types.SAVE_DEV_SKILLS,
  navigate,
  data,
  setDetails,
  resetForm,
});

export const updateDevSkills = (navigate, data, setDetails, resetForm) => ({
  type: types.UPDATE_DEV_SKILLS,
  navigate,
  data,
  setDetails,
  resetForm,
});
export const deleteDevSkills = (navigate, data) => ({
  type: types.DELETE_DEV_SKILLS,
  navigate,
  data,
});
export const getDevAchievement = (navigate) => ({
  type: types.GET_DEV_ACHIEVEMENT,
  navigate,
});

export const saveDevAchievement = (navigate, data, setDetails, resetForm) => ({
  type: types.SAVE_DEV_ACHIEVEMENT,
  navigate,
  data,
  setDetails,
  resetForm,
});

export const updateDevAchievement = (
  navigate,
  data,
  setDetails,
  resetForm
) => ({
  type: types.UPDATE_DEV_ACHIEVEMENT,
  navigate,
  data,
  setDetails,
  resetForm,
});
export const deleteDevAchievement = (navigate, data) => ({
  type: types.DELETE_DEV_ACHIEVEMENT,
  navigate,
  data,
});
export const updateDevShortDescription = (navigate, data, closeModal=null) => ({
  type: types.UPDATE_DEV_DESCRIPTION,
  navigate,
  data,
  closeModal
});

export const approvalUpdateAction = (navigate, data) => ({
  type: types.APPROVAL_UPDATE,
  navigate,
  data,
});

export const currentEmploymentAction = (navigate) => ({
  type: types.GET_CURRENT_EMPLOYMENT,
  navigate,
});

export const getResumeOpenAction = (userId, navigate) => ({
  type: types.GET_RESUME_OPEN,
  userId,
  navigate,
});

export const setTabTitle = (data) => ({
  type: types.SET_TAB_TITLE,
  data,
});

export const setActiveTab = (data) => ({
  type: types.SET_ACTIVE_TAB,
  data,
});

export const setBackTab = () => ({
  type: types.SET_BACK_TAB,
});

export const setNextTab = () => ({
  type: types.SET_NEXT_TAB,
});

export const postResultClosedClick = () => ({
  type: types.POST_RESULT_DATA_CLOSED_CLICK,
});

export const saveJobFavourit = (navigate, id,userId, obj, setObj) => ({
  type: types.SAVE_FAVOURITE_JOB,
  id,
  navigate,
  obj,
  setObj,
  userId
})

export const getFavouriteJob = (navigate) => ({
  type: types.GET_FAVOURITE_JOB,
  navigate
})
export const updateBasicDetails = (navigate,data,closeModal) => ({
  type: types.UPDATE_BASIC_DETAIL,
  navigate,
  data,
  closeModal
})

export const devActivation=(navigate,data,userType="dev")=>({
  type: types.DEV_ACTIVATION,
  navigate,
  data,
  userType
})

export const getDevEmailAutomation=(navigate)=>({
  type: types.GET_DEV_EMAIL_AUTO,
  navigate,
})

//Interview
export const saveInterviewSlot = (navigate, slot, onSlotAcceptingDone, socket) => ({
  type: types.SET_INTERVIEWS_SLOT_DEV,
  navigate,
  slot,
  onSlotAcceptingDone,
  socket
});

export const getInterviewsData = (navigate, filters) => ({
  type: types.GET_INTERVIEWS_DATA_DEV,
  navigate,
  filters,
});

export const rescheduleInterviewByDev = (id, navigate, interviewData, socket) => ({
  type: types.RESCHEDULE_INTERVIEW_BY_DEV,
  id,
  navigate,
  interviewData,
  socket
});

export const cancelInterviewByDev = (id, navigate, interviewData, socket) => ({
  type: types.CANCEL_INTERVIEW_BY_DEV,
  id,
  navigate,
  interviewData,
  socket
});

export const updateSnooze = (navigate,setChecked) => ({
  type: types.UPDATE_SNOOZE,
  setChecked,
  navigate,
});