import React from "react";
import LocationIcon from "../../../assets/img/icons/location.svg";
const JobLocation = ({ jobDetail }) => {
  return (
    <div className="job-desc-block job-details-open">
      <h2>Location</h2>
      {jobDetail?.jobLocation == "Remote" && (
        <p>
          <span>
            <img src={LocationIcon} height="13px" />
          </span>
          {jobDetail?.jobLocation}
          {jobDetail?.jobCountry?.map((itm, id) => (
            <span>
              {
               ", "+ (itm?.countryName || "India")}
            </span>
          ))}
        </p>
      )}
      {jobDetail?.jobLocation !== "Remote" &&jobDetail?.jobAddress?.map((itm, index) => (
        <p>
          <span>
            <img src={LocationIcon} height="13px" />
          </span>

          <span>
            {itm?.city}
            {!!itm?.city && ","}{" "}
            {itm?.stateName +
              (itm?.stateName && ", ") +
              (jobDetail?.jobCountry?.[0]?.countryName || jobDetail?.jobCountry?.countryName|| "India")}
          </span>
        </p>
      ))}
    </div>
  );
};

export default JobLocation;
