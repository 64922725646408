import React, { useState, useRef, useEffect } from "react";
import CreateProfile from "../../../assets/img/icons/user-filled.svg";
import Projector from "../../../assets/img/icons/projector.svg";
import Skills from "../../../assets/img/icons/skills.svg";
import FinalizeResume from "../../../assets/img/icons/checked.svg";
import CreateProfileComponent from "./create-profile/create_profile";
import ProficiencyTest from "./test/test";
import SkillTest from "./skill-test/skilltest";
import FinalResume from "./final-resume/finalResume";
import "./dashboard.css";
import SelectCustomSkill from "../../../components/select-skill/SelectCustomSkill";

const Dashboard = (props) => {
  const nextBtnRef = useRef();
  const [activeStatus, setActiveStatus] = useState(1);
  const [regionVal, setRegionVal] = useState("");

  useEffect(() => {
    setRegionVal(props?.user?.[0]?.region);
  }, [props?.user?.[0]]);

  const handleActiveStatus = (status) => {
    setActiveStatus(status);
  };

  return (
    <>
      <div className="tab-block">
        <ul className="tabs">
          <li className="tab" onClick={() => handleActiveStatus(1)}>
            <a className={activeStatus >= 1 ? "current" : ""}>
              <span className="num">1</span>
              <span className="tab-icon">
                <img src={CreateProfile} alt="Create Profile" />
              </span>
              <h4>User Profile</h4>
            </a>
          </li>
          {((!!regionVal && regionVal === "IN") || !regionVal) && (
            <li className="tab" onClick={() => handleActiveStatus(2)}>
              <a className={activeStatus >= 2 ? "current" : ""}>
                <span className="num">2</span>
                <span className="tab-icon">
                  <img src={Projector} alt="English Proficiency Test" />
                </span>
                <h4>Select Skill</h4>
              </a>
            </li>
          )}
          <li className="tab" onClick={() =>{
            if(activeStatus>3){
              handleActiveStatus(3)
            }else{
              nextBtnRef?.current?.click()
            }
           }}>
            <a className={activeStatus >= 3 ? "current" : ""}>
              <span className="num">{regionVal === "IN" ? 3 : 2}</span>
              <span className="tab-icon">
                <img src={Skills} alt="" />
              </span>
              
              <h4>English Proficiency Test</h4>
            </a>
          </li>
          <li className="tab" onClick={() => handleActiveStatus(4)}>
            <a className={activeStatus >= 4 ? "current" : ""}>
              <span className="num">{regionVal === "IN" ? 4 : 3}</span>
              <span className="tab-icon">
                <img src={FinalizeResume} alt="" />
              </span>
              <h4>Finalize Resume</h4>
            </a>
          </li>
        </ul>
      </div>
      <div className="com-block">
        {activeStatus === 1 && (
          <CreateProfileComponent
            nextBtnRef={nextBtnRef}
            onNext={() => setActiveStatus(activeStatus + 1)}
            filterValue={props.filterValue}
            limit={props.limit}
            page={props.page}
          />
        )}
        {activeStatus === 2 && 
        (
          // <ProficiencyTest
          //   onNext={() => setActiveStatus(activeStatus + 1)}
          //   onBack={() => setActiveStatus(activeStatus - 1)}
          //   userData={props?.user?.[0]}
          // />
          <SelectCustomSkill
           nextBtnRef={nextBtnRef}
           handleActiveStatus={handleActiveStatus}
           userData={props?.user?.[0]}
          />
        )
        }
        {activeStatus === 3 && (
          <ProficiencyTest
          onNext={() => setActiveStatus(activeStatus + 1)}
          onBack={() => setActiveStatus(activeStatus - 1)}
          userData={props?.user?.[0]}
        />
        )}
        {activeStatus === 4 && (
          <FinalResume
            onBack={() => setActiveStatus(activeStatus - 1)}
            filterValue={props.filterValue}
            devIsFresher={props.devIsFresher}
            limit={props.limit}
            page={props.page}
            userDatas={props?.user}
          />
        )}
      </div>
    </>
  );
};

export default Dashboard;
