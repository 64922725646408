import React from 'react'
import { Icon } from '../../../icon'

const SallaryDetals = ({openModal,resumeData}) => {
  return (
    <div className="setting-card">
        <div className="row">
          <div className="col-6">
            <h3>
              Current Salary
              <span onClick={() => openModal("salary")}>
                <Icon name="edit" />
              </span>
            </h3>
            <p>{resumeData?.devCSalar ? resumeData?.devCSalar : "N/A"}</p>
          </div>
          <div className="col-6">
            <h3>
              Expected Salary
              <span onClick={() => openModal("salary")}>
                <Icon name="edit" />
              </span>
            </h3>
            <p>{resumeData?.devESalary ? resumeData?.devESalary : "N/A"}</p>
          </div>
        </div>
      </div>
  )
}

export default SallaryDetals