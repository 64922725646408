import React from "react";
import { countryFlag } from "../functions";

const CountryFlag = ({ jobCountry }) => {
  if (Array?.isArray(jobCountry)) {
  return  jobCountry?.map((itm, id) => (
      <img
        key={id}
        src={countryFlag(itm?.countryCode)}
        height="13px"
        style={{ marginLeft: id > 0 && "3px" }}
      />
    ));
  } else {
    return (
      <img
        src={countryFlag(jobCountry?.countryCode || "IN")}
        height="13px"
      />
    );
  }
};

export default CountryFlag;
