import React from 'react'

const ProgressSpinner = ({
    svgClassName="profile-preview-spinner",
    obtainedScore,
    maxScore,
    percentageScore
}) => {
  return (
    <div className="skill-graph">
    <div className="skill-graph">
        <div className={`single-chart`}>
            <svg
                viewBox="0 0 36 36"
                className={`circular-chart ${svgClassName} ${true ? "green" : "red"
                    }`}
            >
                <path
                    className="circle-bg"
                    d="M18 2.0845
a 15.9155 15.9155 0 0 1 0 31.831
a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path
                    className="circle"
                    stroke-dasharray={`${percentageScore}, 100`}
                    d="M18 2.0845
a 15.9155 15.9155 0 0 1 0 31.831
a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <text x="18" y="20.35" className="percentage">
                    {parseInt(obtainedScore)}/{parseInt(maxScore)}
                </text>
            </svg>
        </div>
    </div>
</div>
  )
}

export default ProgressSpinner