import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSpeakWithDevListAction } from "../../../service/action/admin";

const SpeakWithDevList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { speakDevList } = useSelector((state) => state.adminReducer);

  useEffect(() => {
    dispatch(getSpeakWithDevListAction(navigate));
  }, [navigate, dispatch]);

  useEffect(() => {
    console.log(speakDevList);
  }, [speakDevList]);

  const toRequestDetail = (requestId) => {
    navigate(`/admin/interview-request/${requestId}`);
  };

  return (
    <Fragment>
      <div className="main-div-developer">
        <div className="create-profile">
          <div className="block-header">
            <h4 className="">Interview Request List</h4>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th className="column0">S.No</th>
                <th className="column1">Client Name</th>
                <th className="column1">Client Email</th>
                <th className="column1">Organisation</th>
                <th className="column1">Dev Name</th>
                <th className="column1">Dev Email</th>
                <th className="column1">Job Title</th>
                <th className="column1">Sent On</th>
                <th className="column1">Interview Status</th>
              </tr>
            </thead>
            <tbody>
              {speakDevList?.data?.map((item, i) => (
                <Fragment key={item?._id + Math.random()}>
                  <tr
                    className="faqdiv"
                    onClick={() => toRequestDetail(item?._id)}
                  >
                    <td className="subQues sno">{i + 1}</td>
                    <td className="subQues">
                      {item?.clientData?.[0]?.fullName}
                    </td>
                    <td className="subQues">{item?.clientData?.[0]?.email}</td>
                    <td className="subQues">
                      {item?.clientData?.[0]?.companyName}
                    </td>
                    <td className="subQues">
                      {item?.developerData?.[0]?.fName +
                        " " +
                        item?.developerData?.[0]?.lName}
                    </td>
                    <td className="subQues">
                      {item?.developerData?.[0]?.devEmail}
                    </td>
                    <td className="postedOn">{item?.jobTitle}</td>
                    <td className="column4">
                      {new Date().toDateString(item?.sentAt)}
                    </td>
                    <td className="column4">
                      {item?.isScheduled ? "SCHEDULED" : "NOT SCHEDULED"}
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export default SpeakWithDevList;
