import React, { useContext } from "react";
import ChipsArray from "./common/ChipsArray";
import PropTypes from "prop-types";
import DevAvailble from "./DevAvailble";
import { useState } from "react";
import OlibrShareLink from "../share-link";
import { getDevLinkUrl } from "../functions";
// import EditProfile from "./editProfile/EditProfile";
import VideoModal from "./common/VideoModal";
import Modal from "../modal/ModalBox";
import { MainContext } from "../../context/MainContext";
const ProfileHeader = ({
  devData,
  // setEdit = false,
  // reapprovalEdit = false,
  isSavedProfile = false,
  isViewSaveProfile,
  onSaveProfile,
}) => {
  const OlibrLogo="https://test.olibr.com/images/logo.svg"
  const [share, setShare] = useState(false);
  // const [reapproval, setReapprovalEdit] = useState(false);
  const { user } = useContext(MainContext)
  const [openVideo, setOpenVideo] = useState(false);



  return (
    <div className="upper-box">
      <img src={OlibrLogo} alt="" width={120} className="olibr-logo" />
      <OlibrShareLink
        onClose={() => setShare(false)}
        baseUrl={getDevLinkUrl(
          devData?._id + "-" + devData?.fName + "-" + devData?.lName
        )}
        isOpen={share}
        title="Share Job"
      />
      {/* <Modal
        open={reapproval}
        title={"Profile Edit"}
        onClose={() => setReapprovalEdit(false)}
        content={() => <EditProfile setReapprovalEdit={setReapprovalEdit} />}
        isFooterView={false}
        bodyClass="modal-type-two edit-profile-modal"
        size="sm"
      /> */}
      <div className="auto-container">
        <div className="candidate-block-five">
          <div className="inner-box">
            <div className="content video-box">
              <figure className="image">
                <Modal
                  open={openVideo}
                  onClose={() => setOpenVideo(false)}
                  hasHeader={false}
                  isFooterView={false}
                  content={() => <VideoModal link={devData?.devVideo} />}
                  bodyClass="modal-type-two"
                />
                <img
                  src={devData?.userImage}
                  width={55}
                  height={55}
                  alt="user"
                />
                {devData?.devVideo && (
                  <i
                    className="icon fa fa-solid fa-play pointer"
                    aria-hidden="true"
                    onClick={() => setOpenVideo(true)}
                  ></i>
                )}
              </figure>

              <h4 className="name">
                {devData?.fName} {devData?.lName}
                <span
                  onClick={() => setShare(true)}
                  className="fa fa-solid fa-share-alt share-icon pointer"
                ></span>
                {/* {setEdit ||
                  (reapprovalEdit && (
                    <span
                      className="margin-left-10 pointer"
                      onClick={() => {
                        reapprovalEdit
                          ? setReapprovalEdit(true)
                          : setEdit(false);
                      }}
                    >
                      <i className="fas fa-edit"></i>
                    </span>
                  ))} */}
              </h4>

              <p className="theme-color-txt margin-bottom-10">
                {devData?.devDesg}
              </p>

              <ul className="candidate-info">
                {/* <li className="designation">{devData?.devDesg}</li> */}
                <li>
                  <span className="icon fa fa-solid fa-map-marker"></span>
                  {devData?.devCity}, {devData?.devState}
                </li>
                <li>
                  <span className="icon fa fa-solid fa-briefcase"></span>
                  {devData?.devTotalExperience} years
                </li>
                {/* <li>
                  <span className="icon flaticon-money"></span>
                  {devData?.devCSalar || devData?.devESalary} LPA
                </li> */}
                <li>
                  <span className="icon fa fa-solid fa-money"></span>
                  {devData?.devCSalar > 99999 || devData?.devESalary > 99999
                    ? (devData?.devCSalar || devData?.devESalary) / 100000 +
                      " LPA"
                    : (devData?.devCSalar || devData?.devESalary) + " LPA"}
                </li>
                <li>
                  <span className="icon fa fa-solid fa-envelope"></span>
                  {devData?.email}
                </li>
                <li>
                  <span className="icon fa fa-solid fa-phone"></span>+
                  {devData?.devCountryCode} {devData?.number}
                </li>
              </ul>

              <DevAvailble devData={devData} />

              <ChipsArray />
            </div>

            <div className="btn-box">
              {/* <DownloadPdf /> */}

              {/* use .saved-profile-btn class to show profile has been saved  */}
              {user?.userType === "Client" && isViewSaveProfile && (
                <button className="bookmark-btn" onClick={onSaveProfile}>
                  {isSavedProfile ? (
                    <i className="fas icon fa-solid fa-bookmark"></i>
                  ) : (
                    <i className="las icon la-bookmark"></i>
                  )}
                  {/* <i className="fas icon fa-solid fa-bookmark"></i> */}
                </button>
              )}

              {/* {setEdit ||
                (reapprovalEdit && (
                  <button
                    className="bookmark-btn tooltip-btn-two"
                    data-text="Edit Profile"
                    type="button"
                    onClick={() => {
                      console.log("reapprovalEdit", reapprovalEdit);
                      setReapprovalEdit(true);
                    }}
                  >
                    <i className="flaticon-edit"></i>
                  </button>
                ))} */}
            </div>
          </div>
        </div>
        {/*  <!-- Candidate block Five --> */}
      </div>
    </div>
  );
};
ProfileHeader.propTypes = {
  devData: PropTypes.object,
  setEdit: PropTypes.any,
  reapprovalEdit: PropTypes.bool,
  isSavedProfile: PropTypes.any,
  onSaveProfile: PropTypes.func,
  isViewSaveProfile: PropTypes.bool,
};
export default ProfileHeader;
