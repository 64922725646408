import React, { Fragment } from 'react'
// import brandLogo from "../../assets/img/icons/hackerrank_logo.svg";
// import VettedSkillIcon from "../../assets/img/icons/badge-icon.svg";
import downArrow from "../../assets/img/icons/down-arrow.png";
import arrowRight from "../../assets/img/icons/arrow-right.png";
import DateIcon from "../../assets/img/icons/date-icon.png"
import "./SkillTestResult.css"
import ProgressSpinner from '../progress-bar/ProgressSpinner';
import DateFormate from '../../common/DateFormate';
import { checkExistPlarismObj, findSkillTime, getPercentage } from '../functions';
import { ProgrfessBar } from '../progress-bar/ProgrfessBar';
import { useState } from 'react';
import Modal from '../modal/ModalBox';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReport } from '../../service/action/common';
import { useNavigate } from 'react-router-dom';
import { olibrToast } from '../toaster';
import { Icon } from '../icon';
import { ClipLoader } from 'react-spinners';
import DetailedReport from '../../view/common/skill-test-new/components/detailed-report';

const SkillTestResult = ({ data = [], skillList, stackLists, userType }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { devTestReport, isTestReportLoading } = useSelector(
        (state) => state.commonReducer
    );

    const [detailReport, setDetailsReport] = useState(false)
    const [openIndex, setOpenIndex] = useState([])
    const [skill, setSkill] = useState({})

    const handleOpeClose = (index) => {
        let checkindex = openIndex.includes(index)
        if (checkindex) {
            let filters = openIndex.filter(itm => itm != index)
            setOpenIndex(filters)
        } else {
            setOpenIndex([...openIndex, index])
        }
    }

    const fetchIconByTestId = (iconId) => {
        let url = skillList?.find((itm) => itm?._id == iconId) || stackLists?.find((itm) => itm?._id == iconId)
        return url = url?.skillTestIcon || url?.stackIcon
    }


    const onDetailedReport = (candidateId, testId, skills) => {
        setSkill(skills)
        if (candidateId && testId) {
            dispatch(
                fetchReport(
                    { candidateId, testId },
                    navigate,
                    setDetailsReport
                )
            );
        } else {
            olibrToast.error("Report is not available yet.");
        }
    };


   
    const detailReportContent = () => {
        return (
            <Fragment>
                <div className="olibr-bran dr-logo">
                    <Icon name="logo-olibr" />
                </div>
                <DetailedReport report={devTestReport} resultCard={skill} />
            </Fragment>
        )
    }

    const closeModal = () => {
        setDetailsReport(false)
        setSkill(false)
    }
    return (
        <>
            <Modal
                open={detailReport}
                title={skill?.testName}
                isFooterView={false}
                onClose={() => closeModal()}
                content={() => detailReportContent()}
                size='100%'
            />
            {
                data?.map((itm, index) =>checkExistPlarismObj(itm) && (
                    <div className='result-box'>
                        <div className='row'>
                            <div className='col-9 title-header'>
                                <span className='skill-icon-pass'>
                                    {/* <img src={VettedSkillIcon} height={"28px"} /> */}
                                    <img src="https://ik.imagekit.io/olibr/icons/tr:w-28,h-28/badge-icon.svg" height="28" />
                                </span>
                                <img className='skill-icon' height={"34px"} src={fetchIconByTestId(itm?.subject)} alt="" />
                                <span dangerouslySetInnerHTML={{__html:itm?.testName}}></span>
                            </div>
                            <div className='col-3 text-right'>
                                {itm?.isHackerearth?<Icon name="hackerearth-logo-test" />:<Icon name="hackerrank-logo" /> }
                                <span
                                    className="collapse-button-opens"
                                    onClick={() => handleOpeClose(index)}
                                >
                                    <img src={openIndex?.includes(index) ? downArrow : arrowRight} />
                                </span>

                            </div>
                        </div>
                        <div className='row' style={{ display: openIndex?.includes(index) ? "" : "none" }}>
                            <div className='col-12'>

                                <div className=' result-stats'>
                                    <div className='row'>
                                        <div className='col-9'>
                                            <h3 className='coding-challange'>
                                                {/* Coding Challenge */}
                                            </h3>
                                        </div>
                                        <div className='col-3 date-icon'>
                                            <img src={DateIcon} height={"12px"} width={"12px"} />
                                            <span>Date : {DateFormate(itm?.attemptEndedAt)}</span>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-4 text-center'>
                                            <ProgressSpinner
                                                svgClassName={"profile-preview-spinner"}
                                                maxScore={itm?.maxScore}
                                                obtainedScore={itm?.obtainedScore}
                                                percentageScore={itm?.percentageScore}
                                            />
                                            {
                                                !!itm?.testDuration && !!userType   && !itm?.isHackerearth&& (
                                                    <button className='detail-btn' onClick={() => onDetailedReport(itm?.candidateId, itm?.testId, itm)}>
                                                        {
                                                            isTestReportLoading ? <ClipLoader color="#fff" size={"16px"} /> : " Detail Report"
                                                        }

                                                    </button>
                                                )
                                            }

                                        </div>
                                        <div className='col-8'>
                                            {
                                                <p className='time'>Time : <span className='duration'>{findSkillTime(itm?.testDuration||itm?.subject,skillList,stackLists)}{ }</span></p>
                                            }
                                            <ProgrfessBar title={"Test Score"} marks={itm?.maxScore} />
                                            <ProgrfessBar title={"Your Score"} marks={itm?.obtainedScore} percentageScore={itm?.percentageScore} />
                                            <ProgrfessBar title={"Cutoff Score"} marks={itm?.cutOffScore} percentageScore={getPercentage(itm?.cutOffScore, itm?.maxScore)} />
                                            <ProgrfessBar title={"Total"} marks={`${parseFloat(itm?.percentageScore).toFixed(2)}%`} percentageScore={itm?.percentageScore} />

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>


                    </div>
                ))
            }


        </>
    )
}

export default SkillTestResult