import { Text, View } from '@react-pdf/renderer'
import React from 'react'
import { TestProgress } from '../css/TestProgress'
const TestScore = ({ title,obtainedScore, width = 20, bg = "#fcb349" }) => (
    <View style={TestProgress.container}>
        <View style={{width:"60px"}}>
            <Text style={TestProgress.title}>{title}</Text>
        </View>
        <View>
            <View style={TestProgress.progressbar}>
                <View style={{ ...TestProgress.progressbar, backgroundColor: bg, margin: 0, width: `${width}%` }}>

                </View>
            </View>
        </View>
        <View>
            <Text style={TestProgress.duration}>{obtainedScore}</Text>
        </View>
    </View>
)

export default TestScore