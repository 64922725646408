import { put, call } from "redux-saga/effects";
import * as types from "../../action";
import { olibrToast } from "../../../components/toaster";
import {
  getDeveloperLists,
  devUpdateStatus,
  getAdminSkillLists,
  updateSkillStatus,
  addStack,
  editStack,
  getAdminStackLists,
  updateStackStatus,
  refreshXobinAssessment,
  renderAllHackerrankAssessments,
  addSkill,
  editSkill,
  renderTopicList,
  getAllIcons,
  uploadIcons,
  deleteStack,
  deleteSkill,
  getDeveloperProfile,
  updateProfileStatus,
  resultParsing,
  setMemberPassword,
  addUser,
  getUserById,
  updateUser,
  deleteUser,
  userListing,
  getDevResult,
  getCategoryList,
  addCategoryBlog,
  deleteCategoryBlog,
  updateCategoryBlog,
  getBlogList,
  postBlog,
  updateBlog,
  deleteBlog,
  addFaqGroup,
  addFaq,
  updateFaq,
  updateFaqGroup,
  deleteFaq,
  deleteFaqGroup,
  addQuestion,
  englishAllQuestion,
  getAllJobs,
  addJob,
  editJob,
  deleteJob,
  adminDashboardStepOne,
  getAllFaqGroup,
  getAllTools,
  deleteTool,
  updateTool,
  addTool,
  updateUserStatusAPI,
  getClientListAdmin,
  getClientDetailAdmin,
  getJobListByClientAdmin,
  getJobFullClientAdmin,
  blogsSyncupApi,
  updateSpotlightedCandidates,
  updateEmailTemplate,
  getAllEmailTemplate,
  deleteEmailTemplate,
  addEmailTemplate,
  getEmailTemplateById,
  getEmailHistory,
  getClientListAdminShortListed,
  getClientListAdminHired,
  getAdminDevAcademic,
  updateAdminDevAcademic,
  saveAdminDevAcademic,
  deleteAdminDevAcademic,
  getAdminDevCertification,
  saveAdminDevCertification,
  updateAdminDevCertification,
  deleteAdminDevCertification,
  updateBlogCatApi,
  deleteBlogCatApi,
  postBlogCatApi,
  getBlogCatOpenApi,
  getBlogOpenApi,
  postBlogNewApi,
  updateBlogNewApi,
  deleteBlogNewApi,
  getAdminDevSocialProfile,
  saveAdminDevSocialProfile,
  updateAdminDevSocialProfile,
  getAdminDevEmployement,
  deleteAdminDevEmployement,
  saveAdminDevEmployement,
  updateAdminDevEmployement,
  getAdminDevProject,
  saveAdminDevProject,
  updateAdminDevProject,
  deleteAdminDevProject,
  getAdminDevTools,
  updateAdminDevTools,
  saveAdminDevTools,
  deleteAdminDevTools,
  getAdminDevSkills,
  saveAdminDevSkills,
  updateAdminDevSkills,
  deleteAdminDevSkills,
  getAdminDevAchievement,
  saveAdminDevAchievement,
  updateAdminDevAchievement,
  deleteAdminDevAchievement,
  updateAdminDevShortDesc,
  getResumeAdmin,
  approvalUpdatesAccept,
  approvalUpdatesReject,
  resumeParsingAdmin,
  deleteEnglishQuestion,
  updateEnglishQuestion,
  verifyDevEmailApi,
  getQueriesListApi,
  deleteQueryApi,
  updateQueryApi,
  approveDevEmailApi,
  unapproveDevEmailApi,
  getEnglishResultAdmin,
  saveEnglishResultAdmin,
  getAdminSkillOrStack,
  getAdminDevSkillResult,
  updateAdminSkillTest,
  allBlogNameIdApi,
  saveCreateSkills,
  getCreateSkills,
  deleteUserProfile,
  getSitemapTypesApi,
  updateSitemapTypesApi,
  deleteSitemapTypesApi,
  postSitemapTypesApi,
  deleteProfileImage,
  getSpotlightHistoryApi,
  getMaintenanceApi,
  updateMaintenanceStatusApi,
  addMaintenanceApi,
  updateMaintenanceApi,
  deleteMaintenanceApi,
  getMaintenanceOneApi,
  getSpeakDevListApi,
  getInterviewListApi,
  getJobCatApi,
  updateJobCatApi,
  deleteJobCatApi,
  postJobCatApi,
  setJobApprovedOrReject,
  getAllPerksBenefit,
  savePerkBenefints,
  updatePerkBenefints,
  deletePerkBenefints,
  getClientProfileDetails,
  getClientProfile,
  getCompanyProfile,
  updateFaqGroupStatusApi,
  updateFaqStatusApi,
  updateAdminJobStatusApi,
  getSkillStackExpApi,
  getSkillStackGroupApi,
  updateAssessmentNameApi,
  renderAllHackerrankAssessmentsNoLimit,
  updateEmailSettingStatusApi,
  getSkillCategoryApi,
  getSkillCategoryListsApi,
  getStackCategoryListsApi,
  createSkillsCategoryApi,
  updateSkillsCategoryApi,
  getAllEmailSlugsApi,
  updatePlagiarismApi,
  blockUserProfile,
  getEmailCountApi,
  sendJobMailApi,
  getEmailListsApi,
  getJobTitleListsApi,
  getPlanListsApi,
  getFeatureApi,
  getFeatureListsApi,
  getAddOnListsApi,
  addPlanFeatureApi,
  updatePlanFeatureApi,
  addPricingPlanApi,
  updatePricingPlanApi,
  updateAddOnAdminApi,
  saveAddOnAdminApi,
  updatePlanStatusApi,
  updateAddOnStatusApi,
  getPricingSlabApi,
  addPricingSlabApi,
  updatePricingSlabApi,
  getPendingSkillAPI,
  updatePendingSkillApi,
} from "../../api";

export function* addJobCatSaga({ data, navigate, closeModal }) {
  try {
    yield put({ type: types.ADD_JOB_CAT_START });
    const response = yield call(postJobCatApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.ADD_JOB_CAT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        olibrToast.success(response?.data?.message);
        const resp = yield call(getJobCatApi);
        yield put({
          type: types.ADD_JOB_CAT_SUCCESS,
          data: response.data,
        });
        yield put({
          type: types.GET_JOB_CAT_SUCCESS,
          data: resp.data,
        });
        closeModal();
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.ADD_JOB_CAT_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.ADD_JOB_CAT_FAIL });
  }
}

export function* deleteJobCatSaga({ jobCatId, navigate, closeModal }) {
  try {
    yield put({ type: types.DELETE_JOB_CAT_START });
    const response = yield call(deleteJobCatApi, jobCatId);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_JOB_CAT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        const resp = yield call(getJobCatApi);
        olibrToast.success(response?.data?.message);
        yield put({
          type: types.DELETE_JOB_CAT_SUCCESS,
          data: response.data,
        });
        yield put({
          type: types.GET_JOB_CAT_SUCCESS,
          data: resp.data,
        });
        closeModal();
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_JOB_CAT_FAIL });
  }
}

export function* getJobCatListSaga({ navigate }) {
  try {
    yield put({ type: types.GET_JOB_CAT_START });
    const response = yield call(getJobCatApi);
    if (response.data.status === 401) {
      yield put({ type: types.GET_JOB_CAT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_JOB_CAT_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_JOB_CAT_FAIL });
  }
}

export function* updateJobCatSaga({ jobCatId, data, navigate, closeModal }) {
  try {
    yield put({ type: types.UPDATE_JOB_CAT_START });
    const response = yield call(updateJobCatApi, jobCatId, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_JOB_CAT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        olibrToast.success(response?.data?.message);
        const resp = yield call(getJobCatApi);
        yield put({
          type: types.UPDATE_JOB_CAT_SUCCESS,
          data,
        });
        yield put({
          type: types.GET_JOB_CAT,
          data: resp.data,
        });
        closeModal();
      } else if (response?.data?.statusCode === "FAIL") {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_JOB_CAT_FAIL });
  }
}

export function* getDeveloperListsSaga({
  data,
  navigate,
  limit,
  page,
  term,
  apiType,
}) {
  try {
    yield put({ type: types.GET_DEVELOPER_LIST_START });
    const response = yield call(getDeveloperLists, data, limit, page, term);
    if (response.data.status === 401) {
      yield put({ type: types.GET_DEVELOPER_LIST_FAIL });
      olibrToast.error(response.data.message);
      olibrToast.clearWaitingQueue();
      navigate("/login");
    } else {
      yield put({
        type: types.GET_DEVELOPER_LIST_SUCCESS,
        data: response?.data,
        limit,
        page,
        apiType,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_DEVELOPER_LIST_FAIL });
  }
}

export function* devUpdateStatusSaga({ data, navigate }) {
  try {
    yield put({ type: types.DEV_UPDATE_STATUS_START });
    const response = yield call(devUpdateStatus, data);
    if (response.data.status === 401) {
      yield put({ type: types.DEV_UPDATE_STATUS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.DEV_UPDATE_STATUS_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.DEV_UPDATE_STATUS_FAIL });
  }
}

export function* getAdminSkillListsSaga({ skillType, navigate, pageData }) {
  try {
    yield put({ type: types.GET_ADMIN_SKILL_LIST_START });
    const response = yield call(getAdminSkillLists, skillType, pageData);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ADMIN_SKILL_LIST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ADMIN_SKILL_LIST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ADMIN_SKILL_LIST_FAIL });
  }
}

export function* updateSkillStatusSaga({ data, navigate, category }) {
  try {
    yield put({ type: types.UPDATE_SKILL_STATUS_START });
    const response = yield call(updateSkillStatus, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_SKILL_STATUS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        // olibrToast.success(response?.data?.message);
        yield put({
          type: types.UPDATE_SKILL_STATUS_SUCCESS,
          data,
          category,
        });
      } else if (response?.data?.statusCode === "FAIL") {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_SKILL_STATUS_FAIL });
  }
}

export function* addStackSaga({
  data,
  navigate,
  skillType,
  pageData,
  closeModal,
  resetForm,
  offset,
}) {
  try {
    yield put({ type: types.ADD_STACK_START });
    const response = yield call(addStack, data);
    if (response.data.status === 401) {
      yield put({ type: types.ADD_STACK_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        const resStackLists = yield call(getStackCategoryListsApi);
        yield put({
          type: types.GET_STACK_CATEGORY_LISTS_SUCESS,
          data: resStackLists?.data?.formattedSkillList,
        });
        const resOffset = yield call(renderAllHackerrankAssessments, 0);
        yield put({
          type: types.RENDER_ALL_HACKER_RANK_ASSESSMENT_SUCCESS,
          data: resOffset.data,
        });
        yield put({ type: types.ADD_STACK_SUCCESS, data: response.data });
        yield put({ type: types.ADD_STACK_SUCCESS });
        olibrToast.success(response?.data?.message);
        closeModal();
        resetForm();
      } else if (response?.data?.statusCode === "FAIL") {
        yield put({ type: types.EDIT_STACK_FAIL });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.ADD_STACK_FAIL });
  }
}

export function* editStackSaga({
  data,
  navigate,
  skillType,
  pageData,
  closeModal,
  resetForm,
  offset,
}) {
  try {
    yield put({ type: types.EDIT_STACK_START });
    const response = yield call(editStack, data);
    if (response.data.status === 401) {
      yield put({ type: types.EDIT_STACK_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        const resStackLists = yield call(getStackCategoryListsApi);
        yield put({
          type: types.GET_STACK_CATEGORY_LISTS_SUCESS,
          data: resStackLists?.data?.formattedSkillList,
        });
        const resOffset = yield call(renderAllHackerrankAssessments, 0);
        yield put({
          type: types.RENDER_ALL_HACKER_RANK_ASSESSMENT_SUCCESS,
          data: resOffset.data,
        });
        yield put({ type: types.EDIT_STACK_SUCCESS, data: response.data });
        yield put({ type: types.EDIT_STACK_SUCCESS });
        olibrToast.success(response?.data?.message);
        closeModal();
      } else if (response?.data?.statusCode === "FAIL") {
        yield put({ type: types.EDIT_STACK_FAIL });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.EDIT_STACK_FAIL });
  }
}

export function* getAdminStackListsSaga({ stack, navigate, pageData }) {
  try {
    yield put({ type: types.GET_ADMIN_STACK_LIST_START });
    const response = yield call(getAdminStackLists, stack, pageData);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ADMIN_STACK_LIST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ADMIN_STACK_LIST_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ADMIN_STACK_LIST_FAIL });
  }
}

export function* updateStackStatusSaga({ data, navigate, category }) {
  try {
    yield put({ type: types.UPDATE_STACK_STATUS_START });
    const response = yield call(updateStackStatus, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_STACK_STATUS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        const adminStackList = yield call(getStackCategoryListsApi);
        yield put({
          type: types.GET_STACK_CATEGORY_LISTS_SUCESS,
          data: adminStackList?.data?.formattedSkillList,
        });
        yield put({
          type: types.UPDATE_STACK_STATUS_SUCCESS,
          data: response.data,
        });
        // olibrToast.success(response?.data?.message);
      } else if (response?.data?.statusCode === "FAIL") {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_STACK_STATUS_FAIL });
  }
}

export function* refreshXobinAssessmentSaga({ navigate }) {
  try {
    yield put({ type: types.REFRESH_XOBIN_ASSESSMENT_START });
    const response = yield call(refreshXobinAssessment);
    if (response.data.status === 401) {
      yield put({ type: types.REFRESH_XOBIN_ASSESSMENT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.REFRESH_XOBIN_ASSESSMENT_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.REFRESH_XOBIN_ASSESSMENT_FAIL });
  }
}

export function* renderAllHackerrankAssessmentsSaga({ navigate, offset }) {
  try {
    yield put({ type: types.RENDER_ALL_HACKER_RANK_ASSESSMENT_START });
    const response = yield call(renderAllHackerrankAssessments, offset);
    if (response.data.status === 401) {
      yield put({ type: types.RENDER_ALL_HACKER_RANK_ASSESSMENT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.RENDER_ALL_HACKER_RANK_ASSESSMENT_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.RENDER_ALL_HACKER_RANK_ASSESSMENT_FAIL });
  }
}

export function* renderAllHackerrankAssessmentsNoLimitSaga({
  navigate,
  offset,
}) {
  try {
    yield put({ type: types.RENDER_ALL_HACKER_RANK_ASSESSMENT_NO_LIMIT_START });
    const response = yield call(renderAllHackerrankAssessmentsNoLimit, offset);
    if (response.data.status === 401) {
      yield put({
        type: types.RENDER_ALL_HACKER_RANK_ASSESSMENT_NO_LIMIT_FAIL,
      });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.RENDER_ALL_HACKER_RANK_ASSESSMENT_NO_LIMIT_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.RENDER_ALL_HACKER_RANK_ASSESSMENT_NO_LIMIT_FAIL });
  }
}

export function* addSkillSaga({
  data,
  navigate,
  skillType,
  pageData,
  closeModal,
  resetForm,
}) {
  try {
    yield put({ type: types.ADD_SKILL_START });
    const response = yield call(addSkill, data);
    if (response.data.status === 401) {
      yield put({ type: types.ADD_SKILL_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        const responseSkill = yield call(getSkillCategoryListsApi);
        yield put({
          type: types.GET_ADMIN_SKILL_LIST_SUCCESS,
          data: responseSkill?.data,
        });
        const responseHkrRnk = yield call(renderAllHackerrankAssessments, 0); //0 i  offset

        yield put({
          type: types.RENDER_ALL_HACKER_RANK_ASSESSMENT_SUCCESS,
          data: responseHkrRnk.data,
        });
        yield put({
          type: types.GET_SKILL_CATEGORY_LISTS_SUCESS,
          data: responseSkill?.data?.formattedSkillList,
        });
        yield put({ type: types.ADD_SKILL_SUCCESS });
        olibrToast.success(response?.data?.message);
        closeModal();
        resetForm();
      } else if (response?.data?.statusCode === "FAIL") {
        yield put({ type: types.ADD_SKILL_FAIL });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.ADD_SKILL_FAIL });
  }
}

export function* editSkillSaga({
  data,
  navigate,
  skillType,
  pageData,
  closeModal,
  resetForm,
}) {
  try {
    yield put({ type: types.EDIT_SKILL_START });
    const response = yield call(editSkill, data);
    if (response.data.status === 401) {
      yield put({ type: types.EDIT_SKILL_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        const responseSkill = yield call(getSkillCategoryListsApi);
        const responseHkrRnk = yield call(renderAllHackerrankAssessments, 0); //0 i  offset
        yield put({
          type: types.GET_ADMIN_SKILL_LIST_SUCCESS,
          data: responseSkill?.data,
        });
        yield put({
          type: types.RENDER_ALL_HACKER_RANK_ASSESSMENT_SUCCESS,
          data: responseHkrRnk.data,
        });
        yield put({
          type: types.GET_SKILL_CATEGORY_LISTS_SUCESS,
          data: responseSkill?.data?.formattedSkillList,
        });
        yield put({ type: types.EDIT_SKILL_SUCCESS });
        olibrToast.success(response?.data?.message);
        closeModal();
        resetForm();
      } else if (response?.data?.statusCode === "FAIL") {
        yield put({ type: types.EDIT_SKILL_FAIL });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.EDIT_SKILL_FAIL });
  }
}

export function* renderTopicListSaga({ navigate, offset }) {
  try {
    yield put({ type: types.RENDER_TOPIC_LIST_START });
    const response = yield call(renderTopicList);
    if (response.data.status === 401) {
      yield put({ type: types.RENDER_TOPIC_LIST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.RENDER_TOPIC_LIST_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.RENDER_TOPIC_LIST_FAIL });
  }
}

export function* getAllIconsSaga({ navigate, offset }) {
  try {
    yield put({ type: types.GET_ALL_ICONS_START });
    const response = yield call(getAllIcons);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ALL_ICONS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_ALL_ICONS_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.GET_ALL_ICONS_FAIL });
  }
}

export function* uploadIconsSaga({ data, navigate }) {
  try {
    yield put({ type: types.UPLOAD_ICONS_START });
    const response = yield call(uploadIcons, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPLOAD_ICONS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      const iconsRes = yield call(getAllIcons);
      yield put({ type: types.GET_ALL_ICONS_SUCCESS, data: iconsRes.data });
      yield put({ type: types.UPLOAD_ICONS_SUCCESS, data: response.data });
      olibrToast.success(response.data.message);
    }
  } catch (error) {
    yield put({ type: types.UPLOAD_ICONS_FAIL });
  }
}

export function* deleteStackSaga({ stackId, navigate, offset }) {
  try {
    yield put({ type: types.DELETE_STACK_START });
    const response = yield call(deleteStack, stackId);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_STACK_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        const tempStack = yield call(getStackCategoryListsApi);
        yield put({
          type: types.GET_STACK_CATEGORY_LISTS_SUCESS,
          data: tempStack?.data?.formattedSkillList,
        });
        const resOffset = yield call(renderAllHackerrankAssessments, offset);
        yield put({
          type: types.RENDER_ALL_HACKER_RANK_ASSESSMENT_SUCCESS,
          data: resOffset.data,
        });
        yield put({ type: types.DELETE_STACK_SUCCESS, data: response.data });
        olibrToast.success(response?.data?.message);
      } else if (response?.data?.statusCode === "FAIL") {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_STACK_FAIL });
  }
}

export function* deleteSkillSaga({ skillId, navigate, skillType, pageData }) {
  try {
    yield put({ type: types.DELETE_SKILL_START });
    const response = yield call(deleteSkill, skillId);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_SKILL_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        const responseSkill = yield call(
          getAdminSkillLists,
          skillType,
          pageData
        );
        const responseHkrRnk = yield call(renderAllHackerrankAssessments, 0); //0 i  offset
        yield put({
          type: types.GET_ADMIN_SKILL_LIST_SUCCESS,
          data: responseSkill?.data,
        });
        yield put({
          type: types.RENDER_ALL_HACKER_RANK_ASSESSMENT_SUCCESS,
          data: responseHkrRnk.data,
        });
        yield put({ type: types.DELETE_SKILL_SUCCESS, data: response.data });
        olibrToast.success(response?.data?.message);
      } else if (response?.data?.statusCode === "FAIL") {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_SKILL_FAIL });
  }
}

export function* getDeveloperProfileSaga({ devId, navigate, setModal }) {
  try {
    yield put({ type: types.GET_DEVELOPER_PROFILE_START });
    const response = yield call(getDeveloperProfile, devId);
    if (response.data.status === 401) {
      yield put({ type: types.GET_DEVELOPER_PROFILE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_DEVELOPER_PROFILE_SUCCESS,
        data: response?.data?.data,
      });
      setModal(true);
    }
  } catch (error) {
    yield put({ type: types.GET_DEVELOPER_PROFILE_FAIL });
  }
}

export function* getAllEmailSlugsSaga({ navigate }) {
  try {
    yield put({ type: types.GET_ALL_EMAILS_SLUGS_START });
    const response = yield call(getAllEmailSlugsApi);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ALL_EMAILS_SLUGS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ALL_EMAILS_SLUGS_SUCCESS,
        data: response?.data?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ALL_EMAILS_SLUGS_FAIL });
  }
}

export function* updateProfileStatusSaga({
  userName,
  data,
  navigate,
  page,
  limit,
  filter,
  setModal,
}) {
  try {
    yield put({ type: types.UPDATE_PROFILE_STATUS_START });
    const response = yield call(updateProfileStatus, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_PROFILE_STATUS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        // data.status === "approved" &&
        //   window.provesrc.display("information", null, {
        //     message: `${userName}’s profile is now Live on Olibr.`,
        //   });
        yield put({
          type: types.UPDATE_PROFILE_STATUS_SUCCESS,
          data: response.data,
        });
        const devProfile = yield call(getDeveloperProfile, data?.devId);
        yield put({
          type: types.GET_DEVELOPER_PROFILE_SUCCESS,
          data: devProfile?.data?.data,
        });
        setModal(true);
        olibrToast.success(response?.data?.message);
        const devlists = yield call(getDeveloperLists, filter, limit, page);
        yield put({
          type: types.GET_DEVELOPER_LIST_SUCCESS,
          data: devlists?.data,
          limit,
          page,
        });
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    navigate("/login");
    yield put({ type: types.UPDATE_PROFILE_STATUS_FAIL });
  }
}

export function* resultParsingSaga({ data, navigate }) {
  try {
    yield put({ type: types.RESULT_PARSING_START });
    const response = yield call(resultParsing, data);
    if (response.data.status === 401) {
      yield put({ type: types.RESULT_PARSING_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.RESULT_PARSING_SUCCESS, data: response.data });
      olibrToast.success("Result Posted!");
    }
  } catch (error) {
    yield put({ type: types.RESULT_PARSING_FAIL });
  }
}

export function* getAllToolsSaga({ navigate, pageData }) {
  try {
    yield put({ type: types.GET_ALL_TOOLS_START });
    const response = yield call(getAllTools, pageData);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ALL_TOOLS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusText === "OK") {
        yield put({ type: types.GET_ALL_TOOLS_SUCCESS, data: response.data });
      } else {
        // olibrToast.error(response?.data?.message)
        yield put({ type: types.GET_ALL_TOOLS_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.GET_ALL_TOOLS_FAIL });
  }
}

export function* getSpeakDevSaga({ navigate }) {
  try {
    yield put({ type: types.GET_SPEAK_WITH_DEV_LIST_START });
    const response = yield call(getSpeakDevListApi);
    if (response.data.status === 401) {
      yield put({ type: types.GET_SPEAK_WITH_DEV_LIST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.GET_SPEAK_WITH_DEV_LIST_SUCCESS,
          data: response.data,
        });
      } else {
        // olibrToast.error(response?.data?.message)
        yield put({ type: types.GET_SPEAK_WITH_DEV_LIST_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.GET_SPEAK_WITH_DEV_LIST_FAIL });
  }
}

export function* getInterviewListSaga({ navigate, pageData }) {
  try {
    yield put({ type: types.GET_INTERVIEW_LIST_START });
    const response = yield call(getInterviewListApi, pageData);
    if (response.data.status === 401) {
      yield put({ type: types.GET_INTERVIEW_LIST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.GET_INTERVIEW_LIST_SUCCESS,
          data: response.data,
        });
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.GET_INTERVIEW_LIST_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.GET_INTERVIEW_LIST_FAIL });
  }
}

export function* deleteToolSaga({ id, navigate, pageData }) {
  try {
    yield put({ type: types.DELETE_ADMIN_TOOL_START });
    const response = yield call(deleteTool, id);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ALL_TOOLS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.DELETE_ADMIN_TOOL_SUCCESS,
          data: response.data,
        });
        olibrToast.success(response?.data?.message);
        const resAllTool = yield call(getAllTools, pageData);
        yield put({ type: types.GET_ALL_TOOLS_SUCCESS, data: resAllTool.data });
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_ADMIN_TOOL_FAIL });
  }
}
export function* updateToolSaga({
  data,
  navigate,
  pageData,
  closeModal,
  resetForm,
}) {
  try {
    yield put({ type: types.ADMIN_UPDATE_SKILL_START });
    const response = yield call(updateTool, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPLOAD_ICONS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.ADMIN_UPDATE_SKILL_SUCCESS,
          data: response.data,
        });
        const resTools = yield call(getAllTools, pageData);
        yield put({ type: types.GET_ALL_TOOLS_SUCCESS, data: resTools?.data });
        olibrToast.success(response?.data?.message);
        closeModal();
        resetForm();
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.ADMIN_UPDATE_SKILL_FAIL });
  }
}

export function* addToolSaga({
  data,
  navigate,
  pageData,
  closeModal,
  resetForm,
}) {
  try {
    yield put({ type: types.ADMIN_ADD_TOOL_START });
    const response = yield call(addTool, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPLOAD_ICONS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({ type: types.ADMIN_ADD_TOOL_FAIL, data: response.data });
        const resTools = yield call(getAllTools, pageData);
        yield put({ type: types.GET_ALL_TOOLS_SUCCESS, data: resTools?.data });
        olibrToast.success(response?.data?.message);
        closeModal();
        resetForm();
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.ADMIN_ADD_TOOL_FAIL });
  }
}

export function* setMemberPasswordSaga({ data, navigate }) {
  try {
    yield put({ type: types.SET_MEMBER_PASSWORD_START });
    const response = yield call(setMemberPassword, data);
    if (response.data.status === 401) {
      yield put({ type: types.SET_MEMBER_PASSWORD_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.SET_MEMBER_PASSWORD_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.SET_MEMBER_PASSWORD_FAIL });
  }
}

export function* addUserSaga({ data, navigate, setDetails, resetForm }) {
  try {
    yield put({ type: types.ADD_USER_START });
    const response = yield call(addUser, data);
    if (response.data.status === 401) {
      yield put({ type: types.ADD_USER_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.ADD_USER_SUCCESS,
          data: response?.data?.payload,
        });
        olibrToast.success(response?.data?.message, "added");
        setDetails({});
        resetForm();
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.ADD_USER_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.ADD_USER_FAIL });
  }
}

export function* getUserByIdSaga({ userId, navigate }) {
  try {
    yield put({ type: types.GET_USER_BY_ID_START });
    const response = yield call(getUserById, userId);
    if (response.data.status === 401) {
      yield put({ type: types.GET_USER_BY_ID_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_USER_BY_ID_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.GET_USER_BY_ID_FAIL });
  }
}

export function* updateUserSaga({
  userId,
  data,
  pageData,
  setDetails,
  resetForm,
  navigate,
}) {
  try {
    yield put({ type: types.UPDATE_USER_START });
    const response = yield call(updateUser, { userId, data });
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({ type: types.UPDATE_USER_SUCCESS, data: response.data });
        olibrToast.success(response?.data?.message, "added");
        setDetails({});
        resetForm();
        const resUserLists = yield call(userListing, pageData);
        yield put({
          type: types.USER_LISTING_SUCCESS,
          data: resUserLists.data,
        });
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_USER_FAIL });
  }
}

export function* updateUserStatus({ userId, status, navigate }) {
  try {
    yield put({ type: types.UPDATE_USER_STATUS_START });
    const response = yield call(updateUserStatusAPI, userId);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_USER_STATUS_SUCCESS,
          status,
          userId,
        });
        // olibrToast.success(response?.data?.message);
      } else {
        olibrToast.error(response.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_USER_STATUS_FAIL });
  }
}

export function* deleteUserSaga({ userId, setDetails, resetForm, navigate }) {
  try {
    yield put({ type: types.DELETE_USER_START });
    const response = yield call(deleteUser, userId);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_USER_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({ type: types.DELETE_USER_SUCCESS, userId });
        olibrToast.success(response?.data?.message, "deleted");
        setDetails({});
        resetForm();
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_USER_FAIL });
  }
}

export function* userListingSaga({ navigate, pageData }) {
  try {
    yield put({ type: types.USER_LISTING_START });
    const response = yield call(userListing, pageData);
    if (response.data.status === 401) {
      yield put({ type: types.USER_LISTING_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.USER_LISTING_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.USER_LISTING_FAIL });
  }
}

export function* getResumeAdminSaga({ userId, navigate }) {
  try {
    yield put({ type: types.GET_RESUME_ADMIN_START });
    const response = yield call(getResumeAdmin, userId);
    if (response.data.status === 401) {
      yield put({ type: types.GET_RESUME_ADMIN_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_RESUME_ADMIN_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.GET_RESUME_ADMIN_FAIL });
  }
}

export function* getDevResultSaga({ userId, navigate }) {
  try {
    yield put({ type: types.GET_DEV_RESULT_START });
    const response = yield call(getDevResult, userId);
    if (response.data.status === 401) {
      yield put({ type: types.GET_DEV_RESULT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_DEV_RESULT_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.GET_DEV_RESULT_FAIL });
  }
}

export function* getCategoryListSaga({ navigate }) {
  try {
    yield put({ type: types.GET_CATEGORY_LIST_START });
    const response = yield call(getCategoryList);
    if (response.data.status === 401) {
      yield put({ type: types.GET_CATEGORY_LIST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_CATEGORY_LIST_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.GET_CATEGORY_LIST_FAIL });
  }
}
export function* addCategoryBlogSaga({ data, navigate }) {
  try {
    yield put({ type: types.ADD_CATEGORY_BLOG_START });
    const response = yield call(addCategoryBlog, data);
    if (response.data.status === 401) {
      yield put({ type: types.ADD_CATEGORY_BLOG_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.ADD_CATEGORY_BLOG_SUCCESS,
          data: response.data,
        });
        olibrToast.success(response?.data?.message);
        const resCatgoryList = yield call(getCategoryList);
        yield put({
          type: types.GET_CATEGORY_LIST_SUCCESS,
          data: resCatgoryList.data,
        });
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.ADD_CATEGORY_BLOG_FAIL });
  }
}

export function* deleteCategoryBlogSaga({ categoryId, navigate }) {
  try {
    yield put({ type: types.DELETE_CATEGORY_BLOG_START });
    const response = yield call(deleteCategoryBlog, categoryId);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_CATEGORY_BLOG_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.DELETE_CATEGORY_BLOG_SUCCESS,
          data: response.data,
        });
        olibrToast.success(response?.data?.message);
        const resCatgoryList = yield call(getCategoryList);
        yield put({
          type: types.GET_CATEGORY_LIST_SUCCESS,
          data: resCatgoryList.data,
        });
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_CATEGORY_BLOG_FAIL });
  }
}

export function* updateCategoryBlogSaga({ categoryId, data, navigate }) {
  try {
    yield put({ type: types.UPDATE_CATEGORY_BLOG_START });
    const response = yield call(updateCategoryBlog, { categoryId, data });
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_CATEGORY_BLOG_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_CATEGORY_BLOG_SUCCESS,
          data: response.data,
        });
        olibrToast.success(response?.data?.message);
        const resCatgoryList = yield call(getCategoryList);
        yield put({
          type: types.GET_CATEGORY_LIST_SUCCESS,
          data: resCatgoryList.data,
        });
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_CATEGORY_BLOG_FAIL });
  }
}

export function* allBlogNameIdSaga({ navigate }) {
  try {
    yield put({ type: types.ALL_BLOG_NAME_AND_ID_START });
    const response = yield call(allBlogNameIdApi);
    if (response.data.status === 401) {
      yield put({ type: types.ALL_BLOG_NAME_AND_ID_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.ALL_BLOG_NAME_AND_ID_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.ALL_BLOG_NAME_AND_ID_FAIL });
  }
}

export function* getBlogListSaga({ navigate }) {
  try {
    yield put({ type: types.GET_BLOG_LIST_START });
    const response = yield call(getBlogList);
    if (response.data.status === 401) {
      yield put({ type: types.GET_BLOG_LIST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_BLOG_LIST_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.GET_BLOG_LIST_FAIL });
  }
}

export function* getSitemapTypesSaga({ navigate }) {
  try {
    yield put({ type: types.GET_SITEMAP_TYPE_START });
    const response = yield call(getSitemapTypesApi);
    if (response.data.status === 401) {
      yield put({ type: types.GET_SITEMAP_TYPE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_SITEMAP_TYPE_SUCCESS, data: response.data });
    }
  } catch (error) {
    yield put({ type: types.GET_SITEMAP_TYPE_FAIL });
  }
}

export function* postBlogSaga({ data, navigate }) {
  try {
    yield put({ type: types.POST_BLOG_START });
    const response = yield call(postBlog, data);
    if (response.data.status === 401) {
      yield put({ type: types.POST_BLOG_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({ type: types.POST_BLOG_SUCCESS, data: response.data });
        olibrToast.success(response?.data?.message);
        const resJobLists = yield call(getBlogList);
        yield put({
          type: types.GET_BLOG_LIST_SUCCESS,
          data: resJobLists.data,
        });
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.POST_BLOG_FAIL });
  }
}

export function* postSitemapTypesSaga({ data, navigate }) {
  try {
    yield put({ type: types.POST_SITEMAP_TYPE_START });
    const response = yield call(postSitemapTypesApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.POST_SITEMAP_TYPE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.POST_SITEMAP_TYPE_SUCCESS,
          data: response.data,
        });
        olibrToast.success(response?.data?.message);
        const resJobLists = yield call(getSitemapTypesApi);
        yield put({
          type: types.GET_SITEMAP_TYPE_SUCCESS,
          data: resJobLists.data,
        });
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.POST_SITEMAP_TYPE_FAIL });
  }
}

export function* updateBlogSaga({ blogId, data, navigate }) {
  try {
    yield put({ type: types.UPDATE_BLOG_START });
    const response = yield call(updateBlog, blogId, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_BLOG_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({ type: types.UPDATE_BLOG_SUCCESS, data: response.data });
        olibrToast.success(response?.data?.message);
        const resJobLists = yield call(getBlogList);
        yield put({
          type: types.GET_BLOG_LIST_SUCCESS,
          data: resJobLists.data,
        });
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_BLOG_FAIL });
  }
}

export function* updateSitemapTypesSaga({ data, sitemapTypeId, navigate }) {
  try {
    yield put({ type: types.UPDATE_SITEMAP_TYPE_START });
    const response = yield call(updateSitemapTypesApi, data, sitemapTypeId);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_SITEMAP_TYPE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_SITEMAP_TYPE_SUCCESS,
          data: response.data,
        });
        olibrToast.success(response?.data?.message);
        const resJobLists = yield call(getSitemapTypesApi);
        yield put({
          type: types.GET_SITEMAP_TYPE_SUCCESS,
          data: resJobLists.data,
        });
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_SITEMAP_TYPE_FAIL });
  }
}

export function* deleteBlogSaga({ blogId, navigate }) {
  try {
    yield put({ type: types.DELETE_BLOG_START });
    const response = yield call(deleteBlog, blogId);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_BLOG_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({ type: types.DELETE_BLOG_SUCCESS, data: response.data });
        olibrToast.success(response?.data?.message);
        const resJobLists = yield call(getBlogList);
        yield put({
          type: types.GET_BLOG_LIST_SUCCESS,
          data: resJobLists.data,
        });
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_BLOG_FAIL });
  }
}

export function* deleteSitemapTypesSaga({ sitemapTypeId, navigate }) {
  try {
    yield put({ type: types.DELETE_SITEMAP_TYPE_START });
    const response = yield call(deleteSitemapTypesApi, sitemapTypeId);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_SITEMAP_TYPE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.DELETE_SITEMAP_TYPE_SUCCESS,
          data: response.data,
        });
        olibrToast.success(response?.data?.message);
        const resJobLists = yield call(getSitemapTypesApi);
        yield put({
          type: types.GET_SITEMAP_TYPE_SUCCESS,
          data: resJobLists.data,
        });
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_SITEMAP_TYPE_FAIL });
  }
}

export function* addFaqGroupSaga({ data, setModal, resetForm, navigate }) {
  try {
    yield put({ type: types.ADD_FAQ_GROUP_START });
    const response = yield call(addFaqGroup, data);
    if (response.data.status === 401) {
      yield put({ type: types.ADD_FAQ_GROUP_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response.data.statusValue === 200) {
        yield put({
          type: types.ADD_FAQ_GROUP_SUCCESS,
          data: response?.data?.data,
          faqFor: data?.faqFor,
        });
        olibrToast.success(response.data.message);
        setModal(false);
        resetForm();
      } else {
        olibrToast.error(response.data.message);
      }
    }
  } catch (error) {
    yield put({ type: types.ADD_FAQ_GROUP_FAIL });
  }
}

export function* addFaqSaga({ navigate, data, faqFor, setModal, resetForm }) {
  try {
    yield put({ type: types.ADD_FAQ_START });
    const response = yield call(addFaq, data);
    if (response.data.status === 401) {
      yield put({ type: types.ADD_FAQ_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response.data.statusValue === 200) {
        yield put({
          type: types.ADD_FAQ_SUCCESS,
          data: response?.data?.data,
          faqFor,
        });
        olibrToast.success(response.data.message);
        resetForm();
        setModal(false);
      } else {
        olibrToast.error(response.data.message);
      }
    }
  } catch (error) {
    yield put({ type: types.ADD_FAQ_FAIL });
  }
}

export function* updateFaqSaga({
  data,
  faqFor,
  setModal,
  resetForm,
  navigate,
}) {
  try {
    yield put({ type: types.UPDATE_FAQ_START });
    const response = yield call(updateFaq, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_FAQ_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response.data.statusValue === 200) {
        yield put({
          type: types.UPDATE_FAQ_SUCCESS,
          data: response?.data?.data,
          faqFor,
        });
        olibrToast.success(response.data.message);
        setModal(false);
        resetForm();
      } else {
        olibrToast.error(response.data.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_FAQ_FAIL });
  }
}

export function* updateFaqGroupSaga({ data, setModal, resetForm, navigate }) {
  try {
    yield put({ type: types.UPDATE_FAQ_GROUP_START });
    const response = yield call(updateFaqGroup, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_FAQ_GROUP_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response.data.statusValue === 200) {
        yield put({
          type: types.UPDATE_FAQ_SUCCESS,
          data: response?.data?.data,
          faqFor: data.faqFor,
        });
        olibrToast.success(response.data.message);
        setModal(false);
        resetForm();
      } else {
        olibrToast.error(response.data.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_FAQ_GROUP_FAIL });
  }
}

export function* deleteFaqSaga({
  navigate,
  data,
  faqFor,
  setModal,
  resetForm,
}) {
  try {
    yield put({ type: types.DELETE_FAQ_START });
    const response = yield call(deleteFaq, data);

    if (response.data.status === 401) {
      yield put({ type: types.DELETE_FAQ_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response.data.statusValue === 200) {
        yield put({
          type: types.DELETE_FAQ_SUCCESS,
          faqFor,
          faqId: data?.faqId,
          faqGroupId: data?.faqGroupId,
        });
        olibrToast.success(response.data.message);
        setModal(false);
        resetForm(false);
      } else {
        olibrToast.error(response.data.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_FAQ_FAIL });
  }
}

export function* deleteFaqGroupSaga({
  navigate,
  data,
  faqFor,
  setModal,
  resetForm,
}) {
  try {
    yield put({ type: types.DELETE_FAQ_GROUP_START });
    const response = yield call(deleteFaqGroup, data);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_FAQ_GROUP_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response.data.statusValue === 200) {
        yield put({
          type: types.DELETE_FAQ_GROUP_SUCCESS,
          faqFor,
          faqGroupId: data?.faqGroupId,
        });
        olibrToast.success(response.data.message);
        setModal(false);
        resetForm(false);
      } else {
        olibrToast.error(response.data.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_FAQ_GROUP_FAIL });
  }
}

export function* addQuestionSaga({ data, navigate, closeModal }) {
  try {
    yield put({ type: types.ADD_QUESTION_START });
    const response = yield call(addQuestion, data);
    if (response.data.status === 401) {
      yield put({ type: types.ADD_QUESTION_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.ADD_QUESTION_SUCCESS,
          data: response?.data?.data,
        });
        olibrToast.success(response?.data?.message);
        closeModal();
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.ADD_QUESTION_FAIL });
  }
}

export function* deleteEnglishQuestionSaga({ questionId, navigate }) {
  try {
    yield put({ type: types.DELETE_ENGLISH_QUESTION_START });
    const response = yield call(deleteEnglishQuestion, questionId);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_ENGLISH_QUESTION_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({ type: types.DELETE_ENGLISH_QUESTION_SUCCESS, questionId });
        olibrToast.success(response?.data?.message);
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_ENGLISH_QUESTION_FAIL });
  }
}

export function* updateEnglishQuestionSaga({ data, navigate }) {
  try {
    yield put({ type: types.UPDATE_ENGLISH_QUESTION_START });
    const response = yield call(updateEnglishQuestion, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_ENGLISH_QUESTION_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_ENGLISH_QUESTION_SUCCESS,
          data: response?.data?.data,
        });
        const englishResponse = yield call(englishAllQuestion);
        yield put({
          type: types.ENGLISH_ALL_QUESTION_SUCCESS,
          data: englishResponse?.data,
        });
        olibrToast.success(response?.data?.message);
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_ENGLISH_QUESTION_FAIL });
  }
}

export function* englishAllQuestionSaga({ navigate }) {
  try {
    yield put({ type: types.ENGLISH_ALL_QUESTION_START });
    const response = yield call(englishAllQuestion);
    if (response.data.status === 401) {
      yield put({ type: types.ENGLISH_ALL_QUESTION_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.ENGLISH_ALL_QUESTION_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.ENGLISH_ALL_QUESTION_FAIL });
  }
}

export function* getAllJobsSaga({ pageData, navigate, filterObj, clearFirst }) {
  try {
    yield put({ type: types.GET_ALL_JOB_START });
    const response = yield call(getAllJobs, pageData, filterObj);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ALL_JOB_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (clearFirst) {
        yield put({
          type: types.GET_ALL_JOB_SUCCESS_FILTER,
          data: response.data,
        });
      } else {
        yield put({ type: types.GET_ALL_JOB_SUCCESS, data: response.data });
      }
    }
  } catch (error) {
    yield put({ type: types.GET_ALL_JOB_FAIL });
  }
}

export function* addJobSaga({ data, setModal, navigate, socket }) {
  try {
    yield put({ type: types.ADD_JOB_START });
    const response = yield call(addJob, data);
    if (response.data.status === 401) {
      yield put({ type: types.ADD_JOB_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response.data.statusCode === "SUCCESS") {
        yield put({ type: types.ADD_JOB_SUCCESS, data: response?.data?.data });
        socket.emit("jobPost", response?.data?.data);
        olibrToast.success(response.data.message);
        setModal(false);
      } else {
        olibrToast.error(response.data.message);
        yield put({ type: types.ADD_JOB_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.ADD_JOB_FAIL });
  }
}

export function* editJobSaga({ data, setModal, navigate }) {
  try {
    yield put({ type: types.EDIT_JOB_START });
    const response = yield call(editJob, data);
    if (response.data.status === 401) {
      yield put({ type: types.EDIT_JOB_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response.data.statusCode === "SUCCESS") {
        yield put({ type: types.EDIT_JOB_SUCCESS, data: response?.data?.data });
        olibrToast.success(response.data.message);
        setModal(false);
      } else {
        yield put({ type: types.EDIT_JOB_FAIL });
        olibrToast.error(response.data.message);
      }
    }
  } catch (error) {
    yield put({ type: types.EDIT_JOB_FAIL });
  }
}

export function* deleteJobSaga({ navigate, jobId, setModal, setDeleteModal }) {
  try {
    yield put({ type: types.DELETE_JOB_START });
    const response = yield call(deleteJob, jobId);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_JOB_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response.data.statusCode === "SUCCESS") {
        yield put({ type: types.DELETE_JOB_SUCCESS, jobId });
        olibrToast.success(response.data.message);
        setModal({ edit: false });
        setDeleteModal(false);
      } else {
        olibrToast.error(response.data.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_JOB_FAIL });
  }
}

export function* adminDashboardStepOneSaga({
  devId,
  data,
  filterValue,
  page,
  limit,
  navigate,
}) {
  try {
    yield put({ type: types.ADMIN_DASHBOARD_STEP_ONE_START });
    const response = yield call(adminDashboardStepOne, { devId, data });
    if (response.data.status === 401) {
      yield put({ type: types.ADMIN_DASHBOARD_STEP_ONE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else if (response.data.statusValue === 400) {
      yield put({ type: types.ADMIN_DASHBOARD_STEP_ONE_FAIL });
      olibrToast.error(response.data.message);
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.ADMIN_DASHBOARD_STEP_ONE_SUCCESS,
          data,
          devId,
        });
      }
    }
  } catch (error) {
    yield put({ type: types.ADMIN_DASHBOARD_STEP_ONE_FAIL });
  }
}

export function* getClientListAdminSaga({ data, navigate, limit, page, term }) {
  try {
    yield put({ type: types.GET_CLIENT_LIST_ADMIN_START });
    const response = yield call(getClientListAdmin, data, limit, page, term);
    if (response.data.status === 401) {
      yield put({ type: types.GET_CLIENT_LIST_ADMIN_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.GET_CLIENT_LIST_ADMIN_SUCCESS, data: response });
    }
  } catch (error) {
    yield put({ type: types.GET_CLIENT_LIST_ADMIN_FAIL });
  }
}

export function* getClientDetailAdminSaga({ clientId, navigate }) {
  try {
    yield put({ type: types.GET_CLIENT_DETAIL_ADMIN_START });
    const response = yield call(getClientDetailAdmin, clientId);
    if (response.data.status === 401) {
      yield put({ type: types.GET_CLIENT_DETAIL_ADMIN_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_CLIENT_DETAIL_ADMIN_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_CLIENT_DETAIL_ADMIN_FAIL });
  }
}

export function* getClientListAdminShortListedSaga({ clientId, navigate }) {
  try {
    yield put({ type: types.GET_ADMIN_CLIENT_SHORTLIST_START });
    const response = yield call(getClientListAdminShortListed, clientId);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ADMIN_CLIENT_SHORTLIST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ADMIN_CLIENT_SHORTLIST_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ADMIN_CLIENT_SHORTLIST_FAIL });
  }
}

export function* getClientListAdminHiredSaga({ clientId, navigate }) {
  try {
    yield put({ type: types.GET_ADMIN_CLIENT_HIRED_START });
    const response = yield call(getClientListAdminHired, clientId);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ADMIN_CLIENT_HIRED_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ADMIN_CLIENT_HIRED_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ADMIN_CLIENT_HIRED_FAIL });
  }
}

export function* getJobListByClientAdminSaga({ clientId, pageData, navigate }) {
  try {
    yield put({ type: types.GET_JOB_LIST_BY_CLIENT_ADMIN_START });
    const response = yield call(getJobListByClientAdmin, clientId, pageData);
    if (response.data.status === 401) {
      yield put({ type: types.GET_JOB_LIST_BY_CLIENT_ADMIN_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_JOB_LIST_BY_CLIENT_ADMIN_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_JOB_LIST_BY_CLIENT_ADMIN_FAIL });
  }
}

export function* getJobFullClientAdminSaga({ jobId, navigate }) {
  try {
    yield put({ type: types.GET_JOB_FULL_CLIENT_ADMIN_START });
    const response = yield call(getJobFullClientAdmin, jobId);
    if (response.data.status === 401) {
      yield put({ type: types.GET_JOB_FULL_CLIENT_ADMIN_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_JOB_FULL_CLIENT_ADMIN_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_JOB_FULL_CLIENT_ADMIN_FAIL });
  }
}

export function* blogsSyncupSaga({ navigate }) {
  try {
    yield put({ type: types.BLOGS_SYNCUP_START });
    const response = yield call(blogsSyncupApi);
    if (response.data.status === 401) {
      yield put({ type: types.BLOGS_SYNCUP_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.BLOGS_SYNCUP_SUCCESS });
      olibrToast.success(response.data.message);
    }
  } catch (error) {
    olibrToast.error("Unable to process your request");
    yield put({ type: types.BLOGS_SYNCUP_FAIL });
  }
}

export function* updatePlagiarismSaga({ data, navigate, setPlag, devId }) {
  try {
    yield put({ type: types.UPDATE_PLAGIARISM_STATUS_START });
    const response = yield call(updatePlagiarismApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_PLAGIARISM_STATUS_FAIL }); //current
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_PLAGIARISM_STATUS_SUCCESS,
          data,
        });
        const resp = yield call(getDeveloperProfile, devId);
        yield put({
          type: types.GET_DEVELOPER_PROFILE_SUCCESS,
          data: resp?.data?.data,
        });
        setPlag(data?.status);
      }
      // olibrToast.success(response?.data?.message);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_PLAGIARISM_STATUS_FAIL });
  }
}

export function* updateSpotlightedCandidatesSaga({ data, navigate }) {
  try {
    yield put({ type: types.UPDATE_SPOTLIGHTED_CANDIDATES_START });
    const response = yield call(updateSpotlightedCandidates, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_SPOTLIGHTED_CANDIDATES_FAIL }); //current
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_SPOTLIGHTED_CANDIDATES_SUCCESS,
          data,
        });
      }
      // olibrToast.success(response?.data?.message);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_SPOTLIGHTED_CANDIDATES_FAIL });
  }
}

export function* updateEmailSettingStatusSaga({ data, navigate }) {
  try {
    yield put({ type: types.UPDATE_EMAIL_SETTING_STATUS_START });
    const response = yield call(updateEmailSettingStatusApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_EMAIL_SETTING_STATUS_FAIL }); //current
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        // const resp = yield call(getAllEmailTemplate);
        // yield put({
        //   type: types.GET_ADMIN_TEMPLATE_LIST_SUCCESS,
        //   data: resp.data,
        // });
        yield put({
          type: types.UPDATE_EMAIL_SETTING_STATUS_SUCCESS,
          data: response?.data,
        });
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_EMAIL_SETTING_STATUS_FAIL });
  }
}

export function* updateFaqGroupStatusSaga({ data, navigate, faqFor }) {
  try {
    yield put({ type: types.UPDATE_FAQ_GROUP_STATUS_START });
    const response = yield call(updateFaqGroupStatusApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_FAQ_GROUP_STATUS_FAIL }); //current
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        const resp = yield call(getAllFaqGroup, { faqFor });
        yield put({
          type: types.UPDATE_FAQ_GROUP_STATUS_SUCCESS,
          data,
        });
        yield put({
          type: types.GET_ALL_FAQ_GROUP_SUCCESS,
          data: resp?.data,
        });
      }
      // olibrToast.success(response?.data?.message);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_FAQ_GROUP_STATUS_FAIL });
  }
}

export function* updateFaqStatusSaga({ data, navigate, faqFor }) {
  try {
    yield put({ type: types.UPDATE_FAQ_STATUS_START });
    const response = yield call(updateFaqStatusApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_FAQ_STATUS_FAIL }); //current
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        const resp = yield call(getAllFaqGroup, { faqFor });
        yield put({
          type: types.UPDATE_FAQ_STATUS_SUCCESS,
          data,
        });
        yield put({
          type: types.GET_ALL_FAQ_GROUP_SUCCESS,
          data: resp?.data,
        });
      }
      // olibrToast.success(response?.data?.message);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_FAQ_STATUS_FAIL });
  }
}

export function* jobApprovedOrRejectSaga({
  data,
  navigate,
  setApprovalConfirm,
  handleGetAllJob,
  pageData,
  socket,
}) {
  try {
    yield put({ type: types.JOB_APPROVED_OR_REJECT_START });
    const response = yield call(setJobApprovedOrReject, data);
    if (response.data.status === 401) {
      yield put({ type: types.JOB_APPROVED_OR_REJECT_FAIL }); //current
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        setApprovalConfirm({ isOpen: false });
        response?.data?.data?.status === "open" &&
          socket.emit("jobPost", response?.data?.data);
        yield put({
          type: types.JOB_APPROVED_OR_REJECT_SUCCESS,
          data: response?.data,
        });
      }
      olibrToast.success(response?.data?.message);
    }
  } catch (error) {
    yield put({ type: types.JOB_APPROVED_OR_REJECT_FAIL });
  }
}

export function* updateEmailTemplateSaga({ templateId, data, navigate }) {
  try {
    yield put({ type: types.UPDATE_ADMIN_TEMPLATE_LIST_START });
    const response = yield call(updateEmailTemplate, templateId, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_ADMIN_TEMPLATE_LIST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response.data.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_ADMIN_TEMPLATE_LIST_SUCCESS,
          data,
          templateId,
        });
        olibrToast.success(response?.data?.message);
        navigate("/admin/email-management");
      } else {
        olibrToast.error(response.data.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_ADMIN_TEMPLATE_LIST_FAIL });
  }
}

export function* getAllEmailTemplateSaga({ navigate }) {
  try {
    yield put({ type: types.GET_ADMIN_TEMPLATE_LIST_START });
    const response = yield call(getAllEmailTemplate);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ADMIN_TEMPLATE_LIST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ADMIN_TEMPLATE_LIST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ADMIN_TEMPLATE_LIST_FAIL });
  }
}

export function* getEmailHistorySaga({ data, pageData, navigate }) {
  try {
    yield put({ type: types.GET_ADMIN_EMAIL_HISTORY_START });
    const response = yield call(getEmailHistory, data, pageData);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ADMIN_EMAIL_HISTORY_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ADMIN_EMAIL_HISTORY_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ADMIN_EMAIL_HISTORY_FAIL });
  }
}

export function* deleteEmailTemplateSaga({ templateId, navigate }) {
  try {
    yield put({ type: types.DELETE_ADMIN_TEMPLATE_LIST_START });
    const response = yield call(deleteEmailTemplate, templateId);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_ADMIN_TEMPLATE_LIST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response.data.statusCode === "SUCCESS") {
        const templateList = yield call(getAllEmailTemplate);
        yield put({
          type: types.GET_ADMIN_TEMPLATE_LIST_SUCCESS,
          data: templateList.data,
        });
        yield put({
          type: types.DELETE_ADMIN_TEMPLATE_LIST_SUCCESS,
          data: response.data,
        });
        olibrToast.success(response.data.message);
        navigate("/admin/email-management");
      } else {
        olibrToast.error(response.data.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_ADMIN_TEMPLATE_LIST_FAIL });
  }
}

export function* addEmailTemplateSaga({ data, navigate, resetForm }) {
  try {
    yield put({ type: types.ADD_JOB_START });
    const response = yield call(addEmailTemplate, data);
    if (response.data.status === 401) {
      yield put({ type: types.POST_ADMIN_TEMPLATE_LIST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response.data.statusCode === "SUCCESS") {
        const templateList = yield call(getAllEmailTemplate);
        yield put({
          type: types.GET_ADMIN_TEMPLATE_LIST_SUCCESS,
          data: templateList.data,
        });
        yield put({
          type: types.POST_ADMIN_TEMPLATE_LIST_SUCCESS,
          data: response.data,
        });
        olibrToast.success(response.data.message);
        resetForm();
        navigate("/admin/email-management");
      } else {
        olibrToast.error(response.data.message);
      }
    }
  } catch (error) {
    yield put({ type: types.POST_ADMIN_TEMPLATE_LIST_FAIL });
  }
}

export function* getEmailTemplateByIdSaga({ templateId, navigate }) {
  try {
    yield put({ type: types.GET_ADMIN_TEMPLATE_BY_ID_START });
    const response = yield call(getEmailTemplateById, templateId);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ADMIN_TEMPLATE_BY_ID_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ADMIN_TEMPLATE_BY_ID_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ADMIN_TEMPLATE_BY_ID_FAIL });
  }
}

export function* getAdminDevAcademicSaga({ navigate, data }) {
  try {
    yield put({ type: types.GET_ADMIN_DEV_ACADEMIC_START });
    const response = yield call(getAdminDevAcademic, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ADMIN_DEV_ACADEMIC_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ADMIN_DEV_ACADEMIC_SUCCESS,
        data: response?.data?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ADMIN_DEV_ACADEMIC_FAIL });
  }
}

export function* updateAdminDevAcademicSaga({
  navigate,
  devId,
  data,
  setDetails,
  resetForm,
}) {
  try {
    yield put({ type: types.UPDATE_ADMIN_DEV_ACADEMIC_START });
    const response = yield call(updateAdminDevAcademic, devId, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_ADMIN_DEV_ACADEMIC_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_ADMIN_DEV_ACADEMIC_SUCCESS,
          data: response?.data,
        });
        // olibrToast.success(response?.data?.message);
        resetForm();
        setDetails({ modal: false, edit: false });
      } else {
        yield put({
          type: types.UPDATE_ADMIN_DEV_ACADEMIC_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_ADMIN_DEV_ACADEMIC_FAIL });
  }
}

export function* saveAdminDevAcademicSaga({
  navigate,
  data,
  setDetails,
  resetForm,
}) {
  try {
    yield put({ type: types.SAVE_ADMIN_DEV_ACADEMIC_START });
    const response = yield call(saveAdminDevAcademic, data);
    if (response.data.status === 401) {
      yield put({ type: types.SAVE_ADMIN_DEV_ACADEMIC_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.SAVE_ADMIN_DEV_ACADEMIC_SUCCESS,
          data: response?.data,
        });
        // olibrToast.success(response?.data?.message);
        resetForm();
        setDetails({ modal: false, edit: false });
      } else {
        yield put({
          type: types.SAVE_ADMIN_DEV_ACADEMIC_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.SAVE_ADMIN_DEV_ACADEMIC_FAIL });
  }
}

export function* deleteAdminDevAcademicSaga({ navigate, data, devId }) {
  try {
    yield put({ type: types.DELETE_ADMIN_DEV_ACADEMIC_START });
    const response = yield call(deleteAdminDevAcademic, data, devId);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_ADMIN_DEV_ACADEMIC_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.DELETE_ADMIN_DEV_ACADEMIC_SUCCESS,
          data: data?.id,
        });
        olibrToast.success(response?.data?.message);
      } else {
        yield put({
          type: types.DELETE_ADMIN_DEV_ACADEMIC_SUCCESS,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_ADMIN_DEV_ACADEMIC_FAIL });
  }
}
export function* postBlogCatSaga({ navigate, data, resetForm }) {
  try {
    yield put({ type: types.POST_BLOG_CAT_START });
    const response = yield call(postBlogCatApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.POST_BLOG_CAT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.POST_BLOG_CAT_SUCCESS,
          data: response?.data?.data,
        });
        olibrToast.success(response?.data?.message);
        resetForm();
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.POST_BLOG_CAT_FAIL });
  }
}

export function* deleteBlogCatSaga({ categoryId, setModal, navigate }) {
  try {
    yield put({ type: types.DELETE_BLOG_CAT_START });
    const response = yield call(deleteBlogCatApi, categoryId);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_BLOG_CAT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({ type: types.DELETE_BLOG_CAT_SUCCESS, categoryId });
        olibrToast.success(response?.data?.message);
        setModal(false);
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_BLOG_CAT_FAIL });
  }
}

export function* updateBlogCatSaga({ categoryId, data, setModal, navigate }) {
  try {
    yield put({ type: types.UPDATE_BLOG_CAT_START });
    const response = yield call(updateBlogCatApi, categoryId, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_BLOG_CAT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_BLOG_CAT_SUCCESS,
          data: response?.data?.data,
          categoryId,
        });
        olibrToast.success(response?.data?.message);
        setModal(false);
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_BLOG_CAT_FAIL });
  }
}

export function* getAdminDevCertificationSaga({ navigate, data }) {
  try {
    yield put({ type: types.GET_ADMIN_DEV_CERTIFICATION_START });
    const response = yield call(getAdminDevCertification, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ADMIN_DEV_CERTIFICATION_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ADMIN_DEV_CERTIFICATION_SUCCESS,
        data: response?.data?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ADMIN_DEV_CERTIFICATION_FAIL });
  }
}

export function* saveAdminDevCertificationSaga({
  navigate,
  data,
  setDetails,
  resetForm,
}) {
  try {
    yield put({ type: types.SAVE_ADMIN_DEV_CERTIFICATION_START });
    const response = yield call(saveAdminDevCertification, data);
    if (response.data.status === 401) {
      yield put({ type: types.SAVE_ADMIN_DEV_CERTIFICATION_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.SAVE_ADMIN_DEV_CERTIFICATION_SUCCESS,
          data: response?.data,
        });
        // olibrToast.success(response?.data?.message);
        setDetails({ modal: false, edit: false });
        resetForm();
      } else {
        yield put({
          type: types.SAVE_ADMIN_DEV_CERTIFICATION_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.SAVE_ADMIN_DEV_CERTIFICATION_FAIL });
  }
}

export function* updateAdminDevCertificationSaga({
  navigate,
  devId,
  data,
  setDetails,
  resetForm,
}) {
  try {
    yield put({ type: types.UPDATE_ADMIN_DEV_CERTIFICATION_START });
    const response = yield call(updateAdminDevCertification, devId, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_ADMIN_DEV_CERTIFICATION_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_ADMIN_DEV_CERTIFICATION_SUCCESS,
          data: response?.data,
        });
        // olibrToast.success(response?.data?.message);
        setDetails({ modal: false, edit: false });
        resetForm();
      } else {
        yield put({
          type: types.UPDATE_ADMIN_DEV_CERTIFICATION_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_ADMIN_DEV_CERTIFICATION_FAIL });
  }
}

export function* deleteAdminDevCertificationSaga({ navigate, data, devId }) {
  try {
    yield put({ type: types.DELETE_ADMIN_DEV_CERTIFICATION_START });
    const response = yield call(deleteAdminDevCertification, data, devId);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_ADMIN_DEV_CERTIFICATION_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.DELETE_ADMIN_DEV_CERTIFICATION_SUCCESS,
          data: data?.id,
        });
        // olibrToast.success(response?.data?.message);
      } else {
        yield put({
          type: types.DELETE_ADMIN_DEV_CERTIFICATION_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_ADMIN_DEV_CERTIFICATION_FAIL });
  }
}

export function* postBlogNewSaga({ data, navigate }) {
  try {
    yield put({ type: types.POST_BLOG_NEW_START });
    const response = yield call(postBlogNewApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.POST_BLOG_NEW_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({ type: types.POST_BLOG_NEW_SUCCESS, data: response.data });
        olibrToast.success(response?.data?.message);
        navigate("/admin/blog");
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.POST_BLOG_NEW_FAIL });
      }
    }
  } catch (error) {
    if (error?.response?.status === 413) {
      olibrToast?.error("Please reduce the image size and size of the Blog");
    }
    yield put({ type: types.POST_BLOG_NEW_FAIL });
  }
}

export function* deleteBlogNewSaga({ blogId, navigate }) {
  try {
    yield put({ type: types.DELETE_BLOG_NEW_START });
    const response = yield call(deleteBlogNewApi, blogId);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_BLOG_NEW_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({ type: types.DELETE_BLOG_NEW_SUCCESS, data: response.data });
        olibrToast.success(response?.data?.message);
        navigate("/admin/blog");
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_BLOG_NEW_FAIL });
  }
}

export function* updateBlogNewSaga({ blogId, data, navigate }) {
  try {
    yield put({ type: types.UPDATE_BLOG_NEW_START });
    const response = yield call(updateBlogNewApi, blogId, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_BLOG_NEW_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({ type: types.UPDATE_BLOG_NEW_SUCCESS, data: response.data });
        olibrToast.success(response?.data?.message);
        navigate("/admin/blog");
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    if (error?.response?.status === 413) {
      olibrToast?.error("Please reduce the image size and size of the Blog");
    }
    yield put({ type: types.UPDATE_BLOG_NEW_FAIL });
  }
}

export function* getAdminDeveloperSocialProfileSaga({ navigate, data }) {
  try {
    yield put({ type: types.GET_ADMIN_DEV_SOCIAL_PROFILE_START });
    const response = yield call(getAdminDevSocialProfile, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ADMIN_DEV_SOCIAL_PROFILE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ADMIN_DEV_SOCIAL_PROFILE_SUCCESS,
        data: response?.data?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ADMIN_DEV_ACADEMIC_FAIL });
  }
}

export function* saveAdminDeveloperSocialProfileSaga({ navigate, data }) {
  try {
    yield put({ type: types.SAVE_ADMIN_DEV_SOCIAL_PROFILE_START });
    const response = yield call(saveAdminDevSocialProfile, data);
    if (response.data.status === 401) {
      yield put({ type: types.SAVE_ADMIN_DEV_SOCIAL_PROFILE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.SAVE_ADMIN_DEV_SOCIAL_PROFILE_SUCCESS,
          data: response?.data,
        });
        // const getResponse = yield call(getAdminDevSocialProfile, data);
        // yield put({
        //   type: types.GET_ADMIN_DEV_SOCIAL_PROFILE_SUCCESS,
        //   data: getResponse?.data?.data,
        // });
        // olibrToast.success(response?.data?.message);
      } else {
        yield put({
          type: types.SAVE_ADMIN_DEV_SOCIAL_PROFILE_FAIL,
          data: response?.data,
        });
        // olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.SAVE_ADMIN_DEV_SOCIAL_PROFILE_FAIL });
  }
}

export function* updateAdminDeveloperSocialProfileSaga({
  navigate,
  devId,
  data,
}) {
  try {
    yield put({ type: types.UPDATE_ADMIN_DEV_SOCIAL_PROFILE_START });
    const response = yield call(updateAdminDevSocialProfile, devId, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_ADMIN_DEV_SOCIAL_PROFILE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_ADMIN_DEV_SOCIAL_PROFILE_SUCCESS,
          data: response?.data,
        });
        const getResponse = yield call(getAdminDevSocialProfile, devId);
        yield put({
          type: types.GET_ADMIN_DEV_SOCIAL_PROFILE_SUCCESS,
          data: getResponse?.data?.data,
        });
        // olibrToast.success(response?.data?.message);
      } else {
        yield put({
          type: types.UPDATE_ADMIN_DEV_SOCIAL_PROFILE_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_ADMIN_DEV_SOCIAL_PROFILE_FAIL });
  }
}

export function* getAdminDevEmployementSaga({ navigate, data }) {
  try {
    yield put({ type: types.GET_ADMIN_DEV_EMPLOYEMENT_START });
    const response = yield call(getAdminDevEmployement, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ADMIN_DEV_EMPLOYEMENT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ADMIN_DEV_EMPLOYEMENT_SUCCESS,
        data: response?.data?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ADMIN_DEV_EMPLOYEMENT_FAIL });
  }
}
export function* saveAdminDevEmployementSaga({
  navigate,
  data,
  setDetails,
  resetForm,
  devId,
}) {
  try {
    yield put({ type: types.SAVE_ADMIN_DEV_EMPLOYEMENT_START });
    const response = yield call(saveAdminDevEmployement, data);
    if (response.data.status === 401) {
      yield put({ type: types.SAVE_ADMIN_DEV_EMPLOYEMENT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        const resp = yield call(getAdminDevEmployement, devId);
        yield put({
          type: types.SAVE_ADMIN_DEV_EMPLOYEMENT_SUCCESS,
          data: response?.data,
        });
        yield put({
          type: types.GET_ADMIN_DEV_EMPLOYEMENT_SUCCESS,
          data: resp?.data?.data,
        });
        // olibrToast.success(response?.data?.message);
        setDetails({ modal: false, edit: false });
        resetForm();
      } else {
        yield put({
          type: types.SAVE_ADMIN_DEV_EMPLOYEMENT_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.SAVE_ADMIN_DEV_SOCIAL_PROFILE_FAIL });
  }
}
export function* updateAdminDevEmployementSaga({
  navigate,
  devId,
  data,
  setDetails,
  resetForm,
}) {
  try {
    yield put({ type: types.UPDATE_ADMIN_DEV_EMPLOYEMENT_START });
    const response = yield call(updateAdminDevEmployement, devId, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_ADMIN_DEV_EMPLOYEMENT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        const resp = yield call(getAdminDevEmployement, devId);
        yield put({
          type: types.UPDATE_ADMIN_DEV_EMPLOYEMENT_SUCCESS,
          data: response?.data,
        });
        yield put({
          type: types.GET_ADMIN_DEV_EMPLOYEMENT_SUCCESS,
          data: resp?.data?.data,
        });
        // olibrToast.success(response?.data?.message);
        setDetails({ modal: false, edit: false });
        resetForm();
      } else {
        yield put({
          type: types.UPDATE_ADMIN_DEV_EMPLOYEMENT_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_ADMIN_DEV_EMPLOYEMENT_FAIL });
  }
}

export function* deleteAdminDevEmployementSaga({ navigate, data, devId }) {
  try {
    yield put({ type: types.DELETE_ADMIN_DEV_EMPLOYEMENT_START });
    const response = yield call(deleteAdminDevEmployement, data, devId);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_ADMIN_DEV_EMPLOYEMENT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.DELETE_ADMIN_DEV_EMPLOYEMENT_SUCCESS,
          data: data?.id,
        });
        olibrToast.success(response?.data?.message);
      } else {
        yield put({
          type: types.DELETE_ADMIN_DEV_EMPLOYEMENT_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_ADMIN_DEV_EMPLOYEMENT_FAIL });
  }
}

export function* getAdminDevProjectSaga({ navigate, data }) {
  try {
    yield put({ type: types.GET_ADMIN_DEV_PROJECT_START });
    const response = yield call(getAdminDevProject, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ADMIN_DEV_PROJECT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ADMIN_DEV_PROJECT_SUCCESS,
        data: response?.data?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ADMIN_DEV_PROJECT_FAIL });
  }
}

export function* saveAdminDevProjectSaga({
  navigate,
  data,
  setDetails,
  resetForm,
  isAdditionProject,
}) {
  try {
    yield put({ type: types.SAVE_ADMIN_DEV_PROJECT_START });
    const response = yield call(saveAdminDevProject, data, isAdditionProject);
    if (response.data.status === 401) {
      yield put({ type: types.SAVE_ADMIN_DEV_PROJECT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.SAVE_ADMIN_DEV_PROJECT_SUCCESS,
          data: response?.data,
        });
        // olibrToast.success(response?.data?.message);
        setDetails([]);
        resetForm();
      } else {
        yield put({
          type: types.SAVE_ADMIN_DEV_PROJECT_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.SAVE_ADMIN_DEV_PROJECT_FAIL });
  }
}

export function* updateAdminDevProjectSaga({
  navigate,
  data,
  setDetails,
  resetForm,
  isAdditionProject,
}) {
  try {
    yield put({ type: types.UPDATE_ADMIN_DEV_PROJECT_START });
    const response = yield call(updateAdminDevProject, data, isAdditionProject);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_ADMIN_DEV_PROJECT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_ADMIN_DEV_PROJECT_SUCCESS,
          data: response?.data,
        });
        // olibrToast.success(response?.data?.message);
        setDetails([]);
        resetForm();
      } else {
        yield put({
          type: types.UPDATE_ADMIN_DEV_PROJECT_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_ADMIN_DEV_PROJECT_FAIL });
  }
}
export function* deleteAdminDevProjectSaga({
  navigate,
  data,
  devId,
  isAdditionProject,
  cb,
}) {
  try {
    yield put({ type: types.DELETE_ADMIN_DEV_PROJECT_START });
    const response = yield call(
      deleteAdminDevProject,
      data,
      devId,
      isAdditionProject
    );
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_ADMIN_DEV_EMPLOYEMENT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.DELETE_ADMIN_DEV_PROJECT_SUCCESS,
          data: data?.id,
        });
        olibrToast.success(response?.data?.message);
        cb && cb();
      } else {
        yield put({
          type: types.DELETE_ADMIN_DEV_PROJECT_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_ADMIN_DEV_PROJECT_FAIL });
  }
}

export function* finalResumeCallSaga() {}
export function* getAdminDevToolsSaga({ navigate, data }) {
  try {
    yield put({ type: types.GET_ADMIN_DEV_TOOLS_START });
    const response = yield call(getAdminDevTools, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ADMIN_DEV_TOOLS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ADMIN_DEV_TOOLS_SUCCESS,
        data: response?.data?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ADMIN_DEV_TOOLS_FAIL });
  }
}
export function* saveAdminDevToolsSaga({
  navigate,
  devId,
  data,
  setDetails,
  resetForm,
}) {
  try {
    yield put({ type: types.SAVE_ADMIN_DEV_TOOLS_START });
    const response = yield call(saveAdminDevTools, devId, data);
    if (response.data.status === 401) {
      yield put({ type: types.SAVE_ADMIN_DEV_TOOLS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.SAVE_ADMIN_DEV_TOOLS_SUCCESS,
          data: response?.data,
        });
        // olibrToast.success(response?.data?.message);
        setDetails({});
        resetForm();
      } else {
        yield put({
          type: types.SAVE_ADMIN_DEV_TOOLS_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.SAVE_ADMIN_DEV_TOOLS_FAIL });
  }
}
export function* updateAdminDevToolsSaga({
  navigate,
  devId,
  data,
  setDetails,
  resetForm,
}) {
  try {
    yield put({ type: types.UPDATE_ADMIN_DEV_TOOLS_START });
    const response = yield call(updateAdminDevTools, devId, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_ADMIN_DEV_TOOLS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_ADMIN_DEV_TOOLS_SUCCESS,
          data: response?.data,
        });
        // olibrToast.success(response?.data?.message);
        setDetails({});
        resetForm();
      } else {
        yield put({
          type: types.UPDATE_ADMIN_DEV_TOOLS_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_ADMIN_DEV_TOOLS_FAIL });
  }
}
export function* deleteAdminDevToolsSaga({ navigate, data, devId }) {
  try {
    yield put({ type: types.DELETE_ADMIN_DEV_TOOLS_START });
    const response = yield call(deleteAdminDevTools, data, devId);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_ADMIN_DEV_TOOLS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.DELETE_ADMIN_DEV_TOOLS_SUCCESS,
          data: data?.id,
        });
        olibrToast.success(response?.data?.message);
      } else {
        yield put({
          type: types.DELETE_ADMIN_DEV_TOOLS_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_ADMIN_DEV_TOOLS_FAIL });
  }
}

export function* getAdminDevSkillsSaga({ navigate, data }) {
  try {
    yield put({ type: types.GET_ADMIN_DEV_SKILLS_START });
    const response = yield call(getAdminDevSkills, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ADMIN_DEV_SKILLS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ADMIN_DEV_SKILLS_SUCCESS,
        data: response?.data?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ADMIN_DEV_SKILLS_FAIL });
  }
}
export function* saveAdminDevSkillsSaga({
  navigate,
  data,
  setDetails,
  resetForm,
}) {
  try {
    yield put({ type: types.SAVE_ADMIN_DEV_SKILLS_START });
    const response = yield call(saveAdminDevSkills, data);
    if (response.data.status === 401) {
      yield put({ type: types.SAVE_ADMIN_DEV_SKILLS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.SAVE_ADMIN_DEV_SKILLS_SUCCESS,
          data: response?.data,
        });
        // olibrToast.success(response?.data?.message);
        setDetails({});
        resetForm();
      } else {
        yield put({
          type: types.SAVE_ADMIN_DEV_SKILLS_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.SAVE_ADMIN_DEV_SKILLS_FAIL });
  }
}
export function* updateAdminDevSkillsSaga({
  navigate,
  devId,
  data,
  setDetails,
  resetForm,
}) {
  try {
    yield put({ type: types.UPDATE_ADMIN_DEV_SKILLS_START });
    const response = yield call(updateAdminDevSkills, devId, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_ADMIN_DEV_SKILLS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_ADMIN_DEV_SKILLS_SUCCESS,
          data: response?.data,
        });
        // olibrToast.success(response?.data?.message);
        setDetails({});
        resetForm();
      } else {
        yield put({
          type: types.UPDATE_ADMIN_DEV_SKILLS_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_ADMIN_DEV_SKILLS_FAIL });
  }
}
export function* deleteAdminDevSkillsSaga({ navigate, data, devId }) {
  try {
    yield put({ type: types.DELETE_ADMIN_DEV_SKILLS_START });
    const response = yield call(deleteAdminDevSkills, data, devId);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_ADMIN_DEV_SKILLS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.DELETE_ADMIN_DEV_SKILLS_SUCCESS,
          data: data?.id,
        });
        olibrToast.success(response?.data?.message);
      } else {
        yield put({
          type: types.DELETE_ADMIN_DEV_SKILLS_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_ADMIN_DEV_SKILLS_FAIL });
  }
}

export function* getAdminDevAchievementSaga({ navigate, data }) {
  try {
    yield put({ type: types.GET_ADMIN_DEV_ACHIEVEMENT_START });
    const response = yield call(getAdminDevAchievement, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ADMIN_DEV_ACHIEVEMENT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ADMIN_DEV_ACHIEVEMENT_SUCCESS,
        data: response?.data?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ADMIN_DEV_ACHIEVEMENT_FAIL });
  }
}
export function* saveAdminDevAchievementSaga({
  navigate,
  data,
  setDetails,
  resetForm,
}) {
  try {
    yield put({ type: types.SAVE_ADMIN_DEV_ACHIEVEMENT_START });
    const response = yield call(saveAdminDevAchievement, data);
    if (response.data.status === 401) {
      yield put({ type: types.SAVE_ADMIN_DEV_ACADEMIC_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.SAVE_ADMIN_DEV_ACHIEVEMENT_SUCCESS,
          data: response?.data,
        });
        // olibrToast.success(response?.data?.message);
        setDetails({});
        resetForm();
      } else {
        yield put({
          type: types.SAVE_ADMIN_DEV_ACHIEVEMENT_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.SAVE_ADMIN_DEV_ACHIEVEMENT_FAIL });
  }
}
export function* updateAdminDevAchievementSaga({
  navigate,
  devId,
  data,
  setDetails,
  resetForm,
}) {
  try {
    yield put({ type: types.UPDATE_ADMIN_DEV_ACHIEVEMENT_START });
    const response = yield call(updateAdminDevAchievement, devId, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_ADMIN_DEV_ACHIEVEMENT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_ADMIN_DEV_ACHIEVEMENT_SUCCESS,
          data: response?.data,
        });
        // olibrToast.success(response?.data?.message);
        setDetails({});
        resetForm();
      } else {
        yield put({
          type: types.UPDATE_ADMIN_DEV_ACHIEVEMENT_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_ADMIN_DEV_ACHIEVEMENT_FAIL });
  }
}
export function* deleteAdminDevAchievementSaga({ navigate, data, devId }) {
  try {
    yield put({ type: types.DELETE_ADMIN_DEV_ACHIEVEMENT_START });
    const response = yield call(deleteAdminDevAchievement, data, devId);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_ADMIN_DEV_ACHIEVEMENT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.DELETE_ADMIN_DEV_ACHIEVEMENT_SUCCESS,
          data: data?.id,
        });
        olibrToast.success(response?.data?.message);
      } else {
        yield put({
          type: types.DELETE_ADMIN_DEV_ACHIEVEMENT_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_ADMIN_DEV_ACHIEVEMENT_FAIL });
  }
}

export function* updateAdminDevShortDescSaga({
  navigate,
  devId,
  data,
  closeModal,
}) {
  try {
    yield put({ type: types.UPDATE_ADMIN_DEV_SHORT_DESC_START });
    const response = yield call(updateAdminDevShortDesc, devId, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_ADMIN_DEV_SHORT_DESC_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_ADMIN_DEV_SHORT_DESC_SUCCESS,
          data: data,
        });
        olibrToast.success(response?.data?.message);
        closeModal();
      } else {
        yield put({
          type: types.UPDATE_ADMIN_DEV_SHORT_DESC_FAIL,
          data: response?.data,
        });
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_ADMIN_DEV_SHORT_DESC_FAIL });
  }
}

export function* approvalUpdatesAcceptRejectSaga({ navigate, data }) {
  try {
    yield put({
      type: types.PROFILE_UPDATE_REQUEST_APPROVE_REJECT_START,
      data,
    });
    const response = data?.feedback
      ? yield call(approvalUpdatesReject, data)
      : yield call(approvalUpdatesAccept, data);
    if (response.data.status === 401) {
      yield put({ type: types.PROFILE_UPDATE_REQUEST_APPROVE_REJECT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({ type: types.PROFILE_UPDATE_REQUEST_APPROVE_REJECT_SUCCESS });
      olibrToast?.success(response?.data?.message);
    }
  } catch (error) {
    yield put({ type: types.PROFILE_UPDATE_REQUEST_APPROVE_REJECT_FAIL });
  }
}

export function* adminResumeparseSaga({ data, navigate, devId, setResume }) {
  try {
    yield put({ type: types.RESUME_PARSING_ADMIN_START });
    const response = yield call(resumeParsingAdmin, data, devId);
    if (response.data.status === 401) {
      yield put({ type: types.RESUME_PARSING_ADMIN_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response.data.statusValue === 200) {
        olibrToast.success(response.data.message);
        setResume(response?.data?.resume);
        yield put({
          type: types.RESUME_PARSING_ADMIN_SUCCESS,
          data: response?.data,
        });
      } else {
        olibrToast.error(response.data.message);
      }
    }
  } catch (error) {
    yield put({ type: types.RESUME_PARSING_ADMIN_FAIL });
  }
}

export function* verifyDevEmailSaga({ data, navigate, setModal, setContent }) {
  try {
    yield put({ type: types.VERIFY_DEV_EMAIL_START });
    const response = yield call(verifyDevEmailApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.VERIFY_DEV_EMAIL_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        setContent({
          registered: response?.data?.verifiedIds,
          unregistered: response?.data?.unRegisteredEmail,
        });
        setModal(true);
        yield put({
          type: types.VERIFY_DEV_EMAIL_SUCCESS,
          data: response?.data,
        });
        // let str = ""
        // if (response?.data?.totalUnregisterd !== 0) {
        //   response?.data?.unRegisteredEmail?.forEach(item => {
        //     str += (item + '\n');
        //   })
        //   olibrToast.error(`Sorry, Couldn't Verify The following emails\n ${str}`);
        // }
        // else {
        //   olibrToast.success("Email Verification Process Complete");
        // }
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.VERIFY_DEV_EMAIL_FAIL });
  }
}

export function* approveDevEmailSaga({ data, navigate, setContent, setModal }) {
  try {
    yield put({ type: types.APPROVE_DEV_EMAIL_START });
    const response = yield call(approveDevEmailApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.APPROVE_DEV_EMAIL_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        setContent({
          registered: response?.data?.verifiedIds,
          unregistered: response?.data?.unRegisteredEmail,
          uncompleteStep: response?.data?.notStepComplete,
        });
        setModal(true);
        yield put({
          type: types.APPROVE_DEV_EMAIL_SUCCESS,
          data: response?.data,
        });
        // let str = ""
        // if (response?.data?.totalUnregisterd !== 0) {
        //   response?.data?.unRegisteredEmail?.forEach(item => {
        //     str += (item + '\n');
        //   })
        //   olibrToast.error(`Sorry, Couldn't Approve The following emails\n ${str}`);
        // }
        // olibrToast.success("Profile Approval Process Complete");
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.APPROVE_DEV_EMAIL_FAIL });
  }
}

export function* unapproveDevEmailSaga({
  data,
  navigate,
  setContent,
  setModal,
}) {
  try {
    yield put({ type: types.UNAPPROVE_DEV_EMAIL_START });
    const response = yield call(unapproveDevEmailApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.UNAPPROVE_DEV_EMAIL_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        setContent({
          registered: response?.data?.updatedIds,
          unregistered: response?.data?.unRegisteredEmail,
          uncompleteStep: response?.data?.alreadyUnapproved,
        });
        setModal(true);
        yield put({
          type: types.UNAPPROVE_DEV_EMAIL_SUCCESS,
          data: response?.data,
        });
        // let str = ""
        // if (response?.data?.totalUnregisterd !== 0) {
        //   response?.data?.unRegisteredEmail?.forEach(item => {
        //     str += (item + '\n');
        //   })
        //   olibrToast.error(`Sorry, Couldn't Approve The following emails\n ${str}`);
        // }
        // olibrToast.success("Profile Approval Process Complete");
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UNAPPROVE_DEV_EMAIL_FAIL });
  }
}

export function* updateAdminJobStatus({ navigate, status }) {
  try {
    yield put({ type: types.UPDATE_ADMIN_JOB_STATUS_START });
    const response = yield call(updateAdminJobStatusApi, status);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
      yield put({ type: types.UPDATE_ADMIN_JOB_STATUS_FAIL });
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({ type: types.UPDATE_ADMIN_JOB_STATUS_SUCCESS, status });
        olibrToast.success(response?.data?.message);
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.UPDATE_ADMIN_JOB_STATUS_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_ADMIN_JOB_STATUS_FAIL });
  }
}

export function* getQueryListSaga({ navigate, pageData }) {
  try {
    yield put({ type: types.GET_QUERIES_LIST_START });
    const response = yield call(getQueriesListApi, pageData);
    if (response.data.status === 401) {
      yield put({ type: types.GET_QUERIES_LIST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusText === "OK") {
        yield put({
          type: types.GET_QUERIES_LIST_SUCCESS,
          data: response.data,
        });
      } else {
        yield put({ type: types.GET_QUERIES_LIST_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.GET_QUERIES_LIST_FAIL });
  }
}

export function* deleteQuerySaga({ id, pageData, navigate }) {
  try {
    yield put({ type: types.DELETE_QUERY_START });
    const response = yield call(deleteQueryApi, id);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_QUERY_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.DELETE_QUERY_SUCCESS,
          data: response.data,
        });
        olibrToast.success(response?.data?.message);
        const resAllTool = yield call(getQueriesListApi, pageData);
        yield put({
          type: types.GET_QUERIES_LIST_SUCCESS,
          data: resAllTool?.data,
        });
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_QUERY_FAIL });
  }
}

export function* updateQuerySaga({ data, pageData, navigate }) {
  try {
    yield put({ type: types.UPDATE_QUERY_START });
    const response = yield call(updateQueryApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_QUERY_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.UPDATE_QUERY_SUCCESS,
          data: response?.data,
        });
        olibrToast.success(response?.data?.message);
        const resAllTool = yield call(getQueriesListApi, pageData);
        yield put({
          type: types.GET_QUERIES_LIST_SUCCESS,
          data: resAllTool?.data,
        });
      } else {
        yield put({
          type: types.UPDATE_QUERY_FAIL,
          data: response?.data,
        });
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_QUERY_FAIL });
  }
}

export function* getEnglishResultSaga({ data, navigate }) {
  try {
    yield put({ type: types.GET_ADMIN_DEV_ENGLISH_RESULT_START });
    const response = yield call(getEnglishResultAdmin, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ADMIN_DEV_ENGLISH_RESULT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.status === 200) {
        yield put({
          type: types.GET_ADMIN_DEV_ENGLISH_RESULT_SUCCESS,
          data: response?.data?.data,
        });
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.GET_ADMIN_DEV_ENGLISH_RESULT_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.GET_ADMIN_DEV_ENGLISH_RESULT_FAIL });
  }
}

export function* updateAdminDevEnglishSaga({ data, navigate }) {
  try {
    yield put({ type: types.UPDATE_ADMIN_DEV_ENGLISH_RESULT_START });
    const response = yield call(saveEnglishResultAdmin, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_ADMIN_DEV_ENGLISH_RESULT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.status === 200) {
        yield put({
          type: types.UPDATE_ADMIN_DEV_ENGLISH_RESULT_SUCCESS,
          status: response?.data?.data.status,
          correct: response?.data?.data.correct,
        });
        olibrToast.success(response?.data?.message);
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.UPDATE_ADMIN_DEV_ENGLISH_RESULT_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_ADMIN_DEV_ENGLISH_RESULT_FAIL });
  }
}

export function* getAdminStackOrSkillSaga({ navigate, data }) {
  try {
    yield put({ type: types.GET_ADMIN_STACK_SKILL_START });
    const response = yield call(getAdminSkillOrStack, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ADMIN_STACK_SKILL_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.status === 200) {
        yield put({
          type: types.GET_ADMIN_STACK_SKILL_SUCCESS,
          data: response?.data?.data,
        });
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.GET_ADMIN_STACK_SKILL_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.GET_ADMIN_STACK_SKILL_SUCCESS });
  }
}

export function* getAdminDevSkillResultsSaga({ email, navigate }) {
  try {
    yield put({ type: types.GET_ADMIN_DEV_SKILL_RESULT_START });
    const response = yield call(getAdminDevSkillResult, email);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ADMIN_DEV_SKILL_RESULT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ADMIN_DEV_SKILL_RESULT_SUCCESS,
        developerName: response?.data?.developerName,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ADMIN_DEV_SKILL_RESULT_FAIL });
  }
}

export function* updateAdminTestSkillSaga({ navigate, data }) {
  try {
    yield put({ type: types.UPDATE_ADMIN_SKILL_TEST_START });
    const response = yield call(updateAdminSkillTest, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_ADMIN_SKILL_TEST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.status === 200) {
        olibrToast.success(response?.data?.message);
        yield put({ type: types.UPDATE_ADMIN_SKILL_TEST_SUCCESS });
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.UPDATE_ADMIN_SKILL_TEST_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_ADMIN_SKILL_TEST_FAIL });
  }
}

export function* saveAdminCreateTest({ navigate, data }) {
  try {
    yield put({ type: types.CREATE_SKILL_TEST_START });
    const response = yield call(saveCreateSkills, data);
    if (response.data.status === 401) {
      yield put({ type: types.CREATE_SKILL_TEST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        olibrToast.success(response?.data?.message);
        yield put({
          type: types.CREATE_SKILL_TEST_SUCCESS,
          data: response.data,
        });
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.CREATE_SKILL_TEST_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.CREATE_SKILL_TEST_FAIL });
  }
}

export function* getAdminTestListsSaga({ navigate }) {
  try {
    yield put({ type: types.GET_SKILL_TEST_START });
    const response = yield call(getCreateSkills);
    if (response.data.status === 401) {
      yield put({ type: types.GET_SKILL_TEST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.GET_SKILL_TEST_SUCCESS,
          data: response?.data?.data,
        });
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.GET_SKILL_TEST_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.GET_SKILL_TEST_FAIL });
  }
}

export function* deleteUserProfileSaga({
  navigate,
  userId,
  closeModal,
  filter,
  pageData,
  userType,
  isRoutingDisable,
}) {
  try {
    yield put({ type: types.DELETE_USER_PROFILE_START });
    const response = yield call(deleteUserProfile, userId, userType);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_USER_PROFILE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        olibrToast.success(response?.data?.message);
        yield put({ type: types.DELETE_USER_PROFILE_SUCCESS });
        closeModal(); //active
        if (userType === "client") {
          !isRoutingDisable && navigate("/admin/client-management");
        } else {
          const devlists = yield call(
            getDeveloperLists,
            filter,
            pageData?.limit,
            pageData?.page
          );
          yield put({
            type: types.GET_DEVELOPER_LIST_SUCCESS,
            data: devlists?.data,
            limit: pageData?.limit,
            page: pageData?.page,
          });
        }
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.DELETE_USER_PROFILE_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_USER_PROFILE_FAIL });
  }
}

export function* blockUserProfileSaga({ navigate, data, closeModal }) {
  try {
    yield put({ type: types.BLOCK_USER_PROFILE_START });
    const response = yield call(blockUserProfile, data);
    if (response.data.status === 401) {
      yield put({ type: types.BLOCK_USER_PROFILE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        olibrToast.success(response?.data?.message);
        yield put({ type: types.BLOCK_USER_PROFILE_SUCCESS });
        closeModal(); //active
        navigate("/admin/client-management");
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.BLOCK_USER_PROFILE_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.BLOCK_USER_PROFILE_FAIL });
  }
}

export function* deleteProfileImageSaga({ navigate, devId, setModal }) {
  try {
    yield put({ type: types.DELETE_PROFILE_IMAGE_START });
    const response = yield call(deleteProfileImage, devId);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_PROFILE_IMAGE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({ type: types.DELETE_PROFILE_IMAGE_SUCCESS, devId });
        olibrToast.success(response?.data?.message);
        setModal(false);
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.DELETE_PROFILE_IMAGE_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_PROFILE_IMAGE_FAIL });
  }
}

export function* getSpotlightHistorySaga({ navigate }) {
  try {
    yield put({ type: types.GET_SPOTLIGHT_HISTORY_START });
    const response = yield call(getSpotlightHistoryApi);
    if (response.data.status === 401) {
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.GET_SPOTLIGHT_HISTORY_SUCCESS,
          data: response?.data,
        });
      } else {
        olibrToast.error(response.message);
      }
    }
  } catch (error) {
    yield put({ type: types.GET_SPOTLIGHT_HISTORY_FAIL });
  }
}
export function* getMaintenanceMessageSaga({ navigate }) {
  try {
    yield put({ type: types.GET_MAINTENANCE_MESSAGE_START });
    const response = yield call(getMaintenanceApi);
    if (response.data.status === 401) {
      yield put({ type: types.GET_MAINTENANCE_MESSAGE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_MAINTENANCE_MESSAGE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_MAINTENANCE_MESSAGE_FAIL });
  }
}

export function* updateMaintenanceStatusSaga({ data, navigate }) {
  try {
    yield put({ type: types.UPDATE_MAINTENANCE_STATUS_START });
    const response = yield call(updateMaintenanceStatusApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_MAINTENANCE_STATUS_FAIL }); //current
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        const resp = yield call(getMaintenanceApi);
        yield put({
          type: types.UPDATE_MAINTENANCE_STATUS_SUCCESS,
          data,
        });
        yield put({
          type: types.GET_MAINTENANCE_MESSAGE_SUCCESS,
          data: resp.data,
        });
      }
      olibrToast.success(response?.data?.message);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_MAINTENANCE_STATUS_FAIL });
  }
}

export function* addMaintenanceMessageSaga({ data, navigate, closeModal }) {
  try {
    yield put({ type: types.ADD_MAINTENANCE_MESSAGE_START });
    const response = yield call(addMaintenanceApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.ADD_MAINTENANCE_MESSAGE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        olibrToast.success(response?.data?.message);
        const resp = yield call(getMaintenanceApi);
        yield put({
          type: types.ADD_MAINTENANCE_MESSAGE_SUCCESS,
          data: response.data,
        });
        yield put({
          type: types.GET_MAINTENANCE_MESSAGE_SUCCESS,
          data: resp.data,
        });
        closeModal();
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.ADD_MAINTENANCE_MESSAGE_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.ADD_MAINTENANCE_MESSAGE_FAIL });
  }
}

export function* updateMaintenanceMessageSaga({ data, navigate, closeModal }) {
  try {
    yield put({ type: types.UPDATE_MAINTENANCE_MESSAGE_START });
    const response = yield call(updateMaintenanceApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_MAINTENANCE_MESSAGE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        olibrToast.success(response?.data?.message);
        const resp = yield call(getMaintenanceApi);
        yield put({ type: types.UPDATE_MAINTENANCE_MESSAGE_SUCCESS });
        yield put({
          type: types.GET_MAINTENANCE_MESSAGE_SUCCESS,
          data: resp.data,
        });
        closeModal();
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.UPDATE_MAINTENANCE_MESSAGE_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_MAINTENANCE_MESSAGE_FAIL });
  }
}

export function* deleteMaintenanceSaga({ data, navigate, closeModal }) {
  try {
    yield put({ type: types.DELETE_MAINTENANCE_MESSAGE_START });
    const response = yield call(deleteMaintenanceApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_MAINTENANCE_MESSAGE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        const resp = yield call(getMaintenanceApi);
        olibrToast.success(response?.data?.message);
        yield put({
          type: types.DELETE_MAINTENANCE_MESSAGE_SUCCESS,
          data: response.data,
        });
        yield put({
          type: types.GET_MAINTENANCE_MESSAGE_SUCCESS,
          data: resp.data,
        });
        closeModal();
      } else {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_MAINTENANCE_MESSAGE_FAIL });
  }
}

export function* getAllPerkAndBenefitSaga({ navigate, pageData, forType }) {
  try {
    yield put({ type: types.GET_ALL_PERK_BENEFIT_START });
    const response = yield call(getAllPerksBenefit, pageData, forType);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ALL_PERK_BENEFIT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusValue === 200) {
        yield put({
          type: types.GET_ALL_PERK_BENEFIT_SUCCESS,
          data: response?.data,
        });
      } else {
        yield put({ type: types.GET_ALL_PERK_BENEFIT_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.GET_ALL_PERK_BENEFIT_FAIL });
  }
}

export function* savePerkAndBenefitSaga({ navigate, data, close }) {
  try {
    yield put({ type: types.SAVE_PERK_BENEFIT_START });
    const response = yield call(savePerkBenefints, data);
    if (response.data.status === 401) {
      yield put({ type: types.SAVE_PERK_BENEFIT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        close();
        yield put({
          type: types.SAVE_PERK_BENEFIT_SUCCESS,
          data: response.data,
        });
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.SAVE_PERK_BENEFIT_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.SAVE_PERK_BENEFIT_FAIL });
  }
}
export function* updatePerkAndBenefitSaga({ navigate, data, id, close }) {
  try {
    yield put({ type: types.UPDATE_PERK_BENEFIT_START });
    const response = yield call(updatePerkBenefints, id, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_PERK_BENEFIT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        close();
        olibrToast.success(response?.data?.message);
        yield put({
          type: types.UPDATE_PERK_BENEFIT_SUCCESS,
          data: response?.data?.data,
          id,
        });
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.UPDATE_PERK_BENEFIT_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_PERK_BENEFIT_FAIL });
  }
}

export function* removePerkAndBenefitSaga({ navigate, data }) {
  try {
    yield put({ type: types.DELETE_PERK_BENEFIT_START });
    const response = yield call(deletePerkBenefints, data);
    if (response.data.status === 401) {
      yield put({ type: types.DELETE_PERK_BENEFIT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        yield put({
          type: types.DELETE_PERK_BENEFIT_SUCCESS,
          id: data,
        });
      } else {
        olibrToast.error(response?.data?.message);
        yield put({ type: types.DELETE_PERK_BENEFIT_FAIL });
      }
    }
  } catch (error) {
    yield put({ type: types.DELETE_PERK_BENEFIT_FAIL });
  }
}

export function* updateAssessmentNameSaga({
  data,
  skillType,
  onClose,
  navigate,
  pageData,
}) {
  try {
    yield put({ type: types.UPDATE_ASSESSMENT_NAME_START });
    const response = yield call(updateAssessmentNameApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_ASSESSMENT_NAME_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      if (response?.data?.statusCode === "SUCCESS") {
        const adminSkillList = yield call(
          getAdminSkillLists,
          skillType,
          pageData
        );
        yield put({
          type: types.GET_ADMIN_SKILL_LIST_SUCCESS,
          data: adminSkillList?.data,
        });
        yield put({
          type: types.UPDATE_ASSESSMENT_NAME_SUCCESS,
          data: response.data,
        });
        onClose();
        // olibrToast.success(response?.data?.message);
      } else if (response?.data?.statusCode === "FAIL") {
        olibrToast.error(response?.data?.message);
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_ASSESSMENT_NAME_FAIL });
  }
}

export function* getSkillCategory({ navigate }) {
  try {
    yield put({ type: types.GET_SKILL_CATEGORY_START });
    const response = yield call(getSkillCategoryApi);
    if (response.data.status === 401) {
      yield put({ type: types.GET_SKILL_CATEGORY_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_SKILL_CATEGORY_SUCCESS,
        data: response?.data?.categories,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_SKILL_CATEGORY_FAIL });
  }
}

export function* getSkillCategoryLists({ navigate, typeList }) {
  try {
    yield put({ type: types.GET_SKILL_CATEGORY_LISTS_START });
    const response = yield call(getSkillCategoryListsApi, typeList);
    if (response.data.status === 401) {
      yield put({ type: types.GET_SKILL_CATEGORY_LISTS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_SKILL_CATEGORY_LISTS_SUCESS,
        data: response?.data?.formattedSkillList,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_SKILL_CATEGORY_LISTS_FAIL });
  }
}

export function* getStackCategoryLists({ navigate }) {
  try {
    yield put({ type: types.GET_STACK_CATEGORY_LISTS_START });
    const response = yield call(getStackCategoryListsApi);
    if (response.data.status === 401) {
      yield put({ type: types.GET_STACK_CATEGORY_LISTS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_STACK_CATEGORY_LISTS_SUCESS,
        data: response?.data?.formattedSkillList,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_STACK_CATEGORY_LISTS_FAIL });
  }
}

export function* createSkillsCategory({ navigate, data, setObj }) {
  try {
    yield put({ type: types.CREATE_SKILLS_CATEGORY_START });
    const response = yield call(createSkillsCategoryApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.CREATE_SKILLS_CATEGORY_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      const cat = yield call(getSkillCategoryApi);
      yield put({
        type: types.GET_SKILL_CATEGORY_SUCCESS,
        data: cat?.data?.categories,
      });
      yield put({ type: types.CREATE_SKILLS_CATEGORY_SUCCESS });
      setObj({ name: "" });
    }
  } catch (error) {
    yield put({ type: types.CREATE_SKILLS_CATEGORY_FAIL });
  }
}

export function* updateSkillsCategory({
  navigate,
  data,
  setObj,
  skillOrStack,
}) {
  try {
    yield put({ type: types.UPDATE_SKILLS_CATEGORY_START });
    const response = yield call(updateSkillsCategoryApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_SKILLS_CATEGORY_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      const cat = yield call(getSkillCategoryApi);
      yield put({
        type: types.GET_SKILL_CATEGORY_SUCCESS,
        data: cat?.data?.categories,
      });
      if (skillOrStack == "skill") {
        const tempSkils = yield call(getSkillCategoryListsApi);
        yield put({
          type: types.GET_SKILL_CATEGORY_LISTS_SUCESS,
          data: tempSkils?.data?.formattedSkillList,
        });
      } else {
        const tempStack = yield call(getStackCategoryListsApi);
        yield put({
          type: types.GET_STACK_CATEGORY_LISTS_SUCESS,
          data: tempStack?.data?.formattedSkillList,
        });
      }
      yield put({ type: types.UPDATE_SKILLS_CATEGORY_SUCCESS });
      setObj({ name: "" });
    }
  } catch (error) {
    yield put({ type: types.UPDATE_SKILLS_CATEGORY_FAIL });
  }
}

export function* getEmailCountSaga({ navigate, filter }) {
  try {
    yield put({ type: types.GET_EMAIL_COUNT_START });
    const response = yield call(getEmailCountApi, filter);
    if (response.data.status === 401) {
      yield put({ type: types.GET_EMAIL_COUNT_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_EMAIL_COUNT_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_EMAIL_COUNT_SUCCESS });
  }
}

export function* sendJobMailSaga({ navigate, data }) {
  try {
    yield put({ type: types.SEND_JOB_MAIL_START });
    const response = yield call(sendJobMailApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.SEND_JOB_MAIL_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      olibrToast.success(response?.data?.message);
      yield put({
        type: types.SEND_JOB_MAIL_SUCCESS,
      });
    }
  } catch (error) {
    olibrToast.error(error?.response?.data);
    yield put({ type: types.SEND_JOB_MAIL_FAIL });
  }
}

export function* getClientEmailListsSaga({ navigate }) {
  try {
    yield put({ type: types.GET_CLIENT_EMAIL_START });
    const response = yield call(getEmailListsApi);
    if (response.data.status === 401) {
      yield put({ type: types.GET_CLIENT_EMAIL_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_CLIENT_EMAIL_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    olibrToast.error(error?.response?.data);
    yield put({ type: types.SEND_JOB_MAIL_FAIL });
  }
}

export function* getJobTitleListsSaga({ navigate, clientId }) {
  try {
    yield put({ type: types.GET_JOB_TITLE_START });
    const response = yield call(getJobTitleListsApi, clientId);
    if (response.data.status === 401) {
      yield put({ type: types.GET_JOB_TITLE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_JOB_TITLE_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    olibrToast.error(error?.response?.data);
    yield put({ type: types.GET_JOB_TITLE_FAIL });
  }
}

export function* getPlanListsSaga({ navigate, data }) {
  try {
    yield put({ type: types.GET_PLAN_LISTS_START });
    const response = yield call(getPlanListsApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_PLAN_LISTS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_PLAN_LISTS_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    olibrToast.error(error?.response?.data);
    yield put({ type: types.GET_PLAN_LISTS_FAIL });
  }
}

export function* getFeatureListsSaga({ navigate, data }) {
  try {
    yield put({ type: types.GET_FEATURE_LIST_START });
    const response = yield call(getFeatureListsApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_FEATURE_LIST_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_FEATURE_LIST_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    olibrToast.error(error?.response?.data);
    yield put({ type: types.GET_FEATURE_LIST_FAIL });
  }
}

export function* getAddOnListsSaga({ navigate, data }) {
  try {
    yield put({ type: types.GET_ADD_ON_LISTS_START });
    const response = yield call(getAddOnListsApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_ADD_ON_LISTS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_ADD_ON_LISTS_SUCCESS,
        data: response?.data?.data,
      });
    }
  } catch (error) {
    olibrToast.error(error?.response?.data);
    yield put({ type: types.GET_ADD_ON_LISTS_FAIL });
  }
}

export function* addFeaturePlanSaga({ navigate, data, cb }) {
  try {
    yield put({ type: types.ADD_PRICING_FEATURE_START });
    const response = yield call(addPlanFeatureApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.ADD_PRICING_FEATURE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      cb && cb();
      yield put({
        type: types.ADD_PRICING_FEATURE_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    olibrToast.error(error?.response?.data);
    yield put({ type: types.ADD_PRICING_FEATURE_FAIL });
  }
}

export function* updateFeaturePlanSaga({ navigate, data, cb }) {
  try {
    yield put({ type: types.UPDATE_PRICING_FEATURE_START });
    const response = yield call(updatePlanFeatureApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_PRICING_FEATURE_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      cb && cb();
      yield put({
        type: types.UPDATE_PRICING_FEATURE_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    olibrToast.error(error?.response?.data);
    yield put({ type: types.UPDATE_PRICING_FEATURE_FAIL });
  }
}

export function* addPricingPlanSaga({ navigate, data, cb }) {
  try {
    yield put({ type: types.ADD_PRICING_PLAN_START });
    const response = yield call(addPricingPlanApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.ADD_PRICING_PLAN_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      cb && cb();
      yield put({
        type: types.ADD_PRICING_PLAN_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    olibrToast.error(error?.response?.data);
    yield put({ type: types.ADD_PRICING_PLAN_FAIL });
  }
}

export function* updatePricingPlanSaga({ navigate, data, cb }) {
  try {
    yield put({ type: types.UPDATE_PRICING_PLAN_START });
    const response = yield call(updatePricingPlanApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_PRICING_PLAN_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      console.log("response?.data", response?.data);
      if (response?.data?.statusValue == 200) {
        cb && cb();
      }
    }
  } catch (error) {
    olibrToast.error(error?.response?.data);
    yield put({ type: types.UPDATE_PRICING_PLAN_FAIL });
  }
}

export function* saveAddOnAdminSaga({ navigate, data, cb }) {
  try {
    yield put({ type: types.SAVE_ADD_ON_START });
    const response = yield call(saveAddOnAdminApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.SAVE_ADD_ON_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      cb && cb();
      yield put({
        type: types.SAVE_ADD_ON_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    olibrToast.error(error?.response?.data);
    yield put({ type: types.SAVE_ADD_ON_FAIL });
  }
}

export function* updateAddOnAdminSaga({ navigate, data, cb }) {
  try {
    yield put({ type: types.UPDATE_ADD_ON_START });
    const response = yield call(updateAddOnAdminApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_ADD_ON_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      cb && cb();
      yield put({
        type: types.UPDATE_ADD_ON_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    olibrToast.error(error?.response?.data);
    yield put({ type: types.UPDATE_ADD_ON_FAIL });
  }
}

export function* updatePlanStatusAdminSaga({ navigate, data, cb }) {
  try {
    yield put({ type: types.UPDATE_PLAN_STATUS_START });
    const response = yield call(updatePlanStatusApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_PLAN_STATUS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      cb && cb();
    }
  } catch (error) {
    olibrToast.error(error?.response?.data);
    yield put({ type: types.UPDATE_PLAN_STATUS_FAIL });
  }
}

export function* updateAddOnStatusAdminSaga({ navigate, data, cb }) {
  try {
    yield put({ type: types.UPDATE_ADD_ON_STATUS_START });
    const response = yield call(updateAddOnStatusApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_ADD_ON_STATUS_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      cb && cb();
      yield put({
        type: types.UPDATE_ADD_ON_STATUS_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    olibrToast.error(error?.response?.data);
    yield put({ type: types.UPDATE_ADD_ON_STATUS_FAIL });
  }
}

export function* getPricingSlabSaga({ navigate, data }) {
  try {
    yield put({ type: types.GET_PRICING_SLAB_START });
    const response = yield call(getPricingSlabApi, data);
    if (response.data.status === 401) {
      yield put({ type: types.GET_PRICING_SLAB_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_PRICING_SLAB_SUCCESS,
        data: response?.data?.data,
      });
    }
  } catch (error) {
    olibrToast.error(error?.response?.data);
    yield put({ type: types.GET_PRICING_SLAB_FAIL });
  }
}

export function* addPricingSlabSaga({ navigate, payload }) {
  try {
    yield put({ type: types.ADD_PRICING_SLAB_START });
    const response = yield call(addPricingSlabApi, payload);
    if (response.data.status === 401) {
      yield put({ type: types.ADD_PRICING_SLAB_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      payload?.cb && payload?.cb();
      yield put({
        type: types.ADD_PRICING_SLAB_SUCCESS,
        data: response?.data?.data,
      });
    }
  } catch (error) {
    olibrToast.error(error?.response?.data);
    yield put({ type: types.ADD_PRICING_SLAB_FAIL });
  }
}

export function* updatePricingSlabSaga({ navigate, payload }) {
  try {
    yield put({ type: types.UPDATE_PRICING_SLAB_START });
    const response = yield call(updatePricingSlabApi, payload);
    if (response.data.status === 401) {
      yield put({ type: types.UPDATE_PRICING_SLAB_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      payload?.cb && payload?.cb();
      yield put({
        type: types.UPDATE_PRICING_SLAB_SUCCESS,
        data: response?.data?.data,
      });
    }
  } catch (error) {
    olibrToast.error(error?.response?.data);
    yield put({ type: types.UPDATE_PRICING_SLAB_FAIL });
  }
}

export function* getPendingSkillSaga({ navigate }) {
  try {
    yield put({ type: types.GET_PENDING_SKILL_START });
    const response = yield call(getPendingSkillAPI);
    if (response.data.status === 401) {
      yield put({ type: types.GET_PENDING_SKILL_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      yield put({
        type: types.GET_PENDING_SKILL_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    olibrToast.error(error?.response?.data);
    yield put({ type: types.GET_PENDING_SKILL_FAIL });
  }
}
export function* updatePendingSkillSaga({ navigate, payload }) {
  try {
    yield put({ type: types.UPDATE_PENDING_SKILL_START });

    const response = yield call(updatePendingSkillApi, payload?.data);
    if (response?.data?.status === 401) {
      yield put({ type: types.UPDATE_PENDING_SKILL_FAIL });
      olibrToast.error(response.data.message);
      navigate("/login");
    } else {
      payload?.cb && payload?.cb()
      yield put({
        type: types.UPDATE_PENDING_SKILL_SUCCESS,
        data: response?.data,
      });
    }
  } catch (error) {
    olibrToast.error(error?.response?.data);
    yield put({ type: types.UPDATE_PENDING_SKILL_FAIL });
  }
}
