import { Fragment, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Icon } from "../../../components/icon";
import { emailVerification } from "../../../service/action/authentication";
import "./verifyEmail.css";

const VerifyEmail = () => {
  const params = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [colorName, setColorName] = useState("red");

  useEffect(() => {
    dispatch(emailVerification(params, navigate, setColorName))
  }, [params]);

  return (
    <Fragment>
      <div className="login-page">
        <header className="login-header">
          <Link to="/">
          <Icon name="logo-olibr" />
          </Link>
        </header>
        <div className="login-block">
          <div className="login-left">
            <h4>Exclusive Network of</h4>
            <h5>
              <span>Best Talents</span>
            </h5>
            <Icon name="login-network" />
          </div>
          <div className="login-right">
            <div className="login-form">
              <div className="login-form-header">
                <figure>
                <Icon name="olibr-favicon" />
                </figure>
                <div className="login-form-text">
                  <h3>Email Verification</h3>
                  <p style={{ fontSize: "18px", color: colorName }}/>
                </div>
              </div>
              <div className="to-login">
                <Link to='/login' className="link-button-verify">Go To Login<i className="arrow-right-class"></i></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">&copy; 2023 Olibr Resourcing Pvt Ltd. All rights reserved</div>
      </div>
    </Fragment>
  );
}
export default VerifyEmail;
