/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState } from "react";
import { Controller } from "react-hook-form";
import SelectOption from "./SelectOption";

const SelectCustomSkill = ({
  jobControl,
  register,
  allSkillLists,
  getValues,
  setValue,
  allStackLists,
  watch,
  requiredSkill,
  optionalSkill,
  setRequiredSkill,
  setOptionalSkill,
}) => {
  const watchChanges = watch();
  const [optionSll, setOptinalSkll] = useState([]);

  function filterExistSkill(tmpRqrSkl, tmpOptnlSkl) {
    let tempAllSkill = [];
    if (allSkillLists && tmpRqrSkl && tmpOptnlSkl) {
      for (let skill of allSkillLists) {
        if (
          ![...tmpRqrSkl, ...tmpOptnlSkl]?.find(
            (itm) => itm?.skillId == skill?.skillId
          )
        ) {
          tempAllSkill.push(skill);
        }
      }
    }
    setOptinalSkll(tempAllSkill);
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="custom-skill-query">
          <h4>{watchChanges?.isAssessmentRequired && "Must"} Clear All</h4>
          <div className="form-group">
            <label>Skills</label>
            <SelectOption
              borderGray={true}
              searchable={true}
              optionArray={optionSll}
              state={requiredSkill}
              onChangeFunction={(itm) => setRequiredSkill(itm)}
              onClick={() => filterExistSkill(requiredSkill, optionalSkill)}
              isMulti={true}
            />
          </div>
          <div className="custom-skill-circle text-center">
            <span className="circle">AND</span>
          </div>
          <h4>{watchChanges?.isAssessmentRequired && "Must"} Clear Any One</h4>
          <div className="form-group">
            <label>Skills</label>
            <SelectOption
              borderGray={true}
              searchable={true}
              optionArray={optionSll}
              state={optionalSkill}
              isMulti={true}
              onClick={() => filterExistSkill(requiredSkill, optionalSkill)}
              onChangeFunction={(itm) => setOptionalSkill(itm)}
            />
          </div>
        </div>
        <div className="custom-skill-circle text-center">
          <span className="circle" style={{ margin: "10px" }}>
            OR
          </span>
        </div>
        <div className="custom-skill-query">
          <h4>{watchChanges?.isAssessmentRequired && "Must"} Clear</h4>
          <div className="form-group">
            <label>Stacks</label>
            <Controller
              control={jobControl}
              name="devChooseStacks"
              {...register("devChooseStacks")}
              render={({ field }) => (
                <SelectOption
                  borderGray={true}
                  searchable={true}
                  optionArray={!!allStackLists?.length ? allStackLists : []}
                  fieldProp={field}
                  getValue={getValues}
                  getValueName={"devChooseStacks"}
                  isMulti={true}
                  setValue={setValue}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="selected-query">
          <h4>Final Query</h4>

          <ul className="icon-tags">
            <h3>
              {!!optionalSkill?.length && !!requiredSkill?.length &&<span className="link">{"{"}</span>}
              {!!requiredSkill?.length && <span>(</span>}
            </h3>
            {requiredSkill?.map((item, index) => (
              <Fragment key={index}>
                <li key={item?.stackId}>
                  <a>
                    <img src={item?.skillTestIcon} alt="" />
                    {item?.skillName}
                  </a>
                </li>
                {!!requiredSkill[index + 1]  && (
                  <span style={{ marginTop: "7px" }}>And</span>
                )}
              </Fragment>
            ))}
            <h3>
              {" "}
              {(!!optionalSkill?.length && !!requiredSkill?.length) && <span>)+</span>}
              {!!optionalSkill?.length && <span>(</span>}
            </h3>
            {optionalSkill?.map((item, index) => (
              <Fragment key={index}>
                <li key={item?.stackId}>
                  <a>
                    <img src={item?.skillTestIcon} alt="" /> {item?.skillName}
                  </a>
                </li>
                {!!optionalSkill[index + 1]  && (
                  <span style={{ marginTop: "7px" }}>Or</span>
                )}
              </Fragment>
            ))}
            <h3>
              {" "}
              {(!!optionalSkill?.length|| !!requiredSkill?.length) && <span>)</span>}
              {(!!optionalSkill?.length && !!requiredSkill?.length) &&<span className="link">{"}"}</span>}
              {(!!optionalSkill?.length || !!requiredSkill?.length) && (!!watchChanges?.devChooseStacks?.length) && <span>or</span>} 
            </h3>

            {watchChanges?.devChooseStacks?.map((item, index) => (
              <Fragment key={index}>
                <li key={item?.stackId}>
                  <a>
                    <img src={item?.stackIcon} alt="" /> {item?.stackName}
                  </a>
                </li>
                {!!watchChanges?.devChooseStacks[index + 1] && (
                  <span style={{ marginTop: "7px" }}>Or</span>
                )}
              </Fragment>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SelectCustomSkill;
