import { Image, Text, View } from '@react-pdf/renderer'
import React from 'react'
import { EmployementStyle } from './css/EmployementStyle'
import EmployementIcon from "../../../assets/img/icons/skill-icon.png";
import { Skill } from './css/Skill';
const Skills = ({ data }) => {
    return (
        Array.isArray(data) && (
        <View style={EmployementStyle.employetmentMain}>
            <View style={{ ...EmployementStyle.emplLayoutLeft, ...Skill.leftLayout }} >
                <View>
                    <Image source={EmployementIcon} style={EmployementStyle.employementIcon} />
                </View>
                <View style={EmployementStyle.emplLayoutLists}>
                    <Text style={EmployementStyle.empHeading}> Skills</Text>
                </View>
            </View>
            <View>
                {
                     data?.map((skills) => (
                        <View style={Skill.rightLayout}>
                            <Text style={Skill.skillTitle}>{skills?.title}</Text>
                            <View style={Skill.technichalSkill}>
                                {
                                    skills?.tagArr?.map((itm) => (
                                        <View style={Skill.skillLists}>
                                            <Text style={Skill.technichalSkillTitle}>{itm}</Text>
                                        </View>
                                    ))
                                }
                            </View>

                        </View>
                    ))
                }


            </View>
        </View>
        )
    )
}

export default Skills