import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { name, email } from "../../../common/staticData/admin/userManagement";
import { Icon } from "../../../components/icon";
import Modal from "../../../components/modal/ModalBox";
import {
  userListing,
  updateUserStatus,
  updateUser,
  addUser,
  deleteUser,
} from "../../../service/action/admin";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import { optionSelect, specificFunctions } from "../../../common/sidebarRoute";
import InfiniteScroll from "react-infinite-scroll-component";
import SelectOption from "../../../components/select/SelectOption";
import { useContext } from "react";
import { MainContext } from "../../../context/MainContext";
import { checkPermission, readOnlyCheck } from "../../../components/functions";
const UserManagement = () => {
  const {user:{access}}=useContext(MainContext)
  const readOnly=readOnlyCheck(access,"User Management")
  const {
    register: userRegister,
    setValue: setUserValue,
    handleSubmit: userSubmit,
    getValues: getUserValue,
    reset: resetUserForm,
    control: userControl,
    watch,
    formState: { errors: userError },
  } = useForm();

  const [options, setOptions] = useState([]);

  useEffect(() => {
    let tempArr1 = [];
    let tempArr2 = [];
    optionSelect?.map((item) => tempArr1?.push({ value: item, label: item }));
    specificFunctions?.map((item) =>
      tempArr2?.push({ value: item?.value, label: item?.label })
    );
    let arr = [...tempArr1, ...tempArr2];
    arr.sort((a, b) => {
      let fa = a?.label?.toLowerCase(),
        fb = b?.label?.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    setOptions(arr);
  }, [specificFunctions, optionSelect]);

  const dispatch = useDispatch();
  const { userData, isUserListLoading, isUserInviting } = useSelector(
    (state) => state.adminReducer
  );

  const [userDetails, setUserDetails] = useState({
    modal: false,
    edit: false,
    id: null,
  });

  let ModuleLists = watch("module");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const [pageData, setPageData] = useState({
    page: 1,
    limit: 13,
    search: "",
  });
  const [resendInviteId, setResendInviteId] = useState();

  const handleEditUser = (user) => {
    const module = user?.module?.map((item) => ({
      value: item.name,
      label: item.name,
      readOnly:item?.readOnly
    }));
    setUserValue("fName", user?.fName);
    setUserValue("email", user?.email);
    setUserValue("password", "");
    setUserValue("module", module);

    setUserDetails({
      modal: true,
      edit: true,
      id: user?._id,
    });
  };

  const openModal = () => {
    setUserDetails({
      modal: true,
    });
  };

  const closeModal = () => {
    setUserDetails({
      modal: false,
      edit: false,
      id: null,
    });
    resetUserForm();
    setErrors({});
  };

  const changeUserStatus = (userId, userStatus) => {
    dispatch(
      updateUserStatus(
        userId,
        userStatus === "Active" ? "Disable" : "Active",
        navigate
      )
    );
  };

  const fetchUserList = (userDetails) => {
    dispatch(userListing(navigate, pageData));
    setUserDetails(userDetails)
  }

  const handleAddMember = (data) => {
    const userData = { ...data,module:ModuleLists, userType: "Admin" };
    userData.module = userData?.module?.map((item) => ({
      name: item.value,
      status: item.status,
      readOnly:item?.readOnly
    }));

    const handelUpdate = () => {
      !userData?.password && delete userData?.password;
      dispatch(
        updateUser(
          userDetails?.id,
          userData,
          pageData,
          fetchUserList,
          resetUserForm,
          navigate
        )
      );
    };

    const handleSave = () => {
      userData?.hasOwnProperty("userError") && delete userData?.userError;
      dispatch(addUser(userData, navigate, fetchUserList, resetUserForm));
    };
    userDetails?.edit ? handelUpdate() : handleSave();
  };

  const onResendInviteMail = (userId) => {
    setResendInviteId(userId);
    dispatch(addUser({ userId }, navigate, setUserDetails, resetUserForm));
  };

  const onDeleteMember = () => {
    dispatch(
      deleteUser(userDetails?.id, fetchUserList, resetUserForm, navigate)
    );
  };
  const handleUserModalContent = () => {
    return (
      <form>
        <div className="row">
          <div className="form-group col-12">
            <input
              type="text"
              placeholder="Email"
              name="email"
              {...userRegister("email", {
                required: true,
              })}
              className={`form-control ${userError?.email && "form-required"}`}
            />
          </div>
          <div
            className={`form-group col-12 ${userError?.module && "select-required"
              }`}
          >
            <Controller
              control={userControl}
              name="module"
              {...userRegister("module", {
                required: true,
              })}
              render={({ field }) => (
                <Fragment>
                  <SelectOption
                    isMulti={true}
                    closeMenuOnSelect={false}
                    optionArray={options?.length ? options : []}
                    fieldProp={field}
                    getValue={getUserValue}
                    getValueName={"module"}
                    placeholder={"Select module"}
                    searchable={true}
                  />
                </Fragment>
              )}
            />
          </div>
        </div>
        <br />
        {
          !!ModuleLists?.length && (
            <div className="col-12 table">
              <table className="table-blog">
                <thead>
                  <tr>
                    <th className="column">
                      <div className="serialNumber">S.No</div>
                    </th>
                    <th className="column">
                      <div className=" left-aligned">Module</div>
                    </th>
                    <th className="column">
                      <div className=" left-aligned">Read Only</div>
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {
                    ModuleLists?.map((item, index) => (
                      <tr className="faqdiv table-border-bottom">
                        <td
                          className="sno"
                        >
                          {index + 1}
                        </td>
                        <td
                          className="postedOn"
                        > {
                            item?.value
                          }
                        </td>
                        <td
                          className="postedOn"
                        >
                          <div className="">
                            <input
                              type="checkbox"
                              name="switch"
                              checked={ModuleLists?.[index]?.readOnly}
                              onChange={(e) => e.preventDefault()}
                            />
                            <label
                              onClick={()=>{
                               ModuleLists[index].readOnly=!ModuleLists?.[index]?.readOnly
                                setUserValue("module",ModuleLists)
                              }}
                              className="label"
                              htmlFor="switch3"
                            ></label>
                          </div>
                        </td>
                      </tr>
                    ))
                  }

                </tbody>
              </table>
            </div>
          )
        }

        <div className="col-12">
          <div className="bottom-button">
            <button className="cancelBtn" type="button" onClick={closeModal}>
              Cancel
            </button>
            <button
              onClick={userSubmit(handleAddMember)}
              className="submitBtn themeButton"
            >
              {isUserInviting ? <ClipLoader size={15} color="#FFFFFF" /> : "Submit"}
            </button>
          </div>
        </div>
      </form>
    );
  };
  const handleSearch = (e) => {
    dispatch(
      userListing(navigate, { ...pageData, page: 1, search: e.target.value })
    );
    setPageData({ ...pageData, search: e.target.value });
  };

  const fetchMoreData = () => {
    setPageData({
      ...pageData,
      limit: pageData?.limit + 13,
    });
  };
  useEffect(() => {
    dispatch(userListing(navigate, pageData));
  }, [pageData.page, pageData?.limit]);

  return (
    <>
      <div className="main-div-developer">
        <div className="skills-management">
          <div className="main-div-blog">
            <div className="blog-list">
              <div className="block-header">
                <h4 className="">All Users</h4>
                <span onClick={
                  ()=>{
                    if (checkPermission(readOnly)) return false
                    openModal()
                  }
                  }>
                  {" "}
                  <Icon name="add-icon" />{" "}
                </span>
              </div>
              <div className="row">
                <div className="col-8"></div>
                <div className="search-block col-4">
                  <input
                    type="text"
                    className="form-control"
                    id="asdfgh"
                    value={pageData.search}
                    placeholder="Search Users..."
                    onChange={handleSearch}
                  />
                  <a className="search-btn">
                    <Icon name="search-icon" />
                  </a>
                </div>
              </div>
              <br />
              <div className="table">
                <InfiniteScroll
                  height={"60vh"}
                  dataLength={userData?.data ? userData?.data?.length : 0}
                  next={fetchMoreData}
                  hasMore={
                    userData?.totalDataCount <= userData?.data?.length
                      ? false
                      : true
                  }
                  loader={
                    isUserListLoading && (
                      <div style={{ textAlign: "center" }}>
                        {" "}
                        <ClipLoader color="#3BA0AA" />{" "}
                      </div>
                    )
                  }
                >
                  <table className="table-blog">
                    <thead>
                      <tr>
                        <th className="column">
                          <div className="serialNumber">S.No</div>
                        </th>
                        <th className="column">
                          <div className=" left-aligned">{name}</div>
                        </th>
                        <th className="column">
                          <div className=" left-aligned">{email}</div>
                        </th>
                        <th className="column">
                          <div className=" left-aligned">Last Login</div>
                        </th>
                        <th className="column">
                          <div className=" left-aligned">Status</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData?.data
                        ?.filter(
                          (user) =>
                            user?.fName
                              ?.toLowerCase()
                              ?.includes(pageData?.search?.toLowerCase()) ||
                            user?.email
                              ?.toLowerCase()
                              ?.includes(pageData?.search?.toLowerCase())
                        )
                        .map((item, i) => (
                          <tr key={i} 
                          onClick={()=>{
                            if (checkPermission(readOnly)) return false
                            handleEditUser(item)
                          }}
                          className="faqdiv table-border-bottom">
                            <td
                              className="sno"
                            >
                              {i + 1}
                            </td>
                            <td
                              className="subQues"
                            >
                              {item.fName}
                            </td>
                            <td
                              className="postedOn"
                            >
                              {item.email}
                            </td>
                            <td
                              className="postedOn"
                            >
                              {item.lastLogin
                                ? new Date(item.lastLogin).toDateString()
                                : "-"}
                            </td>
                            <td className="postedOn">
                              <div className="flex-row-item">
                                <div>
                                  <input
                                    type="checkbox"
                                    name="switch"
                                    checked={item.status === "Active"}
                                    onChange={(e) => e.preventDefault()}
                                  />
                                  <label
                                    className="label"
                                    htmlFor="switch3"
                                    onClick={() => {
                                      if (checkPermission(readOnly)) return false
                                      changeUserStatus(item._id, item?.status);
                                    }}
                                  ></label>
                                </div>
                                <div>
                                  {!item.fName && (
                                    <>
                                      {(isUserInviting && resendInviteId === item?._id) ? (
                                        <ClipLoader size={20} color="#3BA0AA" />
                                      ) : (
                                        <Icon
                                          name="resend"
                                          className="cursor"
                                          onClick={() => onResendInviteMail(item._id)}
                                        />
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
              <Modal
                open={userDetails?.modal}
                title={`${userDetails?.edit ? "Edit" : "Add"} User`}
                onClose={closeModal}
                onCancel={closeModal}
                isDeleteIcon={true}
                isDeletable={userDetails?.edit}
                onDelete={() => onDeleteMember()}
                content={handleUserModalContent}
                isFooterView={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManagement;
