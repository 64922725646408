import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../components/icon";
import { getBlogOpen, getRecentFiveBlogs } from "../../../service/action/common";
import CommonList from "./blog-listing-common/CommonList";

const BlogListNew = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNav = () => {
    navigate("/admin/add-blog");
  };

  const { blogOpenList, recentFiveBlogs } = useSelector((state) => state.commonReducer);

  const [pageData, setPageData] = useState({ page: 1, limit: 10 });
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getRecentFiveBlogs(navigate));
  }, [navigate, dispatch]);
  
  const onSyncupClick = () => {
    dispatch(getRecentFiveBlogs(navigate));
  }
  
  return (
    <Fragment>
      <div className="main-div-developer">
        <div className="skills-management">
          <div className="block-header">
            <h4>Blog Management</h4>
            <span onClick={onSyncupClick} data-tooltip="Blog sync-up" className="btn" style={{padding: 0}}>
              <Icon name="imp-blog" />
            </span>
          </div>
          <div className="row">
            <div className="col-8"></div>
            <div className="search-block col-4">
              <input
                type="text"
                className="form-control"
                value={search}
                placeholder="Search Users"
                onChange={(e) => {
                  setSearch(e.target.value);
                  setPageData({ ...pageData, page: 1 });
                }}
              />
              <a className="search-btn">
                <Icon name="search-icon" />
              </a>
            </div>
          </div>
          <br />
          <div className="table-div dev-page-table">
            <CommonList
              isLoading={false}
              blogListArray={recentFiveBlogs}
            />
          </div>
        </div>
        <br />
        <div>
        </div>
      </div>
    </Fragment>
  );
};

export default BlogListNew;
