import React, { Fragment } from "react";
import { Icon } from "../../components/icon";



const CardSection = ({ cardData, heading }) => {
  return (
    <Fragment>
    <section className="why-join">
      <div className="container">
        <h2 className="section-title">{heading}</h2>
        <div className="cards">
          {cardData.map((card, index) => (
            <div className="card" key={index}>
            <figure>
              <Icon name={card.iconName}/>
            </figure>
            <h4 className="card__title">{card.title}</h4>
            <p>{card.para}</p>
          </div>
          ))}
        </div>
      </div>
    </section>
    </Fragment>
  );
};

export default CardSection;
