import React, { Fragment, useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  blockClientProfileAction,
  deleteDevProfile,
  getClientDetailAdmin,
  getClientListAdminHired,
  getClientListAdminShortListed,
} from "../../../service/action/admin";
import ClientProfile from "./ClientProfile";
import CompanyProfile from "./CompanyProfile";
import SavedProfile from "./SavedProfile";
import Candidate from "./Candidate";
import PostedJobs from "./PostedJobs";
import { Icon } from "../../../components/icon";
import Modal from "../../../components/modal/ModalBox";
import { MainContext } from "../../../context/MainContext";
import PaymentDetails from "../../client/subscription/PaymentDetails";

const states = ["tabBtn-active", "tabBtn"];

const ClientDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const { user } = useContext(MainContext);
  const {
    clientDetailAdmin,
    allClientListAdmin,
    isAllClientListAdminLoading,
    isDeleteProfile,
    isBlockProfile,
    isProfileBlocking,
  } = useSelector((state) => state.adminReducer);
  const [isReachedOnFirst, setIsReachedOnFirst] = useState(true);
  const [isReachedOnLast, setIsReachedOnLast] = useState(true);
  const [confirmDelete, setDeleteConfirm] = useState(false);
  const [confirmBlock, setBlockConfirm] = useState(false);

  useEffect(() => {
    dispatch(getClientDetailAdmin(clientId, navigate));
    dispatch(getClientListAdminShortListed(clientId, navigate));
    dispatch(getClientListAdminHired(clientId, navigate));
  }, [clientId]);

  const handleClick = (index) => setSelectedTab(index);
  const [selectedTab, setSelectedTab] = useState(0);
  const [deleteAccess, setDeleAccess] = useState(false);
  const [blockAccess, setBlockAccess] = useState(false);

  const tabArr = [
    "Client Profile",
    "Company Profile",
    "Saved Profiles",
    "Candidate",
    "Posted Jobs",
    "Subscription",
  ];
  const compArr = [
    <ClientProfile clientDetailAdmin={clientDetailAdmin} />,
    <CompanyProfile/>,
    <SavedProfile />,
    <Candidate />,
    <PostedJobs />,
    <PaymentDetails isAdmin={true} clientId={clientId}/>
  ];

  const handleGoBack = () => {
    navigate("/admin/client-management");
  };

  const onCancelDelte = () => {
    setDeleteConfirm(false);
  };

  const onClientDelete = () => {
    dispatch(
      deleteDevProfile(navigate, clientId, onCancelDelte, {}, {}, "client")
    );
  };

  const onCancelBlock = () => {
    setBlockConfirm(false);
  };

  const onClientBlock = () => {
    if (clientDetailAdmin?.data[0]?._id) {
      dispatch(
        blockClientProfileAction(
          navigate,
          {
            userId: clientDetailAdmin?.data[0]?._id,
            company: clientDetailAdmin?.data[0]?.clientCompanyName,
          },
          onCancelBlock
        )
      );
    }
  };

  useEffect(() => {
    const itemIndex = allClientListAdmin?.data?.data?.findIndex(
      (clientData) => clientData._id === clientId
    );
    if (allClientListAdmin?.data?.data) {
      if (itemIndex === 0) {
        !isReachedOnFirst && setIsReachedOnFirst(true);
      } else {
        isReachedOnFirst && setIsReachedOnFirst(false);
      }

      if (itemIndex === allClientListAdmin?.data?.data?.length - 1) {
        !isReachedOnLast && setIsReachedOnLast(true);
      } else {
        isReachedOnLast && setIsReachedOnLast(false);
      }
    }
  }, [clientId]);

  useEffect(() => {
    const isDeleteAccess = user?.access?.filter(
      (item) => item?.name == "DeleteClientProfile" && item?.status == true
    );

    const isClientBlockAccess = user?.access?.filter(
      (item) => item?.name == "BlockClientProfile" && item?.status == true
    );

    if (isDeleteAccess?.length || user?.access?.length < 1) {
      setDeleAccess(true);
    }

    if (isClientBlockAccess?.length || user?.access?.length < 1) {
      setBlockAccess(true);
    }
  }, [user]);

  const onPrevious = () => {
    if (!isAllClientListAdminLoading) {
      const itemIndex = allClientListAdmin?.data?.data?.findIndex(
        (clientData) => clientData._id === clientId
      );
      if (itemIndex > 0) {
        navigate(
          `/admin/client-management/${
            allClientListAdmin?.data?.data?.[itemIndex - 1]?._id
          }`
        );
      }
    }
  };

  const onNext = () => {
    if (!isAllClientListAdminLoading) {
      const itemIndex = allClientListAdmin?.data?.data?.findIndex(
        (clientData) => clientData._id === clientId
      );
      if (itemIndex < allClientListAdmin?.data?.data?.length - 1) {
        navigate(
          `/admin/client-management/${
            allClientListAdmin?.data?.data?.[itemIndex + 1]?._id
          }`
        );
      }
    }
  };

  return (
    <Fragment>
      <div className="skills-management client-skill-pt0">
        <div className="pre-next-box">
          <div className="pre-next-btn-text" onClick={onPrevious}>
            {isReachedOnFirst ? "" : "< Previous"}
          </div>
          <div className="pre-next-btn-text" onClick={onNext}>
            {isReachedOnLast ? "" : "Next >"}
          </div>
        </div>
        <Modal
          open={confirmDelete}
          title={"Are you sure you want to delete client profile?"}
          onClose={onCancelDelte}
          onDelete={() => onClientDelete()}
          isDeleteLoading={isDeleteProfile}
          onCancel={onCancelDelte}
          confirmDelete={true}
          isFooterView={true}
        />
        <Modal
          open={confirmBlock}
          title={`Are you sure you want to ${
            clientDetailAdmin?.data?.[0]?.isBlocked ? "unblock" : "block"
          } the client profile?`}
          onClose={onCancelBlock}
          onDone={() => onClientBlock()}
          buttonTitle={`${
            clientDetailAdmin?.data?.[0]?.isBlocked ? "Unblock" : "block"
          }`}
          isDoneLoading={isProfileBlocking}
          isDeleteLoading={isBlockProfile}
          onCancel={onCancelBlock}
          confirmDelete={false}
          isFooterView={true}
        />
        <div className="main-div-tab">
          <div className="tabContainer client-tab-mb5">
            <div>
              {tabArr?.map((item, index) => (
                <span
                  key={index}
                  className={selectedTab == index ? states[0] : states[1]}
                  onClick={() => handleClick(index)}
                >
                  {item}
                </span>
              ))}
            </div>

            <div className="flex-row-item cm-btn">
              {blockAccess && (
                <Icon
                  className="cursor"
                  name="block-user"
                  onClick={() => setBlockConfirm(true)}
                />
              )}
              {deleteAccess && (
                <Icon
                  className="cursor"
                  name="delete"
                  onClick={() => setDeleteConfirm(true)}
                />
              )}
              <Icon
                className="cursor"
                name="modal-close"
                onClick={handleGoBack}
              />
            </div>
          </div>
          <div>{compArr[selectedTab]}</div>
        </div>
      </div>
    </Fragment>
  );
};

export default ClientDetail;
