import React, { useEffect } from "react";
import SelectOption from "./select/SelectOption";
import { cityArrFunc,  stateArrFunc } from "./functions";
import { useDispatch, useSelector } from "react-redux";
import { getCity, getState } from "../service/action/common";
import { useNavigate } from "react-router-dom";

const CompanyMultipeJob = ({
  jobAddress,
  setErrors,
  errorsAddress,
  jobError,
  setJobAddress,
  country,
}) => {
  const { stateList, cityList } = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchState = (countryCode) => {
    dispatch(getState({ countryCode }, navigate));
  };

  const fetchCities = (countryCode, stateCode) => {
    dispatch(getCity({ countryCode, stateCode }, navigate));
  };

  const getCityName = (index) => {
    let cityListsC = jobAddress?.[index]?.city;
    return { label: cityListsC, value: cityListsC };
  };

  const handleStateChange = (e, index) => {
    let tempState = [...jobAddress];
    tempState[index].state = e?.value;
    tempState[index].stateName = e?.label;
    tempState[index].city = "";
    fetchCities(tempState[index].countryCode, e?.value);
    setJobAddress(tempState);
  };

  const getStateName = (index,jobAddress) => {
    let state = jobAddress[index];
    return { label: state?.stateName, value: state?.state };
  };

 

  const handleCityChange = (e, index) => {
    let tempCity = [...jobAddress];
    tempCity[index].city = e?.value;

    setJobAddress(tempCity);
  };

  const addNewJobAddress = () => {
    let check = jobAddress.every((item) => item.state);
    if (check) {
      let tempJobAdd = [...jobAddress];
      tempJobAdd?.push({ state: "", city: "" });
      setJobAddress(tempJobAdd);
    } else {
      setErrors([jobAddress.findIndex((itm) => !itm?.state)]);
    }
  };

  const removeJobAddress = (index) => {
    if (jobAddress?.length > 1) {
      setJobAddress(jobAddress?.filter((itm, i) => i !== index));
    }
  };

  useEffect(() => {
    if(country?.[0]?.countryCode){
      fetchState(country?.[0]?.countryCode)
    }
  }, [country]);

  
  return (
      jobAddress?.map((item, index) => (
        <>
          <div className="col-5 ">
            <label
              className={`${errorsAddress?.includes(index) && "text-danger"}`}
            >
              State *
            </label>
            <SelectOption
              optionArray={stateArrFunc(stateList)}
              state={getStateName(index,jobAddress)}
              onChangeFunction={(e) => handleStateChange(e, index)}
              closeMenuOnSelect={true}
              themeStyle={true}
            />
          </div>
          <div className="col-5">
            <label className={`${jobError?.jobState && "text-danger"}`}>
              <span>{!jobError.jobCity && "City"}</span>
              <span className="text-danger">{jobError.jobCity && "City*"}</span>
            </label>
            <SelectOption
              optionArray={cityArrFunc(cityList)}
              state={getCityName(index)}
              onChangeFunction={(e) => handleCityChange(e, index)}
              closeMenuOnSelect={true}
              onFocus={() =>
                fetchCities(country?.[0]?.countryCode, jobAddress[index]?.state)
              }
              themeStyle={true}
            />
          </div>
          <div className="col-2">
            <div className="actions">
              <a
                href="#-"
                className="minus-icon minus-icon-job"
                onClick={() => removeJobAddress(index)}
              >
                -
              </a>
              <a
                href="#-"
                className="plus-icon plus-icon-job"
                onClick={addNewJobAddress}
              >
                +
              </a>
            </div>
          </div>
        </>
      ))
  );
};

export default CompanyMultipeJob;
