import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import OlibrLogo from "./../../../assets/img/logo.png";
import OlibrFavicon from "./../../../assets/img/favicon.png";
import { resendEmailVerification } from "../../../service/action/authentication";
import MetaTagSEO from "../../../components/seo/MetaTagSEO";

const ResendEmail = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    dispatch(resendEmailVerification(data, navigate, reset));
  };

  return (
    <Fragment>
      <MetaTagSEO helmetName="resend-email" />
      <div className="login-page">
        <header className="login-header">
          <Link to="/">
            <img src={OlibrLogo} alt="Olibr Logo" />
          </Link>
        </header>
        <div className="login-block">
          <div className="login-left">
            <h1>Exclusive Network of</h1>
            <h5>
              <span>Best Talents</span>
            </h5>
            <img src={"https://ik.imagekit.io/olibr/assets/tr:w-571,h-237/lo-si-left.webp"} alt="Login netwrok" />
          </div>
          <div className="login-right">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="login-form">
                <div className="login-form-header">
                  <figure>
                    <img src={OlibrFavicon} alt="Olibr Favicon" />
                  </figure>
                  <div className="login-form-text">
                    <h3>Resend Verification Email</h3>
                    <p>
                      <Link to="/login" className="link">
                        Login Here
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    placeholder="Email"
                    {...register("email", {
                      required: true,
                      pattern: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
                    })}
                    name="email"
                  />
                  <small className="text-danger">
                    {errors.email && "Email is required & valid."}
                  </small>
                </div>

                <div className="form-group">
                  <button type="submit" className="btn btn-full">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="copyright">&copy; 2023 Olibr Resourcing Pvt Ltd. All rights reserved</div>
      </div>
    </Fragment>
  );
};

export default ResendEmail;
