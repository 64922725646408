import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { testimonialContent } from "./testimonial-content";
import "./Testimonial.css";

const Testimonial4 = () => {
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [fullStory, setFullStory] = useState(false);

  return (
    <>
      <Slider {...settings} arrows={false}>
        {testimonialContent.slice(0, 3).map((item) => (
          <div className="testimonial-block" key={item.id}>
            <div className="inner-box">
              <h4 className="title">{item.feedback}</h4>
              <div className="text">
                {fullStory
                  ? item.feedbackText
                  : item.feedbackText.substring(0, 265)}{" "}
                {item.feedbackText.length >= 265 ? (
                  <a
                    className="read-more"
                    onClick={() => setFullStory(!fullStory)}
                  >
                    ....
                    {fullStory ? "less" : "Read more"}
                  </a>
                ) : (
                  ""
                )}
              </div>
              <div className="info-box">
                <div className="thumb">
                  <img src={item.avatar} alt="testimonial" />
                </div>
                <h4 className="name">{item.name}</h4>
                <span className="designation">{item.designation}</span>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default Testimonial4;
