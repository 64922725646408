import React from "react";
import { useParams } from "react-router-dom";
import ClipLoaderComp from "../../../components/clipLoader/ClipLoader";
import PageTour from "../../../components/page-tour/PageTour";
import ReactDatePicker from "react-datepicker";
import { Controller } from "react-hook-form";
import {
  cityArrFunc,
  stateArrFunc,
} from "../../../components/functions";
import SelectOption from "../../../components/select/SelectOption";
import { useSelector } from "react-redux";
import TempleteOption from "./TempleteOption";
import AwardAndRecog from "./AwardAndRecog";
import { isObject } from "lodash";
import { olibrToast } from "../../../components/toaster";

const BasicDetailsForm = ({
  clientProfile,
  setAwardRecognitions,
  watch,
  setValue,
  awardRecognitions,
  imgIndex,
  coverTemplate,
  fetchCities,
  setCompanyAdress,
  handleStateChange,
  adresses,
  control,
  skipPageTour,
  setPageTour,
  companyProfile,
  register,
  errors,
  companyLogo,
  companyLogoImg,
  uploadImages,
  pageTour,
  isLogoLoading,
}) => {
  const { clientId } = useParams();
  const companySize = watch("companySize");
  const { stateList, cityList } = useSelector((state) => state.commonReducer);
  const getCityName = (index) => {
    let cityListsC = adresses[index].city;
    return { label: cityListsC, value: cityListsC };
  };
  const handleCityChange = (e, index) => {
    let tempCity = [...adresses];
    tempCity[index].city = e?.value;
    setCompanyAdress(tempCity);
  };
  const getLatestCity = (index) => {
    let tempCity = [...adresses];
    let stateName = tempCity[index].state;
    if (stateName) fetchCities(stateName);
  };

  const removeLocation = (index) => {
    if (adresses?.length > 1) {
      setCompanyAdress(adresses?.filter((itm, i) => i !== index));
    }
  };

  const addNewLocation = () => {
    let check = adresses.every((item) => item.state);
    if (check) {
      let tempJobAdd = [...adresses];
      tempJobAdd?.push({ state: "", city: "" });
      setCompanyAdress(tempJobAdd);
    }
  };

  return (
    <>
      <h4 className="form-title">Add company info</h4>
      <div className="row">
        <div className="col-3">
          <div className="form-group">
            <label>
              Company Name<i className="required">*</i>
            </label>
            <input
              type="text"
              {...register("clientCompanyName")}
              className="form-control"
              disabled={clientId ? false : true}
            />
          </div>
        </div>
        <div className="col-3">
          <div className="form-group adornment">
            <label
              className={`${errors?.includes("companyLogo") && "text-danger"}`}
            >
              Company Logo <i className="required">* </i>
            </label>
            <input
              type="text"
              className={`form-control ${errors?.jobLogo && "form-required"}`}
              disabled={clientId ? false : true}
            />
            <img className="input-img" src={companyLogo} />
            <input
              ref={companyLogoImg}
              type="file"
              onChange={(e) => uploadImages(e, "logo")}
              className={`input-file ${errors?.jobLogo && "form-required"}`}
              accept=".png, .jpeg, .jpg, .svg"
            />
            <a
              className="browseImage browseImageNew"
              onClick={() => companyLogoImg.current.click()}
            >
              {isLogoLoading ? (
                <ClipLoaderComp size={20} color="#3BA0AA" />
              ) : (
                "Upload"
              )}
            </a>
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <label>Contact No</label>
            <input
              {...register("number")}
              disabled={clientId ? false : true}
              type="text"
              className="form-control"
            />
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <label
              className={`${
                errors?.includes("companyWebsite") && "text-danger"
              }`}
            >
              Website Link<i className="required">*</i>
            </label>
            <input
              type="text"
              {...register("companyWebsite")}
              className="form-control"
              // onBlur={()=>checkUrl(getValues("companyWebsite"))}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label
              className={`${errors?.includes("companyTitle") && "text-danger"}`}
            >
              Profile Banner Tag Line
              <i className="required">* </i>
            </label>
            <PageTour
              open={pageTour == "banner"}
              total={6}
              data={companyProfile.profileBanner}
              next={() => setPageTour("about")}
              className="profile-banner"
              skip={skipPageTour}
            />
            <input
              type="text"
              {...register("companyTitle")}
              maxLength={62}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label
              className={`${
                errors?.includes("companyAddress") && "text-danger"
              }`}
            >
              Corporate Address
              <i className="required">* </i>
            </label>
            <input
              type="text"
              {...register("companyAddress")}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-4">
          <div className="form-group">
            <label>Company Size</label>
            <Controller
              control={control}
              name="companySize"
              {...register("companySize")}
              render={({ field }) => (
                <SelectOption
                  optionArray={[
                    { value: "1-50", label: "1-50" },
                    { value: "50-100", label: "50-100" },
                    { value: "100-500", label: "100-500" },
                    { value: "500-1000", label: "500-1000" },
                    { value: "1000-5000", label: "1000-5000" },
                    { value: "5000+", label: "5000+" },
                  ]}
                  state={[
                    isObject(companySize)
                      ? companySize
                      : { label: companySize, value: companySize },
                  ]}
                  onChangeFunction={(e) => setValue(e?.value)}
                  fieldProp={field}
                  closeMenuOnSelect={true}
                />
              )}
            />
          </div>
        </div>
        <div className="col-4">
          <div className="form-group">
            <label>Industry Domain</label>
            <input
              type="text"
              maxLength={38}
              {...register("industryDomain")}
              className="form-control"
            />
          </div>
        </div>

        <div className="col-4">
          <div className="form-group">
            <label>Company Founded in</label>
            <Controller
              control={control}
              {...register("foundedIn", {
                required: true,
              })}
              render={({ field }) => (
                <ReactDatePicker
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                  placeholderText="Year"
                  dateFormat="yyyy"
                  showYearPicker
                  maxDate={new Date()}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="row">
        {adresses?.map((item, index) => (
          <>
            <div className="col-5">
              <div className="form-group">
                <label
                // className={`${errorsAddress?.includes(index) && "text-danger"}`}
                >
                  Company Location
                </label>
                <SelectOption
                  optionArray={stateArrFunc(stateList)}
                  state={stateArrFunc(stateList)?.find(
                    (item) => item?.value === adresses[index]?.state
                  )}
                  onChangeFunction={(e) => handleStateChange(e, index)}
                  closeMenuOnSelect={true}
                  placeholder={"Select State"}
                />
              </div>
            </div>
            <div className="col-5">
              <div className="form-group">
                <label className={`${errors?.jobState && "text-danger"}`}>
                  &nbsp;
                </label>
                <SelectOption
                  optionArray={cityArrFunc(cityList)}
                  state={getCityName(index)}
                  onChangeFunction={(e) => handleCityChange(e, index)}
                  closeMenuOnSelect={true}
                  onClick={() => getLatestCity(index)}
                  placeholder={"Select City"}
                />
              </div>
            </div>
            <div className="col-2">
              <div className="actions">
                <a
                  className="minus-icon minus-icon-job"
                  onClick={() => removeLocation(index)}
                >
                  -
                </a>
                <a className="plus-icon plus-icon-job" onClick={addNewLocation}>
                  +
                </a>
              </div>
            </div>
          </>
        ))}
      </div>

      <TempleteOption register={register} coverTemplate={coverTemplate} />
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label
              className={`${errors?.includes("aboutCompany") && "text-danger"}`}
            >
              About Company
              <i className="required text-danger">* </i>
              (500 Words)
            </label>
            <PageTour
              open={pageTour == "about"}
              data={companyProfile.aboutCompany}
              next={() => setPageTour("perk-benefit")}
              className="profile-banner"
              skip={skipPageTour}
              total={6}
              id="#benefit"
            />
            <textarea
              {...register("aboutCompany")}
              className="form-control"
              maxLength={500}
            ></textarea>
          </div>
        </div>
      </div>
      <AwardAndRecog
        imgIndex={imgIndex}
        uploadImages={uploadImages}
        pageTour={pageTour}
        skipPageTour={skipPageTour}
        companyProfile={companyProfile}
        setPageTour={setPageTour}
        awardRecognitions={awardRecognitions}
        setAwardRecognitions={setAwardRecognitions}
      />
    </>
  );
};

export default BasicDetailsForm;
