import React from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getJobFullClientAdmin } from "../../../service/action/admin";
import { Icon } from "../../../components/icon";

const JobFullDetail = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientFullJob } = useSelector(
    (state) => state.adminReducer
  );

  useEffect(() => {
    dispatch(getJobFullClientAdmin(jobId, navigate));
  }, [navigate, dispatch]);

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <Fragment>
      <div className="main-div-developer">
        <div className="skills-management">
          <div className="block-header flex-row-center space-between">
            <h4>Job Detail</h4>
            <div className="cursor" onClick={handleGoBack}>
              <Icon name="modal-close" />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h4>{clientFullJob?.data?.jobTitle}</h4>
              <br />
              <h4>Skills</h4>
              <ul className="icon-tags-finalize">
                {clientFullJob?.data?.devChooseSkills?.map((skillItem, index) => (
                  <li key={index}>
                    <a>
                      <img src={skillItem?.skillTestIcon} alt="" />{" "}
                      {skillItem?.skillName}
                    </a>
                  </li>
                ))}
                {clientFullJob?.data?.stackLists?.map((skillItem, index) => (
                  <li key={index}>
                    <a>
                      <img src={skillItem?.stackIcon} alt="" />{" "}
                      {skillItem?.stackName}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {/* full experience detail */}
            <div className="col-12">
              <br />
              <h4>Preferred Timings</h4>
              <span>{clientFullJob?.data?.workTiming}</span>
            </div>
            <div className="col-12">
              <br />
              Job Description
              <div
                dangerouslySetInnerHTML={{
                  __html: clientFullJob?.data?.shortDesc,
                }}>
              </div>
              <br/>
              Job Responsiblities
              <div
                dangerouslySetInnerHTML={{
                  __html: clientFullJob?.data?.editorText,
                }}
              ></div>
            </div>
            <div className="col-12">
            <ul className="tags tags-fill">
                {clientFullJob?.data?.tags?.map((skillItem) => (
                  <li>
                    <a>
                      {skillItem}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default JobFullDetail;
