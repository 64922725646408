import "./../ClientMain.css";
import CloseIcon from "./../../../assets/img/icons/closed_icon.svg";
import UserImg from "./../../../assets/img/user-img.jpg";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { monthArr } from "../../../common/constant/monthArr";
import SuiteCase from "../../../assets/img/icons/suitcase_new.svg";

import {
  assignToJob,
  unassignToJob,
  clientSavedProfileList,
  getAllClientJobLists,
  getAllSkillClient,
  getAllStackClient,
  unsavedProfileAction,
} from "../../../service/action/client";
import { ClipLoader } from "react-spinners";
import Modal from "../../../components/modal/ModalBox";
import ProfilePreviewCommon from "../../../components/profile-preview/profilePreview";
import { clearResume } from "../../../service/action/developer";
import { countryFlag } from "../../../components/functions";
export default function ShortlistedDeveloper() {
  const {
    clientShortLists,
    isClientShortlist,
    clientAllJoblists,
    clientSkillList,
    skillListLoading,
    stackListsLoading,
    clientStackList,
  } = useSelector((state) => state.clientReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const objDummy = {
    jobId: "",
    devId: "",
  };
  const [fullStory, setFullStory] = useState(false);
  const [devProfile, setDevProfile] = useState({
    modal: false,
    devId: null,
    jobStatus: null,
  });

  const [pageData, setPageData] = useState({
    page: 1,
    limit: 10,
    search: "",
    status: "open",
  });

  const [assignObj, setAssignObj] = useState(objDummy);

  useEffect(() => {
    dispatch(getAllSkillClient(navigate, "profile"));
    dispatch(getAllStackClient(navigate, ""));
    dispatch(
      getAllClientJobLists(navigate, {
        ...pageData,
        clientId,
        page: 1,
        limit: 100,
      })
    );
  }, [clientId]);

  const fetchSavedProfileData = () => {
    dispatch(clientSavedProfileList(navigate, pageData));
  };

  const handleUnsavedProfile = (devId) => {
    dispatch(unsavedProfileAction(navigate, fetchSavedProfileData, devId));
  };

  const handleAssignToJob = (jobId) => {
    const obj = { ...assignObj, jobId };
    dispatch(assignToJob(navigate, fetchSavedProfileData, obj));
    handleCloseAssign();
  };

  const handleJobUnassigned = (devId, jobId) => {
    dispatch(unassignToJob(navigate, fetchSavedProfileData, { devId, jobId }));
  };

  useEffect(() => {
    fetchSavedProfileData();
  }, [pageData.page]);

  const handleOpenAssign = (id) => {
    setAssignObj({ ...assignObj, devId: id });
    setAssignOpen(true);
  };

  const handleCloseAssign = () => {
    setAssignObj(objDummy);
    setAssignOpen(false);
  };

  const handleContentAssign = () => {
    return (
      <Fragment>
        <div className="table-div">
          <table className="table">
            <thead>
              <tr>
                <th className="column">
                  <div className="serialNumber">S.No</div>
                </th>
                <th className="column">Title</th>
              </tr>
            </thead>
            <tbody>
              {clientAllJoblists?.data?.map((item, i) => (
                <tr
                  key={i}
                  className="faqdiv"
                  onClick={() => handleAssignToJob(item?._id)}
                >
                  <td className="titleQues sno">{i + 1}</td>
                  <td className="titleQues">{item.jobTitle}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  };

  const [assignOpen, setAssignOpen] = useState(false);
  const handleCloseDevProfile = () => {
    dispatch(clearResume());
    setDevProfile({ devId: null, modal: false, jobStatus: null });
  };

  const handleOpenDevProfile = (Id, status) => {
    setDevProfile({ devId: Id, modal: true, jobStatus: status });
  };

  const fetchMoreData = () => {
    dispatch(
      clientSavedProfileList(navigate, {
        ...pageData,
        limit: pageData.limit + 10,
      })
    );
    setPageData({ ...pageData, limit: pageData.limit + 10 });
  };

  const findSkills = (id, passed = false, type) => {
    if (type == "skill") {
      for (let element of clientSkillList) {
        if (element?.aId == id && passed) {
          return (
            <li>
              <a>
                <img src={element?.skillTestIcon} alt="AWS" />{" "}
                {element?.skillName}
              </a>
            </li>
          );
        }
      }
    } else {
      for (let element of clientStackList) {
        if (element?.assessmentId == id && passed) {
          return (
            <li>
              <a>
                <img src={element?.stackIcon} alt="AWS" />{" "}
                {element?.assessmentName}
              </a>
            </li>
          );
        }
      }
    }
  };

  return (
    <>
      <Modal
        open={assignOpen}
        title={"Assign To Job"}
        onClose={handleCloseAssign}
        isFooterView={false}
        content={handleContentAssign}
      />

      <Modal
        open={devProfile?.modal}
        title={"Developer Profile"}
        size={"100%"}
        onClose={handleCloseDevProfile}
        isFooterView={false}
        content={() =>
          devProfile?.devId && (
            <ProfilePreviewCommon
              userId={devProfile?.devId}
              jobStatus={devProfile?.jobStatus}
              onClose={handleCloseDevProfile}
              profileStatus={false}
            />
          )
        }
      />
      <div className="shortlisted-wrap">
        <div className="shortlisted-block">
          <h4 className="shortlisted-block-title">
            Choose the candidates you wish to interview
          </h4>
          {clientShortLists?.data?.length < 1 &&
          (isClientShortlist || skillListLoading || stackListsLoading) ? (
            <div style={{ textAlign: "center" }}>
              {" "}
              <ClipLoader color="#3BA0AA" />{" "}
            </div>
          ) : clientShortLists?.data?.length == undefined ||
            clientShortLists?.data?.length < 1 ? (
            <p>No records found...</p>
          ) : (
            <div className="shortlister-list">
              <InfiniteScroll
                dataLength={clientShortLists?.data?.length}
                next={fetchMoreData}
                hasMore={
                  clientShortLists?.totalData <= clientShortLists?.data?.length
                    ? false
                    : true
                }
                loader={
                  <div style={{ textAlign: "center" }}>
                    {" "}
                    <ClipLoader color="#3BA0AA" />{" "}
                  </div>
                }
              >
                {clientShortLists?.data?.map((developer, index) => (
                  <div
                    className="developer-block"
                    key={index}
                  >
                    <figure className="developer-img">
                      <img
                        className="user-img"
                        src={
                          developer?.developerDataId?.userImage
                            ? developer?.developerDataId?.userImage
                            : UserImg
                        }
                        alt="User"
                      />
                    </figure>
                    <div className="developer-details">
                      <div className="detail-header">
                        <div className="detail-header-left">
                          <span>
                            <h4
                              style={{ cursor: "pointer" }}
                              className="fit-content"
                              onClick={(e) => {
                                e.stopPropagation();
                                !clientId &&
                                  handleOpenDevProfile(
                                    developer?.devId,
                                    developer?.status
                                  );
                              }}
                            >
                              {developer?.developerDataId?.fName +
                                " " +
                                developer?.developerDataId?.lName}
                            </h4>
                          </span>
                          <p>{developer?.developerDataId?.devDesg}</p>
                          {(!!developer?.developerDataId?.devState ||
                            !!developer?.developerDataId?.devCity) && (
                            <p className="user-location">
                              <span>
                                <img
                                  src={countryFlag(
                                    developer?.developerDataId?.region
                                  )}
                                  height="13px"
                                />
                              </span>
                              {developer?.developerDataId?.devCity},{" "}
                              {developer?.developerDataId?.devState}
                            </p>
                          )}
                        </div>
                        <div className="detail-header-right">
                          <ul className="icon-tags">
                            {developer?.assignedJobId?.map(
                              (item) =>
                                item.jobId && (
                                  <li
                                    key={item?._id}
                                    className="assign-job-lists"
                                  >
                                    <a>
                                      <img src={SuiteCase} alt="" />{" "}
                                      {item?.jobTitle?.substring(0, 20)}
                                      {""}
                                      {item?.jobTitle?.length >= 20
                                        ? "..."
                                        : ""}
                                      {!clientId && (
                                        <img
                                          style={{ marginLeft: "5px" }}
                                          src={CloseIcon}
                                          alt="Shortlisted developer"
                                          onClick={() =>
                                            handleJobUnassigned(
                                              developer?.devId,
                                              item.jobId
                                            )
                                          }
                                        />
                                      )}
                                    </a>
                                  </li>
                                )
                            )}
                          </ul>
                          {!clientId && (
                            <Fragment>
                              <a
                                href="#-"
                                onClick={() =>
                                  handleOpenAssign(developer?.devId)
                                }
                                className="assign-job"
                              >
                                Assign to job
                              </a>
                              <a
                                href="#-"
                                className="close-icon"
                                onClick={() =>
                                  handleUnsavedProfile(developer?.devId)
                                }
                              >
                                <img
                                  src={CloseIcon}
                                  alt="Shortlisted developer"
                                />
                              </a>
                            </Fragment>
                          )}
                        </div>
                      </div>
                      <ul className="icon-tags">
                        {developer?.developerDataId?.devTechTestResult?.map(
                          (item) => (
                            <>
                              {findSkills(
                                item?.testId,
                                item?.isPassed,
                                "skill"
                              )}
                              {findSkills(
                                item?.testId,
                                item?.isPassed,
                                "stack"
                              )}
                            </>
                          )
                        )}
                      </ul>

                      <div className="developer-description">
                        <p>
                          {fullStory
                            ? developer?.developerDataId?.devTellUrStory
                            : developer?.developerDataId?.devTellUrStory?.substring(
                                0,
                                260
                              )}
                          {""}
                          {developer?.developerDataId?.devTellUrStory?.length >=
                          260
                            ? "..."
                            : ""}
                        </p>

                        <p className="avaiability">
                          <strong>
                            {developer?.developerDataId?.devAvlFrom
                              ? " Available From : "
                              : "Notice Period : "}
                          </strong>
                          {developer?.developerDataId?.devAvlFrom ? (
                            <span>
                              {
                                developer?.developerDataId?.devAvlFrom
                                  ?.split("T")[0]
                                  .split("-")[2]
                              }{" "}
                              {
                                monthArr[
                                  parseInt(
                                    developer?.developerDataId?.devAvlFrom
                                      ?.split("T")[0]
                                      .split("-")[1]
                                  ) - 1
                                ]
                              }{" "}
                              {
                                developer?.developerDataId?.devAvlFrom
                                  ?.split("T")[0]
                                  .split("-")[0]
                              }
                            </span>
                          ) : (
                            <span>
                              {developer?.developerDataId?.devNoticePeriod}
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
