import React, { Fragment } from "react";
import { statusObject } from "./constants";
import { Icon } from "../../../components/icon";

const ReviewRow = ({ item, index, onDelete, onResend, rowOpen }) => {
  return (
    <Fragment>
      <tr className="faqdiv table-border-bottom" onClick={rowOpen}>
        <td className="sno">{index + 1}</td>
        <td className="subQues text-success">{item?.receiverName}</td>
        <td className="postedOn">{item?.receiverEmail}</td>
        <td className="postedOn">{item?.receiverDesg}</td>
        <td className="postedOn">{item?.companyName}</td>
        <td className={`postedOn ${statusObject[item?.status]?.class}`}>
          {statusObject[item?.status]?.title}
        </td>
        <td className="postedOn">
          <div style={{display: "flex", justifyContent: "space-evenly"}}>
          <span onClick={(e) => {
            onDelete();
            e.stopPropagation();
            }}>
            <Icon name="delete" />
          </span>
          {item?.status === "pending" && (<span style={{marginTop: "3px"}} onClick={(e) => {
            onResend();
            e.stopPropagation();
            }}>
            <Icon name="resend" />
          </span>)}
          </div>
        </td>
      </tr>
    </Fragment>
  );
};

export default ReviewRow;
