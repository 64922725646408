import { useState, useEffect, Fragment } from "react";
import { Icon } from "../../../../components/icon";
import {
    deleteEnglishQuestion,
    englishAllQuestion,
    updateEnglishQuestion,
} from "../../../../service/action/admin";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../components/modal/ModalBox";
import { useForm } from "react-hook-form";
import { checkPermission } from "../../../../components/functions";

const Question = ({readOnly}) => {
    const [search, setSearch] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { allQuestions, deleteQuestion, updateQuestion } = useSelector(
        (state) => state.adminReducer
    );
    
    const {
        register: testRegister,
        handleSubmit: testSubmit,
        setValue: setTestValue,
        getValues: gettestValue,
        control: testControl,
        reset: resetTestForm,
        formState: { errors: testError },
    } = useForm();

    useEffect(() => {
        dispatch(englishAllQuestion(navigate));
        resetTestForm();
    }, []);

    const handleQuestionDelete = () => {
        dispatch(deleteEnglishQuestion(gettestValue("quesId"), navigate));
        setQuesOpen(false)
    };

    useEffect(() => {
        setQuesOpen(false);
    }, [deleteQuestion, updateQuestion]);

    const [quesOpen, setQuesOpen] = useState(false);
    const handleQuestionClick = (question) => {
       
        setTestValue("question",question?.question)
        setTestValue("option1",question?.options[0])
        setTestValue("option2",question?.options[1])
        setTestValue("option3",question?.options[2])
        setTestValue("option4",question?.options[3])
        setTestValue("correct",question?.options[question?.correct])
        setTestValue("quesId",question?._id)
        setTestValue("type",question?.type)
        setQuesOpen(true);
    };
    const handleQuesClose = () => {
        setQuesOpen(false);
    };

    const handleContent = () => {
        return (
            <Fragment>
                <div className="form-group">
                    <div className="row">
                        <div className="col-12">
                            <label className="floating-label" htmlFor=""></label>
                            <input
                                type="text"
                                className={`form-control fields ${testError?.question && "form-required"}`}
                                placeholder='Question'
                                {
                                ...testRegister("question", {
                                    required: true
                                })
                                }
                            />
                        </div>
                        <div className="col-3">
                            <input
                                type="text"
                                className={`form-control fields ${testError?.option1 && "form-required"}`}
                                placeholder='Option 1'
                                {
                                ...testRegister("option1", {
                                    required: true
                                })
                                }
                            />
                        </div>
                        <div className="col-3">
                            <input
                                type="text"
                                className={`form-control fields ${testError?.option2 && "form-required"}`}
                                placeholder='Option 2'
                                {
                                ...testRegister("option2", {
                                    required: true
                                })
                                }
                            />
                        </div>
                        <div className="col-3">
                            <input
                                type="text"
                                className={`form-control fields ${testError?.option3 && "form-required"}`}
                                placeholder='Option 3'
                                {
                                ...testRegister("option3", {
                                    required: true
                                })
                                }
                            />
                        </div>
                        <div className="col-3" >
                            <input
                                type="text"
                                className={`form-control fields ${testError?.option4 && "form-required"}`}
                                placeholder='Option 4'
                                {
                                ...testRegister("option4", {
                                    required: true
                                })
                                }
                            />
                        </div>
                        <div className="col-12">
                            <select className={`form-control ${testError?.correct && "select-required-border"}`}
                                {...testRegister("correct", {
                                    required: true
                                })}
                            >
                                <option value={gettestValue("option1")}>{gettestValue("option1")}</option>
                                <option value={gettestValue("option2")}>{gettestValue("option2")}</option>
                                <option value={gettestValue("option3")}>{gettestValue("option3")}</option>
                                <option value={gettestValue("option4")}>{gettestValue("option4")}</option>

                            </select>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };

    const submitQuestion = (data) => {
        let temparr=[data?.option1, data?.option2, data?.option3, data?.option4];
        let correct=temparr?.findIndex((item)=>item==data?.correct);
        const newObject = {
            options:temparr ,
            correct,
            question: data?.question,
            quesId:gettestValue("quesId"),
            type:gettestValue("type"),
        }
        dispatch(updateEnglishQuestion(newObject, navigate));
    };

    return (
        <div className="main-div">
            <Modal
                open={quesOpen}
                title={"Question"}
                onClose={handleQuesClose}
                onCancel={handleQuesClose}
                content={handleContent}
                onDone={testSubmit(submitQuestion)}
                isDeletable={true}
                onDelete={handleQuestionDelete}
            />
            <div className="faq-management">
                <br />
                <div className="row">
                    <div className="col-8"></div>
                    <div className="search-block col-4">
                        <input
                            type="text"
                            className="form-control"
                            value={search}
                            placeholder="Search Questions"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <a className="search-btn">
                            <Icon name="search-icon" />
                        </a>
                    </div>
                </div>
                <br />

                <div className="table-div">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="column">
                                    <div className="serialNumber">S.No</div>
                                </th>
                                <th className="column">Question</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allQuestions?.data
                                ?.filter((ques) =>
                                    ques?.question?.toLowerCase().includes(search.toLowerCase())
                                )
                                .map((item, i) => (
                                    <>
                                        <tr
                                            key={item?._id}
                                            className="faqdiv"
                                            onClick={() =>{
                                                if (checkPermission(readOnly)) return false
                                                handleQuestionClick(item)}}
                                        >
                                            <td className="titleQues sno">{i + 1}</td>
                                            <td className="titleQues">{item?.question}</td>
                                        </tr>
                                        {item?.options?.map((sub, i) => (
                                            <tr key={i + Math.random()} className="faqdiv">
                                                <td></td>
                                                <td className="subQues">
                                                    <div className="row">
                                                        <div
                                                            className="col-3"
                                                            key={sub}
                                                            style={{
                                                                color:
                                                                    i == item?.correct ? "#679B31" : "#F15C3B",
                                                                display: "flex",
                                                            }}
                                                        >
                                                            {sub}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                ))}
                        </tbody>
                    </table>

                    {/* <div className="modal fade" id="editFAQ">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Edit FAQ</h4>
                                    <button type="button" className="btn-close">
                                        <Icon name="modal-close" />
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Question"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Answer"
                                        />
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <div className="bottom-button">
                                        <button className="cancelBtn">Delete</button>
                                        <button className="cancelBtn">cancel</button>
                                        <button className="submitBtn themeButton">submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="editFAQ">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Edit FAQ Group Name</h4>
                                    <button type="button" className="btn-close">
                                        <Icon name="modal-close" />
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Question"
                                        />
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <div className="bottom-button">
                                        <button className="cancelBtn">Delete</button>
                                        <button className="cancelBtn">cancel</button>
                                        <button className="submitBtn themeButton">submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Question;
