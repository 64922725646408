import React from 'react'
import { Link } from 'react-router-dom'
import "./Home.css"

const Banner = () => {
  return (
    <section className="home-banner banner-bg">
          <div className="container">
            <div className="home-banner__text">
              <h1>Top Software Jobs With The Top Companies</h1>
              <p>
              {/* Join our brilliant talent pool and get placed with the best global software jobs. */}
              Find Jobs, Employment & Career Opportunities
              </p>
              <div>
                <Link to="/sign-up" className="btn">
                  Apply Now
                </Link>
              </div>
            </div>
            <div className="home-banner__img">
              <figure>
                {/* <img src={HomeBannerImg} alt="" /> */}
                <img src="https://ik.imagekit.io/olibr/assets/tr:w-480,h-480/Olibr-home-banner.webp" alt="Olibr home banner" />;
              </figure>
            </div>
          </div>
        </section>
  )
}

export default Banner