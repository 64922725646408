import React from "react";
import { Fragment } from "react";
import UpperBoxes from "./UpperBoxes";
import DetailedReportTable from "./DetailedReportTable";

const DetailedReport = ({ report, resultCard }) => {
  let finalScores = {
    maxScore: resultCard?.maxScore,
    obtainedScore: resultCard?.obtainedScore,
    isPassed: resultCard?.isPassed,
    percentageScore: resultCard?.percentageScore
  };
  return (
    <Fragment>
      <UpperBoxes isPlag={report?.plagiarism_status} scores={finalScores}/>
      <DetailedReportTable questions={report?.questions} />
    </Fragment>
  );
};

export default DetailedReport;
