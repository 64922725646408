import React, { Fragment, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Icon } from '../../../components/icon';
import Modal from '../../../components/modal/ModalBox';
import Paginations from '../../../components/pagination/paginations';
import { deleteQueriesAction, getAllQueries, getUser, updateQueriesAction } from '../../../service/action/admin';
import { useContext } from 'react';
import { MainContext } from '../../../context/MainContext';
import { checkPermission, readOnlyCheck } from '../../../components/functions';

const ContactUsQueries = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(()=>{
     dispatch(getUser(null,navigate))
  },[])
  const {user:{access}}=useContext(MainContext)
  const readOnly=readOnlyCheck(access,"Queries")
 


  const { queryList ,isLoadingQueryList} = useSelector((state) => state.adminReducer);
  const [pageData, setPageData] = useState({
    page: 1,
    limit: 13,
    search: "",
  });
  const fetchMoreData=()=>{
    setPageData({
      ...pageData,
      limit:pageData?.limit+13
    })
  }

  useEffect(() => {
    dispatch(getAllQueries(navigate, pageData));
  }, [pageData.page,pageData?.limit, dispatch, navigate]);

  const handleQueryStatus = (resolved, id) => {
    dispatch(updateQueriesAction({ queryStatus: !resolved, queryId: id }, pageData, navigate));
  }

  const [isOpen, setOpen] = useState(false);
  const [messageValue, setMessageValue] = useState("");
  const handleContent = () => {
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <textarea
              className='form-control'
              rows={10}
              defaultValue={messageValue} />
            <br />
          </div>
        </div>
      </Fragment>
    )
  }

  const openModal = (message) => {
    setMessageValue(message)
    setOpen(true);
  }

  const handleDeleteQuery = (id) => {
    dispatch(deleteQueriesAction(id, pageData, navigate));
  }

  return (
    <div className="main-div-developer">
      <Modal
        title={"Query"}
        open={isOpen}
        isFooterView={false}
        onClose={() => setOpen(false)}
        content={handleContent}
      />
      <div className="skills-management">
        <InfiniteScroll
          height={"70vh"}
          dataLength={
            queryList?.data ? queryList?.data?.length : 0
          }
          next={fetchMoreData}
          hasMore={
            queryList?.totalDataCount <= queryList?.data?.length
              ? false
              : true
          }
          loader={
            isLoadingQueryList && (
              <div style={{ textAlign: "center" }}>
                {" "}
                <ClipLoader color="#3BA0AA" />{" "}
              </div>
            )
          }
        >
          <table className='table' border="0" cellPadding="0" cellSpacing="0">
            <thead>
              <tr>
                {/* <th>S. No</th> */}
                <th>Name</th>
                <th>Email</th>
                <th>Mobile Number</th>
                <th>Subject</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {
                queryList?.data?.map((item, index) =>
                  <tr key={index} style={{ cursor: "pointer" }} onClick={() =>{ openModal(item?.message)}}>
                    <td>{item?.fullName}</td>
                    <td>{item?.email}</td>
                    <td>{item?.mobileNumber}</td>

                    <td>
                      <span>
                        {item?.subject}
                      </span>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        name="switch"
                        onChange={(e) => e.preventDefault()}
                        checked={item?.isQueryResolved}
                      />
                      <label
                        onClick={(e) => {
                          e.stopPropagation();
                          if (checkPermission(readOnly)) return false
                          handleQueryStatus(item?.isQueryResolved, item?._id);
                        }}
                        className="label"
                        htmlFor="switch3"
                      ></label>
                    </td>
                    <td>
                      <span onClick={(e) => {
                           e.stopPropagation();
                           if (checkPermission(readOnly)) return false
                        handleDeleteQuery(item?._id)}}>
                        <Icon name="delete" />
                      </span>
                    </td>
                  </tr>
                )
              }

            </tbody>
          </table>
          </InfiniteScroll>
      </div>
      {/* <Paginations
               onNext={handleNext}
               onPrev={handlePrev}
               data={queryList}
               setPage={setPageData}
               pageData={pageData}
             /> */}
    </div>
  )
}

export default ContactUsQueries;