import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { clientSignupOG } from "../imageLinks";
import NoFollow from "../../../common/staticData/seo/NoFollow";

const ClientSignUp = ({ baseUrl }) => {
  return (
    <Helmet>
      <title>Sign up with olibr to hire software developers & engineers</title>
      <meta charset="UTF-8" />
      <meta
        name="title"
        content="Sign up with olibr to hire software developers & engineers"
      />
      <meta
        name="description"
        content="Hire pre-vetted software developers proficient in over 20 technologies and skills such as React, Python, Node, AWS, React Native, JavaScript and more."
      ></meta>
      <link rel="canonical" href={`${baseUrl}/sign-up-client`} />
      <meta
        name="keywords"
        content="Signup Clients, olibr.com, tech jobs, olibr, olibr.com, Hiring developers, remote software developers, work-from-home developers, wfh, remote jobs, IT jobs, US based IT companies, US Jobs, US IT Jobs"
      />
       <meta name="robots" content={NoFollow()}></meta>

      <meta name="language" content="english"></meta>
      <html lang="en" />
      <meta name="url" content={`${baseUrl}/sign-up-client`} />
      <meta name="identifier-URL" content={`${baseUrl}/sign-up-client`}></meta>
      <meta name="referrer" content="origin-when-crossorigin"></meta>
      <link rel="image_src" href={clientSignupOG} />
      <meta name="copyright" content="olibr.com" />

      <meta
        property="og:title"
        content="Sign up with olibr to hire software developers & engineers"
      />
      <meta
        property="og:description"
        content="Hire pre-vetted software developers proficient in over 20 technologies and skills such as React, Python, Node, AWS, React Native, JavaScript and more."
      />
      <meta property="og:url" content={`${baseUrl}/sign-up-client`} />
      <meta property="og:image" content={clientSignupOG} />
      <meta property="og:site_name" content="Olibr" />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@olibr" />
      <meta property="twitter:domain" content="olibr.com" />
      <meta property="twitter:url" content={`${baseUrl}/sign-up-client`} />
      <meta
        name="twitter:title"
        content="Sign up with olibr to hire software developers & engineers"
      />
      <meta
        name="twitter:description"
        content="Hire pre-vetted software developers proficient in over 20 technologies and skills such as React, Python, Node, AWS, React Native, JavaScript and more."
      />
      <meta name="twitter:image" content={clientSignupOG} />

      <meta property="og:type" content="business.business" />
      <meta
        property="business:contact_data:street_address"
        content="26 Arrowhead Ln"
      />
      <meta property="business:contact_data:locality" content="Menlo Park" />
      <meta property="business:contact_data:region" content="CA" />
      <meta property="business:contact_data:postal_code" content="94025" />
      <meta property="business:contact_data:country_name" content="US" />

      {/* ------Apple Devices ------ */}
      <meta
        name="apple-mobile-web-app-title"
        content="Sign up with olibr to hire software developers & engineers"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-touch-fullscreen" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black"></meta>

      {/* ----------JSON LD Schema for SEO------ */}

      <script type="application/ld+json">
        {JSON.stringify(
          // breadcrumbs---
          {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: `${baseUrl}/`,
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Client Sign Up",
                item: `${baseUrl}/sign-up-client`,
              },
            ],
          }
        )}
      </script>
      {/* ------- */}
      <script type="application/ld+json">
        {JSON.stringify(
          // Speakable ---
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            name: "Register with Olibr to hire remote developers",
            speakable: {
              "@type": "SpeakableSpecification",
              xpath: [
                "/html/head/title",
                "/html/head/meta[@name='description']/@content",
              ],
            },
            url: `${baseUrl}/sign-up-client`,
          }
        )}
      </script>
      {/* ------------ */}
      <script type="application/ld+json">
        {JSON.stringify(
          //  Website/webpage schema--
          {
            "@context": "http://www.schema.org",
            "@type": "WebSite",
            name: "Signup Olibr Clients | Top US Companies | Hire Remote Software Developers",
            url: `${baseUrl}/sign-up-client`,
          }
        )}
      </script>
    </Helmet>
  );
};

export default ClientSignUp;
