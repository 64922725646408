import React, { Fragment, useEffect, useState } from "react";
import { Icon } from "../../../../components/icon";
import Listing from "./Listing";
import Modal from "../../../../components/modal/ModalBox";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteJobCatAction,
  getJobCatAction,
  postJobCatAction,
  updateJobCatAction,
} from "../../../../service/action/admin";
import { olibrToast } from "../../../../components/toaster";

const CategoryList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { jobCatList } = useSelector((state) => state.adminReducer);
  const [modalState, setModalState] = useState({
    isOpen: false,
    isEdit: false,
  });

  const [postData, setPostData] = useState({
    categoryName: "",
    catTags: [],
  });

  const closeModal = () => {
    setModalState({
      isOpen: false,
      isEdit: false,
    });
    setPostData({
      categoryName: "",
      catTags: [],
    });
  };

  const openModal = (isEdit) => {
    setModalState({
      isOpen: true,
      isEdit: isEdit,
    });
  };

  const onDeleteCat = () => {
    const id = postData?.catId;
    dispatch(deleteJobCatAction(id, navigate, closeModal));
  };

  const submitCategory = () => {
    let newObj = postData;
    let catId = newObj.catId;
    delete newObj["catId"];
    modalState.isEdit
      ? dispatch(updateJobCatAction(catId, newObj, navigate, closeModal))
      : dispatch(postJobCatAction(newObj, navigate, closeModal));
  };

  const [tag, setTag] = useState("");
  const addTagToCat = () => {
    if (!tag) {
      olibrToast.error("please enter skill name!");
      return false;
    } else {
      const check = postData?.catTags?.filter(
        (item) => item?.toUpperCase() == tag?.toUpperCase()
      );
      if (check?.length > 0) {
        olibrToast.error("tag already exists!");
      } else {
        setPostData({ ...postData, catTags: [...postData.catTags, tag] });
      }
    }
    setTag("");
  };

  const modalContent = () => {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="floating-label" style={{ zIndex: 0 }}>
                Category Name
              </label>
              <input
                type="text"
                className="form-control"
                value={postData?.categoryName}
                onChange={(e) =>
                  setPostData({
                    ...postData,
                    categoryName: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="floating-label" htmlFor="">
                Add Tags
              </label>
              <div style={{ display: "flex" }}>
                <input
                  type="text"
                  name="tags"
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                  //   {...blogRegister("tags")}
                  className="form-control"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      addTagToCat();
                    }
                  }}
                />
                <a onClick={() => addTagToCat()}>
                  <Icon name="add-icon" />
                  {/* <img
                    style={{ height: "30px", width: "30px" }}
                    src={add}
                    alt=""
                  /> */}
                </a>
              </div>
            </div>
          </div>
          <div className="col-12">
            <ul className="tags tags-fill">
              {postData?.catTags?.map((item, index) => (
                <li>
                  <a>
                    <span>{item}</span>{" "}
                    <i
                      onClick={() => {
                        const tempSkill = [...postData?.catTags];
                        tempSkill.splice(index, 1);
                        setPostData({
                          ...postData,
                          catTags: tempSkill,
                        });
                      }}
                    >
                      <Icon name="modal-close" />
                    </i>
                  </a>
                </li>
              ))}
            </ul>
            <br />
          </div>
        </div>
      </>
    );
  };

  return (
    <Fragment>
      <Modal
        open={modalState.isOpen}
        title={modalState?.isEdit ? "Edit Category" : "Add Category"}
        onClose={closeModal}
        content={() => modalContent()}
        onCancel={closeModal}
        onDone={submitCategory}
        isDeletable={modalState?.isEdit}
        onDelete={onDeleteCat}
      />
      <div className="main-div-developer">
        <div className="skill-management">
          <div className="block-header">
            <h4 className="">All Job Categories</h4>
            <span onClick={() => openModal(false)}>
              <Icon name="add-icon" />
            </span>
          </div>
          <div className="row"></div>
          <br />
          <div className={`table`}>
            <Listing
              jobCatList={jobCatList}
              setModalState={setModalState}
              setPostData={setPostData}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CategoryList;
