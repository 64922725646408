import { StyleSheet } from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";
import popinMidium from "../../../../assets/fonts/Poppins-Medium.ttf";
import popinLight from "../../../../assets/fonts/Poppins-Light.ttf";
import popinRegular from "../../../../assets/fonts/Poppins-Regular.ttf";

Font.register({
  family: "Poppins-Medium",
  fonts: [{ src: popinMidium, fontWeight: "normal" }],
});

Font.register({
  family: "Poppins-Light",
  fonts: [{ src: popinLight, fontWeight: "normal" }],
});
Font.register({
  family: "Poppins-Regular",
  fonts: [{ src: popinRegular, fontWeight: "normal" }],
});
export const Skill = StyleSheet.create({
  skillTitle: {
    fontFamily: "Poppins-Regular",
    fontSize: "11px",
    display: "block"
  },
  leftLayout: {
    marginTop: "10px",
  },
  rightLayout: {
    fontSize: "12px",
    width: "445px",
    fontFamily: "Poppins-Regular",
    marginLeft: "10px",
    marginTop: "10px",
    // flexDirection:"column"
  },
  technichalSkill: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  technichalSkillTitle: {
    backgroundColor: "#edf5fd",
    padding: "1px 10px",
    fontSize: "9px",
    borderRadius: "8px",
    // marginLeft: ""
  },
  skillLists: {
    marginRight: "5px",
    marginTop: "2px",
    padding: "2px 4px"
  },
});
