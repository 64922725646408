import { monthArr } from "../../../common/constant/monthArr";
import * as types from "../../action";

const initialState = {
  devLists: [],
  devListLoading: false,
  isLoading: false,
  devUser: [],
  devUserLoading: false,
  //IT WILL BE REMVOE AFTER ALL API INTEGRATE
  tempData: {},
  ActionFaqGroup: {},
  ActionJobManagement: {},
  ActionFaqGroupCommon: {},
  isLoadingSkillList: false,
  isIconUploading: false,
  isStackLoading: false,
  stackList: [],
  skillList: [],
  allJobLists: {},
  assessmentList: [],
  topicList: [],
  skillIcons: [],
  uploadIcon: {},
  toolList: [],
  isLoadingAllTools: false,
  allQuestions: [],
  userData: [],
  blogListResp: [],
  categoryListResp: [],
  allClientListAdmin: {},
  isAllClientListAdminLoading: false,
  clientDetailAdmin: {},
  isClientDetailLoading: false,
  clientJobListAdmin: {},
  isClientJobListAdminLoading: false,
  clientFullJob: {},
  isClientFullJobAdminLoading: false,
  isBlogsSyncup: false,
  updateSpotLightMessage: {},
  getTemplateList: [],
  postTemplateList: {},
  deleteTemplateList: {},
  updateTemplateList: {},
  emailHistory: [],
  adminShortlist: [],
  adminHired: [],
  adminDevAcademic: [],
  adminDevCertification: [],
  adminDevSocialProfileData: [],
  adminDevEmployementeData: [],
  adminDevProjectData: [],
  adminDevToolData: [],
  adminChooseSkill: [],
  adminDevAchievementData: [],
  isAdminDevProFileLoad: false,
  isUpdating: false,
  profileUpdateStatus: "",
  postBlogNewLoading: false,
  updateBlogNewLoading: false,
  deleteBlogNewLoading: false,
  isEmailTempLoadinf: false,
  devAdminEnglishResult: {},
  devSkillTestResult: {},
  updateSkills: false,
  saveEnglishClearance: false,
  isUpdateLoading: false,
  skillCategory: [],
  skillWithCategory: [],
  stackWithCategory: [],
  filter: {
    fName: "",
    lName: "",
    email: "",
    status: "all",
    mobNumber: "",
    registrationDate: "",
  },
  isUserInviting: false,
  isLoadingSaveSkill: false,
  isStackSaveLoading: false,
  isStackWithCat: false,
  isSkillWithCat: false,
  allEmailSlugs: {},
  emailCount: [],
  isRejectLoading: false,
};

export const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DEVELOPER_LIST_START:
      return { ...state, devListLoading: true };
    case types.GET_DEVELOPER_LIST_SUCCESS:
      let tempDevLists = state?.devLists?.data && [...state.devLists?.data];
      if (!!tempDevLists?.length && action?.apiType !== "filter") {
        tempDevLists = [...tempDevLists, ...action?.data?.data];
      } else {
        tempDevLists = [...action?.data?.data];
      }
      return {
        ...state,
        devListLoading: false,
        devLists: { ...action?.data, data: tempDevLists },
      };
    case types.GET_DEVELOPER_LIST_FAIL:
      return { ...state, devListLoading: false };

    case types.UPDATE_JOB_CAT_START:
      return { ...state, updatejobCatLoading: true, updateJobCatData: {} };
    case types.UPDATE_JOB_CAT_SUCCESS:
      return {
        ...state,
        updatejobCatLoading: false,
        updateJobCatData: action?.data,
      };
    case types.UPDATE_JOB_CAT_FAIL:
      return { ...state, updatejobCatLoading: false, updateJobCatData: {} };

    case types.DELETE_JOB_CAT_START:
      return { ...state, deletejobCatLoading: true, deleteJobCatData: {} };
    case types.DELETE_JOB_CAT_SUCCESS:
      return {
        ...state,
        deletejobCatLoading: false,
        deleteJobCatData: action?.data,
      };
    case types.DELETE_JOB_CAT_FAIL:
      return { ...state, deletejobCatLoading: false, deleteJobCatData: {} };

    case types.GET_JOB_CAT_START:
      return { ...state, jobCatListLoading: true, jobCatList: [] };
    case types.GET_JOB_CAT_SUCCESS:
      return { ...state, jobCatListLoading: false, jobCatList: action?.data };
    case types.GET_JOB_CAT_FAIL:
      return { ...state, jobCatListLoading: false, jobCatList: [] };

    case types.ADD_JOB_CAT_START:
      return { ...state, postJobCatLoading: true, postJobCatData: {} };
    case types.ADD_JOB_CAT_SUCCESS:
      return {
        ...state,
        postJobCatLoading: false,
        postJobCatData: action?.data,
      };
    case types.ADD_JOB_CAT_FAIL:
      return { ...state, postJobCatLoading: false, postJobCatData: {} };

    case types.DEV_UPDATE_STATUS_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.DEV_UPDATE_STATUS_SUCCESS:
      return { ...state, isLoading: false, tempData: action.data };
    case types.DEV_UPDATE_STATUS_FAIL:
      return { ...state, isLoading: false };

    case types.GET_ALL_EMAILS_SLUGS_START:
      return { ...state, isLoading: true, allEmailSlugs: {} };
    case types.GET_ALL_EMAILS_SLUGS_SUCCESS:
      return { ...state, isLoading: false, allEmailSlugs: action.data };
    case types.GET_ALL_EMAILS_SLUGS_FAIL:
      return { ...state, isLoading: false, allEmailSlugs: {} };

    case types.UPDATE_PLAGIARISM_STATUS_START:
      return { ...state, isLoading: true, updatedPlagStatus: {} };
    case types.UPDATE_PLAGIARISM_STATUS_SUCCESS:
      return { ...state, isLoading: false, updatedPlagStatus: action.data };
    case types.UPDATE_PLAGIARISM_STATUS_FAIL:
      return { ...state, isLoading: false, updatedPlagStatus: {} };

    case types.VERIFY_DEV_EMAIL_START:
      return { ...state, verifyDevLoading: true, verifyDevEmailReducer: {} };
    case types.VERIFY_DEV_EMAIL_SUCCESS:
      return {
        ...state,
        verifyDevLoading: false,
        verifyDevEmailReducer: action.data,
      };
    case types.VERIFY_DEV_EMAIL_FAIL:
      return { ...state, verifyDevLoading: false, verifyDevEmailReducer: {} };

    case types.APPROVE_DEV_EMAIL_START:
      return { ...state, approveDevLoading: true, approveDevEmailReducer: {} };
    case types.APPROVE_DEV_EMAIL_SUCCESS:
      return {
        ...state,
        approveDevLoading: false,
        approveDevEmailReducer: action.data,
      };
    case types.APPROVE_DEV_EMAIL_FAIL:
      return { ...state, approveDevLoading: false, approveDevEmailReducer: {} };

    case types.GET_QUERIES_LIST_START:
      return { ...state, isLoadingQueryList: true };
    case types.GET_QUERIES_LIST_SUCCESS:
      return { ...state, isLoadingQueryList: false, queryList: action?.data };
    case types.GET_QUERIES_LIST_FAIL:
      return { ...state, isLoadingQueryList: false, queryList: [] };

    case types.UPDATE_QUERY_START:
      return { ...state, isLoadingUpdateQ: true, updateQueryRed: {} };
    case types.UPDATE_QUERY_SUCCESS:
      return {
        ...state,
        isLoadingUpdateQ: false,
        updateQueryRed: action?.data,
      };
    case types.UPDATE_QUERY_FAIL:
      return { ...state, isLoadingUpdateQ: false, updateQueryRed: {} };

    case types.UPDATE_FAQ_STATUS_START:
      return { ...state, faqStUpdateLoading: true };
    case types.UPDATE_FAQ_STATUS_SUCCESS:
      // let faqs = state?.faqGroupLists?.map((item))
      return { ...state, faqStUpdateLoading: false };
    case types.UPDATE_FAQ_STATUS_FAIL:
      return { ...state, faqStUpdateLoading: false };

    case types.UPDATE_FAQ_GROUP_STATUS_START:
      return { ...state, faqGStUpdateLoading: true };
    case types.UPDATE_FAQ_GROUP_STATUS_SUCCESS:
      // console.log("state faq group list" ,state.faqGroupLists)
      return { ...state, faqGStUpdateLoading: false };
    case types.UPDATE_FAQ_GROUP_STATUS_FAIL:
      return { ...state, faqGStUpdateLoading: false };

    case types.DELETE_QUERY_START:
      return { ...state, isLoadingDeleteQ: true, deleteQueryRed: {} };
    case types.DELETE_QUERY_SUCCESS:
      return {
        ...state,
        isLoadingDeleteQ: false,
        deleteQueryRed: action?.data,
      };
    case types.DELETE_QUERY_FAIL:
      return { ...state, isLoadingDeleteQ: false, deleteQueryRed: {} };

    case types.GET_ADMIN_SKILL_LIST_START:
      return { ...state, isLoadingSkillList: false };
    case types.GET_ADMIN_SKILL_LIST_SUCCESS:
      return { ...state, isLoadingSkillList: false, skillList: action?.data };
    case types.GET_ADMIN_SKILL_LIST_FAIL:
      return { ...state, isLoadingSkillList: false, skillList: [] };

    case types.UPDATE_SKILL_STATUS_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.UPDATE_SKILL_STATUS_SUCCESS:
      const tempSkillLists = [...state.skillWithCategory];
      tempSkillLists?.map((item, ind) => {
        item[action?.category]?.map((itm, index) => {
          if (action?.data?.skillId == itm?._id) {
            tempSkillLists[ind][action.category][index].skillStatus =
              action?.data?.skillStatus;
          }
        });
      });
      return {
        ...state,
        isLoading: false,
        skillWithCategory: tempSkillLists,
      };
    case types.UPDATE_SKILL_STATUS_FAIL:
      return { ...state, isLoading: false };

    case types.ADD_STACK_START:
      return { ...state, isStackSaveLoading: true, tempData: {} };
    case types.ADD_STACK_SUCCESS:
      return { ...state, isStackSaveLoading: false, tempData: action.data };
    case types.ADD_STACK_FAIL:
      return { ...state, isStackSaveLoading: false };

    case types.PROFILE_UPDATE_REQUEST_APPROVE_REJECT_START:
      if (action?.data?.feedback) {
        return { ...state, isRejectLoading: true, profileUpdateStatus: "" };
      } else {
        return { ...state, isUpdating: true, profileUpdateStatus: "" };
      }

    case types.PROFILE_UPDATE_REQUEST_APPROVE_REJECT_SUCCESS:
      return {
        ...state,
        isRejectLoading: false,
        isUpdating: false,
        profileUpdateStatus: "success",
      };
    case types.PROFILE_UPDATE_REQUEST_APPROVE_REJECT_FAIL:
      return { ...state, isUpdating: false, profileUpdateStatus: "" };

    case types.EDIT_STACK_START:
      return { ...state, isStackSaveLoading: true, tempData: {} };
    case types.EDIT_STACK_SUCCESS:
      return { ...state, isStackSaveLoading: false, tempData: action.data };
    case types.EDIT_STACK_FAIL:
      return { ...state, isStackSaveLoading: false };

    case types.GET_ADMIN_STACK_LIST_START:
      return { ...state, isStackLoading: false };
    case types.GET_ADMIN_STACK_LIST_SUCCESS:
      return { ...state, isStackLoading: false, stackList: action?.data };
    case types.GET_ADMIN_STACK_LIST_FAIL:
      return { ...state, isStackLoading: false };

    case types.UPDATE_STACK_STATUS_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.UPDATE_STACK_STATUS_SUCCESS:
      return { ...state, isLoading: false, tempData: action.data };
    case types.UPDATE_STACK_STATUS_FAIL:
      return { ...state, isLoading: false };

    case types.GET_INTERVIEW_LIST_START:
      return { ...state, isLoadingInterList: true, interviewListReducer: {} };
    case types.GET_INTERVIEW_LIST_SUCCESS:
      return {
        ...state,
        isLoadingInterList: false,
        interviewListReducer: action?.data,
      };
    case types.GET_INTERVIEW_LIST_FAIL:
      return { ...state, isLoadingInterList: false, interviewListReducer: {} };

    case types.GET_ALL_TOOLS_START:
      return { ...state, isLoadingAllTools: true };
    case types.GET_ALL_TOOLS_SUCCESS:
      return { ...state, isLoadingAllTools: false, toolList: action?.data };
    case types.GET_ALL_TOOLS_FAIL:
      return { ...state, isLoadingAllTools: false };

    case types.GET_SPEAK_WITH_DEV_LIST_START:
      return { ...state, isLoadingAllTools: true, speakDevList: {} };
    case types.GET_SPEAK_WITH_DEV_LIST_SUCCESS:
      return { ...state, isLoadingAllTools: false, speakDevList: action?.data };
    case types.GET_SPEAK_WITH_DEV_LIST_FAIL:
      return { ...state, isLoadingAllTools: false, speakDevList: {} };

    case types.REFRESH_XOBIN_ASSESSMENT_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.REFRESH_XOBIN_ASSESSMENT_SUCCESS:
      return { ...state, isLoading: false, tempData: action.data };
    case types.REFRESH_XOBIN_ASSESSMENT_FAIL:
      return { ...state, isLoading: false };

    case types.RENDER_ALL_HACKER_RANK_ASSESSMENT_START:
      return { ...state, isLoading: true };
    case types.RENDER_ALL_HACKER_RANK_ASSESSMENT_SUCCESS:
      return { ...state, isLoading: false, assessmentList: action.data.data };
    case types.RENDER_ALL_HACKER_RANK_ASSESSMENT_FAIL:
      return { ...state, isLoading: false };

    case types.RENDER_ALL_HACKER_RANK_ASSESSMENT_NO_LIMIT_START:
      return { ...state, isLoading: true };
    case types.RENDER_ALL_HACKER_RANK_ASSESSMENT_NO_LIMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        assessmentListNoLimit: action?.data?.data,
      };
    case types.RENDER_ALL_HACKER_RANK_ASSESSMENT_NO_LIMIT_FAIL:
      return { ...state, isLoading: false };

    case types.UPDATE_EMAIL_SETTING_STATUS_START:
      return { ...state, isLoading: true };
    case types.UPDATE_EMAIL_SETTING_STATUS_SUCCESS:
      let getTemplateList = [...state.getTemplateList.data];
      console.log("UPDATE_EMAIL_SETTING_STATUS_SUCCESS", action?.data);
      getTemplateList[
        getTemplateList.findIndex(
          (item) => item?._id === action?.data?.body?.templateId
        )
      ][action?.data?.body?.templateFor] = action?.data?.body?.status;
      return {
        ...state,
        isLoading: false,
        updateEmailSetting: action?.data?.data,
      };
    case types.UPDATE_EMAIL_SETTING_STATUS_FAIL:
      return { ...state, isLoading: false };

    case types.ADD_SKILL_START:
      return { ...state, isLoadingSaveSkill: true, tempData: {} };
    case types.ADD_SKILL_SUCCESS:
      return { ...state, isLoadingSaveSkill: false, tempData: action.data };
    case types.ADD_SKILL_FAIL:
      return { ...state, isLoadingSaveSkill: false };

    case types.EDIT_SKILL_START:
      return { ...state, isLoadingSaveSkill: true, tempData: {} };
    case types.EDIT_SKILL_SUCCESS:
      return { ...state, isLoadingSaveSkill: false, tempData: action.data };
    case types.EDIT_SKILL_FAIL:
      return { ...state, isLoadingSaveSkill: false };

    case types.RENDER_TOPIC_LIST_START:
      return { ...state, isLoading: true };
    case types.RENDER_TOPIC_LIST_SUCCESS:
      return { ...state, isLoading: false, topicList: action?.data?.data };
    case types.RENDER_TOPIC_LIST_FAIL:
      return { ...state, isLoading: false };

    case types.GET_ALL_ICONS_START:
      return { ...state, isLoading: true };
    case types.GET_ALL_ICONS_SUCCESS:
      return { ...state, isLoading: false, skillIcons: action?.data?.icons };
    case types.GET_ALL_ICONS_FAIL:
      return { ...state, isLoading: false };

    case types.UPLOAD_ICONS_START:
      return { ...state, isIconUploading: true, uploadIcon: {} };
    case types.UPLOAD_ICONS_SUCCESS:
      return { ...state, isIconUploading: false, uploadIcon: action.data };
    case types.UPLOAD_ICONS_FAIL:
      return { ...state, isIconUploading: false };

    case types.JOB_APPROVED_OR_REJECT_START:
      return { ...state, isJobAppLoading: true, jobApproved: {} };
    case types.JOB_APPROVED_OR_REJECT_SUCCESS:
      const allJobLists = [...state.allJobLists.data];
      let obj =
        allJobLists[
          allJobLists?.findIndex((item) => item?._id == action?.data?.data?._id)
        ];
      obj.isApproved = action?.data?.data?.isApproved;
      obj.status = "open";
      return { ...state, isJobAppLoading: false, jobApproved: action.data };
    case types.JOB_APPROVED_OR_REJECT_FAIL:
      return { ...state, isJobAppLoading: false, jobApproved: {} };

    case types.DELETE_STACK_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.DELETE_STACK_SUCCESS:
      return { ...state, isLoading: false, tempData: action.data };
    case types.DELETE_STACK_FAIL:
      return { ...state, isLoading: false };

    case types.DELETE_SKILL_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.DELETE_SKILL_SUCCESS:
      return { ...state, isLoading: false, tempData: action.data };
    case types.DELETE_SKILL_FAIL:
      return { ...state, isLoading: false };

    case types.GET_DEVELOPER_PROFILE_START:
      return { ...state, devUserLoading: true };
    case types.GET_DEVELOPER_PROFILE_SUCCESS:
      return { ...state, devUserLoading: false, devUser: action?.data };
    case types.GET_DEVELOPER_PROFILE_FAIL:
      return { ...state, devUserLoading: false, devUser: [] };

    case types.GET_SPOTLIGHT_HISTORY_START:
      return {
        ...state,
        spotLightHistoryListLoading: true,
        spotLightHistoryList: [],
      };
    case types.GET_SPOTLIGHT_HISTORY_SUCCESS:
      return {
        ...state,
        spotLightHistoryListLoading: false,
        spotLightHistoryList: action?.data,
      };
    case types.GET_SPOTLIGHT_HISTORY_FAIL:
      return {
        ...state,
        spotLightHistoryListLoading: false,
        spotLightHistoryList: [],
      };

    case types.RESUME_PARSING_ADMIN_START:
      return { ...state, isResumeLoading: true, adminResumeData: [] };
    case types.RESUME_PARSING_ADMIN_SUCCESS:
      let tempUserss = [...state?.devUser];
      tempUserss = tempUserss[0];
      tempUserss.devResume = action?.data?.resume;
      tempUserss.devTellUrStory = action?.data?.data?.ProfessionalSummary;
      return {
        ...state,
        isResumeLoading: false,
        adminResumeData: action?.data?.data,
        devUser: [tempUserss],
      };
    case types.RESUME_PARSING_ADMIN_FAIL:
      return { ...state, isResumeLoading: false, adminResumeData: [] };

    case types.UPDATE_PROFILE_STATUS_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.UPDATE_PROFILE_STATUS_SUCCESS:
      return { ...state, isLoading: false };
    case types.UPDATE_PROFILE_STATUS_FAIL:
      return { ...state, isLoading: false };

    case types.RESULT_PARSING_START:
      return { ...state, resultParsingLoading: true, tempData: {} };
    case types.RESULT_PARSING_SUCCESS:
      return { ...state, resultParsingLoading: false, tempData: action.data };
    case types.RESULT_PARSING_FAIL:
      return { ...state, resultParsingLoading: false };

    case types.SET_MEMBER_PASSWORD_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.SET_MEMBER_PASSWORD_SUCCESS:
      return { ...state, isLoading: false, tempData: action.data };
    case types.SET_MEMBER_PASSWORD_FAIL:
      return { ...state, isLoading: false };

    case types.ADD_USER_START:
      return { ...state, isUserInviting: true, tempData: {} };
    case types.ADD_USER_SUCCESS:
      return { ...state, isUserInviting: false, tempData: {} };
    case types.ADD_USER_FAIL:
      return { ...state, isUserInviting: false, tempData: {} };

    case types.GET_USER_BY_ID_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.GET_USER_BY_ID_SUCCESS:
      return { ...state, isLoading: false, tempData: action.data };
    case types.GET_USER_BY_ID_FAIL:
      return { ...state, isLoading: false };

    case types.UPDATE_USER_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.UPDATE_USER_SUCCESS:
      return { ...state, isLoading: false, tempData: action.data };
    case types.UPDATE_USER_FAIL:
      return { ...state, isLoading: false };

    case types.USER_LISTING_START:
      return { ...state, isUserListLoading: true };
    case types.USER_LISTING_SUCCESS:
      return { ...state, isUserListLoading: false, userData: action.data };
    case types.USER_LISTING_FAIL:
      return { ...state, isUserListLoading: false };

    case types.GET_DEV_RESULT_START:
      return { ...state, skillTestLoading: true, resultDevData: {} };
    case types.GET_DEV_RESULT_SUCCESS:
      return { ...state, skillTestLoading: false, resultDevData: action.data };
    case types.GET_DEV_RESULT_FAIL:
      return { ...state, skillTestLoading: false, resultDevData: {} };

    case types.GET_CATEGORY_LIST_START:
      return { ...state, isLoading: true };
    case types.GET_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categoryListResp: action?.data?.data,
      };
    case types.GET_CATEGORY_LIST_FAIL:
      return { ...state, isLoading: false };

    case types.ADD_CATEGORY_BLOG_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.ADD_CATEGORY_BLOG_SUCCESS:
      return { ...state, isLoading: false, tempData: action.data };
    case types.ADD_CATEGORY_BLOG_FAIL:
      return { ...state, isLoading: false };

    case types.ALL_BLOG_NAME_AND_ID_START:
      return { ...state, isLoading: true, blogNameIdList: {} };
    case types.ALL_BLOG_NAME_AND_ID_SUCCESS:
      return { ...state, isLoading: false, blogNameIdList: action.data };
    case types.ALL_BLOG_NAME_AND_ID_FAIL:
      return { ...state, isLoading: false, blogNameIdList: {} };

    case types.DELETE_CATEGORY_BLOG_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.DELETE_CATEGORY_BLOG_SUCCESS:
      return { ...state, isLoading: false, tempData: action.data };
    case types.DELETE_CATEGORY_BLOG_FAIL:
      return { ...state, isLoading: false };

    case types.UPDATE_CATEGORY_BLOG_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.UPDATE_CATEGORY_BLOG_SUCCESS:
      return { ...state, isLoading: false, tempData: action.data };
    case types.UPDATE_CATEGORY_BLOG_FAIL:
      return { ...state, isLoading: false };

    case types.GET_BLOG_LIST_START:
      return { ...state, isLoading: true };
    case types.GET_BLOG_LIST_SUCCESS:
      return { ...state, isLoading: false, blogListResp: action.data };
    case types.GET_BLOG_LIST_FAIL:
      return { ...state, isLoading: false };

    case types.POST_BLOG_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.POST_BLOG_SUCCESS:
      return { ...state, isLoading: false, tempData: action.data };
    case types.POST_BLOG_FAIL:
      return { ...state, isLoading: false };

    case types.UPDATE_BLOG_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.UPDATE_BLOG_SUCCESS:
      return { ...state, isLoading: false, tempData: action.data };
    case types.UPDATE_BLOG_FAIL:
      return { ...state, isLoading: false };

    case types.DELETE_BLOG_START:
      return { ...state, isLoading: true, tempData: {} };
    case types.DELETE_BLOG_SUCCESS:
      return { ...state, isLoading: false, tempData: action.data };
    case types.DELETE_BLOG_FAIL:
      return { ...state, isLoading: false };

    case types.UPDATE_FAQ_GROUP_START:
      return { ...state, isLoading: true, ActionFaqGroupCommon: {} };
    case types.UPDATE_FAQ_GROUP_SUCCESS:
      return { ...state, isLoading: false };
    case types.UPDATE_FAQ_GROUP_FAIL:
      return { ...state, isLoading: false, ActionFaqGroupCommon: {} };

    case types.ENGLISH_ALL_QUESTION_START:
      return { ...state, isLoading: true };
    case types.ENGLISH_ALL_QUESTION_SUCCESS:
      return { ...state, isLoading: false, allQuestions: action?.data };
    case types.ENGLISH_ALL_QUESTION_FAIL:
      return { ...state, isLoading: false };

    case types.UPDATE_ENGLISH_QUESTION_START:
      return { ...state, isLoading: true, updateQuestion: {} };
    case types.UPDATE_ENGLISH_QUESTION_SUCCESS:
      return { ...state, isLoading: false, updateQuestion: action?.data };
    case types.UPDATE_ENGLISH_QUESTION_FAIL:
      return { ...state, isLoading: false, updateQuestion: {} };

    case types.GET_ALL_JOB_START:
      return { ...state, isJobListsLoading: true };
    case types.GET_ALL_JOB_SUCCESS:
      let tempArr = { ...state.allJobLists };
      if (!!tempArr?.data?.length) {
        let tempArrs = [...tempArr?.data, ...action?.data?.data];
        tempArr.data = tempArrs;
      } else {
        tempArr = action?.data;
      }
      return { ...state, isJobListsLoading: false, allJobLists: tempArr };

    case types.GET_ALL_JOB_FAIL:
      return { ...state, isJobListsLoading: false, allJobLists: {} };

    case types.GET_ALL_JOB_SUCCESS_FILTER:
      return { ...state, isJobListsLoading: false, allJobLists: action?.data };

    case types.GET_CLIENT_LIST_ADMIN_START:
      return {
        ...state,
        isAllClientListAdminLoading: true,
      };
    case types.GET_CLIENT_LIST_ADMIN_SUCCESS:
      return {
        ...state,
        isAllClientListAdminLoading: false,
        allClientListAdmin: action.data,
      };
    case types.GET_CLIENT_LIST_ADMIN_FAIL:
      return { ...state, isAllClientListAdminLoading: false };

    case types.GET_CLIENT_DETAIL_ADMIN_START:
      return { ...state, isClientDetailLoading: true, clientDetailAdmin: {} };
    case types.GET_CLIENT_DETAIL_ADMIN_SUCCESS:
      return {
        ...state,
        isClientDetailLoading: false,
        clientDetailAdmin: action.data,
      };
    case types.GET_CLIENT_DETAIL_ADMIN_FAIL:
      return { ...state, isClientDetailLoading: false };

    case types.GET_JOB_LIST_BY_CLIENT_ADMIN_START:
      return {
        ...state,
        isClientJobListAdminLoading: true,
        clientJobListAdmin: {},
      };
    case types.GET_JOB_LIST_BY_CLIENT_ADMIN_SUCCESS:
      return {
        ...state,
        isClientJobListAdminLoading: false,
        clientJobListAdmin: action.data,
      };
    case types.GET_JOB_LIST_BY_CLIENT_ADMIN_FAIL:
      return { ...state, isClientJobListAdminLoading: false };

    case types.GET_JOB_FULL_CLIENT_ADMIN_START:
      return { ...state, isClientFullJobAdminLoading: true, clientFullJob: {} };
    case types.GET_JOB_FULL_CLIENT_ADMIN_SUCCESS:
      return {
        ...state,
        isClientFullJobAdminLoading: false,
        clientFullJob: action.data,
      };
    case types.GET_JOB_FULL_CLIENT_ADMIN_FAIL:
      return { ...state, isClientFullJobAdminLoading: false };

    case types.BLOGS_SYNCUP_START:
      return { ...state, isBlogsSyncup: true };
    case types.BLOGS_SYNCUP_SUCCESS:
      return { ...state, isBlogsSyncup: false };
    case types.BLOGS_SYNCUP_FAIL:
      return { ...state, isBlogsSyncup: false };

    case types.UPDATE_SPOTLIGHTED_CANDIDATES_START:
      return { ...state, isLoading: true, updateSpotLightMessage: {} };

    case types.UPDATE_SPOTLIGHTED_CANDIDATES_SUCCESS:
      const devList = [...state.devLists.data];
      // console.log("UPDATE_SPOTLIGHTED_CANDIDATES_SUCCESS",action?.data)
      devList[
        devList.findIndex((item) => item?._id === action?.data?.devId)
      ].spotLight = action?.data?.spotLight;
      return { ...state, isLoading: false, devLists: { data: devList } };
    case types.UPDATE_SPOTLIGHTED_CANDIDATES_FAIL:
      return { ...state, isLoading: false, updateSpotLightMessage: {} };

    case types.POST_ADMIN_TEMPLATE_LIST_START:
      return { ...state, isLoading: true, postTemplateList: {} };
    case types.POST_ADMIN_TEMPLATE_LIST_SUCCESS:
      return { ...state, isLoading: false, postTemplateList: action?.data };
    case types.POST_ADMIN_TEMPLATE_LIST_FAIL:
      return { ...state, isLoading: false, postTemplate: {} };

    case types.GET_ADMIN_TEMPLATE_LIST_START:
      return { ...state, isEmailTempLoadinf: true, getTemplateList: [] };
    case types.GET_ADMIN_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        isEmailTempLoadinf: false,
        getTemplateList: action?.data,
      };
    case types.GET_ADMIN_TEMPLATE_LIST_FAIL:
      return { ...state, isEmailTempLoadinf: false, getTemplateList: [] };

    case types.GET_ADMIN_TEMPLATE_BY_ID_START:
      return { ...state, isLoading: true, getTemplateById: {} };
    case types.GET_ADMIN_TEMPLATE_BY_ID_SUCCESS:
      return { ...state, isLoading: false, getTemplateById: action?.data };
    case types.GET_ADMIN_TEMPLATE_BY_ID_FAIL:
      return { ...state, isLoading: false, getTemplateById: {} };

    case types.DELETE_ADMIN_TEMPLATE_LIST_START:
      return { ...state, isLoading: true, deleteTemplateList: {} };
    case types.DELETE_ADMIN_TEMPLATE_LIST_SUCCESS:
      return { ...state, isLoading: false, deleteTemplateList: action?.data };
    case types.DELETE_ADMIN_TEMPLATE_LIST_FAIL:
      return { ...state, isLoading: false, deleteTemplateList: {} };

    case types.GET_ADMIN_EMAIL_HISTORY_START:
      return { ...state, emailHistoryLoading: true };
    case types.GET_ADMIN_EMAIL_HISTORY_SUCCESS:
      return {
        ...state,
        emailHistoryLoading: false,
        emailHistory: action?.data,
      };
    case types.GET_ADMIN_EMAIL_HISTORY_FAIL:
      return { ...state, emailHistoryLoading: false, emailHistory: [] };

    case types.GET_ADMIN_CLIENT_SHORTLIST_START:
      return { ...state, isLoading: true, adminShortlist: [] };
    case types.GET_ADMIN_CLIENT_SHORTLIST_SUCCESS:
      return { ...state, isLoading: false, adminShortlist: action?.data };
    case types.GET_ADMIN_CLIENT_SHORTLIST_FAIL:
      return { ...state, isLoading: false, adminShortlist: [] };

    case types.GET_ADMIN_CLIENT_HIRED_START:
      return { ...state, isLoading: true, adminHired: [] };
    case types.GET_ADMIN_CLIENT_HIRED_SUCCESS:
      return { ...state, isLoading: false, adminHired: action?.data };
    case types.GET_ADMIN_CLIENT_HIRED_FAIL:
      return { ...state, isLoading: false, adminHired: [] };

    case types.GET_ADMIN_DEV_ACADEMIC_START:
      return { ...state, isLoading: true };
    case types.GET_ADMIN_DEV_ACADEMIC_SUCCESS:
      return { ...state, isLoading: false, adminDevAcademic: action?.data };
    case types.GET_ADMIN_DEV_ACADEMIC_FAIL:
      return { ...state, isLoading: false };

    case types.UPDATE_BLOG_NEW_START:
      return { ...state, updateBlogNewLoading: true, updateBlogNewData: {} };
    case types.UPDATE_BLOG_NEW_SUCCESS:
      return {
        ...state,
        updateBlogNewLoading: false,
        updateBlogNewData: action.data,
      };
    case types.UPDATE_BLOG_NEW_FAIL:
      return { ...state, updateBlogNewLoading: false, updateBlogNewData: {} };

    case types.DELETE_BLOG_NEW_START:
      return { ...state, deleteBlogNewLoading: true, deleteBlogNewData: {} };
    case types.DELETE_BLOG_NEW_SUCCESS:
      return {
        ...state,
        deleteBlogNewLoading: false,
        deleteBlogNewData: action.data,
      };
    case types.DELETE_BLOG_NEW_FAIL:
      return { ...state, deleteBlogNewLoading: false, deleteBlogNewData: {} };

    case types.POST_BLOG_NEW_START:
      return { ...state, postBlogNewLoading: true, postBlogNewData: {} };
    case types.POST_BLOG_NEW_SUCCESS:
      return {
        ...state,
        postBlogNewLoading: false,
        postBlogNewData: action.data,
      };
    case types.POST_BLOG_NEW_FAIL:
      return { ...state, postBlogNewLoading: false, postBlogNewData: {} };

    case types.GET_ADMIN_DEV_CERTIFICATION_START:
      return { ...state, isLoading: true };
    case types.GET_ADMIN_DEV_CERTIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adminDevCertification: action?.data,
      };
    case types.GET_ADMIN_DEV_CERTIFICATION_FAIL:
      return { ...state, isLoading: false };

    case types.GET_ADMIN_DEV_SOCIAL_PROFILE_START:
      return { ...state, isLoading: true };
    case types.GET_ADMIN_DEV_SOCIAL_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adminDevSocialProfileData: action?.data[0],
      };
    case types.GET_ADMIN_DEV_SOCIAL_PROFILE_FAIL:
      return { ...state, isLoading: false };

    case types.GET_ADMIN_DEV_EMPLOYEMENT_START:
      return { ...state, isLoading: true };
    case types.GET_ADMIN_DEV_EMPLOYEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adminDevEmployementeData: action?.data,
      };
    case types.GET_ADMIN_DEV_EMPLOYEMENT_FAIL:
      return { ...state, isLoading: false };

    case types.GET_ADMIN_DEV_PROJECT_START:
      return { ...state, isLoading: true };
    case types.GET_ADMIN_DEV_PROJECT_SUCCESS:
      return { ...state, isLoading: false, adminDevProjectData: action?.data };
    case types.GET_ADMIN_DEV_PROJECT_FAIL:
      return { ...state, isLoading: false };

    case types.GET_ADMIN_DEV_TOOLS_START:
      return { ...state, isLoading: true };
    case types.GET_ADMIN_DEV_TOOLS_SUCCESS:
      return { ...state, isLoading: false, adminDevToolData: action?.data };
    case types.GET_ADMIN_DEV_TOOLS_FAIL:
      return { ...state, isLoading: false };

    case types.GET_ADMIN_DEV_SKILLS_START:
      return { ...state, isLoading: true };
    case types.GET_ADMIN_DEV_SKILLS_SUCCESS:
      return { ...state, isLoading: false, adminChooseSkill: action?.data };
    case types.GET_ADMIN_DEV_SKILLS_FAIL:
      return { ...state, isLoading: false };

    case types.GET_ADMIN_DEV_ACHIEVEMENT_START:
      return { ...state, isLoading: true };
    case types.GET_ADMIN_DEV_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adminDevAchievementData: action?.data,
      };
    case types.GET_ADMIN_DEV_ACHIEVEMENT_FAIL:
      return { ...state, isLoading: false };

    case types.GET_RESUME_ADMIN_START:
      return { ...state, devResumeAdminLoading: true, devResumeAdmin: {} };
    case types.GET_RESUME_ADMIN_SUCCESS:
      return {
        ...state,
        devResumeAdminLoading: false,
        devResumeAdmin: action?.data,
      };
    case types.GET_RESUME_ADMIN_FAIL:
      return { ...state, devResumeAdminLoading: false, devResumeAdmin: {} };

    case types.UPDATE_ADMIN_DEV_PROFILE_IMAGE_START:
      return { ...state, isAdminDevProFileLoad: true };
    case types.UPDATE_ADMIN_DEV_PROFILE_IMAGE_SUCCESS:
      let tempData = [...state.devUser];
      tempData[0].userImage = action?.data?.payload?.filename;
      return {
        ...state,
        isAdminDevProFileLoad: false,
        devUser: tempData,
      };
    case types.UPDATE_ADMIN_DEV_PROFILE_IMAGE_FAIL:
      return { ...state, isAdminDevProFileLoad: false };

    case types.GET_ADMIN_DEV_ENGLISH_RESULT_START:
      return { ...state, devAdminEnglishResult: [] };
    case types.GET_ADMIN_DEV_ENGLISH_RESULT_SUCCESS:
      return { ...state, devAdminEnglishResult: action?.data };
    case types.GET_ADMIN_DEV_ENGLISH_RESULT_FAIL:
      return { ...state, devAdminEnglishResult: [] };

    case types.SAVE_ADMIN_DEV_ACADEMIC_START:
      return { ...state, isSaveAcademic: true };
    case types.SAVE_ADMIN_DEV_ACADEMIC_SUCCESS:
      return {
        ...state,
        isSaveAcademic: false,
        adminDevAcademic: [action?.data?.data, ...state.adminDevAcademic],
      };
    case types.SAVE_ADMIN_DEV_ACADEMIC_START:
      return { ...state, isSaveAcademic: false };

    case types.UPDATE_ADMIN_DEV_ACADEMIC_START:
      return { ...state, isSaveAcademic: true };
    case types.UPDATE_ADMIN_DEV_ACADEMIC_SUCCESS:
      const devAcademic = [...state.adminDevAcademic];
      devAcademic[
        devAcademic.findIndex((item) => item?._id === action?.data?.data?._id)
      ] = action?.data?.data;
      return { ...state, isSaveAcademic: false, adminDevAcademic: devAcademic };
    case types.UPDATE_ADMIN_DEV_ACADEMIC_FAIL:
      return { ...state, isSaveAcademic: false };

    case types.DELETE_ADMIN_DEV_ACADEMIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adminDevAcademic: state.adminDevAcademic.filter(
          (item) => item?._id !== action?.data
        ),
      };

    case types.SAVE_ADMIN_DEV_EMPLOYEMENT_START:
      return { ...state, isUpdateEmplmt: true };
    case types.SAVE_ADMIN_DEV_EMPLOYEMENT_SUCCESS:
      return {
        ...state,
        isUpdateEmplmt: false,
      };
    case types.SAVE_ADMIN_DEV_EMPLOYEMENT_FAIL:
      return { ...state, isUpdateEmplmt: false };

    case types.UPDATE_ADMIN_DEV_EMPLOYEMENT_START:
      return { ...state, isUpdateEmplmt: true };
    case types.UPDATE_ADMIN_DEV_EMPLOYEMENT_SUCCESS:
      return {
        ...state,
        isUpdateEmplmt: false,
      };
    case types.UPDATE_ADMIN_DEV_EMPLOYEMENT_FAIL:
      return { ...state, isUpdateEmplmt: false };

    case types.DELETE_ADMIN_DEV_EMPLOYEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adminDevEmployementeData: state.adminDevEmployementeData.filter(
          (item) => item?._id !== action?.data
        ),
      };

    case types.SAVE_ADMIN_DEV_PROJECT_START:
      return { ...state, isProjectSubmit: true };
    case types.SAVE_ADMIN_DEV_PROJECT_SUCCESS:
      return {
        ...state,
        isProjectSubmit: false,
        adminDevProjectData: [action?.data?.data, ...state.adminDevProjectData],
      };
    case types.SAVE_ADMIN_DEV_PROJECT_FAIL:
      return { ...state, isProjectSubmit: false };

    case types.UPDATE_ADMIN_DEV_PROJECT_START:
      return { ...state, isProjectSubmit: true };
    case types.UPDATE_ADMIN_DEV_PROJECT_SUCCESS:
      const devProject = [...state.adminDevProjectData];
      devProject[
        devProject.findIndex((item) => item?._id === action?.data?.data?._id)
      ] = action?.data?.data;
      return {
        ...state,
        isProjectSubmit: false,
        adminDevProjectData: devProject,
      };
    case types.UPDATE_ADMIN_DEV_PROJECT_FAIL:
      return { ...state, isProjectSubmit: false };

    case types.DELETE_ADMIN_DEV_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adminDevProjectData: state.adminDevProjectData.filter(
          (item) => item?._id !== action?.data
        ),
      };

    case types.SAVE_ADMIN_DEV_CERTIFICATION_START:
      return { ...state, isCertificateSubmit: true };
    case types.SAVE_ADMIN_DEV_CERTIFICATION_SUCCESS:
      return {
        ...state,
        isCertificateSubmit: false,
        adminDevCertification: [
          action?.data?.data,
          ...state.adminDevCertification,
        ],
      };
    case types.SAVE_ADMIN_DEV_CERTIFICATION_FAIL:
      return { ...state, isCertificateSubmit: true };

    case types.UPDATE_ADMIN_DEV_CERTIFICATION_START:
      return { ...state, isCertificateSubmit: true };
    case types.UPDATE_ADMIN_DEV_CERTIFICATION_SUCCESS:
      const devCertificate = [...state.adminDevCertification];
      devCertificate[
        devCertificate.findIndex(
          (item) => item?._id === action?.data?.data?._id
        )
      ] = action?.data?.data;
      return {
        ...state,
        isCertificateSubmit: false,
        adminDevCertification: devCertificate,
      };
    case types.UPDATE_ADMIN_DEV_CERTIFICATION_FAIL:
      return { ...state, isCertificateSubmit: false };

    case types.DELETE_ADMIN_DEV_CERTIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adminDevCertification: state.adminDevCertification.filter(
          (item) => item?._id !== action?.data
        ),
      };

    case types.SAVE_ADMIN_DEV_SKILLS_START:
      return { ...state, isSkillSubmit: true };
    case types.SAVE_ADMIN_DEV_SKILLS_SUCCESS:
      return {
        ...state,
        isSkillSubmit: false,
        adminChooseSkill: [action?.data?.data, ...state.adminChooseSkill],
      };
    case types.SAVE_ADMIN_DEV_SKILLS_FAIL:
      return { ...state, isSkillSubmit: false };

    case types.UPDATE_ADMIN_DEV_SKILLS_START:
      return { ...state, isSkillSubmit: true };
    case types.UPDATE_ADMIN_DEV_SKILLS_SUCCESS:
      const devSkills = [...state.adminChooseSkill];
      devSkills[
        devSkills.findIndex((item) => item?._id === action?.data?.data?._id)
      ] = action?.data?.data;
      return { ...state, isSkillSubmit: false, adminChooseSkill: devSkills };
    case types.UPDATE_ADMIN_DEV_SKILLS_FAIL:
      return { ...state, isSkillSubmit: false };

    case types.DELETE_ADMIN_DEV_SKILLS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adminChooseSkill: state.adminChooseSkill.filter(
          (item) => item?._id !== action?.data
        ),
      };

    case types.SAVE_ADMIN_DEV_TOOLS_START:
      return { ...state, isToolSubmit: true };
    case types.SAVE_ADMIN_DEV_TOOLS_SUCCESS:
      return {
        ...state,
        isToolSubmit: false,
        adminDevToolData: [...action?.data?.data, ...state.adminDevToolData],
      };
    case types.SAVE_ADMIN_DEV_TOOLS_FAIL:
      return { ...state, isToolSubmit: false };

    case types.UPDATE_ADMIN_DEV_TOOLS_START:
      return { ...state, isToolSubmit: true };
    case types.UPDATE_ADMIN_DEV_TOOLS_SUCCESS:
      const devTools = [...state.adminDevToolData];
      devTools[
        devTools.findIndex((item) => item?._id === action?.data?.data?._id)
      ] = action?.data?.data;
      return { ...state, isToolSubmit: false, adminDevToolData: devTools };
    case types.UPDATE_ADMIN_DEV_TOOLS_FAIL:
      return { ...state, isToolSubmit: false };

    case types.DELETE_USER_PROFILE_START:
      return { ...state, isDeleteProfile: true };
    case types.DELETE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isDeleteProfile: false,
      };
    case types.DELETE_USER_PROFILE_FAIL:
      return { ...state, isDeleteProfile: false };

    case types.BLOCK_USER_PROFILE_START:
      return { ...state, isProfileBlocking: true };
    case types.BLOCK_USER_PROFILE_SUCCESS:
      return { ...state, isProfileBlocking: false };
    case types.BLOCK_USER_PROFILE_FAIL:
      return { ...state, isProfileBlocking: false };

    case types.DELETE_ADMIN_DEV_TOOLS_SUCCESS:
      let tempDevUser = state.devUser[0];
      tempDevUser = tempDevUser[0]?.devTools;
      let tempTools = [];
      if (tempDevUser) {
        tempTools = tempDevUser?.filter((item) => item?._id !== action?.data);
      }
      return {
        ...state,
        isLoading: false,
        adminDevToolData: state.adminDevToolData.filter(
          (item) => item?._id !== action?.data
        ),
        devUser: [
          {
            ...state.devUser[0],
            devTools: tempTools,
          },
        ],
      };

    case types.SAVE_ADMIN_DEV_ACHIEVEMENT_START:
      return { ...state, isAchievementSubmit: true };
    case types.SAVE_ADMIN_DEV_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        isAchievementSubmit: false,
        adminDevAchievementData: [
          action?.data?.data,
          ...state.adminDevAchievementData,
        ],
      };
    case types.SAVE_ADMIN_DEV_ACHIEVEMENT_FAIL:
      return { ...state, isAchievementSubmit: false };

    case types.UPDATE_ADMIN_DEV_ACHIEVEMENT_START:
      return { ...state, isAchievementSubmit: true };
    case types.UPDATE_ADMIN_DEV_ACHIEVEMENT_SUCCESS:
      const devAchievement = [...state.adminDevAchievementData];
      devAchievement[
        devAchievement.findIndex(
          (item) => item?._id === action?.data?.data?._id
        )
      ] = action?.data?.data;
      return {
        ...state,
        isAchievementSubmit: false,
        adminDevAchievementData: devAchievement,
      };
    case types.UPDATE_ADMIN_DEV_ACHIEVEMENT_FAIL:
      return { ...state, isAchievementSubmit: false };

    case types.DELETE_ADMIN_DEV_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adminDevAchievementData: state.adminDevAchievementData.filter(
          (item) => item?._id !== action?.data
        ),
      };

    case types.UPDATE_USER_STATUS_SUCCESS:
      const userType = state.userData?.data;
      userType[
        userType?.findIndex((item) => item?._id === action?.userId)
      ].status = action?.status;
      return { ...state };
    case types.ADD_USER_SUCCESS:
      const userDatas = state.userData?.data;
      return {
        ...state,
        userData: { ...state?.userData, data: [action?.data, ...userDatas] },
      };

    case types.DELETE_USER_SUCCESS:
      const memberData = [...state.userData?.data];
      const leftUser = memberData?.filter(
        (item) => item?._id !== action?.userId
      );
      return { ...state, userData: { ...state?.userData, data: leftUser } };

    case types.DELETE_JOB_SUCCESS:
      const jobData = [...state.allJobLists?.data];
      const leftJob = jobData?.filter((item) => item._id !== action?.jobId);
      return {
        ...state,
        allJobLists: { ...state.allJobLists, data: leftJob },
      };

    case types.ADD_QUESTION_SUCCESS:
      const tempQuestn = [...state.allQuestions?.data];
      return {
        ...state,
        allQuestions: {
          ...state?.allQuestions,
          data: [action?.data, ...tempQuestn],
        },
      };

    case types.DELETE_ENGLISH_QUESTION_SUCCESS:
      const allQuestn = [...state.allQuestions.data];
      const leftQuestn = allQuestn?.filter(
        (item) => item._id !== action?.questionId
      );
      return {
        ...state,
        allQuestions: { ...state.allQuestions, data: leftQuestn },
      };

    case types.UPDATE_ADMIN_TEMPLATE_LIST_START:
      return { ...state, emailTemplateLoading: true };
    case types.UPDATE_ADMIN_TEMPLATE_LIST_SUCCESS:
      const tempEmailLists = [...state?.getTemplateList?.data];
      const emailIndex = tempEmailLists?.findIndex(
        (item) => item?._id === action?.templateId
      );
      tempEmailLists[emailIndex].status = action?.data?.status;
      return {
        ...state,
        emailTemplateLoading: false,
        getTemplateList: { ...state.getTemplateList, data: tempEmailLists },
      };
    case types.UPDATE_ADMIN_TEMPLATE_LIST_FAIL:
      return { ...state, emailTemplateLoading: false };

    case types.UPDATE_ADMIN_DEV_ENGLISH_RESULT_START:
      return { ...state, saveEnglishClearance: true };
    case types.UPDATE_ADMIN_DEV_ENGLISH_RESULT_SUCCESS:
      return {
        ...state,
        saveEnglishClearance: false,
        devAdminEnglishResult: {
          ...state?.devAdminEnglishResult,
          status: action?.status,
          correct: action?.correct,
        },
      };
    case types.UPDATE_ADMIN_DEV_ENGLISH_RESULT_FAIL:
      return { ...state, saveEnglishClearance: false };

    case types.GET_SKILL_TEST_SUCCESS:
      return { ...state, testSkills: action?.data };

    case types.GET_ADMIN_DEV_SKILL_RESULT_START:
      return { ...state, devSkillTestResult: {} };
    case types.GET_ADMIN_DEV_SKILL_RESULT_SUCCESS:
      let data = { candidateName: action?.developerName };

      return { ...state, devSkillTestResult: data };
    case types.GET_ADMIN_DEV_SKILL_RESULT_FAIL:
      return { ...state, devSkillTestResult: {} };

    case types.UPDATE_ADMIN_SKILL_TEST_START:
      return { ...state, isUpdateLoading: true, updateSkills: false };
    case types.UPDATE_ADMIN_SKILL_TEST_SUCCESS:
      return { ...state, isUpdateLoading: false, updateSkills: true };
    case types.UPDATE_ADMIN_SKILL_TEST_FAIL:
      return { ...state, isUpdateLoading: false, updateSkills: false };

    case types.ADMIN_DASHBOARD_STEP_ONE_SUCCESS:
      let tempUser = [...state?.devLists?.data];
      let index = tempUser?.findIndex((item) => item?._id == action?.devId);
      tempUser[index] = { ...tempUser[index], ...action?.data };
      return {
        ...state,
        devUser: [{ ...state?.devUser[0], ...action?.data }],
        devLists: { ...state?.devLists, data: tempUser },
      };

    case types.GET_MAINTENANCE_MESSAGE_START:
      return { ...state, maintenanceLoading: true, getMaintenanceMessage: {} };
    case types.GET_MAINTENANCE_MESSAGE_SUCCESS:
      return {
        ...state,
        maintenanceLoading: false,
        getMaintenanceMessage: action?.data,
      };
    case types.GET_MAINTENANCE_MESSAGE_FAIL:
      return { ...state, maintenanceLoading: false, getMaintenanceMessage: {} };

    case types.UPDATE_MAINTENANCE_STATUS_START:
      return {
        ...state,
        updatemaintenanceLoading: true,
        updateMaintenanceStatus: {},
      };
    case types.UPDATE_MAINTENANCE_STATUS_SUCCESS:
      return {
        ...state,
        updatemaintenanceLoading: false,
        updateMaintenanceStatus: action?.data,
      };
    case types.UPDATE_MAINTENANCE_STATUS_FAIL:
      return {
        ...state,
        updatemaintenanceLoading: false,
        updateMaintenanceStatus: {},
      };

    case types.ADD_MAINTENANCE_MESSAGE_START:
      return {
        ...state,
        addmaintenanceLoading: true,
        addMaintenanceMessage: {},
      };
    case types.ADD_MAINTENANCE_MESSAGE_SUCCESS:
      return {
        ...state,
        addmaintenanceLoading: false,
        addMaintenanceMessage: action?.data,
      };
    case types.ADD_MAINTENANCE_MESSAGE_FAIL:
      return {
        ...state,
        addmaintenanceLoading: false,
        addMaintenanceMessage: {},
      };

    case types.UPDATE_MAINTENANCE_MESSAGE_START:
      return {
        ...state,
        updatemaintenanceLoading: true,
        updateMaintenanceMessage: {},
      };
    case types.UPDATE_MAINTENANCE_MESSAGE_SUCCESS:
      return {
        ...state,
        updatemaintenanceLoading: false,
        updateMaintenanceMessage: action?.data,
      };
    case types.UPDATE_MAINTENANCE_MESSAGE_FAIL:
      return {
        ...state,
        updatemaintenanceLoading: false,
        updateMaintenanceMessage: {},
      };

    case types.DELETE_MAINTENANCE_MESSAGE_START:
      return {
        ...state,
        deletemaintenanceLoading: true,
        deleteMaintenanceMessage: {},
      };
    case types.DELETE_MAINTENANCE_MESSAGE_SUCCESS:
      return {
        ...state,
        deletemaintenanceLoading: false,
        deleteMaintenanceMessage: action?.data,
      };
    case types.DELETE_MAINTENANCE_MESSAGE_FAIL:
      return {
        ...state,
        deletemaintenanceLoading: false,
        deleteMaintenanceMessage: {},
      };

    case types.GET_SITEMAP_TYPE_START:
      return { ...state, sitemapTypeListLoading: true, sitemapTypeList: [] };
    case types.GET_SITEMAP_TYPE_SUCCESS:
      return {
        ...state,
        sitemapTypeListLoading: false,
        sitemapTypeList: action?.data,
      };
    case types.GET_SITEMAP_TYPE_FAIL:
      return { ...state, sitemapTypeListLoading: false, sitemapTypeList: [] };

    case types.POST_SITEMAP_TYPE_START:
      return { ...state, sitemapTypePostLoading: true, sitemapTypePost: [] };
    case types.POST_SITEMAP_TYPE_SUCCESS:
      return {
        ...state,
        sitemapTypePostLoading: false,
        sitemapTypePost: action?.data,
      };
    case types.POST_SITEMAP_TYPE_FAIL:
      return { ...state, sitemapTypePostLoading: false, sitemapTypePost: [] };

    case types.UPDATE_SITEMAP_TYPE_START:
      return {
        ...state,
        sitemapTypeUpdateLoading: true,
        sitemapTypeUpdate: [],
      };
    case types.UPDATE_SITEMAP_TYPE_SUCCESS:
      return {
        ...state,
        sitemapTypeUpdateLoading: false,
        sitemapTypeUpdate: action?.data,
      };
    case types.UPDATE_SITEMAP_TYPE_FAIL:
      return {
        ...state,
        sitemapTypeUpdateLoading: false,
        sitemapTypeUpdate: [],
      };

    case types.DELETE_SITEMAP_TYPE_START:
      return {
        ...state,
        sitemapTypeDeleteLoading: true,
        sitemapTypeDelete: [],
      };
    case types.DELETE_SITEMAP_TYPE_SUCCESS:
      return {
        ...state,
        sitemapTypeDeleteLoading: false,
        sitemapTypeDelete: action?.data,
      };
    case types.DELETE_SITEMAP_TYPE_FAIL:
      return {
        ...state,
        sitemapTypeDeleteLoading: false,
        sitemapTypeDelete: [],
      };

    case types.UPDATE_ADMIN_DEV_SHORT_DESC_SUCCESS:
      let tempUsers = [...state.devUser];
      tempUsers = tempUsers[0];
      tempUsers.devTellUrStory = action?.data?.devTellUrStory;
      return { ...state, devUser: [tempUsers] };

    case types.GET_ALL_PERK_BENEFIT_START:
      return { ...state, perkAndBenefit: [] };
    case types.GET_ALL_PERK_BENEFIT_SUCCESS:
      return { ...state, perkAndBenefit: action?.data?.data };
    case types.GET_ALL_PERK_BENEFIT_FAIL:
      return { ...state, perkAndBenefit: [] };

    case types.SAVE_PERK_BENEFIT_START:
      return { ...state };
    case types.SAVE_PERK_BENEFIT_SUCCESS:
      let temptData = [...state?.perkAndBenefit];
      temptData.push(action?.data?.data);
      return { ...state, perkAndBenefit: temptData };
    case types.SAVE_PERK_BENEFIT_FAIL:
      return { ...state };

    case types.DELETE_PERK_BENEFIT_SUCCESS:
      let temptData2 = [...state?.perkAndBenefit];
      temptData2 = temptData2?.filter((item) => item?._id != action?.id);
      return { ...state, perkAndBenefit: temptData2 };

    case types.UPDATE_PERK_BENEFIT_SUCCESS:
      let temptData3 = [...state?.perkAndBenefit];
      console.log("action", action?.data);
      let index3 = temptData3.findIndex((item) => item?._id == action?.id);
      if (index3 >= 0) {
        temptData3[index3] = action?.data;
      }
      return { ...state, perkAndBenefit: temptData3 };

    case types.UPDATE_ASSESSMENT_NAME_START:
      return { ...state, updateAssessName: {} };
    case types.UPDATE_ASSESSMENT_NAME_SUCCESS:
      return { ...state, updateAssessName: action?.data };
    case types.UPDATE_ASSESSMENT_NAME_FAIL:
      return { ...state, updateAssessName: {} };

    case types.GET_SKILL_CATEGORY_SUCCESS:
      return { ...state, skillCategory: action?.data };

    case types.GET_SKILL_CATEGORY_LISTS_START:
      return { ...state, isSkillWithCat: true };
    case types.GET_SKILL_CATEGORY_LISTS_SUCESS:
      return {
        ...state,
        isSkillWithCat: false,
        skillWithCategory: action?.data,
      };
    case types.GET_SKILL_CATEGORY_LISTS_FAIL:
      return { ...state, isSkillWithCat: false };

    case types.GET_STACK_CATEGORY_LISTS_START:
      return { ...state, isStackWithCat: true };
    case types.GET_STACK_CATEGORY_LISTS_SUCESS:
      return {
        ...state,
        isStackWithCat: false,
        stackWithCategory: action?.data,
      };
    case types.GET_STACK_CATEGORY_LISTS_START:
      return { ...state, isStackWithCat: false };

    case types.SET_CLIENT_FILTER:
      return { ...state, filter: action.data };

    case types.GET_EMAIL_COUNT_START:
      return { ...state, isEmailList: true, emailCount: [] };
    case types.GET_EMAIL_COUNT_SUCCESS:
      return { ...state, isEmailList: false, emailCount: action?.data };
    case types.GET_EMAIL_COUNT_FAIL:
      return { ...state, isEmailList: false, emailCount: [] };

    case types.ADD_JOB_START:
      return { ...state, isSaveJob: true };
    case types.ADD_JOB_SUCCESS:
      const tempJob = [...state.allJobLists?.data];
      return {
        ...state,
        isSaveJob: false,
        allJobLists: {
          ...state?.allJobLists,
          data: [action?.data, ...tempJob],
        },
      };
    case types.ADD_JOB_FAIL:
      return { ...state, isSaveJob: false };

    case types.EDIT_JOB_START:
      return { ...state, isEditJob: true };
    case types.EDIT_JOB_SUCCESS:
      const tempJobdata = [...state.allJobLists?.data];
      tempJobdata[
        tempJobdata?.findIndex((item) => item?._id === action?.data?._id)
      ] = action?.data;
      return {
        ...state,
        isEditJob: false,
        allJobLists: { ...state.allJobLists, data: tempJobdata },
      };
    case types.EDIT_JOB_FAIL:
      return { ...state, isEditJob: false };

    case types.GET_CLIENT_EMAIL_SUCCESS:
      return { ...state, clientEmailList: action?.data };

    case types.GET_JOB_TITLE_START:
      return { ...state, jobTitleLists: null };
    case types.GET_JOB_TITLE_SUCCESS:
      return { ...state, jobTitleLists: action?.data };
    case types.GET_JOB_TITLE_FAIL:
      return { ...state, jobTitleLists: null };

    case types.GET_PLAN_LISTS_START:
      return { ...state };
    case types.GET_PLAN_LISTS_SUCCESS:
      return { ...state, planLists: action?.data?.data };
    case types.GET_PLAN_LISTS_FAIL:
      return { ...state };

    case types.GET_FEATURE_LIST_START:
      return { ...state, fetureList: null };
    case types.GET_FEATURE_LIST_SUCCESS:
      return { ...state, fetureList: action?.data?.data };
    case types.GET_FEATURE_LIST_FAIL:
      return { ...state, fetureList: null };

    case types.GET_ADD_ON_LISTS_START:
      return { ...state };
    case types.GET_ADD_ON_LISTS_SUCCESS:
      return { ...state, addOnList: action?.data };
    case types.GET_ADD_ON_LISTS_FAIL:
      return { ...state };

    case types.GET_PRICING_SLAB_SUCCESS:
      return { ...state, pricingSlab: action?.data };

    case types.GET_PENDING_SKILL_SUCCESS:
      return { ...state, pendingSkill: action?.data?.data };

    default:
      return { ...state };
  }
};
