import React, { useState, Fragment, useEffect } from "react";
import Question from "./question-list/Question";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../components/icon";
import { addQuestion } from "../../../service/action/admin";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import SelectOption from "../../../components/select/SelectOption";
import { useContext } from "react";
import { MainContext } from "../../../context/MainContext";
import { checkPermission, readOnlyCheck } from "../../../components/functions";

const TestManagement = () => {
  const {
    register: testRegister,
    handleSubmit: testSubmit,
    setValue: setTestValue,
    getValues: gettestValue,
    control: testControl,
    reset: resetTestForm,
    watch,
    formState: { errors: testError },
  } = useForm();

  const {user:{access}}=useContext(MainContext)
  const readOnly=readOnlyCheck(access,"English Test")

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [questionObject, setQuestionObject] = useState({
    type: "",
  });
  const handleClose = () => {
    resetTestForm();
    setAddQuestion(false);
    setQuestionObject({
      type: "",
    });
  };
  const addQuestionPost = (data) => {
    let temparr = [data?.option1, data?.option2, data?.option3, data?.option4];
    let correct = temparr?.findIndex((item)=>item==data?.correct?.value);
    const newObject = {
      type: questionObject?.type,
      options: temparr,
      correct,
      question: data?.question,
    };
    dispatch(addQuestion(newObject, navigate, handleClose));
  };

  const [optionNow, setOptionNow] = useState({});
  const onHandleChange = (item) => {
    setOptionNow(item);
    console.log(item);
    item.value == "long"
      ? setQuestionObject({ ...questionObject, options: [], type: "long" })
      : setQuestionObject({
          ...questionObject,
          options: ["", "", "", ""],
          type: "mcq",
        });
  };

  watch('option4')

  const [addQuestions, setAddQuestion] = useState(false);

  return (
    <form>
      <div className="main-div-tab">
        <div
          className="toggleAddFaq"
          style={{ display: addQuestions ? "block" : "none" }}
        >
          <div className="faq-management">
            <div className="block-header">
              <h4 className="">Add Question</h4>
            </div>

            <div className="row">
              <div className="col-12">
                {/* <Select
                  isLoading={false}
                  isSearchable={false}
                  maxMenuHeight={300}
                  options={[
                    { value: "mcq", label: "MCQ" },
                    { value: "long", label: "Essay Question" },
                  ]}
                  onChange={(e) => onHandleChange(e)}
                /> */}
                <SelectOption
                  optionArray={[
                    { value: "mcq", label: "MCQ" },
                    { value: "long", label: "Essay Question" },
                  ]}
                  state={optionNow}
                  onChangeFunction={onHandleChange}
                  closeMenuOnSelect={true}
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control fields ${
                      testError?.question && "form-required"
                    }`}
                    placeholder="Question"
                    {...testRegister("question", {
                      required: true,
                    })}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: questionObject.type == "mcq" ? "block" : "none",
              }}
            >
              <div className="row">
                <div className="col-3">
                  <div className="form-group">
                    <label className="floating-label" htmlFor=""></label>
                    <input
                      type="text"
                      className={`form-control fields ${
                        testError?.option1 && "form-required"
                      }`}
                      placeholder="Option 1"
                      {...testRegister("option1", {
                        required: true,
                      })}
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <label className="floating-label" htmlFor=""></label>
                    <input
                      type="text"
                      className={`form-control fields ${
                        testError?.option2 && "form-required"
                      }`}
                      placeholder="Option 2"
                      {...testRegister("option2", {
                        required: true,
                      })}
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <label className="floating-label" htmlFor=""></label>
                    <input
                      type="text"
                      className={`form-control fields ${
                        testError?.option3 && "form-required"
                      }`}
                      placeholder="Option 3"
                      {...testRegister("option3", {
                        required: true,
                      })}
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <label className="floating-label" htmlFor=""></label>
                    <input
                      type="text"
                      className={`form-control fields ${
                        testError?.option4 && "form-required"
                      }`}
                      placeholder="Option 4"
                      {...testRegister("option4", {
                        required: true,
                      })}
                    />
                  </div>
                </div>
                <div
                  className={`col-12 ${
                    testError?.correct && "select-required"
                  }`}
                >
                  <Controller
                    control={testControl}
                    name="correct"
                    {...testRegister("correct", {
                      required: true,
                    })}
                    render={({ field }) => (
                      <SelectOption
                        optionArray={[
                          {
                            value: gettestValue("option1"),
                            label: gettestValue("option1"),
                          },
                          {
                            value: gettestValue("option2"),
                            label: gettestValue("option2"),
                          },
                          {
                            value: gettestValue("option3"),
                            label: gettestValue("option3"),
                          },
                          {
                            value: gettestValue("option4"),
                            label: gettestValue("option4"),
                          },
                        ]}
                        fieldProp={field}
                        getValue={gettestValue}
                        getValueName={"correct"}
                        closeMenuOnSelect={true}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-12">
                <div className="bottom-button">
                  <button
                    className="cancelBtn"
                    type="button"
                    onClick={() => setAddQuestion(false)}
                  >
                    cancel
                  </button>
                  <button
                    className="submitBtn themeButton"
                    onClick={testSubmit(addQuestionPost)}
                  >
                    submit
                  </button>
                </div>
              </div>
            </div>

            <br />
          </div>
        </div>
        <br />
        <br />

        <div
          className="main-faq-list"
          style={{ display: !addQuestions ? "block" : "none" }}
        >
          <div className="faq-management">
            <div className="block-header">
              <h4 className="">Questions</h4>
              <span onClick={() => {
                  if (checkPermission(readOnly)) return false
                setAddQuestion(true)}}>
                <Icon name="add-icon" />
              </span>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="form-group"></div>
              </div>
            </div>
          </div>
          <div>
            <Question readOnly={readOnly} />
          </div>
        </div>
      </div>
    </form>
  );
};

export default TestManagement;
