import React from 'react'
import { EmployementStyle } from './css/EmployementStyle'
import EmployementIcon from "../../../assets/img/icons/tool-icon.png";
import { Image, Text, View } from '@react-pdf/renderer';
import { Tool } from './css/Tool';
import { awsPngToImage } from '../../functions';
const Tools = ({ data }) => {
  console.log("data",data)
  return (
   Array.isArray(data) && data?.length && (
    <View style={{ ...EmployementStyle.employetmentMain, marginTop: "20px" }}>
      <View style={EmployementStyle.emplLayoutLeft} >
        <View>
          <Image source={EmployementIcon} style={EmployementStyle.employementIcon} />
        </View>
        <View style={EmployementStyle.emplLayoutLists}>
          <Text style={EmployementStyle.empHeading}> Tools</Text>
        </View>
      </View>
      <View style={Tool.rightLayout}>
        {
          data?.map((tool) => (
            <View>
              <Text style={Tool.toolTitle}>
                {/* {awsPngToImage(tool?.toolIcon) &&(<Image source={awsPngToImage(tool?.toolIcon)} />)} */}
                <Text style={Tool.toolName}> {tool?.toolName}</Text>
              </Text>
            </View>
          ))
        }


      </View>
    </View>
    )
  )
}

export default Tools