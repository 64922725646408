import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { comingSoonOG } from "../imageLinks";
import NoFollow from "../../../common/staticData/seo/NoFollow";

const Comingsoon = ({ baseUrl }) => {
  return (
    <Helmet>
      <title>Coming Soon | Olibr is preparing something great for you</title>

      <meta charset="UTF-8" />
      <meta
        name="title"
        content="Coming Soon | Olibr is preparing something great for you"
      />

      <meta
        name="description"
        content="Coming Soon Page | Olibr is preparing something great for you to improve your experience"
      ></meta>

      {/* <meta
          name="keywords"
          content="olibr, olibr.com, Hiring developers, remote software developers, work-from-home developers, wfh, remote jobs, IT jobs, US based IT companies, US Jobs, US IT Jobs"
        /> */}

      <meta name="language" content="english"></meta>

      <html lang="en" />

      <meta name="robots" content={NoFollow()}></meta>

      <meta name="url" content={`${baseUrl}/coming-soon`} />

      <meta name="identifier-URL" content={`${baseUrl}/coming-soon`}></meta>

      {/* <meta name='subtitle' content='about us -- learn more'></meta> */}

      <link rel="canonical" href={`${baseUrl}/coming-soon`} />

      <meta name="referrer" content="origin-when-crossorigin"></meta>

      <link rel="image_src" href={comingSoonOG} />

      <meta name="copyright" content="olibr.com" />

      {/* -----------Organisation Details----- */}

      {/* <meta name="og:latitude" content="37.416343" />

        <meta name="og:longitude" content="-122.153013" />

        <meta name="og:street-address" content="1601 S California Ave" />

        <meta name="og:locality" content="Palo Alto" />

        <meta name="og:region" content="CA" />

        <meta name="og:postal-code" content="94304" />

        <meta name="og:country-name" content="USA"></meta>

        <meta name="og:email" content="me@example.com" />

        <meta name="og:phone_number" content="650-123-4567"></meta> */}

      {/* ----Social Media Open Graph--------- */}

      <meta
        name="og:title"
        content="Coming Soon | Olibr is preparing something great for you"
      />

      <meta name="og:type" content="company" />

      <meta name="og:url" content={`${baseUrl}/coming-soon`} />

      <meta name="og:image" content={comingSoonOG} />

      <meta name="og:site_name" content="olibr.com" />

      <meta
        name="og:description"
        content="Coming Soon Page | Olibr is preparing something great for you to improve your experience"
      ></meta>

      {/* <meta name='medium' content='blog'></meta> */}

      {/*   <meta name='fb:page_id' content='------------'></meta> */}

      {/* ------ Twitter Cards--------- */}

      <meta name="twitter:site" content="@olibr" />

      <meta
        name="twitter:title"
        content="Coming Soon | Olibr is preparing something great for you"
      />

      <meta
        name="twitter:description"
        content="Coming Soon Page | Olibr is preparing something great for you to improve your experience"
      />

      {/*  <meta name="twitter:creator" content="@olibr"/> */}

      <meta name="twitter:card" content="summary_large_image" />

      <meta name="twitter:image" content={comingSoonOG} />

      {/* ------Apple Devices ------ */}

      <meta
        name="apple-mobile-web-app-title"
        content="Coming Soon | Olibr is preparing something great for you"
      />

      <meta name="apple-mobile-web-app-capable" content="yes" />

      <meta name="apple-touch-fullscreen" content="yes" />

      <meta name="apple-mobile-web-app-status-bar-style" content="black"></meta>

      {/* ----------blog or Article------------- */}

      {/*  




          <meta name='date' content='Sep. 27, 2010'/>  




          <meta name='search_date' content='2010-09-27'></meta>  




          <meta name='DC.title' content='Unstoppable Robot Ninja'/>  




          <meta name='revised' content='Sunday, July 18th, 2010, 5:15 pm'/>  




          <meta name='abstract' content=''/>  




          <meta name='topic' content=''/>  




          <meta name='summary' content=''/>  




          <meta name='Classification' content='Business'/>  




          <meta name='author' content='name, email@hotmail.com'/>  




          <meta name='designer' content=''/>  




          <meta name='reply-to' content='email@hotmail.com'/>  




          <meta name='owner' content=''></meta>  




                                                   */}

      {/* ----------------------------- */}
      {/* ----------JSON LD Schema for SEO------ */}

      <script type="application/ld+json">
        {JSON.stringify(
          // breadcrumbs---
          {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: `${baseUrl}/`,
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Coming Soon | Olibr is preparing something great for you",
                item: `${baseUrl}/coming-soon`,
              },
            ],
          }
        )}
      </script>
      {/* ----------------------------- */}
      <script type="application/ld+json">
        {JSON.stringify(
          // speakable--
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            name: "Coming Soon | Olibr is preparing something great for you",
            speakable: {
              "@type": "SpeakableSpecification",
              xpath: [
                "/html/head/title",
                "/html/head/meta[@name='description']/@content",
              ],
            },
            url: `${baseUrl}/coming-soon`,
          }
        )}
      </script>
      {/* ------------------------------ */}
      <script type="application/ld+json">
        {JSON.stringify(
          // --website/webpage---
          {
            "@context": "https://www.schema.org",

            "@type": "WebSite",

            name: "Coming Soon | Olibr is preparing something great for you",

            url: `${baseUrl}/coming-soon`,
          }
        )}
      </script>
    </Helmet>
  );
};

export default Comingsoon;
