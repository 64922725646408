import React from "react";
import { ReactComponent as Suitcase } from "./../../assets/img/icons/suitcase_new.svg";
import { Icon } from "../icon";

import { filterWeb } from "../../common/FilterWebsite";
import OlibrShareLink from "../share-link";
import { useState } from "react";
import { checkMultipleJob, getJobLinkUrl } from "../functions";
import {  useSelector } from "react-redux";
import { useEffect } from "react";
import CountryFlag from "./CountryFlag";
import JobLocation from "./job-location/JobLocation";
import JobDetailSkill from "./JobDetailSkill";
// import ctcIcon from "../../assets/img/icons/ctc-icon.svg";
// import yrexpIcon from "../../assets/img/icons/yr-exp.svg";

const JobDetail = ({
  jobDetail,
  details,
  apply,
  appliedArr = null,
  categoryName = null,
}) => {
  const [isINR, setIsInr] = useState(true);
  const [shareOpen, setShareOpen] = useState(false);
  const { stateList } = useSelector((state) => state.commonReducer);


  useEffect(() => {
    if (jobDetail?.jobCountry?.length < 0 || jobDetail?.jobCountry?.[0]?.countryCode == "IN" || jobDetail?.jobCountry?.countryCode == "IN") {
      setIsInr(true);
    }else{
      setIsInr(false);
    }
  }, [jobDetail]);

  return (
    <>
      <div className="jd-content">
        <OlibrShareLink
          isOpen={shareOpen}
          onClose={() => setShareOpen(false)}
          baseUrl={getJobLinkUrl(jobDetail?.slug || jobDetail?._id)}
          title={"Share Job"}
        />
        <div className="row">
          <div className="col-2">
            <div
              className="logo"
              onClick={() => details(jobDetail?.companySlug)}
              style={{ cursor: "pointer" }}
            >
              {/* {" "} */}
              <img src={jobDetail?.jobLogo} width={120} height={120} alt="" />
            </div>
          </div>
          <div className="col-10">
            <h1>
              {jobDetail?.jobTitle}{" "}
              {jobDetail?.isApproved && jobDetail?.status == "open" && (
                <span onClick={() => setShareOpen(true)}>
                  <Icon name="share-profile" />
                </span>
              )}
              <span className="text-right">
                <strong>Posted on : </strong>
                {new Date(jobDetail?.postDate || jobDetail?.createdAt)
                  .toDateString()
                  .split(" ")
                  .slice(1)
                  .join(" ")}{" "}
              </span>
            </h1>
            <div className="row">
              <div className="col-4 job-det">
                <p
                  onClick={() => details(jobDetail?.companySlug)}
                  style={{ cursor: "pointer" }}
                >
                  <span>
                    <Suitcase />
                  </span>
                  {jobDetail?.organisation}
                </p>
              </div>
              <div className="col-2">
                <p>
                  {/* <span><img src={yrexpIcon} style={{ width: "18px", height: "18px" }} /></span> */}
                  <span>
                    <img
                      src={
                        "https://ik.imagekit.io/olibr/icons/tr:w-18,h-18/yr-exp.svg"
                      }
                      style={{ width: "18px", height: "18px" }}
                    />
                  </span>
                  {jobDetail?.minExpRange} - {jobDetail?.maxExpRange} Yrs
                </p>
              </div>
              <div className="col-3">
                <p>
                  <span className="vertical-bottom">
                    {/* <img src={LocationIcon} height="13px" style={{ paddingTop: "0px", marginTop: "3px" }} /> */}
                    <CountryFlag jobCountry={jobDetail?.jobCountry} />
                  </span>
                  {jobDetail?.jobLocation
                    ? jobDetail?.jobLocation == "On Site" ||
                      jobDetail?.jobLocation == "Hybrid"
                      ? checkMultipleJob(jobDetail, stateList)
                      : jobDetail?.jobLocation
                    : "Remote"}
                </p>
              </div>
              {!jobDetail?.hideBudgetToDev &&
                !!jobDetail?.budgetMin &&
                !!jobDetail?.budgetMax && (
                  <div className="col-3">
                    <p>
                      <span>
                        <img
                          src={
                            "https://ik.imagekit.io/olibr/icons/tr:w-18,h-18/ctc-icon.svg"
                          }
                          style={{ width: "18px", height: "18px" }}
                          alt=""
                        />
                      </span>{" "}
                      {isINR
                        ? jobDetail?.budgetMin +
                          " - " +
                          jobDetail?.budgetMax +
                          " Lacs PA"
                        : "$" +
                          jobDetail?.budgetMin +
                          " - " +
                          jobDetail?.budgetMax +
                          " PA"}
                    </p>{" "}
                  </div>
                )}
            </div>
            <div className="row">
              <div className="col-12">
                <ul className="services-lists">
                  {jobDetail?.perksAndBenifits?.map((item) => (
                    <li>
                      <span
                        className="icon-round"
                        data-tooltip={item?.perkName}
                      >
                        <img src={item?.iconUrl} alt="" />
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <ul className="icon-tags job-detail-ul">
                  {/* {jobDetail?.devChooseSkills?.map((item) => (
                    <li key={item?.skillId}>
                      <a>
                        <img src={item?.skillTestIcon} alt="" />{" "}
                        {item?.skillName}
                        {item?.isRequired && (
                          <i
                            class="fa fa-star required-skill-star"
                            style={{ color: "#ff4000" }}
                            aria-hidden="true"
                          ></i>
                        )}
                      </a>
                    </li>
                  ))}
                  {jobDetail?.devChooseStacks?.map((item) => (
                    <li key={item?.stackId}>
                      <a>
                        <img src={item?.stackIcon} alt="" /> {item?.stackName}
                        {item?.isRequired && (
                          <i
                            class="fa fa-star required-skill-star"
                            style={{ color: "#ff4000" }}
                            aria-hidden="true"
                          ></i>
                        )}
                      </a>
                    </li>
                  ))} */}
                  <JobDetailSkill
                   skill={jobDetail?.devChooseSkills}
                   stack={jobDetail?.devChooseStacks}
                  />
                </ul>
              </div>
              <div className="col-12">
                  <ul className="skill">
                    {jobDetail?.tags?.map((skillTag, i) => (
                      <li key={i + Math.random()}>{skillTag}</li>
                    ))}
                  </ul>
                </div>
            </div>
            {jobDetail?.status == "closed" && (
              <div className="row">
                <div className="col-12">
                  <p className="text-right close-text-details">Closed</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="job-desc-block">
        <h4>
          Description{" "}
          <span className="jl-btn">
            <a href="#">&#9733;</a>
          </span>
        </h4>
        <p dangerouslySetInnerHTML={{ __html: jobDetail?.shortDesc }}></p>
      </div>
      <div className="job-desc-block">
        <h2>Job Responsibilities</h2>
        <div dangerouslySetInnerHTML={{ __html: jobDetail?.editorText }}></div>
      </div>
      <JobLocation jobDetail={jobDetail} />

      {jobDetail?.status == "open" && (
        <div className="video-banner-bg job-apply-banner">
          <div className="videobanner-text">
            <h3>Interested in this Job?</h3>
            <button
              className="themeBtn submitBtn redButton"
              onClick={() => apply(jobDetail)}
              disabled={appliedArr?.includes(jobDetail?._id)}
            >
              {appliedArr?.includes(jobDetail?._id) ? "Applied" : "Apply Now"}
            </button>
          </div>
        </div>
      )}

      <div className="job-postedby-block">
        <h4 className="post-title">Posted By</h4>
        <div className="row">
          <div className="col-12 flex">
            <div className="user">
              <Icon name="job-avatar" />
            </div>
            <div className="posted-by">
              <h5>{jobDetail?.postedBy}</h5>
              <p>{jobDetail?.organisation}</p>
            </div>
          </div>
        </div>
        <br />
        <h3 style={{ fontSize: "16px" }}>About Company</h3>
        <p>{jobDetail?.aboutCompany}</p>
        <div className="row">
          <div className="col-4">
            <div className="sub">Posted In:</div>
            <div className="non-sub">
              {categoryName || jobDetail?.jobCategory?.label}
            </div>
          </div>
          <div className="col-4">
            <div className="sub">website:</div>
            <a
              className="non-sub link-web"
              href={filterWeb(jobDetail?.companyWebsite)}
            >
              {jobDetail?.companyWebsite}
            </a>
          </div>
          <div className="col-4">
            <div className="sub">Address:</div>
            <div className="non-sub">{jobDetail?.companyAddress}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDetail;
