import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addFeatureAction,
  getFeatureAction,
  updateFeatureAction,
} from "../../../../service/action/admin";

const AddfeatureForm = ({data,setAddFeature}) => {
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const callBack=()=>{
    setAddFeature(false)
    dispatch(getFeatureAction());
  }

  const handleSave = (values) => {
    if (values?.featureId) {
      dispatch(updateFeatureAction(navigate, values,callBack));
    } else {
      dispatch(addFeatureAction(navigate, values,callBack));
    }
  };

  useEffect(()=>{
   if(data){
    setValue("title",data?.title)
    setValue("description",data?.description)
    setValue("featureId",data?._id)
   }else{
    reset()
    setValue("title","")
    setValue("description","")
   }
  },[data,setValue,reset])
  return (
    <div className="job-rquirement">
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label className={errors?.title && "text-danger"}>
              Feature Name*
            </label>
            <input
              {...register("title", {
                required: true,
              })}
              type="text"
              className="form-control"
              placeholder="Feature Name"
            />
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label>Feature Description</label>
            <input
              type="text"
              {...register("description")}
              className="form-control"
              placeholder="Feature Description"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-right">
          <button className="btn" onClick={handleSubmit(handleSave)}>
          {data?._id ? "Update" : "Add"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddfeatureForm;
