import React from "react";
import './SearchBar.css';
import { useState } from "react";
import CloseIcon from "../../../../assets/img/icons/close.svg"

const SearchBar = ({ setSearch, submitSearch, search,clearFilter }) => {
  return (
    <>
      <div className="container-fluide search-section">
        <div className="search-f">
          <h1>{"Search Jobs for the future!"}</h1>

          <div className="search-row">
            <div className="search-input">
              <input
                type="text"
                className="input-skill"
                placeholder={`Search Skills, Jobs here`}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e)=>{
                  if (e.key === 'Enter') {
                    submitSearch()
                  }
                }}
              />

            </div>
            {
              search && (
                <div className="search-select">
                  <img src={CloseIcon} width={"7px"} onClick={clearFilter}/>
                </div>
              )
            }

            {/* <div className="search-select">
              <select className="input-location">
                <option value=""> Experience</option>
                <option value="Deoria"> 2 years</option>
                <option value="Kushingar"> 3 years</option>
                <option value="Gorakhpur"> 4 years</option>
              </select>
            </div> */}
            <div className="search-btn">

              <a className="search-box-block" onClick={submitSearch}>&#10140;</a>
            </div>
          </div>

          {/* <div className="row">
            <div className="col-5 nopadding">
              <input
                type="text"
                className="input-skill"
                placeholder={`Search Skills, Jobs here`}
              />
            </div>
            <div className="col-3 nopadding">
              <select className="input-location">
                <option value=""> location</option>
                <option value="Deoria"> Deoria</option>
                <option value="Kushingar"> Kushingar</option>
                <option value="Gorakhpur"> Gorakhpur</option>
              </select>
            </div>
            <div className="col-3 nopadding">
              <select className="input-location">
                <option value=""> Experience</option>
                <option value="Deoria"> 2 years</option>
                <option value="Kushingar"> 3 years</option>
                <option value="Gorakhpur"> 4 years</option>
              </select>
            </div>
            <div className="col-1 nopadding">
              <a className="search-box-block">&#10140;</a>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default SearchBar;
