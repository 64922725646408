import { StyleSheet } from "@react-pdf/renderer";
import { Font } from '@react-pdf/renderer';
import popinMidium from "../../../../assets/fonts/Poppins-Medium.ttf"
import popinLight from "../../../../assets/fonts/Poppins-Light.ttf"
import popinRegular from "../../../../assets/fonts/Poppins-Regular.ttf"


Font.register({
  family: 'Poppins-Medium',
  fonts: [
    { src: popinMidium, fontWeight: 'normal' },

  ],
});

Font.register({
  family: 'Poppins-Light',
  fonts: [
    { src: popinLight, fontWeight: 'normal' },
  ],
});
Font.register({
  family: 'Poppins-Regular',
  fonts: [
    { src: popinRegular, fontWeight: 'normal' },
  ],
});
export const Certification = StyleSheet.create({
    rightLayout: {
    fontSize: "12px",
    width:"445px",
    fontFamily: "Poppins-Regular",
    flexDirection:"row"
  },
  left:{
    width:"500px",
    fontSize:"10px"
  },
  right:{
    width:"100px",
    fontSize:"9px",
    marginTop:"10px"
  },
  gray:{
    color:"#7f837f"
  }
 
})