import { StyleSheet } from "@react-pdf/renderer";
import { Font } from '@react-pdf/renderer';
import popinMidium from "../../../../assets/fonts/Poppins-Medium.ttf"
import popinLight from "../../../../assets/fonts/Poppins-Light.ttf"
import popinRegular from "../../../../assets/fonts/Poppins-Regular.ttf"


Font.register({
    family: 'Poppins-Medium',
    fonts: [
        { src: popinMidium, fontWeight: 'normal' },

    ],
});

Font.register({
    family: 'Poppins-Light',
    fonts: [
        { src: popinLight, fontWeight: 'normal' },
    ],
});
Font.register({
    family: 'Poppins-Regular',
    fonts: [
        { src: popinRegular, fontWeight: 'normal' },
    ],
});
export const MyReviewsCSS = StyleSheet.create({
    container: {
        width: "75%",
        backgroundColor: "#f6fbfe",
        marginRight: "20px",
        border: "1px solid #d0dded",
        borderRadius: "8px"
    },
    quoteImage: {
        width: "60px",
        height: "30px",
        margin: "5px auto"
    },
    quoteImageContainer: {
        width: "100%",
    },
    message: {
        fontFamily: "Poppins-Light",
        fontSize: "10px",
        margin: "10px",
        textAlign: "center"
    },
    ratingContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center"
    },
    ratingItem: {
        flexDirection: "row",
        padding: "5px"
    },
    ratingItemContainer: {
        border: "1px solid #c7e5eb",
        borderRadius: "8px",
        flexDirection: "row"
    },
    pointText: {
        fontSize: "8px",
        padding: "3px 3px 3px 8px"
    },
    blueStar: {
        margin: "2px 1px",
        height: "9px",
        width: "9px"
    },
    ratingNo: {
        fontSize: "8px",
        padding: "2px 2px",
    },
    reviewerName: {
        textAlign: "center",
        fontSize: "10px",
        fontFamily: "Poppins-Medium"
    },
    reviewerDesg: {
        textAlign: "center",
        fontSize: "10px",
        fontFamily: "Poppins-Light"
    },
    footerArea:{
        height:"16px",
        width:"100%",
        flexDirection:"row",
    },
    footerLeft:{
        width:"50%",
        height:"100%",
        marginLeft:"10px"
    },
    footerRight:{
        width:"50%",
        height:"100%",
        flexDirection:"row",
        marginRight:"10px",
        justifyContent:"flex-end"
    },
    footerLeftBox:{
        width:"50px",
        height:"13px",
        backgroundColor:"#d7f2e3",
        borderRadius:"6px",
        flexDirection:"row"
       
    },
    lastDate:{
        fontSize:"6px",
        fontFamily: "Poppins-Light",
        padding:"1px"
    },
    startIcon:{
        width:"10px",
        height:"10px"
    }



})