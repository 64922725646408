export const accordionData = [
  {
    title: "Why should I hire developers from Olibr?",
    content: `Olibr has a vast network of pre-vetted, seasoned developers ready to work full-time from their remote locations. Our deep matching and extensive vetting process source all the data required to vet developers to match them with US job market standards. Our AI matches and finds the best fit for your business requirements from our large community of vetted developers. Many leading US companies are hiring with us for our proven success in distributed teams, quick scalability, higher retention, security and compliance, and complete remote onboarding & support.`,
  },
  {
    title: "What is the process to hire a developer from Olibr?",
    content: `Hiring developers with Olibr is easy. You get access to our large database of vetted developers. You can shortlist the candidates that best suit your business requirements and we will arrange an interview for you with the shortlisted candidates. Secondly, if you don’t find what you are looking for in our database, you can contact us and share your specific requirements. Our experts will assess your needs and look for candidates that fit your job requirements. They will send you the shortlisted candidates and you can pick your best fit to interview and hire. Lastly, Olibr gives you the liberty to do your own job posting on our website. Suitable candidates can apply for the position directly and you can choose from the applied list.`,
  },
  {
    title: "What type of work engagements does Olibr offer?",
    content: `Olibr only offers long-term full-time job engagements between clients and developers.`,
  },
  {
    title: "Can Olibr build up a team for us?",
    content: `Yes, Olibr can help you scale up your team. You will just have to share your requirements with us, and we will find you candidates that are perfectly suitable to scale up your dream team. You also get access to our large database of developers to choose from. Shortlist your preferred candidates and inform us so that we can arrange an interview for you with the candidates. You also can post jobs directly on our website and select candidates accordingly.  `,
  },
  {
    title: "How quickly can I hire an Olibr developer? ",
    content: `Hiring a dedicated full-time developer takes time. Unlike freelancers who are readily available and work on multiple projects at a time, dedicated full-time developers are looking for the best opportunity that offers career growth, stability, and other benefits. Remember, you are at Olibr to hire senior skilled developers. They are the cream of the crop and transitioning from one position to another involves certain rules and procedures. As they are dedicated employees, they are expected to serve a 1-3 months’ notice period in their current positions so either you will have to give them the time to serve their notice period or buy out the candidate’s notice period.`,
  },
];

export const olibrBanner = "Experience Hiring Perfection - Connect with Olibr's <br><strong>Top 5% Developers</strong>";
export const olibrPara =
  "<p>Transform Your Tech Journey with Olibr's Elite Developer Network</p>";
export const hireDeveloper = "Hire Developer";
export const fullTime = "Full-Time Engagements With";
export const worldClassDevelopers = "World-Class Developers";
export const leverageTechnologies =
  "Leverage access to senior remote developers skilled in over 20 technologies";

export const hiring = "Hiring With Olibr Is Easy";
export const accessDevelopers =
  "<li><h4>Browse our extensive database of <span>pre-vetted developers</span> and handpick the top candidates for your needs.</h4></li><li><h4>Publish your job openings on Olibr and receive applications from interested candidates directly through our platform.</h4></li><li><h4>Conduct interviews with your shortlisted candidates and select the ideal match for your organization.</h4></li>";
export const combProfiles =
  "Publish your job openings on Olibr and receive applications from interested candidates directly through our platform.";
export const inform =
  "Conduct interviews with your shortlisted candidates and select the ideal match for your organization.";
export const trendSetters = "We Are Trendsetters In Tech Hiring";
export const trendsPara =
  "Bid adieu to the old ways of hiring! Hire the best tech talent from the vast and choicest community of developers at Olibr!";
export const extensivepipline =
  "Join Olibr to find the finest talent from our diverse pool of developers.";
export const remotePractice =
  "Lead the tech hiring revolution - Olibr connects you with exceptional talent from an extensive developer pool.";
export const jobs =
  "Over 20 technologies available: React, Node, Python, Angular, Swift, React Native, Android, Java, Rails, Golang, DevOps, ML, Data Engineers, and more.";
export const businessChoose = "What Olibr Offers?";
export const businessPara =
  "Olibr is the leading, cutting-edge job platform dedicated exclusively to fully vetted developers and programmers, streamlining the hiring process for top tech talent. ";

export const benefits = "Why Choose Olibr?";
export const benefitsPara4 =
  "So what are you waiting for? Team up with the brilliant minds in the industry right away";
const twoWeekTrail =
  "Elite Talent Pool";
const eliteTalent =
  "Every developer clears rigorous HackerRank tests.";
const libertyJobs =
  "Save 70%+ time";
const saveTime =
  "Dive into a pre-filtered talent pool and fast-track recruitment.";
const exclusive = "Candidate Filtration";
const candidateFiltration =
  "Post jobs and invite candidates. Only those who clear coding tests can apply.";
export const testimonal =
  "Join the top league of companies including fast-growing start-ups, tech unicorns, and Fortune 500 companies that are hiring Olibr developers";
export const frequentlyAsked = "Frequently Asked Questions ";
export const viewMore = "View more FAQs";
export const ourBlog = "Our Blog";
export const blogPara =
  "Get in-depth insight into recent trends and innovation in business and technology through well-researched articles by industry experts";
export const danNapierski = "Dan Napierski";
export const freelanceAngular = "Freelance Angular Developer";
export const citizen = "Is the Citizen Developer the New Face of Agility?";
export const olibrBlogMatches =
  "Olibr matches architects with long-term remote iOS opportunities at leading companies. Additionally, We provides professional growth opportunities including a global talent community,.";
export const blogDate = "11 March 2022";
export const commonMistakes = "Six Common Interview Mistakes Remote Developers";

export const logoArr = [
  "logo-partner1",
  "logo-partner2",
  "logo-partner3",
  "logo-partner4",
  "logo-partner5",
  "logo-partner6",
  "logo-partner7",
];

const vastCommunity = "Unlimited Users";
const hugeDatabase =
  "Collaborate seamlessly with your entire team, no user limits.";

const distributedTeams = "Database Access ";
const withInaTeam =
  "Discover top talent effortlessly with our free resume database access – the perfect resource to elevate your hiring game!";

const customizedSolutions = "Unlimited Candidate Filtration Tests";
const AIfinds =
  "Assess as many candidates as you need, no test restrictions.";

const quickScalability = "Unlimited Job Postings ";
const scalabilityRequirement =
  "Unlock endless opportunities with our unlimited job postings feature – success is just a post away! ";

const remoteOnboarding = "Custom Test Evaluation";
const olibrDevelopersReady =
  "Tailor assessments to your unique criteria for precise candidate evaluation.";

// const vettedDevelopers = "Efficient Hiring";
// const screenedInterviews =
//   "Optimize your hiring with Olibr, dramatically reducing time and cost by over 70% compared to conventional recruitment methods.";

// const higherRetention = "Higher Retention";
// const higherRetentionPara =
//   "Olibr only provides you with developers who are ready for full-time engagements. We understand how much time and effort is spent on training and onboarding an employee.";

const overlap = "Online Coding Interview Platform ";
const overlapPara =
  "Maximize candidate assessments using our live coding interview platform, allowing real-time evaluation of developer skills through coding tests.";

// const securityHead = "Direct Hiring Tool ";
// const securityPara =
//   "Can't find the developers you seek? Reach out for our specialized services at no extra cost, making direct hiring a breeze.";

export const whyDoBussinessCardContent = [
  {
    title: quickScalability,
    para: scalabilityRequirement,
    // iconImage: "quickscale-icon",
    iconImage: "full-time-work",
  },
  {
    title: vastCommunity,
    para: hugeDatabase,
    iconImage: "commu-icon",
  },
  {
    title: overlap,
    para: overlapPara,
    iconImage: "developer-icon",
  },
  {
    title: customizedSolutions,
    para: AIfinds,
    iconImage: "custsol-icon",
  },
  {
    title: distributedTeams,
    para: withInaTeam,
    // iconImage: "provesucc-icon",
    iconImage: "better-profile",
  },
  {
    title: remoteOnboarding,
    para: olibrDevelopersReady,
    iconImage: "remoteon-icon",
  },
  // {
  //   title: vettedDevelopers,
  //   para: screenedInterviews,
  //   iconImage: "overlapus-icon",
  // },
  // {
  //   title: higherRetention,
  //   para: higherRetentionPara,
  //   iconImage: "highret-icon",
  // },
  // {
  //   title: securityHead,
  //   para: securityPara,
  //   iconImage: "secandcomp-icon",
  // },
];

export const homeBannerContentSection = [
  {
    imageName: "inter-icon",
    para: twoWeekTrail,
    desc: eliteTalent,
  },
  {
    imageName: "free-trial",
    para: libertyJobs,
    desc: saveTime,
  },
  {
    imageName: "job-posting-icon",
    para: exclusive,
    desc: candidateFiltration,
  },
];

const FEdevps = "Front-end Developers";
const FEpara =
  "Hire the best front-end developers with hands-on expertise in UI/UX designs, CSS, JavaScript, HTML, and a growing collection of UI frameworks.";

const fullStackdevps = "Full-stack Developers";
const fullstackPara =
  "Hire seasoned full-stack developers with 5+ years of experience in building a fully functional web application.";

const devopsEngineer = "DevOps Engineers";
const devopsPara =
  "Hire the best DevOps developers with experience in technologies including Kubernetes, Terraform, Docker, Apache Jenkins, and more.";

const dataScientist = "Data Scientist";
const dataScientistPara =
  "Senior Data Scientists with experience in machine learning, data visualization, predictive modelling, and more.";

const mobileDevelopers = "Mobile Developers";
const mobileDevelopersPara =
  "Find seasoned mobile developers proficient with Android and iOS, and languages such as Java, Swift, Objective-C, C++, Java, Kotlin, C# and more.";

const BEDevps = "Back-end Developers";
const BEpara =
  "Hire highly skilled backend developers well-versed in all the top programming languages such as Python, Java, C, C++, Ruby, Perl, Scala, Go, and more.";

export const whyJoinOlibrConstant = [
  {
    title: FEdevps,
    para: FEpara,
    iconImage: "developer-icon",
  },
  {
    title: fullStackdevps,
    para: fullstackPara,
    iconImage: "fullstack-icon",
  },
  {
    title: devopsEngineer,
    para: devopsPara,
    iconImage: "digi-icon",
  },
  {
    title: dataScientist,
    para: dataScientistPara,
    iconImage: "software-icon",
  },
  {
    title: mobileDevelopers,
    para: mobileDevelopersPara,
    iconImage: "design-icon",
  },
  {
    title: BEDevps,
    para: BEpara,
    iconImage: "backenddev-icon",
  },
];
