import DateFormate from "../../../common/DateFormate";

const ColumnLists = () => {
  const columns = [
    {
        name: "S.No",
        selector: "sNo",
        sortable: true,
        cell:(row,index)=>index+1,
        width:"100px"
    }, {
        name: "Admin Name",
        selector: "adminName",
        sortable: true,
    }, {
        name: "Admin Email",
        selector: "adminEmail",
        sortable: true,

    }, {
        name: "Developer Name",
        selector: "developerName",
        sortable: true,
    }, {
        name: "Developer Email",
        selector: "developerEmail",
        sortable: true,
    }, {
        name: "Switch",
        selector: "switch",
        sortable: true,
        cell:(row)=>row?.switch?"Spotlighted":"Removed"
    }, {
        name: "Action Date",
        selector: "createdAt",
        sortable: true,
        cell:(row)=>DateFormate(row?.createdAt)
    },
   
];
return columns;
}

export default ColumnLists