import React from "react";
import SelectOption from "../../../components/select/SelectOption";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  getJobFullClientAdmin,
  getJobTitleListAction,
} from "../../../service/action/admin";
import { valueBoolean } from "../../../common/constant";

const CompanyJobForm = ({
  selectedEmail,
  setQuestionaries,
  setJobAddress,
  setLocation,
  setSelectedEmail,
  reset,
  setValue,
  categList,
  setTags,
  setCountry,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientEmailList, jobTitleLists, clientFullJob } = useSelector(
    (state) => state.adminReducer
  );
  const [jobTitle, setJobTitle] = useState({});

  useEffect(() => {
    if (selectedEmail?.value) {
      dispatch(getJobTitleListAction(navigate, selectedEmail));
    } else {
      setJobTitle({});
      reset();
    }
  }, [selectedEmail, navigate, dispatch, reset]);


  useEffect(() => {
    if (jobTitle?.value) {
      dispatch(getJobFullClientAdmin(jobTitle?.value, navigate));
    }
  }, [jobTitle, navigate, dispatch]);

  useEffect(() => {
    if (clientFullJob && clientFullJob?.data) {
      let tempObj = { ...clientFullJob?.data };
      setTags(tempObj?.tags);
      setValue("hideBudgetToDev", tempObj?.hideBudgetToDev);
      setValue("clientId", tempObj?.clientId);

      setValue("jobTitle", tempObj?.jobTitle);
      setValue("jobLocation", tempObj?.jobLocation);

      setValue("minExpRange", parseInt(tempObj?.minExpRange));
      setValue("maxExpRange", parseInt(tempObj?.maxExpRange));
      setValue(
        "jobCategory",
        categList?.find((item) => item?.value === tempObj?.jobCategory)
      );
      setValue(
        "isPopular",
        valueBoolean?.find((item) => item?.value == tempObj?.isPopular)
      );
      setLocation(tempObj?.jobLocation);

      setJobAddress(tempObj?.jobAddress);
      let skills_arr = tempObj?.devChooseSkills?.map((item) => ({
        ...item,
        value: item?._id,
        label: item?.skillName || item?.stackName,
      }));
      let stack_arr = tempObj?.devChooseStacks?.map((item) => ({
        ...item,
        value: item?._id,
        label: item?.skillName || item?.stackName,
      }));
      setValue("devChooseSkills", skills_arr);
      setValue("devChooseStacks", stack_arr);
      setQuestionaries({
        data: tempObj?.questionaries,
      });
      setValue(
        "postDate",
        tempObj?.postDate
          ? new Date(tempObj?.postDate).toISOString().split("T")[0]
          : new Date().toISOString().split("T")[0]
      );

      setValue("budget", tempObj?.budget);
      setValue("budgetMin", tempObj?.budgetMin);
      setValue("budgetMax", tempObj?.budgetMax);
      setValue("budgetFor", tempObj?.budgetFor);

      setValue("tags", tempObj?.tags);
      setValue("editorText", tempObj?.editorText);
      setValue("shortDesc", tempObj?.shortDesc);
      if (tempObj?.jobCountry?.length) {
        setCountry(tempObj?.jobCountry);
      } else {
        setCountry([{ countryCode: "IN", countryName: "India" }]);
      }
    }
  }, [
    clientFullJob,
    setValue,
    setQuestionaries,
    categList,
    setTags,
    setJobAddress,
    setLocation,
  ]);

  useEffect(() => {
    if (!jobTitleLists) {
      setJobTitle({});
      setJobAddress([
        {
          countryCode: "",
          state: "",
          city: "",
          stateName: "",
        },
      ]);
      setLocation("");
      setTags([]);
      reset();
    } else {
      setValue("employerRole", selectedEmail?.designation);
      setValue("postedBy", selectedEmail?.name);
      setValue("companyEmail", jobTitleLists?.company?.companyWebsite);
      setValue("organisation", jobTitleLists?.company?.clientCompanyName);
      setValue("jobLogo", jobTitleLists?.company?.companyLogo);
      setValue("companyAddress", jobTitleLists?.company?.companyAddress);
      setValue("aboutCompany", jobTitleLists?.company?.aboutCompany);
    }
  }, [jobTitleLists, setValue, reset, selectedEmail]);

  return (
    <>
      <div className="col-4">
        <div className="form-group">
          <label>Client Email</label>
          <SelectOption
            optionArray={
              clientEmailList?.data
                ? [{ value: "", label: "" }, ...clientEmailList.data]
                : []
            }
            state={selectedEmail}
            onChangeFunction={(e) => setSelectedEmail(e)}
            closeMenuOnSelect={true}
            themeStyle={true}
          />
        </div>
      </div>
      <div className="col-4">
        <div className="form-group">
          <label>Job Template</label>
          <SelectOption
            optionArray={jobTitleLists?.data || []}
            state={jobTitle}
            onChangeFunction={(e) => setJobTitle(e)}
            closeMenuOnSelect={true}
            themeStyle={true}
          />
        </div>
      </div>
    </>
  );
};

export default CompanyJobForm;
