import React, { Fragment } from "react";

const AssesmentTitle = ({getWatch,setJobValue}) => {
  return (
    <Fragment>
      <div className="col-12">
        <div className="ss-click">
          <strong>Note: </strong>Each Skill and Stack is associated with a
          HackerRank test. Simply pick the skills or stacks that you'd like
          developers to pass.<br></br> Check out this{" "}
          <a href="/skill-library" className="link" target="_blank">
            handy HackerRank Test Guide{" "}
          </a>
          to understand more about the tests.
        </div>
      </div>
      <div className="col-6">
        <label
          htmlFor="vettedCandidates"
          className={
            "pointer radio-btn-big radio-btn-md " +
            (getWatch?.isAssessmentRequired && "active")
          }
          onClick={() => setJobValue("isAssessmentRequired", true)}
        >
          <input
            type="radio"
            id="vettedCandidates"
            name="interview-type"
            checked={getWatch?.isAssessmentRequired}
          />
          Only candidates clearing the test can apply
        </label>
      </div>
      <div className="col-6">
        <label
          htmlFor="verifiedCandidates"
          className={
            `pointer radio-btn-big radio-btn-md ` +
            (!getWatch?.isAssessmentRequired && "active")
          }
          onClick={() => setJobValue("isAssessmentRequired", false)}
        >
          <input
            type="radio"
            id="verifiedCandidates"
            name="interview-type"
            checked={!getWatch?.isAssessmentRequired}
          />
          Test clearing is optional but if resume matches apply
        </label>
      </div>
    </Fragment>
  );
};

export default AssesmentTitle;
