import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../components/icon";
import Modal from "../../../components/modal/ModalBox";
import {
  createDevReviewAction,
  deleteDevReviewAction,
  getDevReviewAction,
  getProfileStatus,
  resendDevReviewAction,
} from "../../../service/action/developer";
import { personalEmail } from "../../../common/constant/personalEmail";
import Tooltip from "../../../components/tooltip/Tooltip";
import { statusObject, temp } from "./constants";
import ReviewHead from "./ReviewHead";
import ReviewRow from "./ReviewRow";
import ReviewBlock from "./ReviewBlock";
import ReviewHeader from "./ReviewHeader";
import { ratingName } from "../../reviews/constant";
import { ClipLoader } from "react-spinners";

const MyReview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProfileStatus(navigate));
    dispatch(getDevReviewAction(navigate));
  }, []);

  const [emailPattern, setEmailPattern] = useState(personalEmail?.diffEmail);
  useEffect(() => {
    window.location.href?.includes(`http://localhost:3000/`) ||
    window.location.href?.includes(`https://test.olibr.com/`) ||
    window.location.href?.includes(`https://dev.olibr.com/`)
      ? setEmailPattern(personalEmail?.email)
      : setEmailPattern(personalEmail?.diffEmail);
  }, []);

  const { devReviews, isReviewCreateLoad } = useSelector((state) => state?.developerReducer);
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    control: reviewRegister,
    reset: resetForm,
    formState: { errors: reviewError },
  } = useForm();
  const [modal, setModal] = useState(false);

  const sendReview = (data) => {
    console.log("data", data);
    dispatch(createDevReviewAction(data, navigate, closeReviewModal));
  };

  const resendReviewRequest = (data) => {
    console.log("resend hit")
    dispatch(resendDevReviewAction(data, navigate));
  };

  const reviewContent = () => (
    <form>
      <div>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label
                className={`floating-label ${
                  reviewError?.receiverName && "text-danger"
                }`}
              >
                Name*
              </label>
              <input
                className="form-control"
                type="text"
                {...register("receiverName", {
                  required: true,
                })}
                name="receiverName"
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label
                className={`floating-label ${
                  reviewError?.receiverEmail && "text-danger"
                }`}
              >
                Work Email*
              </label>
              <input
                className="form-control"
                type="text"
                {...register("receiverEmail", {
                  required: true,
                  pattern: emailPattern,
                })}
                name="receiverEmail"
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label
                className={`floating-label ${
                  reviewError?.receiverDesg && "text-danger"
                }`}
              >
                Designation*
              </label>
              <input
                className="form-control"
                type="text"
                {...register("receiverDesg", {
                  required: true,
                })}
                name="receiverDesg"
              />
            </div>
          </div>
          <div className="col-6 ">
            <div className="form-group">
              <label
                className={`floating-label ${
                  reviewError?.companyName && "text-danger"
                }`}
              >
                Company*
              </label>
              <input
                className="form-control"
                type="text"
                {...register("companyName", {
                  required: true,
                })}
                name="companyName"
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label
                className={`floating-label ${
                  reviewError?.message && "text-danger"
                }`}
              >
                Message*
              </label>
              <textarea
                className="form-control"
                {...register("message", { required: true })}
              />
            </div>
          </div>
          <div className="col-12 text-right">
            <button
              className="cancelBtn"
              type="button"
              onClick={closeReviewModal}
            >
              Cancel
            </button>
            <button className="submitBtn" onClick={handleSubmit(sendReview)}>
              {isReviewCreateLoad ? <ClipLoader color="white" size={"20px"}/> : "Send"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );

  const [toolTipOpen, setToolTipOpen] = useState(false);

  const closeToolTip = () => {
    setToolTipOpen(false);
  };

  const [deleteIdModal, setDeleteIdModal] = useState({
    deleteModalOpen: false,
    deleteId: "",
  });

  const deleteContent = () => {
    return (
      <Fragment>
        <div className="form-group">
          <p>Are you sure you want to delete this Feedback Request?</p>
        </div>
      </Fragment>
    );
  };

  const deleteModalClose = () => {
    setDeleteIdModal({
      deleteModalOpen: false,
      deleteId: "",
    });
  };

  const handleDeleteReview = () => {
    let reviewId = deleteIdModal?.deleteId;
    dispatch(deleteDevReviewAction({ reviewId }, navigate, deleteModalClose));
  };

  const deleteModalOpen = (id) => {
    setDeleteIdModal({
      ...deleteIdModal,
      deleteModalOpen: true,
      deleteId: id,
    });
  };

  const closeReviewModal = () => {
    setModal(false);
    resetForm();
  };

  const [responseModal, setResponseModal] = useState(false);
  const [responseData, setResponseData] = useState({});

  const onRowClick = (row) => {
    setResponseData(row);
    setResponseModal(true);
  }
  const responseModalContent = () => {
    return (
      <Fragment>
        <div className="review-modal">
        <div className="row">
          <div className="col-12">
          Your Message: <p>{responseData?.message}</p>
          </div>
            {responseData?.status === "done" && (
            <Fragment>
         <div className="col-12" style={{width: "80%", margin: "auto", border: "1px solid #e2e2e2"}}>
            <div className="rating-stars">
              {Object.keys(ratingName)?.map((item) => (
                <div className="row">
                  <div className="col-6">
                    <span className="para">{ratingName[item]}</span>
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <div className="col-6" style={{textAlign: "right"}}>
                    {[0, 0, 0, 0, 0]?.map((star, index) => (
                      <span
                        className={`fa fa-star fa-lg ${
                          index < responseData?.rateParams?.[item]
                            ? "rating-checked"
                            : "rating-unchecked"
                        }`}
                      ></span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
            <div className="col-12">
            <div className="comment">
              Comments: 
              <p>{responseData?.comment}</p>
            </div>
            </div>
            </Fragment>
            )}
        </div>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Tooltip
        title={"Reviews"}
        tooltipText={temp}
        open={toolTipOpen}
        onClose={closeToolTip}
      />
      <Modal
        open={responseModal}
        title={"Feedback Response"}
        onClose={() => setResponseModal(false)}
        isFooterView={false}
        content={responseModalContent}
      />
      <Modal
        open={deleteIdModal.deleteModalOpen}
        title={"Delete Review"}
        onClose={deleteModalClose}
        onCancel={deleteModalClose}
        onDone={handleDeleteReview}
        content={deleteContent}
      />
      <Modal
        title={"Request Feedback"}
        open={modal}
        onClose={closeReviewModal}
        content={reviewContent}
        isFooterView={false}
      />
      <div className="main-div-developer">
        <div className="skills-management">
          <div className="main-div-blog">
            <div className="blog-list">
              <ReviewHeader
                onInfoClick={() => setToolTipOpen(true)}
                onAddClick={() => setModal(true)}
              />
              {!devReviews?.length ? (
                <ReviewBlock
                  onClick={() => {
                    resetForm();
                    setModal(true);
                  }}
                />
              ) : (
                <div className="table">
                  <table className="table-blog">
                    <ReviewHead />
                    <tbody>
                      {devReviews?.map((row, index) => (
                        <ReviewRow
                          index={index}
                          item={row}
                          rowOpen={() => onRowClick(row)}
                          onDelete={() => deleteModalOpen(row?._id)}
                          onResend={() => resendReviewRequest(row?._id)}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MyReview;
