import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { termsAndConditionOG } from "../imageLinks";
import NoFollow from "../../../common/staticData/seo/NoFollow";

const Terms = ({ baseUrl }) => {
  return (
    <Helmet>
      <title>
        Terms and Conditions of Olibr | For Users, Visitors, & others
      </title>

      <meta charset="UTF-8" />

      <meta
        name="title"
        content="Terms and Conditions of Olibr | For Users, Visitors, & others"
      />

      <meta
        name="description"
        content="Know more about Olibr’s terms and conditions for all visitors, users, developers, hiring companies, & others looking to use our services.!"
      ></meta>

      <meta
        name="keywords"
        content="about olibr.com, olibr, olibr.com, Hiring developers, remote software developers, work-from-home developers, wfh, remote jobs, IT jobs, US based IT companies, US Jobs, US IT Jobs"
      />

      <meta name="language" content="english"></meta>

      <html lang="en" />

      <meta name="robots" content={NoFollow()}></meta>

      <meta name="url" content={`${baseUrl}/term-and-conditions`} />

      <meta
        name="identifier-URL"
        content={`${baseUrl}/term-and-conditions`}
      ></meta>

      {/* <meta name='subtitle' content='about us -- learn more'></meta> */}

      <link rel="canonical" href={`${baseUrl}/term-and-conditions`} />

      <meta name="referrer" content="origin-when-crossorigin"></meta>

      <link rel="image_src" href={termsAndConditionOG} />

      <meta name="copyright" content="olibr.com" />

      {/* ----Social Media Open Graph--------- */}

      <meta
        property="og:title"
        content="Olibr's Terms & Conditions | For Users, Visitors, & others"
      />

      <meta property="og:type" content="company" />

      <meta property="og:url" content={`${baseUrl}/term-and-conditions`} />

      <meta property="og:image" content={termsAndConditionOG} />

      <meta property="og:site_name" content="olibr.com" />

      <meta
        property="og:description"
        content="Know more about Olibr’s terms and conditions for all visitors, users, developers, hiring companies, & others looking to use our services."
      ></meta>

      {/* <meta name='medium' content='blog'></meta> */}

      {/*   <meta name='fb:page_id' content='------------'></meta> */}

      {/* ---Organisation Details -Open Graph Business Tags----- */}

      <meta property="og:type" content="business.business" />

      <meta
        property="business:contact_data:street_address"
        content="26 Arrowhead Ln"
      />
      <meta property="business:contact_data:locality" content="Menlo Park" />
      <meta property="business:contact_data:region" content="CA" />
      <meta property="business:contact_data:postal_code" content="94025" />
      <meta property="business:contact_data:country_name" content="US" />

      {/* <meta name="og:latitude" content="37.416343" />

        <meta name="og:longitude" content="-122.153013" />


        <meta name="og:email" content="me@example.com" />

        <meta name="og:phone_number" content="650-123-4567"></meta> */}

      {/* ------ Twitter Cards--------- */}

      <meta name="twitter:site" content="@olibr" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Olibr's Terms & Conditions | For Users, Visitors, & others"
      />

      <meta
        name="twitter:description"
        content="Know more about Olibr’s terms and conditions for all visitors, users, developers, hiring companies, & others looking to use our services."
      />

      {/*  <meta name="twitter:creator" content="@olibr"/> */}

      <meta name="twitter:image" content={termsAndConditionOG} />

      {/* ------Apple Devices ------ */}

      <meta
        name="apple-mobile-web-app-title"
        content="Olibr's Terms & Conditions | For Users, Visitors, & others"
      />

      <meta name="apple-mobile-web-app-capable" content="yes" />

      <meta name="apple-touch-fullscreen" content="yes" />

      <meta name="apple-mobile-web-app-status-bar-style" content="black"></meta>

      {/* ----------blog or Article------------- */}

      {/* 

                  <meta name='date' content='Sep. 27, 2010'/> 

                  <meta name='search_date' content='2010-09-27'></meta> 

                  <meta name='DC.title' content='Unstoppable Robot Ninja'/> 

                  <meta name='revised' content='Sunday, July 18th, 2010, 5:15 pm'/> 

                  <meta name='abstract' content=''/> 

                  <meta name='topic' content=''/> 

                  <meta name='summary' content=''/> 

                  <meta name='Classification' content='Business'/> 

                  <meta name='author' content='name, email@hotmail.com'/> 

                  <meta name='designer' content=''/> 

                  <meta name='reply-to' content='email@hotmail.com'/> 

                  <meta name='owner' content=''></meta> 

                                                           */}

      {/* ----------JSON LD Schema for SEO------ */}

      <script type="application/ld+json">
        {JSON.stringify(
          // ----breadcrumbs------
          {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Olibr Home",
                item: baseUrl,
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Olibr's Terms & Conditions | For Users, Visitors, & others",
                item: `${baseUrl}/term-and-conditions`,
              },
            ],
          }
        )}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(
          // ----breadcrumbs------
          {
            "@context": "https://www.schema.org",
            "@type": "WebSite",
            name: "Olibr's Terms & Conditions | For Users, Visitors, & others",
            url: `${baseUrl}/term-and-conditions`,
          }
        )}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(
          // ----breadcrumbs------
          {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Olibr Home",
                item: baseUrl,
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Olibr's Terms & Conditions | For Users, Visitors, & others",
                item: `${baseUrl}/term-and-conditions`,
              },
            ],
          }
        )}
      </script>
      {/* -------------------------- */}
      <script type="application/ld+json">
        {JSON.stringify(
          // Speakable ---
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            name: "Olibr's Terms & Conditions | For Users, Visitors, & others",
            speakable: {
              "@type": "SpeakableSpecification",
              xpath: [
                "/html/head/title",
                "/html/head/meta[@name='description']/@content",
              ],
            },
            url: `${baseUrl}/term-and-conditions`,
          }
        )}
      </script>
      {/* -------------------- */}
      <script type="application/ld+json">
        {JSON.stringify(
          // ---webpage/website---
          {
            "@context": "https://www.schema.org",
            "@type": "WebSite",
            name: "Olibr's Terms & Conditions | For Users, Visitors, & others",
            url: `${baseUrl}/term-and-conditions`,
          }
        )}
      </script>
    </Helmet>
  );
};

export default Terms;
