export const temp = 
`<div>
<h4>Importance of Review</h4><br/>
<ul>
<li><strong>Boost Credibility</strong>: Testimonials and reviews from previous employers add credibility to your skills, expertise, and work experience. They demonstrate that you have successfully worked with others in the past and can be trusted to do the same in the future.</li>
<li><strong>Enhance Job Prospects</strong>: Having positive reviews and testimonials assert that you have a good work ethic and are an asset to any organization.</li>
<li><strong>Expand your Reach</strong>: Positive testimonials and reviews can also help you expand your professional network. When potential employers see that you have a good reputation, they may be more likely to connect with you and offer new job opportunities.</li>
<li><strong>Career Growth</strong>: Testimonials and reviews can also serve as a stepping stone to career growth. They can help you land a new job with better pay.</li>  
</ul></div>\n\n`

export const statusObject = {
    "pending" : {title: "Pending", class: "text-danger"},
    "resent" : {title: "Resent", class: "text-warning"},
    "done" : {title: "Done", class: "text-success"}
}

export const isCoreArray = [
    {value: true, label: "Core"},
    {value: false, label: "Non Core"}
]